import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import firebase from 'firebase/compat/app';
import { BaseProduct } from 'src/app/core/services/interfaces';
import { OrdersService } from 'src/app/core/services/orders.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-prescription-manager',
  templateUrl: './prescription-manager.component.html',
  styleUrls: ['./prescription-manager.component.scss']
})
export class PrescriptionManagerComponent implements OnInit {
  @ViewChild('editPrescriptionModal', { read: TemplateRef }) editPrescriptionModal:TemplateRef<any>;
  @Input() user: any;
  prescriptions: any[] = [];
  loading = true;
  currentLang: string = 'en';

  constructor(private afs: AngularFirestore,
              private modalService: NgbModal,
              private orderService: OrdersService,
              private router: Router) { }
  
  ngOnInit() {

    if(!this.user) {
      this.afs.firestore.collection('users').doc(firebase.auth().currentUser.uid).get().then((user: any) => {
        this.user = user.data();
        this.getPrescriptions();
      })
    }
    else {
      this.getPrescriptions();
    }
  }

  getPrescriptions() {
    this.afs.firestore.collection('prescription').where("uid", "==", this.user.uid).where('pharmacy', '==', 'tempExternalPharmacy').where('valid', '==', true).get().then((querySnapshot) => {
      this.prescriptions = querySnapshot.docs.map(doc => doc.data()); 
      this.loading = false;
      console.log(this.prescriptions, this.user, querySnapshot)
    });
  }

  dummyOrder: any;
  dummyOrderLines: any[] = [];
  createDummyOrder(prescId){
    let tempTotal = 0
    let dummyShipping = 0;
    
    this.afs.firestore.collection('prescription').doc(prescId).collection('Items').get().then((products: any) => {
      let product = products.docs.map(doc => doc.data());
    
      this.afs.firestore.collection('users').doc(this.user.uid).collection("preferedShippingMethod").doc("Shipping").get().then((shipping:any) => {
        product = product.concat(shipping.data())
        product.forEach((p) => {
          if(p.type == 'product'){
            tempTotal += Number(!Array.isArray(p.productPrice) ? p.productPrice[this.user.currentLocation.code] : p.productPrice[0][this.user.currentLocation.code])
          }
    
          if(p.type == 'shipping'){
            dummyShipping += Number(typeof(p.price) == 'string' ? p.price : !Array.isArray(p.price) ? p.price[this.user.currentLocation.code] : p.price[0][this.user.currentLocation.code])
          }
        })
    
        let create = {      
          productType: 'product',
          subTotal: tempTotal,
          shipping: dummyShipping,
          total: tempTotal + dummyShipping,
          tax: 0,
        }
    
        let checkProducts = product //.filter(p => p.type != 'shipping') 
        this.dummyOrderLines = []
        this.dummyOrder = undefined;
        if(checkProducts.length > 0){
          this.dummyOrder = create;
          this.dummyOrderLines = product.sort((a, b) => {
            return a.productId - b.productId
          });
        }
      })
    })
    this.createTempPrescriptionOrder(prescId);

    this.modalService.open(this.editPrescriptionModal, { size: 'lg', centered: true });

  }

  prescriptionOrder: any;
  prescriptionOrderLines: any[] = [];
  createTempPrescriptionOrder(prescId){
    let tempTotal = 0
    
    this.afs.firestore.collection('consultRules').doc('Erectile Dysfunction').collection('services').doc('4822').get().then((products: any) => {
      let product = [products.data()];
      product.forEach((p) => {
        tempTotal += Number(!Array.isArray(p.productPrice) ? p.productPrice[this.user.currentLocation.code] : p.productPrice[0][this.user.currentLocation.code])
      })
  
      let create = {      
        productType: 'service',
        subTotal: tempTotal,
        total: tempTotal,
        tax: 0,
        prescriptionId: prescId
      }
  
      let checkProducts = product //.filter(p => p.type != 'shipping') 
      this.prescriptionOrderLines = []
      this.prescriptionOrder = undefined;
      if(checkProducts.length > 0){
        this.prescriptionOrder = create;
        this.prescriptionOrderLines = product.sort((a, b) => {
          return a.productId - b.productId
        });
      }
    })
  }

  setPrice(price){
    return Number(!Array.isArray(price) ? price[this.user.currentLocation.code] : price[0][this.user.currentLocation.code])
  }

  async createExternalPrescriptionOrder(){
    let product = this.prescriptionOrderLines[0];
    let _product: BaseProduct = {
      automaticPayment:false,
      id: uuidv4(),
      image: product.productImg,
      orderLineID: "",
      productID: product.odooProductId.toString(),
      productName: product.productName,
      productPrice: product.productPrice,
      productQty: "1",
      recurrency_order: false,
      type:product.productType,
    }

    const { uid } = this.user;
    let response:any = await this.orderService.createOrderOnCall(uid, '', this.user, _product, '');
    
    if(response && response.data.status == 200){
      this.afs.collection("orders").doc(response.data.order.id).update({orderNextAction: 'sendPrescriptionToExternalPharmacy', prescriptionId: this.prescriptionOrder.prescriptionId});
      this.router.navigate(['/orders&Refills', response.data.order.id]);
      this.modalService.dismissAll()
    }else{
      await this.createExternalPrescriptionOrder();
    }
  }
}

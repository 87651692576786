import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { updateConsultationTrigger, updateUserTrigger } from './interfaces';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})

export class DbCollectionTriggersService {

  constructor(
    private afs: AngularFirestore
  ) { }

  async updateUserCollection(data: updateUserTrigger){
    try{
      let date = new Date(Date.now());
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();
      let id:any = yyyy + mm + dd + uuidv4();
      data.id = id;
      if(!data.uid){
        data.uid = firebase.auth().currentUser.uid;
      }
      data.date = date;
      data.status = 'pending';
      this.afs.collection('triggerUpdateUser').doc(data.id).set(data);
    }
    catch(e){
    }
  }

  async updateConsultationCollection(data: updateConsultationTrigger){
    try{
      let date = new Date(Date.now());
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();
      let id:any = yyyy + mm + dd + uuidv4();
      data.id = id;
      data.date = date;
      data.status = 'pending';
      this.afs.collection('triggerUpdateConsultation').doc(data.id).set(data);
    }
    catch(e){
    }
  }
}

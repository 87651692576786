import { Injectable, OnDestroy } from '@angular/core';
import {AngularFirestore,AngularFirestoreCollection} from '@angular/fire/compat/firestore';
import firebase from "firebase/compat/app";
//import * as firebase from 'firebase';
import 'firebase/storage';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

import {UsersI} from '../../account/auth/signup/data';
import { User } from '../models/auth.models';
import { ConsultationsService } from 'src/app/pages/consultations/consultations.service';
import { HttpClient, HttpHeaders , HttpParams} from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { PrescriptionUpdate } from './interfaces';
import { Consult } from '../interfaces/consultations';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticationService } from './auth.service';
import { CookieService } from 'ngx-cookie-service';

export interface UserID extends UsersI {id: string;}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService implements OnDestroy {

  user: User;
  private snapshotChangesSubscription: any;
  vouchersList: any;

  private userCollection: AngularFirestoreCollection<UsersI>;
  users: Observable<UsersI>;
  usersList: any;

  public newVoucher = {
    id:null,
    name: '',
    voucherId: '',
    lastPaymentDate: '',
    amount: ''
  }

  public newUser = {
    id:null,
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    companyName: '',
    barNumber: '',
    accountType: '',
    isAccountDisabled: ''
  }

  public selectedUser = {
    id:null,
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    companyName: '',
    barNumber: '',
    accountType: '',
    isAccountDisabled: ''
  }

  //varibles for consults and questions
  dates;
  ids;
  questions;

  subscription = new Subscription()
  
  constructor(
    public afAuth: AngularFireAuth,
    private readonly afs: AngularFirestore,
    private httpClient: HttpClient,
    private coockieService: CookieService
  ) {
    //----------- Users ------------
    this.usersList = this.users;
    this.userCollection = afs.collection<UsersI>('users');
    this.usersList = this.userCollection.snapshotChanges().pipe(
      map(actions => actions.map ( a => {
        const data = a.payload.doc.data() as UsersI;
        const id = a.payload.doc.id;
        return {id, ... data};
      }))
    )   
   }
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

    unsubscribeOnLogOut() {
     this.snapshotChangesSubscription.unsubscribe();
    }

   //--------------------------------- Credits ---------------------------------
  
    getAllVouchers(){
      return this.vouchersList;
    }

    //--------------------------------- Users ---------------------------------
  
    getAllUsers(){
      return this.usersList;
    }

    editUser(user:UserID){
      return this.userCollection.doc(user.id).update(user);
    }

    deleteUser(id: string){
      return this.userCollection.doc(id).delete();
    }

    addUser(user:UsersI){
      this.userCollection.doc(user.uid).set(user, {merge: true});
    }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////HANDLE WRITING QUESTIONS///////////////////////////////////////////////////////////////////
////////////////////////////////////////////////HANDLE WRITING QUESTIONS///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  createConsult(condition, patient){
    // const referredBy = this.coockieService.get('ref') || null;
    const referredBy = document.cookie.split('; ')?.find(row => row.startsWith('ref='))?.split('=')[1] || null;
    return new Promise<any>((resolve, reject) => {
      let date = new Date(Date.now());
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();
      let id:any = yyyy + mm + dd + uuidv4();
      let consult:Consult = {
        Date: new Date(Date.now()),
        dateToSort: new Date(Date.now()),
        ID: id,
        Type: condition.id,
        UID_Patient: patient.uid,
        UID_Practitioner: "",
        Status: "Open",
        totalPoints: 0,
        scoreLimit: condition.scoreLimit,
        Photo1: condition.Photo1,
        coupon: '',
        shippingMethod: '',
        creditCardRef: '',
        patientName: patient.anonymousName ? patient.anonymousName : patient.middleName ? patient.firstName + ' ' + patient.middleName + ' ' + patient.lastName1 : patient.firstName + ' ' + patient.lastName1,
        patientEmail: patient.email,
        conditionName: condition.Name,
        preconsult: condition.preconsult,
        biologicalGender: condition.biologicalGender,
        image: patient.image,
        identification: patient.identification,
        // identificationBack: patient.identificationBack,
        passScoreLimit: condition.passScoreLimit,
        country: patient.currentLocation ? patient.currentLocation.country : "",
        state: {code: patient.currentLocation ? patient.currentLocation.code : "", name: patient.currentLocation ? patient.currentLocation.name : ""},
        // country: "United States",
        // state: {code: "PR", name: "Puerto Rico"},
        rxcuiSource: condition.rxcuiSource,
        updateId: false,
        currentQuestion: condition.currentQuestion,
        currentWizardStep: condition.currentWizardStep,
        totalQuestions: condition.totalQuestions,
        consultLanguage: patient.preferedLang,
        practitionerForConsult: condition.practitionerForConsult ? condition.practitionerForConsult : '',
        typeOfContact: condition.typeOfContact,
        lastRevision: condition.lastRevision,
        typeOfPractitioner: condition.typeOfPractitioner,
        serviceType: condition.serviceType,
        requiredFollowUp: condition.requiredFollowUp,
        consultModale: condition.consultModale,
        hasLabs: condition.hasLabs || false,
        steps: condition.steps || [],
        conditionQuestionsId: condition.conditionQuestionsId,
        referredBy: referredBy
      }

      if(condition.diagnosticsTests){
        let diagnosticsTestsScale = []
        this.afs.firestore.collection('diagnosticsTests').get().then(async (res: any) => {
            for(let i = 0; i < res.docs.length; i++){
              if(condition.diagnosticsTests.includes(res.docs[i].data().id)){
               diagnosticsTestsScale.push({scale: res.docs[i].data().scale})
                consult = Object.assign(consult, {
                  diagnosticsTests: condition.diagnosticsTests,
                  diagnosticsTestsPoints: condition.diagnosticsTestsPoints,
                  diagnosticsTestsScale: diagnosticsTestsScale
                })
              }

              if(i == res.docs.length -1){
                this.afs.collection('consultation').doc(consult.ID).set(consult)
                .then(
                  res => {
                    resolve(consult)},
                  err => reject(err)
                );
              }
            }
        })
      } else {
        this.afs.collection('consultation').doc(consult.ID).set(consult)
        .then(
          res => {
            resolve(consult)
          },
          err => reject(err)
        );
      }
    });
  }

  getConsults(){
    const currentUser = firebase.auth().currentUser; 
    if(currentUser){
      return new Promise<any>((resolve, reject) => {
        let sub = this.afs.collection("consultation", ref => ref.where("UID_Patient", "==", currentUser.uid).where('Status', '!=', 'Canceled')).valueChanges().subscribe(consult => {
          if(consult.length > 0){
            resolve(consult);
          }else{
            resolve(false);
          }
        });
        this.subscription.add(sub)
      });
    }
    else{ 
      return new Promise<any>((resolve, reject) => {
        resolve(false);
      });
    }  
  }

  setConsultPoints(consult, value){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection('consultation').doc(consult).update(value)
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  // addQuestionsToUser(questions: any[], currentDoc: any): Promise<any> { 
  //   return new Promise(async (resolve, reject) => { 
  //     try { 
  //       const batch = this.afs.firestore.batch(); // Initialize a batch 
  //       questions.forEach((question) => { 
  //         const questionRef = this.afs.collection('consultation') 
  //                             .doc(currentDoc) 
  //                             .collection('Questions') 
  //                             .doc(question.id).ref; 
  //         batch.set(questionRef, question); // Add the set operation to the batch 
  //       }); 

  //       await batch.commit(); // Commit the batch write resolve(true);
  //     } 
  //     catch (err) { 
  //       reject(err); 
  //     } 
  //   }); 
  // }

  addQuestionsToUser(questions: any[], currentDoc: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const batch = this.afs.firestore.batch(); // Initialize a batch
  
        if (!questions || !Array.isArray(questions)) {
          throw new Error('Invalid questions: Expected an array');
        }
  
        if (!currentDoc) {
          throw new Error('Invalid currentDoc: Expected a non-empty value');
        }
  
        questions.forEach((question) => {
          if (!question.id) {
            throw new Error(`Invalid question: Missing 'id' field`);
          }
          const questionRef = this.afs
            .collection('consultation')
            .doc(currentDoc)
            .collection('Questions')
            .doc(question.id).ref;
          batch.set(questionRef, question); // Add the set operation to the batch
        });
  
        await batch.commit(); // Commit the batch write
        // console.log('Batch commit successful');
        resolve(true); // Resolve the promise explicitly
      } catch (err) {
        // console.error('Error in addQuestionsToUser:', err);
        reject(err); // Reject the promise with the error
      }
    });
  }  

  updateAnswers(questionID, consultID, value, profile){
    
    return new Promise<any>(async (resolve, reject) =>{
      let currentUser;
      let sub = this.afAuth.authState.subscribe(user => {
        if(user){
          if(user.isAnonymous === false){
              currentUser = profile;
              value = Object.assign(value, {answeredBy: currentUser.middleName ?  currentUser.firstName + ' ' + currentUser.middleName + ' ' + currentUser.lastName1 : currentUser.firstName + ' ' + currentUser.lastName1, answeredByUid: user.uid, answeredByAccountType: currentUser.accountType, answeredByDate: new Date()})
              this.afs.collection('consultation').doc(consultID).collection('Questions').doc(questionID).update(value)
              .then(
                  res => resolve(sub.unsubscribe()),
                  err => reject('err')
                );
          }
          else {
            value = Object.assign(value, {answeredBy: '(Anonymous)', answeredByUid: user.uid, answeredByAccountType: 'none', answeredByDate: new Date()})
            this.afs.collection('consultation').doc(consultID).collection('Questions').doc(questionID).update(value)
            .then(
                res => resolve(sub.unsubscribe()),
                err => reject('err')
              );
          }
        }
      });
    })
  }
  
   //add profile info to firebase
   updateAddress(value, addressID, uid){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection('users').doc(uid).collection("Address").doc(addressID).update(value)
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  //add profile info to firebase
  updateProfile(value, uid){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection('users').doc(uid).update(value)
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  saveMyNotes(id, notes){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection('consultation').doc(id).collection("Notes").doc(notes.id).set(notes)
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  updateNotes(id, notes){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection('consultation').doc(id).collection("Notes").doc(notes.id).update(notes)
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  createPrescription(id, values){
    return new Promise<boolean>((resolve, reject) =>{
      this.afs.collection('prescription').doc(id).set(values)
        .then(
          res => resolve(true),
          err => reject(err)
        );
    });
  }
  
  editPrescription(id, values){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection('prescription').doc(id).collection("Items").doc(values.id).set(values)
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  saveProduct(value:any, currentDoc:string){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection('consultation').doc(currentDoc).collection("Items").doc(value.type).set(value)
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  saveAddressInConsult(value:any, consultId: any){
    if(value){
      return new Promise<any>(async (resolve, reject) =>{
        await this.afs.collection('consultation').doc(consultId).collection("Address").doc(value.AddressType).set(value)
          .then(
            res => resolve(res),
            err => reject(err)
          );
      });
    }
    
  }

  createAddress(value:any, addressId, uid){
    return new Promise<any>((resolve, reject) =>{
      let date = new Date(Date.now());
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();
      let id:any = yyyy + mm + dd + uuidv4();
      let docId = addressId ? addressId : id
      this.afs.collection('users').doc(uid).collection("Address").doc(docId).set({
        id: docId,
        AddressLine1: value.AddressLine1,
        AddressLine2: value.AddressLine2,
        AddressCity: value.AddressCity,
        AddressState: value.AddressState,
        AddressStateCode: value.AddressStateCode,
        AddressCountry: value.AddressCountry,
        AddressZipcode: value.AddressZipcode,
        AddressType: value.AddressType,
        AddressDefault: value.AddressDefault,
      })
        .then(
          res => resolve({id: docId,
            AddressLine1: value.AddressLine1,
            AddressLine2: value.AddressLine2,
            AddressCity: value.AddressCity,
            AddressState: value.AddressState,
            AddressStateCode: value.AddressStateCode,
            AddressCountry: value.AddressCountry,
            AddressZipcode: value.AddressZipcode,
            AddressType: value.AddressType,
            AddressDefault: value.AddressDefault}),
          err => reject(err)
        );
    });
  }

  deleteAddress(value:any, uid){
    this.afs.collection('users').doc(uid).collection("Address").doc(value).update({AddressDisabled: true});
  }

  deleteMail(data: any){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection('mail').doc(data.id).delete().catch(res => {
      }
      )
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  archiveMail(data: any){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection('mail').doc(data.id).update(data)
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  readChats(roomId, data: any){
    // return new Promise<any>((resolve, reject) =>{
      const room = {
       roomId: roomId
      } 

      if(data.id != undefined){
        this.afs.collection('chatMessages').doc(data.id).update(data).then(
          res => {
          }
        )
        this.afs.collection("chats").doc(roomId).update(room).then(
          res => {
          }
        );
      }
  }

  createChat(room, messages){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection("chats").doc(room.roomId).set(room).then(
        res => {
          
        } 
      );
      // this.afs.collection("chats").doc(room.roomId).collection("messageID").doc(messages.messageId).set(messages).then(
      //   res => resolve(
      //     'chat created'
      //   ),
      //   err => reject(err)
      //   );

      this.afs.collection('chatMessages').doc(messages.messageId).set(messages).then(
        res => resolve(
          'chat created'
        ),
        err => reject(err)
      )

      this.afs.collection("chats").doc(room.roomId).update(room).then(
        res => {          
        } 
      );
    })
    
  }

  deleteChat(data: any){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection('chats').doc(data).delete()
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  scheduleEvent(data:any){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection('calendar').doc(data.id).set(data)
        .then(
          res => resolve(res),
          err => reject(err)
        ); 
    });
  }
  scheduleEventUpdate(data:any){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection('calendar').doc(data.id).update(data)
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }
  scheduleEventDelete(data:any){
    return new Promise<any>((resolve, reject) =>{
      this.afs.collection('calendar').doc(data).delete()
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  deleteConsult(consultID:any){
    this.afs.collection('consultation').doc(consultID).delete();
  }

  updateConsult(consultID:any, value:any ){
    return new Promise<boolean>((resolve, reject) =>{
    this.afs.collection('consultation').doc(consultID).update(value).then(
      res => {
        if(value.UID_Practitioner && value.UID_Practitioner != ''){
          this.afs.firestore.collection('users').doc(value.UID_Practitioner).collection('NPI Registry').get().then(data => {
            let practitioner: any = data.docs.length > 0 ? data.docs[0].data() : null;
            if(practitioner != null){
              this.afs.collection('consultation').doc(consultID).collection('NPI Registry').doc(practitioner.NPI.toString()).set(practitioner);
            }
          })
        }
        else {
          this.afs.firestore.collection('consultation').doc(consultID).collection('NPI Registry').get().then(data => {
            let practitioner: any = data.docs.length > 0 ? data.docs[0].data() : null
            if(practitioner != null){
              this.afs.collection('consultation').doc(consultID).collection('NPI Registry').doc(practitioner.NPI.toString()).delete()
            }
          });       
        }       
        resolve(true)
      },
      err => {
      }
    )
    })
  }

  updatePrescription(data:PrescriptionUpdate){
    return new Promise<boolean>((resolve, reject) =>{
      this.afs.collection('prescription').doc(data.ID).update(data).then(
        res => {
          resolve(true)
        },
        err => {
          reject(err);
        }
      )
    })
  }

  deleteItem(consultID:any, item:any){
    this.afs.collection('consultation').doc(consultID).collection("Items").doc(item).delete();
    this.afs.collection('consultation').doc(consultID).collection("wishlist").doc(item).delete();
  }

  uploadImage(imageURI, randomId, userUid, deleteImageRef) {
    return new Promise<any>((resolve, reject) => {
      const storageRef = firebase.storage().ref();

      //deletePrevImg
      //if(!deleteImageRef.includes('profile.png') && !deleteImageRef.includes('IDBack.svg') && !deleteImageRef.includes('IDFront.svg')){ //assures that image isnt the default one
      //  const urlRef = firebase.storage().refFromURL(deleteImageRef).name;
      //  storageRef.child('users').child(userUid).child(urlRef).delete();
      //}
        

      //add new Img
      const imageRef = storageRef.child('users').child(userUid).child(randomId);      
      this.encodeImageUri(imageURI, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            snapshot.ref.getDownloadURL()
              .then(res => resolve(res));
          }, err => {
            reject(err);
          });
      });
    });
  }

  uploadPdf(pdfURI, randomId, userUid) {
    return new Promise<any>((resolve, reject) => {
      const storageRef = firebase.storage().ref();
      const imageRef = storageRef.child('users').child(userUid).child(randomId);
      imageRef.putString(pdfURI, 'data_url' ,{contentType: 'application/pdf'}).then(snapshot => {
        snapshot.ref.getDownloadURL()
          .then(res => resolve(res));
      }, err => {
        reject(err);
      });
    });
  }

  uploadConsultImage(imageURI, randomId) {
    return new Promise<any>((resolve, reject) => {
      const storageRef = firebase.storage().ref();
      const imageRef = storageRef.child('image').child('conditions').child(randomId);
      this.encodeImageUri(imageURI, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            snapshot.ref.getDownloadURL()
              .then(res => resolve(res));
          }, err => {
            reject(err);
          });
      });
    });
  }

  dynamicImages(folder, language){
    let path = `image/${folder}`
    let lang = language;
    let images = [];
    return new Promise<any>((resolve, reject) => {
      const storageRef = firebase.storage().ref(path);
      storageRef.listAll().then(url => {
        url.items.forEach(imageRef => {
          
          imageRef.getDownloadURL().then(dURL => {
            let imgLang = dURL.split("_").pop().split(".")[0]
            if(lang == imgLang){
              images.push(dURL);
            }
          }).then(
            res => {
              resolve(images)
            }
          ).catch((error) =>  {
            // Handle any errors
          })
        });
      })
      .catch((error) => {
        // Handle any errors
      });
    });
  }

  encodeImageUri(imageUri, callback) {
    const c = document.createElement('canvas');
    const ctx = c.getContext('2d');
    const img = new Image();
    img.onload = function () {
      const aux: any = this;
      c.width = aux.width;
      c.height = aux.height;
      ctx.drawImage(img, 0, 0);
      const dataURL = c.toDataURL('image/jpeg');
      callback(dataURL);
    };
    img.src = imageUri;
  }

  interactions(rxcuis, consult){
    return new Promise<any>((resolve, reject) => {
      let list: string = "";
      let rxcui = []
      rxcuis.forEach(element => {
        list += "+" + element;
        list.trim();
        rxcui.push(element);
      });
      let interactions: any = [];
      if(rxcuis){
        this.httpClient.get<any>("https://rxnav.nlm.nih.gov/REST/interaction/list.json?rxcuis="+list).subscribe(response =>{
          if(response.fullInteractionTypeGroup != undefined){
            for(let i = 0; i < response.fullInteractionTypeGroup[0].fullInteractionType.length; i++){
              let comment:string = response.fullInteractionTypeGroup[0].fullInteractionType[i].comment;
              let description:string = response.fullInteractionTypeGroup[0].fullInteractionType[i].interactionPair[0].description;
              let minConcept = response.fullInteractionTypeGroup[0].fullInteractionType[i].minConcept;
              let interactionConcept = response.fullInteractionTypeGroup[0].fullInteractionType[i].interactionPair[0].interactionConcept;
              let severity = response.fullInteractionTypeGroup[0].fullInteractionType[i].interactionPair[0].severity;
              // if(rxcuis.includes())
              let data = {
                comment: comment,
                description: description,
                interactionConcept: interactionConcept,
                minConcept: minConcept,
                severity: severity,
                source: minConcept[0].rxcui
              };
              
              this.afs.collection("consultation").doc(consult).collection("Interactions Analysis").doc(data.source).set(data).then(
              res => {
              });
            }
          }  
          let sub = this.afs.collection("consultation").doc(consult).collection("Interactions Analysis").valueChanges().subscribe(interactions => {
            interactions = interactions;
            if(interactions.length == rxcuis.length){
              resolve(interactions);
            }
          })
          this.subscription.add(sub)
        });
      }
    });
  }

  deleteStorage(uid){
    let path = `users/${uid}`
    const storage = firebase.storage().ref(path);
    storage.listAll().then(dir => {
      dir.items.forEach(file => {
        const ref = firebase.storage().ref(storage.fullPath)
        const childRef = ref.child(file.name);
        childRef.delete();
      })
    }).catch((error) => {
    })
  }

  deleteInteractions(consult, interaction){
    return new Promise<any>((resolve, reject) => {
      let sub = this.afs.collection("consultation").doc(consult).collection("Interactions Analysis").valueChanges().subscribe(interactions => {
        interactions.forEach(interac => {
          const { source } = interac
          if(source == interaction.rxcui){
            this.afs.collection("consultation").doc(consult).collection("Interactions Analysis").doc(source).delete().then(
              res => {
              });
          }
        })
      })
      this.subscription.add(sub)
    });
  }

  //for consult creation
  getDate(){
    this.dates = new Date(Date.now());
    
    this.ids = this.dates.getFullYear().toString() + ("0" + (this.dates.getMonth() + 1 )).slice(-2).toString()  + ("0" + (this.dates.getDate())).slice(-2).toString() + ("0" + (this.dates.getHours())).slice(-2).toString() + ("0" +(this.dates.getMinutes())).slice(-2).toString() + ("00" + (this.dates.getSeconds())).slice(-2).toString() + ("00" + (this.dates.getMilliseconds())).slice(-3).toString();
    return this.ids;
  }

  sendsms(user, body, forceSms){
    if(user.phoneNumberMsg || forceSms){
      this.afs.collection('mail_sms').add({to: '+1' + user.phoneNumber, body: body})
    }
  }

  practitionersAddresses(addressess){
    const currentUser = firebase.auth().currentUser;
    let obj = { AddressType: "", AddressLine1: "", AddressState: "", AddressZipcode: '',
                AddressCity: "", AddressCountry: "",AddressLine2: "", AddressStateCode: "", AddressDefault: false};
    return new Promise<any>((resolve, reject) => {
      addressess.forEach(address => {
        if(address.address_purpose == "MAILING"){
          obj.AddressType = "Shipping"
          obj.AddressLine1 = address.address_1
          obj.AddressLine2 = address?.address_2 ? address.address_2 : ""
          obj.AddressState = address.state
          obj.AddressStateCode = address.state
          obj.AddressCity = address.city
          obj.AddressCountry = address.country_name
          obj.AddressZipcode = address.postal_code

          address = obj;
        }else{
          obj.AddressType = "Billing"
          obj.AddressLine1 = address.address_1
          obj.AddressLine2 = address?.address_2 ? address.address_2 : ""
          obj.AddressState = address.state
          obj.AddressStateCode = address.state
          obj.AddressCity = address.city
          obj.AddressCountry = address.country_name
          obj.AddressZipcode = address.postal_code

          address = obj;
        }

        this.createAddress(address, address.AddressType, currentUser.uid).then(res => {
          resolve(res);
        
        });
      });
    });
  }

  public addressSub = new BehaviorSubject([]);  
  addressessSubscription = this.addressSub.asObservable();
  addressess;
  getUserAddresses(user){
    return new Promise<any>((resolve, reject) => {
      let currentUser = firebase.auth().currentUser;
      if(this.addressess == undefined || this.addressess.length == 0 || currentUser.uid != user.uid){
        let sub = this.afs.collection("users").doc(user.uid).collection("Address").valueChanges().subscribe(address => {
          this.addressess = address.filter((address:any) => address.AddressDisabled != true);
          if(this.addressess.length > 0){
            this.addressSub.next(this.addressess);
            resolve(this.addressess);
          }else{
            this.addressSub.next([]);
            resolve([]);
          }
        });
        this.subscription.add(sub)
      }else{
        this.addressSub.next(this.addressess);
        resolve(this.addressess)
      }
    });
  }
}
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import {AngularFirestore} from '@angular/fire/compat/firestore';
import {FirebaseService} from '../../../core/services/firebase.service';
import 'firebase/firestore';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserProfiles } from '../../../account/auth/signup/data';

import Swal from 'sweetalert2';
import { AuthenticationService } from '../../../core/services/auth.service';
import { environment } from '../../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient, } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import firebase from "firebase/compat/app";
import { Router } from '@angular/router';
import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';
import { LaboratoriesService } from 'src/app/core/services/laboratories/laboratories.service';
import { NPIRegistryService } from 'src/app/core/services/npi-registry.service';

import { DatePipe } from '@angular/common';
import { query } from 'firebase/firestore';
import { set } from 'zod';

import { PaginationInstance } from 'ngx-pagination';

import { Timestamp } from "firebase/firestore";

import { getAuth } from 'firebase/auth';
const auth = getAuth();

@Component({
  selector: 'app-allied-pharmacies',
  templateUrl: './allied-pharmacies.component.html',
  styleUrls: ['./allied-pharmacies.component.scss']
})
export class AlliedPharmaciesComponent {

  pagetitle = 'PHARMACY.PHARMACY';

  term: any;

  prescriptions: any;

  consultation: any;

  filteredPrescriptions: any;

  allPrescriptionItems: any;

  currentPage = 1;

  page = 1;
  pageSize = 10;
  totalRecords = 0;

  pageOrders = 1;
  pageSizeOrders = 10;
  totalOrderRecords = 0;

  order: any;

  prescriptionDetails: any;

  orders: any;

  filteredOrders: any;

  allOrderItems: any;

  hideAcquireIcon: Boolean = false;

  showLoading: Boolean = false;

  showRxIcon: Boolean = false;

  selectedOrderId: any;

  orderAcquired: Boolean = false;

  currentUser: any;

  currentUserAccountType: any;

  currentUserUid: any;

  consultId: any;

  orderSubscriptionRef: any;

  prescriptionID: any;

  allPrescriptions: any;

  prescriptionItemsDetails: any;

  AddressCity: any;
  AddressCountry: any;
  AddressLine1: any;
  AddressLine2: any;
  AddressState: any;
  AddressType: any;
  AddressZipcode: any;
  pharmacyID: any;
  pharmacyEmail: any;
  pharmacyFax: any;
  pharmacyNCPDPID: any;
  pharmacyNPI: any;
  pharmacyName: any;
  pharmacyPhone: any;
  pharmacyDEA: any;

  swal: any;

  loadingOrders: Boolean = false;

  ordersFound: Boolean = null;

  filteredPrescriptionsByStatus = 'done';

   //--------------------NEW PAGINATION--------------------
  // ordersData: any[] = [];
  // lastVisibleMap: Map<number, any> = new Map(); // Keep track of last visible document for each page
  // totalItems: number = 0; // Total number of items (for pagination controls)
  // itemsPerPage: 25;
  // currentPageTest: number = 1;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private afs: AngularFirestore,
    public firebaseService: FirebaseService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    public afAuth: AngularFireAuth,
    private httpClient: HttpClient,
    public translate: TranslateService,
    private router: Router,
    public labsService: LaboratoriesService,
    private NPIRegistryService: NPIRegistryService,
    private datePipe: DatePipe
  ) { 

    this.loadingOrders = true;

    this.getCurrentUser();

  }

  ngOnInit(): void {

    this.getPrescriptions();

    this.getOrders();

    // this.getUser();
  
    //--------------------NEW PAGINATION--------------------
    // this.getTotalItems(); // Get total number of items initially
    // this.getTestData(this.currentPageTest);

  }

  getCurrentUser() {

    auth.onAuthStateChanged(async(user) => {
      if (user) {

        var currentUid = auth.currentUser.uid ? auth.currentUser.uid : "";

        const usersRef = this.afs.firestore.collection("users").where('uid', '==', currentUid);  

        await usersRef.get().then(async (querySnapshot: any) => {

          querySnapshot.forEach(async (doc: any) => {

            var data = doc.data();

            this.currentUser = data;

            // console.log("this.currentUser", this.currentUser);

            this.currentUserAccountType = this.currentUser.accountType;

            // console.log("this.currentUserAccountType", this.currentUserAccountType);

            this.currentUserUid = this.currentUser.uid;

            // console.log("this.currentUserUid", this.currentUserUid);

            if(this.currentUserAccountType === "pharmacist" || this.currentUserAccountType === "admin" || this.currentUserAccountType === "practitioner"){

              this.getPharmacy(this.currentUserUid);

            }

          });

        });

      } else {
      }
    });

  }

  getPharmacy(uid: string){

    this.afs.firestore.collection('pharmacies').where('uid', 'array-contains', uid).get().then((querySnapshot) => {

      querySnapshot.forEach((doc) => {

        // console.log("doc", doc.data());

        var data = doc.data();

        // console.log("data", data);

        this.AddressCity = data.cityLocality;
        this.AddressCountry = data.countryCode;
        this.AddressLine1 = data.addressLine1;
        this.AddressLine2 = data.addressLine2;
        this.AddressState = data.stateProvince;
        this.AddressZipcode = data.postalCode;
        this.pharmacyID = data.id;
        this.pharmacyEmail = data.email;
        this.pharmacyFax = data.fax;
        this.pharmacyNCPDPID = data.ncpdpid;
        this.pharmacyNPI = data.npi;
        this.pharmacyName = data.companyName;
        this.pharmacyPhone = data.phone;
        this.pharmacyDEA = data.deaNumber;

      });

    });

  }

  getPrescriptions() {

    // const prescriptionRef = 
    this.afs.firestore.collection('prescription').where('status', '==', 'Signed').orderBy('date', 'asc').get().then((querySnapshot) => {

      this.prescriptions = [];

      querySnapshot.forEach((doc) => {

        this.prescriptions.push(doc.data());

      });

      const promiseResults = Promise.all([this.prescriptions]);

      promiseResults.then(async()=>{

        this.allPrescriptionItems = this.prescriptions;

        this.filteredPrescriptions = this.allPrescriptionItems;

        this.totalRecords = this.filteredPrescriptions.length;

      });

      // this.getConsultationDetails();
      
    });

    // prescriptionRef.then((querySnapshot) => {

      

    // });

  }

  filterPrescriptions(term: string) {
    if (!term) {
      this.filteredPrescriptions = this.allPrescriptionItems;
    } else {
      this.filteredPrescriptions = this.allPrescriptionItems.filter(user => {
        return Object.values(user).some(val => {
          if (typeof val === 'string') {
            return val.toLowerCase().includes(term.toLowerCase());
          }
          return false;
        });
      });
    }
    this.page = 1;
  }

  async filterPrescriptionsByStatus(status: string) {
    // console.log("status", status);
    this.filteredOrders = await this.allOrderItems.filter((order) => 
      (status == 'inProcess' && status == order.orderState && order.paymentStatus == 'paid') || 
      (status == 'completed' && order.orderState == 'done' && order.deliveryStatus == 'Done' && order.paymentStatus == 'paid') || 
      (status == 'done' && status == order.orderState && order.deliveryStatus != 'Done' && order.paymentStatus == 'paid') || 
      (status == 'error' && status == order.paymentStatus && order.orderState == 'inProcess' && order.deliveryStatus == 'Draft' && order.delivery == 'Pending')
    );
    // console.log("this.filteredOrders", this.filteredOrders);
  }

  getOrders() {

    const fetchPromises: Promise<any>[] = [];

    // // Get the current date
    // const currentDate = new Date();

    // // Set the date to the first day of the current month
    // const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    // // Convert the date to a Firestore Timestamp
    // const firestoreTimestamp = Timestamp.fromDate(firstDayOfMonth);

    // const orderRef = 
    // this.afs.firestore.collection('orders').where('orderState', '==', 'done').where('productType', '==', 'product').where('paymentStatus', '==', 'paid').orderBy('date', 'asc').get().then((querySnapshot) => {
    // this.afs.firestore.collection('orders').where('productType', '==', 'product').where('paymentStatus', '==', 'paid').orderBy('date', 'asc').get().then((querySnapshot) => {
    this.afs.firestore.collection('orders').where('productType', '==', 'product').orderBy('date', 'asc').get().then((querySnapshot) => {

      this.orders = [];

      querySnapshot.forEach(async (doc) => {

        const data = doc.data();

        // console.log("data", data);

        // console.log("data.shippingAddress", data.shippingAddress);

        if(data.orderState === "done" || data.orderState === "inProcess"){

          if(data.shippingAddress !== undefined && data.shippingAddress !== null && data.shippingAddress !== ""){

            if(data.shippingAddress.AddressStateCode === "FL"){
  
              // if (!data.hasOwnProperty('delivery') || !data.hasOwnProperty('deliveryStatus')) {
              // if ((data.delivery === 'Pending' && data.deliveryStatus === 'Ready') || (data.delivery === 'Pending' && data.deliveryStatus === 'Draft')){

                // if(data.date >= firestoreTimestamp){

                  const promise = this.fetchUserData(doc.data().uid, doc.data())
                  .then((mergedObject) => {
                      if (mergedObject) {
                          this.orders.push(mergedObject);
                      }
                  })
                  .catch(error => {
                    // console.error('Error fetching user data:', error);

                    this.loadingOrders = false;
                    this.ordersFound = false;
    
                    this.swal = Swal.fire({
                      customClass: {
                        confirmButton: 'swalPurple',
                      },
                      title: 'Error',
                      text: 'An error has occurred while fetching the data.',
                      icon: 'error',
                      showCancelButton: false,
                      showConfirmButton: true,
                      confirmButtonText: 'OK',
                      allowOutsideClick: false
                    });
              
                    this.swal.then((result) => {
                      if (result.isConfirmed) {
                        this.modalService.dismissAll();
                      }
                    });
    
                  });
    
                  fetchPromises.push(promise);

                // }
  
              // }
  
            }
  
          }

        }

      });

      Promise.all(fetchPromises)
      .then(async() => {

        // console.log("this.orders", this.orders);

        this.loadingOrders = false;
        this.ordersFound = true;

        this.allOrderItems = await this.orders.sort((a, b) => {
          return a.paymentDate?.seconds - b.paymentDate?.seconds;
        });

        await this.filterPrescriptionsByStatus(this.filteredPrescriptionsByStatus);

        // console.log("this.filteredOrders", this.filteredOrders);

        this.totalOrderRecords = this.filteredOrders.length;

        // console.log("this.totalOrderRecords", this.totalOrderRecords);

      })
      .catch(error => {

        console.error('Error fetching user data for all documents:', error);

        this.loadingOrders = false;
        this.ordersFound = false;

        this.swal = Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          title: 'Error',
          text: 'An error has occurred while fetching the data.',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'OK',
          allowOutsideClick: false
        });
  
        this.swal.then((result) => {
          if (result.isConfirmed) {
            this.modalService.dismissAll();
          }
        });

      });

      // this.getConsultationDetails();


    });

    // orderRef.then((querySnapshot) => {

      

    // }).then(()=>{

      

    // });

  }

  async filterOrders(term: string) {
    if (!term) {
     await this.filterPrescriptionsByStatus(this.filteredPrescriptionsByStatus);
    } else {
      await this.filterPrescriptionsByStatus(this.filteredPrescriptionsByStatus);
      this.filteredOrders = this.filteredOrders.filter(user => {
        return Object.values(user).some(val => {
          if (typeof val === 'string') {
            return val.toLowerCase().includes(term.toLowerCase());
          }
          return false;
        });
      });
    }
    this.pageOrders = 1;
  }

  fetchUserData(uid: string, orderObj: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afs.firestore.collection('users').doc(uid).get()
      .then((user) => {
          if (user.exists) {
              var userData = user.data();
              var fullName = userData.firstName + " " + userData.lastName1;
              var obj = {
                  fullName: fullName,
              };
              resolve({ ...obj, ...orderObj });
          } else {
              resolve(null);
          }
      })
      .catch(reject);
    });
  }

  getDetails(item: any) {

    // console.log("item", item);

    // this.router.navigate([`pharmacy/${item.consultID}`]);

    // this.router.navigate([`pharmacy/${item.consultationID}`]);

    this.router.navigate([`pharmacy/${item.id}`]);

  }

  acquireOrder(item: any) {

    let dataArray = [];

    this.showLoading = true;
    this.hideAcquireIcon = true;

    setTimeout(() => {

      this.showLoading = false;
      this.showRxIcon = true;
      this.orderAcquired = true;

    }, 3000);

    this.selectedOrderId = item.id;
      
    // console.log("item", item);

    // console.log("item.id", item.id);

    // console.log("item.consultationID", item.consultationID);

    // console.log("Acquired order...Change status to 'In Process.")

    if(item.consultationID === false || item.consultationID === "" || item.consultationID === null || item.consultationID === undefined){

      // console.log("item.consultationID - acquireOrder()", item.consultationID);

      this.afs.firestore.collection('orders').doc(item.id).collection('orderLines').where('prescriptionID', '!=', null).get().then((querySnapshot) => {

        querySnapshot.forEach((doc) => {
          // Process each document here
          // console.log(doc.id, " => ", doc.data());

          var data = doc.data();

          // this.prescriptionDetails = data;

          this.prescriptionID = data.prescriptionID;

          var obj = {
            pharmacyAdd: {
              AddressCity: this.AddressCity,
              AddressCountry: this.AddressCountry,
              AddressLine1: this.AddressLine1,
              AddressLine2: this.AddressLine2,
              AddressState: this.AddressState,
              AddressZipcode: this.AddressZipcode,
              id: this.pharmacyID,
            },
            pharmacyEmail: this.pharmacyEmail,
            pharmacyFax: this.pharmacyFax,
            pharmacyNCPDPID: this.pharmacyNCPDPID,
            pharmacyNPI: this.pharmacyNPI,
            pharmacyName: this.pharmacyName,
            pharmacyPhone: this.pharmacyPhone,
            pharmacyDEA: this.pharmacyDEA
          }

          // console.log("obj", obj);

          //--------------------Uncomment in production--------------------
          this.afs.firestore.collection('prescription').doc(this.prescriptionID).set(obj, { merge: true });

          this.getPrescriptionItemsDetails(data.prescriptionID)

        });

      })
      .catch((error) => {
        // console.error("Error getting documents: ", error);

        this.swal = Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          title: 'Error',
          text: 'An error has occurred while fetching the data.',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'OK',
          allowOutsideClick: false
        });
  
        this.swal.then((result) => {
          if (result.isConfirmed) {
            this.modalService.dismissAll();
          }
        });

      });

    }
    else{

      this.getPrescriptionByOrderID(item);

    }

    //--------------------Uncomment in production--------------------
    this.afs.collection('orders').doc(item.id).update({
      orderState: 'inProcess'
    });

  }

  getPrescriptionByOrderID(item: any) {

    let dataArray = [];

    const prescriptionRef = this.afs.firestore.collection('prescription').where('orderID', '==', item.id).get();

    prescriptionRef.then((querySnapshot) => {

      // console.log("querySnapshot.empty - getPrescriptionDetails()", querySnapshot.empty);

      if(querySnapshot.empty){

        // console.log("querySnapshot.empty - getPrescriptionDetails()");

        this.consultId = item.consultationID;

        // console.log("this.consultId - getPrescriptionDetails()", this.consultId);

        if(this.consultId != undefined && this.consultId != null && this.consultId != "" && this.consultId != false){

          this.getPrescriptionsByConsultationID(this.consultId);

        }
        else {

          this.orderSubscriptionRef = item.subscriptionRef[0];

          // console.log("this.orderSubscriptionRef - getPrescriptionDetails()", this.orderSubscriptionRef);

          this.getPrescriptionBySubscription(this.orderSubscriptionRef);

        }

      }
      else {

        querySnapshot.forEach((doc) => {

          // console.log("doc", doc.data());
  
          var data = doc.data();
  
          dataArray.push(data);
  
          this.prescriptionDetails = doc.data();
  
          // console.log("this.prescriptionDetails", this.prescriptionDetails);
  
          this.prescriptionID = this.prescriptionDetails.ID;

          var obj = {
            pharmacyAdd: {
              AddressCity: this.AddressCity,
              AddressCountry: this.AddressCountry,
              AddressLine1: this.AddressLine1,
              AddressLine2: this.AddressLine2,
              AddressState: this.AddressState,
              AddressZipcode: this.AddressZipcode,
              id: this.pharmacyID,
            },
            pharmacyEmail: this.pharmacyEmail,
            pharmacyFax: this.pharmacyFax,
            pharmacyNCPDPID: this.pharmacyNCPDPID,
            pharmacyNPI: this.pharmacyNPI,
            pharmacyName: this.pharmacyName,
            pharmacyPhone: this.pharmacyPhone,
            pharmacyDEA: this.pharmacyDEA
          }

          // console.log("obj", obj);

          //--------------------Uncomment in production--------------------
          this.afs.firestore.collection('prescription').doc(this.prescriptionID).set(obj, { merge: true });
  
          this.getPrescriptionItemsDetails(this.prescriptionID);
  
        });
  
        Promise.all([dataArray]).then(() => {
  
          this.allPrescriptions = dataArray;
  
          // console.log("this.allPrescriptions", this.allPrescriptions);
  
          if(this.allPrescriptions.length === 0){
  
            if(this.consultId != undefined && this.consultId != null && this.consultId != ""){
  
              this.getPrescriptionsByConsultationID(this.consultId);
  
            }
  
          }
  
        });

      }

    });

  }

  getPrescriptionItemsDetails(prescriptionid) {

    let dataArray = [];

    const prescriptionItemsRef = this.afs.firestore.collection('prescription').doc(prescriptionid).collection('Items').get();

    prescriptionItemsRef.then((querySnapshot) => {

      querySnapshot.forEach((doc) => {

        // console.log("doc", doc.data());

        var data = doc.data();

        // console.log("data", data);

        dataArray.push(data);

        // this.getPrescriptionProduct(data.parentProductId);

      });

      Promise.all([dataArray]).then(() => {

        this.prescriptionItemsDetails = dataArray;

        // console.log("this.prescriptionItemsDetails", this.prescriptionItemsDetails);

      });

    });

  }

  getPrescriptionsByConsultationID(consultId) {

    let dataArrayByConsultation = [];

    const prescriptionRef = this.afs.firestore.collection('prescription').where('consultID', '==', consultId).get();

    prescriptionRef.then((querySnapshot) => {

      querySnapshot.forEach((doc) => {

        // console.log("doc", doc.data());

        var data = doc.data();

        dataArrayByConsultation.push(data);

        this.prescriptionDetails = doc.data();

        // console.log("this.prescriptionDetails", this.prescriptionDetails);

        this.prescriptionID = this.prescriptionDetails.ID;

        var obj = {
          pharmacyAdd: {
            AddressCity: this.AddressCity,
            AddressCountry: this.AddressCountry,
            AddressLine1: this.AddressLine1,
            AddressLine2: this.AddressLine2,
            AddressState: this.AddressState,
            AddressZipcode: this.AddressZipcode,
            id: this.pharmacyID,
          },
          pharmacyEmail: this.pharmacyEmail,
          pharmacyFax: this.pharmacyFax,
          pharmacyNCPDPID: this.pharmacyNCPDPID,
          pharmacyNPI: this.pharmacyNPI,
          pharmacyName: this.pharmacyName,
          pharmacyPhone: this.pharmacyPhone,
          pharmacyDEA: this.pharmacyDEA
        }

        // console.log("obj", obj);

        //--------------------Uncomment in production--------------------
        this.afs.firestore.collection('prescription').doc(this.prescriptionID).set(obj, { merge: true });

        this.getPrescriptionItemsDetails(this.prescriptionID);

      });

      Promise.all([dataArrayByConsultation]).then(() => {

        this.allPrescriptions = dataArrayByConsultation;

        // console.log("this.allPrescriptions", this.allPrescriptions);

      });

    });



  }

  getPrescriptionBySubscription(id){

    // console.log("this.orderSubscriptionRef - getPrescriptionBySubscription()", this.orderSubscriptionRef);

    this.afs.firestore.collection('pharmacyOrderSchedule').doc(id).get().then((doc) => {

      this.prescriptionID = doc.data().prescriptionID;

      // console.log("this.prescriptionID - getPrescriptionBySubscription()", this.prescriptionID);

      var obj = {
        pharmacyAdd: {
          AddressCity: this.AddressCity,
          AddressCountry: this.AddressCountry,
          AddressLine1: this.AddressLine1,
          AddressLine2: this.AddressLine2,
          AddressState: this.AddressState,
          AddressZipcode: this.AddressZipcode,
          id: this.pharmacyID,
        },
        pharmacyEmail: this.pharmacyEmail,
        pharmacyFax: this.pharmacyFax,
        pharmacyNCPDPID: this.pharmacyNCPDPID,
        pharmacyNPI: this.pharmacyNPI,
        pharmacyName: this.pharmacyName,
        pharmacyPhone: this.pharmacyPhone
      }

      // console.log("obj", obj);

      //--------------------Uncomment in production--------------------
      this.afs.firestore.collection('prescription').doc(this.prescriptionID).set(obj, { merge: true });

      this.getPrescriptionDetailsBySubscription(this.prescriptionID);

    });

  }

  getPrescriptionDetailsBySubscription(id) {

    let dataArray = [];

    // console.log("id - getPrescriptionDetails()", id);

    const prescriptionRef = this.afs.firestore.collection('prescription').where('ID', '==', id).get();

    prescriptionRef.then((querySnapshot) => {

      // console.log("querySnapshot.empty - getPrescriptionDetails()", querySnapshot.empty);

      querySnapshot.forEach((doc) => {

        // console.log("doc", doc.data());

        var data = doc.data();

        dataArray.push(data);

        this.prescriptionDetails = doc.data();

        // console.log("this.prescriptionDetails", this.prescriptionDetails);

        this.prescriptionID = this.prescriptionDetails.ID;

        this.getPrescriptionItemsDetails(this.prescriptionID);

      });

      Promise.all([dataArray]).then(() => {

        this.allPrescriptions = dataArray;

        // console.log("this.allPrescriptions", this.allPrescriptions);

        if(this.allPrescriptions.length === 0){

          if(this.consultId != undefined || this.consultId != null || this.consultId != ""){

            this.getPrescriptionsByConsultationID(this.consultId);

          }

        }

      });

    });

  }


  //--------------------NEW PAGINATION--------------------
  // getTotalItems() {
  //   this.afs.firestore.collection("orders").get().then(snapshot => {
  //     this.totalItems = snapshot.size;
  //   });
  // }

  // getTestData(page: number) {
  //   let query = this.afs.firestore.collection("orders")
  //     .orderBy("date", "desc")
  //     .limit(this.itemsPerPage);

  //   const lastVisible = this.lastVisibleMap.get(page - 1);

  //   if (lastVisible) {
  //     query = query.startAfter(lastVisible);
  //   }

  //   query.get().then((documentSnapshots) => {
  //     if (documentSnapshots.docs.length > 0) {
  //       const lastVisibleDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
  //       this.lastVisibleMap.set(page, lastVisibleDoc);

  //       const newOrders = documentSnapshots.docs.map(doc => doc.data());
  //       this.ordersData = newOrders;
  //     }
  //   });
  // }

  // onPageChange(page: number) {
  //   this.currentPageTest = page;
  //   this.getTestData(page);
  // }

  maskPhoneNumber(phoneNumber: string): string {
    const areaCode = phoneNumber.slice(0, 3);
    const firstPart = phoneNumber.slice(3, 6);
    const lastPart = phoneNumber.slice(6);
  
    return `(${areaCode}) ${firstPart}-${lastPart}`;
  }

  copyTextToClipboard() {
    // console.warn('Copying text to clipboard: ', data);

    let orderArray = this.filteredOrders;

    let textArray = orderArray.map(order => {
      return [order.orderNo, order.paymentDate
      .toDate().toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric"
      }), 
      order.fullName,
      this.maskPhoneNumber(order.phone),
      order.email,]
    })

    let testString = (data: string[][]): string => {
      return data.map(row => row.join("\t")).join("\n");
    };
    navigator.clipboard.writeText(testString(textArray)).then(() => {
      console.log('Text copied to clipboard', textArray);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }

  // getUser(){
  //   firebase.auth().currentUser.getIdToken(true).then((idToken) => {
  //     console.log("Firebase ID Token:", idToken);
  //   }).catch((error) => {
  //       console.error("Error retrieving ID Token:", error);
  //   });
  // }
}

<div class="d-flex mobile">
  <app-telehealth *ngIf="consult && consult[0]" class="w-100" [practitionerUid]="consult[0].practitionerForConsult" [consultId]="consult[0].ID"></app-telehealth>
    
  <div class="mt-5 ml-2">
    <div class="col-lg-12">
      <div class="form mx-lg-auto">
        <h4>{{'EVENTS.ADDEVENT' | translate}}</h4>
  
        <form [formGroup]="eventform">                
          <div class="form-group row">
            <div class="col-md-12 mx-auto align-self-center" >
              <label for="startDate" class="inp input">
                <input id="startDate" class="form-control" type="datetime-local" placeholder="&nbsp;" formControlName="Start" (change)="calcEndTime(eventform.controls.Start.value)" >
                <span class="label">{{'EVENTS.STARTDATE' | translate}}</span>
                <span class="focus-bg"></span>
              </label>                   
            </div>
          </div>
          <div class="form-group row" [ngClass]="{'disableDiv': true}" >
            <div class="col-md-12 mx-auto align-self-center" >
              <label for="endDate" class="inp input"> 
                <input id="endDate" class="form-control" type="datetime-local" placeholder="&nbsp;" formControlName="End">
                <span class="label">{{'EVENTS.ENDDATE' | translate}}</span>
                <span class="focus-bg"></span>
              </label>
            </div>
          </div>
          <!-- <button class="btn btn-info btn-submit ml-1" [ngClass]="{'active': minutes == 15}" (click)="minutes = 15; calcEndTime(startSelected);">15 min</button>
          <button class="btn btn-info btn-submit ml-1" [ngClass]="{'active': minutes == 20}" (click)="minutes = 20; calcEndTime(startSelected);">20 min</button>
          <button class="btn btn-info btn-submit ml-1" [ngClass]="{'active': minutes == 25}" (click)="minutes = 25; calcEndTime(startSelected);">25 min</button>
          <button class="btn btn-info btn-submit ml-1" [ngClass]="{'active': minutes == 30}" (click)="minutes = 30; calcEndTime(startSelected);">30 min</button>
          <button class="btn btn-info btn-submit ml-1" [ngClass]="{'active': minutes == 60}" (click)="minutes = 60; calcEndTime(startSelected);">60 min</button> -->
          <!-- <input type="range" min="15" max="60" (change)="minutes = $event.target.value; calcEndTime(startSelected)">  -->
          <div class="d-flex flex-row align-items-center">
            <h4 class="m-0">{{'FOLLOWUP.DURATION' | translate}}:&nbsp;</h4>
            <select [clearable]="false" (change)="minutes = $event.target.value; calcEndTime(eventform.controls.Start.value)">
              <option *ngFor="let min of getMinutes()">{{min}}</option>
            </select>&nbsp;{{'FOLLOWUP.MINUTES' | translate}}
          </div>
          <p *ngIf="warning" style="color: red;">{{warning | translate}}</p>
          <p *ngIf="passedDate" style="color: red;">{{passedDate | translate}}</p>
        </form>
        <div class="d-flex justify-content-center mt-2" *ngIf="consult">
          <p *ngIf="consult && consult[0].followUpConsult">{{'FOLLOWUP.HASFOLLOWUP' | translate}}</p>
          <button *ngIf="consult && !consult[0].followUpConsult && !creatingConsult" class="btn btn-cyan mx-auto" (click)="verifyConsult(consult[0])" [disabled]="ret == true">{{'FOLLOWUP.CREATEFOLLOWUP' | translate}}</button>
          <div *ngIf="creatingConsult" class="row justify-content-center">
            <div class="col-lg-12">
              <div style="margin-left: auto; margin-right: auto; display: block;">
                <span class="spinner-border spinner-border-sm" role="status"></span>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-reload *ngIf="loading" [message]="'RELOAD.CREATING'"></app-reload>

<!-- <br>
  <br>
  <app-referral></app-referral>
<br> -->
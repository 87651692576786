<div class="main">

  <h4 class="title">Your Referals</h4>
  <p class="w-75">Upload your lab test results here to share with your doctor. 
    These results are crucial for your doctor to make informed decisions about your care. 
    Ensure your documents are accurate and complete. For any questions or assistance, contact your healthcare provider.
  </p>

  <div class="grid-container">
    <div class="list" *ngFor="let p of prescriptions">
      <div class="d-flex w-100 justify-content-between">
        <svg width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 5H7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-2"></path>
          <path d="M13 3h-2a2 2 0 1 0 0 4h2a2 2 0 1 0 0-4Z"></path>
          <path d="M9 17v-5"></path>
          <path d="M12 17v-1"></path>
          <path d="M15 17v-3"></path>
        </svg>
        <button class="btn btn-info btn-upload">
          <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 18.003a4.815 4.815 0 0 1-3.326-1.318 4.403 4.403 0 0 1-1.378-3.182c0-1.193.496-2.338 1.378-3.182A4.815 4.815 0 0 1 7 9.003c.295-1.313 1.157-2.466 2.397-3.207a5.971 5.971 0 0 1 2.025-.749 6.223 6.223 0 0 1 2.19.006c.722.131 1.408.39 2.02.76a5.36 5.36 0 0 1 1.543 1.397c.408.553.69 1.172.832 1.823.142.65.14 1.32-.007 1.97h1a3.5 3.5 0 0 1 0 7h-1"></path>
            <path d="m9 15 3-3 3 3"></path>
            <path d="M12 12v9"></path>
          </svg>
          Upload
        </button>
      </div>
      <h5 class="title">Referal</h5>
      <p class="ellipsis" *ngIf="p.items">{{concatLabNames(p.items)}}</p>
      <span>{{p.signedDate.toDate() | date: "medium"}}</span>
    </div>
  </div>
</div>



<ng-template #upload let-modal>
  <app-upload></app-upload>
</ng-template>
import { Injectable } from '@angular/core';
import { Prescription } from './Prescription'
// import { prescriptionPdf } from './prescriptionPdf';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {

  constructor() { }


}


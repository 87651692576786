import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-thirdpartycookies',
  templateUrl: './thirdpartycookies.component.html',
  styleUrls: ['./thirdpartycookies.component.scss']
})
export class ThirdpartycookiesComponent implements OnInit {
  @Output() dismiss = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {

  }

  close(){
    this.dismiss.emit(true);
  }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import 'firebase/firestore';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})

export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public afAuth: AngularFireAuth,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let guard
    return guard = this.authenticationService.profile().then(res => {
      let profile:any = res;
      if(res){
        if((profile.finishedSignup == false)){
          if(!this.router.url.includes('account')){
            this.router.navigate(['/account/welcome/register']);
          }
          return false;
        }

        if(route.data.expectedRole && !route.data.expectedRole.includes(profile.accountType)){
          this.router.navigate(['/']);
        }
        return true;
      }
      this.router.navigate(['/account/welcome'], { queryParams: { returnUrl: state.url} });
      return false;
    })
  }
}
import { Component } from '@angular/core';
import { fbRemoteConfig } from '../../../authUtils'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
  copyright = fbRemoteConfig.defaultConfig.year
  
  constructor() {}
}
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-labs-and-diagnostics',
  templateUrl: './labs-and-diagnostics.component.html',
  styleUrls: ['./labs-and-diagnostics.component.scss']
})
export class LabsAndDiagnosticsComponent implements OnInit, OnDestroy {
  @ViewChild('editPanel', { read: TemplateRef }) editPanelModal:TemplateRef<any>;
  
  labs: Array<any> = [];
  selectedLab: any = null;

  constructor(
    private db: AngularFirestore,
    private modalService: NgbModal,
  ) { } 


  ngOnInit(): void {  

    this.getLabs();
    
    // this.addFields();

  }



  getLabs(){
    this.db.collection("labAnalysisTemplates").valueChanges().subscribe((templates: any) => { 
      this.labs = templates;
      // this.labs.forEach((lab: any) => { 
      //   // console.log(lab);
      //   // let newest = Object.assign({showPatient: true}, lab);
      //   // console.log(newest);
      //   // this.db.collection("labAnalysisTemplates").doc(lab.id).update(newest);
      //   this.db.collection("labAnalysisTemplates").doc(lab.id).update({
      //     sample_type: "",
      //     preparation: "",
      //     test_type: "",
      //     testing_information: "",
      //     signs_and_symptoms: "",
      //     after_test: ""
      //   });
      // });
    });
  }



  activateSidePanel(lab:any){
    // let sidePanel = document.getElementById("side-panel");
    // // sidePanel?.classList.add("active");
    // sidePanel.style.display = "block";
    this.selectedLab = lab;
    this.modalService.open(this.editPanelModal, {size:"lg", backdrop:'static', windowClass: "sidepanel-modal"})
  }

  //! first check whats been discussed about this part with Melanie
  manageDiagnostic(icd10){
    // this.modalService.open(this.editPanelModal, {size:"lg", backdrop:'static', windowClass: "sidepanel-modal"})
  }

  


  ngOnDestroy(): void { 

  }

}

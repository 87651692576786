
import { Injectable, OnDestroy } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';

import { AngularFireAuth } from '@angular/fire/compat/auth';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirebaseService } from '../../core/services/firebase.service';
import 'firebase/firestore';
import firebase from "firebase/compat/app";

import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Profile } from './interfaces';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { TranslationPipe } from 'src/app/translation.pipe';
import { fbRemoteConfig } from '../../authUtils'
import { GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';

import { getFunctions, httpsCallable } from "firebase/functions";
import { CookieService } from 'ngx-cookie-service';

const app = firebase.initializeApp(environment.firebaseConfig);
const functions = getFunctions(app);
@Injectable({ providedIn: 'root' })

export class AuthenticationService implements OnDestroy {
  public auth = new BehaviorSubject("");
  authenticated = this.auth.asObservable();

  public userSub = new BehaviorSubject('');  
  userSubscription = this.userSub.asObservable();

  user: User;
  userData: any;
  newValues: any;
  response:any;
  uid:any;
  subscription = new Subscription();

  currentLang = this.translate.store.currentLang;
  recaptcha: any;
  browser: any;
 swal 
  settings = fbRemoteConfig.defaultConfig
  translationPipe = new TranslationPipe()

  modalRef:any;
  private GETUser_URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/getAuthUser`;

  claims = null;
  
  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public firebaseService: FirebaseService,
    private router: Router,
    private httpClient: HttpClient,
    private http: HttpClient,
    private translate: TranslateService,
    private cookieService: CookieService
  ) {
    this.setSessionPersistence();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  
  changeState(state:any) {
    this.auth.next(state);
  }

  private setSessionPersistence(): void {
    this.afAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        // console.log('Session persistence set to SESSION');
      })
      .catch((error) => {
        // console.error('Error setting session persistence:', error);
      });
  }

  async getClaims(){
    if(firebase.auth().currentUser){
      await firebase.auth().currentUser.getIdToken(true)
      if(!this.claims || !this.claims.accountType){
        this.claims = (await firebase.auth().currentUser.getIdTokenResult()).claims
      }
      return this.claims
    }

    return null
  }

  async getUser(){
    let promise = new Promise((resolve, reject) => {
      let sub = this.afAuth.authState.subscribe(user => {
        if(user && user.isAnonymous == false) {
          let timer = setInterval(async () => {
            if(this.claims !== null && this.claims.accountType){
              let sub = this.afs.collection("users").doc(user.uid).valueChanges().subscribe(async profile => {
                let data:any = profile;
                this.userData = profile;
                if(data?.adminRefresh){
                  this.afs.collection("users").doc(user.uid).update({adminRefresh: false}).then(() => {
                    this.reloadPage()
                  });
                }
                // if(data?.mfaLoginDate){
                //   resolve(data.mfaLoginDate)
                // }
                if(firebase.auth().currentUser){
                  this.userSub.next(this.userData)
                }
                if(profile){
                  resolve(profile)
                }
              })
              this.subscription.add(sub)
              clearInterval(timer);
            }
            else {
              await this.getClaims();
            }
          }, 1000)
        }
        else {
          this.userData = undefined
          this.userSub.next(undefined)
          resolve(undefined)
        }
      });
      this.subscription.add(sub)
    })
    return promise;
  }

  setRecaptcha(recaptcha){
    this.recaptcha = recaptcha
  }

  setBrowser(browser){
    this.browser = browser
  }


  public getRequest(idToken:string){
    // return this.httpClient.get(this.GET_URL);
    return new Promise(async (resolve, reject) => {
      // const searchUserByEmail = httpsCallable(functions, 'getAllAuthUsers');
      const searchUserByEmail = httpsCallable(functions, 'oncall-get-all-auth-users');
      // const searchUserByEmail = httpsCallable(functions, 'oncall-create-accepta-payment-initiation');
      let users = await searchUserByEmail({
        idToken: idToken
      });
      if(users){
        resolve(users)
      }else{
        resolve(null)
      }
    });
  }

  isLoggedIn(){
    this.afAuth.authState.subscribe(user => {
      if(user){
        return true;
      }else{
        return false;
      }
    });
  }

  profile(): Promise<any>{
    return new Promise<any>(async (resolve, reject) => {
      try{
        if(!this.userData){
          let user = await this.getUser()
          resolve(user);
        }
        else {
          resolve(this.userData);
        }

      } catch(err){
        // reject(err);
      }
    })
  }

  getUserProfile(uid:string): Promise<Profile>{//not currentUser
    return new Promise<Profile>((resolve, reject) => {
      this.afs.collection("users").doc(uid).valueChanges().subscribe((profile:Profile) => {
        resolve(profile)
      });
    });
  }

  getNPIRegistry(uid){
    return new Promise<any>((resolve, reject) => {
      let sub = this.afs.collection("users").doc(uid).collection("NPI Registry").valueChanges().subscribe(async registry => {
        resolve(registry[0])
      })
      this.subscription.add(sub)
    }).catch(err => {
    })
  }

    /**
     * Returns the current user
     */
    public currentUser(): User {
      return getFirebaseBackend().getAuthenticatedUser();
    }

    public removeSession(){
      let data = {
        mfaLoginDate: ""
      }
      let promise = new Promise((resolve, reject) => {
        let sub = this.afAuth.authState.subscribe(user => {
          if(user){
            this.afs.collection("users").doc(user.uid).update(data);
          }
          else {}
        });
        this.subscription.add(sub)
      })
      return promise;
    }


    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
      return getFirebaseBackend().loginUser(email, password).then((response: any) => {
        const user = response;
        return user;
      });
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string, profile: any) {
      return getFirebaseBackend().registerUser(email, password).then(async (response: any) => {
        const user = response;
        var uid = user.uid;
        profile.uid = uid;
        firebase.auth().currentUser.updateProfile({
          displayName: profile.firstName + " " + profile.lastName1,
        }).then(() => {
        })
        this.addNewUser(profile);
        // (await this.medicalHistoryService.getMedicalHistoryQuestions()).subscribe(async questions => {
        //   questions.forEach(async question => {
        //     this.afs.collection("users").doc(uid).collection("medicalHistory").doc(question.medicalHistoryId).set(question);
        //   });
        // });

        return user;
      });
    }

    /**
     * Reset password
     * @param email email
     */
    async resetPassword(email: string) {
      let userData
      let promise = await new Promise((resolve, reject) =>{
        this.userByEmail(email).then((data:any) => {
          userData = data
          if(userData){
            resolve([
              this.sendResetPasswordEmail(userData.uid),
            ]);
            // for(let i = 0; i < userData.provider.length; i++){
            //   if(userData.provider[i].providerId == "password"){
            //     resolve([
            //       //   firebase.auth().sendPasswordResetEmail(email, actionCodeSettings).then((response: any) => {
            //       // })
            //       this.sendResetPasswordEmail(userData.uid),
            //     ]);
            //   }
            //   else if((userData.provider.length - 1) == i && 
            //     userData.provider[i].providerId != "password"){
            //       if(userData.provider[i].providerId == 'google.com'){
            //         resolve(['no user with password', 'Google', 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg'])
            //       }
            //       else if(userData.provider[i].providerId == 'phone'){
            //         if(userData.provider[i - 1].providerId == 'google.com'){
            //           resolve(['no user with password', 'Google', 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg'])
            //         }             
            //         else {
            //           resolve(['no user with password', 'Microsoft', 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg'])                    
            //         }
            //       }
            //       else {
            //         resolve(['no user with password', 'Microsoft', 'https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg'])                    
            //       }
            //   }
            // }
          }
          else {
            resolve(['user doesnt exist'])
          }      
        })
      })
    return await promise      
      // return getFirebaseBackend().forgetPassword(email, actionCodeSettings).then((response: any) => {
      //     const message = response.data;
      //     return message;
      // });
    }

    /**
     * Logout the user
     */
    logout() {
      // logout the user
      return getFirebaseBackend().logout();
    }

    createPasswordAccount(request){
      let promise = new Promise((resolve, reject) => {
        let body = new HttpParams();
        body = body.set("email", request.email)
        body = body.set("password", request.password)
        body = body.set("accountType", request.accountType)
        body = body.set("preferedLang", request.preferedLang)
        body = body.set("timezone", request.timezone)
        body = body.set("referredBy", request.referredBy)
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        headers.set('Access-Control-Allow-Origin', this.settings.domain);
  
        this.httpClient.post(`https://us-central1-${this.settings.env}.cloudfunctions.net/createPasswordAccount`, body, {headers: headers}).subscribe(res => {
          resolve(res);
          // this.subscription.add(sub)
        });
      })

      return promise;
    }

    linkPasswordAccount(request){
      let promise = new Promise(async (resolve, reject) => {
        const linkPasswordAccount = httpsCallable(functions, 'linkPasswordAccount');
        let users = await linkPasswordAccount({
          email: request.email,
          password: request.password,
          accountType: request.accountType,
          preferedLang: request.preferedLang,
          timezone: request.timezone,
          referredBy: request.referredBy

        });
  
        resolve(users.data);
      })

      return promise;
    }

    linkPasswordlessAccount(request){
      let promise = new Promise((resolve, reject) => {
        let body = new HttpParams();
        body = body.set("email", request.email)
        body = body.set("accountType", request.accountType)
        body = body.set("preferedLang", request.preferedLang)
        body = body.set("timezone", request.timezone)
        body = body.set("referredBy", request.referredBy)
        body = body.set("uid", request.uid)
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        headers.set('Access-Control-Allow-Origin', this.settings.domain);
  
        this.httpClient.post(`https://us-central1-${this.settings.env}.cloudfunctions.net/linkPasswordlessAccount`, body, {headers: headers}).subscribe(res => {
          resolve(res);
          // this.subscription.add(sub)
        });
      })

      return promise;
    }

    sendPasswordlessLink(request){
      let promise = new Promise((resolve, reject) => {
        let body = new HttpParams();
        body = body.set("email", request.email)
        body = body.set("preferedLang", request.preferedLang)
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        headers.set('Access-Control-Allow-Origin', this.settings.domain);
  
        this.httpClient.post(`https://us-central1-${this.settings.env}.cloudfunctions.net/sendPasswordlessLink`, body, {headers: headers}).subscribe(res => {
          resolve(res);
          // this.subscription.add(sub)
        });
      })

      return promise;
    }

    addNewUser(user){
      if(user.accountType == undefined || ""){
        user.accountType = 'patient'
      }
      const referredBy = this.cookieService.get('ref') || null;
      let promise = new Promise((resolve, reject) => {
        this.afs.firestore.collection("settings").doc("Terms").collection('Terms').where('termsUserType', '==', 'patient').get().then(async data => {
          let termsOfUse = await [data.docs[data.docs.length -1].data()]
          let newUserData = { 
            email: user.email ? user.email.toLowerCase().trim() : '',
            firstName: user.firstName || '',
            middleName: '',
            lastName1: user.lastName1 || '',
            biologicalgender: user.biologicalgender || '',
            birthdate: user.birthdate || '',
            image: '../assets/images/users/profile.png',
            accountType: user.accountType,
            isAccountDisabled: false,
            uid: user.uid.toString(),
            identification:'../assets/images/users/IDFront.svg',
            // identificationBack: '../assets/images/users/IDBack.svg',
            phoneNumber: user.phoneNumber ? user.phoneNumber : '',
            phoneVerified: user.phoneVerified ? user.phoneVerified : false, 
            phoneNumberMsg: user.phoneNumberMsg ? user.phoneNumberMsg : false,
            alternateNumber:'',
            status: 'unverified',
            source: user.source ? user.source : '',
            preferedLang: user.preferedLang,
            timezone: user.timezone ? user.timezone : [],
            gender: user.gender ? user.gender : '',
            idType: "",
            idExpDate: "",
            idNumber: "",
            idStateIssued: "",
            idCountryIssued: "",
            finishedSignup: false,
            referredBy: referredBy,
            terms: [{
              acceptedTerms: true,
              acceptedDate: new Date(),
              docLang: user.preferedLang ? user?.preferedLang : this.currentLang,
              version: termsOfUse[0].version
            }],
            afterRegister: user.afterRegister ? user.afterRegister : null
          }
          this.newValues = newUserData;
          firebase.auth().languageCode = newUserData.preferedLang;
          this.firebaseService.addUser(this.newValues)
          // resolve(true)
          resolve(newUserData)
        })
      })
      return promise;
    }

    async googleSignIn(){
      localStorage.setItem('cookieRef', this.cookieService.get('ref'));

      if(this.browser != 'other'){
        let provider = new GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: 'select_account'
        });

        if(window.location.host.includes('localhost')){
          // Redirect to sign in with the custom URL
          const credential = await this.afAuth.signInWithPopup(provider);
          var path = credential.user.uid;
          var uid = credential.user.uid;
          var email = credential.user.email;
          return this.findorcreateUser(path,email,uid);
        }
        else {        
          this.router.navigateByUrl('/account/welcome/register');  
          const credential = await this.afAuth.signInWithRedirect(provider);
        }
      }
      else {
        // window.open(this.router.url, '_blank')
        // window.open(`googlechrome://${this.router.url}`, '_blank')
      }
      // // const credential = await this.afAuth.signInWithRedirect(provider);
      // var path = credential.user.uid;
      // var uid = credential.user.uid;
      // var email = credential.user.email;
      // if(email.includes('@dzeus.com')){
      //   return this.staffAction(uid)
      // }
      // return this.findorcreateUser(path,email,uid);
    }

    async facebookSignIn(){
      // const provider = new firebase.auth.GoogleAuthProvider;
      // let redirectURL = '/account/welcome/register'; // Replace with your actual redirect URL
      let redirectURL = '/account/welcome/register'; // Replace with your actual redirect URL
      if(this.router.url.includes('preconsult')){
        redirectURL = this.router.url
      }
      
      localStorage.setItem('redirectURL', redirectURL);
      localStorage.setItem('cookieRef', this.cookieService.get('ref'));

      // const credential = await this.afAuth.signInWithPopup(provider);
      if(this.browser != 'other'){
        if(window.location.host.includes('localhost')){
          let provider = new FacebookAuthProvider();
          provider.setCustomParameters({
            prompt: 'select_account'
          });
          const credential = await this.afAuth.signInWithPopup(provider);
          var path = credential.user.uid;
          var uid = credential.user.uid;
          var email = credential.user.email;
          return this.findorcreateUser(path,email,uid);
        }
        else {
          let provider = new FacebookAuthProvider();
          provider.setCustomParameters({
            prompt: 'select_account'
          });
          const credential = await this.afAuth.signInWithRedirect(provider);
        }
      }
      else {
        // window.open(this.router.url, '_blank')
        // window.open(`googlechrome://${this.router.url}`, '_blank')
      }
      // // const credential = await this.afAuth.signInWithRedirect(provider);
      // var path = credential.user.uid;
      // var uid = credential.user.uid;
      // var email = credential.user.email;
      // if(email.includes('@dzeus.com')){
      //   return this.staffAction(uid)
      // }
      // return this.findorcreateUser(path,email,uid);
    }

    async phoneSignIn(phoneNumber: string){

      const credential = await this.afAuth.signInWithPhoneNumber(phoneNumber, this.recaptcha);
      return credential;
    }

    async credentialSignIn(error){
      let credential = GoogleAuthProvider.credentialFromError(error);

      await this.afAuth.signInWithCredential(credential);
    }

    async continueAfterRedirect(credential){
      let path = credential.uid;
      let uid = credential.uid;
      let email = credential.email;
      if(email.includes('@dzeus.com')){
        return this.staffAction(uid)
      }
      return await this.findorcreateUser(path,email,uid);
    }

    async appleSignIn(){
      const provider = new firebase.auth.OAuthProvider('apple.com');
      const credential = await this.afAuth.signInWithPopup(provider);
       //this.updateUserData(credential.user);
      let path = credential.user.uid;
      let uid = credential.user.uid;
      let email = credential.user.email;
      this.findorcreateUser(path,email,uid);
    }
    async microsoftSignIn(){
      // const provider = new firebase.auth.OAuthProvider('microsoft.com');
      let redirectURL = '/account/welcome/register'; // Replace with your actual redirect URL
      if(this.router.url.includes('preconsult')){
        redirectURL = this.router.url
      }
      localStorage.setItem('redirectURL', redirectURL);
      localStorage.setItem('cookieRef', this.cookieService.get('ref'));
      // const credential = await this.afAuth.signInWithPopup(provider);
      let provider = new firebase.auth.OAuthProvider('microsoft.com')
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      const credential = await this.afAuth.signInWithRedirect(provider);

      //  this.updateUserData(credential.user);
      // var path = credential.user.uid;
      // var uid = credential.user.uid;
      // var email = credential.user.email;
      // return await this.findorcreateUser(path,email,uid);
      // return email;
    }



    userExists(path: string) {
      return this.afs.doc(`users/${path}`).valueChanges().pipe(first()).toPromise()
    }







    async checkUserExist(email:string){
      // let body = new HttpParams();
      // body = body.set('email', email);
      // let sub
      
      // return sub = this.httpClient.post(`https://us-central1-${this.settings.env}.cloudfunctions.net/checkUser`, body).subscribe(res => {
      //   this.response = res;
      //   this.subscription.add(sub)
      // });
      try{
        const resend = httpsCallable(functions, 'oncall-send-reset-password-email');
        let result = await resend({email: email});
        this.response = result.data;
        return result.data;

      }
      catch(err){
      }
    }


    handleResponse(){
      return this.response;
    }







    async findorcreateUser(path: string, email: string, uid: string) {
      let timer = setInterval(async () => {
        if(this.claims != null && this.claims.accountType){
          this.currentLang = this.translate.store.currentLang;
          await this.afs.firestore.collection('users').doc(path).get().then(user => {
            if(user.exists){
              return user.data();
            } else {
              this.addNewUser({uid: uid, email: email, preferedLang: this.currentLang, accountType: 'patient'}); 
            }
          });
          clearInterval(timer);
        }
        else {
          await this.getClaims();
        }
      }, 1000)
    }

    async staffAction(uid){
      const doc = await this.afs.collection('users').doc(uid).valueChanges().pipe(first()).toPromise();

      return doc ? true : 'no dzeus user'
    }


    async userByEmail(email: string) {
      interface UserByEmail {
        data: {
          user?: string;
          code?: string;
        }
      }
      // const URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/searchUserByEmail`;
      const searchUserByEmail = httpsCallable(functions, 'oncall-search-user-by-email');
      return new Promise((resolve, reject) => {
        searchUserByEmail({
          email: email
        }).then(async (result:UserByEmail) => {
          resolve(result.data);
        }).catch((error) => {
          reject(error);
        });
      });
      // return new Promise((resolve, reject) => {
      //   let body = new HttpParams();
      //   const headers = new HttpHeaders();
      //   headers.set('Content-Type', 'application/json');
      //   headers.set('Access-Control-Allow-Origin', this.settings.domain);
        
      //   body = body.set('email', email);
      //   let sub 
      //   return sub =  this.http.post(URL, body, { headers: headers }, )
      //   .subscribe(res => {
      //     this.subscription.add(sub)
      //    resolve(res);
      //   });
      // }).catch(err => {
      // });
    }


    createRefund() {
      const URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/createRefund`;
      return new Promise((resolve, reject) => {
        let body = new HttpParams();
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        headers.set('Access-Control-Allow-Origin', this.settings.domain);
          
        // body = body.set('email', email);
        let sub
        return sub = this.http.post(URL, { headers: headers }, )
        .subscribe(res => {
          this.subscription.add(sub)
          resolve(res);
        });
      }).catch(err => {
      });
    }

    getPharmasictSignature():Observable<any> {  
      let signature:any; 
      let sub = this.afs.collection("prescription").valueChanges().subscribe(presc => {
        signature = this.afs.collection('prescription').valueChanges().subscribe(res => {
          return res;
        });
        this.subscription.add(signature)
      });
      this.subscription.add(sub)
      return of(signature);
    }

    async googleLogin(modal?: any) { 
      this.modalRef = modal;
      try{
        // const auth2 = await gapi.auth2.getAuthInstance()
        // const googleUser = await auth2.signIn();
  
        // const token = googleUser.getAuthResponse().id_token;
    
        // const credential = firebase.auth.GoogleAuthProvider.credential(token);
  
        // return await this.afAuth.signInAndRetrieveDataWithCredential(credential).then(res => {
        //   return res;
        // })
      }catch(err){
        
        if(err.error == "idpiframe_initialization_failed"){
          if(navigator.userAgent.indexOf("Chrome") != -1 ){
            this.googleSignIn();
            // this.modalService.open(modal, { centered: true, size: 'lg' });
            // Swal.fire({
            //   title: "Chrome Browser",
            //   // text: `Please enable third party cookies in your browser`,
            //   html: `
            //   <h5>Please enable third party cookies in your browser</h5>
            //   <img src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fmiscellaneous%2Fallcookiespath.png?alt=media&token=c95911f8-076e-4640-b6ae-c3469aa6f16d" style='width:400px;'>
            //   <img src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fmiscellaneous%2Fchrome-allowallcookies.png?alt=media&token=426996a3-b1c7-40f4-94a0-c46f9477e7b9" style='width:400px;'>
            //   <br>
            //   <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">Click here to enable third party cookies</a>
            //   `,
            // });
          }
          else if(navigator.userAgent.indexOf("Firefox") != -1 ){
            this.translate.get('POPUPS').subscribe((res: any) => {
              this.swal = res
            })
            Swal.fire({
              title: this.swal.FIREFOXERROR,
              // text: `Please enable third party cookies in your browser`,
              html: `
              <h3>${this.swal.ENABLETHIRDPARTY}</h3>
              <img src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fmiscellaneous%2FScreen%20Shot%202022-04-12%20at%2012.00.33%20PM.png?alt=media&token=03e97ffb-dd63-47dd-bc82-d038af27e46b" style='width:100%;'>
              <br>
              <a href="https://support.google.com/chrome/answer" target="_blank">${this.swal.ENABLETHIRDPARTYBTN}</a>
              `,
            });
          }
        }
      }
    }

  async getRemoteConfig(){
    return new Promise((resolve, reject) => {
      resolve(this.settings);
    })
  }
 
  public async sendResetPasswordEmail(uid:string){
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '* ');
    // let body = new HttpParams();
    // body = body.set('uid', uid);

    const resend = httpsCallable(functions, 'oncall-send-reset-password-email');
    let result = await resend({remoteConfig: await this.getRemoteConfig(), uid: uid});
    return result.data;
    // return this.httpClient.post(this.resetPasswordUrl, body, { headers: headers }).subscribe(res => {
    // }); //,this.httpClient.get(this.GET_URL)
  }

  public async getAuthUser(uid){
    let promise = new Promise((resolve, reject) => {
      const headers = new HttpHeaders();
      headers.set('Content-Type', 'application/json');
      headers.set('Access-Control-Allow-Origin', '* ');
      let body = new HttpParams();
      body = body.set('uid', uid);
      
      this.httpClient.post(this.GETUser_URL, body, { headers: headers }).subscribe(async res => {
        resolve(res)
      }); //,this.httpClient.get(this.GET_URL)
    })
    return promise
  }

  reloadPage(){
    window.location.reload()
  }
}
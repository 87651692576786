import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from "firebase/compat/app";
import { formatDate } from '@angular/common';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { TranslationPipe } from 'src/app/translation.pipe';
import { AuthenticationService } from './auth.service';
import { labLogoImage, rxLogoImage } from 'src/app/core/services/images';
import { PrescriptionService } from './prescription.service';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { environment } from 'src/environments/environment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;



@Injectable({
  providedIn: 'root'
})

export class PdfService {
  pdfMake: any;
  externalPrescriptionPDFRef: any;
  mergePDFContent: any[] = [];
  
  constructor(
    private afs: AngularFirestore,
    private authService: AuthenticationService,
    private prescriptionService: PrescriptionService
  ) {}

  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }

  async getDocumentItems(prescriptionId){
    return new Promise<any>((resolve, reject) => {
      this.afs.firestore.collection("prescription").doc(prescriptionId).collection("Items").get()
      .then(async data => {
        let items = data.docs.map(item => item.data())
        resolve(await items);
      });
    });
  }

  async generatePdf(prescription:any, signature?:any, justifyLetter?: boolean, referralText?: string):Promise<any> {
    let app = firebase.initializeApp(environment.firebaseConfig);
    let functions = getFunctions(app);
    const pdfCloudFunctionService = httpsCallable(functions, 'generatePdf');
    const response:any = await pdfCloudFunctionService({ 
      prescription: prescription,
      signature: signature ? signature : '',
      justifyLetter: justifyLetter ? justifyLetter : '',
      referralText: referralText ? referralText : '',
      callFunction: 'generatePdf'
    }).catch(() => {
      return 'An error has occured.'
    });
    return response;
    // return new Promise<any>(async (resolve, reject) => {
    //   const { prescription_pdf } = prescription;
      
    //   let start = performance.now();
    //   await this.loadPdfMaker();

    //   // let tt = await this.pdfTemplate(prescription,signature);
    //   let tt = await prescription.transcribed ? await this.pdfExternalTemplate(prescription,signature) : await this.pdfTemplate(prescription,signature) 

    //   if(justifyLetter){
    //     prescription.practitionerSignature = signature;
    //     let originalContent = tt.content;
    //     let letter = await this.attachLetter(prescription, referralText)
    //     tt.content = letter.concat(originalContent);
    //   }
      
    //   let final = this.pdfMake.createPdf(tt);
    //   if(this.externalPrescriptionPDFRef && this.externalPrescriptionPDFRef?.extension !== "pdf"){
    //     this.mergePDFContent = [tt.content, this.externalPrescriptionPDFRef.existingPdfUrl]
    //     final = this.pdfMake.createPdf({ content: this.mergePDFContent });
    //   }
    //   // }else{
    //   //   this.mergePDFContent = [tt.content, this.externalPrescriptionPDFRef.existingPdfUrl]
    //   //   final = this.pdfMake.createPdf({ content: this.mergePDFContent });
    //   // }
    //   if(final){
    //     let end = performance.now();   
    //   }
    //   let pdfBase64 = final.getDataUrl((dataUrl) => {
    //     var d = new Date();
    //     var year = d.getFullYear();
    //     var month = d.getMonth();
    //     var day = d.getDate();
    //     var expires = new Date(year + 1, month, day);
    //     let obj = {
    //       z_base64: dataUrl.split("base64,")[1],
    //       prescriptionURL: "",
    //       status: signature ? "Signed" : "Unsigned",
    //       signedDate: prescription.signedDate || signature ? new Date() : '',
    //       expirationDate: prescription.expirationDate || expires,
    //       practitionerSignature: signature || '',
    //       justifyLetter: justifyLetter || false
    //     }

    //     //save pdf to storage to get download url => pass to ERP
    //     let path = `users/${prescription.uid}`
    //     const storage = firebase.storage().ref(path);
    //     let imageRef = storage.child(`${prescription_pdf}`);

    //     imageRef.putString(dataUrl, 'data_url' ,{contentType: 'application/pdf'}).then((snapshot) => {          
    //       snapshot.ref.getDownloadURL().then(async (url) => {
    //         obj.prescriptionURL = url;
    //         this.afs.collection("prescription").doc(prescription.ID).update(obj);
    //         prescription = Object.assign(prescription, obj);
    //         resolve(prescription);
    //       });
    //     }, err => {
    //       reject(err);
    //     });
    //   });
    // });
  }

  getDiagnostics(consult){
    let arr = [];
    return new Promise<any>((resolve, reject) => {
      this.afs.firestore.collection("consultation").doc(consult).collection("Diagnostics").get().then(async (data:any) => {
        data.docs.forEach(diag => {
          let split = diag.data().code.split("-")[0];
          arr.push(split);
        });
        resolve(arr);
      })
    });
  }

  async addWaterMark(pdf, patient, invalidate?){
    const url = pdf.prescriptionURL;
    if(url){
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
      
      const pdfDoc = await PDFDocument.load(existingPdfBytes, {ignoreEncryption: true})
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
      if(pdf.practitionerSignature){
        const pngImage = await pdfDoc.embedPng(pdf.practitionerSignature);
        const pngDims = pngImage.scale(0.25)
      }
      const pages = pdfDoc.getPages()
      const firstPage = pages[0]
      const { width, height } = firstPage.getSize()

      if(invalidate){
        firstPage.drawText('INVALID!', {
          x: 425,
          y: height / 2 + 250,
          size: 25,
          font: helveticaFont,
          color: rgb(0.95, 0.1, 0.1),
        })
      }
      else {
        firstPage.drawText('PROCESSED!', {
          x: 425,
          y: height / 2 + 250,
          size: 25,
          font: helveticaFont,
          color: rgb(0.95, 0.1, 0.1),
        })
      }
  
      const pdfBytes = await pdfDoc.save();
      const pdfB64 = await pdfDoc.saveAsBase64();

      let path = `users/${patient}`
      const storage = firebase.storage().ref(path);
      let imageRef = storage.child(`processed_by_practitioner_${pdf.ID}.pdf`);
      imageRef.putString(pdfB64, 'base64' ,{contentType: 'application/pdf'}).then((snapshot) => {
        snapshot.ref.getDownloadURL().then(res => {
          this.afs.collection("prescription").doc(pdf.ID).update({prescriptionProcessed:res, z_processedB64:pdfB64});
        });
      }, err => {
      });
    }
  }

  async generateEncryptedPrescription(prescription){
    let app = firebase.initializeApp(environment.firebaseConfig);
    let functions = getFunctions(app);
    const pdfCloudFunctionService = httpsCallable(functions, 'generatePdf');
    const response:any = await pdfCloudFunctionService({ 
      prescription: prescription,
      signature: '',
      justifyLetter: '',
      referralText: '',
      callFunction: 'encryptedPrescription'
    }).catch(() => {
      return 'An error has occured.'
    });;
    return response;
    // let randomCode = this.randomCodeGenerator();
    // return new Promise<any>(async (resolve, reject) => {
    //   let signature:string = await prescription.practitionerSignature;
    //   const { prescription_pdf } = prescription;
        
    //   let start = performance.now();
    //   await this.loadPdfMaker();
  
    //   if(signature !== undefined){
    //     prescription.pharmacy = 'External Pharmacy';
    //     let tt = await this.pdfTemplate(prescription, signature);
    //     tt = Object.assign(tt, {userPassword: randomCode.toString(), ownerPassword: '@DzeusOwnerAdmin@2023'});

    //     let final = this.pdfMake.createPdf(tt);
    //     if(final){
    //       let end = performance.now();        
    //     }
  
    //     let pdfBase64 = final.getDataUrl((dataUrl) => {
    //       let prescName = prescription.prescription_type == 'Rx' ? 'prescriptionExternalPharmacyURL' : 'prescriptionExternalLabURL';
    //       let obj = {
    //         z_base64withPass: dataUrl.split("base64,")[1],
    //         [prescName]: "",
    //         externalPDFCode: randomCode
    //       }
  
    //       //save pdf to storage to get download url => pass to ERP
    //       let path = `users/${prescription.uid}`
    //       const storage = firebase.storage().ref(path);
    //       let imageRef = storage.child(`${prescription_pdf}`);
    //       imageRef.putString(dataUrl, 'data_url', {contentType: 'application/pdf'}).then((snapshot) => {
    //         snapshot.ref.getDownloadURL().then(async (url) => {
    //           obj[prescName] = url;
    //           this.afs.collection("prescription").doc(prescription.ID).update(obj);
    //           if(signature !== undefined){
    //             resolve(obj);
    //           }else{
    //             // //save pdf to storage to get download url => pass to ERP
    //             // let externalPdfPath = `externalPdfs/${prescription.uid}`;
    //             // const externalStorage = firebase.storage().ref(externalPdfPath);
    //             // let externalImageRef = externalStorage.child(`${prescription_pdf}`);
    //             // externalImageRef.putString(dataUrl, 'data_url', {contentType: 'application/pdf'}).then((snapshot) => {
    //             //   snapshot.ref.getDownloadURL().then(async (url) => {
    //             //     if(signature !== undefined){
    //             //       let externalPdf = {
    //             //         externalPDFCode: randomCode,
    //             //         [prescName]: url
    //             //       }
    //             //       resolve(externalPdf);
    //             //     }else{
    //             //       resolve(dataUrl)
    //             //     }
    //             //   });
    //             // }, err => {
    //             //   reject(err);
    //             // });
    //             resolve(dataUrl)
    //           }
    //         });
    //       }, err => {
    //         reject(err);
    //       });
    //     });
    //   } 
    // });
  }

  randomCodeGenerator(){
    return Math.floor(Math.random() * 10000000) + 1;
  }

  async pdfTemplate(prescription, signature){
    let promise = new Promise<any>(async (resolve, reject) => {
      const {address, docAdd, docEmail, NPI, age, email, birthdate, docName, name, License, LicenseState} = prescription;
      
      const format = 'h:mm a';
      const locale = "en"
      let time = formatDate(new Date(), format, locale);
      let rows = [];

      if(address?.AddressLine2 != null){
        address.AddressLine2 = ' ' + address.AddressLine2
      }
      else if(address){
        address.AddressLine2 = ''
      }
      if(docAdd?.AddressLine2 != null){
        docAdd.AddressLine2 = ' ' + docAdd.AddressLine2
      } else if(docAdd){
        docAdd.AddressLine2 = ''
      }

      let diagnostics = await this.getDiagnostics(prescription.consultID);

      let products = await this.getDocumentItems(prescription.ID);
      let notPacks = await products.map(product => {
        if(!product.isPack && prescription.prescription_type == 'Rx'){
          let line = `${product.name} #${product.qty} Refills: ${product.refillQty}`;
          if(line.length > 99){
            let first = line.match(/.{1,100}(\s|$)/g)
            first.forEach(row => {
              rows.push([row]);
            });
          }else{
            let pipe = new TranslationPipe();
            let instruction = pipe.transform(product.rxInstruction, 'en');
            let refill = product.refillQty > 0 ? `${product.refillQty} every ${product.refillIntervalDays} days` : `None`
            rows.push([{text:`${product.productName} #${product.productQty} for ${product.refillIntervalDays} days   Refills: ${refill}`}]);
            rows.push([{text:`SIG:${instruction}`}]);
            rows.forEach(row => {
              if(row[0].text.length < 99){
                  row[0].text;
              }
              else{
                let first = row[0].text.match(/.{1,100}(\s|$)/g)
                first.forEach(row => {
                  rows.push([row]);
                })
              }
            })
          }
          return product
        }
        else if(prescription.prescription_type == 'Lab'){
          let diagnostics = product.diagnostics.map(diag => {
            return diag.code
          }).join(", ")

          rows.push([{text: `${product.labName} ${product.labCode ? '(' + product.labCode + ')' : ''} ${diagnostics ? '- ' + diagnostics : ''}`}]);
        }
      });

      if(rows.length !== 20){
        const itemsLength = rows.length;
        for(let i = 0; i < 20 - itemsLength; i++){
          rows.push([{text:"- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -", color:"#D3D3D3", fontSize:12}]);
        }
      }

      await this.authService.getNPIRegistry(prescription.docId).then(async res => {
        let credential = res.basic.credential || '';

        let sign = signature ? 'image' : 'text';
        var dd: any = {
          pageSize: 'A4',
          permissions: {
            printing: 'highResolution',
            modifying: false,
            copying: false,
            annotating: false,
            fillingForms: false,
            contentAccessibility: true,
            documentAssembly: true
          },
          content: [
          {
            stack: [
            {
              image: prescription.prescription_type == 'Rx' ? rxLogoImage : labLogoImage,
              width: 70.5,
              margin: [0, 0, 227.5, 0]
            }, 
            {   
              margin: [0, -70.5, 0, 0],
              text: [{
                text: `Physician: `,
                bold: true,
              },
              {
                text: `${docName}, ${credential}`,
              },'\n',
              {
                text: `Address: `,
                bold: true,
              },
              {
                text: `${docAdd.AddressLine1}${docAdd.AddressLine2}
                        ${docAdd.AddressCity}, ${docAdd.AddressState} ${docAdd.AddressZipcode} ${docAdd.AddressCountry}`,
              },'\n',
              {
                text: `Email: `,
                bold: true,
              },
              {
                text: `${docEmail}`,
              },
              '\n',
              {
                text: `ID: `,
                bold: true,
              },
              {
                text: `${prescription.ID}`,
              }]
            }],
            alignment: 'right',
          }, 
          '\n',
          {
            margin: [0, -11.5, 0, 0],
            columns: [{
              text: [{
                text: `Patient: `,
                bold: true
              },
              {
                text: `${name}`,
              }, `\n`,
              {
                text: `DOB: `,
                bold: true
              },
              {
                text: `${birthdate} (${age}) years old`,
              }, '\n',
              {
                text: `Address: `,
                bold: true,
              }, 
              {
                text: `${address.AddressLine1}${address.AddressLine2} 
                      ${address.AddressCity}, ${address.AddressState} ${address.AddressZipcode} ${address.AddressCountry} `
              }, '\n',
              {
                text: "Email: ",
                bold: true
              }, 
              {
                text: `${email ? email : ''}`
              }, "\n", 
              {
                text: diagnostics.length > 0 ? "Diagnostics: " : "",
                bold: true
              }, 
              {
                text: `${diagnostics}`
              }],
            }],
            layout: 'noBorders',
          }, 
          '\n',
          {
            text: [
              { text: `Date: `, bold: true},
              { text: `${new Date().toDateString() + " " + time}`}
            ]  
          },
          '\n',
          {
            text: prescription.prescription_type == 'Rx' ? 'Rx Details' : 'Lab Order Details',
            bold: true,
            fontSize: '20',
          },
          '\n',
          {
            margin: [0, -5, 0, 0],
            table: {
              headerRows: 1,
              body: rows
            },
            layout: 'noBorders'
          }, 
          '\n',
          {
            margin: [0, -5, 0, 0],
            layout: 'noBorders',
            table: {
              body: [[
                {
                  text: [
                  '\n',
                  {
                    text: `NPI: `,
                    bold: true
                  }, {
                    text: `${NPI}`,
                  },
                  '\n',
                  {
                    text: `Licence Number: `,
                    bold: true
                  }, {
                    text: `${License}`,
                  }, 
                  '\n', 
                  {
                    text: `State: `,
                    bold: true
                  }, {
                    text: `${LicenseState}`
                  }]
                },
                {
                  alignment: 'right',
                  columns: [{
                    text: "Physician Signature: \xa0",
                    bold: true,
                    margin: [0, 20, 0, 0],
                    width: 215,
                  },
                  {
                    [sign]: signature ? signature : '',
                    width: 150
                  }],
                }
              ]],
              fontSize:12
            }
          },
          '\n',
          {
            style: 'pharmacyTable',
            table: {
              widths: [800],
              heights: 20,
              body: [
                [{
                  text: prescription.prescription_type == 'Rx' ? `Pharmacy processing section below this line` : 'Laboratory processing section below this line',
                  margin: [30, 2, 0, 0],
                  bold: true
                }],
              ]
            },
            layout: 'noBorders',
          }],
          styles: {
            pharmacyTable: {
              margin: [-40, -13, 0, 0],
              fillColor: '#ebecf0',
            }
          }
        };

        if(signature){
          dd.content[10].table.body[0][1].columns[1].margin = [0, 5, 0, 0];
          dd.content[11] = {
            alignment: "right",
            margin: [0, -25, 5, 0],
            text:[
                  {text: 'Digitally Signed by: ' + prescription.docName,
                  fontSize: 10,
                  color: "red"
              },'\n',
              {
                  text: 'Date: ' + new Date().toLocaleDateString(),
                  fontSize: 10,
                  color: "red"
              },'\n\n',
            ]
          }
        }

        if(prescription.prescription_type == 'Rx' && signature && prescription.pharmacy != 'External Pharmacy' && prescription.patientSignature){
          dd.content.push({
            alignment: "right",
            margin: [0, -20, 5, 0],
            text:[
                  {text: 'Patient Signature: ',
                  fontSize: 13,
              },'\n\n',
              {
                  text: 'Digitally Signed by: ' + prescription.patientSignature,
                  fontSize: 12,
              },'\n',
              {
                text: 'Date: ' + prescription.patientSignedDate.seconds ? prescription.patientSignedDate.toDate().toLocaleDateString() : prescription.patientSignedDate ? prescription.patientSignedDate.toLocaleDateString() : '',
                fontSize: 12,
              },'\n',
              {
                  text: `${prescription.orientation ? 'Patient requested orientation' : 'Patient did not request orientation'}`,
                  fontSize: 12,
              }
            ]
          })
        }

        resolve(dd);
      })
    });

    return promise;
  }



  async pdfExternalTemplate(prescription, signature){
    let promise = new Promise<any>(async (resolve, reject) => {
      const {address, docAdd, docEmail, NPI, age, email, birthdate, docName, name, License, LicenseState} = prescription;
      
      const format = 'h:mm a';
      const locale = "en"
      let time = formatDate(new Date(), format, locale);
      let rows = [];

      if(address[0]?.AddressLine2 != null){
        address[0].AddressLine2 = ' ' + address[0].AddressLine2
      }
      else if(address){
        address[0].AddressLine2 = ''
      }
      if(docAdd?.AddressLine2 != null){
        docAdd.AddressLine2 = ' ' + docAdd.AddressLine2
      } else if(docAdd){
        docAdd.AddressLine2 = ''
      }

      // let diagnostics = await this.getDiagnostics(prescription.consultID);

      let products = await this.getDocumentItems(prescription.ID);

      let notPacks = await products.map(product => {
        // if(!product.isPack && prescription.prescription_type == 'Rx'){
          let line = `${product.productName} #${product.productQty} Refills: ${product.refillQty}`;
          if(line.length > 99){
            let first = line.match(/.{1,100}(\s|$)/g)
            first.forEach(row => {
              rows.push([row]);
            });
          }else{
            let pipe = new TranslationPipe();
            let instruction = pipe.transform(product.rxInstruction, 'en');
            let refill = product.refillQty > 0 ? `${product.refillQty} every ${product.refillIntervalDays} days` : `None`
            rows.push([{text:`${product.productName} #${product.productQty} for ${product.refillIntervalDays} days   Refills: ${refill}`}]);
            rows.push([{text:`SIG:${instruction}`}]);
            rows.forEach(row => {
              if(row[0].text.length < 99){
                row[0].text;
              }
              else{
                let first = row[0].text.match(/.{1,100}(\s|$)/g)
                first.forEach(row => {
                  rows.push([row]);
                })
              }
            })
          }
          return product
        // }
       
      });

      if(rows.length !== 20){
        const itemsLength = rows.length;
        for(let i = 0; i < 20 - itemsLength; i++){
          rows.push([{text:"- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -", color:"#D3D3D3", fontSize:12}]);
        }
      }

      await this.authService.getNPIRegistry(prescription.docId).then(async res => {
        let credential = res.basic.credential || '';

        let sign = signature ? 'image' : 'text';
        var dd: any = {
          pageSize: 'A4',
          permissions: {
            printing: 'highResolution',
            modifying: false,
            copying: false,
            annotating: false,
            fillingForms: false,
            contentAccessibility: true,
            documentAssembly: true
          },
          content: [
          {
            stack: [
            {
              image: prescription.prescription_type == 'Rx' ? rxLogoImage : labLogoImage,
              width: 70.5,
              margin: [0, 0, 227.5, 0]
            }, 
            {   
              margin: [0, -70.5, 0, 0],
              text: [{
                text: `Physician: `,
                bold: true,
              },
              {
                text: `${docName}, ${credential}`,
              },'\n',
              {
                text: `Address: `,
                bold: true,
              },
              {
                text: `${docAdd.AddressLine1}${docAdd.AddressLine2}
                       ${docAdd.AddressCity}, ${docAdd.AddressState} ${docAdd.AddressZipcode} ${docAdd.AddressCountry}`,
              },'\n',
              {
                text: `Email: `,
                bold: true,
              },
              {
                text: `${docEmail}`,
              },
              '\n',
              {
                text: `ID: `,
                bold: true,
              },
              {
                text: `${prescription.ID}`,
              }]
            }],
            alignment: 'right',
          }, 
          '\n',
          {
            margin: [0, -11.5, 0, 0],
            columns: [{
              text: [{
                text: `Patient: `,
                bold: true
              },
              {
                text: `${name}`,
              }, `\n`,
              {
                text: `DOB: `,
                bold: true
              },
              {
                text: `${birthdate} (${age}) years old`,
              }, '\n',
              {
                text: `Address: `,
                bold: true,
              }, 
              {
                text: `${address[0].AddressLine1}${address[0].AddressLine2} 
                      ${address[0].AddressCity}, ${address[0].AddressState} ${address[0].AddressZipcode} ${address[0].AddressCountry} `
              }, '\n',
              {
                text: "Email: ",
                bold: true
              }, 
              {
                text: `${email ? email : ''}`
              }, "\n", 
              ],
            }],
            layout: 'noBorders',
          }, 
          '\n',
          {
            text: [
              { text: `Date: `, bold: true},
              { text: `${new Date().toDateString() + " " + time}`}
            ]  
          },
          '\n',
          {
            text: prescription.prescription_type == 'Rx' ? 'Rx Details' : 'Lab Order Details',
            bold: true,
            fontSize: '20',
          },
          '\n',
          {
            margin: [0, -5, 0, 0],
            table: {
              headerRows: 1,
              body: rows
            },
            layout: 'noBorders'
          }, 
          '\n',
          {
            margin: [0, -5, 0, 0],
            layout: 'noBorders',
            table: {
              body: [[
                {
                  text: [
                  '\n',
                  {
                    text: `NPI: `,
                    bold: true
                  }, {
                    text: `${NPI}`,
                  },
                  '\n',
                  {
                    text: `Licence Number: `,
                    bold: true
                  }, {
                    text: `${License}`,
                  }, 
                  '\n', 
                  {
                    text: `State: `,
                    bold: true
                  }, {
                    text: `${LicenseState}`
                  }]
                },
                {
                  alignment: 'right',
                  columns: [{
                    text: "Physician Signature: \xa0",
                    bold: true,
                    margin: [0, 20, 0, 0],
                    width: 215,
                  },
                  {
                    [sign]: signature ? signature : '',
                    width: 150
                  }],
                }
              ]],
              fontSize:12
            }
          },
          '\n',
          {
            style: 'pharmacyTable',
            table: {
              widths: [800],
              heights: 20,
              body: [
                [{
                  text: prescription.prescription_type == 'Rx' ? `Pharmacy processing section below this line` : 'Laboratory processing section below this line',
                  margin: [30, 2, 0, 0],
                  bold: true
                }],
              ]
            },
            layout: 'noBorders',
          }],
          styles: {
            pharmacyTable: {
              margin: [-40, -13, 0, 0],
              fillColor: '#ebecf0',
            }
          }
        };

        if(signature){
          dd.content[10].table.body[0][1].columns[1].margin = [0, 5, 0, 0];
          dd.content[11] = {
            alignment: "right",
            margin: [0, -25, 5, 0],
            text:[
                  {text: 'Digitally Signed by: ' + prescription.docName,
                  fontSize: 10,
                  color: "red"
              },'\n',
              {
                  text: 'Date: ' + new Date().toLocaleDateString(),
                  fontSize: 10,
                  color: "red"
              },'\n\n',
            ]
          }
        }

        if(prescription.prescription_type == 'Rx' && signature && prescription.pharmacy != 'External Pharmacy' && prescription.patientSignature){
          dd.content.push({
            alignment: "right",
            margin: [0, -20, 5, 0],
            text:[
                  {text: 'Patient Signature: ',
                  fontSize: 13,
              },'\n\n',
              {
                  text: 'Digitally Signed by: ' + prescription.patientSignature,
                  fontSize: 12,
              },'\n',
              {
                  text: 'Date: ' + prescription.patientSignedDate.seconds ? prescription.patientSignedDate.toDate().toLocaleDateString() : prescription.patientSignedDate ? prescription.patientSignedDate.toLocaleDateString() : '',
                  fontSize: 12,
              },'\n',
              {
                  text: `${prescription.orientation ? 'Patient requested orientation' : 'Patient did not request orientation'}`,
                  fontSize: 12,
              }
            ]
          })
        }

        resolve(dd);
      })
    });

    //! add the external-prescription pdf doc to the final pdf
    let external_pdf = await this.prescriptionService.getExternalTranscribedPrescription(prescription.uid, prescription.ID);
    if(external_pdf && external_pdf.extension !== "pdf"){
      this.externalPrescriptionPDFRef = {extension:external_pdf.extension, existingPdfUrl: await this.convertImgToPDF(external_pdf.downloadURL)};
    }else{
      // let b64 = Buffer.from(external_pdf.downloadURL, 'base64');
      let b64 = btoa(external_pdf.downloadURL)
      // const pdfDefinition = {
      //   content: [{ image: `data:image/jpeg;base64,${b64}`, width: 500 }]
      // };
      // Create a PDF file
      // const external_pdf = pdfMake.createPdf(pdfDefinition);
      const existingPdfUrl = `data:application/pdf;base64,${b64}`; 
      this.externalPrescriptionPDFRef = {extension:external_pdf.extension, existingPdfUrl};
    }
    
    return promise;
  }

  async attachLetter(prescription, referralText){
    const {docAdd, NPI, docName, License, LicenseState} = prescription;
    const format = 'h:mm a';
    const locale = "en"
    let time = formatDate(new Date(), format, locale);
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.authService.getNPIRegistry(prescription.docId).then(async res => {
        let credential = res.basic.credential || '';
        
        resolve([
          {
            stack: [
            {   
              text: [
              {
                text: `${docName}, ${credential}`,
              },
              '\n',
              {
                text: `${docAdd.AddressLine1}${docAdd.AddressLine2}
                        ${docAdd.AddressCity}, ${docAdd.AddressState} ${docAdd.AddressZipcode} ${docAdd.AddressCountry}`,
              }]
            }],
            alignment: 'right',
          }, 
          '\n',
          {
            text: [
              { text: `Date: `, bold: true},
              { text: `${new Date().toDateString() + " " + time}`}
            ]  
          },
          '\n',
          {
            text: referralText, fontSize:12
          }, 
          '\n',
          {
            text: "Sincerely,",
          },
          {
            image: prescription.practitionerSignature,
            width: 150
          },
          {text: 'Digitally Signed by: ' + prescription.docName,
            fontSize: 10,
            color: "red"
          },
          {
            text: 'Date: ' + new Date().toLocaleDateString(),
            fontSize: 10,
            color: "red"
          },
          {
            text: [{
              text: `${docName}, ${credential}`,
            },
            '\n',
            {
              text: `NPI: `,
              bold: true
            }, 
            {
              text: `${NPI}`,
            },
            '\n',
            {
              text: `Licence Number: `,
              bold: true
            }, 
            {
              text: `${License}`,
            },
            '\n',
            {
              text: `State: `,
              bold: true
            }, 
            {
              text: `${LicenseState}`
            }],
            pageBreak: 'after', 
          }
        ])
      })
      
    })
    return promise
  }


  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result as string;
            resolve(base64String.split(",")[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
  }


  convertImgToPDF(url:string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Fetch the image from the URL
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          // Convert the blob to base64
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = (reader.result as string).split(',')[1]; // Get base64 data
            // Create a PDF document with the image
            const pdfDefinition = {
              content: [{ image: `data:image/jpeg;base64,${base64data}`, width: 500 }]
            };
            // Create a PDF file
            // const external_pdf = pdfMake.createPdf(pdfDefinition);
            resolve(pdfDefinition);
          };
        })
        .catch(error => {
          reject(error);
        });
    });
  }


}
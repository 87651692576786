<router-outlet *ngIf="!offline && ((settings && !settings.maintenance) || (claims && (claims.devAdmin || claims.superAdmin || claims.testUsers)) || (claims == null && settings && !settings.maintenance))"></router-outlet>

<div *ngIf="offline" class="connection">
  <div id="content" class="content">
    <div id="wifi-loader">
      <svg class="circle-outer" viewBox="0 0 86 86">
        <circle class="back" cx="43" cy="43" r="40"></circle>
        <circle class="front" cx="43" cy="43" r="40"></circle>
        <circle class="new" cx="43" cy="43" r="40"></circle>
      </svg>
      <svg class="circle-middle" viewBox="0 0 60 60">
        <circle class="back" cx="30" cy="30" r="27"></circle>
        <circle class="front" cx="30" cy="30" r="27"></circle>
      </svg>
      <svg class="circle-inner" viewBox="0 0 34 34">
        <circle class="back" cx="17" cy="17" r="14"></circle>
        <circle class="front" cx="17" cy="17" r="14"></circle>
      </svg>
      <!-- <div class="text" data-text="Searching"></div> -->
    </div>
    <div class="text-div d-flex flex-column">
      <span id="connection-text">{{'NOINTERNET' | translate}}</span>
      <p id="action-text">{{'CHECKINTERNET' | translate}}</p>
      <button id="refresh-btn" class="refresh-btn mt-3" (click)="refresh()" style="display:none;">{{'RELOADTHEPAGE'}}</button>
    </div>
  </div>
</div>
<div id="recaptcha-container"></div>

<div class="d-flex justify-content-center align-items-center flex-column" style="height: 100vh;" *ngIf="(settings && settings.maintenance && claims && !claims.devAdmin && !claims.superAdmin && !claims.testUsers) || (claims == null && settings && settings.maintenance)">
  <img style="width: 200px;" src="../assets/images/logos/Dzeus-Logo.png">
  <h3 class="mt-3 text-center bold">Dzeus Health está bajo mantenimiento. <br> Por favor regrese mas tarde.</h3> 
  <h5 class="mt-2 text-center bold">Dzeus Health is under maintenance. <br> Please come back later.</h5> 
  <div class="hourglass"></div>
  <h4 class="mt-2 text-center">Tiempo estimado para regresar - {{settings.maintanceTime.toDate() | date:'MMMM dd, yyyy hh:mm aa':'':'es' | titlecase}}</h4>
  <div class="mt-2">¿Necesita ayuda? <a href="https://dzeus.com/contactus" target="_blank">¡Hable con nosotros! <i class="fas fa-comment cyan"></i></a></div>
  
  <h5 class="mt-3 text-center">Estimated time to come back - {{settings.maintanceTime.toDate() | date:'MMMM dd, yyyy hh:mm aa'}}</h5>
  <div>Need Support? <a href="https://dzeus.com/contactus" target="_blank">Chat with us <i class="fas fa-comment cyan"></i></a></div>
</div>


<ng-template #idle let-modal>
  <div class="">
    <h3 class="text-center"> {{'IDLE.TITLE' | translate}}</h3>
    <h5 class="text-center"> {{'IDLE.DESCRIPTION' | translate}} <span class="text-danger" id="nums1">60</span><span class="text-danger"> {{'IDLE.SECONDS' | translate}}</span></h5>
    <div class="d-flex justify-content-center">
      <div id="pie" class="pie-wrapper progress-95 style-2">
        <span id="nums" class="label">60</span>
        <div class="pie">
          <div class="left-side half-circle"></div>
          <div class="right-side half-circle"></div>
        </div>
        <div class="shadow"></div>
      </div>
    </div>
    <div class="w-100 text-center mt-3">
      <button (click)="reset()" class="btn button">{{'IDLE.ACTION' | translate}}</button>
    </div>
  </div>
</ng-template>

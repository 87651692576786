<div class="container-fluid">
  <app-pagetitle [title]="pagetitle"></app-pagetitle>

  <div *ngIf="!patientInfo && !manageClaims" class="card">
    <div class="card-body">
      <button class="btn btn-cyan mb-1" (click)="openModal(true, false)"><i style="color: white !important;" class="ml-1 fas fa-user-plus text-success" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.CREATEACCOUNT' | translate}}" (click)="openModal(true, false)"></i> {{'ACCOUNTS.NGBTOOLTIP.CREATEACCOUNT' | translate}}</button>
      <button class="btn btn-cyan mb-1 ml-2" (click)="createExternalPatient()"><i style="color: white !important;" class="ml-1 fas fa-user-plus text-success" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.CREATEACCOUNTEXTERNAL' | translate}}"></i> {{'ACCOUNTS.NGBTOOLTIP.CREATEACCOUNTEXTERNAL' | translate}}</button>
      <button *ngIf="myClaims && myClaims.devAdmin" class="btn btn-cyan mb-1 ml-2" (click)="manageClaims = true; getGroups()"><i style="color: white !important;" class="ml-1 fas fa-user-plus text-success" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.CREATEACCOUNT' | translate}}" (click)="manageClaims = true; getGroups()"></i> Manage Claims</button>
      <button *ngIf="myClaims && myClaims.devAdmin" class="btn btn-cyan mb-1 ml-2" (click)="getUsersWithoutClaims()"><i style="color: white !important;" class="ml-1 fas fa-user-plus text-success" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.CREATEACCOUNT' | translate}}" (click)="getUsersWithoutClaims()"></i> Users without claims</button>
      <!-- <button *ngIf="myClaims && myClaims.devAdmin" class="btn btn-cyan mb-1 ml-2" (click)="addClaimsForExistingUsers()"><i style="color: white !important;" class="ml-1 fas fa-user-plus text-success" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.CREATEACCOUNT' | translate}}" (click)="addClaimsForExistingUsers()"></i> Add to existing users</button> -->
      <span class="row">
        <div class="col-sm-12 col-md-12">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right" >
            <label for="search"
              class="d-inline-flex align-items-center justify-content-end">{{'ACCOUNTS.SEARCH' | translate}}
              <input id="search" type="text" name="searchTerm" class="form-control form-control-sm ml-2"aria-controls="tickets-table" [(ngModel)]="term" (keyup)="filterAccounts(term)" />
            </label>
          </div>
        </div>
      </span>
      <div *ngIf="!showLoadingAccounts" class="table-responsive">
        <table class="table table-centered m-0">
          <thead class="thead-light">
            <tr> 
              <th>{{'ACCOUNTS.ACTION' | translate}}</th>
              <th>{{'ACCOUNTS.NAME' | translate}}</th>
              <th>{{'ACCOUNTS.PHONENUMBER' | translate}}</th>
              <th>{{'ACCOUNTS.EMAIL' | translate}}</th>
              <th>{{'ACCOUNTS.TYPE' | translate}}</th>
              <th>{{'ACCOUNTS.ACCOUNTVERIFIED' | translate}}</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of allUserAccounts | paginate: { itemsPerPage: 10, currentPage: pageAccounts, id: 'first'}; let i = index; filter: term;">
              <td>
                <i class="mdi mdi-pencil font-size-18 mr-3 text-success" style="cursor: pointer;" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.EDIT' | translate}}" (click)="openModal(false, user)"></i>
                <i class="mdi mdi-lock-reset font-size-18 mr-3 text-dark" style="cursor: pointer;" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.RESETPASSWORD' | translate}}" (click)="showAlertMsg(user)"></i> <!--(click)="openModal(item)"-->
              </td>  <!---->
              <td>{{user.firstName}} {{user.middleName}} {{user.lastName1}}</td>
              <td>{{user.phoneNumber | mask:'(000) 000-0000'}}</td>
              <td>{{user.email}}</td>
              <td *ngIf="user.accountType == 'admin'">{{'ACCOUNTS.EMAIL&PASSWORD.ADMIN' | translate}}</td>  
              <td *ngIf="user.accountType == 'practitioner'">{{'ACCOUNTS.EMAIL&PASSWORD.PRACTITIONER' | translate}}</td>  
              <td *ngIf="user.accountType == 'patient'">{{'ACCOUNTS.EMAIL&PASSWORD.PATIENT' | translate}}</td>  
              <td *ngIf="user.accountType == 'analyticsViewer'">Analytics Viewer</td>  
              <td *ngIf="user.accountType == undefined || user.accountType == null || user.accountType == ''"></td>  
              <td>
                <h5 class="badge badge-success font-size-12 mb-0" style="text-transform: capitalize;" *ngIf="user.verified == 1">{{'ACCOUNTS.YES' | translate}}</h5>
                <h5 class="badge badge-danger font-size-12 mb-0" *ngIf="user.verified== 0">{{'ACCOUNTS.NO' | translate}}</h5>
              </td>
              <td>
                <h5 class="badge badge-success font-size-12 mb-0" style="text-transform: capitalize;" *ngIf="!user.identification?.startsWith('../assets')">{{'ACCOUNTS.YES' | translate}}</h5>
                <h5 class="badge badge-danger font-size-12 mb-0" *ngIf="user.identification?.startsWith('../assets')">{{'ACCOUNTS.NO' | translate}}</h5>                
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row justify-content-end mt-4">
          <div class="col text-right">
            <pagination-controls (pageChange)="pageAccounts = $event" id="first" style="text-align: right;"></pagination-controls>
          </div>
        </div>

      </div>
      

      <div *ngIf="showLoadingAccounts" class="row" style="justify-content: center; align-items: center;">
        <div class="col-lg-12">
            <div class="text-center">
                <span class="spinner-border spinner-border-sm" role="status"></span>
            </div> 
        </div>
      </div>


      <div *ngIf="!showLoadingAccounts" class="table-mobile">
        <li class="mb-3" *ngFor="let user of allUserAccounts | paginate: { itemsPerPage: 10, currentPage: pageAccounts, id: 'first'}; let i = index; filter:term;">
          <button class="btn btn-primary mr-2" style="font-size: 16px !important; padding: 0 0.75rem !important;" (click)="openModal(false, user)">{{'ACCOUNTS.VIEWACCOUNT' | translate}}</button>
          <div>{{'ACCOUNTS.NAME' | translate}}: <span class="bold">{{user.firstName}} {{user.middleName}} {{user.lastName1}}</span></div>
          <div>{{'ACCOUNTS.PHONENUMBER' | translate}}: <span class="bold">{{user.phoneNumber | mask:'(000) 000-0000'}}</span></div>
          <div>{{'ACCOUNTS.EMAIL' | translate}}: <span class="bold">{{user.email}}</span></div>
          <div *ngIf="user.accountType == 'admin'">{{'ACCOUNTS.TYPE' | translate}}: <span class="bold">{{'ACCOUNTS.EMAIL&PASSWORD.ADMIN' | translate}}</span></div>  
          <div *ngIf="user.accountType == 'practitioner'">{{'ACCOUNTS.TYPE' | translate}}: <span class="bold">{{'ACCOUNTS.EMAIL&PASSWORD.PRACTITIONER' | translate}}</span></div>  
          <div *ngIf="user.accountType == 'patient'">{{'ACCOUNTS.TYPE' | translate}}: <span class="bold">{{'ACCOUNTS.EMAIL&PASSWORD.PATIENT' | translate}}</span></div>  
          <div class="d-flex flex-row">
            <div class="mr-5">
              {{'ACCOUNTS.ACCOUNTVERIFIED' | translate}}:
              <h5 class="badge badge-success font-size-12 mb-0" style="text-transform: capitalize;" *ngIf="user.verified == 1">{{'ACCOUNTS.YES' | translate}}</h5>
              <h5 class="badge badge-danger font-size-12 mb-0" *ngIf="user.verified== 0">{{'ACCOUNTS.NO' | translate}}</h5>
            </div>
            <div>
              ID:
              <h5 class="badge badge-success font-size-12 mb-0" style="text-transform: capitalize;" *ngIf="!user.identification?.startsWith('../assets')">{{'ACCOUNTS.YES' | translate}}</h5>
              <h5 class="badge badge-danger font-size-12 mb-0" *ngIf="user.identification?.startsWith('../assets')">{{'ACCOUNTS.NO' | translate}}</h5>                
            </div>
          </div>
          <hr>
        </li>

        <div class="row justify-content-end mt-4">
          <div class="col text-right">
            <pagination-controls (pageChange)="pageAccounts = $event" id="first" style="text-align: right;"></pagination-controls>
          </div>
        </div>
      </div>

    </div>
  </div>  

  <!-- end row -->


<!-- Large Modal -->
<!-- <ng-template #largeDataModal let-modal> -->
  <div *ngIf="patientInfo && !manageClaims">
    <div class="modal-header">
      <!-- <h5 *ngIf="createAccount" class="modal-title mt-0">{{'ACCOUNTS.CREATEACCOUNT' | translate}}</h5> -->
      <!-- <h5 *ngIf="!createAccount" class="modal-title mt-0">{{'ACCOUNTS.EDITACCOUNT' | translate}}</h5> -->
      <button type="button" style="font-size: 1.35rem !important;" class="close" (click)="patientInfo = false; navigation = 1" aria-hidden="true">×</button>
    </div>
    <div class="modal-body p-0">
  
      <div class="row" *ngIf="isUpdated">
          <div class="col-12">
          <div class="alert alert-success" role="alert">
            {{'ACCOUNTS.ALERT' | translate}}
          </div>
          </div>  
      </div>   
  
  
    <!-- Start custom nav tab -->
    <div class="nav-content">
      <ul ngbNav #customNav="ngbNav" [activeId]="2" class="nav-tabs nav-tabs-custom nav-justified">
        <li *ngIf="createAccount" [ngbNavItem]="2">
          <a ngbNavLink>
            <span>{{'ACCOUNTS.EMAIL&PASSWORD.EMAIL&PASSWORD' | translate}}</span>
          </a>
          <ng-template ngbNavContent>
            <div class="mt-3 title">
              <h4>{{'ACCOUNTS.EMAIL&PASSWORD.TITLE' | translate}}</h4>
            </div>
            <form class="form-horizontal" [formGroup]="createUserForm" (ngSubmit)="onSubmit()">
              <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
              <div class="fields">
                <div class="form-group auth-form-group-custom mb-4">
                  <i class="ri-mail-line auti-custom-input-icon"></i>
                  <label for="email">{{'ACCOUNTS.EMAIL&PASSWORD.EMAIL' | translate}}</label>
                    <input type="email" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" placeholder="{{'ACCOUNTS.EMAIL&PASSWORD.EMAIL' | translate}}">
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">{{'ACCOUNTS.EMAIL&PASSWORD.REQUIREEMAIL' | translate}}</div>
                    <div *ngIf="f.email.errors.email">{{'ACCOUNTS.EMAIL&PASSWORD.VALIDEMAIL' | translate}}</div>
                  </div>
                </div>
                <div>
                  <h4 class="card-title">{{'ACCOUNTS.EMAIL&PASSWORD.TYPEOFACCOUNT' | translate}}</h4>  
                  <div class="form-group row">
                    <div class="col-md-12">
                      <ng-select formControlName="accountType"
                      [ngClass]="{ 'is-invalid': submitted && f.accountType.errors }" id="accountType">
                      <ng-option value="patient">{{'ACCOUNTS.EMAIL&PASSWORD.PATIENT' | translate}}</ng-option>
                      <ng-option value="practitioner">{{'ACCOUNTS.EMAIL&PASSWORD.PRACTITIONER' | translate}}</ng-option>
                      <ng-option value="admin">{{'ACCOUNTS.EMAIL&PASSWORD.ADMIN' | translate}}</ng-option>
                      <ng-option value="analyticsViewer">Analytics Viewer</ng-option>
                      </ng-select>
                      <div *ngIf="submitted && f.accountType.errors" class="invalid-feedback">
                        <div *ngIf="f.accountType.errors.required">{{'ACCOUNTS.EMAIL&PASSWORD.REQUIREDACCOUNT' | translate}}</div>
                      </div>
                    </div>
                  </div>

                  <h4 *ngIf="f.accountType.value == 'practitioner'" class="card-title">{{'ACCOUNTS.EMAIL&PASSWORD.TYPEOFPRACTITIONER' | translate}}</h4>  
                  <div *ngIf="f.accountType.value == 'practitioner'" class="form-group row">
                    <div class="col-md-12">
                      <ng-select formControlName="typeOfPractitioner" [multiple]="true"
                      [ngClass]="{ 'is-invalid': submitted && f.typeOfPractitioner.errors }" id="typeOfPractitioner">
                      <ng-option value="Doctor">{{'ACCOUNTS.EMAIL&PASSWORD.DOCTOR' | translate}}</ng-option>
                      <ng-option value="Psychologist">{{'ACCOUNTS.EMAIL&PASSWORD.PSYCHOLOGIST' | translate}}</ng-option>
                      <ng-option value="Pharmacist">{{'ACCOUNTS.EMAIL&PASSWORD.PHARMACIST' | translate}}</ng-option>
                      </ng-select>
                      <div *ngIf="submitted && f.typeOfPractitioner.errors" class="invalid-feedback">
                        <div *ngIf="f.typeOfPractitioner.errors.required">{{'ACCOUNTS.EMAIL&PASSWORD.REQUIREDTYPEOFPRACTITIONER' | translate}}
                        </div>   
                      </div>
                    </div>
                  </div>                     
                </div>  
              </div>
            </form>
          </ng-template>
          <!-- <app-external-patient-form></app-external-patient-form> -->
        </li>
        <li *ngIf="!createAccount" [ngbNavItem]="2" >
          <a ngbNavLink (click)="navigation = 2">
            <span>{{'VIEWCONSULTS.PATIENTPROFILE.PATIENTPROFILE' | translate}}</span>
          </a>
          <ng-template ngbNavContent>
            <app-admin-accounts *ngIf="profileToEdit && allUsers" [accountToEdit]="profileToEdit?.uid" [accountMetadata]="profileToEdit" [allUsers]="allUsers"></app-admin-accounts>
            <div class="manage-account">
              <div class="d-flex flex-column mt-2">                   
                <button type="button" style="display: flex; font-size: 16px !important; padding: 0 0.75rem !important;" class="btn btn-cyan btn-sm cardBtn adminBtn mb-1" *ngIf="profileToEdit && !this.profileToEdit?.isAccountDisabled && !this.profileToEdit?.emailVerified && !sendingEmailLoading" (click)="resendVerificationEmail(profileToEdit)">
                  {{'EMAILVERIFY.RESEND' | translate}}
                </button>
                <button type="button" style="display: flex; font-size: 16px !important; padding: 0 0.75rem !important;" class="btn btn-cyan btn-sm cardBtn adminBtn mb-1" *ngIf="profileToEdit && !this.profileToEdit?.isAccountDisabled && !this.profileToEdit?.emailVerified && sendingEmailLoading">
                  {{'EMAILVERIFY.SENDINGEMAIL' | translate}}
                </button>
              </div>
            </div>
          </ng-template>
        </li>
        <li *ngIf="!createAccount && profileToEdit?.accountType == 'practitioner'" [ngbNavItem]="3" >
          <a ngbNavLink (click)="navigation = 3">
            <span>{{'ACCOUNTS.PRACTITIONERDOCS' | translate}}</span>
          </a>
          <ng-template ngbNavContent>
            <div style="display: flex; flex-direction: column;" *ngIf="profileToEdit && profileToEdit?.accountType == 'practitioner'">
              <div class="data" style="padding-bottom: 20px;">
                <div class="box fade-in userProfile">
                  <div class="col-lg-12 d-md-block">
                    <div class="card-body" style="padding-bottom: 0 !important;">
                      <h4 class="card-title mb-3">{{'PROFILE.PRACTITIONERINFO' | translate}}</h4>
                    
                      <button (click)="openModals(choosePdf)" class="btn btn-primary py-1 m-0" style="color: white;">
                        {{'CONSULTATIONS.UPLOADPDFFILE' | translate}}
                      </button>
                      <div class="mt-1" style="display: flex; flex-direction: column; align-items: flex-start; width: 100%;">
                        <div class="w-100">
                          <div class="w-75 mx-auto mt-3">
                            <div *ngFor="let p of pdfsLabel; let i = index" class="mb-3">
                              <h4 class="d-flex justify-content-between m-0" style="min-width: 100%; font-weight: 400; text-align: start; font-weight: bold !important;">{{p | translation:currentLang}}
                              </h4>
                              <div *ngFor="let pdfs of myPdfs[i]" class="ml-2">
                                <span style="text-align: start; margin: 0; font-size: large;">{{pdfs?.pdfName}}</span>
                                <div class="d-flex flex-row justify-content-between">
                                  <h5 style="text-align: start;" *ngIf="pdfs.expDate">{{'PROFILE.EXPDATE' | translate}}: {{pdfs.expDate | date:'MMMM dd, yyyy'}}</h5>
                                </div>
                                <button class="btn btn-cyan d-flex ml-auto" (click)="pdf = pdfs.pdf; showPDF(showPdf)">{{'CONSULTATIONS.VIEW' | translate}}</button>
                                <hr class="w-100">
                              </div>
                            </div>
                          </div>
                        </div>          
                      </div>
                    </div>
                  </div>        
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li *ngIf="!createAccount && profileToEdit?.accountType == 'practitioner'" [ngbNavItem]="4" >
          <a ngbNavLink (click)="navigation = 4">
            <span>{{'PROFILE.PRACTITIONERSCHEDULE' | translate}}</span>
          </a>
          <ng-template ngbNavContent>
            <!-- Practitioner Info -->
            <div style="display: flex; flex-direction: column;"  *ngIf="profileToEdit && profileToEdit?.accountType == 'practitioner'">
              <div class="data" style="padding-bottom: 20px;">
                <div class="box fade-in userProfile">
                  <div class="col-lg-12 d-md-block">
                    <div class="card-body" style="padding-bottom: 0 !important;">
                      <h4 class="card-title mb-3">{{'PROFILE.PRACTITIONERSCHEDULE' | translate}}</h4>
                    
                      <app-practitioner-schedule [ngClass]="{'disableDiv': !editSchedule}" [admin]="true" [editSchedule]="editSchedule" (cancelEdit)="editSchedule = false" [practitioner]="profileToEdit"></app-practitioner-schedule>
                      <h5 *ngIf="!editSchedule" style="display: flex; align-items: center; justify-content: center;"><div class="cyan" style="cursor: pointer" (click)="editSchedule = true;">{{'ACCOUNTS.NGBTOOLTIP.EDIT' | translate}}</div></h5>
                    </div>
                  </div>        
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <!-- <li *ngIf="!createAccount" [ngbNavItem]="5">
          <a ngbNavLink (click)="navigation = 5">
            <span>{{'ACCOUNTS.MANAGEACCOUNT' | translate}}</span>
          </a>
          <ng-template ngbNavContent *ngIf="profileToEdit">
            <div class="manage-account">
              <div class="d-flex flex-column mt-2">
                <div style="margin-top: 5px;">
                  <h4 class="card-title">{{'ACCOUNTS.UID' | translate}}: <span class="mb-0 bold">{{profileToEdit.uid}}</span></h4> 
                </div>
                <div style="margin-top: 5px;">
                  <h4 class="card-title">{{'ACCOUNTS.EMAIL&PASSWORD.EMAIL' | translate}}: <span class="mb-0 bold">{{profileToEdit.email}}</span></h4> 
                </div>
                <div style="margin-top: 5px;">
                  <h4 *ngIf="profileToEdit.accountType == 'patient'" class="card-title">{{'ACCOUNTS.EMAIL&PASSWORD.TYPEOFACCOUNT' | translate}}: <span class="mb-0 bold">{{'ACCOUNTS.EMAIL&PASSWORD.PATIENT' | translate}}</span></h4>
                  <h4 *ngIf="profileToEdit.accountType == 'practitioner'" class="card-title">{{'ACCOUNTS.EMAIL&PASSWORD.TYPEOFACCOUNT' | translate}}: <span class="mb-0 bold">{{'ACCOUNTS.EMAIL&PASSWORD.PRACTITIONER' | translate}}</span></h4>
                  <h4 *ngIf="profileToEdit.accountType == 'admin'" class="card-title">{{'ACCOUNTS.EMAIL&PASSWORD.TYPEOFACCOUNT' | translate}}: <span class="mb-0 bold">{{'ACCOUNTS.EMAIL&PASSWORD.ADMIN' | translate}}</span></h4>
                  
                  <h4 *ngIf="profileToEdit.accountType == 'practitioner'" class="card-title">{{'ACCOUNTS.EMAIL&PASSWORD.TYPEOFPRACTITIONER' | translate}}</h4>  
                  <div *ngIf="profileToEdit.accountType == 'practitioner'" class="form-group row">
                    <div class="col-md-10">
                      <ng-select disabled [(ngModel)]="profileToEdit.typeOfPractitioner && profileToEdit.typeOfPractitioner[0]" id="typeOfPractitioner">
                        <ng-option value="Doctor">{{'ACCOUNTS.EMAIL&PASSWORD.DOCTOR' | translate}}</ng-option>
                        <ng-option value="Psychologist">{{'ACCOUNTS.EMAIL&PASSWORD.PSYCHOLOGIST' | translate}}</ng-option>
                        <ng-option value="Pharmacist">{{'ACCOUNTS.EMAIL&PASSWORD.PHARMACIST' | translate}}</ng-option>
                      </ng-select>
                    </div>
                  </div>  
                </div>                           
                <div style="margin-top: 5px;">
                  <h4 class="card-title">{{'ACCOUNTS.ACD' | translate}}: <span class="m-0 bold" *ngIf="profileToEdit.metadata">{{profileToEdit.metadata.creationTime | date:'medium':'':currentLang}}
                  </span></h4>
                </div>
                <div style="margin-top: 5px;">
                  <h4 class="card-title">{{'ACCOUNTS.LSID' | translate}}: <span class="m-0 bold" *ngIf="profileToEdit.metadata">{{profileToEdit.metadata.lastSignInTime  | date:'medium':'':currentLang}}
                    </span></h4>
                </div>
                <div style="margin-top: 5px;" *ngIf="profileToEdit.accountType == 'practitioner' && practitionerNPI">
                  <h4 class="card-title">{{'CONSULT-PRESCRIPTIONS.NPI' | translate}}: <span class="m-0 bold">{{practitionerNPI.NPI}}
                    </span></h4>
                  <div>
                    <h5 style="text-align: start;">Taxonomies</h5>
                    <div *ngFor="let taxonomy of practitionerNPI.taxonomies">
                      <h6 style="text-align: start;" class="ml-2">{{taxonomy.code}} - {{taxonomy.desc}}</h6>
                    </div>
                  </div>
                  <button type="button" style="display: flex; font-size: 16px !important; padding: 0 0.75rem !important;" class="btn btn-primary btn-sm cardBtn adminBtn mb-1" (click)="searchRegistry()">
                    Update NPI
                  </button>
                </div>
                <div class="d-flex" style="margin-top: 5px;">
                  <h4 class="card-title">{{'ACCOUNTS.VERIFIED' | translate}}: </h4>
                  <div class="ml-2">
                    <div class="badge badge-danger font-size-12" style="text-transform: capitalize;" *ngIf="!profileToEdit.emailVerified">{{'ACCOUNTS.NO' | translate}}</div>
                    <div class="badge badge-success font-size-12" style="text-transform: capitalize;" *ngIf="profileToEdit.emailVerified">{{'ACCOUNTS.YES' | translate}}</div>
                  </div>
                </div>
                <div>
                  <button type="button" style="display: flex; font-size: 16px !important; padding: 0 0.75rem !important;" class="btn btn-cyan btn-sm cardBtn adminBtn mb-1" *ngIf="profileToEdit && !this.profileToEdit?.isAccountDisabled && !this.profileToEdit?.emailVerified && !sendingEmailLoading" (click)="resendVerificationEmail(profileToEdit)">
                    {{'EMAILVERIFY.RESEND' | translate}}
                  </button>
                  <button type="button" style="display: flex; font-size: 16px !important; padding: 0 0.75rem !important;" class="btn btn-cyan btn-sm cardBtn adminBtn mb-1" *ngIf="profileToEdit && !this.profileToEdit?.isAccountDisabled && !this.profileToEdit?.emailVerified && sendingEmailLoading">
                    {{'EMAILVERIFY.SENDINGEMAIL' | translate}}
                  </button>
                </div>
                <div class="d-flex" style="margin-top: 5px;">
                  <h4 class="card-title m-0">{{'ACCOUNTS.DISABLED' | translate}}: </h4>
                  <div class="ml-2">
                    <div class="badge badge-success font-size-12" style="text-transform: capitalize;" *ngIf="!profileToEdit.disabled">{{'ACCOUNTS.NO' | translate}}</div>
                    <div class="badge badge-danger font-size-12" style="text-transform: capitalize;" *ngIf="profileToEdit.disabled">{{'ACCOUNTS.YES' | translate}}</div>
                  </div>
                </div>  
                <div>
                  <button type="button" style="display: flex; font-size: 16px !important; padding: 0 0.75rem !important;" class="btn btn-primary btn-sm cardBtn adminBtn mb-1" *ngIf="profileToEdit && !this.profileToEdit?.isAccountDisabled" (click)="disable(true)">
                    {{'ACCOUNTS.DISABLEACCOUNT' | translate}}
                  </button>
                  <button type="button" style="display: flex; font-size: 16px !important; padding: 0 0.75rem !important;" class="btn btn-primary btn-sm cardBtn adminBtn mb-1" *ngIf="profileToEdit && this.profileToEdit?.isAccountDisabled" (click)="disable(false)">
                    {{'ACCOUNTS.ENABLEACCOUNT' | translate}}
                  </button>
                </div>        
                <div>
                  <div style="margin-top: 5px;">
                    <h4 class="card-title">{{'ACCOUNTS.LASTVERIFIEDBY' | translate}} <span class="mb-0 bold">{{verifiedEmail}}</span></h4>              
                  </div>
                  <div style="margin-top: 5px;">
                    <h4 class="card-title">{{'ACCOUNTS.LASTVERIFIEDDATE' | translate}} <span *ngIf="profileToEdit && profileToEdit.verifiedDate" class="mb-0 bold">{{profileToEdit.verifiedDate?.toDate() | date: 'MMMM dd, yyyy h:mma zzzz':'':currentLang  | titlecase }}</span></h4>                              
                  </div> 
                  <div style="margin-top: 5px;">
                    <button class="btn btn-cyan mb-4" style="font-size: 16px !important; padding: 0 0.75rem !important;" (click)="showAlertMsg(profileToEdit)">{{'ACCOUNTS.NGBTOOLTIP.RESETPASSWORD' | translate}}</button>
                  </div>
                </div>
              </div>
              <button type="button" style="margin-right: auto; margin-left: auto; display: flex; font-size: 16px;" class="btn btn-primary btn-sm cardBtn adminBtn mt-2" *ngIf="profileToEdit && this.profileToEdit?.status == 'unverified' || this.profileToEdit?.status == undefined || this.profileToEdit?.status == 'none'" (click)="accountStatus('Verified')">
                {{'PROFILE.VERIFY' | translate}}
              </button>
              <button type="button" style="margin-right: auto; margin-left: auto; display: flex; font-size: 16px;" class="btn btn-primary btn-sm cardBtn adminBtn mt-2" *ngIf="profileToEdit && this.profileToEdit?.status == 'Verified'" (click)="accountStatus('unverified')">
                {{'PROFILE.UNVERIFY' | translate}}
              </button>
            </div>
          </ng-template>
        </li> -->
        
        <!-- <li *ngIf="!createAccount && profileToEdit?.accountType == 'patient'" [ngbNavItem]="6">
          <a ngbNavLink (click)="navigation = 6">
            <span>{{'MENUITEMS.PRACTITIONERSCONSULTS.TEXT' | translate}}</span>
          </a>
          <ng-template ngbNavContent *ngIf="profileToEdit">
            <app-consultations [patientUid]="profileToEdit.uid"></app-consultations>
          </ng-template>
        </li> -->
        <li *ngIf="!createAccount && profileToEdit?.accountType == 'patient'" [ngbNavItem]="7">
          <a ngbNavLink (click)="navigation = 7">
            <span>{{'ORDERS.ORDERS' | translate}}</span>
          </a>
          <ng-template ngbNavContent *ngIf="profileToEdit">
            <app-orders [patientUid]="profileToEdit.uid"></app-orders>
          </ng-template>
        </li>
        <!-- <li *ngIf="!createAccount && profileToEdit?.accountType == 'patient'" [ngbNavItem]="8">
          <a ngbNavLink (click)="navigation = 7">
            <span>{{'SUBSCRIPTIONS.SUBSCRIPTIONS' | translate}}</span>
          </a>
          <ng-template ngbNavContent *ngIf="profileToEdit">
            <app-subscriptions [patientUid]="profileToEdit.uid"></app-subscriptions>
          </ng-template>
        </li> -->
        <!-- <li *ngIf="!createAccount && profileToEdit?.accountType == 'patient'" [ngbNavItem]="9"> -->
        <!-- <li *ngIf="!createAccount" [ngbNavItem]="8">

          <a ngbNavLink (click)="navigation = 8; getLabsDocuments()">
            <span>Laboratories Documents</span>
          </a>
          <ng-template ngbNavContent *ngIf="profileToEdit">
            <div *ngIf="!showLabs" class="labs-list">
              <table class="table">
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Date Uploaded</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr id="row-{{lab.id}}" *ngFor="let lab of labs" (click)="addLabs(lab)">
                    <td class="w-50" [ngClass]="{'unavailable': lab.labCode == ''}"> <img class="pdf-logo" src="../../../../assets/icons/pdf-logo.png" alt=""> {{ lab.fileName }}</td>
                    <td class="w-50" [ngClass]="{'unavailable': lab.uploadedAt == ''}">{{ lab.uploadedAt.toDate() | date:"medium" }}</td>
                    <td class="w-50"><a href="{{lab.downloadUrl}}" target="_blank">View PDF</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <i *ngIf="showLabs" class="fa fa-chevron-left" (click)="showLabs = false"></i>
            <app-labs-manager *ngIf="showLabs" [documentRef]="labDocument"></app-labs-manager>
          </ng-template>
        </li> -->
        <li *ngIf="!createAccount && profileToEdit?.accountType == 'patient'" [ngbNavItem]="11">
          <a ngbNavLink (click)="navigation = 10">
            <span>{{'MENUITEMS.PRESCRIPTIONS.TEXT' | translate}}</span>
          </a>
          <ng-template ngbNavContent *ngIf="profileToEdit">
            <app-prescription-list [user]="profileToEdit"></app-prescription-list>
          </ng-template>
        </li>
        <li *ngIf="!createAccount && profileToEdit?.accountType == 'patient'" [ngbNavItem]="10">
          <a ngbNavLink (click)="navigation = 9">
            <span>External Prescriptions</span>
          </a>
          <ng-template ngbNavContent *ngIf="profileToEdit">
            <app-external-prescription [user]="profileToEdit" class="mt-2"></app-external-prescription>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="customNav"></div>
    </div>
    </div>
    <div *ngIf="createAccount" class="modal-footer">
      <button type="button" class="btn btn-info" (click)="onSubmit()">{{'ACCOUNTS.EMAIL&PASSWORD.CREATE' | translate}}</button>
    </div>
  </div>
<!-- </ng-template> -->

  <div *ngIf="manageClaims">
    <button type="button" class="close" style="font-size: 1.35rem !important;" (click)="manageClaims = false;" aria-hidden="true">×</button>
    <button type="button" class="btn btn-info" (click)="saveGroupClaims()" aria-hidden="true">save</button>
    <ng-select class="w-50" [(ngModel)]="group">
      <ng-option *ngFor="let g of customClaimGroups" [value]="g.id">{{g.id}}</ng-option>
    </ng-select>

    <div *ngIf="myClaims.devAdmin">
      <label for="devAdmin" class="container d-flex" (click)="addClaim($event, 'devAdmin')">
        <input id="devAdmin" type="checkbox" style="position: relative;" [ngModel]="getClaim('devAdmin')">
        <span class="checkmark" style="min-width: 1rem; width: 1rem; height: 1rem;top: 2px; position: relative;"></span>
        <h5 class="ml-2 mb-0 text-muted">Dev Admin</h5>            
      </label> 
      <label for="superAdmin" class="container d-flex" (click)="addClaim($event, 'superAdmin')">
        <input id="superAdmin" type="checkbox" style="position: relative;" [ngModel]="getClaim('superAdmin')">
        <span class="checkmark" style="min-width: 1rem; width: 1rem; height: 1rem;top: 2px; position: relative;"></span>
        <h5 class="ml-2 mb-0 text-muted">Super Admin</h5>            
      </label> 
      <label for="mainAdmin" class="container d-flex" (click)="addClaim($event, 'mainAdmin')">
        <input id="mainAdmin" type="checkbox" style="position: relative;" [ngModel]="getClaim('mainAdmin')">
        <span class="checkmark" style="min-width: 1rem; width: 1rem; height: 1rem;top: 2px; position: relative;"></span>
        <h5 class="ml-2 mb-0 text-muted">Regular Admin</h5>            
      </label> 
      <label for="testUsers" class="container d-flex" (click)="addClaim($event, 'testUsers')">
        <input id="testUsers" type="checkbox" style="position: relative;" [ngModel]="getClaim('testUsers')">
        <span class="checkmark" style="min-width: 1rem; width: 1rem; height: 1rem;top: 2px; position: relative;"></span>
        <h5 class="ml-2 mb-0 text-muted">Test User</h5>            
      </label> 
      <label for="questionAdmin" class="container d-flex" (click)="addClaim($event, 'questionAdmin')">
        <input id="questionAdmin" type="checkbox" style="position: relative;" [ngModel]="getClaim('questionAdmin')">
        <span class="checkmark" style="min-width: 1rem; width: 1rem; height: 1rem;top: 2px; position: relative;"></span>
        <h5 class="ml-2 mb-0 text-muted">Question Admin</h5>            
      </label> 
      <label for="analyticsViewer" class="container d-flex" (click)="addClaim($event, 'analyticsViewer')">
        <input id="analyticsViewer" type="checkbox" style="position: relative;" [ngModel]="getClaim('analyticsViewer')">
        <span class="checkmark" style="min-width: 1rem; width: 1rem; height: 1rem;top: 2px; position: relative;"></span>
        <h5 class="ml-2 mb-0 text-muted">Analytics Viewer</h5>            
      </label> 
    </div>

    <div *ngIf="collections">
      <div *ngFor="let c of collections" class="my-4 terms log text-center d-flex flex-column justify-content-center align-items-start">
        <label for="{{c}}'_create'" class="container d-flex" (click)="addClaim($event, c + '_create')">
          <input id="{{c}}'_create'" type="checkbox" style="position: relative;" [ngModel]="getClaim(c + '_create')">
          <span class="checkmark" style="min-width: 1rem; width: 1rem; height: 1rem;top: 2px; position: relative;"></span>
          <h5 class="ml-2 mb-0 text-muted">{{c}}_create</h5>            
        </label>      
        <label for="{{c}}'_update'" class="container d-flex" (click)="addClaim($event, c + '_update')">
          <input id="{{c}}'_update'" type="checkbox" style="position: relative;" [ngModel]="getClaim(c + '_update')">
          <span class="checkmark" style="min-width: 1rem; width: 1rem; height: 1rem;top: 2px; position: relative;"></span>
          <h5 class="ml-2 mb-0 text-muted">{{c}}_update</h5>
        </label>
        <label for="{{c}}'_read'" class="container d-flex" (click)="addClaim($event, c + '_read')">
          <input id="{{c}}'_read'" type="checkbox" style="position: relative;" [ngModel]="getClaim(c + '_read')">
          <span class="checkmark" style="min-width: 1rem; width: 1rem; height: 1rem;top: 2px; position: relative;"></span>
          <h5 class="ml-2 mb-0 text-muted">{{c}}_read</h5>
        </label>                     
      </div>
    </div>
  </div>
</div>


<ng-template #showPdf let-modal>
  <div>
    <ngx-extended-pdf-viewer *ngIf="pdf != undefined" [base64Src]="pdf" backgroundColor="#e8e8ef"
      [showZoomButtons]="true" [height]="'75vh'" [width]="'55vw'" [useBrowserLocale]="true"
      [sidebarVisible]="false" [showHandToolButton]="true" [zoom]="'50%'" style="width: 55vw;">
    </ngx-extended-pdf-viewer>
  </div>
  
<button *ngIf="pdf != undefined" style="margin-left: auto; margin-right: auto;" class="btn btn-info btn-sm cardBtn mt-2 mb-4" (click)="pdf = undefined; closePdf()">{{'ACCOUNTS.SWAL.CLOSE' | translate}}</button>
</ng-template>



<div *ngIf="loading" class="loading">
  <app-reload [message]="'RELOAD.CREATINGACCOUNT'"></app-reload>
</div>

<ng-template #choosePdf let-modal>
  <div class="p-2 d-flex flex-column">
    <div class="justify-content-end">
      <button type="button" class="close float-right modal-close" style="font-size: xx-large; font-size: 1.35rem !important;" (click)="modal.close()" aria-hidden="true">×</button>
    </div>
    <div class="d-flex" *ngFor="let label of pdfsLabel">
      <div class="custom-control custom-radio mb-2">
        <input type="radio" class="custom-control-input" id="{{label}}" (change)="description = label" [checked]="description == label">
        <label class="custom-control-label" for="{{label}}">{{label | translation:currentLang}}</label>
      </div>
    </div>
    <button (click)="pdfInput.click()" class="btn btn-primary py-1 m-0 mb-2" style="color: white; width: fit-content;">
      {{'CONSULTATIONS.UPLOADPDFFILE' | translate}}
    </button>
    <input type="file" id="pdf-file-upload" hidden accept=".pdf" capture="environment" #pdfInput (change)="pdfToSave = $event">   
    <p *ngIf="pdfToSave">Pdf Selected: {{pdfToSave.target.files[0].name}}</p>
    <div class="d-flex flex-row mb-2" style="align-items: flex-end;">
      <p class="m-0">{{'PROFILE.EXPDATE'| translate}}:&nbsp;</p>
      <label for="expDate" class="inp m-0">
        <input  style="height: 30px;" class="p-0" type="date" id="expDate" value="{{expDate}}" name="expDate" placeholder="{{'PROFILE.EXPDATE' | translate}}" [(ngModel)]="expDate" required>
      </label>
    </div>
    <button class="btn btn-cyan mx-auto" style="width: fit-content;" (click)="pdfSelected(pdfToSave); modal.close()" [disabled]="pdfToSave == '' || expDate == '' || description == ''">{{'ADDRESS.SAVE' | translate}}</button>
  </div>
</ng-template>


<ng-template #createExternalPatientModal let-modal>
  <div class="d-flex w-100 p-2 justify-content-end">
    <svg (click)="modal.close()" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 6 6 18"></path>
      <path d="m6 6 12 12"></path>
    </svg>
  </div>
  <app-external-patient-form (close)="modal.close()"></app-external-patient-form>
</ng-template>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a-restructure-component',
  templateUrl: './a-restructure-component.component.html',
  styleUrls: ['./a-restructure-component.component.scss']
})
export class ARestructureComponentComponent implements OnInit {
  ngOnInit(): void {
  }
}
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { MedicalHistoryService } from 'src/app/core/services/medical-history/medical-history.service';

@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.scss', './cards.scss']
})
export class MedicalHistoryComponent {
  @Input() patientProfile:any;
  @Input() onboarding:boolean = false;

  @Output() completedOnboarding = new EventEmitter<boolean>();

  questions: any[] = [];
  currentQuestion: any;
  currentLang: string = "en";
  currentQuestionIndex: number = 0;
  sequenceCounter: number = 0;

  userMedicalHistory: any[] = [];

  heigth: number = 0;
  inches: number = 0;

  medicines:any = [];
  loading: boolean = false;

  filteredSurgeries:any = [];
  filteredConditions:any = []
  tempAnswered: boolean;

  multipleAnswer =  new Set();

  completed: boolean = false;

  constructor(
    private http: HttpClient,
    private db: AngularFirestore,
    private firebaseService: FirebaseService,
    private authService: AuthenticationService,
    private medicalHistoryService: MedicalHistoryService,
  ) { }

  async ngOnInit() {
    if(this.patientProfile == undefined){
      this.patientProfile = await this.authService.profile();
    }
    this.currentLang = this.patientProfile.preferedLang;
    await this.getMedicalHistory(this.patientProfile.uid);
    await this.getMedicalHistoryQuestions();

  }


  async getMedicalHistory(uid:string) {
    (await this.medicalHistoryService.getMedicalHistory(uid)).subscribe((data) => {
      this.userMedicalHistory = data;
    });
  }
  async getMedicalHistoryQuestions() {
    (await this.medicalHistoryService.getMedicalHistoryQuestions()).subscribe((data) => {
      this.questions = data;
      this.currentQuestion = this.questions[this.currentQuestionIndex];
      this.userMedicalHistory.forEach((element, index) => {
        this.questions[index].answer = element.answerHistory[element.answerHistory.length - 1].answer ? element.answerHistory[element.answerHistory.length - 1].answer : element.answerHistory[element.answerHistory.length - 1].optionAnswer;
        this.questions[index].optionAnswer = element.answerHistory[element.answerHistory.length - 1].optionAnswer;
        this.questions[index].explain = element.answerHistory[element.answerHistory.length - 1].explain;
      });
    });
  }


  next(){

    if(this.currentQuestionIndex == this.questions.length - 1){
      this.currentQuestionIndex++;
      if(this.onboarding){
        this.completedOnboarding.emit(true);
      }
    }
    
    if(this.currentQuestionIndex < this.questions.length - 1) {
      this.currentQuestionIndex++;
      this.currentQuestion = this.questions[this.currentQuestionIndex];
      // this.saveAnswers(this.currentQuestion);
    }
    
    if(this.currentQuestionIndex == this.questions.length){
      this.completed = true;
    }
  }

  back(){
    // let saved = this.saveAnswers(this.currentQuestion);
    // if(saved){
      if(this.currentQuestionIndex == 0)
        return 

      if(!(this.currentQuestionIndex <= 0)) {
        this.currentQuestionIndex--;
        this.currentQuestion = this.questions[this.currentQuestionIndex];
      }
    // }
  }


  saveAnswers(question){
    // let history = this.userMedicalHistory[this.currentQuestionIndex - 1].answerHistory;
    let history = this.userMedicalHistory[this.currentQuestionIndex].answerHistory;
    if(question.type != "Multiple" && question.type != "SearchMedicines" && question.type != "SearchConditions" && question.type != "SearchSurgeries"){
      let answer = {
        Date: new Date(),
        answer: question.answer,
        consultId: "",
        consultType: "",
        optionAnswer: question?.optionAnswer ? question.optionAnswer : "",
        explain: question?.explain ? question.explain : "",
      }
      // if(question.type == "BMI"){
      //   let bmicalc = this.medicalHistoryService.calculateBMI(Number(question.answer[0]), Number(question.answer[1]), Number(question.answer[2]));
      //   // answer.answer.push(bmicalc)
      // }
      history.push(answer);
      this.db.collection("users").doc(this.patientProfile.uid).collection("medicalHistory").doc(question.medicalHistoryId).update({answerHistory: history});
    }else{
      let answer = {
        Date: new Date(),
        answer: this.multipleAnswer.size > 0 ? Array.from(new Set(this.multipleAnswer)) : this.userMedicalHistory[this.currentQuestionIndex].answerHistory[this.userMedicalHistory[this.currentQuestionIndex].answerHistory.length - 1]?.answer,
        consultId: "",
        consultType: "",
        optionAnswer: question?.optionAnswer ? question.optionAnswer : "",
        explain: question?.explain ? question.explain : "",
      }
      history.push(answer);
      this.db.collection("users").doc(this.patientProfile.uid).collection("medicalHistory").doc(question.medicalHistoryId).update({answerHistory: history});
      this.multipleAnswer = new Set();
    }

    if(question.type == "SearchConditions" || question.type == "SearchSurgeries"){
      let answer =  {
        Date: new Date(),
        answer: "",
        consultId: "",
        consultType: "",
        optionAnswer: "",
        explain: question?.explain ? question.explain : "",
      }
      if(!question.answer.includes(question.optionAnswer)){
        answer.answer = this.multipleAnswer.size > 0 ? Array.from(new Set(this.multipleAnswer)) : this.userMedicalHistory[this.currentQuestionIndex].answerHistory[this.userMedicalHistory[this.currentQuestionIndex].answerHistory.length - 1].answer;
      }else{
        answer.optionAnswer = question.answer;
      }
      history.push(answer);
      this.db.collection("users").doc(this.patientProfile.uid).collection("medicalHistory").doc(question.medicalHistoryId).update({answerHistory: history});
      this.multipleAnswer = new Set();

    }
  }


  saveMultipleAnswer($event, option, question){
    if($event.target.checked){
      this.multipleAnswer.add(option);
      // if(!this.multipleAnswer.has(option)){
      //   this.multipleAnswer.add(option);
      // }else{
      //   this.multipleAnswer.delete(option);
      // }
    }else{
      this.multipleAnswer.delete(option);
    }
    
    
    
    this.currentQuestion.answer = Array.from(this.multipleAnswer); // Convert Set to Array
    this.questions[this.currentQuestionIndex].answer = Array.from(this.multipleAnswer); // Convert Set to Array
    this.currentQuestion.answer = Array.from(this.multipleAnswer); // Convert Set to Array

   
    // question.answer = this.multipleAnswer;
  }



  searchSurgeries($event){
    this.loading = true;
    this.filteredSurgeries = []
    this.http.get<any>("https://clinicaltables.nlm.nih.gov/api/procedures/v3/search?terms="+$event.term).subscribe(response =>{
      for(let i = 0; i < 15; i++){
          this.filteredSurgeries = response[3];
          this.loading = false;
        }
    }, error => {
    });
  }

  saveMeds($event:any, question:any){
    let data = {
      answer: [],
      answered: false,
      rxcui: [],
      optionAnswer: ''
    }
    this.questions[this.currentQuestionIndex].answered = false;

    let arr = [];
    let rxcui = [];

    if(question.type === "SearchMedicines"){
      for (let i = 0; i < $event.length; i++) {
        if($event[i] !== undefined){
          if($event[i].name != undefined){
            arr.push($event[i].name);
            rxcui.push($event[i].rxcui);
          }
          else {
            arr.push($event[i])
          }
          data.answer = arr;
          data.rxcui = rxcui;
          
          
          data.answered = true;
          this.questions[this.currentQuestionIndex].answered = true;
        }
        else{
          let index = arr.indexOf($event[i].name);
          if(index > -1){
            arr.splice(index, 1);
            data.answer = arr;
            data.rxcui = rxcui;
          }
        }
      }
    }

    if(question.medicalHistory){
      let newQuestion = {
        medicalHistoryId: question.medicalHistoryId,
        question: question.question,
        answerHistory: [{answer: data.answer, rxcui: data.rxcui, Date: new Date(), optionAnswer: '', consultId: "", consultType: "Onboarding"}]
      }
      
      this.db.collection("users").doc(this.patientProfile.uid).collection("medicalHistory").doc(question.medicalHistoryId).set(newQuestion);
    }
    
  
  }

  Rx_getConditions($event){
    this.loading = true;
    this.filteredConditions = []
    this.http.get<any>("https://clinicaltables.nlm.nih.gov/api/conditions/v3/search?terms="+$event.term+'&df=primary_name').subscribe(response =>{
    for(let i = 0; i < 15; i++){
      this.filteredConditions = response[3];
      this.loading = false;
    }
    }, error => {
    });
  } 

  onConditionSelected($event:any, question:any){

    let data = {
      answer: [],
      answered: false,
    }
    this.questions[this.currentQuestionIndex].answered = false;

    let obj = {
      points: 0
    }
    let arr = [];


    if(question.type == "BMI"){
      let element1 = <HTMLInputElement> document.getElementById("feet");
      let element2 = <HTMLInputElement> document.getElementById("inches");
      let element3 = <HTMLInputElement> document.getElementById("pounds");
      if(element1.value != '' && element2.value != '' && element3.value != '' && Number(element1.value) != 0 && Number(element3.value) != 0){  
        let BMICalc: any = (Number(element3.value) * 703)/(((Number(element1.value) * 12) + Number(element2.value)) * ((Number(element1.value) * 12) + Number(element2.value)))
            BMICalc = Number(BMICalc.toFixed(1))

        if(BMICalc < 18.5){
          BMICalc = '<en>' + BMICalc + ' - Underweight</en><es>' + BMICalc + ' - Bajo Peso</es>'
        }
        if(BMICalc >= 18.5 && BMICalc <= 24.9){
          BMICalc = '<en>' + BMICalc + ' - Normal Weight</en><es>' + BMICalc + ' - Peso Normal</es>'
        }
        if(BMICalc >= 25 && BMICalc <= 29.9 ){
          BMICalc = '<en>' + BMICalc + ' - Overweight</en><es>' + BMICalc + ' - Sobre Peso</es>'
        }
        if(BMICalc >= 30){
          BMICalc = '<en>' + BMICalc + ' - Obese</en><es>' + BMICalc + ' - Obeso</es>'
        }
        if(BMICalc >= 35){
          obj.points = 10
          data = Object.assign(data, obj);
        }
        if(BMICalc < 35){
          obj.points = 0
          data = Object.assign(data, obj);
        }
        
        data.answer.push(Number(element1.value));
        data.answer.push(Number(element2.value));
        data.answer.push(Number(element3.value));
        data.answer.push(BMICalc)
        data.answered = true;
        // this.question.answered = true;

        if(question.medicalHistory){
          let newQuestion = {
            medicalHistoryId: question.medicalHistoryId,
            question: question.question,
            answerHistory: [{answer: data.answer, Date: new Date(), consultId: "", consultType: ""}]
          }
          for(let i in this.userMedicalHistory){
            if(this.userMedicalHistory[i].medicalHistoryId == question.medicalHistoryId && this.userMedicalHistory[i].answerHistory){
              this.userMedicalHistory[i].answerHistory.push({answer: data.answer, Date: new Date(), consultId: "", consultType: ""})
              newQuestion.answerHistory = this.userMedicalHistory[i].answerHistory
            }
          }
          this.db.collection("users").doc(this.patientProfile.uid).collection("medicalHistory").doc(question.medicalHistoryId).set(newQuestion);
        }

        // this.firebaseService.updateAnswers(question.id, this.firebaseService.currentDocument, data, this.patientProfile).then(
        //   res => { 
            this.questions = Object.assign(question, data)
            let BMI = { feet: Number(element1.value), inches: Number(element2.value),pounds: Number(element3.value), BMI: BMICalc}
            this.db.collection("users").doc(this.patientProfile.uid).update({BMI: BMI})
        //   }
        // )    
      }     
      else {
        data.answered = false;
        this.questions[this.currentQuestionIndex].answered = false;
      }
    }
  }



  getNDC($event){
    this.loading = true;
    this.medicines = [];
    let arr:any[] = [];

    if ($event.target.value !== undefined && $event.target.value !== "") {
      this.http.get<any>("https://rxnav.nlm.nih.gov/REST/approximateTerm?term=" + $event.target.value + "&maxEntries=10").subscribe(response => {
        if (!response.approximateGroup.candidate) {
          this.loading = false;
          return;
        }
        const filteredRxcui = response.approximateGroup.candidate
          .map(candidate => candidate.rxcui)
          .filter(this.onlyUnique)
          .filter(value => value !== undefined && value !== "");

        filteredRxcui.forEach(rxcui => {
          this.http.get<any>("https://rxnav.nlm.nih.gov/REST/rxcui/"+rxcui).subscribe(res => {
            if(res.idGroup.name){
              res.idGroup = Object.assign(res.idGroup, {rxcui: rxcui});
              arr.push(res.idGroup);
              arr.forEach(item => {
                var i = this.medicines.findIndex(x => x.name == item.name);
                if(i <= -1){
                  this.medicines.push(item);
                }
              });
              this.loading = false;
            }
          });
        });
      });
    }
  }


  trackByFn(index, item) {
    return index;  
  }

  setChecked(option, question){ 
    // if(this.questions[this.currentQuestionIndex].answer.includes(option))
    if(question.answer.includes(option)){
      return true;
    }
    else
      return false;
  }

  setSubOptions(sub, currentQuestion){
    return sub;
  }

  saveSubOptions($event, currentQuestion){
    return $event.target.checked;
  }

  onlyUnique(value, index, self){
    return self.indexOf(value) === index;
  }

  tempAnswer(){
    let element1 = <HTMLInputElement> document.getElementById("feet");
    let element2 = <HTMLInputElement> document.getElementById("inches");
    let element3 = <HTMLInputElement> document.getElementById("pounds");
    if(element1.value != '' && element2.value != '' && element3.value != '' && Number(element1.value) != 0 && Number(element3.value) != 0){
      this.tempAnswered = true
    } else {
      this.tempAnswered = false
    }
  }


  saveConditions($event, question){
    for(let i = 0; i < $event.length; i++){
      this.multipleAnswer.add($event[i][0]);
    }
    // this.multipleAnswer.add($event);

  }
}

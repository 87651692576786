import { Injectable } from '@angular/core';
import { AuthenticationService } from './auth.service';
import firebase from "firebase/compat/app";
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RegisterService {

  currentLang = this.translate.store.currentLang;
  subscription = new Subscription();
  swal

  constructor(
    public authService: AuthenticationService,
    public translate: TranslateService
  ) { }

  async googleSignUp(){
    let promise = new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      let profile = {
        email: "", 
        uid: "", 
        firstName: "", 
        lastName1: "", 
        source: "", 
        biologicalgender: "", 
        birthdate: "", 
        preferedLang: this.currentLang, 
        timezone: [],
        gender:"", 
      }
      
      if(user){
        if(user.isAnonymous){

          firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
          .then(() => {

            profile.uid = user.uid;
            let credential = new firebase.auth.GoogleAuthProvider();
            credential.setCustomParameters({
              prompt: 'select_account' // This prompts the user to select an account during sign-in if they are already signed in with multiple Google accounts
            });
    
            if(!window.location.host.includes('localhost')){
              firebase.auth().currentUser.linkWithRedirect(credential)
            }
            else {
              firebase.auth().currentUser.linkWithPopup(credential).then(async credentials => {
                profile.email = credentials.user.email;
                
                await this.authService.addNewUser(profile).then(res => {
                  if(res){
                    resolve(res)
                  }
                }).catch(err => {
                  throw err;
                });
              }).catch(error => {
                if(error.code == 'auth/popup-closed-by-user'){
                  resolve('popup closed')
                }
  
                if(error.code == "auth/credential-already-in-use" || error.code == "auth/email-already-in-use"){
                  this.authService.googleSignIn().then(async userCredential => {
                          
                    resolve(userCredential);
                  })
                  .catch(err => {
                    if(error.code == 'auth/popup-closed-by-user'){
                      resolve('popup closed');
                    }
  
                    if(err.code == 'auth/popup-blocked'){
                      // this.popupBlocked()
                      resolve('popupBlocked');
                    }
                  });
                }       
              });
            }
          });
 
        }else{
          resolve(user)
        }
      }
      else if(environment.defaultauth === "firebase" && !user){
        this.authService.googleSignIn().then(async userCredential =>  {
          // User is not enrolled with a second factor and is successfully signed in.
          // ...      
          resolve(userCredential);
        })
        .catch(error => {
          if(error.code == 'auth/popup-closed-by-user'){
            resolve('popup closed');
          }

          if (error.code == 'auth/multi-factor-auth-required') {
            resolve(error);
          }
        });
      }
    })
    return promise
  }

  async microsoftSignUp(){ 
    let promise = new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      let resolver;
      let selectedIndex = 0;
      let profile = {
        email: "", 
        uid: "", 
        firstName: "", 
        lastName1: "", 
        source: "", 
        biologicalgender: "", 
        birthdate: "", 
        preferedLang:this.currentLang, 
        timezone: [],
        gender:"", 
        finishedSignup: false
      }

      if(user){
        if(user.isAnonymous){
          profile.uid = user.uid;
          let credential = new firebase.auth.OAuthProvider('microsoft.com')
          firebase.auth().currentUser.linkWithPopup(credential).then(credentials => {
            profile.email = credentials.user.email;
            this.authService.addNewUser(profile).then(async res => {
              if(res){
                // const svg = document.getElementById('check')
                // svg.classList.add('progress')
                
                resolve(await this.authService.profile())
              }
            })
          }).catch(err => {
            // console.log('error', err)
            if(err.code == "auth/credential-already-in-use" || err.code == "auth/email-already-in-use"){
              this.authService.microsoftSignIn().then(async userCredential => {
              
                //new
                resolve(await this.authService.profile())
              }).catch(err => {
                if(err.code == 'auth/popup-blocked'){
                  // this.popupBlocked()
                  resolve('popupBlocked')
                }
              })
            }
          })     
        }else{
        }
      }
      else if (environment.defaultauth === 'firebase' && !user){
        this.authService.microsoftSignIn().then(async userCredential =>  {
          // User is not enrolled with a second factor and is successfully signed in.
          // ...

          //new
          resolve(await this.authService.profile())
        })
        .catch(error => {
          // if (error.code == 'auth/multi-factor-auth-required') {
          //   // this.steps = 8;
          //   // The user is a multi-factor user. Second factor challenge is required.
          //   resolver = error.resolver;
          //   this.resolver = error.resolver;
          //   this.resolve = true;

          //   this.phone = resolver.hints[selectedIndex].phoneNumber;
          //   // Ask user which second factor to use.
          //   if(resolver.hints[selectedIndex].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
          //     let phoneInfoOptions = {
          //       multiFactorHint: resolver.hints[selectedIndex],
          //       session: resolver.session,
          //     };
          //     let phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          //     //Send SMS verification code
          //     return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier)
          //       .then(verificationId => {
          //         // Ask user for the SMS verification code.
          //         this.verificationId = verificationId;
          //         // let cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
          //         // let multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
          //         // // Complete sign-in.
          //         // return resolver.resolveSignIn(multiFactorAssertion)
          //       })
          //       // .then(userCredential => {
          //       //   let login = false;
          //       //   // User successfully signed in with the second factor phone number.
          //       //   this.router.navigate[("/")]
          //       // });
          //   } else {
          //     // Unsupported second factor.
          //   }
          // }
        })
      }
    })
    return promise
  }

  async facebookSignUp(){
    let promise = new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      let profile = {
        email: "", 
        uid: "", 
        firstName: "", 
        lastName1: "", 
        source: "", 
        biologicalgender: "", 
        birthdate: "", 
        preferedLang: this.currentLang, 
        timezone: [],
        gender:"", 
      }
      
      if(user){
        if(user.isAnonymous){

          firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
          .then(() => {

            profile.uid = user.uid;
            let credential = new firebase.auth.FacebookAuthProvider();
            credential.setCustomParameters({
              prompt: 'select_account' // This prompts the user to select an account during sign-in if they are already signed in with multiple Google accounts
            });
    
            firebase.auth().currentUser.linkWithPopup(credential).then(async credentials => {
              profile.email = credentials.user.email;
              
              await this.authService.addNewUser(profile).then(res => {
                if(res){
                  resolve(res)
                }
              }).catch(err => {
                throw err;
              });
            }).catch(error => {
              if(error.code == 'auth/popup-closed-by-user'){
                resolve('popup closed')
              }

              if(error.code == "auth/credential-already-in-use" || error.code == "auth/email-already-in-use"){
                this.authService.facebookSignIn().then(async userCredential => {
                        
                  resolve(userCredential)
                }).catch(err => {
                  if(error.code == 'auth/popup-closed-by-user'){
                    resolve('popup closed')
                  }

                  if(err.code == 'auth/popup-blocked'){
                    // this.popupBlocked()
                    resolve('popupBlocked')
                  }
                })
              }       
            });

          });
 
        }else{
          resolve(user)
        }
      }
      else if(environment.defaultauth === "firebase" && !user){
        this.authService.facebookSignIn().then(async userCredential =>  {
          // User is not enrolled with a second factor and is successfully signed in.
          // ...      
          resolve(userCredential)
        })
        .catch(error => {
          if(error.code == 'auth/popup-closed-by-user'){
            resolve('popup closed')
          }

          if (error.code == 'auth/multi-factor-auth-required') {
            resolve(error)
          }

          // if (error.code == 'auth/multi-factor-auth-required') {
          //   // The user is a multi-factor user. Second factor challenge is required.
          //   resolver = error.resolver;
          //   this.resolver = error.resolver;
          //   this.resolve = true;
          //   this.phone = resolver.hints[selectedIndex].phoneNumber;
          //   // Ask user which second factor to use.
          //   if(resolver.hints[selectedIndex].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
          //     let phoneInfoOptions = {
          //       multiFactorHint: resolver.hints[selectedIndex],
          //       session: resolver.session,
          //     };
          //     let phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          //     //Send SMS verification code
          //     return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier)
          //       .then(verificationId => {
          //         // Ask user for the SMS verification code.
          //         this.verificationId = verificationId;
          //         // let cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
          //         // let multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
          //         // // Complete sign-in.
          //         // return resolver.resolveSignIn(multiFactorAssertion)
          //       })
          //       // .then(userCredential => {
          //       //   let login = false;
          //       //   // User successfully signed in with the second factor phone number.
          //       //   this.router.navigate[("/")]
          //       // });
          //   } else {
          //     // Unsupported second factor.
          //   }
          // }
          // else if (error.code == 'auth/credential-already-in-use') {
          //   this.errorHandler.showError(error.message)
          //   console.error("auth/credential-already-in-useauth/credential-already-in-useauth/credential-already-in-useauth/credential-already-in-use")
          // }
          // else if(error.code == 'auth/popup-blocked'){
          //   // this.popupBlocked()
          // }  
        });
      }
    })
    return promise
  }

  popupBlocked(){
    this.translate.get('POPUPS').subscribe((res: any) => {
      this.swal = res
    })

    Swal.fire({
      icon:"info",
      title: this.swal.POPUPSBLOCKED,
      text: this.swal.TEXT,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: this.swal.CLOSE
    }).then(res => {
      if(res.isDenied){
        // console.error("Res :::::> ", res)
      }
    })
  }
}
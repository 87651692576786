<div class="p-4">
  <h3>{{'SURVEY.TITLE' | translate}}</h3>
  <div>
    <div *ngFor="let question of surveyQuestions">
      <label class="bold">{{question.question | translation:currentLang}}</label>
      
      <div *ngIf="question.type == 'Selection' && question.icons && question.typeOfIcon == 'stars'">
        <label id="{{question.id}}" *ngFor="let options of question.options; let i = index">
          <input hidden type="radio" name="{{question.id}}" [value]="options" [(ngModel)]="question.answer"> 
            <i class="fas fa-star mx-1" style="font-size: large;" [ngClass]="{'fa-solid fillSurveyStar orange': question.answer != '' && i < question.answer, 'fa-light removeFillStar gray': i >= question.answer || question.answer == ''}"></i>
        </label>
      </div>
      <div *ngIf="question.type == 'Selection' && question.icons && question.typeOfIcon == 'emoji'">
        <label id="{{question.id}}" *ngFor="let options of question.options; let i = index" (click)="changeEmoji(options)">
          <input hidden type="radio" name="{{question.id}}" [value]="options" [(ngModel)]="question.answer"> 
            <i id="showEmoji-{{i}}" class="fas {{displayEmoji}} mx-1" style="font-size: large;" [ngClass]="{'fillSurveyStar': question.answer != '' && i < question.answer, 'removeFillStar': i >= question.answer || question.answer == ''}"></i>
          </label>
      </div>
      <div class="d-flex flex-column" *ngIf="question.type == 'Selection' && !question.icons">
        <label id="{{question.id}}" class="selected-label" *ngFor="let options of question.options; let i = index">
          <input hidden type="radio" name="{{question.id}}" [value]="options" [(ngModel)]="question.answer"> 
          <div class="answer-card">
            {{options | translation: currentLang}}
          </div>
        </label>
      </div>
      <div *ngIf="question.type == 'Text'">
        <label for="explain" class="w-100">
          <div class="answer-card mx-auto p-0" style="background-color: transparent;" >
            <textarea id="explain" class="w-100" [(ngModel)]="question.explain" style="min-height: 150px; min-width: fit-content;" (blur)="saveExplanation($event)" placeholder="{{question.hints | translation: currentLang}}"></textarea>
          </div>
        </label>
      </div>
    </div>
  </div>
  <div>
    <!-- <label class="ml-2 mb-0 text-muted" style="text-align: start;">¿Nos permite utilizar está información en nuestra sección de testimonios? Su opinión es importante para nosotros y queremos que nuestros pacientes sepan como en Dzeus marcamos la diferencia.</label>  -->
    <div class="mb-1 terms log d-flex justify-content-start align-items-start">
      <label for="acceptTerms" class="container">
        <input type="checkbox" [(ngModel)]="anonymousAnswer" id="acceptTerms" style="position: relative;">
        <span class="checkmark" style="min-width: 1rem; width: 1rem; height: 1rem;top: 3px; position: relative;"></span>
      </label>    
      <label class="ml-2 mb-0 text-muted" style="text-align: start;">{{'SURVEY.ANONYMOUS' | translate}}</label>                     
    </div>
  </div>
  <div>
    <!-- <label class="ml-2 mb-0 text-muted" style="text-align: start;">¿Nos permite utilizar está información en nuestra sección de testimonios? Su opinión es importante para nosotros y queremos que nuestros pacientes sepan como en Dzeus marcamos la diferencia.</label>  -->
    <div class="mb-4 terms log d-flex justify-content-start align-items-start">
      <label for="allowPublishSurvey" class="container">
        <input type="checkbox" [(ngModel)]="allowPublishSurvey" id="allowPublishSurvey" style="position: relative;">
        <span class="checkmark" style="min-width: 1rem; width: 1rem; height: 1rem;top: 3px; position: relative;"></span>
      </label>    
      <label class="ml-2 mb-0 text-muted" style="text-align: start;">{{'SURVEY.USEFEEDBACK' | translate}}</label>                     
    </div>
  </div>
  <div>
    <p class="font-size-12 text-muted">{{'SURVEY.YOUROPINIONISIMPORTANT' | translate}}</p>
  </div>
  <div class="d-flex flex-column mx-auto" style="width: fit-content;">
    <button class="btn btn-primary w-100" (click)="submitSurvey()" [disabled]="validateSurvey()">{{'VIEWCONSULTS.NOTES.SUBMIT' | translate}}</button>
    <button class="btn btn-light w-100 mt-2" (click)="answerLater()">{{'VIEWCONSULTS.NOTES.ANSWERLATER' | translate}}</button>
  </div>
</div>
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { Profile } from 'src/app/core/services/interfaces';
import { LaboratoriesService } from 'src/app/core/services/laboratories/laboratories.service';
import { PrescriptionService } from 'src/app/core/services/prescription.service';

@Component({
  selector: 'app-laboratories-dashboard',
  templateUrl: './laboratories-dashboard.component.html',
  styleUrls: ['./laboratories-dashboard.component.scss']
})
export class LaboratoriesDashboardComponent implements OnInit {
  @ViewChild('upload', { read: TemplateRef }) uploadModal:TemplateRef<any>;

  user: Profile;
  prescriptions: any[] = [];
  constructor(
    private modalService: NgbModal,
    private labsService: LaboratoriesService,
    private authService: AuthenticationService,
    private prescriptionService: PrescriptionService
  ) { }

  async ngOnInit() {
    this.user = await this.authService.profile();
    this.getPrescriptionsRef();
  }

  async getPrescriptionsRef(){
    let prescription_list = [];
    let _prescriptions = [];
    this.prescriptionService.getPatientLabsPrescriptions(this.user.uid).subscribe(async (prescriptions:any) => {
      prescription_list = prescriptions.filter(prescription => prescription.prescription_type == "Lab");

      _prescriptions = await Promise.all(
        prescription_list.map(async (p: any) => {
          const items = await this.prescriptionService.getPatientLabsPrescriptionsItems(p.ID);
          return { ...p, items };
        })
      );
      this.prescriptions = _prescriptions;
    });    

  
    
  }



  concatLabNames(items:any[]){
    return items.map(item => item.labName).join(", ");
  }


  async openModal(prescription){
    this.modalService.open(this.uploadModal, { size: 'lg', backdrop: 'static' });
    // this.prescriptionService.getPatientLabsPrescriptionsItems(prescription.ID).subscribe((items:any) => {
    //   prescription.items = items;
    // });
  }



}

import { Injectable, OnDestroy } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from "firebase/compat/app";
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { AuthenticationService } from './auth.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class ReferralService implements OnDestroy {

  practitioner: any;
  practitionerAddress: any;
  patient: any;
  patientAddress: any;
  consult: any;
  text: any;
  referralCondition;
  signature


  pdfMake: any;
  allSubscriptions = new Subscription()

  constructor(
    private afs: AngularFirestore,
    private authService: AuthenticationService,
    ) {
     }

  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe()
  }

  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }

  getDiagnostics(consult){
    let arr = [];
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("consultation").doc(consult).collection("Diagnostics").valueChanges().subscribe(async (data:any) => {
        data.forEach(diag => {
          let split = diag.code.split(" -")[0];
          arr.push(split);
        });
        let arrSent = arr.join(', ')
        resolve(arrSent);
      })
    });
  }

  async modifyPDF(referralId, patient, ref){
    return new Promise<any>(async (resolve, reject) => {
      let doc:any = await this.getReferralUrl(referralId);
      if(doc !== undefined){
        const url = doc.referralURL
        const pSignature:string = this.signature;
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const pngImage = await pdfDoc.embedPng(pSignature);
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        firstPage.drawText(`Digitally Sign by ${this.practitioner.firstName + ' ' + this.practitioner.lastName1} Date: ${new Date().toLocaleDateString()}`, {
          x: 310,
          y: 80,
          size: 10,
          font: helveticaFont,
          color: rgb(0.95, 0.1, 0.1),
        })
       
        firstPage.drawImage(pngImage, {
          x: 310,
          y: 85,
          width: 100,
          height: 40
        })
      
        const pdfB64 = await pdfDoc.saveAsBase64();
  
        let path = `users/${patient}`
        const storage = firebase.storage().ref(path);
        let imageRef = storage.child(`${ref}`);
        imageRef.putString(pdfB64, 'base64' ,{contentType: 'application/pdf'}).then((snapshot) => {
          snapshot.ref.getDownloadURL().then(res => {
            this.afs.collection("referrals").doc(referralId).update({
              referralURL:res, 
              z_base64:pdfB64,
            }).then(async () => {
              let udpdatedPrescription = await this.getDocument(referralId);
              resolve(udpdatedPrescription);
            })
          });
        }, err => {
        });
      }
    })
  }

  getReferralUrl(referralId){
    return new Promise<any>((resolve, reject) => {
      this.afs.firestore.collection("referrals").doc(referralId).get().then(async (data:any) => {
        if(data.data().referralURL){
          resolve(data.data());
        }else{
          reject(undefined);
        }
      })
    });
  }

  async getDocument(referralId){
    return new Promise<any>((resolve, reject) => {
      let sub = this.afs.collection("referrals").doc(referralId).valueChanges().pipe(take(1)).subscribe(async data => {
        resolve(await data);
      });
      this.allSubscriptions.add(sub)
    });
  }

  calculateAge(user){
    var date = new Date(user);
    var today = new Date();
    var timeDiff = Math.abs(today.getTime() - date.getTime());
    // var age1 = Math.ceil(timeDiff / (1000 * 3600 * 24)) / 365;
    var age1 = Math.floor(timeDiff / (1000 * 3600 * 24)) / 365.25;
    var age = Math.floor(age1);
    return age;
  }

  async generatePdf(signature?:any):Promise<any> {
    return new Promise<any>(async (resolve, reject) => {  

        let ID = Math.random().toString(36).substr(2, 9);
        const format = 'h:mm a';
        const locale = "en"
        let time = formatDate(new Date(), format, locale);  

        let NPI: any = await this.authService.getNPIRegistry(this.practitioner.uid);
        NPI.taxonomies = NPI.taxonomies.filter(tax => tax.state == this.consult.state.code);

        let diagnostics = await this.getDiagnostics(this.consult.ID)
        let License = NPI.taxonomies[0].license
        let LicenseState = NPI.taxonomies[0].state
        let referral_pdf = "referral-" + this.consult.ID+"-"+ID
        NPI = NPI.NPI

        let age = this.calculateAge(this.patient.birthdate)

        let rows: any[] = [];
        let tt: any;
        
        await this.loadPdfMaker();

        if(this.referralCondition.length > 99){
          let first = this.referralCondition.match(/.{1,100}(\s|$)/g)
          first.forEach(row => {
            rows.push([row]);
          });
        }else{
          rows.push([{text: this.referralCondition}]);
        }

        if(this.text.length > 99) {
          let first = this.text.match(/.{1,100}(\s|$)/g)
          first.forEach(row => {
            rows.push([row]);
          });
        } else{
          rows.push([{text: this.text}]);
        }

        if(rows.length !== 20){
          const itemsLength = rows.length;
          for(let i = 0; i < 20 - itemsLength; i++){
            rows.push([{text:"- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -", color:"#D3D3D3", fontSize:12}]);
          }
        }

        if(signature !== undefined){
          tt = {
            pageSize: 'A4',
            content: [{
              stack: [
                {text:'Medical Referral', bold: true, fontSize: 22, alignment: 'center'},
              ],
              },
              {
                alignment: 'right',
                text: [{ text: 'Dzeus Health', bold: true},
                  '\n',
                  {
                    text: `Address: `,
                    bold: true,
                  },
                  {text: `57 Calle Principal 
                          Barranquitas, PR 00794\n`},
                  {text:`Phone: `, bold: true},
                  {text: '787-333-0770, (939) 323-6279\n'},
                  {
                    width: "*",
                    text:[
                      {text:"Email: ", bold: true}, {text:`support@dzeus.com`},"\n",
                    ], 
                  },
                  {
                    text: `ID: `, bold: true,
                  },
                  {text: ID},                
                ],
              },
              '\n',
              {
                margin: [0, -11.5, 0, 0],
                columns: [{             
                text: [
                  {text: `Patient: `, bold: true},
                  {text:`${this.patient.firstName + ' ' + this.patient.lastName1}\n`}, 
                  {text: `DOB: `, bold: true}, 
                  {text:`${this.patient.birthdate} (${age}) years old`},"\n",
                  {text:"Email: ", bold: true}, 
                  {text:`${this.patient.email}`},"\n",
                  {text: diagnostics.length > 0 ? "Diagnostics: " : "", bold: true}, 
                  {text:`${diagnostics}`            
                }], 
              }], 
                layout: 'noBorders',
              },
              '\n',
              {
                text: [
                  { text: `Date: `, bold: true},
                  { text: `${new Date().toDateString() + " " + time}`}
                ]  
              },
              '\n',
              {
                text: 'Reason for Referral',
                bold: true,
                fontSize: '20',
              },
              '\n',
              {
                margin: [0, -5, 0, 0],
                table: {
                  headerRows: 1,
                  body: rows
                },
                layout: 'noBorders'
              },
              '\n',
              {
                margin: [0, -5, 0, 0],
                layout: 'noBorders',    
                table: {
                  fontSize:12,
                  body: [
                    [{
                      text: [
                        {text:`NPI: `, bold: true},{text:`${NPI}`,},'\n',
                        {text:`Licence Number: `, bold: true},{text:`${License}`,},'\n',
                        {text:`State: `, bold: true},  {text:`${LicenseState}`},'\n',  
                      ]
                    },
                    {
                      alignment: 'right',
                      columns: [{
                        text: "Physician Signature: \xa0",
                        bold: true,
                        margin: [0, 20, 0, 0],
                        width: 215,
                      },
                      {
                        image: signature,
                        width: 150
                      }],
                    }
                  ]],
                },
              },
            ]         
          }
        }

        tt.content[11].table.body[0][1].columns[1].margin = [0, 5, 0, 0];
        tt.content[12] = {
          alignment: "right",
          margin: [0, -10, 5, 0],
          text:[
                {text: 'Digitally Signed by: ' + this.practitioner.firstName + ' ' + this.practitioner.lastName1 + ' ',
                fontSize: 10,
                color: "red"
            },'\n',
            {
                text: 'Date: ' + new Date().toLocaleDateString(),
                fontSize: 10,
                color: "red"
            },'\n\n',
          ]
        }

      let final = this.pdfMake.createPdf(tt);
      if(final){
        let end = performance.now();       
      }

      final.getDataUrl((dataUrl) => {
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var expires = new Date(year + 1, month, day);
        let obj = {
          z_base64: dataUrl.split("base64,")[1],
          referralURL: "",
          date: d,
          expirationDate: expires,
          text: this.text,
          referralCondition: this.referralCondition,
          ID: ID,
          consultID: this.consult.ID,
          valid: true,
          patientUid: this.consult.UID_Patient,
          practitionerUid: this.consult.UID_Practitioner,
          signedDate: new Date()
        }

        //save pdf to storage to get download url => pass to ERP
        let path = `users/${this.patient.uid}`
        const storage = firebase.storage().ref(path);
        let imageRef = storage.child(`${referral_pdf}`);
        imageRef.putString(dataUrl, 'data_url' ,{contentType: 'application/pdf'}).then((snapshot) => {
          snapshot.ref.getDownloadURL().then(async (url) => {
            obj.referralURL = url;
            this.afs.collection("referrals").doc(ID).set(obj);
            if(signature !== undefined){
              resolve(obj);
            }else{
              resolve(dataUrl)
            }
          });
        }, err => {
          reject(err);
        });
      });
    });
  }
  
  async addWaterMark(referral, patient){
    const url = referral.referralURL;
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const { width, height } = firstPage.getSize()

    firstPage.drawText('INVALID!', {
      x: 425,
      y: height / 2 + 250,
      size: 25,
      font: helveticaFont,
      color: rgb(0.95, 0.1, 0.1),
    })

    const pdfBytes = await pdfDoc.save();
    const pdfB64 = await pdfDoc.saveAsBase64();

    let path = `users/${patient}`
    const storage = firebase.storage().ref(path);
    let imageRef = storage.child(`invalid-referral-${referral.ID}.pdf`);
    imageRef.putString(pdfB64, 'base64' ,{contentType: 'application/pdf'}).then((snapshot) => {
      snapshot.ref.getDownloadURL().then(res => {
        this.afs.collection("referrals").doc(referral.ID).update({referralProcessed:res, z_processedB64:pdfB64});
      });
      });
  }
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFilter'
})
export class DateFilterPipe implements PipeTransform {

  transform(invoices: any[], timestamp: number): any[] {
    if (!invoices || !timestamp) {
      return [];
    }

    const currentDate = new Date(timestamp);
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    return invoices.filter(invoice => {
      const invoiceDate = new Date(invoice.date); // Assuming your invoice object has a 'timestamp' property
      const invoiceMonth = invoiceDate.getMonth();
      const invoiceYear = invoiceDate.getFullYear();
      // return invoiceDate.getMonth() === currentMonth;
      return invoiceMonth === currentMonth && invoiceYear === currentYear;
    });
  }

}

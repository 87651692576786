import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { EventService } from '../../../core/services/event.service';

import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import 'firebase/firestore';


import { MENU } from './menu';
import firebase from "firebase/compat/app";
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/core/services/dashboard/dashboard.service';
import { AuthenticationService } from '../../../core/services/auth.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements AfterViewInit, OnDestroy {
  @Output() mobileMenuButtonClicked = new EventEmitter();

  menu: any;
  menuItems = MENU;

  subscription = new Subscription()
  public userData: any;
  currentUser: any;

  public emailVerified: Boolean = false;
  public claims: any;
  programmer = false;
  questionAdmin = false;

  orders:any[]= []

  @ViewChild('sideMenu') sideMenu: ElementRef;

  superAdmin = false;

  constructor(
    private eventService: EventService, 
    private router: Router,
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private dashboardService: DashboardService,
    private authService: AuthenticationService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
      }    
      if(router.url == '/telehealth'){
        this._activateMenuDropdown();
      }
    });

    let sub = this.afAuth.authState.subscribe(async user => {
      if (user) {
        this.userData = user;
        this.emailVerified = firebase.auth().currentUser.emailVerified;
        let sub = this.afs.collection('users').doc(`${user.uid}`).valueChanges().subscribe(async (data:any) =>{          
          if(!this.claims){
            this.currentUser = data;
            this.claims = await authService.getClaims();
            this.programmer = this.claims.devAdmin ? true : false;
            this.questionAdmin = this.claims.questionAdmin ? true : false;
            this.superAdmin = this.claims.superAdmin ? true : false;
            if(this.claims.accountType == 'patient'){
              this.orders = await this.dashboardService.getOrders(data);
              this.orders = this.orders.filter(x => x.paymentStatus !== "paid" && x.productType != 'service')
            }
          }
        });
        this.subscription.add(sub)
      } else {
        this.subscription.unsubscribe()
      }
    });
    this.subscription.add(sub)

  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  ngAfterViewInit() {
    this._activateMenuDropdown();
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;

    const paths = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      paths.push(links[i]['pathname']);
    }
    const itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }

    if (menuItemEl) {
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add('mm-active');

        const parent2El = parentEl.parentElement.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;

          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');

            if (childAnchor) { childAnchor.classList.add('mm-active'); }
            if (childDropdown) { childDropdown.classList.add('mm-active'); }

            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
              }
            }
          }
        }
      }
    }
  }

  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout: string) {
    this.eventService.broadcast('changeLayout', layout);
  }


  toggleMobileMenu() {
    this.mobileMenuButtonClicked.emit();
  }

  searchTypeOfPractitioner(item){
    return item.typeOfPractitioner?.length == 0 || item.typeOfPractitioner?.some(p => this.currentUser.typeOfPractitioner.includes(p))
  }
}
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LABORATORY_INFORMATION, LABORATORY_MISC, MEDICAL_ANALYSIS_TEMPLATE } from 'src/app/core/constants/laboratory';
import { LabInformation, LaboratoryBase, MedicalAnalysisTemplate, TestResult } from 'src/app/core/interfaces/laboratories';
import { LaboratoriesService } from 'src/app/core/services/laboratories/laboratories.service';

@Component({
  selector: 'app-labs-manager',
  templateUrl: './labs-manager.component.html',
  styleUrls: ['./labs-manager.component.scss']
})
export class LabsManagerComponent implements OnInit {
  @ViewChild('testResultForm', { read: TemplateRef }) formModal:TemplateRef<any>;
  @Input() documentRef:LaboratoryBase;

  pdf: SafeResourceUrl
  url: string = "https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/users%2FTHSmUV56a6eKLUdh590FuxmQn9X2%2FlabAnalysis%2Flabel%20(2).pdf?alt=media&token=1849322f-ce8f-485e-80f2-4dd91daf90a0";
  laboratories: any = [];
  selected_labs:any = {};
  results_template:any = [];

  test_results:TestResult[] = [];
  labInformation:LabInformation = LABORATORY_INFORMATION;

  lab_misc = LABORATORY_MISC;
  results = {...MEDICAL_ANALYSIS_TEMPLATE.testResults[0]};
  final_results:any = {};
  


  image = new Image();


  constructor(
    private db: AngularFirestore,
    private sanitizer: DomSanitizer,
    private labsService: LaboratoriesService,
    private modalService: NgbModal
  ) 
  {}

  ngOnInit(){
    this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.labsService.getLabs().subscribe(labs => this.laboratories = labs);
    this.labsService.getLabResults(this.documentRef.uid).subscribe(results => {
      this.test_results = results.map(result => result.testResults).flat();
      this.lab_misc.dateOfTest = results[0].dateOfTest;
      this.lab_misc.interpretation = results[0].interpretation;
      this.lab_misc.comments = results[0].comments;

      this.labInformation.name = results[0].name;
      this.labInformation.address = results[0].address;
      this.labInformation.contactNumber = results[0].contactNumber;
    });
  }


  handleLabSelection(lab) {
    this.selected_labs = lab;
    this.results_template.push(lab);
    this.modalService.open(this.formModal, {size: 'lg', backdrop:'static', windowClass: 'formModal'})
    // const labIndex = this.selected_labs.findIndex((selectedLab) => selectedLab.labName === lab.labName);
    // const allLabsIndex = this.laboratories.findIndex((selectedLab) => selectedLab.labName === lab.labName);
  
    // if (labIndex === -1) {
    //   this.selected_labs.push(lab);
    // } else {
    //   // Lab already included, so remove it
    //   this.selected_labs.splice(labIndex, 1);
    // }
  }


  addTestResultTemplate(){
   
  }

  saveUnitsOfMeasure(event:any){
    this.results.range.unit = event;
  }

  edit(result){
    this.results = result;
  }

  save(result, index?:number){
    let is = this.test_results.some(res => res.name == result.name);
    if(is){
      let i = this.test_results.findIndex(res => res.name == result.name);
      this.test_results[i] = result;
      this.results = {...MEDICAL_ANALYSIS_TEMPLATE.testResults[0]};
      return
    }
    this.test_results.push({...result})
    this.results = {...MEDICAL_ANALYSIS_TEMPLATE.testResults[0]};
  }

  submit(){
    // this.test_results.forEach((result, index) => {
    //   result.labInformation = this.labInformation;
    //   result.labName = this.selected_labs.labName;
    //   result.labCode = this.selected_labs.labCode;
    // });
    this.final_results = Object.assign(this.labInformation, this.lab_misc,{
        labName:this.selected_labs.labName, 
        labCode: this.selected_labs.labCode, 
        testResults: this.test_results
      })
    this.labsService.storeTestResults(this.final_results, this.documentRef)
  }



  dismiss(){
    this.modalService.dismissAll();
  }

}

import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Injectable } from '@angular/core';
import { TranslationPipe } from "src/app/translation.pipe";

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private afs: AngularFirestore) {}
  translationPipe = new TranslationPipe();

  public getProducts(type:any, consultRules){
    let condition = type.Type;
    return new Promise<any>((resolve, reject) => {
      this.afs.firestore.collection("All Products").get().then((snapshot:any) => {
        let products = snapshot.docs.map((product:any) => product.data()).filter((product:any) => product.productCondition.includes(condition) || condition == 'All');
        let noSelection = products.filter((product:any) => product.productId == 980);
        if(noSelection.length > 0){ 
          let productName = noSelection[0].productName;
          noSelection[0].productName = productName.replace('this condition', this.translationPipe.transform(consultRules?.conditionName, 'en')).replace('esta condición', this.translationPipe.transform(consultRules?.conditionName, 'es'));
          let noSelectionIndex = products.findIndex((product:any) => product.productId == 980);
          products[noSelectionIndex] = noSelection[0];
        }
        resolve(products);
      });
    });
  }

  public getServices(type:any){
    let condition = type.Type;
    return new Promise<any>((resolve, reject) => {
      this.afs.firestore.collection('All Services').get().then((snapshot:any) => {
        let services = snapshot.docs.map((service:any) => service.data()).filter((service:any) => service.productCondition.includes(condition));
  
        resolve(services);
      });
    });
  }

  public getShipping(type:any){
    let condition = type.Type;
    return new Promise<any>((resolve, reject) => {
      this.afs.firestore.collection('All Shippings').get().then((snapshot:any) => {
        let shipping = snapshot.docs.map((shipping:any) => shipping.data()).filter((shipping:any) => shipping.productCondition.includes(condition));
  
        resolve(shipping);
      });
    });
  }

  public getPromos(type: any){
    let condition = type.Type;
    return new Promise<any>((resolve, reject) => {
      this.afs.firestore.collection('All Promos').get().then((snapshot:any) => {
        let promos = snapshot.docs.map((promo:any) => promo.data()).filter((promo:any) => promo.productCondition.includes(condition));
  
        resolve(promos);
      });
    });
  }
}

export interface Product {
  productId
  odooProductId	
  productName: string;
  productBrandName: string;
  productBrandNameTranslation: string;
  productDosage: string;
  productDosageForm: string;
  productIsStandardDosage: boolean;
  productRxcui	
  productQty: number;
  productPrice;
  productMinPoint: number;
  productMaxPoint: number;
  productRefillQty: number;
  productRefillMinQty: number;
  productRefillIntervalDays: number;
  productDeliveryFrequencies	
  productOTC: boolean;
  productIsVisible: boolean;
  productRxInstruction: string;
  productImg: string;
  productType: string;
  productCondition;
  productStates;
  productInfo: string;
}

export interface Service {
  productId
  odooProductId
  productName: string;
  productPrice;
  productImg: string;
  productType: string;
  productDescription: string;
  productServiceType: string;
  productStates;
  productCondition;
}

export interface shipping {
  productId
  odooProductId
  productName: string;
  productPrice;
  productImg: string;
  productType: string;
  productStates;
  productCondition;
}
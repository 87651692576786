import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import firebase from "firebase/compat/app";
import { AuthenticationService } from './core/services/auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ReloadComponent } from './extrapages/reload/reload.component';
import { ConnectionService } from './core/services/connection.service';
import { filter } from 'rxjs/operators';
import { IdleService } from './core/services/idle.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');
import localeEn from '@angular/common/locales/en';
import { FirebaseService } from './core/services/firebase.service';
import { LanguageService } from './core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { CustomCookieService } from './core/services/customcookie.service';
import { getRedirectResult, getAuth } from 'firebase/auth';
registerLocaleData(localeEn, 'en');

// import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
// import { Keepalive } from '@ng-idle/keepalive';


declare const gtag: Function; // <------------Important: the declartion for gtag is required!
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  @ViewChild('authenticator', { read: TemplateRef }) authModal:TemplateRef<any>;
  @ViewChild('idle', { read: TemplateRef }) idleModal:TemplateRef<any>;
  modalRef:any; 

  title = 'Dzeus Health';
  recaptchaVerifier
  settings: any;

  offline: boolean = false;

  auth = getAuth();


  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  swal: any;

  claims:any = null;

  constructor(
    private authenticationService: AuthenticationService,
    public afs: AngularFirestore,
    private router: Router, 
    private idleService: IdleService,
    // private idle: Idle, 
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    public afAuth: AngularFireAuth,
    private firebaseService: FirebaseService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private customCookieService: CustomCookieService,
  ){
    let language = !this.translate.store.currentLang ? (localStorage.getItem('lang') || 'es') : this.translate.store.currentLang;
    this.languageService.setLanguage(language);
    // this.offline = true;
    window.addEventListener("offline", (event) => {
      this.offline = true;
    });
    
    window.addEventListener("online", (event) => {
      let text = document.getElementById("connection-text");
      let content = document.getElementById("content");
      let actionText = document.getElementById("action-text");
      // const btn = document.getElementById("refresh-btn");
      text.innerText = "Back online, Enjoy!";
      actionText.innerText = "Your internet connection has been restored. Please reload the page";
      content.style.animationName = "colorTransition"
      // btn.style.display = "block"
      // content.style.background = "#2bb334";
      
      setTimeout(() => {
        this.offline = false;
      }, 2000);
    });
    /** START : Code to Track Page View using gtag.js */
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      
      gtag('event', 'page_view', {
        page_path: "/" + event.urlAfterRedirects.split('/')[1]
      })
    })
 /** END : Code to Track Page View  using gtag.js */

    this.activatedRoute.queryParams.subscribe(params => {
      const ref = params['ref'];

      if (ref && cookieService.get('ref') != ref) {// && !this.cookieService.check('ref')
        this.setCookieWithAffiliateDate(ref); 
      }
    });
  }

  async setCookieWithAffiliateDate(ref: string) {
    try {
      const affiliateRef = ref.split("-")[0];
      const docRef = this.afs.collection('affiliates').doc(affiliateRef);
      const doc = await docRef.get().toPromise();

      if (doc.exists) {
        const affiliateData = doc.data();
        const affiliateDate = affiliateData['affiliatesCookieExpirationDate'];
        if (affiliateDate) {
          const expirationDays = affiliateDate; // Assuming AffiliateDate is in days
       
         // this.cookieService.set('ref', ref, options);
         const maxAge = expirationDays * 24 * 60 * 60; // Convert days to seconds
         document.cookie = `ref=${ref}; path=/; max-age=${maxAge}; samesite=Lax`;
          
        }
      } else {
        // console.log(`No matching affiliate found for ref: ${ref}`);
      }
    } catch (error) {
      // console.error('Error fetching affiliate data:', error);
    }
  }

  async ngAfterViewInit(): Promise<void> {
    let container = "recaptcha-container"; 
    // let test = new RecaptchaVerifier('recaptcha-container',{size: 'invisible'}, null)
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(container, {
      size: 'invisible'                    
     });
    // this.recaptchaVerifier = test
    this.recaptchaVerifier.render();

    this.authenticationService.setRecaptcha(this.recaptchaVerifier)

    this.afs.collection('settings').doc('maintenance').valueChanges().subscribe(async (res: any) => {
      if(this.settings?.maintenance && res?.maintenance != this.settings.maintenance){
        let currentURL = this.router.url
        ReloadComponent.loader = 'RELOAD.UPDATING'
        await this.router.navigateByUrl('/account/reload', { skipLocationChange: true });
        await setTimeout(async () => {
          ReloadComponent.loader = ''
          return await this.router.navigateByUrl(currentURL).then(() => {
            window.location.reload();
          });
        },500)
      }
      this.settings = res;
    })
  }

  async ngOnInit(){
    let browser = this.getBrowserName();
    this.authenticationService.setBrowser(browser);

    await getRedirectResult(this.auth).then(async (result) => {
    }).catch((error) => {
      if (error.code === 'auth/credential-already-in-use') {
        // Handle 'credential-already-in-use' error
        this.authenticationService.credentialSignIn(error);
      }
    });

    this.afAuth.authState.subscribe(user => {
      if(user && !user.isAnonymous && user.email){        
        this.customCookieService.setCookie(user)
        this.authenticationService.continueAfterRedirect(user)
      }
    })
    
    let timer = setInterval(async () => {
      if(this.claims !== null && this.claims.accountType){
        this.afAuth.authState.subscribe(async user => {
          if(user && !user.isAnonymous){
            if(!this.claims.devAdmin){  
              this.cd.detectChanges();
              this.idleService.reset();
              
              this.idleService.notification.subscribe(bool => { 
                if(bool){
                  this.triggerIdleNotification()
                }else{
                  this.reset();
                }
              });
            }
            else {
              // console.warn(browser)
            }
          }
        })
        clearInterval(timer);
      }
      else {
        this.claims = await this.authenticationService.getClaims();
      }
    },500)
  }

  refresh(){
    location.reload();
  }

  triggerIdleNotification(){
    let num = 0;
    let start = 60;
    this.modalRef = this.modalService.open(this.idleModal, {size:'md', backdrop:'static', centered:true, windowClass:'idleModal'})
    let countdownEle = document.getElementById('pie');
    let label = document.getElementById('nums');
    let label1 = document.getElementById('nums1');
    let interval = setInterval(() => {
      countdownEle.classList.add(`progress-${num}`);
      label1.innerText = (start).toString();
      label.innerText = (start--).toString();
      num++;
      if(start == -1){
        clearInterval(interval);
      }
    },1000)
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idleService.reset();
    this.modalService.dismissAll();
    this.modalRef = undefined;
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
}
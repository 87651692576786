import { Injectable } from '@angular/core';
import { AuthenticationService } from '../auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class MedicalHistoryService {

  constructor(
    private db: AngularFirestore,
    private authService: AuthenticationService,
  ) {
    
   }


  async getMedicalHistory(uid:string) {
    return this.db.collection("users").doc(uid).collection("medicalHistory").valueChanges();
  }

  async getMedicalHistoryQuestions() {
    return this.db.collection("conditionsQuestions").doc("medicalHistory").collection("Questions").valueChanges();
  }

  async createMedicalHistory(uid:string){
    (await this.getMedicalHistoryQuestions()).subscribe(async (questions:any) => {
      if(questions.length > 0){
        questions.forEach(async (question:any) => {
          let data = {
            answerHistory: question.answerHistory,
            medicalHistoryId: question.medicalHistoryId,
            question: question.question,
          }
          this.db.collection("users").doc(uid).collection("medicalHistory").doc(data.medicalHistoryId).set(data);
        });
      };
    });
  }


  calculateBMI(height: number, inches: number, weight: number) {
    let totalHeight = height * 12 + inches;
    let bmi = (weight / (totalHeight * totalHeight)) * 703;
    // console.log("BMI: ", bmi)
    let BMICalc:any = bmi.toFixed(1);
    if(BMICalc < 18.5){
      BMICalc = '<en>' + BMICalc + ' - Underweight</en><es>' + BMICalc + ' - Bajo Peso</es>'
    }
    if(BMICalc >= 18.5 && BMICalc <= 24.9){
      BMICalc = '<en>' + BMICalc + ' - Normal Weight</en><es>' + BMICalc + ' - Peso Normal</es>'
    }
    if(BMICalc >= 25 && BMICalc <= 29.9 ){
      BMICalc = '<en>' + BMICalc + ' - Overweight</en><es>' + BMICalc + ' - Sobre Peso</es>'
    }
    if(BMICalc >= 30){
      BMICalc = '<en>' + BMICalc + ' - Obese</en><es>' + BMICalc + ' - Obeso</es>'
    }
    return BMICalc;
  }


  
}

import { BaseProductPrescription, Diagnostic, DiagnosticTemplate, ExternalPatient, MedicalInsurance, MedicationRequest, Practitioner, Profile } from "../../services/interfaces";
import { RxPrescription } from "../prescriptions";

const NULL_PRACTITIONER: Practitioner = {
    accountType: null,
    biologicalgender: null,
    birthdate: null,
    email: null,
    firstName: null,
    gender: null,
    alternateNumber: null,
    alternateNumberMsg: null,
    idExpDate: null,
    idNumber: null,
    idStateIssued: null,
    idCountryIssued: null,
    idType: null,
    identification: null,
    // identificationBack: null,
    image: null,
    isAccountDisabled: null,
    lastName1: null,
    lastName2: null,
    lastUpdate: null,
    mfaLoginDate: null,
    middleName: null,
    partnerID: null,
    phoneNumber: null,
    phoneNumberMsg: null,
    preferedLang: null,
    status: null,
    source: null,
    stripeID: null,
    timezone: null,
    twoFA: null,
    uid: null,
    Licence: null,
    LicenceState: null,
    Specialty: null
};


export const NULL_DIAGNOSTIC: Diagnostic = {
  id: null,
  date: null,
  name: null,
  code_name: null,
  code: null,
  recommendation: null,
  assessment: null,
  
}


export const NULL_MEDICAL_INSURANCE: MedicalInsurance = {
  id: null,
  uid: null,
  name: null,
  contractNumber: null,
  groupNumber: null,
  copayment: null,
  date: null,
  efectiveDate: null,
  url: null
}


export const NULL_EXTERNAL_PATIENT: ExternalPatient = {
  email: null,
  phone: null,
  firstName: null,
  lastName1: null,
  birthdate: null,
  accountType: null,
  biologicalgender: null,
  typeOfPractitioner: null,
}


export const NULL_PRODUCT_PRESCRIPTION: BaseProductPrescription = {
  automaticPayment: null,
  daw: null,
  id: null,
  image: null,
  isPack: null,
  minRefillQty: 0,
  packID: null,
  prescriptionID: null,
  productID: null,
  productName: null,
  productPrice: null,
  productQty: null,
  recurrency_order: null,
  refillIntervalDays: null,
  refillMainQty: 11,
  refillQty: null,
  rxInstruction: null,
  rxcui: null,
  rxcuiName: null,
  treatment: null,
  type: null,
  deliveryFrequencyInDays: null
};



export const NULL_MEDICATION_REQUEST: MedicationRequest = {
  resourceType: 'MedicationRequest',
  status: "active",
  intent: "order",
  medicationCodeableConcept: {
    coding: [{
      system: '',
      code: '',
      display: ''
    }],
    text: ''
  },
  subject: {
    reference: '',
    display: ''
  },
  authoredOn: '',
  requester: {
    reference: '',
    display: ''
  },
  dosageInstruction: [{
    text: '',
    timing: {
      code: {
        text: '',
        system: '',
        code: ''
      },
      repeat: {
        frequency: 0,
        period: 0,
        periodUnit: ''
      }
    },
    route: {
      coding: [{
        system: '',
        code: '',
        display: ''
      }],
      text: ''
    },
    doseAndRate: [{
      doseQuantity: {
        value: 0,
        unit: '',
        system: '',
        code: ''
      }
    }]
  }],
  dispenseRequest: {
    validityPeriod: {
      start: '',
      end: ''
    },
    quantity: {
      value: 0,
      unit: '',
      system: '',
      code: ''
    },
    expectedSupplyDuration: {
      value: 0,
      unit: '',
      system: '',
      code: ''
    }
  }
};


export const DEFAULT_PROFILE: Profile = {
  phoneVerified: false,
  BMI: [],
  accountType: '',
  biologicalgender: '',
  birthdate: '',
  email: '',
  firstName: '',
  gender: '',
  alternateNumber: '',
  alternateNumberMsg: false,
  idExpDate: '',
  idNumber: '',
  idStateIssued: '',
  idCountryIssued: '',
  idType: '',
  identification: '',
  image: '',
  isAccountDisabled: false,
  lastName1: '',
  lastUpdate: '',
  mfaLoginDate: '',
  middleName: '',
  partnerID: null,
  phoneNumber: '',
  phoneNumberMsg: false,
  preferedLang: 'en',
  status: 'none',
  source: '',
  timezone: '',
  twoFA: false,
  uid: '',
  workExtension: '',
  workNumber: '',
  workNumberMsg: '',
  zipcode: '',
  residence: [{
    country: "",
    state: [{name: "", code: ""}],  
  }],
  authenticatorStatus: '',
  terms: [{
    acceptedDate: "",
    acceptedTerms: false,
    docLang: "",
    version: ""
  }],
  finishedSignup: false,
  refreshToken: '',
  abbr: '',
  typeOfPractitioner: '',
  schedule: [],
  abscentDates: [],
  adminRefresh: false,
  sync: false,
};




export const NULL_PRESCRIPTION:RxPrescription = {
  ID: null,
  address: null,
  age: null,
  date: null,
  docId: null,
  docAdd: null,
  docName: null,
  docPhone: null,
  docEmail: null,
  name: null,
  email: null,
  phone: null,
  consultID: null,
  diagnostics: null,
  License: null,
  LicenseState: null,
  Specialty: null,
  pharmacy: '',
  prescription_pdf: null,
  prescription_type: 'Rx',
  status: 'Unsigned',
  birthdate: null,
  NPI: null,
  internal: null,
  valid: true,
  erpPrescriptionRef: "",
  uid: null,
  partnerID: null,
  lang: null,
  prescriptionURL: null,
  z_base64: null,
  signedDate: null,
  originalID: null,
  automaticPayment: null,
  patientSignature: null,
  patientSignedDate: null,
  patientSignRef: null,
  orientation: null,
  transcribed: false
}
<body style="-webkit-text-size-adjust: 100%; font-family: Neue, Helvetica, Arial, sans-serif;">
  <div style="min-width: 320px; max-width: 600px; width: 100%; margin: auto;" role="presentation" width="100%">
    <div align="center">
      <img style="width: 150px;" src="{{logo}}">  
    </div>
    
    <div style="color:#505D69; text-align: justify;"> 
      <p style="margin: auto 0; padding: 10px 0px; text-align: center; line-height: 1.2"><b style="font-size: 32px; color:#16395f;">Your account has been created!</b></p>
      <div style="font-size: 16px; text-align: justify; line-height:1.2; padding: 10px">
        <p>Hello {{email}}, we have created your account.<br><br>Click the button below to sign in!</p>
        <br>
        <div style="justify-content: center; display: flex; text-align-last: center; margin: auto; width: fit-content;">
          <a href="{{link}}" target="_blank" style="line-height: initial; padding: 10px 20px; color: #ffffff; background-color: #ef7636; border-radius: 10px; -webkit-border-radius: 10px; -moz-border-radius: 10px; text-decoration: none;">
            <b>SIGN IN</b>
          </a>
        </div>
        <br>
        <p>If you believe this is an error, please contact us for help.</p>
        <p>Thanks,
          <br><br>
          <b>{{appName}}</b>
        </p>
        <br>
      </div>                     
    </div>

    <hr style="border: 1px solid rgba(0, 0, 0, 0.1) !important;">
    
    <div style="line-height: 1.2; font-size: 12px; padding: 10px; color:#808080;">
      <div align="center">
        <a href="{{facebook}}" target="_blank"><img width="32" height="32" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-only-logo-dark-gray/facebook@2x.png" alt="Facebook" title="Facebook" style="text-decoration: none; padding: 0 2.5px;"></a>
        <a href="{{instagram}}" target="_blank"><img width="32" height="32" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-only-logo-dark-gray/instagram@2x.png" alt="Instagram" title="Instagram" style="text-decoration: none; padding: 0 2.5px;"></a>
        <a href="{{twitter}}" target="_blank"><img width="32" height="32" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-only-logo-dark-gray/twitter@2x.png" alt="Twitter" title="Twitter" style="text-decoration: none; padding: 0 2.5px;"></a>
      </div>
      <div style="text-align: center;">
        <p style="margin: 0; margin-bottom: 10px;"><a href="tel:+{{phoneNumber}}" style="color: #ef7636;">{{phoneNumber}}</a></p>
        <p style="margin: 0; margin-bottom: 10px; color: #808080;">Please feel free to contact us at <a href="mailto:{{supportEmail}}" style="color: #ef7636;" rel="noopener">{{supportEmail}}</a>.<br>This is an automatic generated email, please do not reply.</p>
        <p style="margin: 0;">{{year}}</p>
        <p style="margin: 0;">
          <a href="{{myAccount}}" target="_blank" style="color: #808080;" title="My Account" rel="noopener">My Account</a> |
          <a href="{{help}}" target="_blank" style="color: #808080;" title="Help" rel="noopener">Help</a> |
          <a href="{{privacyPolicy}}" target="_blank" style="color: #808080;" title="Privacy Policy" rel="noopener">Privacy Policy</a>
        </p>
      </div>
    </div>
  </div>
</body> 
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subscription } from 'rxjs';
import { DataService } from './data.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {User} from '@firebase/auth-types';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { TranslationPipe } from 'src/app/translation.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { fbRemoteConfig } from '../../authUtils'
import { EmailsService } from 'src/app/core/services/emails.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})

export class NotificationsComponent implements OnInit, OnDestroy{
  //Message section inside consult (practitioners)
  @Input() patient:any;
  @Input() chatLeftsidebar: Boolean;
  @Input() filteredNotifications;

  //Inbox or read message counter
  unread = [];
  orderUnread = [];
  consultMailUnread = [];
  
  allSubscriptions = new Subscription()
  pagetitle = "NOTIFICATIONS.TITLE"
  emailIds: Array<any> = [];
  searchText:any = '';

  //user
  user: User;
  profile
  allNotifications;
  selectedNotification: Array<any> = [];
  helpdesk: any = [];
  to: any;
  toName: any;
  toLang: any;

  public Editor = ClassicEditor;
  html:any;
  toUID:any;
  subject:any;
  disableTo: Boolean;

  // for mobile view
  hidden: Boolean;

  placeholder = "NOTIFICATIONS.STARTMESSAGE"

  currentLang = this.translate.store.currentLang
  currentConsult

  //for swal
  swal

  settings = fbRemoteConfig.defaultConfig
  //notification obj
  notification = {
    html:"",
    text:""
  }

  translationPipe = new TranslationPipe()

  supportEmail;
  previousUrl = 'general'
  filter = 'myMessages'

  constructor(
    public modalService: NgbModal,
    private notificationsService: DataService,
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public firebaseService: FirebaseService,
    public router: Router,
    public translate: TranslateService,
    private sanitaizer: DomSanitizer,
    private emailService: EmailsService,
    ) { 
    this.getUser();
    this.allSubscriptions.add(this.notificationsService.notification.subscribe(data => this.filteredNotifications = data)); 
    this.allSubscriptions.add(this.notificationsService.currentNotification.subscribe((data: any) => {
      this.selectedNotification[0] = data
      this.selectedNotification[0].html = this.sanitaizer.bypassSecurityTrustHtml(this.translationPipe.transform(data?.template?.data?.html, this.currentLang)) 
      this.selectedNotification[0].initials = data?.template?.data?.fromName ? data?.template?.data?.fromName.split(' ')[0].charAt(0) + (data?.template?.data?.fromName.split(' ')[1]?.charAt(0) ?  data?.template?.data?.fromName.split(' ')[1]?.charAt(0) : '') : 'DS'
    })); 
  }

  ngOnInit() {  
    if(this.router.url.includes('chat') || this.router.url.includes('helpdesk') || 
    this.router.url.includes('faq') || this.router.url.includes('contactus')){
      this.filteredNotifications = undefined
    }
    if(this.patient){
      this.to = this.patient.email;
      this.toName = this.patient.firstName + ' ' + this.patient.lastName1
      this.toLang = this.patient.preferedLang
    }
    if(screen.width < 650 || (window.matchMedia("(orientation: landscape-primary)") && screen.height < 650)){
      this.hidden = true; 
      this.hide(true)
      this.chatLeftsidebar = true
    }
    else {
      this.hidden = false;
    }
  }

  getUser() {
    this.afAuth.user.subscribe(user => {
      if(user){
        this.user = user;
        this.afs.firestore.collection('users').doc(`${user.uid}`).get().then(async data =>{
          this.profile = await data.data()
          this.getHelpDesk(data.data()['uid'])
          this.getAllNotifications()
          this.supportEmail = this.translationPipe.transform(fbRemoteConfig.defaultConfig.supportEmail, this.profile.preferedLang)
        });
      }
    });
  }

  getAllNotifications(){
    let arr1
    let arr2
    let sub = this.afs.collection('mail', ref => ref.where('toUID', '==', this.profile.uid).orderBy('id', 'desc')).valueChanges().subscribe(async mail => {
      arr1 = mail
      let sub1 = this.afs.collection('mail', ref => ref.where('fromUID', '==', this.profile.uid).orderBy('id', 'desc')).valueChanges().subscribe(async mail => {
        arr2 = mail
        this.allNotifications = arr1.concat(arr2).sort((a, b) => {
          return b?.delivery?.startTime - a?.delivery?.startTime
        })

        for(let i = 0;i < this.allNotifications.length; i++){
          if(this.router.url === `/inboxandsupport/read/${this.allNotifications[i].id}` || this.router.url === `/inboxandsupport/orders/${this.allNotifications[i].template.data.orderNumber}`){
            if(this.allNotifications[i].to != this.profile.email){
              this.previousUrl = 'sent'
            }
            this.handleNotification(this.allNotifications[i]);
          }
        }
        this.unread = this.allNotifications.filter(n => {
          return n.read == false && n.toUID == this.profile.uid && !n.archive.includes(this.profile.uid) && ((n.consultMail == false && n.deliveryOrder == true && n.template.data.Order == true) || n.generalMail == true || n.template.name.includes('chatReminder') || n.template.name.includes('updatedID') || (n.template.name.includes('IDRequired') && !n.consultID) || n.template.name.includes('consultsAvailable'))
        })
        this.consultMailUnread = this.allNotifications.filter(n => {
          return n.read == false && n.toUID == this.profile.uid && !n.archive.includes(this.profile.uid) && n.consultMail == true
        })
        this.orderUnread = this.allNotifications.filter(n => {
          return n.read == false && n.toUID == this.profile.uid && !n.archive.includes(this.profile.uid) && n.deliveryOrder == false && n.template.data.Order == true
        })

        if(this.router.url.startsWith('/inboxandsupport/orders')){
          if(this.router.url === '/inboxandsupport/orders' || this.selectedNotification[0].length == 0){
            this.getOrderMails()
          }
        }
        if(this.router.url === '/inboxandsupport/consults'){
          this.getConsultMails()
        }
        if(this.router.url === '/inboxandsupport/sent'){
          this.getSentNotifications()
        }
        if(this.router.url === '/inboxandsupport/general' || this.router.url === '/inboxandsupport'){
          this.getNotifications()
        }
        if(this.router.url === '/inboxandsupport/archive'){
          this.getArchiveMails()
        }
      });
      this.allSubscriptions.add(sub1)
    });     
    this.allSubscriptions.add(sub)
  }

  getNotifications(){
    this.previousUrl = 'general'
    this.filteredNotifications = this.allNotifications?.filter((n: any) => {
      return n.toUID == this.profile.uid && !n.archive.includes(this.profile.uid) && ((n.consultMail == false && n.deliveryOrder == true && n.template.data.Order == true) || n.generalMail || n.template.name.includes('chatReminder') || n.template.name.includes('updatedID') || (n.template.name.includes('IDRequired') && !n.consultID) || n.template.name.includes('consultsAvailable'))
    })
  }

  getSentNotifications(){
    this.previousUrl = 'sent'
    this.filteredNotifications = this.allNotifications?.filter(n => {
      return n.fromUID == this.profile.uid && !n.archive.includes(this.profile.uid)
    })
  }

  getArchiveMails(){
    this.previousUrl = 'archive'
    this.filteredNotifications = this.allNotifications?.filter(n => {
      return n.toUID == this.profile.uid && n.archive.includes(this.profile.uid)
    })
  }

  getConsultMails(){
    this.previousUrl = 'consults'
    this.filteredNotifications = this.allNotifications?.filter(n => {
      return n.toUID == this.profile.uid && !n.archive.includes(this.profile.uid) && n.consultMail == true
    })
  }

  getOrderMails(){
    this.previousUrl = 'orders'
    this.filteredNotifications = this.allNotifications?.filter(n => {
      return n.toUID == this.profile.uid && !n.archive.includes(this.profile.uid) && ((n.deliveryOrder == false && n.template.data.Order == true) || n.orderMail)
    })
  }

  getHelpDesk(user: any){
    this.afs.firestore.collection('helpdesk').where('uid', '==', user).orderBy('id', 'desc').get().then(async helpDesk => {
      this.helpdesk = [];

      for(let i = 0;i < helpDesk.docs.length; i++){
        this.helpdesk[i] = helpDesk.docs[i].data()
        if(this.router.url === `/inboxandsupport/helpdesk/${this.helpdesk[i].id}`){
          this.currentTicket = [this.helpdesk[i]]
        }
      }
    }); 
  }

  currentTicket
  handleNotification(notification:any){ 
    this.filteredNotifications = undefined;
    if(notification.consultID){
      this.afs.firestore.collection('consultation').doc(notification.consultID).get().then(data => {
        this.currentConsult = data.data()
      })
    }
    else {
      this.currentConsult = undefined
    }
    if(notification.read == false && notification.to == this.user.email){    
      notification.read = true;   
      this.firebaseService.archiveMail(notification); 
    }  
    this.notificationsService.selectedNotification(notification); 
    this.viewPrevMessages = false
    this.prevMessages = []
    this.checkPrevMessages(notification);
  }

  prevMessages = []
  viewPrevMessages = false
  checkPrevMessages(notification){
    this.prevMessages = this.allNotifications.filter(n => {
      return n.groupMessageId && 
      n.groupMessageId == notification.groupMessageId && 
      n.date.seconds < notification.date.seconds &&
      n.id != notification.id
    }).map(m => {
      m.html = this.sanitaizer.bypassSecurityTrustHtml(this.translationPipe.transform(m?.template?.data?.html, this.currentLang))
      m.initials = m?.template?.data?.fromName ? m?.template?.data?.fromName.split(' ')[0].charAt(0) + (m?.template?.data?.fromName.split(' ')[1]?.charAt(0) ? m?.template?.data?.fromName.split(' ')[1]?.charAt(0) : '') : 'DS'
      return m
    })
  }

  open(content, compose) {
    this.translate.get('NOTIFICATIONS.STARTMESSAGE').subscribe((res: any) => {
      this.placeholder= res
    })
    
        if(compose != undefined){
      this.to = compose.template.data.from;
      this.toName = compose.template.data.fromName;
      if(compose.template.data.subject.includes('Re:')){
        this.subject = this.translationPipe.transform(compose.template.data.subject, this.currentLang);
      }
      else {
        this.subject = "Re: " + this.translationPipe.transform(compose.template.data.subject, this.currentLang);
      }

      this.html = '';
      this.disableTo = true;
      this.disable();
      // this.html =  '\n\n\n' + compose.template.data.html
    }
    else {
      this.to = compose;
      this.subject = compose;
      this.disableTo = false;
      this.disable();
    }
    this.modalService.open(content, { centered: true });
  }

  /**
   * select mail
   * @param event event
   * @param id id of record
   */
  selectMail(event, id) {
    if (event.target.checked) {
      this.emailIds.push(id);
    } else {
      this.emailIds.splice(this.emailIds.indexOf(id), 1);
    }
  }

  deleteMail() {
    this.emailIds = [];
  }

  markUnread() {
    this.emailIds = [];
  }

  archiveMails(){
    this.translate.get('NOTIFICATIONS.SWAL').subscribe((res: any) => {
      this.swal = res
    })
    Swal.fire({
      title: this.swal.ARCHIVEMAILSTEXT,
      text: this.swal.ACTIVEBTN,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: this.swal.ARCHIVEBTN,
      cancelButtonText: this.swal.CANCEL
    }).then(result => {
      if (result.value) {
        for(let i = 0; i < this.emailIds.length; i++){
          this.emailIds[i].archive.push(this.profile.uid);
          this.firebaseService.archiveMail(this.emailIds[i]);         
        }
        this.deleteMail();
        Swal.fire(this.swal.ARCHIVED,this.swal.ARCHIVEDMSG, 'success');
      }
    });
  }

  unarchiveMails(){
    this.translate.get('NOTIFICATIONS.SWAL').subscribe((res: any) => {
      this.swal = res
    })
    Swal.fire({
      title: this.swal.DELETETITLE,
      text: this.swal.UNARCHIVEMAILSTEXT,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: this.swal.UNARCHIVEBTN,
      cancelButtonText: this.swal.CANCEL
    }).then(result => {
      if (result.value) {
        for(let i = 0; i < this.emailIds.length; i++){
          let id = this.emailIds[i].archive.indexOf(this.profile.uid)
          this.emailIds[i].archive.splice(id,1);
          this.firebaseService.archiveMail(this.emailIds[i]);         
        }
        this.deleteMail();
        Swal.fire(this.swal.UNARCHIVED, this.swal.UNARCHIVEDMAILSMSG, 'success');
      }
    });
  }

  sendNotification(){
    if(this.subject == undefined || this.subject == '' || this.subject == null || this.notification.html == undefined || this.notification.html == '' || this.notification.html == null){
      return;
    }
    if(this.toLang == undefined || this.toLang == '' || this.toLang == null){
      this.toLang = 'es'
    }

    let consultID = this.selectedNotification[0]?.consultID ? this.selectedNotification[0].consultID : '';
    let consultMail = this.selectedNotification[0]?.consultMail ? this.selectedNotification[0].consultMail : false;

    this.afs.firestore.collection('users').where('email', '==', this.to).get().then(async data => {
      this.toUID = data.docs[0].data().uid;
      
      let customSms: any = {
        shortUrl: {code: ''},
        smsShortUrl: '',
      }

      let date = new Date(Date.now());
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();
      let id:any = yyyy + mm + dd + uuidv4();
      let groupMessageId = this.selectedNotification[0]?.groupMessageId ? this.selectedNotification[0].groupMessageId : id

      await this.emailService.shortenUrl('https://app.dzeus.com/inboxandsupport/read/' + id, this.settings.shortenedUrl).then(code => {     
        customSms.shortUrl.code = code
        customSms.smsShortUrl = code
        customSms.link = '/inboxandsupport/read/' + id
      
        this.emailService.internalCustomMessages(id, data.docs[0].data(), this.subject, this.notification.html, this.notification.text, this.settings, consultID, consultMail, this.profile, '', customSms, groupMessageId)
        this.modalService.dismissAll();
        this.to = "";
        this.toName = "";
        this.subject = "";
        this.toLang = "";
      })
    });
  }

  disable(){
    if(this.disableTo){
     return true;
    }
    else false;
  }

  disableToolbar(){
    if(this.emailIds.length == 0){
      var element = <HTMLInputElement> document.getElementById("toolbarArchive");
      element.disabled = true;
    }
    else{
      var element = <HTMLInputElement> document.getElementById("toolbarArchive");
      element.disabled = false;
    }
  }

  public onChange( { editor }: ChangeEvent ) {
    const data = editor.getData();
    this.notification.html = data;
  }

  collectMessage($event){
    this.notification.text = $event.target.innerText;
  }

  hide(bool: boolean){
    if( screen.width < 650 || (window.matchMedia("(orientation: landscape-primary)") && screen.height < 650)){
      var element =  <HTMLInputElement> document.getElementById("sidebar");
      element.hidden = bool;
      var element1 =  <HTMLInputElement> document.getElementById("navBar");
      element1.hidden = !bool;
    }    

    this.selectedNotification = [];
  }

  chatLeftsidebarComponent(chatLeftsidebar){
    this.chatLeftsidebar = chatLeftsidebar
  }

  ngOnDestroy(){
    this.allSubscriptions.unsubscribe()
  }

  pageLoad(){
    if(this.router.url.startsWith('/inboxandsupport/read')){
      return 0
    }
    if(this.router.url === '/inboxandsupport/general' || this.router.url === '/inboxandsupport'){
      return 1
    }
    if(this.router.url === '/inboxandsupport/consults'){
      return 2
    }
    if(this.router.url.startsWith('/inboxandsupport/orders')){
      if(this.router.url !== '/inboxandsupport/orders'){
        return 0
      }
      return 3
    } 
    if(this.router.url === '/inboxandsupport/sent'){
      return 4
    }
    if(this.router.url === '/inboxandsupport/archive'){
      return 5
    }
    if(this.router.url.startsWith('/inboxandsupport/helpdesk')){
      if(this.router.url != '/inboxandsupport/helpdesk') {
        return 0
      }
      return 6
    }
    if(this.router.url === '/inboxandsupport/faq'){
      return 7
    }
    if(this.router.url === '/inboxandsupport/contactus'){
      return 8
    }
    if(this.router.url === '/inboxandsupport/chat'){
      return 9
    }
  }

  getUserColor(userId: string): string {
    // Example logic: Assign colors based on userId or initials
    const colors = ['#F15A25', '#14395f']; // You can add more colors
    const index = Math.abs(this.hashCode(userId)) % colors.length;
    return colors[index];
  }
  
  // Hash function to generate consistent index for the same userId
  hashCode(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }  

  filterNotifications(){
    
  }
}
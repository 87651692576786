<div class="container-fluid">  
  <div>
    <div class="chat-leftsidebar" *ngIf="leftsidebar" id="chat-leftsidebar">
      <!-- <div class="p-3 border-bottom" style="display: flex; justify-content: space-between;">
        <div class="media">
          <div class="align-self-center mr-3">
            <img style="width: 45px; height: 45px;" src="{{user?.image}}" class="rounded-circle cropped" alt="Header Avatar">
          </div>
          <div class="media-body w-50">
            <h5 class="font-size-15 mt-0 mb-1">{{user?.firstName}} {{user?.lastName1}}</h5>
          </div>
        </div>
      </div> -->
      <!-- <div class="card-body border-bottom py-2">
        <div class="search-box chat-search-box">
          <div class="position-relative">
            <input type="text" class="form-control searchbar" placeholder="{{'NOTIFICATIONS.SEARCH' |  translate}}" name="search" [(ngModel)]="searchChat">
            <i class="ri-search-line search-icon"></i>
          </div>
        </div>
      </div> -->

      <div class="chat-leftsidebar-nav">
        <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-pills nav-justified">
          <li [ngbNavItem]="1">
            <ng-template ngbNavContent>
              <div>
                <h5 class="font-size-24 bold px-3 mb-1" style="margin-top: -1%; text-align: left;">{{'NOTIFICATIONS.CHAT.RECENT' | translate}} </h5>
                <div class="card-body border-bottom py-2">
                  <div class="search-box chat-search-box">
                    <div class="position-relative">
                      <input type="text" class="form-control searchbar" style="background: #f1f5f7;" placeholder="{{'NOTIFICATIONS.SEARCH' |  translate}}" name="search" [(ngModel)]="searchChat">
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </div>
                <perfect-scrollbar style="position: relative; height: auto;">
                  <ul *ngIf="displayUserNameCopy.length != 0" class="list-unstyled chat-list">
                    <li *ngFor="let chat of data | filter: searchChat; let i = index;" [ngClass]="{ 'unread': displayUserNameCopy[i]?.read != undefined && displayUserNameCopy[i]?.read != true}" >
                      <a (click)="consult(displayUserNameCopy[i]?.data.consultId);chatUsername(displayUserNameCopy[i], true); hide(true);">
                        <div class="media">
                          <span *ngIf=" displayUserNameCopy[i]?.read != undefined && displayUserNameCopy[i]?.read != true" class="badge badge-pill badge-orange p-0"></span>
                          <span *ngIf=" displayUserNameCopy[i]?.read == undefined || displayUserNameCopy[i]?.read == true" class="badge badge-pill p-0"></span>
                          <div class="user-img online align-self-center mr-3" *ngIf="displayUserNameCopy[i]?.data.profileImgs" [ngClass]="{'online': chat.status === 'online',
                          'away': chat.status === 'intermediate'}">
                            <img style="width: 40px; height: 40px;" *ngFor="let img of displayUserNameCopy[i]?.data.profileImgs; let j = index" src="{{img}}" [ngClass]="{'disable-div': user.uid == displayUserNameCopy[i]?.data.usersUid[j], 'photoShift': displayUserNameCopy[i]?.data.usersUid.length > 2 && j < 3}" class="rounded-circle cropped avatar-xs" alt="">
                          </div>
                          <div class="user-img mr-3" *ngIf="!displayUserNameCopy[i]?.data?.profileImgs" [ngClass]="{'online': chat.status === 'online',
                          'away': chat.status === 'intermediate'}">
                            <div class="avatar-xs align-self-center">
                              <img style="width: 40px; height: 40px;" *ngFor="let img of displayUserNameCopy[i]?.data.profileImgs" src="{{img}}" class="rounded-circle cropped" alt="Header Avatar">
                            </div>
                          </div>
                          <div class="media-body w-50">
                            <div class="d-flex">
                              <h5 class="text-truncate font-size-14 mb-1 d-flex" *ngFor="let name of displayUserNameCopy[i]?.data?.userNames; let j = index" [ngClass]="{'disable-div': user.uid == displayUserNameCopy[i]?.data.usersUid[j]}">{{name}}<p class="m-0" *ngIf="name != displayUserNameCopy[i]?.data.userNames[displayUserNameCopy[i]?.data.userNames.length - 1]" [ngClass]="{'disable-div': user.uid == displayUserNameCopy[i]?.data.usersUid[j + 1]}">,&nbsp;</p></h5>
                            </div>
                            <div class="text-truncate mb-0 font-size-14" [innerHTML]="displayUserNameCopy[i]?.message | translation:currentLang"></div>
                          </div>
                          <div class="font-size-11">
                            {{displayUserNameCopy[i]?.lastMessageDate.toDate() | date:'MM/dd/yyyy'}}
                          </div>
                        </div>
                      </a>
                    </li>                     
                  </ul>
                  <div style="text-align: center;" *ngIf="displayUserNameCopy.length == 0 && !warning">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {{'RELOAD.LOADING' | translate}}
                  </div>
                  <div style="text-align: center;" *ngIf="displayUserNameCopy.length == 0 && warning">
                    <p>{{warning | translate}}</p>
                   </div>
                </perfect-scrollbar>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="py-4"></div>
      </div>
    </div>

    <!-- RIGHT CONTENT -->
    <div class="w-100 user-chat mt-sm-0" *ngIf="rightsidebar" id="chat-rightsidebar">
      <div class="p-3 px-lg-4 user-chat-border">   
        <div class="row">
          
          <div class="col-6" style="display: flex; flex-direction: row; align-items: center;">
            <div class="btn-group mb-2 mr-3 mb-sm-0">
            <button type="button" class="btn btn-primary toolbarBtn" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button>
          </div>
          <div>
            <h5 class="font-size-15 mb-1 text-truncate">{{username}}</h5>
            <!-- <p class="text-muted text-truncate mb-0"><i class="mdi mdi-circle text-success align-middle mr-1" [ngClass]="{'text-success': status === 'online',
                'text-warning': status === 'intermediate'}"></i>
              {{status}}</p> -->
            </div>
          </div>
          <div class="col-6">
            <ul class="list-inline user-chat-nav text-right mb-0">
              <li class="list-inline-item d-inline-block d-sm-none">
                <div class="dropdown" ngbDropdown>
                  <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" ngbDropdownToggle>
                    <i class="mdi mdi-magnify"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-md" ngbDropdownMenu>
                    <form class="p-2">
                      <div class="search-box">
                        <div class="position-relative">
                          <input id="search" type="text" class="form-control searchbar rounded" placeholder="{{'NOTIFICATIONS.SEARCH' |  translate}}" name="search" [(ngModel)]="searchChat">
                          <i class="mdi mdi-magnify search-icon"></i>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </li>
              <li class="d-none d-sm-inline-block tabletView">
                <div class="search-box mr-2">
                  <div class="position-relative">
                    <input type="text" class="form-control searchbar" placeholder="{{'NOTIFICATIONS.SEARCH' |  translate}}" name="search" [(ngModel)]="searchChat">
                    <i class="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
              </li>
              <!-- <li class="list-inline-item m-0 d-none d-sm-inline-block">
                <div class="dropdown" ngbDropdown>
                  <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" ngbDropdownToggle>
                    <i class="mdi mdi-cog"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                   <a class="dropdown-item" (click)="deleteChat()">{{'NOTIFICATIONS.CHAT.DELETE' | translate}}</a>
                  </div>
                </div>
              </li> -->
            </ul>
          </div>
        </div>
      </div>

      <div class="px-lg-2">
        <div class="chat-conversation p-3 chatView" #scrollMe style="display: flex; overflow-y: auto;" id="messageBody">
          <div class="w-100">
            <ul class="list-unstyled mb-0 pr-3" *ngFor="let data of chatMessagesData | filter: searchChat"
              [ngClass]="{ 'right': data.align === 'right' }">
              <li *ngIf="!data.text">
                <div class="conversation-list">
                  <div class="chat-avatar">
                    <img class="cropped" src="{{data.image}}" alt="">
                  </div>
                  <div class="ctext-wrap">
                    <div class="conversation-name">{{data.name}}</div>
                    <div class="ctext-wrap-content">
                      <div class="mb-0" [innerHTML]="data.message | translation:currentLang">
                      </div>
                    </div>
                    <p class="chat-time my-0 d-flex align-items-center justify-content-end"><i class="mdi mdi-clock-outline align-middle mr-1"></i> {{data.time}} <i *ngIf="data.align == 'right'" class="ri-check-double-line" [ngClass]="{'text-success': data.messageRead}" style="color: #cedada; font-size: 20px;"></i></p>
                  </div>
                </div>
              </li>
              <li *ngIf="data.text">
                <div class="chat-day-title">
                  <span class="title">{{data.text}}</span>
                </div>
              </li>
            </ul>
            <div style="text-align: center;" *ngIf="chatMessagesData.length == 0 && !warning">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {{'RELOAD.LOADING' | translate}}
            </div>
            <div style="text-align: center;" *ngIf="chatMessagesData.length == 0 && warning">
              <p>{{warning | translate}}</p>
             </div>
          </div>
        </div>
      </div>
      <div *ngIf="showScroll">
        <button class="btn btn-block" style="font-size: 15px !important; margin-top: -32px;position: absolute;" (click)="scrollToBottom()">{{'NOTIFICATIONS.NEWMESSAGE' | translate}} <i class="fas fa-chevron-down"></i></button>
      </div>
      <div class="px-lg-3">
        <div class="p-3 chat-input-section">
          <form (ngSubmit)="messageSave()" *ngIf="canWrite && this.status != 'abandoned'" [formGroup]="formData" class="row"> 
          <!-- <form *ngIf="currentConsult?.Status != 'Completed'" (ngSubmit)="messageSave()" [formGroup]="formData" class="row">  -->
              <div class="col">
              <div class="position-relative">
                <input id="message" type="text" class="form-control chat-input" placeholder="{{'NOTIFICATIONS.CHAT.ENTERMESSAGE' | translate}}"
                  formControlName="message" [ngClass]="{'is-invalid': chatSubmit && form.message.errors}">
                <div *ngIf="chatSubmit && form.message.errors" class="invalid-feedback">
                  <span *ngIf="form.message.errors.required">{{'NOTIFICATIONS.CHAT.VALUE' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-dark chat-send w-md waves-effect waves-light" (click)="messageSave()"><span
                  class="d-none d-sm-inline-block mr-2">{{'NOTIFICATIONS.CHAT.SEND' | translate}}</span> <i class="mdi mdi-send"></i></button>
            </div> 
          </form>
          <!-- <div *ngIf="currentConsult?.Status == 'Completed'" class="col-auto"> -->
            <div *ngIf="!canWrite && currentConsult?.Status == 'Completed'" class="col-auto">
              <p>{{'NOTIFICATIONS.CHAT.COMPLETED' | translate}}<a href="/inboxandsupport/helpdesk">{{'NOTIFICATIONS.CHAT.HELPDESK' | translate}}</a></p>
            </div>  
            <div *ngIf="status == 'abandoned'" class="col-auto">
              <p>{{'NOTIFICATIONS.CHAT.ABANDONED' | translate}}</p>
            </div> 
        </div>
      </div>
    </div>
  </div>
</div>
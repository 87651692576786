import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ProductService, shipping } from 'src/app/core/services/product.service';

@Component({
  selector: 'app-prefered-shipping-method',
  templateUrl: './prefered-shipping-method.component.html',
  styleUrls: ['./prefered-shipping-method.component.scss']
})
export class PreferedShippingMethodComponent implements OnInit{
  @Input() consult: any;
  @Input() patientProfile: any;
  shippingMethod: shipping[] = [];
  currentShippingMethod: shipping;
  constructor(private productService: ProductService,
    private afs: AngularFirestore,) { 
    
  }
  
  ngOnInit(): void {
    this.getShippingMethods();
  }

  getShippingMethods(){
    this.productService.getShipping(this.consult).then((methods: shipping[]) => {
      this.shippingMethod = methods.filter(m => {
       return m.productStates.some(pS => pS.state_code.includes(this.consult.state.code))
      });
    })
  }

  setSelectedShippingMethod(event, shippingMethod){

    var parentDocRef = this.afs.firestore.collection('consultation').doc(`${this.consult.ID}`);

    var subcollectionRef = parentDocRef.collection('wishlist');

    var query = subcollectionRef.where('type', '==', 'shipping');

    var obj = {
      id: shippingMethod.odooProductId ? shippingMethod.odooProductId : shippingMethod.id,
      fbProductId: shippingMethod.productId ? shippingMethod.productId : (shippingMethod.fbProductId ? shippingMethod.fbProductId : shippingMethod.id),
      odooProductId: shippingMethod.odooProductId ? shippingMethod.odooProductId : shippingMethod.id,
      imgRef: shippingMethod.productImg ? shippingMethod.productImg : shippingMethod.imgRef,
      name: shippingMethod.productName ? shippingMethod.productName : shippingMethod.name,
      price: typeof(shippingMethod.productPrice) == 'string' ? (shippingMethod.productPrice ? [{[this.consult.state.code]:shippingMethod.productPrice}] : [{[this.consult.state.code]:shippingMethod.price}]) : (shippingMethod.productPrice ? shippingMethod.productPrice : shippingMethod.price),
      qty: 1,
      type: shippingMethod.productType ? shippingMethod.productType : shippingMethod.type
    }

    query.get().then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            
            var data = doc.data();
            if(data.fbProductId != obj.fbProductId){
              var parentDocRef = this.afs.firestore.collection('consultation').doc(`${this.consult.ID}`);
  
              var subcollectionRef = parentDocRef.collection('wishlist');
  
              var docToDeleteRef = subcollectionRef.doc(data.id.toString());
  
              docToDeleteRef.delete().then(() => {
                  this.afs.firestore.collection("consultation").doc(this.consult.ID).collection("wishlist").doc(shippingMethod.odooProductId.toString()).set(obj);
                  this.afs.collection("users").doc(this.patientProfile.uid).collection("preferedShippingMethod").doc("Shipping").set(obj);
  
              }).catch((error) => {
              });
            }
          });
        }
        else {
          this.afs.firestore.collection("consultation").doc(this.consult.ID).collection("wishlist").doc(obj.id.toString()).set(obj);
          this.afs.collection("users").doc(this.patientProfile.uid).collection("preferedShippingMethod").doc("Shipping").set(obj);
        }
    }).catch((error) => {
    });

  }
}

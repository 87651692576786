import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Prescription } from 'src/app/core/classes/prescriptions';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiUrl = 'https://us-central1-dzeus-app-telemd.cloudfunctions.net/getZPLContent'; //'https://us-central1-dzeusrnd.cloudfunctions.net/getZPLContent'; //'https://us-central1-dzeus-app-telemd.cloudfunctions.net/getZPLContent';

  private apiUrl2 = 'https://us-central1-dzeus-app-telemd.cloudfunctions.net/ShipEngine_CreateLabelFromRate';

  private apiUrl3 = 'https://us-central1-dzeus-app-telemd.cloudfunctions.net/EasyPost_CreateShipment'; //'https://us-central1-dzeusrnd.cloudfunctions.net/EasyPost_CreateShipment';

  private apiUrl4 = 'https://us-central1-dzeus-app-telemd.cloudfunctions.net/sendDataToFloridaPharmacy'; //'https://us-central1-dzeusrnd.cloudfunctions.net/sendDataToFloridaPharmacy';
  
  private apiUrl5 = 'https://us-central1-dzeus-app-telemd.cloudfunctions.net/EasyPost_RefundShipment'; //'https://us-central1-dzeusrnd.cloudfunctions.net/EasyPost_RefundShipment';

  private apiUrl6 = 'https://us-central1-dzeus-app-telemd.cloudfunctions.net/FloridaShippingConfirmationEmail';

  // private apiUrl7 = 'https://us-central1-dzeus-app-telemd.cloudfunctions.net/testEmail';

  constructor(private http: HttpClient) { }

  getData(zpl: string): Observable<any> {

    // console.log('zpl', zpl);

    return this.http.get<any>(this.apiUrl, { params: { zpl } });
  }

  generateZPL(rateId: string): Observable<any> {

    return this.http.get<any>(this.apiUrl2, { params: { rateId } });

  }

  sendData(data: any): Observable<any> {

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');

    var obj = {
      name: data.name,
      street1: data.street1,
      street2: data.street2,
      city: data.city,
      state: data.state,
      zip: data.zip,
      country: data,
      phone: data.phone,
      predefined_package: data.predefined_package,
      weight: data.weight,
      label_format: data.label_format,
      orderId: data.orderId,
      prescriptionId: data.prescriptionId,
    }

    var name = obj.name;
    var street1 = obj.street1;
    var street2 = obj.street2;
    var city = obj.city;
    var state = obj.state;
    var zip = obj.zip;
    var phone = obj.phone;
    var predefined_package = obj.predefined_package;
    var weight = obj.weight;
    var label_format = obj.label_format;
    var orderId = obj.orderId;
    var prescriptionId = obj.prescriptionId;

    return this.http.get<any>(this.apiUrl3, 
    { params: 
      { 
        name,
        street1,
        street2, 
        city,
        state,
        zip,
        phone,
        predefined_package,
        weight,
        label_format,
        orderId,
        prescriptionId
      } 
    });

  }

  sendDataToPharmacy(data: any): Observable<any> {

    var prescriptionId = data.prescriptionId;

    var patientGender = data.patientGender;

    var dosage = data.dosage;

    var productId = data.productId;

    var acquiredByUID = data.acquiredByUID;

    // console.log("productId", productId)

    return this.http.get<any>(this.apiUrl4, 
      { params: 
        {
          prescriptionId,
          patientGender,
          dosage,
          productId,
          acquiredByUID
        } 
    });

  }

  sendDataToVoid(data: any): Observable<any> {

    var carrier = data.carrier;
    var tracking_code = data.tracking_code;
    var orderId = data.orderId;

    return this.http.get<any>(this.apiUrl5, 
      { params: 
        {
          carrier,
          tracking_code,
          orderId
        } 
    });

  }

  sendShippingConfirmationEmail(data: any): Observable<any> {

    // console.log('data', data);

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');

    var uid = data.uid ? data.uid : '';

    var tracking_code = data.tracking_code ? data.tracking_code : '';

    var tracking_url = data.tracking_url ? data.tracking_url : '';

    var orderId = data.orderId ? data.orderId : '';

    var AddressLine1 = data.AddressLine1 ? data.AddressLine1 : '';

    var AddressLine2 = data.AddressLine2 ? data.AddressLine2 : '';

    var AddressCity = data.AddressCity ? data.AddressCity : '';

    var AddressStateCode = data.AddressStateCode ? data.AddressStateCode : '';

    var AddressZipCode = data.AddressZipCode ? data.AddressZipCode : '';

    var AddressCountryCode = data.AddressCountryCode ? data.AddressCountryCode : '';

    var patientName = data.patientName ? data.patientName : '';

    var orderNo = data.orderNo ? data.orderNo : '';

    try{

      return this.http.get<any>(this.apiUrl6, 
        { params: 
          {
            uid,
            tracking_code,
            tracking_url,
            orderId,
            AddressLine1,
            AddressLine2,
            AddressCity,
            AddressStateCode,
            AddressZipCode,
            AddressCountryCode,
            patientName,
            orderNo
          }
        });

    }
    catch(e){
      // console.log(e);
    }

  }

  // testEmail(data: any): Observable<any> {

  //   return this.http.get<any>(this.apiUrl7);

  // }

}

<div *ngIf="loaded">
  <div class="nav-content">
    <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav-tabs nav-tabs-custom nav-justified">
      <li [ngbNavItem]="1" >
        <a ngbNavLink (click)="reset()">
          <span>{{'MENUITEMS.PRACTITIONERSCONSULTS.TEXT' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="consultData"></ng-template>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink (click)="reset()">
          <span>{{'ACCOUNTS.ACCOUNTS' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="accountData"></ng-template>
        </ng-template>
      </li>
      <!-- <li [ngbNavItem]="3">
        <a ngbNavLink (click)="reset()">
          <span>{{'INFLUENCERS.INFLUENCERS' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="influencerData"></ng-template>
        </ng-template>
      </li> -->
    </ul>
    <div [ngbNavOutlet]="customNav"></div>
  </div>
</div>

<app-reload *ngIf="!loaded" [message]="'RELOAD.LOADING'"></app-reload>
<ng-template #consultData>
  <div class="mt-2">
    <button class="btn btn-primary" (click)="filterConsults('all'); reset()">All Consults</button>&nbsp;
    <div class="d-flex">
      <input type="datetime-local" [(ngModel)]="startDate" (change)="filterConsults($event.target.value)">&nbsp; - &nbsp;
      <input type="datetime-local" [(ngModel)]="endDate" (change)="filterConsults($event.target.value)">
    </div>
  </div>

  Open Consult Analytics
  <apx-chart dir="ltr" class="apex-charts" [series]="openConsultAnalytics.series" [chart]="{height: 300, type: 'bar'}"
   [labels]="openConsultAnalytics.labels" [colors]="['#F10000']">
  </apx-chart>

  <apx-chart dir="ltr" class="apex-charts" [series]="openConsultAnalytics.series[0].data" [chart]="{type: 'pie', height: 300}"
    [labels]="openConsultAnalytics.labels" [colors]="openConsultAnalytics.colors">
  </apx-chart>

  All Consults
  <apx-chart dir="ltr" class="apex-charts" [series]="allConsultAnalytics.series" [chart]="{height: 300, type: 'bar'}"
   [labels]="allConsultAnalytics.labels" [colors]="allConsultAnalytics.colors" [plotOptions]="{bar: {horizontal: true}}">
  </apx-chart>

  <div *ngFor="let item of allConsultAnalytics.status | keyvalue | paginate: { itemsPerPage: 2, currentPage: pageAnalytics, id: 'first'}">
    <h5 class="m-0 mt-3">{{item.key}}:</h5>
    <div *ngFor="let value of item.value | keyvalue; let i = index">
      <p class="m-0" [ngClass]="{'ml-4': value.key == 'Open Non Anonymous', 'ml-2': value.key != 'Open Non Anonymous'}">{{value.key}}: {{value.value}}</p>
    </div>
  </div>

  <div class="row justify-content-end mt-4">
    <div class="col text-right">
      <pagination-controls (pageChange)="pageAnalytics = $event" id="first" style="text-align: right;"></pagination-controls>
    </div>
  </div>
</ng-template>

<ng-template #accountData>
  <div style="min-height: 70vh;">
    <div class="mt-2">
      <button class="btn btn-primary" (click)="filterAccounts('all'); reset()">All Accounts</button>&nbsp;
      <div>
        <div class="d-flex">
          <input type="datetime-local" [(ngModel)]="startDate" (change)="filterAccounts($event.target.value)">&nbsp; - &nbsp;
          <input type="datetime-local" [(ngModel)]="endDate" (change)="filterAccounts($event.target.value)">
        </div>
      </div>
    </div>
  
    <div class="mt-2">
      <p>All Users Registered - {{filterUsers.length}}</p>
      <p>Completed Users Registered - {{completedUsers.length}}</p>
      <p>Completed Users With Consult - {{consultUsers.length}}</p>


      <div class="table-responsive">
        <table class="table table-striped">
          <tr>
            <th></th>
            <th>Name</th>
            <th>Consult</th>
            <th>Status</th>
            <th>Current Step</th>
            <th>Has Address</th>
            <th>Has Card</th>
          </tr>
          <tr *ngFor="let user of consultUsers; let i = index" [ngClass]="{'alternate': evenOdd(i), 'disable-div': user.Status == 'Canceled'}">
            <td><a class="btn btn-primary  w-auto" style="color: white; font-size: 16px !important; padding-top: 0 !important; padding-bottom: 0 !important;" [routerLink]="['../consults', user.ID]">{{'CONSULTATIONS.VIEWCONSULT' | translate}}</a></td>
            <td>{{user.patientName}}</td>
            <td>{{user.Type}}</td>
            <td>{{user.Status}}</td>
            <td *ngIf="user.Status == 'Open' || user.Status == 'Draft'">{{user.currentWizardStep}}</td>
            <td *ngIf="user.Status != 'Open' && user.Status != 'Draft'">N/A</td>
            <td *ngIf="user.address" style="text-transform: capitalize;">Yes</td> 
            <td *ngIf="!user.address" style="text-transform: capitalize;">No</td> 
            <td *ngIf="user.acceptaID" style="text-transform: capitalize;">Yes</td> 
            <td *ngIf="!user.acceptaID" style="text-transform: capitalize;">No</td> 
          </tr>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #influencerData>
  <div>
    <div class="mt-2">
      <button class="btn btn-primary" (click)="filterAccounts('all');getOrdersBasedOnTimeRange('all'); reset()">All Referrals and Orders</button>&nbsp;
      <div class="d-flex">
        <input type="datetime-local" [(ngModel)]="startDate" (change)="filterAccounts($event.target.value); getOrdersBasedOnTimeRange($event.target.value)">&nbsp; - &nbsp;
        <input type="datetime-local" [(ngModel)]="endDate" (change)="filterAccounts($event.target.value); getOrdersBasedOnTimeRange($event.target.value)">
      </div>
    <button class="btn btn-secondary" (click)="exportToCSV()">Export to CSV</button>
    </div>
  
    <table class="table mt-3">
      <thead>
        <tr>
          <th>Influencer</th>
          <th>Orders</th>
          <th>Registered Users</th>
          <th>Campaign</th>
          <th>Campaign Orders</th>
          <th>Campaign Users</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of combinedData">
          <td>{{ row.influencer }}</td>
          <td>{{ row.orders }}</td>
          <td>{{ row.registered }}</td>
          <td>
            <ul>
              <li *ngFor="let campaign of getObjectKeys(row.campaignsOrders)">{{ campaign }}</li>
            </ul>
          </td>
          <td>
            <ul>
              <li *ngFor="let campaign of getObjectKeys(row.campaignsOrders)">{{ row.campaignsOrders[campaign] }}</li>
            </ul>
          </td>
          <td>
            <ul>
              <li *ngFor="let campaign of getObjectKeys(row.campaignsUsers)">{{ row.campaignsUsers[campaign] }}</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    
  <h1> Registers per Influencer </h1>
  <apx-chart dir="ltr" class="apex-charts" [series]="ReferalInfluencerAnalytics.series" [chart]="{height: 300, type: 'bar'}"
   [labels]="ReferalInfluencerAnalytics.labels" [colors]="['#F10000']">
  </apx-chart>

    <apx-chart
    class="apex-charts" 
    [series]="ReferalInfluencerAnalytics.series[0].data"
     [chart]="{height: 300,type: 'pie'}"
    [labels]="ReferalInfluencerAnalytics.labels" 
    [colors]="ReferalInfluencerAnalytics.colors">
  
  </apx-chart>
  <h1> Orders per Influencer </h1>
  <apx-chart dir="ltr" class="apex-charts" [series]="OrderReferralAnalytics.series" [chart]="{height: 300, type: 'bar'}"
   [labels]="OrderReferralAnalytics.labels" [colors]="['#F10000']">
  </apx-chart>

  <apx-chart 
    dir="ltr" 
    class="apex-charts" 
    [series]="OrderReferralAnalytics.series[0].data" 
    [chart]="{height: 300, type: 'pie'}"
    [labels]="OrderReferralAnalytics.labels" 
    [colors]="OrderReferralAnalytics.colors">
  </apx-chart>
  
    
  </div>
</ng-template>

  <!-- <br> <button class="btn btn-primary" (click)="ageRange()">Age Ranges</button>
   <div class="mt-3" *ngIf="loadAges && ageRanges.length != 0">
     <p>Age Ranges: </p>
     <div *ngFor="let item of ageRanges; let i = index">
       <h5 class="m-0 mt-3" style="text-align: start;">{{ageRangeLabels[i]}}: {{item}}</h5>
     </div>

     <p>Open Age Ranges: </p>
     <div *ngFor="let item of openConsults; let i = index">
       <h5 class="m-0 mt-3" style="text-align: start;">{{ageRangeLabels[i]}}: {{item}}</h5>
     </div>

     <p>Completed Age Ranges: </p>
     <div *ngFor="let item of completedConsults; let i = index">
       <h5 class="m-0 mt-3" style="text-align: start;">{{ageRangeLabels[i]}}: {{item}}</h5>
     </div>

     <p>Open And Completed Age Ranges: </p>
     <div *ngFor="let item of openAndCompletedConsults; let i = index">
       <h5 class="m-0 mt-3" style="text-align: start;">{{ageRangeLabels[i]}}: {{item}}</h5>
     </div>
   
     <p>Get partner: </p>
     <div *ngFor="let item of getPartner; let i = index">
       <h5 class="m-0 mt-3" style="text-align: start;">{{item.partnerID}}: {{item.openConsults}}, {{item.completedConsults}} {{item.openAndCompletedConsults}}</h5>
     </div>

     <apx-chart dir="ltr" class="apex-charts" [series]="ageRanges" [chart]="{type: 'pie', height: 300}"
         [labels]="ageRangeLabels" [colors]="openConsultAnalytics.colors">
     </apx-chart>
   </div> -->

  <div *ngIf="loadAges">
    <div *ngFor="let gender of genders">
      <h5>Males: {{gender.male}}</h5>
      <h5>Females: {{gender.female}}</h5>
    </div>
  </div>

    <!-- <br><br>
    Consults with Exceptions:
    <div *ngFor="let item of invalidIds">
      <h6 class="m-0 ml-2">{{item.UID_Patient}}</h6>
    </div> -->
    <!-- <apx-chart dir="ltr" class="apex-charts" [series]="{data: [allConsultAnalytics.status.Psychotherapy.Completed]}" [chart]="{height: 300, type: 'bar'}"
     [labels]="allConsultAnalytics.labels" [colors]="allConsultAnalytics.colors">
    </apx-chart> -->
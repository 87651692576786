import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { remoteConfig } from 'src/environments/environment';
import firebase from 'firebase/compat/app';
import { getFunctions, httpsCallable } from "firebase/functions";
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileDataService {
  settings = remoteConfig
  // private GET_URL = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/getCustomerPaymentMethodsStripe";

  private POST_URL = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/getCustomerPaymentMethodsStripe";

  private POST_URL2 = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/updateCustomerStripeInfo";

  private POST_URL3 = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/addCustomerCardStripe";

  private POST_URL4 = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/deleteCustomerCardStripe";

  private POST_URL5 = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/updateCustomerCardStripe";

  public POST_URL6 = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/updateCustomerStripeAddress";

  private POST_URL7 = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/getCustomerStripe";

  private POST_ZIPURL = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/cityStateLookup";

  private POST_URL8 = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/cityStateLookup";

  // private resendEmailUrl = `https://us-central1-${this.settings.env}.cloudfunctions.net/resendEmailVerificationCode`;
  private assignRolesUrl = `https://us-central1-${this.settings.env}.cloudfunctions.net/adminAssignRoles`;
  //key BfWWS4J7vNqVL9MTNLv16Oag50R2BuH4kGKbOFUJfzbXxJAwxHtI9LZBb0fEw7n8
  public response: any;
  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService
  ) { }

  public postRequest(emailCurrentUser: any, userProfile: any) {

    var currentUser = {
      email: emailCurrentUser.toString()
    }

    let body = new HttpParams();

    body = body.set('email', currentUser.email);
    body = body.set('name', userProfile.name);
    body = body.set('phone', userProfile.phone);
    body = body.set('description', userProfile.description);
    body = body.set('city', userProfile.city);
    body = body.set('country', userProfile.country);
    body = body.set('line1', userProfile.line1);
    body = body.set('line2', userProfile.line2);
    body = body.set('postal_code', userProfile.postal_code);
    body = body.set('state', userProfile.state);

    // var email = JSON.stringify(emailUser);

    return this.httpClient.post(this.POST_URL7, body); //,this.httpClient.get(this.GET_URL)

  }

  public postRequestPaymentMethods(emailCurrentUser: any, stripeIDCurrentUser: any) {

    var currentUser = {
      email: emailCurrentUser.toString(),
      stripeID: stripeIDCurrentUser.toString()
    }

    let body = new HttpParams();

    body = body.set('email', currentUser.email);
    body = body.set('stripeID', currentUser.stripeID);

    // var email = JSON.stringify(emailUser);

    return this.httpClient.post(this.POST_URL, body); //,this.httpClient.get(this.GET_URL)

  }

  public postRequestUpdateCustomerInfo(emailCurrentUser: any, userProfile: any) {

    var currentUser = {
      email: emailCurrentUser.toString(),
    }

    let body = new HttpParams();

    body = body.set('email', currentUser.email);
    body = body.set('stripeID', userProfile.stripeID);
    body = body.set('name', userProfile.stripeID);
    body = body.set('phone', userProfile.stripeID);
    body = body.set('description', userProfile.stripeID);
    body = body.set('new_email', userProfile.stripeID);

    // var email = JSON.stringify(emailUser);

    return this.httpClient.post(this.POST_URL2, body); //,this.httpClient.get(this.GET_URL)

  }

  public postRequestUpdateCustomerAddress(emailCurrentUser: any, userProfile: any) {

    var currentUser = {
      email: emailCurrentUser.toString(),
    }

    let body = new HttpParams();

    body = body.set('email', currentUser.email);
    body = body.set('stripeID', userProfile.stripeID);
    body = body.set('city', userProfile.city);
    body = body.set('country', userProfile.country);
    body = body.set('line1', userProfile.line1);
    body = body.set('line2', userProfile.line2);
    body = body.set('postal_code', userProfile.postal_code);
    body = body.set('state', userProfile.state);

    // var email = JSON.stringify(emailUser);

    return this.httpClient.post(this.POST_URL6, body); //,this.httpClient.get(this.GET_URL)

  }

  public postRequestAddCard(emailCurrentUser: any, stripeIDCurrentUser: any, stripeTokenCurrentUser: any, uidCurrentUser: any) {

    var currentUser = {
      email: emailCurrentUser.toString(),
      stripeID: stripeIDCurrentUser.toString(),
      token: stripeTokenCurrentUser.toString(),
      uid: uidCurrentUser.toString()
    }

    let body = new HttpParams();

    body = body.set('email', currentUser.email);
    body = body.set('stripeID', currentUser.stripeID);
    body = body.set('token', currentUser.token);
    body = body.set('uid', currentUser.uid);

    // var email = JSON.stringify(emailUser);

    return this.httpClient.post(this.POST_URL3, body); //,this.httpClient.get(this.GET_URL)

  }

  public postRequestDeleteCard(emailCurrentUser: any, stripeIDCurrentUser: any, stripeTokenCurrentUser: any) {

    var currentUser = {
      email: emailCurrentUser.toString(),
      stripeID: stripeIDCurrentUser.toString(),
      source: stripeTokenCurrentUser.toString()
    }

    let body = new HttpParams();

    body = body.set('email', currentUser.email);
    body = body.set('stripeID', currentUser.stripeID);
    body = body.set('source', currentUser.source);

    // var email = JSON.stringify(emailUser);

    return this.httpClient.post(this.POST_URL4, body); //,this.httpClient.get(this.GET_URL)

  }

  public postRequestUpdateCard(emailCurrentUser: any, stripeIDCurrentUser: any, stripeTokenCurrentUser: any, cardNameCurrentUser: any) {

    var currentUser = {
      email: emailCurrentUser.toString(),
      stripeID: stripeIDCurrentUser.toString(),
      source: stripeTokenCurrentUser.toString(),
      name: cardNameCurrentUser.toString()
    }

    let body = new HttpParams();

    body = body.set('email', currentUser.email);
    body = body.set('stripeID', currentUser.stripeID);
    body = body.set('source', currentUser.source);
    body = body.set('name', currentUser.name);

    // var email = JSON.stringify(emailUser);

    return this.httpClient.post(this.POST_URL5, body); //,this.httpClient.get(this.GET_URL)

  }

  public async resendEmail(profile) {

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '* ');

    let remoteConfig = await this.authService.getRemoteConfig();
    // let body = new HttpParams();
    // body = body.set('profile', JSON.stringify(profile));

    const functions = getFunctions();
    const resend = httpsCallable(functions, 'oncall-resend-email-verification-code');
    let result = await resend({remoteConfig: remoteConfig, profile: profile});
    return result.data;
    // return this.httpClient.post(this.resendEmailUrl, body, { headers: headers }).subscribe(res => {
    // }); //,this.httpClient.get(this.GET_URL)
  }

  public getCityStateLookup(zipcode: any) {
    let body = new HttpParams();
    body = body.set('zip', zipcode);
    return this.httpClient.post(this.POST_ZIPURL, body).subscribe(res => {
      this.response = res;
    });
  }

  assignRoles(user, removeRoles){

    // const headers = new HttpHeaders();
    // headers.set('Content-Type', 'application/json');
    // headers.set('Access-Control-Allow-Origin', '* ');

    // let body = new HttpParams();
    // body = body.set('uid', user.uid);
    // body = body.set('accountType', user.accountType);
    // body = body.set('admin', user.admin);
    
    // return this.httpClient.post(this.assignRolesUrl, body, { headers: headers }).subscribe(res => {
    // }); //,this.httpClient.get(this.GET_URL)
    const functions = getFunctions();
    const assignRoles = httpsCallable(functions, 'adminAssignRoles');
    assignRoles({ users: [user.uid], claims: {accountType: user.accountType} })
    .then((result) => {
      // Read result of the Cloud Function.        
    });
  }

  handleResponse() {
    return this.response;
  }
  // public getRequest(emailUser: any){

  //   //var headers = new HttpHeaders().set('Content-Type', 'application/json').set('Access-Control-Allow-Origin', '*');

  //   // return this.httpClient.get(this.GET_URL,{headers});

  //   var email = JSON.stringify(emailUser);

  //   console.log("email", email)

  //   const params = new HttpParams({fromString: email});

  //   return this.httpClient.get(this.GET_URL, {responseType:'json', params});

  // }

  verifyCityStateUsingZip(zipcode: any, currentLocation: any, currentLocationName: any) {

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    
    var zip = zipcode;

    var currentLocation = currentLocation;

    var currentLocationName = currentLocationName;

    return this.httpClient.get<any>(this.POST_URL8, 
      { params: 
        {
          zip,
          currentLocation,
          currentLocationName
        }
      });

  }

}

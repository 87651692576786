import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { remoteConfig } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatorService {

  constructor(
    private http: HttpClient,

  ) { }
    settings = remoteConfig

  validate(uid, code:string){
    return new Promise(resolve=>{
      const url = `https://us-central1-${this.settings.env}.cloudfunctions.net/authenticatorTokenValidator`;
      // const url = "http://localhost:5001/dzeus-app-telemd/us-central1/authenticatorTokenValidator";
      const headers = new HttpHeaders();
      headers.set('Content-Type', 'application/json');
      headers.set('Access-Control-Allow-Origin', '* ');

      let body = new HttpParams();
      body = body.set('uid', uid);
      body = body.set('token', code);

      this.http.post(url, body, {responseType: 'json'}).subscribe(async (res:any ) => {
        let data:any = await res;
        resolve(data);
        // this.response = data;
        // if(data == null){
        //  this.valid = false;
        //  this.toast.showError("Invalid code", "Invalid");
        // }else{
        //   this.valid = true;
        //   console.log("Valid")
        // }
      });
    })
  }




}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Profile } from 'src/app/core/services/interfaces';
import { AgeValidator } from '../../walkthroughmodal/walkthroughmodal.mustwatch';

@Component({
  selector: 'app-birthdate',
  templateUrl: './birthdate.component.html',
  styleUrls: ['./birthdate.component.scss']
})
export class BirthdateComponent implements OnInit, OnDestroy{

  @Input() profile:Profile;
  @Input() styling = ''
  @Output() birthdate = new EventEmitter<any>();
  @Output() back = new EventEmitter<boolean>();
  @Input() background = 'white';
  currentLang = this.translate.store.currentLang || 'es';

  daySelected = '';
  monthSelected = '';
  yearSelected = '';
  numberOfDays;
  monthNumber = '';

  signupForm: UntypedFormGroup;

  
  
  constructor(
    public translate: TranslateService,
    private formBuilder: UntypedFormBuilder 
  ) { }

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group({
      birthdate: [this.profile.birthdate, Validators.compose([
        Validators.required, AgeValidator
      ])],
    }, {
    });
    this.daySelected = this.profile?.birthdate ? new Date(this.profile.birthdate).getUTCDate().toString().padStart(2, '0') : '';
    this.monthSelected = this.profile && this.profile.birthdate ?  new Intl.DateTimeFormat(this.profile.preferedLang, { month: 'long' }).format(new Date(this.profile.birthdate)) : ''
    this.yearSelected = this.profile?.birthdate ? new Date(this.profile.birthdate).getFullYear().toString() : '';
    this.f.birthdate.patchValue(this.profile.birthdate ? this.profile.birthdate : this.yearSelected + '-' + this.monthNumber + '-' + this.daySelected);
    this.daysRemainingInMonth();
  }

  get f() { 
    return this.signupForm.controls; 
  }

  async daysRemainingInMonth() {
    this.getMonths().forEach((month, index )=> {
      if(month === this.monthSelected){
        this.monthNumber = (index + 1).toString().padStart(2, '0');
        this.numberOfDays = new Date(Number(this.yearSelected), Number(this.monthNumber), 0).getDate()
        this.daySelected = this.numberOfDays.toString().padStart(2, '0') > this.daySelected ? this.daySelected : this.numberOfDays.toString().padStart(2, '0');
      }
    });
  }

  getMonths() {
    let months = [];
    for (let i = 0; i <= 11; i++) {
      const date = new Intl.DateTimeFormat(this.currentLang, { month: 'long' }).format(new Date(2024, i, 1));
      
      months.push(date);
    }
    return months;
  }

  getDays() {
    let days = [];
    for (let i = 1; i <= this.numberOfDays; i++) {
      days.push(i.toString().padStart(2, '0'));
    }
    return days;
  }

  getYears() {
    let years = [];
    let currentYear = new Date().getFullYear();
    for (let i = currentYear - 21; i >= currentYear - 100; i--) {
      years.push(i);
    }
    return years;
  }


  save(){
    // this.birthdate.emit($event);
    this.f.birthdate.patchValue(this.yearSelected + '-' + this.monthNumber + '-' + this.daySelected);
  }

  emit(){
    this.birthdate.emit({target: {
      value: this.f.birthdate.value,
      name: 'birthdate'
    }});
  }


  formSteps(){
    if(this.f.birthdate.invalid) {
      var element = <HTMLInputElement> document.getElementById("nextBtn");
      element.disabled = true;
      element.style.display = 'none';
      return true;
    }
    else{
      var element = <HTMLInputElement> document.getElementById("nextBtn");
      element.disabled = false;
      element.style.display = 'block';
      return false;
    }
  }


  goBack(){
    this.back.emit(true);
  } 

  ngOnDestroy(): void {
    
  }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-custom-camera',
  templateUrl: './custom-camera.component.html',
  styleUrls: ['./custom-camera.component.scss']
})
export class CustomCameraComponent implements OnInit {
  @ViewChild("video") public video: ElementRef;
  @ViewChild("canvas") public canvas: ElementRef;
  constructor() { }

  ngOnInit(): void {
    const canvas = document.querySelector("canvas");
    const ctx = canvas.getContext("2d");
    const video = document.querySelector("video");
    
    // video.addEventListener("play", () => {
    //   function step() {
    //     ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    //     requestAnimationFrame(step);
    //   }
    //   requestAnimationFrame(step);
    // });

    // var video = document.createElement('video');
    video.setAttribute('playsinline', '');
    video.setAttribute('autoplay', '');
    video.setAttribute('muted', '');
    video.style.width = '100%';
    video.style.height = '50vh';

    // /* Setting up the constraint */
    var facingMode = "user"; // Can be 'user' or 'environment' to access back or front camera (NEAT!)
    var constraints = {
      audio: false,
      video: {
      facingMode: facingMode
      }
    };
    // const videoEle = document.getElementById("video") as HTMLVideoElement;
    
    
    // /* Stream it to video element */
    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {

        // this.video.nativeElement.srcObject = stream
        // this.video.nativeElement.play();
        // this.videoSrc = stream
        video.srcObject = stream;
        video.play();
      });
    }   
    video.play();
    video.addEventListener("play", () => {
    function step() {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      ctx.rect(20, 20, 250, 100);
      ctx.stroke();
      requestAnimationFrame(step);
    }
    requestAnimationFrame(step);
  });
    // video.onplay = function() {
    //   canvas.width = video.videoWidth;
    //   canvas.height = video.videoHeight;
    //   draw();
    // };
   
  function draw() {
      if(video.paused || video.ended) return false;
      ctx.drawImage(video, 0, 0);
      setTimeout(draw, 20);
  }
  }
  
  startCam(){

    // if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //   navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {

    //     this.video.nativeElement.srcObject = stream
    //     this.video.nativeElement.play();
    //     this.loading = false
    //     this.videoSrc = stream
    //   });
    // }   
  }

}

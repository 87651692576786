<div class="main d-flex flex-column customHeight" [ngClass]="{'justify-content-between': styling == 'justify-content-between', 'justify-content-start': styling == 'justify-content-start', 'background-cyan': background == 'cyan'}">
    <div class="form-group d-flex flex-column justify-content-center fade-in mt-0">
            <h5 class="m-0">
                {{'PROFILE.WRITECODE' | translate}} <span class="orangeText">{{profile.phoneNumber | mask:'(000) 000-0000'}}</span>.
            </h5>
        </div>
    
        <div class="input-wrap token m-auto">
            <!-- <input type="number" class="form-control" name="phoneVerificationCode" id="verificationCode" placeholder="&nbsp;" maxlength="6" minlength="6" (input)="checkVerificationCode($event)"> -->
            <!-- <div class="input token-fake" *ngFor="let i of [1,2,3,4,5,6]">
            <input autocomplete="false" type="number" class="token-input" (keyup)="checkVerificationCode($event)" (click)="previousInput = {code: i, value: $event.target.value}" placeholder="0" id="code-{{i}}">
            </div> -->
            <label for="verificationCode" class="inp m-auto" style="width: 150px;">
                <input type="number" class="form-control py-0 inp" style="height: 56px;" name="phoneVerificationCode" id="verificationCode" placeholder="0 0 0 0 0 0" maxlength="6" minlength="6" (input)="checkVerificationCode($event)">
            </label>
        </div> 
        <h6 style="color: red; margin-top: 10px; margin-bottom: 0;" *ngIf="msg">{{msg | translate}}</h6>  
        <div class="row">
            <div class="form-group justify-content-center text-center mt-2 d-flex flex-column align-items-center" style="margin: 0 auto;">
            <!-- <h6 class="fade-in text-center" style="color: #7f7f7f;" >{{'SIGNUP.HAVENTRECEIVEDCODE' | translate}}</h6> -->
            <button type="button" class="btn btn-primary nextbtn text-center" style="width: 300px !important;" id="resendBtn" (click)="resendVerificationCode()"> {{'SIGNUP.RESENDCODE' | translate}}</button>
            <!-- <button class="btn justify-content-start float-left" (click)="linkPhoneNumber.emit(false);"> {{'PROFILE.BACK' | translate}}</button> -->
            </div>
        </div>
    
        <div class="msg-info mt-2">
            <p class="m-0" style="color: #7f7f7f; text-align: center;">{{'PROFILE.MSG-INFONEW' | translate}}</p>
        </div>
</div>

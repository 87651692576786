import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AcceptaPaymentInitiation, Blocked, CardResponse, checkSum, Transaction, UserAccountCreditCard } from './interfaces';
import firebase from "firebase/compat/app";
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs';
import { environment, remoteConfig } from 'src/environments/environment';

import { v4 as uuidv4 } from 'uuid';
import { map } from 'rxjs/internal/operators/map';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { P } from '@fullcalendar/core/internal-common';
import { formatDate } from '@angular/common';
import { AuthenticationService } from './auth.service';

const app = firebase.initializeApp(environment.firebaseConfig);
const functions = getFunctions(app);

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  settings = remoteConfig
  private createAcceptaUACreditCardURL = `${this.settings.env}.cloudfunctions.net/createAcceptaUACreditCard`;
  // private createAcceptaUACreditCardURL = 'https://us-central1-dzeus-app-telemd.cloudfunctions.net/createAcceptaUACreditCard';
  // private createAcceptaPIURL = 'https://us-central1-dzeus-app-dev.cloudfunctions.net/createAcceptaPaymentInitiation';
  private createAcceptaPIURL = `https://us-central1-${this.settings.env}.cloudfunctions.net/createAcceptaPaymentInitiation`;
  // private createAcceptaPIURL = 'https://us-central1-dzeus-app-telemd.cloudfunctions.net/createAcceptaPaymentInitiation';
  // private cloudFunctionWriteTest = 'https://us-central1-dzeus-app-dev.cloudfunctions.net/writeFirestore';
  // private createAcceptaPaymentPython = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/createAcceptaPaymentInitiationPython";
  public editCard = new BehaviorSubject<boolean>(false);
  editCC = this.editCard.asObservable();
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private afs: AngularFirestore
  ) { }

  

  async createAcceptaUACreditCard(data:UserAccountCreditCard): Promise<any>{
    // let body = new HttpParams();
    // let response = {
    //   "response": {},
    //   "cardData": {}
    // };
    // body = body.set('data', JSON.stringify(data));
    // return this.http.post(this.createAcceptaUACreditCardURL, body, {responseType: 'json'});
    // const createCreditCard = httpsCallable(functions, 'createAcceptaUACreditCardOnCall');
    const createCreditCard = httpsCallable(functions, 'oncall-create-accepta-ua-creditcard');
    const response:any = await createCreditCard({ 
      creditCard: data.creditCard,
      expDate: data.expDate,
      customerNumber: data.customerNumber,
      firstName: data.firstName,
      lastName: data.lastName,
      address: data.address,
      zipCode: data.zipCode, 
    });
    return response.data.info;

  }

  createAcceptaPaymentInitiation(data:AcceptaPaymentInitiation, cardRef, patient, order, remoteConfig, orderLines){
    let promise: any = new Promise((resolve, reject) => {

      let body = new HttpParams();
      let response = {
        "response": {},
        "cardData": {}
      };
  
      let pharmacists = [];
      this.afs.firestore.collection("users").where('typeOfPractitioner', 'array-contains', 'Pharmacist').where('isAccountDisabled', '==', false).get().then(res =>{
        res.docs.forEach(p => {
          pharmacists.push(p.data())
        })
  
        body = body.set('data', JSON.stringify(data));
        body = body.set('cardRef', JSON.stringify(cardRef));
        body = body.set('user', JSON.stringify(patient));
        body = body.set('order', JSON.stringify(order));
        body = body.set('orderLines', JSON.stringify(orderLines));
        body = body.set('remoteConfig', JSON.stringify(remoteConfig));
        body = body.set('pharmacists', JSON.stringify(pharmacists))
    
        this.http.post(this.createAcceptaPIURL, body, { responseType: 'json' }).subscribe(res => {
          resolve(res);
        })
      })   
    })

    return promise;
  }


  editCardEmitter(bool: boolean){
    this.editCard.next(bool);
  }

  public checksum(number) {
    const ccNumber: string = number;

    const luhnArray: Array<number> = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
    let length: number = ccNumber ? ccNumber.length : 0;
    let sum = 0;
    let shouldMultiply = true;

    while (length) {
      const val: number = parseInt(ccNumber.charAt(--length), 10);
      sum += (shouldMultiply = !shouldMultiply) ? luhnArray[val] : val;
    }
    const data:checkSum = {
      checksum: sum,
      valid: !(sum && sum % 10 === 0) ? false : true
    }
    // return !(sum && sum % 10 === 0) ? CardValidator.CHECKSUM_INVALID : null;
    return data
  }

  async createPaymentAttempt(transaction:Transaction, order:any){
    //informacion de la tarjeta,
    //transacion
    //cardRef
    const cardRef = order.acceptaID ? await this.getCard(order.uid, order.acceptaID) : {};

    let data = {
      id: uuidv4(),
      attempts: order.attempts += 1,
      uid: order.uid,
      consultationId: order.consultationID ? order.consultationID  : "",
      salesOrderID: order.orderNo,
      orderID: order.id,
      orderIDRef: Number(order.orderId),
      status: transaction.status || "pending",
      paymentStatus: transaction.paymentStatus || "pending",
      date: new Date(Date.now()),

    }
      
    let paymentAttempt = Object.assign(transaction, cardRef, data);

    return new Promise<any>((resolve, reject) => {
      this.afs.collection("creditCardPayments").doc(paymentAttempt.id).set(paymentAttempt).then(res => {
        //OnCall cloud function
        resolve(paymentAttempt);
      }).catch(err => {
      });
    });
  }


  async getCard(uid:string, acceptaID:string){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("users").doc(uid).collection("wallet").doc(acceptaID).valueChanges().subscribe(card => {
        resolve(card);
      });
    });
  }
  

  orderPayments$(uid:string): Observable<any[]> {
    return new Observable<any[]>((observer) => {
      const sub = this.afs.collection("creditCardPayments", ref => ref.where("uid", "==", uid).where("paymentStatus", "==", "pending"))
        .valueChanges()
        .pipe(
          map((trail: any) => trail.sort((a, b) => b.date.seconds - a.date.seconds))
        )
        .subscribe((data) => {
          observer.next(data);
        }, (error) => {
          observer.error(error);
        });

      return () => {
        // Clean up the subscription when unsubscribed
        sub.unsubscribe();
      };
    });
  }

  handleCardAttempts(remoteConfig, cardRef, order, user){
    const { paymentAttempts, paymentRetrialTime } = remoteConfig
    const nextAttempt:any = (cardRef?.blockedDate?.seconds + (paymentRetrialTime * 3600)) * 1000;
    return new Promise<any>((resolve, reject) => {

      if(cardRef.attempts >= paymentAttempts){
        let blocked:Blocked = {
          blocked: true,
          blockedDate: new Date(Date.now()),
          blockedReason: "Too many failed attempts",
          attempts: cardRef.attempts
        }
   
        if(Date.now() < nextAttempt){
          this.afs.collection("users").doc(order.uid).collection("wallet").doc(order.uid).update(blocked);
          if(cardRef.attempts == 2){
            // this.failedAttemptMail(cardRef)
            this.afs.collection("orders").doc(order.id).update({processing: false});
            resolve(false)
          }
          
          let res = {
            value: "blocked",
            cardRef: cardRef,
            nextAttempt: blocked.blockedDate ? formatDate(new Date((blocked.blockedDate.seconds + (paymentRetrialTime * 3600)) * 1000), 'MMMM d, y hh:mm a zzzz', user.preferedLang) : formatDate(new Date((Date.now() + (paymentRetrialTime * 3600)) * 1000), 'MMMM d, y hh:mm a zzzz', user.preferedLang)
          }
          resolve(res);
        }
      }
    })
  }


  acceptaQueryServiceTransactionByCustomer(attempt:any): Promise<any>{
   
    const addMessage = httpsCallable(functions, 'AcceptaQueryServiceTransactionsByCustomer');
    return new Promise<any>((resolve, reject) => {
      attempt.date = this.formatDateToMMDDYYYY(attempt.date)
      addMessage({ creditCardPaymentAttempt: attempt})
        .then(async (response:any) => {
          // Read result of the Cloud Function.
          let sanitizedMessage = await response.data.result;
          resolve(sanitizedMessage);
        });
    });
  }

  formatDateToMMDDYYYY(timestamp:any) {
    const date = timestamp.toDate() // Convert Firebase timestamp to JavaScript Date
  
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${month}/${day}/${year}`;
  }
  

 
}
<div>
  <h1>Melanie's Command Center</h1>

  <h3>Orders:</h3>
  <div class="d-flex flex-row align-items-center mb-2">
    <p>Remove wrong credit card payment attempts from users (from more than 5 minutes ago): &nbsp;</p>
    <button class="btn btn-primary" (click)="resetPendingAttempts()">Reset Attempts</button>
  </div>
  <div class="d-flex flex-row align-items-center mb-2">
    <p>Reset all pending orders that weren't created to draft: &nbsp;</p>
    <button class="btn btn-primary" (click)="resetPendingOrders()">Reset Orders to draft</button>
  </div>
  <div class="d-flex flex-row align-items-center mb-2">
    <p>Refund entry (creates entry and updates order status): &nbsp;</p>
    <button class="btn btn-primary" (click)="openModal(refund)">Refund Entry</button>
  </div>
  <div class="d-flex flex-row align-items-center mb-2">
    <p>Credit entry (creates entry and updates order status): &nbsp;</p>
    <button class="btn btn-primary" (click)="openModal(credit)">Credit Entry</button>
  </div>
  <div class="d-flex flex-row align-items-center mb-2">
    <p>Ath móvil entry (creates entry and pays the order): &nbsp;</p>
    <button class="btn btn-primary" (click)="openModal(athMovil)">Ath móvil Entry</button>
  </div>
  <div class="d-flex flex-row align-items-center mb-2">
    <p>Trigger update consultation (when order creation failed): &nbsp;</p>
    <button class="btn btn-primary" (click)="openModal(triggerConsultation)">Handle Consultation</button>
  </div>
  <br><br>
  <div>
    <h3>Cloud Functions:</h3>
    <div class="mb-3" *ngFor="let cf of cloudFunctionsSummary | keyvalue">
      <div *ngFor="let value of cf.value | keyvalue; let i = index">
        <p *ngIf="value.key == '1 cf'">Cloud Function Name: <span class="bold">{{value.value}}</span></p>
        <p *ngIf="value.key == '2 date'">Last Run Date: <span class="bold">{{value.value.toDate() | date:'MMMM dd, yyyy hh:mm aa'}}</span></p>
        <p *ngIf="value.key == '3 description'">Description: {{value.value}}</p>
        <p *ngIf="value.key != '1 cf' && value.key != '2 date' && value.key != '3 description'" class="m-0 ml-2">{{value.key}}: {{value.value}}</p>
      </div>
    </div>
  </div>
</div>

<ng-template let-modal #refund>
  <div class="p-3">
    <h4>Orders</h4>
    <div>
      <div class="search">
        <i class="fas fa-search"></i>
        <input id="search" type="text" placeholder="{{'ORDERS.PLACEHOLDER' | translate}}" [(ngModel)]="term">
      </div>

      <div class="d-flex flex-column my-2 w-50">
        <input type="text" [(ngModel)]="RefundAmount" placeholder="Refund Amount">
        <input type="text" [(ngModel)]="Code" placeholder="Refund Code">
        <input type="text" [(ngModel)]="ErrorMessage" placeholder="Refund Error Message (Optional)">
        <input type="text" [(ngModel)]="UserTraceCode" placeholder="Refund User Trace Code">
        <input type="text" [(ngModel)]="AuthorizationCode" placeholder="Refund Authorization Code">
      </div>
      <div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">{{'ORDERS.ORDER' | translate}}</th>
              <th scope="col">{{'CHECKOUT.TOTAL' | translate}}</th>
              <th scope="col">{{'ORDERS.DATE' | translate}}</th>
              <th scope="col">{{'ORDERS.ACTION' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let order of allPaidOrders | filter:term">
              <td>{{order.orderNo}}</td>
              <td>{{order.orderTotal | currency:'USD'}}</td>
              <td>{{order.date.toDate() | date:'MMMM dd, yyyy hh:mm aa'}}</td>
              <td>
                <button class="btn btn-primary" (click)="createRefundAttempt(order)">Refund</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-modal #credit>
  <div class="p-3">
    <h4>Orders</h4>
    <div>
      <div class="search">
        <i class="fas fa-search"></i>
        <input id="search" type="text" placeholder="{{'ORDERS.PLACEHOLDER' | translate}}" [(ngModel)]="term">
      </div>

      <div class="d-flex flex-column my-2 w-50">
        <input type="text" [(ngModel)]="CreditAmount" placeholder="Credit Amount">
      </div>
      <div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">{{'ORDERS.ORDER' | translate}}</th>
              <th scope="col">{{'CHECKOUT.TOTAL' | translate}}</th>
              <th scope="col">{{'ORDERS.DATE' | translate}}</th>
              <th scope="col">{{'ORDERS.ACTION' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let order of allPaidOrders | filter:term">
              <td>{{order.orderNo}}</td>
              <td>{{order.orderTotal | currency:'USD'}}</td>
              <td>{{order.date.toDate() | date:'MMMM dd, yyyy hh:mm aa'}}</td>
              <td>
                <button class="btn btn-primary" (click)="createCreditAttempt(order)">Credit</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-modal #athMovil>
  <div class="p-3">
    <h4>Orders</h4>
    <div>
      <div class="search">
        <i class="fas fa-search"></i>
        <input id="search" type="text" placeholder="{{'ORDERS.PLACEHOLDER' | translate}}" [(ngModel)]="term">
      </div>

      <div class="d-flex flex-column my-2 w-50">
        <input type="text" [(ngModel)]="athMovilRef" placeholder="Phone Number">
      </div>
      <div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">{{'ORDERS.ORDER' | translate}}</th>
              <th scope="col">{{'CHECKOUT.TOTAL' | translate}}</th>
              <th scope="col">{{'ORDERS.DATE' | translate}}</th>
              <th scope="col">{{'ORDERS.ACTION' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let order of allPendingOrders | filter:term">
              <td>{{order.orderNo}}</td>
              <td>{{order.orderTotal | currency:'USD'}}</td>
              <td>{{order.date.toDate() | date:'MMMM dd, yyyy hh:mm aa'}}</td>
              <td>
                <button class="btn btn-primary" (click)="createAthMovilPayment(order)">Ath Móvil</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-modal #triggerConsultation>
  <div class="p-3">
    <h4>Consultations</h4>
    <div>
      <div class="search">
        <i class="fas fa-search"></i>
        <input id="search" type="text" placeholder="{{'ORDERS.PLACEHOLDER' | translate}}" [(ngModel)]="term">
      </div>

      <div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">{{'CONSULTATIONS.ID' | translate}}</th>
              <th scope="col">{{'CONSULTATIONS.PATIENTNAME' | translate}}</th>
              <th scope="col">{{'ORDERS.DATE' | translate}}</th>
              <th scope="col">{{'ORDERS.ACTION' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let consult of allConsults | filter:term">
              <td>{{consult.ID}}</td>
              <td>{{consult.patientName}}</td>
              <td>{{consult.Date.toDate() | date:'MMMM dd, yyyy hh:mm aa'}}</td>
              <td>
                <button class="btn btn-primary" (click)="triggerConsultationUpdate(consult)">Trigger Consultation</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationService } from 'src/app/core/services/translation.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';

import { OrdersService } from 'src/app/core/services/orders.service';
import { Subscription } from 'rxjs';
import { testUsers } from 'src/environments/environment';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})

export class OrdersComponent implements OnInit, OnDestroy {
  @ViewChild('refill', { read: TemplateRef }) refillsModal:TemplateRef<any>;
  @ViewChild('orderDetailsRef', { read: TemplateRef }) orderDetailsRefModal:TemplateRef<any>;

  @Input() consult:any;
  @Input() consultOrdersDetails: boolean = false;
  @Input() patientUid = '';

  lang:string;
  orders:any = [];
  orderId:string = "";
  consultationId:string;

  patientUser:any;
  currentUser;

  term

  allSubscription = new Subscription();
  filter = "all";
  
  constructor(
    private afs: AngularFirestore,
    public translation: TranslationService,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private router: Router,
    public orderService: OrdersService, 
  ) {}

  async ngOnInit(){
    this.authService.userSubscription.subscribe(res => {
      this.currentUser = res})
    let patientUid = this.patientUid ? this.patientUid : this.currentUser.uid
    this.patientUser = await this.authService.getUserProfile(patientUid);

    if(this.currentUser.accountType == "admin" && !this.patientUid){
      this.consultOrdersDetails ? this.getSpecificConsultOrders() : this.getAllOrdersForAdmins();
    }
    else {
      this.getOrders();
    }
    this.lang = this.currentUser.preferedLang;
  }

  async orderDetails(order:any){
    let date = new Date();
    let oneYearAgo = new Date(date.setFullYear(date.getFullYear() - 1)).getTime() / 1000;
    if((order.date.seconds > oneYearAgo && order.paymentStatus == 'pending') || order.paymentStatus != 'pending'){
      if(order.paymentStatus == 'pending'){
        this.afs.firestore.collection("orders").doc(order.id).collection("orderLines").get().then(async (orderLines:any) => {
          let getProducts = orderLines.docs.map(doc => doc.data());
          let products = await getProducts.filter(x => x.type == "product");

          for(let i = 0; i < products.length; i++){
            let prescriptionId = products[i].prescriptionID;
            
            this.afs.firestore.collection("prescription").doc(prescriptionId).get().then(async (presc:any) => {
              let prescription = await presc.data();
              
              if(prescription && prescription.expirationDate.seconds > (new Date().getTime() / 1000)){
                this.openOrderDetails(order);
              }
              else {
                this.afs.collection("orders").doc(order.id).update({paymentStatus: "expired", orderState: "done"})
                this.openOrderDetails(order);
              }
            })
          }

          if(products.length == 0){
            this.openOrderDetails(order);
          }
        });
      } 
      else {
        this.openOrderDetails(order);
      }
    }
    else {
      this.afs.collection("orders").doc(order.id).update({paymentStatus: "expired", orderState: "done"})
      this.openOrderDetails(order);
    }
  }

  async openOrderDetails(order:any){
    await this.getConsult(order.consultationID).then(() => {
      this.orderId = order.id ? order.id : order.orderNo;
      this.consultationId = order.orderRef ? order.orderRef : order.consultationID;
      if(!this.modalService.hasOpenModals()){
        this.modalService.open(this.orderDetailsRefModal, {size:"lg", backdrop:'static', windowClass: "test"})
      }
    });
  }

  async orderFromRoute(id, orderNo?){
    let orderExists = this.orders.filter(order => order.id == id);
    if(orderNo){
      orderExists = this.orders.filter(order => order.orderNo == orderNo);
    }
      if(orderExists.length == 0){
        this.router.navigate(['/account/404'])
      }
      else {
        this.orderDetails(orderExists[0]);
      }
  }

  getOrders(){ //Orders for Patients
    let sub = this.afs.collection("orders", ref => ref.where("uid", "==", this.patientUser.uid)).valueChanges().subscribe(async (orders:any) => {
      this.orders = await orders.sort((a,b) => Number(b.date.seconds) - Number(a.date.seconds));
      let test = this.orders.filter(x => {
        if(x?.refill){
          let order_date = new Date(x?.refillDate);
          
          let day = order_date.getDay();
          let month = order_date.getMonth();
          let year = order_date.getFullYear();

          let date = `${year}-${month}-${day}`;
          let today = {
            year: new Date().getFullYear(),
            month:new Date().getMonth(),
            day: new Date().getDate(),
          }
          let bool = date == [today.year, today.month, today.day].join('-');
          if(bool){
            return x
          }
        }
      })

      this.orders.forEach(order => {         
        let obj = {
          customer: `${this.patientUser.firstName + " " + this.patientUser.lastName1}`,
          icon: "",
        }
        if(order.paymentStatus == "paid"){
          obj.icon = "far fa-check-circle text-success";
        }else if(order.paymentStatus == "pending"){
          obj.icon = "far fa-clock text-warning";
        }else if(order.paymentStatus == "cancelled"){
          obj.icon = "far fa-times-circle text-danger";
        }
      });
    });
    this.allSubscription.add(sub);

    let id = this.router.url.split("/orders&Refills/")[1];
    if(id !== undefined){
      setTimeout(() => {
        this.orderId = id;
        this.orderFromRoute(id);
      }, 1000);
    }

    let orderNo = this.router.url.split("/orders&Refills?orderNo=")[1];
    if(orderNo !== undefined){
      setTimeout(() => {
        this.orderId = orderNo;
        this.orderFromRoute('', orderNo);
      }, 1000);
    }
  }

  async getSpecificConsultOrders(){ //Orders for Admins
    let sub = this.afs.collection("orders", ref => ref.where("consultationID", "==", this.consult.ID)).valueChanges().subscribe(async (orders:any) => {
      this.orders = orders.sort((a,b) => Number(a.date.seconds) - Number(b.date.seconds));;
    });
    this.allSubscription.add(sub);
  }

  getDateMinusMonths(months: number): string {
    const date = new Date();
    // Subtract months from the current date
    date.setMonth(date.getMonth() - months);

    // Format the date to YYYY-MM-DD
    return date.toISOString().split('T')[0]; // "YYYY-MM-DD"
  }

  getAllOrdersForAdmins(){ //Orders for Admins
    let last6Months = new Date(this.getDateMinusMonths(18));
    this.afs.firestore.collection("orders").where("date", ">", last6Months).get().then(async (orders:any) => {
      this.afs.firestore.collection("users").get().then(async (users:any) => {
        this.orders = await orders.docs.map(doc => doc.data()).filter(o => !testUsers.includes(o.email)).sort((a,b) => Number(b.date.seconds) - Number(a.date.seconds));
        let allUsers = users.docs.map(doc => doc.data());
        this.orders.forEach(order => {
          let user = allUsers.filter(u => u.uid == order.uid)[0];
          if(user){
            order.customer = user.firstName + " " + user.lastName1;
          }
        });
      })
    });
  }

  copyTextToClipboard() {
    // console.warn('Copying text to clipboard: ', data);
    let orderArray = this.orders.filter(order => {
      if(this.handleFilter(order)){
        return order;
      }
    })
    let textArray = orderArray.map(order => {
      return [order.customer, order.orderNo, order.date
      .toDate().toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit"
      }), 
      order.orderTotal]
    })

    let testString = (data: string[][]): string => {
      return data.map(row => row.join("\t")).join("\n");
    };
    navigator.clipboard.writeText(testString(textArray)).then(() => {
      console.log('Text copied to clipboard', textArray);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }

  ngOnDestroy(){
    this.allSubscription.unsubscribe();
  }

  emmiterListener(product){
    this.orderDetails(product.order);
  }

  async getConsult(id:string){
    return new Promise<any>(async (resolve, reject) => {
      if(id){
        let sub = this.afs.collection("consultation").doc(id).valueChanges().subscribe(async (consult) => {
          this.consult = consult;
          resolve(consult);
        });
        this.allSubscription.add(sub);
      }
      else {
        this.consult = undefined;
        resolve(null);
      }
    });
  }

  dismissListener(){
    this.modalService.dismissAll();
  }

  handleFilter(order){
    return this.filter == "all" || order.paymentStatus == this.filter || (this.filter == "refill" && order.refill) || (this.filter == 'pendingRefill' && order.refill && order.paymentStatus == 'pending');
  }
}
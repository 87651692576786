<div class="main">
  <div class="header">
    <h3 class="title">{{'EXTERNAL-PATIENT-FORM.TITLE' | translate}}</h3>
    <p class="title">{{'EXTERNAL-PATIENT-FORM.DESCRIPTION' | translate}}</p>
  </div>
  <!-- <div class="progress-section d-flex">
    <div class="simulate-steps" [ngClass]="{'completed': account.phone !== null}"></div>
    <div class="simulate-steps ml-3"></div>
    <div class="simulate-steps ml-3"></div>
    <div class="simulate-steps ml-3"></div>
  </div> -->

    <div class="form-group mt-2">
      <label for="patientEmail">{{'EXTERNAL-PATIENT-FORM.EMAIL' | translate}} {{'EXTERNAL-PATIENT-FORM.OPTIONAL' | translate}}</label>
      <input type="email" class="form-control" id="patientEmail" placeholder="{{'EXTERNAL-PATIENT-FORM.EMAIL' | translate}}"  [(ngModel)]="account.email">
    </div>
    <div class="form-group mt-2">
      <label for="patientPhone">{{'EXTERNAL-PATIENT-FORM.PATIENT-PHONE' | translate}}</label>
      <input type="tel" class="form-control" id="patientPhone" placeholder="{{'EXTERNAL-PATIENT-FORM.PATIENT-PHONE' | translate}}" mask="(000) 000-0000" [(ngModel)]="account.phone">
    </div>
    <div class="form-group">
      <label for="patientName">{{'EXTERNAL-PATIENT-FORM.PATIENT-NAME' | translate}}</label>
      <input type="text" class="form-control" id="patientName" placeholder="{{'EXTERNAL-PATIENT-FORM.PATIENT-NAME' | translate}}" [(ngModel)]="account.firstName">
    </div>
    <div class="form-group">
      <label for="patientLastName">{{'EXTERNAL-PATIENT-FORM.PATIENT-LASTNAME' | translate}}</label>
      <input type="text" class="form-control" id="patientLastName" placeholder="{{'EXTERNAL-PATIENT-FORM.PATIENT-LASTNAME' | translate}}" [(ngModel)]="account.lastName1">
    </div>
    <div class="form-group">
      <label for="patientDOB">{{'EXTERNAL-PATIENT-FORM.DATE-OF-BIRTH' | translate}}</label>
      <input type="date" class="form-control" id="patientDOB" placeholder="{{'EXTERNAL-PATIENT-FORM.DATE-OF-BIRTH' | translate}}" [(ngModel)]="account.birthdate">
    </div>
    <div class="form-group">
      <label for="patientBG">{{'EXTERNAL-PATIENT-FORM.PATIENT-BG' | translate}}</label>
      <ng-select id="patientBG" [(ngModel)]="account.biologicalgender">
        <ng-option value="Female">{{'PROFILE.FEMALE' | translate}}</ng-option>
        <ng-option value="Male">{{'PROFILE.MALE' | translate}}</ng-option>
      </ng-select>
    </div>
    <div class="form-group">
      <label for="patientBG">{{'ACCOUNTS.EMAIL&PASSWORD.TYPEOFACCOUNT' | translate}}</label>
      <ng-select id="accountType" [(ngModel)]="account.accountType">
        <ng-option value="patient">{{'ACCOUNTS.EMAIL&PASSWORD.PATIENT' | translate}}</ng-option>
        <ng-option value="practitioner">{{'ACCOUNTS.EMAIL&PASSWORD.PRACTITIONER' | translate}}</ng-option>
        <ng-option value="admin">{{'ACCOUNTS.EMAIL&PASSWORD.ADMIN' | translate}}</ng-option>
      </ng-select>
    </div>
    <div *ngIf="account.accountType == 'practitioner'" class="form-group">
      <label for="patientBG">{{'ACCOUNTS.EMAIL&PASSWORD.TYPEOFACCOUNT' | translate}}</label>
      <ng-select [(ngModel)]="account?.typeOfPractitioner && account?.typeOfPractitioner[0]" id="typeOfPractitioner">
        <ng-option value="Doctor">{{'ACCOUNTS.EMAIL&PASSWORD.DOCTOR' | translate}}</ng-option>
        <ng-option value="Psychologist">{{'ACCOUNTS.EMAIL&PASSWORD.PSYCHOLOGIST' | translate}}</ng-option>
        <ng-option value="Pharmacist">{{'ACCOUNTS.EMAIL&PASSWORD.PHARMACIST' | translate}}</ng-option>
      </ng-select>
    </div>

    <div class="d-flex justify-content-center">
      <button id="create-btn" class="btn btn-info w-50" (click)="createUser()">{{'EXTERNAL-PATIENT-FORM.CREATE-ACCOUNT' | translate}}</button>
    </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-refill-before-reasons',
  templateUrl: './refill-before-reasons.component.html',
  styleUrls: ['./refill-before-reasons.component.scss']
})
export class RefillBeforeReasonsComponent implements OnInit {
  @Input() order: any;
  @Output() proccess = new EventEmitter<boolean>();

  reasons = ['I have to travel', "Lost my previous refill", "They expired", "Used more quantity"];
  selectedReasons = [];

  constructor(
    private db: AngularFirestore
  ) { }

  ngOnInit() {

  } 

  addReason(reason){
    if(this.selectedReasons.includes(reason)){
      this.selectedReasons.splice(this.selectedReasons.indexOf(reason), 1);
    }else{
      this.selectedReasons.push(reason);
    }
  }


  storeReasons(){
    if(this.selectedReasons.length > 0){
      this.db.collection("orders").doc(this.order.id).update({refillBeforeReasons: this.selectedReasons}).then(() => {
        this.proccess.emit(true);
      });
    }else{
      let warn = document.getElementById("warn");
      warn.style.display = "block";
    }
  }

}

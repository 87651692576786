<div class="filters">
  <div class="d-flex">
  </div>
  <div (click)="verifyPatient()" class="filters row ml-0 mr-0">
    <div class="filters d-flex justify-content-between">
      <div class="w-100 d-flex" [ngClass]="{'flex-column': change == false}">
        <div *ngIf="change == false" class="create-prescription-div d-flex">
          <!-- <button *ngIf="consult && !consult.conditionName.includes('Psychotherapy') && consult.Status != 'Completed' && user?.accountType != 'admin'" class="btn btn-info mr-2" (click)="getAllPrescriptions()">Previous Prescriptions</button> -->
          <button *ngIf="consult && !consult.conditionName.includes('Psychotherapy') && consult.Status != 'Completed' && allProducts.length != 0" class="btn btn-info mr-2" (click)="generatePrescription()">{{'CONSULT-PRESCRIPTIONS.CREATEPRESCRIPTIONS' | translate}}</button>
          <button *ngIf="consult && !consult.conditionName.includes('Psychotherapy') && consult.Status != 'Completed'" type="button" class="btn btn-info" (click)="isLab = true; openModal(addLab);">
            {{'CONSULT-PRESCRIPTIONS.LABPRESCRIPTIONS' | translate}}
          </button>
        </div>

        <div *ngIf="change == false" class="content content-mobile w-100" style="margin:1% 0">
          <div class="d-flex justify-content-between mobile mt-1">
            <div>
              <div class="d-flex flex-column">
                <div class="col-lg-12">
                  <ng-template [ngTemplateOutlet]="prescription"></ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>  
        
        <div *ngIf="change == true" class="all-products w-100"> <!-- col-lg-8-->
          <div>
            <button class="btn btn-cyan d-flex ml-auto mb-2" (click)="change = false;">{{'CONSULT-PRESCRIPTIONS.CLOSE' | translate}}</button>
          </div>
          <ul ngbNav #justifiednav="ngbNav" [activeId]="2" class="nav-pills nav-justified mb-2">
            <li [ngbNavItem]="2">
              <ng-template ngbNavContent>
                <ng-template [ngTemplateOutlet]="allPacks"></ng-template>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="justifiednav"></div>
          <div class="floating-1" (click)="openSummary()">
            <div class="w-100 d-flex justify-content-center">
              <svg width="46" height="46" style="width: 40px; color:white" fill="" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 6h11"></path>
                <path d="M9 12h11"></path>
                <path d="M9 18h11"></path>
                <path d="M5 6v.01"></path>
                <path d="M5 12v.01"></path>
                <path d="M5 18v.01"></path>
              </svg>
            </div>
          </div>
        </div>
        </div>
      </div>

<ng-template #interactionsList>
<div class="interactions">
  <h6>{{'CONSULT-PRESCRIPTIONS.MEDICATIONSTAKING' | translate }} {{patient.firstName}} {{'CONSULT-PRESCRIPTIONS.CURRENTLYTAKING' | translate }}:</h6>
  <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" *ngFor="let interaction of interactions">
    <ngb-panel>
      <ng-template ngbPanelTitle class="text-black">
        <h5 class="m-0">{{'CONSULT-PRESCRIPTIONS.INTERACTIONSWITH' | translate }} {{interaction.minConcept[0].name}} </h5>
      </ng-template>
      <ng-template ngbPanelContent>
        <h5>{{'CONSULT-PRESCRIPTIONS.CANCAUSE' | translate }}</h5>
      <p class="interaction-desc">{{interaction.description}}</p>
      <h6>{{'CONSULT-PRESCRIPTIONS.AKA' | translate }}</h6>
      <ul>
        <li>{{interaction.minConcept[0].name}}</li>
      </ul>
      <a href="{{interaction.interactionConcept[1].sourceConceptItem.url}}" target="_blank" >{{'CONSULT-PRESCRIPTIONS.MOREDETAILS' | translate }}</a>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
  <p *ngIf="interactions.length == 0">{{'CONSULT-PRESCRIPTIONS.NOINTERACTIONS' | translate}}</p>
</div>
</ng-template>

<app-reload *ngIf="loading" [message]="loadingMessage"></app-reload>


<ng-template #prescription let-modal>
<div class="presc-list-rx-lab">
  <span class="font-size-20">{{'CONSULT-PRESCRIPTIONS.CONSULTPRESC' | translate}}</span>
  <div class="d-flex flex-wrap mt-2">
    <div class="radio-card uncheckedProduct" [ngClass]="{'checkProduct': setChecked(product), 'hide': !patientProduct[0].name}" *ngFor="let product of patientProduct" >
      <div class="w-100 d-flex justify-content-end align-items-end" style="float: right;">
        <p class="recommended">{{'CONSULT-PRESCRIPTIONS.PATIENTSELECTION' | translate}}</p>
      </div>
      <div class="product-card">
        <div class="card-content d-flex w-100">
          <img style="height: 100px;" src="{{product.imgRef}}" alt=""/>
          <div class="flex-column">
            <p class="mt-2 mb-0 bold font-size-16">{{product.name | translation:user.preferedLang }} {{product.productDosage}} {{product.productDosageForm}} - {{product.productQty}} {{'CONSULT-PRESCRIPTIONS.PILLPACK' | translate}}</p>
            <p class="m-0">{{'CONSULT-PRESCRIPTIONS.REFILLQTY' | translate }} &nbsp;{{product.refillQty}}</p>
          </div>
      </div>
        
        <div class="w-100 d-flex justify-content-center align-items-center">
          <button *ngIf="consult && !consult.conditionName.includes('Psychotherapy') && consult.Status != 'Completed'" class="btn btn-info" (click)="createPrescriptionBasedOnPatientSelection(product)">{{'CONSULT-PRESCRIPTIONS.CREATEPRESCRIPTION' | translate}}</button>
        </div>
      </div>
    </div>
    <label for="{{presc.ID}}" class="radio-card uncheckedProduct" *ngFor="let presc of prescriptions; let i = index">
      <input hidden type="radio" name="radio-card" id="{{presc.ID}}"/>
      <div class="">          
        <div class="card-content">
          <div class="sections ml-auto" style="width:fit-content !important; text-align-last: end">
            <span *ngIf="presc.status == 'Signed' && presc.prescription_type == 'Rx'" class="prescription-status bold" (click)="getPrescriptionsItems(presc); goToPrescription('prescription', presc.ID); openPrescriptionPDF(presc)" [ngClass]="{'badge-soft-danger': presc.status === 'Unsigned', 'badge-soft-success': presc.status === 'Signed'}">{{'CONSULT-PRESCRIPTIONS.SIGNED' | translate }}</span>
            <span *ngIf="presc.status == 'Signed' && presc.prescription_type == 'Lab'" class="prescription-status bold" (click)="openPrescriptionPDF(presc)" [ngClass]="{'badge-soft-danger': presc.status === 'Unsigned', 'badge-soft-success': presc.status === 'Signed'}">{{'CONSULT-PRESCRIPTIONS.SIGNED' | translate }}</span>
            <span *ngIf="presc.status == 'Unsigned' && presc.valid == true" class="prescription-status bold" (click)="getPrescriptionsItems(presc); goToPrescription('prescription', presc.ID); openPrescriptionPDF(presc)" [ngClass]="{'badge-soft-danger': presc.status === 'Unsigned', 'badge-soft-success': presc.status === 'Signed'}">{{'CONSULT-PRESCRIPTIONS.UNSIGNED' | translate }}</span>
          </div>
          <div class="d-flex mb-2 align-items-center">
            <div class="d-flex img">
              <img *ngIf="presc.prescription_type == 'Rx'" src="{{rxLogo}}" alt="" class="mx-auto justify-content-center"/>
              <img  *ngIf="presc.prescription_type == 'Lab'" src="{{labLogo}}" alt=""/>
            </div>
            <div>
              <h3 *ngIf="presc.prescription_type == 'Rx'" class="prescription-type m-0 bold" style="text-align: start;">{{'CONSULT-PRESCRIPTIONS.MEDICALPRESCRIPTION' | translate }}</h3>
              <h3 *ngIf="presc.prescription_type == 'Lab'" class="prescription-type m-0 bold" style="text-align: start;">{{'CONSULT-PRESCRIPTIONS.LABORATORY' | translate }}</h3>
              <p *ngIf="presc && presc?.date?.seconds" class="m-0">{{'CONSULT-PRESCRIPTIONS.CREATED' | translate}}: {{(presc.date.seconds * 1000) | date:'MMMM dd, yyyy hh:mm aa':'':currentLang}}</p>
              <p *ngIf="presc && presc?.signedDate?.seconds" class="m-0">{{'CONSULT-PRESCRIPTIONS.SIGNED' | translate}}: {{presc.signedDate.toDate() | date:'MMMM dd, yyyy hh:mm aa':'':currentLang}}</p>
              <!-- <p *ngIf="presc.expirationDate && presc.expirationDate.toDate() > today" class="m-0" [ngClass]="{'text-success': presc.expirationDate.toDate() >= aMonthFromNow, 'text-primary': presc.expirationDate.toDate() <= aMonthFromNow && presc.expirationDate.toDate() > today}">{{'CONSULTATIONS.EXPIRES' | translate}} {{presc.expirationDate.toDate() | date:'MMMM dd, yyyy hh:mm aa':'':currentLang}}</p>
              <p *ngIf="presc.expirationDate && presc.expirationDate.toDate() <= today" class="m-0" [ngClass]="{'text-danger': presc.expirationDate.toDate() <= today}">{{'ORDERS.STATUS.EXPIRED' | translate}}: {{presc.expirationDate.toDate() | date:'MMMM dd, yyyy hh:mm aa':'':currentLang}}</p> -->
              <div *ngFor="let items of prescriptionsItems[i];" class="prescriptionItems d-flex flex-column justify-content-center">
                <p class="mb-0">- {{items.productName ? items.productName : items.labName}}</p>
              </div>
            </div>
          </div>
          <div *ngIf="labPdfs[i] && labPdfs[i].length > 0" class="mt-2">
            <p class="m-0 bold cyan">{{'CONSULTATIONS.UPLOADEDDOCS' | translate}}</p>
            <div *ngFor="let pdf of labPdfs[i]">
              <button class="orangeText" style="background: transparent;border: 0; text-decoration: underline;" (click)="prescriptionID = presc.ID; currentPdf = pdf.data().pdf; showPDF(showPdf)">{{pdf.data().pdfName}}</button>
            </div> 
          </div>
        </div>
        <div class="buttons d-flex justify-content-center mx-auto" *ngIf="presc.valid">
          <button *ngIf="presc.internal && presc.status == 'Unsigned'" [disabled]="prevConsult || consult.Status == 'Completed' || user?.accountType == 'admin'" class="btn btn-info" (click)="change = true; prescriptionRef = presc; prescriptionCurrentItems()">{{'CONSULT-PRESCRIPTIONS.VIEWORCHANGE' | translate}}</button>
          <button *ngIf="presc.prescription_type == 'Lab' && presc.status == 'Unsigned'" [disabled]="prevConsult || consult.Status == 'Completed' || user?.accountType == 'admin'" class="btn btn-info" (click)="isLab = true; prescriptionRef = presc; prescriptionCurrentItems();">{{'CONSULT-PRESCRIPTIONS.VIEWORCHANGE' | translate}}</button>
          <button *ngIf="!presc.z_processedB64 && labPdfs[i] && labPdfs[i].length == 0" [disabled]="prevConsult || consult.Status == 'Completed' || user?.accountType == 'admin'" class="btn btn-info ml-2" (click)="deletePrescription(presc);">{{'CONSULT-PRESCRIPTIONS.INVALIDATE' | translate}}</button>
        </div>
        <div class="buttons d-flex flex-column justify-content-center mx-auto" *ngIf="!presc.valid">
          <h3>{{'CONSULT-PRESCRIPTIONS.INVALID' | translate}}</h3>
        </div>
      </div>
    </label>
  </div>
  <hr *ngIf="all_prescriptions.length > 0">
  <span *ngIf="all_prescriptions.length > 0" class="font-size-20">{{'CONSULT-PRESCRIPTIONS.PRESCHISTORY' | translate}}</span>
  <ng-template *ngIf="all_prescriptions.length > 0" [ngTemplateOutlet]="allPrescriptions"></ng-template>
</div>
</ng-template>

<ng-template #addManualProduct let-modal>
  <div class="add-rx">
    <div class="row modal-header">
      <h3 *ngIf="!isEdit" style="width: 90%;" class="title">{{'CONSULT-PRESCRIPTIONS.SEARCH' | translate }}</h3>
      <i class="ri-close-fill" style="font-size: 23px;" (click)="modal.close('Cross click')"></i>
    </div>

    <div class="content" style="min-height: 90%;">
      <div class="prescription-options">
        <p class="labels">{{'CONSULT-PRESCRIPTIONS.MED' | translate}}</p>
        <ng-select placeholder="{{'CONSULT-PRESCRIPTIONS.SEARCHPLACEHOLDER' | translate }}"
          [multiple]="false" [(ngModel)]="finalProductTest[0]['productName']" (change)="filterAtributes(finalProductTest[0]['productName'], 'productName')">
          <ng-option *ngFor="let atr of productFilter[0]" value="{{atr.productName}}">{{atr.productName}}</ng-option>
        </ng-select>

        <p *ngIf="productFilter[1]" class="labels">{{'CONSULT-PRESCRIPTIONS.DOSAGE' | translate }}</p>
        <ng-select *ngIf="productFilter[1]" class="dose" placeholder="{{'CONSULT-PRESCRIPTIONS.DOSE' | translate }}" (change)="filterAtributes(finalProductTest[1]['productDosage'], 'productDosage')" [(ngModel)]="finalProductTest[1]['productDosage']">
          <ng-option *ngFor="let atr of productFilter[1]" value="{{atr.productDosage}}">{{atr.productDosage}}</ng-option>
        </ng-select>

        <p *ngIf="productFilter[2]" class="labels">{{'CONSULT-PRESCRIPTIONS.QTY' | translate }}</p>
        <ng-select *ngIf="productFilter[2]" class="dose" placeholder="{{'CONSULT-PRESCRIPTIONS.QTY' | translate }}" (change)="filterAtributes(finalProductTest[2]['productQty'], 'productQty')" [(ngModel)]="finalProductTest[2]['productQty']">
          <ng-option *ngFor="let atr of productFilter[2]" value="{{atr.productQty}}">{{atr.productQty}}</ng-option>
        </ng-select>
        
        <div class="flex-column">
          <p *ngIf="productFilter[3]" class="labels">{{'CONSULT-PRESCRIPTIONS.REFILLEVERY' | translate }}</p>
          <ng-select *ngIf="productFilter[3]" class="dose" placeholder="{{'CONSULT-PRESCRIPTIONS.REFILLEVERY' | translate }}" (change)="filterAtributes(finalProductTest[3]['productRefillIntervalDays'], 'productRefillIntervalDays')" [(ngModel)]="finalProductTest[3]['productRefillIntervalDays']">
            <ng-option *ngFor="let atr of productFilter[3]" value="{{atr.productRefillIntervalDays}}">{{atr.productRefillIntervalDays | translation:currentLang}}</ng-option>
          </ng-select>
        </div>

        <div class="flex-column">
          <p *ngIf="finalProductTest[3]" class="labels">{{'CONSULT-PRESCRIPTIONS.REFILLQTY' | translate }}</p>
          <ng-select *ngIf="finalProductTest[3]" class="dose" placeholder="{{'CONSULT-PRESCRIPTIONS.REFILLEVERY' | translate }}" [(ngModel)]="refillQty">
            <ng-option *ngFor="let refill of refillCounter(this.productRemaining[0])" value="{{refill}}">{{refill}}</ng-option>
          </ng-select>
        </div>

        <div class="d-flex w-100 justify-content-between">
          <div class="w-100">
            <p class="labels">{{'CONSULT-PRESCRIPTIONS.INSTRUCTIONS' | translate }}</p>
            <input type="text" maxlength="90" placeholder="({{'SIGNUP.OPTIONAL' | translate}})" class="customInput" [(ngModel)]="rxInstruction" >
          </div>
        </div>

        <div class="row justify-content-center portrait">
          <button *ngIf="finalProductTest[3]" type="button" class="send-prescription mt-5" (click)="manageProduct(productRemaining[0])">{{'CONSULT-PRESCRIPTIONS.SAVE1' | translate}}</button>
        </div>
      </div>
    </div>
</div> 
</ng-template>

<ng-template #addLab let-modal>
  <div class="row modal-header">
    <h3 style="width: 90%;" class="title">{{'CONSULT-PRESCRIPTIONS.SEARCHLAB' | translate }}</h3>
    <i class="ri-close-fill" style="font-size: 23px;" (click)="dismiss()"></i>
  </div>
  <div class="col-sm-6 col-md-6 mx-auto mb-2">
    <div id="tickets-table_filter" class="dataTables_filter position-relative">
      <input type="text" name="searchTerm" class="form-control searchbar form-control-sm" 
        aria-controls="tickets-table" placeholder="{{'NOTIFICATIONS.SEARCH' |  translate}}" [(ngModel)]="searchText"/> 
    </div>
  </div>   

  <div class="container">
    <table class="data-table">
      <thead>
        <tr>
          <th>Laboratories</th> 
          <th>Diagnostics</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let lab of laboratories | filter:searchText; let i = index">
          <td class="first-col">
            <input type="checkbox" name="radio-card" id="{{lab.ID}}" [checked]="setLabChecked(lab)" (click)="handleLabSelection(lab)"/>
            <h4 class="title mb-0">{{lab.labName}}</h4>
          </td>
          <!-- <td><h4 class="title mb-0">{{lab.labName}}</h4></td> -->
          <td>
            <div *ngIf="lab.diagnostics">
              <p *ngIf="lab.diagnostics" class="m-0 bold cyan">{{'VIEWCONSULTS.DIAGNOSTICS.DIAGNOSTICS' | translate}}</p>
              <div *ngFor="let items of currentPrescriptionItems; let k = index">
                <div class="w-100 d-flex flex-row" *ngFor="let d of items.diagnostics; let j = index">
                  <span *ngIf="items.labName == lab.labName" class="w-100">{{d.code_name}}</span>
                  <i *ngIf="items.labName == lab.labName" class="fas fa-pen ml-3 text-success" (click)="documentLabDiagnostic(d)"  [ngClass]="{ 'disableDiv': prevConsult || consult?.Status == 'Completed' || user?.accountType == 'admin'}"></i>
                  <i *ngIf="items.labName == lab.labName" class="fas fa-trash ml-3 " (click)="deleteLabDiagnostic(lab, j)" [ngClass]="{ 'disableDiv': prevConsult || consult?.Status == 'Completed' || user?.accountType == 'admin'}"></i>
                </div>
              </div>
              <div *ngFor="let items of currentPrescriptionItems; let k = index">
                <button *ngIf="items.labName == lab.labName" class="btn btn-cyan mb-1" style="font-size: 13px !important;" (click)="searchDiagnostics(addDiagnosticsInLab, items, lab)">{{'VIEWCONSULTS.DIAGNOSTICS.ADDDIAGNOSTIC' | translate}}</button>
              </div>

            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>




  <div class="row justify-content-center portrait">
    <button *ngIf="isEdit == false" type="button" class="btn button btn-primary" (click)="createPrescription()">{{'CONSULT-PRESCRIPTIONS.SAVE' | translate }}</button>
    <button *ngIf="isEdit == true" type="button" class="btn button btn-primary" (click)="saveLabPrescription(prescriptionRef)">{{'CONSULT-PRESCRIPTIONS.SAVE' | translate }}</button>
  </div>
</ng-template>

<ng-template #signaturePad let-modal>
<i class="ri-close-fill" style="font-size: 23px; text-align: end;" (click)="modal.close('Cross click')"></i>
<app-signaturepad [practitionerProfile]="practitioner" [currentUser]="user.uid" [consultID]="consult.ID" [consult]="[consult]" (signature)="storeSignature($event)"></app-signaturepad>
</ng-template>

<ng-template #pdfViewer let-modal>
<div *ngIf="base64 != undefined && (prescriptionRef.status == 'Unsigned')" class="pdf-container media-body overflow-hidden">
  <ngx-extended-pdf-viewer *ngIf="base64 != undefined" [base64Src]="base64" backgroundColor="#e8e8ef"
    [showZoomButtons]="true"  [useBrowserLocale]="true"
    [sidebarVisible]="false" [showHandToolButton]="true" [zoom]="'50%'">
  </ngx-extended-pdf-viewer>
</div>
<div *ngIf="base64 == undefined && prescriptionRef.status == 'Unsigned'" class="pdf-container-empty loading-skeleton"></div>

<div *ngIf="(!prescriptionRef?.erpPrescriptionRef && prescriptionRef?.pharmacy != 'External Pharmacy') && prescriptionRef.status == 'Signed'" class="pharmacy-step">
  <div *ngIf="!showPreview" id="prescription-preview" class="prescription-preview" style="height: 25%;">
    <span>{{'CONSULT-PRESCRIPTIONS.PREVIEW' | translate}}</span> 
    
    <div class="pdf-preview" (click)="showPreviewLG($event)">
      <button class="btn btn-primary">{{'CONSULT-PRESCRIPTIONS.VIEWPDF' | translate}}</button>
    </div>
  </div>
  <div  *ngIf="!showPreview && prescriptionRef.prescription_type == 'Rx'" class="pharmacy-selection">
    <div class="title">
      <h2>{{'CONSULT-PRESCRIPTIONS.PHARMACY1' | translate}}</h2>
      <p>{{'CONSULT-PRESCRIPTIONS.SELECTPHARMACY' | translate}}</p>
    </div>
    <ul ngbNav #justifiednav="ngbNav" [activeId]="1" class="nav-pills nav-justified">
      <li [ngbNavItem]="1">
        <a ngbNavLink>
          <span>{{'CONSULT-PRESCRIPTIONS.DZEUSPHARMACY' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="dzeusPharmacy"></ng-template>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>
          <span>{{'CONSULT-PRESCRIPTIONS.EXTERNALPHARMACY' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="externalPharmacy"></ng-template>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="justifiednav"></div>
  </div>

  <div  *ngIf="!showPreview && prescriptionRef.prescription_type == 'Lab'" class="pharmacy-selection">
    <ng-template [ngTemplateOutlet]="sendToLab"></ng-template>
  </div>
  
  <div *ngIf="showPreview" class="preview-lg animate pop">
    <div style="align-items: center; font-size: 18px;" class="d-flex" (click)="showPreview = false">
      <i class="fa fa-angle-left"></i>
      <span class="ml-3">{{'CONSULT-PRESCRIPTIONS.BACK' | translate}}</span>
    </div>
    <ngx-extended-pdf-viewer class="animate pop" *ngIf="base64" [base64Src]="base64" backgroundColor="#e8e8ef"
      [showZoomButtons]="true"  [useBrowserLocale]="true"
      [sidebarVisible]="false" [showHandToolButton]="false" [zoom]="'50%'">
    </ngx-extended-pdf-viewer>
  </div>
</div>
<div *ngIf="(prescriptionRef?.erpPrescriptionRef || prescriptionRef?.pharmacy == 'External Pharmacy') && prescriptionRef.status == 'Signed'" class="sent-prescription flex-column">
  <div *ngIf="!showPreview" id="prescription-preview" class="prescription-preview" style="height: 25%;">
    <span>{{'CONSULT-PRESCRIPTIONS.PREVIEW' | translate}}</span>
    <div class="pdf-preview" (click)="showPreviewLG($event)">
      <button class="btn btn-primary">{{'CONSULT-PRESCRIPTIONS.VIEWPDF' | translate}}</button>
    </div>
  </div>
  <div *ngIf="showPreview" class="preview-lg animate pop">
    <div style="align-items: center; font-size: 18px;" class="d-flex" (click)="showPreview = false">
      <i class="fa fa-angle-left"></i>
      <span class="ml-3">{{'CONSULT-PRESCRIPTIONS.BACK' | translate}}</span>
    </div>
    <ngx-extended-pdf-viewer class="animate pop sendView" *ngIf="base64" [base64Src]="base64" backgroundColor="#e8e8ef"
      [showZoomButtons]="true"  [useBrowserLocale]="true"
      [sidebarVisible]="false" [showHandToolButton]="false" [zoom]="'50%'">
    </ngx-extended-pdf-viewer>
  </div>
  <div *ngIf="!showPreview" class="content">
    <div class="check-wrap"></div>
    <h2>{{'CONSULT-PRESCRIPTIONS.PRESCSENT' | translate}}</h2>
    <p *ngIf="prescriptionRef.pharmacy" class="sent-to">{{'CONSULT-PRESCRIPTIONS.PRESCWASSENT' | translate}}<span class="pharmacy">{{prescriptionRef.pharmacy}}</span>{{'CONSULT-PRESCRIPTIONS.PHARMACY' | translate}}</p>
    <p *ngIf="!prescriptionRef.pharmacy" class="sent-to">{{'CONSULT-PRESCRIPTIONS.PRESCWASSENT' | translate}}<span class="pharmacy">{{patient.email}}</span></p>
    <div class="prescription-contents">
      <div *ngFor="let product of sentProducts" class="products d-flex">
        <img src="{{product.image | translation:user.preferedLang}}" alt="">
        <span>{{product.productName | translation:user.preferedLang}}<span *ngIf="product.productType == 'product'"><span *ngIf="product.productBrandName != product.productName && product.productBrandName != ''">&nbsp;({{product.productBrandName}})</span> {{product.productDosage}} {{product.productDosageForm}} - {{product.productQty}} {{'CONSULT-PRESCRIPTIONS.PILLPACK' | translate}}</span></span><br>
        <span class="ml-2"> # {{product.productQty}}</span>
      </div>
    </div>

    <div class="d-flex mt-3 w-50">
      <button class="close-prescription" (click)="closePrescription()">{{'CONSULT-PRESCRIPTIONS.CLOSE' | translate}}</button>
    </div>
  </div>
</div>

<div *ngIf="prescriptionRef.status == 'Unsigned' && prescriptionRef.prescription_type == 'Lab'">
  <button class="btn btn-cyan" (click)="createJustificationLetter(); openModal(modifyLetter);">Attach Justification Letter</button>
</div>

<div class="total d-flex justify-content-end">
  <button class="btn btn-primary mb-5" [ngClass]="{'disableDiv': prevConsult || consult?.Status == 'Completed' || user?.accountType == 'admin' || prescriptionsItems.length == 0}" (click)="signPad()" [disabled]="!base64" [hidden]="prescriptionRef.status == 'Signed'">{{'CONSULT-PRESCRIPTIONS.FINISH' | translate }}</button>
</div>
</ng-template>  

<ng-template #internalPrescription let-modal>
<div class="row mt-3 product-wizard">
  <div class="col-lg-12">
    <div class="row justify-content-between">
      <h2>{{'CONSULT-PRESCRIPTIONS.PRESCRIPTION' | translate }}</h2>
      <i class="ri-close-fill" style="font-size: 23px;" (click)="dismiss()"></i>
    </div>
    <ng-template [ngTemplateOutlet]="pdfViewer"></ng-template>
  </div>
</div>
</ng-template>

<ng-template #dzeusPharmacy>
<div class="details">
  <div class="section">
    <div class="options">
      <img src="../../../../assets/images/FAQ_Icons/Dzeus_Icono_d+.svg" class="cropped" style="border-radius: 0;" alt="">
      <div class="d-flex flex-column patient align-items-center justify-content-center">
        <span>{{'CONSULT-PRESCRIPTIONS.DZEUSPHARMACY' | translate}}</span>
        <p class="m-0">Barranquitas, Puerto Rico 00705</p>
      </div>
      <button class="send-prescription" id="send-internal" (click)="sendInternal($event)">{{'CONSULT-PRESCRIPTIONS.SEND' | translate}}</button>
      <!-- <button class="send-prescription" id="send-internal" (click)="sendInternal(false)">{{'CONSULT-PRESCRIPTIONS.SEND' | translate}}</button> -->
    </div>
  </div>
</div>

</ng-template>

<ng-template #externalPharmacy>
<div class="details">
  <div class="section">
    <h3 class="ml-4">{{'CONSULT-PRESCRIPTIONS.COMMINGSOON' | translate}}</h3>
    <div class="options" >
      <div class="external">
        <span>{{'CONSULT-PRESCRIPTIONS.SEARCHPHARMACY' | translate}}</span>
        <ng-select placeholder="{{'CONSULT-PRESCRIPTIONS.SEARCHPLACEHOLDER' | translate }}" [loading]="loadingLabs"
          [multiple]="false"  (keyup)="searchPharmacies($event)" (change)="selectPharmacy($event)">
          <ng-option *ngFor="let pharmacy of pharmacies['name.full']" [value]="pharmacy">{{pharmacy}}</ng-option>
        </ng-select>
      </div>
    </div>
    <a *ngIf="claims && (claims.devAdmin || claims.superAdmin)" class="btn btn-primary" (click)="sendExternal($event)">{{'CONSULT-PRESCRIPTIONS.SENDPHARMACY' | translate}}</a>
  </div>
</div>
</ng-template>

<ng-template #sendToLab>
  <div class="content" style="text-align: -webkit-center;">
    <div class="check-wrap"></div>
    <h2>An alert has been sent to the patient to download their prescription.</h2>
    <div class="d-flex mt-3 w-50">
      <button class="close-prescription" (click)="closePrescription()">{{'CONSULT-PRESCRIPTIONS.CLOSE' | translate}}</button>
    </div>
  </div>

</ng-template>

<ng-template #allPacks>
<h2 class="">{{'CONSULT-PRESCRIPTIONS.ALLPRODUCTS' | translate}}</h2>
<!-- <button *ngIf="products.length != allProducts.length - 1  - patientProduct.length" class="btn btn-cyan mb-3" (click)="viewProducts('all')">{{'CONSULT-PRESCRIPTIONS.VIEWALLPRODUCTS' | translate}}</button>
<button *ngIf="products.length == allProducts.length - 1 - patientProduct.length" class="btn btn-cyan mb-3" (click)="viewProducts('')">{{'CONSULT-PRESCRIPTIONS.FFPS' | translate}}</button> -->
<button *ngIf="products && allProducts && products.length != allProducts.length - 1" class="btn btn-cyan mb-3" (click)="viewProducts('all')">{{'CONSULT-PRESCRIPTIONS.VIEWALLPRODUCTS' | translate}}</button>
<button *ngIf="products && allProducts && products.length == allProducts.length - 1" class="btn btn-cyan mb-3" (click)="viewProducts('')">{{'CONSULT-PRESCRIPTIONS.FFPS' | translate}}</button>
<button class="btn btn-info ml-3 mb-3" (click)="openModal(addManualProduct)">{{'CONSULT-PRESCRIPTIONS.MANUALINPUT' | translate}}</button>
<div *ngIf="loading == false" class="allPacks d-flex"> <!--  class="grid-wrapper" -->
  <div class="left w-75 d-flex flex-wrap" style="height: fit-content;">
    <label for="{{product.productId}}" class="radio-card animate pop uncheckedProduct" [ngClass]="{'checkProduct': setChecked(product)}" *ngFor="let product of products" >
      <input *ngIf="productGroups.canChooseMultipleProducts == false" hidden disabled type="radio" name="radio-card" id="{{product.productId}}" [checked]="setChecked(product)"/>
      <input *ngIf="productGroups.canChooseMultipleProducts == true"  hidden disabled type="checkbox" name="radio-card" id="{{product.productId}}"  [checked]="setChecked(product)"/>
      <div *ngIf="requiredProducts.includes(product.odooProductId)" class="w-100 d-flex justify-content-end align-items-end" style="float: right;">
        <p class="recommended">{{'AUTHORIZEUSERSDOC.REQUIRED' | translate}}</p>
      </div>
      <div class="product-card">
        <span *ngIf="productGroups.canChooseMultipleProducts == false" class="check-icon"></span>
        <span *ngIf="productGroups.canChooseMultipleProducts == true"  class="icon-check d-flex" style="width: 14px; height: 14px;"></span>
      
        <div class="card-content d-flex">
          <img style="height: 100px;" src="{{product.productImg}}" alt=""/>
          <div class="w-100 d-flex flex-column">
            <p class="mt-2 mb-0 bold font-size-16" style="text-align: start;">{{ product.productName | translation:user.preferedLang }} <span class="bold" *ngIf="product.productBrandName != product.productName && product.productBrandName != ''">({{product.productBrandName}})</span> {{product.productDosage}} {{product.productDosageForm}} - {{product.productQty}} {{'CONSULT-PRESCRIPTIONS.PILLPACK' | translate}}</p>
            <p class="mt-0 mb-1 bold font-size-16" style="text-align: start;">{{'CONSULT-PRESCRIPTIONS.REFILLEVERY' | translate }} {{product.productRefillIntervalDays }} {{'CONSULT-PRESCRIPTIONS.DAYS' | translate }}</p>
            <button class="btn btn-info mx-auto" style="width: fit-content;" (click)="manageProduct(product)">{{'CONSULT-PRESCRIPTIONS.SELECT' | translate}}</button>
          </div>
        </div>
      </div>
    </label>
  </div>
  <div class="right w-25">
    <ng-template [ngTemplateOutlet]="prescriptionSummary"></ng-template>
  </div>
</div>
</ng-template>

<ng-template #prescriptionSummary let-modal>
<button type="button" class="close arrow" aria-label="Close" style="text-align-last: right; font-size: 30px;" (click)="modal.close()">
  <span aria-hidden="true">&times;</span>
</button>
<div *ngIf="change == true && prescriptionRef !== undefined" id="summary" class="selection-summary p-2" style="height: fit-content;">
  <h2 class="d-flex justify-content-center">{{'CONSULT-PRESCRIPTIONS.SELECTIONSUMMARY' | translate}}</h2>

  <div class="details">
    <div class="section w-100">
      <div class="collapsible d-flex justify-content-between" (click)="handleSection()">
        <span class="" (click)="handleSection()">{{'ORDERS.ORDERSUMMARY' | translate}}</span>
      </div>
      <div class="content">
        <div *ngFor="let product of currentPrescriptionItems; let i = index" class="d-flex mb-1 center">
          <div class="d-flex flex-column w-100 justify-content-between center">
            <div class="d-flex w-100 justify-content-between">
              <div class="d-flex flex-column">
                <span class="product-name bold">{{product.productName | translation:currentLang}} <span class="bold" *ngIf="product.productBrandName != product.productName && product.productBrandName != ''">({{product.productBrandName}})</span> {{product.productDosage}} {{product.productDosageForm}}</span>
              </div>
            </div>
            <div class="d-flex flex-column w-100">
              <div class="d-flex flex-column">
                <span class="ml-1 product-name">{{'CONSULT-PRESCRIPTIONS.QTY' | translate}}: {{product.productQty }}</span>
              </div>
              <div class="d-flex align-items-center flex-row">
                <span class="ml-1 font-size-14">{{'CONSULT-PRESCRIPTIONS.REFILL' | translate}}: 
                  <!-- {{product.refillQty}} -->
                </span>
                <div class="flex-column w-50 ml-1 mb-1" [ngClass]="classMap">
                  <!-- <p class="labels">{{'CONSULT-PRESCRIPTIONS.REFILLQTY' | translate }}</p> -->
                  <ng-select (focusout)="onSelectClose();" [ngModelOptions]="{standalone: true}" [clearable]="false" (close)="onSelectClose();" (blur)="onSelectClose();" (focus)="onOpen(product, i)" (open)="onOpen(product, i);" class="dose" placeholder="{{'CONSULT-PRESCRIPTIONS.REFILLEVERY' | translate }}" (change)="updateProduct(product)" [(ngModel)]="product.refillQty">
                    <ng-option *ngFor="let refill of refillCounter(product)" value="{{refill}}">{{refill}}</ng-option>
                  </ng-select>
                </div>
              </div>
              <!-- <div class="d-flex w-100 justify-content-between mb-1">
                <div class="w-100">
                  <p class="labels m-0">{{'CONSULT-PRESCRIPTIONS.INSTRUCTIONS' | translate }}:</p>
                  <input (focusout)="updateProduct(product);" type="text" maxlength="90" class="customInput"  [(ngModel)]="product.rxInstruction" >
                </div>
              </div> -->
              <div class="d-flex justify-content-center">
                <button class="btn btn-cyan fit-content" (click)="removeProduct(product)">{{'CONSULT-PRESCRIPTIONS.REMOVE' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      <div class="invoice d-flex justify-content-between">
        </div>
        <hr>
      </div>
    </div>
    <button class="btn btn-info fit-content mx-auto mb-4 d-flex" (click)="change = false; getPrescriptionsItems(prescriptionRef); goToPrescription('prescription', prescriptionRef.ID); openPrescriptionPDF(prescriptionRef)">{{'CONSULT-PRESCRIPTIONS.SIGNPRESCRIPTION' | translate}}</button>
    <div class="section w-100">
      <div class="collapsible d-flex justify-content-between" (click)="handleSection()">
        <span class="" (click)="handleSection()">{{'CONSULT-PRESCRIPTIONS.INTERACTIONS' | translate}}</span>
      </div>
      <div class="content d-flex">
        <div class="d-flex flex-column">
          <ng-template [ngTemplateOutlet]="interactionsList"></ng-template>
        </div>
      </div>
    </div>
  </div>   
</div>
</ng-template>

<ng-template #pharmacyDetails let-modal>
  <div class="w-100 p-3 d-flex justify-content-between">
    <h3>{{'PHARMACY.PHARMACY' | translate}}</h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="">

    <div class="details" style="align-items: normal;">
      <div class="section">
        
        <div class="content">
          <div class="d-flex mb-1 product-div">
            <img class="doctor" src="{{practitioner.image}}" alt="">
            <div class="d-flex w-100 justify-content-between">
              <div class="d-flex flex-column">
                <span class="ml-3 product-name">{{practitioner.typeOfPractitioner[0]}} {{prescriptionRef.docName}}</span>
                <div class="d-flex justify-content-between">
                  <span class="" style="margin-left: 12%;">{{practitioner.email}}</span>
                </div>
              </div>
              <span class="typeOfPractitioner">{{practitioner.typeOfPractitioner[0]}}</span>
            </div>
          </div>
        </div>
      </div>

      <div  class="section">
        <div class="collapsible d-flex justify-content-between">
          <span class="">{{'PHARMACY.DETAILS' | translate}}</span>
          <i class="fas fa-chevron-down vertical-align"></i>
        </div>
        <div class="content">
          <div class="d-flex flex-column mb-1 product-div">
            <span>{{'PHARMACY.EMAIL' | translate}}:</span>
            <input type="email" style="height: 32px; border: solid #E5E6E8;" [(ngModel)]="pharmacy.email"> <br>
            <span>{{'PHARMACY.LOCATION' | translate}}:</span>
            <input type="text" style="height: 32px; border: solid #E5E6E8;" [(ngModel)]="pharmacy.location"> <br>
          </div>
          <div class="mt-5">
            <button *ngIf="sending" style="border-radius: 0.25rem;" class="btn btn-cyan"> <span id="spinner-checkout" class="spinner spinner-border"></span>
            </button>
            <button *ngIf="!sending" class="btn btn-cyan" (click)="sendToPharmacy()" [disabled]="disableSend()">{{'PHARMACY.SEND' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template> 

<ng-template #allPrescriptions>
  <div class="d-flex flex-wrap">
    <div class="radio-card uncheckedProduct" *ngFor="let presc of all_prescriptions; let i = index">
      <div class="card-content">
        <div class="sections ml-auto" style="width:fit-content !important; text-align-last: end">
          <span *ngIf="presc.status == 'Signed' && presc.prescription_type == 'Rx'" class="prescription-status bold" (click)="getPrescriptionsItems(presc); goToPrescription('prescription', presc.ID); openPrescriptionPDF(presc)" [ngClass]="{'badge-soft-danger': presc.status === 'Unsigned', 'badge-soft-success': presc.status === 'Signed'}">{{'CONSULT-PRESCRIPTIONS.SIGNED' | translate }}</span>
          <span *ngIf="presc.status == 'Signed' && presc.prescription_type == 'Lab'" class="prescription-status bold" (click)="showPreview = true; openPrescriptionPDF(presc)" [ngClass]="{'badge-soft-danger': presc.status === 'Unsigned', 'badge-soft-success': presc.status === 'Signed'}">{{'CONSULT-PRESCRIPTIONS.SIGNED' | translate }}</span>
          <span *ngIf="presc.status == 'Unsigned' && presc.valid == true" class="prescription-status bold" (click)="getPrescriptionsItems(presc); goToPrescription('prescription', presc.ID); openPrescriptionPDF(presc)" [ngClass]="{'badge-soft-danger': presc.status === 'Unsigned', 'badge-soft-success': presc.status === 'Signed'}">{{'CONSULT-PRESCRIPTIONS.UNSIGNED' | translate }}</span>
        </div>
        <div class="d-flex mb-2 align-items-center">
          <div class="d-flex img">
            <img *ngIf="presc.prescription_type == 'Rx'" src="{{rxLogo}}" alt="" class="mx-auto justify-content-center"/>
            <img  *ngIf="presc.prescription_type == 'Lab'" src="{{labLogo}}" alt=""/>
          </div>
          <div>
            <h3 *ngIf="presc.prescription_type == 'Rx'" class="prescription-type m-0 bold" style="text-align: start;">{{'CONSULT-PRESCRIPTIONS.MEDICALPRESCRIPTION' | translate }}</h3>
            <h3 *ngIf="presc.prescription_type == 'Lab'" class="prescription-type m-0 bold" style="text-align: start;">{{'CONSULT-PRESCRIPTIONS.LABORATORY' | translate }}</h3>
            <p class="m-0">{{'CONSULT-PRESCRIPTIONS.CREATED' | translate}}: {{presc.date.toDate() | date:'MMMM dd, yyyy hh:mm aa':'':currentLang}}</p>
            <p *ngIf="presc?.signedDate?.seconds" class="m-0">{{'CONSULT-PRESCRIPTIONS.SIGNED' | translate}}: {{presc.signedDate.toDate() | date:'MMMM dd, yyyy hh:mm aa':'':currentLang}}</p>
            <p *ngIf="presc.expirationDate && presc.expirationDate.toDate() > today" class="m-0" [ngClass]="{'text-success': presc.expirationDate.toDate() >= aMonthFromNow, 'text-primary': presc.expirationDate.toDate() <= aMonthFromNow && presc.expirationDate.toDate() > today}">{{'CONSULTATIONS.EXPIRES' | translate}} {{presc.expirationDate.toDate() | date:'MMMM dd, yyyy hh:mm aa':'':currentLang}}</p>
            <p *ngIf="presc.expirationDate && presc.expirationDate.toDate() <= today" class="m-0" [ngClass]="{'text-danger': presc.expirationDate.toDate() <= today}">{{'ORDERS.STATUS.EXPIRED' | translate}}: {{presc.expirationDate.toDate() | date:'MMMM dd, yyyy hh:mm aa':'':currentLang}}</p>
            <div *ngFor="let items of all_prescriptions_items[i];" class="prescriptionItems d-flex flex-column justify-content-center">
              <p class="mb-0">- {{items.productName ? items.productName : items.labName}}</p>
            </div>
          </div>
        </div>
        <div *ngIf="all_prescriptions_labs[i] && all_prescriptions_labs[i].length > 0" class="mt-2">
          <p class="m-0 bold cyan">{{'CONSULTATIONS.UPLOADEDDOCS' | translate}}</p>
          <div *ngFor="let pdf of all_prescriptions_labs[i]">
            <button class="orangeText" style="background: transparent;border: 0; text-decoration: underline;" (click)="prescriptionID = presc.ID; currentPdf = pdf.data().pdf; showPDF(showPdf)">{{pdf.data().pdfName}}</button>
          </div> 
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showPdf let-modal>
<div>
  <ngx-extended-pdf-viewer *ngIf="currentPdf != undefined" [base64Src]="currentPdf" backgroundColor="#e8e8ef"
    [showZoomButtons]="true" [height]="'75vh'" [width]="'55vw'" [useBrowserLocale]="true"
    [sidebarVisible]="false" [showHandToolButton]="true" [zoom]="'50%'" style="width: 55vw;">
  </ngx-extended-pdf-viewer>
</div>

<button *ngIf="currentPdf != undefined" style="margin-left: auto; margin-right: auto;" class="btn btn-info btn-sm cardBtn mt-2 mb-4" (click)="currentPdf = undefined; closePrescription()">{{'ACCOUNTS.SWAL.CLOSE' | translate}}</button>
</ng-template>


<ng-template #modifyLetter let-modal>
  <div class="p-2">
    <div class="row modal-header">
      <h3>{{'CONSULT-PRESCRIPTIONS.MODIFYLETTER' | translate }}</h3>
      <i class="ri-close-fill" style="font-size: 23px;" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <textarea class="my-2" value="referralText" style="width: 100%; height: 500px; resize: none;" (keyup)="collectMessage($event)" placeholder="{{'REFERRALS.REASONFORREFERRAL' | translate}}" [ngModel]="referralText"></textarea>
    <button class="btn btn-cyan" (click)="justifyLetterAttached = true; modal.close(); signPad()">{{'CONSULT-PRESCRIPTIONS.SAVEANDSIGN' | translate}}</button>
  </div>
</ng-template>

<ng-template #addDiagnosticsInLab let-modal>
  <div class="p-4">
    <div class="row modal-header">
      <h3>{{'VIEWCONSULTS.DIAGNOSTICS.ADDDIAGNOSTIC' | translate }}</h3>
      <i class="ri-close-fill" style="font-size: 23px;" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <div class="d-flex flex-column">
      <!-- <div class="d-flex flex-row">
        <input type="text" class="form-control" placeholder="{{'VIEWCONSULTS.DIAGNOSTICS.SEARCH' | translate}}" [(ngModel)]="searchText1">
      </div> -->

      <ng-select #ngSelect id="diagnostic" class="w-75 mb-2" placeholder="{{'VIEWCONSULTS.DIAGNOSTICS.SEARCH' | translate}}" [loading]="loadingSearch" [multiple]="true"
        (search)='getDiagnostics($event)' (change)="setDiagnostic($event);" [multiple]="false" [clearable]="false" >
        <ng-option *ngFor="let med of filteredDiagnostics" [value]="med" >{{med[0]}} - {{med[1]}}</ng-option>
      </ng-select>

      <div class="d-flex flex-column section mt-2">
        <div class="d-flex flex-column" *ngFor="let diagnostic of possible_diagnostics | filter:searchText1; let i = index">
          <div class="d-flex flex-row">
            <input class="inp-cbx" type="checkbox" name="radio-card" id="{{diagnostic.id}}" [checked]="setDiagnosticChecked(diagnostic)" (click)="addLabDiagnostic(diagnostic)"/>
            <label for="{{diagnostic.id}}" class="cbx">
              <span>
                <svg width="12px" height="10px">
                  <use xlink:href="#check"></use>
                </svg>
              </span>
              <span class="ml-2">{{diagnostic.code_name}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <svg class="inline-svg">
      <symbol id="check" viewBox="0 0 12 10">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
      </symbol>
    </svg>
    <div class="d-flex justify-content-center">
      <button class="btn btn-cyan " (click)="modal.close()">{{'VIEWCONSULTS.DIAGNOSTICS.ADDDIAGNOSTIC' | translate}}</button>
    </div>
  </div>
</ng-template>



<ng-template #documentLabDiagnosticModal let-modal>
  <app-diagnostics [consult]="consult.ID" [labDiagnosticDoc]="labDiagnosticToDocument" (labDiagnosticClose)="modal.close();"></app-diagnostics>
</ng-template>


<ng-template #handlePrevPrescriptionsModal let-modal>
  <div class="p-2">
    <div class="row modal-header">
      <h3 class="m-0">{{'CONSULT-PRESCRIPTIONS.PREVIOUSPRESCRIPTIONS' | translate }}</h3>
      <i class="ri-close-fill" style="font-size: 23px;" (click)="modal.dismiss('Cross click')"></i>
    </div>
    <div class="d-flex flex-column">
      <div class="d-flex flex-column mt-2">
        <h4>The patient has previous prescriptions with active Refills. Do you need to remove  unnecesary pending refills?</h4>
        <div class="d-flex flex-wrap">
          <div class="d-flex flex-column mx-2 p-2 uncheckedProduct w-auto" [ngClass]="{'checkProduct': checkMedication(product)}" style="min-width: 0;" *ngFor="let product of patientActivePrescriptions; let i = index">
            <div class="mb-2 d-flex flex-column">
              <label for="{{product.id}}">
                <input hidden type="checkbox" name="radio-card" id="{{product.id}}" (click)="selectMedication(product)" [checked]="checkMedication(product)"/>
                <div class="product-card">
                  <span class="icon-check d-flex" style="width: 14px; height: 14px;"></span>
                </div>
                <span class="ml-2">{{product.productName}}</span>
              </label>
  
              <!-- <button class="btn btn-cyan" style="width: fit-content;" (click)="selectMedication(product)">Select</button> -->
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center">
          <button class="btn btn-cyan" style="width: fit-content;" (click)="removeMedications()">Remove</button>
          <button class="btn btn-cyan ml-2" style="width: fit-content;" (click)="modal.close(); sendInternal(true)">Continue</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<header *ngIf="router.url != '/404'" id="page-topbar" > 
  <div class="navbar-header flex-column">
    <div class="d-flex w-100 justify-content-between">
      <div class="d-flex align-items-center overflow-x-visible">
        <button type="button" style="font-size: 16.5pt !important" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn"
          (click)="toggleMobileMenu($event)">
          <i class="ri-menu-2-line align-middle"></i>
        </button>
        <!-- <a href="https://dzeus.com" class="logo"> -->
          <div class="d-flex align-items-end">
            <img style="width: 100px; height: auto; vertical-align: initial;" src="/assets/images/logos/Dzeus-Logo.png" alt="" height="50" class="img-container"> 
            <!-- <div (click)="contactUs(chooseLocation, $event);" *ngIf="userData?.accountType == 'patient'" class="cyan d-flex justify-content-end" style="position: relative; line-height: normal; text-decoration: underline;">
              <p class="m-0 bold font-size-23">{{userData?.currentLocation?.code}}</p>
              <i class="ri-map-pin-line bold font-size-21"></i>
            </div> -->
          </div>
            
        <!-- </a> -->
      </div>
  
      <div class="d-flex justify-content-end overflow-x-visible">
        <!-- <div *ngIf="userData?.accountType == 'patient'">
          <button type="button" class="btn header-item noti-icon waves-effect icon-color" style="padding: 0.47rem 0.5rem" (click)="contactUs(chooseLocation, $event);">
            <i class="ri-map-pin-line icon-color"></i>
          </button>      
        </div> -->
        
        <div class="d-flex">
          <button type="button" class="popup btn header-item noti-icon icon-color" style="padding: 0.47rem 0.5rem" (click)="contactUs(contactus, $event);">
            <i class=" ri-customer-service-line icon-color"></i>
            <!-- <span class="popuptext show" id="myPopup">
              <span style="width: -webkit-fill-available;">
                {{'TOPBAR.NEEDHELP' | translate}}
              </span>
              <span style=" float: right; padding: 5%; margin-top: -0.5em; color: #ffffff;">
                <small>X</small>
              </span>
            </span> -->
          </button>      
          <div class="dropdown d-inline-block" ngbDropdown>
            <button type="button" ngbDropdownToggle class="btn topbarBtn header-item noti-icon waves-effect icon-color" style="padding: 0.47rem 0.5rem"
              id="page-header-notifications-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="ri-notification-3-line icon-color"></i>
              <span  *ngIf="notifications && unread > 0" class="badge badge-pill badge-orange p-0"><p style="margin-top:0.1rem; margin-bottom:0">{{unread}}</p></span>
            </button>
            <div class="notification-dropdown dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" ngbDropdownMenu aria-labelledby="page-header-notifications-dropdown">
              <div class="p-3">
                <h4 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h4>
              </div>
              <perfect-scrollbar style="position: relative; height: 230px;">
                <div *ngIf="notifications.length > 0" class="media-body">
                  <li class="notification unread" *ngFor="let data of notifications" [ngClass]="{ 'unread': data.read === true }" [routerLink]="notificationLink(data)" (click)="handleNotification(data)">
                    <i *ngIf="data.read === true" class="ri-mail-open-fill"></i>
                    <i *ngIf="data.read === false" class="ri-mail-unread-fill"></i>
                    <h6 *ngIf="data?.template?.data.subject !== undefined" class="msg-subject mt-0 mb-1 ml-3">{{ data?.template?.data.subject | translation:translate.store.currentLang }}</h6>
                    <h6 *ngIf="data?.template?.data.text !== undefined" class="msg-html mt-0 mb-1 ml-3" >{{ data?.template?.data.text | translation:translate.store.currentLang }}</h6>
                    <h6 *ngIf="data?.template?.data.text == undefined && data.deliveryOrder != false" class="msg-html mt-0 mb-1 ml-3" >&nbsp;</h6>
                    <h6 *ngIf="data?.template?.data.orderNumber !== undefined && data.deliveryOrder == false" class="msg-subject mt-0 mb-1 ml-3">{{'TOPBAR.ORDERCONFIRMATION' | translate}}</h6>
                    <h6 *ngIf="data?.template?.data.orderNumber !== undefined && data.deliveryOrder == false" class="msg-html mt-0 mb-1 ml-3" >{{'TOPBAR.ORDERNUM' | translate}}{{ data?.template?.data.orderNumber }}</h6>
                    <h6 class="msg-time mt-0 mb-1 ml-3 mr-2" >{{ data?.delivery?.endTime?.toDate() | date:'yyyy-MM-dd h:mm a'}}</h6>
                    <hr>
                  </li>
                </div>
                <div *ngIf="notifications.length == 0" class="media-body d-flex mt-3">
                  <span class="text-center w-100">{{'TOPBAR.NONOTIFICATIONS' | translate}}</span>
                </div>
              </perfect-scrollbar>
              <a class="p-2 btn btn-sm btn-link font-size-14 btn-block text-center icon-color" [routerLink]="['inboxandsupport/general']">
                <i class="mdi mdi-arrow-right-circle mr-1 icon-color"></i> {{ 'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}
              </a>
            </div>
          </div>
          <div>
            <button type="button" class="btn header-item noti-icon waves-effect icon-color" style="padding: 0.47rem 0.5rem" [routerLink]="['inboxandsupport/chat']" (click)="emit();">
              <i class="ri-chat-3-line icon-color"></i>
              <span  *ngIf="chats && unreadChatsCopy > 0" class="badge badge-pill badge-orange p-0"><p style="margin-top:0.1rem; margin-bottom:0">{{unreadChatsCopy}}</p></span>
            </button>      
          </div>
    
          <div class="dropdown d-inline-block user-dropdown" ngbDropdown> 
            <button type="button" ngbDropdownToggle class="btn topbarBtn header-item waves-effect" style="padding: 0.47rem 0.5rem" id="page-header-user-dropdown"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img class="rounded-circle header-profile-user cropped" src="{{image}}">
              <span class="d-none d-xl-inline-block ml-2">{{user?.email}}</span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
    
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <a class="dropdown-item" [routerLink]="['profile']"><i class="ri-user-line align-middle mr-1"></i>
                {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
              <a class="dropdown-item" [routerLink]="['inboxandsupport/general']"><i class="ri-mail-line align-middle mr-1"></i>
                {{ 'HEADER.LOGIN.SUPPORT' | translate}}</a>
                <a class="dropdown-item" href="https://dzeus.com/" target="_blank">
                  <i class="mdi mdi-monitor-dashboard mr-1"></i>
                  <span>{{'HEADER.WEBSITE' | translate}}</span>
                </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-orange" href="javascript: void(0);" (click)="logout()"><i
                  class="ri-shut-down-line align-middle mr-1 text-orange"></i>
                {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
            </div>
          </div>
        </div>
        <!-- <div (click)="contactUs(chooseLocation, $event);" *ngIf="userData?.accountType == 'patient'" class="cyan d-flex justify-content-end" style="position: relative; line-height: normal; text-decoration: underline;">
          <p class="m-0 bold font-size-16">{{userData?.currentLocation?.code}}</p>
          <i class="ri-map-pin-line bold font-size-14"></i>
        </div> -->
      </div>
    </div>
    <!-- <div (click)="contactUs(chooseLocation, $event);" *ngIf="userData?.accountType == 'patient'" class="cyan d-flex justify-content-end" style="position: relative; line-height: normal; text-decoration: underline; bottom: 21px;">
      <p class="m-0 bold font-size-16" style="line-height: 16px;">{{userData?.currentLocation?.name}}</p>
      <i class="ri-map-pin-line bold font-size-16" style="line-height: 11px;"></i>
    </div> -->
  </div>
  <!-- <hr style="border: solid 1px rgba(0, 0, 0, 0.1) !important; margin-left: -10%; width: 120%; margin-top: 0rem !important; margin-bottom: 0rem !important;"> -->
</header>

<ng-template let-modal #contactus>
  <div class="card" style="box-shadow: none;">
    <button type="button" class="close mr-2 ml-auto" style="text-align: end;" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span style="font-size: 32px;" aria-hidden="true">&times;</span>
    </button>
    <app-contactus></app-contactus>
  </div>  
</ng-template>

<ng-template let-modal #chooseLocation>
  <div class="card" style="box-shadow: none;">
    <button type="button" class="close mr-2 ml-auto" style="text-align: end;" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span style="font-size: 32px;" aria-hidden="true">&times;</span>
    </button>
    <app-choose-location *ngIf="userData" [collectionToUpdate]="'users'" [currentUser]="userData" (close)="modal.close()"></app-choose-location>
  </div>  
</ng-template>
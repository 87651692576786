import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {User} from '@firebase/auth-types';
import { DataService } from 'src/app/pages/notifications/data.service';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { CookieService } from 'ngx-cookie-service';
import { TranslationPipe } from 'src/app/translation.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { fbRemoteConfig } from '../../../../authUtils'
import { EmailsService } from 'src/app/core/services/emails.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-consultmessages',
  templateUrl: './consultmessages.component.html',
  styleUrls: ['./consultmessages.component.scss']
})
export class ConsultmessagesComponent implements OnInit, OnDestroy {

 //Message section inside consult (practitioners)
  @Input() patient:any;
  @Input() consult:any;
  @Input() currentUser: any;
  @Input() chatLeftsidebar: Boolean;
  @Input() collaborator: boolean;

  chat = true
  sent = false;
  consults = false
  consultMailUnread = 0
  hidden: Boolean;

  pagetitle = "NOTIFICATIONS.MESSAGES"

  notifications: Array<any>;
  sentEmails: Array<any>;
  subscription: Subscription;
  //public Editor = ClassicEditor;
  emailIds: number[] = [];

  //user
  user: User;
  public userData: any;

  //for swal modal
  title
  text
  cancelBtn
  delete
  deleteMSG
  confirmBtn

  translationPipe = new TranslationPipe()

  placeholder = "NOTIFICATIONS.STARTMESSAGE"
  public Editor = ClassicEditor;

  //email/notifications fields
  subject:string = '';
  html: string = '';
  from:any;
  to:any;
  toName:any;
  toLang: any;
  toUID:any;
  send: Boolean = false;

  //for filtering
  general:any;
  order_shipment:any;
  archive:any;

  readMessage: Boolean = false;
  selectedNotification:any;
  searchText:any = '';

  currentLang
  settings = fbRemoteConfig.defaultConfig
  allSubscriptions = new Subscription()

  previousUrl: string = 'general';

  constructor(
    private modalService: NgbModal,
    private notificationsService: DataService,
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private router: Router,
    public firebaseService: FirebaseService,
    public translate: TranslateService,
    private sanitaizer: DomSanitizer,
    private emailService: EmailsService
  ) { 
    this.currentLang = this.translate.store.currentLang
  }
  
  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe()
  }

  ngOnInit() {
    this.getUser();

    if(this.patient){
      this.getNotifications();
      this.to = this.patient.email;
      this.toName = this.patient.firstName +  ' ' +  this.patient.lastName1;
      this.toLang = this.patient.preferedLang;
    }
    else {
      let tempConsultID = this.router.url.split('consults/')[1]
      tempConsultID = tempConsultID.split('/')[0];

      this.afs.collection("consultation").doc(tempConsultID).valueChanges().subscribe(consult => {
        this.consult = consult;

        this.afs.collection('users').doc(this.consult.UID_Patient).valueChanges().subscribe(async (profile: any) => {
          this.patient = await profile;
          this.getNotifications()
          this.getSentNotifications()
          this.to = await profile.email;
          this.toName = await profile.firstName +  ' ' +  profile.lastName1;
          this.toLang = await profile.preferedLang;
        });

        if(this.consult.UID_Practitioner){
          this.afs.collection('users').doc(this.consult.UID_Practitioner).valueChanges().subscribe(profile => {
            this.currentUser = profile;
          });
        }
      });
    }

    if(screen.width < 650 || (window.matchMedia("(orientation: landscape-primary)") && screen.height < 650)){
      this.hidden = true;
    }
    else { 
      this.hidden = false;
    }
  }

  getUser() {
    let sub = this.afAuth.user.subscribe(user => {
      this.user = user;
      if(user){
        let sub = this.afs.collection('users').doc(`${user.uid}`).valueChanges().subscribe(data =>{
          this.currentUser = data;
          if(this.consult != undefined){
            this.getSentNotifications();    
          }
        });
        this.allSubscriptions.add(sub)
      }
    });
    this.allSubscriptions.add(sub)
  }

  getNotifications(){
    let sub = this.afs.collection<any>('mail', ref => ref.where('consultID', '==', this.consult.ID).where('fromUID', '==', this.patient.uid).orderBy('id', 'desc')).valueChanges().subscribe(async mail => {
      this.notifications = mail.sort((a, b) => {
        return b?.delivery?.startTime - a?.delivery?.startTime
      })  
    });
    this.allSubscriptions.add(sub)
  }

  getSentNotifications(){
    let sub = this.afs.collection<any>('mail', ref => ref.where('consultID', '==', this.consult.ID).where('fromUID', 'in', [this.consult.UID_Practitioner, this.currentUser.uid]).orderBy('id', 'desc')).valueChanges().subscribe(async mail => {
      this.sentEmails = mail.sort((a, b) => {
        return b?.delivery?.startTime - a?.delivery?.startTime
      })  
    });
    this.allSubscriptions.add(sub)
  }

  handleNotification(notification:any){
    if(notification.read == false && notification.to == this.user.email){    
      notification.read = true;   
      this.firebaseService.archiveMail(notification); 
    }
    notification.html = this.sanitaizer.bypassSecurityTrustHtml(this.translationPipe.transform(notification?.template?.data?.html, this.currentLang)) ;
    notification.initials = notification?.template?.data?.fromName ? notification?.template?.data?.fromName.split(' ')[0].charAt(0) + (notification?.template?.data?.fromName.split(' ')[1]?.charAt(0) ? notification?.template?.data?.fromName.split(' ')[1]?.charAt(0) : '') : 'DS'
    this.notificationsService.selectedNotification(notification);
    this.selectedNotification = notification
    this.readMessage = true;
    this.readComponent()
    this.viewPrevMessages = false
    this.prevMessages = []
    this.checkPrevMessages(notification);
  }

  prevMessages = []
  viewPrevMessages = false
  checkPrevMessages(notification){
    let allNotifications = this.notifications.concat(this.sentEmails)
    this.prevMessages = allNotifications.filter(n => {
      return n.groupMessageId && 
      n.groupMessageId == notification.groupMessageId && 
      n.date.seconds < notification.date.seconds &&
      n.id != notification.id
    }).map(m => {
      m.html = this.sanitaizer.bypassSecurityTrustHtml(this.translationPipe.transform(m?.template?.data?.html, this.currentLang))
      m.initials = m?.template?.data?.fromName ? m?.template?.data?.fromName.split(' ')[0].charAt(0) + (m?.template?.data?.fromName.split(' ')[1]?.charAt(0) ? m?.template?.data?.fromName.split(' ')[1]?.charAt(0) : '') : 'DS'
      return m
    })
  }

  open(content, compose) {
    this.translate.get('NOTIFICATIONS.STARTMESSAGE').subscribe((res: any) => {
      this.placeholder = res
    })

    if(compose != undefined){
      this.to = compose.template.data.from;
      if(compose.template.data.subject.includes('Re:')){
        this.subject = this.translationPipe.transform(compose.template.data.subject, this.currentLang);
      }
      else {
        this.subject = "Re: " + this.translationPipe.transform(compose.template.data.subject, this.currentLang);
      }
      this.html = ''
      // this.html =  '\n\n\n' + compose.template.data.html
    }
    else {
      this.subject = '';
      this.html = '';
    }
    this.modalService.open(content, { centered: true });
  }

  /**
   * select mail
   * @param event event
   * @param id id of record
   */

  selectMail(event, id) {
    if (event.target.checked) {
      this.emailIds.push(id);
    } else {
      this.emailIds.splice(this.emailIds.indexOf(id), 1);
    }
  }

  deleteMail() {
    this.emailIds = [];
  }

  markUnread() {
    this.emailIds = [];
  }

  confirm() {
    this.translate.get('NOTIFICATIONS.SWAL').subscribe((res: any) => {
      this.title = res.DELETETITLE
      this.text = res.DELETETEXT
      this.confirmBtn = res.DELETEBTN
      this.delete = res.DELETED
      this.deleteMSG = res.DELETEDMSG
      this.cancelBtn = res.CANCEL
    })
    Swal.fire({
      title: this.title,
      text: this.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: this.confirmBtn,
      cancelButtonText: this.cancelBtn
    }).then(result => {
      if (result.value) {
        this.firebaseService.deleteMail(this.selectedNotification);
        this.deleteMail();
        this.readMessage = false;
        this.consultsComponent()
        Swal.fire(this.delete, this.deleteMSG, 'success');
      }
    });
  }

  confirmDelete(){
    this.translate.get('NOTIFICATIONS.SWAL').subscribe((res: any) => {
      this.title = res.DELETETITLE
      this.text = res.DELETETEXT
      this.confirmBtn = res.DELETEBTN
      this.delete = res.DELETED
      this.deleteMSG = res.DELETEDMSG
      this.cancelBtn = res.CANCEL
    })

    Swal.fire({
      title: this.title,
      text: this.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: this.confirmBtn,
      cancelButtonText: this.cancelBtn
    }).then(result => {
      if (result.value) {

        for(let i = 0; i < this.emailIds.length; i++){
          this.firebaseService.deleteMail(this.emailIds[i]);         
        }
        this.deleteMail();
        // this.read = false;
        // this.deleteMail();
        Swal.fire(this.delete, this.deleteMSG, 'success');
      }
    });
  }

  disableToolbar(){
    if(this.emailIds.length == 0){
      // var element = <HTMLInputElement> document.getElementById("toolbarArchive");
      var element1 = <HTMLInputElement> document.getElementById("toolbarDelete");
      // element.disabled = true;
      element1.disabled = true;
    }
    else{
      // var element = <HTMLInputElement> document.getElementById("toolbarArchive");
      var element1 = <HTMLInputElement> document.getElementById("toolbarDelete");
      // element.disabled = false;
      element1.disabled = false;
    }
  }

  public onChange( { editor }: ChangeEvent ) {
    const data = editor.getData();
    this.html = data;
  }

  collectMessage($event){
    this.text = $event.target.innerText;
  }

  sendMail = true
  async sendNotification(){
    let date = new Date(Date.now());
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    let id:any = yyyy + mm + dd + uuidv4();
    let groupMessageId = this.selectedNotification?.groupMessageId ? this.selectedNotification.groupMessageId : id

    let customSms: any = {
      shortUrl: {code: ''},
      smsShortUrl: '',
    }

    if(this.to == this.patient.email){
      await this.emailService.shortenUrl('https://app.dzeus.com/inboxandsupport/read/' + id, this.settings.shortenedUrl).then(code => {     
        customSms.shortUrl.code = code
        customSms.smsShortUrl = code
        customSms.link = '/inboxandsupport/read/' + id
      
      
        this.emailService.internalCustomMessages(id, this.patient, this.subject, this.html, 
            this.text, this.settings, this.consult.ID, true, this.currentUser, '', customSms, groupMessageId);
        this.modalService.dismissAll();
        this.to = "";
        this.subject = "";
      })
    }
    else {
      this.to = this.patient.email;
    }
  }

  handleRoute(id){
    if(this.router.url.endsWith('message/'+id)){
      this.readMessage = true;
      this.readComponent()
    }else{
      this.readMessage = false;
      this.consultsComponent()
    }
  }
  
  //sidebar component

  consultsComponent(){
    this.consults = true;
    this.chat = false;
    this.sent = false;
    this.readMessage = false;

    this.previousUrl = 'general'
  }

  sentComponent(){
    this.consults = false;
    this.chat = false;
    this.sent = true;
    this.readMessage = false;

    this.previousUrl = 'sent'
  }

  readComponent(){
    this.consults = false;
    this.chat = false;
    this.sent = false;
    this.readMessage = true;
  }

  chatComponent(){
    this.consults = false;
    this.readMessage = false;
    this.chat = true;
    this.sent = false;

    this.previousUrl = 'chat'
  }

  hide(bool: Boolean){
    if( screen.width < 650 || (window.matchMedia("(orientation: landscape-primary)") && screen.height < 650)){
      if(bool){
        var element =  <HTMLInputElement> document.getElementById("sidebar");
        element.hidden = true;
      }
      else {
        var element =  <HTMLInputElement> document.getElementById("sidebar");
        element.hidden = false;
        this.consultsComponent()
        this.consults = false;
      }
    }
  }

  chatLeftsidebarComponent(chatLeftsidebar){
    this.chatLeftsidebar = chatLeftsidebar
  } 

  getUserColor(userId: string): string {
    // Example logic: Assign colors based on userId or initials
    const colors = ['#F15A25', '#14395f']; // You can add more colors
    const index = Math.abs(this.hashCode(userId)) % colors.length;
    return colors[index];
  }
  
  // Hash function to generate consistent index for the same userId
  hashCode(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }  
}

    <div> 
      <div class="mt-2">
        <button class="btn btn-primary" (click)="filterAccounts('all');getOrdersBasedOnTimeRange('all'); reset() ; getCookiesCount('all')">All Referrals and Orders</button>&nbsp;
        <div class="d-flex">
          <input type="datetime-local" [(ngModel)]="startDate" (change)="filterAccounts($event.target.value); getOrdersBasedOnTimeRange($event.target.value); ">&nbsp; - &nbsp;
          <input type="datetime-local" [(ngModel)]="endDate" (change)="filterAccounts($event.target.value); getOrdersBasedOnTimeRange($event.target.value); ">
        </div>
      <button class="btn btn-secondary" (click)="exportToCSV()">Export to CSV</button>
      </div>
    
      <table class="table mt-3">
        <thead>
          <tr>
            <th>Influencer</th>
            <th>Orders</th>
            <th>Registered Users</th>
            <th>Cookies</th>
            <th>Campaign</th>
            <th>Campaign Orders</th>
            <th>Campaign Users</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of combinedData">
            <td>{{ row.influencer }}</td>
            <td>{{ row.orders }}</td>
            <td>{{ row.registered }}</td>
            <td>{{ row.cookies }}</td>
            <td>
              <ul>
                <li *ngFor="let campaign of getObjectKeys(row.campaignsOrders)">{{ campaign }}</li>
              </ul>
            </td>
            <td>
              <ul>
                <li *ngFor="let campaign of getObjectKeys(row.campaignsOrders)">{{ row.campaignsOrders[campaign] }}</li>
              </ul>
            </td>
            <td>
              <ul>
                <li *ngFor="let campaign of getObjectKeys(row.campaignsUsers)">{{ row.campaignsUsers[campaign] }}</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>


     
      
    <h1> Registers per Influencer </h1>
    <apx-chart dir="ltr" class="apex-charts" [series]="ReferalInfluencerAnalytics.series" [chart]="{height: 300, type: 'bar'}"
     [labels]="ReferalInfluencerAnalytics.labels" [colors]="['#F10000']">
    </apx-chart>  
  
      <apx-chart
      class="apex-charts" 
      [series]="ReferalInfluencerAnalytics.series[0].data"
       [chart]="{height: 300,type: 'pie'}"
      [labels]="ReferalInfluencerAnalytics.labels" 
      [colors]="ReferalInfluencerAnalytics.colors">
    
    </apx-chart>
    <h1> Orders per Influencer </h1>
    <apx-chart dir="ltr" class="apex-charts" [series]="OrderReferralAnalytics.series" [chart]="{height: 300, type: 'bar'}"
     [labels]="OrderReferralAnalytics.labels" [colors]="['#F10000']">
    </apx-chart>
  
    <apx-chart 
      dir="ltr" 
      class="apex-charts" 
      [series]="OrderReferralAnalytics.series[0].data" 
      [chart]="{height: 300, type: 'pie'}"
      [labels]="OrderReferralAnalytics.labels" 
      [colors]="OrderReferralAnalytics.colors">
    </apx-chart>      
 </div>

import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-exit-modal',
  templateUrl: './exit-modal.component.html',
  styleUrls: ['./exit-modal.component.scss']
})
export class ExitModalComponent {

  @Output() continue = new EventEmitter<void>();

  closeModal() {
    this.continue.emit();
  }

}

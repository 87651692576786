import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, from } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import {FirebaseService} from '../../core/services/firebase.service'; //Se añadió esta línea para evitar errores de compilación. Se puede usar el import que se encuentre en el código original en 'consultations.component.ts'
import { OrdersService } from 'src/app/core/services/orders.service'; //Se añadió esta línea para evitar errores de compilación. Se puede usar el import que se encuentre en el código original en 'consultations.component.ts'
import { BaseProduct } from 'src/app/core/services/interfaces'; //Se añadió esta línea para evitar errores de compilación. Se puede usar el import que se encuentre en el código original en 'consultations.component.ts'
import { v4 as uuidv4 } from 'uuid'; //Se añadió esta línea para evitar errores de compilación. Se puede usar el import que se encuentre en el código original en 'consultations.component.ts'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';

import { getAuth } from 'firebase/auth';
import { ProductService } from 'src/app/core/services/product.service';
const auth = getAuth();


@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent {
  @Input() currentConsult: any = {}; //Se añadió esta línea para evitar errores de compilación. Se puede usar la variable que se encuentre en el código original en 'consultations.component.ts'
  @Input() selectedPromotion: number;
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();
  promosDocs: any;

  currentUser: any;
  currentUserAccountType: any;
  currentUserUid: any;
  currentUserLocation: any;
  currentUserPreferredLang: any;

  loader: boolean = false;

  modalDetails: any;
  @ViewChild("detailsModal") modalDetailsContent: TemplateRef<any>;

  constructor(
    private afs: AngularFirestore,
    public translate: TranslateService,
    private authService: AuthenticationService,
    public firebaseService: FirebaseService,
    private orderService: OrdersService,
    private modalService: NgbModal,
    private productService: ProductService
  ) {

    this.getCurrentUser();

  }

  ngOnInit() {

    if(this.currentConsult) {
      this.getCurrentPromos(this.currentConsult.Type);
    }
    else {
      this.getCurrentPromos("Erectile Dysfunction");
    }
  }

  selectPromotion(promotion: number) {
    this.selectedPromotion = promotion;
    // console.log("Selected Promotion: ", this.selectedPromotion);
  }

  // getSelectedPromotionText() {

  //   console.log(this.selectedPromotion);

  //   var selectedPromotionText = this.selectedPromotion.toString();

  // }

  getCurrentPromos(parentDocId: string): void {

    let promosDocs = this.productService.getPromos({Type: parentDocId}).then((res: any) => {
      this.promosDocs = res.filter((product: any) => product.productValue[0][this.currentConsult.state.code]) ;
    });
  }

  getCurrentUser() {

    auth.onAuthStateChanged(async(user) => {
      if (user && !user.isAnonymous) {

        var currentUid = auth.currentUser.uid ? auth.currentUser.uid : "";

        const usersRef = this.afs.firestore.collection("users").where('uid', '==', currentUid);  

        await usersRef.get().then(async (querySnapshot: any) => {

          querySnapshot.forEach(async (doc: any) => {

            var data = doc.data();

            this.currentUser = data;

            this.currentUserAccountType = this.currentUser.accountType;

            this.currentUserUid = this.currentUser.uid;

            this.currentUserLocation = this.currentUser.currentLocation.code;

            this.currentUserPreferredLang = this.currentUser.preferedLang;

          });

        });

      } 
      else {
        this.currentUserLocation = this.currentConsult.state.code;
      }
    });

  }

  // getSelectedOffer(offer: any) {

  //   console.log(offer);

  //   this.afs.collection('consultation').doc('')

  // }

  async saveService(product: any){
    try{
      this.loader = true;
      this.selectedPromotion = product.productId

      // let order = await this.orderService.orderExists(this.currentConsult.ID, product.productType, this.patientProfile.uid);
      product.type = product.productType;
      product.id = product.odooProductId
      // product.productPricing = product.productPrice.filter(p => p[this.currentConsult.state.code])[0]
      // console.warn('product', product, order, this.currentConsult)
      this.firebaseService.saveProduct(product, this.currentConsult.ID);
      if(this.currentConsult.patientName != '(Anonymous)'){
        let _product: BaseProduct = {
          automaticPayment:false,
          id: uuidv4(),
          image: product.productImg,
          orderLineID: "",
          productID: product.odooProductId.toString(),
          productName: product.productName,
          productPrice: product.productPrice,
          productQty: "1",
          recurrency_order: false,
          type:product.productType,
        }

        let response:any = await this.orderService.createOrderOnCall(this.currentUser.uid, this.currentConsult.ID, this.currentUser, _product, this.currentConsult.state.code);
        
        if(response && response.data.status == 200){
          this.afs.collection("orders").doc(response.data.order.id).update({orderState: "create", sync_time: new Date(Date.now())});
          this.loader = false;
          // this.continue.emit(true);
        }else{
          await this.saveService(product);
        }
      }
      else {
        this.loader = false;
        // this.continue.emit(true);
      }
    }
    catch(e){
      this.loader = false;
    }
  }

  checkSelectedPromo(productId) {
    return this.selectedPromotion?.toString() == productId?.toString();
  }

  openDetailsModal(detailsModal: any) {

    this.modalService.open(detailsModal, { size: 'xl', backdrop: 'static', centered: true });

  }

}
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class LanguageService implements OnDestroy {
  public languages: string[] = ['en', 'es', 'de', 'it', 'ru'];
  public user;
  allSubscriptions = new Subscription()
  
  constructor(
    public translate: TranslateService, 
    private readonly afs: AngularFirestore, 
    private authService: AuthenticationService,
    public afAuth: AngularFireAuth
    ) {
    this.translate.addLangs(this.languages);
    
    if(this.authService.userData != undefined){
      this.userProfile()
    }
    else if(localStorage.getItem('lang')){
      this.setLanguage(localStorage.getItem('lang'))
    }
    else {
      const moment = require('moment-timezone');  
      let currentTimezone = moment.tz.guess();
      if(currentTimezone == 'America/Puerto_Rico'){
        this.setLanguage('es') 
      }else {
        this.setLanguage('es') 
      }
    }
  }

  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe()
  }

  public async setLanguage(lang) {
    localStorage.setItem('lang',lang)
    this.translate.use(lang);
  }

  async userProfile(){
    this.afAuth.authState.subscribe(async user => {
      if(user){
        this.authService.profile().then(data => {
          this.user = data;
          if(this.user != undefined && this.user.preferedLang != undefined){
            this.setLanguage(this.user.preferedLang)
          }
          else {
            this.setLanguage('es')
          }
        })
      }
      
    })
  }
}
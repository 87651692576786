import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NULL_PRODUCT_PRESCRIPTION } from 'src/app/core/interfaces/const/interfaces';
import { RxPrescription } from 'src/app/core/interfaces/prescriptions';
import { BaseProductPrescription } from 'src/app/core/services/interfaces';
import { PdfService } from 'src/app/core/services/pdf.service';
import { PrescriptionService } from 'src/app/core/services/prescription.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-manual-prescription',
  templateUrl: './manual-prescription.component.html',
  styleUrls: ['./manual-prescription.component.scss']
})

export class ManualPrescriptionComponent implements OnInit {
  
  @Input() prescription:RxPrescription;

  @Output() items = new EventEmitter<BaseProductPrescription[]>();  
  @Output() close = new EventEmitter<boolean>();  

  product:BaseProductPrescription = {...NULL_PRODUCT_PRESCRIPTION}; //product to be added to the prescription
  productsList = []; //all the prescription products
  products:any[]= []; //all the products to be displayed from the search
  dose:string;

  productIndex:number = 0;

  constructor(
    private db: AngularFirestore, 
    private pdfService: PdfService,
    private prescriptionService: PrescriptionService,
  ) { }

  async ngOnInit() {
    this.getPrescriptionItems();
  }

  async getPrescriptionItems(){
    this.db.collection('prescription').doc(this.prescription.ID).collection("Items").valueChanges().subscribe(items => {
      this.productsList = items;
      this.items.emit(this.productsList);
    })
  }

  async searchProducts(term:string){
    let productSearch = await this.prescriptionService.searchMedicationAPI(term)
    this.products = productSearch ? productSearch : [];
  }

  handleSelection(product){
    this.productIndex = this.products[1].findIndex(p => p == product);
  } 

  handleDosage(dosage){
    this.product.rxcui = this.products[2].RXCUIS[this.productIndex][this.products[2].STRENGTHS_AND_FORMS[this.productIndex].findIndex(p => p == dosage)];
    this.dose = dosage;
  }

  async save(){
    this.product.id = uuidv4();
    this.product.productName = this.product.productName + " " + this.dose;

    this.db.collection("prescription").doc(this.prescription.ID).collection("Items").doc(this.product.id).set(this.product).then(() => {
      this.productsList.push(this.product);
      this.items.emit(this.productsList);
      
      this.product = {  
        automaticPayment: null,
        daw: null,
        id: null,
        image: null,
        isPack: null,
        minRefillQty: 0,
        packID: null,
        prescriptionID: null,
        productID: null,
        productName: null,
        productPrice: null,
        productQty: null,
        recurrency_order: null,
        refillIntervalDays: null,
        refillMainQty: 11,
        refillQty: null,
        rxInstruction: null,
        rxcui: null,
        rxcuiName: null,
        treatment: null,
        type: null,
        deliveryFrequencyInDays: null
      };
      this.products = [];
    });
  }

  async closeAndGeneratePdf(){    
    if(this.productsList.length > 0){
      await this.pdfService.generatePdf(this.prescription);
    }
    this.close.emit(true);
  }

  setRID(event){
    this.product.refillIntervalDays = event === '0' ? 0 : null;
  }
}
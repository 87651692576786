import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { MedicalInsurance, MedicalInsuranceBase } from '../interfaces';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class MedicalInsuranceService {

  constructor(
    private db: AngularFirestore
  ) { }


  async getMedicalInsurance(id: string){
    return await this.db.collection('medical_insurance', ref => ref.where('uid', '==', id)).get().toPromise();
  }

  async getMedicalInsuranceTemplates(){
    return await this.db.collection('medical_insurance_templates').get().toPromise();
  }


  async addMedicalInsurance(id: string, data: any){
    return await this.db.collection('medical_insurance').doc(id).set(data);
  }

  async updateMedicalInsurance(id: string, data: any){
    return await this.db.collection('medical_insurance').doc(id).update(data);
  }

  async deleteMedicalInsurance(id: string){
    return await this.db.collection('medical_insurance').doc(id).delete();
  }

  async getMedicalInsurances(){
    return await this.db.collection('medical_insurance').get().toPromise();
  }

  async saveMedicalInsurance(uid, data:MedicalInsuranceBase, url: string, id?: string){
    let insurance: MedicalInsurance = {
      contractNumber: data.contractNumber,
      copayment: data.copayment,
      date: new Date(Date.now()),
      efectiveDate: data.efectiveDate, //! Change this to data
      groupNumber: data.groupNumber,
      id: id || uuidv4().toString(),
      name: data.name,
      uid: uid,
      url: url
    }
    return await this.db.collection('medical_insurance').doc(insurance.id).set(insurance);
  }

  saveToStorage(uid: string, data:MedicalInsuranceBase, file: File): Promise<string> {
    const storage = getStorage();
    const storageRef = ref(storage, `users/${uid}/${file.name}`);
  
    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Handle progress, bytesTransferred, etc.
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          // Handle unsuccessful uploads
          reject(error);
        },
        () => {
          // Handle successful uploads on complete
          // Get the download URL for the uploaded file
          getDownloadURL(storageRef)
            .then((url) => {
              this.saveMedicalInsurance(uid, data, url);
              resolve(url);
            })
            .catch((error) => {
              // Handle any errors
              reject(error);
            });
        }
      );
    });
  }

}

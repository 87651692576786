import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { OrdersService } from 'src/app/core/services/orders.service';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';


import { EmailsService } from 'src/app/core/services/emails.service';
import { testUsers } from 'src/environments/environment';

@Component({
  selector: 'app-migration',
  templateUrl: './migration.component.html',
  styleUrls: ['./migration.component.scss']
})
export class MigrationComponent implements OnInit {
  usersRevoked:any[] = [];
  invalidIds = [];
  bulbs = Array(25).fill(0);
  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
    private orderService: OrdersService,
    private authService: AuthenticationService,
    private firebaseService: FirebaseService,
    private emailService: EmailsService,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit(): void {
  }

  revoke(){
    let url = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/revokeTest"
    this.db.collection("users").valueChanges().subscribe(async (users:any) => {
      users.forEach(user => {
        let body = new HttpParams();
        body = body.set("uid", user.uid);
        this.http.post(url, body).subscribe(res => {
          this.usersRevoked.push({uid:user.uid, email:user.email});
        })
      });
    });
  }

  createMentalHealthConsult(){
    this.db.firestore.collection('conditions').doc('Anxiety').collection('productGroup').doc('0').collection('searchGroup').get().then(res =>{
      res.docs.forEach(anx => {
        this.db.collection('conditions').doc('Mental Health').collection('productGroup').doc('0').collection('searchGroup').doc(anx.data().id).set(anx.data())
      })
    })
  }

  cancelConsults(){
    this.db.firestore.collection('consultation').where('patientName', '==', '(Anonymous)').get().then(res => {
      for(let i = 0; i < res.docs.length; i++){
        this.db.collection('consultation').doc(res.docs[i].data().ID).update({Status: 'Canceled'})
      }
    })
  }

  // Upload email templates automatically
  async fileSelected(fileInput: any){
    if (fileInput.target.files && fileInput.target.files[0]) {
      for(let i = 0; i < fileInput.target.files.length; i++){
        const reader = new FileReader();
        reader.onload = (e: any) => {
          let blob = new Blob(fileInput.target.files, { type: fileInput.target.files[i].type });
          let url = window.URL.createObjectURL(blob);
          this.pwaUpload(blob, fileInput.target.files[i].name);
        };
        reader.readAsDataURL(fileInput. target.files[i]); 
      }
    }
  }

  data
  pwaUpload(file, fileName) {
    // const reader = new FileReader();
    // reader.readAsText(file, 'UTF-8');
    // console.warn('Uploading file: ', reader.result);
    // reader.onload = (event) => {
    //   // this.db.collection('mail_templates').doc(fileName.split('.html')[0]).set({html: reader.result, subject: ''})
    // };

    const reader: FileReader = new FileReader();
    reader.onload = async (e: any) => {
      const binaryString: string = e.target.result;
      const workBook: XLSX.WorkBook = XLSX.read(binaryString, { type: 'binary' });

      for(let i = 0; i < workBook.SheetNames.length; i++){
        const workSheetName: string = workBook.SheetNames[i];
        const workSheet: XLSX.WorkSheet = workBook.Sheets[workSheetName];

        this.data = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
        console.log(this.data);

        for(let i = 1; i < this.data.length; i++){
          let obj: any = {}
          for(let j = 0; j < await this.data[i].length; j++){
            obj = Object.assign(obj, {
              [this.data[0][j]]: typeof this.data[i][j] == 'string' && this.data[i][j].startsWith('[') ? JSON.parse(this.data[i][j]) : this.data[i][j] != undefined ? this.data[i][j] : ''            
            })

            if(j == this.data[i].length - 1){
              console.log(workSheetName)
              this.db.collection(workSheetName).doc(obj.productId.toString()).set(obj) 
            }
          }
        }
      }
    };

    reader.readAsBinaryString(file);
  }

  setConsultSteps(){
    // this.db.firestore.collection('consultTemplates').get().then(templates => {

    //   templates.docs.forEach(template => {
    //     let consult = template.data();
        
    //     // if(consult.id == 'STD' || consult.id == 'Preventive Health'){
    //     //   let steps = [
    //     //     { stepId: 'loader'},
    //     //     { stepId: 'preConsultQuestions'},
    //     //     { stepId: 'engagementStep'},
    //     //     { stepId: 'gender'},
    //     //     { stepId: 'biologicalgender'},
    //     //     { stepId: 'chooseState'},
    //     //     { stepId: 'birthdate'},
    //     //     // { stepId: 'chooseLabTests'},
    //     //     // { stepId: 'chooseLabLocation'},
    //     //     { stepId: 'chooseMedication'},
    //     //     { stepId: 'nonPreconsultQuestions'},
    //     //     { stepId: 'choosePractitioner'},
    //     //     { stepId: 'chooseService'},
    //     //     { stepId: 'profileImage'},
    //     //     { stepId: 'checkout'},
    //     //     { stepId: 'chooseTime/Type'}
    //     //   ];
          
    //     // }


    //     // // other code
    //     // let steps = consult.steps;
    //     // const lastIndexOfEight = steps.findIndex(step => step.stepId == 'nonPreconsultQuestions')
    //     // if (lastIndexOfEight !== -1) {
    //     //   steps.splice(lastIndexOfEight + 1, 0, {stepId: 'phoneNumber'}); //add stuff in between of array
    //     // }
  
    //     // this.db.collection('consultTemplates').doc(consult.serviceType).update({steps: steps})
    //   })
    // })
  }

  addMultipleChoise(){
    // this.db.firestore.collection('consultRules').get().then(res => {
    //   res.docs.forEach(rule => {
    //     if(rule.data().id == 'Weight Loss'){
    //     // this.db.collection('consultRules').doc(rule.data().id).update({conditions: [Object.assign(rule.data().conditions[0], {canChooseMultipleProducts: false})]})
    //       this.db.collection('consultRules').doc(rule.data().id).update({states: [{state_name: 'Puerto Rico', state_code: 'PR'}, {state_name: 'Florida', state_code: 'FL'}], conditions: [
    //         {canChooseMultipleProducts:false,
    //         condition: "Weight Loss",
    //         conditionName: "<en>Weight Loss</en><es>Pérdida de Peso</es>",
    //         hasProducts:true,
    //         required: true}],
    //         canChooseMultipleProducts: false})
    //     }
    //   })
    // })
  }

  migrateConsultations(){
    // this.db.firestore.collection('consultation').where('Type', '!=', 'Erectile Dysfunction').where('Status', 'in', ['Open', 'Draft']).get().then(res => {
    //   let response = res.docs.map(doc => doc.data())
    //   let res1 = response;
    //   console.warn(res1.length)
    //   let counter = 0
    //   for(let i = 0; i < res1.length; i++){
    //     let consult = res1[i];
    //     let index = consult.steps ? consult.steps.findIndex(step => step.stepId == 'engagementStep') : -1

    //     if(index != -1){

    //       let removeSteps = consult.steps.filter(step => step.stepId != 'engagementStep');

    //       if(consult.currentWizardStep == 'engagementStep'){
    //         consult.currentWizardStep = 'preconsultQuestions'
    //       }

    //       let data = {
    //         ID: consult.ID,
    //         steps: removeSteps,
    //         currentWizardStep: consult.currentWizardStep
    //       }
    //       counter++
    //       this.db.collection('consultation').doc(data.ID).update(data)
    //     }

    //     if(i == res1.length - 1){
    //       console.log(counter)
    //     }
    //   }
    // })
  }

  migrateConsultations2(){
    this.db.firestore.collection('consultation').where('Type', '==', 'Erectile Dysfunction').where('Status', 'in', ['Open', 'Draft']).get().then(res => {
      let response = res.docs.map(doc => doc.data()).filter(consult => consult.patientName != '(Anonymous)' && consult.currentWizardStep == 'chooseMedication');
      let res1 = response;
      console.warn(res1.length)
      // let counter = 0
      // for(let i = 0; i < res1.length; i++){
      //   let consult = res1[i];
      //   let index = consult.steps ? consult.steps.findIndex(step => step.stepId == 'chooseMedication') : -1

      //   if(index != -1){

      //     let currentWizardStepIndex = consult.steps.findIndex(step => step.stepId == consult.currentWizardStep);
      //     if(currentWizardStepIndex > index){
      //       consult.currentWizardStep = 'chooseMedication'
      //     }

      //     let data = {
      //       ID: consult.ID,
      //       currentWizardStep: consult.currentWizardStep
      //     }

      //     if(consult.currentWizardStep == 'chooseMedication'){
      //       counter++
      //       console.log(counter, consult.currentWizardStep)
      //       this.db.collection('consultation').doc(data.ID).update(data)
      //     }
      //   }

      //   if(i == res1.length - 1){
      //     console.log(counter)
      //   }
      // }
    })
  }

  // migrateSubscriptions(){
  //   this.db.firestore.collection('pharmacyOrderSchedule').where('status', '==', 'active').get().then(res => {
  //     let response = res.docs.map(doc => doc.data()).filter(subscription => subscription.pricelist)
  //     let counter = 0
  //     console.warn(response.length)
  //     // for(let i = 0; i < response.length; i++){
  //     //   let subscription = response[i];
  //     //   let data = {
  //     //     id: subscription.id,
  //     //     sendNotifications: true,
  //     //     pricelist: subscription.shippingAddress.AddressStateCode == 'FL' ? 2 : 3
  //     //   }
  //     //   counter++
  //     //   console.log(counter)
  //     //   this.db.collection('pharmacyOrderSchedule').doc(data.id).update(data)

  //     //   if(i == response.length - 1){
  //     //     console.log(counter)
  //     //   }
  //     // }
  //   })
  // }

  // migrateOrders(){
  //   this.db.firestore.collection('orders').where('orderState', 'in', ['ready', 'draft', 'create']).get().then(res => {
  //     let response = res.docs.map(doc => doc.data()).filter(order => !order.subscriptionRef && order.pricelist)
  //     let counter = 0
  //     console.warn(response.length, res.docs.length)
  //     // for(let i = 0; i < response.length; i++){
  //     //   let order = response[i];
  //     //   let data = {
  //     //     id: order.id,
  //     //     pricelist: 9
  //     //   }
  //     //   counter++
  //     //   // this.db.collection('orders').doc(data.id).update(data)

  //     //   if(i == response.length - 1){
  //     //     console.log(counter)
  //     //   }
  //     // }
  //   })
  // }


  searchOrder(){
    // this.db.firestore.collection('orders').doc('ba509cf7-9083-467b-a7e8-36997c62b9fb').get().then((res: any) => {

      // let order = res.data();
      // order.id = uuidv4();
      // order.date = new Date();
      // order.orderId = ''
      // order.orderNo = ''
      // order.paymentStatus = 'pending'
      // order.orderState = 'draft'
      // order.processing = false

    //   this.db.collection('orders').doc(order.id).set(order);
    //   this.db.firestore.collection('orders').doc('ba509cf7-9083-467b-a7e8-36997c62b9fb').collection('orderLines').get().then((res1: any) => {
    //     let orderItems = res1.docs.map(doc => doc.data());
    //     for(let i = 0; i < orderItems.length; i++){
    //       this.db.collection('orders').doc(order.id).collection('orderLines').doc(orderItems[i].id).set(orderItems[i]);
    //     }
    //   })

    // })
  }

  searchPatients(){
    this.db.firestore.collection('users').where('accountType', '==', 'patient').where('isAccountDisabled', '==', false).get().then(res => {
      let users = res.docs.map(doc => doc.data());
      
      this.db.firestore.collection('orders').where('paymentStatus', '==', 'paid').get().then(res1 => {
        let orders = res1.docs.map(doc => doc.data()).filter(order => order.productType == 'product');
        let patients = users.filter(user => orders.find(order => order.uid == user.uid));
        let patientWhoHaventOrdered = users.filter(user => !orders.find(order => order.uid == user.uid));
        console.log(patients.length)
        console.warn(patientWhoHaventOrdered.length)
        let counter = 0
        for(let i = 0; i < patients.length; i++){
          // console.log(patients[i])
          let lastOrder = orders.filter(order => order.uid == patients[i].uid).sort((a, b) => b.date.toDate() - a.date.toDate())[0];
          
          if(lastOrder.date.toDate() < new Date('Jan 01, 2024')){
            counter++
          }

          if(i == patients.length - 1){
            console.log('done', counter)
          }
        }
      })
    })
  }

  testAccounts = testUsers
  searchOrders(){      
    this.db.firestore.collection('orders').get().then(res => {
      let orders = res.docs.map(doc => doc.data()).filter(order => !testUsers.includes(order.email)).sort((a, b) => a.date.toDate() - b.date.toDate());
      console.log(orders.length)
      console.log(orders.filter(order => !order.date))
      // let counter = 0

      console.warn(orders.filter(order => order.productType == 'product').length)
      console.warn(orders.filter(order => order.productType == 'service').length)
      console.warn(orders.filter(order => order.productType != 'service' && order.productType != 'product'))
      console.warn(orders.filter(order => order.paymentStatus == 'paid').length)
      console.warn(orders.filter(order => order.paymentStatus == 'paid' && order.productType == 'service').length)
      console.warn(orders.filter(order => order.paymentStatus == 'paid' && order.productType == 'product').length)
      console.warn(orders.filter(order => order.paymentStatus == 'paid' && order.productType != 'product' && order.productType != 'service').length)
      console.warn(orders.filter(order => order.paymentStatus == 'pending'))
      console.warn(orders.filter(order => order.paymentStatus == 'pending' && order.productType == 'service'))
      console.warn(orders.filter(order => order.paymentStatus == 'pending' && order.productType == 'product'))
      console.warn(orders.filter(order => order.paymentStatus == 'pending' && order.productType != 'product' && order.productType != 'service'))
      console.warn(orders.filter(order => order.paymentStatus == 'expired').length)
    })
  }

  filterOrders(){
    this.db.firestore.collection('orders').get().then(res => {
      this.db.firestore.collection('users').where('accountType', '==', 'patient').get().then(res1 => {
        this.db.firestore.collection('consultation').where('Status', '==', 'Completed').get().then(res2 => {
          let users = res1.docs.map(doc => doc.data());
          let oneYearAgo = new Date('Aug 23, 2024');
          let orders = res.docs.map(doc => doc.data()).filter(order => (order.paymentStatus == 'paid' && order.productType == 'product' && !order.refill) && order.date.toDate() > oneYearAgo).filter(order => !testUsers.includes(order.email));
          let consultations = res2.docs.map(doc => doc.data());
          console.warn(orders.length)
          // for(let i = 0; i < users.length; i++){
          //   let user = users[i];
          //   let patientOrders = orders.filter(order => user.uid == order.uid);
            
          // }
          
          let groupOrdersByPatient = orders.reduce((acc, order) => {
              if(!acc[order.uid]){
                  acc[order.uid] = [];
                }
                acc[order.uid].push(order);
                return acc;
              })
              console.warn(Object.keys(groupOrdersByPatient).length)

          // let getUsersWithLessThanTwoOrdersAndSeeTheOrders = Object.keys(groupOrdersByPatient).filter(uid => groupOrdersByPatient[uid].length == 2).map(uid => {
          //   let user = users.find(user => user.uid == uid) || {};
          //   return {user: user.firstName + ' ' + user.lastName1 + ' (' + user.email + ')' , orders: groupOrdersByPatient[uid], consultations: consultations.filter(consult => consult.UID_Patient == uid)}
          // }).filter(user => user.user != "undefined undefined (undefined)" && user.consultations.length > 0);
          // console.log(getUsersWithLessThanTwoOrdersAndSeeTheOrders)

          let getUsersWithOneOrderAndSeeTheOrders = Object.keys(groupOrdersByPatient).filter(uid => groupOrdersByPatient[uid].length == 1).map(uid => {
            let user = users.find(user => user.uid == uid) || {};
            return {user: user.firstName + ' ' + user.lastName1 + ' (' + user.email + ')' , orders: groupOrdersByPatient[uid], consultations: consultations.filter(consult => consult.UID_Patient == uid)}
          }).filter(user => user.user != "undefined undefined (undefined)" && user.consultations.length > 0);
          console.log(getUsersWithOneOrderAndSeeTheOrders)
        })
      })
    })
  }


  searchProducts(){
    this.db.firestore.collection('consultation').where('currentWizardStep', '==', 'checkout').get().then(res => {
      let consultations = res.docs.map(doc => doc.data()).filter(consult => consult.acceptaID && consult.Type == 'Erectile Dysfunction' && consult.Date.toDate() > new Date('Jul 17, 2023') && (consult.Status == 'Open' || consult.Status == 'Draft') && !testUsers.includes(consult.email));

      this.db.firestore.collectionGroup('wishlist').where('productId', '==', 980).get().then(async res => {
        console.warn(res.size)
        let consultsInCheckoutWithNoProduct = 0
        for(let i = 0; i < res.size; i++){
          const doc = await res.docs[i];
          const fullPath = await doc.ref.path; // Get the full path of the document
      
          let consultId = fullPath.split('/')[1].split('/')[0];
          if(consultations.find(consult => consult.ID == consultId)){
            consultsInCheckoutWithNoProduct++
          }
  
          if(i == res.size - 1){
            console.log(consultsInCheckoutWithNoProduct,' of ', consultations.length, ' consultations are in checkout with no product')
          }
          // if(!fullPath.startsWith('products')){ //Old products collection
          //   await allProducts.push(await doc.data());
          // }
        }
      })
    })
  }

  filterSubscriptions(){
    this.db.firestore.collection('pharmacyOrderSchedule').get().then(res => {
      this.db.firestore.collection('users').where('accountType', '==', 'patient').get().then(res1 => {
          let users = res1.docs.map(doc => doc.data());
          let subscriptions = res.docs.map(doc => doc.data()).filter(sub => sub.status == 'active');

          let groupSubsByPatient = [];
          for(let i = 0; i < subscriptions.length; i++){
            let subscription = subscriptions[i]

            if(!groupSubsByPatient[subscription.uid]){
              groupSubsByPatient[subscription.uid] = [];
            }

            groupSubsByPatient[subscription.uid].push(subscription.tabletID);
          }

          let getUsersWithLessThanTwoOrdersAndSeeTheOrders = Object.keys(groupSubsByPatient).filter(uid => groupSubsByPatient[uid].length >= 2).map(uid => {
            let user = users.find(user => user.uid == uid) || {};
            return {user: user.firstName + ' ' + user.lastName1 + ' (' + user.email + ')' , orders: groupSubsByPatient[uid]}
          })
          // .filter(user => user.user != "undefined undefined (undefined)");
          console.log(getUsersWithLessThanTwoOrdersAndSeeTheOrders)

          let getUsersWithOneOrderAndSeeTheOrders = Object.keys(groupSubsByPatient).filter(uid => groupSubsByPatient[uid].length == 1).map(uid => {
            let user = users.find(user => user.uid == uid) || {};
            return {user: user.firstName + ' ' + user.lastName1 + ' (' + user.email + ')' , orders: groupSubsByPatient[uid]}
          })
          // .filter(user => user.user != "undefined undefined (undefined)");
          console.log(getUsersWithOneOrderAndSeeTheOrders)
      })
    })
  }

  maskPhoneNumber(phoneNumber: string): string {
    const areaCode = phoneNumber.slice(0, 3);
    const firstPart = phoneNumber.slice(3, 6);
    const lastPart = phoneNumber.slice(6);
  
    return `(${areaCode}) ${firstPart}-${lastPart}`;
  }

  async fixSubscriptions(){
    // July 1, 2024
    let allProducts = [];
    let productSnapshot = await this.db.firestore.collectionGroup('products').get();
    let allUsers: any = await this.db.firestore.collection('users').get();
    allUsers = allUsers.docs.map(doc => doc.data());
    for(let i = 0; i < productSnapshot.size; i++){
      const doc = await productSnapshot.docs[i];
      const fullPath = await doc.ref.path; // Get the full path of the document
  
      if(!fullPath.startsWith('products')){ //Old products collection
        await allProducts.push(await doc.data());
      }
    }

    this.db.firestore.collection('pharmacyOrderSchedule').get().then(res => {
      let subscriptions = res.docs.map(doc => doc.data()).filter(sub => sub.status == 'active' && sub.nextSubscriptionDate && sub.nextSubscriptionDate.toDate() >= new Date('Sep 18, 2024') && sub.nextSubscriptionDate.toDate() < new Date('Sept 23, 2024') && sub.shippingAddress?.AddressStateCode != 'FL').sort((a, b) => a.nextSubscriptionDate.toDate() - b.nextSubscriptionDate.toDate());
      
      console.warn(subscriptions.length, res.docs.length)
      for(let i = 0; i < subscriptions.length; i++){
        let subscription = subscriptions[i];
        
        let user = allUsers.find(user => user.uid == subscription.uid);
        let product = allProducts.find(product => product.productId == subscription.productID);
        if(user && user?.firstName != 'Melanie'){
          console.log(i + ": " + user?.firstName + " " + user?.lastName1 + '\n' + this.maskPhoneNumber(user.phoneNumber),'\n', product.productName + ' ' + product.productDosage + ' ' + product.productPrice[0].PR,'\n', subscription.nextSubscriptionDate.toDate())
          // let data = {
          //   id: subscription.id,
          //   nextSubscriptionDate: new Date(),
          //   ogNextSubscriptionDate: subscription.nextSubscriptionDate.toDate()
          // }
       
          // console.log(data)
          // this.db.collection('pharmacyOrderSchedule').doc(data.id).update(data)
        }
        // const now = new Date(subscription.lastSubscriptionDate.toDate());
        // const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        // let newDate = new Date(today);

        // console.log(subscription.lastSubscriptionDate.toDate(), data.nextSubscriptionDate)
      }
    })
  
  }

  // fixSubscriptions(){
    //July 1, 2024
    // this.db.firestore.collection('pharmacyOrderSchedule').get().then(res => {
    //   let subscriptions = res.docs.map(doc => doc.data()).filter(sub => sub.status == 'active' && sub.lastSubscriptionDate && sub.lastSubscriptionDate.toDate() > new Date('Jul 01, 2024'));
      
    //   console.warn(subscriptions.length)
    //   for(let i = 0; i < subscriptions.length; i++){
    //     let subscription = subscriptions[i];
        
    //     const now = new Date(subscription.lastSubscriptionDate.toDate());
    //     const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    //     let newDate = new Date(today);
    //     let data = {
    //       id: subscription.id,
    //       nextSubscriptionDate: new Date(newDate.setDate(newDate.getDate() + subscription.deliveryFrequencyInDays))
    //     }

    //     console.log(subscription.lastSubscriptionDate.toDate(), data.nextSubscriptionDate)
    //     this.db.collection('pharmacyOrderSchedule').doc(data.id).update(data)
    //   }
    // })
  // }
}
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { History } from 'src/app/core/interfaces/history';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { EmailsService } from 'src/app/core/services/emails.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { TranslationPipe } from 'src/app/translation.pipe';
import Swal from 'sweetalert2';

import { MEDICAL_CONSULTATION } from 'src/app/core/enums/process-history';
import { testUsers } from 'src/environments/environment';

import { Observable } from 'rxjs';
import { DbCollectionTriggersService } from 'src/app/core/services/db-collection-triggers.service';

@Component({
  selector: 'app-admin-consults',
  templateUrl: './admin-consults.component.html',
  styleUrls: ['./admin-consults.component.scss']
})
export class AdminConsultsComponent implements OnInit {
  @Output() viewConsult: EventEmitter<any> = new EventEmitter<any>();

  translationPipe = new TranslationPipe()

  consultData: any;
  followUpConsults: any;
  activeConsults: any;
  allConsults: any = [];
  userData: any;
  pagetitle = 'MENUITEMS.PRACTITIONERSCONSULTS.TEXT'

  oldConsultsDate: any = new Date('2023-07-18').getTime() / 1000;

  allSubscriptions = new Subscription()
  term: any;

  loading = false

  currentLang = this.translate.store.currentLang
  assignConsult
  assignPractitioners

  swal
  registry
  testConsult
  translateStatus = ["MENUITEMS.PRACTITIONERSCONSULTS.TEXT", "PRACTITIONER-CONSULTS.DRAFT", "PRACTITIONER-CONSULTS.OPEN", "PRACTITIONER-CONSULTS.PAID", "PRACTITIONER-CONSULTS.INPROGRESS", "PRACTITIONER-CONSULTS.COMPLETED", "PRACTITIONER-CONSULTS.REJECTED", "PRACTITIONER-CONSULTS.DENIED"];
  icons = ["fas fa-file-medical", "fas fa-file-invoice", "fas fa-file-alt", "fas fa-file-invoice-dollar", "fas fa-regular fa-clock", "fas fa-file-signature",
  //  "PRACTITIONER-CONSULTS.REJECTED"
  ];
  consultsStatus = ["All", "Draft", "Open", "Paid", 'In Progress', "Completed", "Rejected", "Denied"];
  filterConsults = "All"
  testUsers = testUsers
  consultsManaging = 'allConsults'
  patientType = 'Patients';

  allFilteredConsults: any;
  pageConsults = 1;
  pageSizeConsults = 10;
  totalConsultRecords = 0;

  currentSelectedStatus: any = 'All';

  //--------------------NEW PAGINATION--------------------
  // consultsData: any[] = [];
  // totalItems: number = 0; // Total number of items (for pagination controls)
  // itemsPerPage: number = 10;
  // currentPage: number = 1;
  // lastVisible: any = null;

  // consultsArray: any = [];

  // allConsultsSorted: any = [];

  constructor(private afs: AngularFirestore,
    public authService: AuthenticationService,
    public translate: TranslateService,
    private modalService: NgbModal,
    public firebaseService: FirebaseService,
    private emailService: EmailsService,
    private updateConsultErp: DbCollectionTriggersService
    ) { }

  ngOnInit(): void {
    this.getUser();
    this.getAllConsults();
    //--------------------NEW PAGINATION--------------------
    // this.getTotalItems(); // Get total number of items initially
    // this.getTestData(this.currentPage);
  }

  getUser(){
    this.authService.profile().then(async (data: any) => {
      this.userData = await data;
    });
  }

  copyTextToClipboard() {
    // console.warn('Copying text to clipboard: ', data);
    let consultArray = this.allFilteredConsults
    let textArray = consultArray.map(c => {
      return [c.patientName, c.patientEmail, c.phoneNumber, c.Date
      .toDate().toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit"
      }), 
      c.Type, c.Status]
    })

    let testString = (data: string[][]): string => {
      return data.map(row => row.join("\t")).join("\n");
    };
    navigator.clipboard.writeText(testString(textArray)).then(() => {
      console.log('Text copied to clipboard', textArray);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }

  getDateMinusMonths(months: number): string {
    const date = new Date();
    // Subtract months from the current date
    date.setMonth(date.getMonth() - months);

    // Format the date to YYYY-MM-DD
    return date.toISOString().split('T')[0]; // "YYYY-MM-DD"
  }

  getAllConsults(){
    this.afs.firestore.collection('users').get().then(allUsers => {
      let users = allUsers.docs.map(user => user.data())

      let last12Months = new Date(this.getDateMinusMonths(12));
      let sub = this.afs.firestore.collection('consultation').where('patientName', '!=', '(Anonymous)').get().then((res:any) => {
        this.consultData = []
        this.followUpConsults = []
        res = res.docs.map(r => r.data()).filter((consult) => {return consult.Status != 'Canceled' && consult.Date.seconds >= (last12Months.getTime() / 1000)});
        
        this.allConsults = res.forEach((c: any) => {
          c.userVerified = users.filter(u => u.uid == c.UID_Patient)[0]?.status == 'Verified';
          c.phoneNumber = users.filter(u => u.uid == c.UID_Patient)[0]?.phoneNumber;

          return c
        })
        
        this.allConsults = res.sort((a: any, b: any) => {
          return b.dateToSort - a.dateToSort;
        });

        this.consultData = this.allConsults.filter(consult => !consult.serviceType.includes('Follow Up'))

        this.followUpConsults = this.allConsults.filter(consult => consult.serviceType.includes('Follow Up'))
        
  
        this.selectUsers({target: {value: this.patientType}}, this[this.consultsManaging], this.consultsManaging);
      })
    })
  }

  openModal(consult){
    this.viewConsult.emit(consult.ID)
  }

  open(modal, consult){
    this.modalService.open(modal,{ size: 'lg', backdrop: 'static', windowClass: 'addressModal', centered: true})
    this.assignConsult = consult

    this.afs.firestore.collection('users').where('accountType', '==', 'practitioner').where('isAccountDisabled', '==', false).where('status', '==', 'Verified').get().then((res: any) => {
      this.assignPractitioners = res.docs.map(r => r.data()).filter(r => this.assignConsult.typeOfPractitioner.some((el) => r.typeOfPractitioner.includes(el)))

      this.assignPractitioners.forEach(p => {
        let consults = this.allConsults.filter(c => c.UID_Practitioner == p.uid && c.Status == 'In Progress')
        p = Object.assign(p, {consults: consults.length})
      })
    })
  }

  async assignConsults(practitioner, consult:any){
    let patient: any;
    this.afs.firestore.collection('users').doc(consult.UID_Patient).get().then(patientProifle => {
      patient = patientProifle.data()
    })

    this.afs.firestore.collection('users').doc(consult.UID_Patient).collection('myPractitioners').get().then(async practitioners => {
      let patientsPractitioners = practitioners.docs.map(p => p.data()).filter(p => p.uid == practitioner.uid)

      if(patientsPractitioners.length == 0){
        let newPractitioner = {
          id:(practitioners.docs.length + 1).toString(),
          uid: practitioner.uid
        }
        this.afs.collection('users').doc(consult.UID_Patient).collection('myPractitioners').doc(newPractitioner.id).set(newPractitioner)
      }      
    })

    const data = {
      UID_Practitioner: practitioner.uid, 
      practitionerForConsult: practitioner.uid,
      Status: "In Progress",
      practitionerName: practitioner.firstName + " " + practitioner.lastName1,
      dateToSort: new Date(Date.now()),
      // state: "make_pdf"
    }

    let currentConsult = consult

    if(currentConsult.UID_Practitioner === "" || currentConsult.practitionerForConsult === practitioner.uid){
      let history: History = {
        date: new Date(Date.now()),
        id: this.firebaseService.getDate(),
        userUID: practitioner.uid,
        user: practitioner.firstName + ' ' + practitioner.lastName1,
        userAccountType: practitioner.accountType,
        action: MEDICAL_CONSULTATION.AQURIED,
        // consultID: currentConsult.ID,
        // consultStatus: currentConsult.Status,
        // createdBy: this.userData.firstName + ' ' + this.userData.lastName1,
        // description: PROCESS_HISTORY_DESCRIPTION.ASSIGNED + this.userData.firstName + ' ' + this.userData.lastName1
      }

      this.afs.collection('consultation').doc(consult.ID).collection('History').doc(history.id).set(history)

      //newest possible versions
          // this.historyService.storeHistory(history);      ----  its own collection
          // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation
      
      this.firebaseService.updateConsult(consult.ID, data).then(
        async res => {
          if(res){
            this.sendMail(practitioner, consult);
          }
          this.translate.get('PRACTITIONER-CONSULTS.SWAL').subscribe((res: any) => {
            this.swal = res.TITLE
          })

          Swal.fire({
            title: this.swal,
            timer:2000,
            timerProgressBar: true,
            position: "top",
            toast: true,
            showConfirmButton: false,
            background: '#5cb85c'
          })

          if(!practitioner.transformedAbbr){
            let abbr = await this.translationPipe.transform(practitioner.abbr, consult.consultLanguage);
            if(consult.consultLanguage == 'en'){
              practitioner.transformedAbbr = abbr;
            }
            else {
              if(practitioner.biologicalgender == 'Female'){
                practitioner.transformedAbbr = abbr.split('/la ')[1]
              }
              else {
                practitioner.transformedAbbr = abbr.split('el ')[1]?.split('/')[0]
              }
            }
          }

          if(!practitioner.transformedAbbr){
            practitioner.transformedAbbr = 'Dr.'
          }

          const currentDate = new Date();
          let Month
          let Day
          if((currentDate.getMonth() + 1) <= 9){
            Month = '0' + (currentDate.getMonth() + 1)
          }
          else{
            Month = (currentDate.getMonth() + 1)
          }
          if((currentDate.getDate()) <= 9){
            Day = '0' + currentDate.getDate()
          }
          else{
            Day = currentDate.getDate()
          }

          let dateSent = Month+ '/' + Day + '/' + currentDate.getFullYear()
          const room = {
            roomId: this.firebaseService.getDate(),
            dateCreated: currentDate,
            roomUsers: [practitioner.email, consult.patientEmail],
            userNames: [practitioner.firstName + " " + practitioner.lastName1, consult.patientName],
            usersUid: [practitioner.uid, consult.UID_Patient],
            profileImgs: [practitioner.image, patient.image],
            numberOfMessages: 1,
            consultId: consult.ID,
            status: 'active'
          }
          const messages = {
            messageId: this.firebaseService.getDate(),
            message: consult.consultModale == 'Asynchronous' ? '<en>Hello ' + consult.patientName + '. I will be attending your consultation. If you qualify for treatment, you will receive the prescription and instructions as soon as possible. If you have any questions about your consultation, do not hesitate to contact me. I am at your disposal. <br> - '+ practitioner.transformedAbbr + ' ' +practitioner.firstName + " " +practitioner.lastName1 + '</en><es>Saludos ' + 
                     consult.patientName + '. Estaré atendiendo su consulta. De usted cualificar para el tratamiento, recibirá la receta y las instrucciones lo antes posible. De tener alguna duda sobre su consulta, no dude en contactarme. Quedo a su disposición. <br> - ' + practitioner.transformedAbbr + ' ' + practitioner.firstName + " " + practitioner.lastName1 + '</es>' :
                     '<en>Hello ' + consult.patientName + '. I will be attending your consultation. If you have any questions about your consultation, do not hesitate to contact me. I am at your disposal. <br> - '+ practitioner.transformedAbbr + ' ' +practitioner.firstName + " " +practitioner.lastName1 + '</en><es>Saludos ' + 
                     consult.patientName + '. Estaré atendiendo su consulta. De tener alguna duda sobre su consulta, no dude en contactarme. Quedo a su disposición. <br> - ' + practitioner.transformedAbbr + ' ' + practitioner.firstName + " " + practitioner.lastName1 + '</es>',            timeSent: this.formatAMPM(new Date),
            dateSent: dateSent,
            dateCreated: currentDate,
            to: [practitioner.email, consult.patientEmail],
            from: practitioner.email,
            read: [true, false],
            roomId: room.roomId,
            consultId: consult.ID,
            toUid: room.usersUid,
            toUserNames: room.userNames
          }    

          this.firebaseService.createChat(room, messages)

          this.emailService.generalEmails(practitioner, 'consultAssigned_' + practitioner.preferedLang, '<en>Consult Assigned</en><es>Consulta Asignada</es>', await this.authService.getRemoteConfig(), 'consults/' + consult.ID , undefined, await this.translationPipe.transform(consult.conditionName, practitioner.preferedLang))
          
          this.updateConsultErp.updateConsultationCollection({consultationId: consult.ID, trigger: 'handleConsultationErp'})
          let consultIndex = this.allConsults.findIndex(c => c.ID == consult.ID) 
          this.allConsults[consultIndex] = Object.assign(this.allConsults[consultIndex], data)
          this.allConsults[consultIndex].dateToSort = {_seconds: currentDate.getTime() / 1000, _nanoseconds: 0}
          let filteredConsultIndex = this.allFilteredConsults.findIndex(c => c.ID == consult.ID)
          this.allFilteredConsults[filteredConsultIndex] = Object.assign(this.allFilteredConsults[filteredConsultIndex], data)
          this.allFilteredConsults[filteredConsultIndex].dateToSort = {_seconds: currentDate.getTime() / 1000, _nanoseconds: 0}
        },
        err => {
          // console.log(err);
        }
      );
    }
    else{
      this.translate.get('PRACTITIONER-CONSULTS.SWAL').subscribe((res: any) => {
        this.swal = res
      })
      Swal.fire({
        icon: 'error',
        title: this.swal.ERRORTITLE,
        text: `${consult.UID_Practitioner}`+ this.swal.TEXT,
      })
    }
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime; 
  } 

  sendMail(practitioner, consult:any){
    let practitionerProfile:any;
    let patientProfile: any;
    let promise2 = new Promise ((resolve, reject) => {
        practitionerProfile = practitioner;  
        
        if(!practitionerProfile.transformedAbbr){
          let abbr = this.translationPipe.transform(practitionerProfile.abbr, consult.consultLanguage);
          if(consult.consultLanguage == 'en'){
            practitionerProfile.transformedAbbr = abbr;
          }
          else {
            if(practitionerProfile.biologicalgender == 'Female'){
              practitionerProfile.transformedAbbr = abbr.split('/la ')[1]
            }
            else {
              practitionerProfile.transformedAbbr = abbr.split('el ')[1].split('/')[0]
            }
          }
        }
        if(!practitionerProfile.transformedAbbr){
          practitionerProfile.transformedAbbr = 'Dr.'
        }
        resolve(practitionerProfile);
    });
    let promise3 = new Promise ((resolve, reject) => {
      this.afs.firestore.collection("users").doc(consult.UID_Patient).get().then(async user => {
        patientProfile = user.data();
        resolve(user.data());
      });
    });

    promise2.then(data => {
      practitionerProfile = data;
      promise3.then(async data1 => {
        patientProfile = data1;
        let typeConditions = [
          'Audiocall', 'Llamada',
          // 'Any type of contact method', 'Cualquier método de contacto',
          // '<en>Any type of contact method</en><es>Cualquier método de contacto</es>',
          '<en>Audiocall</en><es>Llamada</es>'
        ]
        this.emailService.patientConsultEmails(practitionerProfile = Object.assign(practitionerProfile, {displayName: practitionerProfile.transformedAbbr + " " + practitionerProfile.lastName1}), patientProfile, consult, 'consultReview_' + patientProfile.preferedLang, '<en>Consult Review</en><es>Revisión de Consulta</es>', await this.authService.getRemoteConfig(), '', '', '', '', '','', typeConditions.some(el => consult.schedule?.type.includes(el)))
      });
    });
  }

  consultFilter
  selectUsers(event, consults, manage) {
    this.consultsManaging = manage;
    this.patientType = event.target.value;
    if(event.target.value == 'Test'){
      this.consultFilter = consults?.filter(consult => { return this.testUsers.includes(consult.patientEmail) })

      this.getCurrentFilterStatus(this.currentSelectedStatus);
    }
    else {
      this.consultFilter = consults?.filter(consult => { return !this.testUsers.includes(consult.patientEmail) })

      this.getCurrentFilterStatus(this.currentSelectedStatus);

    }
  }

  filterConsultations(term: string) {
    this.allFilteredConsults = this.consultFilter;

    if(this.filterConsults !== 'All'){
      this.allFilteredConsults = this.allFilteredConsults.filter((consult: any) => { 
        return consult.Status === this.filterConsults;
      });
    }

    if (term) {
      this.allFilteredConsults = this.allFilteredConsults.filter(user => {
        return Object.values(user).some(val => {
          if (typeof val === 'string') {
            return val.toLowerCase().includes(term.toLowerCase());
          }
        });
      });

    }

    this.pageConsults = 1;
  }

  getCurrentFilterStatus(status){

    this.currentSelectedStatus = status;

    this.allFilteredConsults = this.consultFilter;
    
    if(status !== 'All'){

      this.allFilteredConsults = this.allFilteredConsults.filter((consult: any) => { 
        return consult.Status === status;
      });

    }

    if(this.term){
      this.allFilteredConsults = this.allFilteredConsults.filter(user => {
        return Object.values(user).some(val => {
          if (typeof val === 'string') {
            return val.toLowerCase().includes(this.term.toLowerCase());
          }
        });
      });
    }
  }

  //--------------------NEW PAGINATION--------------------
  // getTotalItems() {

  //   this.afs.firestore.collection('consultation')
  //   .where('patientName', '!=', '(Anonymous)')
  //   .get()
  //   .then(snapshot => {
  //     this.totalItems = snapshot.size;
  //     console.log('Total items: ', this.totalItems);
  //   })
  //   .catch(error => {
  //     console.error("Error getting documents: ", error);
  //   });

  // }


  //-------------------------NEW PAGINATION-------------------------
  // getTotalItems() {
  //   this.afs.collection('consultation', ref => ref
  //   .where('patientName', '!=', '(Anonymous)')).get().subscribe((snapshot) => {
  //     this.totalItems = snapshot.size;

  //     console.log('Total items: ', this.totalItems);
  //   });

  // }

  // getTestData(page: number) {

  //   this.afs.firestore.collection('users').get().then(allUsers => {

  //     let users = allUsers.docs.map(user => user.data());

  //     console.log('Users length: ', users.length);

  //     console.log('Items per page: ', this.itemsPerPage);

  //     let query = this.afs.collection('consultation', ref => ref
  //     .where('patientName', '!=', '(Anonymous)')
  //     .orderBy('patientName')
  //     .orderBy('dateToSort', 'desc')
  //     .limit(this.itemsPerPage));

  //     if (this.lastVisible && page > 1) {
  //       query = this.afs.collection('consultation', ref => ref
  //       .where('patientName', '!=', '(Anonymous)')
  //       .orderBy('patientName')
  //       .orderBy('dateToSort', 'desc')
  //       .startAfter(this.lastVisible)
  //       .limit(this.itemsPerPage));
  //     }

  //     query.get().subscribe(async(documentSnapshots) => {

  //       console.log('DocumentSnapshots: ', documentSnapshots.docs.length);

  //       if (documentSnapshots.docs.length > 0) {

  //         const newConsults = documentSnapshots.docs.map(doc => doc.data());

  //         this.consultsData = newConsults;

  //         this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

  //         this.consultData = [];
  //         this.followUpConsults = [];
  //         this.consultsArray = this.consultsData.filter((consult) => {return consult.Status != 'Canceled'});
          
  //         var arr = await this.consultsArray.map((c: any) => {
  //           c.userVerified = users.filter(u => u.uid == c.UID_Patient)[0]?.status == 'Verified';
  //           c.phoneNumber = users.filter(u => u.uid == c.UID_Patient)[0]?.phoneNumber;
        
  //           return c;
  //         });

  //         this.allConsults = arr;

  //         this.allConsultsSorted = this.allConsults.sort((a: any, b: any) => {
  //           return b.dateToSort - a.dateToSort;
  //         });

  //         this.consultData = this.allConsultsSorted.filter(consult => !consult.serviceType.includes('Follow Up'));

  //         this.followUpConsults = this.allConsultsSorted.filter(consult => consult.serviceType.includes('Follow Up'));
    
  //         this.selectUsers({target: {value: this.patientType}}, this[this.consultsManaging], this.consultsManaging);

  //       }
  //       else{
  //         this.consultsData = [];
  //       }

  //       console.log('Consults:', this.consultsData);
  //       console.log('Last Visible:', this.lastVisible);

  //     });

  //   });
    
  // }

  // pageChanged(event: any) {
  //   this.currentPage = event;
  //   this.getTestData(this.currentPage);
  // }

}
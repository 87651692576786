<div class="m-3">
  <h3 class="text-left">Labs and Diagnostics</h3>
  <p>See and manage our current labs and diagnostics</p>

  <div class="mt-5">
    <div class="table-container">
      <table class="table">
        <thead>
          <tr>
            <!-- <th>Medical Condition</th> -->
            <th>CPT Code</th>
            <th>Lab Name</th>
            <th>Categories</th>
            <th>ICD-10 Code</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lab of labs" (click)="activateSidePanel(lab)">
            <!-- <td [ngClass]="{'unavailable': lab.condition.length == 0}">{{ lab.condition.length > 0 ? lab.condition : "null" }}</td> -->
            <td [ngClass]="{'unavailable': lab.labCode == ''}">{{ lab.labCode ? lab.labCode : "null" }}</td>
            <td [ngClass]="{'unavailable': lab.labName == ''}">{{ lab.labName ? lab.labName : "null" }}</td>
            <td [ngClass]="{'unavailable': lab.labName == ''}">{{ lab.category.join(", ") }}</td>
            <td [ngClass]="{'unavailable': lab.icd10.length == 0, 'icd10': lab.icd10.length > 0}">
              <span *ngFor="let icd of lab.icd10" (click)="manageDiagnostic(icd)">{{ lab.icd10.length > 0 ? icd : "null" }}</span> 
              <span *ngIf="lab.icd10.length === 0">null</span>
            </td>
            <!-- <td [ngClass]="{'unavailable': lab.icd10.length == 0}">{{ lab.icd10.length > 0 ? icd : "null" }}</td> -->
            <!-- <td [ngClass]="{'paid': lab.paymentState == 'Paid'}"></td>
            <td [ngClass]="{'due':lab.paymentState == 'Pending'}"></td>
            <td [ngClass]="{'paid': lab.paymentState == 'Paid', 'pending':lab.paymentState == 'Pending'}">
              {{ lab.paymentState }}<br>
              {{ lab?.paymentDate | date:"mediumDate" | titlecase }}
            </td> -->
          </tr>
          
          
        </tbody>
      </table>
    </div>
  </div>
</div>


<!-- <div id="side-panel" class="edit-panel">
  <app-labs-and-diagnostics-editpanel></app-labs-and-diagnostics-editpanel>
</div> -->



<ng-template #editPanel let-modal>
  <app-labs-and-diagnostics-editpanel [lab]="selectedLab" (dismiss_)="modal.close()"></app-labs-and-diagnostics-editpanel>
</ng-template>
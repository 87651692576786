<!-- <button (click)="filterSubscriptions()">Test</button> -->

<!-- HTML Upload: <input type="file" multiple accept=".html" id="file-upload" capture="environment" #img (change)="fileSelected($event);">  -->
Product Upload: <input type="file" multiple accept=".xlsx" id="file-upload" capture="environment" #img (change)="fileSelected($event);"> 


  <div class="d-flex">
    <div class="light-string" *ngFor="let bulb of bulbs">
      <div class="bulb red">
        <div class="bulb-glow"></div>
      </div>
      <div class="bulb green">
        <div class="bulb-glow"></div>
      </div>
      <div class="bulb yellow">
        <div class="bulb-glow"></div>
      </div>
      <div class="bulb blue">
        <div class="bulb-glow"></div>
      </div>
    </div>
    <!-- <div class="bulb red">
      <div class="bulb-glow"></div>
    </div>
    <div class="bulb green">
      <div class="bulb-glow"></div>
    </div>
    <div class="bulb yellow">
      <div class="bulb-glow"></div>
    </div>
    <div class="bulb blue">
      <div class="bulb-glow"></div>
    </div> -->
  </div>

<div class="main">
  <div class="form-group" style="width: 90%; margin: 0 5% !important;">
    <i (click)="goBack()" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
  </div>
  <h1 class="animate">{{'CONSULTATIONS.PICKLAB' | translate }}</h1>

  <!-- <div *ngIf="consult.hasLabs == LAB_VIEW_OPTIONS.GROUP" class="grid-wrapper">
    <label *ngFor="let lab of categories"  for="{{lab.category}}" class="radio-card d-flex">
      <input hidden type="checkbox" name="{{lab.category}}" id="{{lab.category}}"  [disabled]="enforceSelection(lab.category)"/>
      <div class="card-content-wrapper" (click)="getSelectedCategories(lab.category)">
        <span class="check-icon"></span>
        <div class="card-content">
          <img [src]="setImage(lab.category)" alt=""/>
          <h4>{{lab.category}}</h4>
          
          <h5 *ngFor="let l of  setListOfLabs(lab.category)">{{ l | translation: "en" }}</h5>
        </div>
      </div>
    </label>
  </div> -->

  <!-- <div *ngIf="consult.hasLabs == LAB_VIEW_OPTIONS.DETAILED" class="grid-wrapper"> -->
  <div *ngIf="consult.hasLabs == 'group' && categories && categories.length > 0" class="grid-wrapper animate">
    <label *ngFor="let lab of categories" for="{{lab.category}}" class="radio-card d-flex">
      <input hidden type="checkbox" name="{{lab.category}}" id="{{lab.category}}" [checked]="uncheckCategory(lab.category)"/>
      <!-- <input hidden type="checkbox" name="{{lab.category}}" id="{{lab.category}}"/> -->
      <div class="card-content-wrapper" (click)="getSelectedCategories(lab.category)">
        <span class="check-icon"></span>
        <div class="card-content">
          <img [src]="setImage(lab.category)" alt=""/>
          <h4>{{lab.categoryTranslation | translation:consult.consultLanguage}}</h4>
          <span class="multiLine"> {{ setListOfLabs(lab.category).join(", ") }}</span>
        </div>
        <!-- <span class="list-wrapper"> {{ setListOfLabs(lab.category).join(", ") | translation: "en" }}  </span> -->
      </div>
    </label>
  </div>

  <div *ngIf="consult.hasLabs == 'detailed'" class="grid-wrapper animate">
    <label *ngFor="let lab of laboratories"  for="{{lab.id}}" class="radio-card d-flex" (click)="selectLab(lab)" [disabled]="lab.required" [attr.disabled]="lab.required">
      <input hidden type="checkbox" name="{{lab.id}}" id="{{lab.id}}" [disabled]="lab.required" [attr.disabled]="lab.required" [checked]="uncheck(lab)"/>
      <div class="card-content-wrapper" >
        <div class="d-flex justify-content-between">
          <span class="check-icon"></span>
          <i *ngIf="lab.sample_type == 'Blood'" class="fa fa-tint text-danger m-1" aria-hidden="true"></i>
          <i *ngIf="lab.sample_type == 'Urine'" class="fa fa-flask text-warning m-1" aria-hidden="true"></i>
        </div>
        <div class="card-content">
          <img src="{{lab.image}}" alt=""/>
          <h4> {{ lab.labTranslation | translation: consult.consultLanguage }} </h4>
          <div class="text-center">
            <span> {{ lab.description | translation: consult.consultLanguage }} </span>
          </div>
        </div>
      </div>
    </label>
  </div>

  <!-- <div class="w-100 d-flex justify-content-between">
    <button class="btn btn-info" (click)="goBack()">{{'PROFILE.BACK' | translate | uppercase}}</button>
    <button class="btn btn-info" (click)="storeLabs()" [disabled]="selectedCategoriesLabs.length == 0">{{'PROFILE.AGREE' | translate | uppercase}}</button>
  </div> -->
  <div class="row animate">
    <div class="form-group justify-content-center text-center mt-2 flex-column" style="margin: 0 auto;">
      <button style="border-radius: 0.25rem;" class="btn btn-primary nextbtn text-center mb-2" (click)="storeLabs()" [disabled]="selectedCategoriesLabs.length == 0">{{'PROFILE.NEXT' | translate | uppercase}}</button>
      <!-- <div style="padding-bottom: 20px;">
        <button style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="goBack()">{{'PROFILE.BACK' | translate | uppercase}}</button>
      </div> -->
    </div>
  </div>
</div>
import { Input, Output, EventEmitter, Component, OnInit, SimpleChanges, OnChanges } from "@angular/core";

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../core/services/auth.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import 'firebase/firestore';
import firebase from "firebase/compat/app";
import { TranslateService } from "@ngx-translate/core";

//Accept IMPORTS
import { ICardDetails } from "./domain/i-card-details";
import { PaymentCardService } from "./service/payment-card.service";
import { CardValidator } from './validator/card-validator';
import { CardDetails } from './domain/card-details';
import { PaymentService } from "src/app/core/services/payment.service";
import { createCreditCardTracer, UserAccountCreditCard } from "src/app/core/services/interfaces";
import { FirebaseService } from "src/app/core/services/firebase.service";
import Swal from "sweetalert2";
import { AuditTraceService } from "src/app/core/services/audit-trace.service";
import { Consult } from "../../practitioner-consults/consults.model";
import { WalletService } from "src/app/core/services/wallet/wallet.service";

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit, OnChanges {
  @Input() cardInput: any;
  @Input() modalRef: any;
  @Input() name: string;
  @Input() lastName: string;
  @Input() order:any;
  @Output() cardAdded = new EventEmitter<Boolean>();
  @Output() close = new EventEmitter<any>();
  @Input() accountToEdit: string;
  @Input() account;
  @Input() user;
  @Input() validate: boolean = false;
  @Output() validateCardState = new EventEmitter<string>();

  //Accepta Inputs and Outputs
  @Input() consult:Consult;
  public validateCCNum? = true;
  @Input()
  public validateCardHolder? = true;
  @Input()
  public validateExpirationMonth? = true;
  @Input()
  public validateExpirationYear? = true;
  @Input()
  public validateCardExpiration? = true;
  @Input()
  public validateCCV? = true;
  @Input()
  public validateAddress? = true;
  @Input()
  public validateZip? = true;
  @Output()
  public formSaved: EventEmitter<ICardDetails> = new EventEmitter<CardDetails>();

  swal
  editCard: Boolean = false;

  cardsAvailable = [];
  
  laptop
  phone

  acceptaID:string;
  testCards: string[] = [];

  //Accepta Variables
  public ccForm: UntypedFormGroup;
  public months: Array<string> = [];
  public years: Array<number> = [];
  cardToEdit:any;

  //default payment method
  default:boolean = false;

  constructor(
    private afs: AngularFirestore,
    private authenticationService: AuthenticationService,
    public afAuth: AngularFireAuth,
    public translate: TranslateService,
    private _fb: UntypedFormBuilder,
    private paymentService: PaymentService,
    private firebaseService: FirebaseService,
    private auditTrace: AuditTraceService,
    private walletService: WalletService,
    ) 
    { 
      this.getTestCards();
  }

  async ngOnInit(): Promise<void> {
    if(screen.width<768){
      this.phone = true
      this.laptop = false
    }
    else {
      this.phone = false
      this.laptop = true
    }

    if(!this.user){
      this.user = this.authenticationService.currentUser();
      if(this.user.email == null){
        this.user = firebase.auth().currentUser;
      }         
    }
      this.getAcceptaCards(this.user.uid);
      this.buildForm();
      this.assignDateValues();
  }

  dismiss(){
    this.modalRef.dismiss();
  }

  /////////////// /////////////// /////////////// /////////////// /////////////// ///////////////
  /////////////// /////////////// /////////////// /////////////// /////////////// /////////////// /////////////// 
  loading:boolean = false;
  // Accepta methods
  public async emitSavedCard(): Promise<void> {
    this.loading = true;
    const cardDetails: ICardDetails = <CardDetails>this.ccForm.value;
    const { cardNumber, cardHolder, expirationMonth, expirationYear, zipCode, addressLine1, addressLine2, ccv } = cardDetails;
    let resp:any
    const customerNumber = this.firebaseService.getDate().substring(1);
    const data: UserAccountCreditCard = {
      creditCard: cardNumber,
      expDate: expirationMonth+"/"+expirationYear,
      customerNumber: customerNumber,
      firstName: cardHolder,
      lastName:  '',
      address: addressLine1 + ' ' + addressLine2,
      zipCode: zipCode,
      default: this.default
    }
    this.formSaved.emit(cardDetails);

    const response = await this.paymentService.createAcceptaUACreditCard(data);
    let profile;
    let adminProfile:any = await this.authenticationService.profile();
    if(adminProfile.accountType == "admin"){
      let userToEdit
      if(this.user.uid != adminProfile.uid){
        //admin adding card
        userToEdit = this.user.uid;
      }
      else {
        userToEdit = this.order.uid;
      }
      profile = await this.authenticationService.getUserProfile(userToEdit);
    }
    else {
      profile = await this.authenticationService.profile();
    }

    const info = {
      provider: "accepta",
      brand: this.getCardType(cardNumber),
      cardType: "",
      cardHolder: response.card.firstName,
      expDate: response.card.expDate,
      customerNumber: response.response.CustomerNumber,
      last4: response.card.creditCard.substring(response.card.creditCard.length - 4),
      bin: "",
      binCardBrand: "",
      binOrganization: "",
      binCardType: "",
      binCardSubType: "",
      binCountry: "",
      active: true,
      verified: false,
      attempts: 0,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      zipCode: zipCode,
      blocked: false,
      blockedDate: null,
      blockedReason: null,
      partnerId: profile.partnerID,
      ccv: ccv,
      createdBy: adminProfile.uid,
      createdByName: adminProfile.firstName + ' ' + adminProfile.lastName1,
      createdByEmail: adminProfile.email,
      createdDate: new Date(Date.now()),
      default: this.default
    }
    
    let duplicateCard = this.allCards.filter(card => card.last4 == info.last4 && card.expDate == info.expDate);
    if(duplicateCard.length > 0){
      resp = this.paymentService.checksum(data.creditCard);
      this.cardToEdit = duplicateCard[0];
      if(resp.valid == true && resp.checksum === duplicateCard[0].checksum && info.last4 == duplicateCard[0].last4){
      this.swalCardExist(cardNumber);
      this.loading = false;
      }else {
      resp = this.paymentService.checksum(data.creditCard);
      const dataMerge = Object.assign(info, resp)
      this.afs.collection('users').doc(profile.uid).collection('wallet').doc(info.customerNumber).set(dataMerge).then(async () => {
        let admin:any = await this.authenticationService.profile();
        if(dataMerge.default == true){
          this.walletService.setDefault({target: {checked: true}}, profile, dataMerge);
        }
        if(admin.accountType == "admin" && this.order){
          let data:createCreditCardTracer = {
            id: this.firebaseService.getDate(),
            date: new Date(),
            userName: profile.firstName + " " + profile.lastName1,
            userUID: this.order.uid,
            description: "Creates a credit card in order",
            authorizedAdminName: admin.firstName + " " + admin.lastName1,
            authorizedAdminUID: admin.uid       
          }
          this.auditTrace.createCreditCard(data, this.order.orderNo);
        }
        this.modalRef.dismiss();
      })

      this.loading = false;
      }
    }else{
      resp = this.paymentService.checksum(data.creditCard);
      const dataMerge = Object.assign(info, resp)
      this.afs.collection('users').doc(profile.uid).collection('wallet').doc(info.customerNumber).set(dataMerge).then(async () => {
        let admin:any = await this.authenticationService.profile();
        if(dataMerge.default == true){
          this.walletService.setDefault({target: {checked: true}}, profile, dataMerge);
        }
        if(admin.accountType == "admin" && this.order){
          let data:createCreditCardTracer = {
            id: this.firebaseService.getDate(),
            date: new Date(),
            userName: profile.firstName + " " + profile.lastName1,
            userUID: this.order.uid,
            description: "Creates a credit card in order",
            authorizedAdminName: admin.firstName + " " + admin.lastName1,
            authorizedAdminUID: admin.uid       
          }
          this.auditTrace.createCreditCard(data, this.order.orderNo);
        }
        this.cardAdded.emit(dataMerge);
        this.modalRef?.dismiss();
        this.loading = false;
      })
    }
  }

  public async emitSavedCardEdit(): Promise<void> {
    this.loading = true;
    const cardDetails: ICardDetails = <CardDetails>this.ccForm.value;
    const { cardNumber, cardHolder, expirationMonth, expirationYear, addressLine1, addressLine2, zipCode, ccv } = cardDetails;
    const customerNumber = this.cardToEdit.customerNumber;

    const response = this.paymentService.checksum(cardNumber);

    if(response.valid == true && response.checksum == this.cardToEdit.checksum  && this.cardToEdit.last4 == cardNumber.substring(cardNumber.length - 4)){

      const data: UserAccountCreditCard = {
        creditCard: cardNumber,
        expDate: expirationMonth+"/"+expirationYear,
        customerNumber: customerNumber,
        firstName: cardHolder,
        lastName:  '',
        address: addressLine1 + ' ' + addressLine2,
        zipCode: zipCode,
        default: this.default
      }
      this.formSaved.emit(cardDetails);
      
      this.paymentService.createAcceptaUACreditCard(data).then(async (res:any) => {
        let profile;
        let adminProfile:any = await this.authenticationService.profile();
        if(adminProfile.accountType == "admin"){
          let userToEdit
          if(this.user.uid != adminProfile.uid){
            //admin adding card
            userToEdit = this.user.uid;
          }
          else {
            userToEdit = this.order.uid;
          }
          profile = await this.authenticationService.getUserProfile(userToEdit);
        }
        else {
          profile = await this.authenticationService.profile();
        }
        const info = {
          provider: "accepta",
          brand: this.getCardType(cardNumber),
          cardType: "",
          cardHolder: res.card.firstName,
          expDate: res.card.expDate,
          customerNumber: customerNumber,
          last4: res.card.creditCard.substring(res.card.creditCard.length - 4),
          bin: "",
          binCardBrand: "",
          binOrganization: "",
          binCardType: "",
          binCardSubType: "",
          binCountry: "",
          active: true,
          verified: false,
          attempts: 0,
          blocked: false,
          blockedDate: null,
          blockedReason: null,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          zipCode: zipCode,
          partnerId: profile.partnerID,
          ccv: ccv,
          createdBy: adminProfile.uid,
          createdByName: adminProfile.firstName + ' ' + adminProfile.lastName1,
          createdByEmail: adminProfile.email,
          createdDate: new Date(Date.now()),
          default: this.default
        }

        this.afs.collection('users').doc(profile.uid).collection('wallet').doc(info.customerNumber).update(info).then(async () => {
          let admin:any = await this.authenticationService.profile();
          if(info.default == true){
            this.walletService.setDefault({target: {checked: true}}, profile, info);
          }
          if(admin.accountType == "admin"){
            let data:createCreditCardTracer = {
              id: this.firebaseService.getDate(),
              date: new Date(),
              userName: profile.firstName + " " + profile.lastName1,
              userUID: this.order.uid,
              description: `Updates ${customerNumber} credit card in order`,
              authorizedAdminName: admin.firstName + " " + admin.lastName1,
              authorizedAdminUID: admin.uid       
            }
            this.auditTrace.createCreditCard(data, this.order.orderNo);
          }
          this.modalRef.dismiss();
          this.loading = false;
        })
      });
    }
  }

  async validateCard(cardNumber: string){
    let checksum = await this.paymentService.checksum(cardNumber);
    if(cardNumber.length == 16){
      let match = await this.allCards.filter(card => card.last4 == cardNumber.substring(cardNumber.length - 4));
      this.cardToEdit = match[0];
      if(match.length > 0){
        if(checksum.valid == true && checksum.checksum == match[0].checksum && cardNumber.substring(cardNumber.length - 4) == match[0].last4){
          this.swalCardExist(cardNumber);
        }
      }

      if(this.testCards.includes(cardNumber)){
        this.swalTestCard();
      }
    }
  }

  async selectShippingAddress(event) {
    if (event.target.checked) {
      await this.firebaseService.getUserAddresses(this.user)
      this.firebaseService.addressessSubscription.subscribe(data => {
        let address = data.filter((address:any) => address.AddressType === "Shipping" && address.AddressStateCode === this.user.currentLocation.code && address.AddressDefault);
         if(address && address.length != 0){ 
           this.ccForm.controls.addressLine1.setValue(address[0].AddressLine1);
           this.ccForm.controls.addressLine2.setValue(address[0].AddressLine2);
           this.ccForm.controls.zipCode.setValue(address[0].AddressZipcode);
         }
         else {
            address = data.filter((address:any) => address.AddressType === "Shipping" && address.AddressStateCode === this.user.currentLocation.code);
            if(address && address.length != 0){ 
              this.ccForm.controls.addressLine1.setValue(address[0].AddressLine1);
              this.ccForm.controls.addressLine2.setValue(address[0].AddressLine2);
              this.ccForm.controls.zipCode.setValue(address[0].AddressZipcode);
            }
         }
      })
    } 
  }

  swalCardExist(cardNumber){
    this.translate.get('WALLET').subscribe((res: any) => {
      this.swal = res
    })
    Swal.fire({
      customClass: {
        confirmButton: 'swalPurple',
        cancelButton: 'btn-light'
      },
      title: this.swal.CARDREGISTERED,
      text: this.swal.UPDATECARD,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.swal.CONFIRM,
      cancelButtonText: this.swal.CANCEL
    }).then((result) => {
      if(result.isConfirmed){
        this.editCard = true;
        this.ccForm.controls.cardHolder.setValue(this.cardToEdit.cardHolder);
        this.ccForm.controls.cardNumber.setValue(cardNumber);
        this.ccForm.controls.expirationMonth.setValue(this.cardToEdit.expDate.split("/")[0]);
        this.ccForm.controls.expirationYear.setValue(this.cardToEdit.expDate.split("/")[1]);
        this.ccForm.controls.addressLine1.setValue(this.cardToEdit.addressLine1);
        this.ccForm.controls.addressLine2.setValue(this.cardToEdit.addressLine2);
        this.ccForm.controls.zipCode.setValue(this.cardToEdit.zipCode);
        this.ccForm.controls.ccv.setValue(this.cardToEdit.ccv);
        this.default = this.cardToEdit.default;

        const cardHolder: HTMLInputElement = document.getElementById('cc-holder-name') as HTMLInputElement;
        const ccNumber: HTMLInputElement = document.getElementById('cc-number') as HTMLInputElement;
        cardHolder.disabled = true;
        ccNumber.disabled = true;
        ccNumber.readOnly = true;
        cardHolder.readOnly = true;
      }
      else if(result.isDenied|| result.dismiss){
        this.ccForm.controls.cardNumber.setValue("");
      }
    })
  }

  swalTestCard(){
    this.translate.get('WALLET').subscribe((res: any) => {
      this.swal = res
      Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
          cancelButton: 'btn-light'
        },
        title: res.INVALIDNUMBER,
        text: res.TESTMESSAGE,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: res.TESTCLOSE,
      }).then((result) => {
          this.ccForm.controls.cardNumber.setValue("");
      });
    });
  }

  private assignDateValues(): void {
    this.months = PaymentCardService.getMonths();
    this.years = PaymentCardService.getYears();
  }

  private async buildForm(): Promise<void> {
    this.ccForm = this._fb.group(
      {
        cardNumber: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(12),
            Validators.maxLength(19),
            CardValidator.numbersOnly,
            CardValidator.checksum,
          ]),
        ],
        cardHolder: ['', Validators.compose([Validators.required, Validators.maxLength(22)])],
        expirationMonth: ['', Validators.required],
        expirationYear: ['', Validators.required],
        ccv: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
            CardValidator.numbersOnly,
          ]),
        ],
        zipCode: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(9)])],
        addressLine1: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
        addressLine2: [''],
      },
      {
        validator: CardValidator.expiration,
      }
    );

    this.ccForm.controls.cardHolder.setValue(this.user && this.user.firstName ? this.user.firstName + ' ' + this.user.lastName1 : this.name + ' ' + this.lastName);
    
    // await this.firebaseService.getUserAddresses(this.user)
    // this.firebaseService.addressessSubscription.subscribe(data => {
    //   let address = data.filter((address:any) => address.AddressType === "Billing");
    //   if(address.length != 0){    
    //     this.ccForm.controls.addressLine1.setValue(address[0].AddressLine1);
    //     this.ccForm.controls.addressLine2.setValue(address[0].AddressLine2);
    //     this.ccForm.controls.zipCode.setValue(address[0].AddressZipcode);
    //   }
    // })
  }

  public getCardType(ccNum: string): string | null {  
    let cardType = PaymentCardService.getCardType(ccNum);

    let element1 = <HTMLInputElement> document.getElementById("cc-number");
    if(cardType == 'American Express'){
      element1.maxLength = 15
    } 
    else {
      element1.maxLength = 16
    }

    return cardType
  }

  invalidCard(){
    if(this.ccForm && (this.ccForm.invalid || !this.cardsAvailable.includes(this.getCardType(this.ccForm.controls.cardNumber.value)))){
      return true
    }
    return false
  }

  getAcceptaCard(id){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('users').doc(firebase.auth().currentUser.uid).collection('wallet').doc(id).valueChanges().subscribe((res:any) => {
        resolve(res);
      });
    }); 
  }

  allCards = [];
  async getAcceptaCards(uid){
    this.afs.collection("users").doc(uid).collection("wallet").valueChanges().subscribe(async (data:any) => {
      this.allCards = data.filter(card => card.active == true);
    });
  }

  getTestCards(){
    this.afs.collection("settings").doc("testCards").valueChanges().subscribe(async (data:any) => {
      this.testCards = data.invalidCards;
      this.cardsAvailable = data.cardTypesAvailable ? data.cardTypesAvailable : [];
    })
  }

  makeDefaultPaymentMethod($event, card) {
    this.walletService.setDefault($event, this.user, card);
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes?.validate?.currentValue == true && (this.invalidCard() == false || this.allCards.length > 0)){
      this.emitSavedCard().then(() => {
        this.validateCardState.emit('valid');
      });
    }
    if(changes?.validate?.currentValue == true && this.invalidCard() == true){
      this.translate.get('PAYMENTS').subscribe((res: any) => {
        this.swal = res
      })

      Swal.fire({
        title: this.swal.ERROR,
        text: this.swal.CHECKPAYMENT,
        icon: 'error', 
        iconColor: '#ff0000 !important',
        showCancelButton: false,
        confirmButtonColor: '#34c38f',
        confirmButtonText: 'Ok',
        allowEscapeKey : false,
        allowOutsideClick: false
      })

      this.validateCardState.emit('error');
    }
  }
}
<div [ngClass]="{'disableDiv': !activeBtn}" *ngIf="!showButtons">
  <div class="w-100" style="height: calc(100vh - 70px);">
    <app-reload [message]="'RELOAD.LOADING'"></app-reload>
  </div>
</div>
<div class="row" style="width: 90%; margin: 0 5% !important;" *ngIf="showButtons">
  <div class="form-group m-0 flex-column">
    <div>
      <button *ngIf="(questionsCounter == 0 && preconsultQuestions == true && currentConsult.steps[0].stepId != 'preconsultQuestions') || (sequenceCounter == currentConsultQuestions[0]?.sequence && preconsultQuestions == false)" style="background-color: transparent !important; border: 0;" class="btn" (click)="removeProgress(); prevWizardStep.emit();"><i class="fas fa-arrow-left vertical-align cursor"></i></button>
      <button *ngIf="questionsCounter !== 0 && sequenceCounter != currentConsultQuestions[0]?.sequence" style="background-color: transparent !important; border: 0;" class="btn" (click)="removeProgress(); prevQuestion()"><i class="fas fa-arrow-left vertical-align cursor"></i></button>
      <button *ngIf="questionsCounter == 0 && preconsultQuestions == true && currentConsult.steps[0].stepId == 'preconsultQuestions'" style="margin-top: 30px; border: 0; padding: 0;"></button>
    </div>
  </div>
</div> 
<!-- <div *ngIf="showButtons" [ngClass]="{ 'disableDiv': userProfile.accountType == 'admin' || (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft'), 'extraMargin': questionsCounter == 0}" class="row"> -->
<div *ngIf="showButtons" [ngClass]="{ 'disableDiv': (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft') || !activeBtn, 'extraMargin': questionsCounter == 0}" class="row">
  <div class="justify-content-center w-100" *ngFor="let question of currentConsultQuestions; trackBy: trackByFn;" >
    <div class="form-group row justify-content-center questions"  *ngIf="question.sequence == sequenceCounter; let i = index" [attr.data-index]="i">
      <div class="question-list" style="text-align: center;">
        <!-- <h3 *ngIf="question.assessment && question.assessment != 'lastQuestionsInConsult'">Assessment: {{question.assessment}}</h3>
        <h3 *ngIf="question.assessment && question.assessment == 'lastQuestionsInConsult'">Assessment: {{currentConsultQuestions[0].assessment}}</h3>
        <h3 *ngIf="!question.assessment">Assessment: {{currentConsult.Type}}</h3> -->
        <h2 class="questionFont">{{question.question | translation:currentLang}}</h2>
        <h5 class="infoFont">{{question?.info | translation:currentLang}}</h5>
        <!-- <h5 *ngIf="question.type == 'BP' && currentLang == 'en'" class="infoFont">Need to check your Blood Pressure? Learn where to meassure it <a target="_blank" [href]="'https://www.google.com/maps/search/Where+to+get+blood+pressure+checked+near+me'">here</a></h5>
        <h5 *ngIf="question.type == 'BP' && currentLang == 'es'" class="infoFont">Necesita medir su presión arterial? Aprenda donde hacerlo <a target="_blank" [href]="'https://www.google.com/maps/search/Donde+medir+la+presión+arterial+cerca+de+mí'">aquí</a></h5> -->
      </div>
      <div [ngClass]="{'BMI': question.type == 'BMI'}" class="options-cards d-flex flex-column align-items-center">
        <ng-select style="max-width:500px" class="searchConditions mb-4 w-100" *ngIf="question.type === 'SearchSurgeries'" placeholder="{{'HEADER.SEARCH' | translate}}" [items]="filteredSurgeries" [loading]="loading" 
          bindLabel="0" [multiple]="true" (search)='searchSurgeries($event)' (change)="onConditionSelected($event, question)" [(ngModel)]="question.answer">
        </ng-select>
        <ng-select style="max-width:500px" class="searchConditions mb-4 w-100" *ngIf="question.type === 'SearchConditions'" placeholder="{{'HEADER.SEARCH' | translate}}" [items]="filteredConditions" [loading]="loading" 
          bindLabel="0" [multiple]="true" (search)='Rx_getConditions($event)' (change)="onConditionSelected($event, question)" [(ngModel)]="question.answer">
        </ng-select>
        <ng-select style="max-width:500px" class="custom text-danger mb-4 w-100" *ngIf="question.type === 'SearchMedicines'" placeholder="{{'HEADER.SEARCH' | translate}}"   [loading]="loading"
          [multiple]="true" [(ngModel)]="question.answer" (keyup)="getNDC($event)" (change)="saveMeds($event, question)">
          <ng-option *ngFor="let med of medicines" [value]="med">{{med.name}}</ng-option>
        </ng-select>
        <ng-container *ngIf="question.type != 'BP' && question.type != 'slider' && question.type != 'SelectionWithOptions' && question.type != 'MultipleSelectionWithOptions' && question.type != 'uploadDocuments'">
          <div class="row options mb-1 justify-content-center" *ngFor="let option of question.options; let j = index; trackBy: trackByFn;" style="max-width: 450px; text-align-last: center;" [attr.data-index]="j" >
            <div class="grid-wrapper grid-col-3 mx-auto d-flex" >
              <div class="selection-wrapper animate__animated animate__fadeInDown" >
                <label for="{{option}}" class="selected-label answers animate" (click)="saveAnswers($event, question)">
                  <input class="selects" type="radio" *ngIf="question.displayType =='text' && question.type === 'Selection'" value="{{option}}" name="selected-item" id="{{option}}" [(ngModel)]="question.answer">
                  <input class="selects" type="radio" *ngIf="question.displayType =='textImage' && question.type === 'Selection'" value="{{option.text}}" name="selected-item" id="{{option.text}}" [(ngModel)]="question.answer.text">
                  <input class="selects" type="radio" *ngIf="question.displayType =='image' && question.type === 'Selection'" value="{{option}}" name="selected-item" id="{{option}}" [(ngModel)]="question.answer.text">

                  <input class="selects" type="radio" *ngIf="question.type === 'SelectionExplain'" value="{{option}}" name="selected-item" id="{{option}}" [(ngModel)]="question.answer">
                  <input class="selects" type="radio" *ngIf="question.type === 'SearchMedicines' || question.type == 'SearchConditions' || question.type == 'SearchSurgeries' " value="{{option}}" name="selected-item" id="{{option}}" [(ngModel)]="question.optionAnswer">
                  <input class="selects" type="checkbox" *ngIf="question.type === 'Multiple'" name="selected-item" id="{{option}}"  [ngModel]="setChecked(option, question)">
                  <div class="multiple-text"  *ngIf="question.type === 'BP'">
                    <input id="{{option}}" class="selects" type="text"  name="selected-item"  [(ngModel)]="question.answer">
                  </div>
                  <div class="d-flex justify-content-center flex-row" >
                    <!-- <img *ngIf="question.icons" class="activeIcon" style="width: 35px; padding: 5px; height: 70px;" src="{{question.icons[j]}}"> -->
                    <div value={{j}} id="{{j}}" class="answer-card" [ngClass]="{'highlightSignature': highlightSignature && question.lastQuestion}"  *ngIf="question.type != 'slider' && question.type !== 'Search' && question.type !== 'BP' && question.type !== 'uploadDocuments'">
                      <span class="icon-check" *ngIf="question.type == 'Multiple'" ></span>
                      <span value={{j}} id="{{j}}" class="check-icon" *ngIf="question.type !== 'Search' && question.type !== 'BP' && question.type !== 'Multiple'" ></span>
                      <div class="text ml-2">
                        <p *ngIf="question.displayType == 'text'" class="m-0 optionOfQuestions optionFont">{{option | translation:currentLang}}</p>
                        <img *ngIf="question.displayType == 'image'" value={{j}} id="{{j}}" src="{{option}}" >
                        <div class="d-flex align-items-center flex-column w-100" value={{j}} id="{{j}}" *ngIf="question.displayType == 'textImage'">
                          <p value={{j}} id="{{j}}" class="m-0 optionOfQuestions optionFont">{{option.text | translation:currentLang}}</p>
                          <img value={{j}} id={{j}} src="{{option.image | translation:currentLang}}" >
                        </div>
                      </div>
                    </div>
                  </div>  
                  <p *ngIf="highlightSignature && question.lastQuestion" class="mt-2" style="text-align: center;">* Acepte y Firme la DECLARACIÓN DE AUTENTICIDAD de Documentos Y VERACIDAD DE LA INFORMACIÓN</p>                    
                  <div *ngIf="question.lastQuestion && question.answered == true">
                    <p class="bold" style="text-align: center;">Signed By: {{question.signedBy}} Ref: {{question.signRef}}</p>
                  </div>
                </label>
                <div class="row options mb-1 justify-content-center mx-auto" *ngIf="question.type === 'Multiple' && option == question.options[question.options.length-2] && setChecked(question.options[question.options.length -2], question) && question.explain != undefined">
                  <div class="grid-wrapper d-flex">
                    <div class="selection-wrapper animate__animated animate__fadeInDown" >
                      <label for="explain" class="selected-label answers animate">
                        <span class="d-flex justify-content-start mx-auto" style="text-align: center; width: 90%;">{{question?.subquestion | translation:currentLang}}</span>
                        <div class="answer-card p-0 mx-auto" style="background-color: transparent; width: 90%;" >
                          <div class="text d-flex" style="flex-direction: column; margin: 0;">
                            <textarea id="explain" [(ngModel)]="question.explain" (blur)="saveExplanation($event, question)" placeholder="{{question?.hints | translation: currentLang}}"></textarea>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="text-center animate" *ngIf="question.type === 'BP'">
            <span class="d-flex justify-content-center w-100">{{'CONSULTATIONS.SYSTOLIC' | translate}}</span>
            <div class="d-flex btn-group btn-group-toggle mt-3 w-100" data-toggle="buttons">
              <label for="sys" class="inp bmi animate BP" [ngClass]="classMapSystolic">
                <div class="d-flex align-items-center justify-content-center mb-4 flex-wrap" style="flex-direction: row;">
                  <ng-select #sys style="width: 150px; font-size: 18px;" name="systolic" id="sys" (focus)="checkDropdown('systolic')" (focusout)="onFocusOutSystolic();" (change)="onConditionSelected($event, question);" [ngModelOptions]="{standalone: true}" [searchable]="false" [clearable]="false" [(ngModel)]="systolic" (close)="onSelectSystolicClose();" (blur)="onBlurSystolic();" (focus)="onFocusSystolic()" (open)="onOpenSystolic();">
                    <ng-option [attr.id]="sys" #sys *ngFor="let s of getSystolicValues()" [value]="s">{{s}}</ng-option>
                  </ng-select>
                </div>
              </label>
            </div>
            <br>
            <hr>
            <br>
            <span class="animate">{{'CONSULTATIONS.DIASTOLIC' | translate}}</span>
            <div class="d-flex btn-group btn-group-toggle mt-3" data-toggle="buttons">
              <label for="dias" class="inp bmi animate BP" [ngClass]="classMapDiastolic">
                <div class="d-flex align-items-center justify-content-center mb-4 flex-wrap" style="flex-direction: row;">
                  <ng-select #dias style="width: 150px; font-size: 18px;" id="dias" name="diastolic" (focus)="checkDropdown('diastolic');" (focusout)="onFocusOutDiastolic();" (change)="onConditionSelected($event, question);" [ngModelOptions]="{standalone: true}" [searchable]="false" [clearable]="false" [(ngModel)]="diastolic" (close)="onSelectDiastolicClose();" (blur)="onBlurDiastolic();" (focus)="onFocusDiastolic()" (open)="onOpenDiastolic();">
                    <ng-option [attr.id]="dias" #dias *ngFor="let d of getDiastolicValues()" [value]="d">{{d}}</ng-option>
                  </ng-select>
                </div>
              </label>
            </div>
            <!-- <input #focusInput type="text" placeholder="Focus will move here"/> -->
        </div>

        <div *ngIf="question.type == 'slider'" class="slidecontainer d-flex flex-column align-items-center mt-2 animate">
          <p *ngIf="sliderValue">{{sliderValue}} {{question.rangeTarget | translation:currentLang}}</p>
          <p *ngIf="!sliderValue" style="color: transparent;">Slider</p>
          <input type="range" min="{{question.range[0]}}" max="{{question.range[1]}}" value="{{question.answer || question.range[0]}}" class="slider" [ngModelOptions]="{standalone: true}"  [(ngModel)]="sliderValue" id="{{question.range[0]}}" (touchend)="saveAnswers($event, question)" (mouseup)="saveAnswers($event, question)">
        </div>

        <div class="text-center" style="width: -webkit-fill-available;" *ngIf="question.type === 'BMI'">
          <label for="feet" style="min-height: 300px;" class="inp bmi animate">
            <div class="d-flex align-items-center justify-content-center mb-4 flex-wrap" style="flex-direction: row;">
              <p class="cyan mr-2 animate" style="margin-bottom: 0; font-size: 18px;">{{'CONSULTATIONS.HEIGHT' | translate | uppercase}}</p> 
              <!-- <input style="width: 50%; padding-top: 0px; height: 30px; min-width: 40px;" type="tel" id="feet" name="selected-item" value="question.answer[0]" (keyup)="tempAnswer();" (blur)="onConditionSelected($event, question)" [(ngModel)]="question.answer[0]">
              <span class="ml-2 mr-3">{{'CONSULTATIONS.FEET' | translate}}</span>
              <input style="width: 50%; padding-top: 0px; height: 30px; min-width: 40px;" type="tel" id="inches" name="selected-item" value="question.answer[1]" (keyup)="tempAnswer();" (blur)="onConditionSelected($event, question)" [(ngModel)]="question.answer[1]">
              <span class="ml-2">{{'CONSULTATIONS.INCHES' | translate}}</span> -->
              <ng-select #feet style="width: 70px; font-size: 18px;" id="feet" (keyup)="tempAnswer();" (blur)="onConditionSelected($event, question)" [ngModelOptions]="{standalone: true}" [searchable]="false" [clearable]="false" [(ngModel)]="feetSelected">
                <ng-option [attr.id]="feet" #feet  *ngFor="let feet of getFeetValues()" [value]="feet">{{feet}}</ng-option>
              </ng-select>
              <span class="ml-2 mr-3 animate">{{'CONSULTATIONS.FEET' | translate}}</span>
              <ng-select #feet style="width: 70px; font-size: 18px;" id="inches" (keyup)="tempAnswer();" (blur)="onConditionSelected($event, question)" [ngModelOptions]="{standalone: true}" [searchable]="false" [clearable]="false" [(ngModel)]="inchesSelected">
                <ng-option [attr.id]="inches" #inches  *ngFor="let inches of getInchesValues()" [value]="inches">{{inches}}</ng-option>
              </ng-select>
              <span class="ml-2 animate">{{'CONSULTATIONS.INCHES' | translate}}</span>
            </div>
            <div class="d-flex align-items-center justify-content-center mb-4 animate" style="flex-direction: row;">
              <p class="cyan mr-2" style="margin-bottom: 0%; font-size: 18px;">{{'CONSULTATIONS.WEIGHT' | translate | uppercase}}</p>
              <input style="width: 150px; padding-top: 0px; height: 40px; font-size: 18px;" type="tel" id="pounds" maxlength="3" name="selected-item" value="question.answer[2]" (keyup)="tempAnswer();" (blur)="onConditionSelected($event, question)" [(ngModel)]="question.answer[2]">
              <span class="ml-2" style="width: 50px; text-align: start; font-size: 18px;">{{'CONSULTATIONS.POUNDS' | translate}}</span>
            </div>
          </label>
        </div>
        <div class="row options mb-1" *ngIf="question.type === 'SelectionExplain' && question.answer === question.options[question.options.length -1]">
          <div class="grid-wrapper grid-col-3 mx-auto d-flex" >
            <div class="selection-wrapper animate__animated animate__fadeInDown" >
              <label for="text" class="selected-label answers animate">
                <span class="d-flex justify-content-start mx-auto" style="text-align: center; width: 90%;" >{{question?.subquestion | translation:currentLang}}</span>
                <div class="answer-card mx-auto p-0" style="background-color: transparent; width: 90%;" >
                  <div class="text d-flex" style="flex-direction: column;">
                    <textarea id="text" [(ngModel)]="question.explain" (blur)="saveExplanation($event, question)" placeholder="{{question.hints | translation: currentLang}}"></textarea>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="text-center" *ngIf="question.type === 'SelectionWithOptions' || question.type === 'MultipleSelectionWithOptions'">
          <div class="row options mb-1" *ngFor="let q of question.options; let j = index; trackBy: trackByFn;" [attr.data-index]="j" >
            <div class="grid-wrapper grid-col-3 mx-auto d-flex" >
              <div class="selection-wrapper animate__animated animate__fadeInDown" >
                <label class="selected-label answers animate" for="{{q.options}}" (click)="saveAnswers($event, question)">
                  <input class="selects" type="radio" id="{{q.options}}" value="{{q.options}}" name="selected-item" [(ngModel)]="question.answer">
                  <div class="d-flex justify-content-center flex-row">
                    <div class="answer-card">
                      <span class="check-icon"></span>
                      <div class="text ml-2">
                        <p class="m-0 optionOfQuestions optionFont" >{{q.options | translation:currentLang}}</p>
                      </div>
                    </div>
                  </div>                  
                </label>
                <div *ngIf="q.options == question.answer">
                  <p *ngIf="q.subOptions" class="animate" style="text-align: center;">{{question?.subquestion | translation:currentLang}}</p>
                  <div *ngFor="let sub of q.subOptions">
                    <label *ngIf="question.type == 'MultipleSelectionWithOptions' || question.type == 'SelectionWithOptions'" class="selected-label answers animate" for="{{sub}}" id="{{sub}}" (click)="saveSubOptions($event, question)">
                      <input *ngIf="question.type == 'SelectionWithOptions'" class="selects" id="{{sub}}" value="{{sub}}" name="selected-item" [(ngModel)]="question.optionAnswer">
                      <input *ngIf="question.type == 'MultipleSelectionWithOptions'" [ngModel]="setSubOptions(sub, question)" class="selects" id="{{sub}}" value="{{sub}}" name="selected-item">
                      <div class="answer-card subOptions" id="{{sub}}" [ngClass]="{subIcon: setSubOptions(sub, question) || sub == question.optionAnswer}">
                        <span class="check-icon" id="{{sub}}" *ngIf="question.type == 'SelectionWithOptions'"></span>
                        <span class="icon-check" id="{{sub}}" *ngIf="question.type == 'MultipleSelectionWithOptions'"></span>    
                          <!-- style="filter: invert(33%) sepia (54.3%) saturate(5833%) hue-rotate(326deg) brightness(130%) contrast(74%)" -->
                        <div class="text ml-2" id="{{sub}}">
                            <p style="margin-bottom: 0px;" id="{{sub}}">{{sub | translation:currentLang}}</p>
                        </div>
                      </div>                         
                    </label>
                  </div>                              
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center animate" *ngIf="question.type === 'uploadDocuments'">
          <div *ngIf="allPrescriptions.length == 0" class="mt-3">
            <h3>{{'CONSULTATIONS.NOLABS' | translate}}</h3>
          </div>
          <div class="d-flex flex-wrap mt-2">
            <label for="{{prescription.ID}}" class="radio-card" style="margin-right: 30px; margin-bottom: 30px; cursor: default;" *ngFor="let prescription of allPrescriptions; let i = index">
              <div id="{{prescription.ID}}" class="card-content-wrapper">          
                <div class="card-content">
                  <div class="d-flex justify-content-center align-items-center" style="font-size: 80px;">
                  <img style="width: 70px; height: auto; height: intrinsic;" src="{{labLogo}}">
                  </div>
                  <h5 *ngIf="prescription.signedDate" style="text-align: center;">{{'CONSULT-PRESCRIPTIONS.SIGNED' | translate}}: {{prescription.signedDate.toDate() | date:'MMMM dd, yyyy':'':patientProfile.preferedLang}}</h5>
                  <div class="sections d-flex mx-auto justify-content-center mb-2">
                    <button *ngIf="prescription.z_base64" class="btn btn-cyan" style="padding-top: 0 !important; padding-bottom: 0 !important;" (click)="prescriptionID = prescription.ID; openPrescription(internalPrescription); base64 = prescription.z_base64; pdfName = 'REFERRALS.REFERRAL'" [ngClass]="{'badge-soft-success': true}">{{'CONSULTATIONS.VIEWLAB' | translate}}</button>
                  </div>
                  <p class="m-0">{{'CONSULTATIONS.UPLOADLABS' | translate}}</p>
                  <input type="file" id="fileInput" hidden (change)="prescriptionID = prescription.ID; pdfSelected($event)"> 
                  <label for="fileInput" class="custom-file-button" style="cursor: pointer;">{{'CONSULTATIONS.UPLOADPDFFILE' | translate}}</label>
                  <div *ngIf="labPdfs[i] && labPdfs[i].length > 0" class="mt-2">
                    <p class="m-0">{{'CONSULTATIONS.UPLOADEDDOCS' | translate}}</p>
                    <div *ngFor="let pdf of labPdfs[i]">
                      <button style="background: transparent;border: 0;" (click)="prescriptionID = prescription.ID; base64 = pdf.data().pdf; pdfName = pdf.data().pdfName; openPrescription(internalPrescription)">{{pdf.data().pdfName}}</button>
                    </div> 
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>

        <div class="row options mb-1" *ngIf="question.type === 'Text'">
          <div class="grid-wrapper grid-col-3 mx-auto d-flex" >
            <div class="selection-wrapper animate__animated animate__fadeInDown" >
              <label for="explain" class="selected-label answers animate">
                <div class="answer-card mx-auto p-0" style="background-color: transparent;" >
                  <div class="text d-flex" style="flex-direction: column;">
                    <textarea id="explain" [(ngModel)]="question.explain" style="min-height: 150px;" (blur)="saveExplanation($event, question)" placeholder="{{question.hints | translation: currentLang}}"></textarea>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div [ngClass]="{'disableDiv': !activeBtn}" class="row" *ngIf="showButtons">
  <div *ngIf="currentQuestion.answered == true || currentQuestion.required == false" [disabled]="disabledBtn" class="form-group justify-content-center text-center mt-2 flex-column" style="margin: 0 auto;">
    <button *ngIf="preconsultQuestions && questionsCounter !== currentConsultQuestions.length - 1 && currentQuestion.type != 'Selection'" style="border-radius: 0.25rem;" class="btn btn-primary nextbtn text-center mb-2" (click)="doProgress(); nextQuestion(this.questionsCounter + 1, true);" [disabled]="handleNextButtonState()">{{'PROFILE.NEXT' | translate | uppercase}}</button>
    <button *ngIf="preconsultQuestions && questionsCounter === currentConsultQuestions.length - 1 && currentQuestion.type != 'Selection'" style="border-radius: 0.25rem;" class="btn btn-primary nextbtn text-center mb-2" (click)="doProgress(); nextWizardStep.emit(currentWizardStep + 1); checkNextQuestion();" [disabled]="handleNextButtonState()">{{'PROFILE.NEXT' | translate | uppercase}}</button>        
    <button *ngIf="!preconsultQuestions && questionsCounter !== allQuestionsLength.length - 1 && currentQuestion.type != 'Selection'" style="border-radius: 0.25rem;" class="btn btn-primary nextbtn text-center mb-2" (click)="doProgress(); nextQuestion(this.questionsCounter + 1, true);" [disabled]="handleNextButtonState()">{{'PROFILE.NEXT' | translate | uppercase}}</button>
    <button *ngIf="!preconsultQuestions && questionsCounter === allQuestionsLength.length - 1 && currentQuestion.type != 'Selection'" style="border-radius: 0.25rem;" class="btn btn-primary nextbtn text-center mb-2" (click)="doProgress(); nextWizardStep.emit(currentWizardStep + 1); checkNextQuestion();" [disabled]="handleNextButtonState()">{{'PROFILE.NEXT' | translate | uppercase}}</button>        
    <!-- <div style="padding-bottom: 20px;">
      <button *ngIf="questionsCounter !== 0 && sequenceCounter != currentConsultQuestions[0]?.sequence" style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="removeProgress(); prevQuestion();">{{'PROFILE.BACK' | translate | uppercase}}</button>
      <button *ngIf="questionsCounter == 0 && preconsultQuestions == true && currentConsult.hasLabs && currentConsult.hasLabs != 'none'" style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="removeProgress(); prevWizardStep.emit(currentWizardStep - 1);">{{'PRECONSULT.BACK' | translate | uppercase}}</button>
      <button *ngIf="sequenceCounter == currentConsultQuestions[0]?.sequence && treatment && preconsultQuestions == false" style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="removeProgress(); prevWizardStep.emit(currentWizardStep - 1);">{{'PRECONSULT.BACK' | translate | uppercase}}</button>
      <button *ngIf="sequenceCounter == currentConsultQuestions[0]?.sequence && !treatment && preconsultQuestions == false" style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="removeProgress(); prevWizardStep.emit(currentWizardStep - 2); checkPrevQuestion()">{{'PRECONSULT.BACK' | translate | uppercase}}</button>
    </div> -->
  </div>
  <div *ngIf="(currentQuestion.answered == false && currentQuestion.required == true)" class="form-group justify-content-center text-center mt-2 flex-column" style="margin: 0 auto;">
    <button style="border-radius: 0.25rem;" *ngIf="currentQuestion.type != 'Selection'" class="btn btn-primary nextbtn text-center mb-2" [disabled]="(handleNextButtonState() && !currentQuestion.lastQuestion)" (click)="highlightSignatureAnimation()">{{'PROFILE.NEXT' | translate | uppercase}}</button>
    <!-- <div style="padding-bottom: 20px;">
      <button *ngIf="questionsCounter !== 0 && sequenceCounter != currentConsultQuestions[0]?.sequence" style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="removeProgress(); prevQuestion();">{{'PROFILE.BACK' | translate | uppercase}}</button>
      <button *ngIf="questionsCounter == 0 && preconsultQuestions == true && currentConsult.hasLabs && currentConsult.hasLabs != 'none'" style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="removeProgress(); prevWizardStep.emit(currentWizardStep - 1);">{{'PRECONSULT.BACK' | translate | uppercase}}</button>
      <button *ngIf="sequenceCounter == currentConsultQuestions[0]?.sequence && treatment && preconsultQuestions == false" style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="removeProgress(); prevWizardStep.emit(currentWizardStep - 1);">{{'PRECONSULT.BACK' | translate | uppercase}}</button>
      <button *ngIf="sequenceCounter == currentConsultQuestions[0]?.sequence && !treatment && preconsultQuestions == false && currentConsult.hasLabs && currentConsult.hasLabs != 'none'" style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="removeProgress(); prevWizardStep.emit(currentWizardStep - 2); checkPrevQuestion()">{{'PRECONSULT.BACK' | translate | uppercase}}</button>
      <button *ngIf="sequenceCounter == currentConsultQuestions[0]?.sequence && !treatment && preconsultQuestions == false && (!currentConsult.hasLabs || currentConsult.hasLabs == 'none')" style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="removeProgress(); prevWizardStep.emit(currentWizardStep - 4); checkPrevQuestion()">{{'PRECONSULT.BACK' | translate | uppercase}}</button>
    </div> -->
  </div>
</div>         

<ng-template #internalPrescription let-modal>
  <div class="row mt-3 product-wizard">
    <div class="col-lg-12">
      <div class="row justify-content-between px-2">
        <h3>{{pdfName | translate}}</h3>
        <i class="ri-close-fill" style="font-size: 23px;" (click)="modal.dismiss('Cross click')"></i>
      </div>
      <ng-template [ngTemplateOutlet]="pdfViewer"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #pdfViewer let-modal>
  <div *ngIf="base64 != undefined" class="pdf-container media-body overflow-hidden" style="min-height: 600px;">
    <ngx-extended-pdf-viewer *ngIf="base64 != undefined" [base64Src]="base64" backgroundColor="#e8e8ef"
      [showZoomButtons]="true"  [useBrowserLocale]="true" [height]="'600px'"
      [sidebarVisible]="false" [showHandToolButton]="true" [zoom]="'50%'">
    </ngx-extended-pdf-viewer>
  </div>
</ng-template> 

<ng-template #telehealthConcent let-modal>
  <div class="row justify-content-between px-2">
    <h3>{{'CONSULTATIONS.TELEHEALTHCONSENT' | translate}}</h3>
  </div>
  <div class="row justify-content-center">
    <div class="col-lg">
    </div>
  </div>
</ng-template>
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {AngularFirestore} from '@angular/fire/compat/firestore';
import 'firebase/firestore';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import { Subject, Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer} from '@angular/platform-browser';
import { TelehealthService } from '../telehealth/telehealth.service';

import { ConsultationsService } from '../consultations/consultations.service';
import { RegisterService } from 'src/app/core/services/register.service';

import { DashboardService } from 'src/app/core/services/dashboard/dashboard.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { Profile } from 'src/app/core/services/interfaces';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('termsModal') termsModal: any;
  @ViewChild('authUsersModal') authUsersModal: any;

  pagetitle = "HOME.TITLE"
  profile:any;
  exception = ''
  currentLang = this.translate.store.currentLang;

  subscriptions = new Subscription();

  termsOfUse 

  orders:any[] = [];
  consults:any[] = [];
  telehealth: any[] = [];
  helpdesk: any[] = [];
  labs = [];
  referrals = []

  appointments:any[] = [];

  ngOnDestroy$:Subject<boolean> = new Subject();

  base64;
  claims;

  constructor(
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public translateBreadcrumb: TranslateService,
    public translate: TranslateService,
    private modalService: NgbModal,
    private sanitaizer: DomSanitizer,
    public telehealthService: TelehealthService,
    public consultationsService: ConsultationsService,
    public registerService: RegisterService,
    private router: Router,
    private dashboardService: DashboardService,
    private authService: AuthenticationService,
    
  ) { 
    this.ngOnDestroy$.next(true);

    let sub = this.afAuth.authState.subscribe(user => {
      if (user) {
        this.authService.userSubscription.subscribe(res => {
          this.profile = res
        
          if(this.currentLang == null && this.profile['preferedLang']){
            this.currentLang = this.profile['preferedLang']
          }
          else if(this.currentLang == null){
            this.currentLang = 'es'
          }
        })
       this.subscriptions.add(sub)
      } 
    });
    this.subscriptions.add(sub);
    // this.deleteProdData();
  }

  async ngOnInit(){
    
    this.claims = await this.authService.getClaims();
    this.getUser();

    if(this.modalService.hasOpenModals()){
      this.modalService.dismissAll();
    }

    let remoteConfig: any = await this.authService.getRemoteConfig()
    this.base64 = remoteConfig.manual
  }
  
  ngOnDestroy(): void {
    this.ngOnDestroy$.next(true)
    this.subscriptions.unsubscribe();
  }

  // getUser() {
  //   let sub = this.afAuth.user.pipe(takeUntil(this.ngOnDestroy$)).subscribe(async user => {
  //     if(user){
  //         let month = (new Date().getMonth() + 1).toString()
  //         let day = (new Date().getDate()).toString()
  //         if(Number(month) < 10){
  //           month = '0' + month
  //         }
  //         if(Number(day) < 10){
  //           day = '0' + day
  //         }
  //         let date = new Date().getFullYear() + '' + month + '' + day
  //         let expDate = this.profile?.idExpDate?.replaceAll('-','')

  //         if(this.profile.identification == '../assets/images/users/IDFront.svg' 
  //         // || this.profile.identificationBack == '../assets/images/users/IDBack.svg' 
  //         || (this.profile.idExpDate != '' && Number(expDate) < Number(date))){
  //           this.exception = 'HOME.IDEXCEPTION'
  //         }
  //         else {
  //           this.exception = ''
  //         }

  //         // let sub = this.afs.collection<any>('users').doc(user.uid).collection('authUsers').doc('1').valueChanges().subscribe(async user1 => {
  //         //   if(user1 == undefined && this.profile.accountType == 'patient'){
  //         //     this.modalService.open(this.authUsersModal, {size: 'lg', backdrop: 'static', centered: true, keyboard: false})
  //         //   }else {
  //           if(!this.claims.devAdmin){
  //             this.getTerms()
  //           }
  //         //   }
  //         // })
  //         // this.subscriptions.add(sub)
  //         this.consults = await this.dashboardService.getConsults(this.profile);
  //         this.telehealth = await this.dashboardService.getTelehealth(this.profile);    
  //         this.handleTelehealth()
          
  //         if(this.profile.accountType == 'patient'){
  //           this.getReferrals()
  //           this.getPrescriptions()
  //           this.orders = await this.dashboardService.getOrders(this.profile)
  //         }
  //           // this.calculateProgress()
  //     }
  //   });
  //   this.subscriptions.add(sub)
  // }

  getUser() {
  this.afAuth.user
    .pipe(takeUntil(this.ngOnDestroy$))
    .subscribe(async user => {
      if (user) {
        const currentDate = this.getCurrentDate();
        const expDate = this.profile?.idExpDate?.replaceAll('-', '');
        // this.exception = this.getException(currentDate, expDate);

        if (!this.claims.devAdmin) {
          this.getTerms();
        }

        this.consults = await this.dashboardService.getConsults(this.profile);
        this.telehealth = await this.dashboardService.getTelehealth(this.profile);
        this.handleTelehealth();

        if (this.profile?.accountType === 'patient') {
          this.getReferrals();
          this.getPrescriptions();
          this.orders = await this.dashboardService.getOrders(this.profile);
        }
      }
    });
  }

  private getCurrentDate(): string {
    const month = (new Date().getMonth() + 1).toString().padStart(2, '0');
    const day = new Date().getDate().toString().padStart(2, '0');
    return `${new Date().getFullYear()}${month}${day}`;
  }
  
  private getException(currentDate: string, expDate: string | undefined): string {
    if (
      this.profile?.identification === '../assets/images/users/IDFront.svg' ||
      (expDate && Number(expDate) < Number(currentDate))
    ) {
      return 'HOME.IDEXCEPTION';
    }
    return '';
  }

  openModal = false
  async getTerms(){
    let sub = this.afs.collection("settings").doc("Terms").collection('Terms', ref => ref.where('termsUserType', '==', this.profile.accountType)).valueChanges().pipe(takeUntil(this.ngOnDestroy$)).subscribe(async data => {
      this.termsOfUse = await [data[data.length -1]]
      if(this.termsOfUse[0]){
        if(this.currentLang == 'en'){
          this.termsOfUse.html = this.sanitaizer.bypassSecurityTrustHtml(this.termsOfUse[0].html_en)
        }
        else {
          this.termsOfUse.html = this.sanitaizer.bypassSecurityTrustHtml(this.termsOfUse[0].html_es)
        }
        
        if(( await this.profile?.terms == undefined
          || await this.profile?.terms[0]?.acceptedTerms == false 
          || await this.profile?.terms[0]?.version != await this.termsOfUse[0]?.version) && this.openModal == false){
          this.modalService.open(this.termsModal, {size: 'lg', backdrop: 'static', centered: true, keyboard: false})
          this.openModal = true
        }  
      }      
    })   
    this.subscriptions.add(sub);
  }

  getPrescriptions(){
    let allConsults = []
    this.afs.firestore.collection("consultation").where('UID_Patient', '==', this.profile.uid).get().then(res => {
      allConsults = res.docs;
      this.afs.collection("prescription", ref => ref.where("uid", '==', this.profile.uid).where('prescription_type', '==', 'Lab')).valueChanges().subscribe(async (presc:any) => {
        this.labs = [];
        for(let i = 0; i < presc.length; i++){
          let consultStatus = allConsults.filter(c => c.data().ID == presc[i].consultID)
          let prescExp = presc[i].date.seconds * 1000 + 60*24*60*60*1000; //60 days after the prescription created
          if(consultStatus.length > 0 && Number(Date.now()) <= Number(prescExp)){ // && consultStatus[0].data().Status == 'In Progress'
            this.labs.push(Object.assign(presc[i], consultStatus[0].data()))
          }
        }
      })
    })
  }

  getReferrals(){
    let allConsults = []
    this.afs.firestore.collection("consultation").where('UID_Patient', '==', this.profile.uid).where('Status', 'in', ['In Progress', 'Completed']).get().then(res => {
      allConsults = res.docs.map(c => c.data());
      this.referrals = [];
      for(let i = 0; i < allConsults.length; i++){
        this.afs.firestore.collection("referrals").where('patientUid','==', this.profile.uid).where("consultID", '==', allConsults[i].ID).get().then(async (ref:any) => {
          for(let j = 0; j < ref.docs.length; j++){
            let refExp = ref.docs[j].data().date.seconds * 1000 + 60*24*60*60*1000; //60 days after the prescription created
            if(Number(Date.now()) <= Number(refExp)){
              this.referrals.push(Object.assign(ref.docs[j].data(), allConsults[i]))
            }
          }
        })
      }
    })
  }

  close(){
    this.modalService.dismissAll()
    this.openModal = false
  }

  changeAccountType(type){
    type = type.includes('×') ? type.replace('×', ''): type
    if(type){
      this.afs.collection('users').doc(this.profile.uid).update({accountType: type});
    }
  }

  goToConsult(consult, lab?){
    let nav = lab ? consult.consultID : consult.ID
    this.profile.accountType == 'patient' ? this.router.navigate(['consultations'], { queryParams: { consultation: nav}}) : consult.Status == ('In Progress' || 'Completed') ? this.router.navigate([`consults/${consult.ID}`]) : ''
  }

  goToOrder(order){
    this.router.navigate([`orders&Refills/${order.id}`]);
  }
  
  goToComponent(component:string){
    this.router.navigate([`${component}`]);
  }

  handleTelehealth(){
    this.appointments = this.telehealth.map(t => {
      if(t.eventTimezoneValue.split('UTC')[1] != this.profile.timezone.timeValue){
        let changeStart = new DatePipe('en-Us').transform(t.start+':00.000' + t.eventTimezoneValue.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.profile.timezone.timeValue);
        let changeEnd = new DatePipe('en-Us').transform(t.end+':00.000' + t.eventTimezoneValue.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.profile.timezone.timeValue);
        t.start = changeStart
        t.end = changeEnd
      }
      
      const { start, end } = t;
      let startTime = start.split("T")[1].replace(":","").trim();
      let endTime = end.split("T")[1].split(":")[0] > start.split("T")[1].split(":")[0] ? Number(end.split("T")[1].replace(":","").trim()) - 40 : end.split("T")[1].replace(":","").trim();

      t = Object.assign(t, {duration: endTime - startTime});
      return t; 
    });
  }

  async contactus(){
    this.router.navigate(["/inboxandsupport/contactus"])
  }

  async goToHelpdesk(){
    this.router.navigate(["/inboxandsupport/helpdesk"])
  }

  goToProfile(){
    this.router.navigate(['/profile'])
  }
}
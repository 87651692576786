import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PharmacyOrderScheduleService } from 'src/app/core/services/pharmacy-order-schedule.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-melanies-command-center',
  templateUrl: './melanies-command-center.component.html',
  styleUrls: ['./melanies-command-center.component.scss']
})

export class MelaniesCommandCenterComponent {
  constructor(private afs: AngularFirestore,
              private pharmacyOrderScheduleService: PharmacyOrderScheduleService,
              private modalService: NgbModal ) { }

  cloudFunctionsSummary = [];
  allCloudFunctionsSummary = [];
  allPaidOrders = [];
  allPendingOrders = [];
  allConsults = [];
  term: string;
  Code: string = '0000';
  ErrorMessage: string = '';
  UserTraceCode: string;
  AuthorizationCode: string;
  RefundAmount: string;
  CreditAmount: string;
  athMovilRef: string;

  ngOnInit(): void {
    this.getCloudFunctionsSummary();

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set to midnight (12:00:00 AM)
    const lastMonth = new Date(today.setDate(today.getDate() - 60));


    this.afs.firestore.collection('orders').where('paymentStatus', '==', 'paid').where('paymentDate','>=',lastMonth).get().then(res => {
      this.allPaidOrders = res.docs.map(doc => doc.data());
    })

    this.afs.firestore.collection('orders').where('paymentStatus', '==', 'pending').where('date', '>=', lastMonth).get().then(res => {
      this.allPendingOrders = res.docs.map(doc => doc.data());
    })

    this.afs.firestore.collection('consultation').where('Status', '==', 'Completed').where('dateToSort', '>=', lastMonth).get().then(res => {
      this.allConsults = res.docs.map(doc => doc.data());
    })
  }

  getCloudFunctionsSummary(){
    this.afs.firestore.collection('CFDailySummary').orderBy('2 date', 'desc').limit(10).get().then(res => {
      this.allCloudFunctionsSummary = res.docs.map(doc => doc.data());
      
      const latestRuns: { [key: string]: any } = {};

      for(const summary of this.allCloudFunctionsSummary){
        const functionName = summary['1 cf']; //  property that identifies the cloud function
        const runDate = summary['2 date']; // property that represents the date the cloud function was run

        if (!latestRuns[functionName] || runDate.seconds > latestRuns[functionName]['2 date'].seconds) {
          latestRuns[functionName] = summary;
        }
      };

      this.cloudFunctionsSummary = Object.values(latestRuns).sort((a, b) =>  a['1 cf'].localeCompare(b['1 cf']));
      console.warn(this.cloudFunctionsSummary);
    })
  }
  resetPendingAttempts(){
    this.afs.firestore.collection('creditCardPayments').where('paymentStatus', '==', 'pending').where('status', '==', 'pending').get().then(res => {
      let last5Minutes = new Date().getTime() - 300000;
      let attempts = res.docs.map(doc => doc.data()).filter(x => x.date.seconds * 1000 < last5Minutes);
      for(let i = 0; i < attempts.length; i++){
        this.afs.collection('creditCardPayments').doc(attempts[i].id).update({status: 'error'});

        if(i == attempts.length - 1){
          alert((i + 1) + ' pending attempts that are older than 5 minutes have been reset');
        }
      }

      if(attempts.length == 0){
        alert('No pending attempts older than 5 minutes were found');
      }
    })
  }

  resetPendingOrders(){
    this.afs.firestore.collection('orders').where('paymentStatus', '==', 'pending').where('orderState', '==', 'create').get().then(res => {
      let last5Minutes = new Date().getTime() - 300000;
      let orders = res.docs.map(doc => doc.data()).filter(x => x.sync_time.seconds * 1000 < last5Minutes);
      for(let i = 0; i < orders.length; i++){
        this.afs.collection('orders').doc(orders[i].id).update({orderState: 'draft'});

        if(i == orders.length - 1){
          alert((i + 1) + ' pending orders that are older than 5 minutes have been reset');
        }
      }

      if(orders.length == 0){
        alert('No pending orders older than 5 minutes were found');
      }
    })
  }

  createAthMovilPayment(order){
    let data = { //Payment ref for Ath Móvil
      A_Note: 'Order was paid through ATH Movil',
      athMovilRef: this.athMovilRef,
      addressLine1: order.shippingAddress.AddressLine1,
      addressLine2: order.shippingAddress.AddressLine2,
      amount: order.orderTotal,
      consultationId: order.consultationID,
      date: new Date(),
      id: uuidv4(),
      orderID: order.id,
      orderIDRef: order.orderId,
      partnerId: order.partnerID,
      paymentStatus: "paid",
      salesOrderID: order.orderNo,
      status: "success",
      uid: order.uid,
      zipCode: order.shippingAddress.AddressZipcode,
    }

    this.afs.collection('creditCardPayments').doc(data.id).set(data)
    this.afs.collection('orders').doc(data.orderID).update({paymentStatus: 'paid', orderState: "done"});

    this.afs.firestore.collection('orders').doc(data.orderID).get().then(res => {
      let details = res.data();
      this.afs.firestore.collection('orders').doc(data.orderID).collection('orderLines').get().then(res1 => {
        let product = res1.docs.map(p => p.data()).filter(x => x.type == "product")
        this.pharmacyOrderScheduleService.generatePharmacyOrderSchedule(details, product);
        alert('Ath Movil payment attempt created')
        this.athMovilRef = '';
        this.allPendingOrders = this.allPendingOrders.filter(x => x.id != order.id);
        this.modalService.dismissAll();    
      })
    })
  }

  createRefundAttempt(order){

    let paymentStatus = 'Refunded'
    if(Number(order.orderTotal) != Number(this.RefundAmount)){
      paymentStatus == 'partial refund'
    }

    let data = { //Refund template
      A_Note: 'Order was Refunded manually',
      addressLine1: order.shippingAddress.AddressLine1,
      addressLine2: order.shippingAddress.AddressLine2,
      amount: "-" + this.RefundAmount,
      consultationId: order.consultationID,
      date: new Date(),
      id: uuidv4(),
      orderID: order.id,
      orderIDRef: order.orderId,
      partnerId: order.partnerID,
      paymentStatus: "Refunded",
      salesOrderID: order.orderNo,
      status: "success",
      uid: order.uid,
      zipCode: order.shippingAddress.AddressZipcode,
      "Code": this.Code,
      "ErrorMessage": this.ErrorMessage,
      "UserTraceCode": this.UserTraceCode,
      "AuthorizationCode": this.AuthorizationCode,
      last4: order.last4 || '',
      brand: ""
    }

    this.afs.collection('creditCardPayments').doc(data.id).set(data)
    this.afs.collection('orders').doc(data.orderID).update({paymentStatus: paymentStatus});
    
    alert('Refund attempt created')

    this.Code = '';
    this.ErrorMessage = '';
    this.UserTraceCode = '';
    this.AuthorizationCode = '';
    this.RefundAmount = '';
    this.allPaidOrders = this.allPaidOrders.filter(x => x.id != order.id);
    this.modalService.dismissAll();
  }

  createCreditAttempt(order){
    let paymentStatus = 'credit'
    if(Number(order.orderTotal) != Number(this.CreditAmount)){
      paymentStatus = 'partial credit'
    }

    let data = { //Credit template
      A_Note: 'Credit for future order',
      addressLine1: order.shippingAddress.AddressLine1,
      addressLine2: order.shippingAddress.AddressLine2,
      amount: this.CreditAmount,
      consultationId: order.consultationID,
      date: new Date(),
      id: uuidv4(),
      orderID: order.id,
      orderIDRef: order.orderId,
      partnerId: order.partnerID,
      paymentStatus: paymentStatus,
      salesOrderID: order.orderNo,
      status: "success",
      uid: order.uid,
      zipCode: order.shippingAddress.AddressZipcode,
    }

    this.afs.collection('creditCardPayments').doc(data.id).set(data)

    this.afs.collection('orders').doc(data.orderID).update({paymentStatus: paymentStatus});
    
    alert('Credit attempt created')

    this.Code = '';
    this.ErrorMessage = '';
    this.UserTraceCode = '';
    this.AuthorizationCode = '';
    this.CreditAmount = '';
    this.allPaidOrders = this.allPaidOrders.filter(x => x.id != order.id);
    this.modalService.dismissAll();
  }

  // createCreditAttempt(order){
  //   let paymentStatus = 'credit'
  //   if(order.orderTotal != this.CreditAmount){
  //     paymentStatus == 'partial credit'
  //   }

  //   let data = { //Credit template
  //     A_Note: 'Credit for future order',
  //     addressLine1: order.shippingAddress.AddressLine1,
  //     addressLine2: order.shippingAddress.AddressLine2,
  //     amount: this.CreditAmount,
  //     consultationId: order.consultationID,
  //     date: new Date(),
  //     id: uuidv4(),
  //     orderID: '',
  //     orderIDRef: '',
  //     prevOrderID: order.id,
  //     prevOrderIDRef: order.orderId,
  //     partnerId: order.partnerID,
  //     paymentStatus: paymentStatus,
  //     salesOrderID: order.orderNo,
  //     status: "unused",
  //     uid: order.uid,
  //     zipCode: order.shippingAddress.AddressZipcode,
  //   }

  //   this.afs.collection('users').doc(order.uid).collection('credits').doc(data.id).set(data)

  //   this.afs.collection('orders').doc(data.prevOrderID).update({paymentStatus: paymentStatus});
    
  //   alert('Credit attempt created')

  //   this.Code = '';
  //   this.ErrorMessage = '';
  //   this.UserTraceCode = '';
  //   this.AuthorizationCode = '';
  //   this.CreditAmount = '';
  //   this.allPaidOrders = this.allPaidOrders.filter(x => x.id != order.id);
  //   this.modalService.dismissAll();
  // }

  openModal(modal){
    this.modalService.open(modal, { size: 'lg' });
  }

  triggerConsultationUpdate(consult){
    this.afs.firestore.collection('orders').where('consultationID', '==', consult.ID).where('productType', '==', 'product').get().then(res => {
      if(res.docs.length > 0){
        this.afs.firestore.collection('prescription').where('orderID', '==', res.docs[0].data().id).get().then(res1 => {
          let prescription = res1.docs.map(doc => doc.data())[0];
          if(prescription){
            delete prescription.orderID;
            this.afs.collection('prescription').doc(prescription.id).update(prescription);
            
            this.afs.collection('orders').doc(res.docs[0].data().id).delete();
    
            let data = {
              consultationId: consult.ID,
              date: new Date(Date.now()),
              id: uuidv4(),
              status: 'pending',
              trigger: "handleConsultationCompleted"
            }
        
            this.afs.collection('triggerUpdateConsultation').doc(data.id).set(data);
            alert('Consultation triggered')
            this.modalService.dismissAll();
          }
        })
      }
      else {
        let data = {
          consultationId: consult.ID,
          date: new Date(Date.now()),
          id: uuidv4(),
          status: 'pending',
          trigger: "handleConsultationCompleted"
        }
    
        this.afs.collection('triggerUpdateConsultation').doc(data.id).set(data);
        alert('Consultation triggered')
        this.modalService.dismissAll();
      }
    })
  }
}

import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FullCalendarComponent }from '@fullcalendar/angular'; 
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi }from '@fullcalendar/core'; 

import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';


import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { AddEventComponent } from '../components/add-event/add-event.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/core/models/auth.models';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { Profile } from 'src/app/core/services/interfaces';
import { Subscription } from 'rxjs';
import { TelehealthService } from './telehealth.service';
import { DatePipe } from '@angular/common';
import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';
import { TranslationPipe } from 'src/app/translation.pipe';

@Component({
  selector: 'app-telehealth',
  templateUrl: './telehealth.component.html',
  styleUrls: ['./telehealth.component.scss', "../../../assets/scss/_variables.scss"
]
})

export class TelehealthComponent implements OnInit, OnDestroy {
  @ViewChild('addEventModal', { read: TemplateRef }) addEvent:TemplateRef<any>;
  @ViewChild('scheduler', { read: TemplateRef }) rescheduleModal:TemplateRef<any>;
  @Input() consultId: string;
  @Input() consultMeetings: any[];
  @Input() practitionerUid: any[];
  @Output() path = new EventEmitter<any>();

  user: User;
  userAccountType
  pagetitle ="TELEHEALTH.BREADCRUMBS"
  calendarVisible = true;
  patientUID: any;
  patientName: any;
  patientEmail: any;
  consultID: any;
  currentUser: any;
  meetings: any = [];
  todaysMeetings: any = [];
  appointments: any[] = [];
  abscentDates: any = []

  userName:any;
  notInThisConsults: any = []
  calendardata = 0;
  time;
  currentURL
  modalRef

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  calendarOptions: CalendarOptions = {
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    initialView: 'listWeek',
  }

 // event form
 formEditData: UntypedFormGroup;
 currentLanguage

 allSubscriptions =  new Subscription();

 //For swal modal
 title
 text
 dismiss
 change
 profileTime
 currentTime

  n =  new Date();
  y = this.n.getFullYear();
  m = this.n.getMonth() + 1;
  d = this.n.getDate();
  currentDate;
  currentConsult
  appointment: any;
  
  timezoneList = [];
  currentTimezone;

  constructor(
    public afAuth: AngularFireAuth,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private afs: AngularFirestore,
    public route: Router,
    public translate: TranslateService,
    public authenticationService: AuthenticationService,
    public telehealthService: TelehealthService
  ) {

    const moment = require('moment-timezone');  
      moment.tz.names().forEach(t => {
        let timeValue = String((moment.tz(t).utcOffset())/60).includes('-') ? '-' + String((moment.tz(t).utcOffset())/60).replace('-', '').padStart(2, '0') : String((moment.tz(t).utcOffset())/60).padStart(2, '0')
        this.timezoneList.push({nameValue: t, timeValue: timeValue + ':00', name: t + ' (' + timeValue + ':00)'})
      })

      this.currentTimezone = this.timezoneList.find(t => t.nameValue == moment.tz.guess())

    this.currentURL = this.route.url

    this.authenticationService.profile().then((profile: Profile) => {

      this.calendarOptions = {
        plugins: [  
          interactionPlugin,
          listPlugin,
          timeGridPlugin,
          bootstrapPlugin,
          dayGridPlugin
        ],
        locale: profile.preferedLang + '-US',
        initialView: 'listWeek',
        headerToolbar: {
          start: 'prev,next today', 
          center: 'title',
          end: 'dayGridMonth,listWeek' 
        },
        editable: true,
        selectable: true,
        fixedWeekCount: false,
        select: this.handleDateSelect.bind(this), //add event
        eventClick: this.handleEventClick.bind(this), //edit event
        eventsSet: this.handleEvents.bind(this),
        dayMaxEventRows: 2,
        longPressDelay: 50,
        eventTimeFormat: {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        }
      }

      if(profile.preferedLang == "es"){
        this.calendarOptions.buttonText = {today: "Hoy", month:"Mes",list:"Agenda"}
      }
      else {
        this.calendarOptions.buttonText = {today: "Today", month:"Month",list:"List"}
      }
    })  
  }

  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe()
  }

  ngOnInit() {
    if(this.route.url.startsWith('/consults')){
      // this.consultID = this.route.url.split('/consults/').pop().split('/telehealth')[0];
      let sub = this.afs.collection("consultation").doc(this.consultId).valueChanges().subscribe(async consult => {
        this.currentConsult = await consult;
        this.patientUID = consult["UID_Patient"];
        this.allSubscriptions.add(sub)
        if(this.currentConsult){
          this.getUser()
        }
        if (this.patientUID){
          let sub1 = this.afs.collection("users").doc(`${this.patientUID}`).valueChanges().subscribe(user => {
            this.allSubscriptions.add(sub1)
            this.patientName = user['firstName'] + " " + user['lastName1'];
            this.patientEmail = user['email'];
          });
        }
      });
    }
    else {
      this.getUser()
    }

    if(this.m <= 9){
      this.currentDate = this.y + "-0" + this.m;
     }
     else{
      this.currentDate = this.y + "-" + this.m;
     }
     if(this.d <= 9){
      this.currentDate += "-0" + this.d;
     }
     else{
      this.currentDate += "-" + this.d;
     }
  }

  getUser(){
    this.afAuth.user.subscribe(async user => {
      if(await user){ 
        this.currentUser = await user.uid;
        this.getAccountType();
      }
    });
  }

  getAccountType(){
    let user = this.practitionerUid ? this.practitionerUid : this.currentUser
    let sub = this.afs.collection("users").doc(user).valueChanges().subscribe(async (data: any) =>{
      this.allSubscriptions.add(sub)
      this.userAccountType = await data
      this.abscentDates = data?.abscentDates ? data.abscentDates : []

      for(let i of this.abscentDates){
        i.title = 'Unavailable'
      }
      
      if(this.userAccountType.accountType == 'practitioner'){
        let translationPipe = new TranslationPipe()
        let abbr = translationPipe.transform(this.userAccountType.abbr, this.userAccountType.preferedLang);
        if(this.userAccountType.preferedLang == 'en'){
          this.userAccountType.abbr = abbr;
        }
        else {
          if(this.userAccountType.biologicalgender == 'Female'){
            this.userAccountType.abbr = abbr.split('/la ')[1]
          }
          else {
            this.userAccountType.abbr = abbr.split('el ')[1].split('/')[0]
          }
        }
  
        if(this.userAccountType?.timezone?.name == undefined){
          this.afs.collection("users").doc(this.userAccountType.uid).update({timezone: this.currentTimezone})
          this.userAccountType.timezone = this.currentTimezone
        }
      }
      
      if(this.userAccountType?.timezone != ''){
        setInterval(() => {
          var d = new Date();
          var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
          var nd = new Date(utc + (3600000*Number(this.userAccountType?.timezone?.timeValue?.split(':00')[0])));
          this.time = new DatePipe('en-Us').transform(nd, 'MMMM dd, yyyy hh:mm:ss a', '', this.userAccountType.preferedLang);
        }, 1);
      }    
      
      if(this.currentTimezone?.name != this.userAccountType?.timezone?.name){
       
        this.translate.get('TELEHEALTH.SWAL').subscribe(res => {
          this.title = res.TITLE
          this.dismiss = res.KEEPTIMEZONE
          this.text = res.INFO
          this.change = res.CHANGE
          this.currentTime = res.CURRENTTIMEZONE
          this.profileTime = res.PROFILETIMEZONE

          Swal.fire({
            customClass: {
              confirmButton: 'swalPurple',
              cancelButton: 'btn-light'
            },
            title: this.title,
            html: `<div class="m-2"><p style="margin-bottom: 0; font-weight:bold">${this.profileTime}</p> (${this.userAccountType?.timezone?.name})</div> <div class="m-2"><p style="margin-bottom: 0; font-weight:bold">${this.currentTime}</p> (${this.currentTimezone?.name})</div>${this.text}`,
            showCancelButton: true,
            confirmButtonText: this.change,
            cancelButtonText: this.dismiss
          }).then(async response => {
            if(response.isConfirmed){
              this.afs.collection("users").doc(this.userAccountType.uid).update({timezone: this.currentTimezone})
              ReloadComponent.loader = 'RELOAD.DEFAULT'
              await this.route.navigateByUrl('/reload', { skipLocationChange: true });
              await setTimeout(async () => {
                return await this.route.navigateByUrl(this.currentURL);
              },500)
            }
          })
        })      
      }
      this.getCalendarEvents();
    })
  }

  getCalendarEvents(){
    if(this.route.url == '/telehealth' && this.userAccountType.accountType == 'practitioner'){
      let user = this.practitionerUid ? this.practitionerUid : this.currentUser
      let sub = this.afs.collection("calendar", ref => ref.where('uidPractitioner', '==', user).orderBy('start', 'asc')).valueChanges().subscribe(data => {
        this.allSubscriptions.add(sub)
        const calendar = this.calendarComponent.getApi();
        calendar?.removeAllEvents() 
        this.todaysMeetings = []
        data = data.concat(this.abscentDates)
        this.notInThisConsults = []
        this.meetings = []
        this.appointments = data;
        this.appointmetsTime(this.appointments);
        
        
        for(let i = 0; i < data.length; i++){
          this.meetings = data.filter(this.onlyUnique);   
          if(this.meetings[i].eventTimezoneValue && this.meetings[i].eventTimezoneValue.split('UTC')[1] != this.userAccountType.timezone.timeValue){
            let changeStart = new DatePipe('en-Us').transform(this.meetings[i].start+':00.000' + this.meetings[i].eventTimezoneValue.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.userAccountType.timezone.timeValue);
            let changeEnd = new DatePipe('en-Us').transform(this.meetings[i].end+':00.000' + this.meetings[i].eventTimezoneValue.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.userAccountType.timezone.timeValue);
            this.meetings[i].start = changeStart
            this.meetings[i].end = changeEnd
          }
          if (calendar?.getEvents().length < data.length) {
            if(this.meetings[i].start.startsWith(this.currentDate)){ 
              this.meetings[i].start = new Date(this.meetings[i].start);      
              this.todaysMeetings.push(this.meetings[i])
            } 
            if(this.currentConsult && this.currentConsult?.practitionerForConsult != this.currentUser){
              this.meetings[i].title = 'Appointment'
            }
            calendar?.addEvent(this.meetings[calendar?.getEvents().length]);
            calendar?.render();   
          }
        }  
      });
    }
    else if(this.route.url != '/telehealth' && this.userAccountType.accountType == 'practitioner'){
      let user = this.practitionerUid ? this.practitionerUid : this.currentUser
      let sub = this.afs.collection("calendar", ref => ref.where('uidPractitioner', '==', user).orderBy('start', 'asc')).valueChanges().subscribe(data => {
        data = data.concat(this.abscentDates)
        this.appointments = data;
        this.appointmetsTime(this.appointments);

        this.allSubscriptions.add(sub)
        const calendar = this.calendarComponent.getApi();
        calendar?.removeAllEvents() 

        this.todaysMeetings = [];
        this.notInThisConsults = [];
        this.meetings = [];
    
        for(let i = 0; i < data.length; i++){
          let allMeetings: any = data.filter(this.onlyUnique);  
          if(allMeetings[i]?.eventTimezoneValue && allMeetings[i]?.eventTimezoneValue?.split('UTC')[1] != this.userAccountType.timezone.timeValue){
            let changeStart = new DatePipe('en-Us').transform(allMeetings[i].start+':00.000' + allMeetings[i].eventTimezoneValue.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.userAccountType.timezone.timeValue);
            let changeEnd = new DatePipe('en-Us').transform(allMeetings[i].end+':00.000' + allMeetings[i].eventTimezoneValue.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.userAccountType.timezone.timeValue);
            allMeetings[i].start = changeStart
            allMeetings[i].end = changeEnd
          }
          if(allMeetings[i].consultID == this.consultID){
            this.meetings.push(allMeetings[i])
            if(this.currentConsult?.practitionerForConsult != this.currentUser){
              allMeetings[i].title = 'Appointment'
            }
            calendar?.addEvent(allMeetings[i]);
            calendar?.getEvents()[i].setProp('color','#F3732A');
            if(allMeetings[i].start.startsWith(this.currentDate)){    
              allMeetings[i].start = new Date(allMeetings[i].start);
              this.todaysMeetings.push(allMeetings[i])
            } 
          }
          else{
            this.notInThisConsults.push(allMeetings[i])
            if(this.currentConsult?.practitionerForConsult != this.currentUser){
              allMeetings[i].title = 'Appointment'
            }
            calendar?.addEvent(allMeetings[i]);
            calendar?.getEvents()[i].setProp('color','#16395f');

            if(allMeetings[i].start.startsWith(this.currentDate)){ 
              allMeetings[i].start = new Date(allMeetings[i].start);         
              this.todaysMeetings.push(allMeetings[i])
            } 
          }        
        }  
      })
    }

    if(this.route.url == '/telehealth' && this.userAccountType.accountType == 'patient'){
      let sub = this.afs.collection("calendar", ref => ref.where('uidPatient', '==', this.currentUser).orderBy('start', 'asc')).valueChanges().subscribe(data =>{
        this.allSubscriptions.add(sub);
        this.appointments = data;
        this.appointmetsTime(this.appointments);

        const calendar = this.calendarComponent.getApi();
        calendar?.removeAllEvents()
        for(let i = 0; i < data.length; i++){
          this.meetings = data.filter(this.onlyUnique);                 
          if(this.meetings[i].eventTimezoneValue?.split('UTC')[1] && this.meetings[i].eventTimezoneValue?.split('UTC')[1] != this.userAccountType.timezone.timeValue){
            let changeStart = new DatePipe('en-Us').transform(this.meetings[i].start+':00.000' + this.meetings[i].eventTimezoneValue?.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.userAccountType.timezone.timeValue);
            let changeEnd = new DatePipe('en-Us').transform(this.meetings[i].end+':00.000' + this.meetings[i].eventTimezoneValue?.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.userAccountType.timezone.timeValue);
            this.meetings[i].start = changeStart
            this.meetings[i].end = changeEnd
          }
          if (calendar?.getEvents().length < data.length) {
            if(this.meetings[i].start.startsWith(this.currentDate)){ 
              this.meetings[i].start = new Date(this.meetings[i].start)
              this.todaysMeetings.push(this.meetings[i]);
            } 
          calendar?.addEvent(this.meetings[calendar?.getEvents().length]);
          calendar?.render();     
          }
        }  
      });
    }

    if(this.userAccountType.accountType == 'admin' && this.currentConsult){
      let sub = this.afs.collection("calendar", ref => ref.where('uidPractitioner', '==', this.currentConsult.UID_Practitioner).orderBy('start', 'asc')).valueChanges().subscribe(data =>{
        this.appointments = data;
        this.appointmetsTime(this.appointments);

        this.allSubscriptions.add(sub)
        const calendar = this.calendarComponent.getApi();
        calendar?.removeAllEvents() 

        this.todaysMeetings = []
        this.notInThisConsults = []
        this.meetings = []
    
        for(let i = 0; i < data.length; i++){
          let allMeetings: any = data.filter(this.onlyUnique);  
          if(allMeetings[i].eventTimezoneValue?.split('UTC')[1] != this.userAccountType.timezone.timeValue){
            let changeStart = new DatePipe('en-Us').transform(allMeetings[i].start+':00.000' + allMeetings[i].eventTimezoneValue.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.userAccountType.timezone.timeValue);
            let changeEnd = new DatePipe('en-Us').transform(allMeetings[i].end+':00.000' + allMeetings[i].eventTimezoneValue.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.userAccountType.timezone.timeValue);
            allMeetings[i].start = changeStart
            allMeetings[i].end = changeEnd
          }
          if(allMeetings[i].consultID == this.consultID){
            this.meetings.push(allMeetings[i])
            calendar?.addEvent(allMeetings[i]);
            calendar?.getEvents()[i].setProp('color','#F3732A');

            if(allMeetings[i].start.startsWith(this.currentDate)){    
              allMeetings[i].start = new Date(allMeetings[i].start);
              this.todaysMeetings.push(allMeetings[i])
            } 
          }
          else{
            this.notInThisConsults.push(allMeetings[i])
            calendar?.addEvent(allMeetings[i]);
            calendar?.getEvents()[i].setProp('color','#16395f');

            if(allMeetings[i].start.startsWith(this.currentDate)){ 
              allMeetings[i].start = new Date(allMeetings[i].start);         
              this.todaysMeetings.push(allMeetings[i])
            } 
          }        
        }  
      })
    }
  }

  showModalAddEvent(modal:any){

    let selectInfo = {
      title: "",
      start: "",
      end:   ""
    }

    let data = {
      uidPractitioner: this.practitionerUid ? this.practitionerUid : this.currentUser,
      uidPatient: this.patientUID,
      patientName: this.patientName,
      patientEmail: this.patientEmail,
      consultID: this.consultID,
      conditionName: this.currentConsult.conditionName,
      room:""
    }
 
    AddEventComponent.sendDate(selectInfo, false, data);

    this.modalRef = this.modalService.open(modal,{
      size: 'md',
      centered: true,
      backdrop: 'static'
    })
   }

  close(){
    this.modalService.dismissAll();
  }

  handleEventClick(clickInfo: EventClickArg) {
    if((this.userAccountType.accountType == 'practitioner' || this.userAccountType.accountType == 'admin') && !this.route.url.includes('/followUp')){
      let data = {
        uidPatient: clickInfo.event.extendedProps.uidPatient,
        consultID: clickInfo.event.extendedProps.consultID,
        conditionName: clickInfo.event.extendedProps.conditionName
      }

      AddEventComponent.sendDate(clickInfo, true, data);
      this.modalRef = this.modalService.open(this.addEvent,{
        size: 'md',
        centered: true,
        backdrop: 'static'
      })
    }
  }

 currentEvents: EventApi[] = [];
  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }
  
  handleDateSelect(selectInfo: DateSelectArg,) {
    if(this.route.url.startsWith('/consults') && !this.route.url.includes('/followUp') && (this.userAccountType.accountType == 'practitioner' || this.userAccountType.accountType == 'admin')){
      let data = {
        uidPractitioner: this.practitionerUid,
        uidPatient: this.patientUID,
        patientName: this.patientName,
        patientEmail: this.patientEmail,
        consultID: this.consultID,
        conditionName: this.currentConsult.conditionName,
        room:""
      }
      selectInfo.view.calendar.unselect(); // clear date selection
      AddEventComponent.sendDate(selectInfo, false, data);

      this.modalRef = this.modalService.open(this.addEvent,{
        size: 'md',
        centered: true,
        backdrop: 'static'
      })
    }
    else if(this.route.url.startsWith('/telehealth') && this.userAccountType.accountType == 'practitioner'){

      this.translate.get('TELEHEALTH.SWAL').subscribe((res: any) => {
        this.title = res.TITLE
        this.text = res.TEXT
        this.dismiss = res.DISMISS
    })
      Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
        },
        title: this.title,
        text: this.text,
        icon: 'warning',
        confirmButtonText: this.dismiss
      })
    }
    // else if(this.route.url.startsWith('/telehealth') && this.userAccountType.accountType == 'patient'){
    //   this.translate.get('TELEHEALTH.SWAL').subscribe((res: any) => {
    //     this.title = res.TITLE
    //     this.text = res.TEXT2
    //     this.dismiss = res.DISMISS
    // })
    //   Swal.fire({
    //     title: this.title,
    //     text: this.text,
    //     icon: 'warning',
    //     confirmButtonColor: '#7ba1cf',
    //     confirmButtonText: this.dismiss
    //   })
    // }
  }
  
  sendMessage(){
    this.modalService.dismissAll();
  }

  onlyUnique(value, index, self){
    return self.indexOf(value) === index;
  }

  closeModal(){
    this.modalService.dismissAll();
    this.path.emit(`consults/${this.consultId}`)
    // this.route.navigateByUrl(`consults/${this.consultId}`, {skipLocationChange: true});
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes?.consultId?.currentValue){
      this.consultID = changes.consultId.currentValue;
    }
  }

  appointmetsTime(appointments:any[]){
    let formated = appointments.map(appointment => {
      const { start, end } = appointment;
      let startTime = start?.split("T")[1]?.replace(":","").trim();
      let endTime = end.split("T")[1].split(":")[0] > start.split("T")[1].split(":")[0] ? Number(end.split("T")[1].replace(":","").trim()) - 40 : end.split("T")[1].replace(":","").trim();
      appointment = Object.assign(appointment, {duration: endTime - startTime});

      return appointment;
    });
    this.appointments = formated;
  }

  reschedule(appointment){
    this.appointment = appointment;
    this.modalRef =  this.modalRef = this.modalService.open(this.rescheduleModal, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      windowClass: "appointment-scheduler"
      
    })
  }
} 
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from 'src/app/core/services/translation.service';
import firebase from "firebase/compat/app";
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslationPipe } from 'src/app/translation.pipe';
import { BaseProduct, Profile, Refill } from 'src/app/core/services/interfaces'
import { FirebaseService } from 'src/app/core/services/firebase.service';
import Swal from 'sweetalert2';
import { OrdersService } from 'src/app/core/services/orders.service';
@Component({
  selector: 'app-refills',
  templateUrl: './refills.component.html',
  styleUrls: ['./refills.component.scss']
})
export class RefillsComponent implements OnInit {
  @ViewChild('sidebar', { read: TemplateRef }) detailsModal:TemplateRef<any>;
  @ViewChild('orderShipping', { read: TemplateRef }) shippingModal:TemplateRef<any>;

  @Output() viewOrder = new EventEmitter<any>();

  @Input() consultID:string;

  user:Profile;
  originalFields:any = [];
  cleanedFields:any = {};
  fields: any = [];

  lang:string;
  swal
  orders:any = [];
  orderId:string = "";
  orderLines:any = [];
  orderBreakdown:any = [];
  details:any;


  customerApproval:boolean = false;
  term: string;

  params:string;

  needsApproval:boolean = true;
  orderChangeApproved:boolean = undefined
  orderChangeDeclined:boolean = undefined;

  modalRef:any;
  approvalBtn: HTMLElement;
  cancelBtn: HTMLElement;
  consultationId:string;
  address
  title

  shippingMethod: any[] = [];
  orderShippingMethod:any;
  hasShipping:boolean = false;
  orderLinesLoaded:boolean = true;


  //cyclycOrders
  cyclicOrders:Refill[] = [];
  consult:any;
  paymentMethod:any = [];
  creditCardRef:any;
  allPaymentMethods:any = [];
  icon:string = "fa fa-angle-down";

  order:any;
  acceptaID:string;
  profile:any;

  refill:Refill;
  productRefillTrackList: any[] = [];
  refillClicked:any;

  constructor(
    private afs: AngularFirestore,
    public translation: TranslationService,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private firebaseService: FirebaseService, 
    private orderService: OrdersService
  ) {
    let translating = new TranslationPipe();

    // if(this.consultID){
    //   this.getCyclicOrders();
    // }


    // if(this.orderId !== ""){
    //   this.afs.collection("orders").doc(this.orderId).valueChanges().subscribe(async (data:any ) => {
    //     this.orderBreakdown = await [data];
    //     this.orderBreakdown[0].orderTax = Number(this.orderBreakdown[0].orderTax).toFixed(2)
    //     this.orderBreakdown[0].orderSubtotal = Number(this.orderBreakdown[0].orderSubtotal).toFixed(2)
    //     this.orderBreakdown[0].orderTotal = Number(this.orderBreakdown[0].orderTotal).toFixed(2)

    //     this.afs.collection("orders").doc(this.orderId).collection("orderLines").valueChanges().subscribe((lines:any) => {
    //       this.orderLines = lines
    //       this.orderLinesLoaded = true;
    //       this.orderLines.forEach(line => {
    //         line.productPrice = Number(line.productPrice).toFixed(2)
    //       });
    //       this.authService.profile().then((profile:any)=> {
    //         this.lang = profile.preferedLang;
    //       })
    //     });
    //   });
    // }
  }

  async ngOnInit() {
   
    // this.afs.collection("conditions").doc("Erectile Dysfunction").valueChanges().subscribe(data => {
    //   this.originalFields = [data];
    //   this.fields = [data];
    // });
    this.routeCheck();
    this.user = await this.authService.profile();
    // this.authService.profile().then((profile:any)=> {
    //   let orders = new Array<any>(this.orders.length);
    //   this.afs.collection("payment_tests", ref => ref.where("uid", "==", profile.uid)).valueChanges().subscribe(async tests => {
    //     this.orders = await tests;
    //     this.orders.forEach(order => {
          
    //       this.afs.collection('users').doc(profile.uid).valueChanges().subscribe((profile:any) => {
    //         let obj = {
    //           customer: `${profile.firstName + " " + profile.lastName1}`,
    //           icon: "",
    //         }
    //         if(order.paymentStatus == "paid"){
    //           obj.icon = "far fa-check-circle text-success";
    //         }else if(order.paymentStatus == "pending"){
    //           obj.icon = "far fa-clock text-warning";
    //         }else if(order.paymentStatus == "cancelled"){
    //           obj.icon = "far fa-times-circle text-danger";
    //         }
    //         for(let i = 0; i < this.orders.length; i++){
    //           let merge = Object.assign(order, obj);
    //           // this.orders[i] = merge;
              
    //         }
    //       })
    //     });
    //   //this.orders = orders;
    //   });
    //   let id = this.router.url.split("/ordersAndPrescriptions/")[1];
    //   if(id !== undefined){
    //     this.orderFromRoute(id);
    //   }
    // });
    // this.orders =  [{
    //   id: "S00001",
    //   date: "1 Oct 2021, 13:00",
    //   status: "Paid",
    //   icon:"far fa-check-circle text-success",
    //   customer: "Aramis Martinez Morales"
    //  },
    //  {
    //   id: "S00002",
    //   date: "2 Oct 2021, 14:00",
    //   status: "Refunded",
    //   icon:"fas fa-undo-alt",
    //   customer: "Aramis Martinez Morales"
    //  },
    //  {
    //   id: "S00003",
    //   date: "3 Oct 2021, 15:00",
    //   status: "Chargeback",
    //   icon:"far fa-times-circle text-danger",
    //   customer: "Aramis Martinez Morales"
    //  },
    //  {
    //   id: "S00004",
    //   date: "4 Oct 2021, 15:00",
    //   status: "Pending",
    //   icon:"far fa-clock text-warning",
    //   customer: "Aramis Martinez Morales"
    //  },
    // ]
    this.getCyclicOrders();
  }

  removeTags(lang){
    let fields = this.originalFields[0];
    let cleanedFields:any = {};
    // for (const [key, value] of Object.entries(fields)) {
      Object.keys(fields)
      .forEach(function eachKey(key){ 
        let value:string = (fields[key])
        if(typeof value === "string"){
          if(value.includes(`<${lang}>`)){
            Object.assign(cleanedFields, {[key]: value.split(`<${lang}>`).pop().split(`</${lang}>`)[0]})
          }
        }else{
          Object.assign(cleanedFields, {[key]: value})
        }
      });
      this.cleanedFields = cleanedFields;
      this.fields = [cleanedFields];
  }

  original(){
    this.fields = this.originalFields;
  }
  
  // translate(){
  //   this.translation.translate(this.cleanedFields);
  // }

  saveTranslations(){

  }


  async approve(){
    let cancel: HTMLElement = document.getElementById("cancel-btn") as HTMLElement;
    let button: HTMLElement = document.getElementById("approval-btn") as HTMLElement;
    if(this.orderChangeApproved == true){
      setTimeout(() => {
        this.translate.get('ORDERS').subscribe((res: any) => {
          this.title = res.ALREADYAPPROVED
        })
        button.innerText = this.title;
        setTimeout(() => {
          this.translate.get('ORDERS').subscribe((res: any) => {
            this.title = res.APPROVED
          })
          button.innerText = this.title;
        }, 2000)
      })
    }else{
      let consult = await this.hasShippingMethod();
      if(consult.shippingMethod == ""){
        this.hasShipping = false;
        // this.getShippingMethods();
        this.modalRef = this.modalService.open(this.shippingModal,{size:"md", backdrop:"static"});
      }
      else{
        let btn = document.getElementById("confirmbtn-modal");
        btn.innerHTML = "<span class='spinner-border'></span>";
        this.hasShipping = true;
        button.classList.remove("scale");
        cancel.style.display = "none";
        this.customerApproval = true;
        this.translate.get('ORDERS').subscribe((res: any) => {
          this.title = res.APPROVING
        })
        button.innerText = this.title;
        setTimeout(() => {
          this.translate.get('ORDERS').subscribe((res: any) => {
            this.title = res.APPROVED
          })
          button.innerText = this.title;
          btn.innerHTML = "Done!";
          // button.classList.add("scale");
          // button.classList.add("text-success");
        }, 2000);
        this.afs.collection("orders").doc(this.details.orderID).update({
          orderCustomerApproval: true
        });
        setTimeout(() => {
         this.dismiss();
        }, 3500);
      }
    }
  }

  decline(){
    let approvalbtn: HTMLElement = document.getElementById("approval-btn") as HTMLElement;
    let button: HTMLElement = document.getElementById("cancel-btn") as HTMLElement;
    
    this.translate.get('REFILLS').subscribe((res: any) => {
      this.swal = res
    })

    Swal.fire({
      title: this.swal.AREYOUSURE,
      text: this.swal.TEXT,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.swal.CONFIRM
    }).then((result) => {
      if(result.isConfirmed){
        this.order.state = "closed";
        // this.afs.collection("cyclycOrders").doc(this.orderId).update({state: "closed"});
        this.afs.collection("cyclicOrders").doc(this.orderId).update({recurrency_payment: "manual"});
      }
    });
  }

  async orderDetails(order){
    const user:any = await this.authService.profile();
    this.profile = user;
    this.orderId = order.id;
    this.order = order;
    this.consultationId = order.consultationId;

    if(order.state == "In Progress"){
      this.icon = "far fa-check-circle text-success";
    }else if(order.state == "Hold"){
      this.icon = "far fa-clock text-warning";
    }else if(order.state == "Closed"){
      this.icon = "far fa-times-circle text-danger";
    }

    if(!this.modalService.hasOpenModals()){
      this.modalRef = this.modalService.open(this.detailsModal, { size: 'md', backdrop: 'static',centered: false, windowClass:"test" });
      this.details = order;
    }
  }


  async orderFromRoute(id){
    // this.details = order;
    let arr = this.orders.filter(order => order.orderID == id);
    this.details = arr[0];
    this.afs.collection("orders", ref => ref.where('id', '==', id).where('uid', '==', this.user.uid)).valueChanges().subscribe(async (data:any ) => {
      if(data){
        this.orderBreakdown = await data;
        this.orderBreakdown[0].orderTax = Number(this.orderBreakdown[0].orderTax).toFixed(2)
        this.orderBreakdown[0].orderSubtotal = Number(this.orderBreakdown[0].orderSubtotal).toFixed(2)
        this.orderBreakdown[0].orderTotal = Number(this.orderBreakdown[0].orderTotal).toFixed(2);
  
        this.afs.collection('consultation').doc(this.orderBreakdown[0].orderRef).collection('Address').doc('Shipping').valueChanges().subscribe(async res => {
          this.address = await res
          this.address = await Object.assign(this.address, {customer: this.details.customer})
        })
  
        this.afs.collection("orders").doc(id).collection("orderLines").valueChanges().subscribe((lines:any) => {
          this.orderLines = lines;
          this.orderLinesLoaded = true;
          this.orderLines.forEach(line => {
            line.productPrice = Number(line.productPrice).toFixed(2)
          });
        });
      }
      else{
        this.router.navigate(['/account/404'])
      }
    });

    let interval = setInterval(() => {
      if(!this.orderBreakdown.length){
      }else{
        clearInterval(interval);
        setTimeout(() => {
          this.modalRef = this.modalService.open(this.detailsModal, { size: 'md', backdrop: 'static',centered: false, windowClass:"test" });
          this.approvalBtn = document.getElementById("approval-btn") as HTMLElement;
          this.cancelBtn = document.getElementById("cancel-btn") as HTMLElement;
          // if(this.orderBreakdown[0]?.orderCustomerApproval){
            if(this.orderBreakdown[0].orderCustomerApproval == true){

              this.orderChangeApproved = true;
              this.approvalBtn.classList.remove("scale");
              this.cancelBtn.style.display = 'none';
              this.translate.get('ORDERS').subscribe((res: any) => {
                this.title = res.APPROVED
              })
              this.approvalBtn.innerText = this.title

            }else if(this.orderBreakdown[0].orderCustomerApproval == false){

              this.orderChangeDeclined = false;
              this.approvalBtn.style.display = 'none';
              this.translate.get('ORDERS').subscribe((res: any) => {
                this.title = res.CANCELED
              })
              this.cancelBtn.innerText = "Order Cancelled!"
            }
          // }
        }, 1000);
      }
    }, 1000);
  }



  handleSection(){
    let coll = document.getElementsByClassName("collapsible");
    let i;
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.display === "block") {
          content.style.display = "none";
        } else {
          content.style.display = "block";
        }
      });
    }
  }


  getOrders(){
    let consults = [];
    this.afs.collection("consultation", ref => ref.where("UID_Patient", "==", firebase.auth().currentUser)).valueChanges().subscribe(data => {
      
      this.afs.collection("orders").valueChanges().subscribe(data => {
        this.orders = data;
      });
    });
  }


  dismiss(){
    if(this.modalRef){
      this.modalRef.close();
    }
    this.orderChangeApproved = undefined;
    this.orderChangeDeclined = undefined;
    this.productRefillTrackList = [];
    this.modalRef = undefined;
  }

  routeCheck(){
    this.authService.profile().then((profile:any) => {
      let orders = new Array<any>(this.orders.length);
      
        // this.afs.collection("stripe_payments", ref => ref.where("uid", "==", profile.uid)).valueChanges().subscribe(async tests => {
        this.afs.collection("square_payments", ref => ref.where("uid", "==", profile.uid)).valueChanges().subscribe(async tests => {
          this.orders = await tests;
          this.orders.forEach(order => {
            
            this.afs.collection('users').doc(profile.uid).valueChanges().subscribe((profile:any) => {
              let obj = {
                customer: `${profile.firstName + " " + profile.lastName1}`,
                icon: "",
              }
              if(order.paymentStatus == "paid"){
                obj.icon = "far fa-check-circle text-success";
              }else if(order.paymentStatus == "pending"){
                obj.icon = "far fa-clock text-warning";
              }else if(order.paymentStatus == "cancelled"){
                obj.icon = "far fa-times-circle text-danger";
              }
              for(let i = 0; i < this.orders.length; i++){
                let merge = Object.assign(order, obj);
                // this.orders[i] = merge;
                
              }
            })
          });
        });
        setTimeout(() => {
        let id = this.router.url.split("/orders&Refills/")[1];
        if(id !== undefined){
          setTimeout(() => {
            this.orderId = id;
            this.orderFromRoute(id);
          }, 1000);
        }
      }, 1000);
      
    });
  }

  async hasShippingMethod(){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("consultation").doc(this.consultationId).valueChanges().subscribe(data => {
        resolve(data);
      })
    });
  }


  // getShippingMethods(){
  //   this.afs.collection("products", ref => ref.where("type", "==", "shipping")).valueChanges().subscribe(methods => {
  //     this.shippingMethod = methods;
  //   });
  // }


  async addShippingMethod(){
     this.orderLinesLoaded = false;
    let btn = document.getElementById("confirmbtn-modal");
    btn.innerHTML = "<span class='spinner-border'></span>";
    this.afs.collection("consultation").doc(this.consultationId).update({shippingMethod: this.orderShippingMethod}).then(res => {
      btn.innerHTML = "Confirm order";
      // this.modalRef.dismiss();
    })
    const data = {
      shippingMethod: this.orderShippingMethod.name
    }

    this.afs.collection("consultation").doc(this.consultationId).update(data);
    let ship = this.afs.firestore.collection("consultation").doc(this.consultationId).collection("Items").doc("shipping")
    ship.get().then((doc:any) => {
      if(doc.exists){
        this.afs.collection("consultation").doc(this.consultationId).collection("Items").doc(this.orderShippingMethod.type).update(this.orderShippingMethod).then(() =>  this.hasShipping = true)
      }else{
        this.afs.collection("consultation").doc(this.consultationId).collection("Items").doc(this.orderShippingMethod.type).set(this.orderShippingMethod).then(() =>  this.hasShipping = true)
      }
    });
  }

  disabledContinue(){
    if(this.hasShipping == false){
      return true;
    }else{
      return false;
    }
  }

  async getAcceptaCards(user){
    this.afs.collection("users").doc(user.uid).collection("wallet").valueChanges().subscribe(async (res:any) => {
      this.allPaymentMethods = await res.filter(x => x.active);
    });
  }

  async onItemChange(event){
    let data = {
      creditCardRef: "",
      acceptaID: "",
    };
    if(event !== undefined){
      data.creditCardRef = event.brand + "-" + event.last4 + " Expires " + event.expDate;
      data.acceptaID = event.customerNumber;

      // this.creditCardId = item.id.toString();
      // this.consultationsService.setCreditCardId(item.id.toString());
    }
    this.paymentMethod = [event]
    
    // this.afs.collection("consultation").doc(this.consult).update(data);  
    this.afs.collection("cyclicOrders").doc(this.order.id).update({acceptaID: data.acceptaID});
    this.order.acceptaID = data.acceptaID;
    this.getCardRegisteredOnRefill();
  }

  getCardRegisteredOnRefill(){
    this.afs.collection("users").doc(this.refill.uid).collection("wallet").doc(this.refill.acceptaID).valueChanges().subscribe((res:any) => {
      this.creditCardRef = res.brand + "-" + res.last4 + " Expires " + res.expDate;
      this.paymentMethod = res;
    });
  }

  getCyclicOrders(){
    this.afs.collection("orders", ref => ref.where("uid", "==", this.user.uid).where("refill", "==", true)).valueChanges().subscribe(async (orders:any) => {
      orders.forEach(async (o,i )=> {
        
        let lines:any = await this.orderService.getOrderLines(o.id);
        lines = lines.filter((l:BaseProduct) => l.type == "product");
        // all = [...new Map(all.map((item, key) => [item['productID'], item])).values()]
        let balance = i+"/"+lines[0].refillQty;
        let find = this.productRefillTrackList.find(x => x.orderID == o.id);
        if(lines.length > 0 && !find){
          let balance = i+"/"+lines[0].refillQty;
          lines[0] = Object.assign(lines[0], {balance:balance, date: o.date, paymentStatus: o.paymentStatus, order: o, orderID: o.id})
          this.cyclicOrders.push(lines[0]);
          this.cyclicOrders = [...new Map(this.cyclicOrders.map((item, key) => [item['productID'], item])).values()]
        }
      });
    });
  }



  async refillDetails(refill:Refill){
    const user:any = await this.authService.profile();
    this.refill = refill;
    // this.details = order;
    this.profile = user;
    this.orderId = refill.orderID;
    this.consultationId = refill.consultationId;
    // this.order = order;
    if(refill.state == "In Progress"){
      this.icon = "far fa-check-circle text-success";
    }else if(refill.state == "Hold"){
      this.icon = "far fa-clock text-warning";
    }else if(refill.state == "Closed"){
      this.icon = "far fa-times-circle text-danger";
    }

    this.getAcceptaCards(user);
    this.afs.collection('consultation').doc(refill.consultationId).collection('Address').doc('Shipping').valueChanges().subscribe(async res => {
      this.address = await res;
      this.address = await Object.assign(this.address, {customer: user.firstName + " " + user.lastName1});
    });
    this.getCardRegisteredOnRefill();
  }

  openRefillDetails(product){
    this.refillClicked = product;
    this.dismiss();
    this.orderDetails(product.order);
  }

  goToOrder(product){
    // this.router.navigate([`orders&Refills/${product.orderID}`]);
    this.viewOrder.emit(product);
    this.dismiss()
  }

  back(){
    if(this.modalRef){
      this.dismiss();
      this.productRefillTrackList = [];
    }
  }

  trackRefills(product:BaseProduct){
    this.refillClicked = product;
    this.searchRefillsBasedOnProduct(product);
    if(!this.modalService.hasOpenModals()){
      this.modalRef = this.modalService.open(this.detailsModal, { size: 'md', backdrop: 'static',centered: false, windowClass:"test" });
    }
  }


  searchRefillsBasedOnProduct(product: BaseProduct){
    this.afs.collection("orders", ref => ref.where("uid", "==", this.user.uid).where("refill", "==", true)).valueChanges().subscribe(async (orders:any) => {
      // let og = orders.filter(x => x.orderID == this.details.id);
      orders.map(async (o,i) => {
        let lines:any = await this.orderService.getOrderLines(o.id);
        lines = lines.filter((l:BaseProduct) => l.type == "product" && l.productID == product.productID);

        // if(lines.includes(product.productID)){
        //   let tracker = {
        //     balance: 
        //   } 
        let find = this.productRefillTrackList.find(x => x.orderID == o.id);
        if(lines.length > 0 && !find){
          
          let balance = i+"/"+lines[0].refillQty;
          lines[0] =  Object.assign(lines[0], {balance:balance, date: o.date, paymentStatus: o.paymentStatus, order: o, orderID: o.id})
          this.productRefillTrackList.push(lines[0]);
        }
        // }
        return lines;
      });
      // this.productRefillTrackList = this.productRefillTrackList.filter(item => item);
      this.productRefillTrackList = this.productRefillTrackList.sort(this.sortFunction);
      // this.productRefillTrackList = this.productRefillTrackList.filter(x => x.orderID !== this.details.id);
    });
  }

  sortFunction(a,b){  
    // let first = Number(a.orderNo);
    // let second = Number(b.orderNo);
    var dateA = new Date(a.date).getTime();
    var dateB = new Date(b.date).getTime();
    return dateA > dateB ? 1 : -1;  
  }; 
}

<div class="main">
  <h3 class="title">External Prescription</h3>
  <p class="title">Upload the user external prescription and enter manually the prescribed products.</p>

  <!-- <div class="uploader">
    <app-upload [uploadPrescription]="true"></app-upload>
  </div> -->

  <ul ngbNav #customNav="ngbNav" [activeId]="activeNav" class="nav nav-pills nav-justified" role="tablist">
    <li [ngbNavItem]="1" class="nav-item waves-effect waves-light" role="presentation" (click)="activeNav = 1">
        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab" aria-selected="true">
            <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
            <span class="d-none d-sm-block">Internal Practitioners</span> 
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="internal"></ng-template>
        </ng-template>
    </li>
    <li [ngbNavItem]="2" class="nav-item waves-effect waves-light" role="presentation" (click)="activeNav = 2">
        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false" tabindex="-1">
            <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
            <span class="d-none d-sm-block">External Practitioners</span> 
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="external"></ng-template>
        </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="customNav"></div>



  <ng-template #internal>
    <ng-template *ngIf="!currentPrescription.ID" [ngTemplateOutlet]="prescriptions"></ng-template>
    <ng-template *ngIf="currentPrescription.ID && currentPrescription.docName" [ngTemplateOutlet]="prescriptionDetails"></ng-template>
    <div *ngIf="currentPrescription.ID && !currentPrescription.docId" class="search mt-4">
      <label for="">Our practitioners</label>
      <ng-select [appendTo]="'body'" placeholder="{{'CONSULT-PRESCRIPTIONS.SEARCHPLACEHOLDER' | translate }}"
        [multiple]="false" (change)="handleSelection($event)">
        <!-- [multiple]="false" (keyup)="searchProducts($event.target.value)" (change)="handleSelection($event)" > -->
        <ng-option *ngFor="let practitioner of internalPractitioners" value="{{practitioner.uid}}">
          <div class="d-flex">
            <img src="{{practitioner.image}}" alt="{{practitioner.firstName}} + {{practitioner.lastName1}}">
            <div class="d-flex flex-column ml-2">
              <span class="">{{practitioner.firstName}} {{practitioner.lastName1}}</span>
              <span class="email">{{practitioner.email}}</span>
            </div>
          </div>
        </ng-option>
      </ng-select>
    </div>

    <app-manual-prescription *ngIf="currentPrescription.ID" [user]="user" [prescription]="currentPrescription" [practitioner]="practitioner" (items)="productList = $event" (prescriptionUpdate)="currentPrescription = $event" (close)="close()"></app-manual-prescription>
  </ng-template>

  <ng-template #external>

    <ng-template *ngIf="!currentPrescription.ID" [ngTemplateOutlet]="prescriptions"></ng-template>
    <ng-template *ngIf="currentPrescription.ID && currentPrescription.docName" [ngTemplateOutlet]="prescriptionDetails"></ng-template>

    <label *ngIf="currentPrescription.ID && currentPrescription && !currentPrescription.docId" class="mt-2" for="">Search External Practitioners</label>
    <input *ngIf="currentPrescription.ID && currentPrescription && !currentPrescription.docId" class="form-control" type="text" placeholder="Doctor NPI Number" (keyup)="searchRegistry($event.target.value)">
  <app-manual-prescription *ngIf="currentPrescription.ID" [user]="user" [prescription]="currentPrescription" [practitioner]="practitioner" (items)="productList = $event" (prescriptionUpdate)="currentPrescription = $event" (close)="close()"></app-manual-prescription>
  </ng-template>




  <ng-template #prescriptions>
    <div class="d-flex">
      <h3 class="title mt-3">Prescriptions ({{prescriptionsList.length}})</h3> <button *ngIf="prescriptionsList.length > 0" class="btn btn-info mt-3 ml-2" (click)="generatePrescription()">New Prescription</button>
    </div>
    <div *ngIf="prescriptionsList.length == 0" class="new-prescription d-flex justify-content-between" (click)="generatePrescription()">
      <div class="flex-column">
        <h4 *ngIf="currentPrescription.ID" class="title">Create a new prescription</h4>
        <h4 *ngIf="currentPrescription.ID == null" class="title">No prescriptions available yet.</h4>
        <p class="title">Transcribe a new prescription</p>
      </div>
      <svg class="align" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 12h14"></path>
        <path d="m15 16 4-4"></path>
        <path d="m15 8 4 4"></path>
      </svg>
    </div>
    <div class="grid-container">
      <div *ngFor="let prescription of prescriptionsList" class="prescription list">
        <div class="d-flex w-100 justify-content-between">
          <div class="d-flex">
            <img *ngIf="practitioners.length > 0" src="{{ setPractitionerImage(prescription.docId) }}" alt="{{prescription.docName}}">
            <div class="flex-column ml-2">
              <h5 class="title mb-0">{{prescription.docName}}</h5>
              <span class="email">{{prescription.docEmail}}</span>
            </div>
          </div>

          <div ngbDropdown placement="bottom-left" class="dropleft">
            <svg id="dropdown1" ngbDropdownToggle class="mx-auto" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
              <path d="M12 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
              <path d="M12 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
            </svg>
            <div ngbDropdownMenu aria-labelledby="dropdown1" style="left: -120px !important;">
              <button ngbDropdownItem (click)="setEditMode(prescription)" [disabled]="prescription.pharmacy == 'Dzeus'">
                Edit
              </button>
              <button ngbDropdownItem (click)="setUpload(prescription)" [disabled]="prescription.pharmacy == 'Dzeus'">
                Upload Prescription
              </button>
              <button ngbDropdownItem (click)="sendToPharmacy(prescription)" [disabled]="prescription.pharmacy == 'Dzeus'">
                Send to pharmacy
              </button>
            </div>
          </div>
        </div>
        <!-- <h5 class="title mt-2">{{prescription.docName}}</h5> -->
        <h6 class="title font-weight-bold mt-3">Product List</h6>
        <p class="ellipsis m-0" *ngFor="let product of prescriptionsItemsList[prescription.ID]">
          <ng-container *ngIf="product; else lineBreak">
            <span>{{ product.productName }}</span> #{{ product.productQty }} <br>
            <span>{{ product.rxInstruction}}</span>
          </ng-container>
          <ng-template #lineBreak><br></ng-template>
        </p>
  
        <div class="d-flex justify-content-end">
          <span>{{prescription.date.toDate() | date: "medium"}}</span>
        </div>
      </div> 
    </div>
  </ng-template>


  <ng-template #prescriptionDetails>
    <h3 class="title mt-3">Prescription Products List</h3>
    <div class="prescription list">
      <div class="d-flex w-100 justify-content-between">
        <div class="d-flex">
          <img src="{{ practitioner.image ? practitioner.image : basic_profile_image }}" alt="{{practitioner.firstName}} + {{practitioner.lastName1}}">
          <div class="flex-column ml-2">
            <h5 class="title mb-0">{{currentPrescription.docName}}</h5>
            <span class="title">{{currentPrescription.docEmail ? currentPrescription.docEmail : currentPrescription.docPhone}} </span>
          </div>
        </div>
        <div ngbDropdown placement="bottom-left" class="dropleft">
          <svg id="dropdown1" ngbDropdownToggle class="mx-auto" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
            <path d="M12 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
            <path d="M12 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
          </svg>
          <div ngbDropdownMenu aria-labelledby="dropdown1" style="left: -120px !important;">
            <button ngbDropdownItem (click)="setUpload(currentPrescription)">
              Upload Prescription
            </button>
            <button ngbDropdownItem (click)="sendToPharmacy(currentPrescription)">
              Send to pharmacy
            </button>
          </div>
        </div>
      </div>
      
      <span class="title font-weight-bold mt-3">Product List</span>
      <p class="ellipsis" *ngFor="let product of productList">
        <ng-container *ngIf="product; else lineBreak">
          <div class="d-flex w-100 justify-content-between">
            <div class="d-flex flex-column w-100">
              <span>{{ product.productName }} #{{ product.productQty }}</span>
              <span>{{ product.rxInstruction}}</span>
            </div>
            <svg class="delete" (click)="deleteItem(currentPrescription.ID, product)" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 7h16"></path>
              <path d="M10 11v6"></path>
              <path d="M14 11v6"></path>
              <path d="m5 7 1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12"></path>
              <path d="M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3"></path>
            </svg>
          </div>
        </ng-container>
        <ng-template #lineBreak><br></ng-template>
      </p>
    </div> 

  </ng-template>

  <ng-template #uploadPrescription let-modal>
    <app-upload [user]="user" [file_types]="'image/*, .pdf'" [prescription]="currentPrescription" [uploadPrescription]="true" (close)="modal.close()"></app-upload>
  </ng-template>
</div>

<ng-template #pharmacyDetails let-modal>
  <div class="content">
    <div class="check-wrap"></div>
    <h2>{{'CONSULT-PRESCRIPTIONS.PRESCSENT' | translate}}</h2>
    <p *ngIf="currentPrescription.pharmacy" class="sent-to">{{'CONSULT-PRESCRIPTIONS.PRESCWASSENT' | translate}}<span class="pharmacy">{{currentPrescription.pharmacy}}</span>{{'CONSULT-PRESCRIPTIONS.PHARMACY' | translate}}</p>
    <div class="prescription-contents">
      <div *ngFor="let product of prescriptionsItemsList[currentPrescription.ID]" class="products d-flex">
        <img src="{{product.image | translation:user.preferedLang}}" alt="">
        <span>{{product.productName | translation:user.preferedLang}}</span><br>
        <span class="ml-2"> #{{product.productQty}}</span>
      </div>
    </div>

    <div class="d-flex mt-3 w-100">
      <button class="btn close-prescription" (click)="close()">{{'CONSULT-PRESCRIPTIONS.CLOSE' | translate}}</button>
    </div>
  </div>
</ng-template> 
<div class="container-fluid">
  <app-pagetitle [title]="pagetitle"></app-pagetitle>

  <div class="col-lg-12 p-0">
    <ul ngbNav #customNav="ngbNav" [activeId]="3" class="nav-tabs firstNav nav-tabs-custom nav-justified" style="border-bottom: 0">
      <li [ngbNavItem]="1">
        <a ngbNavLink (click)="filterConsults = 'All'; selectUsers({target: {value: 'Patients'}}, consultData, 'consultData')">
          <span class="d-block d-sm-none" style="font-size: 12px !important;">{{'MENUITEMS.PRACTITIONERSCONSULTS.TEXT' | translate}}</span>
          <span class="d-none d-sm-block">{{'MENUITEMS.PRACTITIONERSCONSULTS.TEXT' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="consultManager"></ng-template>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink (click)="filterConsults = 'All'; selectUsers({target: {value: 'Patients'}}, followUpConsults, 'followUpConsults')">
          <span class="d-block d-sm-none" style="font-size: 12px !important;">{{'PRACTITIONER-CONSULTS.FOLLOWUPCONSULTS' | translate}}</span>
          <span class="d-none d-sm-block">{{'PRACTITIONER-CONSULTS.FOLLOWUPCONSULTS' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="consultManager"></ng-template>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink (click)="filterConsults = 'All'; selectUsers({target: {value: 'Patients'}}, allConsults, 'allConsults')">
          <span class="d-block d-sm-none" style="font-size: 12px !important;">{{'PRACTITIONER-CONSULTS.ALLCONSULTS' | translate}}</span>
          <span class="d-none d-sm-block">{{'PRACTITIONER-CONSULTS.ALLCONSULTS' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="consultManager"></ng-template>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="customNav"></div>
  </div>

  <ng-template #consultManager>
    <div class="d-flex wrap" style="justify-content: space-evenly;">
      <a class="d-flex flex-column" style="height: fit-content; margin-top: 10px;" *ngFor="let status of translateStatus; let i = index" [ngClass]="{'filteredConsult': filterConsults == consultsStatus[i]}" (click)="filterConsults = consultsStatus[i]; getCurrentFilterStatus(consultsStatus[i])"> <!--(click)="getCurrentFilterStatus(consultsStatus[i])"-->
        <!-- <i class="{{icons[i]}}" [ngClass]="{'iconColor': filterConsults != consultsStatus[i]}" style="font-size: 16px; height: 18px;"></i>  -->
        {{status | translate}} <p class="m-0" [ngClass]="{'underline': filterConsults == consultsStatus[i]}"></p>
      </a>
    </div>
    <div class="card">
      <div class="card-body">
        <span class="row">
          <div class="col-sm-12 col-md-12 d-flex mb-2 justify-content-between flex-column"> 
            <div>
              <button class="btn btn-link" (click)="copyTextToClipboard()">Copy Data <i class="mdi mdi-content-copy text-primary"></i></button>
            </div>
            <div class="d-flex">
              <select class="mr-2" (change)="selectUsers($event, this[consultsManaging], consultsManaging)">
                <option>Patients</option>
                <option>Test</option>
              </select>
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label for="search" class="d-inline-flex align-items-center">{{'ACCOUNTS.SEARCH' | translate}}
                  <input id="search" type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                    aria-controls="tickets-table" [(ngModel)]="term" (keyup)="filterConsultations(term)"/>
                </label>
              </div>
            </div>
          </div>
        </span>

        <!--------------------NEW PAGINATION-------------------->
        <!-- <div class="table-responsive">
          <table class="table table-centered">
            <thead class="thead-light">
              <tr> 
                <th>{{'ADMINCONSULTS.ACTION' | translate}}</th>
                <th>{{'ADMINCONSULTS.ID' | translate}}</th>  
                <th>{{'ADMINCONSULTS.CONSULTTYPE' | translate}}</th>
                <th>{{'ADMINCONSULTS.DATE' | translate}}</th>
                <th>{{'ADMINCONSULTS.PATIENTNAME' | translate}}</th>
                <th>{{'ADMINCONSULTS.PATIENTEMAIL' | translate}}</th>
                <th>{{'ADMINCONSULTS.PRACTITIONERNAME' | translate}}</th>
                <th>{{'ADMINCONSULTS.STATUS' | translate}}</th>
                <th>{{'ADMINCONSULTS.STEP' | translate}}</th>
              </tr>
            </thead>
            <tbody *ngFor="let consult of allFilteredConsults | paginate: { id: 'consult-pagination', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index; filter: term;">
              <tr *ngIf="consult.Status == filterConsults || filterConsults == 'All'">
                <td>
                  <i class="mdi mdi-pencil font-size-18 mr-3 text-success" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.EDIT' | translate}}" (click)="openModal(consult)" [routerLink]="['../consults', consult.ID]"></i>
                </td>
                <td>{{consult.ID}}</td>
                <td> {{ consult.conditionName | translation:currentLang}}</td>
                <td *ngIf="consult.dateToSort">{{ consult.dateToSort.toDate() | date:'MMMM dd, yyyy h:mma' }}</td>
                <td>{{ consult.patientName}}</td>  
                <td>{{ consult.patientEmail}} <br> {{ consult.phoneNumber | mask:'(000) 000-0000'}} <br> {{consult.state.name}}, {{consult.country}}</td>
                <td *ngIf="consult.practitionerName">{{ consult?.practitionerName}}</td>
                <td *ngIf="!consult.practitionerName && consult.Status != 'Paid' && (consult?.exception?.length == 0 || consult?.exception == undefined)"></td>
                <td *ngIf="!consult.practitionerName && consult.Status == 'Paid' && consult?.exception?.length == 0 && consult.userVerified"><button class="btn btn-cyan" (click)="open(assignModal,consult)">{{'ADMINCONSULTS.ASSIGN' | translate}}</button></td>
                <td *ngIf="!consult.practitionerName && consult.Status == 'Paid' && consult?.exception?.length == 0 && !consult.userVerified"><span style="color: red;">User Unverified</span></td>
                <td *ngIf="!consult.practitionerName && consult.Status == 'Paid' && (consult?.exception?.includes('idExpired') || consult?.exception?.includes('invalidId'))"><span style="color: red;">Consult missing id</span></td>
                <td *ngIf="!consult.practitionerName && (consult?.exception?.includes('payLater'))"><span style="color: red;">Pay Later</span></td>
                <td *ngIf="consult.Status == 'Completed'">{{'PRACTITIONER-CONSULTS.COMPLETED' | translate}}</td>
                <td *ngIf="consult.Status == 'Rejected'">{{'PRACTITIONER-CONSULTS.REJECTED' | translate}}</td>
                <td *ngIf="consult.Status == 'Paid'">{{'PRACTITIONER-CONSULTS.PAID' | translate}}</td>
                <td *ngIf="consult.Status == 'In Progress'">{{'PRACTITIONER-CONSULTS.INPROGRESS' | translate}}</td>
                <td *ngIf="consult.Status == 'Open'">{{'PRACTITIONER-CONSULTS.OPEN' | translate}}</td>
                <td *ngIf="consult.Status == 'Denied'">{{'PRACTITIONER-CONSULTS.DENIED' | translate}}</td>
                <td *ngIf="consult.Status == 'Draft'">{{'PRACTITIONER-CONSULTS.DRAFT' | translate}}</td>  
                <td *ngIf="consult.Status != 'Open' && consult.Status != 'Draft'">{{'ADMINCONSULTS.DONE' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'preconsultQuestions' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.QUESTIONS' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'engagementStep' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.ENGAGEMENT' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'gender' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.GENDER' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'biologicalgender' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.BIOLOGICALGENDER' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseState' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.COUNTRY' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'birthdate' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.BIRTHDATE' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseMedication' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.SELECTPRODUCT' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseLabTests' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.LABS' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseLabLocation' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.LABLOCATION' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'nonPreconsultQuestions' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.QUESTIONS' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'phoneNumber' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.PHONE' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'choosePractitioner' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.CHOOSEPRACTITIONER' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseService' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.SELECTSERVICE' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'profileImage' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.IDENTIFICATION' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'checkout' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.CHECKOUT' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseTime/Type' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.CHOOSETIME' | translate}}</td>
                <td *ngIf="consult.Status == 'Open' && consult.lastRevision.seconds < oldConsultsDate">outdated consult</td>
              </tr>
            </tbody>
          </table>
        </div> -->

        <div class="table-responsive">
          <table class="table table-centered">
            <thead class="thead-light">
              <tr> 
                <th>{{'ADMINCONSULTS.ACTION' | translate}}</th>
                <th>{{'ADMINCONSULTS.ID' | translate}}</th>  
                <th>{{'ADMINCONSULTS.CONSULTTYPE' | translate}}</th>
                <th>{{'ADMINCONSULTS.DATE' | translate}}</th>
                <th>{{'ADMINCONSULTS.PATIENTNAME' | translate}}</th>
                <th>{{'ADMINCONSULTS.PATIENTEMAIL' | translate}}</th>
                <th>{{'ADMINCONSULTS.PRACTITIONERNAME' | translate}}</th>
                <th>{{'ADMINCONSULTS.STATUS' | translate}}</th>
                <th>{{'ADMINCONSULTS.STEP' | translate}}</th>
              </tr>
            </thead>
            <tbody *ngFor="let consult of allFilteredConsults | paginate: { itemsPerPage: 10, currentPage: pageConsults, id: 'first'}; let i = index; filter: term;">
              <tr *ngIf="consult.Status == filterConsults || filterConsults == 'All'">
                <td>
                  <i class="mdi mdi-pencil font-size-18 mr-3 text-success" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.EDIT' | translate}}" (click)="openModal(consult)" [routerLink]="['../consults', consult.ID]"></i>
                </td>
                <td>{{consult.ID}}</td>
                <td> {{ consult.conditionName | translation:currentLang}}</td>
                <td *ngIf="consult.dateToSort">{{ consult.dateToSort.toDate() | date:'MMMM dd, yyyy h:mma' }}</td>
                <td>{{ consult.patientName}}</td>  
                <td>{{ consult.patientEmail}} <br> {{ consult.phoneNumber | mask:'(000) 000-0000'}} <br> {{consult.state.name}}, {{consult.country}}</td>
                <td *ngIf="consult.practitionerName">{{ consult?.practitionerName}}</td>
                <td *ngIf="!consult.practitionerName && consult.Status != 'Paid' && (consult?.exception?.length == 0 || consult?.exception == undefined)"></td>
                <td *ngIf="!consult.practitionerName && consult.Status == 'Paid' && consult?.exception?.length == 0 && consult.userVerified"><button class="btn btn-cyan" (click)="open(assignModal,consult)">{{'ADMINCONSULTS.ASSIGN' | translate}}</button></td>
                <td *ngIf="!consult.practitionerName && consult.Status == 'Paid' && consult?.exception?.length == 0 && !consult.userVerified"><span style="color: red;">User Unverified</span></td>
                <td *ngIf="!consult.practitionerName && consult.Status == 'Paid' && (consult?.exception?.includes('idExpired') || consult?.exception?.includes('invalidId'))"><span style="color: red;">Consult missing id</span></td>
                <td *ngIf="!consult.practitionerName && (consult?.exception?.includes('payLater'))"><span style="color: red;">Pay Later</span></td>
                <td *ngIf="consult.Status == 'Completed'">{{'PRACTITIONER-CONSULTS.COMPLETED' | translate}}</td>
                <td *ngIf="consult.Status == 'Rejected'">{{'PRACTITIONER-CONSULTS.REJECTED' | translate}}</td>
                <td *ngIf="consult.Status == 'Paid'">{{'PRACTITIONER-CONSULTS.PAID' | translate}}</td>
                <td *ngIf="consult.Status == 'In Progress'">{{'PRACTITIONER-CONSULTS.INPROGRESS' | translate}}</td>
                <td *ngIf="consult.Status == 'Open'">{{'PRACTITIONER-CONSULTS.OPEN' | translate}}</td>
                <td *ngIf="consult.Status == 'Denied'">{{'PRACTITIONER-CONSULTS.DENIED' | translate}}</td>
                <td *ngIf="consult.Status == 'Draft'">{{'PRACTITIONER-CONSULTS.DRAFT' | translate}}</td>  
                <td *ngIf="consult.Status != 'Open' && consult.Status != 'Draft'">{{'ADMINCONSULTS.DONE' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'preconsultQuestions' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.QUESTIONS' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'engagementStep' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.ENGAGEMENT' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'gender' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.GENDER' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'biologicalgender' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.BIOLOGICALGENDER' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseState' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.COUNTRY' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'birthdate' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.BIRTHDATE' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseMedication' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.SELECTPRODUCT' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseLabTests' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.LABS' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseLabLocation' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.LABLOCATION' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'nonPreconsultQuestions' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.QUESTIONS' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'phoneNumber' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.PHONE' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'choosePractitioner' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.CHOOSEPRACTITIONER' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseService' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.SELECTSERVICE' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'profileImage' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.IDENTIFICATION' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'checkout' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.CHECKOUT' | translate}}</td>
                <td *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseTime/Type' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.CHOOSETIME' | translate}}</td>
                <td *ngIf="consult.Status == 'Open' && consult.lastRevision.seconds < oldConsultsDate">outdated consult</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mobile-table">
          <div class="">
            <li *ngFor="let consult of allFilteredConsults | paginate: { itemsPerPage: 10, currentPage: pageConsults, id: 'first'}; let i = index; filter:term;" [ngClass]="{'disable-div': consult.Status != filterConsults && filterConsults != 'All'}">
              <div class="d-flex" *ngIf="consult.Status == filterConsults || filterConsults == 'All'">
                <div class="w-100" style="min-width: 200px;">
                  <div class="d-flex flex-column">
                    <div>
                      <a class="btn btn-primary  w-auto" style="color: white; font-size: 16px !important; padding-top: 0 !important; padding-bottom: 0 !important;" (click)="openModal(consult)" [routerLink]="['../consults', consult.ID]">{{'CONSULTATIONS.VIEWCONSULT' | translate}}</a>
                    </div>
                    <ngb-highlight class="font-size-18 bold" [result]="consult?.conditionName | translation:currentLang"></ngb-highlight>
                  </div>
                  <div class="d-flex justify-content-start">
                    <ngb-highlight class="payment-status" [result]="'Status:&nbsp;'"></ngb-highlight>
                    <ngb-highlight class="w-50 payment-status bold" *ngIf="consult?.Status === 'Paid'" [result]="'PRACTITIONER-CONSULTS.PAID' | translate"></ngb-highlight>
                    <ngb-highlight class="w-50 payment-status bold" *ngIf="consult?.Status === 'Open'" [result]="'PRACTITIONER-CONSULTS.OPEN' | translate"></ngb-highlight>
                    <ngb-highlight class="w-50 payment-status bold" *ngIf="consult?.Status === 'Denied'" [result]="'PRACTITIONER-CONSULTS.DENIED' | translate"></ngb-highlight>
                    <ngb-highlight class="w-50 payment-status bold" *ngIf="consult?.Status === 'Completed'" [result]="'PRACTITIONER-CONSULTS.COMPLETED' | translate"></ngb-highlight>
                    <ngb-highlight class="w-50 payment-status bold" *ngIf="consult?.Status === 'In Progress'" [result]="'PRACTITIONER-CONSULTS.INPROGRESS' | translate"></ngb-highlight>
                    <ngb-highlight class="w-50 payment-status bold" *ngIf="consult?.Status === 'Rejected'" [result]="'PRACTITIONER-CONSULTS.REJECTED' | translate"></ngb-highlight>
                    <ngb-highlight class="w-50 payment-status bold" *ngIf="consult?.Status === 'Draft'" [result]="'PRACTITIONER-CONSULTS.DRAFT' | translate"></ngb-highlight>
                  </div>
                  
                 
                  <div class="d-flex">
                    <span>{{'ADMINCONSULTS.PATIENTNAME' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="consult.patientName"></ngb-highlight> </span>
                    
                  </div>

                  <div class="d-flex">
                    <span>{{'PRACTITIONER-CONSULTS.LOCATION' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="consult.state.name + ', ' + consult.country"></ngb-highlight> </span>
                  </div>
                  
                  <div class="d-flex">
                    <span>{{'ADMINCONSULTS.PRACTITIONERNAME' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="consult.practitionerName || 'N/A'"></ngb-highlight> </span>
                  </div>
                  <div class="d-flex">
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'preconsultQuestions' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.QUESTIONS' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'engagementStep' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.ENGAGEMENT' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'gender' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.GENDER' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'socialGender' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.BIOLOGICALGENDER' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseState' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.COUNTRY' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'birthdate' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.BIRTHDATE' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseLabTests' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.LABS' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseLabLocation' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.CHOOSELABLOCATION' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseMedication' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.SELECTPRODUCT' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'nonPreconsultQuestions' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.QUESTIONS' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'phoneNumber' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.PHONE' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'choosePractitioner' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.CHOOSEPRACTITIONER' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseService' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.SELECTSERVICE' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'profileImage' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.IDENTIFICATION' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'checkout' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.CHECKOUT' | translate"></ngb-highlight></span>
                    <span *ngIf="(consult.Status == 'Open' || consult.Status == 'Draft') && consult.currentWizardStep == 'chooseTime/Type' && (consult.lastRevision.seconds >= oldConsultsDate || consult.Status == 'Draft')">{{'ADMINCONSULTS.STEP' | translate}}:&nbsp; <ngb-highlight class="bold" [result]="'ADMINCONSULTS.CHOOSETIME' | translate"></ngb-highlight></span>
                    <span *ngIf="consult.Status == 'Open' && consult.lastRevision.seconds < oldConsultsDate">Outdated Consult</span>
                  </div>
                  <div class="d-flex flex-column justify-content-end align-items-end">
                    <ngb-highlight *ngIf="consult.dateToSort" [result]="consult?.dateToSort.toDate() | date:'MMMM dd, yyyy hh:mm a'"></ngb-highlight>
                    <div *ngIf="!consult.practitionerName && consult.Status == 'Paid'  && consult?.exception?.length == 0 && consult.userVerified" [result]=""><button class="btn btn-cyan" (click)="open(assignModal,consult)">{{'ADMINCONSULTS.ASSIGN' | translate}}</button></div>
                    <div *ngIf="!consult.practitionerName && consult.Status == 'Paid' && consult?.exception?.length == 0 && !consult.userVerified"><span style="color: red;">User Unverified</span></div>
                    <div *ngIf="!consult.practitionerName && consult.Status == 'Paid' && (consult?.exception?.includes('idExpired') || consult?.exception?.includes('invalidId'))"><span style="color: red;">Consult missing id</span></div>
                    <div *ngIf="!consult.practitionerName && (consult?.exception?.includes('payLater'))"><span style="color: red;">Pay Later</span></div>
                  </div>
                </div>
              </div>
              <hr>
            </li>
          </div>
        </div>


        <div class="row justify-content-end mt-4">
          <div class="col text-right">
            <pagination-controls (pageChange)="pageConsults = $event" id="first" style="text-align: right;"></pagination-controls>
          </div>
        </div>

        <!--------------NEW PAGINATION-------------->
        <!-- <div class="row justify-content-end">
          <div class="col text-right">
              <pagination-controls 
                [directionLinks]="true"
                [autoHide]="false"
                [responsive]="true"
                [totalItems]="totalItems"
                [itemsPerPage]="itemsPerPage"
                (pageChange)="pageChanged($event)" 
                id="consult-pagination" 
                style="text-align: right;">
              </pagination-controls>
          </div>
        </div> -->



      </div>
    </div>  
  </ng-template>

  <div *ngIf="loading" class="loading">
    <app-reload [message]="'RELOAD.CREATINGACCOUNT'"></app-reload>
  </div>

  <ng-template #assignModal let-modal>
    <button type="button" class="close float-right d-flex justify-content-end mr-2 modal-close" (click)="modal.close()" aria-hidden="true">×</button>

    <div class="table-responsive">
      <table class="table table-centered">
        <thead class="thead-light">
          <tr> 
            <th>Name</th>
            <th>Consults Attending</th>
            <th>Assign</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let p of assignPractitioners" [ngClass]="{'highlight': assignConsult.practitionerForConsult == p.uid}">
            <td>
              <img style="width: 50px;" class="rounded-circle" src="{{p.image}}"> {{p.firstName}} {{p.lastName1}}
            </td>  <!---->
            <td>{{p.consults}} opened consults</td>
            <td><button class="btn btn-cyan" (click)="assignConsults(p,assignConsult); modal.close()">Assign</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</div>
  

import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {User} from '@firebase/auth-types';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ProfileDataService } from '../../profile/data.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PharmacyOrderScheduleService } from 'src/app/core/services/pharmacy-order-schedule.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Output() dismiss = new EventEmitter<Boolean>();
  @Input() address: any = {AddressDefault: false};
  @Input() consultShipping: any = false;
  @Input() bool: boolean;
  @Input() save: boolean;
  @Input() consultID: string;
  @ViewChild('addressForm') myTemplate; 
  @Input() accountToEdit: string;
  @Output() output = new EventEmitter<Boolean>()
  @Input() validate = false;
  @Input() showBtns = false;
  @Output() validateAddressState = new EventEmitter<string>()
  
  currentUser:any;

  billingAddresses:any = [];
  shippingAddresses:any = [];
  shipping:any;
  addressform:UntypedFormGroup;
  hide:Boolean = false;
  isUpdated:Boolean = false;

  allSubscriptions = new Subscription()
  country:any = [];
  states:any = [];

  countries: any;
  statesUS: any = [];

  swal

  zipcodeResponse:any = '';
  validation_messages = {
    'AddressLine1': [
      {type: 'required', message: 'ADDRESS.VALIDATIONS.ADDRESSREQUIRED'},
      {type: 'pattern', message: 'ADDRESS.VALIDATIONS.ADDRESSVALID'},
    ],
    'AddressState': [
      {type: 'required', message: 'ADDRESS.VALIDATIONS.STATEREQUIRED'},
      {type: 'pattern', message: 'ADDRESS.VALIDATIONS.STATEVALID'},
    ],
    'AddressCity': [
      {type: 'required', message: 'ADDRESS.VALIDATIONS.CITYREQUIRED'},
      {type: 'pattern', message: 'ADDRESS.VALIDATIONS.CITYVALID'},
    ],
    'AddressCountry': [
      {type: 'required', message: 'ADDRESS.VALIDATIONS.COUNTRYREQUIRED'},
      {type: 'pattern', message: 'ADDRESS.VALIDATIONS.COUNTRYVALID'},
    ],
    'AddressZipcode': [
      {type: 'required', message: 'ADDRESS.VALIDATIONS.ZIPCODEREQUIRED'},
      {type: 'pattern', message: 'ADDRESS.VALIDATIONS.ZIPCODEVALID'},
    ],
    'AddressType': [
      {type: 'required', message: 'ADDRESS.VALIDATIONS.TYPEREQUIRED'},
      // {type: 'pattern', message: 'ADDRESS.VALIDATIONS.TYPEVALID'},
    ]
   };

   currentLang = this.translate.store.currentLang;

   currentZipCode: any;

   currentCityInput: any;

   zipCodeValidated: boolean = undefined;

   validatingZipCode: boolean = false;
  
  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public firebaseService: FirebaseService, 
    public formBuilder: UntypedFormBuilder,  
    public dataService: ProfileDataService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private pharmacyOrderScheduleService: PharmacyOrderScheduleService,
    private modalService: NgbModal,
  ) { }
  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe()
  }

 
  ngOnInit() {
    this.getUser();
    this.addressform = this.formBuilder.group({
      AddressLine1: new UntypedFormControl({value: '', disabled: false}),
      AddressLine2: new UntypedFormControl({value: '', disabled: false}),
      AddressState: new UntypedFormControl({value: undefined, disabled: true}),
      AddressStateCode: new UntypedFormControl({value: undefined, disabled: true}),
      AddressCity: new UntypedFormControl({value: '', disabled: false}),
      AddressCountry: new UntypedFormControl({value: undefined, disabled: true}),
      AddressZipcode: new UntypedFormControl({value: '', disabled: false}),
      AddressType: new UntypedFormControl({value: undefined, disabled: false}),
      AddressDefault: new UntypedFormControl({value: false, disabled: false})
    });

    // this.getStates();
    this.getCountry();

    this.isEdit();

    if(this.address.id){
      this.getStates({country: this.address.AddressCountry})
    }

  }

  setState(){
    this.addressform.controls.AddressState.patchValue(this.currentUser.currentLocation.name);
    this.addressform.controls.AddressStateCode.patchValue(this.currentUser.currentLocation.code);
    this.addressform.controls.AddressCountry.patchValue(this.currentUser.currentLocation.country);
    this.getStates({country: this.currentUser.currentLocation.country})

    if(this.address){
      this.addressform.patchValue(this.address)
    }
  }

  async ngAfterViewInit(){
    this.cdr.detectChanges();
    
    // if(this.bool == true){
    //   var element = <HTMLInputElement> await document.getElementById("select");
    //   element.disabled = true;
    // }
    // else {
    //   var element = <HTMLInputElement> await document.getElementById("select");
    //   element.disabled = false
    // }
    if(this.consultShipping){
      this.addressform.controls.AddressType.patchValue('Shipping')
    }
  }

  get addressgetter() {
     return this.addressform?.controls;
   }

  getUser() {
    if(this.accountToEdit != null || this.accountToEdit != undefined){
      // this.afs.firestore.collection('users').doc(`${this.accountToEdit}`).collection("Address").get().then(address => {
      //   this.billingAddresses = address.docs.filter(a => a.data().AddressType == 'Billing');
      //   this.shippingAddresses = address.docs.filter(a => a.data().AddressType == 'Shipping');;
      // }) 

      this.afs.collection('users').doc(this.accountToEdit).valueChanges().subscribe(data => {
        this.currentUser = data;
        this.setState();
        this.firebaseService.getUserAddresses({uid: this.accountToEdit}).then(address => {
          this.billingAddresses = address.filter(a => a.AddressType == 'Billing');
          this.shippingAddresses = address.filter(a => a.AddressType == 'Shipping' && a.AddressStateCode == this.currentUser.currentLocation.code);
        })
      })

    }
    else{
    this.afAuth.user.subscribe(user => {
      // if(user){
      //   this.afs.firestore.collection('users').doc(`${this.currentUser}`).collection("Address").get().then(address => {
      //     this.billingAddresses = address.docs.filter(a => a.data().AddressType == 'Billing');
      //     this.shippingAddresses = address.docs.filter(a => a.data().AddressType == 'Shipping');
      //   })
      // }
      this.afs.collection('users').doc(`${user.uid}`).valueChanges().subscribe(data => {
        this.currentUser = data;
        this.setState()
        this.firebaseService.getUserAddresses(user).then(address => {
          this.billingAddresses = address.filter(a => a.AddressType == 'Billing');
          this.shippingAddresses = address.filter(a => a.AddressType == 'Shipping').filter(a => a.AddressStateCode == this.currentUser.currentLocation.code);
        })
      })

    });
    }
  }

  isEdit(){
    if(this.bool === true){
      //edit
      return true
    }else{
      //add
      return false
    }
  }

  getCountry(){
    let sub = this.afs.collection<any>('settings').doc('Country').collection('Country').valueChanges().subscribe(data => {
      this.countries = []; 
      for(let i = 0; i < data.length; i++) {
        if(data[i].enabled == true){
          this.countries.push(data[i])
        }
      }
    });
    this.allSubscriptions.add(sub)
  }

  countryWithStates = true;
  getStates(country: any){
    let sub = this.afs.collection("settings").doc('Country').collection('Country').doc(country.country).valueChanges().subscribe(data => {
      if(data && data.hasStates){
        let sub = this.afs.collection("settings").doc('Country').collection('Country').doc(country.country).collection('States').valueChanges().subscribe(data => {
          this.statesUS = data;
          this.statesUS.sort((a) => {
            if (a.name === 'Puerto Rico') {
              return -1;
            }
            else {
              return 1;
            }
          });
        });
        this.allSubscriptions.add(sub)
        this.countryWithStates = true
      }
      else {
        this.countryWithStates = false
        this.statesUS = []
      }
    });
    this.allSubscriptions.add(sub)
  }

  async validSubmit() {

    this.addressform.controls['AddressState'].enable();
    this.addressform.controls['AddressStateCode'].enable();
    this.addressform.controls['AddressCity'].enable();
    this.addressform.controls['AddressCountry'].enable();

    if(this.addressform.controls['AddressLine2'] == null){
      this.addressform.controls['AddressLine2'].patchValue('')
    }

    if(!this.countryWithStates){
      this.addressform.controls['AddressState'].patchValue('')
    }

    const data = this.addressform.value;

    this.firebaseService.createAddress(data, '',this.currentUser.uid).then(
      res => {
        if(this.consultID){
          this.afs.collection('consultation').doc(this.consultID).collection('Address').doc('Shipping').set(res)
        }
        this.addressform.reset();
        if(res.id && res.AddressDefault == true){
          this.updateSubscriptions(data);
          this.makeDefaultAddress({target: {checked: true}}, res, true)
        }
        else {
          if(this.dismiss){
            this.dismiss?.emit(true)
          }
        }
      }
    );
  }

  invalidSubmit() {
    if(this.addressgetter && this.addressgetter.AddressLine1.value != null && this.addressgetter.AddressLine1.value != '' &&
      this.addressgetter.AddressCountry.value != null && this.addressgetter.AddressCountry.value != '' &&
      this.addressgetter.AddressZipcode.value != null && this.addressgetter.AddressZipcode.value > 4 && this.addressgetter.AddressZipcode.value != '' &&
      this.addressgetter.AddressCity.value != null && this.addressgetter.AddressCity.value != '' && 
      ((this.addressgetter.AddressState.value != null && this.addressgetter.AddressState.value != '') || this.countryWithStates == false) &&
      this.addressgetter.AddressType.value != null && this.addressgetter.AddressType.value != '' && !this.validatingZipCode)
    {

      // if(this.billingAddresses.length !== 0){
      //   if(this.addressgetter.AddressType.value == this.billingAddresses[0].data().AddressType && this.bool == false){
      //     var element = <HTMLInputElement> document.getElementById("saveBtn");
      //     element.disabled = true;
      //     return true;
      //   }  
      // }
      // if(this.shippingAddresses.length !== 0){
      //   if(this.addressgetter.AddressType.value == this.shippingAddresses[0].data().AddressType && this.bool == false){
      //       var element = <HTMLInputElement> document.getElementById("saveBtn");
      //       element.disabled = true;
      //     return true;
      //   }   
      // }

      return false;
     
      // this.output.emit(false)
      // if(<HTMLInputElement> document.getElementById("saveBtn")){
      //   var element = <HTMLInputElement> document.getElementById("saveBtn");
      //   element.disabled = false;
      // }
      // return false;

    }
    else {
      this.output.emit(true)
      if(<HTMLInputElement> document.getElementById("saveBtn")){
        var element = <HTMLInputElement> document.getElementById("saveBtn");
        element.disabled = true;
        return true;
      }
    }

    return true;
  }

  updateAddress(){
  
    var updatedData = {
      AddressType: this.addressform.controls.AddressType.value,
      AddressLine1: this.addressform.controls.AddressLine1.value,
      AddressLine2: this.addressform.controls.AddressLine2.value,
      AddressState: this.addressform.controls.AddressState.value,
      AddressStateCode: this.addressform.controls.AddressStateCode.value,
      AddressCity: this.addressform.controls.AddressCity.value,
      AddressCountry: this.addressform.controls.AddressCountry.value,
      AddressZipcode: this.addressform.controls.AddressZipcode.value,
      AddressDefault: this.addressform.controls.AddressDefault.value,
      id: this.address.id,
      odooId: this.address.odooId
    }

    if(!this.countryWithStates){
      updatedData = Object.assign(updatedData, {AddressState: '', AddressStateCode: ''})
    }
    
    this.firebaseService.updateAddress(updatedData, this.address.id, this.currentUser.uid);

    this.afs.firestore.collection('pharmacyOrderSchedule').where('uid', '==', this.currentUser.uid).get().then((data: any) => {
      let subs = data.docs.map(x => x.data()).filter(x => x.shippingAddress.id == this.address.id && x.status == 'active');
      if(subs.length > 0){
        data.forEach(async (element: any) => {
          this.afs.collection('pharmacyOrderSchedule').doc(element.id).update({shippingAddress: updatedData})
        });
      }
    })

    if(this.consultID){
      updatedData = Object.assign(updatedData, {id: this.address.id})
      this.afs.collection('consultation').doc(this.consultID).collection('Address').doc('Shipping').set(updatedData)
    }
    this.dismiss.emit(true);
  }

  cancel(){
    this.dismiss.emit(true);
  }

  currentState
  stateAllowed(stateInfo){
    // this.state = stateInfo.code;
    this.currentState = stateInfo;
    this.addressform.controls.AddressStateCountry.patchValue(stateInfo.code);
    if(stateInfo?.enabled == false){
      this.translate.get('ADDRESS.SWAL').subscribe((res: any) => {
        this.swal = res;
      })
      Swal.fire({
        title: this.swal.TITLE,
        text: this.swal.TEXT,
        icon: 'warning',
        iconColor: '#ff0000 !important',
        showCancelButton: false,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#ff3d60',
        confirmButtonText: this.swal.CONFIRM,
        allowEscapeKey : false,
        allowOutsideClick: false,
      }).then(result => {
        if(result.isConfirmed){
          this.addressform.controls.AddressState.patchValue('')
        }
      })
    }
  }

  ngOnChanges(changes: SimpleChanges){
    let interval = setInterval(() => {
      if(!this.validatingZipCode){
        if(changes?.validate?.currentValue == true && this.invalidSubmit() == true){
          this.translate.get('ADDRESS').subscribe((res: any) => {
            this.swal = res;
          })
  
          Swal.fire({
            title: this.swal.ERROR,
            text: this.swal.CHECKADDRESS,
            icon: 'error',
            iconColor: '#ff0000 !important',
            showCancelButton: false,
            confirmButtonColor: '#34c38f',
            confirmButtonText: 'Ok',
            allowEscapeKey : false,
            allowOutsideClick: false
          })
  
          this.addressform.controls['AddressZipcode'].patchValue('')
          this.validateAddressState.emit('error');
        }
        clearInterval(interval)
      }
      
      if(changes?.validate?.currentValue == true && (this.invalidSubmit() == false || this.shippingAddresses.length > 0)){
        this.validSubmit().then(() => {
          this.validateAddressState.emit('valid');
        })
        clearInterval(interval)
      } 
    }, 1000)

  }

  warnDefaultAddress(event){
    if(event.target.checked && this.shippingAddresses.length > 0){
      this.translate.get('ADDRESS.SWAL').subscribe((res: any) => {
        this.swal = res;
      })
  
      Swal.fire({
        customClass: {
          confirmButton: 'btn-cyan',
          cancelButton: 'btn-light'
        },
        title: this.swal.TITLE,
        text: this.swal.CHANGEDEFAULT,
        icon: 'warning',
        iconColor: '#ff0000 !important',
        confirmButtonText: this.swal.CHANGE,
        cancelButtonText: this.swal.CANCEL,
        allowEscapeKey : false,
        showCancelButton: true,
        allowOutsideClick: false,
      }).then(result => {
        if(result.isConfirmed){
          this.makeDefaultAddress(event, this.address)
          this.addressform.controls.AddressDefault.patchValue(event.target.checked)
          this.address = Object.assign(this.address, {AddressDefault: event.target.checked})

          if(this.address.id){
            this.updateSubscriptions(this.address);
          }
        }

        if(result.isDismissed){
          this.makeDefaultAddress({target: {checked: false}}, this.address)
          this.addressform.controls.AddressDefault.patchValue(false)
          this.address = Object.assign(this.address, {AddressDefault: false})
        }
      })
    }
    else {
      this.makeDefaultAddress(event, this.address)
      this.addressform.controls.AddressDefault.patchValue(event.target.checked)
      this.address = Object.assign(this.address, {AddressDefault: event.target.checked})
    }
  }

  makeDefaultAddress($event, address, dismissAddress?) {
    if(address.id){
      if ($event.target.checked) {
        const subscription = this.afs.collection("users").doc(this.currentUser.uid).collection("Address").valueChanges().subscribe(async (address1: any) => {
          subscription.unsubscribe(); // Unsubscribe from the observable after the initial value is fetched
          
          let addressess = address1.filter(x => address.id != x.id);
          if (addressess.length > 0) {
            addressess.forEach(async (a: any) => {
              this.afs.collection("users").doc(this.currentUser.uid).collection("Address").doc(a.id).update({ AddressDefault: false });
            });
          }
          this.afs.collection("users").doc(this.currentUser.uid).collection("Address").doc(address.id).update({ AddressDefault: true });
          if(this.dismiss && dismissAddress){
            this.dismiss?.emit(true)
          }
        });
      } else {
        this.afs.collection("users").doc(this.currentUser.uid).collection("Address").doc(address.id).update({ AddressDefault: false });
        if(this.dismiss && dismissAddress){
          this.dismiss?.emit(true)
        }
      }
    }
    else {
      this.addressform.controls.AddressDefault.patchValue($event.target.checked)
    }
  }

  updateSubscriptions(address){
    this.pharmacyOrderScheduleService.updatePharmacyOrderSubscriptions(this.currentUser.uid, address)
  }

  onZipCodeInput(event: Event): void {

    const inputElement = event.target as HTMLInputElement;
    const zipCode = inputElement.value;

    var currentLocation = this.currentUser.currentLocation.code;

    var currentLocationName = this.currentUser.currentLocation.name;

    this.validatingZipCode = true;

    if (zipCode.length === 5) {

      this.dataService.verifyCityStateUsingZip(zipCode,currentLocation,currentLocationName).subscribe((response) => {

        if(response !== null && response !== undefined && response != "") {

          this.zipcodeResponse = response;

          if(response.res === "success"){

            if (response.AddressCity !== null && response.AddressCity !== undefined && response.AddressCity != "") {

              const cityText = response.AddressCity.toLowerCase();
              const words = cityText.split(" ");

              for (let i = 0; i < words.length; i++) {
                  words[i] = words[i][0].toUpperCase() + words[i].substr(1);
              }

              const city = words.join(" ");

              this.currentCityInput = city;
  
              this.addressform.controls.AddressCity.patchValue(city);

              this.zipCodeValidated = true;

              this.validatingZipCode = false;
  
            }

            if (response.AddressState !== null && response.AddressState !== undefined && response.AddressState != "") {
  
              this.addressform.controls.AddressState.patchValue(response.AddressState);
  
            }
  
            if (response.AddressStateCode !== null && response.AddressStateCode !== undefined && response.AddressStateCode != "") {
  
              this.addressform.controls.AddressStateCode.patchValue(response.AddressStateCode);
  
            }
  
            if (response.AddressZipCode !== null && response.AddressZipCode !== undefined && response.AddressZipCode != "") {
  
  
            }

          }
          else if(response.res === "current_location_error"){

            if(this.currentLang === "es"){

              var resMsgCurrentLocationError = response.msg_es;

              if(<HTMLInputElement> document.getElementById("saveBtn")){
                var element = <HTMLInputElement> document.getElementById("saveBtn");
                element.disabled = false;
              }

              this.swal = Swal.fire({
                customClass: {
                  confirmButton: 'swalPurple',
                },
                title: '¡Error!',
                text: resMsgCurrentLocationError,
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
                allowOutsideClick: false
              });
        
              this.swal.then((result) => {
                if (result.isConfirmed) {
                  this.modalService.dismissAll();
                  this.validatingZipCode = false;
                  this.addressform.controls['AddressZipcode'].patchValue('')
                }
              });

            }
            else if(this.currentLang === "en"){

              var resMsgCurrentLocationError = response.msg_en;

              if(<HTMLInputElement> document.getElementById("saveBtn")){
                var element = <HTMLInputElement> document.getElementById("saveBtn");
                element.disabled = false;
              }

              this.swal = Swal.fire({
                customClass: {
                  confirmButton: 'swalPurple',
                },
                title: '¡Error!',
                text: resMsgCurrentLocationError,
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
                allowOutsideClick: false
              });
        
              this.swal.then((result) => {
                if (result.isConfirmed) {
                  this.modalService.dismissAll();
                  this.validatingZipCode = false;
                  this.addressform.controls['AddressZipcode'].patchValue('')
                }
              });

            }

          }
          else if(response.res === "other"){

            if(this.currentLang === "es"){

              var resMsgOther = response.msg_es;

              if(<HTMLInputElement> document.getElementById("saveBtn")){
                var element = <HTMLInputElement> document.getElementById("saveBtn");
                element.disabled = false;
              }
  
              this.swal = Swal.fire({
                customClass: {
                  confirmButton: 'swalPurple',
                },
                title: '¡Error!',
                text: resMsgOther,
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
                allowOutsideClick: false
              });
        
              this.swal.then((result) => {
                if (result.isConfirmed) {
                  this.modalService.dismissAll();
                  this.validatingZipCode = false;
                  this.addressform.controls['AddressZipcode'].patchValue('')
                }
              });

            }
            else if(this.currentLang === "en"){

              var resMsgOther = response.msg_en;

              if(<HTMLInputElement> document.getElementById("saveBtn")){
                var element = <HTMLInputElement> document.getElementById("saveBtn");
                element.disabled = false;
              }
  
              this.swal = Swal.fire({
                customClass: {
                  confirmButton: 'swalPurple',
                },
                title: 'Error!',
                text: resMsgOther,
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
                allowOutsideClick: false
              });
        
              this.swal.then((result) => {
                if (result.isConfirmed) {
                  this.modalService.dismissAll();
                  this.validatingZipCode = false;
                  this.addressform.controls['AddressZipcode'].patchValue('')
                }
              });

            }

          }
          else if(response.res === "error"){

            if(this.currentLang === "es"){

              var resMsgError = response.msg_es;

              if(<HTMLInputElement> document.getElementById("saveBtn")){
                var element = <HTMLInputElement> document.getElementById("saveBtn");
                element.disabled = false;
              }
  
              this.swal = Swal.fire({
                customClass: {
                  confirmButton: 'swalPurple',
                },
                title: '¡Error!',
                text: resMsgError,
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
                allowOutsideClick: false
              });
        
              this.swal.then((result) => {
                if (result.isConfirmed) {
                  this.modalService.dismissAll();
                  this.validatingZipCode = false;
                  this.addressform.controls['AddressZipcode'].patchValue('')
                }
              });

            }
            else if(this.currentLang === "en"){

              var resMsgError = response.msg_en;

              if(<HTMLInputElement> document.getElementById("saveBtn")){
                var element = <HTMLInputElement> document.getElementById("saveBtn");
                element.disabled = false;
              }
  
              this.swal = Swal.fire({
                customClass: {
                  confirmButton: 'swalPurple',
                },
                title: 'Error!',
                text: resMsgError,
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
                allowOutsideClick: false
              });
        
              this.swal.then((result) => {
                if (result.isConfirmed) {
                  this.modalService.dismissAll();
                  this.validatingZipCode = false;
                  this.addressform.controls['AddressZipcode'].patchValue('')
                }
              });

            }
            
          }

        }

      });

      // this.validateZipCode(zipCode);
    }

  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-body-anatomy',
  templateUrl: './body-anatomy.component.html',
  styleUrls: ['./body-anatomy.component.scss']
})
export class BodyAnatomyComponent implements OnInit {
  @Input() patient:any;

  BMI = 'normal'
  image: string = '';
  constructor() { }

  ngOnInit(): void {
    let BMICalc
    if(this.patient?.BMI?.BMI != '' && !Number.isNaN(this.patient?.BMI?.BMI)){
      BMICalc = Number(this.patient?.BMI?.BMI.split('<en>')[1].split(' - ')[0])
    }
    else {
      BMICalc = (Number(this.patient?.BMI?.pounds) * 703)/(((Number(this.patient?.BMI?.feet * 12)) + Number(this.patient?.BMI?.inches)) * ((Number(this.patient?.BMI?.feet * 12)) + Number(this.patient?.BMI?.inches)))
      BMICalc = Number(BMICalc.toFixed(1))
    }

    if(BMICalc < 18.5){
      BMICalc = '<en>' + BMICalc + ' - Underweight</en><es>' + BMICalc + ' - Bajo Peso</es>'
      this.BMI = 'underweight';
      this.image = `assets/images/siluetes/underweight-${this.patient.biologicalgender}.svg`
      
    }
    if(BMICalc >= 18.5 && BMICalc <= 24.9){
      BMICalc = '<en>' + BMICalc + ' - Normal Weight</en><es>' + BMICalc + ' - Peso Normal</es>'
      this.BMI = 'normal'
      this.image = `assets/images/siluetes/normal-${this.patient.biologicalgender}.svg`
    }
    if(BMICalc >= 25){
      BMICalc = '<en>' + BMICalc + ' - Overweight</en><es>' + BMICalc + ' - Sobre Peso</es>'
      this.BMI = 'obese';
      this.image = `assets/images/siluetes/obese-${this.patient.biologicalgender}.svg`
    }
    
    var svg = document.getElementsByTagName('svg')[0]; //Get svg element
    var newElement = document.createElementNS(this.image, 'svg'); //Create a path in SVG's namespace
    svg.appendChild(newElement);
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { remoteConfig } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TelehealthService {
  settings = remoteConfig
  private POST_URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/addEventToCalendar`
  private DELETE_URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/deleteEventInCalendar`
  private UPDATE_URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/updateEventInCalendar`

  constructor(
    public afAuth: AngularFireAuth,
    private httpClient: HttpClient
  ) { }

  public async updateMeet(obj: any){
    let promise = new Promise(async (resolve, reject) =>{

      var meet = {
        startTime: await obj.startTime.toString(),
        endTime: await obj.endTime.toString(),
        eventId: await obj.meetRef.toString(),
        timezone: await obj.timezone.toString(),
        eventName: await obj.eventName.toString(),
        description: await obj.description.toString(),
        practitionerEmail: await obj.practitionerEmail.toString(),
        patientEmail: await obj.patientEmail.toString(),
        // token: await obj.token.toString()
      }
  
      let body = await new HttpParams();
  
      body = await body.set('eventName', meet.eventName);
      body = await body.set('description', meet.description);
      body = await body.set('startTime', meet.startTime);
      body = await body.set('endTime', meet.endTime);
      body = await body.set('eventId',meet.eventId);
      body = await body.set('timezone', meet.timezone);
      body = await body.set('practitionerEmail', meet.practitionerEmail)
      body = await body.set('patientEmail', meet.patientEmail)
      // body = await body.set('token', meet.token);

      this.httpClient.post(this.UPDATE_URL, body).subscribe(async res => {
        resolve(res)
      });
    })
    return await promise
  }

  async deleteMeet(eventId){
    let promise = new Promise(async (resolve, reject) =>{

      let body = await new HttpParams();
  
      body = await body.set('eventId',eventId.eventId.toString());
      body = await body.set('practitionerEmail', eventId.practitionerEmail.toString())
      // body = await body.set('token', eventId.token.toString());

      this.httpClient.post(this.DELETE_URL, body)
      .subscribe(async res => {
        resolve(res)
      });
    })
    return await promise
  }

  public async createMeet(obj: any){

    let promise = new Promise(async (resolve, reject) =>{

      var meet = {
        eventName: await obj.eventName.toString(),
        description: await obj.description.toString(),
        startTime: await obj.startTime.toString(),
        endTime: await obj.endTime.toString(),
        practitionerEmail: await obj.practitionerEmail.toString(),
        patientEmail: await obj.patientEmail.toString(),
        randomId: await obj.randomId.toString(),
        timezone: await obj.timezone.toString(),
        // token: await obj.token
      }
  
      let body = await new HttpParams();
  
      body = await body.set('eventName', meet.eventName);
      body = await body.set('description', meet.description);
      body = await body.set('startTime', meet.startTime);
      body = await body.set('endTime', meet.endTime);
      body = await body.set('practitionerEmail', meet.practitionerEmail);
      body = await body.set('patientEmail', meet.patientEmail);
      body = await body.set('randomId', meet.randomId);
      body = await body.set('timezone', meet.timezone);
      // body = await body.set('token', meet.token);
  
      this.httpClient.post(this.POST_URL, body).subscribe(async res => {
        resolve(res)
      });
    })
    return await promise
  }
}
import { Component, OnInit } from '@angular/core';
import { BackupService } from 'src/app/core/services/backup.service';

@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.scss']
})
export class BackupComponent implements OnInit {

  constructor(
    private backupService: BackupService
  ) { }

  ngOnInit(): void {

  }


  backupCalendar(){
    this.backupService.calendarBackup();
  }
  backupChat(){
    this.backupService.chatsBackup();
  }
  backupConditions(){
    this.backupService.conditionsBackup();
  }
  backupConditionQuestions(){
    this.backupService.conditionsQuestionsBackup();
  }
  backupCreditCardPayments(){
    this.backupService.creditCardPaymentsBackup();
  }
  backupDeliveryOrders(){
    this.backupService.deliveryOrdersBackup();
  }
  backupConsultation(){
    this.backupService.consultationBackup();
  }
  
  backupMail(){
    this.backupService.mailBackup();
  }
  backupMailTemplates(){
    this.backupService.mailTemplatesBackup();
  }
  backupDiagnosticTemplates(){
    this.backupService.diagnosticTemplatesBackup()
  }
  backupDiagnosticTest(){
    this.backupService.diagnosticsTestBackup();
  }
  backupOrders(){
    this.backupService.ordersBackup();
  }
  backupPrescription(){
    this.backupService.prescriptionBackup();
  }
  backupProducts(){
    this.backupService.productsBackup();
  }
  backupProductsPacks(){
    this.backupService.productsPacksBackup();
  }
 
  backupUsers(){
    this.backupService.usersBackup();
  }


}

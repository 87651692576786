import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import {AngularFirestore} from '@angular/fire/compat/firestore';
import {FirebaseService} from '../../core/services/firebase.service';
import 'firebase/firestore';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserProfiles } from '../../account/auth/signup/data';
import { DataService } from './data.service';
import { ProfileDataService } from 'src/app/pages/profile/data.service'; 


import Swal from 'sweetalert2';
import { AuthenticationService } from '../../core/services/auth.service';
import { environment } from '../../../environments/environment';
import { MustMatch } from './email.mustmatch';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient, } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import firebase from "firebase/compat/app";
import { Router } from '@angular/router';
import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';
import { LaboratoriesService } from 'src/app/core/services/laboratories/laboratories.service';
import { NPIRegistryService } from 'src/app/core/services/npi-registry.service';
import { DbCollectionTriggersService } from 'src/app/core/services/db-collection-triggers.service';

export interface Users {
  email?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  companyName?: string;
  barNumber?: string;
  accountType?: string;
  accountStatus?: string;
  uid?: string;
  isAccountDisabled?: boolean;
}

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, OnDestroy {
  // @ViewChild('largeDataModal', { read: TemplateRef }) largeDataModal:TemplateRef<any>;

  @ViewChild('createExternalPatientModal') createExternalPatientModal: TemplateRef<any>;

  patientInfo = false;
  term: any;

  loading:boolean = false;

  pagetitle = 'ACCOUNTS.ACCOUNTS'
  allSubscriptions = new Subscription()

  submitted = false;
  userCreatedRes:any;
  items: Observable<any[]>;
  roles: Array<any> = ['admin', 'patient', 'practitioner', 'nurse', 'patient service'];
  accountTypes = [];
  public isUpdated: Boolean = false;
  updatedValues: any;

  model: Users = new UserProfiles();

  profileToEdit;
  error = '';
  typeValidationForm: UntypedFormGroup; // type validation form
  createUserForm: UntypedFormGroup; // type validation form

  public isEmailUpdated: Boolean = false;

  validations_form: UntypedFormGroup; // type validation form

  //for swal modal
  title
  text
  cancel
  success
  canceled

  pdfsLabel = ['<en>Medicine Licence</en><es>Licencia de Medicina</es>', '<en>Telemedicine Certificate</en><es>Certificado de telemedicina</es>', '<en>Endoursement</en><es>Endoso</es>']
  currentLang = this.translate.store.currentLang


  navigation = 1;
  editSchedule = false

  delete: Boolean = false;
  createAccount: Boolean = false;
  validation_messages = {
    'email': [
      {type: 'required', message: 'ACCOUNTS.VALIDATIONS.EMAILREQUIRED'},
      {type: 'pattern', message: 'ACCOUNTS.VALIDATIONS.EMAILVALID'}
    ],
    'confirmemail': [
      {type: 'required', message: 'ACCOUNTS.VALIDATIONS.EMAILREQUIRED'},
      {type: 'pattern', message: 'ACCOUNTS.VALIDATIONS.EMAILVALID'}
    ],
    'accountType': [
      {type: 'required', message: 'ACCOUNTS.VALIDATIONS.ACCOUNTTYPE'},
      
    ],
    'validator': [
      {type: 'mustMatch', message: 'ACCOUNTS.VALIDATIONS.MUSTMATCH'},
    ]
  };

  public submit: Boolean = false;

  admin = this.authenticationService.currentUser()
  manageClaims = false;

  myClaims;

  description = '';
  pdfToSave: any = '';
  expDate: any = '';

  labs:any[] = [];
  showLabs: boolean = false;
  labDocument:any;

  allUserAccounts: any;
  pageAccounts = 1;
  pageSizeAccounts = 10;
  totalAccountsRecords = 0;

  showLoadingAccounts: boolean = false;

  profile: any;

  sendingEmailLoading: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private afs: AngularFirestore,
    public firebaseService: FirebaseService,
    private modalService: NgbModal,
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    public afAuth: AngularFireAuth,
    private httpClient: HttpClient,
    public translate: TranslateService,
    private router: Router,
    public labsService: LaboratoriesService,
    private NPIRegistryService: NPIRegistryService,
    private updateUserAccount: DbCollectionTriggersService,
    private profileDataService: ProfileDataService
  ) { 
    this.getUsers();  
    if(this.router.url.split('/accounts/')[1]){
      this.afs.firestore.collection('users').doc(this.router.url.split('/accounts/')[1]).get().then(user => {
        this.openModal(false, user.data())
        router.navigate(['/accounts'])
      })
    }
  }
  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe()
  }

  formData: UntypedFormGroup;

  allUsers = [];
  myPdfs = []
  pdf
  verifyForm: UntypedFormGroup;

  async ngOnInit(): Promise<void> {
    this.myClaims = await this.authenticationService.getClaims();

    this.verifyForm = this.formBuilder.group({
      status: [''],
      verifiedBy_Uid: [''],
      verifiedDate: ['']
    });
    // this.firebaseService.getAllUsers().subscribe(res => {
    //   this.items = res;
    // });
    this.typeValidationForm = this.formBuilder.group({
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      confirmemail: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),
    }, {
      validator: MustMatch('email', 'confirmemail'),
    });  

    this.createUserForm = this.formBuilder.group({
      firstName: [''],
      lastName1: [''],
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      // password: ['', Validators.required],
      source: [''],
      accountType: ['', Validators.required],
      typeOfPractitioner: [[]]
    });  

    this.showLoadingAccounts = true;
    
    
  }

  async getUsers(){
   const adminIdToken = await firebase.auth().currentUser.getIdToken();
   let response:any = await this.authenticationService.getRequest(adminIdToken);
    let authData = response.data.users.filter(o => o.providerData.length != 0);
    let tempUsers = [];
    this.afs.firestore.collection("users").get().then(async profile => {
      let users = profile.docs.map(u => u.data())

      authData.forEach((auth, i) => {
        let userInfo = users.filter(o => o.uid == auth.uid)
        if(userInfo.length == 0){
          console.warn('No user info', auth)
        }
        tempUsers.push(Object.assign(auth, userInfo[0]))

        if(i == authData.length - 1){
          if(this.profileToEdit){
            this.profileToEdit = Object.assign(this.profileToEdit, tempUsers.filter(u => u.uid == this.profileToEdit.uid)[0]);
            if(this.profileToEdit?.verifiedBy_Uid != undefined){
              this.verifiedEmail = this.allUsers.length > 0 ? this.allUsers.filter(o => o.uid == this.profileToEdit.verifiedBy_Uid)[0].email : ''
            }
          }
          this.allUsers = this.sortData(tempUsers)
          this.allUserAccounts = this.allUsers;
          this.filterAccounts(this.term)
          
          this.showLoadingAccounts = false;

          
          // console.warn('Microsoft 2023', this.allUsers.filter(o => o.providerData[0].providerId == 'microsoft.com' && o.metadata.creationTime.includes('2023')).length)
          // console.warn('Google 2023',this.allUsers.filter(o => o.providerData[0].providerId == 'google.com' && o.metadata.creationTime.includes('2023')).length)
          // console.warn('Email y Password 2023',this.allUsers.filter(o => o.providerData[0].providerId == 'password' && o.metadata.creationTime.includes('2023')).length)
          //   console.warn('Not google, email or microsoft 2023', this.allUsers.filter(o => o.providerData[0].providerId != 'password' && o.metadata.creationTime.includes('2023') && o.providerData[0].providerId != 'microsoft.com' && o.providerData[0].providerId != 'google.com'))
            console.warn('All Registers Since May 23, 2024', response.data.users.filter(o => new Date(o.metadata.creationTime) >= new Date('23 May 2024 18:00') && new Date(o.metadata.creationTime) < new Date('19 Jun 2024 18:00')))
            console.warn('Completed Registers Since May 23, 2024', this.allUsers.filter(o => new Date(o.metadata.creationTime) >= new Date('23 May 2024 18:00') && new Date(o.metadata.creationTime) < new Date('19 Jun 2024 18:00')))
            // console.warn('Register 2024', response.data.users.filter(o => new Date(o.metadata.creationTime) >= new Date('23 May 2024 18:00')))
            console.warn('All Registers Since Jun 19, 2024', response.data.users.filter(o => new Date(o.metadata.creationTime) >= new Date('19 Jun 2024 18:00')))
            console.warn('Completed Registers Since Jun 19, 2024', this.allUsers.filter(o => new Date(o.metadata.creationTime) >= new Date('19 Jun 2024 18:00')))
          tempUsers = []

        }
      })
    });
  }

  sortData(user) {
    
    for(let i = 0; i < user.length; i++){
      if(user[i].status == "Verified"){
        user[i].verified = 1
      }
      else {
        user[i].verified = 0        
      }
    }
    let data = user.sort((a, b) => {
      return a.verified - b.verified;
      // return new Date(b.metadata.creationTime).getTime() - new Date(a.metadata.creationTime).getTime() //sorting for Date
    }); 
    let data1 = data.sort((a) => {
      if(a.verified == 1) return 1
      if(a?.identification?.startsWith('../assets')) return 0;
      if(!a?.identification?.startsWith('../assets')) return -1;
      // return new Date(b.metadata.creationTime).getTime() - new Date(a.metadata.creationTime).getTime() //sorting for Date
    });
    return data1
  }


  verifiedEmail
  openModal(bool:any, user?:any,) {
    this.createAccount = bool;
    if(this.createAccount == false){
        this.profileToEdit = this.allUsers ? Object.assign(user, this.allUsers.filter(u => u.uid == user.uid)[0]) : user;
        this.afs.collection('users').doc(this.profileToEdit.uid).valueChanges().subscribe(async res => {
          this.profileToEdit = this.allUsers ? Object.assign(user, this.allUsers.filter(u => u.uid == user.uid)[0]) : user;
          this.profileToEdit.verifiedBy_Uid = res['verifiedBy_Uid']
          this.profileToEdit.verifiedDate = res['verifiedDate']
          this.afs.collection('Documents', ref => ref.where('referenceID', '==', this.profileToEdit.uid)).valueChanges().subscribe(async (docs: any) => {
            this.myPdfs = []
            for(let i = 0; i < this.pdfsLabel.length; i++){
              this.myPdfs[i]= docs.filter(doc => doc.description == this.pdfsLabel[i]) || []
            }
          })

          if(this.profileToEdit.accountType == 'practitioner'){
            this.authenticationService.getNPIRegistry(this.profileToEdit.uid).then((profile:any) => {
              this.practitionerNPI = profile;
            })
          }

          if(this.profileToEdit?.verifiedBy_Uid != undefined){
            this.verifiedEmail = this.allUsers.length > 0 ? await this.allUsers.filter(o => o.uid == this.profileToEdit.verifiedBy_Uid)[0].email : ''
          }
          else {
            this.verifiedEmail = ''
          }
        })
        this.patientInfo = true;
      // this.modalService.open(largeDataModal, { size: 'xl', backdrop: 'static', centered: true });
    }
    else{
      this.patientInfo = true;
      // this.modalService.open(largeDataModal, { size: 'lg', backdrop: 'static', centered: true  });
    }
  }

  pdfModal
  showPDF(pdfTag){
    this.pdfModal = this.modalService.open(pdfTag, { size: 'lg', backdrop: 'static', centered: true  });
  }

  closePdf(){
    this.pdfModal.dismiss()
  }

  updateUser(profile: any){
      var updatedData = {
        accountType: profile.accountType ? profile.accountType: '',
        firstName: profile.firstName ? profile.firstName : '',
        middleName: profile.middleName ? profile.middleName : '',
        lastName: profile.lastName ? profile.lastName : '',
        isAccountDisabled: profile.isAccountDisabled
      }


      this.afs.collection('users').doc(`${profile.uid}`).update(updatedData).then(()=>{
        this.isUpdated = true;
        setTimeout(() => this.isUpdated = false, 2000);
        setTimeout(()=> this.modalService.dismissAll(), 4000);
      });
  }

  showAlertMsg(user: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn swalBtnCustom',
        cancelButton: 'btn btn-danger ml-2'
      },
      buttonsStyling: false
    });

    this.translate.get('ACCOUNTS.SWAL').subscribe((res: any) => {
      this.title = res.DELETEACCOUNT
      this.text = res.CONFIRM
      this.cancel = res.CANCEL
      this.success = res.SENT
      this.canceled = res.ERROR
  })

    swalWithBootstrapButtons
      .fire({
        title: this.title,
        //text: 'You won\'t be able to revert this action.',
        icon: 'warning',
        confirmButtonText: this.text,
        cancelButtonText: this.cancel,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.sendResetPassword(user);
          swalWithBootstrapButtons.fire(
            this.success,
            '',
            'success'
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) 
        {
          swalWithBootstrapButtons.fire(
            this.canceled,
            '',
            'error'
          );
        }
      });
  }

  sendResetPassword(user: any){
    if (environment.defaultauth === 'firebase') {
      this.authenticationService.resetPassword(user.email)
        .catch(error => {
          this.error = error ? error : '';
        });
    }
  }

  openChangeEmailModal(content: any, p) {
    this.modalService.open(content,  { centered: true });
  }

  get type() {
    return this.typeValidationForm.controls;
  }

  emailSubmit(value, userUID) {
    this.submit = true;

    // stop here if form is invalid
    if (this.typeValidationForm.invalid) {
      return;
    }



    if(value.email === value.confirmemail){

      var updatedData = {
        email: value.email ? value.email : '',
      }
  
  
      this.afs.collection('users').doc(`${userUID}`).update(updatedData).then(()=>{
  
        this.isEmailUpdated = true;
  
        setTimeout(() => this.isEmailUpdated = false, 2000);
  
        setTimeout(()=> this.modalService.dismissAll(), 4000);
  
      });
      
    }
  }


  deleteUser(profile){
    this.translate.get('ACCOUNTS.SWAL').subscribe((res: any) => {
      this.title = res.TITLE
      this.text = res.TEXT
      this.cancel = res.CANCEL
  })
    this.delete = true;
    if(this.delete == true){
      Swal.fire({
        title: this.title,
        text: this.text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#ff3d60',
        cancelButtonText: this.cancel,
        confirmButtonText: this.title
      }).then(result => {
        if(result.isConfirmed){
          let user = this.profileToEdit.uid;
          this.firebaseService.deleteUser(user);
          this.allUsers = this.allUsers.filter(o => o.uid !== user)
          this.allUserAccounts = this.allUsers;
          this.modalService.dismissAll();
        }
        else if(result.isDismissed){
          this.delete = false;
        }
      })
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.createUserForm.controls; }


  async onSubmit() {
    this.submitted = true;
    const generatePassword = (
      passwordLength = 12,
      passwordChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    ) => [...window.crypto.getRandomValues(new Uint32Array(passwordLength))]
        .map(x => passwordChars[x % passwordChars.length])
        .join('');
    // stop here if form is invalid
    if (this.createUserForm.invalid || !this.f.email.value.includes('@dzeus.com')) {
      return;
    } else {
      if (environment.defaultauth === 'firebase') {
        this.loading = true;
        if(this.f.accountType.value != 'practitioner'){
          this.f.typeOfPractitioner.patchValue([])
        }
        await this.dataService.createUser(this.f.email.value, generatePassword(), this.f.accountType.value, this.f.typeOfPractitioner.value).then(res => {
          this.getResponse()
        });
        // this.sendResetPassword(this.f.email.value);
      }
    }
  }

  storageRemoval(user){
    this.firebaseService.deleteStorage(user.uid);
  }

  async getResponse(){
    this.userCreatedRes = await this.dataService.handleResponse();
    this.error = this.userCreatedRes.message;
    if(this.userCreatedRes.uid !== undefined){
      
      setTimeout(() => 
        this.updateUserAccount.updateUserCollection({trigger: 'handlePractitionerCreation', uid: this.userCreatedRes.uid}),
      5000);

      this.translate.get('ACCOUNTS.SWAL').subscribe((res: any) => {
        this.title = res.ACCOUNTCREATED
        this.text = res.ACCOUNTCREATEDTEXT
         this.cancel = res.CLOSE
      })

      this.loading = false;
      Swal.fire({
        title: this.title,
        text: this.text + `${this.userCreatedRes.email}` ,
        showCancelButton: false,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#ff3d60',
        confirmButtonText: this.cancel
      }).then(result => {
        if(result.isConfirmed){
          result.dismiss;
          this.allUsers = [];
          this.allUserAccounts = [];
          this.getUsers();
          this.modalService.dismissAll();
        }
       })
    }
  }


  accountStatus(status){
    let adminUid: any = this.authenticationService.currentUser()
    if(adminUid.email == null){
      adminUid = firebase.auth().currentUser;
    }
    this.verifyForm.patchValue({
      status: status,
      verifiedBy_Uid: adminUid.uid,
      verifiedDate: new Date()
    });

    const data = this.verifyForm.value;
    this.firebaseService.updateProfile(data, this.profileToEdit.uid).then(
      res => {
        this.profileToEdit.status = data.status;
        this.profileToEdit.verifiedBy_Uid = data.verifiedBy_Uid;
        // this.profileToEdit.verifiedDate = data.verifiedDate;
      },
      err => {
      }
    );
  }

  disable(isAccountDisabled){
    let adminUid: any = this.authenticationService.currentUser()
    if(adminUid.email == null){
      adminUid = firebase.auth().currentUser;
    }
    let action = {
      isAccountDisabled: isAccountDisabled,
      verifiedBy_Uid: adminUid.uid,
      verifiedDate: new Date()
    };

    this.firebaseService.updateProfile(action, this.profileToEdit.uid).then(
      res => {
        this.profileToEdit.isAccountDisabled = action.isAccountDisabled;
        this.profileToEdit.verifiedBy_Uid = action.verifiedBy_Uid;
        this.profileToEdit.disabled = action.isAccountDisabled
        // this.profileToEdit.verifiedDate = data.verifiedDate;
        this.updateUserAccount.updateUserCollection({trigger: 'handleUserEnableDisable', uid: this.profileToEdit.uid})
      },
      err => {
      }
    );
  }

  customClaimGroups = [];
  collections;
  group = '';
  claims = [];
  getGroups(){ 
    this.afs.collection('groups').valueChanges().subscribe((res: any) => {
      this.customClaimGroups = res.filter(o => o.id != 'collectionList')
      this.collections = res.filter(o => o.id == 'collectionList')[0].collections
      if(this.group == ''){
        this.group = this.customClaimGroups[0].id
      }
      this.claims = this.customClaimGroups[0].claims || {}
    })
  }

  addClaim(tag, claim){
    if(tag.target.localName == 'input'){
      this.claims[claim] ?  delete this.claims[claim] : this.claims[claim] = true
    }
  }

  getClaim(claim){
    return this.claims[claim]
  }

  saveGroupClaims(){
    this.dataService.assignRoles(this.customClaimGroups.filter(o => o.id == this.group)[0].users, Object.assign(this.claims, {accountType: 'admin'})).then(res => {
      this.afs.collection('groups').doc(this.group).update({claims: this.claims})
    })
  }

  addClaimsForExistingUsers(){
    this.dataService.assignRolesToExistingUsers()
  }

  getUsersWithoutClaims(){
    let users = this.allUsers.filter(o => o.customClaims == undefined)
    // users.forEach(user => {
    //   this.dataService.assignRoles([user.uid], {accountType: user.accountType}).then(res => {
    //   })
    // })
  }

  openModals(modal){
    this.modalService.open(modal, {size: 'lg', backdrop: 'static', centered: true, keyboard: false})
  }

  pdfName
  async pdfSelected(fileInput: any){
    this.loading = true
    ReloadComponent.loader = 'RELOAD.SAVING'

    if (fileInput.target.files && fileInput.target.files[0]) {
      this.pdfName = fileInput.target.files[0].name;
      const reader = new FileReader();
      reader.onload = (e: any) => {
		    let blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
        let url = window.URL.createObjectURL(blob);
        this.pdfUpload(blob);
      };
      reader.readAsDataURL(fileInput.target.files[0]); 
    }
  }

  pdfUpload(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadPDFToFirebase(reader.result);
    };
  }

  async uploadPDFToFirebase(pdf) {
    const randomId = Math.random().toString(36).substr(2, 5);
    this.firebaseService.uploadPdf(pdf, randomId, this.profileToEdit.uid)
      .then(pdfURL => {
        this.uploadDocToFirebase(pdf)
      });
  }
  
  uploadDocToFirebase(pdfUrl){
    let adminUid: any = this.authenticationService.currentUser()

    let Data = {
      id: this.firebaseService.getDate(),
      pdf: pdfUrl.split("base64,")[1],
      pdfName: this.pdfName,
      uploaded: new Date(),
      referenceID: this.profileToEdit.uid,
      referenceIDModel: 'users',
      docFor: 'Practitioner Info',
      description: this.description,
      uidPatient: 'N/A',
      uidPractitioner: this.profileToEdit.uid,
      valid: false,
      expDate: this.expDate,
      addedBy: adminUid.uid
    }

    this.afs.collection('Documents').doc(Data.id).set(Data);
    this.loading = false;
  }


  getLabsDocuments(){
    this.labsService.getLabDocuments(this.profileToEdit.uid).subscribe(labs => {
      this.labs = labs
    });
  }


  addLabs(file: any){
    this.showLabs = true;
    this.labDocument = file;
  }



  createExternalPatient(){
    this.modalService.open(this.createExternalPatientModal, { size: 'md', backdrop: 'static', centered: true  });
  }

  practitionerNPI;
  searchRegistry(){
    this.loading = true;
    this.NPIRegistryService.searchRegistry(this.practitionerNPI.NPI, this.profileToEdit.uid).then((res:any) => {
      if(Object.keys(res.message).length !== 0){
        if(res.message.result_count > 0){
          this.loading = false;  
          this.practitionerNPI = res.message;
          // let billing = res.message.addresses[0];
          // let shipping = res.message.addresses[1];
          // this.firebaseService.practitionersAddresses([billing, shipping]).then(() => {
          //   this.modalService.dismissAll()
          // })
        }
      }else{
        this.loading = false;
      }
    });
  }

  filterAccounts(term: string) {
    // console.log("term", term);
    if (!term) {
      this.allUserAccounts = this.allUsers;
    } else {
      this.allUserAccounts = this.allUsers.filter(user => {
        return Object.values(user).some(val => {
          if (typeof val === 'string') {
            return val.toLowerCase().includes(term.toLowerCase());
          }
          return false;
        });
      });
    }
    this.pageAccounts = 1;
  }

  resendVerificationEmail(profile){

    console.log("Resend Verification Email");

    console.log("Profile", profile);

    this.sendingEmailLoading = true;

    this.profileDataService.resendEmail(profile).then(res => {
      console.log("Response", res);

      if(res){
        Swal.fire({
          icon: 'success',
          title: 'Email Sent',
          text: 'Verification email has been sent to the user',
        });

        this.sendingEmailLoading = false;

      }
      else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error sending email',
        });

        this.sendingEmailLoading = false;

      }

    });

  }

}
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { ProfileDataService } from '../../profile/data.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import firebase from "firebase/compat/app";
import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';
import { EmailsService } from 'src/app/core/services/emails.service';
import { v4 as uuid_v4 } from "uuid";
import { DbCollectionTriggersService } from 'src/app/core/services/db-collection-triggers.service';
import { NPIRegistryService } from 'src/app/core/services/npi-registry.service';
// import admin from 'firebase-admin';

@Component({
  selector: 'app-admin-accounts',
  templateUrl: './admin-accounts.component.html',
  styleUrls: ['./admin-accounts.component.scss']
})
export class AdminAccountsComponent implements OnInit, OnDestroy {
  @Input() completion: Boolean;
  @Output() cardId: EventEmitter<any> = new EventEmitter<any>();
  @Input() accountToEdit: string;
  @Input() accountMetadata;
  @Input() allUsers;
  @ViewChild('imageCropper', { read: TemplateRef }) imageCropper:TemplateRef<any>;
  @ViewChild('profileUpdate', { read: TemplateRef }) profileUpdate:TemplateRef<any>;
  
  profileToEdit;
  phoneInProfile;
  phoneMsgInProfile;
  phoneNumber;
  address:any;
  shipping:any = {};
  country:any = [];
  states:any = [];
  preferedLang = ["English", "Español"]

  image:any;
  steps:any = 1;
  defaultProfileImg = '../assets/images/users/profile.png'
  defaultIDImg = '../assets/images/users/IDFront.svg'
  allPaymentMethods = []

  // Form submition
  submit: boolean;

  //loader
  public loading = false;

  adminUid

  title
  text
  cancel
  confirmBtn
  remove 
  removeMsg 
  modalRef: any;

  editProfile: Boolean = false;

  verifyForm: UntypedFormGroup;
  hasConsult = false;
  allSubscriptions = new Subscription()

  //image-cropper
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = true;
  containWithinAspectRatio = false;
  transform: any = {};
  ratio = 1

  verifyFields = []
  profileFields = [
    {field: 'PROFILE.PROFILEPICTURE'},
    {field: 'PROFILE.FIRSTNAME'},
    {field: 'PROFILE.LASTNAME1'},
    {field: 'PROFILE.PHONE'},
    {field: 'PROFILE.IDFRONT'},
    {field: 'PROFILE.IDNUMBER'},
    {field: 'PROFILE.EXPDATE'},
    {field: 'PROFILE.IDTYPE'},
    {field: 'PROFILE.COUNTRY'},
    {field: 'PROFILE.STATE'},
  ]

  constructor(
    public firebaseService: FirebaseService,
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private emailService: EmailsService,
    private dataService: ProfileDataService,
    private updateUserErp: DbCollectionTriggersService,
    private NPIRegistryService: NPIRegistryService
  ) {
    this.adminUid = authenticationService.currentUser()
    if(this.adminUid.email == null){
      this.adminUid = firebase.auth().currentUser;
    }    
    this.adminUid = this.adminUid.uid;
  } 
  ngOnDestroy(): void {
    if(this.profileToEdit.phoneNumberMsg == true && this.profileToEdit.phoneVerified == false && this.codeInfo){
      this.cancelPhoneVerification()
    }

    this.allSubscriptions.unsubscribe()
  }

  ngOnInit(): void {  
    this.getUser();

    this.verifyForm = this.formBuilder.group({
      status: [''],
      verifiedBy_Uid: [''],
      verifiedDate: ['']
    });

    window.onbeforeunload = () => this.ngOnDestroy();
  }


  verifiedEmail
  async getUser(){
    let sub = this.afs.collection('users').doc(`${this.accountToEdit}`).valueChanges().subscribe(async (data: any) =>{
      this.profileToEdit = await data;
      // this.profileToEdit = Object.assign(this.accountMetadata, this.profileToEdit)
      this.accountMetadata = Object.assign(this.accountMetadata, this.profileToEdit)
      console.log(this.profileToEdit, this.accountMetadata)
      if(this.profileToEdit?.verifiedBy_Uid != undefined){
        this.verifiedEmail = this.allUsers.length > 0 ? await this.allUsers.filter(o => o.uid == this.profileToEdit.verifiedBy_Uid)[0].email : ''
      }
      else {
        this.verifiedEmail = ''
      }
     
      this.getCountry();
      if(await this.profileToEdit.idCountryIssued != undefined && await this.profileToEdit.idCountryIssued != ''){
        this.getStates({country: await this.profileToEdit.idCountryIssued})
      }
      this.phoneInProfile = data.phoneNumber
      this.phoneMsgInProfile = data.phoneNumberMsg
      
      this.allSubscriptions.add(sub)

      let sub2 = this.afs.collection('consultation', ref => ref.where('UID_Patient', '==', this.accountToEdit)).valueChanges().subscribe(async consult => {
        if(consult.length > 0){
          this.hasConsult = true
        }
        else {
          this.hasConsult = false
        }
      })
      this.allSubscriptions.add(sub2)

      await this.firebaseService.getUserAddresses(this.profileToEdit)
      let sub1 = this.firebaseService.addressessSubscription.subscribe(data => {
        let address = data.filter((address:any) => address.AddressType === "Shipping" && address.AddressStateCode === this.profileToEdit.currentLocation.code);
        this.shipping = address[0];
      })
      this.allSubscriptions.add(sub1)

      if(this.profileToEdit.accountType == 'practitioner'){
        this.authenticationService.getNPIRegistry(this.profileToEdit.uid).then((profile:any) => {
          this.practitionerNPI = profile;
        })
      }
    });
    this.allSubscriptions.add(sub)

    let sub3 = this.afs.collection('users').doc(this.accountToEdit).collection("wallet").valueChanges().subscribe(data => {  
      
      if(data){
        this.allPaymentMethods = data;
      }
      else {
        this.allPaymentMethods = []
      }   
      this.allSubscriptions.add(sub3)
    });

    // this.shipping = await this.authenticationService.userShippingAddress(this.accountToEdit)[0];
  }

  deleteCard(item: any){
    var id = item.customerNumber.toString();
    var uid = this.profileToEdit.uid.toString();
    this.afs.collection("users").doc(uid).collection("wallet").doc(id).delete()
  }

  deleteCardConfirmation(item: any){
    this.translate.get('PROFILE.SWAL').subscribe((res: any) => {
      this.title = res.TITLE
      this.text = res.TEXT
      this.cancel = res.CANCEL
      this.confirmBtn = res.CONFIRM,
      this.remove = res.REMOVED,
      this.removeMsg = res.REMOVEDMSG
  })

    Swal.fire({
      title: this.title,
      text: this.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#ff3d60',
      confirmButtonText: this.confirmBtn,
      cancelButtonText: this.cancel
    }).then(result => {
      if (result.isConfirmed) {
        this.deleteCard(item);       
        Swal.fire(this.remove, this.removeMsg, 'success');
      }
    })
  }

  addRoles(){
    let user = {
      uid: this.profileToEdit.uid,
      accountType: this.profileToEdit.accountType,
      // devAdmin: true
    }    
    this.dataService.assignRoles(user, false)
    firebase.auth().currentUser.getIdTokenResult().then(t => {
    })
  }

  removeRoles(){
    let user = {
      uid: this.profileToEdit.uid,
      accountType: this.profileToEdit.accountType,
      admin: this.adminUid
    }    
    this.dataService.assignRoles(user, true)
    firebase.auth().currentUser.getIdTokenResult().then(t => {
    })
  }

  disable(isAccountDisabled){
    let adminUid: any = this.authenticationService.currentUser()
    if(adminUid.email == null){
      adminUid = firebase.auth().currentUser;
    }
    let action = {
      isAccountDisabled: isAccountDisabled,
      verifiedBy_Uid: adminUid.uid,
      verifiedDate: new Date()
    };

    this.firebaseService.updateProfile(action, this.profileToEdit.uid).then(
      res => {
        this.profileToEdit.isAccountDisabled = action.isAccountDisabled;
        this.profileToEdit.verifiedBy_Uid = action.verifiedBy_Uid;
        this.accountMetadata.disabled = action.isAccountDisabled
        this.updateUserErp.updateUserCollection({trigger: 'handleUserEnableDisable', uid: this.profileToEdit.uid})
      },
      err => {
      }
    );
  }

  getCountry(){
    let sub = this.afs.collection<any>('settings').doc('Country').collection('Country').valueChanges().subscribe(data => {
      for (let i = 0; i < 1; i++) {
        this.country.push(data[i].name);
        this.country.splice(1,1)
      }   
    });
    this.allSubscriptions.add(sub)
  }

  countryWithStates = true;
  async getStates(country: any){
    let sub = await this.afs.collection("settings").doc('Country').collection('Country').doc(country.country).valueChanges().subscribe(data => {
      if(data.hasStates){
        let sub = this.afs.collection("settings").doc('Country').collection('Country').doc(country.country).collection('States').valueChanges().subscribe(data => {
          this.states = data;
          this.states.sort((a) => {
            if (a.name === 'Puerto Rico') {
              return -1;
            }
            else {
              return 1;
            }
          });
        });
        this.allSubscriptions.add(sub)
        this.countryWithStates = true
      }
      else {
        this.countryWithStates = false
        this.states = []
      }
    });
    this.allSubscriptions.add(sub)
  }
  

    /**
   * Bootsrap validation form submit method
   */
  profileSubmit() {
    this.loading = true;
    this.submit = true;
  
    this.verifyForm.patchValue({
      status: 'Verified',
      verifiedBy_Uid: this.adminUid,
      verifiedDate: new Date()
    }) 
    
    const data = this.profileToEdit;

    if((this.profileToEdit.phoneNumber != this.phoneInProfile || this.profileToEdit.phoneNumberMsg != this.phoneMsgInProfile) && this.profileToEdit.phoneNumberMsg == true){
      this.linkPhone();
    }
    let finalObject = Object.assign(data, this.verifyForm.value)
    
    this.firebaseService.updateProfile(finalObject, this.profileToEdit.uid).then(
      res => {
        if(this.shipping.id == undefined){
          this.shipping.AddressType = "Shipping";
          this.shipping.id = uuid_v4();
          this.firebaseService.createAddress(this.shipping, '', this.profileToEdit.uid);
        }
        else {
          this.firebaseService.updateAddress(this.shipping, this.shipping.id, this.profileToEdit.uid);
        }
        this.updateUserErp.updateUserCollection({trigger: 'handleUserErp', uid: this.profileToEdit.uid})
        this.loading = false;
        
      },
      err => {
        this.loading = false;
        // console.log(err)
      }
    );
  }

  windowRef: any;
  linkPhoneNumber = false;
  manageUserPhone;
  randomGenerator;
  codeInfo;
  msg;
  linkPhone(){
    this.randomGenerator = Math.floor(100000 + Math.random() * 900000); //6 digit code
    let date = new Date(Date.now());
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    let id:any = yyyy + mm + dd + uuid_v4();
    this.codeInfo = {
      code: this.randomGenerator,
      usedCode: false,
      userUid: this.profileToEdit.uid,
      userAccountType: this.profileToEdit.accountType,
      generatedDate: new Date(Date.now()),
      expired: false,
      phoneNumber: this.profileToEdit.phoneNumber,
      id: id
    }

    this.firebaseService.updateProfile({phoneVerified: false}, this.profileToEdit.uid);
    this.afs.collection('phoneVerification').doc(this.codeInfo.id).set(this.codeInfo);
    let sms = this.profileToEdit.preferedLang == 'es' ? `Su codigo de verificacion para Dzeus Health es: ${this.randomGenerator}. Este codigo expira en los proximos 5 minutos.` : `Your Dzeus Health verification code is: ${this.randomGenerator}. This code expires in the next 5 minutes.`
    this.firebaseService.sendsms(this.profileToEdit, sms, true);
    this.linkPhoneNumber = true;
    this.phoneNumber = this.profileToEdit.phoneNumber
  }

  nextVC = ''
  verificationCode = ''
  verifyCode(code){
    this.verificationCode = ''

    if(Number(code.target.value)|| code.code == 'Backspace'){
      for(let i = 1; i <= 6; i++){
        let code = <HTMLInputElement>document.getElementById('code-' + i);
        this.verificationCode = this.verificationCode.concat(code.value)
  
        // if(i == 1 && code.value == ''){
        //   code.focus();
        // }
  
        if(code.value.length > 1 && document.activeElement == code){
          let test = code.value
          let start = code.value.length == 6 ? 1 : i
          for(let j = start, k = 0; k < test.length; j++, k++){
            if(j <= 6){
              let splitcode = <HTMLInputElement>document.getElementById('code-' + j);
              splitcode.value = test.charAt(k)
              this.nextVC = this.verificationCode + 't';
              splitcode.focus() //.blur()
            }
          }
        }
  
        if(document.activeElement == code && code.value == '' && i != 1){
          if(this.nextVC.includes('t')){
            this.nextVC = this.nextVC.replaceAll('t', '')
          }
          else{
            let prevCode = <HTMLInputElement>document.getElementById('code-' + (i - 1))
            prevCode.value = '';
            prevCode.focus();
            break;
          }
        }
  
        if(document.activeElement == code && code.value != ''){
          let nextCode = <HTMLInputElement>document.getElementById('code-' + (i + 1))
          if(nextCode && nextCode.value == ''){
            this.nextVC = this.verificationCode + 't';
            nextCode.focus()
            break;
          }
        }
      }
  
      if(this.verificationCode.length == 6){
        if(this.verificationCode.toString() == this.randomGenerator.toString()){
          this.afs.collection('phoneVerification').doc(this.codeInfo.id).update({expired: true, usedCode: true, usedCodeDate: new Date()})
          this.msg = ''
          this.firebaseService.updateProfile({phoneVerified: true}, firebase.auth().currentUser.uid)
          this.profileToEdit.phoneVerified = true;
          this.translate.get('PROFILE').subscribe((res: any) => {
            this.swal = res
          })
          Swal.fire({
            customClass: {
              confirmButton: 'swalPurple',
            },
            showConfirmButton: true,
            title: this.swal.PHONEVERIFIED,
            icon: 'success',
            confirmButtonText: this.swal.CONTINUE
          }).then(res => {
          })
          this.linkPhoneNumber = false;
        }
        else {
          this.msg = 'SIGNUP.INVALIDCODE'
        }
      }
      else {
        this.msg = 'SIGNUP.INVALIDCODE'
      }
    }
  }

  async resendVerificationCode(){
    let sms = this.profileToEdit.preferedLang == 'es' ? `Su codigo de verificacion para Dzeus Health es: ${this.randomGenerator}. Este codigo expira en los proximos 5 minutos.` : `Your Dzeus Health verification code is: ${this.randomGenerator}. This code expires in the next 5 minutes.`
    this.firebaseService.sendsms(this.profileToEdit, sms, true);
  }

  cancelPhoneVerification() {
    this.afs.collection<any>('users').doc(this.profileToEdit.uid).update({phoneNumberMsg:false, phoneVerified: false}).then(
      res => {
        this.afs.collection('phoneVerification').doc(this.codeInfo.id).update({expired: true})
    })
    this.linkPhoneNumber = false;
  }

  accountStatus(status){
    this.verifyForm.patchValue({
      status: status,
      verifiedBy_Uid: this.adminUid,
      verifiedDate: new Date()
    });

    this.loading = true;
    const data = this.verifyForm.value;
    this.firebaseService.updateProfile(data, this.profileToEdit.uid).then(
      res => {
        this.profileToEdit.status = data.status;
        this.profileToEdit.verifiedBy_Uid = data.verifiedBy_Uid;
        this.loading = false;
      },
      err => {
        this.loading = false;
        // console.log(err)
      }
    );
  }

  disableCheckbox(item, id){
    var element: any = <HTMLInputElement> document.getElementById(id);
  
   if(this.editProfile){
     if (item == '' || item == null || item == undefined || item.length != 10) { 
       element.disabled = true
       if(element.checked)
       {
         element.checked = false;
         element.value = false;
       }
       return true
     }
     if (item != '' || item != null || item != undefined && item.length == 10) {
       element.disabled = false
       return false
     }
   }
   if(this.editProfile == false) {
     element.disabled = true
   }
 }

 selectPhone(event) {
  if (event.target.checked && event.target.id == 'phoneType') {
    this.profileToEdit.phoneNumberMsg = true;
  } 
  else if (!event.target.checked && event.target.id == 'phoneType') {
    this.profileToEdit.phoneNumberMsg = false;
  } 
}

  showModal(modal:any){
    this.modalRef = this.modalService.open(modal,{
      size: 'md',
      centered: true,
      backdrop: 'static'
    })
   }

  dismiss(){
    if(this.modalRef){
      this.modalRef.dismiss();
    }
  }

  invalidSubmit(){
    var element = <HTMLInputElement> document.getElementById("save");

    if(this.profileToEdit.firstName != undefined && this.profileToEdit.firstName != ''
    && this.profileToEdit.lastName1 != undefined && this.profileToEdit.lastName1 != ''
    && this.profileToEdit.biologicalgender != undefined && this.profileToEdit.biologicalgender != ''
    && this.profileToEdit.birthdate != undefined && this.profileToEdit.birthdate != ''
    // && this.profileToEdit.idNumber != undefined && this.profileToEdit.idNumber != ''
    // && this.profileToEdit.idExpDate != undefined && this.profileToEdit.idExpDate != ''
    // && this.profileToEdit.idCountryIssued != undefined && this.profileToEdit.idCountryIssued != ''
    // && this.profileToEdit.idStateIssued != undefined && this.profileToEdit.idStateIssued != ''
    ){
      element.disabled = false
    }
    else {
      element.disabled = true
    }
  }

  swal
  sendRequest(){
    this.translate.get('VIEWCONSULTS.SWAL').subscribe((res: any) => {
      this.swal = res
    })

    Swal.fire({
      customClass: {
        confirmButton: 'swalPurple',
        cancelButton: 'btn-light'
      },
      title: this.swal.TITLE,
      text: this.swal.ADMINREQUEST,
      showCancelButton: true,
      confirmButtonText: this.swal.SENDEMAIL,
      cancelButtonText: this.swal.CANCEL
    }).then(
      async res => {
        if(res.isConfirmed){
          await this.translate.getTranslation(this.profileToEdit.preferedLang).subscribe(async (res: any) => {
            for(let i = 0; i < this.verifyFields.length; i++){
              this.verifyFields[i].field = res.PROFILE[this.verifyFields[i].field.split('.')[1]]
              
              if(i == this.verifyFields.length - 1){
                this.emailService.generalEmails(this.profileToEdit, 'IDRequired_'+ this.profileToEdit.preferedLang, '<en>Verify Account</en><es>Verificar Cuenta</es>', await this.authenticationService.getRemoteConfig(), 'profile', '', '', this.verifyFields).then(res => {
                  this.dismiss()
                  Swal.fire(this.swal.SENT, this.swal.ADMINNOTIFY, 'success');
                })
              }
            }
          })
        }
      }
    );
  }

  imageLoaded = false
  loadingImg
  async cropImage(prevPhoto, type){
    this.modalRef = this.modalService.open(this.imageCropper, {size: 'md', backdrop: 'static', windowClass: 'addAddressModal', centered: true})

    const data = await fetch(prevPhoto);
    const blob = await data.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;   
      
      if(prevPhoto != this[type] && this.profileToEdit.accountType != 'practitioner'){
        this.imageLoaded = true
        this.imageCropped({base64: prevPhoto})
        this.imageChangedEvent = base64data
      }
      else {
        this.imageLoaded = false
        let element = <HTMLInputElement> document.getElementById("imageInput")
        element.click();
      }
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event) {
    this.croppedImage = event.base64;
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  saveImage(){
    ReloadComponent.loader = 'RELOAD.SAVING'

    const decodedData = window.atob(this.croppedImage.split(';base64,')[1]);
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    let test = new Blob([uInt8Array], { type: 'image/png' });

    this.pwaUpload(test)
    this.imageChangedEvent = '';
    this.croppedImage = ''
    this.dismiss()
  }


  pwaUpload(file) {
    this.loadingImg = true;
    ReloadComponent.loader = 'RELOAD.SAVING';

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadImageToFirebase(reader.result);
    };
  }

  profileImage = false;
  profileId = false;
  defaultIDImgBack = '../assets/images/users/IDBack.svg';
  async uploadImageToFirebase(image) {
    const randomId = Math.random().toString(36).substr(2, 5);
    let deleteImageRef = this.profileToEdit.image

    if(!this.editProfile){
      if(this.front){
        deleteImageRef = this.profileToEdit.identification
      }
      // else {
      //   deleteImageRef = this.profileToEdit.identificationBack
      // }
    }

    this.firebaseService.uploadImage(image, randomId, this.profileToEdit.uid, deleteImageRef)
    .then(photoURL => {
      this.image = photoURL;
      this.onSubmit();
    }, err => {
      // console.log(err);
    });
  }

  front = true;
  onSubmit() {
    var data
    if(this.profileId){
      if(this.front == true){
        data = {
          identification: this.image,
          status: 'unverified'
        };
      }
      // else {
      //   data = {
      //     identificationBack: this.image,
      //     status: 'unverified'
      //   };
      // }
      
      let finalObject = Object.assign(this.profileToEdit, data)
      this.firebaseService.updateProfile(finalObject, this.profileToEdit.uid)
      .then(
        res => {
          let update = true
          let sub = this.afs.collection('consultation', ref => ref.where('UID_Patient', '==', this.profileToEdit.uid).where('Status', 'in', ['Open', 'In Progress'])).valueChanges().subscribe(consult => {
            let consults: any = consult
            if(update){
              for(let i = 0; i < consults.length; i++){
                // if(consults[i].Status == 'Open' || consults[i].updateId == true) {
                  let idException = []
                  if(consults[i].exception.includes('payLater')){
                    idException = ['payLater']
                  }
                  if(this.front && consults[i].identification != data.identification){
                    this.afs.collection('consultation').doc(consults[i].ID).update({identification: data.identification, exception: idException})
                  }
                  // else if(!this.front && consults[i].identificationBack != data.identificationBack){                  
                  //   this.afs.collection('consultation').doc(consults[i].ID).update({identificationBack: data.identificationBack, exception: idException})
                  // }
                // }      
                if(i == consults.length - 1){
                  update = false
                }    
              }
            }          
          })
          this.allSubscriptions.add(sub)
          this.loadingImg = false;
          this.profileImage = false;
          this.profileId = false;
        }
      );
    }
    if(this.profileImage){
      const data = {
        image: this.image,
        status: 'unverified'
      };
      this.firebaseService.updateProfile(data, this.profileToEdit.uid)
      .then(
        res => {
          this.loadingImg = false
          this.profileImage = false;
          this.profileId = false;

          let update = true
          let sub = this.afs.collection('consultation', ref => ref.where('UID_Patient', '==', this.profileToEdit.uid).where('Status', 'in', ['Open', 'In Progress'])).valueChanges().subscribe(consult => {
            let consults: any = consult
            if(update){
              for(let i = 0; i < consults.length; i++){
                // if((consults[i].Status == 'Open' || consults[i].updateId == true) && consults[i].image != data.image) {
                if(consults[i].image != data.image) {
                    this.afs.collection('consultation').doc(consults[i].ID).update({image: data.image})
                }
                if(i == consults.length - 1){
                  update = false
                }  
              }
            }          
          })
          this.allSubscriptions.add(sub)
        }
      );
    }
    this.loadingImg = false;
    this.profileImage = false;
    this.profileId = false;
  }

  openModal(){
    this.modalRef = this.modalService.open(this.profileUpdate)
  }

  selectFields(event) {
    if (event.target.checked ) {
      this.verifyFields.push({field: event.target.id});
    } else {
      let index = this.verifyFields.findIndex(x => x.field == event.target.id.toString())
      this.verifyFields.splice(index, 1);
    }
  }

  adminRefreshUserPage(){
    this.afs.collection('users').doc(this.profileToEdit.uid).update({adminRefresh: true});
  }

  practitionerNPI;
  searchRegistry(){
    this.loading = true;
    this.NPIRegistryService.searchRegistry(this.practitionerNPI.NPI, this.profileToEdit.uid).then((res:any) => {
      if(Object.keys(res.message).length !== 0){
        if(res.message.result_count > 0){
          this.loading = false;  
          this.practitionerNPI = res.message;
          // let billing = res.message.addresses[0];
          // let shipping = res.message.addresses[1];
          // this.firebaseService.practitionersAddresses([billing, shipping]).then(() => {
          //   this.modalService.dismissAll()
          // })
        }
      }else{
        this.loading = false;
      }
    });
  }

  showAlertMsg(user: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn swalBtnCustom',
        cancelButton: 'btn btn-danger ml-2'
      },
      buttonsStyling: false
    });

    this.translate.get('ACCOUNTS.SWAL').subscribe((res: any) => {
      this.swal = res
  })

    swalWithBootstrapButtons
      .fire({
        title: this.swal.DELETEACCOUNT,
        //text: 'You won\'t be able to revert this action.',
        icon: 'warning',
        confirmButtonText: this.swal.CONFIRM,
        cancelButtonText: this.swal.CANCEL,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.sendResetPassword(user);
          swalWithBootstrapButtons.fire(
            this.swal.SENT,
            '',
            'success'
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) 
        {
          swalWithBootstrapButtons.fire(
            this.swal.ERROR,
            '',
            'error'
          );
        }
      });
  }

  error
  sendResetPassword(user: any){
    this.authenticationService.resetPassword(user.email)
      .catch(error => {
        this.error = error ? error : '';
      });
  }
}
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import {AngularFirestore} from '@angular/fire/compat/firestore';
import {FirebaseService} from '../../core/services/firebase.service';
import 'firebase/firestore';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserProfiles } from '../../account/auth/signup/data';

import Swal from 'sweetalert2';
import { AuthenticationService } from '../../core/services/auth.service';
import { environment } from '../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient, } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import firebase from "firebase/compat/app";
import { Router } from '@angular/router';
import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';
import { LaboratoriesService } from 'src/app/core/services/laboratories/laboratories.service';
import { NPIRegistryService } from 'src/app/core/services/npi-registry.service';

import { DatePipe } from '@angular/common';
import { query } from 'firebase/firestore';
import { set } from 'zod';

import { PaginationInstance } from 'ngx-pagination';

import { getAuth } from 'firebase/auth';
const auth = getAuth();

import { testUsers } from 'src/environments/environment';

@Component({
  selector: 'app-orders-report',
  templateUrl: './orders-report.component.html',
  styleUrls: ['./orders-report.component.scss']
})
export class OrdersReportComponent {

  pagetitle = 'DRAFT-ORDERS.DRAFT-ORDERS';

  term: any;

  prescriptions: any;

  consultation: any;

  filteredPrescriptions: any;

  allPrescriptionItems: any;

  currentPage = 1;

  page = 1;
  pageSize = 10;
  totalRecords = 0;

  pageOrders = 1;
  pageSizeOrders = 10;
  totalOrderRecords = 0;

  order: any;

  prescriptionDetails: any;

  orders: any;

  filteredOrders: Array<any> = [];

  allOrderItems: any;

  hideAcquireIcon: Boolean = false;

  showLoading: Boolean = false;

  showRxIcon: Boolean = false;

  selectedOrderId: any;

  orderAcquired: Boolean = false;

  currentUser: any;

  currentUserAccountType: any;

  currentUserUid: any;

  consultId: any;

  orderSubscriptionRef: any;

  prescriptionID: any;

  allPrescriptions: any;

  prescriptionItemsDetails: any;

  AddressCity: any;
  AddressCountry: any;
  AddressLine1: any;
  AddressLine2: any;
  AddressState: any;
  AddressType: any;
  AddressZipcode: any;
  pharmacyID: any;
  pharmacyEmail: any;
  pharmacyFax: any;
  pharmacyNCPDPID: any;
  pharmacyNPI: any;
  pharmacyName: any;
  pharmacyPhone: any;
  pharmacyDEA: any;

  swal: any;

  loadingOrders: Boolean = false;

  ordersFound: Boolean = null;

  startDate: any;

  endDate: any;

  testUsers = testUsers;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private afs: AngularFirestore,
    public firebaseService: FirebaseService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    public afAuth: AngularFireAuth,
    private httpClient: HttpClient,
    public translate: TranslateService,
    private router: Router,
    public labsService: LaboratoriesService,
    private NPIRegistryService: NPIRegistryService,
    private datePipe: DatePipe
  ) { 

    // this.loadingOrders = true;

  }

  ngOnInit(): void {

    // this.getOrders();

  }

  filterPrescriptions(term: string) {
    if (!term) {
      this.filteredPrescriptions = this.allPrescriptionItems;
    } else {
      this.filteredPrescriptions = this.allPrescriptionItems.filter(user => {
        return Object.values(user).some(val => {
          if (typeof val === 'string') {
            return val.toLowerCase().includes(term.toLowerCase());
          }
          return false;
        });
      });
    }
    this.page = 1;
  }

  filterOrdersByDate(startDate, endDate){

    // console.log("startDate", startDate);

    // console.log("endDate", endDate);

    this.loadingOrders = true;

    const fetchPromises: any[] = [];

    var startDateTimestamp = `${startDate}T00:00:00Z`;
    var endDateTimestamp = `${endDate}T23:59:59Z`;

    const queryStartDate = new Date(startDateTimestamp);
    const queryStartTimestamp = firebase.firestore.Timestamp.fromDate(queryStartDate);

    const queryEndDate = new Date(endDateTimestamp);
    const queryEndTimestamp = firebase.firestore.Timestamp.fromDate(queryEndDate);

      this.afs.firestore.collection('orders').where('orderState', '==', 'ready').where('paymentStatus', '==', 'pending').where('productType', '==', 'service').where('date', '>=', queryStartTimestamp).where('date', '<=', queryEndTimestamp).orderBy('date', 'asc').get().then((querySnapshot) => {

      this.orders = [];

      const filteredData = querySnapshot.docs.map(doc => doc.data()).filter(order => !this.testUsers.includes(order.email));

      filteredData.forEach((order) => {

        // const data = doc.data();

        const data = order;

        // console.log("data", data);

        this.afs.firestore.collection('consultation').doc(data.consultationID).get().then((docConsultation) => {

          if(docConsultation.exists){

            var consultationData = docConsultation.data();

            if(consultationData){

              if(consultationData.Status === 'Open' && consultationData.currentWizardStep === 'checkout' && consultationData.totalPoints == 0){

                // console.log("subscriptionData", subscriptionData);

                this.afs.firestore.collection('users').doc(data.uid).get().then((docUser) => {

                  if(docUser.exists){
        
                    var userData = docUser.data();
        
                    if(userData){

                      var ordersObj = {
                        acceptaID: data.acceptaID,
                        attempts: data.attempts,
                        automaticPayment: data.automaticPayment,
                        confirmed: data.confirmed,
                        consultationID: data.consultationID,
                        date: data.date,
                        email: data.email,
                        id: data.id,
                        lang: data.lang,
                        orderId: data.orderId,
                        orderLineUpdate: data.orderLineUpdate,
                        orderState: data.orderState,
                        partnerID: data.partnerID,
                        paymentStatus: data.paymentStatus,
                        phone: userData.phoneNumber,
                        processing: data.processing,
                        productType: data.productType,
                        refill: data.refill,
                        subscriptionRef: data.subscriptionRef,
                        syncPromo: data.syncPromo,
                        sync_time: data.sync_time,
                        uid: data.uid,
                        consultationType: consultationData.Type,
                        consultationStatus: consultationData.Status,
                        consultationStep: consultationData.currentWizardStep,
                        patientName: consultationData.patientName,
                        address:data.shippingAddress,
                      };
              
                      this.orders.push(ordersObj);

                    }

                  }

                });

              }

            }

          }

        });

      });

      Promise.all(fetchPromises)
      .then(async() => {

        // console.log("this.orders", this.orders);

        // console.log("this.orders.length", this.orders.length);

        this.loadingOrders = false;
        this.ordersFound = true;

        this.allOrderItems = await this.orders.sort((a, b) => {
          return a.date.seconds - b.date.seconds;
        });

        this.filteredOrders = await this.allOrderItems;

        // console.log("this.filteredOrders", this.filteredOrders);

        this.totalOrderRecords = this.filteredOrders.length;

        // console.log("this.totalOrderRecords", this.totalOrderRecords);

      })
      .catch(error => {

        // console.error('Error fetching user data for all documents:', error);

        this.loadingOrders = false;
        this.ordersFound = false;

        this.swal = Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          title: 'Error',
          text: 'An error has occurred while fetching the data.',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'OK',
          allowOutsideClick: false
        });
  
        this.swal.then((result) => {
          if (result.isConfirmed) {
            this.modalService.dismissAll();
          }
        });

      });

    });

  }

  resetOrdersFilter(){

    this.startDate = null;
    this.endDate = null;

    this.filteredOrders = [];

    this.totalOrderRecords = 0;

    this.term = null;

  }

  getOrders() {

    const fetchPromises: any[] = [];

    const queryDate = new Date('2024-05-01T00:00:00Z');
    const queryTimestamp = firebase.firestore.Timestamp.fromDate(queryDate);

    this.afs.firestore.collection('orders').where('orderState', '==', 'draft').where('productType', '==', 'product').where('refill', '==', true).where('date', '>=', queryTimestamp).orderBy('date', 'asc').get().then((querySnapshot) => {

      this.orders = [];

      querySnapshot.forEach((doc) => {

        const data = doc.data();

        // console.log("data", data);

        if(data.subscriptionRef){

          if(data.subscriptionRef.length > 0){

            this.afs.firestore.collection('pharmacyOrderSchedule').doc(data.subscriptionRef[0]).get().then((docSubscription) => {

              if(docSubscription.exists){

                var subscriptionData = docSubscription.data();

                if(subscriptionData){

                  if(subscriptionData.status === 'active'){

                    // console.log("subscriptionData", subscriptionData);

                    const promise = this.fetchSubscriptionData(data.subscriptionRef[0]).then((subscriptionItem) => {

                      if(subscriptionItem){

                        var ordersObj = {
                          acceptaID: doc.data().acceptaID,
                          attempts: doc.data().attempts,
                          automaticPayment: doc.data().automaticPayment,
                          confirmed: doc.data().confirmed,
                          consultationID: doc.data().consultationID,
                          date: doc.data().date,
                          email: doc.data().email,
                          id: doc.id,
                          lang: doc.data().lang,
                          orderId: doc.data().orderId,
                          orderLineUpdate: doc.data().orderLineUpdate,
                          orderState: doc.data().orderState,
                          partnerID: doc.data().partnerID,
                          paymentStatus: doc.data().paymentStatus,
                          phone: doc.data().phone,
                          processing: doc.data().processing,
                          productType: doc.data().productType,
                          refill: doc.data().refill,
                          subscriptionRef: doc.data().subscriptionRef,
                          syncPromo: doc.data().syncPromo,
                          sync_time: doc.data().sync_time,
                          uid: doc.data().uid,
                          status: subscriptionItem.status,
                        };
                
                        this.orders.push(ordersObj);

                      }

                    })
                    .catch(error => {
                      // console.error('Error fetching user data:', error);
    
                      this.loadingOrders = false;
                      this.ordersFound = false;
      
                      this.swal = Swal.fire({
                        customClass: {
                          confirmButton: 'swalPurple',
                        },
                        title: 'Error',
                        text: 'An error has occurred while fetching the data.',
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                      });
                
                      this.swal.then((result) => {
                        if (result.isConfirmed) {
                          this.modalService.dismissAll();
                        }
                      });
      
                    });

                    fetchPromises.push(promise);

                  }

                }

              }

            });
  
          }

        }

      });

      Promise.all(fetchPromises)
      .then(async() => {

        // console.log("this.orders", this.orders);

        // console.log("this.orders.length", this.orders.length);

        this.loadingOrders = false;
        this.ordersFound = true;

        this.allOrderItems = await this.orders.sort((a, b) => {
          return a.date.seconds - b.date.seconds;
        });

        this.filteredOrders = await this.allOrderItems;

        // console.log("this.filteredOrders", this.filteredOrders);

        this.totalOrderRecords = this.filteredOrders.length;

        // console.log("this.totalOrderRecords", this.totalOrderRecords);

      })
      .catch(error => {

        // console.error('Error fetching user data for all documents:', error);

        this.loadingOrders = false;
        this.ordersFound = false;

        this.swal = Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          title: 'Error',
          text: 'An error has occurred while fetching the data.',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'OK',
          allowOutsideClick: false
        });
  
        this.swal.then((result) => {
          if (result.isConfirmed) {
            this.modalService.dismissAll();
          }
        });

      });

    });

  }

  filterOrders(term: string) {
    if (!term) {
      this.filteredOrders = this.allOrderItems;
    } else {
      this.filteredOrders = this.allOrderItems.filter(user => {
        return Object.values(user).some(val => {
          if (typeof val === 'string') {
            return val.toLowerCase().includes(term.toLowerCase());
          }
          return false;
        });
      });
    }
    this.pageOrders = 1;
  }

  fetchSubscriptionData(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afs.firestore.collection('pharmacyOrderSchedule').doc(id).get()
      .then((subscription) => {
          if (subscription.exists) {
            var subscriptionDataData = subscription.data();
            var obj = {
              status: subscriptionDataData.status,
            };
            resolve({ ...obj });
          } 
          else {
            resolve(null);
          }
      })
      .catch(reject);
    });
  }

}

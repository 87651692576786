import { Component, Input, OnInit } from '@angular/core';

import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading'; 
import { AuthenticationService } from 'src/app/core/services/auth.service';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';

@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.scss']
})
export class ReloadComponent implements OnInit {
  @Input() message;
  @Input() loader = ReloadComponent.loader;
  settings 

  loading = true;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };
  static loader: any;

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.authService.getRemoteConfig().then(async ref => {
      this.settings = ref;
    });
    if(this.message != undefined){
      this.loader = this.message;
    }
  }
}

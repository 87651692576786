<div class="d-flex flex-column customHeight" [ngClass]="{'justify-content-between': styling == 'justify-content-between', 'justify-content-start': styling == 'justify-content-start', 'background-cyan': background == 'cyan'}">
  <div class="form-group" style="width: 90%; margin: 0 5% !important;" >
    <i (click)="back.emit(true)" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
  </div>
  <form [formGroup]="signupForm" class="main d-flex flex-column align-items-center">
    <h2>{{'PROFILE.QUESTION4' | translate}}</h2>
    
    <label for="phoneNumber" class="inp">
      <input type="tel" mask="(000) 000-0000" (input)="checkPhoneLength()" maxlength="14"  name="phoneNumber" id="phoneNumber" placeholder="&nbsp;"  formControlName="phoneNumber" (blur)="save($event)">
      <span class="label">{{'SIGNUP.PHONENUMBER' | translate}}</span>
      <span class="focus-bg"></span> 
    </label>

    <div class="row m-auto" style="width: fit-content; align-items: flex-end;">
      <label hidden for="switch" class="switch mt-3">
        <input id="switch" type="checkbox" (input)="selectPhone($event); save($event);" name="phoneNumberMsg" formControlName="phoneNumberMsg" [attr.disabled]="disableCheckbox(profile.phoneNumber, 'switch')">
        <span class="slider round"></span>
      </label>
      <span hidden class="ml-2 mb-0">{{'PROFILE.MSG' | translate}}</span>
    </div>

    <div *ngIf="linkPhoneNumber == true" class="row d-flex flex-column justify-content-center mt-2">
      <app-phone-verification [background]="background" [phoneNumber]="phoneNumber" [profile]="phoneProfile" (linkPhoneNumber)="linkPhoneNumber = false;"></app-phone-verification>
    </div> 

  </form>
  <div class="row justify-content-center text-center mt-5 flex-column align-items-center btn-group w-100" style="margin: 0 auto;">
    <button type="button" class="btn btn-info nextbtn text-center mb-3" id="nextBtn" (click)="continueEmitter.emit(true)" [disabled]="formSteps()">{{'PROFILE.NEXT' | translate}}</button>
  </div>
</div>
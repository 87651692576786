<div class="main">
  <h2 class="title">Document Managment</h2>

  <div class="folders">

    <div class="folder">
      <div class="icon">
        <svg width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2"></path>
        </svg>
      </div>
      <h5>Legal Documents</h5>
      <span>8 files</span>
    </div>

    <div class="folder">
      <div class="icon">
        <svg width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2"></path>
        </svg>
      </div>
      <h5>Prescriptions</h5>
      <span>30 files</span>
    </div>

    <div class="folder">
      <div class="icon">
        <svg width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2"></path>
        </svg>
      </div>
      <h5>Dzeus Documentation</h5>
      <span>5 files</span>
    </div>
  </div>

  <h3 class="title">Recent Uploads</h3>

  <!-- <div class="d-flex w-100 justify-content-even">
    <span class="w-100"><b>Name</b></span>
    <span class="w-100"><b>Date</b></span>
    <span class="w-100"><b>Folder</b></span>
  </div> -->
  <!-- <table class="table">
    <thead>
      <tr>
        <th><b>Name</b></th>
        <th><b>Date</b></th>
        <th><b>Folder</b></th>
      </tr>
    </thead>
  </table>

  <div class="file">
    <div class="">
      <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
        <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2Z"></path>
        <path d="M9 7h1"></path>
        <path d="M9 13h6"></path>
        <path d="M13 17h2"></path>
      </svg>
      <span>Registro de Educación Médica.pdf</span>
    </div>
    <span>Dec 13, 2023</span>
    <span>Legal Documents</span>
  </div>
  <div class="file">
    <div class="">
      <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
        <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2Z"></path>
        <path d="M9 7h1"></path>
        <path d="M9 13h6"></path>
        <path d="M13 17h2"></path>
      </svg>
      <span>Licencia.pdf</span>
    </div>
    <span>Dec 13, 2023</span>
    <span>Legal Documents</span>
  </div>
  <div class="file">
    <div class="">
      <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
        <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2Z"></path>
        <path d="M9 7h1"></path>
        <path d="M9 13h6"></path>
        <path d="M13 17h2"></path>
      </svg>
      <span>Licencia.pdf</span>
    </div>
    <span style="width: 33%;">Dec 13, 2023</span>
    <span style="width: 33%;">Legal Documents</span>
  </div> -->

  <div class="table-container">
    <table class="table">
      <thead>
        <tr>
          <th><b>Name</b></th>
          <th><b>Date</b></th>
          <th><b>Folder</b></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
              <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2Z"></path>
              <path d="M9 7h1"></path>
              <path d="M9 13h6"></path>
              <path d="M13 17h2"></path>
            </svg>
            Registro de Educación Médica.pdf
          </td>
          <td>Dec 13, 2023</td>
          <td>Legal Documents ></td>
        </tr>
        <tr>
          <td>
            <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
              <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2Z"></path>
              <path d="M9 7h1"></path>
              <path d="M9 13h6"></path>
              <path d="M13 17h2"></path>
            </svg>
            Diploma.pdf
          </td>
          <td>Dec 13, 2023</td>
          <td>Legal Documents ></td>
        </tr>
        <tr>
          <td>
            <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
              <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2Z"></path>
              <path d="M9 7h1"></path>
              <path d="M9 13h6"></path>
              <path d="M13 17h2"></path>
            </svg>
            Licencia.pdf
          </td>
          <td>Dec 13, 2023</td>
          <td>Legal Documents ></td>
        </tr>
        <tr>
          <td>
            <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
              <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2Z"></path>
              <path d="M9 7h1"></path>
              <path d="M9 13h6"></path>
              <path d="M13 17h2"></path>
            </svg>
            Certificación de Telemedicina.pdf
          </td>
          <td>Dec 13, 2023</td>
          <td>Legal Documents ></td>
        </tr>
        
        
      </tbody>
    </table>
  </div>

  <!-- <div>
    <video #videoElement width="640" height="480" autoplay></video>
    <div class="overlay"></div>
    <button (click)="captureImage()">Capture</button>
  </div> -->
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'app-family-medical-history',
  templateUrl: './family-medical-history.component.html',
  styleUrls: ['./family-medical-history.component.scss']
})
export class FamilyMedicalHistoryComponent {

  categoryCounter = 0;
  categories = ['Cancer', 'Hepatitis', 'Diabetes']
  deepCategories = [['Breast Cancer', 'Lung Cancer', 'Liver Cancer', 'Colon Cancer', 'Pancreatic Cancer', 'Prostate Cancer', 'Skin Cancer', 'Stomach Cancer', 'Thyroid Cancer', 'Other Cancer'], ['Hepatitis A', 'Hepatitis B', 'Hepatitis C', 'Other Hepatitis'], ['Type 1 Diabetes', 'Type 2 Diabetes', 'Other Diabetes']]

  handleSection(position){
    let coll: any = document.getElementsByClassName("collapsible-content");
    let arrow: any = document.getElementsByClassName("arrowFunction");

    for (let i = 0; i < coll.length; i++) {
      if (coll[i].style.display === "flex") {
        coll[i].style.display = "none";
        arrow[i].classList.remove("fa-chevron-up");
        arrow[i].classList.add("fa-chevron-down");
      } else if(position == i) {
        coll[i].style.display = "flex";
        arrow[i].classList.remove("fa-chevron-down");
        arrow[i].classList.add("fa-chevron-up");
      }
    }
  }

  scrollToTop(){
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
  }
}

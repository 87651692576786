import { UntypedFormControl } from '@angular/forms';

export function AgeValidator(control: UntypedFormControl): { [key: string]: any } | null {
   const invalidFormat:any ={
     message: "You must be 21 years old or older"
   }
   
   const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // Regular expression for 'YYYY-MM-DD' format

    // If control value is empty or does not match the regex, return an error object
    if (control.value && !dateRegex.test(control.value)) {
      return { 
        message: "Invalid date format"
       };
    }
    var date = new Date(control.value);
    var today = new Date();
    var timeDiff = today.getTime() - date.getTime();
    // var age1 = Math.ceil(timeDiff / (1000 * 3600 * 24)) / 365;
    var age1 = Math.floor(timeDiff / (1000 * 3600 * 24)) / 365.25;
    var minAge = 20.999315537303218; // 21 years old
    if (age1 < minAge) {
      return {
        message: "You must be 21 years old or older"
      }   
    }
    else
    {
      return null;     
    }   
  }
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AdminManagementService } from 'src/app/core/services/admin-management/admin-management.service';
@Component({
  selector: 'app-labs-and-diagnostics-editpanel',
  templateUrl: './labs-and-diagnostics-editpanel.component.html',
  styleUrls: ['./labs-and-diagnostics-editpanel.component.scss']
})
export class LabsAndDiagnosticsEditpanelComponent implements OnInit, OnDestroy {

  @Input() lab: any;  
  @Output() dismiss_ = new EventEmitter<boolean>();
  conditions: any = [];

  updated_data: any = {};

  diagnostics: any = [];  
  diagnosticsTemplates: any = [];
  currentDiagnostic: any = [];

  groupByFn = (item) => item.child.state;

  groupValueFn = (_: string, children: any[]) => ({ name: children[0].child.state, total: children.length });

  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
    private adminManagementService: AdminManagementService,
  ) { }


  async ngOnInit() {  
    this.getConditions();
    // this.getDiagnostics();
    this.updated_data = this.lab;
    
    // let t = this.adminManagementService.getDiagnosticsTemplates(this.lab.icd10)
    // this.currentDiagnostic = await this.adminManagementService.getDiagnosticsTemplates(this.lab.icd10);
    let diagnostics:any = await this.adminManagementService.getDiagnosticsTemplates(this.lab.icd10);
    this.currentDiagnostic = diagnostics.map((item: any) => item.code_name);

  }

  getConditions(){
    this.db.collection("consultTemplates").valueChanges().subscribe((data: any) => {
      this.conditions = data;
    });
  }

  searchDiagnostics($event){
    // this.loadingSearch = true;
    this.http.get<any>("https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms="+$event.term).subscribe(response =>{
      for(let i = 0; i < 15; i++){
        this.diagnostics = response[3];
        // this.filteredDiagnostics = response[3];
        // this.loadingSearch = false;
      }

    }, error => {
    });
  }

  setDiagnostic(diagnostic){
    for(let i = 0; i < diagnostic.length; i++){
      // const diagnosticIndex = this.currentDiagnostic.findIndex((selectedDiagnostic) => selectedDiagnostic === `${diagnostic[i][0]} - ${diagnostic[i][1]}`);
      // if(diagnosticIndex === -1){
      //   this.currentDiagnostic.push(`${diagnostic[i][0]} - ${diagnostic[i][1]}`)
      // }
      // else{
      //   this.currentDiagnostic.splice(diagnosticIndex, 1);
      // }
      if(!this.currentDiagnostic.includes(`${diagnostic[i][0]} - ${diagnostic[i][1]}`)){
        this.currentDiagnostic.push(`${diagnostic[i][0]} - ${diagnostic[i][1]}`)
      }
    }
    // this.currentDiagnostic.push(diagnostic[0] + ' - ' + diagnostic[1])
    // const labIndex = this.selectedPresc.findIndex((selectedLab) => selectedLab.labName === lab.labName);
    
    // if (labIndex === -1) {
    //   // Lab not included, so add it
    //   this.selectedPresc.push({ labName: lab.labName, labCode: lab.labCode, id: Math.random().toString(36).substr(2, 9) });
    // } else {
    //   // Lab already included, so remove it
    //   this.selectedPresc.splice(labIndex, 1);
    // }
    // this.labs = this.selectedPresc
  }


  removeDiagnostic(diagnostic) {
    const diagnosticIndex = this.currentDiagnostic.findIndex((selectedDiagnostic) => selectedDiagnostic === `${diagnostic.value[0]} - ${diagnostic.value[1]}`);
    this.currentDiagnostic.splice(diagnosticIndex, 1);
  }

  




  setChecked(condition){
    let index = this.lab.condition.indexOf(condition);
    if(index > -1){
      this.lab.condition.splice(index, 1);
    }else{
      this.lab.condition.push(condition);
    }
  }


  saveDiagnosticTemplates(){
    //! ajustar en el tab de diagnostics para que guarde la data igual que aquí
    const templates = this.currentDiagnostic.map((item: any) => { 
      return {
        // code: item.split(' - ')[0],
        id: item,
        code_name: item,
        code: item.split(' - ')[0],
        name: item.split(' - ')[1],
        assestment: "",
        recommendation: "",
      }
    });
    this.adminManagementService.storeDiagnosticTemplate(templates);
    
  }

  saveICD10(){  
    const codes = this.currentDiagnostic.map((item: any) =>  item.split(' - ')[0]);
    this.adminManagementService.storeICD10(this.lab, codes);
  }


  saveAndClose(){
    this.saveDiagnosticTemplates();
    this.saveICD10();
    this.dismiss_.emit(true)
  }






  dismiss(){
    this.dismiss_.emit(true)
  }

  ngOnDestroy(): void { 

  }

}

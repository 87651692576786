<div class="panel m-2">
  <div class="w-100 p-2 d-flex justify-content-end topbar">
    <div class="loading"> 
      <svg width="16px" height="12px">
        <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
        <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
      </svg>
    </div>
    <div class="d-flex flex-column w-100">
      <h5 class="mx-auto mt-5 mb-0">{{lab.labName}}</h5>
      <div class="d-flex justify-content-around">
        <div class="">
          <span>Sample Type:</span> 
          <i *ngIf="lab.sample_type == 'Blood'" class="fa fa-tint text-danger m-1" aria-hidden="true"></i>
          <i *ngIf="lab.sample_type == 'Urine'" class="fa fa-flask text-warning m-1" aria-hidden="true"></i>
          <span>{{lab.sample_type}}</span>
        </div>
        <div class="">
          <span>Test Type:</span> 
          <span>{{lab.test_type}}</span>
        </div>
      </div>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- <div class="mt-5">
    <div class="d-flex">
      <img class="w-25 rounded-circle" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/users%2FTHSmUV56a6eKLUdh590FuxmQn9X2%2F7rivo?alt=media&token=9e0cad33-505d-4811-8717-a7f15769be03" alt="">
      <div class="d-flex flex-column">
        <span>Aramis Martinez</span>
        <span>Nov 16th, 2023</span>
      </div>
    </div>
    <hr>
  </div> -->

  <div class="m-3">
    <div class="section">
      <span>Lab details</span>
      <i class="fa fa-caret-down" aria-hidden="true"></i>
    </div>
    <div class="mt-3 d-flex justify-content-between">

      <div class="flex-column">
        <label for="labName">CPT Code</label>
        <div class="input">
          <div class="text">
            <input id="labName" type="text" placeholder="CPT Code" [(ngModel)]="updated_data.labCode"/>
          </div>
        </div>
      </div>
  
      <!-- <div class="flex-column">
        <label for="labName">ICD-10 Code</label>
        <div class="input">
          <div class="text">
            <input id="labName" type="text" placeholder="ICD-10 Code"  [(ngModel)]="updated_data.icd10"/>
          </div>
        </div>
      </div> -->
    </div>

    <!-- <div class="flex-column">
      <label for="labName">ICD-10 Code</label>
      <div class="input w-100">
        <div class="text">
          <input id="labName" type="text" placeholder="ICD-10 Code"  [(ngModel)]="updated_data.icd10"/>
        </div>
      </div>
    </div> -->

    <span class="mt-3">ICD-10 Code</span>
    <!-- <ng-select id="diagnostic" placeholder="{{'VIEWCONSULTS.DIAGNOSTICS.SEARCH' | translate}}" [multiple]="true" [clearable]="false" (search)='searchDiagnostics($event)' (change)="setDiagnostic($event)"> -->
    <ng-select id="diagnostic" placeholder="{{'VIEWCONSULTS.DIAGNOSTICS.SEARCH' | translate}}" [multiple]="true" [clearable]="false" (search)='searchDiagnostics($event)' (change)="setDiagnostic($event)" (remove)="removeDiagnostic($event)" [ngModel]="currentDiagnostic">
      <ng-option *ngFor="let template of diagnostics" [value]="template"> <span *ngIf="template.length > 0">{{ template[0] }} -  {{ template[1] }} </span></ng-option>
    </ng-select>

      <!-- <input type="checkbox" name="radio-card" id="{{lab.id}}"  [checked]="setChecked(lab)"/> -->
      <!-- <input id="{{lab.id}}" style="vertical-align: middle;" type="checkbox" name="radio-card" class="inp-cbx" (click)="handleLabSelection(lab)" /> -->
      <!-- <div class="mt-4">
        <span class="">Medical Conditions</span>
        <div class="checkboxes">
          <div style="width: fit-content;" *ngFor="let condition of conditions; let i = index">
            <input id="{{i}}" style="vertical-align: middle;" type="checkbox" name="radio-card" class="inp-cbx"/>
            <label for="{{i}}" class="cbx">
              <span>
                <svg width="12px" height="10px">
                  <use xlink:href="#check"></use>
                </svg>
              </span>
              <span class="ml-2">{{condition.serviceType}}</span>
            </label>
            <svg style="display:none;" class="inline-svg">
              <symbol id="check" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
              </symbol>
            </svg>
          </div>
        </div>
      </div> -->


      <!-- <div class="section">
        <span>Last updated by</span>
      </div> -->

      

    
    <!-- <label for="labName">Lab Name</label>
    <div class="input">
      <div class="text">
        <input id="labName" type="text" placeholder="Lab Name" />
      </div>
    </div> -->
    <div class="section mt-4">
      <span>General information</span>
      <i class="fa fa-caret-down" aria-hidden="true"></i>
    </div>

    <!-- <div class="flex-column mt-3">
      <label for="labName">Sample type</label>
      <div class="input">
        <div class="text">
          <input id="labName" type="text" placeholder="Blood, Urine..." [(ngModel)]="updated_data.sample_type"/>
        </div>
      </div>
    </div> -->
    <div class="row align-items-center how-it-works d-flex">
      <div class="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
        <div class="circle font-weight-bold">
          <svg width="15" height="15" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="m16 4 3 3L6.65 19.35a2.121 2.121 0 0 1-3-3L16 4"></path>
            <path d="M10 10h6"></path>
            <path d="m19 15 1.5 1.6a2 2 0 1 1-3 0L19 15"></path>
          </svg>
        </div>
      </div>
      <div class="col-6">
        <h5 class="text-left">What's Tested</h5>
        <div class="text-left" [innerHTML]="lab.testing_information"></div>
      </div>
    </div>
    <div class="row timeline">
      <div class="col-2">
        <div class="corner top-right"></div>
      </div>
      <div class="col-8">
        <hr/>
      </div>
      <div class="col-2">
        <div class="corner left-bottom"></div>
      </div>
    </div>
    <!--second section-->
    <div class="row align-items-center justify-content-end how-it-works d-flex">
      <div class="col-6 text-right">
        <h5>Sign and Symptoms</h5>
        <div class="text-left" [innerHTML]="lab.signs_and_symptoms"></div>
      </div>
      <div class="col-2 text-center full d-inline-flex justify-content-center align-items-center">
        <div class="circle font-weight-bold">
          <svg width="15" height="15" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 8c0-.796.369-1.559 1.025-2.121C9.681 5.316 10.572 5 11.5 5h1c.928 0 1.819.316 2.475.879C15.63 6.44 16 7.204 16 8a3 3 0 0 1-2 3c-.614.288-1.14.833-1.501 1.555A5.04 5.04 0 0 0 12 15"></path>
            <path d="M12 19v.01"></path>
          </svg>
        </div>
      </div>
    </div>
    <!--path between 2-3-->
    <div class="row timeline">
      <div class="col-2">
        <div class="corner right-bottom"></div>
      </div>
      <div class="col-8">
        <hr/>
      </div>
      <div class="col-2">
        <div class="corner top-left"></div>
      </div>
    </div>

    <!--third section-->
    <div class="row align-items-center how-it-works d-flex">
      <div class="col-2 text-center top d-inline-flex justify-content-center align-items-center">
        <div class="circle font-weight-bold">
          <svg width="15" height="15" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.5 5.5 5 7l2.5-2.5"></path>
            <path d="M3.5 11.5 5 13l2.5-2.5"></path>
            <path d="M3.5 17.5 5 19l2.5-2.5"></path>
            <path d="M11 6h9"></path>
            <path d="M11 12h9"></path>
            <path d="M11 18h9"></path>
          </svg>
        </div>
      </div>
      <div class="col-6">
        <h5 class="text-left">Preparing for the test</h5>
        <div class="text-left" [innerHTML]="lab.preparation"></div>
      </div>
    </div>

    <div class="row timeline">
      <div class="col-2">
        <div class="corner top-right"></div>
      </div>
      <div class="col-8">
        <hr/>
      </div>
      <div class="col-2">
        <div class="corner left-bottom"></div>
      </div>
    </div>
    <div class="row align-items-center justify-content-end how-it-works d-flex">
      <div class="col-6 text-right">
        <h5 >After the test</h5>
        <div class="text-left" [innerHTML]="lab.after_test"></div>
      </div>
      <div class="col-2 text-center full d-inline-flex justify-content-center align-items-center">
        <div class="circle font-weight-bold">
          <svg width="15" height="15" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9.998a7 7 0 1 1 13 3.6 9.996 9.996 0 0 1-2 2 8 8 0 0 0-2 3 4.5 4.5 0 0 1-6.8 1.4"></path>
            <path d="M10 9.997a3 3 0 1 1 5 2.2"></path>
          </svg>
        </div>
      </div>
    </div>
    <!--path between 2-3-->
    <div class="row timeline">
      <div class="col-2">
        <div class="corner right-bottom"></div>
      </div>
      <div class="col-8">
        <hr/>
      </div>
      <div class="col-2">
        <div class="corner top-left"></div>
      </div>
    </div>
    
    
  </div>


  <div class="action-btns d-flex justify-content-center">
    <button type="button" class="btn btn-primary btn-lg" (click)="saveAndClose()">Save & Close</button>
  </div>

</div>

  
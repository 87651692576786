<div class="mt-2 mx-auto" style="max-width: 450px;">
  <div [hidden]="i != categoryCounter" *ngFor="let category of categories; let i = index">
    <div style="min-height: 550px;">
      <h2 class="mb-0">{{category}}</h2>
      <div *ngFor="let deep of deepCategories[i]; let j = index">
        <div class="mx-2 d-flex flex-row justify-content-between">
          <h4 style="text-align: start;" class="mb-0">{{deep}}</h4>
          <i (click)="handleSection(j)" class="fas fa-chevron-down vertical-align arrowFunction" style="align-self: center;"></i>
        </div>
        <div style="display: none;" class="flex-column collapsible-content">
          <div class="d-flex flex-row justify-content-start align-items-center m-2">
            <label for="acceptTerms" class="container">
              <input type="checkbox" hidden id="acceptTerms" style="position: relative;">
              <span class="checkmark" style="position: relative;"></span>
            </label>                        
            <h5 class="ml-2 mb-0">Yo</h5>
          </div>
          <div class="d-flex flex-row justify-content-start align-items-center m-2">
            <label for="acceptTerms" class="container">
              <input type="checkbox" hidden id="acceptTerms" style="position: relative;">
              <span class="checkmark" style="position: relative;"></span>
            </label>                        
            <h5 class="ml-2 mb-0">Madre Biológica</h5>
          </div>
          <div class="d-flex flex-row justify-content-start align-items-center m-2">
            <label for="acceptTerms" class="container">
              <input type="checkbox" hidden id="acceptTerms" style="position: relative;">
              <span class="checkmark" style="position: relative;"></span>
            </label>                        
            <h5 class="ml-2 mb-0">Padre Biológico</h5>
          </div>
        </div>
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="form-group justify-content-center text-center mt-2 flex-column" style="margin: 0 auto;">
        <button *ngIf="categoryCounter != categories.length - 1" style="border-radius: 0.25rem;" class="btn btn-primary nextbtn text-center mb-2" (click)="scrollToTop(); categoryCounter = categoryCounter + 1">{{'PROFILE.NEXT' | translate | uppercase}}</button>
        <button *ngIf="categoryCounter == categories.length - 1" style="border-radius: 0.25rem;" class="btn btn-primary nextbtn text-center mb-2" (click)="scrollToTop();">{{'ADDRESS.SAVE' | translate | uppercase}}</button>
        <div style="padding-bottom: 20px;">
          <button *ngIf="categoryCounter != 0" style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="scrollToTop(); categoryCounter = categoryCounter - 1">{{'PROFILE.BACK' | translate | uppercase}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex flex-column">
  <div class="d-flex justify-content-end">
    <i [hidden]="disableBtn" class="fas fa-times cursor-pointer" (click)="close()"></i>
  </div>
  <div class="">
    <h2>{{ 'ORDER-CHANGE-PRODUCT.CHANGESHIPPING' | translate }}</h2>
    <div class="content">
      <div *ngIf="productToChange !== undefined" class="d-flex mb-1">
        <div class="d-flex w-100 justify-content-between">
          <div class="d-flex flex-column">
            <h3 class="ml-3 product-name">{{productToChange?.productName}}</h3>
            <div class="d-flex justify-content-between">
              <!-- <span class="ml-3 badge badge-info">{{ 'ORDER-CHANGE-PRODUCT.REFILLAVAILABLE' | translate }}</span> -->
              <span class="ml-3 text-success">{{ productToChange?.productPrice | translation:user?.preferedLang}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h2>{{ 'ORDER-CHANGE-PRODUCT.AVAILABLESHIPPINGS' | translate }}</h2>
    <div class="col-lg-12 all-products">
      <div class="grid-wrapper">
        <label for="{{product.id}}" class="radio-card animate pop" *ngFor="let product of products">
          <input hidden type="radio" name="radio-card animate pop" id="{{product.id}}"  [checked]="setChecked(product)"/>
          <!-- <input hidden type="radio" name="radio-card animate pop" id="{{product.id}}" [(ngModel)]="selection.id"/> -->
          <div class="card-content-wrapper" (click)="handleSelection(product)">
            <span class="check-icon"></span>
            <div class="card-content">
              <img src="{{product.imgRef | translation:user?.preferedLang}}" alt=""/>
              <div class="d-flex flex-column mt-1">
                <h3 class="mx-auto text-success">$ {{product.price | translation:user?.preferedLang}}</h3>
                <!-- <h4 class="mt-2 mx-auto">{{product.name | translation: user.preferedLang}}</h4> -->
                <h4 class="mt-2 mx-auto">{{product.name}}</h4>
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-2">
      <button *ngIf="!disableBtn" id="change-btn" class="btn btn-info" (click)="changeProduct()" [disabled]="disableChange()">{{ 'ORDER-CHANGE-PRODUCT.CHANGE' | translate }}</button>
      <button *ngIf="disableBtn" id="change-btn" class="btn btn-info" (click)="changeProduct()" [disabled]="disableChange()"><span class='spinner spinner-border' style="width: 1rem; height: 1rem"></span>&nbsp;{{ 'RELOAD.DEFAULT' | translate }}</button>
      <button id="change-btn" class="btn btn-info ml-2" (click)="close()" [hidden]="disableBtn">{{ 'ORDER-CHANGE-PRODUCT.CLOSE' | translate }}</button>
    </div>
    <div style="font-size: initial; color: red; display: flex; justify-content: center !important;" *ngIf="disableBtn">{{'ORDER-CHANGE-PRODUCT.WARNING' | translate}}</div>
  </div>
</div>




<div class="main d-flex flex-column customHeight" [ngClass]="{'justify-content-between': styling == 'justify-content-between', 'justify-content-start': styling == 'justify-content-start', 'background-cyan': background == 'cyan'}">
  <div class="form-group" style="width: 90%; margin: 0 5% !important;">
    <i (click)="back.emit()" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
  </div>
  <div>
    <h2>{{'PROFILE.QUESTION3' | translate}}</h2>
  
    <div class="grid-wrapper mt-5">
      <!-- <label for="{{gender}}" class="radio-card" *ngFor="let gender of biological_genders"> -->
      <label for="male" class="radio-card">
        <input type="radio" name="radio-card" id="male" value="Male" (click)="emit($event.target.value)"/>
        <div class="card-content-wrapper">
          <span class="check-icon"></span>
          <span class="w-100 gender text-center">{{'PROFILE.MALE' | translate}}</span>
        </div>
      </label>
      <label for="female" class="radio-card">
        <input type="radio" name="radio-card" id="female" value="Female" (click)="emit($event.target.value)"/>
        <div class="card-content-wrapper">
          <span class="check-icon"></span>
          <span class="w-100 gender text-center">{{'PROFILE.FEMALE' | translate}}</span>
        </div>
      </label>
    </div>
  </div>
  <div></div>
        
  <!-- <label for="biologicalgender" class="inp">
    <select id="biologicalgender" formControlName="biologicalgender" name="biologicalgender" required (change)="emit($event)">
      <option value=""></option>
      <option value="Male">{{'PROFILE.MALE' | translate}}</option>
      <option value="Female">{{'PROFILE.FEMALE' | translate}}</option>
    </select>
    <span class="label">{{'PROFILE.GENDER' | translate}}</span>
    <span class="focus-bg"></span>
  </label> -->
</div>
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BaseProductPrescription, IPharmacySubscription } from './interfaces';
import { v4 as uuidv4 } from 'uuid';
import { co } from '@fullcalendar/core/internal-common';
import { ProductsService } from './products/products.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { remoteConfig } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PharmacyOrderScheduleService {

  settings = remoteConfig

  constructor(
    private db: AngularFirestore,
    private productService: ProductsService,
    private httpClient: HttpClient
  ) { }


  // async generatePharmacyOrderSchedule(order:any, products) {

  //   if(!products) {
  //     throw new Error('No products to generate schedule');
  //   }

  //   if(order.subscriptionRef) {
  //     return "Subscription already exists for this products."
  //   }
    
  //   products.forEach(async product => {

  //     if(await this.hasSubscription(order.uid, product)) {
  //       return
  //     }

  //     this.db.firestore.collection('prescription').doc(product.prescriptionID).collection('Items').get().then(async (snapshot) => {
  //       let productTablet = snapshot.docs.map(doc => doc.data());
        
  //       let tablet = productTablet ? productTablet.filter(tablet => (tablet.odooProductId?.toString() === product.productID.toString()) || (tablet.productID?.toString() === product.productID.toString())) : [];
  //       // let frequencies = await this.productService.getProductDeliveryWishlistFrequency([Object.assign(product, {id: product.productID.toString()})])
        
  //       let frequencies = [];
  //       frequencies[product.productID.toString()] = await tablet.length > 0 ? tablet[0].productDeliveryFrequencies : [];
        
  //       console.log(frequencies, tablet, productTablet, product)
  //       let defaultFrequency = frequencies[product?.productID.toString()] ? frequencies[product.productID.toString()].filter(f => f.defaultFrequency == true)[0] :  {frequency: product.refillIntervalDays, autoPayment: product.automaticPayment};
    

  //       let pharmacySchedule: IPharmacySubscription = {
  //         date: new Date(Date.now()),
  //         id: uuidv4(),
  //         uid: order.uid,
  //         status: 'active',
  //         walletID: order.acceptaID,
  //         productID: product.productID,
  //         tabletID: tablet.length > 0 ? (tablet[0]['odooProductId'] ? tablet[0].odooProductId : tablet[0].productID) : '',
  //         productQty: product.productQty.toString(),
  //         prescriptionID: product.prescriptionID,
  //         automaticPayment: product.automaticPayment != undefined ? product.automaticPayment : defaultFrequency?.autoPayment,
  //         deliveryFrequencyInDays: product.deliveryFrequencyInDays != undefined ? product.deliveryFrequencyInDays : defaultFrequency?.frequency,
  //         nextSubscriptionDate: (() => {
  //           const date = new Date(Date.now() + ((product.deliveryFrequencyInDays != undefined ? product.deliveryFrequencyInDays : defaultFrequency.frequency) * 24 * 60 * 60 * 1000));
  //           date.setHours(0, 0, 0, 0);
  //           return date;
  //         })(),
  //         shippingAddress: order.shippingAddress,
  //       }
  //       this.db.collection('pharmacyOrderSchedule').doc(pharmacySchedule.id).set(pharmacySchedule);
  //     })
  //   });
  // }

  async generatePharmacyOrderSchedule(order:any, products) {

    if(!products) {
      // throw new Error('No products to generate schedule');
      return "No products to generate schedule"
    }

    if(order.subscriptionRef) {
      for(let i = 0; i < products.length; i++){
        let product = products[i]
        this.updatePharmacyOrderScheduleDeliveryFrequency(order.uid, product, product.deliveryFrequencyInDays, product.automaticPayment, new Date(Date.now() + (product.deliveryFrequencyInDays * 24 * 60 * 60 * 1000)))
      }
      return "Subscription already exists for this products."
    }
    
    let POST_URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/createPharmacyOrderSchedule`;
    let body = new HttpParams();
  
    body = body.set('order', JSON.stringify(order));
    body = body.set('products', JSON.stringify(products));

    this.httpClient.post(POST_URL, body)
    .pipe(
      catchError(error => {
        return throwError('Something went wrong with the request.');
      })
    )
    .subscribe(
      async (res: any) => {
      },
      error => {
        // console.error('Error during subscription:', error);
      }
    );
  }

  //create a function that checks if the products has already a subscription
  async hasSubscription(uid: string, product: BaseProductPrescription): Promise<string> {
    const snapshot = await this.db.collection<IPharmacySubscription>('pharmacyOrderSchedule', ref => 
      ref.where('productID', '==', product.productID).where('prescriptionID', '==', product.prescriptionID).where('uid', '==', uid)).get().toPromise();
    return snapshot.docs[0]?.data().id;
  }


  async updatePharmacyOrderScheduleDeliveryFrequency(uid:string, product:any, frequency: number, automaticPayment: boolean, nextSubscriptionDate?: Date){
    const snapshot = await this.db.collection('pharmacyOrderSchedule', ref => 
      ref.where('uid', '==', uid).where('prescriptionID', '==', product.prescriptionID)).get().toPromise();
    snapshot.docs.forEach(doc => {
      const sub:any = doc.data();

      if(sub.productID == product.productID || sub.tabletID == product.productID) {
        let data = {
          deliveryFrequencyInDays: frequency,
          automaticPayment: automaticPayment
        }
  
        if(nextSubscriptionDate) {
          data['nextSubscriptionDate'] = nextSubscriptionDate
        }
  
        this.db.collection('pharmacyOrderSchedule').doc(sub.id).update(data).then(() => {
        })
      }
    });
  }

  updatePharmacyOrderSubscriptions(uid:string, address:any){
    this.db.firestore.collection('pharmacyOrderSchedule').where('uid', '==', uid).where('status', '==', 'active').get().then((snapshot) => {
      snapshot.docs.forEach(doc => {
        const sub:any = doc.data();
        this.db.collection('pharmacyOrderSchedule').doc(sub.id).update({
          shippingAddress: address
        }).then(() => {
        });
      });
    })

    this.db.firestore.collection('orders').where('uid', '==', uid).where('paymentStatus', '==', 'pending').get().then((snapshot) => {
      snapshot.docs.forEach(doc => {
        const order:any = doc.data();
        let warehouse = 2
        if(address.AddressStateCode == "PR"){
          warehouse = 1
        }
        this.db.collection('orders').doc(order.id).update({
          shippingAddress: address,
          warehouse_id: warehouse
        }).then(() => {
        });
      });
    })
  }
}
<div class="row">
    <div class="col-12">
      <div class="page-title-box pb-1 d-flex align-items-center justify-content-between px-1 mt-3">
        <h3 class="mb-0">{{ title | translate | titlecase}}</h3>
        <div class="dropdown d-flex w-50 align-items-center justify-content-end" ngbDropdown>
          <button type="button" class="ml-2 btn header-item langBtn h-auto" style="padding: 0.47rem 0.5rem;" id="page-header-user-dropdown" ngbDropdownToggle>
            <!-- <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16" class="flag">  -->
            <span class="ml-1 ">{{countryName}}<i class="fas fa-caret-down"></i></span>
            <!-- <img class="flag" *ngIf="flagvalue === undefined" src="{{valueset}}" height="16"> -->
            <!-- <span *ngIf="flagvalue === undefined" class="lang ml-1"> English(US)<i class="fas fa-caret-down"></i></span> -->
          </button>
          <div class="dropdown-menu dropdown-menu-right ml-2 p-0" style="min-width: auto;" ngbDropdownMenu>
            <a href="javascript:void(0);" class="dropdown-item notify-item langDropdown" style="padding: 0.35rem 0.85rem;" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
              <!-- <img src="{{item.flag}}" class="mr-1 flag" height="12">  -->
              <span class="align-middle ml-1">{{item.text}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  
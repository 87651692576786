<div class="row">
    <div class="col-lg-12">
        <div class="row" style="justify-content: space-between;">
            <div class="col-6 pb-2">
                <div>
                    <a class="btn btn-cyan" (click)="goBack()">
                        Return to Pharmacy
                    </a>
                </div>
            </div>
            <div class="col-6">
                <div *ngIf="orderProcessing">
                    <a *ngIf="shippingLabelExists" class="btn btn-success" style="float: right;" (click)="confirmOrderShipment()">
                        <i class="mdi mdi-check-bold text-white" style="font-size: 22px;"></i> Complete Order Shipment
                    </a>
                    <div *ngIf="loadingCompleteOrderShipment" style="float: right;">
                        <span class="spinner-border spinner-border-sm" role="status"></span>
                    </div> 
                </div>
                <div *ngIf="!orderProcessing">
                    <a *ngIf="showCancelOrderButton" class="btn btn-danger" style="float: right;" (click)="cancelOrder()">
                        <i class="mdi mdi-close-circle text-white" style="font-size: 22px;"></i> <span style="vertical-align: text-bottom;">&nbsp;Cancel Order</span>
                    </a>
                    <div *ngIf="loadingCancelOrder" style="float: right;">
                        <span class="spinner-border spinner-border-sm" role="status"></span>
                    </div> 
                </div>
            </div>
        </div>
        <!-- <a class="btn btn-dark" (click)="sendDataToPharmacy()" style="float: right;">
            Send to Pharmacy
        </a> -->
        <ng-template [ngTemplateOutlet]="OrderSummary"></ng-template>
        <ng-template [ngTemplateOutlet]="PrescriptionDetails"></ng-template>
        <ng-template [ngTemplateOutlet]="PrescriptionOrderSummary"></ng-template>
        <!-- <ng-template [ngTemplateOutlet]="PurchaseOrderSummary"></ng-template> -->
    </div>
</div>

<!-- <div class="consult-sections">
    <div class="row mt-4 mb-4">
        <div class="col-lg-12">
            <button class="btn btn-secondary btn-lg" (click)="printZPL('https://api.shipengine.com/v1/downloads/10/Ggt7d-OPr0qwSimseDwpAg/label-447586692.zpl')">Print Label</button>
        </div>
    </div>
</div> -->


<ng-template #OrderSummary>
    <div *ngIf="allOrders" class="consult-sections mt-4 mb-4">
        <ng-container *ngFor="let item of allOrders; let i = index;">
            <h2 class="bold" style="text-align: left;">Order Summary<!--: {{item.id}}--></h2>
            <hr style="margin: auto; margin-bottom: 5px; border: none; border-bottom: 2px dotted #e9ebee;">
            <div class="row">
                <!-- Profile -->
                <div class="w-100 py-0" style="padding: calc(2% + 3px);">
                    <div class="row mt-2" style="justify-content: space-between; align-items: start;">
                        <!-- Prescription -->
                        <div class="col-auto">
                            <div>
                                <h6 class="bold">Patient Name</h6>
                                <span>{{currentPatientName}}</span>
                            </div>
                            <div class="mt-3">
                                <h6 class="bold">Shipping Address</h6>
                                <div *ngIf="AddressLine1">
                                    <span>{{AddressLine1}}</span>
                                </div>
                                <div *ngIf="AddressLine2">
                                    <span>{{AddressLine2}}</span>
                                </div>
                                <div *ngIf="AddressCity && AddressState && AddressZipcode">
                                    <span>{{AddressCity}}</span>,&nbsp;<span>{{AddressState}}</span>&nbsp;<span>{{AddressZipcode}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div>
                                <h6 class="bold">{{'VIEWCONSULTS.PATIENTPROFILE.EMAIL' | translate}}</h6>
                                <span>{{item.email}}</span>
                            </div>
                            <div class="mt-3">
                                <h6 class="bold">{{'VIEWCONSULTS.PATIENTPROFILE.PHONE' | translate}}</h6>
                                <span>{{item.phone | mask:'(000) 000-0000'}}</span>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div>
                                <h6 class="bold">{{'VIEWCONSULTS.GENDER' | translate}}</h6>
                                <span *ngIf="PatientGender == 'Male'">{{'PROFILE.MALE' | translate}}</span>
                                <span *ngIf="PatientGender == 'Female'">{{'PROFILE.FEMALE' | translate}}</span>
                            </div>
                            <div class="mt-3">
                                <h6 class="bold">Order Date</h6>
                                <div>
                                    <span>{{item.paymentDate.toDate() | date:'MM/dd/yyyy'}}</span>
                                </div>
                            </div> 
                        </div>
                        <div class="col-auto">
                            <div>
                                <h6 class="bold">Order Status</h6>
                                <div>
                                    <span *ngIf="item.paymentStatus==='paid'" class="badge badge-success font-size-16 mb-0" style="text-transform: capitalize;">{{item.paymentStatus}}</span>
                                    <span *ngIf="item.paymentStatus==='error'" class="badge badge-danger font-size-16 mb-0" style="text-transform: capitalize;">{{item.paymentStatus}}</span>
                                    <span *ngIf="item.paymentStatus!='error' && item.paymentStatus!='paid'" class="badge badge-info font-size-16 mb-0" style="text-transform: capitalize;">{{item.paymentStatus}}</span>
                                </div>
                            </div> 
                            <div class="mt-3" *ngIf="!shippingLabelExists">
                                <h6 class="bold">Create Shipping Label</h6>
                                <div *ngIf="!loadingShippingLabel">
                                    <button class="btn btn-secondary btn-lg" (click)="openShippingDetailsModal(shippingDetailsModal)">Create Label</button>
                                </div> 
                                <div *ngIf="loadingShippingLabel">
                                    <span class="spinner-border spinner-border-sm" role="status"></span>
                                </div> 
                            </div>
                            <div class="mt-3" *ngIf="shippingLabelExists">
                                <h6 class="bold">View Shipping Label</h6>
                                <div>
                                    <button class="btn btn-primary btn-lg" (click)="openShippingLabelModal(viewShippingLabelsModal)">View Label</button>
                                    <!-- <button class="btn btn-primary btn-lg" (click)="printZPL('https://api.shipengine.com/v1/downloads/10/Ggt7d-OPr0qwSimseDwpAg/label-447586692.zpl')">Print Label</button> -->
                                    <!-- <button class="btn btn-primary btn-lg" (click)="printZPL(item.zplLabelUrl)">Print Label</button> -->
                                </div> 
                            </div>
                        </div>
                        <div class="col-auto">
                            <!-- <div *ngIf="shippingLabelAvailable">
                                <h6 class="bold">View Shipping Label</h6>
                                <div>
                                    <button class="btn btn-primary btn-lg" (click)="openShippingLabelModal(viewShippingLabelsModal)">View Label</button>
                                </div> 
                            </div> -->
                            <!-- <div class="mt-3" style="visibility: hidden;">
                                <h6 class="bold">Shipping Label</h6>
                                <div>
                                    <button class="btn btn-primary btn-lg" (click)="printZPL('https://api.shipengine.com/v1/downloads/10/Ggt7d-OPr0qwSimseDwpAg/label-447586692.zpl')">Print Label</button>
                                </div> 
                            </div> -->
                            <div style="display: none;">
                                <h6 class="bold">Prescription Details</h6>
                                <div>
                                    <button class="btn btn-primary" (click)="openModal(prescriptionItemsDetailsModal)">View Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-100 py-0 d-flex justify-content-center align-items-center mt-2" style="padding: calc(2% + 3px);">
                    <div class="table-responsive">
                        <table class="table table-centered m-0">
                            <thead class="thead-light">
                                <tr> 
                                    <!-- <th>ID</th> -->
                                    <th>Name</th>
                                    <!-- <th>Image</th> -->
                                    <th style="text-align: right;">Quantity</th>
                                    <th style="text-align: right;">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of allOrderLines; let i = index;">
                                    <!-- <td>{{item.orderLineId}}</td> -->
                                    <td>{{item.productName | translation:currentLang}}</td>
                                    <!-- <td>
                                        <img src="{{item.image}}" class="img-fluid pb-4" style="width: 200px; height: auto;"/>
                                    </td> -->
                                    <td style="text-align: right;">{{item.productQty}}</td>
                                    <td style="text-align: right;">{{item.productPrice | currency:'USD':'symbol':'1.2-2'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #PrescriptionDetails>
    <div *ngIf="allPrescriptions" class="consult-sections mt-4 mb-4">
        <ng-container *ngFor="let item of allPrescriptions; let i = index;">
            <h2 class="bold" style="text-align: left;">Prescription</h2>
            <hr style="margin: auto; margin-bottom: 5px; border: none; border-bottom: 2px dotted #e9ebee;">
            <div class="w-100 py-0" style="padding: calc(2% + 3px);">
                <div class="row mt-2" style="justify-content: space-between; align-items: start;">
                    <!-- Prescription -->
                    <div class="col-auto">
                        <div>
                            <h6 class="bold">Doctor Name</h6>
                            <div>
                                <span>{{item.docName}}</span>
                            </div>
                        </div>
                        <div class="mt-3">
                            <h6 class="bold">NPI</h6>
                            <span>{{item.NPI}}</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div>
                            <h6 class="bold">License Number</h6>
                            <span>{{item.License}}</span>
                        </div>
                        <div class="mt-3">
                            <h6 class="bold">Doctor Email</h6>
                            <span>{{item.docEmail}}</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div>
                            <h6 class="bold">Doctor Phone</h6>
                            <span>{{item.docPhone | mask:'(000) 000-0000'}}</span>
                        </div>
                        <div class="mt-3">
                            <h6 class="bold">Prescription Date</h6>
                            <span>{{item.date.toDate() | date:'MM/dd/yyyy'}}</span> 
                        </div> 
                    </div>
                    <div class="col-auto">
                        <div>
                            <h6 class="bold">Prescription Status</h6>
                            <div>
                                <span class="badge badge-success font-size-16 mb-0" style="text-transform: capitalize;">{{item.status}}</span>
                            </div>
                        </div>
                        <div class="mt-3">
                            <h6 class="bold">Prescription PDF</h6>
                            <div>
                                <!-- <a href="{{item.prescriptionURL}}" class="btn btn-primary" target="_blank">View PDF</a> -->
                                <button class="btn btn-primary" (click)="openModalPrescriptionPDF(prescriptionPDFModal,item.prescriptionURL)">View PDF</button>
                            </div>  
                        </div>
                    </div>
                    <div class="col-auto">
                        <div *ngIf="prescriptionItemsDetails && prescriptionItemsDetails.length === 1">
                            <h6 class="bold">Send to Pharmacy</h6>
                            <div *ngIf="!showSendLoading">
                                <a class="btn btn-dark btn-sm" (click)="sendDataToPharmacy(item)">
                                    Send
                                </a>
                            </div>
                            <div *ngIf="showSendLoading">
                                <span class="spinner-border" style="border-width: 0.3rem;" role="status" aria-hidden="true"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container>
            <div *ngIf="prescriptionItemsDetails" class="consult-sections">
                <h2 class="bold" style="display: none; text-align: left;">Prescription Details</h2>
                <hr style="margin: auto; margin-bottom: 5px; border: none; border-bottom: 2px dotted #e9ebee; display: none;">
                <div class="row">
                    <!-- Prescription Details -->
                    <div class="w-100 py-0 d-flex justify-content-center align-items-center mt-2" style="padding: calc(2% + 3px);">
                        <div class="table-responsive">
                            <table class="table table-centered m-0">
                                <thead class="thead-light">
                                    <!-- <tr *ngFor="let item of prescriptionItemsDetails; let i = index;">  -->
                                    <!-- <tr *ngFor="let item of prescriptionItemsDetails; let i = index;">  -->
                                    <tr> 
                                        <!-- <th>ID</th> -->
                                        <th>RxCUI</th>
                                        <th>Name</th>
                                        <!-- <th>Image</th> -->
                                        <th style="text-align: right;">Quantity</th>
                                        <!-- <th *ngIf="item.refillMainQty" style="text-align: right;">Refill Quantity</th> -->
                                        <th style="text-align: right;">Refill Quantity</th>
                                        <!-- <th *ngIf="item.refillQty">Refills</th> -->
                                        <!-- <th>RX Instructions</th> -->
                                        <th *ngIf="prescriptionItemsDetails.length > 1" class="text-center">Send to Pharmacy</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of prescriptionItemsDetails; let i = index;">
                                        <!-- <td>{{item.orderLineId}}</td> -->
                                        <td>{{item.rxcui}}</td>
                                        <td>{{item.productName}}</td>
                                        <!-- <td>
                                            <img src="{{item.image}}" class="img-fluid pb-4" style="width: 200px; height: auto;"/>
                                        </td> -->
                                        <td style="text-align: right;">{{item.productQty}}</td>
                                        <td *ngIf="item.refillMainQty" style="text-align: right;">{{item.refillMainQty}}</td>
                                        <!-- <td *ngIf="item.refillQty">{{item.refillQty}} every {{item.refillIntervalDays}} days</td> -->
                                        <!-- <td>{{item.rxInstruction}}</td> -->
                                        <td *ngIf="prescriptionItemsDetails.length > 1" style="text-align: center;">
                                            <div *ngIf="!showSendLoading">
                                                <a class="btn btn-dark btn-sm" (click)="sendDataToPharmacyIndividualItem(item)">
                                                    Send
                                                </a>
                                            </div>
                                            <div *ngIf="showSendLoading">
                                                <span class="spinner-border" style="border-width: 0.3rem;" role="status" aria-hidden="true"></span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #PrescriptionItemsDetails>
    <div *ngIf="prescriptionItemsDetails" class="consult-sections mt-4">
        <h2 class="bold" style="text-align: left;">Prescription Details</h2>
        <hr style="margin: auto; margin-bottom: 5px; border: none; border-bottom: 2px dotted #e9ebee;">
        <div class="row">
            <!-- Prescription Details -->
            <div class="w-100 py-0 d-flex justify-content-center align-items-center mt-4" style="padding: calc(2% + 3px);">
                <div class="table-responsive">
                    <table class="table table-centered m-0">
                        <thead class="thead-light">
                            <tr> 
                                <!-- <th>ID</th> -->
                                <th>Name</th>
                                <!-- <th>Image</th> -->
                                <th style="text-align: right;">RxCUI</th>
                                <th style="text-align: right;">Quantity</th>
                                <!-- <th>RX Instructions</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of prescriptionItemsDetails; let i = index;">
                                <!-- <td>{{item.orderLineId}}</td> -->
                                <td>{{item.name}}</td>
                                <!-- <td>
                                    <img src="{{item.image}}" class="img-fluid pb-4" style="width: 200px; height: auto;"/>
                                </td> -->
                                <td style="text-align: right;">{{item.rxcui}}</td>
                                <td style="text-align: right;">{{item.qty}}</td>
                                <!-- <td>{{item.rxInstruction}}</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #PurchaseOrderSummary>
    <div *ngIf="allOrders" class="consult-sections">
        <ng-container *ngFor="let item of allOrders; let i = index;">
            <h2 class="bold" style="text-align: left;">Purchase Order<!--: {{item.id}}--></h2>
            <hr style="margin: auto; margin-bottom: 5px; border: none; border-bottom: 2px dotted #e9ebee;">
            <div class="row">
                <!-- Profile -->
                <div class="w-100 py-0" style="padding: calc(2% + 3px);">
                    <div class="row mt-2" style="justify-content: start; align-items: start;">
                        <!-- Prescription -->
                        <div class="col-auto">
                            <div>
                                <h6 class="bold">Vendor</h6>
                                <span></span>
                            </div>
                            <div class="mt-3">
                                <h6 class="bold">Vendor Reference</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div>
                                <h6 class="bold">Date</h6>
                                <span></span>
                            </div>
                            <div class="mt-3">
                                <h6 class="bold">Order Reference</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div>
                                <h6 class="bold">{{'VIEWCONSULTS.PATIENTPROFILE.EMAIL' | translate}}</h6>
                                <span></span>
                            </div>
                            <div class="mt-3">
                                <h6 class="bold">{{'VIEWCONSULTS.PATIENTPROFILE.PHONE' | translate}}</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div>
                                <h6 class="bold">Status</h6>
                                <span class="badge badge-success font-size-16 mb-0" style="text-transform: capitalize;"></span>
                            </div>
                            <div class="mt-3" style="visibility: hidden;">
                                <h6 class="bold">Order Reference</h6>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-100 py-0 d-flex justify-content-center align-items-center mt-2" style="padding: calc(2% + 3px);">
                    <div class="table-responsive">
                        <table class="table table-centered m-0">
                            <thead class="thead-light">
                                <tr> 
                                    <th>Product</th>
                                    <th>Description</th>
                                    <th style="text-align: right;">Quantity</th>
                                    <th>UoM</th>
                                    <th style="text-align: right;">Unit Price</th>
                                    <th style="text-align: right;">Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of allOrderLines; let i = index;">
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right;"></td>
                                    <td></td>
                                    <td style="text-align: right;"></td>
                                    <td style="text-align: right;"></td>
                                </tr>
                                <tr>
                                    <td colspan="5" style="text-align: right; font-weight: bold !important;">Total</td>
                                    <td style="text-align: right; font-weight: bold !important;"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #PrescriptionOrderSummary>
    <div *ngIf="allOrders" class="consult-sections">
        <ng-container *ngFor="let item of allOrders; let i = index;">
            <h2 class="bold" style="text-align: left;">Prescription & Order Summary (For Google Sheets)<!--: {{item.id}}--></h2>
            <hr style="margin: auto; margin-bottom: 5px; border: none; border-bottom: 2px dotted #e9ebee;">
            <div class="row mt-4 mb-4">
                <div class="col">
                    <div>Copy and paste the information below to the following Google Sheets document:</div>
                    <div>
                        <button class="btn btn-link" (click)="openLinkInNewWindow('https://docs.google.com/spreadsheets/d/1HUY9VNx9fhAqpPVeuRG_-x1rkZ5kcCAwr3X5D8cFEEU/edit?usp=sharing')" style="font-size: 12px !important;">
                            {{googleSheetsUrl}}
                        </button>
                    </div>
                </div>
                <div>
                    <button class="btn btn-link" (click)="copyTextToClipboard(item)">Copy Data <i class="mdi mdi-content-copy text-primary"></i></button>
                </div>
            </div>
            <div class="row">
                <!-- Profile -->
                <div class="w-100 py-0" style="padding: calc(2% + 3px);">
                    <div class="row mt-2" style="justify-content: space-between; align-items: start;">
                        <!-- Prescription -->
                        <div class="col-auto">
                            <div>
                                <h6 class="bold">Name</h6>
                                <span>{{currentPatientName}}</span>
                            </div>
                            <div class="mt-3">
                                <h6 class="bold">Order Date</h6>
                                <div>
                                    <span>{{item.paymentDate.toDate() | date:'MM/dd/yyyy'}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div>
                                <h6 class="bold">RX #</h6>
                                <ng-container *ngIf="prescriptionItemsDetails">
                                    <span>{{ prescriptionItemsDetails.length }}</span>
                                </ng-container>
                            </div>
                            <div class="mt-3">
                                <h6 class="bold">Processing Date (Optional)</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <h6 class="bold">Message ID</h6>
                                <ng-container *ngFor="let prescriptionItem of allPrescriptions; let i = index;">
                                    <span>{{prescriptionItem.ID}}</span>
                                </ng-container>
                            </div>
                            <div class="mt-3">
                                <h6 class="bold">Prescription PDF URL</h6>
                                <ng-container *ngFor="let prescriptionItem of allPrescriptions; let i = index;">
                                    <span style="word-break: break-all;">{{prescriptionItem.prescriptionURL}}</span>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <h6 class="bold">Shiping Label URL</h6>
                                <ng-container *ngIf="hasShippingLabelUrl">
                                    <ng-container *ngFor="let shippingItem of shippingLabels; let i = index;">
                                        <span style="word-break: break-all;">{{shippingItem.label_url}}</span>
                                    </ng-container>
                                </ng-container>
                            </div> 
                            <div class="mt-3">
                                <h6 class="bold">Shipping Label URL #2 (Optional)</h6>
                                <span></span>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div style="display: none;">
                                <h6 class="bold">Prescription Details</h6>
                                <div>
                                    <button class="btn btn-primary" (click)="openModal(prescriptionItemsDetailsModal)">View Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #prescriptionItemsDetailsModal let-modal>
    <div class="modal-header" style="display: none;">
      <h5 class="modal-title" id="exampleModalLongTitle">Prescription Details</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <ng-template [ngTemplateOutlet]="PrescriptionItemsDetails"></ng-template>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

<ng-template #prescriptionPDFModal let-modal>
    <div class="modal-header" style="display: none;" appNoRightClick (contextmenu)="disableRightClick($event)">
      <h5 class="modal-title" id="exampleModalLongTitle">Prescription PDF</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" appNoRightClick oncontextmenu="disableRightClick(event)">
        <ngx-extended-pdf-viewer *ngIf="base64 != undefined" appNoRightClick (contextmenu)="disableRightClick($event)" [textLayer]="false" [src]="base64" backgroundColor="#e8e8ef"
            [showZoomButtons]="true" [showPrintButton]="false" [showDownloadButton]="false" [useBrowserLocale]="true"
            [sidebarVisible]="false"  [showSecondaryToolbarButton]="false" [showSidebarButton]="false" [zoom]="'50%'">
        </ngx-extended-pdf-viewer>
    </div>
    <div class="modal-footer" appNoRightClick (contextmenu)="disableRightClick($event)">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

<ng-template #shippingDetailsModal let-modal>
    <div class="modal-header" appNoRightClick (contextmenu)="disableRightClick($event)">
      <h5 class="modal-title" id="exampleModalLongTitle">Shipping Labels</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" appNoRightClick oncontextmenu="disableRightClick(event)">
        <div class="row">
            <div class="col">
                <div>
                    <h6 class="bold">Shipping Address <!--span *ngIf="!mode" class="mdi mdi-square-edit-outline text-primary" (click)="editShippingDetails('edit')" style="cursor: pointer; vertical-align: middle;"></span--> <!--span *ngIf="mode" class="mdi mdi-content-save text-secondary" (click)="saveShippingDetails('save')" style="cursor: pointer; vertical-align: middle;"></span--></h6>
                    <div *ngIf="currentPatientName">
                        <span>{{currentPatientName}}</span>
                    </div>
                    <div *ngIf="AddressLine1">
                        <span>{{AddressLine1}}</span>
                    </div>
                    <div *ngIf="AddressLine2">
                        <span>{{AddressLine2}}</span>
                    </div>
                    <div *ngIf="AddressCity && AddressState && AddressZipcode">
                        <span>{{AddressCity}}</span>,&nbsp;<span>{{AddressState}}</span>&nbsp;<span>{{AddressZipcode}}</span>
                    </div>
                </div>
            </div>
            <div class="col">
                <form>
                    <div class="form-group">
                      <label for="packageWeight1" class="bold">Enter the package weight (lbs):</label>
                      <input type="number" class="form-control" id="packageWeight1" aria-describedby="packageWeight" value="0.5">
                      <!-- <small id="packageWeight" class="form-text text-muted">The default value is shown.</small> -->
                    </div>
                    <div class="form-group">
                        <label for="packageTypeSelect1" class="bold">Select Package Type</label>
                        <select class="form-control" id="packageTypeSelect1">
                          <option selected>Parcel</option>
                          <option>Priority</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="labelFormatSelect1" class="bold">Select Label Format</label>
                        <select class="form-control" id="labelFormatSelect1">
                          <option>ZPL</option>
                          <option selected>PNG</option>
                          <option>PDF</option>
                        </select>
                    </div>
                    <div *ngIf="!generatingShippingLabel" style="justify-content: center; display: flex;">
                        <button type="submit" class="btn btn-primary" (click)="sendShippingDetails()">Generate</button>
                    </div>
                    <div *ngIf="generatingShippingLabel" style="justify-content: center; display: flex;">
                        <span class="spinner-border spinner-border-sm" role="status"></span>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer" appNoRightClick (contextmenu)="disableRightClick($event)" style="display: none;">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

<ng-template #viewShippingLabelsModal let-modal>
    <div class="modal-header" appNoRightClick (contextmenu)="disableRightClick($event)">
      <h5 class="modal-title" id="exampleModalLongTitle">Shipping Labels</h5>
      <button type="button" class="close" (click)="closeViewShippingLabelModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" appNoRightClick oncontextmenu="disableRightClick(event)">
        <div class="row">
            <div class="col-auto">
                <div>
                    <h6 class="bold">Shipping Address <!--span *ngIf="!mode" class="mdi mdi-square-edit-outline text-primary" (click)="editShippingDetails('edit')" style="cursor: pointer; vertical-align: middle;"></span--> <!--span *ngIf="mode" class="mdi mdi-content-save text-secondary" (click)="saveShippingDetails('save')" style="cursor: pointer; vertical-align: middle;"></span--></h6>
                    <div *ngIf="currentPatientName">
                        <span>{{currentPatientName}}</span>
                    </div>
                    <div *ngIf="AddressLine1">
                        <span>{{AddressLine1}}</span>
                    </div>
                    <div *ngIf="AddressLine2">
                        <span>{{AddressLine2}}</span>
                    </div>
                    <div *ngIf="AddressCity && AddressState && AddressZipcode">
                        <span>{{AddressCity}}</span>,&nbsp;<span>{{AddressState}}</span>&nbsp;<span>{{AddressZipcode}}</span>
                    </div>
                </div>
                <div class="mt-3" *ngIf="showLabelPreview">
                    <div *ngIf="!showImageLoading">
                        <img src="{{labelPngUrl}}" class="img-fluid pb-4" style="width: 200px; height: auto;"/>
                    </div>
                    <div class="text-center" *ngIf="showImageLoading">
                        <span class="spinner-border spinner-border-sm" role="status"></span>
                    </div>
                </div>
                <!-- <div> -->
                    <!-- <div>
                        <textarea [(ngModel)]="zplInput" rows="10" cols="50" placeholder="Enter ZPL code here"></textarea>
                        <button (click)="renderZPL()">Render ZPL</button>
                    </div> -->
                    <!-- <canvas id="zplCanvas"></canvas> -->
                <!-- </div> -->
            </div>
            <div class="col">
                <div class="table-responsive" *ngIf="shipmentsAvailable">
                    <table class="table table-centered m-0">
                        <thead class="thead-light">
                            <tr> 
                                <th class="text-center">Tracking Number</th>
                                <th class="text-center">Carrier</th>
                                <th class="text-center">Service</th>
                                <th class="text-center">Format</th>
                                <th class="text-center">Status</th>
                                <th class="text-center">View Label</th>
                                <th class="text-center">Print Label</th>
                                <th class="text-center">Void Label</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of allShippingLabels; let i = index;">
                                <td class="text-center">
                                    <a [href]="getItemUrl(item)" target="_blank">{{item.tracking_code}}</a>
                                </td>
                                <td class="text-center">{{item.carrier}}</td>
                                <td class="text-center">{{item.service}}</td>
                                <td class="text-center">{{item.label_format}}</td>
                                <td class="text-center">
                                    <span *ngIf="item.status === 'active'" class="badge badge-success font-size-16 mb-0" style="text-transform: capitalize;">{{item.status}}</span>
                                    <span *ngIf="item.status === 'voided'" class="badge badge-dark font-size-16 mb-0" style="text-transform: capitalize;">{{item.status}}</span>
                                </td>
                                <td class="text-center">
                                    <div>
                                        <button *ngIf="item.status === 'active'" class="btn btn-primary btn-sm" (click)="viewLabelPreview(item.label_url)">View</button>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div *ngIf="!loadingPrint">
                                        <button *ngIf="item.status === 'active'" class="btn btn-primary btn-sm" (click)="printSelectedShippingLabel(item)">Print</button>
                                    </div>
                                    <div *ngIf="loadingPrint && item.tracking_code === selectedShippingLabel">
                                        <span class="spinner-border spinner-border-sm" role="status"></span>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div *ngIf="!loadingVoid">
                                        <button *ngIf="item.status === 'active'" class="btn btn-secondary btn-sm" (click)="voidShippingLabel(item)">Void</button>
                                    </div>
                                    <div *ngIf="loadingVoid && item.tracking_code === selectedShippingLabel">
                                        <span class="spinner-border spinner-border-sm" role="status"></span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-responsive" *ngIf="!shipmentsAvailable">
                    <table class="table table-centered m-0">
                        <thead class="thead-light">
                            <tr> 
                                <th class="text-center">Format</th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Print Label</th>
                                <th class="text-center">Void Label</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center" colspan="4">No shipments available.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" appNoRightClick (contextmenu)="disableRightClick($event)" style="display: none;">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>
<div *ngIf="settings" style="overflow-y: auto;" class="p-3">     
    <h1>{{'NOTIFICATIONS.CONTACTUS.CONTACTUS' | translate}}</h1> 
    <hr>
    <h5>{{'NOTIFICATIONS.CONTACTUS.TEXT1' | translate}} <a href="{{'tel:'+911}}">9-1-1</a><br>
      {{'NOTIFICATIONS.CONTACTUS.TEXT2' | translate}}</h5><br>
      
      <div style="text-align: center; width: fit-content;" class="d-flex flex-column justify-content-center mx-auto mb-2">
        <button class="btn btn-primary mb-2 d-flex flex-row justify-content-center align-items-center"><i class="ri-whatsapp-line" style="font-size: 25px;"></i> <a target="_blank" href="{{settings.whatsapp}}" style="color: white; font-size: 19px;">&nbsp;{{settings.practitionerPhoneNumber | mask: '(000) 000-0000'}}</a></button>
        <button class="btn btn-primary mb-2 d-flex flex-row justify-content-center align-items-center"><i class="ri-phone-fill" style="font-size: 25px;"></i> <a style="color: white; text-decoration: none; font-size: 19px;" href="{{'tel:'+settings.phoneNumber}}">&nbsp;{{settings.dzeusPhoneNumber}}</a></button>
        <button class="btn btn-primary mb-2 d-flex flex-row justify-content-center align-items-center"><i class="ri-mail-line align-middle" style="font-size: 25px;"></i> <a style="color: white; text-decoration: none; font-size: 19px;" href="{{'mailto:'+supportEmail }}">&nbsp;{{settings.supportEmail | translation:currentLang}}</a></button>
      </div>
      <!-- <h4 style="font-size: 22px;">{{'NOTIFICATIONS.CONTACTUS.TEXT3' | translate}}</h4> -->
      <!-- <h5>{{'NOTIFICATIONS.CONTACTUS.PHONE' | translate}} <a href="{{'tel:'+settings.phoneNumber}}">{{settings.phoneNumber}}</a></h5> -->
      <!-- <h5>{{'NOTIFICATIONS.CONTACTUS.WRITEUS' | translate}} <a href="{{'tel:'+settings.textNumber}}">{{settings.textNumber}}</a></h5> -->
      <h6 class="font-size-14">{{'NOTIFICATIONS.CONTACTUS.WHEREAVAILABLE' | translate}} {{settings.chatSchedule | translation:currentLang}}</h6>
      <br>
      <!-- <h4 style="font-size: 22px;">{{'NOTIFICATIONS.CONTACTUS.TEXT4' | translate}}</h4> -->
    <!-- <h5><i class="fab fa-facebook"></i><a target="_blank" href="{{settings.facebook}}"> DzeusMD</a></h5>
    <h5><i class="fab fa-twitter"></i><a target="_blank" href="{{settings.twitter}}"> DzeusMD</a></h5>
    <h5><i class="fab fa-instagram"></i><a target="_blank" href="{{settings.instagram}}"> DzeusMD</a></h5> -->
  </div>
import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { EmailsService } from 'src/app/core/services/emails.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { Profile } from 'src/app/core/services/interfaces';
import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';
import { TranslationPipe } from 'src/app/translation.pipe';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { TelehealthService } from '../../telehealth/telehealth.service';
import { fbRemoteConfig } from '../../../authUtils'
import { QuestionServiceService } from 'src/app/core/services/question-service.service';
declare var gapi: any;

@Component({
  selector: 'app-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.scss']
})
export class FollowUpComponent implements OnInit {
  @Input() consultID:any;
  @Output() consultUpdate = new EventEmitter<any>();

  user 
  currentUser
  practitionerProfile
  abscentDates
  consult
  consultQuestions
  sendMail = true
  patientProfile
  userTimezone
  swal

  eventform:UntypedFormGroup;
  allSubscriptions = new Subscription()
  loading = false
  token
  settings = fbRemoteConfig.defaultConfig
  currentURL
  draftConsult

  creatingConsult: boolean = false;

  constructor(    
    private afs: AngularFirestore,
    public firebaseService: FirebaseService,
    private modalService: NgbModal,
    public afAuth: AngularFireAuth,
    public translate: TranslateService,
    public formBuilder: UntypedFormBuilder,
    private authService: AuthenticationService,
    private telehealthService: TelehealthService,
    private route: Router,
    private emailService: EmailsService,
    private questionService: QuestionServiceService,
    @Inject(LOCALE_ID) private locale: string
) { 
  this.currentURL = this.route.url
  if(this.consultID == undefined){
    let tempConsultID = this.route.url.split('consults/')[1]
    this.consultID = tempConsultID.split('/')[0];
  }

  // gapi.load('client:auth2', () => {
  //   gapi.auth2.authorize({
  //       scope: 'https://www.googleapis.com/auth/calendar',
  //       client_id: environment.firebaseConfig.clientId,
  //       // client_secret: '12m0a5Rz18UlS-0fbkL_8-8d',
  //       prompt: 'none',
  //       access_type: 'offline',
  //       include_granted_scopes: true
  //     }, res => {
  //       this.token = res
  //     })
  //   })

  this.getUser()
}

  ngOnInit(): void {
    var today: any = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd ;
    this.eventform = this.formBuilder.group({
      Start: today + "T08:00",
      End: today + "T08:15"
    });

    this.afs.collection("consultation", ref => ref.where("ID", '==', this.consultID)).valueChanges().subscribe(consult => {
      this.consult = consult;
     
      //bring user profile
      this.getPatientProfile(consult[0]['UID_Patient']);
      this.getPractitionerProfile(consult[0]['practitionerForConsult']);
      this.getConsultQuestions();

    });
  }

  getUser() {
    this.afAuth.user.subscribe(user => {
      this.currentUser = user.uid;
      // this.user = user;
      if(user){
        this.authService.profile().then(async (data: Profile) => {
          // this.practitionerProfile = data;
          // this.userTimezone = 'UTC' + data.timezone.timeValue
          this.user = data
        });
      }
    });
  }

  getPatientProfile(patient){
    this.afs.collection('users').doc(patient).valueChanges().subscribe(profile => {
      this.patientProfile = profile;
    });
  }

  getPractitionerProfile(practitioner){
    this.afs.collection('users').doc(practitioner).valueChanges().subscribe((profile: any) => {
      this.practitionerProfile = profile;
      this.abscentDates = profile.abscentDates ? profile.abscentDates : []
      this.userTimezone = 'UTC' + profile.timezone.timeValue
    });
  }


  getConsultQuestions(){
    this.afs.collection('consultation').doc(this.consultID).collection('Questions').valueChanges().subscribe(questions => {
      let arr = [];
      for(let i of questions){
        if(i.biologicalGender == undefined || i.biologicalGender.length == 0 || i.biologicalGender.includes(this.patientProfile.biologicalgender)){
          arr.push(i)
        }
      }
      this.consultQuestions = arr;
      
      this.consultQuestions.sort((a, b) => {
        return a.sequence - b.sequence;
      })
    });
  }

  verifyConsult(consult){

    this.creatingConsult = true;

    this.afs.firestore.collection('consultation').where('UID_Patient', '==', consult.UID_Patient).where('Type', '==', consult.Type).where('Status', '==', 'Completed').get().then((res: any) => {
      
      if(res.docs.length != 0){
        res = res.docs.sort((a, b) => {
          return b.data().Date.seconds - a.data().Date.seconds
        })
        for(let i = 0; i < res?.length; i++){
          if(res[i].data().serviceType == consult.Type){ //service Type should be main Consult not Follow Up
            var d = res[i].data().Date.toDate();
            var year = d.getFullYear();
            var month = d.getMonth();
            var day = d.getDate();
            var c = new Date(year + 1, month, day);
  
            if(c < new Date(this.eventform.controls.Start.value)){ //a year has passed
              this.copyConsult(consult, true)
            }
            else {
              this.copyConsult(consult)
            }
            break;
          }
          if(i == res.length - 1){
            this.copyConsult(consult)
          }
        }
      }
      if(res?.docs?.length == 0){
        this.copyConsult(consult)
      }
    })
  }

  copyConsult(consult, mainConsult?){
    this.ret = true;

    this.afs.firestore.collection('consultRules').doc(consult.Type).get().then(condition => {
      
      let followUpType = mainConsult ? condition.data().consultTemplate : condition.data().followUpTemplate
    
      this.afs.firestore.collection('consultTemplates').doc(followUpType).get().then(querySnapshot => {
        let condition = querySnapshot.data()
      
        const data = {
          Type: condition.id,
          UID_Patient: consult.UID_Patient,
          UID_Practitioner: consult.practitionerForConsult,
          practitionerName: consult.practitionerForConsult == consult.UID_Practitioner ? consult.practitionerName : '',
          scoreLimit: condition.scoreLimit,
          Photo1: condition.Photo1,
          preconsult: false,
          patientName: consult.patientName,
          patientEmail: consult.patientEmail,
          consultLanguage: consult.consultLanguage,
          conditionName: condition.Name,
          biologicalGender: condition.biologicalGender,
          image: consult.image,
          identification: consult.identification,
          // identificationBack: consult.identificationBack,
          rxcuiSource: condition.rxcuiSource,
          passScoreLimit: condition.passScoreLimit,
          // country: 'United States', 
          // state: {name: 'Puerto Rico', code: 'PR'},
          country: consult.country,
          state: consult.state,
          currentQuestion: 1,
          currentWizardStep: condition.steps[0].stepId || 'preconsultQuestions',
          totalQuestions: condition.totalQuestions,
          typeOfContact: condition.typeOfContact,
          lastRevision: condition.lastRevision,
          totalPoints: 0,
          coupon: '',
          shippingMethod: '',
          creditCardRef: '',
          updateId: false,
          practitionerForConsult: consult.practitionerForConsult,
          serviceType: condition.serviceType,
          typeOfPractitioner: condition.typeOfPractitioner,
          requiredFollowUp: condition.requiredFollowUp,
          consultModale: condition.consultModale,
          hasLabs: condition.hasLabs || '',
          steps: condition.steps || [],
          conditionQuestionsId: condition.conditionQuestionsId,
          dateToSort: new Date(Date.now())
        };
    
        this.draftConsult = data
        let date = new Date(Date.now());
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();
        let id:any = yyyy + mm + dd + uuidv4();
        this.draftConsult.ID = id;
        this.draftConsult.Status = 'Draft'
        this.draftConsult.Date = new Date(this.eventform.controls.Start.value)
        // this.draftConsult.patientSignature = ''
        // this.draftConsult.patientSignatureDate = ''
        // this.draftConsult.currentProgress = 0
        // this.draftConsult.currentQuestion = this.consultQuestions[0].sequence
        // this.draftConsult.currentWizardStep = 1
        this.draftConsult.submittedConsultDate = '';
        // this.draftConsult.shippingMethod = ''
        // this.draftConsult.productByPatient = false
        
        this.draftConsult.originalConsult = this.consultID
    
        if(condition?.diagnosticsTests){
          this.draftConsult = Object.assign(this.draftConsult, {
            diagnosticsTests: condition.diagnosticsTests,
            diagnosticsTestsPoints: condition.diagnosticsTestsPoints,
          })
        }
    
        if(this.draftConsult.diagnosticsTests){
          let diagnosticsTestsScale = []
          this.afs.firestore.collection('diagnosticsTests').get().then(async (res: any) => {
              for(let i = 0; i < res.docs.length; i++){
                if(this.draftConsult.diagnosticsTests.includes(res.docs[i].data().id)){
                 diagnosticsTestsScale.push({scale: res.docs[i].data().scale})
                  this.draftConsult = Object.assign(this.draftConsult, {
                    diagnosticsTests: this.draftConsult.diagnosticsTests,
                    diagnosticsTestsPoints: this.draftConsult.diagnosticsTestsPoints,
                    diagnosticsTestsScale: diagnosticsTestsScale
                  })
                }
    
                if(i == res.docs.length -1){
                  this.afs.collection('consultation').doc(this.draftConsult.ID).set(this.draftConsult)
                }
              }
          })
        } else {
          this.afs.collection('consultation').doc(this.draftConsult.ID).set(this.draftConsult)
        }
        
        let history = {
          date: new Date(Date.now()),
          id: this.firebaseService.getDate(),
          userUID: this.user.uid,
          user: this.user.firstName + ' ' + this.user.lastName1,
          userAccountType: this.user.accountType,
          action: 'Follow Up Created'
        }
        this.afs.collection('consultation').doc(this.draftConsult.ID).collection('History').doc(history.id).set(history)

        this.questionService.getTemplateQuestions(condition.conditionQuestionsId).then((res:any) => {
          this.firebaseService.addQuestionsToUser(res, this.draftConsult.ID);
        })
    
          let sub = this.afs.collection('users').doc(this.draftConsult.practitionerForConsult).collection('NPI Registry').valueChanges().subscribe(data => {
            let practitioner: any = data[0]
            this.afs.collection('consultation').doc(this.draftConsult.ID).collection('NPI Registry').doc(practitioner.NPI.toString()).set(practitioner).then(() =>
            {})
          })
    
          this.afs.collection('consultation').doc(this.consultID).collection('Address').doc('Shipping').valueChanges().subscribe(data => {
            let address: any = data
            this.afs.collection('consultation').doc(this.draftConsult.ID).collection('Address').doc('Shipping').set(address).then(() =>
            {})
          })
    
          this.afs.collection('consultation').doc(this.consultID).update({followUpConsult: this.draftConsult.ID})
          this.consultUpdate.emit(Object.assign(this.consult, {followUpConsult: this.draftConsult.ID}))
    
          // this.allSubscriptions.add(sub) 
          this.sendFollowUpCreatedEmail()
          this.createEventTest()
      })
    })
  }

  sendFollowUpCreatedEmail(){
    let pipe = new TranslationPipe()

    this.sendMail = true;
 
    let datePipe = formatDate(this.eventform.controls.Start.value,'MMMM d, y',this.patientProfile.preferedLang);
    let timePipe = formatDate(this.eventform.controls.Start.value,'hh:mm a',this.patientProfile.preferedLang);

    if(this.patientProfile.timezone.timeValue != this.userTimezone.split('UTC')[1]){
      let changeStart = new DatePipe('en-Us').transform(this.eventform.controls.Start.value + ':00.000' + this.userTimezone.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.patientProfile.timezone.timeValue);
      datePipe = formatDate(changeStart,'MMMM d, y',this.patientProfile.preferedLang);
      timePipe = formatDate(changeStart,'hh:mm a',this.patientProfile.preferedLang);
    }
    // if(this.patientProfile.preferedLang == 'es'){
    //   this.firebaseService.sendsms(this.patientProfile, 'Dzeus Health: Tiene un nuevo mensaje dentro de la plataforma. Favor de iniciar sesión para mas detalles.', true)
    // }
    // if(this.patientProfile.preferedLang == 'en'){
    //   this.firebaseService.sendsms(this.patientProfile, 'Dzeus Health: You have a new message inside the platform. Please login for more details.', true)
    // }
 
    this.emailService.patientConsultEmails(Object.assign(this.practitionerProfile, {displayName: this.practitionerProfile.firstName + ' ' + this.practitionerProfile.lastName1}), this.patientProfile, this.consult[0], 'followUpCreated_' + this.patientProfile.preferedLang, '<en>Follow Up Created</en><es>Seguimiento Creado</es>', this.settings, '', '', pipe.transform(`<en>${datePipe + " at " + timePipe + ' - UTC' + this.patientProfile.timezone.timeValue}</en><es>${datePipe + " a las " + timePipe + ' - UTC' + this.patientProfile.timezone.timeValue}</es>`, this.patientProfile.preferedLang))
  }

  minutes = 15;
  calcEndTime(startTime){
    if(new Date(startTime).getTime() <= new Date().getTime()){
      this.overlap(true, true);
    }
    else {
      var futureDate: any = new Date(startTime).getTime() + (this.minutes*60000);
      this.eventform.controls.End.patchValue(formatDate(new Date(futureDate),'yyyy-MM-ddTHH:mm',this.patientProfile.preferedLang))    
      let sub = this.afs.collection<any>('calendar', ref => ref.where('uidPractitioner', '==', this.practitionerProfile.uid)).valueChanges().subscribe(data => {
        data.concat(this.abscentDates)
        
        for(let i = 0; i < data.length; i++){
          if(new Date(data[i].start).getTime() <= new Date(data[i + 1]?.start).getTime() && new Date(data[i].end).getTime() >= new Date(data[i + 1]?.end).getTime()) {
            data.splice(i + 1,1)
            i--
          }
          if(i != -1 && new Date(data[i].start).getTime() <= new Date(data[i + 1]?.start).getTime() && new Date(data[i].end).getTime() <= new Date(data[i + 1]?.end).getTime() && new Date(data[i].end).getTime() >= new Date(data[i + 1]?.start).getTime()) {
            data[i].end = data[i + 1].end
            data.splice(i + 1,1)
            i--
          }
          if(i != -1 && new Date(data[i].start).getTime() <= new Date(data[i + 1]?.start).getTime() && new Date(data[i + 1]?.end).getTime() >= new Date(data[i].end).getTime() && new Date(data[i].end).getTime() >= new Date(data[i + 1]?.start).getTime()) {
            data[i].start = data[i + 1].start
            data.splice(i + 1,1)
            i--
          }
        }

        for (let i = 0;i < data.length; i++) {  
          if(data[i].eventTimezoneValue.split('UTC')[1] != this.userTimezone){
            let changeStart = new DatePipe('en-Us').transform(data[i].start+':00.000' + data[i].eventTimezoneValue.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.userTimezone);
            let changeEnd = new DatePipe('en-Us').transform(data[i].end+':00.000' + data[i].eventTimezoneValue.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.userTimezone);
            data[i].start = changeStart
            data[i].end = changeEnd
          }
          // if(data[i].consultID != this.consultID){
            if (new Date(startTime).getTime() <= new Date(data[i].end).getTime() && new Date(futureDate).getTime() >= new Date(data[i].start).getTime())
            {
              this.overlap(true, false)
              break; 
            }
            if(i == data.length - 1) {
              this.overlap(false, false)
            }
          // }  
          // else {
          //   this.overlap(false)
          //   break;
          // }        
        }   
        if(data.length == 0) {
          this.overlap(false, false)
        }
      });
      this.allSubscriptions.add(sub)
    }  
  }

  ret = true
  warning
  passedDate
  overlap(ret: boolean, passed?: boolean){
    this.passedDate = ''
    if(ret){
      this.ret = true;
      this.warning = 'EVENTS.WARNING'
    }
    else {
      this.ret = false;
      this.warning = ''
    }
    if(passed){
      this.passedDate = 'EVENTS.PASSED'
      this.warning = ''
      ret = true;
    }
  }

  createEventTest(){
    let pipe = new TranslationPipe()

    var Start = this.eventform.controls.Start.value;
    var Title;
    var description;
    if(this.patientProfile.preferedLang == 'en'){
      // Title = 'Dzeus Health - ' + pipe.transform(this.draftConsult.conditionName, this.patientProfile.preferedLang) + ' Consultation' 
      Title = 'Medical Consultation'
      description = 'Telehealth consultation with ' + this.practitionerProfile.firstName + ' ' + this.practitionerProfile.lastName1
    }
    else {
      // Title = 'Dzeus Health - Consulta de ' + pipe.transform(this.draftConsult.conditionName, this.patientProfile.preferedLang)
      Title = 'Consulta Médica'
      description = 'Consulta de Telemedicina con el Proveedor de Salud ' + this.practitionerProfile.firstName + ' ' + this.practitionerProfile.lastName1
    }
    if(typeof(Start) != 'string'){
      Start = Start[0]
    }
    const End = this.eventform.controls.End.value;

    let datePipe = formatDate(Start,'MMMM d, y',this.patientProfile.preferedLang);
    let timePipe = formatDate(Start,'hh:mm a',this.patientProfile.preferedLang);

    if(this.patientProfile.timezone.timeValue != this.userTimezone.split('UTC')[1]){
      let changeStart = new DatePipe('en-Us').transform(Start + ':00.000' + this.userTimezone.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.patientProfile.timezone.timeValue);
      datePipe = formatDate(changeStart,'MMMM d, y',this.patientProfile.preferedLang);
      timePipe = formatDate(changeStart,'hh:mm a',this.patientProfile.preferedLang);
    }

    let data = {
      id: this.firebaseService.getDate(),
      title: Title,
      start: Start,
      end: End,
      uidPractitioner: this.practitionerProfile.uid,
      practitionerEmail: this.practitionerProfile.email,
      uidPatient: this.patientProfile.uid,
      patientName: this.patientProfile.firstName + ' ' + this.patientProfile.lastName1,
      patientEmail: this.patientProfile.email,
      consultID: this.draftConsult.ID,
      conditionName: this.draftConsult.conditionName,
      dateCreated: new Date(Date.now()),
      room:"",
      description: description,
      meetRef: '',
      meetStatus: 'pending',
      eventTimezoneValue: this.userTimezone,
      duration: this.minutes
    }
    
    this.loading = true
    this.firebaseService.scheduleEvent(data);
    this.loading = false;

    this.creatingConsult = false;

    this.modalService.dismissAll();
  }

  getMinutes(): Array<number> {
    const mins: Array<number> = [];
    for (let i = 15; i <= 60; i = i + 5) {
      mins.push(i);
    }
    return mins;
  }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { remoteConfig } from 'src/environments/environment';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  settings = remoteConfig
  private DELETE_URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/deleteAnonymousUsers`;
  private DELETECONSULTS_URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/deleteAnonymousConsults`;
  private POST_URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/adminCreateUser`;
  public adminAccountCreation = `https://us-central1-${this.settings.env}.cloudfunctions.net/accountCreatedPractitioners`;
  //                             https://us-central1-${this.settings.env}.cloudfunctions.net/accountCreatedPractitioners
  public response:any;
  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService
  ) { }

  public deleteRequest(){
    return this.httpClient.get(this.DELETECONSULTS_URL);
  }

  createUser(email, password, accountType, typeOfPractitioner){
    let promise = new Promise((resolve, reject) => {
      let body = new HttpParams();
      body = body.set('email', email);
      body = body.set('password', password);
      body = body.set('accountType', accountType);
      body = body.set('typeOfPractitioner', typeOfPractitioner);
      
      this.httpClient.post<any>(this.POST_URL, body).subscribe(data => {
        this.response = data;
        resolve(data);
      });
    })

    return promise;
  }
  
  handleResponse(){
    return this.response;
  }

  assignRoles(users, claims) {
    const promise = new Promise((resolve) => {
      const functions = getFunctions();
      const assignRoles = httpsCallable(functions, 'adminAssignRoles');
      assignRoles({ users: users, claims: claims })
      .then(() => {
        resolve('success');
        // Read result of the Cloud Function.        
      });
    })

    return promise;
  }

  assignRolesToExistingUsers() {
    const promise = new Promise((resolve) => {
      const functions = getFunctions();
      const assignRoles = httpsCallable(functions, 'addRolesForExistingUsers');
      assignRoles().then(() => {
        resolve('success');
        // Read result of the Cloud Function.        
      });
    })

    return promise;
  }
}
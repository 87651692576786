import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { C } from '@fullcalendar/core/internal-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'src/app/core/interfaces/subscriptions';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { Profile } from 'src/app/core/services/interfaces';
import { SubscriptionsService } from 'src/app/core/services/subscriptions.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

  @ViewChild('historyRef',{ read: TemplateRef }) historyModal:TemplateRef<any>;
  @ViewChild('upgradeRef',{ read: TemplateRef }) upgradeModal:TemplateRef<any>;
  @ViewChild('actionRef',{ read: TemplateRef }) actionModal:TemplateRef<any>;
  @ViewChild('upgradeNotification',{ read: TemplateRef }) upgradeNotificationModal:TemplateRef<any>;
  @Input() patientUid = ''

  currentUser:Profile;
  modalRef:any;
  orders:any[] = [];
  subscriptions:any[] = [];
  currentSubscription:any;
  currentPromoProduct:any;
  ordersFromSubscription:any[] = [];

  card:boolean = false;
  allPaymentMethods:any[] = [];

  upgradeBillingTerm:number = 30;
  planList:any[] = [];

  possibleUpgradePlan:any;
  options = ['SUBSCRIPTIONS.CHANGEQTY', 'SUBSCRIPTIONS.CHANGEDOSE', 'SUBSCRIPTIONS.TTD']

  constructor(
    private db: AngularFirestore,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private firebaseService: FirebaseService,
    private subscriptionService: SubscriptionsService,
    private route: Router
  ) { }

  async ngOnInit() {
    this.currentUser = await this.authService.profile();
    this.patientUid = this.patientUid ? this.patientUid : this.currentUser.uid

    this.getSubscriptions(this.patientUid);
    this.getAcceptaCards(this.patientUid);
  }

  getSubscriptions(uid){
    this.db.collection("subscriptions", ref => ref.where("uid", "==", uid)).valueChanges().subscribe((subs:any) => {
      this.subscriptions = subs;
      subs.forEach((element, i) => {
        let obj 
        this.db.firestore.collection("products").doc(element.productID.toString()).get().then(async (product:any) => {
          if(product.exists){
            const { atr0,atr1, atr2, atr3, atr4, atr5, atr6, atr7, atr8, atr9, qty, ...other } = product.data();
            let atrs = {
              atr0,atr1, atr2, atr3, atr4, atr5, atr6, atr7, atr8, atr9, qty
            }
            let creditCardRef = await this.getCardInSubscription({uid: uid}, element.acceptaID);
            if(creditCardRef){
              element = Object.assign(element, creditCardRef)
            }
            obj = Object.assign(element, atrs);
            
            this.subscriptions[i] = obj;
          }
        });
      });
    });
  }

  async activateSubscription(subscription:any){
    await this.checkOverlap(subscription).then(res => {
      let swal:any;
      this.translate.get('SUBSCRIPTIONS.SWAL').subscribe((res: any) => {
        swal = res
      })
      if(res.length > 0){
        Swal.fire({
          icon:"info",
          title: swal.TITLE,
          text: swal.INFO,
          showConfirmButton: true,
          confirmButtonText: swal.CONFIRM,
          showCancelButton: true,
          cancelButtonText: swal.CLOSE
        }).then(swalRes => {
          if(swalRes.isConfirmed){
            res.forEach((sub, i) => {
              this.db.collection("subscriptions").doc(sub.id).update({state: "Closed", lastUpdatedBy: this.currentUser.uid}).then(res => {
                let history = {
                  date: new Date(Date.now()),
                  id: this.firebaseService.getDate(),
                  userUID: this.currentUser.uid,
                  user: this.currentUser.firstName + ' ' + this.currentUser.lastName1,
                  userAccountType: this.currentUser.accountType,
                  action: `Subscription closed by ${this.currentUser.accountType}`
                }
                this.db.collection("subscriptions").doc(sub.id).collection('History').doc(history.id).set(history)
              });
              if(i == res.length -1){
                this.db.collection("subscriptions").doc(subscription.id).update({state: "In Progress", lastUpdatedBy: this.currentUser.uid}).then(res => {
                  let history = {
                    date: new Date(Date.now()),
                    id: this.firebaseService.getDate(),
                    userUID: this.currentUser.uid,
                    user: this.currentUser.firstName + ' ' + this.currentUser.lastName1,
                    userAccountType: this.currentUser.accountType,
                    action: `Subscription activated by ${this.currentUser.accountType}`
                  }
                  this.db.collection("subscriptions").doc(subscription.id).collection('History').doc(history.id).set(history)
                })
              }
            });
          }
        })
      }
      else {
        this.db.collection("subscriptions").doc(subscription.id).update({state: "In Progress", lastUpdatedBy: this.currentUser.uid}).then(res => {
          let history = {
            date: new Date(Date.now()),
            id: this.firebaseService.getDate(),
            userUID: this.currentUser.uid,
            user: this.currentUser.firstName + ' ' + this.currentUser.lastName1,
            userAccountType: this.currentUser.accountType,
            action: `Subscription activated by ${this.currentUser.accountType}`
          }
          this.db.collection("subscriptions").doc(subscription.id).collection('History').doc(history.id).set(history)
        });
      }
    })
  }
  
  pauseSubscription(subscription:any){
    this.db.collection("subscriptions").doc(subscription.id).update({state: "Hold", lastUpdatedBy: this.currentUser.uid}).then(res => {
      let history = {
        date: new Date(Date.now()),
        id: this.firebaseService.getDate(),
        userUID: this.currentUser.uid,
        user: this.currentUser.firstName + ' ' + this.currentUser.lastName1,
        userAccountType: this.currentUser.accountType,
        action: `Paused subscription by ${this.currentUser.accountType}`
      }

      this.db.collection("subscriptions").doc(subscription.id).collection('History').doc(history.id).set(history)
    })
  }

  async subscriptionHistory(subscription){
    let plans = await this.subscriptionService.getProductsPlans(subscription.condition[0], 'products');
    this.currentPromoProduct = plans.filter(x => x.id == subscription.promoProductID)[0];
    this.currentSubscription = subscription;
    await this.getOrderHistory(subscription);
    this.modalRef = this.modalService.open(this.historyModal, {size: 'xl', backdrop: "static", windowClass:"historyModal"})
  }
  
  getOrderHistory(sub){
    return new Promise<any>((resolve, reject) => {
      this.db.collection('orders', ref => ref.where('subscriptionID', "==", sub.id)).valueChanges().subscribe(async (orders:any) => {
        this.orders = orders;
        resolve(true);
      });
    });
  }

  async checkOverlap(subscription){
    return new Promise<any>(async (resolve, reject) => {
      let active = await this.subscriptions.filter(x => x.condition.includes(subscription.condition[0]) && x.state == "In Progress");
      resolve(active);
    });
  }

  async getAcceptaCards(uid){
    this.db.collection("users").doc(uid).collection("wallet").valueChanges().subscribe(async (res:any) => {
      this.allPaymentMethods = await res.filter(x => x.active);
      if(this.allPaymentMethods.length > 0){
        this.card = true;
      }
    });
  }

  getCardInSubscription(user, acceptaID){
    return new Promise<any>((resolve, reject) => {
      if(acceptaID !== undefined && acceptaID !== "") {
         let data: any = this.allPaymentMethods.filter(p => p.customerNumber == acceptaID)[0];
        if(data){
          let creditCardRef = data.brand + "-" + data.last4 + " Expires " + data.expDate;
          let paymentMethod = {creditCardRef: creditCardRef, brand: data.brand, last4: data.last4};
          resolve(paymentMethod);
          return 
        }
        else {
          resolve(null)
        }
      }else{
        this.getAcceptaCards(user.uid);
      }
    })
  }

  async onItemChange(sub, event){
    let data = {
      creditCardRef: "",
      acceptaID: "",
    };
    if(event !== undefined){
      data.creditCardRef = event.brand + "-" + event.last4 + " Expires " + event.expDate;
      data.acceptaID = event.customerNumber;
    }
    this.db.collection("subscriptions").doc(sub.id).update({acceptaID: data.acceptaID}).then(res => {
      let history = {
        date: new Date(Date.now()),
        id: this.firebaseService.getDate(),
        userUID: this.currentUser.uid,
        user: this.currentUser.firstName + ' ' + this.currentUser.lastName1,
        userAccountType: this.currentUser.accountType,
        action: `${this.currentUser.accountType} updated credit card.`
      }
      this.db.collection("subscriptions").doc(sub.id).collection('History').doc(history.id).set(history)
    });
    this.getCardInSubscription({uid: this.patientUid}, data.acceptaID);
  } 

  async openUpgradeModal(subscription){
    let plans = await this.subscriptionService.getProductsPlans(subscription.condition[0], 'products');
    this.currentPromoProduct = plans.filter(x => x.id == subscription.promoProductID)[0];
    this.currentSubscription = subscription;

    if(!this.modalService.hasOpenModals()){
      this.modalRef = this.modalService.open(this.upgradeModal, {size: 'xl', backdrop: "static", keyboard:false, windowClass:"upgradeModal"})    
      this.upgradeBillingTerm = subscription.refillIntervalDays;
      this.plansList();
    }
  }

  async chooseActionModal(subscription){
    let plans = await this.subscriptionService.getProductsPlans(subscription.condition[0], 'products');
    this.currentPromoProduct = plans.filter(x => x.id == subscription.promoProductID)[0];
    this.currentSubscription = subscription;

    if(!this.modalService.hasOpenModals()){
      this.modalRef = this.modalService.open(this.actionModal, {size: 'md', backdrop: "static", centered: true, keyboard:false, windowClass:"upgradeModal"})    
      this.upgradeBillingTerm = subscription.refillIntervalDays;
      this.plansList();
    }
  }

  async nextAction(){
    await this.modalService.dismissAll();
    if(this.chooseOptions == 'SUBSCRIPTIONS.CHANGEQTY'){
      this.openUpgradeModal(this.currentSubscription)
    }
    else {     
       this.db.collection("consultTemplates").doc(this.currentSubscription.condition[0]).valueChanges().subscribe(async(condition:any) => {
         this.route.navigate(['/consultations'], {queryParams: {condition: condition.conditionCode}});
         this.modalRef.close();
         this.modalRef = undefined;
       })
    }
  }

  chooseOptions = ''
  selectOption(event) {
    // if (event.target.checked ) {
      this.chooseOptions = event.target.id;
    // }
    //  else {
    //   let index = this.chooseOptions.indexOf(event.target.id.toString())
    //   this.chooseOptions.splice(index, 1);
    // }
  }

  async plansList(){
    this.upgradeBillingTerm = this.upgradeBillingTerm == 30 ? 90 : 30;
    let plans:any = await this.subscriptionService.getProductsPlans(this.currentSubscription.condition[0], 'products');
    this.planList = plans.filter(x => x.refillIntervalDays == this.upgradeBillingTerm);
    this.filterProducts()
  }


  availablePlans = [];
  async openUpgradeNotificationModal(plan){
    this.possibleUpgradePlan = plan;
    // this.availablePlans = await this.subscriptionService.getProductsPlans(this.currentSubscription.condition[0], 'service');
    // this.availablePlans = this.availablePlans.filter(x => x.id == this.possibleUpgradePlan.subProductID);
    this.modalRef = this.modalService.open(this.upgradeNotificationModal, {size: 'md', centered: true, backdrop: "static", keyboard:false, windowClass:"upgradeModal"});
  }
  
  
  upgrade(){

    // this.db.collection("subscriptions").doc(this.currentSubscription.id).update({upgrade: true, upgradePlanID: this.possibleUpgradePlan.id}).then(() => {
    this.db.collection("subscriptions").doc(this.currentSubscription.id).update({upgrade: true, upgradePlanID: this.possibleUpgradePlan.id, promoProductIDUpgrade: this.possibleUpgradePlan.id}).then(() => {
      // this.db.collection("consultTemplates").doc(this.possibleUpgradePlan.condition[0]).valueChanges().subscribe(async(condition:any) => {
      //   this.route.navigate(['/consultations'], {queryParams: {condition: condition.conditionCode}});
        this.modalRef.close();
        this.modalService.dismissAll();
        this.modalRef = undefined;
      // });
    });
  }

  filteredProducts:any = []
  filterProducts(){
    this.filteredProducts = [];
      for(let i = 0; i < this.planList.length; i++){
        if(this.planList[i].atr0 != "<en>Doctor's Decision</en><es>Sugerencia Médica</es>" && this.planList[i].atr0 != '' && this.planList[i].type == 'product'){
          if(!this.filteredProducts[this.planList[i].atr0] && this.planList[i].atr0 == this.currentPromoProduct.atr0 && this.planList[i].atr1 == this.currentPromoProduct.atr1){
            this.filteredProducts[this.planList[i].atr0] = [{name: this.planList[i].atr1, products: []}]
          }

          for(let j = 0; j < this.filteredProducts[this.planList[i].atr0]?.length; j++){
            let test = this.filteredProducts[this.planList[i].atr0].filter(el => {
              return el.name === this.planList[i].atr1
            })

            if(test.length == 0 && this.planList[i].atr1 == this.currentPromoProduct.atr1){
              this.filteredProducts[this.planList[i].atr0].push({name: this.planList[i].atr1, products: []})
            }

            this.filteredProducts[this.planList[i].atr0] = this.filteredProducts[this.planList[i].atr0].sort((a, b) => {
              return a.name.split('mg')[0] - b.name.split('mg')[0]
            })

            if(this.planList[i].atr1 == this.filteredProducts[this.planList[i].atr0][j].name && this.planList[i].atr1 == this.currentPromoProduct.atr1 
              && ((this.planList[i].qty <= this.currentPromoProduct.qty && this.upgradeBillingTerm == 30) || this.planList[i].qty <= this.currentPromoProduct.qty * 3 && this.upgradeBillingTerm == 90)
              ){ 
              this.filteredProducts[this.planList[i].atr0][j].products.push(this.planList[i])
              this.filteredProducts[this.planList[i].atr0][j].products.sort((a, b) => {
                return a.name.split('- ')[1].split(" Pill")[0] - b.name.split('- ')[1].split(" Pill")[0];
              });    
            }
          }
        }
      }
  }
}
<div class="col-lg-12">
  <a class="btn btn-cyan" (click)="goBack()">
    {{'VIEWCONSULTS.RETURNTOHOME' | translate}}
  </a>
  <ul ngbNav #customNav="ngbNav" [activeId]="pageLoad()" class="nav-tabs nav-tabs-custom nav-justified" style="border-bottom: 0; margin: 20px -5px 0px;">
    <li [ngbNavItem]="1">
      <a ngbNavLink [routerLink]="['./summary']">
        <span class="d-block d-sm-none">Summary</span>
        <span class="d-none d-sm-block">{{'VIEWCONSULTS.CONSULTSUMMARY.CONSULTSUMMARY' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="ConsultSummary"></ng-template>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" (click)="prevMedicalHistory = false; filterQuestions();">
      <a ngbNavLink [routerLink]="['./medicalHistory']">
        <span class="d-block d-sm-none">Q&A</span>
        <span class="d-none d-sm-block">{{'PDF.QUESTIONNAIRE' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="MedicalHistoryUI"></ng-template>
        <!-- <ng-template [ngTemplateOutlet]="MedicalHistory"></ng-template> -->
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink [routerLink]="['./diagnosticsAndReview']">
        <span class="d-block d-sm-none">Dx</span>
        <span class="d-none d-sm-block">{{'VIEWCONSULTS.DIAGNOSTICS.DIAGNOSTICS' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="Diagnostic"></ng-template>
      </ng-template>
    </li>
    <li [ngbNavItem]="6">
      <a ngbNavLink [routerLink]="['./messages']">
        <span class="d-block d-sm-none">MSG</span>
        <span class="d-none d-sm-block">{{'VIEWCONSULTS.MESSAGES' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="Messages"></ng-template>
      </ng-template>
    </li>
    <li [ngbNavItem]="9">
      <a ngbNavLink [routerLink]="['./prescription']">
        <span class="d-block d-sm-none">Rx & Lab</span>
        <span class="d-none d-sm-block">{{'VIEWCONSULTS.PRESCRIPTIONS' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
       <div *ngIf="!collaborator">
         <h2 class="text-center bold">{{'VIEWCONSULTS.PRESCRIPTIONS' | translate | uppercase}}</h2>
         <hr style="margin: auto; margin-bottom: 20px; border: none; border-bottom: 2px dotted #e9ebee;">
         <app-consult-prescriptions [disabled]="collaborator" *ngIf="consult && patientProfile" [consult]="consult" [patient]="patientProfile" [currentPresc]="prescriptionID"></app-consult-prescriptions>
       </div>
       <div *ngIf="collaborator">
          <h2>{{'CONSULT-PRESCRIPTIONS.ACCESSDENIED' | translate}}</h2>
       </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="10">
        <a ngbNavLink [routerLink]="['./closingSignature']">
          <span class="d-block d-sm-none">Closing</span>
          <span class="d-none d-sm-block">{{'VIEWCONSULTS.CLOSINGSIGNATURE' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="closing"></ng-template>
        </ng-template>
    </li>
    <li [ngbNavItem]="11">
      <a ngbNavLink [routerLink]="['./followUp']">
          <span class="d-block d-sm-none">Follow Up</span>
          <span class="d-none d-sm-block">{{'VIEWCONSULTS.FOLLOWUP' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="followUp"></ng-template>
      </ng-template>
    </li>
    <li *ngIf="consult && currentUser?.accountType == 'admin'" [ngbNavItem]="14">
      <a ngbNavLink [routerLink]="['./orders']">
        <span class="d-block d-sm-none">Orders</span>
        <span class="d-none d-sm-block">{{'ORDERS.ORDERS' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
        <app-orders [consult]="consult" [consultOrdersDetails]="true" [specificConsultRef]=""></app-orders>
      </ng-template>
    </li>
    <li *ngIf="consult && currentUser?.accountType == 'admin'" [ngbNavItem]="15">
      <a ngbNavLink [routerLink]="['./consultHistory']">
        <span class="d-block d-sm-none">History</span>
        <span class="d-none d-sm-block">History</span>
      </a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="consultHistory"></ng-template>
      </ng-template>
    </li>
    <li [ngbNavItem]="17">
      <a ngbNavLink [routerLink]="['./others']">
        <span class="d-block d-sm-none">Others</span>
        <span class="d-none d-sm-block">{{'VIEWCONSULTS.OTHERS' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="others"></ng-template>
      </ng-template>
    </li>
  </ul>
  <div class="mt-4" [ngbNavOutlet]="customNav"></div>
</div>

<ng-template #ConsultSummary>
  <div *ngIf="consult" class="consult-sections">
    <h2 class="text-center bold">{{'VIEWCONSULTS.CONSULTSUMMARY.CONSULTSUMMARY' | translate}} : {{consult.conditionName | translation:currentLang}} </h2>
    <hr style="margin: auto; margin-bottom: 5px; border: none; border-bottom: 2px dotted #e9ebee;">
    <div class="row">
      <!-- Profile -->
      <div class="w-100 py-0" style="padding: calc(2% + 3px);">
        <span class="bold mb-0 cyan customAlignment" style="font-size: 22px;">{{fullName}}</span>
        <div class="summary-content">
          <div class="d-flex flex-column py-0 customAlignment">  
            <img style="width: 100px; height: 100px;" src="{{consult.image}}">
          </div>
          <div class="summary-details" style="align-self: end;">
            <div class="d-flex">         
              <div class="row flex-column" style="width: 55.5%; margin-right: 4.5% !important;">
                <h6 class="bold">{{'VIEWCONSULTS.PATIENTPROFILE.EMAIL' | translate}}</h6>
                <span *ngIf="patientProfile">{{patientProfile.email}}</span>
              </div>
              <div class="row flex-column" style="width: 35.5%; margin-left: 4.5% !important;">
                <h6 class="bold">{{'VIEWCONSULTS.PATIENTPROFILE.DATEOFBIRTH' | translate}}</h6>
                <span>{{patientProfile?.birthdate | date:'MMM dd, YYYY':'':currentLang | titlecase}} | ({{age}} {{'VIEWCONSULTS.PATIENTPROFILE.YEARSOLD' | translate}})</span>
                <!-- <span>{{age}}</span> -->
              </div>
            </div>
            <div class="d-flex">
              <div class="row flex-column" style="width: 55.5%; margin-right: 4.5% !important;">
                <h6 class="bold">{{'VIEWCONSULTS.PATIENTPROFILE.PHONE' | translate}}</h6>
                <span *ngIf="patientProfile">{{patientProfile.phoneNumber | mask:'(000) 000-0000'}}</span>
              </div>
              <div class="row flex-column" style="width: 35.5%; margin-left: 4.5% !important;">
                <h6 class="bold">{{'VIEWCONSULTS.GENDER' | translate}}</h6>
                <span *ngIf="bioGender == 'Male'">{{'PROFILE.MALE' | translate}}</span>
                <span *ngIf="bioGender == 'Female'">{{'PROFILE.FEMALE' | translate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button [disabled]="collaborator" *ngIf="claims?.devAdmin || claims?.accountType == 'admin'" class="btn button btn-primary mt-2" (click)="adminProfile()">{{'CONSULT-PRESCRIPTIONS.SWAL.GOTOPROFILE' | translate}}</button>
      <div class="summary-content mt-2 box1" style="width: 100%;">
        <div class="summary-details">
          <div>      
            <!-- Schedule and Tests-->
            <div class="d-flex">
              <div class="row" style="width: 45.5%; margin-right: 4.5% !important;">
                <h6 class="bold" style="width: fit-content;">{{'VIEWCONSULTS.CONSULTSUMMARY.TYPEOFSCHEDULE' | translate}}</h6>
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span>{{consult.schedule?.type | translation:currentLang}}</span>
                    <span>{{consult.schedule?.time | translation:currentLang}}</span>
                  </div>
                </div>
              </div>
              <div *ngIf="consult?.diagnosticsTests" class="row" style="width: 45.5%; margin-left: 4.5% !important;">
                <h6 class="bold">{{'VIEWCONSULTS.DIAGNOSTICS.TESTS' | translate}}</h6>
                <span *ngFor="let d of consult?.diagnosticsTests; let i = index">{{d}} - {{consult.diagnosticsTestsPoints[i]}}pts.</span>
              </div>
            </div>

            <!-- Date, Lang -->
            <hr class="mt-0">
            <div class="d-flex">
              <div class="d-flex flex-column" style="width: 45.5%; margin-right: 4.5% !important;">
                <h6 class="bold">{{'VIEWCONSULTS.CONSULTDATE' | translate}}</h6>
                <span>{{consult.Date.toDate() | date:'MMMM dd, yyyy hh:mm a':'':currentLang | titlecase}}</span>
              </div>
              <div class="row" style="width: 45.5%; margin-left: 4.5% !important;">
                <h6 class="bold">{{'VIEWCONSULTS.CONSULTLANGUAGE' | translate}}</h6>
                <span *ngIf="consult.consultLanguage == 'en'">{{'VIEWCONSULTS.ENGLISH' | translate}}</span>
                <span *ngIf="consult.consultLanguage == 'es'">{{'VIEWCONSULTS.SPANISH' | translate}}</span>
              </div>        
            </div>       

            <hr class="mt-0">
            <div class="d-flex">
              <div style="width: 45.5%; margin-right: 4.5% !important;">
                <!-- Diagnostics -->
                <div class="row">
                  <h6 class="bold">{{'VIEWCONSULTS.DIAGNOSTICS.DIAGNOSTICS' | translate}}</h6>
                  <span *ngFor="let d of selectedDiagnostics">{{d.code_name | translation:currentLang}}</span>
                  <span *ngIf="selectedDiagnostics && selectedDiagnostics.length == 0">{{'PRACTITIONER-CONSULTS.NODIAGNOSTICS' | translate}}</span>
                </div> 
    
                <!-- Rx & Lab -->
                <hr class="mt-0">
                <div class="row">
                  <h6 class="bold">{{'VIEWCONSULTS.PRESCRIPTIONS' | translate}}</h6>
                  <span *ngFor="let p of prescriptionItems">{{p.productName ? p.productName : p.labName}}</span>
                  <span *ngIf="prescriptionItems && prescriptionItems.length == 0">{{'PRACTITIONER-CONSULTS.NOPRESCRIPTIONS' | translate}}</span>
                </div>
    
                <!-- Notes -->
                <hr class="mt-0">
                <div class="row">
                  <h6 class="bold">{{'VIEWCONSULTS.NOTES.NOTES' | translate}}</h6>
                  <span *ngFor="let note of notes">{{(note.encrypted ? 'Private Note' : note.title) | translation:currentLang}}</span>
                  <span *ngIf="notes && notes.length == 0">{{'PRACTITIONER-CONSULTS.NONOTES' | translate}}</span>
                </div>

                <!-- Location -->
                <hr class="mt-0">
                <div class="row">
                  <h6 class="bold">{{'PRACTITIONER-CONSULTS.LOCATION' | translate}}</h6>
                  <span>{{consult.state.name}}, {{consult.country}}</span>
                </div>

                <!-- Patient Address -->
                <hr class="mt-0">
                <div *ngIf="patientAddress" class="row">
                  <h6 class="bold">{{'CONSULT-PRESCRIPTIONS.ADDRESS' | translate}}</h6>
                  <span>{{patientAddress.AddressLine1}} {{patientAddress.AddressLine2}}</span>
                  <span>{{patientAddress.AddressCity}}, {{patientAddress.AddressState}} {{patientAddress.AddressZipcode}} {{patientAddress.AddressCountry}}</span>
                </div>
              </div>
              <div style="width: 45%; margin-left: 4.5% !important;">
                <!-- History -->
                <div class="row">
                  <h6 class="bold">{{'VIEWCONSULTS.MEDICALHISTORY' | translate}}</h6>
                  <span *ngFor="let consult of allConsults; let i = index">
                    <div *ngIf="i < 5">
                      {{consult.conditionName | translation:currentLang}}
                    </div>
                    <div *ngIf="i == 5">
                      <a disabled href="javascript:void(0);">{{allConsults.length - 5}}+</a>
                    </div>
                  </span>
                  <span *ngIf="allConsults.length == 0">{{'PRACTITIONER-CONSULTS.NOCONSULTS' | translate}}</span>
                </div>
    
                <!-- Alergies -->
                <hr class="mt-0">
                <div class="row">
                  <h6 class="bold">{{'VIEWCONSULTS.ALLERGIES' | translate}}</h6>
                  <span *ngIf="allergies;">
                    <p class="m-0">{{allergies}}</p>
                  </span>
                  <span *ngIf="!allergies">{{'PRACTITIONER-CONSULTS.NOALLERGIES' | translate}}</span>
                </div>

                <!-- Status -->
                <hr class="mt-0">
                <div class="d-flex flex-column">
                  <h6 class="bold">{{'VIEWCONSULTS.CONSULTSUMMARY.CONSULTSTATUS' | translate}}</h6>
                  <span class="success" *ngIf="consult.Status === 'Completed'">{{'PRACTITIONER-CONSULTS.COMPLETED' | translate}}</span>
                  <span *ngIf="consult.Status === 'Paid'" class="text-success"><i class="fa fa-check-circle mr-2" aria-hidden="true"></i> {{'PRACTITIONER-CONSULTS.PAID' | translate}}</span>
                  <span  *ngIf="consult.Status === 'In Progress'" class="text-primary"><i class="fa fa-spinner mr-2" aria-hidden="true"></i>{{'PRACTITIONER-CONSULTS.INPROGRESS' | translate}}</span>
                  <span *ngIf="consult.Status === 'Canceled'" class="text-danger canceled"><i class="fa fa-ban mr-2" aria-hidden="true"></i> {{'PRACTITIONER-CONSULTS.CANCELED' | translate}}</span>
                  <span class="text-primary" *ngIf="consult.Status === 'Open'">{{'PRACTITIONER-CONSULTS.OPEN' | translate}}</span>
                  <span class="canceled" *ngIf="consult.Status === 'Denied'">{{'PRACTITIONER-CONSULTS.DENIED' | translate}}</span>
                  <span class="text-primary" *ngIf="consult.Status === 'Draft'">{{'PRACTITIONER-CONSULTS.DRAFT' | translate}}</span>
                </div>  
              </div>
            </div>

            <!-- Transfered -->
            <hr *ngIf="consult.transfered == 'Temporarly'" class="mt-0">
            <div *ngIf="consult.transfered == 'Temporarly'" class="d-flex">
              <div class="row">
                <h6 class="bold" style="width: fit-content;">{{'VIEWCONSULTS.TEMPTRANSFER' | translate}}</h6>
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span>{{'VIEWCONSULTS.MHCP' | translate}}: {{transferInfo?.user}}</span>
                    <span>{{'VIEWCONSULTS.TRANSFEREDDATE' | translate}}: {{transferInfo?.date.toDate() | date:'MMMM dd, yyyy hh:mm aa':'':currentLang | titlecase}}</span>
                    <span>{{'VIEWCONSULTS.TRANSFERREASON' | translate}}: {{transferInfo?.reason}}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Past Practitioners in Consult -->
            <hr *ngIf="pastPractitioners && pastPractitioners.length != 0" class="mt-0">
            <div *ngIf="pastPractitioners && pastPractitioners.length != 0" class="d-flex">
              <div class="row">
                <h6 class="bold">{{'VIEWCONSULTS.PASTPRACTITIONERS' | translate}}</h6>
                <span *ngFor="let practitioner of pastPractitioners">
                  {{practitioner.user}} - {{practitioner.date.toDate() | date:'MMMM dd, yyyy hh:mm aa':'':currentLang | titlecase}}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="summary-img p-0 d-flex justify-content-center flex-column">
          <app-body-anatomy *ngIf="patientProfile" [patient]="patientProfile"></app-body-anatomy>
        </div>
      </div>
    </div>
    <div class="d-flex mt-2 justify-content-center align-items-center">
      <div class="buttons">
        <button [disabled]="consult?.Status == 'Completed' || collaborator" *ngIf="(patientProfile?.status == 'unverified' || patientProfile?.status == 'none') && currentUser?.accountType == 'admin'" class="btn btn-info button btn-cyan mb-2" role="button" (click)="verifyAccount()" id="verifyBtn">{{'VIEWCONSULTS.VERIFYPATIENT' | translate}}</button>
        <button *ngIf="patientProfile?.status == 'Verified' && currentUser?.accountType == 'admin'" class="btn btn-info button btn-cyan mb-2" role="button" disabled>{{'PROFILE.VERIFIED' | translate}}</button>
        <button [disabled]="collaborator" *ngIf="consult.Status == 'In Progress' && !collaborator" class="btn btn-info button mb-2" role="button" (click)="abandonReopenConsultModal(abandonModal)">{{'VIEWCONSULTS.CONSULTSUMMARY.ABANDONCONSULT' | translate}}</button>
        <button [disabled]="collaborator" *ngIf="consult.Status != 'Denied' && consult.Status != 'Completed' && (claims?.devAdmin || claims?.superAdmin)" class="btn btn-info button mb-2" role="button" (click)="deniedConsultationByPatient()">{{'VIEWCONSULTS.CONSULTSUMMARY.DENYCONSULTATION' | translate}}</button>
        <button [disabled]="collaborator" *ngIf="consult.Status != 'Denied' && consult.Status != 'Completed' && (claims?.devAdmin || claims?.superAdmin)" class="btn btn-info button mb-2" role="button" (click)="updateConsultStatus('Denied')">{{'VIEWCONSULTS.CONSULTSUMMARY.DENYCONSULTATIONFORPATIENT' | translate}}</button>
        <button [disabled]="collaborator" *ngIf="consult.Status == 'Completed' && currentUser?.accountType == 'admin'" class="btn btn-info button" role="button" (click)="abandonReopenConsultModal(reopenModal)">{{'VIEWCONSULTS.CONSULTSUMMARY.REOPENCONSULT' | translate}}</button>
        <button [disabled]="collaborator" *ngIf="consult.Status == 'In Progress' && !consult.transfered" class="btn btn-info button mt-2" role="button" (click)="transfer()">{{'VIEWCONSULTS.TRANSFERCONSULT' | translate}}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #PatientProfile>
  <div *ngIf="patientProfile">
    <h2 class="text-center bold">{{'VIEWCONSULTS.PATIENTPROFILE.PATIENTPROFILE' | translate | uppercase}}</h2>
    <hr style="margin: auto; margin-bottom: 20px; border: none; border-bottom: 2px dotted #e9ebee;">        
    <div class="patient-profile" style="display: flex; align-items: center; justify-content: center;">
      <div class="profileImg">
        <h6 class="center">{{'VIEWCONSULTS.PATIENTPROFILE.PROFILEPICTURE' | translate}}</h6>
        <img src="{{consult.image}}" alt="">
        <div class="buttons justify-content-center mt-2 mx-auto">
          <button [disabled]="consult?.Status == 'Completed' || collaborator" *ngIf="patientProfile?.status == 'unverified' || patientProfile?.status == 'none'" class="btn btn-info button btn-cyan" role="button" (click)="verifyAccount()" id="verifyBtn">{{'VIEWCONSULTS.VERIFYPATIENT' | translate}}</button>
          <button *ngIf="patientProfile?.status == 'Verified'" class="btn btn-info button btn-cyan" role="button" disabled>{{'PROFILE.VERIFIED' | translate}}</button>
          <button [disabled]="consult?.Status == 'Completed' || collaborator" class="btn btn-primary button mt-2" (click)="openProfileModal()">{{'VIEWCONSULTS.REQUESTUPDATE' | translate}}</button>
          <p *ngIf="lastIDReminderMsg" style="color: red;">Last Reminder Date: {{lastIDReminderMsg | date:'MMMM dd, yyyy hh:mm aa'}}</p>
        </div>
      </div>
      <div class="profile p-4 pt-0 ml-3" style="width: auto; font-size: 16px;">
        <div class="patient-name">
          <h6>{{'VIEWCONSULTS.PATIENTPROFILE.FULLNAME' | translate}}</h6>
          <span class="labels">{{fullName}}</span>
        </div>
        <hr class="mt-0">
        <div class="patient-birthdate">
          <h6>{{'VIEWCONSULTS.PATIENTPROFILE.DATEOFBIRTH' | translate}}</h6>
          <span class="labels">{{patientProfile.birthdate}} | ({{age}} {{'VIEWCONSULTS.PATIENTPROFILE.YEARSOLD' | translate}})</span>
        </div>
        <hr class="mt-0">
        <div class="patient-contact">
          <h6>{{'VIEWCONSULTS.PATIENTPROFILE.EMAIL&PHONE' | translate}}</h6>
          <span class="labels">{{patientProfile.email}}</span><br>
          <a href="{{'tel:'+patientProfile.phoneNumber}}">{{patientProfile.phoneNumber | mask:'(000) 000-0000'}}</a>
        </div>
        <hr class="mt-0">
        <!-- <button *ngIf="claims.devAdmin || claims.accountType == 'admin'" class="btn button btn-primary" (click)="adminProfile()">{{'CONSULT-PRESCRIPTIONS.SWAL.GOTOPROFILE' | translate}}</button> -->
      </div>     
    </div>
    <!-- <h6 class="center mt-4">{{'VIEWCONSULTS.PATIENTPROFILE.PATIENTID' | translate}}</h6>
    <div *ngIf="consult" class="d-flex justify-content-center idMobile">
      <div class="patient-identification">
        <img class="id" src="{{consult?.identification}}" alt="">
      </div>
    </div> -->
  </div>
</ng-template>

<ng-template #MedicalHistory>
  <div class="consult-sections">
    <h2 class="text-center bold">{{'PDF.QUESTIONNAIRE' | translate | uppercase}}</h2>
    <hr style="margin: auto; margin-bottom: 20px; border: none; border-bottom: 2px dotted #e9ebee;">
  
    <div *ngIf="consult" class="justify-content-center d-flex mobileBtn">
      <button class="btn btn-info btn-cyan mr-4" (click)="filterQuestions()">{{'VIEWCONSULTS.ALLQUESTIONS' | translate}}</button>
      <button class="btn btn-info btn-cyan mr-4" (click)="filterRedFlags()">{{'VIEWCONSULTS.REDFLAGS' | translate}}</button>
      <button class="btn btn-info btn-cyan mr-4" (click)="filterExplain()">{{'VIEWCONSULTS.PATIENTEXPLANATION' | translate}}</button>
      <button *ngFor="let i of consult?.diagnosticsTests" class="btn btn-info btn-cyan mr-4" (click)="filterDiagnosticsTests(i)">{{i}}</button>
    </div>

    <h4 class="bold d-flex justify-content-center mt-2">{{btnTitles | translate}} <span *ngIf="scalePosition != -1">- {{consult?.diagnosticsTestsPoints[scalePosition]}}pts.</span></h4>
    <div *ngIf="diagnosticsTests" class="d-flex justify-content-center m-2 mt-3">
      <table style="border-collapse: collapse; border: 1px solid;">
        <thead>
          <tr>
            <th style="border: 1px solid;">{{'VIEWCONSULTS.SCORE' | translate}}</th>
            <th style="border: 1px solid;">{{'VIEWCONSULTS.SYMPTOMSEVERITY' | translate}}</th>
            <th style="border: 1px solid;">{{'VIEWCONSULTS.COMMENTS' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let scale of consult.diagnosticsTestsScale[scalePosition].scale">
            <th style="border: 1px solid black;" [ngClass]="{'diagnosticsScore': consult.diagnosticsTestsPoints[scalePosition] >= scale.score[0] && consult.diagnosticsTestsPoints[scalePosition] <= scale.score[1]}">{{scale.score[0]}} - {{scale.score[1]}}</th>
            <th style="border: 1px solid black;" [ngClass]="{'diagnosticsScore': consult.diagnosticsTestsPoints[scalePosition] >= scale.score[0] && consult.diagnosticsTestsPoints[scalePosition] <= scale.score[1]}">{{scale.symptomSeverity | translation:currentLang}}</th>
            <th style="border: 1px solid black;" [ngClass]="{'diagnosticsScore': consult.diagnosticsTestsPoints[scalePosition] >= scale.score[0] && consult.diagnosticsTestsPoints[scalePosition] <= scale.score[1]}">{{scale.comments | translation:currentLang}}</th>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="row consult-questions">
      <div class="col-lg-12">
        <div class="questions-container align-items-start mx-auto" *ngIf="isQuestionsLoaded; else loading">
          <div *ngFor="let question of filteredQuestions; let i = index" class="question-card flex-column mx-auto">
            <div class="question">
              <span class="m-0 optionFont">{{question.question | translation: currentLang}}</span>
            </div>
            <hr style="width: -webkit-fill-available;">
              <!-- <p class="answer">Answered: <span class="text-info">{{question.answer | translation: currentLang}}</span></p>
              <span *ngIf="question.optionAnswer" class="answer text-info">{{question.optionAnswer | translation: currentLang}}</span> -->
            <div *ngIf="question.options || question.type == 'BMI' || question.type == 'Text' || question.type == 'slider'" class="answers">
              <span *ngIf="question.options">{{'CONSULTQUESTIONS.OPTIONS' | translate}}</span>
              <div *ngIf="(question.type == 'SearchMedicines' || question.type == 'SearchConditions' || question.type == 'SearchSurgeries') && question.optionAnswer == ''">
                <div *ngFor="let answer of question.answer">
                  <span class="ml-2" [ngClass]="{'text-primary': true}">- {{answer | translation:currentLang}}</span>          
                </div>
              </div>
              <div *ngIf="question.type == 'slider'">
                <span class="ml-2" [ngClass]="{'text-primary': true}">- {{question.answer}} {{question.rangeTarget | translation:currentLang}}</span>          
              </div>
              <div *ngFor="let option of question.options" class="ml-2">
                <span *ngIf="question.type != 'BP' && question.displayType != 'image' && question.displayType != 'textImage' && question.type != 'SelectionWithOptions' && question.type != 'MultipleSelectionWithOptions'" [ngClass]="optionStyling(question.answer.includes(option) || question.answer == option || question.optionAnswer == option || question.answer[0] == option || question.answer == option.options, i)">- {{option | translation:currentLang}}</span>
                <span *ngIf="question.type == 'SelectionWithOptions' || question.type == 'MultipleSelectionWithOptions'" [ngClass]="optionStyling(question.answer.includes(option) || question.answer == option || question.optionAnswer == option || question.answer[0] == option || question.answer == option.options,i)">- {{option.options | translation:currentLang}}</span>
                <span *ngIf="question.displayType == 'textImage'" [ngClass]="optionStyling( question.answer.text == option.text,i)">- {{option.text | translation:currentLang}}</span>
                <img *ngIf="question.displayType == 'textImage'" style="width: 100px;" src="{{option.image}}">
                <img *ngIf="question.displayType == 'image'" style="width: 100px;" src="{{option}}">
                <div *ngIf="typeOfOption(option)">
                  <div *ngFor="let subOption of option?.subOptions">
                    <span class="ml-3" [ngClass]="optionStyling(question.answer.includes(subOption) || question.answer == subOption || (question.optionAnswer == subOption && question.answer == option.options) || question.answer[0] == subOption || question.answer == option.subOption,i)">- {{subOption | translation:currentLang}}</span>          
                  </div>
      
                  <!-- <span *ngIf="option.systolic">{{'CONSULTATIONS.SYSTOLIC' | translate}}:</span>
                  <div *ngFor="let systolic of option?.systolic">
                    <span class="ml-3" [ngClass]="optionStyling(question.answer[0] == systolic,i)">- {{systolic | translation:currentLang}}</span>          
                  </div>
                  <span *ngIf="option.diastolic">{{'CONSULTATIONS.DIASTOLIC' | translate}}:</span>
                  <div *ngFor="let diastolic of option?.diastolic">
                    <span class="ml-3" [ngClass]="optionStyling(question.answer[1] == diastolic,i)">- {{diastolic | translation:currentLang}}</span>          
                  </div> -->
                  <div *ngIf="option.systolic">
                    <span>{{'CONSULTATIONS.SYSTOLIC' | translate}}: {{question.answer[0]}}</span>
                  </div>
                  <div *ngIf="option.diastolic">
                    <span>{{'CONSULTATIONS.DIASTOLIC' | translate}}: {{question.answer[1]}}</span>
                  </div>
                </div>
              </div>
              <div *ngIf="question.type == 'BMI'">
                <span>{{'CONSULTATIONS.HEIGHT' | translate}}:<br>- {{question.answer[0]}} {{'CONSULTATIONS.FEET' | translate}} {{question.answer[1]}} {{'CONSULTATIONS.INCHES' | translate}}</span><br>
                <span>{{'CONSULTATIONS.WEIGHT' | translate}}:<br>- {{question.answer[2]}} {{'CONSULTATIONS.POUNDS' | translate}}</span><br>
                <span>{{'CONSULTATIONS.BMI' | translate}}: {{question.answer[3] | translation:currentLang}}</span>
              </div>
              <span *ngIf="question.explain" class="d-flex justify-content-start" >{{question?.subquestion | translation:currentLang}}</span>
              <span *ngIf="question.explain">{{'CONSULTQUESTIONS.EXPLANATION' | translate}}: {{question.explain}}</span>
              <span *ngIf="!question.explain && question.required == false">N/A</span>
              <span *ngIf="question.lastQuestion && (claims?.devAdmin || claims?.superAdmin)">Signed By: {{question.signedBy}} Ref: {{question.signRef}}</span>
              <span *ngIf="question.lastQuestion && question.dateCertified && (claims?.devAdmin || claims?.superAdmin)">Date: {{question.dateCertified.toDate() | date:'yyyy-MM-dd h:mm a'}}</span>
            </div>
          </div>
          <div *ngIf="btnTitles == 'VIEWCONSULTS.ALLQUESTIONS' || btnTitles == 'VIEWCONSULTS.PATIENTEXPLANATION'" class="question-card flex-column mx-auto">
            <div class="question">
              <span class="m-0 optionFont">{{'CONSULTATIONS.INFOFORMED' | translate}}</span>
            </div>
            <hr style="width: -webkit-fill-available;">
            <div class="answers">
              <div>
                <div *ngFor="let w of selectedWishlist">
                  <span class="ml-2" [ngClass]="{'text-primary': true}">- {{w.name | translation:currentLang}}</span>          
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>



<ng-template #MedicalHistoryUI>
  <div class="consult-sections mb-2">
    <h2 class="text-center bold">{{'PDF.QUESTIONNAIRE' | translate | uppercase}}</h2>
    <hr style="margin: auto; margin-bottom: 20px; border: none; border-bottom: 2px dotted #e9ebee;">
    
    <div *ngIf="consult" class="justify-content-center d-flex mobileBtn">
      <button class="btn btn-info btn-cyan mr-4" (click)="filterQuestions()">{{'VIEWCONSULTS.ALLQUESTIONS' | translate}}</button>
      <button class="btn btn-info btn-cyan mr-4" (click)="filterRedFlags()">{{'VIEWCONSULTS.REDFLAGS' | translate}}</button>
      <button class="btn btn-info btn-cyan mr-4" (click)="filterExplain()">{{'VIEWCONSULTS.PATIENTEXPLANATION' | translate}}</button>
      <button *ngFor="let i of consult?.diagnosticsTests" class="btn btn-info btn-cyan mr-4" (click)="filterDiagnosticsTests(i)">{{i}}</button>
    </div>

    <h4 class="bold d-flex justify-content-center mt-2">{{btnTitles | translate}} <span *ngIf="scalePosition != -1">- {{consult?.diagnosticsTestsPoints[scalePosition]}}pts.</span></h4>
    <div *ngIf="diagnosticsTests" class="d-flex justify-content-center m-2 mt-3">
      <table style="border-collapse: collapse; border: 1px solid;">
        <thead>
          <tr>
            <th style="border: 1px solid;">{{'VIEWCONSULTS.SCORE' | translate}}</th>
            <th style="border: 1px solid;">{{'VIEWCONSULTS.SYMPTOMSEVERITY' | translate}}</th>
            <th style="border: 1px solid;">{{'VIEWCONSULTS.COMMENTS' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let scale of consult.diagnosticsTestsScale[scalePosition].scale">
            <th style="border: 1px solid black;" [ngClass]="{'diagnosticsScore': consult.diagnosticsTestsPoints[scalePosition] >= scale.score[0] && consult.diagnosticsTestsPoints[scalePosition] <= scale.score[1]}">{{scale.score[0]}} - {{scale.score[1]}}</th>
            <th style="border: 1px solid black;" [ngClass]="{'diagnosticsScore': consult.diagnosticsTestsPoints[scalePosition] >= scale.score[0] && consult.diagnosticsTestsPoints[scalePosition] <= scale.score[1]}">{{scale.symptomSeverity | translation:currentLang}}</th>
            <th style="border: 1px solid black;" [ngClass]="{'diagnosticsScore': consult.diagnosticsTestsPoints[scalePosition] >= scale.score[0] && consult.diagnosticsTestsPoints[scalePosition] <= scale.score[1]}">{{scale.comments | translation:currentLang}}</th>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngFor="let question of filteredQuestions; let i = index" class="questions-answers mx-auto">
      <div class="d-flex">
        <!-- <span class="question-count">{{i}}</span> -->
        <span class="title">{{question.question | translation: currentLang}}</span><br>
        <!-- <h4 class="title">{{question.question | translation: currentLang}}</h4> -->
      </div>
      <p>{{question.info | translation: currentLang}}</p>
      <div *ngIf="question.options || question.type == 'BMI' || question.type == 'Text' || question.type == 'slider'" class="answers">
        <div  *ngIf="(question.type == 'SearchMedicines' || question.type == 'SearchConditions' || question.type == 'SearchSurgeries') && question.optionAnswer == ''">
          <div class="answer" *ngFor="let answer of question.answer">
            <svg class="answer-icon"  width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
              <path d="m9 12 2 2 4-4"></path>
            </svg>
            <span class="ml-2">{{answer | translation:currentLang}}</span>          
          </div>
        </div>
        <div class="answer" *ngIf="question.type == 'slider'">
          <span class="ml-2">{{question.answer}} {{question.rangeTarget | translation:currentLang}}</span>          
        </div>
        <div *ngFor="let option of question.options" class="answer d-flex">
          <svg *ngIf="question.displayType != 'image'" id="answered-icon" 
            [ngClass]="{'answer-icon': (question.answer.includes(option) || question.answer == option || question.optionAnswer == option || question.answer[0] == option || question.answer == option.options) && question.points == 0,
                      'answered-icon-risk': (question.answer.includes(option) || question.answer == option || question.optionAnswer == option || question.answer[0] == option || question.answer == option.options) && question.points > 0,
                      'answered-icon-hide': !(question.answer.includes(option) || question.answer == option || question.optionAnswer == option || question.answer[0] == option || question.answer == option.options) }"
            width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
            <path d="m9 12 2 2 4-4"></path>
          </svg>
          <div class="d-flex flex-column">
            <span *ngIf="question.type != 'BP' && question.displayType != 'image' && question.displayType != 'textImage' && question.type != 'SelectionWithOptions' && question.type != 'MultipleSelectionWithOptions'">{{option | translation:currentLang}}</span>
            <span *ngIf="question.type == 'SelectionWithOptions' || question.type == 'MultipleSelectionWithOptions'">{{option.options | translation:currentLang}}</span>
            <span *ngIf="question.displayType == 'textImage'">
              <svg id="answered-icon" 
                [ngClass]="{'answer-icon': (question.answer.includes(option) || question.answer == option || question.optionAnswer == option || question.answer[0] == option || question.answer == option.options) && question.points == 0,
                          'answered-icon-risk': (question.answer.includes(option) || question.answer == option || question.optionAnswer == option || question.answer[0] == option || question.answer == option.options) && question.points > 0,
                          'answered-icon-hide': !(question.answer.includes(option) || question.answer == option || question.optionAnswer == option || question.answer[0] == option || question.answer == option.options) }"
                width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
                <path d="m9 12 2 2 4-4"></path>
              </svg>
              {{option.text | translation:currentLang}}
            </span>
            <span *ngIf="question.displayType == 'image'">
              <svg id="answered-icon" 
                [ngClass]="{'answer-icon': question.answer.text == option && question.points == 0,
                          'answered-icon-risk': question.answer.text == option && question.points > 0,
                          'answered-icon-hide': question.answer.text != option }"
                width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
                <path d="m9 12 2 2 4-4"></path>
              </svg>
              {{option.text | translation:currentLang}}
            </span>
            <img *ngIf="question.displayType == 'textImage'" style="width: 100px;" src="{{option.image}}">
            <img *ngIf="question.displayType == 'image'" style="width: 100px;" src="{{option}}">
            <div *ngIf="typeOfOption(option)">
              <div *ngFor="let subOption of option?.subOptions">
                <svg id="answered-icon" 
                [ngClass]="optionStyling(question.answer.includes(subOption) || question.answer == subOption || (question.optionAnswer == subOption && question.answer == option.options) || question.answer[0] == subOption || question.answer == option.subOption,i)"
                width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
                <path d="m9 12 2 2 4-4"></path>
              </svg>
                <span class="ml-1"> {{subOption | translation:currentLang}}</span>          
              </div>
<!-- 
              <span *ngIf="option.systolic" class="d-block">{{'CONSULTATIONS.SYSTOLIC' | translate}}:</span>
              <div *ngFor="let systolic of option?.systolic">
                <svg id="answered-icon" 
                  [ngClass]="optionStyling(question.answer[0] == systolic,i)"
                  width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
                  <path d="m9 12 2 2 4-4"></path>
                </svg>
                <span class="ml-3">{{systolic | translation:currentLang}}</span>          
              </div>
              <span *ngIf="option.diastolic" class="d-block">{{'CONSULTATIONS.DIASTOLIC' | translate}}:</span>
              <div *ngFor="let diastolic of option?.diastolic">
                <svg id="answered-icon" 
                  [ngClass]="optionStyling(question.answer[1] == diastolic,i)"
                  width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
                  <path d="m9 12 2 2 4-4"></path>
                </svg>
                <span class="ml-3">{{diastolic | translation:currentLang}}</span>          
              </div> -->
              <div *ngIf="option.systolic">
                <span>{{'CONSULTATIONS.SYSTOLIC' | translate}}:<br><svg id="answered-icon" 
                  [ngClass]="{'answer-icon': question.points == 0,
                  'answered-icon-risk': question.points > 0 }"
                  width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
                  <path d="m9 12 2 2 4-4"></path>
                </svg> {{question.answer[0] | translation:currentLang}}</span>
              </div>
              <div *ngIf="option.diastolic">
                <span>{{'CONSULTATIONS.DIASTOLIC' | translate}}:<br><svg id="answered-icon" 
                  [ngClass]="{'answer-icon': question.points == 0,
                  'answered-icon-risk': question.points > 0 }"                  width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
                  <path d="m9 12 2 2 4-4"></path>
                </svg>{{question.answer[1] | translation:currentLang}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="answer" *ngIf="question.type == 'BMI'">
          <span>{{'CONSULTATIONS.HEIGHT' | translate}}:<br>{{question.answer[0]}} {{'CONSULTATIONS.FEET' | translate}} {{question.answer[1]}} {{'CONSULTATIONS.INCHES' | translate}}</span><br>
          <span>{{'CONSULTATIONS.WEIGHT' | translate}}:<br>{{question.answer[2]}} {{'CONSULTATIONS.POUNDS' | translate}}</span><br>
          <span>{{'CONSULTATIONS.BMI' | translate}}: {{question.answer[3] | translation:currentLang}}</span>
        </div>
        <div class="answer" *ngIf="question.explain">
          <span *ngIf="question.explain" class="d-flex justify-content-start" >{{question?.subquestion | translation:currentLang}}</span>
          <span *ngIf="question.explain">{{'CONSULTQUESTIONS.EXPLANATION' | translate}}: {{question.explain}}</span>
        </div>
        <div class="answer" *ngIf="!question.explain && question.required == false">
          <span *ngIf="!question.explain && question.required == false">N/A</span>
        </div>
        <div class="answer" *ngIf="question.lastQuestion && (claims?.devAdmin || claims?.superAdmin)">
          <span *ngIf="question.lastQuestion && (claims?.devAdmin || claims?.superAdmin)">Signed By: {{question.signedBy}} Ref: {{question.signRef}}</span><br>
          <span *ngIf="question.lastQuestion && question.dateCertified && (claims?.devAdmin || claims?.superAdmin)">Date: {{question.dateCertified.toDate() | date:'MMMM dd, yyyy h:mm a'}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="btnTitles == 'VIEWCONSULTS.ALLQUESTIONS' || btnTitles == 'VIEWCONSULTS.PATIENTEXPLANATION'"  class="questions-answers mx-auto">
      <div class="d-flex">
        <span class="title">{{'CONSULTATIONS.INFOFORMED' | translate}}</span><br>
      </div>
      <div class="answers">
        <div class="answer">
          <div *ngFor="let w of selectedWishlist">
            <span *ngIf="w.id == 980" class="ml-2" [ngClass]="{'text-primary': true}">- {{w.name | translation:currentLang}}</span>          
            <span *ngIf="w.id != 980" class="ml-2" [ngClass]="{'text-primary': true}">- {{w.productName | translation:currentLang}} {{w.productDosage}}</span>          
            <div *ngIf="w.id == 980">
              <span *ngIf="selectedService.length > 0">Selected Promo</span>
              <div *ngFor="let sub of selectedService">
                <span class="ml-3" [ngClass]="{'text-primary': true}">- {{sub.productName | translation:currentLang}}</span>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>




<ng-template #Notes>
  <div *ngIf="!collaborator">
    <h2 class="text-center bold">{{'VIEWCONSULTS.NOTES.NOTES' | translate | uppercase}}</h2>
    <hr style="margin: auto; margin-bottom: 20px; border: none; border-bottom: 2px dotted #e9ebee;">
    <div id="form-container" [ngClass]="{'disableDiv': consult?.Status == 'Completed' || currentUser?.accountType == 'admin' || collaborator}">
      <div id="form">
        <input id="note-title" placeholder="{{'VIEWCONSULTS.NOTES.TITLE' | translate}}" type="text" [(ngModel)]="title">
        <div (click)="openForm()" class="editable-div" id="note-text" contenteditable="true" aria-multiline="true" role="textbox" aria-label="Take a note..." data-text="Take a note..." spellcheck="true">{{notePlaceholder}}</div>
        <div id="form-buttons">
          <i [ngClass]="{'disableDiv': consult?.Status == 'Completed' || currentUser?.accountType == 'admin' || collaborator}" id="encrypt-btn" class="fas fa-lock mr-4" (click)="privateNote()"></i>
          <button [disabled]="consult?.Status == 'Completed' || collaborator || currentUser?.accountType == 'admin'" type="submit" id="submit-button" (click)="saveNotes()">{{'VIEWCONSULTS.NOTES.SUBMIT' | translate}}</button>
          <button [disabled]="consult?.Status == 'Completed' || collaborator || currentUser?.accountType == 'admin'" type="submit" id="form-close-button" (click)="closeForm()">{{'VIEWCONSULTS.NOTES.CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
    
    <div [ngClass]="{'disableDiv': consult?.Status == 'Completed' || currentUser?.accountType == 'admin' || collaborator}" class="notes mt-1 mb-3" *ngFor="let note of notes">
      <div class="note-body">
        <div class="row">
          <h5 *ngIf="note.encrypted && !note.decrypting" style="display: flex; justify-content: space-between; width: 100%;">Encrypted <i class="mdi mdi-lock-outline cyan" (click)="manageEncryption(note)"></i></h5> 
          <h5 *ngIf="note.decrypting" style="display: flex; align-items: baseline;">{{note.innerText | translation:currentLang}} <div class="dot-flashing"></div></h5> 
          <h5 *ngIf="!note.encrypted && !note.decrypting" style="display: flex; justify-content: space-between; width: 100%;">{{note.title}}  <i class="mdi mdi-lock-open-variant-outline cyan" (click)="manageEncryption(note)"></i></h5> 
        </div>
        <div class="row" (click)="openModal(editNotes, note)">
          <p *ngIf="note.encrypted">This note is private.</p>
          <p *ngIf="!note.encrypted">{{note.text}}</p>
        </div>
      </div>
      <div class="row justify-content-end mr-1 d-flex flex-column align-items-end">
        <span class="font-size-12">{{'VIEWCONSULTS.NOTES.CREATIONDATE' | translate}}{{note.date.toDate() | date:'yyyy-MM-dd h:mm a'}}</span>
        <span *ngIf="note.updatedDate" class="font-size-12">&nbsp;{{'VIEWCONSULTS.NOTES.UPDATEDDATE' | translate}}{{note.updatedDate.toDate() | date:'yyyy-MM-dd h:mm a'}}</span>
      </div>
    </div>
  </div>
  <div *ngIf="collaborator">
    <h2>{{'CONSULT-PRESCRIPTIONS.ACCESSDENIED' | translate}}</h2>
  </div>
</ng-template>

<ng-template #editNotes let-modal>
  <div class="modal-notes" *ngFor="let note of editableNote">
    <div class="modal-title" id="note-text" contenteditable="true" aria-multiline="true" role="textbox" aria-label="Take a note..." data-text="Take a note..." spellcheck="true">{{note.title}}</div>
    <div class="editable-div-modal" id="note-text" contenteditable="true" aria-multiline="true" role="textbox" aria-label="Take a note..." data-text="Take a note..." spellcheck="true">{{note.text}}</div>
    <div class="editBtns">
      <button [disabled]="currentUser?.accountType == 'admin' || collaborator || consult?.Status == 'Completed'" type="submit" class="modal-submit-button" (click)="updateNotes()">{{'VIEWCONSULTS.NOTES.SAVE' | translate}}</button>
      <span class="modal-close-button" (click)="dismiss()">{{'VIEWCONSULTS.NOTES.CLOSE' | translate}}</span>
    </div>
  </div>
</ng-template>

<ng-template #Messages>
  <div *ngIf="!collaborator">
    <h2 class="text-center bold">{{'VIEWCONSULTS.MESSAGES' | translate | uppercase}}</h2>
    <hr style="margin: auto; margin-bottom: 20px; border: none; border-bottom: 2px dotted #e9ebee;">
    <app-consultmessages *ngIf="consult" [patient]="patientProfile" [collaborator]="collaborator"
    [consult]="consult" 
    [currentUser]="user"></app-consultmessages>
  </div>
  <div *ngIf="collaborator">
    <h2>{{'CONSULT-PRESCRIPTIONS.ACCESSDENIED' | translate}}</h2>
  </div>
</ng-template>

<ng-template #AllConsults>
  <h2 class="text-center bold">{{'VIEWCONSULTS.MEDICALHISTORY' | translate | uppercase}}</h2>
  <hr style="margin: auto; margin-bottom: 20px; border: none; border-bottom: 2px dotted #e9ebee;">

  <h3>{{'PRACTITIONER-CONSULTS.PATIENTCONSULT' | translate}}</h3>
  <div>
    <div *ngFor="let consult of allConsults" class="card d-flex w-100" style="margin: 1.5% auto;">
      <div class="d-flex p-3" style="justify-content: space-between;">
        <div>
          <h4 class="m-0">{{consult.conditionName | translation:currentLang}}</h4>
          <p class="m-0">{{consult.patientName}}</p>
          <p class="m-0">{{consult.Date.toDate() | date:'MMMM dd, yyyy h:mm a':'':currentLang  | titlecase}}</p>       
        </div>
        <div>
          <button class="btn btn-info" (click)="getPrevConsult(consult, viewPdf)">{{'CONSULTATIONS.VIEW' | translate}}</button>
        </div>
      </div>    
    </div>
    <p *ngIf="allConsults.length == 0">{{'PRACTITIONER-CONSULTS.NOCONSULTS' | translate}}</p>
  </div>
</ng-template>

<ng-template #viewPdf>
  <div class="p-3">
    <div class="justify-content-end">
      <button type="button" class="close float-right modal-close" (click)="dismiss()" aria-hidden="true">×</button>
    </div>
    <div class="mt-3">
      <ngx-extended-pdf-viewer *ngIf="prevConsult[0].pdf != undefined" [src]="this.prevConsult[0].pdf" backgroundColor="#e8e8ef"
        [showZoomButtons]="true" [showPrintButton]="true" [showDownloadButton]="true" [useBrowserLocale]="true"
        [sidebarVisible]="false"  [showSecondaryToolbarButton]="false" [showSidebarButton]="false" [zoom]="'75%'">
      </ngx-extended-pdf-viewer>
    </div>
  </div>
</ng-template>

<ng-template #Diagnostic>
  <app-diagnostics [consult]="consult"></app-diagnostics>
</ng-template>

<ng-template #loading>
    <div class="loading-card">
        <div class="row loading loading-question"></div>
        <div class="row loading loading-line2"></div>
        <div class="row loading loading-answer"></div>
        <div class="row loading loading-line4"></div>
    </div>
    <div class="loading-card">
        <div class="row loading loading-question"></div>
        <div class="row loading loading-line2"></div>
        <div class="row loading loading-answer"></div>
        <div class="row loading loading-line4"></div>
    </div>
    <div class="loading-card">
        <div class="row loading loading-question"></div>
        <div class="row loading loading-line2"></div>
        <div class="row loading loading-answer"></div>
        <div class="row loading loading-line4"></div>
    </div>
    <div class="loading-card">
        <div class="row loading loading-question"></div>
        <div class="row loading loading-line2"></div>
        <div class="row loading loading-answer"></div>
        <div class="row loading loading-line4"></div>
    </div>
    <div class="loading-card">
        <div class="row loading loading-question"></div>
        <div class="row loading loading-line2"></div>
        <div class="row loading loading-answer"></div>
        <div class="row loading loading-line4"></div>
    </div>
</ng-template>

<ng-template #closing>
  <div *ngIf="!collaborator">
    <h2 class="text-center bold">{{'VIEWCONSULTS.CLOSINGSIGNATURE' | translate | uppercase}}</h2>
    <hr style="margin: auto; margin-bottom: 20px; border: none; border-bottom: 2px dotted #e9ebee;">
    <div class="media-body overflow-hidden d-flex flex-column">
      <div class="closing-header mx-auto">
        <h3 class="d-flex flex-column align-items-center bold">{{closingTitle | translate}}</h3>
        <h4 class="infoFont" *ngIf="pdf == undefined">{{closingMessage | translate}}</h4>
        <h4 class="infoFont" *ngIf="pdf !== undefined && closingDate">{{'VIEWCONSULTS.SIGNAT' | translate}}{{closingDate | date:'MMMM dd, yyyy h:mm a':'':currentLang | titlecase}}</h4>
      </div>
      <div  *ngIf="pdf == undefined" class="img d-flex justify-content-center mt-2">
        <img class="sign-img" src="{{closingImg | translation:currentLang}}" alt="">    
      </div>
    </div>
    <div class="">
      <div *ngIf="consult?.pdf != undefined && consult?.pdf != ''" class="">
        <ngx-extended-pdf-viewer *ngIf="consult.pdf != undefined" [src]="this.consult.pdf" backgroundColor="#e8e8ef"
        [showZoomButtons]="true" [showPrintButton]="true" [showDownloadButton]="true" [useBrowserLocale]="true"
        [sidebarVisible]="false"  [showSecondaryToolbarButton]="false" [showSidebarButton]="false" [zoom]="'75%'">
      </ngx-extended-pdf-viewer>
    </div>
      <!-- <object *ngIf="pdf !== undefined" [data]="pdf"  width="100%" height="600px"></object>  -->
      <div class="sign-btn">
        <!-- Commented btn to avoid authenticating in tests -->
        <!-- <button class="btn btn-info mt-5 mx-auto" (click)="signPad()">{{'VIEWCONSULTS.SIGN' | translate}}</button> -->
        <button [disabled]="consult?.Status == 'Completed' || collaborator || currentUser?.accountType == 'admin'" *ngIf="pdf == undefined" class="btn btn-info mt-5 mx-auto" (click)="signPad()">{{'VIEWCONSULTS.SIGN' | translate}}</button>
      </div>
    </div>
  </div>
  <div *ngIf="collaborator">
    <h2>{{'CONSULT-PRESCRIPTIONS.ACCESSDENIED' | translate}}</h2>
  </div>
</ng-template>

<ng-template #abandonModal let-modal>
  <div class="p-3">
    <div class="justify-content-end">
      <button type="button" class="close float-right modal-close" (click)="dismiss()" aria-hidden="true">×</button>
    </div>
   
    <h4 class="mt-3">{{'VIEWCONSULTS.ABANDONCONSULT' | translate}}</h4>
  
    <div class="d-flex" *ngFor="let reason of remoteConfig.reasonsToAbandonConsult">
      <div class="custom-control custom-checkbox mb-2">
        <input type="checkbox" class="custom-control-input" id="{{reason}}" (change)="selectReason($event)">
        <label class="custom-control-label" for="{{reason}}">{{reason | translation:currentLang}}</label>
      </div>
    </div>
    <div>
      <textarea placeholder="{{'VIEWCONSULTS.SWAL.ABANDONREASON' | translate}}" name="other" id="other" cols="30" rows="5" [(ngModel)]="explanation"></textarea>
    </div>
    <div class="d-flex justify-content-center">
      <button [disabled]="collaborator" class="btn btn-info button fit-content" role="button" (click)="abandonConsult()" [disabled]="abandonReopenReasons.length === 0">{{'VIEWCONSULTS.CONSULTSUMMARY.ABANDONCONSULT' | translate | uppercase}}</button>
    </div>
  </div>
</ng-template>

<ng-template #reopenModal let-modal>
  <div class="p-3">
    <div class="justify-content-end">
      <button type="button" class="close float-right modal-close" (click)="dismiss()" aria-hidden="true">×</button>
    </div>
   
    <h4 class="mt-3">{{'VIEWCONSULTS.REOPENCONSULT' | translate}}</h4>
  
    <div class="d-flex" *ngFor="let reason of remoteConfig.reasonsToReopenConsult">
      <div class="custom-control custom-checkbox mb-2">
        <input type="checkbox" class="custom-control-input" id="{{reason}}" (change)="selectReason($event)">
        <label class="custom-control-label" for="{{reason}}">{{reason | translation:currentLang}}</label>
      </div>
    </div>
    <textarea placeholder="{{'VIEWCONSULTS.SWAL.REOPENREASON' | translate}}" name="other" id="other" cols="30" rows="5" [(ngModel)]="explanation"></textarea>
    <div class="d-flex justify-content-center">
      <button [disabled]="collaborator" class="btn btn-info button" role="button" (click)="reopenConsult()" [disabled]="abandonReopenReasons.length === 0">{{'VIEWCONSULTS.CONSULTSUMMARY.REOPENCONSULT' | translate | uppercase}}</button>
    </div>
  </div>
</ng-template>

<ng-template #noPrescriptionModal let-modal>
  <div class="p-3">
    <div class="justify-content-end">
      <button type="button" class="close float-right modal-close" (click)="dismiss()" aria-hidden="true">×</button>
    </div>
   
    <h4 class="mt-3">{{'VIEWCONSULTS.NOPRESCRIPTIONS' | translate}}</h4>

    <div class="d-flex" *ngFor="let reason of remoteConfig.reasonsForNoPrescriptions">
      <div class="custom-control custom-checkbox mb-2">
        <input type="checkbox" class="custom-control-input" id="{{reason}}" (change)="selectReason($event)">
        <label class="custom-control-label" for="{{reason}}">{{reason | translation:currentLang}}</label>
      </div>
    </div>
    <textarea placeholder="{{'VIEWCONSULTS.NOPRESCRIPTIONSREASON' | translate}}" name="other" id="other" cols="30" rows="5" [(ngModel)]="explanation"></textarea>
    <div class="d-flex justify-content-center">
      <button [disabled]="collaborator" class="btn btn-info button" role="button" (click)="submitReason()" [disabled]="abandonReopenReasons.length == 0">{{'VIEWCONSULTS.SUBMIT' | translate | uppercase}}</button>
    </div>
  </div>
</ng-template>

<ng-template #signaturePad let-modal>
  <div class="p-2 d-flex w-100 justify-content-end">
    <i class="fas fa-times" (click)="modal.close()"></i>
  </div>
  <app-signaturepad [patientProfile]="patientProfile" [practitionerProfile]="practitionerProfile" [currentUser]="currentUser.uid" [consultID]="consult.ID" [consult]="consult" (finishedConsult)="showPdf($event)"></app-signaturepad>
</ng-template>

<div *ngIf="loadingDiagnostics" class="loading">
  <app-reload [message]="reloadMsg"></app-reload>
</div>

<ng-template #followUp>
  <div *ngIf="!collaborator">
    <h2 class="text-center bold">{{'VIEWCONSULTS.FOLLOWUP' | translate | uppercase}}</h2>
    <hr style="margin: auto; margin-bottom: 20px; border: none; border-bottom: 2px dotted #e9ebee;">

    <!-- *ngIf asegura que la routa cambie antes de buscar el calendario -->
    <app-follow-up [ngClass]="{'disableDiv': collaborator}" *ngIf="route.url.includes('followUp')" (consult)="consult = $event" [consultID]="consultID"></app-follow-up>
  </div>
  <h2 *ngIf="collaborator">{{'CONSULT-PRESCRIPTIONS.ACCESSDENIED' | translate}}</h2>
</ng-template>

<ng-template #others>
  <div class="consult-sections">        
    <ul ngbNav #otherNav="ngbNav" [activeId]="othersNav()" class="nav-tabs nav-tabs-custom nav-justified" style="border-bottom: 0">
      <!-- <li [ngbNavItem]="2">
        <a ngbNavLink [routerLink]="['./others/profile']">
          <span class="d-block d-sm-none">Profile</span>
          <span class="d-none d-sm-block">{{'VIEWCONSULTS.PATIENTPROFILE.PATIENTPROFILE' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="PatientProfile"></ng-template>
        </ng-template>
      </li> -->
      <li [ngbNavItem]="5">
        <a ngbNavLink [routerLink]="['./others/telehealth', consultID]">
          <span class="d-block d-sm-none">Telemed</span>
          <span class="d-none d-sm-block">{{'VIEWCONSULTS.TELEHEALTH' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <h2 class="text-center bold">{{'VIEWCONSULTS.TELEHEALTH' | translate | uppercase}}</h2>
          <hr style="margin: auto; margin-bottom: 20px; border: none; border-bottom: 2px dotted #e9ebee;">        
          <ng-template [ngTemplateOutlet]="Telehealth"></ng-template>
        </ng-template>
      </li>
      <li [ngbNavItem]="7">
        <a ngbNavLink [routerLink]="['./others/notes']">
          <span class="d-block d-sm-none">Notes</span>
          <span class="d-none d-sm-block">{{'VIEWCONSULTS.NOTES.NOTES' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="Notes"></ng-template>
        </ng-template>
      </li>
      <li [ngbNavItem]="8">
        <a ngbNavLink [routerLink]="['./others/patientConsults']" (click)="allPatientConsults = true">
          <span class="d-block d-sm-none">Prev Consults</span>
          <span class="d-none d-sm-block">{{'VIEWCONSULTS.MEDICALHISTORY' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="AllConsults"></ng-template>
        </ng-template>
      </li>
      <li [ngbNavItem]="13">
        <a ngbNavLink [routerLink]="['./others/referral']">
          <span class="d-block d-sm-none">Referrals</span>
          <span class="d-none d-sm-block">{{'REFERRALS.REFERRALS' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <div *ngIf="!collaborator">
            <app-referral *ngIf="consult" [consult]="consult"></app-referral>
          </div>
          <div *ngIf="collaborator">
            <h2>{{'CONSULT-PRESCRIPTIONS.ACCESSDENIED' | translate}}</h2>
        </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="16">
        <a ngbNavLink [routerLink]="['./others/collaborate']" (click)="getPosibleCollaborators()">
          <span class="d-block d-sm-none">Collab</span>
          <span class="d-none d-sm-block">{{'VIEWCONSULTS.COLLABORATE' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="collaborative"></ng-template>
        </ng-template>
      </li>
    </ul>
    <hr style="border: solid 1px rgba(0, 0, 0, 0.1) !important; margin-left: -10%; width: 120%; margin-top: 1.5rem !important; margin-bottom: 2rem !important;" >
    <div [ngbNavOutlet]="otherNav"></div>
  </div>
</ng-template>

<ng-template #Telehealth>
  <p *ngIf="consultMeetings.length == 0">{{'VIEWCONSULTS.NOEVENTS' | translate}}</p>

  <div *ngIf="consultMeetings">
    <ul class="auto-grid mr-5">
      <div *ngFor="let meet of consultMeetings; let i = index;" style="height: 100%; width: 50%;" class="phoneView">
        <div *ngIf="meet.start" class="meetCard mt-3">
          <h5 class="bold" *ngIf="meet.consultID == consultID" >{{meet.title}}</h5>
          <h6 *ngIf="practitionerProfile && practitionerProfile.accountType == 'practitioner' && practitionerProfile.email != meet.practitionerEmail">{{meet.practitionerEmail}}</h6>
          <h6 *ngIf="practitionerProfile && practitionerProfile.accountType == 'practitioner' && practitionerProfile.email == meet.practitionerEmail">{{practitionerProfile.abbr}} {{practitionerProfile.firstName}} {{practitionerProfile.lastName1}}</h6>
          <h6>{{'VIEWCONSULTS.PATIENT' | translate}} {{meet.patientName}}</h6>
          <h6>{{'TELEHEALTH.STARTS' | translate}} {{meet.start | date:'MMMM dd, yyyy - h:mm a':'':currentLang | titlecase}}</h6>
          <button [disabled]="consult.Status == 'Completed' || currentUser?.accountType == 'admin'" *ngIf="currentUser?.accountType == 'practitioner' && meet.room" class="btn btn-info" target="_blank" href="{{meet.room}}">{{'TELEHEALTH.JOIN' | translate}}</button>
          <p style="color: red;" *ngIf="!meet.room && meet.meetStatus == 'paid'">
            {{'APPOINTMENTS.PAIDSTATUS' | translate}}
          </p>
          <p style="color: red;" *ngIf="!meet.room && meet.meetStatus == 'pending' && !consult.updateFollowUpReasons">
            {{'APPOINTMENTS.PENDINGSTATUS' | translate}}
          </p>
          <button [disabled]="collaborator || consult?.Status == 'Completed'" *ngIf="!meet.requestUpdate" class="btn btn-info ml-2" (click)="openCalendar(event, false, meet)">{{'EVENTS.UPDATE' | translate}}</button>
          <button [disabled]="collaborator || currentUser?.accountType == 'admin' || consult?.Status == 'Completed'" *ngIf="meet.requestUpdate && meet.meetStatus == 'paid'" class="btn btn-info ml-2" (click)="scheduleMeet(meet)">{{'APPOINTMENTS.APPROVE' | translate}}</button>
          
          <div *ngIf="meet.requestUpdate && meet.meetStatus == 'pending'">
            <hr>
            <h5>{{'TELEHEALTH.REQUESTEVENTCHANGE' | translate}}
            </h5>
            <h6>Original: {{meet.start | date:'MMMM dd, yyyy - h:mm a':'':currentLang | titlecase}} - {{meet.end| date:'MMMM dd, yyyy - h:mm a':'':currentLang | titlecase}}</h6>
            <span class="">Suggestion: </span><br>
            <span>{{meet.suggestedSchedule.start | date:'MMMM dd, yyyy - hh:mm aa':'':currentLang | titlecase}} - {{meet.suggestedSchedule.end | date:'MMMM dd, yyyy - hh:mm aa':'':currentLang | titlecase}}</span><br>
    
            <button [disabled]="collaborator || consult?.Status == 'Completed'" *ngIf="meet.requestUpdate && meet.meetStatus == 'pending'" class="btn btn-info ml-2" (click)="reschedule(meet)">{{'APPOINTMENTS.RESCHEDULE' | translate}}</button>
            <button [disabled]="collaborator || currentUser?.accountType == 'admin' || consult?.Status == 'Completed'" *ngIf="consult.updateFollowUpReasons && meet.meetStatus == 'pending'" class="btn btn-info ml-2" (click)="scheduleMeet(meet)">{{'APPOINTMENTS.APPROVE' | translate}}</button>
          </div>
        </div>  
      </div>
    </ul>
  </div>
  
  <button [disabled]="collaborator || consult?.Status == 'Completed'" class="btn btn-info mt-3 ml-2" (click)="openCalendar(calendar)">{{'VIEWCONSULTS.VIEWCALENDAR' | translate}}</button>
  <button [disabled]="collaborator || currentUser?.accountType == 'admin' || consult?.Status == 'Completed'" class="btn btn-info mt-3 ml-2" (click)="createMeet()">{{'APPOINTMENTS.CREATEMEET' | translate}}</button>
</ng-template>

<ng-template #event let-modal> 
  <app-add-event [modalRef]="modalRef"></app-add-event>
</ng-template>

<ng-template #calendar id="calendar" let-modal> 
  <div class="p-2">
    <div class="justify-content-end">
      <button type="button" class="close float-right modal-close" (click)="dismiss()" aria-hidden="true">×</button>
    </div>
    <app-telehealth *ngIf="!route.url.includes('followUp')" [consultId]="consultID" [practitionerUid]="consult.UID_Practitioner" [consultMeetings]="consultMeetings" (path)="pathReset($event)"></app-telehealth>
  </div>
</ng-template>

<ng-template #scheduler let-modal>
  <app-add-event [modalRef]="modalRef"></app-add-event>
  <h3 class="ml-3">{{'APPOINTMENTS.PATIENT' | translate}}</h3>
    <div class="preview d-flex m-2">
      <div class="details">
        <span class="">{{'APPOINTMENTS.BETWEEN' | translate}}: </span><br>
        <span>{{appointment.suggestedSchedule.start | date:'MMMM dd, yyyy hh:mm aa':'':currentLang | titlecase}} - {{appointment.suggestedSchedule.end | date:'MMMM dd, yyyy hh:mm aa':'':currentLang | titlecase}}</span><br>
      </div>   
    </div>
</ng-template>

<ng-template #profileUpdate let-modal style="height: 500px;">
  <div class="p-3">
    <div class="justify-content-end">
      <button type="button" class="close float-right modal-close" (click)="dismiss()" aria-hidden="true">×</button>
    </div>
   
    <h4 class="mt-3">{{'VIEWCONSULTS.REQUESTUPDATE' | translate}}</h4>
  
    <div class="d-flex" *ngFor="let field of profileFields">
      <div class="custom-control custom-checkbox mb-2">
        <input type="checkbox" class="custom-control-input" id="{{field.field}}" (change)="selectFields($event)">
        <label class="custom-control-label" for="{{field.field}}">{{field.field | translate}}</label>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-info button fit-content" role="button" (click)="sendRequest()" [disabled]="verifyFields.length === 0">{{'VIEWCONSULTS.REQUESTUPDATE' | translate | uppercase}}</button>
    </div>
  </div>
</ng-template>

<ng-template #collaborative>
  <div *ngIf="!collaborator">
    <h3 class="mt-3">{{'COLLAB.INVITE' | translate }}</h3>
  
    <div *ngIf="practitioners && collaboratives" class="collab w-100">
      <li *ngFor="let doc of practitioners" class="providers">
        <div class="name-email d-flex">
          <img src="{{doc.image}}"  alt="co-worker">
          <svg *ngIf="validateCollab(doc)?.active" width="46" height="46" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 15a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"></path>
            <path d="m12.002 15 3.4 5.889L17 17.657l3.598.232-3.4-5.889"></path>
            <path d="m6.802 12-3.4 5.889L7 17.657l1.598 3.232 3.4-5.889"></path>
          </svg>
          <div class="main d-flex">
            <div class="d-flex flex-column w-50 ml-2">
              <span class="name">{{doc.firstName}} {{doc.lastName1}}</span>
              <span>{{doc.email}}</span>
            </div>
            <div class="access-time d-flex w-100">
              <div class="d-flex flex-column w-50 ml-2">
                <span class="name">{{'VIEWCONSULTS.COLLABSTART' | translate}}</span>
                <span *ngIf="validateCollab(doc)?.collabStart">{{ validateCollab(doc)?.collabStart.toDate() | date:'medium' }}</span>
                <span *ngIf="validateCollab(doc) == 'N/A'">{{ validateCollab(doc) }}</span>
              </div>
              <div class="d-flex flex-column w-50 ml-2">
                <span class="name">{{'VIEWCONSULTS.COLLABEND' | translate}}</span>
                <span *ngIf="validateCollab(doc)?.collabEnd">{{ validateCollab(doc)?.collabEnd.toDate() | date:'medium' }}</span>
                <span *ngIf="validateCollab(doc) == 'N/A'">{{ validateCollab(doc) }}</span>
              </div>
            </div>
          </div>
  
          <button [disabled]="collaborator || currentUser?.accountType == 'admin' || consult?.Status == 'Completed'" *ngIf="validateCollab(doc) == 'N/A' || !validateCollab(doc)?.active" class="btn d-flex" (click)="inviteCollab(doc)">{{'VIEWCONSULTS.INVITE' | translate}}</button>
          <button [disabled]="collaborator || currentUser?.accountType == 'admin' || consult?.Status == 'Completed'" *ngIf="validateCollab(doc)?.active" class="btn d-flex" (click)="removeCollabAccess(doc)">{{'VIEWCONSULTS.REJECT' | translate}}</button>
        </div>
      </li>
    </div>
  </div>
  <div *ngIf="collaborator">
    <h2>{{'CONSULT-PRESCRIPTIONS.ACCESSDENIED' | translate}}</h2>
  </div>
</ng-template>

<ng-template #consultHistory>
  <h3 class="mt-3">History</h3>

  <div class="mb-2 d-flex flex-column w-50 ml-2" *ngFor="let h of history">
    <li class="name">{{h.user}}</li>
    <span class="name ml-3">&nbsp;{{h.date.toDate() | date:'medium'}}</span>
    <span class="ml-3">&nbsp;{{h.action}}</span>
    <span *ngIf="h.description" class="ml-3">&nbsp;{{h.description}}</span>
    <span *ngIf="h.explanation" class="ml-3">&nbsp;{{h.explanation}}</span>
  </div>
</ng-template>

<ng-template #transferModal let-modal>
  <div class="d-flex w-100 justify-content-between">
    <h3 class="">{{'VIEWCONSULTS.WHOTOTRANSFER' | translate}}</h3>
    <i class="fas fa-times cursor" (click)="modal.close(); modalRef = undefined;"></i>
  </div>

  <div class="transfer w-100">
    <li *ngFor="let doc of practitioners" class="providers">
      <div class="name-email d-flex">
        <img src="{{doc.image}}"  alt="co-worker">
        <div class="main d-flex">
          <div class="d-flex flex-column w-50 ml-2">
            <span class="name">{{doc.firstName}} {{doc.lastName1}}</span>
            <span>{{doc.email}}</span>
          </div>
        </div>
        <button class="btn d-flex" (click)="transferConsult(doc)">{{'VIEWCONSULTS.TRANSFERBTN' | translate}}</button>
      </div>
    </li>
  </div>
</ng-template>

<ng-template #transferModalAction let-modal>
  <div class="d-flex w-100 justify-content-between">
    <h4 class="">{{'VIEWCONSULTS.TRANSFERCONSULT' | translate}}</h4>
    <i class="fas fa-times cursor" (click)="modal.close(); modalRef = undefined;"></i>
  </div>
  <div class="">
    <div class="transfer-container-doc d-flex">
      <img class="transfer-img" src="{{transferDoc.image}}" alt="">
      <div class="flex-column">
        <span class="ml-3">{{transferDoc.firstName}} {{transferDoc.lastName1}}</span><br>
        <span class="ml-3">{{transferDoc.email}}</span>
      </div>
    </div>
    <li class="transfer-options d-flex flex-column mt-3 mb-3">
      <label for="3">
        <input type="radio" id="3" name="transfer" value="Fully transfer this consult" [(ngModel)]="transferOption">
        <span class="ml-2 title">{{'VIEWCONSULTS.FULL' | translate}}</span><br>
        <span class="ml-4">{{'VIEWCONSULTS.FULLYTRANSFER' | translate}}</span>
      </label>
      <label for="2">
        <input type="radio" id="2" name="transfer" value="Temporarly transfer this consult" [(ngModel)]="transferOption">
        <span class="ml-2 title">{{'VIEWCONSULTS.TEMPORARY' | translate}}</span><br>
        <span class="ml-4">{{'VIEWCONSULTS.TEMPORARYTRANSFER' | translate}}</span>
      </label>
    </li>
    <textarea name="other" id="other" cols="30" rows="5" [(ngModel)]="transferReason"></textarea>

    <div class="w-100">
      <button class="btn d-flex float-right" (click)="completeTransfer()">{{'VIEWCONSULTS.COMPLETETRANSFER' | translate}}</button>
    </div>
  </div>
</ng-template>
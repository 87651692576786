<div class="main">
  <div class="heading-custom">
    <svg (click)="close()" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 12h14"></path>
      <path d="m5 12 4 4"></path>
      <path d="m5 12 4-4"></path>
    </svg>
    <div class="d-flex flex-column">
      <h3 class="title">{{'CHANGE-PRODUCT.TITLE' | translate}}</h3>
      <p>{{'CHANGE-PRODUCT.DESCRIPTION' | translate}}</p>
    </div>

  </div>


  <div class="current">
    <span>{{'CHANGE-PRODUCT.CURRENT' | translate}}</span>
    <div class="d-flex box justify-content-between">
      <div class="d-flex">
        <img class="img" src="assets/images/icons/Dzeus_Frasco_logo.png" alt="" style="margin-right: 15px; width: 10% !important;">
        <div class="d-flex flex-column">
          <h5 class="mt-2 mb-0">{{prescription.productName}}</h5>
          <span>{{prescription.productQty}} {{'CHANGE-PRODUCT.TABLETS' | translate}}</span>
          <span>$ {{ prescription?.price }}</span>
        </div>
      </div>
      <svg style="color: #F5F8FD;" id="arrow-right" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 12h14"></path>
        <path d="m15 16 4-4"></path>
        <path d="m15 8 4 4"></path>
      </svg>
    </div>
  </div>

  <div class="description mt-4">
    <p>{{'CHANGE-PRODUCT.ACTION-DESCRIPTION' | translate}}
    </p>
  </div>


  <div class="possible-products">
    <!-- <div class="d-flex selected-box justify-content-between" *ngFor="let product of possible_products" (click)="setProduct(product)">
      <div class="d-flex">
        <img class="img" src="assets/images/icons/Dzeus_Frasco_logo.png" alt="" style="margin-right: 15px; width: 10% !important;">
        <div class="d-flex flex-column">
          <h5 class="mt-2 mb-0 title">{{ setProductName(product.name | translation: user.preferedLang) }}</h5>
          <span>{{ setProductDetails( product.name | translation: user.preferedLang) }}</span>
          <span>${{ product.price }}</span>
        </div>
      </div>
      <svg (click)="setProduct(product)" id="arrow-right" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 12h14"></path>
        <path d="m15 16 4-4"></path>
        <path d="m15 8 4 4"></path>
      </svg>
    </div> -->
    <div class="d-flex selected-box justify-content-between" *ngFor="let product of all_possible_products" (click)="setProduct(product)">
      <div class="d-flex">
        <img class="img" src="assets/images/icons/Dzeus_Frasco_logo.png" alt="" style="margin-right: 15px; width: 10% !important;">
        <div class="d-flex flex-column">
          <h5 class="mt-2 mb-0 title">{{ product.name | translation: user.preferedLang }}</h5>
          <span>{{ product.qty | translation: user.preferedLang }} Tablets</span>
          <span>${{ product.price }}</span>
        </div>
      </div>
      <svg (click)="setProduct(product)" id="arrow-right" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 12h14"></path>
        <path d="m15 16 4-4"></path>
        <path d="m15 8 4 4"></path>
      </svg>
    </div>
    <!-- <div class="d-flex selected-box justify-content-between">
      <div class="d-flex">
        <img class="img" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fproducts%2Fwhitebottle-transparent.png?alt=media&token=4cbacb93-2d8d-4434-b4e1-c7249344fe09" alt="">
        <div class="d-flex flex-column">
          <h5 class="mt-2 mb-0">Sildenafil 50mg - 90 Pill Pack</h5>
          <span>$89.99</span>
        </div>
      </div>
      <svg id="arrow-right" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 12h14"></path>
        <path d="m15 16 4-4"></path>
        <path d="m15 8 4 4"></path>
      </svg>
    </div> -->
  </div>

  <div class="d-flex mt-1 mb-1 justify-content-center">
    <div>{{'CHANGE-PRODUCT.OR' | translate}}</div>
  </div>

  <div class="d-flex mt-3 btn-box justify-content-center">
    <button class="btn btn-primary w-100" (click)="redirectToConsults()">{{'CHANGE-PRODUCT.SELECT-DOSE' | translate}}</button>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="email-leftbar card" id="sidebar" [hidden]="hidden">
        <div class="mail-list">    
          <b>{{'NOTIFICATIONS.SUPPORT' | translate}}</b>
          <a  class="active" [ngClass]="{ 'activeLink': chat === true }" (click)="chatComponent(); hide(true)"><i></i> {{'NOTIFICATIONS.CHAT.CHAT' | translate}}<span class="ml-1 float-right"></span></a>        
          <hr/>
          <b>{{'NOTIFICATIONS.MESSAGES' | translate}}</b>
          <a class="active" [ngClass]="{ 'activeLink': consults === true }" (click)="consultsComponent(); emailIds = []; hide(true)"><i class="ri-survey-fill mr-2"></i> {{'NOTIFICATIONS.INBOX' | translate}} <span
              class="ml-1 float-right"></span></a>
          <a class="active" [ngClass]="{ 'activeLink': sent === true }" (click)="sentComponent(); emailIds = []; hide(true)"><i class="ri-survey-fill mr-2"></i> {{'NOTIFICATIONS.SENT' | translate}} <span
            class="ml-1 float-right"></span></a>
        </div>
      </div> 
  
      <div class="email-rightbar mb-3" *ngIf="consults">
        <div class="card">
          <div class="btn-toolbar p-3" role="toolbar">
            <div class="btn-group mb-2 mb-sm-0">
              <!-- <button class="btn" *ngIf="hidden" (click)="hide(false)" style="display: flex"><i class="Arrow fas fa-angle-left"></i> <p style="font-size: 20px; font-weight: bold;"> {{'PROFILE.BACK' | translate}}</p></button> -->
              <button *ngIf="hidden" type="button" class="btn btn-primary toolbarBtn" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button>
            </div>
            <div [ngClass]="{'disableDiv': collaborator}" class="btn-group mb-2 mb-sm-0" style="height: fit-content;">
              <button type="button" class="btn btn-primary toolbarBtn btn-block" data-toggle="modal" data-target="#composemodal"
              (click)="open(content, undefined)"><i class="fas fa-pen" ngbTooltip="{{'NOTIFICATIONS.COMPOSE' | translate}}"></i></button>
            </div>
            <div class="btn-group ml-2 mr-2 mb-2 mb-sm-0" style="height: fit-content;">
              <!-- <button type="button" class="btn btn-primary toolbarBtn"><i class="fa fa-inbox"></i></button> -->
              <!-- <button type="button" class="btn btn-primary toolbarBtn" (click)="confirmDelete();" id="toolbarDelete" [disabled]="disableToolbar()"><i
                  class="far fa-trash-alt" ngbTooltip="{{'NOTIFICATIONS.DELETE' | translate}}"></i></button> -->
            </div>
              <div class="col-sm-6 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter position-relative">
                  <input id="search" type="text" name="searchTerm" class="form-control searchbar form-control-sm ml-2" 
                    aria-controls="tickets-table" placeholder="{{'NOTIFICATIONS.SEARCH' | translate}}" [(ngModel)]="searchText" />
                </div>
              </div>
          </div>

          <ul class="message-list">
            <li class="unread" *ngFor="let email of notifications | filter: searchText; let i = index;"
              [ngClass]="{ 'unread': email.read === true }" (click)="handleRoute(email.delivery.info?.messageId)" >
              <div class="col-mail col-mail-1" (click)="handleRoute(email.delivery.info?.messageId)" >
                <div class="checkbox-wrapper-mail">
                  <input hidden type="checkbox" id="chk-{{i}}" (change)="selectMail($event,email)"/>
                  <label for="chk-{{i}}"></label>
                </div>
                <a class="title" (click)="handleNotification(email)" (click)="handleRoute(email.delivery.info?.messageId)" >{{email?.template.data.subject | translation:currentLang}}</a>
              </div>
              <div class="col-mail col-mail-2" (click)="handleNotification(email)" (click)="handleRoute(email.delivery.info?.messageId)" >
                <!-- <a class="subject" (click)="handleRoute(email.delivery.info?.messageId)">{{email?.template.data.text | translation:currentLang}}</a> -->
                <div class="date mr-1">{{email?.delivery?.endTime?.toDate() | date:'yyyy-MM-dd h:mm a'}}</div>
              </div>
              <!-- <router-outlet></router-outlet> -->
            </li>
          </ul>
        </div>
      </div>

      <div class="email-rightbar mb-3" *ngIf="sent">
        <div class="card">
          <div class="btn-toolbar p-3" role="toolbar">
            <div class="btn-group mb-2 mb-sm-0">
              <!-- <button class="btn" *ngIf="hidden" (click)="hide(false)" style="display: flex"><i class="Arrow fas fa-angle-left"></i> <p style="font-size: 20px; font-weight: bold;"> {{'PROFILE.BACK' | translate}}</p></button> -->
              <button *ngIf="hidden" type="button" class="btn btn-primary toolbarBtn" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button>
            </div>
            <div [ngClass]="{'disableDiv': collaborator}" class="btn-group mb-2 mb-sm-0" style="height: fit-content;">
              <button type="button" class="btn btn-primary toolbarBtn btn-block" data-toggle="modal" data-target="#composemodal"
              (click)="open(content, undefined)"><i class="fas fa-pen" ngbTooltip="{{'NOTIFICATIONS.COMPOSE' | translate}}"></i></button>
            </div>
            <div class="btn-group ml-2 mr-2 mb-2 mb-sm-0" style="height: fit-content;">
              <!-- <button type="button" class="btn btn-primary toolbarBtn"><i class="fa fa-inbox"></i></button> -->
              <!-- <button type="button" class="btn btn-primary toolbarBtn" (click)="confirmDelete();" id="toolbarDelete" [disabled]="disableToolbar()"><i
                  class="far fa-trash-alt" ngbTooltip="{{'NOTIFICATIONS.DELETE' | translate}}"></i></button> -->
            </div>
              <div class="col-sm-6 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter position-relative">
                  <input id="search" type="text" name="searchTerm" class="form-control searchbar form-control-sm ml-2" 
                    aria-controls="tickets-table" placeholder="{{'NOTIFICATIONS.SEARCH' | translate}}" [(ngModel)]="searchText" />
                </div>
              </div>
          </div>

          <ul class="message-list">
            <li class="unread" *ngFor="let email of sentEmails | filter: searchText; let i = index;"
              [ngClass]="{ 'unread': email.read === true }" (click)="handleRoute(email.delivery.info?.messageId)" >
              <div class="col-mail col-mail-1" (click)="handleRoute(email.delivery.info?.messageId)" >
                <div class="checkbox-wrapper-mail">
                  <input hidden type="checkbox" id="chk-{{i}}" (change)="selectMail($event,email)"/>
                  <label for="chk-{{i}}"></label>
                </div>
                <a class="title" (click)="handleNotification(email)" (click)="handleRoute(email.delivery.info?.messageId)" >{{email?.template.data.subject | translation:currentLang}}</a>
              </div>
              <div class="col-mail col-mail-2" (click)="handleNotification(email)" (click)="handleRoute(email.delivery.info?.messageId)" >
                <!-- <a class="subject" (click)="handleRoute(email.delivery.info?.messageId)">{{email?.template.data.text | translation:currentLang}}</a> -->
                <div class="date mr-1">{{email?.delivery?.endTime?.toDate() | date:'yyyy-MM-dd h:mm a'}}</div>
              </div>
              <!-- <router-outlet></router-outlet> -->
            </li>
          </ul>
        </div>
      </div>

      <div class="readMessages" *ngIf="readMessage">
        <div class="mb-3">
          <div class="card">
            <div class="btn-toolbar p-3" role="toolbar" style="height: 72px;">
              <!-- <i class="fas fa-angle-left icon" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}" (click)="consultsComponent()"></i> -->
              <!-- <button class="btn" (click)="consultsComponent()" style="display: flex"><i class="Arrow fas fa-angle-left"></i> <p style="font-size: 20px; font-weight: bold;"> {{'PROFILE.BACK' | translate}}</p></button> -->
              <button *ngIf="hidden" type="button" class="btn btn-primary toolbarBtn" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button>

              <!-- <div class="btn-group ml-2 mr-2 mb-2 mb-sm-0"  style="height: fit-content;"> -->
                <!-- <button type="button" class="btn btn-primary toolbarBtn"><i class="fa fa-inbox"></i></button> -->
                <!-- <button disabled type="button" class="btn btn-primary toolbarBtn" (click)="confirm();"><i class="far fa-trash-alt" ngbTooltip="{{'NOTIFICATIONS.DELETE' | translate}}"></i></button>
              </div> -->
            </div>
  
            <div class="card-body">
              <div class="media mb-2">
                <div class="circle mr-2" [ngStyle]="{'background-color': getUserColor(selectedNotification.toUID)}">
                  <span class="initials">{{selectedNotification?.initials}}</span>
                </div>
                <!-- <img class="d-flex mr-3 avatar-sm" src="../../../../../assets/images/FAQ_Icons/Dzeus_Icono_d+.svg"
                  alt="Generic placeholder image"> -->
                <div class="media-body">
                  <h5 class="font-size-16 mt-1 mb-0 bold" style="text-align: left;">{{selectedNotification.template.data.subject | translation:currentLang}}</h5>
                  <small *ngIf="previousUrl != 'sent'" class="text-muted">{{'NOTIFICATIONS.FROM' | translate}}: {{selectedNotification?.template.data.fromName}} ({{selectedNotification.template.data.from}})</small>
                  <small *ngIf="previousUrl == 'sent'" class="text-muted">{{'NOTIFICATIONS.CHAT.TO' | translate}}: {{selectedNotification?.template.data.toName}} ({{selectedNotification.template.data.to}})</small>
                  <h5 class="font-size-13 msg-time mt-0 mb-1 ml-3 mr-2" >{{ selectedNotification?.delivery?.endTime?.toDate() | date:'yyyy-MM-dd h:mm a'}}</h5>
                </div>
              </div>
  
              <div class="content mt-0">
                <!-- <h4 class="mt-0 font-size-16" style="text-align: left;">{{selectedNotification.template.data.subject | translation:currentLang}}</h4> -->
                <hr>
                <!-- <p>{{selectedNotification.template.data.html}}</p>  -->
                <div [innerHTML]="selectedNotification.html"></div>
                
                <!-- View prev messages -->
                <div *ngIf="prevMessages && prevMessages.length > 0" class="mb-5">
                  <button *ngIf="!viewPrevMessages" class="dropdown-toggle ml- btn header-item langBtn h-auto" (click)="viewPrevMessages = true"><span class="prevMessages">{{'NOTIFICATIONS.PREVIOUSMESSAGES' | translate}} <i class="fas fa-caret-down"></i></span></button>
                  <button *ngIf="viewPrevMessages" class="dropdown-toggle ml-1 btn header-item langBtn h-auto" (click)="viewPrevMessages = false"><span class="prevMessages">{{'NOTIFICATIONS.HIDEMESSAGES' | translate}} <i class="fas fa-caret-up"></i></span></button>
                  <!-- <h6 class="bold" style="text-align: start;">{{'NOTIFICATIONS.PREVIOUSMESSAGES' | translate}}</h6> -->
                  <div *ngIf="viewPrevMessages">
                    <div class="ml-2" *ngFor="let message of prevMessages">
                      <div class="media mb-1">
                        <div class="circle mr-2" [ngStyle]="{'background-color': getUserColor(message.toUID)}">
                          <span class="initials">{{message.initials}}</span>
                        </div>
                        
                        <!-- <img *ngIf="settings" class="d-flex mr-3 avatar-sm" src="../../../assets/images/FAQ_Icons/Dzeus_Icono_d+.svg"
                        alt="Generic placeholder image"> -->
                        <div class="media-body">
                          <h4 class="font-size-16 mt-1 mb-0 bold" style="text-align: start;">{{message?.template?.data?.subject | translation:currentLang}}</h4>
                          <small *ngIf="previousUrl != 'sent'" class="text-muted">{{'NOTIFICATIONS.FROM' | translate}}: {{message?.template.data.fromName}} ({{message.template.data.from}})</small>
                          <small *ngIf="previousUrl == 'sent'" class="text-muted">{{'NOTIFICATIONS.CHAT.TO' | translate}}: {{message?.template.data.toName}} ({{message.template.data.to}})</small>
                          <h6 class="msg-time" >{{ message?.delivery?.endTime?.toDate() | date:'yyyy-MM-dd h:mm a'}}</h6>
                        </div>
                      </div>
                      <!-- <h4 class="mt-0 font-size-16" style="text-align: left;">{{message?.template?.data?.subject | translation:profile?.preferedLang}}</h4> -->
                      <hr>
                      <div *ngIf="message && message.html" [innerHTML]="message.html"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="selectedNotification.from != user.email && previousUrl != 'sent'" class="replyBtn">       
                <hr/> 
                <button [ngClass]="{'disableDiv': collaborator}" type="button" class="btn btn-secondary" data-toggle="modal" data-target="#composemodal" (click)="open(content, selectedNotification)">
                <i class="mdi mdi-reply"></i>{{'NOTIFICATIONS.REPLY' | translate}}</button>
              </div>         
            </div>
  
          </div>
        </div>      
        <!-- card -->
      </div>
      <div *ngIf="chat">
        <div class="mb-3 email-rightbar">
          <div class="card">
            <!-- <button class="btn" *ngIf="hidden" (click)="hide(false)" style="display: flex"><i class="Arrow fas fa-angle-left"></i> <p style="font-size: 20px; font-weight: bold;"> {{'PROFILE.BACK' | translate}}</p></button> -->
            <!-- <button style="margin: 1rem 1rem 0; width: 33px" *ngIf="hidden" type="button" class="btn btn-primary toolbarBtn" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button> -->
            <button *ngIf="hidden" type="button" class="btn btn-primary toolbarBtn" style="width: fit-content;" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button>
            <app-consultchat [patient]="patient" [collaborator]="collaborator" [consult]="[consult]" style="overflow-y: auto; overflow-x: hidden;" (chatLeftsidebar)="chatLeftsidebarComponent($event)"></app-consultchat>
          </div>
        </div>
      </div>
    </div>
  </div>  
</div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="composemodalTitle">{{'NOTIFICATIONS.NEWMESSAGE' | translate}}</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      
        <div class="form-group">
          <input id="email" type="email" class="form-control" placeholder="To" [(ngModel)]="this.to" disabled>
        </div>

        <div class="form-group">
          <input id="subject" type="text" class="form-control" placeholder="{{'NOTIFICATIONS.SUBJECT' | translate}}" [(ngModel)]="this.subject">
        </div>
        <div class="form-group">
          <ckeditor [editor]="Editor" readOnly="true" [config]="{ placeholder:this.placeholder}" (keyup)="collectMessage($event)" (change)="onChange($event)" [(ngModel)]="this.html"></ckeditor>
        </div>
    
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">{{'NOTIFICATIONS.CLOSE' | translate}}</button>
      <button type="button" class="btn btn-primary" (click)="sendMail = true; sendNotification()">{{'NOTIFICATIONS.SEND' | translate}} <i class="fab fa-telegram-plane ml-1"></i></button>
    </div>
  </ng-template>
<div *ngIf="valid === false" class="content w-100 text-center">
  <h2 class="title">{{'AUTHENTICATOR.SETUP' | translate}}</h2>
  <p>{{'AUTHENTICATOR.SCANQRCODE' | translate}}</p>
  <div class="devices d-flex justify-content-center ">
    <div class="m-1">
      <a class="app" href="https://apps.apple.com/us/app/google-authenticator/id388497605"> 
        <i class="fab fa-app-store"> </i>
        <h6>Apple App Store</h6>
      </a>
    </div>
    <div class="m-1">
      <a class="app" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"> 
        <i class="fab fa-google-play"></i>
        <h6>Google Play Store</h6>
      </a>
    </div>

    
  </div>
  <img id="qrcode" src="" alt="QRCode">
  <div class="copy">
    <button id="copy-btn" class="btn btn-info mt-2 w-auto mx-auto text-center" (click)="copyText()">{{'AUTHENTICATOR.COPY' | translate}}</button>
  </div>
</div>
<div *ngIf="valid === false" class="mt-2 d-flex justify-content-center">
  <div class="flex-column text-center btn-authenticator">
    <button class="btn btn-info mt-2 w-auto mx-auto text-center" (click)="trigger()">{{'AUTHENTICATOR.REGENERATE' | translate}}</button>
  </div>
</div>
<div *ngIf="valid === false" class="mt-5 d-flex justify-content-center">
  <div class="flex-column text-center btn-authenticator">
    <input id="code" class="form-control w-auto mx-auto" type="text" maxlength="6" placeholder="6 digits Auth token" [(ngModel)]="code">
    <button class="btn btn-info w-auto mt-2 x-auto text-center" (click)="validate()">{{'AUTHENTICATOR.VALIDATE' | translate}}</button>
  </div>
</div>



<div *ngIf="valid === true" class="content w-100 text-center">
  <h2 class="title">{{'AUTHENTICATOR.AUTHENTICATED' | translate}}</h2>
  <p>{{'AUTHENTICATOR.SCANQRCODE' | translate}}</p>
  <div class="check-wrap"></div>

  <div class="regen">
    <h2 class="title">{{'AUTHENTICATOR.LOSTTOKEN' | translate}}</h2>
    <p>{{'AUTHENTICATOR.CONTACTUS' | translate}}</p>
    <button class="btn btn-info w-20 mt-2 mx-auto text-center" (click)="trigger()">{{'AUTHENTICATOR.REGENERATE' | translate}}</button>
    <!-- <a href="tel:+496170961709" class="support">
      <i class="fas fa-phone-alt"></i>  Call support now 
    </a> -->
  </div>
</div>


<!-- <button class="btn btn-info" (click)="test()">Call function</button>
<button class="btn btn-info" (click)="trigger()">Trigger cloud function</button>

<button class="btn btn-info ml-5" (click)="onCall()">Call</button>


<img id="qrcode" src="" alt="QRCode">

<div class="mt-5">
    <input type="text" [(ngModel)]="code">
    <button class="btn btn-info" (click)="validate()">Validate code</button>
    {{response}}
</div> -->


<div *ngIf="loading">
  <app-reload [message]="'RELOAD.KEY'"></app-reload>
</div>
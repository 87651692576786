import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';


@Component({
  selector: 'app-affiliate-program',
  templateUrl: './affiliate-program.component.html',
  styleUrls: ['./affiliate-program.component.scss']
})
export class AffiliateProgramComponent {


  getObjectKeys(obj: object): string[] {
    return Object.keys(obj);
  }

  combinedData = [];

  allOrderValuesBasedonCampaign = {};
  allUsersBasedOnCampaign ={};

  constructor(
    private db: AngularFirestore,
    private authenticationService: AuthenticationService,
  ) { }

  allUsers = []
  allCompletedUsers = []
  filterUsers = []
  completedUsers = []
  orderData: any[] = [];


  ReferalInfluencerAnalytics = {
    series: [{ data: [0, 0, 0], name: 'Referral Count' }],
    labels: ['Molusco', 'Jay Fonseca', 'Carlos Ponce'],
    colors: ['#dd4031', '#fe840e', '#c72169'],
  };

  OrderReferralAnalytics = {
    series: [{ data: [0, 0, 0], name: 'Order Count' }],
    labels: ['Molusco', 'Jay Fonseca', 'Carlos Ponce'],
    colors: ['#dd4031', '#fe840e', '#c72169'],
  };

  async getUsers() {
    const adminIdToken = await firebase.auth().currentUser.getIdToken();
    let response:any = await this.authenticationService.getRequest(adminIdToken);
    let authData = response.data.users;
  
    let tempUsers = [];
    let profileSnapshot = await this.db.firestore.collection("users").get();
    let users = profileSnapshot.docs.map(u => u.data());
  
    authData.forEach((auth, i) => {
      let userInfo = users.find(o => o.uid === auth.uid);
  
      if (userInfo) {
        tempUsers.push(Object.assign(auth, userInfo));
      } else if (auth.providerData.length !== 0) {
        console.warn('No user info', auth);
      }
    });
  
    this.allUsers = authData;
    this.filterUsers = authData;
    this.allCompletedUsers = tempUsers;
    this.completedUsers = tempUsers;
    this.updateReferralAnalytics();
  }
  

   startDate: any = '2022-07-15T00:00';
   date = new Date();
   year = this.date.getFullYear();
   month = this.date.getMonth() + 1; // Months are zero-indexed
   day = this.date.getDate();
   endDate = this.year + '-' + (this.month < 10 ? '0' + this.month : this.month) + '-' + (this.day < 10 ? '0' + this.day : this.day) + 'T' + this.date.getHours() + ':' + this.date.getMinutes().toString().padStart(2, '0');
   filterAccounts(timeRange){
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set to midnight (12:00:00 AM)
    let dateFilterStart = timeRange != 'all' ? new Date(today.setDate(today.getDate() - Number(this.startDate))) : new Date(); //timeRange is in days
    let dateFilterEnd = timeRange != 'all' ? new Date(today.setDate(today.getDate() - Number(this.endDate))) : new Date(); //timeRange is in days
    let bypass = timeRange == 'all' ? true : false

    if(this.startDate?.includes('T')){
      dateFilterStart = new Date(this.startDate.replace('T', ' '))
    }
    if(this.endDate?.includes('T')){
      dateFilterEnd = new Date(this.endDate.replace('T', ' '))
    }

    this.filterUsers = bypass ? this.allUsers : this.allUsers.filter(o => new Date(o.metadata.creationTime) >= dateFilterStart && new Date(o.metadata.creationTime) <= dateFilterEnd)
    this.completedUsers = bypass ? this.allCompletedUsers : this.allCompletedUsers.filter(o => new Date(o.metadata.creationTime) >= dateFilterStart && new Date(o.metadata.creationTime) <= dateFilterEnd)
    this.updateReferralAnalytics();
  }

   getAffiliate = (referredBy: string) => {
    if (!referredBy) {
      return null
    }
    const parts = referredBy.split('-');
    return parts[0]; // Return the first part as affiliate
  };

  updateReferralAnalytics() {
    const moluscoUsersObject = [];
    const jayUsersObject = [];
    const carlosUsersObject = [];
  
    this.filterUsers.forEach(user => {
      const affiliate = this.getAffiliate(user.referredBy);
      if (affiliate === 'Molusco') {
        moluscoUsersObject.push(user);
      } else if (affiliate === 'Jay') {
        jayUsersObject.push(user);
      } else if (affiliate === 'Carlos_Ponce') {
        carlosUsersObject.push(user);
      }
    });
    const moluscoUsersLength = moluscoUsersObject.length;
    const jayUsersLength = jayUsersObject.length;
    const carlosUsersLength = carlosUsersObject.length;

    const allUsersObjects = [...moluscoUsersObject, ...jayUsersObject, ...carlosUsersObject];

    this.allUsersBasedOnCampaign = {};
    allUsersObjects.forEach(user => {
      const { affiliate, campaign } = this.getAffiliateAndCampaign(user.referredBy);

      if (campaign) {
        if (!this.allUsersBasedOnCampaign[affiliate]) {
          this.allUsersBasedOnCampaign[affiliate] = {};
        }
        if (this.allUsersBasedOnCampaign[affiliate][campaign]) {
          this.allUsersBasedOnCampaign[affiliate][campaign]++;
        } else {
          this.allUsersBasedOnCampaign[affiliate][campaign] = 1;
        }
      }
    });


    console.log("Carlos Users: ", carlosUsersLength);
    console.log("Molusco Users: ",moluscoUsersLength);
    console.log("Jay Users: ",jayUsersLength);
    console.log("User counts per campaign: ", this.allUsersBasedOnCampaign);

    this.ReferalInfluencerAnalytics.series = [{data: [], name: ''}];
    this.ReferalInfluencerAnalytics.series[0].data = [moluscoUsersLength, jayUsersLength, carlosUsersLength];


    this.updateCombinedData();
  }

  async getOrders() {
    let orders = await this.db.firestore.collection("orders").get();// where status == paid THIS IS VERY IMPORTANT
    this.orderData = orders.docs.map(order => order.data());
    this.updateOrderReferralAnalytics(this.orderData);
  }

  getOrdersBasedOnTimeRange(timeRange: string) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let dateFilterStart = timeRange !== 'all' ? new Date(today.setDate(today.getDate() - Number(this.startDate))) : new Date('2022-07-15T00:00');
    let dateFilterEnd = timeRange !== 'all' ? new Date(today.setDate(today.getDate() - Number(this.endDate))) : new Date();

    if (this.startDate?.includes('T')) {
      dateFilterStart = new Date(this.startDate.replace('T', ' '));
    }
    if (this.endDate?.includes('T')) {
      dateFilterEnd = new Date(this.endDate.replace('T', ' '));
    }
    let filteredOrders = this.orderData.filter(order => {
      let orderDate = order.date instanceof firebase.firestore.Timestamp ? order.date.toDate() : new Date(order.date); //arreglar este filtro
      return orderDate >= dateFilterStart && orderDate <= dateFilterEnd;
    });

    this.updateOrderReferralAnalytics(filteredOrders);
  }










  getAffiliateAndCampaign = (referredBy) => {
    if (!referredBy) {
      return { affiliate: null, campaign: null }; // Return null values if referredBy is not defined
    }

    const [affiliate, campaign] = referredBy.split('-');
    return { affiliate, campaign };
  };

  updateOrderReferralAnalytics(orderData) {
    // Initialize arrays for each affiliate's orders
    const moluscoOrdersObject = orderData.filter(order => {
      const affiliate = this.getAffiliate(order.referredBy);
       return affiliate === 'Molusco';
       });
    const jayOrdersObject = orderData.filter(order => this.getAffiliate(order.referredBy) === 'Jay');
    const carlosOrdersObject = orderData.filter(order => this.getAffiliate(order.referredBy) === 'Carlos_Ponce');
  
    // Initialize counts for each affiliate
    const moluscoOrdersLength = moluscoOrdersObject.length;
    const jayOrdersLength = jayOrdersObject.length;
    const carlosOrdersLength = carlosOrdersObject.length;
  
    // Group all affiliate orders together
    const basedOnCampaign = [...moluscoOrdersObject, ...jayOrdersObject, ...carlosOrdersObject];
  
    // Initialize an object to store counts of campaigns
     this.allOrderValuesBasedonCampaign = {};
    basedOnCampaign.forEach(order => {
      const { affiliate, campaign } = this.getAffiliateAndCampaign(order.referredBy);
    if (campaign) {
      if (!this.allOrderValuesBasedonCampaign[affiliate]) {
        this.allOrderValuesBasedonCampaign[affiliate] = {};
      }
      if (this.allOrderValuesBasedonCampaign[affiliate][campaign]) {
        this.allOrderValuesBasedonCampaign[affiliate][campaign]++;
      } else {
        this.allOrderValuesBasedonCampaign[affiliate][campaign] = 1;
      }
      }
    });


    console.log("Carlos Orders: ",carlosOrdersLength); 
    console.log("Molusco Orders: ",moluscoOrdersLength); 
    console.log("Jay Orders: ",jayOrdersLength);
    console.log("Order counts per campaign: ", this.allOrderValuesBasedonCampaign);



    this.OrderReferralAnalytics.series =[{data: [], name: ''}];
     this.OrderReferralAnalytics.series[0].data = [moluscoOrdersLength, jayOrdersLength, carlosOrdersLength];
     this.updateCombinedData();
  }
  
async getCookiesCount(bypass?: string) {
  const cookiesSnapshot = await this.db.firestore.collection("cookies").get();
  const cookiesData = cookiesSnapshot.docs.map(doc => doc.data());
  
  const filteredCookies = bypass ? cookiesData : cookiesData.filter(cookie => {
    const cookieDate = new Date(cookie.cookiesDate);
    const strStartDate = new Date(this.startDate);
    const strEndDate = new Date(this.endDate);


    return cookieDate >= strStartDate && cookieDate <= strEndDate;
  });

  const moluscoCookies = filteredCookies.filter(cookie => cookie.cookiesAffiliateID === 'Molusco').length;
  const jayCookies = filteredCookies.filter(cookie => cookie.cookiesAffiliateID === 'Jay').length;
  const carlosCookies = filteredCookies.filter(cookie => cookie.cookiesAffiliateID === 'Carlos_Ponce').length;
  
  return {
    molusco: moluscoCookies,
    jay: jayCookies,
    carlos: carlosCookies,
  };
}

  
  async updateCombinedData() {

    const cookiesCount = await this.getCookiesCount();

    this.combinedData = [

      {
        influencer: 'Molusco',
        orders: this.OrderReferralAnalytics.series[0].data[0],
        registered: this.ReferalInfluencerAnalytics.series[0].data[0],

        cookies: cookiesCount.molusco,

        campaignsOrders: this.allOrderValuesBasedonCampaign['Molusco'] || {},
        campaignsUsers: this.allUsersBasedOnCampaign['Molusco'] || {},
      },
      {
        influencer: 'Jay Fonseca',
        orders: this.OrderReferralAnalytics.series[0].data[1],
        registered: this.ReferalInfluencerAnalytics.series[0].data[1],

        cookies: cookiesCount.jay,

        campaignsOrders: this.allOrderValuesBasedonCampaign['Jay'] || {},
        campaignsUsers: this.allUsersBasedOnCampaign['Jay'] || {},
      },
      {
        influencer: 'Carlos Ponce', 
        orders: this.OrderReferralAnalytics.series[0].data[2],
        registered: this.ReferalInfluencerAnalytics.series[0].data[2],

        cookies: cookiesCount.carlos,

        campaignsOrders: this.allOrderValuesBasedonCampaign['Carlos_Ponce'] || {},
        campaignsUsers: this.allOrderValuesBasedonCampaign['Carlos_Ponce'] || {},
      }
    ];
  }
  

  exportToCSV() {
    const csvRows = [
      ['Influencer', 'Orders', 'Registered Users'],
      ...this.combinedData.map(row => [row.influencer, row.orders, row.registered])
    ];

    const csvContent = csvRows.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'referral_analytics.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  reset(){
    this.startDate = '2022-07-15T00:00';
    this.date = new Date();
    this.endDate = this.year + '-' + (this.month < 10 ? '0' + this.month : this.month) + '-' + (this.day < 10 ? '0' + this.day : this.day) + 'T' + this.date.getHours() + ':' + this.date.getMinutes().toString().padStart(2, '0');
  }



}

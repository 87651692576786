<div class="form mt-2 flex-column" *ngIf="!editProfile && !linkPhoneNumber">
  <div class="" *ngIf="profileToEdit && !editProfile"><!--leftSide -->
    <div class="pt-0 data">
      <div class="fade-in userProfile">
        <div class="d-flex justify-content-between align-items-end">
          <div>
            <h5 style="display: flex; align-items: center; justify-content: space-between;" class="mt-3 ml-2">{{profileToEdit.firstName}} {{profileToEdit.middleName}} {{profileToEdit.lastName1}}</h5>     
            <div class="row ml-2 mb-2" [ngClass]="{'disableDiv': editProfile == false}" style="width: 100%;">
              <img style="width: 150px; height: 150px;" class="mt-2" src="{{profileToEdit.image}}" alt="Header Avatar">
            </div>
            <span class="d-flex m-0">{{'ACCOUNTS.EMAIL&PASSWORD.EMAIL' | translate}}</span>
            <h5 style="display: flex; align-items: center;">{{profileToEdit.email}}</h5>
            <span class="d-flex m-0">{{'SIGNUP.PHONENUMBER' | translate}}</span> 
            <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="editProfile == false">{{profileToEdit.phoneNumber | mask:'(000) 000-0000'}}</h5> 
            <div class="custom-control custom-checkbox mb-2">
              <input type="checkbox" class="custom-control-input" id="phoneType" (change)="selectPhone($event)" name="phoneNumberMsg" [(ngModel)]="profileToEdit.phoneNumberMsg" [attr.disabled]="disableCheckbox(profileToEdit.phoneNumber, 'phoneType')">
              <span class="custom-control-label" for="phoneType">{{'PROFILE.MSG' | translate}}</span>
            </div>
            <span class="d-flex m-0" *ngIf="profileToEdit.birthdate">{{'PROFILE.BIRTHDATE' | translate}}</span>
            <h5 style="display: flex; align-items: center;" *ngIf="profileToEdit.birthdate">{{profileToEdit.birthdate}}</h5>
            <span class="d-flex m-0" *ngIf="profileToEdit.biologicalgender">{{'PROFILE.GENDER' | translate}}</span>
            <h5 style="display: flex; align-items: center;" *ngIf="profileToEdit.biologicalgender == 'Male'">{{'PROFILE.MALE' | translate}} </h5>
            <h5 style="display: flex; align-items: center;" *ngIf="profileToEdit.biologicalgender == 'Female'">{{'PROFILE.FEMALE' | translate}} </h5>
            <span class="d-flex m-0">{{'PROFILE.SOCIALGENDER' | translate}}</span>   
            <h5 style="display: flex; align-items: center; justify-content: space-between;" *ngIf="profileToEdit.gender && editProfile == false">{{profileToEdit.gender}}</h5>
          </div>
          <div>
            <span class="d-flex m-0">{{'ACCOUNTS.VERIFIED' | translate}}: </span>
            <div class="ml-2">
              <div class="badge badge-danger font-size-12" style="text-transform: capitalize;" *ngIf="!accountMetadata.emailVerified">{{'ACCOUNTS.NO' | translate}}</div>
              <div class="badge badge-success font-size-12" style="text-transform: capitalize;" *ngIf="accountMetadata.emailVerified">{{'ACCOUNTS.YES' | translate}}</div>
            </div>
            <span class="d-flex m-0">{{'ACCOUNTS.DISABLED' | translate}}: </span>
            <div class="ml-2">
              <div class="badge badge-success font-size-12" style="text-transform: capitalize;" *ngIf="!accountMetadata.disabled">{{'ACCOUNTS.NO' | translate}}</div>
              <div class="badge badge-danger font-size-12" style="text-transform: capitalize;" *ngIf="accountMetadata.disabled">{{'ACCOUNTS.YES' | translate}}</div>
            </div>
            <div class="mb-2 mt-1">
              <button type="button" style="display: flex; font-size: 16px !important; padding: 0 0.75rem !important;" class="btn btn-primary btn-sm cardBtn adminBtn mb-1" *ngIf="profileToEdit && !this.profileToEdit?.isAccountDisabled" (click)="disable(true)">
                {{'ACCOUNTS.DISABLEACCOUNT' | translate}}
              </button>
              <button type="button" style="display: flex; font-size: 16px !important; padding: 0 0.75rem !important;" class="btn btn-primary btn-sm cardBtn adminBtn mb-1" *ngIf="profileToEdit && this.profileToEdit?.isAccountDisabled" (click)="disable(false)">
                {{'ACCOUNTS.ENABLEACCOUNT' | translate}}
              </button>
            </div>
            <h6 *ngIf="msg" style="color: red;">{{msg | translate}}</h6>
            <span class="d-flex m-0">{{'PROFILE.ALTERNATENUMBER' | translate}}
            </span> 
            <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="editProfile == false">{{profileToEdit.alternateNumber | mask:'(000) 000-0000'}} </h5>   
            <span class="d-flex m-0">{{'ACCOUNTS.ACD' | translate}}</span>
            <h5 style="display: flex; align-items: center; justify-content: space-between;" *ngIf="editProfile == false && accountMetadata.metadata">{{accountMetadata.metadata.creationTime | date:'medium':'':profileToEdit.preferredLang}}</h5>
            <span class="d-flex m-0">{{'ACCOUNTS.LSID' | translate}}</span>
            <h5 style="display: flex; align-items: center; justify-content: space-between;" *ngIf="editProfile == false && accountMetadata.metadata">{{accountMetadata.metadata.lastSignInTime | date:'medium':'':profileToEdit.preferredLang}}</h5>
            <div class="mt-4" *ngIf="profileToEdit.accountType == 'practitioner' && practitionerNPI">
              <span class="d-flex m-0">{{'CONSULT-PRESCRIPTIONS.NPI' | translate}}</span>
              <h5 style="display: flex; align-items: center; justify-content: space-between;" class="m-0 bold">{{practitionerNPI.NPI}}</h5>
              <span style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;">Taxonomies</span>
              <div *ngFor="let taxonomy of practitionerNPI.taxonomies">
                <h6 style="display: flex; align-items: center; justify-content: space-between;" class="m-0 ml-2">{{taxonomy.code}} - {{taxonomy.desc}}</h6>
              </div>
              <button type="button" style="display: flex; font-size: 16px !important; padding: 0 0.75rem !important;" class="btn btn-primary btn-sm cardBtn adminBtn mb-1" (click)="searchRegistry()">
                Update NPI
              </button>
            </div>
          </div>
          <div>
            <span class="d-flex m-0">{{'ACCOUNTS.EMAIL&PASSWORD.TYPEOFACCOUNT' | translate}}</span>
            <h5 style="display: flex; align-items: center; justify-content: space-between;" *ngIf="editProfile == false">{{profileToEdit.accountType | titlecase}}</h5>
            <span class="d-flex m-0">{{'ACCOUNTS.UID' | translate}}</span>
            <h5 style="display: flex; align-items: center; justify-content: space-between;" *ngIf="editProfile == false">{{profileToEdit.uid}}</h5>
            <span class="d-flex m-0">Partner ID</span>
            <h5 style="display: flex; align-items: center; justify-content: space-between;" *ngIf="editProfile == false">{{profileToEdit.partnerID}}</h5>
            <span class="d-flex m-0">{{'ACCOUNTS.LASTVERIFIEDBY' | translate}} </span>
            <h5 style="display: flex; align-items: center; justify-content: space-between;" *ngIf="verifiedEmail">{{verifiedEmail}}</h5>
            <span class="d-flex m-0">{{'ACCOUNTS.LASTVERIFIEDDATE' | translate}} </span>
            <h5 style="display: flex; align-items: center; justify-content: space-between;" *ngIf="profileToEdit && profileToEdit.verifiedDate">{{profileToEdit.verifiedDate?.toDate() | date: 'medium':'':profileToEdit.preferredLang }}</h5>
            <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
            <button type="button" style="display: flex; font-size: 16px !important; padding: 0 0.75rem !important;" class="btn btn-primary btn-sm cardBtn adminBtn mb-1" (click)="showAlertMsg(profileToEdit)">
              {{'ACCOUNTS.NGBTOOLTIP.RESETPASSWORD' | translate}}
            </button>
          </div>
        </div>
        <div class="mt-1" style="display: flex; flex-direction: column; align-items: flex-start;">
            <span style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;margin-top: 10px;" *ngIf="editProfile == false">{{'ADMINCONSULTS.ADDRESS' | translate}}</span> 
            <app-address [user]="profileToEdit" [currentUser]="profileToEdit"></app-address>
        </div>        
      </div>
    </div>
  </div>
  







  <div class="" id="hide"> <!-- rightSide -->
    <div class="data">
      <div class="fade-in userProfile">
        <div class="col-lg-12 d-md-block">
          <div class="">
            <div class="card-body p-0">
            
              <div class="mt-3" style="display: flex; justify-content: space-between;">
                <h5 class="mb-4">{{'PROFILE.ID' | translate}}</h5>
                <h4 class="card-title mb-4" *ngIf="profileToEdit && profileToEdit.status == 'Verified'" [ngClass]="{'text-success': profileToEdit && profileToEdit.status == 'Verified'}" style="color: red;">{{'PROFILE.VERIFIED' | translate}}</h4>
                <h4 class="card-title mb-4" *ngIf="profileToEdit && profileToEdit.status != 'Verified'" [ngClass]="{'text-success': profileToEdit && profileToEdit.status == 'Verified'}" style="color: red;">{{'PROFILE.UNVERIFIED' | translate}}</h4>
              </div>     
  
              <div class="form-group d-flex align-items-end w-auto"> 
  
                <div class="custom-dropzone justify-content-center m-0 mr-3">
                  <div class="dropzone-content justify-content-center">
                    <div class="row" [ngClass]="{'disableDiv': editProfile == false}">
                      <img *ngIf="profileToEdit && profileToEdit.identification != ''" class="justify-content-center" src="{{profileToEdit.identification}}" alt="Header Avatar">
                    </div>
                  </div>
                </div>
                <div>
                  <div class="col-md-12 mt-3">
                    <span class="d-flex m-0">{{'PROFILE.IDNUMBER' | translate}}</span>
                    <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="profileToEdit && editProfile == false">{{profileToEdit.idNumber}}</h5>   
                  </div>
                  <div class="col-md-12">
                    <span class="d-flex m-0">{{'PROFILE.EXPDATE' | translate}}</span>
                    <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="profileToEdit && editProfile == false">{{profileToEdit.idExpDate}} </h5>   
                  </div>
                  <div class="col-md-12">
                    <span class="d-flex m-0">{{'PROFILE.COUNTRYISSUED' | translate}}</span>
                    <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="profileToEdit && editProfile == false">{{profileToEdit.idCountryIssued}}</h5>   
                  </div>
                  <div class="col-md-12">
                    <span style="display: flex; margin-bottom: 0;">{{'PROFILE.STATEISSUED' | translate}}</span>
                    <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="profileToEdit && editProfile == false">{{profileToEdit.idStateIssued}} </h5>   
                  </div>     
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="mt-4" *ngIf="editProfile && !linkPhoneNumber">
  <div class="form">
    <div class="leftSide" *ngIf="profileToEdit">
      <div class="Customsimage">
        <h5 class="fade-in">{{'PROFILE.PROFILEPICTURE' | translate}}</h5>
        <div class="row justify-content-center" style="width: 100%;">
          <img style="width: 200px; height: 200px;" class="mt-2 cropped" src="{{profileToEdit.image}}" (click)="cropImage(profileToEdit.image, 'defaultProfileImg'); profileImage = true; profileId = false; ratio = 1; front = undefined" alt="Header Avatar">
        </div>
      </div>

      <div class="col-lg-10">
        <label for="firstName" class="inp">
          <input type="text" id="firstName" value="{{profileToEdit.firstName}}" name="firstName"  placeholder="&nbsp;" [(ngModel)]="profileToEdit.firstName"  required [ngClass]="{'is-invalid': profileToEdit.firstName == ''}">
          <span class="label">{{'PROFILE.FIRSTNAME' | translate}}</span>
          <span class="focus-bg"></span>
        </label>
      </div>   
      
      <div class="col-md-10">
        <label for="middleName" class="inp" [ngClass]=" ">
          <input type="text" value="{{profileToEdit.middleName}}" name="middleName" id="middleName" placeholder="&nbsp;" [(ngModel)]="profileToEdit.middleName">
          <span class="label">{{'PROFILE.MIDDLENAME' | translate}}</span>
          <span class="focus-bg"></span>
        </label>
      </div>

      <div class="col-md-10">
        <label for="lastName1" class="inp">
            <input type="text" value="{{profileToEdit.lastName1}}" name="lastName1" id="lastName1" placeholder="&nbsp;" [(ngModel)]="profileToEdit.lastName1" required [ngClass]="{'is-invalid': profileToEdit.lastName1 == ''}">
            <span class="label">{{'PROFILE.LASTNAME1' | translate}}</span>
            <span class="focus-bg"></span>
        </label>
      </div>

      <div class="col-md-10">
        <label for="date" class="inp">
            <input type="date" id="date" value="{{profileToEdit.birthdate}}" placeholder="&nbsp;" [(ngModel)]="profileToEdit.birthdate" required>
            <span class="label">{{'PROFILE.BIRTHDATE' | translate}}</span>
            <span class="focus-bg"></span>
        </label>
      </div>

      <div class="col-md-10" [ngClass]="{'disableGender': hasConsult == true}">
        <label for="select" class="inp">
            <select id="select" value="{{profileToEdit.biologicalgender}}" [(ngModel)]="profileToEdit.biologicalgender" required>
            <option value="Male">{{'PROFILE.MALE' | translate}}</option>
            <option value="Female">{{'PROFILE.FEMALE' | translate}}</option>
            <!-- <option value="Intersex">{{'PROFILE.INTERSEX' | translate}}</option> -->
            </select>
            <span class="label">{{'PROFILE.GENDER' | translate}}</span>
            <span class="focus-bg"></span>
        </label>
        <div style="color: red;" class="text-center" *ngIf="hasConsult">{{'PROFILE.CANTCHANGEGENDER'| translate}}</div>
      </div>

      <div class="col-md-10">
        <label for="gender" class="inp">
            <input type="text" value="{{profileToEdit.gender}}" name="gender" id="gender" placeholder="&nbsp;" [(ngModel)]="profileToEdit.gender" [ngClass]="{'is-invalid': profileToEdit.gender == ''}"> 
            <span class="label">{{'PROFILE.SOCIALGENDER' | translate}}</span>
            <span class="focus-bg"></span>
        </label>
      </div>
      <div class="col-md-10">
        <label for="phoneNumber" class="inp">
          <input type="tel" value="{{profileToEdit.phoneNumber}}" mask="(000) 000-0000" maxlength="14" name="phoneNumber" id="phoneNumber" placeholder="&nbsp;" [(ngModel)]="profileToEdit.phoneNumber">       
          <span class="label">{{'SIGNUP.PHONENUMBER' | translate}}</span>
          <span class="focus-bg"></span>
        </label>  
      </div>
      <div class="custom-control custom-checkbox ml-3 mb-2">
        <input type="checkbox" class="custom-control-input" id="phoneType" (change)="selectPhone($event)" name="phoneNumberMsg" [(ngModel)]="profileToEdit.phoneNumberMsg" [attr.disabled]="disableCheckbox(profileToEdit.phoneNumber, 'phoneType')">
        <span class="custom-control-label" for="phoneType">{{'PROFILE.MSG' | translate}}</span>
      </div>
      <div class="col-md-10">
        <label for="alternateNumber" class="inp">
          <input type="tel" value="{{profileToEdit.alternateNumber}}" mask="(000) 000-0000" maxlength="14" name="alternateNumber" id="alternateNumber" placeholder="&nbsp;" [(ngModel)]="profileToEdit.alternateNumber">       
          <span class="label">{{'PROFILE.ALTERNATENUMBER' | translate}}</span>
          <span class="focus-bg"></span>
        </label>  
      </div>
    </div>
    <div class="rightSide">
      <div class="form-group row justify-content-center">
        <h5 class="fade-in">{{'PROFILE.ID' | translate}}</h5>
      </div>

      <div class="form-group column justify-content-center" *ngIf="profileToEdit">  
        <div class="custom-dropzone justify-content-center">
          <div class="dropzone-content justify-content-center">
            <div class="row">
              <img *ngIf="profileToEdit && profileToEdit.identification != ''" class="justify-content-center" src="{{profileToEdit.identification}}" (click)="cropImage(profileToEdit.identification, 'defaultIDImg'); profileImage = false; profileId = true; ratio = 485/300; front = true" alt="Header Avatar">
            </div>
          </div>
        </div>
        <br>
        <div class="col-md-12">
          <label for="idNumber" class="inp input">
              <input type="text" value="{{profileToEdit.idNumber}}" name="idNumber" id="idNumber" placeholder="&nbsp;" [(ngModel)]="profileToEdit.idNumber">
              <span class="label">{{'PROFILE.IDNUMBER' | translate}}</span>
              <span class="focus-bg"></span>        
          </label>
        </div>
        <br>
        <div class="col-md-12">
          <label for="idExpDate" class="inp input">
              <input type="date" value="{{profileToEdit.idExpDate}}" name="idExpDate" id="idExpDate" placeholder="&nbsp;" [(ngModel)]="profileToEdit.idExpDate">
              <span class="label">{{'PROFILE.EXPDATE' | translate}}</span>
              <span class="focus-bg"></span>        
          </label>
        </div>
        <br>
        <div class="col-md-12">
          <label for="idCountryIssued" class="inp input">
            <input type="text" value="{{profileToEdit.idCountryIssued}}" name="idCountryIssued" id="idCountryIssued" placeholder="&nbsp;" [(ngModel)]="profileToEdit.idCountryIssued">
            <span class="label">{{'PROFILE.COUNTRYISSUED' | translate}}</span>
            <span class="focus-bg"></span>        
          </label>
        </div>
        <br>
        <div class="col-md-12">
          <!-- <ng-select class="custom select" [items]="AddressStates"  placeholder="{{'PROFILE.STATEISSUED' | translate}}" formControlName="idStateIssued" [(ngModel)]="profileToEdit.idStateIssued" ></ng-select>     -->
          <label for="idStateIssued" class="inp input">
            <input type="text" value="{{profileToEdit.idStateIssued}}" name="idStateIssued" id="idStateIssued" placeholder="&nbsp;" [(ngModel)]="profileToEdit.idStateIssued">
            <span class="label">{{'PROFILE.STATEISSUED' | translate}}</span>
            <span class="focus-bg"></span>        
        </label>
        </div>
      </div>
    </div>
  </div>
</div>  
<div class="mt-4" *ngIf="editProfile && !linkPhoneNumber && profileToEdit.accountType != 'practitioner'">
  <form #myForm="ngForm" class="form">
    <div class="leftSide" *ngIf="profileToEdit">
      <div class="form-group row justify-content-center">
        <h5 class="fade-in">{{'ADMINCONSULTS.SHIPPINGADDRESS' | translate}}</h5>
      </div>
      <div class="col-lg-10">
        <label for="AddressLine1" class="inp">
          <input type="text" id="AddressLine1" name="AddressLine1"  placeholder="&nbsp;" [(ngModel)]="shipping.AddressLine1">
          <span class="label">{{'ADDRESS.ADDRESSLINE1' | translate}}</span>
          <span class="focus-bg"></span>
        </label>
      </div>   
      
      <div class="col-md-10">
        <label for="AddressLine2" class="inp" [ngClass]=" ">
          <input type="text" name="AddressLine2" id="AddressLine2" placeholder="&nbsp;" [(ngModel)]="shipping.AddressLine2">
          <span class="label">{{'ADDRESS.ADDRESSLINE2' | translate}}</span>
          <span class="focus-bg"></span>
        </label>
      </div>

      <div class="col-md-10">
        <label for="AddressCity" class="inp">
            <input type="text" name="AddressCity" id="AddressCity" placeholder="&nbsp;" [(ngModel)]="shipping.AddressCity">
            <span class="label">{{'ADDRESS.CITY' | translate}}</span>
            <span class="focus-bg"></span>
        </label>
      </div>

      <div class="col-md-10">
        <label for="AddressState" class="inp">
            <input type="text" id="AddressState" placeholder="&nbsp;" [(ngModel)]="shipping.AddressState">
            <span class="label">{{'ADDRESS.STATE' | translate}}</span>
            <span class="focus-bg"></span>
        </label>
      </div>

      <div class="col-md-10">
        <label for="AddressCountry" class="inp">
          <input type="text" id="AddressCountry" placeholder="&nbsp;" [(ngModel)]="shipping.AddressCountry">
          <span class="label">{{'ADDRESS.COUNTRY' | translate}}</span>
          <span class="focus-bg"></span>
        </label>
      </div>

      <div class="col-md-10">
        <label for="AddressZipcode" class="inp">
            <input type="text" name="AddressZipcode" id="AddressZipcode" placeholder="&nbsp;" [(ngModel)]="shipping.AddressZipcode"> 
            <span class="label">{{'ADDRESS.ZIPCODE' | translate}}</span>
            <span class="focus-bg"></span>
        </label>
      </div>
    </div>
    <div class="rightSide">
      <a href="javascript: void(0);" class="btn btn-info btn-sm btn-cyan cardBtn my-2 mx-auto d-flex" style="width: fit-content;" (click)="showModal(addCard)"> {{'PROFILE.ADDCARD' | translate}}</a>
      <div class="card-body" style="padding-bottom: 0 !important;">
        <h2 class="card-title mb-2 cyan text-center" style="font-size: x-large;"> {{'PROFILE.WALLET' | translate}}</h2>
        <hr style="margin: auto; border: none; border-bottom: 2px dotted #e9ebee;">

          <perfect-scrollbar style="padding-bottom: 15px;">
          <div class="table-responsive mx-auto cards">
            <table class="table table-hover mb-0 table-centered table-nowrap">
              <tbody>
                <tr *ngFor="let item of allPaymentMethods">
                  <td style="width: 60px;">
                    <div class="avatar-xs">
                      <div class="avatar-title rounded-circle bg-light">
                        <img src="assets/images/cards/visa-color-logo.png" *ngIf="item.brand === 'Visa' || item.brand === 'VISA'" alt="Visa" width="20"/>
                        <img src="assets/images/cards/mastercard-logo.png" *ngIf="item.brand === 'Mastercard' || item.brand === 'MASTERCARD'" alt="MasterCard" width="20"/>
                        <img src="assets/images/cards/american_express_01.png" *ngIf="item.brand === 'American Express' || item.brand === 'AMERICAN_EXPRESS'" alt="American Express" width="20"/>
                        <img src="assets/images/cards/discover.png" *ngIf="item.brand === 'Discover' || item.brand === 'DISCOVER' || item.brand === 'Discover Club'" alt="Discover" width="20"/>
                        <img src="assets/images/cards/jcb_01.png" *ngIf="item.brand === 'JCB' || item.brand === 'JCB'" alt="JCB" width="20"/>
                        <img src="assets/images/cards/diners_club_01.png" *ngIf="item.brand === 'Diners Club' || item.brand === 'DISCOVER_DINERS'" alt="Diners Club" width="20"/>
                        <img src="assets/images/cards/china_union_01.png" *ngIf="item.brand === 'UnionPay' || item.brand === 'CHINA_UNIONPAY'" alt="UnionPay" width="20"/>
                      </div>
                    </div>
                  </td>
                  <td>
                    <h5 class="font-size-14 mb-0" style="text-transform: capitalize; text-align: start;">{{item.brand}}</h5>
                    <p class="lead font-size-14 mb-0">x{{item.last4}}</p>
                    <p class="text-muted font-size-14 mb-0">{{item.expDate}}</p>
                  </td>
                  <td style="width: 10%;">
                    <p class="text-muted mb-0"><i class="mdi mdi-delete-outline font-size-20 mr-3 text-danger" (click)="deleteCardConfirmation(item)"></i></p>
                  </td>
                </tr>                  
              </tbody>
            </table>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </form>
</div>  
<div class="form-group row justify-content-center mt-4" *ngIf="profileToEdit && !linkPhoneNumber">
  <!-- <button *ngIf="!editProfile" class="btn btn-info btn-sm mr-2 mt-2" style="color: white !important;" (click)="addRoles()">Add Roles</button> -->
  <!-- <button *ngIf="!editProfile" class="btn btn-info btn-sm mr-2 mt-2" style="color: white !important;" (click)="removeRoles()">Remove Roles</button> -->
  <button *ngIf="!editProfile" class="btn btn-info btn-sm mr-2 mt-2" style="color: white !important;" (click)="editProfile = true">{{'PROFILE.EDIT' | translate}}</button>
  <button *ngIf="!editProfile" class="btn btn-info btn-sm mr-2 mt-2" style="color: white !important;" (click)="openModal()">{{'VIEWCONSULTS.REQUESTUPDATE' | translate}}</button>
  <button *ngIf="editProfile" class="btn btn-light mr-2 mt-2" style="color: black !important;" (click)="editProfile = false; getUser()">{{'TOPBAR.SWAL.CANCEL' | translate}}</button>
  <!-- <button *ngIf="editProfile" class="btn btn-info btn-sm mr-2 mt-2" style="color: white !important;" id="save" (click)="profileSubmit(); editProfile = false" [disabled]="invalidSubmit()">{{'ADDRESS.SAVE' | translate}}</button> -->
  <button *ngIf="editProfile" class="btn btn-info btn-sm mr-2 mt-2" style="color: white !important;" id="save" (click)="profileSubmit(); editProfile = false" >{{'ADDRESS.SAVE' | translate}}</button>
  <button  class="btn btn-info btn-sm mr-2 mt-2" style="color: white !important;" (click)="adminRefreshUserPage()">{{'ADMINCONSULTS.REFRESH' | translate}}</button>
  <!-- <button *ngIf="editProfile" class="btn btn-info btn-sm mr-2" style="color: white !important;" id="save" (click)="cropImage(); profileImage = true; profileId = false; ratio = 1">Upload image</button> -->
  <button type="button" style="display: flex;" class="btn btn-primary btn-sm cardBtn adminBtn mr-2 mt-2" *ngIf="profileToEdit && this.profileToEdit?.status == 'unverified' || this.profileToEdit?.status == undefined || this.profileToEdit?.status == 'none'" (click)="accountStatus('Verified')">
    {{'PROFILE.VERIFY' | translate}}
  </button>
  <button type="button" style="display: flex;" class="btn btn-primary btn-sm cardBtn adminBtn mr-2 mt-2" *ngIf="profileToEdit && this.profileToEdit?.status == 'Verified'" (click)="accountStatus('unverified')">
    {{'PROFILE.UNVERIFY' | translate}}
  </button>
</div>

<ng-template #imageCropper let-modal style="height: 500px;">
  <input hidden type="file" id="imageInput"  accept="image/*" (change)="fileChangeEvent($event)" />
  
  <!-- <button type="button" class="close float-right modal-close d-flex justify-content-end" (click)="profileImage = false; profileId = false; imageChangedEvent = ''; croppedImage = ''; dismiss()" aria-hidden="true">×</button> -->

  <div class="d-flex justify-content-center align-items-center flex-column">
    <div *ngIf="croppedImage">
      <div class="btn-toolbar p-3" style="flex-wrap: nowrap;" role="toolbar">
        <div class="btn-group mb-sm-0 mx-2 mb-4">    
          <button class="btn btn-info btn-sm btn-cyan cardBtn" style="width: fit-content; border-radius: 4px 0 0 4px !important;" (click)="rotateRight()"><i class="mdi mdi-rotate-right"></i></button>
          <hr class="m-0 h-auto" style="border: 1px solid #2a72ca">
          <button class="btn btn-info btn-sm btn-cyan cardBtn" style="width: fit-content; border-radius: 0 4px 4px 0 !important;" (click)="flipHorizontal()"><i class="mdi mdi-flip-horizontal"></i></button>
        </div>
      </div>
    </div>  
  
    <div>
      <image-cropper *ngIf="!imageLoaded" [ngClass]="{'image-cropper': profileImage == true, 'id-cropper': profileId == true}"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="containWithinAspectRatio"
          [aspectRatio]="ratio"
          [resizeToWidth]="350"
          [cropperMinWidth]="128"
          [onlyScaleDown]="true"
          [roundCropper]="false"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
          [alignImage]="'left'"
          [style.display]="showCropper ? null : 'none'"
          format="png"
          (imageCropped)="imageCropped($event)"
      ></image-cropper>
      <image-cropper *ngIf="imageLoaded" [ngClass]="{'image-cropper': profileImage == true, 'id-cropper': profileId == true}"
          [imageBase64]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="containWithinAspectRatio"
          [aspectRatio]="ratio"
          [resizeToWidth]="350"
          [cropperMinWidth]="128"
          [onlyScaleDown]="true"
          [roundCropper]="false"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
          [alignImage]="'left'"
          [style.display]="showCropper ? null : 'none'"
          format="png"
          (imageCropped)="imageCropped($event)"
      ></image-cropper>
    </div>
    <img hidden [ngClass]="{'image-cropper': profileImage == true, 'id-cropper': profileId == true}" [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />
  
    <button *ngIf="croppedImage" class="btn btn-info btn-sm cardBtn mb-4 mt-2" style="width: fit-content;" (click)="saveImage()">{{'PROFILE.SAVEIMAGE' | translate}}</button>
  </div>


  <div *ngIf="!croppedImage" class="mb-5">
    <h2 class="text-center m-2">{{'PROFILE.WAITINGFORIMAGE' | translate}}</h2>
  </div>
  
  <button *ngIf="!croppedImage" type="button" class="btn btn-cyan mb-2 px-2 py-1 modal-close"  (click)="profileImage = false; profileId = false; imageChangedEvent = ''; croppedImage = ''; dismiss()" aria-hidden="true" style="width: fit-content;">{{'CONSULTATIONS.SWAL.CLOSE' | translate}}</button>

</ng-template>

<ng-template #profileUpdate let-modal style="height: 500px;">
  <div class="p-3">
    <div class="justify-content-end">
      <button type="button" class="close float-right modal-close" (click)="dismiss()" aria-hidden="true">×</button>
    </div>
   
    <h4 class="mt-3">{{'VIEWCONSULTS.REQUESTUPDATE' | translate}}</h4>
  
    <div class="d-flex" *ngFor="let field of profileFields">
      <div class="custom-control custom-checkbox mb-2">
        <input type="checkbox" class="custom-control-input" id="{{field.field}}" (change)="selectFields($event)">
        <label class="custom-control-label" for="{{field.field}}">{{field.field | translate}}</label>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-info button fit-content" role="button" (click)="sendRequest()" [disabled]="verifyFields.length === 0">{{'VIEWCONSULTS.REQUESTUPDATE' | translate | uppercase}}</button>
    </div>
  </div>
</ng-template>

<ng-template #addCard let-modal>
  <app-add-payment [user]="profileToEdit" [name]="profileToEdit.firstName" [lastName]="profileToEdit.lastName1" [modalRef]="modalRef"></app-add-payment>
</ng-template>

<div *ngIf="loadingImg == true">
  <app-reload></app-reload>
</div>

<div *ngIf="linkPhoneNumber == true" class="form-group row d-flex flex-column justify-content-center mt-2">
  <div class="form-group d-flex flex-column justify-content-center fade-in mt-3">
    <h6 class="fade-in text-center" style="width: 80%; align-self: center;">{{'PROFILE.CHECKPHONE' | translate}}</h6>
  </div>

  <div class="input-wrap token">
    <div class="input token-fake" *ngFor="let i of [1,2,3,4,5,6]">
      <input type="number" class="token-input" (keyup)="verifyCode($event)" (click)="nextVC = nextVC + 't';" placeholder="0" name="" id="code-{{i}}">
    </div>
  </div>
  <h6 style="color: red;">{{msg | translate}}</h6>  
  <div *ngIf="linkPhoneNumber"  class="row">
    <div class="form-group justify-content-center text-center mt-2 d-flex flex-column align-items-center" style="margin: 0 auto;">
      <!-- <button type="button" class="btn btn-info nextbtn text-center mb-3" id="nextBtn">{{'PROFILE.VERIFYCODE' | translate}}</button> -->
      <h6 class="fade-in text-center" >{{'SIGNUP.HAVENTRECEIVEDCODE' | translate}}</h6>
      <button type="button" class="btn btn-cyan nextbtn text-center mb-3" style="width: fit-content !important;" id="resendBtn" (click)="resendVerificationCode()"> {{'SIGNUP.RESENDCODE' | translate}}</button>
      <button class="btn justify-content-start float-left" (click)="linkPhoneNumber = false; cancelPhoneVerification();"> {{'PROFILE.BACK' | translate}}</button>
    </div>
  </div>

  <div class="msg-info">
    <p>{{'PROFILE.MSG-INFO' | translate}}</p>
    <!-- <p>{{'PROFILE.MSG-INFO2' | translate}}</p> -->
  </div>
</div> 
import { Component, OnInit } from '@angular/core';




const valid = require("card-validator");

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  constructor() { }
  methods: string[] = ["Credit card", "Debit card", "Paypal", "ATH Movil", "ACH"];
  ngOnInit(): void {

    let test = valid.number("4111");
  }



  handleSituation($event) {
    let option = document.getElementById($event.target.id);
    let previous = [].slice.call(document.getElementById('methods').getElementsByTagName('*'),0);
    for(let i = 0; i < previous.length; i++){
      if(previous[i].classList.contains("active")){
        previous[i].classList.remove("active");
      }
    }
    option.classList.add("active");

    // if(option.classList.contains("active")){
    //   option.classList.remove("active");
    // }
    // else{
    //   option.classList.add("active");
    // }

  }
}

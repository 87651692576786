import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class QuestionServiceService {

  constructor(private afs:AngularFirestore) { }

  getTemplateQuestions(questionCode: any, otherIndex?: number){
    return new Promise<any>(async (resolve, reject) => {
      let startIndex = 1;
      if(typeof(questionCode) == 'string'){
        questionCode = [questionCode]
      }

      let allQuestions = []
      
      if(otherIndex){
        startIndex = otherIndex + 1;
      };

      for(let i = 0; i < questionCode.length; i++){
        await this.afs.firestore.collection("conditionsQuestions").doc(questionCode[i]).collection("Questions").get().then(data => {
          data.docs.sort((a,b) => {
            return a.data().sequence - b.data().sequence
          }).forEach((q, j) => {
            let question = q.data()
            question.assessment = questionCode[i]
            question.assessmentId = question.id
            question.sequence = startIndex;
            question.id = (startIndex).toString();
            if(i > 0 || otherIndex){
              question.preconsult = false;
            }
            allQuestions.push(question);
            startIndex++;
           
            if(i == questionCode.length - 1 && j == data.docs.length - 1){
              resolve(allQuestions);
            }
          });
        })
      }
    });
  }
}

<div class="card">
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-centered m-0">
                <thead class="thead-light">
                    <tr> 
                        <th class="header-bg" scope="col">{{'CONSULT-PRESCRIPTIONS.NAME' | translate}}</th>
                        <th class="header-bg" scope="col">{{'CONSULT-PRESCRIPTIONS.DOSAGE' | translate}}</th>
                        <th class="header-bg" scope="col" style="text-align: right;">{{'CONSULT-PRESCRIPTIONS.QTY' | translate}}</th>
                        <th class="header-bg" scope="col" style="text-align: right;">{{'CONSULT-PRESCRIPTIONS.PRICEPERUNIT' | translate}}</th>
                        <th class="header-bg" scope="col" style="text-align: right;">{{'CONSULT-PRESCRIPTIONS.TOTAL' | translate}}</th>
                    </tr>
                </thead>
                <tbody *ngFor="let group of groupByTabletQty | keyvalue">
                    <tr>
                        <td colspan="5" class="td-border-bottom section-bg lead">{{group.key}} {{'CONSULT-PRESCRIPTIONS.TABLETS' | translate}}</td>
                    </tr>
                    <tr *ngFor="let product of group.value">
                        <td class="td-border-bottom">{{product.productName}}</td>
                        <td class="td-border-bottom">{{product.productDosage}}</td> 
                        <td class="td-border-bottom" style="text-align: right;">{{product.productQty}}</td>
                        <td class="td-border-bottom" style="text-align: right;">{{setPillPrice(product.productPrice, product) | currency:'USD'}}</td>
                        <td class="td-border-bottom" style="text-align: right;">{{setPrice(product.productPrice) | currency:'USD'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> 
<div class="main" *ngIf="!loading">
  <h2 *ngIf="!migrateProduct" class="title">{{'HOME.PRESCRIPTIONS' | translate}}</h2>
  <p  *ngIf="!migrateProduct">{{'PRESCRIPTIONS-LISTS.DESCRIPTIONS' | translate}}</p>
  <div *ngIf="!migrateProduct" class="search ml-4">
    <i class="fas fa-search"></i>
    <input id="search" type="text" placeholder="{{'PRESCRIPTIONS.SEARCH' | translate}}" [(ngModel)]="term">
  </div>
  <div class="table-responsive">
    <table id="basic-datatable" class="table table-centered dt-responsive nowrap datatables no-footer dtr-inline">
      <thead class="border-rounded">
        <tr class="justify-content-center text-center">
          <th sortable="name">{{'PRESCRIPTIONS.PRODUCTNAME' | translate}}</th>
          <th sortable="qty">{{'PRESCRIPTIONS.QTY' | translate}}</th>
          <th sortable="refill">{{'PRESCRIPTIONS.REFILL' | translate}}</th>
          <th sortable="nextRefill">{{'PRESCRIPTIONS.NEXTREFILL' | translate}}</th>
          <th sortable="refillIntervalDays">{{'PRESCRIPTIONS.REFILLEVERY' | translate}}</th>
          <th sortable="date">{{'PRESCRIPTIONS.PRESCDATE' | translate}}</th>
          <th sortable="expirationDate">{{'PRESCRIPTIONS.EXP' | translate}}</th>
          <th sortable="action">{{'PRESCRIPTIONS.ACTION' | translate}}</th>
        </tr>
      </thead>
      <tbody>  
        <tr class="table-row text-center" *ngFor="let prescription of prescriptions | filter: term; let i = index;">
          <td>
            <ngb-highlight [result]="prescription.name"></ngb-highlight>
            <span>{{prescription.productName}}</span>
          </td>
          <td>
            <ngb-highlight class="ml-2" [result]="prescription.productQty"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="prescription?.refillRemaining"></ngb-highlight>
          </td>
          <td>
            <span>{{prescription?.nextSubscriptionDate}}</span>
          </td>
          <td>
            <span>{{prescription.refillIntervalDays}} {{'SUBSCRIPTIONS.DAYS' | translate}}</span>
          </td>
          <td>
            <span>{{prescription.date.toDate() | date:'mediumDate':'':user.preferedLang}}</span>
          </td>
          <td>
            <span>{{prescription?.expires | date:'mediumDate':'':user.preferedLang}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="table-mobile d-flex flex-column" style="width: fit-content;">
    <div class="prescription" [ngClass]="{'noBorder': prescription.id == prescriptions[prescriptions.length - 1].id}" *ngFor="let prescription of prescriptions; let i = index">
      <div class="refillProducts mb-2 phoneView" [ngClass]="{'ml-3': notInPopUp}" style="width: 100%; max-width: 100%;">
        <h4 style="text-align: center;" class="bold m-0 mb-2">{{prescription.productName}}</h4>
        <div class="d-flex flex-row">
          <img class="my-auto mx-0 mr-2" style="width: 70px; height: fit-content; height: intrinsic; background-color: transparent;" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fproducts%2FBotella_Pastillas_2.png?alt=media&token=8b9b34ea-e4da-41d6-860d-183c2f4edc2b">
          <div>
            <h6 style="text-align: start; margin-top: 0 !important;" class="m-1">{{prescription.productQty}} {{'CONSULTATIONS.TABLETSFOR' | translate}} {{prescription.refillIntervalDays}} {{'CONSULTATIONS.DAYS' | translate}}</h6>
            <h6 style="text-align: start;" class="m-1">{{'CONSULTATIONS.REFILLS' | translate}}: {{remainingRefills(prescription.refillRemaining)}}</h6>
            <h6 *ngIf="prescription?.nextSubscriptionDate" style="text-align: start;" class="m-1">{{'PRESCRIPTIONS.NEXTREFILL' | translate}}: {{prescription.nextSubscriptionDate.toDate() | date:'MMMM dd, yyyy':'':user.preferedLang | titlecase}}</h6>
            <h6 style="text-align: start;" class="m-1">{{'CONSULTATIONS.EXPIRES' | translate}} {{prescription.expires | date:'MMMM dd, yyyy':'':user.preferedLang | titlecase}}</h6>
            <h6 style="text-align: start;" class="m-1" *ngIf="currentUser.accountType == 'admin' && pharmacySubscriptions[i] && pharmacySubscriptions[i].sendNotifications">{{'PRESCRIPTIONS-LISTS.HASNOTIFICATIONS' | translate}}: {{'ACCOUNTS.YES' | translate}}</h6>
            <h6 style="text-align: start;" class="m-1" *ngIf="currentUser.accountType == 'admin' && pharmacySubscriptions[i] && !pharmacySubscriptions[i].sendNotifications">{{'PRESCRIPTIONS-LISTS.HASNOTIFICATIONS' | translate}}: {{'ACCOUNTS.NO' | translate}}</h6>
            <h6 style="text-align: start;" class="m-1" *ngIf="currentUser.accountType == 'admin' && pharmacySubscriptions && pharmacySubscriptions[i]">{{'PRESCRIPTIONS-LISTS.PRICELIST' | translate}}: {{pharmacySubscriptions.pricelist}}</h6>
            <h6 style="text-align: start;" class="m-1" *ngIf="currentUser.accountType == 'admin' && pharmacySubscriptions[i] && !pharmacySubscriptions[i].status">{{'PRESCRIPTIONS-LISTS.STATUS' | translate}}: {{pharmacySubscriptions[i].status}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!migrateProduct" class="grid-container">
    <div class="prescription d-flex flex-column justify-content-between" [ngClass]="{'noBorder': prescription.id == prescriptions[prescriptions.length - 1].id}" *ngFor="let prescription of prescriptions; let i = index">
      <div>
        <div class="d-flex w-100">
          <svg class="mr-2" width="20" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="m4.5 12.497 8-8a4.95 4.95 0 1 1 7 7l-8 8a4.95 4.95 0 1 1-7-7"></path>
            <path d="m8.5 8.5 7 7"></path>
          </svg>
  
          <h3 class="title mt-2">{{prescription.erpId}} - {{prescription.productName}}</h3>
        </div>
        <h2 class="title">Rx</h2>
        <h6 style="text-align: start; margin-top: 0 !important;" class="m-1">{{prescription.productQty}} {{'CONSULTATIONS.TABLETSFOR' | translate}} {{prescription.refillIntervalDays}} {{'CONSULTATIONS.DAYS' | translate}}</h6>
        <h6 style="text-align: start;" class="m-1">{{'CONSULTATIONS.REFILLS' | translate}}: {{remainingRefills(prescription.refillRemaining)}} {{'PRESCRIPTIONS-LISTS.OF' | translate}} {{prescription.refillQty}}</h6>
        <h6 style="text-align: start;" class="m-1" *ngIf="prescription.nextSubscriptionDate && remainingRefills(prescription.refillRemaining) != 0">
          {{'PRESCRIPTIONS.NEXTREFILL' | translate}}: {{prescription?.nextSubscriptionDate?.toDate() | date:'MMMM dd, yyyy':'':user.preferedLang | titlecase}}
        </h6>
        <h6 style="text-align: start;" class="m-1">
          <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 5h9a2 2 0 0 1 2 2v9"></path>
            <path d="M19.823 19.824A1.999 1.999 0 0 1 18 21H6a2 2 0 0 1-2-2V7a2 2 0 0 1 1.175-1.823"></path>
            <path d="M16 3v4"></path>
            <path d="M8 3v1"></path>
            <path d="M15 11h5-5Z"></path>
            <path d="M4 11h7-7Z"></path>
            <path d="M11 15h1"></path>
            <path d="M12 15v3"></path>
            <path d="m3 3 18 18"></path>
          </svg>
          {{'CONSULTATIONS.EXPIRES' | translate}} {{prescription.expires | date:'MMMM dd, yyyy':'':user.preferedLang | titlecase}}  
        </h6>
        <h6 style="text-align: start;" class="m-1" *ngIf="currentUser.accountType == 'admin' && pharmacySubscriptions[i] && pharmacySubscriptions[i].sendNotifications">{{'PRESCRIPTIONS-LISTS.HASNOTIFICATIONS' | translate}}: {{'ACCOUNTS.YES' | translate}}</h6>
        <h6 style="text-align: start;" class="m-1" *ngIf="currentUser.accountType == 'admin' && pharmacySubscriptions[i] && !pharmacySubscriptions[i].sendNotifications">{{'PRESCRIPTIONS-LISTS.HASNOTIFICATIONS' | translate}}: {{'ACCOUNTS.NO' | translate}}</h6>
        <h6 style="text-align: start;" class="m-1" *ngIf="currentUser.accountType == 'admin' && pharmacySubscriptions && pharmacySubscriptions[i]">{{'PRESCRIPTIONS-LISTS.PRICELIST' | translate}}: {{getPricelist(pharmacySubscriptions[i].pricelist)}}</h6>
        <h6 style="text-align: start;" class="m-1" *ngIf="currentUser.accountType == 'admin' && pharmacySubscriptions && pharmacySubscriptions[i]">{{'PRESCRIPTIONS-LISTS.STATUS' | translate}}: {{pharmacySubscriptions[i].status}}</h6>
        
        <div *ngIf="prescription.orderID != null">
          <br>
          <br>
          <h2 class="title">{{'PRESCRIPTIONS-LISTS.ORDER' | translate}}</h2>
          <h6 style="text-align: start;" class="m-1">
            <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 5H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2Z"></path>
              <path d="M16 3v4"></path>
              <path d="M8 3v4"></path>
              <path d="M4 11h16"></path>
              <path d="M10 15H8v2h2v-2Z"></path>
            </svg>
            <span *ngIf="prescriptionLatestOrders">{{'PRESCRIPTIONS-LISTS.LASTORDER' | translate}} {{ prescriptionLatestOrders[i]?.orderNo }} | 
              <span *ngIf="prescriptionLatestOrders[i]?.paymentStatus == 'paid'" class="ml-1">{{'ORDERS.STATUS.PAID' | translate | titlecase}}</span>
              <span *ngIf="prescriptionLatestOrders[i]?.paymentStatus == 'Refunded'" class="ml-1">{{'ORDERS.STATUS.REFUNDED' | translate | titlecase}}</span>
              <span *ngIf="prescriptionLatestOrders[i]?.paymentStatus == 'Chargeback'" class="ml-1">{{'ORDERS.STATUS.CHARGEBACK' | translate | titlecase}}</span>
              <span *ngIf="prescriptionLatestOrders[i]?.paymentStatus == 'pending'" class="ml-1">{{'ORDERS.STATUS.PENDING' | translate | titlecase}}</span>  
            </span>
            
          </h6>
          <h6 *ngIf="pharmacySubscriptions && remainingRefills(prescription.refillRemaining) != 0" style="text-align: start;" class="m-1">
            <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 5H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2Z"></path>
              <path d="M16 3v4"></path>
              <path d="M8 3v4"></path>
              <path d="M4 11h16"></path>
              <path d="M10 15H8v2h2v-2Z"></path>
            </svg>
            <span>{{'PRESCRIPTIONS-LISTS.NEXTREFILL' | translate}} {{ pharmacySubscriptions[i]?.nextSubscriptionDate.toDate() | date:'MMMM dd, yyyy':'':user.preferedLang | titlecase }}</span>
            
          </h6>
          <h6 style="text-align: start;" class="m-1">
            <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 19a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
              <path d="M17 19a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
              <path d="M13 6h5l3 5v6h-2"></path>
              <path d="M5 17H3V6a1 1 0 0 1 1-1h9v12"></path>
              <path d="M9 17h6"></path>
              <path d="M21 11h-8"></path>
            </svg>
             
            <span *ngIf="pharmacySubscriptions && pharmacySubscriptions[i]?.automaticPayment">{{'PRESCRIPTIONS-LISTS.DELIVERY' | translate}}  <i class="far fa-check-circle text-success"></i></span>
            <span *ngIf="pharmacySubscriptions && !pharmacySubscriptions[i]?.automaticPayment">{{'PRESCRIPTIONS-LISTS.DELIVERY' | translate}} <i class="far fa-times-circle text-danger"></i></span>
          </h6>
          <br> 
        </div>
  
        <span style="text-align: start;" class="m-1 mt-4 shipped" [ngClass]="{'shipping-pending': prescription.deliveryState == 'Pending', 'shipping-shipped': prescription.deliveryState == 'Shipped' }">
          <span *ngIf="prescription.deliveryState == 'Pending'">{{'PRESCRIPTIONS-LISTS.PENDING' | translate}}</span>
          <span *ngIf="prescription.deliveryState == 'Shipped'">{{'PRESCRIPTIONS-LISTS.SHIPPED' | translate}}</span>
          <span *ngIf="prescription.deliveryState == 'Shipped'">&nbsp;{{'PRESCRIPTIONS-LISTS.ON' | translate}} {{prescription.deliveryDate | date:'MMMM dd, yyyy hh:mm aa':'':user.preferedLang | titlecase}}</span>
        </span>
      </div>

      <div *ngIf="remainingRefills(prescription.refillRemaining) != 0" class="m-2 mt-4 justify-content-between">
        <button class="btn btn-upload" (click)="changeProduct(prescription)">
          {{'PRESCRIPTIONS-LISTS.UPGRADEPRESCRIPTION' | translate}}
        </button>
        <button *ngIf="pharmacySubscriptions && pharmacySubscriptions[i]" class="btn btn-upload" (click)="changeDeliveryFrequency(prescription, pharmacySubscriptions[i])">
          {{'PRESCRIPTIONS-LISTS.DELIVERYSCHEDULE' | translate}}
        </button>
        <button *ngIf="currentUser.accountType == 'admin' && pharmacySubscriptions && pharmacySubscriptions[i]" class="btn btn-upload" (click)="changeNotificationStatus(pharmacySubscriptions[i], i)">
          {{'PRESCRIPTIONS-LISTS.CHANGENOTIFICATIONS' | translate}}
        </button>
        <button *ngIf="currentUser.accountType == 'admin' && pharmacySubscriptions && pharmacySubscriptions[i]" class="btn btn-upload" (click)="changeSubscriptionStatus(pharmacySubscriptions[i], i)">
          {{'PRESCRIPTIONS-LISTS.CHANGESTATUS' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="migrateProduct" class="">
    <app-change-product [user]="user" [condition]="condition" [prescription]="productToChange" (dismiss)="migrateProduct = false;"></app-change-product>
  </div>
</div>

<app-reload *ngIf="loading" [message]="'RELOAD.LOADING'"></app-reload>

<ng-template #orderDetailsRef let-modal>
  <app-order-details [orderId]="prescription_line.orderID" [id]="order?.id" (dismissModal)="dismissListener()"></app-order-details>
</ng-template>

<ng-template #deliveryScheduleModal let-modal>
  <div class="shipment-frequency" style="padding-top: 15px; padding-right: 15px; padding-left: 15px;">
    <h5>{{'PRESCRIPTIONS-LISTS.SCHEDULEREFILLDATE' | translate}}</h5>
    <input [(ngModel)]="formatNextSubscriptionDate" id="datepicker" type="date" class="form-control w-50 mx-auto">
    <br>
    <div *ngIf="productToChange">
      <h5>{{"SHIPMENT-FREQUENCIES.HOWOFTEN" | translate}}</h5>
      <div class="d-flex flex-column align-items-center mt-1" *ngFor="let freq of deliveryFrequencies[productToChange.productID]">
        <label for="{{freq.id}}" class="radio-card" >
          <input type="radio" name="radio-card" id="{{freq.id}}" value="{{freq.id}}" [checked]="setDeliveryFrequency(freq)" (click)="setShipmentFrequency(freq)"/>
          <div class="card-content-wrapper d-flex flex-column">
            <div class="d-flex align-items-center">
              <span class="check-icon"></span>
              <span *ngIf="productToChange && freq.autoPayment" class="font-size-18 w-100 gender ml-2 d-flex justify-content-between align-items-center">{{ freq.description | translation:user.preferedLang }} <img class="mx-2" style="width: 25px;" src="../../../../../../assets/images/frequencies/Calendar gray.png"></span>
              <span *ngIf="productToChange && !freq.autoPayment" class="font-size-18 w-100 gender ml-2 d-flex justify-content-between align-items-center">{{ freq.description | translation:user.preferedLang }} <img class="mx-2" style="width: 25px;" src="../../../../../../assets/images/frequencies/Manual Order Icon (Copy).png"></span>
            </div>
            <div *ngIf="setDeliveryFrequency(freq)">
              <p style="max-width: 300px; margin: 0; font-size: 12px;">{{freq.memo | translation:user.preferedLang}}</p>
            </div>
          </div>
        </label>
      </div>

      <hr *ngIf="currentUser.accountType == 'admin'">
      <h5 *ngIf="currentUser.accountType == 'admin'">Custom by Admin</h5>
      <div *ngIf="currentUser.accountType == 'admin'" class="d-flex flex-column align-items-center mt-1">
        <label class="radio-card" >
          <input type="radio" name="radio-card"/>
          <div class="card-content-wrapper d-flex flex-column">
            <div>
              <input type="text" class="form-control mb-2" placeholder="{{'SHIPMENT-FREQUENCIES.CUSTOM' | translate}}" [(ngModel)]="productToChange.deliveryFrequencyInDays">
              <div>
                <ng-select [(ngModel)]="productToChange.automaticPayment" [clearable]="false">
                  <ng-option [value]="true">Automatic</ng-option>
                  <ng-option [value]="false">Manual</ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-between" style="padding-right: 15px; padding-top: 15px; padding-bottom: 15px;">
    <button class="btn btn-info" (click)="saveSchedule()">{{'ACCOUNTS.EMAIL&PASSWORD.SAVE' | translate}}</button>
    <button class="btn btn-light" (click)="dismissScheduleChanges()">{{'ACCOUNTS.EMAIL&PASSWORD.CANCEL' | translate}}</button>
  </div>
</ng-template>
<div *ngIf="base64 == undefined">
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="text-center">
      <!-- <img src="assets/images/no-data.png" alt="No Data" class="img-fluid" style="max-width: 100px;"> -->
      <h5 class="mt-3">No Prescription Found</h5>
    </div>
  </div>
</div>

<div *ngIf="base64 != undefined" class="pdf-container media-body overflow-hidden">
  <ngx-extended-pdf-viewer *ngIf="base64 != undefined" [textLayer]="false" [base64Src]="base64" backgroundColor="#e8e8ef"
    [showZoomButtons]="true" [showPrintButton]="true" [showDownloadButton]="true" [useBrowserLocale]="true"
    [sidebarVisible]="false"  [showSecondaryToolbarButton]="false" [showSidebarButton]="false" [zoom]="'50%'">
  </ngx-extended-pdf-viewer>
</div>
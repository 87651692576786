
<div class="row card-container justify-content-center" [ngClass]="{'disableDiv': currentOrder && currentOrder.paymentStatus == 'paid'}" *ngFor="let address of shippingAddresses; let i = index">
  <div class="grid-wrapper grid-col-3">
    <div class="selection-wrapper" (click)="saveAddress(address);">
      <label for="{{address.id}}" class="selected-label">
        <input *ngIf="component != 'walkthrough'" id="{{address.id}}" type="radio" [checked]="checkAddress(address)">
        <span *ngIf="component != 'walkthrough'" class="icon"></span>
        <div class="address-card">
          <div class="row">
            <div class="col d-flex p-0" style="max-width: fit-content;">
              <img src="../../../assets/images/shippingIcon.svg" alt="">
            </div>
            <div class="col">
              <div class="row align-items-end address-type-edit">
                <h4 class="m-0" *ngIf="address.AddressType == 'Shipping'">{{'ADDRESS.SHIPPING' | translate}}</h4>                 
                <h4 class="m-0" *ngIf="address.AddressType == 'Billing'">{{'ADDRESS.BILLING' | translate}}</h4>                 
                <div class="d-flex align-items-center">
                  <i class="far fa-edit text-success mr-1" (click)="addAddress(addAddressModal,true, address)"></i>
                  <!-- <i *ngIf="component == 'walkthrough'" class="mdi mdi-delete-outline font-size-20 text-danger" (click)="deleteAddressConfirmation(address)"></i> -->
                </div>
              </div>
              <div class="row mt-2">
                <h4>{{address.AddressLine1}} {{address?.AddressLine2}}</h4>
                <h5>{{address.AddressCity}}, {{address.AddressState}} {{address.AddressZipcode}}</h5>
              </div>
              <div class="d-flex flex-row" *ngIf="component == 'walkthrough'" style="width: fit-content;">
                <div class="d-flex justify-content-between">
                  <label for="switch-address-{{i}}" class="switch-address">
                    <input id="switch-address-{{i}}" type="checkbox" [checked]="address.AddressDefault" [(ngModel)]="address.AddressDefault" (change)="warnDefaultAddress(address, $event)">
                    <span class="slider round"></span>
                  </label>
                </div>
                <span class="default ml-1" *ngIf="!address?.AddressDefault">{{'PAYMENTS.SETDEFAULT' | translate}}</span>
                <span class="default ml-1" *ngIf="address?.AddressDefault">{{'PROFILE.DEFAULT' | translate}}</span>
              </div>
            </div>
          </div>
        </div>
      </label>
    </div>
  </div>
</div>

<ng-template #addAddressModal let-modal>
  <!-- <div class="justify-content-end">
    <button type="button" class="close float-right modal-close" (click)="close()" aria-hidden="true">×</button>
  </div> -->
  <app-add-address [address]="currentAddress" [showBtns]="true" [accountToEdit]="user.uid" [consultID]="currentConsult?.ID" [bool]="edit" [dismiss]="this.modalRef" (dismiss)="close()"></app-add-address>
</ng-template>
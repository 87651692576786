<div class="">
  <div class="heading d-flex mb-2">
      <button type="button" class="btn btn-primary toolbarBtn" (click)="close.emit(true)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button>
    <!-- <i class="fas fa-chevron-left" (click)="close.emit(true)"></i> -->
  </div>
  <div>
<div class="d-flex flex-row">
  <div class="mr-2 d-flex justify-content-center align-items-center">
    <img style="width: 60px; min-width: 60px; height: auto; height: intrinsic;" src="{{consult.Photo1}}">
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h3 class="m-0 bold" style="text-align: start; align-self: start;">{{'CONSULTATIONS.CONSULTATION' | translate}} {{consult.conditionName | translation:consult.consultLanguage}}</h3>
    <h4 class="m-0" style="text-align: start; align-self: start;">{{'CONSULTATIONS.DATE' | translate}} {{consult.Date.toDate() | date:'dd MMMM, yyyy hh:mm aa':'':consult.consultLanguage}}</h4>
  </div>
</div>
  </div>
  <ul ngbNav #justifiednav="ngbNav" [activeId]="customNav" class="my-4 nav-pills nav-justified">
    <li [ngbNavItem]="1">
      <a ngbNavLink>
        <!-- <span class="d-block d-sm-none"><i class="fas fa-question"></i></span> -->
        <span>{{'CONSULTATIONS.ASSESSMENT' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="assesmentRef"></ng-template>
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink>
        <span>{{'CONSULTATIONS.LABS' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="labsRef"></ng-template>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>
        <span>{{'REFERRALS.REFERRALS' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="referral"></ng-template>
      </ng-template>
    </li>
    <!-- <li [ngbNavItem]="3">
      <a ngbNavLink>
        <span class="d-block d-sm-none"><i class="ri-list-ordered"></i></span>
        <span class="d-none d-sm-block ulnav-span-font-color">{{'ORDERS.ORDERS' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="ordersRef"></ng-template>
      </ng-template>
    </li> -->
    <li [ngbNavItem]="2">
      <a ngbNavLink>
        <!-- <span class="d-block d-sm-none"><i class="fas fa-file"></i></span> -->
        <span>{{'CONSULTATIONS.SUMMARY' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="summaryRef"></ng-template>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="justifiednav"></div>
</div>

<ng-template #assesmentRef let-modal>
  <canvas id="pdf2img" hidden></canvas>
  <div class="mt-3 questions" *ngIf="user">
    <h3>{{'CONSULTQUESTIONS.QUESTIONS' | translate}}</h3>
    
    <div *ngFor="let question of questions; let i = index" class="questions-list mb-2">
      <div class="d-flex">
        <!-- <i class="fas fa-question-circle" aria-hidden="true"></i> -->
        <span class="question">{{i + 1}}.</span>
        <span class="question">{{question.question | translation: user.preferedLang}}</span>
      </div>
        <!-- <p class="answer">Answered: <span class="text-info">{{question.answer | translation: user.preferedLang}}</span></p>
        <span *ngIf="question.optionAnswer" class="answer text-info">{{question.optionAnswer | translation: user.preferedLang}}</span> -->
      <div *ngIf="question.options || question.type == 'BMI' || question.type == 'Text'" class="answer-options">
        <span *ngIf="question.options">{{'CONSULTQUESTIONS.OPTIONS' | translate}}</span>
        <div *ngIf="(question.type == 'SearchMedicines' || question.type == 'SearchConditions' || question.type == 'SearchSurgeries') && question.optionAnswer == ''">
          <div *ngFor="let answer of question.answer">
            <span class="ml-2" [ngClass]="{'text-primary': true}">- {{answer | translation:user.preferedLang}}</span>          
          </div>
        </div>
        <div *ngFor="let option of question.options" class="ml-2">
          <span *ngIf="question.type != 'BP' && question.displayType != 'textImage' && question.type != 'SelectionWithOptions' && question.type != 'MultipleSelectionWithOptions'" [ngClass]="{'text-primary': question.answer.includes(option) || question.answer == option || question.optionAnswer == option || question.answer[0] == option || question.answer == option.options}">- {{option | translation:user.preferedLang}}</span>
          <span *ngIf="question.type == 'SelectionWithOptions' || question.type == 'MultipleSelectionWithOptions'" [ngClass]="{'text-primary': question.answer.includes(option) || question.answer == option || question.optionAnswer == option || question.answer[0] == option || question.answer == option.options}">- {{option.options | translation:user.preferedLang}}</span>
          <span *ngIf="question.displayType == 'textImage'" [ngClass]="{'text-primary': question.answer.text == option.text}">- {{option.text | translation:user.preferedLang}}</span>
          <img *ngIf="question.displayType == 'textImage'" style="width: 100px;" src="{{option.image}}">
          <div *ngIf="typeOfOption(option)">
            <div *ngFor="let subOption of option?.subOptions">
              <span class="ml-3" [ngClass]="{'text-primary': question.answer.includes(subOption) || question.answer == subOption || (question.optionAnswer == subOption && question.answer == option.options) || question.answer[0] == subOption || question.answer == option.subOption}">- {{subOption | translation:user.preferedLang}}</span>          
            </div>

            <span *ngIf="option.systolic">{{'CONSULTATIONS.SYSTOLIC' | translate}}:</span>
            <div *ngFor="let systolic of option?.systolic">
              <span class="ml-3" [ngClass]="{'text-primary': question.answer[0] == systolic }">- {{systolic | translation:user.preferedLang}}</span>          
            </div>
            <span *ngIf="option.diastolic">{{'CONSULTATIONS.DIASTOLIC' | translate}}:</span>
            <div *ngFor="let diastolic of option?.diastolic">
              <span class="ml-3" [ngClass]="{'text-primary': question.answer[1] == diastolic }">- {{diastolic | translation:user.preferedLang}}</span>          
            </div>
          </div>
        </div>
        <div *ngIf="question.type == 'BMI'">
          <span>{{'CONSULTATIONS.HEIGHT' | translate}}:<br>- {{question.answer[0]}} {{'CONSULTATIONS.FEET' | translate}} {{question.answer[1]}} {{'CONSULTATIONS.INCHES' | translate}}</span><br>
          <span>{{'CONSULTATIONS.WEIGHT' | translate}}:<br>- {{question.answer[2]}} {{'CONSULTATIONS.POUNDS' | translate}}</span>
        </div>
        <span *ngIf="question.explain" class="d-flex justify-content-start" >{{question?.subquestion | translation:user.preferedLang}}</span>
        <span *ngIf="question.explain">{{'CONSULTQUESTIONS.EXPLANATION' | translate}}: {{question.explain}}</span>
        <span *ngIf="!question.explain && question.required == false">N/A</span>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #summaryRef let-modal>
  <div class="">
    <div *ngIf="!consult.pdf" class="mt-3">
      <h3>{{'CONSULTATIONS.UNFINISHEDCONSULT' | translate}}</h3>
    </div>
    <div class="pdf-container media-body overflow-hidden d-flex flex-column">
      <div *ngIf="consult.pdf != undefined" class="pdf-container media-body overflow-hidden">
        <ngx-extended-pdf-viewer *ngIf="consult.pdf != undefined" [src]="this.consult.pdf" backgroundColor="#e8e8ef"
        [showZoomButtons]="true" [showPrintButton]="true" [showDownloadButton]="true" [useBrowserLocale]="true"
        [sidebarVisible]="false"  [showSecondaryToolbarButton]="false" [showSidebarButton]="false" [zoom]="'75%'">
      </ngx-extended-pdf-viewer>
      </div>
    </div>
    <!-- <object *ngIf="consult.pdf && pdf" [data]="pdf"  width="100%" height="600px"></object> -->
  </div>
</ng-template>

<ng-template #ordersRef let-modal>
  <app-orders [consultOrdersDetails]="true" [consult]="consult" [specificConsultRef]=""></app-orders>
</ng-template>

<ng-template #labsRef>
  <div *ngIf="allPrescriptions.length == 0" class="mt-3">
    <h3>{{'CONSULTATIONS.NOLABS' | translate}}</h3>
  </div>
  <div class="d-flex flex-wrap mt-2">
    <label for="{{prescription.ID}}" class="radio-card" style="margin-right: 30px; margin-bottom: 30px;" *ngFor="let prescription of allPrescriptions; let i = index">
      <input hidden type="radio" name="radio-card" id="{{prescription.ID}}"/>
      <div class="card-content-wrapper">          
        <div class="card-content">
          <div class="d-flex justify-content-center align-items-center" style="font-size: 80px;">
          <!-- <i class="ri-file-list-line"></i> -->
          <img style="width: 70px; height: auto; height: intrinsic;" src="{{labLogo}}">
          </div>
          <!-- <h5 class="m-0" style="text-align: center;">{{'CONSULT-PRESCRIPTIONS.CREATED' | translate}}: {{prescription.date.toDate() | date:'MMMM dd, yyyy':'':user.preferedLang}}</h5> -->
          <h5 *ngIf="prescription.signedDate" style="text-align: center;">{{'CONSULT-PRESCRIPTIONS.SIGNED' | translate}}: {{prescription.signedDate.toDate() | date:'MMMM dd, yyyy':'':user.preferedLang}}</h5>
          <!-- <h4 class="prescription-type">{{prescription.ID}}</h4> -->
          <!-- <h4 class="prescription-typrescripiton">{{'PRESCRIPTIONS.PRESCRIPTION' | translate}}</h4> -->
          <div class="sections d-flex mx-auto justify-content-center mb-2">
            <!-- <button *ngIf="prescription.pharmacy != 'External Pharmacy'" class="send-prescription" (click)="openPharmacyDetails(prescription)">{{'PRESCRIPTIONS.SEND' | translate}}</button>
            <button *ngIf="prescription.pharmacy == 'External Pharmacy'" class="send-prescription" disabled>{{'PRESCRIPTIONS.SENT' | translate}}</button> -->
            <button *ngIf="prescription.z_base64" class="btn btn-cyan" style="padding-top: 0 !important; padding-bottom: 0 !important;" (click)="prescriptionID = prescription.ID; signPad(internalPrescription); base64 = prescription.z_base64" [ngClass]="{'badge-soft-success': true}">{{'CONSULTATIONS.VIEWLAB' | translate}}</button>
          </div>
          <p class="m-0">{{'CONSULTATIONS.UPLOADLABS' | translate}}</p>
          <input type="file" id="fileInput" hidden (change)="prescriptionID = prescription.ID; pdfSelected($event)"> 
          <label for="fileInput" class="custom-file-button">{{'CONSULTATIONS.UPLOADPDFFILE' | translate}}</label>
          <div *ngIf="labPdfs[i] && labPdfs[i].length > 0" class="mt-2">
            <p class="m-0">{{'CONSULTATIONS.UPLOADEDDOCS' | translate}}</p>
            <div *ngFor="let pdf of labPdfs[i]">
              <button style="background: transparent;border: 0;" (click)="prescriptionID = prescription.ID; currentPdf = pdf.data().pdf; showPDF(showPdf)">{{pdf.data().pdfName}}</button>
            </div> 
          </div>
        </div>
      </div>
    </label>
  </div>
</ng-template>

<ng-template #referral>
  <div>
    <div *ngIf="referrals.length == 0" class="mt-3">
      <h3>{{'REFERRALS.NOREFERRALS' | translate}}</h3>
    </div>
  </div>
  <div>
    <div class="d-flex flex-wrap mt-2">
      <label for="{{referral.ID}}" class="radio-card" style="margin-right: 30px; margin-bottom: 30px;" *ngFor="let referral of referrals; let i = index">
        <input hidden type="radio" name="radio-card" id="{{referral.ID}}"/>
        <div class="card-content-wrapper">          
          <div class="card-content">
            <div class="d-flex justify-content-center align-items-center" style="font-size: 80px;">
            <i class="ri-file-list-line"></i>
            </div>
            <h4 class="prescription-type" *ngIf="referral.referralCondition != 'Referral to: Specialist'">{{referral.referralCondition}}</h4>
            <h4 class="prescription-type" *ngIf="referral.referralCondition == 'Referral to: Specialist'">{{'REFERRALS.REFERRALTO' | translate}}</h4>
            <div class="sections d-flex mx-auto justify-content-center">
              <span *ngIf="referral.z_processedB64" class="prescription-status" (click)="signPad(internalPrescription); base64 = referral.z_processedB64" [ngClass]="{'badge-soft-success': true}">{{'REFERRALS.VIEWREFERRAL' | translate}}</span>
              <span *ngIf="referral.z_processedB64 == undefined" class="prescription-status" (click)="signPad(internalPrescription); base64 = referral.z_base64" [ngClass]="{'badge-soft-success': true}">{{'REFERRALS.VIEWREFERRAL' | translate}}</span>
            </div>
          </div>
        </div>
      </label>
    </div>
  </div>
</ng-template>

<ng-template #internalPrescription let-modal>
  <div class="row mt-3 product-wizard">
    <div class="col-lg-12">
      <div class="row justify-content-between pl-5 pr-5">
        <h3>{{'REFERRALS.REFERRAL' | translate}}</h3>
        <i class="ri-close-fill" style="font-size: 23px;" (click)="modal.dismiss('Cross click')"></i>
      </div>
      <ng-template [ngTemplateOutlet]="pdfViewer"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #pdfViewer let-modal>
  <div *ngIf="base64 != undefined" class="pdf-container media-body overflow-hidden" style="min-height: 600px;">
    <ngx-extended-pdf-viewer *ngIf="base64 != undefined" [base64Src]="base64" backgroundColor="#e8e8ef"
      [showZoomButtons]="true"  [useBrowserLocale]="true" [height]="'600px'"
      [sidebarVisible]="false" [showHandToolButton]="true" [zoom]="'50%'">
    </ngx-extended-pdf-viewer>
  </div>
</ng-template> 

<ng-template #showPdf let-modal>
  <div>
    <ngx-extended-pdf-viewer *ngIf="currentPdf != undefined" [base64Src]="currentPdf" backgroundColor="#e8e8ef"
      [showZoomButtons]="true" [height]="'75vh'" [width]="'55vw'" [useBrowserLocale]="true"
      [sidebarVisible]="false" [showHandToolButton]="true" [zoom]="'50%'" style="width: 55vw;">
    </ngx-extended-pdf-viewer>
  </div>
  
  <button *ngIf="currentPdf != undefined" style="margin-left: auto; margin-right: auto;" class="btn btn-info btn-sm cardBtn mt-2 mb-4" (click)="currentPdf = undefined; modalService.dismissAll()">{{'ACCOUNTS.SWAL.CLOSE' | translate}}</button>
</ng-template>
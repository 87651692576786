export interface UsersI {
    email: string;
    firstName: string;
    middleName: string;
    lastName1: string;
    lastName2: string;
    biologicalgender: string;
    birthdate: string;
    accountStatus: string;
    uid: string;
    isAccountDisabled: boolean;
    image: string;
    meetingID: number;
    meetingRol: number;
    identification: string;
    // identificationBack: string;
    phoneNumber:string;
    alternateNumber: string;
    workExtension:string;
    source: string,
    preferedLang: string,
    timezone: Array<string>
}

export class UserProfiles {
    constructor(
        public email?: string,
        public firstName?: string,
        public middleName?: string,
        public lastName1?: string,
        public lastName2?: string,
        public biologicalgender?: string,
        public birthdate?: string,
        public accountStatus?: string,
        public uid?: string,
        public isAccountDisabled?: boolean,
        public image?: string,
        public meetingID?: number,
        public meetingRole?: number,
        public identification?: string,
        // public identificationBack?:string,
        public phoneNumber?:string,
        public alternateNumber?:string,
        public workExtension?:string,
        public source?: string,
        public preferedLang?: string,
        public timezone?: Array<string>

    ) {  }
}
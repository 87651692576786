import { Component, Input, OnInit, ViewChild, Inject,LOCALE_ID, OnDestroy, TemplateRef, AfterViewInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {User} from '@firebase/auth-types';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe, formatDate } from '@angular/common';
import { TelehealthService } from '../../telehealth/telehealth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { TranslationPipe } from 'src/app/translation.pipe';
import { fbRemoteConfig } from '../../../authUtils'
import { EmailsService } from 'src/app/core/services/emails.service';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss']
})

export class AddEventComponent implements OnInit, OnDestroy{
  @ViewChild('gapiModal', { read: TemplateRef }) gapiModal:TemplateRef<any>;
  @Input() modalRef
  user: User;
  currentUser:any;
  patientPreferedLang
  patientData
  userTimezone
  practitionerProfile
  abbr
  abscentDates
  today

  //for swal modal
  title
  cancel
  delete
  deletedmsg

  settings = fbRemoteConfig.defaultConfig;
  currentURL
  allSubscriptions = new Subscription()

  eventform:UntypedFormGroup;
  meetings: any = [];
  bool = AddEventComponent.bool;
  token

  loading = false
  reloadMsg = ''
  translationPipe = new TranslationPipe()
  
  static bool: Boolean = false;
  static date: any;
  static scheduleDetails: any;

  static sendDate(startStr: any, bool: Boolean, data?:any ) {
    this.bool = bool;
    this.date = startStr;
    this.scheduleDetails = data;
  }

  @Input() dismiss: any;
  @Input() consult
  @ViewChild('eventForm') myTemplate; 

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public firebaseService: FirebaseService, 
    public formBuilder: UntypedFormBuilder,  
    private modalService: NgbModal,
    public translate: TranslateService,
    private telehealthService: TelehealthService,
    private authService: AuthenticationService,
    public route: Router,
    private emailService: EmailsService,
    @Inject(LOCALE_ID) private locale: string) {
      
      this.currentURL = this.route.url

      // gapi.load('client:auth2', () => {
      //   gapi.auth2.authorize({
      //     scope: 'https://www.googleapis.com/auth/calendar',
      //     client_id: environment.firebaseConfig.clientId,
      //     prompt: 'none',
      //     access_type: 'offline',
      //     include_granted_scopes: true
      //   }, res => {
      //     this.token = res
      //   })
      // })
    }

  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe()
  }

  ngOnInit(): void {
    this.getUser();

    this.today = formatDate(new Date(), 'yyyy-MM-dd', this.locale);

    if(this.bool == false){
    this.eventform = this.formBuilder.group({
      Start: AddEventComponent.date.startStr + "T00:00",
      End: AddEventComponent.date.endStr + "T00:00"
    });
    }else {
      this.eventform = this.formBuilder.group({
        Start: AddEventComponent.date.event.startStr.split(":00-",1),
        End: AddEventComponent.date.event.endStr.split(":00-",1)
      });
      this.minutes = (new Date(this.eventform.controls.End.value).getTime() - new Date(this.eventform.controls.Start.value).getTime())/60000
    }

    if(AddEventComponent?.date?.event?.extendedProps?.consultID){
      this.afs.collection('consultation').doc(AddEventComponent.date.event.extendedProps.consultID).valueChanges().subscribe(data => {
        this.consult = data
      })
    }
  }

  get form() {
    return this.eventform.controls;
  }

  getUser() {
    let sub = this.afAuth.user.subscribe(async user => {
      this.user = await user;
      this.currentUser = await user.uid;

      let practitionerUid = AddEventComponent.scheduleDetails.uidPractitioner ? AddEventComponent.scheduleDetails.uidPractitioner : AddEventComponent.date.event.extendedProps.uidPractitioner
      let sub = this.afs.collection('users').doc(practitionerUid).valueChanges().subscribe((res: any) => {
        this.userTimezone = 'UTC' + res.timezone.timeValue
        this.practitionerProfile = res
        this.abscentDates = res?.abscentDates ? res?.abscentDates?.filter((date: any) => date.end >= this.today) : []

        if(AddEventComponent.scheduleDetails != undefined){
          let sub = this.afs.collection('users').doc(AddEventComponent.scheduleDetails.uidPatient).valueChanges().subscribe(data=>{
            let Lang: any = data
            this.patientPreferedLang = Lang.preferedLang   
            this.patientData = Lang
    
            if(this.practitionerProfile.accountType == 'practitioner'){
              let translationPipe = new TranslationPipe()
              let abbr = translationPipe.transform(this.practitionerProfile.abbr, Lang.preferedLang);
              if(Lang.preferedLang == 'en'){
                this.abbr = abbr;
              }
              else {
                if(this.practitionerProfile.biologicalgender == 'Female'){
                  this.abbr = abbr.split('/')[1]
                }
                else {
                  this.abbr = abbr.split('/')[0]
                }
              }
            }
            if(this.form.Start.value != undefined + 'T00:00'){
              this.calcEndTime(this.form.Start.value)
            }
          });
          this.allSubscriptions.add(sub)
        }      
      })
      this.allSubscriptions.add(sub)
    });
    this.allSubscriptions.add(sub)
  }


createEventTest(){
    var Start = this.eventform.controls.Start.value;
    var Title;
    var description;
    if(this.patientPreferedLang == 'en'){
      // Title = 'Dzeus Health - ' +  this.translationPipe.transform(AddEventComponent.scheduleDetails.conditionName, this.patientPreferedLang) + ' Consultation'
      Title = 'Medical Consultation'
      description = `Telehealth consultation with ${this.abbr} ` + this.practitionerProfile.firstName + ' ' + this.practitionerProfile.lastName1
    }
    else {
      // Title = 'Dzeus Health - Consulta de ' + this.translationPipe.transform(AddEventComponent.scheduleDetails.conditionName, this.patientPreferedLang)
      Title = 'Consulta Médica'
      description = `Consulta de Telemedicina con ${this.abbr} ` + this.practitionerProfile.firstName + ' ' + this.practitionerProfile.lastName1 
    }
    if(typeof(Start) != 'string'){
      Start = Start[0]
    }
    const End = this.eventform.controls.End.value;

    let datePipe = formatDate(Start,'MMMM d, y',this.patientData.preferedLang);
    let timePipe = formatDate(Start,'hh:mm a',this.patientData.preferedLang);
    let msgDatePipe = formatDate(Start,'MMM d, y',this.patientData.preferedLang);

    if(this.patientData.timezone.timeValue != this.userTimezone.split('UTC')[1]){
      let changeStart = new DatePipe('en-Us').transform(Start + ':00.000' + this.userTimezone.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.patientData.timezone.timeValue);
      datePipe = formatDate(changeStart,'MMMM d, y',this.patientData.preferedLang);
      timePipe = formatDate(changeStart,'hh:mm a',this.patientData.preferedLang);
      msgDatePipe = formatDate(changeStart,'MMM d, y',this.patientData.preferedLang);
    }
    
    let msg = {es: `Dzeus Health - Se ha programado una teleconsulta para ${msgDatePipe + " a las " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}. Entre a https://app.dzeus.com para más detalles.`,
    en: `Dzeus Health - A meeting is scheduled for  ${msgDatePipe + " at " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}. Go to https://app.dzeus.com for more details.`}

    let data = {
      id: this.firebaseService.getDate(),
      title: Title,
      start: Start,
      end: End,
      uidPractitioner: AddEventComponent.scheduleDetails.uidPractitioner,
      practitionerEmail: this.practitionerProfile.email,
      uidPatient: AddEventComponent.scheduleDetails.uidPatient,
      patientName: AddEventComponent.scheduleDetails.patientName,
      patientEmail: AddEventComponent.scheduleDetails.patientEmail,
      consultID: AddEventComponent.scheduleDetails.consultID,
      conditionName: AddEventComponent.scheduleDetails.conditionName,
      dateCreated: new Date(Date.now()),
      room:"",
      description: description,
      meetRef: '',
      eventTimezoneValue: this.userTimezone,
      duration: this.minutes
    }

    let meetData = {
      randomId: this.firebaseService.getDate(),
      eventName: Title,
      startTime: Start,
      endTime: End,
      patientEmail: AddEventComponent.scheduleDetails.patientEmail,
      practitionerEmail: this.practitionerProfile.email,
      description: description,
      timezone: this.userTimezone,
      // token: this.token.access_token
    }
    
      this.loading = true
      this.reloadMsg = 'RELOAD.CREATING'
      this.telehealthService.createMeet(meetData).then(async (res: any)=> {
        data.room = res.conferenceData.entryPoints[0].uri
        data.meetRef = res.id
        this.firebaseService.scheduleEvent(data);
        this.loading = false;
  
        this.close();

        let date = new Date(Date.now());
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();
        let id:any = yyyy + mm + dd + uuidv4();

        let customSms: any = {
          shortUrl: {code: ''},
          smsShortUrl: '',
        }
    
        await this.emailService.shortenUrl('https://app.dzeus.com/inboxandsupport/read/' + id, this.settings.shortenedUrl).then(code => {     
          customSms.shortUrl.code = code
          customSms.smsShortUrl = code
          customSms.link = '/inboxandsupport/read/' + id
        
          this.emailService.internalCustomMessages(id,this.patientData, '<en>New event</en><es>Nuevo evento</es>', `<en>Hello ${AddEventComponent.scheduleDetails.patientName},<br><br> Your Healthcare Provider has schedule a new meeting: "${Title}" for ${datePipe + " at " + timePipe + ' - UTC' + this.patientData.timezone.timeValue}.  
          If you need to update the date, please contact your Healthcare Provider.<br><br>Thanks,<br>your ${this.settings.appName} team</en>
          <es>Saludos ${AddEventComponent.scheduleDetails.patientName},<br><br> Su Proveedor de Salud ha programado una nueva teleconsulta: "${Title}" para ${datePipe + " a las " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}.  
          Si necesita actualizar el día, favor de contactar a su Proveedor de Salud.<br><br>Gracias,<br>su equipo de ${this.settings.appName}</es>`, 
          `<en>Hello ${AddEventComponent.scheduleDetails.patientName},Your Healthcare Provider has schedule a new meeting: "${Title}" for ${datePipe + " at " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}.   
                If you need to update the date, please contact your Healthcare Provider.Thanks,your ${this.settings.appName} team</en><es>Saludos ${AddEventComponent.scheduleDetails.patientName}, Su Proveedor de Salud ha programado una nueva teleconsulta: "${Title}" para ${datePipe + " a las " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}.    
                Si necesita actualizar el día, favor de contactar a su Proveedor de Salud.Gracias, su equipo de ${this.settings.appName}</es>`, this.settings, AddEventComponent.scheduleDetails.consultID, true, this.practitionerProfile, '', customSms, id)
        })
      })
  }

  updateEvent() {
    var Start = this.eventform.controls.Start.value;
    const Title = AddEventComponent.date.event.title;
    const End = this.eventform.controls.End.value;
    if(typeof(Start) != 'string'){
      Start = Start[0]
    }

    //2021-08-13T12:00
    let datePipe = formatDate(Start,'MMMM d, y',this.patientData.preferedLang);
    let timePipe = formatDate(Start,'hh:mm a',this.patientData.preferedLang);
    
    if(this.patientData.timezone.timeValue != this.userTimezone.split('UTC')[1]){
      let changeStart = new DatePipe('en-Us').transform(Start + ':00.000' + this.userTimezone.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.patientData.timezone.timeValue);
      datePipe = formatDate(changeStart,'MMMM d, y',this.patientData.preferedLang);
      timePipe = formatDate(changeStart,'hh:mm a',this.patientData.preferedLang);
    }

    let data = {
      id: AddEventComponent.date.event.id,
      title: Title,
      start: Start,
      end: End,
      uidPractitioner: AddEventComponent.date.event.extendedProps.uidPractitioner,
      uidPatient: AddEventComponent.date.event.extendedProps.uidPatient,
      patientName: AddEventComponent.date.event.extendedProps.patientName,
      patientEmail: AddEventComponent.date.event.extendedProps.patientEmail,
      practitionerEmail: AddEventComponent.date.event.extendedProps.practitionerEmail,
      description: AddEventComponent.date.event.extendedProps.description,
      consultID: AddEventComponent.date.event.extendedProps.consultID,
      // conditionName: AddEventComponent.date.event.extendedProps.conditionName,
      dateCreated: AddEventComponent.date.event.extendedProps.dateCreated,
      room: AddEventComponent.date.event.extendedProps.room,
      meetRef: AddEventComponent.date.event.extendedProps.meetRef,
      eventTimezoneValue: this.userTimezone,
      requestUpdate: false,
      duration: this.minutes
    }

    let meetData = {
      startTime: Start,
      endTime: End,
      meetRef: AddEventComponent.date.event.extendedProps.meetRef,
      timezone: this.userTimezone,
      eventName: Title,
      description: AddEventComponent.date.event.extendedProps.description,
      practitionerEmail: AddEventComponent.date.event.extendedProps.practitionerEmail,
      // token: this.token.access_token,
      patientEmail: AddEventComponent.date.event.extendedProps.patientEmail,
    }
    if(AddEventComponent.date.event.extendedProps.room == '' && (this.consult.Status == 'Paid' || this.consult.Status == 'In Progress')){
        this.loading = true
        this.reloadMsg = 'RELOAD.UPDATING'
        meetData = Object.assign(meetData, {randomId: this.firebaseService.getDate()})
        this.telehealthService.createMeet(meetData).then((res: any) => {
          data.room = res.conferenceData.entryPoints[0].uri
          data.meetRef = res.id
          data = Object.assign(data, {meetStatus: 'approved'})
          this.firebaseService.scheduleEventUpdate(data);
          this.close();
          this.loading = false
    
          this.sendMail()
        })
    } else
    if(this.consult?.Status == 'Draft' && AddEventComponent.date.event.extendedProps.meetStatus == 'pending'){
      this.afs.collection('consultation').doc(this.consult.ID).update({Date: new Date(this.form.Start.value)})
      
      this.firebaseService.scheduleEventUpdate(Object.assign(data, {meetStatus: 'approved'}));
      this.close();
      this.loading = false

      this.sendMail();
    }
    else {
      this.loading = true
      this.reloadMsg = 'RELOAD.UPDATING'
      this.telehealthService.updateMeet(meetData).then(res => {
        if(this.consult?.Status == 'Draft'){
          this.afs.collection('consultation').doc(this.consult.ID).update({Date: new Date(this.form.Start.value)})
        }
        this.firebaseService.scheduleEventUpdate(Object.assign(data, {meetStatus: 'approved'}));
        this.close();
        this.loading = false
  
        this.sendMail();
      })
    }
  }

  deleteEvent(){    
    //Tue Aug 24 2021 14:28:00 GMT-0400 (Atlantic Standard Time).
    let datePipe = formatDate(AddEventComponent.date.event.extendedProps.start,'MMMM d, y',this.patientData.preferedLang);
    let timePipe = formatDate(AddEventComponent.date.event.extendedProps.start,'hh:mm a',this.patientData.preferedLang);
    let msgDatePipe = formatDate(AddEventComponent.date.event.extendedProps.start,'MMM d, y',this.patientData.preferedLang);

    if(this.patientData.timezone.timeValue != AddEventComponent.date.event.extendedProps.eventTimezoneValue.split('UTC')[1]){
      let changeStart = new DatePipe('en-Us').transform(AddEventComponent.date.event.extendedProps.start + ':00.000' + AddEventComponent.date.event.extendedProps.eventTimezoneValue.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.patientData.timezone.timeValue);
      datePipe = formatDate(changeStart,'MMMM d, y',this.patientData.preferedLang);
      timePipe = formatDate(changeStart,'hh:mm a',this.patientData.preferedLang);
      msgDatePipe = formatDate(changeStart,'MMM d, y',this.patientData.preferedLang);
    }

    this.translate.get('EVENTS.SWAL').subscribe((res: any) => {
      this.title = res.TITLE
      this.cancel = res.CANCEL
      this.delete = res.DELETE
      this.deletedmsg = res.DELETEDMSG
    })

    Swal.fire({
      customClass: {
        confirmButton: 'swalPurple',
        cancelButton: 'btn-light'
      },
      title: this.title,
      showCancelButton: true,
      cancelButtonText: this.cancel,
      confirmButtonText: this.delete
    }).then(result => {
      if (result.isConfirmed) {
        this.loading = true
        this.reloadMsg = 'RELOAD.DELETING'
        this.telehealthService.deleteMeet({
          // token: this.token.access_token,
          eventId: AddEventComponent.date.event.extendedProps.meetRef, practitionerEmail: AddEventComponent.date.event.extendedProps.practitionerEmail}).then( async res => {
         
          // AddEventComponent.date.event.remove();
          this.firebaseService.scheduleEventDelete(AddEventComponent.date.event.id);
          Swal.fire(this.deletedmsg)
          this.loading = false
          this.close();

          let msg = {es: `Dzeus Health - Se ha removido su teleconsulta para ${msgDatePipe + " a las " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}. Entre a https://app.dzeus.com para más detalles.`,
          en: `Dzeus Health - A meeting for ${msgDatePipe + " at " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue} was removed. Go to https://app.dzeus.com for more details.`}
      
          let date = new Date(Date.now());
          var dd = String(date.getDate()).padStart(2, '0');
          var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = date.getFullYear();
          let id:any = yyyy + mm + dd + uuidv4();

          let customSms: any = {
            shortUrl: {code: ''},
            smsShortUrl: '',
          }
      
          await this.emailService.shortenUrl('https://app.dzeus.com/inboxandsupport/read/' + id, this.settings.shortenedUrl).then(code => {     
            customSms.shortUrl.code = code
            customSms.smsShortUrl = code
            customSms.link = '/inboxandsupport/read/' + id
          
            this.emailService.internalCustomMessages(id, this.patientData, '<en>Deleted event</en><es>Evento Eliminado</es>', `<en>Hello ${AddEventComponent.date.event.extendedProps.patientName},<br><br> Your Healthcare Provider has deleted your meeting: "${AddEventComponent.date.event.title}" for ${datePipe + " at " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}.  
            <br><br>Thanks,<br>your ${this.settings.appName} team</en><es>Saludos ${AddEventComponent.date.event.extendedProps.patientName},<br><br> Su Proveedor de Salud a eliminado su teleconsulta: "${AddEventComponent.date.event.title}" para ${datePipe + " a las " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}.  
            <br><br>Gracias,<br>su equipo ${this.settings.appName}</es>`, 
                `<en>Hello ${AddEventComponent.date.event.extendedProps.patientName}, Your Healthcare Provider has deleted your meeting: "${AddEventComponent.date.event.title}" for ${datePipe + " at " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}.  
                Thanks,your ${this.settings.appName} team</en><es>Saludos ${AddEventComponent.date.event.extendedProps.patientName}, Su Proveedor de Salud a eliminado su teleconsulta: "${AddEventComponent.date.event.title}" para ${datePipe + " a las " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}.  
                Gracias,su equipo ${this.settings.appName}</es>`, this.settings, AddEventComponent.scheduleDetails.consultID, true, this.practitionerProfile, '', customSms, id)
          })
        })
      }
    })
  }

  onlyUnique(value, index, self){
    return self.indexOf(value) === index;
  }

  close(){
    if(this.modalRef){
      this.modalRef.dismiss()
      this.modalService.dismissAll()
    }
  }

  minutes = 15;
  calcEndTime(startTime){
    if(new Date(startTime).getTime() <= new Date().getTime()){
      this.overlap(true, true);
    }
    else {
      var futureDate: any = new Date(startTime).getTime() + (this.minutes*60000);
      this.eventform.controls.End.patchValue(formatDate(new Date(futureDate),'yyyy-MM-ddTHH:mm',this.patientData.preferedLang))    
      let sub = this.afs.collection<any>('calendar', ref => ref.where('uidPractitioner', '==', this.practitionerProfile.uid).where('start', '>=', this.today)).valueChanges().subscribe(data => {
        data = data.concat(this.abscentDates).sort((a, b) => {
          return Number(a.start.replace(/\D/g, "")) - Number(b.start.replace(/\D/g, ""));
        })
        
        for (let i = 0;i < data.length; i++) {  
          if(data[i].eventTimezoneValue && data[i]?.eventTimezoneValue?.split('UTC')[1] != this.userTimezone){
            let changeStart = new DatePipe('en-Us').transform(data[i].start+':00.000' + data[i].eventTimezoneValue.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.userTimezone);
            let changeEnd = new DatePipe('en-Us').transform(data[i].end+':00.000' + data[i].eventTimezoneValue.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.userTimezone);
            data[i].start = changeStart
            data[i].end = changeEnd
          }
          if(AddEventComponent.date?.event?.id != data[i].id || !data[i].id){
            if (new Date(startTime).getTime() < new Date(data[i].end).getTime() && new Date(futureDate).getTime() > new Date(data[i].start).getTime())
            {
              this.overlap(true, false)
              break;
            }
            if(i == data.length - 1) {
              this.overlap(false, false)
            }
          }       
          else {
            this.overlap(false)
          }        
        }   
        if(data.length == 0) {
          this.overlap(false, false)
        }
      });
      this.allSubscriptions.add(sub)
    }  
  }

  ret
  warning
  passedDate
  overlap(ret: boolean, passed?: boolean){
    this.passedDate = ''
    if(ret){
      this.ret = true;
      this.warning = 'EVENTS.WARNING'
    }
    else {
      this.ret = false;
      this.warning = ''
    }
    if(passed){
      this.passedDate = 'EVENTS.PASSED'
      this.warning = ''
      ret = true;
    }
  }

  getMinutes(): Array<number> {
    const mins: Array<number> = [];
    for (let i = 15; i <= 60; i = i + 5) {
      mins.push(i);
    }
    return mins;
  }

  async sendMail(){
    var Start = this.eventform.controls.Start.value;
    const Title = AddEventComponent.date.event.title;
    if(typeof(Start) != 'string'){
      Start = Start[0]
    }

    //2021-08-13T12:00
    let datePipe = formatDate(Start,'MMMM d, y',this.patientData.preferedLang);
    let timePipe = formatDate(Start,'hh:mm a',this.patientData.preferedLang);
    let msgDatePipe = formatDate(Start,'MMM d, y',this.patientData.preferedLang);
    
    if(this.patientData.timezone.timeValue != this.userTimezone.split('UTC')[1]){
      let changeStart = new DatePipe('en-Us').transform(Start + ':00.000' + this.userTimezone.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.patientData.timezone.timeValue);
      datePipe = formatDate(changeStart,'MMMM d, y',this.patientData.preferedLang);
      timePipe = formatDate(changeStart,'hh:mm a',this.patientData.preferedLang);
      msgDatePipe = formatDate(changeStart,'MMM d, y',this.patientData.preferedLang);
    }

    let msg = {es: `Dzeus Health - Se ha programado una teleconsulta para ${msgDatePipe + " a las " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}. Entre a https://app.dzeus.com para más detalles.`,
    en: `Dzeus Health - A meeting is scheduled for  ${msgDatePipe + " at " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}. Go to https://app.dzeus.com for more details.`}

    let date = new Date(Date.now());
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    let id:any = yyyy + mm + dd + uuidv4();

    let customSms: any = {
      shortUrl: {code: ''},
      smsShortUrl: '',
    }

    await this.emailService.shortenUrl('https://app.dzeus.com/inboxandsupport/read/' + id, this.settings.shortenedUrl).then(code => {     
      customSms.shortUrl.code = code
      customSms.smsShortUrl = code
      customSms.link = '/inboxandsupport/read/' + id
    
      this.emailService.internalCustomMessages(id, this.patientData, '<en>Updated event</en><es>Evento actualizado</es>', `<en>Hello ${AddEventComponent.date.event.extendedProps.patientName},<br><br> Your Healthcare Provider has updated the meeting: "${Title}" for ${datePipe + " at " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}.   
      <br><br>Thanks,<br>your ${this.settings.appName} team</en><es>Saludos ${AddEventComponent.date.event.extendedProps.patientName},<br><br> Su Proveedor de Salud ha actualizado su teleconsulta: "${Title}" para ${datePipe + " a las " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}.  
      <br><br>Gracias,<br>su equipo de ${this.settings.appName}</es>`, 
          `<en>Hello ${AddEventComponent.date.event.extendedProps.patientName}, Your Healthcare Provider has updated the meeting: "${Title}" for ${datePipe + " at " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}.  
          Thanks,your ${this.settings.appName} team</en><es>Saludos ${AddEventComponent.date.event.extendedProps.patientName}, Su Proveedor de Salud ha actualizado su teleconsulta: "${Title}" para ${datePipe + " a las " + timePipe+ ' - UTC' + this.patientData.timezone.timeValue}.  
          Gracias,su equipo de ${this.settings.appName}</es>`, this.settings, AddEventComponent.scheduleDetails.consultID, true, this.practitionerProfile, '', customSms, id)
    })
    
    this.close();
  }
}
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PrescriptionService } from '../consult-prescriptions/prescription.service';
import { Address, BaseProductPrescription, Profile } from 'src/app/core/services/interfaces';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PROFILE, NULL_PRESCRIPTION } from 'src/app/core/interfaces/const/interfaces';
import { NPIRegistryService } from 'src/app/core/services/npi-registry.service';
import { RxPrescription } from 'src/app/core/interfaces/prescriptions';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseService } from 'src/app/core/services/firebase.service';


@Component({
  selector: 'app-external-prescription',
  templateUrl: './external-prescription.component.html',
  styleUrls: ['./external-prescription.component.scss']
})
export class ExternalPrescriptionComponent implements OnInit, OnDestroy{
  @ViewChild('uploadPrescription', { read: TemplateRef }) uploadModal:TemplateRef<any>;
  @ViewChild('pharmacyDetails', { read: TemplateRef }) pharmacyModal:TemplateRef<any>;

  @Input() user:Profile;

  activeNav:number = 1;
  practitioner:any;
  practitioners:any[] = [];
  prescriptionsList:any[] = [];
  prescriptionsItemsList:any[] = [];
  internalPractitioners:any[] = [];
  externalPractitioners:any[] = [];

  npi_registry:any = null;
  addingProducts:boolean = false;
  currentPrescription:any = {...NULL_PRESCRIPTION};
  prescription: RxPrescription;

  productList:BaseProductPrescription[] = []; 

  basic_profile_image:string = '../../../../assets/images//users/profile.png';
  
  constructor(
    private db: AngularFirestore,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private npiRegistryService: NPIRegistryService,
    private prescriptionService: PrescriptionService,
    private firebaseService: FirebaseService
  ) { }

  ngOnInit(){
    this.getPrescriptions();
    this.getPractitioners();
  }


  // getPrescriptions(practitionerId:string){
  //   this.db.collection("prescriptions", ref => ref.where('practitionerId', '==', practitionerId)).valueChanges().subscribe((data:any)=>{
  //     this.prescriptionsList = data;
  //   });
  // }


  async handleSelection(practitioner:any){
    this.practitioner = this.practitioners.find(pract => pract.uid == practitioner);
    this.npi_registry = await this.authService.getNPIRegistry(practitioner);
    this.updatePrescription();
  }

  
  getPractitioners(){
    this.db.collection("users", ref => ref.where('accountType', 'in', ['practitioner', 'external_practitioner'])).valueChanges().subscribe((data:any)=> {
      this.practitioners = data;
      this.internalPractitioners = data.filter((practitioner:any) => practitioner.accountType == "practitioner");
      this.externalPractitioners = data.filter((practitioner:any) => practitioner.accountType == "external_practitioner");
    });
  }

  getPrescriptions(){
    this.db.collection("prescription", ref => ref.where('uid', '==', this.user.uid).where("valid", "==", true)).valueChanges().subscribe((data:any) => {
     
      // let prescriptionsHash = {};
    // this.db.collection("prescription", ref => ref.where('uid', '==', "THSmUV56a6eKLUdh590FuxmQn9X2").where("valid", "==", true)).valueChanges().subscribe((data:any)=>{
      this.prescriptionsList = data.filter((prescription:any) => prescription.transcribed == true);
      this.prescriptionsList.forEach((prescription:any) => {
        this.db.collection("prescription").doc(prescription.ID).collection("Items").valueChanges().subscribe((items:any) => {
          this.addToHashSet(prescription.ID, items);
        });
      });
    });
  }

  addToHashSet(prescriptionId: string, item: BaseProductPrescription[]) {
    if (!this.prescriptionsItemsList[prescriptionId]) {
      this.prescriptionsItemsList[prescriptionId] = [];
    }
    // this.prescriptionsItemsList[prescriptionId].push(...item);
    item.forEach(item => {
      if (!this.prescriptionsItemsList[prescriptionId].some(existingItem => existingItem.id === item.id)) {
        this.prescriptionsItemsList[prescriptionId].push(item);
      }
    });
  }
  


  setEditMode(prescription:RxPrescription){
    this.currentPrescription = prescription;
    this.practitioner = this.practitioners.find(pract => pract.uid == prescription.docId);
    this.addingProducts = true;
  }
  


  setPractitionerImage(uid:string){
    return this.practitioners ? this.practitioners.find(practitioner => practitioner.uid == uid)?.image : this.basic_profile_image;
  }


  async generatePrescription(){
    let prescription:RxPrescription ={...NULL_PRESCRIPTION}
    prescription.transcribed = true;
    prescription.ID = uuidv4();
    prescription.date = new Date();
    prescription.prescription_pdf = `prescription-${prescription.ID}`

    prescription.uid = this.user.uid;
    prescription.email = this.user.email;
    prescription.partnerID = this.user.partnerID
    prescription.phone = this.user.phoneNumber;
    prescription.birthdate = this.user.birthdate;
    prescription.name = this.user.firstName + " " + this.user.lastName1;  
    let addressess = await this.firebaseService.getUserAddresses(this.user)
    let address = await addressess.filter(address => address.AddressType == 'Billing');

    prescription.address = address[0];
    prescription.age = this.calculateAge(this.user.birthdate);
    this.currentPrescription = prescription;
    this.db.collection("prescription").doc(prescription.ID).set(prescription);
    this.addingProducts = true;
    // this.db.collection("prescription").doc(prescription.ID).collection("Items").doc(this.product.id).set(this.product);
    
  }

  calculateAge(user){
    var date = new Date(user);
    var today = new Date();
    var timeDiff = Math.abs(today.getTime() - date.getTime());
    // var age1 = Math.ceil(timeDiff / (1000 * 3600 * 24)) / 365;
    var age1 = Math.floor(timeDiff / (1000 * 3600 * 24)) / 365.25;
    var age = Math.floor(age1);
    return age;
  }

  async searchRegistry(NPI: string){
    if(NPI.length == 10){
      await this.npiRegistryService.searchRegistry(NPI, NPI).then(async res =>{
        this.npi_registry = await res;
        let external_practitioner: Profile = DEFAULT_PROFILE;
        external_practitioner.firstName = this.npi_registry.message.basic.first_name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        external_practitioner.middleName = "";
        external_practitioner.lastName1 = this.npi_registry.message.basic.last_name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        external_practitioner.accountType = "external_practitioner";
        external_practitioner.uid = NPI;
        external_practitioner.email = "dzeuspharmacy@externalpractitioner.com";
        external_practitioner.preferedLang = "en";
        external_practitioner.image = '../assets/images/users/profile.png',
        external_practitioner.finishedSignup = false;
        this.db.collection("users").doc(NPI).set(external_practitioner);
        let billing: Address = {
          id: uuidv4(),
          AddressType: "Billing",
          AddressLine1: this.npi_registry.message.addresses[0].address_1,
          AddressLine2: "",
          AddressState: this.npi_registry.message.addresses[0].state,
          AddressStateCode: this.npi_registry.message.addresses[0].state,
          AddressCity: this.npi_registry.message.addresses[0].city,
          AddressCountry: this.npi_registry.message.addresses[0].country_name,
          AddressZipcode: this.npi_registry.message.addresses[0].postal_code
        }
        let shipping: Address = {
          id: uuidv4(),
          AddressType: "Shipping",
          AddressLine1: this.npi_registry.message.addresses[1].address_1,
          AddressLine2: "",
          AddressState: this.npi_registry.message.addresses[1].state,
          AddressStateCode: this.npi_registry.message.addresses[1].state,
          AddressCity: this.npi_registry.message.addresses[1].city,
          AddressCountry: this.npi_registry.message.addresses[1].country_name,
          AddressZipcode: this.npi_registry.message.addresses[1].postal_code
        }
        await this.db.collection("users").doc(NPI).collection("Address").doc(billing.id).set(billing);
        await this.db.collection("users").doc(NPI).collection("Address").doc(shipping.id).set(shipping);
        await this.db.collection("users").doc(NPI).collection("NPI Registry").doc(NPI).set(this.npi_registry.message);
        await this.db.collection("users").doc(NPI).collection("License").doc(this.npi_registry.message.taxonomies[0].license).set(this.npi_registry.message.taxonomies[0]);
        await this.authService.getUserProfile(NPI).then(res => {
          this.practitioner = res
          setTimeout(() => {
            this.updatePrescription();
          }, 2000)
        });
      });
    }
  }

  updatePrescription(){
      let location: Address = {
      id: uuidv4(),
      AddressType: "Billing",
      AddressLine1: this.npi_registry.message.addresses[0].address_1,
      AddressLine2: "",
      AddressState: this.npi_registry.message.addresses[0].state,
      AddressStateCode: this.npi_registry.message.addresses[0].state,
      AddressCity: this.npi_registry.message.addresses[0].city,
      AddressCountry: this.npi_registry.message.addresses[0].country_name,
      AddressZipcode: this.npi_registry.message.addresses[0].postal_code
    }
    if(this.practitioner.accountType == "external_practitioner" || this.practitioner.accountType == "practitioner"){
      this.currentPrescription.docId = this.practitioner.uid;
      this.currentPrescription.docName = this.practitioner.firstName + " " + this.practitioner.lastName1;
      this.currentPrescription.License = this.npi_registry.message.taxonomies[0].license;
      this.currentPrescription.LicenseState = this.npi_registry.message.taxonomies[0].state;
      this.currentPrescription.Specialty = this.npi_registry.message.taxonomies[0].desc;
      this.currentPrescription.docAdd = location;
      this.currentPrescription.NPI = this.npi_registry.message.NPI;
    }

    if(this.practitioner.accountType == "practitioner"){
      this.currentPrescription.docPhone = this.practitioner.phoneNumber;
      this.currentPrescription.docEmail = this.practitioner.email;
    }

    this.db.collection("prescription").doc(this.currentPrescription.ID).update(this.currentPrescription)
  }

  sendToPharmacy(prescription:RxPrescription){
    this.currentPrescription = prescription;
    this.db.collection("prescription").doc(prescription.ID).update({pharmacy: "Dzeus", signedDate: new Date(Date.now())});
    this.currentPrescription.pharmacy = "Dzeus";
    this.modalService.open(this.pharmacyModal, { size: 'lg', centered: true, backdrop: 'static', windowClass: "pharmacy-modal"});

  }

  setUpload(prescription:RxPrescription){
    this.currentPrescription = prescription;  
    this.modalService.open(this.uploadModal, { size: 'lg', centered: true, backdrop: 'static', windowClass: "upload-modal"});
  }


  deleteItem(id:string, item){

    this.db.collection("prescription").doc(id).collection("Items").doc(item.id).delete()
    this.productList = this.productList.filter(product => product.id !== item.id);
  }


  close(){
    this.addingProducts = false;
    this.currentPrescription = {...NULL_PRESCRIPTION};
    this.modalService.dismissAll();
  }

  ngOnDestroy(){

  }

}

import { LabInformation, MedicalAnalysisTemplate } from "../interfaces/laboratories";

export const MEDICAL_ANALYSIS_TEMPLATE:MedicalAnalysisTemplate = {
  labName: "",
  labCode: "",
  dateOfTest: new Date(),
  uploadDate: new Date(),
  interpretation: "",
  comments: "",
  testResults: [
    {
      name: "",
      value: 0,
      unit:['decimal', "description", "true/false","range" ],
      range: {
        low: 0,
        high: 0,
        unit: ""
      }
    }
  ],
  testMethod: '',
  labInformation: {
    name: '',
    address: '',
    contactNumber: ''
  },
  mesurements: {
    decimal_units: [
      'mg/dL',
      'mmol/L',
      'g/dL',
      'mEq/L',
      'mmHg',
      'mm/hr',
      'IU/L',
      'ng/mL',
      'pg/mL',
      'mL/min/1.73m2'
    ]
  }
};



export const LABORATORY_INFORMATION: LabInformation = {
  name: '',
  address: '',
  contactNumber: ''
}


export const LABORATORY_MISC = {
  dateOfTest: new Date(),
  interpretation: "",
  comments: ""
}
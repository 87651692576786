import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-social-gender',
  templateUrl: './social-gender.component.html',
  styleUrls: ['./social-gender.component.scss']
})


export class SocialGenderComponent implements OnInit, OnDestroy{

  @Input() styling = ''
  @Output() socialGender = new EventEmitter<any>(); 
  @Output() componentLoaded = new EventEmitter<void>();
  @Output() componentUnloaded = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();
  @Input() background = 'white';

  social_genders = ["SOCIAL-GENDERS.MALE", "SOCIAL-GENDERS.FEMALE", "SOCIAL-GENDERS.TRANSGENDER-MALE", "SOCIAL-GENDERS.TRANSGENDER-FEMALE", "SOCIAL-GENDERS.GENDERQUEER-NONBINARY", "SOCIAL-GENDERS.AGENDER", "SOCIAL-GENDERS.QUESTIONING", "SOCIAL-GENDERS.ANOTHER"]
  social_genders_value = ["Male", "Female", "Transgender Male", "Transgender Female", "Genderqueer-Non binary", "Agender", "Questioning", "Other"]

  constructor(){}

  ngOnInit(): void {
    this.componentLoaded.emit();
  }

  emit(value:string){
    this.socialGender.emit({target: {
      name: 'gender',
      value: value
    }});
  }

  ngOnDestroy(): void {
    this.componentUnloaded.emit();
  }

}

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-consult-questions',
  templateUrl: './consult-questions.component.html',
  styleUrls: ['./consult-questions.component.scss']
})
export class ConsultQuestionsComponent implements OnInit {

  manage = 'Consults'
  
  startPage: boolean = true;

  allConditions = [];
  allQuestionsDocuments = [];
  conditionForm: UntypedFormGroup;
  questionForm: UntypedFormGroup;
  consultation;
  questions;
  questionAtributes: any = '';
  questionAtributesOptions
  activeId = 1;
  questionGroupId = ''

  currentLang = this.translate.store.currentLang;

  steps = [
    { stepId: 'preconsultQuestions'},
    { stepId: 'engagementStep'},
    { stepId: 'gender'},
    { stepId: 'biologicalgender'},
    { stepId: 'chooseState'},
    { stepId: 'birthdate'},
    { stepId: 'chooseLabTests'},
    { stepId: 'chooseLabLocation'},
    { stepId: 'chooseMedication'},
    { stepId: 'nonPreconsultQuestions'},
    { stepId: 'uploadImage'},
    { stepId: 'phoneNumber'},
    { stepId: 'choosePractitioner'},
    { stepId: 'chooseService'},
    { stepId: 'profileImage'},
    { stepId: 'checkout'},
    { stepId: 'chooseTime/Type'}
  ];

  constructor(    
    private afs: AngularFirestore,
    public formBuilder: UntypedFormBuilder,
    public firebaseService: FirebaseService,
    public translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.afs.collection<any>('consultTemplates').valueChanges().subscribe(data => {
      this.allConditions = data;
    });

    this.afs.collection<any>('conditionsQuestions').valueChanges().subscribe(data => {
      this.allQuestionsDocuments = data;
    });
  }


  stepsInCondition = [];
  async createConsult() {
    let lastConsultIndex = 0;
    this.allConditions.forEach( allConditions => { 
      if(allConditions.conditionCode > lastConsultIndex ){
        lastConsultIndex = Number(allConditions.conditionCode);
      }
    })

    // let lastQuestionIndex = 0
    // this.allQuestionsDocuments.forEach(allQuestions => { 
    //   if(Number(allQuestions.id) > lastQuestionIndex){
    //     lastQuestionIndex = Number(allQuestions.id);
    //   }
    // })

    await this.conditionForm.patchValue({
      Active: true,
      conditionCode: (lastConsultIndex + 1).toString(),
      // conditionQuestionsId: (lastQuestionIndex + 1).toString(),
      id: this.conditionForm.controls.serviceType.value.toString().includes('Follow Up for') ? this.conditionForm.controls.serviceType.value.toString().replace('Follow Up for ', '') : this.conditionForm.controls.serviceType.value.toString(),
      lastRevision: new Date(),
      productGroupQty: 0,
      rxcuiSource: 'internal',
      steps: this.stepsInCondition
    })

    let update = this.conditionForm.value;
    this.afs.collection<any>('consultTemplates').doc(update.serviceType).set(update).then( getCondition => {
      this.startPageSwitch();
      if(!this.conditionForm.controls.serviceType.value.toString().includes('Follow Up for')){
        this.afs.collection('consultRules').doc(this.conditionForm.controls.serviceType.value).set(
          {id: this.conditionForm.controls.serviceType.value,
           consultService: this.conditionForm.controls.serviceType.value + ' Consultation',
           consultTemplate: this.conditionForm.controls.serviceType.value,
           followUpService: 'Follow Up for ' + this.conditionForm.controls.serviceType.value + ' Consultation',
           followUpTemplate: 'Follow Up for ' + this.conditionForm.controls.serviceType.value,
           daysForNextOriginalConsult: 365,
           states: [{state_name: 'Puerto Rico', state_code: 'PR'}, {state_name: 'Florida', state_code: 'FL'}], 
           conditions: [
              {canChooseMultipleProducts:false,
              condition: this.conditionForm.controls.serviceType.value,
              conditionName: this.conditionForm.controls.Name.value,
              hasProducts:true,
              required: true}],
            canChooseMultipleProducts: false
          });
      }
      Swal.fire('Created', 'Consult created', 'success');
    });    
  }

  createQuestion() {
    let lastQuestionIndex = 0;
    if(this.questions != undefined && this.questions.length != 0) {
      this.questions.forEach( allConditions => { 
        if(allConditions.id > lastQuestionIndex ){
          lastQuestionIndex = Number(allConditions.id);
        }
      })
    }

    this.questionForm.patchValue({
      answer: '',
      answered: false,
      id: (lastQuestionIndex + 1).toString(),
      points: 0,
      sequence: Number(this.questionForm.controls.sequence.value),
      options: this.questionAtributesOptions?.options || [],
      optionsPoints: this.questionAtributesOptions?.optionsPoints || [],
    })

    let update = this.questionForm.value;
    if(this.questionAtributes.type == 'SelectionExplain' || this.questionAtributes.type == 'Text' || (this.questionAtributes.type == 'Multiple' && (this.questionAtributesOptions.options.includes('<en>Other</en><es>Otros</es>') || this.questionAtributesOptions.options.includes('<en>Other</en><es>Otro</es>')))){
      update.explain = '';
    }

    if(lastQuestionIndex == 0){
      this.afs.collection('conditionsQuestions').doc(this.questionGroupId).set({id: this.questionGroupId});
      this.setSelectedQuestionGroup(this.questionGroupId);
    }

    this.afs.collection<any>('conditionsQuestions').doc(this.questionGroupId).collection('Questions').doc(update.id.toString()).set(update).then(update => {
      this.questionAtributes = '';
      this.questionAtributesOptions = '';
      this.tempValue = [];
      this.manageConsultSwitch();
      this.activeId = 2;
    });   
  }

  updateConsult() {
    Swal.fire({
      title: "Are you sure?",
      text: "Created consultations by patients won't be updated.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.value) {
        let update = this.conditionForm.value;
        update.steps = this.stepsInCondition
        
        this.afs.collection<any>('consultTemplates').doc(this.consultation.serviceType).update(update);
        this.consultation = '';
        this.startPageSwitch();
        Swal.fire('Update', 'Consult updated', 'success');
      }
    });
  }

  updateQuestion(){
    this.questionForm.patchValue({
      optionsPoints: this.questionAtributesOptions.optionsPoints,
      options: this.questionAtributesOptions.options,
      sequence: Number(this.questionForm.controls.sequence.value)
    })
    let update = this.questionForm.value;
    if(this.questionAtributes.type == 'SelectionExplain' || this.questionAtributes.type == 'Text' || (this.questionAtributes.type == 'Multiple' && (this.questionAtributesOptions.options.includes('<en>Other</en><es>Otros</es>') || this.questionAtributesOptions.options.includes('<en>Other</en><es>Otro</es>')))){
      update.explain = '';
    }
    else {
      delete update.explain;
    }
    this.afs.collection<any>('conditionsQuestions').doc(this.questionGroupId).collection('Questions').doc(this.questionAtributes.id.toString()).update(update).then(update => {
      this.questionAtributes = '';
      this.questionAtributesOptions = '';
      this.tempValue = []
      this.activeId = 2;
    });
  }
  
  disableConsult() {    
    this.afs.collection<any>('consultTemplates').doc(this.consultation.serviceType).update({disabled: true});
    this.consultation = '';
    this.startPageSwitch();
  }

  deleteQuestion() {
    Swal.fire({
      title: "Are you sure?",
      text: "Open consults wont have this question deleted.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.value) {
        this.afs.collection<any>('conditionsQuestions').doc(this.questionGroupId).collection('Questions').doc(this.questionAtributes.id.toString()).delete().then(update => {
          this.questionAtributes = '';
          this.questionAtributesOptions = '';
          this.tempValue = []
          this.activeId = 2
        });
        Swal.fire('Deleted', 'Question deleted', 'success');
      }
    });
  }

  invalidSubmit(){
    if(this.conditionForm.controls.Name.value != undefined && this.conditionForm.controls.Name.value != '' && this.conditionForm.controls.Photo1.value != undefined && this.conditionForm.controls.Photo1.value != ''
      && this.conditionForm.controls.consultModale.value != '' && this.conditionForm.controls.nextSteps.value.length != 0
      && typeof(this.conditionForm.controls.disabled.value) == 'boolean' && this.conditionForm.controls.minAge.value <= this.conditionForm.controls.maxAge.value
      && this.conditionForm.controls.biologicalGender.value != undefined && this.conditionForm.controls.disabled.value != undefined
      && this.conditionForm.controls.maxAge.value != undefined && this.conditionForm.controls.maxAge.value != ''
      && this.conditionForm.controls.minAge.value != undefined && this.conditionForm.controls.minAge.value != ''
      && typeof(this.conditionForm.controls.passScoreLimit.value) == 'boolean' && this.conditionForm.controls.passScoreLimit.value != undefined
      && this.conditionForm.controls.requiredFollowUp.value != undefined && typeof(this.conditionForm.controls.requiredFollowUp.value) == 'boolean'
      && this.conditionForm.controls.serviceType.value != undefined && this.conditionForm.controls.serviceType.value != ''
      && this.conditionForm.controls.totalQuestions.value != undefined && this.conditionForm.controls.totalQuestions.value != ''
      && this.conditionForm.controls.typeOfContact.value != undefined && this.conditionForm.controls.typeOfContact.value != ''
      && this.conditionForm.controls.typeOfPractitioner.value != undefined && this.conditionForm.controls.typeOfPractitioner.value != ''
      && this.conditionForm.controls.scoreLimit.value != undefined && this.conditionForm.controls.scoreLimit.value != ''){
        var element = <HTMLInputElement> document.getElementById("saveBtn");
        element.disabled = false;
    }
    else {
      var element = <HTMLInputElement> document.getElementById("saveBtn");
        element.disabled = true;
    }
  }

  invalidQuestionSubmit(){
    if(this.questionForm.controls.preconsult.value != undefined && this.questionForm.controls.required.value != undefined 
      && this.questionForm.controls.required.errors == null && this.questionForm.controls.preconsult.errors == null
      && this.questionForm.controls.question.value != undefined && this.questionForm.controls.question.value.length != 0
      ){
      var element = <HTMLInputElement> document.getElementById("saveBtn");
      element.disabled = false;
    }
    else {
      var element = <HTMLInputElement> document.getElementById("saveBtn");
      element.disabled = true;
    } 
  }

  setSelectedCondition(condition:any){ 
    this.consultation = condition;
    this.manageConsultSwitch();
  }

  setSelectedQuestionGroup(questionGroupId:any){ 
    this.questionGroupId = questionGroupId;
    this.afs.collection('conditionsQuestions').doc(questionGroupId).collection('Questions').valueChanges().subscribe(async data => {
      this.questions = await data.map(doc => doc);
      this.questions = this.questions.sort((a, b) => {
        return a.sequence - b.sequence
      })
      this.manageConsultSwitch();
      this.activeId = 2;
    });
  }

  saveQuestionGroup(){
    this.afs.collection('conditionsQuestions').doc(this.questionGroupId).set({id: this.questionGroupId});
  }

  optionPointsVar = []
  getPoints(optionsPoints){
    this.optionPointsVar = [];
    for(let i = 0; i < optionsPoints.length; i++){
      this.optionPointsVar.push(Number(optionsPoints[i]))
    }
  }

  tempValue = [];
  edit(event, i, optionPoints){ 
    if(this.tempValue.length == 0){
      this.tempValue.push({event: event, id: i, optionsPoints: Number(optionPoints)});
    }

    for(let j = 0; j < this.tempValue.length; j++){
      if(this.tempValue[j].id == i){
        this.tempValue[j].event = event;
        this.tempValue[j].optionsPoints = Number(optionPoints)
      }
      else {
        this.tempValue.push({event: event, id: i, optionsPoints: Number(optionPoints)});
      }
    } 
  }

  optionSave(){
    for(let k = 0; k < this.tempValue.length; k++){
      if(this.tempValue[k].optionsPoints == 0){
        this.questionAtributesOptions.optionsPoints[this.tempValue[k].id] = 0;
      }

      this.questionAtributesOptions.options[this.tempValue[k].id] = this.tempValue[k].event;
      this.questionAtributesOptions.optionsPoints[this.tempValue[k].id] = this.tempValue[k].optionsPoints;

      let update = {
        options: this.questionAtributesOptions.options,
        optionsPoints: this.questionAtributesOptions.optionsPoints
      }

      // if(k == this.tempValue.length -1 && this.questionAtributes != '' && this.questionAtributes != 'new'){
      //   this.afs.collection<any>('conditionsQuestions').doc(this.questionGroupId).collection('Questions').doc(this.questionAtributes.id.toString()).update(update).then(update => {
      //     this.tempValue = []
      //   })
      // }
    }
  }

  deleteOption(i){
    if(this.questionAtributesOptions.options.length != 1){
      this.questionAtributesOptions.options.splice(this.questionAtributesOptions.options.indexOf(i), 1);
      this.questionAtributesOptions.optionsPoints.splice(this.questionAtributesOptions.optionsPoints.indexOf(i), 1);
    }
    else {
      Swal.fire('Warning', 'You cant leave options empty', 'warning');
    }
  }

  addOption(){
    if(this.questionAtributesOptions.length == 0){
      this.questionAtributesOptions = {options: [''],optionsPoints: [0]};
    }
    else {
      this.questionAtributesOptions.options.push('')
      this.questionAtributesOptions.optionsPoints.push(0);
    } 
  }

  setSelectedQuestion(question:any){
    if(question != 'new'){      
      this.afs.firestore.collection('conditionsQuestions').doc(this.questionGroupId).collection('Questions').doc(question.id).get().then(async data => {
        this.questionAtributes = await data.data();
        this.questionForm.patchValue(this.questionAtributes)
        this.questionAtributesOptions = {options: data.data()?.options, optionsPoints: data.data()?.optionsPoints};
      });  
    }  
    else {
      this.questionAtributes = 'new'
    }
    this.activeId = 3
  }

  startPageSwitch(){
    this.startPage = true;
    this.consultation = '';
    this.questionAtributes = '';
    this.questionAtributesOptions = '';
    this.tempValue = []
    this.questions = '';
    this.activeId = 1;
    this.questionGroupId = '';
  }

  manageConsultSwitch(){
    this.startPage = false;

    this.setConditionForm()
    if(this.consultation){
      this.stepsInCondition = this.consultation.steps;
      this.conditionForm.patchValue(this.consultation);
    }
    this.setQuestionForm()
  }

  setQuestionForm(){
    this.questionAtributesOptions = {options: [''],optionsPoints: [0]};
    
    this.questionForm = this.formBuilder.group({      
      displayType: ['text', [Validators.required]],
      preconsult: [false,[Validators.required]],
      question: ['', [Validators.required]],
      required: [true, [Validators.required]],
      type: ['', [Validators.required]],
      hints: [''],
      info: [''],
      options: [[]],
      optionsPoints: [[]],
      answer: '',
      answered: false,
      id: (1).toString(),
      points: 0,
      sequence: this.questions ? this.questions.length + 1 : 1, 
      changeNextSequenceTo: '',
      dependsOnQuestion: '',
      dependsOnAnswer: []
    });
  }

  setConditionForm(){
    this.conditionForm = this.formBuilder.group({
      Name: ['', [Validators.required]],
      Photo1: ['', [Validators.required]],
      biologicalGender: [[], [Validators.required]],
      consultModale: ['', [Validators.required]],
      disabled: [true, [Validators.required]],
      maxAge: ['', [Validators.required]],
      minAge: ['', [Validators.required]],
      nextSteps: [["<en>Review your medical history</en><es>Revisar su historial médico</es>",
                   "<en>Determine if the treatment is right for you</en><es>Determinar si el tratamiento es correcto para usted</es>",
                   "<en>Send messages inside the platform regarding your consult</en><es>Enviar mensajes dentro de la plataforma con relación a su consulta</es>"], [Validators.required]],
      passScoreLimit: [true],
      requiredFollowUp: [false],
      scoreLimit: ['', [Validators.required]],
      serviceType: ['', [Validators.required]],
      totalQuestions: [0, [Validators.required]],
      typeOfContact: [[], [Validators.required]],
      typeOfPractitioner: [[], [Validators.required]],
      Active: true,
      conditionCode: (1).toString(),
      conditionQuestionsId: [],
      id: '',
      lastRevision: new Date(),
      productGroupQty: 0,
      rxcuiSource: 'internal',
      steps: [this.stepsInCondition.length > 0 ? this.stepsInCondition : this.steps]
    });
  }

  addMedicalHistoryQuestions(){
    // this.afs.collection('conditionsQuestions').doc('ED New').collection('Questions').valueChanges().subscribe(res => {
    //   res = res.sort((a, b) => {
    //     return a.sequence - b.sequence
    //   })

    //     // let options = [
    //     //   {
    //     //     options: "<en>Regular Menstrual Cycles</en><es>Ciclos Menstruales Regulares</es>",
    //     //     subOptions: [
    //     //       "<en>Normal Duration</en><es>Duración Normal</es>",
    //     //       "<en>Consistent Cycles</en><es>Ciclos Consistentes</es>",
    //     //       "<en>Predictability</en><es>Previsibilidad</es>"
    //     //     ]
    //     //   },
    //     //   {
    //     //     options: "<en>Irregular Menstrual Cycles</en><es>Ciclos Menstruales Irregulares</es>",
    //     //     subOptions: [
    //     //       "<en>Very Short Cycles</en><es>Ciclos Muy Cortos</es>",
    //     //       "<en>Very Long Cycles</en><es>Ciclos Muy Largos</es>",
    //     //       "<en>Significant Variability</en><es>Variabilidad Significativa</es>",
    //     //       "<en>Absence of Menstruation</en><es>Ausencia de Menstruación</es>",
    //     //       "<en>Infrequent Menstruation</en><es>Menstruación Infrecuente</es>",
    //     //       "<en>Intermittent Bleeding or Spotting</en><es>Sangrado Intermitente o Manchado</es>"
    //     //     ]
    //     //   }
    //     // ]

    //     // res[19].options = options;

    //     // this.afs.collection('conditionsQuestions').doc('Birth Control').collection('Questions').doc((22).toString()).set(res[19])


    //   for(let i = 5; i <= res.length; i++){
    //     res[i - 1].id = (i + 5).toString();
    //     res[i - 1].sequence = i + 5;
    //     this.afs.collection('conditionsQuestions').doc('ED V3').collection('Questions').doc((i + 5).toString()).set(res[i - 1])
    //   }
    // })

    // let questionsLength = this.questions.length

    // this.afs.firestore.collection('conditionsQuestions').doc('medicalHistory').collection('Questions').get().then(async res => {
    //   let questions = res.docs.sort((a, b) => {
    //     return a.data().medicalHistoryId - b.data().medicalHistoryId
    //   })
    //   for(let i = 1; i <= questions.length; i++){
    //     let question = Object.assign(questions[i - 1].data(), {id: await (questionsLength + i).toString(), sequence: await (questionsLength + i)})
    //     if(this.questions.filter(q => q.medicalHistoryId == question.medicalHistoryId).length != 1){     
    //       this.afs.collection('conditionsQuestions').doc(this.consultation.conditionQuestionsId.toString()).collection('Questions').doc((questionsLength + i).toString()).set(question)
    //     }
      
    //     if(i == questions.length){
    //       this.addLastQuestionsInConsult(questionsLength + i)
    //     }
    //   }
    // })
  }

  // addLastQuestionsInConsult(questionsLength){
  //   // this.afs.collection('conditionsQuestions').doc('3').collection('Questions', ref => ref.where('id', 'in', ['40', '43'])).valueChanges().subscribe(res => {
  //   //   res = res.sort((a, b) => {
  //   //     return a.sequence - b.sequence
  //   //   })
  //   //   for(let i = 1; i <= res.length; i++){
  //   //     res[i - 1].id = i.toString();
  //   //     res[i - 1].sequence = i;
  //   //     this.afs.collection('conditionsQuestions').doc('lastQuestionsInConsult').collection('Questions').doc(i.toString()).set(res[i - 1])
  //   //   }
  //   // }

  //   this.afs.firestore.collection('conditionsQuestions').doc('lastQuestionsInConsult').collection('Questions').get().then(async res => {
  //     let questions = res.docs.sort((a, b) => {
  //       return a.data().sequence - b.data().sequence
  //     })
  //     for(let i = 1; i <= questions.length; i++){
  //       let question = Object.assign(questions[i - 1].data(), {id: await (questionsLength + i).toString(), sequence: await (questionsLength + i)})
  //       if(this.questions.filter(q => q.question == question.question).length != 1){     
  //         this.afs.collection('conditionsQuestions').doc(this.consultation.conditionQuestionsId.toString()).collection('Questions').doc((questionsLength + i).toString()).set(question)
  //       }
      
  //       if(i == questions.length){
  //         Swal.fire('Completed', 'All questions were added succesfully', 'success');
  //       }
  //     }
  //   })
  // }

  // checkLastQuestions(){
  //   if(this.questions.length > 0 && !this.questions[this.questions.length - 1].lastQuestion){
  //     let finalQuestions = this.questions.filter(q => q.finalQuestion == true).sort((a, b) => {a.sequence - b.sequence});
  //     if(finalQuestions.length > 0){
  //       let newLastQuestionSequence = finalQuestions[0].sequence;
  //       this.afs.collection('conditionsQuestions').doc(this.consultation.conditionQuestionsId.toString()).collection('Questions').doc(this.questions[this.questions.length - 1].id).update({sequence: newLastQuestionSequence})
  //       for(let i = 0; i < finalQuestions.length; i++){
  //         finalQuestions[i].sequence = finalQuestions[i].sequence + 1
  //         this.afs.collection('conditionsQuestions').doc(this.consultation.conditionQuestionsId.toString()).collection('Questions').doc(finalQuestions[i].id).update({sequence: finalQuestions[i].sequence}) 
  //       }
  //     }
  //   }
  // }
}
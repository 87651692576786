import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/language.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit{
  @Input() survey;
  @Output() closeModal = new EventEmitter();

  //Modal reference
  modalRef:any;

  explanation: string = '';
  surveyQuestions: any = [];
  currentLang = this.translate.store.currentLang;
  swal: any;

  anonymousAnswer = false;
  allowPublishSurvey = true;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private afs: AngularFirestore,
    public translate: TranslateService,
    public languageService: LanguageService,
  ) { 
  }

  ngOnInit() {
    this.afs.firestore.collection('conditionsQuestions').doc(this.survey.surveyQuestionsTemplate).collection('Questions').get().then(async (questions) => {
      let testId = this.router.url.split('&q=')[1]?.split('&')[0];
      let testAnswer = this.router.url.split('&a=')[1]?.split('&')[0];
      this.surveyQuestions = await new Promise(async (resolve) => {
        let qData = [];
        questions.docs.map(async (question: any, i) => {
          let tempQData = question.data();

          if(testId && testAnswer && testId.toString() == question.data().id.toString() && tempQData.options.includes(testAnswer.toString())){
            tempQData.answer = testAnswer.toString();
          }
          qData.push(tempQData);
  
          if(i == questions.docs.length - 1){
            resolve(qData);
          }
        })
      })
    })
  }

  saveExplanation($event){
    let data = {
      explain: $event.target.value
    }

    this.explanation = data.explain;
  }

  checkOption(option){
    return !Number.isNaN(Number(option)) ? 'number' : 'string';
  }


  displayEmoji = 'fas fa-regular fa-meh mx-1 gray';
  changeEmoji(option) {
    for(var i = 1; i <= Number(option); i++){
      var emojiDisplay = document.getElementById("showEmoji-" + (i - 1));
    
      switch (option) {
        case "1":
          emojiDisplay.className = "fas fa-regular fa-sad-tear mx-1 red";
          break;
        case "2":
          emojiDisplay.className = "fas fa-regular fa-frown mx-1 orange";
          break;
        case "3":
          emojiDisplay.className = "fas fa-regular fa-meh mx-1 text-warning";
          break;
        case "4":
          emojiDisplay.className = "fas fa-regular fa-smile mx-1 blue";
          break;
        case "5":
          emojiDisplay.className = "fas fa-regular fa-grin-stars mx-1 green";
          break;
        default:
          emojiDisplay.className = "fas fa-regular fa-meh mx-1 gray";
      }
    }

    for(var i = Number(option) + 1; i <= 5; i++){
      var emojiDisplay = document.getElementById("showEmoji-" + (i - 1));
      emojiDisplay.className = "fas fa-regular fa-meh mx-1 removeFillStar gray";
    }
  }

  validateSurvey(){
    return this.surveyQuestions.filter((question) => question.answer == '' && question.type != 'Text' ).length > 0;
  }

  submitSurvey(){
    if(!this.validateSurvey()){
      let surveyData = {
        surveyPatientStatus: 'completed',
        surveyAnswers: [],
        anonymousAnswer: this.anonymousAnswer,
        allowPublishSurvey: this.allowPublishSurvey,
        surveyCompletedDate: new Date()
      }
  
      this.surveyQuestions.forEach((question) => {
        let answer = {
          questionId: question.id,
          answer: question.answer,
        }
  
        if(question.type == 'Text'){
          answer.answer = question.explain ? question.explain : '';
        }
  
        surveyData.surveyAnswers.push(answer);
      })
  
      this.afs.collection('surveys').doc(this.survey.surveyId).update(surveyData).then(() => {
        this.modalService.dismissAll();
        this.translate.get('SURVEY').subscribe((res: any) => {
          this.swal = res
          
          Swal.fire({
            customClass: {
              confirmButton: 'swalPurple',
            },
            title: this.swal.SURVEYSUBMITTED,
            text: this.swal.TYFYF,
            icon: 'success',
            confirmButtonText: this.swal.GOTOHOME
          }).then(() => {
            this.router.navigate(['/']);
          })
        })
      })
    }
  }

  answerLater(){
    this.afs.collection('surveys').doc(this.survey.surveyId).update({surveyLastReminder: new Date()});
    this.modalService.dismissAll();
  }
}

<div>
    <div class="d-flex" *ngFor="let day of scheduleDays; let i = index">
        <!-- <div class="custom-control custom-checkbox mb-2"> -->
            <!-- <input type="checkbox" class="custom-control-input" id="{{i}}" (change)="selectDay($event)" [checked]="day.checked" name="day"> -->
            <span for="{{i}}">{{day.day}}:</span> <!-- class="custom-control-label"-->
        <!-- </div> -->
        <div class="mx-2">
            <div  *ngFor="let schedule of practitionerSchedule[i].schedule; let j = index">             
                <label for="schedule.start" class="inp mr-2" style="max-width: 180px;">
                    <input class="p-0" style="height: 25px;" type="time" value="schedule.start" name="schedule.start" id="schedule.start" placeholder="&nbsp;" [(ngModel)]="practitionerSchedule[i].schedule[j].start">       
                </label>  
                <label for="schedule.end" class="inp mr-2" style="max-width: 180px;">
                    <input class="p-0" style="height: 25px;" type="time" value="schedule.end" name="schedule.end" id="schedule.end" placeholder="&nbsp;" [disabled]="practitionerSchedule[i].schedule[j].start == ''" (change)="checkTime()" [(ngModel)]="practitionerSchedule[i].schedule[j].end">       
                </label>  
                <span *ngIf="admin" [ngClass]="{'buttonDisabled': !editSchedule}" class="orangeText" style="text-decoration: underline;" (click)="removeOption(day, j);" ngbTooltip="{{'CONSULTQUESTIONS.ADDOPTION' | translate}}">
                    {{'PROFILE.REMOVE' | translate}}
                </span>
                <p *ngIf="this.practitionerSchedule[i].schedule[j].end < this.practitionerSchedule[i].schedule[j].start && this.practitionerSchedule[i].schedule[j].end != ''">{{warning}}</p>
            </div>
            <button [ngClass]="{'buttonDisabled': !editSchedule}" class="orangeText" style="text-decoration: underline; background-color: transparent; border:transparent" (click)="addOption(day);"  ngbTooltip="{{'CONSULTQUESTIONS.ADDOPTION' | translate}}"> <!-- [disabled]="!day.checked"-->
                {{'PROFILE.ADDTIME' | translate}}
            </button>
        </div>
    </div>
</div>
<div>
    {{'PROFILE.ABSCENTDATES' | translate}}:
    <div class="mx-2">
        <div  *ngFor="let schedule of abscentDates; let j = index">             
            <label for="schedule.start" class="inp mr-2" style="max-width: 180px;">
                <input class="p-0" style="height: 25px;" type="datetime-local" value="schedule.start" name="schedule.start" id="schedule.start" placeholder="&nbsp;" [(ngModel)]="schedule.start">       
            </label>  
            <label for="schedule.end" class="inp mr-2" style="max-width: 180px;">
                <input class="p-0" style="height: 25px;" type="datetime-local" value="schedule.end" name="schedule.end" id="schedule.end" placeholder="&nbsp;" [disabled]="schedule.start == ''" (change)="checkDate()" [(ngModel)]="schedule.end">       
            </label>  
            <!-- <label [ngClass]="{'buttonDisabled': !editSchedule}" class="orangeText" style="text-decoration: underline;" (click)="removeOption(day, j);" ngbTooltip="{{'CONSULTQUESTIONS.ADDOPTION' | translate}}">
                {{'PROFILE.REMOVE' | translate}}
            </label> -->
            <p *ngIf="schedule.end < schedule.start && this.schedule.end != ''">{{warning}}</p>
        </div>
        <button (click)="addDate()" [ngClass]="{'buttonDisabled': !editSchedule}" class="orangeText" style="text-decoration: underline; background-color: transparent; border:transparent" ngbTooltip="{{'CONSULTQUESTIONS.ADDOPTION' | translate}}"> <!-- [disabled]="!day.checked"-->
             {{'PROFILE.ADDDATE' | translate}}
        </button>
    </div>
</div>
<div style="margin-top: 10px; margin-left: -3rem; display: flex; width: 100%; justify-content: center;" *ngIf="editSchedule">
    <button type="button" class="btn btn-info btn-cyan" style="border-radius: .25rem !important; color: white !important;" id="saveProfileBtn" (click)="saveSchedule()" [disabled]="disableBtn">{{'ADDRESS.SAVE' | translate}}</button>
    <button style="border-radius: .25rem !important; margin-left: 10px;" type="button" class="btn btn-light" (click)="editSchedule = false; cancelEdit.emit(false); ngOnInit()">{{'TOPBAR.SWAL.CANCEL' | translate}}</button>
</div>     
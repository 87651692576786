import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-auth-users-doc',
  templateUrl: './auth-users-doc.component.html',
  styleUrls: ['./auth-users-doc.component.scss']
})
export class AuthUsersDocComponent implements OnInit {
  @ViewChild('signaturePad', { read: TemplateRef }) signatureModal:TemplateRef<any>;

  currentUser;
  name = ''
  todaysDate: any = new Date()
  modalRef
  signature
  signatureDate:any = new Date()
  authUsers: any = [{}, {}, {}]
  relationship = ['<en>Mother</en><es>Madre</es>', '<en>Father</en><es>Padre</es>', '<en>Sibling</en><es>Hermano/a</es>', '<en>Friend</en><es>Amigo/a</es>', '<en>Other</en><es>Otro</es>']

  constructor(
    private modalService: NgbModal,
    public authService: AuthenticationService,
    private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {

    window.addEventListener("orientationchange", () => {
      if(this.modalRef && !this.signature){
        this.modalRef.dismiss()
        setTimeout(res => {
            this.sign();
        }, 400)
      }
    });

    this.authUsers.length = 3

    this.authService.profile().then(async (user: any) => {
      this.currentUser = user
      this.name = user.firstName + ' ' + user.lastName1
      this.afs.collection('users').doc(user.uid).collection('authUsers').doc('1').valueChanges().subscribe(users => {
       
        if(users != undefined){
          this.authUsers = users.authUsers
          this.signature = users.signature
          this.signatureDate = users.signatureDate.toDate()
        }
      })
    })
  }

  saveAuthUsers(event){
    if(event.target.value != undefined){
      if(event.target.name == 'relationship' && (event.target.selectedIndex - 1) > -1 ){
        this.authUsers[event.target.id][event.target.name] = this.relationship[event.target.selectedIndex - 1]
      }
      else {
        this.authUsers[event.target.id][event.target.name] = event.target.value
      }
    }
    this.signature = ''
  }

  sign(){
    this.modalRef = this.modalService.open(this.signatureModal, { keyboard: false, size: 'lg', backdrop: 'static', centered: true});
  }

  getAuthSignature(signature){
    this.signature = signature
    this.signatureDate = new Date()
    this.modalRef.dismiss()
    this.modalRef = undefined
  }

  save(){
    this.afs.collection('users').doc(this.currentUser.uid).collection('authUsers').doc('1').set(
      {
        authUsers: this.authUsers,
        signature: this.signature,
        signatureDate: this.signatureDate
      }
    )

    this.modalService.dismissAll()
  }

  disableSave(){
    if(this.signature && this.authUsers[0].name != '' && this.authUsers[0].relationship != '' && this.authUsers[0].phoneNumber != ''
                      && this.authUsers[0].name != undefined && this.authUsers[0].relationship != undefined && this.authUsers[0].phoneNumber != undefined){
      return false
    }
    else {
      return true
    }
  }
}

import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, HostListener, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationService } from 'src/app/core/services/translation.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { co, el } from '@fullcalendar/core/internal-common';
import { TranslateService } from '@ngx-translate/core';
// import { BrowserPrint } from '../../../../assets/js/BrowserPrint-3.1.250.min.js';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import Swal from 'sweetalert2';
import { error } from 'console';

import { ZplConverterService } from './zpl-converter.service';
import { merge } from 'jquery';

import { getAuth } from 'firebase/auth';
const auth = getAuth();

@Component({
  selector: 'app-pharmacy-details',
  templateUrl: './pharmacy-details.component.html',
  styleUrls: ['./pharmacy-details.component.scss']
})
export class PharmacyDetailsComponent {

  consultId: any;

  order: any;

  prescriptionDetails: any;

  orderDetails: any;

  pharmacyDetails: any;

  currentPatientUid: any;

  AddressCity: any;
  AddressCountry: any;
  AddressLine1: any;
  AddressLine2: any;
  AddressState: any;
  AddressType: any;
  AddressZipcode: any;
  AddressID: any;

  PatientGender: any;

  currentOrderID: any;

  allOrderLines: any;

  prescriptionItemsDetails: any;

  prescriptionID: any;

  allPrescriptions: any;

  allOrders: any;

  modalPrescriptionItemsDetails: any;
  @ViewChild("prescriptionItemsDetailsModal") modalPrescriptionItemsDetailsContent: TemplateRef<any>;

  orderId: any;

  base64: any;

  modalPrescriptionPDF: any;
  @ViewChild("prescriptionPDFModal") modalPrescriptionPDFContent: TemplateRef<any>;

  currentOrder: any;

  orderSubscriptionRef: any;

  currentLang = this.translate.store.currentLang;

  BrowserPrint: any;

  data: any;

  currentPatientName: any;

  shippingLabelAvailable: boolean = false;

  loadingShippingLabel: boolean = false;

  modalShippingDetails: any;
  @ViewChild("shippingDetailsModal") modalShippingDetailsContent: TemplateRef<any>;

  mode: boolean = false;

  currentPatientPhone: any;

  modalShippingLabels: any;
  @ViewChild("shippingLabelsModal") modalShippingLabelsContent: TemplateRef<any>;

  allShippingLabels: any;

  shipmentsAvailable: boolean = false;

  pharmacyDataObj: any;

  swal: any;

  showSendLoading: boolean = false;

  shippingLabelExists: boolean = false;

  PatientGenderAbbr: string;

  loadingPrint: boolean = false;

  loadingVoid: boolean = false;

  selectedShippingLabel: any;

  generatingShippingLabel: boolean = false;

  // zplInput: string = '';

  showLabelPreview: boolean = false;

  labelPngUrl: any;

  showImageLoading: boolean = false;

  currentPatientUID: any;

  currentOrderNumber: any;

  loadingCompleteOrderShipment: boolean = false;

  currentUser: any;
  currentUserAccountType: any;
  currentUserUid: any;

  pharmacyAddressCity: any;
  pharmacyAddressCountry: any;
  pharmacyAddressLine1: any;
  pharmacyAddressLine2: any;
  pharmacyAddressState: any;
  pharmacyAddressZipcode: any;
  pharmacyID: any;
  pharmacyEmail: any;
  pharmacyFax: any;
  pharmacyNCPDPID: any;
  pharmacyNPI: any;
  pharmacyName: any;
  pharmacyPhone: any;
  pharmacyDEA: any;

  googleSheetsUrl: string = 'https://docs.google.com/spreadsheets/d/1HUY9VNx9fhAqpPVeuRG_-x1rkZ5kcCAwr3X5D8cFEEU/edit?usp=sharing';

  shippingLabels: any;

  hasShippingLabelUrl: boolean = false;

  loadingCancelOrder: boolean = false;

  orderProcessing: boolean = false;

  showCancelOrderButton: boolean = false;

  constructor(
    private afs: AngularFirestore,
    public translation: TranslationService,
    public translate: TranslateService,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private router: Router,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private zplConverterService: ZplConverterService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone
  ) {

    this.getCurrentUser();

  }

  ngOnInit(): void {

    let id = this.router.url.split("/pharmacy/")[1];
    // console.log("id", id);

    if(id !== undefined){

      setTimeout(() => {

        this.orderId = id;
        // console.log("this.orderId", this.orderId);
        this.orderFromRoute(id);
        this.getShippingLabels(id);

      }, 1000);

    }

  }

  async orderFromRoute(id){

    if(this.orderId === "" || this.orderId === undefined || this.orderId === null){

      this.router.navigate(['/account/404'])

    }
    else {

      this.getOrder(id);
      this.getPrescriptionDetails(id);
      this.getOrderDetails(id);
      this.checkForExistingShippingLabel();

    }

  }

  getOrder(id) {

    let dataArray = [];

    const orderRef = this.afs.firestore.collection('orders').where('id', '==', id).get();

    orderRef.then((querySnapshot) => {

      querySnapshot.forEach((doc) => {

        // console.log("doc", doc.data());

        var data = doc.data();

        dataArray.push(data);

        this.order = doc.data();

        // console.log("this.order", this.order);

        this.currentOrderNumber = this.order.orderNo;

        // console.log("this.currentOrderNumber", this.currentOrderNumber);

        if(this.order.hasOwnProperty('subscriptionRef')){

          for(let i = 0; i < this.order.subscriptionRef.length; i++){

            this.orderSubscriptionRef = this.order.subscriptionRef[i];

            // console.log("this.orderSubscriptionRef", this.orderSubscriptionRef);

          }

        }

      });

      Promise.all([dataArray]).then(() => {

        this.currentOrder = dataArray;

        // console.log("this.currentOrder", this.currentOrder);

        // if(this.currentOrder.length > 0){

        //   if(this.currentOrder[0].consultationID != undefined || this.currentOrder[0].consultationID != null || this.currentOrder[0].consultationID != ""){

        //     this.consultId = this.currentOrder[0].consultationID;

        //   }

        // }

      });

    });

  }

  getPrescriptionDetails(id) {

    let dataArray = [];

    // console.log("id - getPrescriptionDetails()", id);

    const prescriptionRef = this.afs.firestore.collection('prescription').where('orderID', '==', id).get();

    prescriptionRef.then((querySnapshot) => {

      // console.log("querySnapshot.empty - getPrescriptionDetails()", querySnapshot.empty);

      if(querySnapshot.empty){

        // console.log("querySnapshot.empty - getPrescriptionDetails()");

        // console.log("this.consultId - getPrescriptionDetails()", this.consultId);

        if(this.consultId != undefined && this.consultId != null && this.consultId != "" && this.consultId != false){

          this.getPrescriptionsByConsultationID(this.consultId);

        }
        else {

          // console.log("this.orderSubscriptionRef - getPrescriptionDetails()", this.orderSubscriptionRef);

          this.getPrescriptionBySubscription(this.orderSubscriptionRef);

        }

      }
      else {

        querySnapshot.forEach((doc) => {

          // console.log("doc", doc.data());
  
          var data = doc.data();
  
          dataArray.push(data);
  
          this.prescriptionDetails = doc.data();
  
          // console.log("this.prescriptionDetails", this.prescriptionDetails);
  
          this.prescriptionID = this.prescriptionDetails.ID;
  
          this.getPrescriptionItemsDetails(this.prescriptionID);
  
        });
  
        Promise.all([dataArray]).then(() => {
  
          this.allPrescriptions = dataArray;
  
          // console.log("this.allPrescriptions", this.allPrescriptions);
  
          if(this.allPrescriptions.length === 0){
  
            if(this.consultId != undefined && this.consultId != null && this.consultId != ""){
  
              this.getPrescriptionsByConsultationID(this.consultId);
  
            }
  
          }
  
        });

      }

    });

  }

  getOrderDetails(id){

    let dataArray = [];

    const orderRef = this.afs.firestore.collection('orders').where('id', '==', id).get();

    orderRef.then((querySnapshot) => {

      querySnapshot.forEach((doc) => {

        // console.log("doc", doc.data());

        var data = doc.data();

        dataArray.push(data);

        this.orderDetails = doc.data();

        // console.log("this.orderDetails", this.orderDetails);

        this.currentOrderID = this.orderDetails.id;

        // console.log("this.currentOrderID", this.currentOrderID);

        this.currentPatientUid = this.orderDetails.uid;

        // console.log("this.currentPatientUid", this.currentPatientUid);

        this.getPatientUserDetails(this.currentPatientUid);

        // this.getPatientAddressDetails(this.currentPatientUid);

        this.getPatientAddressDetails(id);

        this.getOrderLines(this.currentOrderID);

        if(data.hasOwnProperty('consultationID')){

          this.consultId = data.consultationID;

        }

        this.getCurrentPatientName(this.currentPatientUid);

        // const combinedArray = this.combineObjectsToArray(this.prescriptionDetails, this.orderDetails);
        // console.log("combinedArray", combinedArray);

        // this.pharmacyDetails = combinedArray;

        // console.log("this.pharmacyDetails", this.pharmacyDetails);

      });

      Promise.all([dataArray]).then(() => {

        this.allOrders = dataArray;

        // console.log("this.allOrders", this.allOrders);

      });

    });

  }

  getPatientUserDetails(uid) {

    this.authService.getUserProfile(uid).then((res) => {

      // console.log("res", res);

      if(res.biologicalgender === 'Male'){

        this.PatientGender = res.biologicalgender ? res.biologicalgender : 'Not Specified';

        this.PatientGenderAbbr = "M";

      }
      else if(res.biologicalgender === 'Female'){
          
        this.PatientGender = res.biologicalgender ? res.biologicalgender : 'Not Specified';

        this.PatientGenderAbbr = "F";

      }
      else {
          
        this.PatientGender = res.biologicalgender ? res.biologicalgender : 'Not Specified';

        this.PatientGenderAbbr = "N";

      }

    });

  }

  getPatientAddressDetails(id) {
  // getPatientAddressDetails(uid) {

    //--------------------------------Modify in production--------------------------------

    this.afs.firestore.collection('orders').doc(id).get().then((doc) => {

      var data = doc.data().shippingAddress;

      // console.log("doc", doc.data());

      // console.log("data", data);

      // console.log("doc.data().shippingAddress", doc.data().shippingAddress)

      // console.log("data.AddressCity", data.AddressCity)

      // console.log("data.AddressCountry", data.AddressCountry)

      // console.log("data.AddressLine1", data.AddressLine1)

      // console.log("data.AddressLine2", data.AddressLine2)

      // console.log("data.AddressStateCode", data.AddressStateCode)

      // console.log("data.AddressType", data.AddressType)

      // console.log("data.AddressZipCode", data.AddressZipcode)

      this.AddressCity = data.AddressCity;

      this.AddressCountry = data.AddressCountry;

      this.AddressLine1 = data.AddressLine1;

      this.AddressLine2 = data.AddressLine2;

      this.AddressState = data.AddressStateCode;

      this.AddressType = data.AddressType;

      this.AddressZipcode = data.AddressZipcode;

      this.AddressID = data.id;

    });

    // this.afs.firestore.collection('users').doc(uid).collection('Address').where('AddressType', '==', 'Shipping').get().then((querySnapshot) => {

    //   querySnapshot.forEach((doc) => {

    //     // console.log("doc", doc.data());

    //     this.AddressCity = doc.data().AddressCity;

    //     this.AddressCountry = doc.data().AddressCountry;

    //     this.AddressLine1 = doc.data().AddressLine1;

    //     this.AddressLine2 = doc.data().AddressLine2;

    //     this.AddressState = doc.data().AddressState;

    //     this.AddressType = doc.data().AddressType;

    //     this.AddressZipcode = doc.data().AddressZipcode;

    //     this.AddressID = doc.data().id;

    //   });

    // });

  }

  getOrderLines(orderId) {

    let dataArray = [];

    const orderLinesRef = this.afs.firestore.collection('orders').doc(orderId).collection('orderLines').get();

    orderLinesRef.then((querySnapshot) => {

      querySnapshot.forEach((doc) => {

        // console.log("doc", doc.data());

        var data = doc.data();

        // console.log("data", data);

        dataArray.push(data);

      });

      Promise.all([dataArray]).then(() => {

        this.allOrderLines = dataArray;

        // console.log("this.allOrderLines", this.allOrderLines);

      });

    });

  }

  getPrescriptionItemsDetails(prescriptionid) {

    let dataArray = [];

    const prescriptionItemsRef = this.afs.firestore.collection('prescription').doc(prescriptionid).collection('Items').get();

    prescriptionItemsRef.then((querySnapshot) => {

      querySnapshot.forEach((doc) => {

        // console.log("doc", doc.data());

        var data = doc.data();

        // console.log("data", data);

        dataArray.push(data);

        // this.getPrescriptionProduct(data.parentProductId);

      });

      Promise.all([dataArray]).then(() => {

        this.prescriptionItemsDetails = dataArray;

        // console.log("this.prescriptionItemsDetails", this.prescriptionItemsDetails);

      });

    });

  }

  // combineObjectsToArray(obj1: any, obj2: any): { [key: string]: any }[] {

  //   const combinedArray: { [key: string]: any }[] = [];

  //   // Merge obj1 and obj2 into one object
  //   const combinedObject = { ...obj1, ...obj2 };

  //   // Add the combined object into the array
  //   combinedArray.push(combinedObject);

  //   return combinedArray;

  // }

  goBack() {

    this.router.navigate(['/pharmacy']);
    
  }

  openModal(prescriptionItemsDetailsModal: any) {

    this.modalService.open(prescriptionItemsDetailsModal, { size: 'xl', backdrop: 'static', centered: true });

  }

  openModalPrescriptionPDF(prescriptionPDFModal: any, base64: any) {

    this.base64 = base64;

    this.modalService.open(prescriptionPDFModal, { size: 'xl', backdrop: 'static', centered: true });

  }

  @HostListener('document:contextmenu', ['$event'])
  disableRightClick(event: MouseEvent): void {
    event.preventDefault();
  }

  getPrescriptionsByConsultationID(consultId) {

    let dataArrayByConsultation = [];

    const prescriptionRef = this.afs.firestore.collection('prescription').where('consultID', '==', consultId).get();

    prescriptionRef.then((querySnapshot) => {

      querySnapshot.forEach((doc) => {

        // console.log("doc", doc.data());

        var data = doc.data();

        dataArrayByConsultation.push(data);

        this.prescriptionDetails = doc.data();

        // console.log("this.prescriptionDetails", this.prescriptionDetails);

        this.prescriptionID = this.prescriptionDetails.ID;

        this.getPrescriptionItemsDetails(this.prescriptionID);

      });

      Promise.all([dataArrayByConsultation]).then(() => {

        this.allPrescriptions = dataArrayByConsultation;

        // console.log("this.allPrescriptions", this.allPrescriptions);

      });

    });



  }

  getPrescriptionBySubscription(id){

    // console.log("this.orderSubscriptionRef - getPrescriptionBySubscription()", this.orderSubscriptionRef);

    this.afs.firestore.collection('pharmacyOrderSchedule').doc(id).get().then((doc) => {

      if(doc.exists){

        // console.log("doc.data() - getPrescriptionBySubscription()", doc.data());

        this.prescriptionID = doc.data().prescriptionID;

        // console.log("this.prescriptionID - getPrescriptionBySubscription()", this.prescriptionID);

        this.getPrescriptionDetailsBySubscription(this.prescriptionID);

      }
      else {

        // console.log("No such document!");

        if(this.order.consultationID != undefined || this.order.consultationID != null || this.order.consultationID != ""){

          // console.log("item.consultationID - acquireOrder()", this.order.consultationID);

          this.afs.firestore.collection('orders').doc(this.order.id).collection('orderLines').where('prescriptionID', '!=', null).get().then((querySnapshot) => {

            querySnapshot.forEach((doc) => {
              // Process each document here
              // console.log(doc.id, " => ", doc.data());

              var data = doc.data();

              this.prescriptionID = data.prescriptionID;

              this.getPrescriptionByOrderLine(data.prescriptionID);

            });

          })
          .catch((error) => {
            // console.error("Error getting documents: ", error);

            this.swal = Swal.fire({
              customClass: {
                confirmButton: 'swalPurple',
              },
              title: 'Error',
              text: 'An error has occurred while fetching the data.',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: 'OK',
              allowOutsideClick: false
            });
      
            this.swal.then((result) => {
              if (result.isConfirmed) {
                this.modalService.dismissAll();
              }
            });

          });

        }

      }

    });

  }

  getPrescriptionDetailsBySubscription(id) {

    let dataArray = [];

    // console.log("id - getPrescriptionDetailsBySubscription()", id);

    const prescriptionRef = this.afs.firestore.collection('prescription').where('ID', '==', id).get();

    prescriptionRef.then((querySnapshot) => {

      // console.log("querySnapshot.empty - getPrescriptionDetailsBySubscription()", querySnapshot.empty);

      querySnapshot.forEach((doc) => {

        // console.log("doc", doc.data());

        var data = doc.data();

        dataArray.push(data);

        this.prescriptionDetails = doc.data();

        // console.log("this.prescriptionDetails", this.prescriptionDetails);

        this.prescriptionID = this.prescriptionDetails.ID;

        this.getPrescriptionItemsDetails(this.prescriptionID);

      });

      Promise.all([dataArray]).then(() => {

        this.allPrescriptions = dataArray;

        // console.log("this.allPrescriptions", this.allPrescriptions);

        if(this.allPrescriptions.length === 0){

          if(this.consultId != undefined || this.consultId != null || this.consultId != ""){

            this.getPrescriptionsByConsultationID(this.consultId);

          }

        }

      });

    });

  }

  printZPL(zpl: string) {
    // console.log('Printing ZPL:', zpl);
    // Dynamically create script element for BrowserPrint
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `../../../../assets/js/BrowserPrint-3.1.250.min.js`;

    // Add event listener for script load completion
    script.onload = () => {
        // console.log('BrowserPrint script has been loaded!');
        // After script is loaded, get default printer and send ZPL
        this.getBPPrinterAndSend(zpl);
    };

    // Add error handling for script loading
    script.onerror = (error) => {
      // console.error('Error loading BrowserPrint script:', error);

      this.swal = Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
        },
        title: 'Error',
        text: `Error loading BrowserPrint script:, ${error}`,
        icon: 'error',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        allowOutsideClick: false
      });

      this.swal.then((result) => {
        if (result.isConfirmed) {
          this.modalService.dismissAll();
        }
      });

    };

    // Append script to the document
    document.head.appendChild(script);
  }

  async getBPPrinterAndSend(zpl: string) {
    // console.log('Getting default printer and sending ZPL:', zpl);

    this.fetchZPLContent(zpl);
  }

  //Original Version Starts Here
  async fetchZPLContent(zpl: any) {

    // console.log('Fetching ZPL content:', zpl);

    this.apiService.getData(zpl).subscribe(
      (response: any) => {
        this.data = response;
        // console.log('Response data:', this.data);

        // this.zplInput = this.data.zplContent;
        
        // Check if BrowserPrint object is available
        if ((window as any).BrowserPrint) {
          const BrowserPrint = (window as any).BrowserPrint;
          // Use BrowserPrint to get default printer
          // console.log("BrowserPrint", BrowserPrint);
          BrowserPrint.getDefaultDevice('printer', (printer: any) => {
            // console.log("printer", printer);
              if (printer) {
                  // console.log('Default printer found:', printer);
                  // Send ZPL to printer
                  printer.send(this.data.zplContent, undefined, (errorMessage: any) => {
                    // console.error('Error sending ZPL to printer:', errorMessage);

                    this.swal = Swal.fire({
                      customClass: {
                        confirmButton: 'swalPurple',
                      },
                      title: 'Error',
                      text: `Error sending ZPL to printer: ${errorMessage}`,
                      icon: 'error',
                      showCancelButton: false,
                      showConfirmButton: true,
                      confirmButtonText: 'OK',
                      allowOutsideClick: false
                    });
              
                    this.swal.then((result) => {
                      if (result.isConfirmed) {
                        this.modalService.dismissAll();
                      }
                    });

                  });
              } else {
                // console.error('No printer found.');

                this.swal = Swal.fire({
                  customClass: {
                    confirmButton: 'swalPurple',
                  },
                  title: 'Error',
                  text: 'No printer found.',
                  icon: 'error',
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: 'OK',
                  allowOutsideClick: false
                });
          
                this.swal.then((result) => {
                  if (result.isConfirmed) {
                    this.modalService.dismissAll();
                  }
                });
              }
          }, (error: any) => {
              // console.error('Error getting default printer:', error);
              this.swal = Swal.fire({
                customClass: {
                  confirmButton: 'swalPurple',
                },
                title: 'Error',
                text: `Error getting default printer: ${error}`,
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
                allowOutsideClick: false
              });
        
              this.swal.then((result) => {
                if (result.isConfirmed) {
                  this.modalService.dismissAll();
                }
              });
          });
      } else {
        // console.error('BrowserPrint object not found.');
        this.swal = Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          title: 'Error',
          text: 'BrowserPrint object not found.',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'OK',
          allowOutsideClick: false
        });
  
        this.swal.then((result) => {
          if (result.isConfirmed) {
            this.modalService.dismissAll();
          }
        });
      }

      },
      (error: any) => {
        // console.error('Error fetching data:', error);

        this.swal = Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          title: 'Error',
          text: 'An error has occurred while fetching the data.',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'OK',
          allowOutsideClick: false
        });
  
        this.swal.then((result) => {
          if (result.isConfirmed) {
            this.modalService.dismissAll();
          }
        });

      }
    );

  }

  //New Version Starts Here
  // async fetchZPLContent(zpl: any) {

  //   console.log('Fetching ZPL content:', zpl);

  //   // Check if BrowserPrint object is available
  //   if ((window as any).BrowserPrint) {
  //     const BrowserPrint = (window as any).BrowserPrint;
  //     // Use BrowserPrint to get default printer
  //     console.log("BrowserPrint", BrowserPrint);
  //     BrowserPrint.getDefaultDevice('printer', (printer: any) => {
  //       console.log("printer", printer);
  //         if (printer) {
  //             console.log('Default printer found:', printer);
  //             // Send ZPL to printer
  //             printer.send(zpl, undefined, (errorMessage: any) => {
  //                 console.error('Error sending ZPL to printer:', errorMessage);
  //             });
  //         } else {
  //             console.error('No printer found.');
  //         }
  //     }, (error: any) => {
  //         console.error('Error getting default printer:', error);
  //     });
  //   } 
  //   else {
  //       console.error('BrowserPrint object not found.');
  //   }

  // }

  getCurrentPatientName(uid: string){

    this.afs.firestore.collection('users').doc(uid).get().then((doc) => {

      var data = doc.data();

      //--------------------Uncomment in production--------------------

      this.currentPatientName = data.firstName + ' ' + data.lastName1; //"Test" + " " + "Patient14";

      this.currentPatientPhone = data.phoneNumber; //"8884439387";

      this.currentPatientUID = data.uid;

    });

  }

  generateShippingLabel(rateId: string){

    this.loadingShippingLabel = true;

    // console.log("Generate Shipping Label...");

    // console.log("rateId", rateId);

    this.apiService.generateZPL(rateId).subscribe(
    (response: any) => {
      // console.log('Response data:', response);

      if(response != undefined && response != null && response != ""){

        if(response === "The label that was created successfully."){

          this.loadingShippingLabel = false;

          this.shippingLabelAvailable = true;

        }
        else {

          this.loadingShippingLabel = false;

          this.shippingLabelAvailable = false;

        }

      }
      else {

        this.loadingShippingLabel = false;

        this.shippingLabelAvailable = false;

      }

    }, error => {

      this.swal = Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
        },
        title: 'Error',
        text: 'An error has occurred while generating the shipping label.',
        icon: 'error',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        allowOutsideClick: false
      });

      this.swal.then((result) => {
        if (result.isConfirmed) {
          this.modalService.dismissAll();
        }
      });

    });

  }

  testGenerateShippingLabel(){

    this.loadingShippingLabel = true;

    setTimeout(() => {

      this.loadingShippingLabel = false;

      this.shippingLabelAvailable = true;

    }, 4000);

  }

  openShippingDetailsModal(shippingDetailsModal: any) {

   this.modalService.open(shippingDetailsModal, { size: 'lg', backdrop: 'static', centered: true });

  }

  checkForExistingShippingLabel(){

    // console.log("Check for Existing Shipping Label...");

    // console.log("this.orderId", this.orderId);

    // Access the main collection
    var mainCollectionRef = this.afs.firestore.collection('orders');

    // Access the document in the main collection
    var documentRef = mainCollectionRef.doc(this.orderId);

    // Access the subcollection within the document
    var subcollectionRef = documentRef.collection('shipments').where('status', '==', 'active');

    subcollectionRef.onSnapshot((querySnapshot) => {

      if (querySnapshot.empty) {

        // console.log("No documents found in the subcollection.");

        this.shippingLabelExists = false;
        this.orderProcessing = false;
        this.showCancelOrderButton = true;

      } 
      else {

        querySnapshot.forEach((doc) => {

          // console.log(doc.id, " => ", doc.data());

          this.shippingLabelExists = true;
          this.orderProcessing = true;
          this.showCancelOrderButton = false;

        });

      }

    }, (error) => {

      // console.error("Error fetching documents: ", error);

      this.swal = Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
        },
        title: 'Error',
        text: 'An error has occurred while fetching the data.',
        icon: 'error',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        allowOutsideClick: false
      });

      this.swal.then((result) => {
        if (result.isConfirmed) {
          this.modalService.dismissAll();
        }
      });

    });

    // Query documents in the subcollection
    // subcollectionRef.get().then((querySnapshot) => {

    //   if (querySnapshot.empty) {
    //     console.log("No documents found in the subcollection.");

    //     this.shippingLabelExists = false;
    //   } 
    //   else {
    //     querySnapshot.forEach((doc) => {
    //       console.log(doc.id, " => ", doc.data());
    //       this.shippingLabelExists = true;
    //     });
    //   }

    // })
    // .catch((error) => {
    //   console.log("Error getting documents: ", error);
    // });

  }

  sendShippingDetails(){

    // console.log("Send Shipping Details...");

    this.generatingShippingLabel = true;

    // console.log("package type", (document.getElementById('packageTypeSelect1') as HTMLInputElement).value);

    // console.log("package weight", (document.getElementById('packageWeight1') as HTMLInputElement).value);

    // console.log("label format", (document.getElementById('labelFormatSelect1') as HTMLInputElement).value);

    var addressState = this.AddressState.trim() ? this.AddressState.trim() : "";

    var addressCountry = this.AddressCountry.trim() ? this.AddressCountry.trim() : "";

    // console.log("addressCountry", addressCountry);

    var obj = {
      name: this.currentPatientName.trim() ? this.currentPatientName.trim() : "",
      street1: this.AddressLine1.trim() ? this.AddressLine1.trim() : "",
      street2: this.AddressLine2.trim() ? this.AddressLine2.trim() : "",
      city: this.AddressCity.trim() ? this.AddressCity.trim() : "",
      state: this.AddressState.trim() ? this.AddressState.trim() : "",
      zip: this.AddressZipcode.trim() ? this.AddressZipcode.trim() : "",
      country: "US",
      phone: this.currentPatientPhone.trim() ? this.currentPatientPhone.trim() : "",
      predefined_package: (document.getElementById('packageTypeSelect1') as HTMLInputElement).value,
      weight: (document.getElementById('packageWeight1') as HTMLInputElement).value,
      label_format: (document.getElementById('labelFormatSelect1') as HTMLInputElement).value,
      orderId: this.orderId,
      prescriptionId: this.prescriptionID
    };

    // console.log("obj", obj);

    //---------------------Comment in production---------------------
    // this.shippingLabelAvailable = true;
    // this.modalService.dismissAll();

    //--------------------Uncomment in production--------------------
    this.apiService.sendData(obj).subscribe(
    (response: any) => {

      // console.log('Response data:', response);
  
      if(response != undefined && response != null && response != ""){

        // this.shippingLabelAvailable = true;

        // var resUrl = response.toString();

        // console.log("resUrl", resUrl);

        if(response.label_format === 'PDF'){

          var resUrl = response.label_pdf_url.toString();

          // console.log("resUrl", resUrl);

          this.shippingLabelAvailable = true;

          this.shippingLabelExists = true;

          // this.modalService.dismissAll();

          this.generatingShippingLabel = false;

          this.swal = Swal.fire({
            customClass: {
              confirmButton: 'swalPurple',
            },
            title: 'Success!',
            text: 'Shipping label generated successfully',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: 'OK',
            allowOutsideClick: false
          });

          this.swal.then((result) => {
            if (result.isConfirmed) {

              this.modalService.dismissAll();

            }
          });

          this.cd.detectChanges();

        }
        else if(response.label_format === 'PNG'){

          var resUrl = response.label_url.toString();

          var resTrackingCode = response.tracking_code.toString();

          // console.log("resUrl", resUrl);

          this.shippingLabelAvailable = true;

          this.shippingLabelExists = true;

          // this.modalService.dismissAll();

          this.generatingShippingLabel = false;

          this.swal = Swal.fire({
            customClass: {
              confirmButton: 'swalPurple',
            },
            title: 'Success!',
            text: 'Shipping label generated successfully',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: 'OK',
            allowOutsideClick: false
          });

          this.swal.then((result) => {
            if (result.isConfirmed) {
              
              this.modalService.dismissAll();
              
            }
          });

          this.cd.detectChanges();

          //--------------------Convert PNG to ZPL--------------------
          // var objParams = {
          //   label_url: resUrl,
          //   orderId: this.orderId,
          //   shipmentId: resTrackingCode
          // }

          // this.generateZplFromPng(objParams);

        }
        else if(response.label_format === 'ZPL'){

          this.shippingLabelAvailable = true;

          this.shippingLabelExists = true;

          // this.modalService.dismissAll();

          this.generatingShippingLabel = false;

          this.swal = Swal.fire({
            customClass: {
              confirmButton: 'swalPurple',
            },
            title: 'Success!',
            text: 'Shipping label generated successfully',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: 'OK',
            allowOutsideClick: false
          });

          this.swal.then((result) => {
            if (result.isConfirmed) {

              this.modalService.dismissAll();

            }
          });

          var resUrl = response.label_url.toString(); //New Code: response.label_zpl_url.toString(); - Commented on 2024-06-20

          // console.log("resUrl", resUrl);

          this.printZPL(resUrl);

        }
        // else{

        //   var resUrl = response.label_url.toString();

        //   console.log("resUrl", resUrl);

        //   this.printZPL(resUrl);

        //   this.shippingLabelAvailable = true;

        //   this.shippingLabelExists = true;

        //   this.modalShippingDetails.close();

        //   this.swal = Swal.fire({
        //     customClass: {
        //       confirmButton: 'swalPurple',
        //     },
        //     title: 'Success!',
        //     text: 'Shipping label generated successfully',
        //     icon: 'success',
        //     showCancelButton: false,
        //     showConfirmButton: true,
        //     confirmButtonText: 'OK',
        //     allowOutsideClick: false
        //   });

        //   this.swal.then((result) => {
        //     if (result.isConfirmed) {
              
        //       this.openShippingLabelDetailsModal();

        //     }
        //   });

        // }

      }
      else {

        //--------------------Display error message--------------------

        // this.modalService.dismissAll();

        this.generatingShippingLabel = false;

        this.swal = Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          title: 'Error',
          text: 'An error has occurred.',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'OK',
          allowOutsideClick: false
        });
  
        this.swal.then((result) => {
          if (result.isConfirmed) {
            this.modalService.dismissAll();
          }
        });

      }

    }, error => {

      // console.log("Error: ", error);

      this.generatingShippingLabel = false;

      this.swal = Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
        },
        title: 'Error!',
        text: 'An error has occurred.',
        icon: 'error',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        allowOutsideClick: false
      });

      this.swal.then((result) => {
        if (result.isConfirmed) {
          this.modalService.dismissAll();
        }
      });

    });

  }

  async sendDataToPharmacy(item: any): Promise<void> {

    // console.log("Send Data to Pharmacy...");

    // console.log("item", item);

    this.showSendLoading = true;

    var productIds = [];

    var productDosages = [];

    // const productData = await this.getPrescriptionProduct(item.parentProductId);

    // console.log("productData", productData);

    let dataObj: any;

    // const promises = this.prescriptionItemsDetails.map((item: any) => {

    //   // console.log("item", item);

    //   var productId = item.productID;

    //   var parentProductId = item.parentProductId;

    //   productIds.push(productId);

    //   (async () => {
    //     const data = await this.getPrescriptionProduct(parentProductId);
    //     // console.log("Fetched data:", data);
    //     dataObj = data;
    //   })().then(async() => {
  
    //     // console.log("dataObj", dataObj);

    //     productDosages.push(dataObj["atr1"]);

    //     // await this.processDataItem(i);

    //   });

    //   return this.asyncOperation(item);

    // });

    const promises = this.prescriptionItemsDetails.map(async (item: any) => {
      
      const productId = item.productID;
      const parentProductId = item.parentProductId;
    
      productIds.push(productId);
    
      const data = await this.getPrescriptionProduct(parentProductId);
      productDosages.push(data["productName"]);
      // productDosages.push(data["atr1"]);
    
      return this.asyncOperation(item);

    });

    Promise.all(promises).then((results) => {
      // console.log('All items processed:', results);
      // Do something after all items are processed

      // console.log("results", results);

      var obj = {
        prescriptionId: this.prescriptionID,
        patientGender: this.PatientGenderAbbr,
        dosage: productDosages, //dataObj["atr1"],
        productId: productIds, //productId //item.productID
        acquiredByUID: this.pharmacyID ? this.pharmacyID : ""
      }

      // console.log("productDosages", productDosages);

      // console.log("productIds", productIds);
  
      // console.log("obj", obj);

      this.pharmacyDataObj = obj;

      // console.log("this.pharmacyDataObj", this.pharmacyDataObj);

      //--------------------Uncomment in production--------------------
      this.apiService.sendDataToPharmacy(this.pharmacyDataObj).subscribe((response) => {
      
        // console.log('Response data:', response);

        if(response != undefined && response != null && response != ""){

          if(response.res === "success"){

            this.showSendLoading = false;

            this.swal = Swal.fire({
              customClass: {
                confirmButton: 'swalPurple',
              },
              title: 'Success!',
              text: 'Data sent to pharmacy successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: 'OK',
              allowOutsideClick: false
            });

            this.swal.then((result) => {
              if (result.isConfirmed) {
                this.modalService.dismissAll();
              }
            });

          }
          else if(response.res === "error"){

            this.showSendLoading = false;

            this.swal = Swal.fire({
              customClass: {
                confirmButton: 'swalPurple',
              },
              title: 'Error!',
              text: 'Data not sent to pharmacy',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: 'OK',
              allowOutsideClick: false
            });

            this.swal.then((result) => {
              if (result.isConfirmed) {
                this.modalService.dismissAll();
              }
            });

          }
          else {
              
            this.showSendLoading = false;

            this.swal = Swal.fire({
              customClass: {
                confirmButton: 'swalPurple',
              },
              title: 'Error!',
              text: 'Data not sent to pharmacy',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: 'OK',
              allowOutsideClick: false
            });

            this.swal.then((result) => {
              if (result.isConfirmed) {
                this.modalService.dismissAll();
              }
            });
  
          }

        }

      }, error => {

        this.showSendLoading = false;

        this.swal = Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          title: 'Error!',
          text: 'Data not sent to pharmacy',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'OK',
          allowOutsideClick: false
        });

        this.swal.then((result) => {
          if (result.isConfirmed) {
            this.modalService.dismissAll();
          }
        });

      });

    });


    // //--------------------Uncomment in production--------------------
    // this.apiService.sendDataToPharmacy(this.pharmacyDataObj).subscribe((response: any) => {
    
    //   console.log('Response data:', response);

    // });

    // var obj = {
    //   prescriptionId: this.prescriptionID,
    //   patientGender: this.PatientGender
    // }

    // console.log("obj", obj);

    // // this.apiService.sendDataToPharmacy(obj).subscribe((response: any) => {

    // //   console.log('Response data:', response);

    // // });

  }

  asyncOperation(item: any): Promise<any> {
    return new Promise((resolve) => {
        setTimeout(() => {
            // console.log(`Processing item: ${JSON.stringify(item)}`);
            resolve(item);
        }, 1000);
    });
  }

  async getPrescriptionProduct(id: any) {

    // console.log("getPrescriotionProduct() is running...")

    // console.log("id", id);

    var productId = Number(id);

    try {

      const querySnapshot = await this.afs.firestore.collection('orders').doc(this.currentOrderID).collection('orderLines').where('type', '==', 'product').get();
      
      let data = null;
      
      querySnapshot.forEach((doc) => {
        if (doc.exists) {

          // console.log("Document data:", doc.data()); // Log document data

          data = doc.data();

        }

      });

      return data;

      // const querySnapshot = await this.afs.firestore.collection('products').where('id', '==', productId).get();
      
      // let data = null;
      
      // querySnapshot.forEach((doc) => {
      //   if (doc.exists) {

      //     // console.log("Document data:", doc.data()); // Log document data

      //     data = doc.data();

      //   }

      // });

      // return data;

    }
    catch (error) {

      // console.log("Error getting documents:", error);

      this.swal = Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
        },
        title: 'Error',
        text: 'An error has occurred while fetching the data.',
        icon: 'error',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        allowOutsideClick: false
      });

      this.swal.then((result) => {
        if (result.isConfirmed) {
          this.modalService.dismissAll();
        }
      });

      return null;
      
    }

  }

  openShippingLabelModal(shippingLabelModal: any) {

    this.modalService.open(shippingLabelModal, { size: 'xl', backdrop: 'static', centered: true });

    this.afs.collection('orders').doc(this.orderId).collection('shipments').get().subscribe((querySnapshot) => {

      let dataArray = [];

      if(!querySnapshot.empty){

        querySnapshot.forEach((doc) => {

          // console.log("doc", doc.data());
  
          var data = doc.data();
  
          dataArray.push(data);
  
        });
  
        Promise.all([dataArray]).then(() => {

          this.shipmentsAvailable = true;
  
          this.allShippingLabels = dataArray;
  
          // console.log("this.allShippingLabels", this.allShippingLabels);
  
        });

      }
      else {

        this.allShippingLabels = [];

        this.shipmentsAvailable = false;

      }

    });

  }

  printSelectedShippingLabel(item: any){

    // console.log("Print Selected Shipping Label...");

    // console.log("item", item);

    this.selectedShippingLabel = item.tracking_code;

    this.loadingPrint = true;

    if(item.label_format === 'PDF'){

      const pdfUrl = item.label_pdf_url;
      const printWindow = window.open(pdfUrl, '_blank');
      if (printWindow) {
          printWindow.addEventListener('load', () => {
              printWindow.print();
          }, { once: true });
          this.loadingPrint = false;
      }

    }
    else if(item.label_format === 'PNG'){

      const pngUrl = item.label_url;
      const printWindow = window.open('', '_blank');
      if (printWindow) {
          printWindow.document.write('<html><head><title>Print</title></head><body>');
          printWindow.document.write(`<img src="${pngUrl}" onload="window.print();window.close();" />`);
          printWindow.document.write('</body></html>');
          printWindow.document.close();
          this.loadingPrint = false;
      }
    
    }
    else if(item.label_format === 'ZPL'){

      var zpl = item.label_url; //item.zplCode; //item.label_zpl_url; //item.label_url;

      // console.log("zpl", zpl);

      this.printZPL(zpl);

      this.loadingPrint = false;

    }

  }

  voidShippingLabel(item: any){

    // console.log("Void Shipping Label...");

    // console.log("item", item);

    this.selectedShippingLabel = item.tracking_code;

    this.loadingVoid = true;

    var obj = {
      carrier: item.carrier,
      tracking_code: item.tracking_code,
      orderId: this.orderId
    }

    this.apiService.sendDataToVoid(obj).subscribe(
    (response: any) => {
      // console.log('Response data:', response);

      if(response != undefined && response != null && response != ""){

        if(response.status === "voided"){

          var objUpdate = {
            status: "voided", //refund.status
          }

          // this.afs.firestore.collection("orders").doc(response.orderId).collection("shipments").doc(response.tracking_code).set(objUpdate, { merge: true });

          this.swal = Swal.fire({
            customClass: {
              confirmButton: 'swalPurple',
            },
            title: 'Success!',
            text: 'Shipping label voided successfully',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: 'OK',
            allowOutsideClick: false
          });

          this.swal.then((result) => {
            if (result.isConfirmed) {
              this.modalService.dismissAll();
            }
          });

          // this.openShippingLabelModal(this.modalShippingLabelsContent);

          this.loadingVoid = false;

        }

      }

    }, error => {

      // console.log("Error: ", error);

      this.swal = Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
        },
        title: 'Error!',
        text: 'An error has occurred.',
        icon: 'error',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        allowOutsideClick: false
      });

      this.swal.then((result) => {
        if (result.isConfirmed) {
          this.modalService.dismissAll();
        }
      });

      this.loadingVoid = false;
    
    });

  }

  getPrescriptionByOrderLine(id){

    let dataArrayByOrderLine = [];

    const prescriptionRef = this.afs.firestore.collection('prescription').where('ID', '==', id).get();

    prescriptionRef.then((querySnapshot) => {

      // console.log("querySnapshot.empty - getPrescriptionDetailsBySubscription()", querySnapshot.empty);

      querySnapshot.forEach((doc) => {

        // console.log("doc", doc.data());

        var data = doc.data();

        dataArrayByOrderLine.push(data);

        this.prescriptionDetails = doc.data();

        // console.log("this.prescriptionDetails", this.prescriptionDetails);

        this.prescriptionID = this.prescriptionDetails.ID;

        this.getPrescriptionItemsDetails(this.prescriptionID);

      });

      Promise.all([dataArrayByOrderLine]).then(() => {

        this.allPrescriptions = dataArrayByOrderLine;

        // console.log("this.allPrescriptions", this.allPrescriptions);

      });

    });

  }

  viewLabelPreview(url: string){

    this.showLabelPreview = true;

    this.showImageLoading = true;

    setTimeout(() => {

      this.showImageLoading = false;

      this.labelPngUrl = url;

    }, 2000);

  }

  // renderZPL() {
  //   const canvas = document.getElementById('zplCanvas') as HTMLCanvasElement;
  //   if (!canvas) {
  //     console.error('Canvas element not found');
  //     return;
  //   }

  //   const ctx = canvas.getContext('2d');
  //   ctx.clearRect(0, 0, canvas.width, canvas.height);

  //   if (this.zplInput) {
  //     // Your rendering logic here
  //     ctx.font = '12px Arial';
  //     ctx.fillText(this.zplInput, 10, 20);
  //   }
  // }

  // editShippingDetails(mode: string){

  //   if(mode === 'edit'){

  //     this.mode = true;

  //   }

  // }

  // saveShippingDetails(mode: string){

  //   if(mode === 'save'){

  //     this.mode = false;

  //   }

  // }

  generateZplFromPng(data){

    this.zplConverterService.convertZplToPng(data).subscribe((response2: any) => {
      // console.log('Response data:', response2);

      if(response2 != undefined && response2 != null && response2 != ""){

        var newZplCode = response2.zplCode; //response2.zpl;

        // console.log("newZplCode", newZplCode);

      }

    });

  }

  closeViewShippingLabelModal(){
      
    this.modalService.dismissAll();

    this.showLabelPreview = false;

  }

  confirmOrderShipment(){

    this.shippingLabelExists = false;

    this.loadingCompleteOrderShipment = true;

    // Access the main collection
    var mainCollectionRef = this.afs.firestore.collection('orders');

    // Access the document in the main collection
    var documentRef = mainCollectionRef.doc(this.orderId);

    // Access the subcollection within the document
    var subcollectionRef = documentRef.collection('shipments').where('status', '==', 'active');

    subcollectionRef.onSnapshot((querySnapshot) => {

      if (querySnapshot.empty) {

        // console.log("No documents found in the subcollection.");

        this.loadingCompleteOrderShipment = false;
        this.shippingLabelExists = true;

        this.swal = Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          title: 'Error',
          text: 'No shipping label found. Please create a shipping label before confirming the shipment.',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'OK',
          allowOutsideClick: false
        });
  
        this.swal.then((result) => {
          if (result.isConfirmed) {
            this.modalService.dismissAll();
          }
        });

      } 
      else {

        querySnapshot.forEach((doc) => {

          // console.log(doc.id, " => ", doc.data());

          var data = doc.data();

          var trackingUrl = `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${data.tracking_code}`; //`https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${data.tracking_code}`;

          var obj = {
            trackingUrl: trackingUrl,
            orderState: "done",
            delivery: "Shipped",
            deliveryStatus: "Done",
          }

          this.afs.firestore.collection('orders').doc(this.currentOrderID).set(obj, {merge: true}).then(() => {

            var objParams = {
              uid: this.currentPatientUID,
              tracking_code: data.tracking_code,
              tracking_url: trackingUrl,
              orderId: this.currentOrderID,
              AddressLine1: this.AddressLine1,
              AddressLine2: this.AddressLine2,
              AddressCity: this.AddressCity,
              AddressStateCode: this.AddressState,
              AddressZipCode: this.AddressZipcode,
              AddressCountryCode: "US",
              patientName: this.currentPatientName,
              orderNo: this.currentOrderNumber
            }

            // console.log("objParams", objParams);

            this.apiService.sendShippingConfirmationEmail(objParams).subscribe((response: any) => {

              // console.log('Response data:', response);

              if(response != undefined && response != null && response != ""){

                // console.log("Email sent successfully.");

                if(response.res === "success"){

                  // console.log("Email sent successfully.");

                  this.loadingCompleteOrderShipment = false;
                  this.shippingLabelExists = true;

                  this.swal = Swal.fire({
                    customClass: {
                      confirmButton: 'swalPurple',
                    },
                    title: 'Success!',
                    text: 'Shipment completed successfully.',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: 'OK',
                    allowOutsideClick: false
                  });
        
                  this.swal.then((result) => {
                    if (result.isConfirmed) {
        
                      this.modalService.dismissAll();
        
                    }
                  });

                }
                else {
                    
                  // console.log("Email not sent.");

                  this.loadingCompleteOrderShipment = false;
                  this.shippingLabelExists = true;

                  this.swal = Swal.fire({
                    customClass: {
                      confirmButton: 'swalPurple',
                    },
                    title: 'Error',
                    text: 'An error has occurred. Please try again.',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: 'OK',
                    allowOutsideClick: false
                  });
            
                  this.swal.then((result) => {
                    if (result.isConfirmed) {
                      this.modalService.dismissAll();
                    }
                  });
                }

              }

            });

          }).catch((error) => {

            // console.error("Error updating document: ", error);

            this.loadingCompleteOrderShipment = false;
            this.shippingLabelExists = true;

            this.swal = Swal.fire({
              customClass: {
                confirmButton: 'swalPurple',
              },
              title: 'Error',
              text: 'An error has occurred. Please try again.',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: 'OK',
              allowOutsideClick: false
            });
      
            this.swal.then((result) => {
              if (result.isConfirmed) {
                this.modalService.dismissAll();
              }
            });

          });

        });

      }

    }, (error) => {

      // console.error("Error fetching documents: ", error);

      this.loadingCompleteOrderShipment = false;
      this.shippingLabelExists = true;

      this.swal = Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
        },
        title: 'Error',
        text: 'An error has occurred while fetching the data.',
        icon: 'error',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        allowOutsideClick: false
      });

      this.swal.then((result) => {
        if (result.isConfirmed) {
          this.modalService.dismissAll();
        }
      });

    });

  }

  getItemUrl(item: any){

    return `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${item.tracking_code}`;

  }

  async sendDataToPharmacyIndividualItem(item: any): Promise<void> {

    // console.log("Send Data to Pharmacy...");

    // console.log("item", item);

    this.showSendLoading = true;

    var itemArray = [];

    itemArray.push(item);

    var productIds = [];

    var productDosages = [];

    // const productData = await this.getPrescriptionProduct(item.parentProductId);

    // console.log("productData", productData);

    let dataObj: any;

    // const promises = this.prescriptionItemsDetails.map((item: any) => {

    //   // console.log("item", item);

    //   var productId = item.productID;

    //   var parentProductId = item.parentProductId;

    //   productIds.push(productId);

    //   (async () => {
    //     const data = await this.getPrescriptionProduct(parentProductId);
    //     // console.log("Fetched data:", data);
    //     dataObj = data;
    //   })().then(async() => {
  
    //     // console.log("dataObj", dataObj);

    //     productDosages.push(dataObj["atr1"]);

    //     // await this.processDataItem(i);

    //   });

    //   return this.asyncOperation(item);

    // });

    const promises = itemArray.map(async (item: any) => {
      
      const productId = item.productID;
      const parentProductId = item.parentProductId;
    
      productIds.push(productId);
    
      const data = await this.getPrescriptionProduct(parentProductId);
      productDosages.push(item.productDosage);
      // productDosages.push(data["atr1"]);
    
      return this.asyncOperation(item);

    });

    Promise.all(promises).then((results) => {
      // console.log('All items processed:', results);
      // Do something after all items are processed

      // console.log("results", results);

      var obj = {
        prescriptionId: this.prescriptionID,
        patientGender: this.PatientGenderAbbr,
        dosage: productDosages, //dataObj["atr1"],
        productId: productIds, //productId //item.productID
        acquiredByUID: this.pharmacyID ? this.pharmacyID : ""
      }

      // console.log("productDosages", productDosages);

      // console.log("productIds", productIds);
  
      // console.log("obj", obj);

      this.pharmacyDataObj = obj;

      // console.log("this.pharmacyDataObj", this.pharmacyDataObj);

      //--------------------Uncomment in production--------------------
      this.apiService.sendDataToPharmacy(this.pharmacyDataObj).subscribe((response) => {
      
        // console.log('Response data:', response);

        if(response != undefined && response != null && response != ""){

          if(response.res === "success"){

            this.showSendLoading = false;

            this.swal = Swal.fire({
              customClass: {
                confirmButton: 'swalPurple',
              },
              title: 'Success!',
              text: 'Data sent to pharmacy successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: 'OK',
              allowOutsideClick: false
            });

            this.swal.then((result) => {
              if (result.isConfirmed) {
                this.modalService.dismissAll();
              }
            });

          }
          else if(response.res === "error"){

            this.showSendLoading = false;

            this.swal = Swal.fire({
              customClass: {
                confirmButton: 'swalPurple',
              },
              title: 'Error!',
              text: 'Data not sent to pharmacy',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: 'OK',
              allowOutsideClick: false
            });

            this.swal.then((result) => {
              if (result.isConfirmed) {
                this.modalService.dismissAll();
              }
            });

          }
          else {
              
            this.showSendLoading = false;

            this.swal = Swal.fire({
              customClass: {
                confirmButton: 'swalPurple',
              },
              title: 'Error!',
              text: 'Data not sent to pharmacy',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: 'OK',
              allowOutsideClick: false
            });

            this.swal.then((result) => {
              if (result.isConfirmed) {
                this.modalService.dismissAll();
              }
            });
  
          }

        }

      }, error => {

        this.showSendLoading = false;

        this.swal = Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          title: 'Error!',
          text: 'Data not sent to pharmacy',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'OK',
          allowOutsideClick: false
        });

        this.swal.then((result) => {
          if (result.isConfirmed) {
            this.modalService.dismissAll();
          }
        });

      });

    });


    // //--------------------Uncomment in production--------------------
    // this.apiService.sendDataToPharmacy(this.pharmacyDataObj).subscribe((response: any) => {
    
    //   console.log('Response data:', response);

    // });

    // var obj = {
    //   prescriptionId: this.prescriptionID,
    //   patientGender: this.PatientGender
    // }

    // console.log("obj", obj);

    // // this.apiService.sendDataToPharmacy(obj).subscribe((response: any) => {

    // //   console.log('Response data:', response);

    // // });

  }

  getCurrentUser() {

    auth.onAuthStateChanged(async(user) => {
      if (user) {

        var currentUid = auth.currentUser.uid ? auth.currentUser.uid : "";

        const usersRef = this.afs.firestore.collection("users").where('uid', '==', currentUid);  

        await usersRef.get().then(async (querySnapshot: any) => {

          querySnapshot.forEach(async (doc: any) => {

            var data = doc.data();

            this.currentUser = data;

            // console.log("this.currentUser", this.currentUser);

            this.currentUserAccountType = this.currentUser.accountType;

            // console.log("this.currentUserAccountType", this.currentUserAccountType);

            this.currentUserUid = this.currentUser.uid;

            // console.log("this.currentUserUid", this.currentUserUid);

            if(this.currentUserAccountType === "pharmacist" || this.currentUserAccountType === "admin" || this.currentUserAccountType === "practitioner"){

              this.getPharmacy(this.currentUserUid);

            }

          });

        });

      } else {
      }
    });

  }

  getPharmacy(uid: string){

    this.afs.firestore.collection('pharmacies').where('uid', 'array-contains', uid).get().then((querySnapshot) => {

      querySnapshot.forEach((doc) => {

        // console.log("doc", doc.data());

        var data = doc.data();

        // console.log("data", data);

        this.pharmacyAddressCity = data.cityLocality;
        this.pharmacyAddressCountry = data.countryCode;
        this.pharmacyAddressLine1 = data.addressLine1;
        this.pharmacyAddressLine2 = data.addressLine2;
        this.pharmacyAddressState = data.stateProvince;
        this.pharmacyAddressZipcode = data.postalCode;
        this.pharmacyID = data.id;
        this.pharmacyEmail = data.email;
        this.pharmacyFax = data.fax;
        this.pharmacyNCPDPID = data.ncpdpid;
        this.pharmacyNPI = data.npi;
        this.pharmacyName = data.companyName;
        this.pharmacyPhone = data.phone;
        this.pharmacyDEA = data.deaNumber;

      });

    });

  }

  copyTextToClipboard(data) {
    console.warn('Copying text to clipboard: ', data);
    let textArray = [[
      '',
      '',
      this.currentPatientName,
      data.paymentDate.toDate().toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit"
      }),
      this.prescriptionItemsDetails.length,
      '',
      this.allPrescriptions[0].ID,
      this.allPrescriptions[0].prescriptionURL,
      this.shippingLabels[0]?.label_url || ''
    ]]

    let testString = (data: string[][]): string => {
      return data.map(row => row.join("\t")).join("\n");
    };
    navigator.clipboard.writeText(testString(textArray)).then(() => {
      console.log('Text copied to clipboard', textArray);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }

  copyDateToClipboard(date: Date) {
    const formattedDate = this.formatDate(date);
    navigator.clipboard.writeText(formattedDate).then(() => {
      console.log('Date copied to clipboard: ' + formattedDate);
    }).catch(err => {
      console.error('Could not copy date: ', err);
    });
  }

  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  openLinkInNewWindow(url: string): void {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
        newWindow.focus();
    } 
    else {
        console.error('Failed to open the link. Please check your popup blocker settings.');
    }
  }
  
  getShippingLabels(orderId: string) {
    const shippingRef = this.afs.collection('orders').doc(orderId).collection('shipments', ref => 
      ref.where('status', '==', 'active').limit(1)
    ).valueChanges();
  
    shippingRef.subscribe((dataArray: any[]) => {
      this.ngZone.run(() => {
        if (dataArray.length > 0) {
          this.hasShippingLabelUrl = true;
          this.shippingLabels = dataArray;
        } 
        else {
          this.shippingLabels = [];
        }
        this.cd.detectChanges();
      });
    });
  }

  cancelOrder(){

    this.loadingCancelOrder = true;
    this.showCancelOrderButton = false;

    var id = this.currentOrderID;

    console.log("id", id);

    this.afs.firestore.collection('orders').doc(id).set({paymentStatus: "error"}, { merge: true }).then(() => {

      this.loadingCancelOrder = false;
      this.showCancelOrderButton = true;

      this.swal = Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
        },
        title: 'Success!',
        text: 'Order cancelled successfully',
        icon: 'success',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        allowOutsideClick: false
      });

      this.swal.then((result) => {
        if (result.isConfirmed) {
          this.modalService.dismissAll();
        }
      });

    });

  }
  
  // getShippingLabels(orderId: string){

  //   const shippingRef = this.afs.firestore.collection('orders').doc(orderId).collection('shipments').where('status', '==', 'active').limit(1).get();

  //   shippingRef.then((querySnapshot) => {

  //     let dataArray = [];

  //     if(!querySnapshot.empty){

  //       querySnapshot.forEach((doc) => {

  //         // console.log("doc", doc.data());
  
  //         var data = doc.data();
  
  //         dataArray.push(data);
  
  //       });
  
  //       Promise.all([dataArray]).then(() => {
  
  //         this.shippingLabels = dataArray;
  
  //         // console.log("this.allShippingLabels", this.allShippingLabels);
  
  //       });

  //     }
  //     else {

  //       this.shippingLabels = [];

  //     }

  //   });

  // }

}

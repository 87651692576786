import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Profile } from 'src/app/core/services/interfaces';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit, OnDestroy{

  @Input() profile:Profile
  @Output() phoneNumberEmitter = new EventEmitter<any>();
  @Output() back = new EventEmitter<boolean>();
  @Output() componentLoaded = new EventEmitter<void>();
  @Output() componentUnloaded = new EventEmitter<void>();
  @Output() continueEmitter = new EventEmitter<boolean>();
  @Input() background = 'white';
  @Input() styling = '';

  signupForm: UntypedFormGroup;
  phoneProfile = {};
  linkPhoneNumber:boolean = false;
  phoneNumberMsg: boolean = false;
  phoneNumber = '';

  
  
  constructor(
    private db: AngularFirestore,
    public translate: TranslateService,
    private formBuilder: UntypedFormBuilder ,
  ) { }

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group({
      phoneNumber: [this.profile.phoneNumber],
      phoneNumberMsg: this.profile.phoneNumberMsg,
    }, {
    });
    this.componentLoaded.emit()
    if(!this.profile.phoneNumberMsg && this.profile.phoneNumber){
      this.checkPhoneLength()
    }
  }

  get f() { 
    return this.signupForm.controls; 
  }

  checkPhoneLength(){
    if(this.f.phoneNumber.value.length == 10){
      this.save({target: {value: this.f.phoneNumber.value, name: 'phoneNumber'}})
      this.phoneNumber = this.f.phoneNumber.value;
      this.selectPhone({target: {checked: true}})
    }
    else {
      this.linkPhoneNumber = false;
      let switchEle = <HTMLInputElement>document.getElementById("switch");
      switchEle ? switchEle.checked = false : '';
    }
  }

  selectPhone(event) {
    let switchEle = <HTMLInputElement>document.getElementById("switch");
    if (event.target.checked) {
      this.f.phoneNumberMsg.patchValue(true); 
      this.profile.phoneNumberMsg = true; 
      this.phoneNumberMsg = true;
      switchEle ? switchEle.checked = true : '';
      this.save({target: {value: true, name: 'phoneNumberMsg' }});
      this.linkPhone()
    } 
    else {
      this.db.collection('users').doc(this.profile.uid).update({lastMessageReminder: new Date()})
      switchEle ? switchEle.checked = false : '';
      this.linkPhoneNumber = false;
    } 
  }


  save($event){
    if($event.target.name == 'phoneNumber'){ //have to do this or the value includes the mask that i dont want
      $event.target['phoneNumber'] = this.f.phoneNumber.value
      $event.target.value = this.f.phoneNumber.value
    }
    this.phoneNumberEmitter.emit($event);
  }

  disableCheckbox(item, id){
    let element: any = <HTMLInputElement> document.getElementById(id); 
    if (item == '' || item == null || item == undefined || item.length != 10) { 
      element.disabled = true
      if(element.checked){
        element.checked = false;
        element.value = false;
      }
      return true
    }
    if (item != '' || item != null || item != undefined && item.length == 10) {
      element.disabled = false
      return false
    }
 }



  
  linkPhone(){
    this.phoneProfile = Object.assign(this.profile, this.signupForm.value)
    this.linkPhoneNumber = true;  
  }

  ngOnDestroy(): void {
    this.componentUnloaded.emit()
  }


  formSteps(){
    if(this.f.phoneNumber.invalid || this.f.phoneNumber.value == ''){
      var element = <HTMLInputElement> document.getElementById("nextBtn");
      element.disabled = true;
      return true;
    }
    else{
      var element = <HTMLInputElement> document.getElementById("nextBtn");
      element.disabled = false;
      return false;
    }
  }
}

<div class="container-fluid">

  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="auto-grid mx-aut0">
                <div class="border border-info">
                    <div class="card-body"> 
                        <a href="https://www.dzeus.com/faq#aboutDzeus" target="_blank">
                        <div class="mt-1 maintenance-box">
                            <div class="container mx-auto" style="justify-content: space-between; align-content: space-between;">

                                <div class="avatar-sm item-icon" style="display: flex; align-items: center">
                                    <span>
                                        <img class="mdi mdi-account-edit-outline text-info icon-size" style="width: 60px;" src="../../../assets/images/FAQ_Icons/FAQ_Icon_1.svg">
                                    </span>                           
                                    <h5 class="font-size-20 text-uppercase pl-2">{{'NOTIFICATIONS.FAQ.GETTINGSTARTED' | translate}}</h5>
                                </div>

                                <div class="item-text" >
                                    <p class="text-muted mb-0">{{'NOTIFICATIONS.FAQ.GETTINGSTARTEDTEXT' | translate}}</p>
                                </div>    

                            </div>
                        </div>
                        </a> 
                    </div>
                </div>
           
                <div class="border border-info">
                    <div class="card-body"> 
                    <a href="https://www.dzeus.com/faq#account" target="_blank"> 
                        <div class="mt-1 maintenance-box">
                            <div class="container mx-auto" style="justify-content: space-between; align-content: space-between;">

                            <div class="avatar-sm item-icon" style="display: flex; align-items: center">
                                <span>
                                <img style="width: 60px" class="mdi mdi-account-circle-outline text-info icon-size" src="../../../assets/images/FAQ_Icons/FAQ_Icon_2.svg">
                                </span>                    
                                <h5 class="font-size-20 text-uppercase pl-2">{{'NOTIFICATIONS.FAQ.ACCOUNT' | translate}}</h5>
                            </div>

                            <div class="item-text" >
                            <p class="text-muted mb-0">{{'NOTIFICATIONS.FAQ.ACCOUNTTEXT' | translate}}</p>
                            </div>    

                            </div>
                        </div>
                    </a>
                    </div>
                </div>
        
                <div class="border border-info">
                    <div class="card-body">  
                    <a href="https://www.dzeus.com/faq#telehealth" target="_blank">
                        <div class="mt-1 maintenance-box">
                            <div class="container mx-auto" style="justify-content: space-between; align-content: space-between;">

                                <div class="avatar-sm item-icon" style="display: flex; align-items: center">
                                    <span>
                                    <img style="width: 60px" class="mdi mdi-credit-card-multiple-outline text-info icon-size" src="../../../assets/images/FAQ_Icons/FAQ_Icon_3.svg">
                                    </span>
                                    <h5 class="font-size-20 text-uppercase pl-2">{{'NOTIFICATIONS.FAQ.BILLING' | translate}}</h5>
                                </div>

                                <div class="item-text" >
                                    <p class="text-muted mb-0">{{'NOTIFICATIONS.FAQ.BILLINGTEXT' | translate}}</p>
                                </div>    

                            </div>
                        </div>
                    </a>
                    </div>
                </div>
                <div class="border border-info">
                    <div class="card-body">  
                    <a href="https://www.dzeus.com/faq#shipping" target="_blank">
                        <div class="mt-1 maintenance-box">
                            <div class="container mx-auto" style="justify-content: space-between; align-content: space-between;">

                            <div class="avatar-sm item-icon" style="display: flex; align-items: center">
                                <span>
                                <img style="width: 60px" class="mdi mdi-package-variant-closed text-info icon-size" src="../../../assets/images/FAQ_Icons/FAQ_Icon_4.svg">
                                </span>
                                <h5 class="font-size-20 text-uppercase pl-2">{{'NOTIFICATIONS.FAQ.ORDERS' | translate}}</h5>
                            </div>

                            <div class="item-text" >
                                <p class="text-muted mb-0">{{'NOTIFICATIONS.FAQ.ORDERSTEXT' | translate}}</p>
                            </div>
                            
                            </div>
                        </div>
                    </a>
                    </div>
                </div>
          
                <div class="border border-info">
                    <div class="card-body">  
                    <a href="https://www.dzeus.com/faq#services" target="_blank">
                        <div class="mt-1 maintenance-box">
                            <div class="container mx-auto" style="justify-content: space-between; align-content: space-between;">

                            <div class="avatar-sm item-icon" style="display: flex; align-items: center">
                                <span>
                                <img style="width: 60px" class="mdi mdi-medical-bag text-info icon-size" src="../../../assets/images/FAQ_Icons/FAQ_Icon_5.svg">
                                </span>
                                <h5 class="font-size-20 text-uppercase pl-2">{{'NOTIFICATIONS.FAQ.MEDICATIONS' | translate}}</h5>
                            </div>

                            <div class="item-text" >
                            <p class="text-muted mb-0">{{'NOTIFICATIONS.FAQ.MEDICATIONSTEXT' | translate}}</p>
                            </div>
                            
                            </div>
                        </div>
                    </a>
                    </div>
                </div>
           
                <div class="border border-info">
                    <div class="card-body">  
                    <a href="https://www.dzeus.com/faq#ed" target="_blank">
                        <div class="mt-1 maintenance-box">
                            <div class="container mx-auto" style="justify-content: space-between; align-content: space-between;">

                            <div class="avatar-sm item-icon" style="display: flex; align-items: center">
                                <span>
                                <img style="width: 60px" class="mdi mdi-security text-info icon-size" src="../../../assets/images/FAQ_Icons/FAQ_Icon_6.svg">
                                </span>
                                <h5 class="font-size-20 text-uppercase pl-2">{{'NOTIFICATIONS.FAQ.PRIVACY' | translate}}</h5>
                            </div>

                            <div class="item-text" >
                            <p class="text-muted mb-0">{{'NOTIFICATIONS.FAQ.PRIVACYTEXT' | translate}}</p>
                            </div>
                            
                            </div>
                        </div>
                    </a>
                    </div>
                </div>
          <!-- end row -->
        </div>
      </div>
    </div>
  </div>
</div>
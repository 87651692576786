import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit, OnDestroy {
  @ViewChild('termsModal') termsModal: any;
  @Input() viewTerms = false;
  @Input() profile: any;
  @Input() anon: boolean;
  @Output() getNPI: EventEmitter<any> = new EventEmitter<any>();
  @Output() getConsults: EventEmitter<any> = new EventEmitter<any>();
  @Output() dismiss = new EventEmitter<boolean>();
  termsOfUse: any;
  currentLang = this.translate.store.currentLang ? this.translate.store.currentLang : 'es';
  acceptedTerms = false;
  allSubscriptions = new Subscription();

  modalRef: any;

  constructor(
    private modalService: NgbModal,
    private afs: AngularFirestore,
    private translate: TranslateService,
    private sanitaizer: DomSanitizer

  ) { 
    this.currentLang = this.translate.store.currentLang ? this.translate.store.currentLang : 'es';
  }
  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe()
  }

  ngOnInit(): void {
    this.getTerms()
  }

  openModal = false
  async getTerms(){
    if(this.profile && this.profile.accountType != 'admin'){
      let sub = this.afs.collection("settings").doc("Terms").collection('Terms', ref => ref.where('termsUserType', '==', this.profile.accountType)).valueChanges().subscribe(async data => {
        this.termsOfUse = await [data[data.length -1]]
        if(this.termsOfUse && this.currentLang == 'en'){
          this.termsOfUse.html = await this.sanitaizer.bypassSecurityTrustHtml(this.termsOfUse[0].html_en)
        }
        else if(this.termsOfUse) {
          this.termsOfUse.html = await this.sanitaizer.bypassSecurityTrustHtml(this.termsOfUse[0].html_es)
        }
        if(this.profile && (await this.profile?.terms == undefined 
          || await this.profile?.terms[0]?.acceptedTerms == false 
          || await this.profile?.terms[0]?.version != await this.termsOfUse[0]?.version) && this.openModal == false){
          // this.modalService.open(this.termsModal, {size: 'lg', backdrop: 'static', centered: true})
          this.openModal = true
        }  
      })
      this.allSubscriptions.add(sub)
    }
    else {

      let sub = this.afs.collection("settings").doc("Terms").collection('Terms', ref => ref.where('termsUserType', '==', 'patient')).valueChanges().subscribe(async data => {
        this.termsOfUse = await [data[data.length -1]]
        if(await this.termsOfUse && this.currentLang == 'en'){
          this.termsOfUse.html = await this.sanitaizer.bypassSecurityTrustHtml(this.termsOfUse[0].html_en)
        }
        else if(await this.termsOfUse){
          this.termsOfUse.html = await this.sanitaizer.bypassSecurityTrustHtml(this.termsOfUse[0].html_es)
        }
        // this.modalService.open(this.termsModal, {size: 'lg', backdrop: 'static', centered: true})
        this.openModal = true
      })
      this.allSubscriptions.add(sub)
    }
  }

  /*


  */
  close(){
    this.dismiss.emit(true);
    
    this.openModal = false
    this.getNPI.emit(true);
    this.getConsults.emit(true)
  }

  acceptTerms(event){
    if (event.target.checked) {
      this.acceptedTerms = true;
    }  
    else {
      this.acceptedTerms = false
    }

    let data = [{
      acceptedTerms: this.acceptedTerms,
      acceptedDate: new Date(),
      docLang: this.profile?.preferedLang ? this.profile?.preferedLang: this.currentLang,
      version: this.termsOfUse[0].version
    } ]

    if(this.profile){
      this.afs.collection('users').doc(this.profile.uid).update({terms:data})
    } else {
      this.afs.collection('anonymousUsers').doc(localStorage.getItem('anonymous')).update({terms:data})
    }
  }
}

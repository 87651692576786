import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fbRemoteConfig } from 'src/app/authUtils';
import { TranslationPipe } from 'src/app/translation.pipe';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  settings:any = fbRemoteConfig.defaultConfig;
  supportEmail = '';
  currentLang = this.translate.store.currentLang ? this.translate.store.currentLang : 'es'; 

  constructor(private translationPipe: TranslationPipe, public translate: TranslateService) { }
  
  
  ngOnInit(): void {
    this.supportEmail = this.translationPipe.transform(fbRemoteConfig.defaultConfig.supportEmail, this.currentLang || 'es')
  }
}

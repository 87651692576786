import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConsultationsService {

  public consult = new BehaviorSubject('');
  public term = new BehaviorSubject('');
  public cardId = new BehaviorSubject('');
  public refillTerms = new BehaviorSubject<boolean>(false);

  currentConsult = this.consult.asObservable();
  currentTerm = this.term.asObservable();
  currentCard = this.cardId.asObservable();
  acceptedRefillTerms = this.refillTerms.asObservable();
  private POST_URL = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/createCustomerPaymentIntentStripe"; //"https://us-central1-dzeus-app-telemd.cloudfunctions.net/createCustomerChargeStripe";
  private POST_URL2 = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/getCustomerPaymentMethodsStripe";
  private POST_URL3 = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/erp-onCreateQuotation-onFirebase";


  constructor(
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private httpClient: HttpClient
  ) {
   }

  changeID(id: string) {
    this.consult.next(id);
  }

  // public postCustomerCharge(objData: any){

  //   var charge = {
  //     email: objData.email.toString(),
  //     stripeID: objData.customer.toString(),
  //     source: objData.source.toString(),
  //     amount: objData.amount.toString(),
  //     description: objData.description.toString(),
  //     metadata: objData.metadata.toString()
  //   }

  //   let body = new HttpParams();

  //   body = body.set('email', charge.email);
  //   body = body.set('stripeID', charge.stripeID);
  //   body = body.set('source', charge.source);
  //   body = body.set('amount', charge.amount);
  //   body = body.set('description', charge.description);
  //   body = body.set('metadata', charge.metadata);

  //   // var email = JSON.stringify(emailUser);

  //   console.log("charge", charge)

  //   return this.httpClient.post(this.POST_URL, body); //,this.httpClient.get(this.GET_URL)

  //   // .pipe(
  //   //   map((data: any) => {
  //   //     console.log
  //   //     return data;
  //   //   }), catchError( error => {
  //   //     return throwError( 'Something went wrong!' );
  //   //   })
  //   // )

  // }

  public postCustomerCharge(objData: any){

    var charge = {
      email: objData.email.toString(),
      stripeID: objData.customer.toString(),
      source: objData.source.toString(),
      amount: objData.amount.toString(),
      description: objData.description.toString(),
      metadata: objData.metadata.toString(),
      uid: objData.uid.toString(),
      orderID: objData.orderID.toString()
    }

    let body = new HttpParams();

    body = body.set('email', charge.email);
    body = body.set('stripeID', charge.stripeID);
    body = body.set('source', charge.source);
    body = body.set('amount', charge.amount);
    body = body.set('description', charge.description);
    body = body.set('metadata', charge.metadata);
    body = body.set('uid', charge.uid);
    body = body.set('orderID', charge.orderID);

    return this.httpClient.post(this.POST_URL, body);

  }

  public postRequestPaymentMethods(emailCurrentUser: any, stripeIDCurrentUser: any){

    var currentUser = {
      email: emailCurrentUser.toString(),
      stripeID: stripeIDCurrentUser.toString()
    }

    let body = new HttpParams();

    body = body.set('email', currentUser.email);
    body = body.set('stripeID', currentUser.stripeID);

    // var email = JSON.stringify(emailUser);

    return this.httpClient.post(this.POST_URL2, body); //,this.httpClient.get(this.GET_URL)

  }

  public postCurrentConsultation(consultationID: any){

    const headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    var consultation = {
      id: consultationID.toString(),
    }

    let body = new HttpParams();

    body = body.set('id', consultation.id);

    // var email = JSON.stringify(emailUser);

    return this.httpClient.post(this.POST_URL3, body, { headers: headers, responseType: 'text' }); //,this.httpClient.get(this.GET_URL)

  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  public sendCurrentConsultation(consultationID: any){

    var consultation = {
      id: consultationID.toString(),
    }

    let params = new HttpParams();

    params = params.append('id', consultation.id);

    // var email = JSON.stringify(emailUser);

    return this.httpClient.get(this.POST_URL3, {params: params});//.pipe(catchError(this.handleError)); //,this.httpClient.get(this.GET_URL)

  }

  getConditions(term){
   return this.httpClient.get("https://clinicaltables.nlm.nih.gov/api/conditions/v3/search?terms="+term+'&df=term_icd9_code,primary_name').subscribe(data =>{
   })
  }


  // getNDC(term){
  //   let arr:any[] = [];
  //   let rxcui = [];
  //   let filteredRxcui = [];
  //   console.log("Input changed",term);
  //   this.httpClient.get<any>("https://rxnav.nlm.nih.gov/REST/approximateTerm?term="+term+"&maxEntries=10").subscribe(response =>{
  //     console.log("response",response);
  //     for(let i = 0; i < response.approximateGroup.candidate.length; i++){
  //       console.log("Possible candidates rxcui's",response.approximateGroup.candidate[i].rxcui)
  //       //Remove duplicates rxcui
  //       rxcui.push(response.approximateGroup.candidate[i].rxcui);
  //       filteredRxcui = rxcui.filter(this.onlyUnique);
  //       console.log("Filtered Rxcui's =>", filteredRxcui);
  //       this.httpClient.get<any>("https://rxnav.nlm.nih.gov/REST/rxcui/"+filteredRxcui[i]).subscribe(res =>{
  //         console.log("Rxcui's matches names", res);
  //         if(res.idGroup.name && res.idGroup.rxnormId){
  //           arr.push([res.idGroup.name]);
  //           this.medicines = arr;
  //         }
  //         console.log('arr',arr);
  //       });
  //     }
  //   });
  //   return of(arr).pipe(delay(100));
  // } 
  
  onlyUnique(value, index, self){
    return self.indexOf(value) === index;
  }


  writeTerm(term:string){
    this.term.next(term);
  }

  emitRefillTerms(bool:boolean){
    this.refillTerms.next(bool);
  }

  // public getStripePaymentResponse(){
  //   const headers = new HttpHeaders();
  //   headers.append('Content-Type','application/json');
  //   headers.append('Access-Control-Allow-Origin', '*');
  //   const url = 'https://us-central1-dzeus-app-telemd.cloudfunctions.net/createCustomerChargeStripe'
   
  //    this.httpClient.get(url, { headers: headers, responseType: 'text' }).subscribe(
  //     res => { console.log(res); },
  //     err => { console.log(err); }
  //    )
  //  }

  setCreditCardId(id:string){
    this.cardId.next(id);
  }

  //functions for products section
  public getProductsSearchGroup(condition:any, id:any){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("conditions").doc(condition.Type).collection("productGroup").doc(id.toString()).collection('searchGroup').valueChanges().subscribe((snapshot:any) => {
        let searchGroup = [];
        snapshot.forEach(doc => {
          searchGroup.push(doc);
        });
        resolve(searchGroup);
      });
    });
  }

  // ? Check if latest version of uploaded lab is within the valid time frame
  // ? If not, then suggest new labs 
  getLatestLab(uid: string) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("laboratories", ref => ref.where("uid", "==", uid).orderBy("uploadedAt", "desc").limit(1)).valueChanges().subscribe((snapshot:any) => {
        resolve(snapshot[0]);
      });
    });
  }
  










}

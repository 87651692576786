<div class="container-fluid">
  <app-pagetitle *ngIf="startPage && showConsultDetails == false" [title]="pagetitle"></app-pagetitle>
      
  <div *ngIf="startPage && showConsultDetails == false && userProfile?.accountType == 'patient'" class="row mb-2">
    <div class="col-lg-12">
      <div class="media">
        <div class="media-body mt-3">
          <button type="button" class="btn payBtn btn-info btn-lg bold" (click)="chooseConsultationSwitch();">{{'CONSULTATIONS.SELECTTREATMENTS' | translate }}</button>
          <!-- <hr style="margin: 2px 0; border: none; border-bottom: 2px dotted #e9ebee;" class="mt-3"> -->
        </div>
      </div>
    </div>
  </div>
  
  <!-- INPROGRESS CONSULTS -->
  <div *ngIf="startPage && showConsultDetails == false" class="column" id="inprogress">
    <!-- FOR PATIENTS -->
    <div class="col-lg-12" *ngIf="userProfile?.accountType == 'patient' || patientUid">
      <div class="card-body">
        <!-- <div>
          <h3 class="mb-0 text-center bold" style="font-size: 24px !important;"> {{'CONSULTATIONS.CONSULTS' | translate }}</h3>
          <h4 class="card-title text-center">{{'CONSULTATIONS.INPROGRESS' | translate }}</h4>
        </div> -->
        <div class="d-flex flex-row mt-3" style="justify-content: space-evenly;">
          <a *ngFor="let status of translateStatusPatients; let i = index" [ngClass]="{'filteredConsult': showConsults == consultsStatusPatients[i] && consultFilter == consultFilterArray[i]}" (click)="showConsults = consultsStatusPatients[i]; consultFilter = consultFilterArray[i]">{{status | translate}} <p [ngClass]="{'underline': showConsults == consultsStatusPatients[i] && consultFilter == consultFilterArray[i]}"></p>
          </a>
        </div>
      </div>
      <div class="card" style="box-shadow: 0px 0px 0px !important;">
        <div class="card-body padding-bottom pt-0">
          <div id="Progress-task" class="task-list">
            <div class="dndPlaceholder" dndPlaceholderRef></div>
            <div class="d-flex flex-wrap">
              <div *ngFor="let consult of this[showConsults]" [ngClass]="{'disable-div': !consultFilter.includes(consult.Status)}" class="col-lg-4 mr-3 mb-2">
                <div class="card-body" style="padding: 15px;">
                  <!-- <div>
                    <h5 *ngIf="consult.Status == 'Open'" style="text-align: start;" class="font-size-14 m-0"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} <span class="bold">{{'PRACTITIONER-CONSULTS.OPEN' | translate}}</span></a></h5>
                    <h5 *ngIf="consult.Status == 'Draft'" style="text-align: start;" class="font-size-14 m-0"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} <span class="bold">{{'PRACTITIONER-CONSULTS.DRAFT' | translate}}</span></a></h5>  
                    <h5 *ngIf="consult.Status == 'Completed'" class="font-size-14 m-0" style="text-align: start;"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} <span class="bold">{{'PRACTITIONER-CONSULTS.COMPLETED' | translate}}</span></a></h5>
                    <h5 *ngIf="consult.Status == 'Rejected'" class="font-size-14 m-0" style="text-align: start;"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} <span class="bold">{{'PRACTITIONER-CONSULTS.REJECTED' | translate}}</span></a></h5>
                    <h5 *ngIf="consult.Status == 'Paid'" class="font-size-14 m-0" style="text-align: start;"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} <span class="bold">{{'PRACTITIONER-CONSULTS.PAID' | translate}}</span></a></h5>
                    <h5 *ngIf="consult.Status == 'Denied'" class="font-size-14 m-0" style="text-align: start;"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} <span class="bold">{{'PRACTITIONER-CONSULTS.DENIED' | translate}}</span></a></h5>
                    <h5 *ngIf="consult.Status == 'In Progress'" class="font-size-14 m-0" style="text-align: start;"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} <span class="bold">{{'PRACTITIONER-CONSULTS.INPROGRESS' | translate}}</span></a></h5>
                  </div> -->
                  <div style="text-align: start;" class="font-size-13">
                    {{'CONSULTATIONS.DATE' | translate}} {{consult.Date.toDate() | date:'yyyy-MM-dd h:mma'}}
                  </div>
                  <div class="d-flex" style="justify-content: center;">
                    <div style="width: -webkit-fill-available;" class="d-flex flex-row justify-content-center align-items-center">
                      <!-- <img width="70px" src="{{consult.Photo1}}">  -->
                      <div *ngIf="consult.Status != 'Draft' && consult.Status != 'Open'" class="d-flex flex-row">
                        <img src="{{consult.practitionerImg}}" class="rounded-circle cropped" style="width: 60px; height: fit-content; height: intrinsic;" alt="">
                        <img src="{{consult.Photo1}}" class="rounded-circle cropped smallIcon" alt="">                
                      </div>
                      <div *ngIf="consult.Status == 'Draft' || consult.Status == 'Open'" style="width: -webkit-fill-available;" class="d-flex flex-row">   
                        <img style="width: 60px;" src="{{consult.Photo1}}">                 
                        <h5 class="font-size-18 bold" style="align-self: center; text-align: start;">{{consult.conditionName | translation:currentLang}}</h5>
                      </div>
                      <div *ngIf="consult.Status != 'Draft' && consult.Status != 'Open'" class="team mt-2 text-center w-100">        
                        <h5 class="font-size-18 bold m-0" style="text-align: start; align-self: center;">{{consult.conditionName | translation:currentLang}}</h5>
                        <div class="d-flex flex-wrap flex-column" style="text-align: start;">
                          {{'CONSULTATIONS.PRACTITIONER' | translate}}<br><span class="bold">{{consult.practitionerName}}</span>
                        </div>
                      </div>  
                    </div>
                  </div>                  
                  <div class="continue-consult d-flex flex-row my-2" style="justify-content: start;">
                    <button *ngIf="consult.Status == 'Open'" type="button" class="btn payBtn btn-primary" (click)="editConsult(consult);">{{'SUBSCRIPTIONS.CONTINUE' | translate }}</button>
                    <button *ngIf="consult.Status == 'Draft'" type="button" class="btn payBtn btn-primary" (click)="checkDraftConsult(consult);">{{'SUBSCRIPTIONS.CONTINUE' | translate }}</button>
                    <button *ngIf="consult.currentWizardStep == 'checkout' && consult.Status == 'Paid'" type="button" class="btn payBtn btn-primary" (click)="editConsult(consult);">{{'CONSULTATIONS.VIEW' | translate }}</button>
                    <button *ngIf="consult.Status != 'Open' && consult.Status != 'Draft' && (consult.currentWizardStep != 'checkout' || consult.Status != 'Paid')" type="button" class="btn payBtn btn-primary" (click)="emitPrescriptionTab = 1; consultDetails(consult);">{{'CONSULTATIONS.VIEW' | translate }}</button>
                  </div>
                </div>
              </div>
              <div *ngIf="inprogressConsults.length == 0 && !loadingConsults" class="d-flex w-100 justify-content-center">
                {{'CONSULTATIONS.NOTREATMENTS' | translate}}
              </div>
              <div *ngIf="loadingConsults" class="d-flex flex-row w-100 m-auto justify-content-center align-items-center">
                <div>
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FOR ADMINS -->
    <div class="col-lg-12" *ngIf="userProfile?.accountType == 'admin' && !patientUid">
      <div class="card-body">
        <h3 class="mb-0 text-center bold" style="font-size: 24px !important;"> {{'PRACTITIONER-CONSULTS.ALLCONSULTS' | translate}}</h3>
        <div class="col-sm-6 col-md-6 mx-auto">
          <div id="tickets-table_filter" class="dataTables_filter position-relative">
            <input type="text" name="searchTerm" class="form-control searchbar form-control-sm" 
              aria-controls="tickets-table" placeholder="{{'NOTIFICATIONS.SEARCH' |  translate}}" [(ngModel)]="searchText" (keyup)="filterConsultations(searchText)"/> 
          </div>
        </div>   
        <div class="d-flex mt-3" style="justify-content: space-evenly;">
          <a *ngFor="let status of translateStatus; let i = index" [ngClass]="{'filteredConsult': filterConsults == consultsStatus[i], 'hidden': consultsStatus[i] == 'Draft'}" (click)="filterConsults = consultsStatus[i]; getCurrentFilterStatus(consultsStatus[i]);">{{status | translate}} <p [ngClass]="{'underline': filterConsults == consultsStatus[i]}"></p>
          </a>
        </div>
      </div>
      <div *ngIf="!showLoadingConsults" class="card" style="box-shadow: 0px 0px 0px !important;">
        <div class="card-body padding-bottom pt-0">

          <div id="Progress-task" class="task-list">
            <div class="dndPlaceholder" dndPlaceholderRef></div>
            <div class="d-flex flex-wrap">
              <div *ngFor="let consult of allFilteredConsults | paginate: { itemsPerPage: 10, currentPage: pageConsults, id: 'first'}; let i = index; filter: searchText;" [ngClass]="{'disable-div': filterConsults != 'All' && consult.Status != filterConsults}" class="col-lg-4 mr-3 mb-2">
                <div *ngIf="consult.Status == filterConsults || filterConsults == 'All'" class="card-body" style="padding: 10px;">
                  <div>
                    <div class="d-flex customDropdown" style="justify-content: center;">
                      <div style="width: -webkit-fill-available;">   
                        <img class="w-25 mx-auto" src="{{consult.Photo1}}">                 
                        <h5 class="font-size-18"><a href="javascript: void(0);">{{consult.conditionName | translation:currentLang}}</a></h5>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p class="text-center">{{'CONSULTATIONS.PATIENT' | translate}} {{consult.patientName}}</p>                 
                    <p class="text-center">{{'CONSULTATIONS.DATE' | translate}} {{consult.Date.toDate() | date:'yyyy-MM-dd h:mma'}}</p>                 
                    <h5 *ngIf="consult.Status == 'Open'" class="font-size-18"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} {{'PRACTITIONER-CONSULTS.OPEN' | translate}}</a></h5>
                    <h5 *ngIf="consult.Status == 'Draft'" class="font-size-18"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} {{'PRACTITIONER-CONSULTS.DRAFT' | translate}}</a></h5>
                    <h5 *ngIf="consult.Status == 'Completed'" class="font-size-16"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} {{'PRACTITIONER-CONSULTS.COMPLETED' | translate}}</a></h5>
                    <h5 *ngIf="consult.Status == 'Rejected'" class="font-size-16"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} {{'PRACTITIONER-CONSULTS.REJECTED' | translate}}</a></h5>
                    <h5 *ngIf="consult.Status == 'Paid'" class="font-size-16"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} {{'PRACTITIONER-CONSULTS.PAID' | translate}}</a></h5>
                    <h5 *ngIf="consult.Status == 'Denied'" class="font-size-16"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} {{'PRACTITIONER-CONSULTS.DENIED' | translate}}</a></h5>
                    <h5 *ngIf="consult.Status == 'In Progress'" class="font-size-16"><a href="javascript: void(0);" class="text-dark">{{'CONSULTATIONS.STATUS' | translate}} {{'PRACTITIONER-CONSULTS.INPROGRESS' | translate}}</a></h5>
                    <p class="mb-2"></p>
                  </div>
                  <div class="continue-consult text-center">
                    <button *ngIf="consult.Status == 'Open' || (consult.currentWizardStep == 'chooseTime/Type' && consult.Status == 'Paid')" type="button" class="btn payBtn btn-primary" (click)="editConsult(consult);">{{'CONSULTATIONS.NEXT' | translate }}</button>
                    <button *ngIf="consult.Status == 'Draft'" type="button" class="btn payBtn btn-primary" (click)="editConsult(consult);">{{'CONSULTATIONS.NEXT' | translate }}</button>
                    <button *ngIf="consult.Status != 'Open' && consult.Status != 'Draft'" type="button" class="btn payBtn btn-primary" (click)="emitPrescriptionTab = 1; consultDetails(consult);">{{'CONSULTATIONS.VIEW' | translate }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showLoadingConsults" class="row" style="justify-content: center; align-items: center;">
        <div class="col-lg-12">
            <div class="text-center">
                <span class="spinner-border spinner-border-sm" role="status"></span>
            </div> 
        </div>
      </div>

      <div class="row justify-content-end mt-4">
        <div class="col text-right">
          <pagination-controls (pageChange)="pageConsults = $event" id="first" style="text-align: right;"></pagination-controls>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="showConsultDetails == true" class="">
    <app-consultation-details *ngIf="patientProfile" [customNav]="emitPrescriptionTab" [user]="patientProfile" [consult]="currentConsult" (close)="showConsultDetails = false;"></app-consultation-details>
  </div>
   
  <div *ngIf="chooseConsultation">
    <div class="row justify-content-end">
      <button type="button" class="close mr-4" (click)="close(); startPageSwitch()" aria-hidden="true">×</button>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="mx-auto align-self-center">
            <h1 class="selectConsult bold">{{'CONSULTATIONS.SELECTCONSULT' | translate}}</h1>
          </div>
          <hr style="margin: 2px 0; border: none; border-bottom: 2px dotted #e9ebee;">
        </div>
        <div class="container conditions-container-height">
          <div class="content auto-grid" style="margin-top: 10px;">
            <div class="grid-wrapper grid-col-3" *ngFor="let condition of conditions" [ngClass]="{'disable-div': condition.disabled}">
              <div *ngIf="!condition.disabled" class="selection-wrapper d-flex flex-column justify-content-center align-items-center" (click)="setSelectedCondition(condition)">
                <label for="{{condition.serviceType}}" class="selected-label animate">
                  <input type="radio" class="selects" name="selected-item" id="{{condition.serviceType}}" [checked]="(serviceType === condition.serviceType)" >
                  <span class="icon" style="top: 5% !important;"></span>
                  <div class="selected-content">
                    <img src="{{condition.Photo1 | translation:currentLang}}" alt="">
                    <h2 class="d-flex justify-content-center align-items-center flex-column mb-0">{{condition.Name | translation:currentLang }}</h2>
                  </div>
                </label>
                <ul *ngIf="serviceType === condition.serviceType" class="list-inline wizard justify-content-end">
                  <li *ngIf="claims.devAdmin || claims.testUsers" style="display: flex; justify-content: center; background-color: transparent;" class="next list-inline-item float-right p-0"><button class="btn payBtn btn-primary" (click)="generateConsult();">Test Patient</button></li>
                  <li *ngIf="enableButton == false && creatingConsult == false && onClick == false" style="display: flex; justify-content: center; background-color: transparent;" class="next list-inline-item float-right p-0"><button class="btn payBtn btn-primary" (click)="onClick = true; verifyDraftConsults();" [disabled]="onClick">{{'CONSULTATIONS.START' | translate }}</button></li>
                  <li *ngIf="creatingConsult" class="next list-inline-item float-right p-0" style="display: flex; justify-content: center; background-color: transparent;"><button class="btn payBtn btn-primary" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {{'CONSULTATIONS.CREATING' | translate}}</button></li>
                  <li *ngIf="onClick" class="next list-inline-item float-right p-0" style="display: flex; justify-content: center; background-color: transparent;"><button class="btn payBtn btn-primary" disabled>
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      {{'RELOAD.VERIFYING' | translate}}</button></li>
                  <li *ngIf="enableButton == true" class="next list-inline-item float-right p-0" style="display: flex; justify-content: center; background-color: transparent;"><button class="btn payBtn btn-primary" (click)="this.editConsult(this.currentConsult);">{{'CONSULTATIONS.NEXT' | translate }}</button></li>
                </ul>
                <ul *ngIf="serviceType !== condition.serviceType" [ngClass]="{'disable-div': hide}" class="list-inline wizard justify-content-end">
                  <li style="display: flex; justify-content: center; background-color: transparent;" class="next list-inline-item float-right p-0"><button class="btn" style="color: transparent;">{{'CONSULTATIONS.START' | translate }}</button></li>
                </ul>
              </div>
            </div>
          </div>     
        </div>
      </div>    
    </div> 
  </div>
  
  <!-- Consults Modal -->
  <div *ngIf="consultationQuestions && patientProfile" class="consultationQuestions w-100"> 
    <div>
      <!-- <div class="progress" style="width: 90%; margin: 0 5%;">
        <div id="progress-bar" class="progress-bar bg-info progress-bar-animated"></div>
      </div> -->
      <div class="d-flex customDropdown" style="justify-content: space-between; margin: auto 5%; width: 90%">
        <div style="width: 50px;"></div>
        <!-- <h3 class="cyan text-center infoFont m-0">{{'CONSULTATIONS.CONSULTPROGRESS' | translate}}</h3> -->
        <div class="dropdown d-flex customDropdown details" *ngIf="currentConsult !== {}" style="width: 50px; justify-content: flex-end;" ngbDropdown>
          <!-- <a href="javascript: void(0);" class="dropdown-toggle arrow-none headerDetails" data-toggle="dropdown" aria-expanded="false"
            > -->
            <!-- </a> -->
            <!-- <button type="button" class="close pt-0" style="height: 19.5px; line-height: 0; font-size: 2.15rem; opacity: 1;float: right; position: relative; bottom: -15px;" (click)="close(); startPageSwitch()" aria-hidden="true">×</button> -->
            <button type="button" class="close pt-0" style="height: 19.5px; line-height: 0; font-size: 2.15rem; opacity: 1;" (click)="close(); startPageSwitch()" aria-hidden="true">×</button>
            <button *ngIf="claims.devAdmin" ngbDropdownToggle class="hideBtn my-auto" ngbTooltip="{{'CONSULTATIONS.VIEWDETAILS' | translate}}"><i class="fa fa-info-circle" aria-hidden="true"></i></button>
          <div class="dropdown-menu dropdown-menu-right" style="width: max-content; padding: 10px;" ngbDropdownMenu>
            <span class="m-0" style="font-weight: bolder;color: black;font-size: large; display: flex; justify-content: center;"> <span class="headerText">{{'CONSULTATIONS.CONSULTATION' | translate}}</span> {{currentConsult.conditionName | translation:currentLang}} </span>
            <span class="m-0" style="display: flex; justify-content: center; width: 100%; font-weight: bolder; color: black;"> <span class="headerText">{{'CONSULTATIONS.CONSULTATIONNUM' | translate}}</span> {{currentConsult.ID}} </span>
            <span class="m-0" *ngIf="currentConsult.Date" style="display: flex; justify-content: center; width: 100%; font-weight: bolder; color: black;"> <span class="headerText">{{'CONSULTATIONS.DATE' | translate}}</span>  {{currentConsult?.Date?.toDate() | date:'yyyy-MM-dd h:mm a'}} </span>       
          </div>
        </div>
      </div>  
    </div>

    <aw-wizard #wizard navBarLocation="" [navBarLayout]="'large-empty-symbols'" [disableNavigationBar]="true">
      <!-- Loader -->
      <aw-wizard-step [stepId]="'loader'" awOptionalStep>
        <app-reload [message]="'RELOAD.OPENING'"></app-reload>
      </aw-wizard-step>

      <!-- Preconsult Questions -->
      <aw-wizard-step [stepId]="'preconsultQuestions'" awOptionalStep>
        <div class="animate">
          <app-consultation-questions *ngIf="userProfile && currentWizardStep == 'preconsultQuestions'" [component]="'Consultation'" (percentageAdd)="scrollToTop(); doProgress()" (percentageRemove)="scrollToTop(); removeProgress()" (nextWizardStep)="scoreLimitCheck(); diagnosticsTestsScoreCheck(); consultWizardStep()" (prevWizardStep)="scrollToTop(); removeProgress(); prevConsultWizardStep();" [currentConsult]="currentConsult" [preconsultQuestions]="true" [userProfile]="userProfile" [patientProfile]="patientProfile"></app-consultation-questions>
        </div>
        <!-- BUTTON IN COMPONENT -->
      </aw-wizard-step>

      <!-- Engagement Step -->
      <aw-wizard-step [stepId]="'engagementStep'" awOptionalStep>
        <div class="d-flex flex-column justify-content-center align-items-center animate">
          <app-engagement-step class="w-100" (back)="scrollToTop(); removeProgress(); prevConsultWizardStep()" (next)="scrollToTop(); doProgress(); consultWizardStep()" [consultRules]="conditionRules"></app-engagement-step>
        </div>
      </aw-wizard-step>

      <!-- Gender Step *Optional* -->
      <aw-wizard-step [stepId]="'gender'" awOptionalStep>
        <!-- COMPONENT DOESNT REQUIRE BUTTON -->
        <div class="animate">
          <app-social-gender *ngIf="currentWizardStep == 'gender'" [styling]="'justify-content-start'" (socialGender)="saveEmitter($event)"></app-social-gender>
        </div>
      </aw-wizard-step>

      <!-- Biological Gender *Optional* -->
      <aw-wizard-step [stepId]="'biologicalgender'" awOptionalStep>
        <!-- COMPONENT DOESNT REQUIRE BUTTON -->
        <div class="animate">
          <app-biological-gender *ngIf="currentWizardStep == 'biologicalgender'" [styling]="'justify-content-start'" [socialGenderValue]="patientProfile.gender" (biologicalGender)="saveEmitter($event)"></app-biological-gender>
        </div>
      </aw-wizard-step>

      <!-- Country/State -->
      <aw-wizard-step [stepId]="'chooseState'" class="animate" awOptionalStep>
        <div class="form-group" style="width: 90%; margin: 0 5% !important;">
          <i (click)="scrollToTop(); removeProgress(); prevConsultWizardStep()" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
        </div>

        <app-choose-location *ngIf="patientProfile" style="display: block; min-height: 60vh;" [collectionToUpdate]="'users'" [currentConsult]="currentConsult" [currentUser]="patientProfile" (close)="scrollToTop(); doProgress(); consultWizardStep($event)"></app-choose-location>
      </aw-wizard-step>

      <!-- Birthdate *Optional* -->
      <aw-wizard-step [stepId]="'birthdate'" awOptionalStep>
        <!-- BUTTON IN COMPONENT -->
        <div class="animate">
          <app-birthdate *ngIf="currentWizardStep == 'birthdate'" [styling]="'justify-content-start'" (birthdate)="saveEmitter($event)" [profile]="patientProfile"></app-birthdate>
        </div>
      </aw-wizard-step>

      <!-- Lab Tests *Optional* -->
      <aw-wizard-step [stepId]="'chooseLabTests'" awOptionalStep>
        <app-labs-picker [consult]="currentConsult" (continue)="scrollToTop(); doProgress(); consultWizardStep()" (back)="scrollToTop(); removeProgress(); prevConsultWizardStep()"></app-labs-picker>
      </aw-wizard-step>

      <!-- Choose Lab Location *Optional* -->
      <aw-wizard-step [stepId]="'chooseLabLocation'" awOptionalStep>
        <!-- Code missing for when a patient can choose lab locations -->
        <div class="form-group" style="width: 90%; margin: 0 5% !important;">
          <i (click)="scrollToTop(); removeProgress(); prevConsultWizardStep()" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
        </div>
        <div class="question-list mt-3" style="text-align: center;">
          <h2 class="questionFont animate">{{'CONSULTATIONS.CHOOSELABLOCATION' | translate}}</h2>
          <h5 class="infoFont animate">{{'CONSULTATIONS.CHOOSELABLOCATIONINFO' | translate}}</h5>
        </div>
        <div class="options-cards d-flex flex-column align-items-center">
          <div class="row mb-1 mx-auto">
            <div class="grid-wrapper grid-col-3 d-flex" >
              <div class="selection-wrapper animate__animated animate__fadeInDown" >
                <label id="selectedLab" class="selected-label answers animate" (click)="selectLab({download: true})">
                  <input id="selectedLab" class="selects" type="radio" name="selected-item" [checked]="selectedLaboratory.download">
                  <div class="d-flex justify-content-center flex-row" >
                    <div class="answer-card lab-answer-card">
                      <span class="check-icon" ></span>
                      <div class="text ml-2">
                        <p class="m-0 optionOfQuestions optionFont">{{'CONSULTATIONS.DOWNLOADLAB' | translate}}</p>
                      </div>
                    </div>
                  </div>  
                </label>
              </div>
            </div>
          </div>
        </div>
        <div id="buttons" class="row ml-0 mr-0 mb-2">
          <div class="form-group justify-content-center text-center mt-4 flex-column d-flex" style="margin: 0 auto;">
            <button class="btn btn-info nextbtn text-center mb-2" (click)="scrollToTop(); doProgress(); consultWizardStep();" [disabled]="handleNextButtonState()">{{'PROFILE.AGREE' | translate }}</button>
            <!-- <div style="padding-bottom: 20px;">
              <button style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="scrollToTop(); removeProgress(); prevConsultWizardStep()">{{'PROFILE.BACK' | translate }}</button>
            </div> -->
          </div>
        </div>
      </aw-wizard-step>

      <!-- Choose Product *Optional* -->
      <aw-wizard-step [stepId]="'chooseMedication'" awOptionalStep>
        <app-choose-product-copy *ngIf="currentWizardStep == 'chooseMedication'" [activatePromo]="activatePromo" [currentUserAuth]="user" [consultRules]="conditionRules" [currentPreviousUrlPath]="previousUrlPath" [currentConsult]="currentConsult" (nextAction)="scrollToTop(); doProgress(); consultWizardStep(); scoreLimitCheck()" (backAction)="scrollToTop(); removeProgress(); prevConsultWizardStep();"></app-choose-product-copy>
      </aw-wizard-step> 

      <!-- Non Preconsult Questions -->
      <aw-wizard-step [stepId]="'nonPreconsultQuestions'" awOptionalStep>
        <app-consultation-questions *ngIf="userProfile && currentWizardStep == 'nonPreconsultQuestions'" [component]="'Consultation'" (percentageAdd)="scrollToTop(); doProgress()" (percentageRemove)="scrollToTop(); removeProgress()" (nextWizardStep)="scoreLimitCheck(); diagnosticsTestsScoreCheck(); consultWizardStep()" (prevWizardStep)="prevConsultWizardStep();" [currentConsult]="currentConsult" [preconsultQuestions]="false" [userProfile]="userProfile" [patientProfile]="patientProfile"></app-consultation-questions>
      </aw-wizard-step>

      <!-- Upload Image *Optional* -->
      <aw-wizard-step [stepId]="'uploadImage'" awOptionalStep>
        <div class="form-group" style="width: 90%; margin: 0 5% !important;">
          <i (click)="scrollToTop(); removeProgress(); prevConsultWizardStep()" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
        </div>
        <div class="row animate">
          <div class="col-lg-12">
            <div class="row">
              <div class="mx-auto align-self-center" style="display: flex; flex-direction: column; align-items: center;">
                <h2 class="text-center">{{'CONSULTATIONS.UPLOADPICTURE' | translate}}</h2>
                <h3 class="text-center">{{'CONSULTATIONS.UPLOADPICTURETEXT' | translate}}</h3>
              </div>
            </div>
          </div>
        </div>

        <!-- <div [ngClass]="{ 'disableDiv': userProfile.accountType == 'admin' || (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft')}" class="form-group column justify-content-center animate">  -->
          <div [ngClass]="{ 'disableDiv': (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft')}" class="form-group column justify-content-center animate"> 
          <div>
            <div class="custom-dropzone justify-content-center">
              <div class="dropzone-content image justify-content-center" style="display: flex;">
                <div class="row mt-3 d-flex flex-column align-items-center"> 
                  <div *ngIf="!takePicture" class="d-flex flex-column align-items-center">
                    <div class="d-flex" style="min-height: 250px;">
                      <div class="m-1" *ngFor="let img of currentConsult.uploadedImages">
                        <img src="{{img}}">
                      </div>
                    </div>
                    <button *ngIf="!currentConsult.uploadedImages || (currentConsult.uploadedImages && currentConsult.uploadedImages.length < 3)" (click)="chooseImgEnv('Upload', chooseImageEnvironment); takePicture = true;" class="btn btn-cyan showLaptop mt-2">
                      {{'CONSULTATIONS.TAKEPHOTO' | translate}}
                    </button>
                    <button *ngIf="!currentConsult.uploadedImages || (currentConsult.uploadedImages && currentConsult.uploadedImages.length < 3)" (click)="cropImage(); ratio = 1; takePicture = true;" class="btn btn-cyan showMobile mt-2">
                      {{'CONSULTATIONS.TAKEPHOTO' | translate}}
                    </button>
                    <input type="file" id="file-upload" hidden accept="image/*" capture="environment" #img (change)="fileSelected($event);"> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        

        <div *ngIf="takePicture" class="d-flex align-items-center flex-column" id="app">
          <div><video #video class="justify-content-center cropped" id="video" style="transform: scaleX(-1);" width="250" height="250" autoplay></video></div>
          <div><button class="takePic btn btn-cyan" id="snap" (click)="capture(); closeCam()">{{'CONSULTATIONS.TAKEPICTURE' | translate}}</button></div>
          <canvas #canvas id="canvas" hidden width="250" height="250"></canvas>
          <button class="btn btn-light" (click)="closeCam()">
            {{'CONSULTATIONS.CANCEL' | translate}}
          </button>
          <canvas id="imageCanvasT" hidden style="border:1px solid #d3d3d3; border-radius: 50%;">
          </canvas>

          <canvas id="imageCanvasTT" hidden style="border:1px solid #d3d3d3; border-radius: 50%; ">
          </canvas>
        </div>

        <div class="row">
          <div class="form-group justify-content-center text-center mt-2 flex-column" style="margin: 0 auto;">
            <button style="border-radius: 0.25rem;" class="btn btn-primary nextbtn text-center mb-2" (click)="scrollToTop(); doProgress(); consultWizardStep();" [disabled]="!currentConsult.uploadedImages">{{'PROFILE.NEXT' | translate }}</button>
          </div>
        </div>
      </aw-wizard-step>

       <!-- Phone Number *Optional* -->
      <aw-wizard-step [stepId]="'phoneNumber'" awOptionalStep>
        <div class="animate">
          <app-phone-number *ngIf="currentWizardStep == 'phoneNumber'" (back)="scrollToTop(); removeProgress(); prevConsultWizardStep()" [profile]="patientProfile" (phoneNumberEmitter)="saveEmitter($event)" (continueEmitter)="scrollToTop(); doProgress(); consultWizardStep()"></app-phone-number>
        </div>
      </aw-wizard-step>

      <!-- Choose Practitioner *Optional* -->
      <aw-wizard-step [stepId]="'choosePractitioner'" awOptionalStep>
        <div class="form-group" style="width: 90%; margin: 0 5% !important;">
          <i (click)="scrollToTop(); removeProgress(); prevConsultWizardStep()" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
        </div>
        <h2 class="d-flex justify-content-center text-center animate">{{'CONSULTATIONS.CHOOSEPRACTITIONER' | translate}}</h2>
        <p class="text-center animate">{{'CONSULTATIONS.PRACTITIONERSINFO' | translate}}<br> 
          {{'CONSULTATIONS.PRACTITIONERSINFO1' | translate}}</p>
        <!-- <div [ngClass]="{ 'disableDiv': userProfile.accountType == 'admin' || (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft') }" style="min-height: calc(100vh - 510px);" class="form-group row flex-column m-0 mt-4 align-items-center animate"> -->
        <div [ngClass]="{ 'disableDiv': (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft') }" style="min-height: calc(100vh - 510px);" class="form-group row flex-column m-0 mt-4 align-items-center animate">
          <div class="form-group d-flex flex-column justify-content-center m-0 fade-in">
            <div (click)="practitionerForConsult({uid: 'random'})">
              <label for="random" class="selected-label answers d-flex justify-content-center">
                <input class="selects" type="radio" name="uid" id="random" value="random" [(ngModel)]="currentConsult.practitionerForConsult">
                <div class="d-flex justify-content-center flex-row">
                  <div class="answer-card" style="width: 300px;">
                    <span class="check-icon mr-2"></span>
                    <div class="row text align-items-center" style="width: 250px; flex-wrap: nowrap;">
                      <img style="width: 50px;" class="rounded-circle cropped" src="{{defaultImg}}">
                      <p class="m-0 ml-2 optionOfQuestions optionFont">{{'CONSULTATIONS.RANDOMPRACTITIONER' | translate}}</p>
                    </div>
                  </div>
                </div>               
              </label>
            </div>  
           
            <h4 class="d-flex justify-content-center align-items-center">{{'CONSULTATIONS.OR' | translate}}</h4>
           
            <div *ngFor="let practitioner of myPractitioners; let i = index;" (click)="practitionerForConsult(practitioner)">
              <label for="{{practitioner.uid}}" class="selected-label answers d-flex justify-content-center">
                <input class="selects" type="radio" value="{{practitioner.uid}}" name="uid" id="{{practitioner.uid}}" [(ngModel)]="currentConsult.practitionerForConsult">
                <div class="d-flex justify-content-center flex-row">
                  <div class="answer-card" style="width: 300px;">
                    <span class="check-icon mr-2"></span>
                    <div class="row text align-items-center" style="width: 250px; flex-wrap: nowrap;">
                      <img value="{{practitioner.uid}}" style="width: 50px;" class="rounded-circle cropped" src="{{practitioner.practitionerPhoto}}">
                      <p value="{{practitioner.uid}}" class="m-0 ml-2 optionOfQuestions optionFont">{{practitioner.name}}</p>
                    </div>
                  </div>
                </div>               
              </label>
            </div>      
          </div>    
        </div> 

      <div class="row">
        <div class="form-group justify-content-center text-center mt-2 flex-column" style="margin: 0 auto;">
          <button style="border-radius: 0.25rem;" class="btn btn-primary nextbtn text-center mb-2" (click)="scrollToTop(); doProgress(); consultWizardStep()" [disabled]="handleNextButtonState()">{{'PROFILE.NEXT' | translate }}</button>
          <!-- <div style="padding-bottom: 20px;">
            <button style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="scrollToTop(); removeProgress(); prevConsultWizardStep()">{{'PROFILE.BACK' | translate }}</button>
          </div> -->
        </div>
      </div>
      </aw-wizard-step>

      <!-- Choose Consult *Optional* -->
      <aw-wizard-step [stepId]="'chooseService'" awOptionalStep>
        <div class="form-group" style="width: 90%; margin: 0 5% !important;">
          <i (click)="scrollToTop(); removeProgress(); prevConsultWizardStep()" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="row animate">
              <div class="mx-auto align-self-center">
                <h2 class="titlePadding text-center" style="font-weight: bold;">{{'CONSULTATIONS.CHOOSEOPTION' | translate}}</h2>
              </div>
            </div>

              <div [ngClass]="{ 'disableDiv': (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft')}" class="mt-4 w-100 mx-auto d-flex justify-content-center animate flex-column"> <!-- without flex-column-->

              <div class="d-flex flex-column align-items-center" style="justify-content: space-evenly; " *ngFor="let pack of packages">
                <div *ngFor="let treatment of treatments" class="mb-4 treatmentSize w-100">
                  <div class="d-flex chooseProducts flex-column align-items-center h-100 uncheckedProduct" [ngClass]="{'checkProduct': currentTreatment?.productId == treatment.productId}" style="justify-content:space-between">
                    <label for="{{treatment.productId}}" class="radio-card animate pop w-100" [class.disabled]="(currentTreatment?.productId == treatment.productId)" >
                      <input hidden type="radio" name="radio-card" id="{{treatment.productId}}" [checked]="(currentTreatment?.productId == treatment.productId)" (click)="saveService(treatment)" [disabled]="(currentTreatment?.productId == treatment.productId)"/>               
                      <span class="check-icon mb-2"></span>
                      <div class="w-100 service d-flex flex-row">
                        <div class="packImg">
                          <img class="mx-auto packImg" src="{{treatment.productImg}}">
                        </div>
                        <th class="packName flex-column d-flex ml-2 mt-3 justify-content-end">              
                          <span>{{treatment.productName | translation:currentLang}}</span> 
                          <div class="d-flex flex-row justify-content-between">   
                            <div class="d-flex flex-column align-content-start flex-wrap" [ngClass]="{'disableDiv': currentTreatment?.productId == treatment.productId}">
                              <!-- <input class="packages" type="checkbox" id="{{treatment.productId}}" name="{{treatment.productId}}" [checked]="(currentTreatment?.productId == treatment.productId)" [disabled]="(currentTreatment?.productId == treatment.productId)" hidden/> -->
                              <!-- <label class="switch mx-auto" for="{{treatment.productId}}" [disabled]="(currentTreatment?.productId == treatment.productId)" (click)="saveService(treatment)"></label> -->
                              <!-- <p class="mx-auto" style="text-align: center; color: black; font-size: 10px" *ngIf="currentConsult.hasLabs == false" >{{'CONSULTATIONS.CONSULTONLY' | translate}}</p>
                              <p class="mx-auto" style="text-align: center; color: black; font-size: 10px" *ngIf="currentConsult.hasLabs" >{{'CONSULTATIONS.SELECTLAB' | translate}}</p> -->
                            </div> 
                            <div>
                              <h2 *ngIf="treatment.productPrice[0]" class="mx-auto orangeText" style="text-align: center;">${{treatment.productPrice[0][currentConsult.state.code]}}</h2>
                            </div>
                          </div>
                        </th>
                      </div>
                      <div class="w-100 mt-3">
                        <p *ngFor="let desc of treatment.productDescription" class="d-flex flex-row ml-3">
                          <i class="fa-li fa fa-plus mr-2" style="color: #d1d1d1; position: inherit; width: auto;"></i>
                          <span [innerHTML]="desc | translation:currentLang"></span>
                        </p>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <h4 class="bold orangeText" style="text-align: center;">*{{'CONSULTATIONS.FINALPRICE' | translate}}</h4><br>
              </div>
            </div>
            <div class="row">
              <div class="form-group justify-content-center text-center mt-2 flex-column" style="margin: 0 auto;">
                <button style="border-radius: 0.25rem;" class="btn btn-primary nextbtn text-center mb-2" (click)="scrollToTop(); doProgress(); consultWizardStep();" [disabled]="handleTreatmentRequired()" >{{'PROFILE.NEXT' | translate }}</button>
              </div>
            </div>
          </div>
        </div> 
      </aw-wizard-step>

      <!-- Profile Image/ID -->
      <aw-wizard-step [stepId]="'profileImage'" awOptionalStep>
        <div class="form-group" style="width: 90%; margin: 0 5% !important;">
          <i (click)="scrollToTop(); removeProgress(); prevConsultWizardStep()" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
        </div>
        <div class="row animate">
          <div class="col-lg-12">
            <div class="row">
              <div class="mx-auto align-self-center" style="display: flex; flex-direction: column; align-items: center;">
                <h2 class="text-center" *ngIf="patientProfile && patientProfile.idType == ''">{{'CONSULTATIONS.COMPLETEPROFILE' | translate}}</h2>
                <h2 class="text-center" *ngIf="patientProfile && patientProfile.idType != ''">{{'CONSULTATIONS.VERIFYPROFILE' | translate}}</h2>
                <!-- <h4 class="text-center">{{'CONSULTATIONS.INFO' | translate}}</h4> -->
                <h3 class="text-center">{{'CONSULTATIONS.HIPPA1' | translate}}
                  <div class="dropdown customDropdown details" style="width: 14px;" ngbDropdown>
                    <!-- <a href="javascript: void(0);" class="dropdown-toggle arrow-none headerDetails" data-toggle="dropdown" aria-expanded="false"
                      > -->
                      <button ngbDropdownToggle class="hideBtn m-0 p-0"><i class="fa fa-info-circle" aria-hidden="true"></i></button>
                    <!-- </a> -->
                    <div class="dropdown-menu dropdown-menu-right" style="padding: 10px; min-width: 250px;" ngbDropdownMenu>
                      <span class="m-0" style="font-weight: bolder;color: black;font-size: large;"> 
                        <span class="headerText">{{'CONSULTATIONS.PRIVACYPOLICY' | translate}}</span> <br><br> <span>{{'CONSULTATIONS.PRIVACYPOLICYINFO' | translate}}<a style="text-decoration: underline !important;" (click)="openTerms()">{{'LOGIN.TERMSOFUSE' | translate}}</a>.</span></span>
                    </div>
                  </div>
                </h3>
                <!-- <h4 class="text-center">{{'CONSULTATIONS.HIPPA' | translate}}</h4> -->
              </div>
            </div>
          </div>
        </div>

        <!-- <div *ngIf="!profileImage || hide" [ngClass]="{ 'disableDiv': userProfile.accountType == 'admin' || (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft')}" class="form-group column justify-content-center animate">  -->
          <div *ngIf="!profileImage || hide" [ngClass]="{ 'disableDiv': (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft')}" class="form-group column justify-content-center animate"> 
          <div>

            <div class="custom-dropzone justify-content-center">
              <div class="dropzone-content image justify-content-center" style="display: flex;">
                <div class="row mt-3 d-flex flex-column align-items-center"> 
                  <img *ngIf="currentConsult && currentConsult.image != '' && !hide" class="justify-content-center cropped" src="{{currentImage}}" (click)="profileImage = true; profileId = false; startCam(); ratio = 1"  alt="Header Avatar">
                  <div *ngIf="!hide" style="display: flex; justify-content: center; flex-wrap: wrap; margin-top: -40px; margin-bottom: 30px;">
                    <i *ngIf="currentConsult && currentConsult.image != ''" class="fas fa-camera take-picture" (click)="profileId = false; profileImage = true; startCam(); ratio = 1"></i>
                  </div>
                  <img *ngIf="currentConsult && currentConsult.image != '' && hide" class="justify-content-center cropped" src="{{currentImage}}" (click)="cropImage(); profileImage = true; profileId = false; ratio = 1"  alt="Header Avatar">
                  <div *ngIf="hide" style="display: flex; justify-content: center; flex-wrap: wrap; margin-top: -40px; margin-bottom: 30px;">
                    <i *ngIf="currentConsult && currentConsult.image != ''" class="fas fa-camera take-picture" (click)="cropImage(); profileImage = true; profileId = false; ratio = 1"></i>
                  </div>

                  <div class="align-items-center">
                    <button (click)="chooseImgEnv('Image', chooseImageEnvironment)" class="btn btn-cyan showLaptop">
                      {{'CONSULTATIONS.TAKESELFIE' | translate}}
                    </button>
                    <button (click)="cropImage(); profileImage = true; profileId = false; ratio = 1" class="btn btn-cyan showMobile">
                      {{'CONSULTATIONS.TAKESELFIE' | translate}}
                    </button>
                    <input type="file" id="file-upload" hidden accept="image/*" capture="environment" #img (change)="fileSelected($event); profileImage = true; profileId = false"> 
                  </div>
                  
                  <h4 *ngIf="currentConsult && currentConsult.image == ''" class="justify-content-center ml-4" (click)="profileId = false; profileImage = true; startCam(); ratio = 1">{{'PROFILE.IDINFO3' | translate}}</h4>
                </div>
              </div>
            </div>
            <!-- <div style="color: red; display: flex; justify-content: center !important;" *ngIf="patientProfile && patientProfile.image == defaultImg">{{'PROFILE.VALIDATIONS.IMAGEREQUIRED' | translate}}</div> -->
          </div>
        </div>
        

        <div *ngIf="profileImage && !hide" class="d-flex align-items-center flex-column" id="app">
          <div><video #video class="justify-content-center cropped" id="video" style="transform: scaleX(-1);" width="250" height="250" autoplay></video></div>
          <div><button class="takePic btn btn-cyan" id="snap" (click)="capture(); closeCam()">{{'CONSULTATIONS.TAKEPICTURE' | translate}}</button></div>
          <canvas #canvas id="canvas" hidden width="250" height="250"></canvas>
          <button class="btn btn-light" (click)="profileImage = false; profileId = false; closeCam()">
            {{'CONSULTATIONS.CANCEL' | translate}}
          </button>
          <canvas id="imageCanvasT" hidden style="border:1px solid #d3d3d3; border-radius: 50%;">
          </canvas>

          <canvas id="imageCanvasTT" hidden style="border:1px solid #d3d3d3; border-radius: 50%; ">
          </canvas>
        </div>

        <!-- <div [ngClass]="{ 'disableDiv': userProfile.accountType == 'admin' || (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft')}" class="form-group column justify-content-center animate">  -->
          <div [ngClass]="{ 'disableDiv': (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft')}" class="form-group column justify-content-center animate"> 
          <div *ngIf="patientProfile">

            <!-- <div class="custom-dropzone justify-content-center">
              <div *ngIf="(!profileId || !front) && !hide" class="dropzone-content justify-content-center" style="display: flex;">
                <div class="row" >
                  <img *ngIf="currentConsult && currentConsult.identification != ''" class="justify-content-center id" src="{{currentId}}" (click)="front = true; profileId = true; profileImage = false; startCam(); ratio = 485/300"  alt="Header Avatar">
                  <h4 *ngIf="currentConsult && currentConsult.identification == ''" class="justify-content-center ml-4" (click)="front = true; profileId = true; profileImage = false; startCam(); ratio = 485/300">{{'PROFILE.IDINFO3' | translate}}</h4>
                </div>          
              </div>
              <div *ngIf="(!profileId || !front) && !hide" style="display: flex; justify-content: center; flex-wrap: wrap; margin-top: -40px; margin-bottom: 30px;">
                <i *ngIf="currentConsult && currentConsult.identification != ''" class="fas fa-camera take-picture id" style="padding: 1%;" (click)="front = true; profileId = true; profileImage = false; startCam(); ratio = 485/300"></i>
              </div>

              <div *ngIf="(!profileId || !front) || hide" class="dropzone-content justify-content-center" style="display: flex;">
                <div class="row" >
                  <img *ngIf="currentConsult && currentConsult.identification != '' && hide" class="justify-content-center id" src="{{currentId}}" (click)="cropImage(); profileImage = false; profileId = true; front = true; ratio = 485/300" alt="Header Avatar">
                  <h4 *ngIf="currentConsult && currentConsult.identification == '' && hide" class="justify-content-center ml-4" (click)="cropImage(); profileImage = false; profileId = true; front = true; ratio = 485/300">{{'PROFILE.IDINFO3' | translate}}</h4>
                  <input type="file" id="id-file-upload" hidden accept="image/*" capture="environment" #img (change)="fileSelected($event); profileImage = false; profileId = true; front = true;"> 
                </div>          
              </div>
              <div *ngIf="(!profileId || !front) || hide" style="display: flex; justify-content: center; flex-wrap: wrap; margin-top: -40px; margin-bottom: 30px;">
                <i *ngIf="currentConsult && currentConsult.identification != '' && hide" class="fas fa-camera take-picture id" style="padding: 1%;" (click)="front = true; profileId = true; profileImage = false;"></i>
                <input type="file" id="id-file-upload" hidden accept="image/*" capture="environment" #img (change)="fileSelected($event); profileImage = false; profileId = true; front = true;"> 
              </div>


              <div *ngIf="!profileId || !front || hide" class="d-flex justify-content-center flex-row align-items-center"> 
                <button (click)="chooseImgEnv('ID', chooseImageEnvironment)" class="btn btn-cyan showLaptop">
                  {{'CONSULTATIONS.UPLOADID' | translate}}
                </button>
                <button (click)="cropImage(); profileImage = false; profileId = true; front = true; ratio = 485/300" class="btn btn-cyan showMobile">
                  {{'CONSULTATIONS.UPLOADID' | translate}}
                </button>
                <input type="file" id="id-file-upload" hidden accept="image/*" capture="environment" #img (change)="fileSelected($event); profileImage = false; profileId = true; front = true;"> 
              </div>

              <div *ngIf="profileId && front && !hide" class="d-flex align-items-center flex-column" id="app">
                <div><video #video class="justify-content-center" id="video" width="325" height="250" autoplay></video></div>
                <div><button class="takePic btn btn-cyan" id="snap" (click)="capture(); closeCam(); front = true">{{'CONSULTATIONS.TAKEPICTURE' | translate}}</button></div>
                <canvas #canvasID id="canvasID" hidden width="325" height="250"></canvas>
                <button class="btn btn-light" (click)="profileId = false; closeCam()">
                  {{'CONSULTATIONS.CANCEL' | translate}}
                </button>
              </div>
            </div> -->
            <!-- <div style="color: red; display: flex; justify-content: center !important;" *ngIf="patientProfile && (patientProfile.identification.startsWith('../assets/images')) -->
            <!-- ">{{'PROFILE.VALIDATIONS.IDREQUIRED' | translate}}</div>  --> <!-- || patientProfile.identificationBack == defaultIDImgBack) -->
            <!-- <div style="display: flex; flex-direction: column; align-items: center; margin-top: 2%;">
              <div class="leftOptions d-flex justify-content-center flex-row ml-0">
                <div class="col-md-12">
                  <span class="animate" style="display: flex; margin-bottom: 0;">{{'PROFILE.IDNUMBER' | translate}}</span>
                  <label for="idNumber" class="inp input animate" style="max-width: 100%;">                        
                      <input class="p-0" style="height: 38px; padding-left: 5% !important;" type="text" name="idNumber" id="idNumber" placeholder="&nbsp;" [(ngModel)]="patientProfile.idNumber">    
                  </label>
                </div>
                <div class="col-md-12" style="align-self: self-end;">
                  <span class="animate" style="display: flex; margin-bottom: 0;">{{'PROFILE.EXPDATE' | translate}}</span>
                  <label for="idExpDate" class="inp input animate" style="max-width: 100%;">
                      <input (change)="verifyExpDate($event.target.value)" class="p-0" style="height: 38px; padding-left: 5% !important; padding-right: 5% !important;" type="date" name="idExpDate" id="idExpDate" placeholder="&nbsp;" [(ngModel)]="patientProfile.idExpDate">       
                  </label>
                </div>
              </div>
              <div *ngIf="idExpired"><p style="color: red;"> {{'PROFILE.VALIDATIONS.IDEXPIRED' | translate}}</p></div>
              <div class="rightOptions d-flex justify-content-center flex-row">
                <div class="col-md-12 selectID">
                  <span class="animate" style="display: flex; margin-bottom: 0; margin-top: 0;">{{'PROFILE.COUNTRYISSUED' | translate}}</span>
                  <ng-select class="custom select mt-0 customSelect" style="border-radius: 5px !important;" [items]="idCountries" [clearable]="false" bindLabel="name" bindValue="name" (change)="getIdStates($event);patientProfile.idStateIssued = ''" placeholder="{{'PROFILE.COUNTRYISSUED' | translate}}" name="idCountryIssued" [(ngModel)]="patientProfile.idCountryIssued"></ng-select>    
                </div>
                <div *ngIf="idStates.length != 0" class="col-md-12">
                  <span class="animate" style="display: flex; margin-bottom: 0; margin-top: 0;">{{'PROFILE.STATEISSUED' | translate}}</span>
                  <ng-select class="custom select mt-0 customSelect" style="border-radius: 5px !important;" [items]="idStates"[clearable]="false" bindLabel="code" bindValue="code"  placeholder="{{'PROFILE.STATEISSUED' | translate}}" name="idStateIssued" [(ngModel)]="patientProfile.idStateIssued"></ng-select>    
                </div>  
              </div>
            </div> -->
          </div>
        </div>

        <div class="row">
          <div class="form-group justify-content-center text-center mt-2 flex-column" style="margin: 0 auto;">
            <button *ngIf="currentWizardStep == 'profileImage'" style="border-radius: 0.25rem;" class="btn btn-primary nextbtn text-center mb-2" (click)="scrollToTop(); doProgress(); consultWizardStep(); submitId()" id="submitId" [disabled]="invalidId()">{{'PROFILE.NEXT' | translate }}</button>
            <!-- <div style="padding-bottom: 20px;">
              <button style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="scrollToTop(); removeProgress(); prevConsultWizardStep()">{{'PROFILE.BACK' | translate }}</button>
            </div> -->
          </div>
        </div>
      </aw-wizard-step>

      <!-- Checkout -->
      <aw-wizard-step [stepId]="'checkout'" awOptionalStep> <!-- (stepEnter)="validateSignature()"-->
        <div class="form-group" style="width: 90%; margin: 0 5% !important;">
          <i (click)="scrollToTop(); removeProgress(); prevConsultWizardStep()" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
        </div>
        <div class="row" *ngIf="showPaymentIntentError">
          <div class="col-lg-12 mx-auto align-self-center">
            <div class="alert alert-danger mt-4 mb-4 mr-4 ml-4" role="alert">
              {{paymentIntentErrorMsg}}
            </div>
          </div>
        </div>
        <!-- <div [ngClass]="{ 'disableDiv': userProfile.accountType == 'admin' || (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft')}" class="row checkout-container justify-content-center"> -->
        <div [ngClass]="{ 'disableDiv': (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft')}" class="row checkout-container justify-content-center">
          <app-checkout *ngIf="currentWizardStep == 'checkout' || currentWizardStep == 'profileImage'" [activatePromo]="activatePromo" [conditionRules]="conditionRules" [consult]="this.currentConsult" [consultRules]="conditionRules" [patientProfile]="patientProfile" (order)="getOrderFromCheckout($event)"  [orderConfirmed]="orderConfirmed" [validateAddress]="validateAddress" (validateAddressState)="validateAddressState = $event" (validateCardState)="validateCardState = $event" [validateCard]="validateCard"></app-checkout>
        </div>
        <div class="row" style="justify-content: center; padding: 0 5% 2%;">
          <div class="form-group text-center mt-2 col-lg-6">
            <!-- <button *ngIf="paymentIntentSuccess == false && chargeProcessingStripe == false && paymentIntentError == false" id="holdBtn" style="border-radius: 0.25rem;" [ngClass]="{ 'disableDiv': userProfile.accountType == 'admin' || (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft')}" class="btn btn-info nextbtn text-center mb-2" (click)="verifyAddressAndCard()" [disabled]="validateAddress || validateCard || order?.orderState == 'create'">{{'CONSULTATIONS.PAY' | translate }}&nbsp;<span id="spinner-pay" style="border-width: 0.3rem;" role="status" aria-hidden="true"></span></button> -->
            <button *ngIf="paymentIntentSuccess == false && chargeProcessingStripe == false && paymentIntentError == false" id="holdBtn" style="border-radius: 0.25rem;" [ngClass]="{ 'disableDiv': (currentConsult.Status != 'Open' && currentConsult.Status != 'Draft')}" class="btn btn-info nextbtn text-center mb-2" (click)="verifyAddressAndCard()" [disabled]="validateAddress || validateCard || order?.orderState == 'create'">{{'CONSULTATIONS.PAY' | translate }}&nbsp;<span id="spinner-pay" style="border-width: 0.3rem;" role="status" aria-hidden="true"></span></button>
            <button *ngIf="chargeProcessingStripe && paymentIntentSuccess == false" style="border-radius: 0.25rem;" class="btn btn-info nextbtn text-center mb-4" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{'CONSULTATIONS.PROCESSING' | translate }}</button>
            <button *ngIf="paymentIntentError" style="border-radius: 0.25rem;" class="btn btn-danger nextbtn text-center mb-4" disabled><i class="mdi mdi-close-circle-outline mr-1 font-size-14"></i>
              {{'CONSULTATIONS.FAILED' | translate }}</button>
            <!-- <div style="padding-bottom: 20px;">
              <button *ngIf="!paymentIntentSuccess || currentConsult.Status == 'Paid' || currentConsult.Status == 'In Progress' || currentConsult.Status == 'Completed'" style="border-radius: 0.25rem;" class="btn btn-light nextbtn text-center mb-4" (click)="scrollToTop(); removeProgress(); prevConsultWizardStep()">{{'PROFILE.BACK' | translate }}</button>
            </div> -->
          </div>
          
        </div>   
        
        <!-- <div>
          <div class="floating-icon" title="Need help?">
            <i class="ri-customer-service-line icon-color"></i>
          </div>
      
          <div class="tooltip">
              Need help? Chat with us!
          </div>
        </div> -->
      </aw-wizard-step>

      <!-- Choose Time/Type -->
      <aw-wizard-step [stepId]="'chooseTime/Type'" awOptionalStep>
        <h2 class="d-flex justify-content-center animate">{{'CONSULTATIONS.HOWTOCONTACT' | translate}}</h2>
        <p class="text-center animate">{{'CONSULTATIONS.CONTACTINFO' | translate}}</p>
        <div class="text-center animate">
          <h3 class="fade-in text-center">{{'CONSULTATIONS.CHOOSETYPE' | translate}}</h3>
          <div class="d-flex justify-content-center align-items-center btn-group btn-group-toggle mt-3 w-100 flex-row flex-wrap" data-toggle="buttons">
            <label *ngFor="let item of typeCopy; let i = index;" for="{{item.text}}" class="selected-label flex-column BPLabel rounded ml-2" style="height: -webkit-fill-available; height: -webkit-fit-content; align-self: self-end;">
              <div class="w-100 d-flex justify-content-end align-items-end">
                <p class="recommended" [ngClass]="{'disable-div': item.recommended != true}">{{'CONSULTATIONS.RECOMMENDED' | translate}}</p>
              </div>
              <input hidden type="checkbox" tabindex="0" name="systolic" id="{{item.text}}" value="{{item.text}}" [ngModel]="checkType(item.text)" (click)="saveSchedule('', $event)">
              <p class="BPBorders mb-0" [ngClass]="{'activeBP': checkedType.includes(item.text)}" >{{typeCopy[i].text | translation:currentLang}}</p>
            </label>
          </div>
          <br>
          <hr>
          <br>
          <h3 class="fade-in text-center">{{'CONSULTATIONS.CHOOSETIME' | translate}}</h3>
          <div class="d-flex justify-content-center align-items-center btn-group btn-group-toggle mt-3 w-100 flex-row flex-wrap" data-toggle="buttons">
            <label *ngFor="let item of timeCopy; let i = index;" for="{{item}}" class="selected-label BPLabel rounded ml-2">
              <input hidden type="checkbox" tabindex="0" name="systolic" id="{{item}}" value="{{item}}" [ngModel]="checkTime(item)" (click)="saveSchedule($event)">
              <p class="BPBorders mb-0" [ngClass]="{'activeBP': checkedTime.includes(item)}" >{{timeCopy[i] | translation:currentLang}}</p>
            </label>
          </div>
        </div>
        <div class="d-flex justify-content-center w-100">
          <p class="d-flex justify-content-center">{{'CONSULTATIONS.WARN' | translate}}</p>            
        </div>

        <div class="row">
          <div class="form-group justify-content-center text-center mt-2 flex-column" style="margin: 0 auto;">
            <button style="border-radius: 0.25rem;" class="btn btn-primary nextbtn text-center mb-2" (click)="finishedConsult()" id="submitImage" [disabled]="handleNextButtonState()">{{'PROFILE.FINISH' | translate }}</button>
          </div>
        </div>
      </aw-wizard-step>

      <!-- Schedule Meet -->
      <aw-wizard-step [stepId]="13" class="confirmation">
        <div *ngIf="currentConsult.practitionerForConsult != 'random' && currentConsult.practitionerForConsult != '' && currentWizardStep == 'chooseTime/Type' || currentWizardStep == '13'">
          <app-appointments-scheduler [consult]="currentConsult" [appointment]="appointment" (finishConsult)="finishedConsult()"></app-appointments-scheduler>
        </div>
      </aw-wizard-step>
    </aw-wizard>
  </div> 
</div>
  
<ng-template #termsModal let-modal style="height: 500px;">
  <app-terms-of-use [profile]="userProfile" (getConsults)="getTerms()" [viewTerms]="viewTerms" (dismiss)="modal.close(); viewTerms = false;"></app-terms-of-use>
</ng-template>

<div *ngIf="loadingImg == true">
  <app-reload></app-reload>
</div>

<ng-template #reevaluate let-modal>
  <app-reevaluation [consult]="currentConsult" [defaultType]="'Reevaluation'" (close)="dismiss()"></app-reevaluation>
</ng-template>

<ng-template #signaturePad let-modal>
  <app-signaturepad (signature)="getSignature($event)"></app-signaturepad>
</ng-template>

<ng-template #addressModal let-modal>
  <!-- <div class="justify-content-end">
    <button type="button" class="close float-right modal-close" (click)="dismiss()" aria-hidden="true">×</button>
  </div> -->
  <app-add-address *ngIf="currentConsult.ID" [consultID]="currentConsult.ID" [consultShipping]="'Shipping'" [bool]="edit" (dismiss)="dismiss()"></app-add-address>
</ng-template>

<ng-template #rejectedPDFModal let-modal>
  <app-pdf-rejected [consult]="currentConsult"></app-pdf-rejected>
</ng-template>

<ng-template #imageCropper let-modal style="height: 500px;">
  <input hidden type="file" id="imageInput"  accept="image/*" (change)="fileChangeEvent($event)" />
  
  <!-- <button type="button" class="close float-right modal-close d-flex justify-content-end" (click)="profileImage = false; profileId = false; imageChangedEvent = ''; croppedImage = ''; dismiss()" aria-hidden="true">×</button> -->

  <div class="d-flex justify-content-center align-items-center flex-column">
    <div *ngIf="croppedImage">
      <div class="btn-toolbar p-3" style="flex-wrap: nowrap;" role="toolbar">
        <div class="btn-group mb-sm-0 mx-2 mb-4">    
          <button class="btn btn-info btn-sm btn-cyan cardBtn d-flex align-items-center flex-row" style="width: fit-content; border-radius: 4px 0 0 4px !important;" (click)="rotateRight()">{{'PROFILE.ROTATE' | translate}} <i class="mdi mdi-rotate-right"></i></button>
          <hr class="m-0 h-auto" style="border: 1px solid #2a72ca">
          <button class="btn btn-info btn-sm btn-cyan cardBtn d-flex align-items-center flex-row" style="width: fit-content; border-radius: 0 4px 4px 0 !important;" (click)="flipHorizontal()">{{'PROFILE.FLIP' | translate}} <i class="mdi mdi-flip-horizontal"></i></button>
        </div>
      </div>
    </div>  
  
    <div>
      <image-cropper *ngIf="!imageLoaded" [ngClass]="{'image-cropper': profileImage == true, 'id-cropper': profileId == true}"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="containWithinAspectRatio"
          [aspectRatio]="ratio"
          [resizeToWidth]="350"
          [cropperMinWidth]="128"
          [onlyScaleDown]="true"
          [roundCropper]="false"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
          [alignImage]="'left'"
          [style.display]="showCropper ? null : 'none'"
          format="jpeg"
          (imageCropped)="imageCropped($event)"
      ></image-cropper>
      <image-cropper *ngIf="imageLoaded" [ngClass]="{'image-cropper': profileImage == true, 'id-cropper': profileId == true}"
          [imageBase64]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="containWithinAspectRatio"
          [aspectRatio]="ratio"
          [resizeToWidth]="350"
          [cropperMinWidth]="128"
          [onlyScaleDown]="true"
          [roundCropper]="false"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
          [alignImage]="'left'"
          [style.display]="showCropper ? null : 'none'"
          format="jpeg"
          (imageCropped)="imageCropped($event)"
      ></image-cropper>
    </div>
    <img hidden [ngClass]="{'image-cropper': profileImage == true, 'id-cropper': profileId == true}" [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />
  
    <button *ngIf="croppedImage" class="btn btn-info btn-sm cardBtn mb-4 mt-2" style="width: fit-content;" (click)="saveImage()">{{'PROFILE.SAVEIMAGE' | translate}}</button>
  </div>


  <div *ngIf="!croppedImage" class="mb-2">
    <h2 class="text-center m-2">{{'PROFILE.WAITINGFORIMAGE' | translate}}</h2>
  </div>

  <div *ngIf="!croppedImage" class="d-flex align-items-center justify-content-center">
    <button type="button" class="btn btn-cyan mb-2 px-2 py-1 modal-close" (click)="profileImage = false; profileId = false; imageChangedEvent = ''; croppedImage = ''; dismiss()" aria-hidden="true" style="width: fit-content;">{{'CONSULTATIONS.SWAL.CLOSE' | translate}}</button>
  </div>
  
</ng-template>

<ng-template #authUsersModal let-modal class="authModal">
  <app-auth-users-doc></app-auth-users-doc>
</ng-template>

<ng-template #updateFollowUpModal let-modal>
  <div class="p-3">
    <div class="justify-content-end">
      <button type="button" class="close float-right modal-close" (click)="close()" aria-hidden="true">×</button>
    </div>
   
    <h4 class="mt-3">{{'CONSULTATIONS.SWAL.REASONTOFOLLOWUP' | translate}}</h4>
  
    <div class="d-flex" *ngFor="let reason of remoteConfig.reasonsToUpdateFollowUp">
      <div class="custom-control custom-checkbox mb-2">
        <input type="checkbox" class="custom-control-input" id="{{reason}}" (change)="selectReason($event)">
        <label class="custom-control-label" for="{{reason}}">{{reason | translation:currentLang}}</label>
      </div>
    </div>
    <textarea id="editable-div" (blur)="followUpTxt($event)" placeholder="{{'CONSULTATIONS.SWAL.FOLLOWUPPLACEHOLDER' | translate}}" (keyup)="followUpTxt($event)" style="font-style: italic; border: 1px solid #ced4da; border-radius: 4px; background-color: transparent; width: 95%;" class="mx-2" value="{{followUpReasonsTxt}}"></textarea>

    <div class="d-flex justify-content-center">
      <button class="btn btn-info button fit-content" role="button" (click)="saveReasons()" [disabled]="followUpReasons.length === 0 || followUpReasonsTxt.length == 0">{{'CONSULTATIONS.SWAL.CONTINUEFOLLOWUP' | translate }}</button>
    </div>
  </div>
 </ng-template>

<ng-template #FollowUpOrRefill let-modal>
  <div class="justify-content-end">
    <button type="button" class="close float-right modal-close" (click)="dismiss()" aria-hidden="true">×</button>
  </div>

  <div class="form-group row justify-content-center text-center m-0 mb-3">
    <h2 class="bold m-0">{{'CONSULTATIONS.ACTIVEPRESCRIPTIONS' | translate}}</h2>
  </div>

  <div class="d-flex">
    <div class="w-100">
      <h2 class="bold">{{'CONSULTATIONS.PENDINGREFILLS' | translate}}</h2>
      <div>
        <app-prescription-list [notInPopUp]="false" [condition]="currentCondition"></app-prescription-list> 
      </div>
    </div>
    <hr class="productHr">
    <div class="w-100">
      <h2 class="bold">{{'CONSULTATIONS.NHCPE' | translate}}</h2>
      <!-- <img class="mx-auto" style="width: 130px;" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fproducts%2FAmandaOrtaServiceImg.png?alt=media&token=a5b031bb-06ce-4efa-90a7-b5fe4983e624"> -->
      <img class="mx-auto rounded-circle cropped" style="width: 130px;" src="{{followUpToEvaluate.practitionerImg}}">
      <h4>{{'CONSULTATIONS.NHCPETEXT' | translate}}</h4>
      <button class="btn btn-primary d-flex m-auto" style="font-size: 14px !important;" (click)="consultChoosed()">{{'CONSULTATIONS.CONTINUEWITHCONSULT' | translate}}</button>
    </div>
  </div>
</ng-template>

<ng-template #chooseTypeOfConsult let-modal>
  <div class="justify-content-end">
    <button type="button" class="close float-right modal-close" (click)="dismiss()" aria-hidden="true">×</button>
  </div>

  <div class="form-group row justify-content-center text-center m-0 mb-3">
    <h2 class="bold m-0">{{'CONSULTATIONS.CHOOSECONSULT' | translate}}</h2>
  </div>

  <div class="d-flex">
    <div class="w-100">
      <h2 class="bold">{{'CONSULTATIONS.UPLOADRESULTS' | translate}}</h2>
      <img class="mx-auto rounded-circle cropped" style="width: 130px;" src="{{followUpToEvaluate.practitionerImg}}">
      <h4>{{'CONSULTATIONS.READRESULTS' | translate}}</h4>
      <button class="btn btn-primary d-flex m-auto" style="font-size: 14px !important;" (click)="setConsult(conditionRules.followUpTemplate);">{{'CONSULTATIONS.CONTINUEWITHCONSULT' | translate}}</button>
    </div>
    <hr class="productHr">
    <div class="w-100">
      <h2 class="bold">{{'CONSULTATIONS.REQUESTNEWLABS' | translate}}</h2>
      <img class="mx-auto rounded-circle cropped" style="width: 130px;" src="{{followUpToEvaluate.practitionerImg}}">
      <h4>{{'CONSULTATIONS.NHCPETEXT' | translate}}</h4>
      <button class="btn btn-primary d-flex m-auto" style="font-size: 14px !important;" (click)="setConsult(conditionRules.consultTemplate);">{{'CONSULTATIONS.CONTINUEWITHCONSULT' | translate}}</button>
    </div>
  </div>
</ng-template>


<ng-template #timezoneModal let-modal>
  <div class="justify-content-end">
    <button type="button" class="close float-right modal-close" (click)="modal.close()" aria-hidden="true">×</button>
  </div>

  <div class="form-group row justify-content-center text-center m-0">
    <h2 class="fade-in text-center m-0">{{'PROFILE.TIMEZONE' | translate}}</h2>
  </div>
  <div class="options-cards mb-2" style="min-height: auto;">
    <div class="col-md-12 d-flex flex-column justify-content-center align-items-center" style="text-align: -webkit-center;">
      <label class="inp">
        <div>
          <ng-select class="custom select mt-0" (change)="selectTimezone($event)" [clearable]="false" [(ngModel)]="currentTimezone.name">
            <ng-option *ngFor="let timezone of timezoneList" value="{{timezone.name}}">{{timezone.name}}</ng-option>
          </ng-select>    
        </div>
      </label>
    </div>
  </div>

  <div class="row">
    <div class="form-group justify-content-center text-center mt-2 flex-column" style="margin: 0 auto;">
      <button class="btn btn-info nextbtn text-center mb-4" (click)="saveTimezone(); modal.close()" [disabled]="currentTimezone.name == ''">{{'ACCOUNTS.EMAIL&PASSWORD.SAVE' | translate }}</button>
    </div>
  </div>
</ng-template>

<ng-template #firstOrderTerms let-modal>
  <div class="p-3 d-flex justify-content-between ml-auto" style="width: fit-content; padding-bottom: 0 !important;">
    <button type="button" class="close" aria-label="Close" (click)="modal.close();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="">
    <page size="A4">
      <div class="w-100 d-flex">
        <h2 class="mx-auto mb-0 justify-content-center">{{'ORDERS.PRODUCTSTOBEPRESCRIBED' | translate}}</h2>
      </div>
      <div class="w-100 d-flex flex-column" style="padding: 0 5%;">
        <div class="content mb-1">
          <div *ngFor="let product of selectedItems" [ngClass]="product.type !== 'product' ? 'not-a-product' : 'green'"  class="d-flex refillProducts mt-2 mb-1 product-div flex-row">
            <img style="max-height: 70px; width: auto;" src="{{product.imgRef | translation:currentLang}}" alt="">
            <div class="d-flex w-100 justify-content-center">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <span class="ml-3 product-name">{{product.name | translation:currentLang}}</span>

                <div class="d-flex flex-column">
                  <div class="d-flex">
                    <span class="inp mb-0">
                      <span  *ngIf="product.daw == 1" class="label ml-3 mb-2">{{'ORDERS.ASPRESCRIBED' | translate}}</span>
                      <input *ngIf="product.daw == 0 || product.daw == 2" class="ml-3" type="checkbox" id="{{product.id}}" (click)="fixedDAW(product)" [checked]="setDAW(product)">        
                      <span  *ngIf="product.daw == 0 || product.daw == 2" class="label ml-2 mb-2">{{'ORDERS.ASPRESCRIBEDOREQUIVALENT' | translate}}</span>
                      <span class="focus-bg"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 *ngIf="selectedItems && selectedItems.length == 0">
            {{'ORDERS.DISCLAIMER1' | translate}}
          </h4>
          <div class="mt-3">
            <h4>{{'ORDERS.HANDLEPAYMENTS' | translate}}</h4>
            <ng-select style="width: 150px; margin: auto;" (change)="updateAutoPayment($event)" [(ngModel)]="currentConsult.productAutoPayment" [clearable]="false">
              <ng-option [value]="true">{{'ORDERS.AUTOMATIC' | translate}}</ng-option>
              <ng-option [value]="false">{{'ORDERS.MANUAL' | translate}}</ng-option>
            </ng-select>
            <h5 class="mt-2">{{'ORDERS.DISCLAIMER2' | translate}}</h5>
          </div>
        </div>
      </div>

      <div class="doc-footer" style="padding: 0 5%;">
        <div class="d-flex w-100 justify-content-start mt-2 flex-row">
          <label for="daw" class="checkbox-container">
            <input class="w-auto h-auto" type="checkbox" id="daw" style="position: relative;" (click)="setOrientation($event.target.checked)" [(ngModel)]="currentConsult.orientation">        
            <span class="checkmark" style="min-width: 13px; width: 13px; height: 13px;top: 2px; position: relative;"></span>
            <span class="label ml-2 font-size-13">{{'ORDERS.ORIENTATION' | translate}}</span>
          </label>  
        </div>

        <div class="mt-2">
          <div class="d-flex flex-row mb-3">
            <label for="signature" class="checkbox-container1">
              <input class="w-auto h-auto mr-2" type="checkbox" id="signature" (click)="electronicSignature()" [disabled]="currentConsult.patientSignature" [attr.disabled]="currentConsult.patientSignature">
              <span class="checkmark1" style="min-width: 13px; width: 13px; height: 13px;top: 2px; position: relative;"></span>
              <p class="m-0 ml-2 font-size-14">{{'ORDERS.DIGITALSIGNATURE' | translate}}</p>
            </label>  
          </div>
          <p class="m-0 font-size-12 orangeText" *ngIf="signature">Digitally Signed by: {{signature}} Ref: {{signRef}}</p>
          <p class="m-0 font-size-12" *ngIf="signature">Date: {{dateCertified | date:'MMMM dd, yyyy hh:mm aa'}}</p>
          <hr class="signature-line w-100">
          <span>{{'ORDERS.PATIENTSIGNATURE' | translate}}</span>
        </div>
        <h5 class="mt-1" *ngIf="selectedItems && selectedItems.length == 0">
          {{'ORDERS.DISCLAIMER' | translate}}
        </h5>
        <div class="d-flex justify-content-end mt-4 ml-auto" style="width: fit-content;">
          <div *ngIf="signature" class="mr-2 pb-3">
            <button *ngIf="paymentIntentSuccess == false && chargeProcessingStripe == false && paymentIntentError == false" class="send-prescription" (click)="modal.close(); createAcceptaPaymentInitiation()">{{'ORDERS.COMPLETEANDSUBMIT' | translate}}</button>
            <button *ngIf="chargeProcessingStripe && paymentIntentSuccess == false" style="border-radius: 0.25rem;" class="btn btn-info nextbtn text-center mb-4" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{'CONSULTATIONS.PROCESSING' | translate }}</button>
          </div>
        </div>
      </div>
    </page>
  </div>
</ng-template>

<ng-template #chooseImageEnvironment let-modal>
  <div class="justify-content-end">
    <button type="button" class="close float-right modal-close" (click)="modal.close()" aria-hidden="true">×</button>
  </div>

  <h2 class="text-center">{{'CONSULTATIONS.CHOOSEENVIRONMENT' | translate}}</h2>
  <div *ngIf="imgType == 'ID'" class="d-flex justify-content-center">
    <button class="btn btn-cyan mr-2" (click)="modal.close(); profileImage = false; profileId = true; front = true; startCam();ratio = 485/300">
      {{'CONSULTATIONS.TAKEIDPHOTO' | translate}}
    </button>
    <span class="mr-2">
      {{'CONSULTATIONS.OR' | translate}}
    </span>
    <button (click)="modal.close(); cropImage(); profileImage = false; profileId = true; front = true; ratio = 485/300" class="btn btn-cyan">
      {{'CONSULTATIONS.CHOOSEIDFILE' | translate}}
    </button>
  </div>
  <div *ngIf="imgType == 'Image'" class="d-flex justify-content-center">
    <button class="btn btn-cyan mr-2" (click)="modal.close(); profileImage = true; profileId = false; startCam(); ratio = 1">
      {{'CONSULTATIONS.TAKESELFIE' | translate}}
    </button>
    <span class="mr-2">
      {{'CONSULTATIONS.OR' | translate}}
    </span>
    <button (click)="modal.close(); cropImage(); profileImage = true; profileId = false; ratio = 1" class="btn btn-cyan">
      {{'CONSULTATIONS.CHOOSEFILE' | translate}}
    </button>
  </div>
  <div *ngIf="imgType == 'Upload'" class="d-flex justify-content-center">
    <button class="btn btn-cyan mr-2" (click)="modal.close(); startCam(); ratio = 1; takePicture = true">
      {{'CONSULTATIONS.TAKEPHOTO' | translate}}
    </button>
    <span class="mr-2">
      {{'CONSULTATIONS.OR' | translate}}
    </span>
    <button (click)="modal.close(); cropImage(); ratio = 1; takePicture = true;" class="btn btn-cyan">
      {{'CONSULTATIONS.CHOOSEFILE' | translate}}
    </button>
  </div>
</ng-template>

<ng-template #camera let-modal>
  <div class="custom-dropzone justify-content-center">
    <div *ngIf="profileId && front && !hide" class="d-flex align-items-center flex-column" id="app">
      <div><video #video class="justify-content-center" id="video" width="325" height="250" autoplay></video></div>
      <div><button class="takePic btn btn-cyan" id="snap" (click)="capture(); closeCam(); modal.close(); front = true">{{'CONSULTATIONS.TAKEPICTURE' | translate}}</button></div>
      <canvas #canvasID id="canvasID" hidden width="325" height="250"></canvas>
      <button class="btn btn-light" (click)="profileId = false; closeCam(); modal.close()">
        {{'CONSULTATIONS.CANCEL' | translate}}
      </button>
    </div>
  </div>
</ng-template>
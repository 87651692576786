import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { PharmacyOrderScheduleService } from 'src/app/core/services/pharmacy-order-schedule.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('addressForm') myTemplate; 
  @Input() currentConsult: any;
  @Input() currentOrder: any;
  @Input() user: any;
  @Input() currentUser:any;
  @Input() component = '';
  
  shippingAddresses:any;
  shipping:any;
  hide:Boolean = false;

  modalRef:any;
  currentAddress:any = [];
  edit: Boolean = false;
  allSubscriptions = new Subscription()

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public firebaseService: FirebaseService, 
    public formBuilder: UntypedFormBuilder,   
    private modalService: NgbModal,
    private translate: TranslateService,
    private pharmacyOrderScheduleService: PharmacyOrderScheduleService,
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.currentConsult){
      this.getShippingAddress();
    }

    if(changes.currentOrder){
      this.getUser();
      if(changes.currentOrder.currentValue.shippingAddress){
        this.shipping = [changes.currentOrder.currentValue.shippingAddress];
      }
      else if (!this.currentOrder.shippingAddress && this.shipping && this.shipping.length > 0) {
        this.saveAddress(this.shipping[0])
      }
    }
  }
  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe()
  }

  ngOnInit(): void {
    // this.getUser();
    if(this.currentConsult){
      this.getShippingAddress();
    }

    if(this.currentOrder){
      this.getUser();
      this.shipping = [this.currentOrder.shippingAddress];
    }

    if(!this.currentConsult && !this.currentOrder){
      this.getUser();
    }
  }

  async getUser() {
    await this.firebaseService.getUserAddresses(this.user)
    let sub = this.firebaseService.addressessSubscription.subscribe(data => {
      this.shippingAddresses = (this.currentConsult || this.currentOrder) ? data.filter((address:any) => address.AddressType === "Shipping" && address.AddressStateCode === this.user.currentLocation.code) : this.component == 'walkthrough' ? data : data.filter((address:any) => address.AddressType === "Shipping");
    })
    this.allSubscriptions.add(sub)
  }

  showModal(modal:any, address:any){
    this.currentAddress = address;
     this.modalRef = this.modalService.open(modal, { size: 'lg', backdrop: 'static', centered: true });
  }

  saveAddress(address:any){
    if(address && this.currentConsult){
      this.firebaseService.saveAddressInConsult(address, this.currentConsult.ID);
    } 
    
    if(address && this.currentOrder && this.currentOrder.paymentStatus != "paid"){
      let warehouse = 2
      if(address.AddressStateCode == "PR"){
        warehouse = 1
      }
      this.afs.collection("orders").doc(this.currentOrder.id).update({shippingAddress: address, warehouse_id: warehouse});
    }
  }

  addAddress(addressModal:any, bool:any, address?:any){
    this.currentAddress = address;
    this.edit = bool;
    this.modalRef = this.modalService.open(addressModal, {size: 'lg', backdrop: 'static', windowClass: 'addAddressModal', centered: true});
    // this.modalService.open(addressModal, {size: 'lg', backdrop: 'static'});
  }

  close(){
    this.modalRef.dismiss()
  }

  getShippingAddress(){
    let sub = this.afs.collection("consultation").doc(this.currentConsult.ID).collection("Address", ref => ref.where('AddressType', '==', "Shipping")).valueChanges().subscribe(data => {
      this.shipping = data;
    });
    this.allSubscriptions.add(sub)
    this.getUser()
  }

  checkAddress(address){
    let ship = this.shipping?.filter((add:any) => add?.id === address?.id);
    return ship && ship.length > 0 ? true : false;
  }

  deleteAddress(address: any){
    var id = address.id.toString();
    this.firebaseService.deleteAddress(id, this.user.uid)
  }

  swal;
  deleteAddressConfirmation(address: any){
    this.translate.get('PROFILE.SWAL').subscribe((res: any) => {
      this.swal = res
    })

    Swal.fire({
      customClass: {
        confirmButton: 'btn-cyan',
        cancelButton: 'btn-light'
      },
      title: this.swal.DELETEADDRESS,
      text: this.swal.DELETEADDRESSTEXT,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.swal.CONFIRM,
      cancelButtonText: this.swal.CANCEL
    }).then(result => {
      if (result.isConfirmed) {

        this.deleteAddress(address);
        
        Swal.fire(this.swal.REMOVED , this.swal.DELETEDADDRESSMSG, 'success');
      }
    })
  }

  warnDefaultAddress(address, event){
    if(event.target.checked){
      this.translate.get('ADDRESS.SWAL').subscribe((res: any) => {
        this.swal = res;
      })
  
      Swal.fire({
        customClass: {
          confirmButton: 'btn-cyan',
          cancelButton: 'btn-light'
        },
        title: this.swal.TITLE,
        text: this.swal.CHANGEDEFAULT,
        icon: 'warning',
        iconColor: '#ff0000 !important',
        confirmButtonText: this.swal.CHANGE,
        showCancelButton: true,
        cancelButtonText: this.swal.CANCEL,
        allowEscapeKey : false,
        allowOutsideClick: false,
      }).then(result => {
        if(result.isConfirmed){
          this.makeDefaultAddress(event, address)
        }
        if(result.isDismissed){
          this.makeDefaultAddress({target: {checked: false}}, address)
        }
      })
    }
    else {
      this.makeDefaultAddress(event, address)
    }
  }

  makeDefaultAddress($event, address) {
    if ($event.target.checked) {
      const subscription = this.afs.collection("users").doc(this.user.uid).collection("Address").valueChanges().subscribe(async (address1: any) => {
        subscription.unsubscribe(); // Unsubscribe from the observable after the initial value is fetched
        
        let addressess = address1.filter(x => address.id != x.id);
        if (addressess.length > 0) {
          addressess.forEach(async (a: any) => {
            this.afs.collection("users").doc(this.user.uid).collection("Address").doc(a.id).update({ AddressDefault: false });
          });
        }
        this.afs.collection("users").doc(this.user.uid).collection("Address").doc(address.id).update({ AddressDefault: true });
        this.pharmacyOrderScheduleService.updatePharmacyOrderSubscriptions(this.user.uid, address)
      });
    } else {
      this.afs.collection("users").doc(this.user.uid).collection("Address").doc(address.id).update({ AddressDefault: false });
    }
  }
}
<h2>{{'REFERRALS.REFERRALS' | translate}}</h2>
<button *ngIf="!creatingReferral" class="btn btn-info" (click)="createReferral(); creatingReferral = true;">{{'REFERRALS.CREATEREFERRAL' | translate}}</button>
<button *ngIf="creatingReferral" class="btn btn-light" (click)="creatingReferral = false;">{{'REFERRALS.CANCEL' | translate}}</button>
<!-- <button class="btn btn-info" (click)="createReferralFollowUp()">Create Referral Follow Up</button> -->

<div *ngIf="creatingReferral" style="margin: 15px">
  <table>
    <tbody>
      <tr class="d-flex flex-row">
        <td class="d-flex m-0 mr-2" style="height: 36px; align-items: end;">{{'REFERRALS.REFERRALFOR' | translate}}</td>
        <td>
          <select class="form-control" id="select" (change)="selectReferral($event)">
              <option></option>
              <option *ngFor="let type of typeOfReferral" id="{{type}}" >{{type | translation:'en'}}</option>
          </select>
        </td>
      </tr>
  
      <tr *ngIf="referralSelection == 'Dzeus Consult'" class="d-flex mt-2">
        <td class="d-flex m-0 mr-2" style="height: 36px; align-items: end;">{{'REFERRALS.TYPEOFREFERRAL' | translate}}</td>
        <td>
          <select class="form-control" id="select" (change)="selectTypeOfConsult($event)">
            <option></option>
            <option *ngFor="let c of consults" id="{{c.Name}}" value="{{c.serviceType}}">{{c.Name | translation:'en'}}</option>
          </select>
        </td>
      </tr>
  
      <tr *ngIf="referralSelection == 'Dzeus Consult' && referralConsult" class="d-flex mt-2">
        <td class="d-flex m-0 mr-2" style="height: 36px; align-items: end;">{{'REFERRALS.HCP' | translate}}</td>
        <td>
          <div class="d-flex mt-2">
            <div *ngIf="pastPractitioners.length != 0" class="radio d-flex">
              <input id="radio-2" name="radio" type="radio" (click)="showPractitioners = 'past'" [checked]="showPractitioners == 'past'">
              <label for="radio-2" style="width: max-content;" class="radio-label m-0 ml-1 d-flex align-items-center flex-row">{{'REFERRALS.PAST' | translate}}</label>
            </div>
            <div class="radio d-flex ml-3">
              <input id="radio-3" name="radio" type="radio" (click)="showPractitioners = 'all'" [checked]="showPractitioners == 'all'">
              <label for="radio-3" style="width: max-content;" class="radio-label m-0 ml-1 d-flex align-items-center flex-row">{{'REFERRALS.ALL' | translate}}</label>
            </div>
          </div>
         <br>
          <select *ngIf="showPractitioners == 'all'" class="form-control" id="select" (change)="selectPractitioner($event)">
            <option></option>
            <option id="'Any Practitioner'">Any Health Care Provider</option>  
            <option *ngFor="let c of practitioners" id="{{c.uid}}" >{{c.firstName}} {{c.lastName1}}</option>
          </select>
    
          <select *ngIf="showPractitioners == 'past'" class="form-control" id="select" (change)="selectPractitioner($event)">
            <option></option>
            <option *ngFor="let c of pastPractitioners" id="{{c.uid}}" >{{c.firstName}} {{c.lastName1}}</option>
          </select>
        </td>
      </tr>
    </tbody>
  </table>

    <textarea class="my-2" value="referralText" style="width: 100%; height: 225px; resize: none;" (keyup)="collectMessage($event)" placeholder="{{'REFERRALS.REASONFORREFERRAL' | translate}}" [ngModel]="referralText"></textarea>

    <button class="btn btn-info mt-1 mx-auto" (click)="signPad(signaturePad)" [disabled]="referralSelection == '' || (referralSelection == 'Dzeus Consult' && (referralConsult == '' || referralPractitioner == '')) || referralText == ''">{{'REFERRALS.CLICKTOSIGN' | translate}}</button>
</div>

<div *ngIf="referrals && !creatingReferral">
    <div>
        <div class="d-flex flex-wrap mt-2">
          <label for="{{referral.ID}}" class="radio-card" style="margin-right: 30px; margin-bottom: 30px;" *ngFor="let referral of referrals; let i = index">
            <input hidden type="radio" name="radio-card" id="{{referral.ID}}"/>
            <div class="card-content-wrapper">          
              <div class="card-content">
                <div class="d-flex justify-content-center align-items-center" style="font-size: 80px;">
                <i class="ri-file-list-line"></i>
                </div>
                <h4 class="prescription-type" *ngIf="referral.referralCondition != 'Referral to: Specialist'">{{referral.referralCondition}}</h4>
                <h4 class="prescription-type" *ngIf="referral.referralCondition == 'Referral to: Specialist'">{{'REFERRALS.REFERRALTO' | translate}}</h4>
                <div class="sections d-flex mx-auto justify-content-center">
                  <span *ngIf="referral.z_processedB64" class="prescription-status" (click)="signPad(internalPrescription); base64 = referral.z_processedB64" [ngClass]="{'badge-soft-success': true}">{{'REFERRALS.VIEWREFERRAL' | translate}}</span>
                  <span *ngIf="referral.z_processedB64 == undefined" class="prescription-status" (click)="signPad(internalPrescription); base64 = referral.z_base64" [ngClass]="{'badge-soft-success': true}">{{'REFERRALS.VIEWREFERRAL' | translate}}</span>
                </div>
             </div>
              <div class="buttons d-flex flex-column justify-content-center mx-auto w-50" *ngIf="referral.valid || referral.valid == undefined">
                <!-- <button [ngClass]="{'disableDiv': consult.Status == 'Completed'}" class="btn btn-info mt-2" (click)="createConsult()">Edit</button> -->
                <button [ngClass]="{'disableDiv': consult.Status == 'Completed'}" class="btn btn-info mt-2" (click)="invalidateReferral(referral);">{{'CONSULT-PRESCRIPTIONS.DELETE' | translate}}</button>
              </div>
              <div class="buttons d-flex flex-column justify-content-center mx-auto" *ngIf="referral.valid == false">
                <h3>{{'REFERRALS.INVALID' | translate}}</h3>
              </div>
            </div>
          </label>
        </div>
      </div>
</div>

<ng-template #internalPrescription let-modal>
    <div class="row mt-3 product-wizard">
      <div class="col-lg-12">
        <div class="row justify-content-between pl-5 pr-5">
          <h3>{{'REFERRALS.REFERRAL' | translate}}</h3>
          <i class="ri-close-fill" style="font-size: 23px;" (click)="modal.dismiss('Cross click')"></i>
        </div>
        <ng-template [ngTemplateOutlet]="pdfViewer"></ng-template>
      </div>
    </div>
  </ng-template>

<ng-template #pdfViewer let-modal>
    <div *ngIf="base64 != undefined" class="pdf-container media-body overflow-hidden" style="min-height: 600px;">
      <ngx-extended-pdf-viewer *ngIf="base64 != undefined" [base64Src]="base64" backgroundColor="#e8e8ef"
        [showZoomButtons]="true"  [useBrowserLocale]="true" [height]="'600px'"
        [sidebarVisible]="false" [showHandToolButton]="true" [zoom]="'50%'">
      </ngx-extended-pdf-viewer>
    </div>
</ng-template>  

<ng-template #signaturePad let-modal>
    <div class="p-2 d-flex w-100 justify-content-end">
      <i class="fas fa-times" (click)="modal.close()"></i>
    </div>
    <app-signaturepad (signature)="getSignature($event)"></app-signaturepad>
  </ng-template>
<div class="main">
  <div class="title">
    <h2 *ngIf="user" style="font-size: 1.7rem;">{{'CHECKOUT.ALMOSTDONE' | translate}}{{patientProfile.firstName}}{{'CHECKOUT.ALMOSTDONE1' | translate}}</h2>
  </div>

  <div class="row mt-2">
    <div class="col-lg-6">
      <div class="products" *ngIf="orderTotal != '0.00' || dummyOrderLines.length == 0">
        <div class="product" style="width: 100%; align-self: center;" *ngFor="let item of orderLines">
          <div  class="d-flex flex-row w-100">
            <div class="image">
              <img *ngIf="item.type == 'product'" src="assets/images/icons/Dzeus_Frasco_logo.png" alt="bottle.png">
              <img *ngIf="item.type == 'service'" src="{{item.image | translate}}" alt="bottle.png">
            </div>
            <div class="d-flex flex-column p-info w-100">
              <div class="product-main-details ml-2 w-100">
                <h5 class="title-prod" style="text-align: start;">{{item.productName | translation:currentLang}}</h5>
                <p *ngIf="item.productPrice && item.type == 'product'" class="m-0">{{setPrice(item.productPrice) | currency:'USD'}} - {{item.productQty}} {{'CONSULTATIONS.PILLS' | translate}}</p>
                <p *ngIf="item.productPrice && item.type == 'service'" class="m-0">{{setPrice(item.productPrice, item) | currency:'USD'}}</p>
              </div>
             
              <div *ngIf="item.type == 'product'" class="d-flex flex-row">
                <button class="change" style="background-color: transparent; border: 0; align-self: start;" (click)="openOTC(chooseProductModal)">{{'CHECKOUT.CHANGE' | translate}}</button>
                <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 7 7 17"></path>
                  <path d="M8 7h9v9"></path>
                </svg>
              </div>
            </div>   
          </div>
          <div *ngIf="item.productPromo && item.productPromo.length > 0" class="product-details d-flex flex-column">
            <h5 class="m-0 list-group-item pl-0 pb-0 mb-2" style="text-align: start; border: 0;">{{'CHECKOUT.INCLUDED' | translate}}</h5>
            <!-- <span *ngFor="let d of item.productDescription" class="info">
              <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5 4H18a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2.5"></path>
                <path d="M8.621 3.515A2 2 0 0 1 10.561 2h2.877a2 2 0 0 1 1.94 1.515L16 6H8l.621-2.485z"></path>
                <path d="M9 12h6"></path>
                <path d="M9 16h6"></path>
              </svg>
              {{d | translation:currentLang}}
            </span> -->
            <ul class="list-group list-group-flush">
              <li class="list-group-item" style="border: none; padding: 0;"><img class="checkmark-img" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fpromos%2FDzeus_Sample_Promo_Checkmark.png?alt=media&token=ff6d31c8-a164-41b4-ac54-63d27a234553"/> <span class="included-txt">{{'PROMOTIONS.ITEM1' | translate}}</span></li>
              <li *ngIf="item.tabletQty != 0" class="list-group-item" style="border: none; padding: 0;"><img class="checkmark-img" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fpromos%2FDzeus_Sample_Promo_Checkmark.png?alt=media&token=ff6d31c8-a164-41b4-ac54-63d27a234553"/> <span class="included-txt">{{item.tabletQty}} {{dosageForm(item.dosageForm) | translate}}</span>&nbsp;<span class="asterisk">*</span></li>
              <li *ngIf="item.productPromo.includes(1293)" class="list-group-item" style="border: none; padding: 0;"><img class="checkmark-img" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fpromos%2FDzeus_Sample_Promo_Checkmark.png?alt=media&token=ff6d31c8-a164-41b4-ac54-63d27a234553"/> <span class="included-txt">10 Benzocaine Wipes</span></li>
              <li *ngIf="item.productPromo.includes(1244)" class="list-group-item" style="border: none; padding: 0;"><img class="checkmark-img" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fpromos%2FDzeus_Sample_Promo_Checkmark.png?alt=media&token=ff6d31c8-a164-41b4-ac54-63d27a234553"/> <span class="included-txt">{{'PROMOTIONS.ITEM3' | translate}}</span>&nbsp;<span class="asterisk">**</span></li>
          </ul>
          <div class="total-section mt-2">
              <div class="total-section-item" *ngIf="orderTotal == '0.00'">
                  <span class="label">{{'PROMOTIONS.TOTAL' | translate}}</span>
                  <span class="amount">{{setPrice(item.productPromoPrice) | currency:'USD'}}</span>
              </div>
              <div class="total-section-item">
                  <span class="label-offer-value">{{'PROMOTIONS.VALUE' | translate}}</span>
                  <span class="amount-offer-value">{{item.productValue[0][consult.state.code]}}</span>
              </div>
          </div>
          <div class="container-small-text mt-4" *ngIf="orderTotal == '0.00'">
              <div class="left-column" (click)="openOTC(detailsModal)">
                  <span class="info-icon"><i class="fa fa-info-circle" style="font-size: 14px; vertical-align: text-top; color: #f37227;"></i></span>
              </div>
              <div class="right-column">
                  <p class="description-info">{{'PROMOTIONS.DISCLAIMER0' | translate}}</p>
              </div>
          </div>
          <div class="container-small-text mt-2" *ngIf="item.tabletQty != 0" >
              <div class="left-column">
                  <span class="asterisk-small">*<span style="visibility: hidden;">*</span></span>
              </div>
              <div class="right-column">
                  <p class="description">{{'PROMOTIONS.DISCLAIMER1' | translate}}</p>
              </div>
          </div>
          <div class="container-small-text mt-2" *ngIf="item.productPromo.includes(1244)">
              <div class="left-column">
                  <span class="asterisk-small">**</span>
              </div>
              <div class="right-column">
                  <p class="description">{{'PROMOTIONS.DISCLAIMER2' | translate}}</p>
              </div>
          </div>
          <div class="container-small-text mt-2">
              <div class="left-column">
                  <span class="info-icon"><i class="fa fa-info-circle" style="font-size: 12px; vertical-align: text-top;"></i></span>
              </div>
              <div class="right-column">
                  <p class="description">{{'PROMOTIONS.DISCLAIMER3' | translate}} <button (click)="openOTC(viewPricesModal)" class="p-0 orangeText" style="background-color: transparent; border: 0; align-self: start; text-decoration: underline;">{{'CHECKOUT.VIEWTREATMENTPRICES' | translate}}</button>
</p>
              </div>
          </div>
          </div>
        </div>
        <div *ngIf="chooseMedication && dummyOrderLines.length == 0 && !hasPromo">
          <div class="d-flex flex-row">
            <button class="change p-0" style="background-color: transparent; border: 0; align-self: start;" (click)="openOTC(chooseProductModal)">{{'CHECKOUT.ADDPRODUCT' | translate}}
            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 16 16"><path fill="#16395f" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/></svg>
            </button>
          </div>  
        </div>
        <div *ngIf="hasPromo && orderTotal == '0.00'">
          <div class="d-flex flex-row">
            <button class="change p-0" style="background-color: transparent; border: 0; align-self: start;" (click)="activatePromo = true; openOTC(chooseProductModal)">{{'CHECKOUT.CHANGEPROMO' | translate}}
            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 16 16"><path fill="#16395f" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/></svg>
            </button>
          </div>  
        </div>
      </div>
    
      <div class="products" id="dummyOrder" *ngIf="dummyOrderLines.length > 0">
        <div class="product" style="width: 100%; align-self: center;" *ngFor="let item of dummyOrderLines; let i = index">
          <div class="d-flex flex-row justify-content-center">
            <div class="d-flex flex-row w-100">
              <div class="image" *ngIf="item.type == 'product'">
                <img src="{{item.imgRef}}" alt="bottle.png">
              </div>
              <div class="d-flex flex-column p-info w-100">
                <div class="product-main-details ml-2 w-100" *ngIf="item.type == 'product'">
                  <h5 class="title-prod" style="text-align: start;">{{item.productName | translation:currentLang}}</h5>
                  <p *ngIf="item.type == 'product'" class="m-0">{{setPrice(item.price) | currency:'USD'}} - {{item.productQty}} {{dosageForm(item.dosageForm) | translate}} {{'CONSULTATIONS.OF' | translate}} {{item.productDosage}}</p>
                  <p *ngIf="item.type == 'service'" class="m-0">{{setPrice(item.price) | currency:'USD'}}</p>
                </div>
                <div *ngIf="item.type == 'product' && !hasPromo" class="d-flex flex-column ml-2">
                  <div class="d-flex justify-content-start">
                    <button class="change m-0" style="background-color: transparent; border: 0; align-self: start;" (click)="openOTC(chooseProductModal)">{{'CHECKOUT.CHANGE' | translate}}</button>
                    <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17 7 7 17"></path>
                      <path d="M8 7h9v9"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="shipment-frequency" *ngIf="item.type == 'product'">
            <h5 class="title mt-2">{{"SHIPMENT-FREQUENCIES.HOWOFTEN" | translate}}</h5>
            <div class="d-flex flex-column justify-content-center align-items-center mt-1" *ngFor="let freq of deliveryFrequencies[item.productId]">
              <label for="{{freq.id}}" class="radio-card" >
                <input type="radio" name="radio-card" id="{{freq.id}}" value="{{freq.id}}" (click)="setShipmentFrequency(freq, item)" [checked]="setDeliveryFrequency(freq.frequency, freq.autoPayment)"/>
                <div class="card-content-wrapper d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <span class="check-icon"></span>
                    <span *ngIf="freq.autoPayment" class="font-size-18 w-100 gender ml-2 d-flex justify-content-between align-items-center">{{ freq.description | translation:currentLang }} <img class="mx-2" style="width: 25px;" src="../../../../../../assets/images/frequencies/Calendar gray.png"></span>
                    <span *ngIf="!freq.autoPayment" class="font-size-18 w-100 gender ml-2 d-flex justify-content-between align-items-center">{{ freq.description | translation:currentLang }} <img class="mx-2" style="width: 25px;" src="../../../../../../assets/images/frequencies/Manual Order Icon (Copy).png"></span>
                  </div>
                  <div *ngIf="setDeliveryFrequency(freq.frequency, freq.autoPayment)">
                    <p style="max-width: 300px; margin: 0; font-size: 12px;">{{freq.memo | translation:currentLang}}</p>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">

      <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" id="acc" #acc="ngbAccordion" activeIds="toggle-1">
        <ngb-panel id="toggle-1">
          <ng-template ngbPanelTitle>
            {{'ADMINCONSULTS.SHIPPINGADDRESS' | translate}} <i class="fa fa-chevron-down accordion-icon ml-2"></i>
          </ng-template>
          <ng-template ngbPanelContent>
            <div style="width: -webkit-fill-available;"> <!-- class="summary"-->
              <div class="checkout-wrapper">
                <div *ngIf="shipping && shipping.length > 0" class="address-shipping">
                  <!-- <div class="address justify-content-center align-items-center" style="display:flex; flex-direction: column;" *ngFor="let address of shipping">
                    <div class="address-container">
                      <i class="fas fa-map-pin"></i>
                      <div>
                        <p style="line-height: normal;" *ngIf="address.AddressLine2 == '' || address.AddressLine2 == null" class="address-lines mt-1 mb-0 ml-3">{{address.AddressLine1}}</p>
                        <p style="line-height: normal;" *ngIf="address.AddressLine2 != '' && address.AddressLine2 != null" class="address-lines mt-1 mb-1 ml-3">{{address.AddressLine1}}, {{address.AddressLine2}}</p>
                        <p style="line-height: normal;" class="address-city mt-0 mb-1 ml-3">{{address.AddressCity}}, {{address.AddressState}} {{address.AddressZipcode}}</p><br>
                        <p class="address-city ml-3">{{address.AddressCountry}}</p>
                      </div>
                    </div>
                  </div> -->
                  <app-address [currentConsult]="consult" [user]="patientProfile" [currentOrder]="orderInfo" [currentUser]="user"></app-address>
                  <div class="d-flex justify-content-center align-items-center">
                    <button class="btn btn-cyan py-0" style="width: fit-content;" (click)="showTerms(addAddress)">{{'ADDRESS.ADDADDRESS' | translate}}</button>
                    <!-- <button *ngIf="shipping && shipping.length == 0" class="btn btn-cyan py-0" style="width: fit-content;" (click)="showTerms(addAddress)">{{'ADDRESS.ADDADDRESS' | translate}}</button> -->
                    <!-- <button *ngIf="shipping && shipping.length != 0" class="btn btn-cyan py-0" style="width: fit-content;" (click)="showTerms(addAddress)">{{'ADDRESS.EDITADDRESS' | translate}}</button> -->
                  </div>
                  <br>
                </div>
                <div *ngIf="shipping && shipping.length == 0">
                  <div class="no-address mx-auto align-self-center animate"> 
                    <app-add-address *ngIf="consult && consult.ID" [validate]="validateAddress" [accountToEdit]="patientProfile.uid" (validateAddressState)="validateAddressState.emit($event); validateAddress = false" [consultID]="consult.ID" [consultShipping]="'Shipping'" [bool]="edit" (dismiss)="dismiss()"></app-add-address>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 d-flex align-items-center" style="justify-content: right;">
                <button class="btn btn-sm btn-primary mr-2 mt-4" *ngIf="!validateAddress" (click)="getAddress()" style="font-size: 14px !important;">{{'SUBSCRIPTIONS.CONTINUE' | translate}}</button>
                <button class="btn btn-sm btn-primary mr-2 mt-4" *ngIf="validateAddress" style="font-size: 14px !important;"><span class="spinner-border spinner-border-sm"></span></button>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="toggle-2" *ngIf="displayShippingMethodOptions && chooseMedication">
          <ng-template ngbPanelTitle>
              {{'CHECKOUT.SHIPPINGMETHOD' | translate}} <i class="fa fa-chevron-down accordion-icon ml-2"></i>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="mt-3 mb-2" style="justify-content: center; display: grid;">
              <label for="shipping-{{shipping.productId}}" class="radio-card" *ngFor="let shipping of shippingMethod; let i = index;">
                <input type="radio" name="shipping-options" value="{{shipping.productId}}" id="shipping-{{shipping.productId}}" [checked]="currentShippingMethod === shipping.productId" (click)="setSelectedShippingMethod($event, shipping)"/> <!--(click)="setShipmentFrequency($event, item)" [checked]="setDeliveryFrequency(freq.frequency)"-->
                <div class="card-content-wrapper" style="width: 250px !important;">
                  <span class="check-icon"></span>
                  <span class="w-100 gender ml-2">{{shipping.productPrice[0][consult.state.code] | currency:'USD'}} - {{shipping.productName}}</span>
                </div>
              </label>
            </div>
            <div class="row">
              <div class="col-lg-12 d-flex align-items-center" style="justify-content: right;">
                <button class="btn btn-sm btn-primary mr-2 mt-4" (click)="acc.toggle('toggle-3')" style="font-size: 14px !important;">{{'SUBSCRIPTIONS.CONTINUE' | translate}}</button>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="toggle-3">
          <ng-template ngbPanelTitle>
            {{'CHECKOUT.PAYMENT' | translate}} <i class="fa fa-chevron-down accordion-icon ml-2"></i>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="mb-3" style="width: -webkit-fill-available;">
              <div class="d-flex w-100" style="text-align: center;"> <!-- class + order-info-->
              </div>
              <div *ngIf="allPaymentMethods && allPaymentMethods.length > 0" class="shipping-method-container w-100 align-items-center justify-content-center"><!-- class + order-info-->
                <div class="form-inline shipping-field">
                  <div class="input-group mb-2 mt-2" style="width: inherit;">
                    <ng-select class="shipping-select checkoutSelect" style="width: inherit;" placeholder="{{'CHECKOUT.SELECTCARD' | translate}}" [multiple]="false" [clearable]="false" [searchable]="false" (change)="onItemChange($event)" [(ngModel)]="creditCardRef" >
                      <ng-option *ngFor="let item of allPaymentMethods" [value]="item">
                        <div>
                          <img src="assets/images/cards/visa-color-logo.png" *ngIf="item.brand === 'Visa' || item.brand === 'VISA'" alt="Visa" width="20"/>
                          <img src="assets/images/cards/mastercard-logo.png" *ngIf="item.brand === 'Mastercard' || item.brand === 'MASTERCARD'" alt="MasterCard" width="20"/>
                          <img src="assets/images/cards/american_express_01.png" *ngIf="item.brand === 'American Express' || item.brand === 'AMERICAN_EXPRESS'" alt="American Express" width="20"/>
                          <img src="assets/images/cards/discover.png" *ngIf="item.brand === 'Discover Club' || item.brand === 'DISCOVER'" alt="Discover" width="20"/>
                          <img src="assets/images/cards/jcb_01.png" *ngIf="item.brand === 'JCB' || item.brand === 'JCB'" alt="JCB" width="20"/>
                          <img src="assets/images/cards/diners_club_01.png" *ngIf="item.brand === 'Diners Club' || item.brand === 'DISCOVER_DINERS'" alt="Diners Club" width="20"/>
                          <img src="assets/images/cards/china_union_01.png" *ngIf="item.brand === 'UnionPay' || item.brand === 'CHINA_UNIONPAY'" alt="UnionPay" width="20"/>
                          {{item.brand}}-{{item.last4}} {{'CHECKOUT.EXPIRES' | translate}} {{item.expDate}}
                        </div>  
                      </ng-option>
                    </ng-select>
                  </div>
                </div> 
              </div>
              <div *ngIf="allPaymentMethods && allPaymentMethods.length > 0" class="d-flex justify-content-center align-items-center">
                <button class="btn btn-cyan py-0" style="width: fit-content;"(click)="showModalAddCard(addCard)">
                  <i class="mdi mdi-plus-circle mr-1"></i>{{'CHECKOUT.ADDPAYMENT' | translate}}
                </button>
                <br>
                <br>
              </div>
              <div *ngIf="allPaymentMethods && allPaymentMethods.length == 0">
                <div class="no-address mx-auto align-self-center animate"> 
                  <app-add-payment [user]="patientProfile" [consult]="consult" [validate]="validateCard" [name]="patientProfile.firstName" [lastName]="patientProfile.lastName1" [modalRef]="this.modalRef" (validateCardState)="validateCardState.emit($event);" (cardAdded)="getAcceptaCards()"></app-add-payment>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
        <ng-container *ngIf="showAddCouponOption">
          <ngb-panel id="toggle-4">
            <ng-template ngbPanelTitle>
              {{'CHECKOUT.COUPON' | translate}} <i class="fa fa-chevron-down accordion-icon ml-2"></i>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="mb-3" style="width: -webkit-fill-available;">
                <div class="d-flex w-100" style="text-align: center;">
                </div>
                <div class="coupon-code-container w-100 align-items-center justify-content-center">
                  <div class="form-inline shipping-field">
                    <div class="input-group mb-2 mt-2" style="width: inherit;">
                      <input type="text" class="form-control" placeholder="{{'CHECKOUT.ENTERCOUPON' | translate}}" id="couponCodeValue" style="border-radius: 5px;">
                      <ng-container *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create'">
                        <div class="input-group-append" style="margin-left: 10px;">   
                          <button *ngIf="!applyingCoupon" class="btn btn-cyan" type="button" (click)="applyCouponCode()">{{'CHECKOUT.APPLY' | translate}}</button>
                          <div style="margin-top: auto; margin-bottom: auto;"><span *ngIf="applyingCoupon" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="orderLinesLoaded == false || orderInfo.orderState == 'create'">
                        <div class="input-group-append" style="margin-left: 10px;">   
                          <div style="margin-top: auto; margin-bottom: auto;"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>
                        </div>
                      </ng-container>
                    </div>
                    <div *ngIf="couponErrorExists">
                      <p *ngIf="couponInvalid" class="error"><i class="fa fa-times"></i> {{'CHECKOUT.INVALIDCOUPON' | translate}}</p>
                      <p *ngIf="couponError" class="error"><i class="fa fa-times"></i> {{'CHECKOUT.ERRORCOUPON' | translate}}</p>
                      <p *ngIf="couponPreviouslyUsed" class="error"><i class="fa fa-times"></i> {{'CHECKOUT.COUPONUSED' | translate}}</p>
                      <p *ngIf="couponInvalidOrder" class="error"><i class="fa fa-times"></i> {{couponCode}} {{'CHECKOUT.INVALIDORDER' | translate}}</p>
                    </div>
                    <div *ngIf="couponExistsInOrder">
                      <p *ngIf="couponValid" style="margin-top: auto; margin-bottom: auto; display: flex;"><span class="valid" style="margin-top: auto; margin-bottom: auto;"><i class="fa fa-check"></i> {{couponCode}} {{'CHECKOUT.VALIDCOUPON' | translate}}</span> <button *ngIf="!removingCoupon" class="btn btn-link" (click)="removeCouponFromOrder()" style="font-size: 12px !important;">{{'CHECKOUT.REMOVECOUPON' | translate}}</button> <span *ngIf="removingCoupon" class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="margin-left: 15px; margin-top: auto; margin-bottom: auto;"></span></p>
                    </div>
                  </div> 
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ng-container>
      </ngb-accordion>

      <div *ngIf="chooseMedication && dummyOrderLines.length > 0" class="product-details d-flex flex-column">
        <h5 >{{'CHECKOUT.INCLUDED' | translate}}</h5>
        <span class="info">
          <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5 4H18a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2.5"></path>
            <path d="M8.621 3.515A2 2 0 0 1 10.561 2h2.877a2 2 0 0 1 1.94 1.515L16 6H8l.621-2.485z"></path>
            <path d="M9 12h6"></path>
            <path d="M9 16h6"></path>
          </svg>
          {{'CHECKOUT.INCLUDES-1' | translate}}
        </span>
        <span class="info">
          <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 12h4l3 8 4-16 3 8h4"></path>
          </svg>
          {{'CHECKOUT.INCLUDES-2' | translate}}
        </span>
        <span class="info"> 
          <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 19a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
            <path d="M17 19a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
            <path d="M13 6h5l3 5v6h-2"></path>
            <path d="M5 17H3v-4"></path>
            <path d="M2 5h11v12"></path>
            <path d="M9 17h6"></path>
            <path d="M3 9h4"></path>
            <path d="M21 11h-8"></path>
          </svg>
          <span *ngIf="conditionRules.freeConsult">{{'CHECKOUT.FREEEN' | translate}}&nbsp;</span>{{'CHECKOUT.INCLUDES-3' | translate}}<span *ngIf="conditionRules.freeConsult">&nbsp;{{'CHECKOUT.FREE' | translate}}</span>
        </span>
      </div>
    
      <div class="order-details-container mt-3 p-4 d-flex justify-content-center align-items-center">
        <div class="row" style="flex-wrap: nowrap;">
          <p class="desc">{{'CHECKOUT.SUBTOTAL' | translate}}</p>
          <p *ngIf="orderLinesLoaded == false || orderInfo.orderState == 'create'" class="numbers"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && dummyOrder && dummyOrder.subTotal != undefined && orderSubtotal != undefined" class="numbers bold">{{(dummyOrder?.subTotal + orderSubtotal) | currency:'USD'}}</p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && dummyOrder && dummyOrder.subTotal != undefined && orderSubtotal == undefined" class="numbers bold">{{dummyOrder?.subTotal | currency:'USD'}}</p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && !dummyOrder && orderSubtotal != undefined" class="numbers bold">{{orderSubtotal | currency:'USD'}}</p>
        </div>
        <div class="row" style="flex-wrap: nowrap;">
          <p class="desc">{{'CHECKOUT.TAX' | translate}}</p>
          <p *ngIf="orderLinesLoaded == false || orderInfo.orderState == 'create'" class="numbers"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && dummyOrder && dummyOrder.tax != undefined && orderTax != undefined" class="numbers bold">{{(dummyOrder?.tax + orderTax) | currency:'USD'}}</p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && dummyOrder && dummyOrder.tax != undefined && orderTax == undefined" class="numbers bold">{{dummyOrder?.tax | currency:'USD'}}</p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && !dummyOrder && orderTax != undefined" class="numbers bold">{{orderTax | currency:'USD'}}</p>
        </div>
        <div *ngIf="chooseMedication" class="row" style="flex-wrap: nowrap;">
          <p class="desc">{{'ADDRESS.SHIPPING' | translate}}</p>
          <p *ngIf="orderLinesLoaded == false || orderInfo.orderState == 'create'" class="numbers"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && dummyOrder && dummyOrder.shipping != undefined && orderShipping != undefined" class="numbers bold">{{(dummyOrder?.shipping + orderTax) | currency:'USD'}}</p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && dummyOrder && dummyOrder.shipping != undefined && orderShipping == undefined" class="numbers bold">{{dummyOrder?.shipping | currency:'USD'}}</p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && !dummyOrder && orderShipping != undefined" class="numbers bold">{{orderTax | currency:'USD'}}</p>
        </div>
        <!-- <div *ngIf="appliedDiscount" class="row" style="flex-wrap: nowrap;">
          <p class="desc">{{'CHECKOUT.DISCOUNT' | translate}}</p>
          <p *ngIf="orderLinesLoaded == false || orderInfo.orderState == 'create'" class="numbers"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && dummyOrder && dummyOrder.shipping != undefined && orderShipping != undefined" class="numbers bold">-{{(dummyOrder?.shipping + orderTax) | currency:'USD'}}</p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && dummyOrder && dummyOrder.shipping != undefined && orderShipping == undefined" class="numbers bold">-{{dummyOrder?.shipping | currency:'USD'}}</p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && !dummyOrder && orderShipping != undefined" class="numbers bold">-{{orderTax | currency:'USD'}}</p>
        </div> -->
        <div style="width: inherit;">
          <hr class="mt-0">
        </div>
        <div class="row" style="flex-wrap: nowrap;">
          <h5 class="desc m-0">{{'CHECKOUT.TOTAL' | translate}}</h5>
          <p *ngIf="orderLinesLoaded == false || orderInfo.orderState == 'create'" class="numbers m-0"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && dummyOrder && dummyOrder.total != undefined && orderTotal != undefined" class="numbers bold m-0" style="align-self: self-end;">{{(dummyOrder?.total + orderTotal) | currency:'USD'}}</p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && dummyOrder && dummyOrder.total != undefined && orderTotal == undefined" class="numbers bold m-0" style="align-self: self-end;">{{dummyOrder?.total | currency:'USD'}}</p>
          <p *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create' && !dummyOrder && orderTotal != undefined" class="numbers bold m-0" style="align-self: self-end;">{{orderTotal | currency:'USD'}}</p>
        </div>
    
        <div *ngIf="dummyOrder" class="row mt-2 flex-column" style="flex-wrap: nowrap;">
          <div class="row" style="flex-wrap: nowrap;">
            <h5 class="desc m-0">{{'CHECKOUT.PAYTODAY' | translate}}</h5>
            <p *ngIf="orderLinesLoaded == false || orderInfo.orderState == 'create'" class="numbers m-0"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></p>
            <h5 *ngIf="orderLinesLoaded ==  true && orderInfo.orderState != 'create'" class="numbers bold m-0 orangeText" style="align-self: self-end;">{{orderTotal | currency:'USD'}}</h5>
          </div>
        </div>
      </div>
      
      <p *ngIf="chooseMedication && dummyOrderLines.length > 0" class="disclaimer mt-4">{{'CHECKOUT.DISCLAIMER-2' | translate}}</p>

    </div>
  </div>
</div>

<ng-template #addCard style="max-height: 300px !important" let-modal>
  <app-add-payment [user]="patientProfile" [name]="patientProfile.firstName" [lastName]="patientProfile.lastName1" [modalRef]="this.modalRef" (cardAdded)="getAcceptaCards()"></app-add-payment>
</ng-template>

<ng-template #addAddress let-modal>
  <div class="row mt-3">
    <div class="col-lg-12 mx-auto align-self-center">
      <div class="no-address mx-auto align-self-center animate"> 
        <!-- <app-add-address *ngIf="consult.ID && shipping.length != 0" [accountToEdit]="patientProfile.uid" [address]="shipping[0]" [showBtns]="true" [consultID]="consult.ID" [consultShipping]="'Shipping'" [bool]="edit" (dismiss)="dismiss()"></app-add-address>
        <app-add-address *ngIf="consult.ID && shipping.length == 0" [accountToEdit]="patientProfile.uid" [consultID]="consult.ID" [showBtns]="false" [consultShipping]="'Shipping'" [bool]="edit" (dismiss)="dismiss()"></app-add-address> -->
        <app-add-address [accountToEdit]="patientProfile.uid" [consultID]="consult.ID" [showBtns]="true" [consultShipping]="'Shipping'" [bool]="false" (dismiss)="dismiss()"></app-add-address>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #chooseProductModal let-modal>
  <div class="justify-content-end">
    <button type="button" class="close float-right modal-close" style="font-size: 30px;" (click)="modal.close()" aria-hidden="true">×</button>
  </div>
  <div class="p-2">
    <app-choose-product-copy [activatePromo]="activatePromo" [currentUserAuth]="user" [consultRules]="conditionRules" [currentConsult]="consult" (nextAction)="modal.close()"></app-choose-product-copy>
  </div>
</ng-template>

<ng-template #viewPricesModal let-modal>
  <div class="justify-content-end">
    <button type="button" class="close float-right modal-close" style="font-size: 30px;" (click)="modal.close()" aria-hidden="true">×</button>
  </div>
  <div class="p-2">
    <app-prices *ngIf="consult" [consult]="consult" [consultRules]="conditionRules"></app-prices>
  </div>
</ng-template>

<ng-template #detailsModal let-modal>
  <div class="modal-header" style="border: 0; padding-bottom: 0;">
    <!-- <h5 class="modal-title" id="exampleModalLongTitle">Details</h5> -->
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="padding-top: 0;">
      <!-- <h3>{{'PROMOTIONS.REASONTITLE' | translate}}</h3> -->
      <h4>{{'PROMOTIONS.REASON' | translate}}</h4>
      <div class="mt-4" style="font-size: 14px; line-height: 2;">
          <ol type="1">
              <li>{{'PROMOTIONS.REASON1' | translate}}</li>
              <li>{{'PROMOTIONS.REASON2' | translate}}</li>
              <li>{{'PROMOTIONS.REASON3' | translate}}</li>
              <li>{{'PROMOTIONS.REASON4' | translate}}</li>
              <li>{{'PROMOTIONS.REASON5' | translate}}</li>
              <li>{{'PROMOTIONS.REASON6' | translate}}</li>
              <li>{{'PROMOTIONS.REASON7' | translate}}</li>
              <li>{{'PROMOTIONS.REASON8' | translate}}</li>
              <li>{{'PROMOTIONS.REASON9' | translate}}</li>
              <li>{{'PROMOTIONS.REASON10' | translate}}</li>
          </ol> 
      </div>
      <div>{{'PROMOTIONS.REASONTEXT' | translate}}</div>
  </div>
  <div class="modal-footer" style="display: none;">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="modal.close('Close click')">{{'PROMOTIONS.CLOSE' | translate}}</button>
  </div>
</ng-template>
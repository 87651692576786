<!-- <div style="margin: 18pt">
  <div id="pdf" #pdf style="width: 400pt;">
    questions with options
    <p>{{i + 1}}. {{q.question | translation:currentLang}}</p>
    <div *ngIf="(q.type == 'SearchMedicines' ||  q.type == 'SearchSurgeries' || q.type == 'SearchConditions')" style="display: flex; flex-wrap: wrap;"><p class="ml-4">Selección en API o </p>&nbsp; <p style="font-style:italic;"> {{q.options | translation:currentLang}}</p></div>
    <div *ngIf="(q.displayType != 'textImage' && q.type != 'BP' && q.type != 'BMI' && q.type !== 'SearchMedicines' && q.type != 'SelectionWithOptions' && q.type != 'SearchSurgeries' && q.type !== 'SearchConditions')" style="display: flex; flex-wrap: wrap;"><ng-container *ngFor="let a of q.options" style="font-style:italic; white-space: pre-wrap;"> - {{a | translation:currentLang}} <br /></ng-container></div>
    <div *ngIf="(q.type == 'SelectionWithOptions')" style="display: flex; flex-wrap: wrap;"><p class="ml-4">Opciones</p>&nbsp; <ng-container *ngFor="let a of q.options" style="font-style:italic;"> - {{a.Product | translation:currentLang}} <br /></ng-container></div>
    <div *ngIf="q.type == 'BP'" style="display: flex; flex-wrap: wrap;"><p class="ml-4">{{'VIEWCONSULTS.SYSTOLIC' | translate}}:</p>&nbsp; <ng-container style="font-style:italic;"> {{q.options[0].systolic[0] | translation:currentLang}}<br />{{q.options[0].systolic[1] | translation:currentLang}}<br />{{q.options[0].systolic[2] | translation:currentLang}}</ng-container></div>
    <div *ngIf="q.type == 'BP'" style="display: flex; flex-wrap: wrap;"><p class="ml-4">{{'VIEWCONSULTS.DIASTOLIC' | translate}}:</p>&nbsp; <ng-container style="font-style:italic;"> {{q.options[1].diastolic[0] | translation:currentLang}}<br />{{q.options[1].diastolic[1] | translation:currentLang}}<br />{{q.options[1].diastolic[2] | translation:currentLang}}<br /></ng-container></div>
    <div *ngIf="(q.type == 'BMI')" style="display: flex; flex-wrap: wrap;"><p class="ml-4"></p>&nbsp; <ng-container style="font-style:italic;">{{'CONSULTATIONS.FEET' | translate}}:<br /> {{'CONSULTATIONS.INCHES' | translate}}:<br />{{'CONSULTATIONS.POUNDS' |translate}}:</ng-container></div>
    <div *ngIf="(q.displayType == 'textImage')" style="display: flex; flex-wrap: wrap;"><p class="ml-4"></p>&nbsp; <ng-container style="font-style:italic;">Need to add question with images</ng-container></div>
  </div>
</div> -->

<div *ngIf="loading">
  <app-reload [message]="'RELOAD.DOWNLOAD'"></app-reload>
</div>

<!-- <img class="cropped" crossorigin="anonymous" id="image"
src="{{consult?.image}}" width="220px" height="220px"> -->
<div style="opacity: 0; position: absolute;">
  <img crossorigin="anonymous" id="imageT"
  src="{{consult?.image}}" width='220px'>

  <canvas id="imageCanvasT" style="border:1px solid #d3d3d3; border-radius: 50%;">
  </canvas>

  <canvas id="imageCanvasTT"  style="border:1px solid #d3d3d3; border-radius: 50%; ">
  </canvas>

  <img crossorigin="anonymous" id="id"
src="{{consult?.identification}}" width="320px" height="220px">

<!-- <img crossorigin="anonymous" id="idBack"
src="{{consult?.identificationBack}}" width="320px" height="220px">

<canvas id="idBackCanvas" width="320px" height="220px" style="border:1px solid #d3d3d3;">
</canvas> -->
<canvas id="idCanvas" width="320px" height="220px" style="border:1px solid #d3d3d3;">
</canvas>
</div>
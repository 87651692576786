import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
  {name: 'square-sandbox', src: 'https://sandbox.web.squarecdn.com/v1/square.js'},
  {name: 'square', src: 'https://sandbox.web.squarecdn.com/v1/square.js'} //Production Script: https://web.squarecdn.com/v1/square.js
];

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private scripts: any = {};
  
  //STRIPE CLOUD FUNCTIONS HTTP REQUESTS URL START HERE
  private POST_URL = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/createCustomerPaymentIntentStripe"; //"https://us-central1-dzeus-app-telemd.cloudfunctions.net/createCustomerChargeStripe";
  private POST_URL2 = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/addCustomerCardStripe";
  private POST_URL5 = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/updateCustomerCardStripe";
  private POST_URL_Customer_Stripe = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/getCustomerStripe";
  //STRIPE CLOUD FUNCTIONS HTTP REQUESTS URL END HERE

  //SQUARE CLOUD FUNCTIONS HTTP REQUESTS URL START HERE
  private POST_URL_SQ_ADD_CARD = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/addCustomerCardOnFileSquare";
  private POST_URL_SQ_CREATE_PAYMENT = "https://us-central1-dzeus-app-telemd.cloudfunctions.net/createCustomerPaymentSquare";
  //SQUARE CLOUD FUNCTIONS HTTP REQUESTS URL END HERE


  constructor(
    private httpClient: HttpClient
  ) {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
          loaded: false,
          src: script.src
      };
    });
   }

  //----------------------------------STRIPE CODE STARTS HERE----------------------------------

  //STRIPE CREATE PAYMENT INTENT HTTP POST REQUEST STARTS HERE - 03/17/22
  public postCustomerCharge(objData: any){

    var charge = {
      email: objData.email.toString(),
      stripeID: objData.stripeID.toString(),
      source: objData.source.toString(),
      amount: objData.amount.toString(),
      description: objData.description.toString()
    }

    let body = new HttpParams();

    body = body.set('email', charge.email);
    body = body.set('stripeID', charge.stripeID);
    body = body.set('source', charge.source);
    body = body.set('amount', charge.amount);
    body = body.set('description', charge.description);

    // var email = JSON.stringify(emailUser);

    return this.httpClient.post(this.POST_URL, body); //,this.httpClient.get(this.GET_URL)

  }
  //STRIPE CREATE PAYMENT INTENT HTTP POST REQUEST ENDS HERE - 03/17/22

  //Original Code Starts Here
  // public postRequestAddCard(objData: any){

  //   var currentUser = {
  //     email: objData.email.toString(),
  //     stripeID: objData.stripeID.toString(),
  //     token: objData.token.toString(),
  //     city: objData.city.toString(),
  //     country: objData.country.toString(),
  //     line1: objData.addressLine1.toString(),
  //     line2: objData.addressLine2.toString(),
  //     postal_code: objData.zipCode.toString(),
  //     state: objData.state.toString(),
  //     uid: objData.uid.toString(),
  //   }

  //   let body = new HttpParams();

  //   body = body.set('email', currentUser.email);
  //   body = body.set('stripeID', currentUser.stripeID);
  //   body = body.set('token', currentUser.token);
  //   body = body.set('city', currentUser.city);
  //   body = body.set('country', currentUser.country);
  //   body = body.set('line1', currentUser.line1);
  //   body = body.set('line2', currentUser.line2);
  //   body = body.set('postal_code', currentUser.postal_code);
  //   body = body.set('state', currentUser.state);
  //   body = body.set('uid', currentUser.uid);

  //   // var email = JSON.stringify(emailUser);


  //   return this.httpClient.post(this.POST_URL2, body); //,this.httpClient.get(this.GET_URL)

  // }
  //Original Code Ends Here

  //STRIPE ADD CARD HTTP POST REQUEST STARTS HERE - 03/17/22
  public postRequestAddCard(objData: any): Observable<any> {

    var currentUser = {
      email: objData.email.toString(),
      stripeID: objData.stripeID.toString(),
      token: objData.token.toString(),
      uid: objData.uid.toString(),
    }

    let body = new HttpParams();

    body = body.set('email', currentUser.email);
    body = body.set('stripeID', currentUser.stripeID);
    body = body.set('token', currentUser.token);
    body = body.set('uid', currentUser.uid);

    return this.httpClient.post(this.POST_URL2, body, { responseType: 'text' }); //, { responseType: 'text' }
  }
  //STRIPE ADD CARD HTTP POST REQUEST ENDS HERE - 03/17/22

  //STRIPE UPDATE CARD HTTP POST REQUEST STARTS HERE - 03/17/22
  public postRequestUpdateCard(emailCurrentUser: any, stripeIDCurrentUser: any, stripeTokenCurrentUser: any, cardNameCurrentUser: any){

    var currentUser = {
      email: emailCurrentUser.toString(),
      stripeID: stripeIDCurrentUser.toString(),
      source: stripeTokenCurrentUser.toString(),
      name: cardNameCurrentUser.toString()
    }

    let body = new HttpParams();

    body = body.set('email', currentUser.email);
    body = body.set('stripeID', currentUser.stripeID);
    body = body.set('source', currentUser.source);
    body = body.set('name', currentUser.name);

    // var email = JSON.stringify(emailUser);

    return this.httpClient.post(this.POST_URL5, body); //,this.httpClient.get(this.GET_URL)

  }


  public postRequest(emailCurrentUser: any, userProfile: any){

    var currentUser = {
      email: emailCurrentUser.toString()
    }

    let body = new HttpParams();

    body = body.set('email', currentUser.email);
    body = body.set('name', userProfile.name);
    body = body.set('phone', userProfile.phone);
    body = body.set('description', userProfile.description);
    body = body.set('city', userProfile.city);
    body = body.set('country', userProfile.country);
    body = body.set('line1', userProfile.line1);
    body = body.set('line2', userProfile.line2);
    body = body.set('postal_code', userProfile.postal_code);
    body = body.set('state', userProfile.state);

    // var email = JSON.stringify(emailUser);

    return this.httpClient.post(this.POST_URL_Customer_Stripe, body); //,this.httpClient.get(this.GET_URL)

  }







  //STRIPE UPDATE CARD HTTP POST REQUEST ENDS HERE - 03/17/22

  //----------------------------------STRIPE CODE ENDS HERE----------------------------------









  //----------------------------------SQUARE CODE STARTS HERE----------------------------------

  //SQUARE SCRIPT CODE STARTS HERE - 03/17/22
  //First Function
  loadScripts(...scripts: string[]): Promise<any> {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  //Second Function
  loadScript(name: string) {
    return new Promise((resolve, reject) => {
        if (!this.scripts[name].loaded) {
            // load script
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = this.scripts[name].src;
            // @ts-ignore
            if (script.readyState) {  // IE
                // @ts-ignore
                script.onreadystatechange = () => {
                    // @ts-ignore
                    if (script.readyState === 'loaded' || script.readyState === 'complete') {
                        // @ts-ignore
                        script.onreadystatechange = null;
                        this.scripts[name].loaded = true;
                        resolve({script: name, loaded: true, status: 'Loaded'});
                    }
                };
            } else {  // Others
                script.onload = () => {
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                };
            }
            script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
            document.getElementsByTagName('head')[0].appendChild(script); // <--- !!!
        } else {
            resolve({script: name, loaded: true, status: 'Already Loaded'});
        }
    });
  }

  //Third Function
  isAlreadyLoaded(name: any): boolean {
    return this.scripts[name]?.loaded;
  }
  //SQUARE SCRIPT CODE ENDS HERE - 03/17/22

  //SQUARE ADD CARD HTTP POST REQUEST STARTS HERE - 03/17/22
  //Add Customer Card On File - Square
  public postRequestAddCardSquare(objData: any): Observable<any> {

    var currentUser = {
      cardToken: objData.cardToken.toString(),
      uid: objData.uid.toString(),
      firstName: objData.firstName.toString(),
      lastName1: objData.lastName1.toString(),
      squareID: objData.squareID.toString(),
    }

    let body = new HttpParams();

    body = body.set('cardToken', currentUser.cardToken);
    body = body.set('uid', currentUser.uid);
    body = body.set('squareID', currentUser.squareID);
    body = body.set('firstName', currentUser.firstName);
    body = body.set('lastName1', currentUser.lastName1);

    return this.httpClient.post(this.POST_URL_SQ_ADD_CARD, body, { responseType: 'text' });
  }
  //SQUARE ADD CARD HTTP POST REQUEST ENDS HERE - 03/17/22

  //SQUARE UPDATE CARD HTTP POST REQUEST STARTS HERE - 03/17/22
  public postCustomerPaymentSquare(objData: any){

    var charge = {
      squareID: objData.squareID.toString(),
      amount: objData.amount.toString(),
      cardId: objData.source.toString(),
      description: objData.description.toString(),
      note: objData.note.toString(),
      uid: objData.uid.toString(),
      referenceId: objData.referenceId.toString()
    }

    let body = new HttpParams();

    body = body.set('squareID', charge.squareID);
    body = body.set('amount', charge.amount);
    body = body.set('cardId', charge.cardId);
    body = body.set('description', charge.description);
    body = body.set('note', charge.note);
    body = body.set('uid', charge.uid);
    body = body.set('referenceId', charge.referenceId);

    return this.httpClient.post(this.POST_URL_SQ_CREATE_PAYMENT, body);

  }
  //SQUARE UPDATE CARD HTTP POST REQUEST ENDS HERE - 03/17/22

  //----------------------------------SQUARE CODE ENDS HERE----------------------------------

}

import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import SignaturePad from 'signature_pad';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import Swal from 'sweetalert2';
import { AuthenticatorService } from '../../authenticator/authenticator.service';
import { EmailsService } from 'src/app/core/services/emails.service';
import { fbRemoteConfig } from 'src/app/authUtils';

@Component({
  selector: 'app-signaturepad',
  templateUrl: './signaturepad.component.html',
  styleUrls: ['./signaturepad.component.scss']
})
export class SignaturepadComponent implements OnInit {

  @ViewChild('signaturePad', { read: TemplateRef }) signaturePadModal:TemplateRef<any>;
  @Output() signature = new EventEmitter<any>();
  @Output() finishedConsult = new EventEmitter<any>();
  @Input() practitionerProfile
  @Input() patientProfile
  @Input() consultID
  @Input() currentUser
  @Input() consult
  @Input() auth
  remoteConfig: any = fbRemoteConfig.defaultConfig;

  history;
  consultUpdateInfo;
  
  canvas:any;
  signaturePad:any;
  modalRef:any;

  closingSignature:any;

  loadingDiagnostics: Boolean = false;

   //for Swal modal
   swaltitle
   swaltext
   cancel
   confirm
   delete
   deletemsg
   title
   text 

  constructor(
    private modalService: NgbModal,
    public router: Router,
    private afs: AngularFirestore,
    public translate: TranslateService,
    private authenticatorService: AuthenticatorService,
    public firebaseService: FirebaseService,
    private emailService: EmailsService
  ) { }

  ngOnInit(): void {
    this.initCanvas();  // Initialize the canvas
  }

  async initCanvas(){
    // this.canvas = <HTMLCanvasElement> document.getElementById('canvas');
    // const canvas = <HTMLCanvasElement> document.getElementById('canvas');
    this.canvas = await this.getCanvas("#canvas")

    var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    this.canvas.width = this.canvas.offsetWidth * ratio;
    this.canvas.height = this.canvas.offsetHeight * ratio;
    this.canvas.getContext("2d").scale(ratio, ratio);


    const canvas = await this.getCanvas("#canvas")
    this.signaturePad = new SignaturePad(this.canvas,{
      minWidth: 1,
      maxWidth: 1,
      penColor: "rgb(3, 154, 229)",
      minDistance: 2
    });
    // const signaturePad = new SignaturePad(canvas);
   
    var ctx = this.canvas.getContext('2d');

    ctx.globalCompositeOperation = 'source-over'; // default value
   
  }

  beginStroke(){
    this.signaturePad.addEventListener("beginStroke", () => {
    }, { once: true });
  }

  getCanvas(canvas){
    return new Promise(resolve => {
      if (document.querySelector(canvas)) {
          return resolve(document.querySelector(canvas));
      }

      const observer = new MutationObserver(mutations => {
          if (document.querySelector(canvas)) {
              resolve(document.querySelector(canvas));
              observer.disconnect();
          }
      });

      observer.observe(document.body, {
          childList: true,
          subtree: true
      });
    });
  }

  clearCanvas(){
    this.signaturePad.clear();
  }

  async storeSignature(){
    this.signature.emit(this.signaturePad.toDataURL('base64'));
  }

  signed = false
  storePDFSignature() {
    this.authenticate().then(async(response:any) => {
      let signature = this.signaturePad;
      // this.signature = signature.split(',')[1];
      if(response.v !== null){
        this.closingSignature = this.signaturePad.toDataURL();

        this.history = {
          date: new Date(Date.now()),
          id: this.firebaseService.getDate(),
          userUID: this.practitionerProfile.uid,
          user: this.practitionerProfile.firstName + ' ' + this.practitionerProfile.lastName1,
          userAccountType: this.practitionerProfile.accountType,
          action: 'Signed'
        }

        this.consultUpdateInfo = {
          authenticatorToken: response.value,
          authenticatorValidationDate: new Date(),
          authenticatorStatus: "valid",
          authenticatorResponse: response.v,
          Status: "Completed",
          dateToSort: new Date(Date.now())
        }
        this.signed = true;
      }
    });
  }
  
  checkPdf(event){
    if(event.pdf){
      this.finishedConsult.emit(Object.assign(this.consult, this.consultUpdateInfo));
      this.modalService.dismissAll();
    }
  }

  authenticate(){
    return new Promise((resolve, reject) => {
      this.translate.get('VIEWCONSULTS.SWAL').subscribe((res: any) => {
        this.title = res.AUTHENTICATE
        this.text = res.GOOGLEAUTH
        this.confirm = res.VALIDATE
        this.cancel = res.CANCEL
      })
      Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
          cancelButton: 'btn-light'
        },
        title: this.title,
        text: this.text,
        showCancelButton: true,
        confirmButtonText: this.confirm,
        cancelButtonText: this.cancel,
        input: 'text',
        inputAttributes:{
          maxlength:"6"
        },
        showLoaderOnConfirm:true,
        preConfirm: (value) => {
          this.loadingDiagnostics = true
          this.authenticatorService.validate(this.currentUser, value).then(v => {
            if(v == null){
              this.loadingDiagnostics = false;
              const Toast = Swal.mixin({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              });
              this.translate.get('VIEWCONSULTS.SWAL').subscribe((res: any) => {
                this.title = res.INVALIDTOKEN
              })
              Toast.fire({
                icon: 'error',
                title: this.title
              }).then(() => {
                // this.authenticate();
                // resolve(v)
                if(this.router.url.includes('closingSignature')){
                  this.storePDFSignature()
                }
              })
            }else{
              const Toast = Swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              });
              this.translate.get('VIEWCONSULTS.SWAL').subscribe((res: any) => {
                this.title = res.VALIDTOKEN
              })
              Toast.fire({
                icon: 'success',
                title: this.title
              })
              this.loadingDiagnostics = false;
              resolve({v: v, value: value})
            }
          })
        }
      })
      .then(async res => {
        if(res.isConfirmed === true){
          // console.log("confirmed")
          // resolve(true)
        }
        else if(res.isDenied === false){
          this.dismiss();
          // console.log("denied")
        }
      })
    })
  }

  dismiss(){
    if(this.modalRef){
      this.modalRef.dismiss();
    }
    this.modalService.dismissAll()
  }
}

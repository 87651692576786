
<div class="container-fluid" *ngIf="profile">
  <app-pagetitle [title]="pagetitle"></app-pagetitle>
  <div class="">
    <ng-select class="mb-4" style="width: fit-content;" [clearable]="false" *ngIf="profile && claims && claims.devAdmin"  (click)="changeAccountType($event.target.textContent)" [(ngModel)]="profile.accountType">
      <ng-option value='admin'>admin</ng-option>
      <ng-option value= 'practitioner'>practitioner</ng-option>
      <ng-option value= 'patient'>patient</ng-option>
    </ng-select>
  </div>

  <div *ngIf="profile && profile.accountType == 'patient'" class="col-lg-12" style="text-align: center;">
    <a class="btn btn-info" href="/consultations/chooseConsult">{{'HOME.STARTNEWCONSULT' | translate}}</a>
  </div>

  <div class="col-lg-12 dashboard p-0">
    <div class="col-md-6">
      <div *ngIf="profile" class="section section-profile mb-0">
        <span class="title">{{'PROFILE.BREADCRUMBS' | translate}}</span>
        <div class="d-flex flex-column">
          <div class="w-100 info">
            <div class="outline">
              <img src="{{profile.image}}" alt="">
            </div>
          </div>
          <div class="w-100 info">
            <span class="name">{{profile.firstName}} {{profile.middleName}} {{profile.lastName1}}</span><br>
            <span class="email font-size-16">{{profile.email}}</span><br>
            <span class="phone font-size-16">{{profile.phoneNumber | mask:'(000) 000-0000' }}</span><br>
            <p class="" style="color: red; line-height: initial;">{{exception | translate}}</p>
          </div>
        </div>
        <div class="w-100 text-center">
          <span class="cursor btn btn-primary" style="padding-top: 0 !important; padding-bottom: 0 !important;" (click)="goToProfile()">{{'PROFILE.EDIT' | translate}}</span>
        </div>
      </div>

      <div class="section" *ngIf="labs.length > 0">
        <span class="title">{{'HOME.LABS' | translate}}</span>
        <ol class="mt-3">
          <li *ngFor="let lab of labs" (click)="goToConsult(lab, true)">
            <span class="ellipsis">{{lab.conditionName | translation:currentLang}} - {{'CONSULTATIONS.LABS' | translate}}</span>
          </li>
        </ol>
      </div>

      <div class="section" *ngIf="referrals.length > 0">
        <span class="title">{{'HOME.REFERRALS' | translate}}</span>
        <ol class="mt-3">
          <li *ngFor="let referral of referrals" (click)="goToConsult(referral)">
            <span class="ellipsis">{{referral.referralCondition | translation:currentLang}}</span>
          </li>
        </ol>
      </div>

      <div class="section" *ngIf="appointments.length > 0">
        <span class="title">{{'TELEHEALTH.BREADCRUMBS' | translate}}</span>
        <div class="meetings">
          <h3>{{'TELEHEALTH.UPCOMINGAPPOINTMENTS' | translate}}</h3>
          <hr>
          <div class="list">
            <div *ngFor="let appointment of appointments" class="appointment">
              <span class="duration">{{appointment.duration}} min</span><br>
              <div class="d-flex justify-content-end">
                <span>{{ appointment.start | date:'MMMM dd, yyyy h:mm a':'':currentLang | titlecase}} - {{ appointment.end | date:'h:mm a':'':currentLang | titlecase }}</span>
              </div>
              <span>{{appointment.title}}</span><br>
              <span>{{appointment.description}}</span>
              <br>
              <button *ngIf="appointment.room" class="btn btn-info">
                <a class="meet-link" target="_blank" href="{{appointment.room}}">{{'TELEHEALTH.JOIN' | translate}}</a>
              </button>
              <p style="color: red;" *ngIf="!appointment.room && appointment.meetStatus == 'paid'">
                {{'APPOINTMENTS.PAIDSTATUS' | translate}}
              </p>
              <p style="color: red;" *ngIf="!appointment.room && appointment.meetStatus == 'pending'">
                {{'APPOINTMENTS.PENDINGSTATUS' | translate}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6" *ngIf="profile?.accountType == 'patient' || profile?.accountType == 'practitioner'">
       <div class="section consults customMargin">
        <span class="title">{{'HOME.PENDINGCONSULTS' | translate}}</span>
        <ol class="mt-3">
          <li *ngFor="let consult of consults" (click)="goToConsult(consult)">
            <span class="ellipsis">{{consult.conditionName | translation:currentLang}}</span>
            <span *ngIf="profile?.accountType == 'practitioner'" class="ellipsis">{{consult.patientName}}</span>
            <span *ngIf="consult.Status == 'Completed'">{{'PRACTITIONER-CONSULTS.COMPLETED' | translate}}</span>
            <span *ngIf="consult.Status == 'Rejected'">{{'PRACTITIONER-CONSULTS.REJECTED' | translate}}</span>
            <span *ngIf="consult.Status == 'Paid'">{{'PRACTITIONER-CONSULTS.PAID' | translate}}</span>
            <span *ngIf="consult.Status == 'In Progress'">{{'PRACTITIONER-CONSULTS.INPROGRESS' | translate}}</span>
            <span *ngIf="consult.Status == 'Open'">{{'PRACTITIONER-CONSULTS.OPEN' | translate}}</span>
            <span *ngIf="consult.Status == 'Denied'">{{'PRACTITIONER-CONSULTS.DENIED' | translate}}</span>
            <span *ngIf="consult.Status == 'Draft'">{{'PRACTITIONER-CONSULTS.DRAFT' | translate}}</span>
          </li>
          <div *ngIf="consults.length == 0" class="text-center">
            <p>{{'HOME.NOCONSULTS' | translate}}</p>
          </div>
        </ol>
        <div class="w-100 text-center">
          <span class="cursor btn btn-cyan" style="padding-top: 0 !important; padding-bottom: 0 !important;" (click)="profile?.accountType == 'practitioner' ? goToComponent('consults') : goToComponent('consultations')">{{'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}} </span>
        </div>
      </div>

      <div class="section consults" *ngIf="profile?.accountType == 'practitioner'">
        <span class="title">Manual: Uso y Manejo de la plataforma</span>
        <ol class="mt-3">
          <div *ngIf="base64 != undefined" class="pdf-container media-body overflow-hidden">
            <ngx-extended-pdf-viewer *ngIf="base64 != undefined" [textLayer]="false" [src]="base64" backgroundColor="#e8e8ef"
              [showZoomButtons]="true" [showPrintButton]="true" [showDownloadButton]="true" [useBrowserLocale]="true"
              [sidebarVisible]="false"  [showSecondaryToolbarButton]="false" [showSidebarButton]="false" [zoom]="'50%'">
            </ngx-extended-pdf-viewer>
          </div>
        </ol>
      </div>

      <div class="section orders" *ngIf="profile?.accountType == 'patient'">
        <span class="title">{{'ORDERS.ORDERS' | translate}}</span>
        <ol class="mt-3">
          <li *ngFor="let order of orders" (click)="goToOrder(order)">
            <span>{{order.orderNo}}</span>
            <span class="w-50 ellipsis">{{order?.date?.toDate() | date:'medium' }}</span>
            <div class="order-status" style="white-space: nowrap;">
              <i *ngIf="order.paymentStatus === 'paid'" class="far fa-check-circle text-success"></i>
              <i *ngIf="order.paymentStatus === 'Refunded'" class="fas fa-undo-alt"></i>
              <i *ngIf="order.paymentStatus === 'Chargeback'" class="far fa-times-circle text-danger"></i>
              <i *ngIf="order.paymentStatus === 'error'" class="far fa-times-circle text-danger"></i>
              <i *ngIf="order.paymentStatus === 'pending'" class="far fa-clock text-warning"></i>
              <ngb-highlight class="ml-2" *ngIf="order.paymentStatus === 'paid'" [result]="'ORDERS.STATUS.PAID' | translate" ></ngb-highlight>
              <ngb-highlight class="ml-2" *ngIf="order.paymentStatus === 'Refunded'" [result]="'ORDERS.STATUS.REFUNDED' | translate" ></ngb-highlight>
              <ngb-highlight class="ml-2" *ngIf="order.paymentStatus === 'Chargeback'" [result]="'ORDERS.STATUS.CHARGEBACK' | translate" ></ngb-highlight>
              <ngb-highlight class="ml-2" *ngIf="order.paymentStatus === 'error'" [result]="'ORDERS.STATUS.ERROR' | translate" ></ngb-highlight>
              <ngb-highlight class="ml-2" *ngIf="order.paymentStatus === 'pending'" [result]="'ORDERS.STATUS.PENDING' | translate" ></ngb-highlight>
            </div>
          </li>
          <div *ngIf="orders.length == 0" class="text-center">
            <p>{{'ORDERS.NOORDERS' | translate}}</p>
          </div>
        </ol>
        <div class="w-100 text-center">
          <span class="cursor btn btn-cyan" style="padding-top: 0 !important; padding-bottom: 0 !important;" (click)="goToComponent('orders&Refills')">{{'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}} </span>
        </div>
      </div>

      <div class="d-flex phone-column mb-3" *ngIf="profile?.accountType == 'patient'">
        <div class="p-0 mr-1 d-flex section align-items-center justify-content-center contactSection">
          <div class="d-flex" (click)="contactus()">
            <img class="w-25" src="assets/images/contactus.svg" alt="">
            <div style="margin:auto; margin-left: 0;">
              <h5 class="m-0 p-2">{{ 'NOTIFICATIONS.CONTACTUS.CONTACTUS' | translate }}</h5>
            </div>
          </div>
        </div>
        <div class="p-0 ml-1 d-flex section align-items-center justify-content-center contactSection">
          <div class="d-flex" (click)="goToHelpdesk()">
            <img class="w-25" src="assets/images/helpdesk.svg" alt="">
            <div style="margin:auto; margin-left: 0;">
              <h5 class="m-0 p-2">{{ 'NOTIFICATIONS.HELPDESK.HELPDESK' | translate }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  
  <ng-template #termsModal let-modal style="height: 500px;">
    <app-terms-of-use [profile]="profile" (dismiss)="modal.close()"></app-terms-of-use>
  </ng-template>

  <ng-template #authUsersModal let-modal class="authModal">
    <app-auth-users-doc></app-auth-users-doc>
  </ng-template>  
<div class="d-flex flex-column">
  <h2>Data BackUp</h2>
  <!-- <button class="mt-2" (click)="migrateOrders()">Migrate Orders</button>
  <button class="mt-2" (click)="migratePrescriptions()">Migrate Prescriptions</button>
  <button class="mt-2" (click)="migrateConsults()">Migrate Consults</button> -->
  <!-- <button class="mt-2" (click)="backupCalendar()">Backup Calendar</button>
  <button class="mt-2" (click)="backupChat()">Backup chats</button>
  <button class="mt-2" (click)="backupConditions()">Backup conditions</button>
  <button class="mt-2" (click)="backupConditionQuestions()">Backup ConditionsQuestions</button>
  <button class="mt-2" (click)="backupCreditCardPayments()">Backup CreditCardPayments</button>
  <button class="mt-2" (click)="backupDeliveryOrders()">Backup Delivery Orders</button>
  <button class="mt-2" (click)="backupConsultation()">Backup Consultation</button> -->
  <!-- <button class="mt-2" (click)="backupDiagnosticTemplates()">Backup Diagnostic templates</button>
  <button class="mt-2" (click)="backupDiagnosticTest()">Backup Diagnostic Test</button>
  <button class="mt-2" (click)="backupMail()">Backup Mail</button>
  <button class="mt-2" (click)="backupMailTemplates()">Backup Mail_templates</button>
  <button class="mt-2" (click)="backupOrders()">Backup Orders</button> -->
  <!-- <button class="mt-2" (click)="backupPrescription()">Backup Prescriptions</button>
  <button class="mt-2" (click)="backupProducts()">Backup Products</button>
  <button class="mt-2" (click)="backupProductsPacks()">Backup Products Packs</button>
  <button class="mt-2" (click)="backupUsers()">Backup Users</button> -->

</div>

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticsService {

  constructor(
    private db: AngularFirestore,
  ) { }


  getConsultDiagnostics(id:string){
    return this.db.collection("consultation").doc(id).collection("Diagnostics").valueChanges();
  }


}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgForm, UntypedFormBuilder } from '@angular/forms';
import { fbRemoteConfig } from 'src/app/authUtils';
import { NULL_EXTERNAL_PATIENT } from 'src/app/core/interfaces/const/interfaces';
import { ExternalPatient } from 'src/app/core/services/interfaces';
import { DataService } from '../../accounts/data.service';
import { getFunctions, httpsCallable } from "firebase/functions";
import firebase from "firebase/compat/app";
import { environment } from 'src/environments/environment';

const app = firebase.initializeApp(environment.firebaseConfig);
const functions = getFunctions(app);

@Component({
  selector: 'app-external-patient-form',
  templateUrl: './external-patient-form.component.html',
  styleUrls: ['./external-patient-form.component.scss']
})
export class ExternalPatientFormComponent implements OnInit{
  @Output() close = new EventEmitter<boolean>(); 

  account:ExternalPatient = NULL_EXTERNAL_PATIENT;
  settings = fbRemoteConfig.defaultConfig;

  


  constructor(
    private db: AngularFirestore,
    private https: HttpClient,
    public formBuilder: UntypedFormBuilder,
    private dataService: DataService,
    
  ) { }


  ngOnInit() {
    
  }


  async createUserViaEmail(){
    this.account.email = this.account?.email?.toLowerCase() ? this.account?.email?.toLowerCase() : "";
    this.account.phone = `+1${this.account.phone}`;

    try{
      const resend = httpsCallable(functions, 'https_generate-external-user');
      let result = await resend(this.account);
      return result.data;

    }
    catch(err){
    }
    // return this.https.post(`https://us-central1-${this.settings.env}.cloudfunctions.net/https_generate-external-user`, body, { headers }).subscribe(res => {
    //   if(res){
    //     let btn = <HTMLButtonElement>document.getElementById('create-btn');
    //     btn.disabled = true;
    //     btn.innerHTML = res['message'];
    //     setTimeout(() => {
    //       this.close.emit(true);
    //     }, 2500);
    //   }
    // });
  }


  createUser() {
    this.account.email = this.account?.email?.toLowerCase() ? this.account?.email?.toLowerCase() : null;
    this.account.finishedSignup = true;
    //* For creating a new user via phone, originally created to handle the creation for the external prescriptions process
    if(this.account.email == null && this.account.accountType == "patient"){
      this.createUserViaEmail()
    }
    else{
      const generatePassword = (
        passwordLength = 12,
        passwordChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
      ) => [...window.crypto.getRandomValues(new Uint32Array(passwordLength))]
          .map(x => passwordChars[x % passwordChars.length])
          .join('');
          


      let body = new HttpParams();
      const headers = new HttpHeaders();
      headers.set('Content-Type', 'application/json');
      headers.set('Access-Control-Allow-Origin', '* ');
      
      this.dataService.createUser(this.account.email.trim(), generatePassword(), this.account.accountType, this.account.typeOfPractitioner)
      setTimeout(() =>{
        let response = this.dataService.handleResponse();
        if(response){
          setTimeout(() => {
            this.close.emit(true);
          }, 2500);
        }
      }, 5000);
    }
  }
}



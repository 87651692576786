import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuditTraceService } from 'src/app/core/services/audit-trace.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { BaseProduct, Profile } from 'src/app/core/services/interfaces';

@Component({
  selector: 'app-change-shipping',
  templateUrl: './change-shipping.component.html',
  styleUrls: ['./change-shipping.component.scss']
})
export class ChangeShippingComponent implements OnInit {
  @Input() consult: any;
  @Input() order: any;
  @Input() productToChange:  any;
  @Input() orderLineProduct:  any;
  @Output() closeChangeProduct = new EventEmitter<boolean>();
  @Output() selectedProduct = new EventEmitter<any>();

  user: any;
  products: any[] = [];
  selection: BaseProduct;
  disableBtn = false
  
  constructor(
    private afs: AngularFirestore,
    private authService: AuthenticationService,
    private auditTraceService: AuditTraceService,
    private firebaseService: FirebaseService
  ) { }

  async ngOnInit() {
    this.orderValueChanges();
    if(this.orderLineProduct !== undefined){
      this.orderLineProduct.parentProductId = Number(this.orderLineProduct.parentProductId); 
      this.orderLineProduct = Object.assign({id:Number(this.orderLineProduct.parentProductId) }, this.orderLineProduct);
      this.selection = this.orderLineProduct;
    }
    this.user = await this.authService.profile();
    this.availablePacks();
  }

  async availablePacks(){
    this.afs.collection("products", ref => ref.where("type", "==", "shipping")).valueChanges().subscribe(async (snapshot:any) => {
      this.products = snapshot
      this.products.sort((a,b) => Number(a.price) - Number(b.price));
    });
  }

  async changeProduct(){
    // let btn = document.getElementById("change-btn");
    let product:any;
    this.disableBtn = true;
    // btn.innerHTML = "<span class='spinner spinner-border'></span>";
    this.selectedProduct.emit(this.selection);
    this.afs.firestore.collection("orders").doc(this.order.id).collection("orderLines").get().then(async (lines) => {
      let promo = lines.docs.filter(x => x.data()?.promo == true);
      if(promo.length > 0){

        promo.forEach(async (line, i) => {
          this.afs.collection("orders").doc(this.order.id).collection("orderLines").doc(line.data().productID).delete();
          if(i == promo.length -1){
            this.afs.collection("orders").doc(this.order.id).collection("orderLines").doc(this.orderLineProduct.productID.toString()).delete();
            this.afs.collection("orders").doc(this.order.id).collection("orderLines").doc(this.selection.productID.toString()).set(this.selection);
            this.afs.collection("consultation").doc(this.consult.ID).update({shippingMethod: this.selection.productName});
            
            let patient:Profile = await this.authService.getUserProfile(this.order.uid);
            
            if(this.user.accountType === "admin"){
              let trace = {
                id: this.firebaseService.getDate(),
                date: new Date(),
                userName: patient.firstName + " " + patient.lastName1,
                userUID: this.order.uid,
                description: `Changed shipping to ${this.selection.productName}`,
                authorizedAdminName: this.user.firstName + " " + this.user.lastName1,
                authorizedAdminUID: this.user.uid     
              }
              this.auditTraceService.changeShippingInOrder(trace, this.order.id);
            }
        
        
            this.afs.collection("orders").doc(this.order.id).update({orderLineUpdate:1, orderState: "draft"})
            this.afs.collection("orders").doc(this.order.id).collection("orderLines").valueChanges().subscribe(async (lines:any) => {
              product = lines.filter(line => line.productID == this.selection.productID)[0];
             
             
              if(product !== undefined && product.orderLineID){
                this.close();
              }
            });
          }
        });


      }else{
        this.afs.collection("orders").doc(this.order.id).collection("orderLines").doc(this.orderLineProduct.productID.toString()).delete();
        this.afs.collection("orders").doc(this.order.id).collection("orderLines").doc(this.selection.productID.toString()).set(this.selection);
        this.afs.collection("consultation").doc(this.consult.ID).update({shippingMethod: this.selection.productName});
        
        let patient:Profile = await this.authService.getUserProfile(this.order.uid);
        
        if(this.user.accountType === "admin"){
          let trace = {
            id: this.firebaseService.getDate(),
            date: new Date(),
            userName: patient.firstName + " " + patient.lastName1,
            userUID: this.order.uid,
            description: `Changed shipping to ${this.selection.productName}`,
            authorizedAdminName: this.user.firstName + " " + this.user.lastName1,
            authorizedAdminUID: this.user.uid     
          }
          this.auditTraceService.changeShippingInOrder(trace, this.order.id);
        }
    
    
        this.afs.collection("orders").doc(this.order.id).update({orderLineUpdate:1, orderState: "draft"})
        this.afs.collection("orders").doc(this.order.id).collection("orderLines").valueChanges().subscribe(async (lines:any) => {
          product = lines.filter(line => line.productID == this.selection.productID)[0];
         
         
          if(product !== undefined && product.orderLineID){
            this.close();
          }
        });
      }
    });
  }

  handleSelection(shipping: any){
    let selected: BaseProduct = {
      id: uuidv4(),
      image: shipping.imgRef,
      productID: shipping.id.toString(),
      productName: shipping.name,
      productPrice: shipping.price,
      productQty: "1",
      recurrency_order: false,
      type: shipping.type,
      orderLineID: "",
      automaticPayment:false

      // promoProductID: shipping.promoProductID ? shipping.promoProductID.toString() : '0'
    }
    this.selection = selected;
  }
   
  disableChange(){
    if(this.selection == undefined){
      return true;
    }else{
      return false;
    }
  }

  setChecked(shipping){
    if(this.selection !== undefined && this.selection.productID == shipping.id){
      return true;
    }else{
      return false
    }
  }

  close(){
    this.closeChangeProduct.emit(true);
  }

  orderValueChanges(){
    // this.afs.collection("orders").doc(this.order.id).collection("orderLines").valueChanges().subscribe((lines:any) => {
    //   let rewards = lines.filter(line => line.type == "service");
    //   rewards.forEach((reward, index) => {
    //     this.afs.collection("orders").doc(this.order.id).collection("orderLines").doc(reward.productID).delete();
    //     if(index == rewards.length -1){
    //       this.afs.collection("orders").doc(this.order.id).update({
    //         orderLineUpdate: 1,
    //         orderState: "draft"
    //       });
    //     }
    //   });
    // });
    this.afs.collection("orders").doc(this.order.id).valueChanges().subscribe((order:any) => {
      this.afs.collection("orders").doc(order.id).collection("orderLines").valueChanges().subscribe((lines:any) => {
        let product = lines.filter(line => line.productID == this.selection.productID);
        if(product.orderLineID && this.disableBtn){
          this.close();
        }
      });
    });
  }
}

function uuidv4(): string {
  throw new Error('Function not implemented.');
}

import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pharmacy-prescriptions',
  templateUrl: './pharmacy-prescriptions.component.html',
  styleUrls: ['./pharmacy-prescriptions.component.scss']
})
export class PharmacyPrescriptionsComponent {
  base64;

  constructor(private router: Router,
              private afs: AngularFirestore) {

    if(router.url.includes('prescriptionId')){
      const prescriptionId = router.url.split('prescriptionId=')[1];
      this.afs.collection('prescription').doc(prescriptionId).get().subscribe((prescription: any) => {
        this.base64 = prescription.data().z_base64;
      });
    }
  }
}

import { AfterViewChecked, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

import { ChatMessage } from '../chat/chat.model';

import {AngularFireAuth} from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { ChatService } from '../chat/chat.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PdfService } from 'src/app/core/services/pdf.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
/**
 * Chat component
 */
export class ChatComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @Output() chatLeftsidebar = new EventEmitter<Boolean>()

  chatMessagesData: ChatMessage[];
  roomData;

  // Form submit
  formData: UntypedFormGroup;
  chatSubmit: boolean;

  username: string;
  status: string;
  allSubscriptions = new Subscription()
  // bread crumb items
  breadCrumbItems: Array<{}>;

  user;

  displayUserName = [];
  currentRoom
  currentTo
  data: any;
  searchChat = '';
  displayUserNameCopy = [];
  roomId = ''
  rightsidebar = false;
  leftsidebar = true;
  currentLang = this.translate.store.currentLang

  constructor(
    public formBuilder: UntypedFormBuilder,
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public firebaseService: FirebaseService,
    public chatService: ChatService,
    public translate: TranslateService,
    private pdfService: PdfService,
    private authService: AuthenticationService
  ) {  
    authService.userSubscription.subscribe(res => { this.user = res})
    if(this.user){
      let sub1 = this.afs.collection('chats', ref => ref.where('usersUid', 'array-contains', this.user.uid)).valueChanges().subscribe(async (dataRoom: any) => {
        this.data = await dataRoom.sort((a, b) => {
          return b.roomId - a.roomId;
        });
        this.searchMessages();
      })
      this.allSubscriptions.add(sub1)
    }          
  }

  warning = ''
  async searchMessages(){
    this.displayUserName = [];
    let allMessages: any = [];
    this.warning = ''
    await this.afs.firestore.collection('chatMessages').where('toUid', 'array-contains', this.user.uid).get().then((messages: any) => {
      allMessages = messages.docs
      for(let i = 0; i < this.data.length; i++){ 
        // this.data[i].profileImgs = []
        // for(let j = 0; j < this.data[i].usersUid.length; j++){
        //   this.afs.firestore.collection('users').doc(`${this.data[i].usersUid[j]}`).get().then(async (data: any) => {
        //     this.data[i].profileImgs[j] = await data.data()?.image
        //   });
        // }
        let lastMessageInRoom = allMessages.filter(m => this.data[i].roomId == m.data().roomId)
        let index = this.data[i].roomUsers.indexOf(this.user.email)
        if(lastMessageInRoom.length > 0){
          this.displayUserName[i] = {message: lastMessageInRoom[lastMessageInRoom.length - 1].data().message, 
                                     lastMessageDate: lastMessageInRoom[lastMessageInRoom.length - 1].data().dateCreated,
                                     read:  lastMessageInRoom[lastMessageInRoom.length - 1].data().read[index], 
                                     data: this.data[i]};
        }
        else {
          this.displayUserName[i] = {message: '', 
                                     lastMessageDate: this.data[i].dateCreated,
                                     read:  true, 
                                     data: this.data[i]};
        }
        if(i == this.data.length - 1){
          this.displayUserNameCopy = this.sortData()
        }   
      }
      if(allMessages.length == 0){
        this.warning = 'NOTIFICATIONS.CHAT.NOCHATS'
      }
    }) 
  }

  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });

    this.leftsidebar = true
    this.chatLeftsidebar.emit(true);
    this.rightsidebar = false;
  }

  chatUsername(roomInfo, openedChat) {
    this.roomId = roomInfo.data.roomId
    this.roomData = roomInfo.data

    let sub = this.afs.collection("chats").doc(this.roomId).valueChanges().subscribe((data1: any) => {
      if(this.roomId != 'noRoom'){
        data1.profileImgs = this.roomData.profileImgs
  
        this.afs.firestore.collection("chatMessages").where('toUid', 'array-contains', this.user.uid).where('roomId', '==', this.roomId).get().then(data => {
          let messages = data.docs
          this.chatMessagesData = []
          let align = 'left'
          for(let i = 0; i < messages.length; i++){
            align = messages[i].data().from == this.user.email ? 'right' : 'left'
            let isMessageRead = undefined;

            for(let j = 0; j < data1.roomUsers.length; j++){
              if(messages[i].data().read[j] != undefined && messages[i].data().read[j] == false){
                isMessageRead = false
              }

              if(j == data1.roomUsers.length - 1 && isMessageRead == undefined){
                isMessageRead = true
              }
            }
            
            let index = data1.roomUsers.indexOf(messages[i].data().from)
            this.chatMessagesData.push({
              align: align,
              name: data1.userNames[index],
              message: messages[i].data().message,
              time: messages[i].data().dateSent + ' ' + messages[i].data().timeSent,
              image: data1.profileImgs[index],
              read: messages[i].data().read,
              messageId: messages[i].data().messageId,
              messageRead: isMessageRead,
            });          
          
            let index1 = data1.roomUsers.indexOf(this.user.email)
            if(messages[i].data().read[index1] != undefined && typeof(messages[i].data().read) != 'boolean' && messages[i].data().read[index1] != true && this.chatMessagesData[i].time != undefined && index1 != -1){
              this.chatMessagesData[i].read[index1] = true;

              if(this.myScrollContainer && this.myScrollContainer.nativeElement.scrollTop != this.myScrollContainer.nativeElement.scrollHeight){
                this.showScroll = true;
              }
              this.firebaseService.readChats(this.roomId, {id: messages[i].data().messageId, read: this.chatMessagesData[i].read});
            }
            if(i == messages.length - 1 && openedChat){
              this.scrollToBottom();
              openedChat = false;
            }
          } 
          if(messages.length == 0){
            this.warning = 'NOTIFICATIONS.CHAT.NOCHATS'
          }
        });
      }
    })
    this.allSubscriptions.add(sub)

    if(this.roomId != 'noRoom'){
      this.username = this.roomData.userNames.join(', ').replace(', ' + this.user.firstName + ' ' + this.user.lastName1, '').replace(this.user.firstName + ' ' + this.user.lastName1 + ', ', '').replace(this.user.firstName + ' ' + this.user.lastName1, '');
      this.chatMessagesData = [];
      this.currentRoom = this.roomId;
      this.currentTo = this.roomData.roomUsers
      this.status = this.roomData.status;
    }
  }

  get form() {
    return this.formData.controls;
  }

  //Save the message in chat
  messageSave() {
    const message = this.formData.get('message').value;
    const currentDate = new Date();
    let Month
    let Day
    if((currentDate.getMonth() + 1) <= 9){
      Month = '0' + (currentDate.getMonth() + 1)
    }
    else{
      Month = (currentDate.getMonth() + 1)
    }
    if((currentDate.getDate()) <= 9){
      Day = '0' + currentDate.getDate()
    }
    else{
      Day = currentDate.getDate()
    }

    let dateSent = Month+ '/' + Day + '/' + currentDate.getFullYear()
    if (message) {

      const room = {
        numberOfMessages: this.chatMessagesData.length + 1
      }

      let read = []
      for(let i = 0; i < this.currentTo.length; i++){
        if(this.currentTo[i] == this.user.email){
          read.push(true)
        }
        else {
          read.push(false)
        }
      }

      const messages = {
        messageId: this.firebaseService.getDate(),
        message: message,
        timeSent: this.formatAMPM(new Date),
        dateSent: dateSent,
        dateCreated: currentDate,
        to: this.currentTo,
        from: this.user.email,
        read: read,
        roomId: this.currentRoom,
        consultId: this.roomData.consultId,
        toUid: this.roomData.usersUid,
        toUserNames: this.roomData.userNames
      }   

      this.afs.collection("chatMessages").doc(messages.messageId).set(messages);
   
      this.afs.collection("chats").doc(this.currentRoom).update(room).then(
        res => {
          this.scrollToBottom();
        }
      );
      this.formData = this.formBuilder.group({
        message: [''],
      }); 
    }
    this.chatSubmit = true;  
  }

  currentConsult
  canWrite = true;
  consult(id){

    this.afs.firestore.collection('consultation').doc(id).get().then(consult => {
      this.currentConsult = consult.data()

      return new Promise<any>(async (resolve, reject) => {
        let userUid = this.user.accountType == 'patient' ? 'uid' : 'docId'
        this.afs.firestore.collection("prescription").where(userUid, '==', this.user.uid).where("consultID", "==", id).where('internal', '==', true).get().then(prescriptions => {
          if(this.currentConsult.Status != 'Completed'){
            this.canWrite = true;
            // reject(false)
          }
          else if(prescriptions.empty && this.currentConsult.Status == 'Completed'){
            this.canWrite = false;
          }
          else{
            prescriptions.docs.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              if(doc.data().authenticatorValidationDate){
                let date = doc.data().authenticatorValidationDate.seconds * 1000 + 7*24*60*60*1000; //signed date + 7 days
                let today = Date.now();

                if(today >= date){
                  this.canWrite = false
                }
                else {
                  this.canWrite = true
                }
              }
              else {
                this.canWrite = false
              }
  
              resolve(doc.data());
              return;
            });
          }
        })
        .catch((error) => {
                      });
      });
    })

  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime; 
  } 

  deleteChat(){
    this.afs.firestore.collection('chatMessages').where('toUid', 'array-contains', this.user.uid).where('roomId', '==', this.currentRoom).get().then((messages: any) => {

      let Messages = messages.docs;
      for(let i = 0; i < Messages.length; i++){
        this.afs.collection('chatMessages').doc(Messages[i].data().messageId).delete()
      } 
      this.firebaseService.deleteChat(this.currentRoom);
      this.hide(false);   
    })
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.firstName.toLocaleLowerCase().indexOf(term) > -1 || 
    item.lastName1.toLocaleLowerCase().indexOf(term) > -1 || 
    (item.firstName + " " + item.lastName1).toLocaleLowerCase().indexOf(term) > -1 || 
    item.email.toLocaleLowerCase().indexOf(term) > -1;
  }

  hide(bool: Boolean){
    if(bool){
      this.leftsidebar = false;
      this.chatLeftsidebar.emit(false)
      this.rightsidebar = true
      this.scrollToBottom();
    }
    else {
      this.leftsidebar = true;
      this.chatLeftsidebar.emit(true)

      this.rightsidebar = false;
      this.chatMessagesData = [];
      this.roomId = 'noRoom';
      this.scrollToBottom();
      this.chatUsername({data: {roomId: this.roomId}}, false)
      this.currentRoom = '';
    }    
  }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
    this.chatMessagesData = [];
    this.roomId = 'noRoom';
    this.currentRoom = ''
  }

  sortData() {
    let data = this.displayUserName.sort((a, b) => {
      return b.lastMessageDate - a.lastMessageDate;
    }); 
   
    return data
  }
  
  showScroll: Boolean = false;
  ngAfterViewChecked() { 
    // if(this.myScrollContainer && this.myScrollContainer.nativeElement.scrollTop != this.myScrollContainer.nativeElement.scrollHeight){
    //   this.showScroll = true;
    // }
    // this.scrollToBottom();        
  } 

  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        this.showScroll = false;
      } catch(err) { }                 
  }
}
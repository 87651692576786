<div *ngIf="!completed" class="new-patient">
  <div class="d-flex flex-column">
    <div class="heading">
      <h3>{{'MEDICAL-HISTORY.FILL' | translate}}</h3>
      <span class="mx-auto">{{'MEDICAL-HISTORY.SUBHEADER' | translate}}</span>
    </div>
    <div *ngIf="currentQuestion" class="questions">
      <h4>{{currentQuestion.question | translation:currentLang}}</h4>
      <span class="mx-auto">{{currentQuestion.subquestion | translation:currentLang}}</span>

      <div *ngIf="!currentQuestion.options" class="BMI w-100">
        <div class="height">
          <label for="feet" class="mx-auto" style="font-size: 26px;">{{'MEDICAL-HISTORY.HEIGHT' | translate}}</label>
          <input id="feet" type="tel" id="height-inp" name="heigth" maxlength="1" class="bmi-inputs" (blur)="onConditionSelected($event, currentQuestion)" [(ngModel)]="currentQuestion.answer[0]">
        </div>
        <div class="height">
          <label for="inches" class="mx-auto" style="font-size: 26px;">{{'MEDICAL-HISTORY.INCHES' | translate}}</label>
          <input id="inches" type="tel" id="height-inp" name="inches" maxlength="2" min="0" max="11" class="bmi-inputs" (blur)="onConditionSelected($event, currentQuestion)" [(ngModel)]="currentQuestion.answer[1]">
        </div>
        <div class="height">
          <label for="pounds" class="mx-auto" style="font-size: 26px;">{{'MEDICAL-HISTORY.WEIGHT' | translate}}</label>
          <input id="pounds" type="tel" name="weight" maxlength="3" class="bmi-inputs" (blur)="onConditionSelected($event, currentQuestion)" [(ngModel)]="currentQuestion.answer[2]"> 
        </div>
      </div>

      <div class="options" *ngFor="let option of currentQuestion.options">

        <ng-select style="max-width:500px" class="searchConditions mb-4 w-100 mx-auto" *ngIf="currentQuestion.type === 'SearchSurgeries'" placeholder="{{'HEADER.SEARCH' | translate}}" [items]="filteredSurgeries" [loading]="loading" 
          bindLabel="0" [multiple]="true" (search)='searchSurgeries($event)' (change)="saveConditions($event, currentQuestion)"  [(ngModel)]="currentQuestion.answer">
        </ng-select>
        <ng-select style="max-width:500px" class="searchConditions mb-4 w-100 mx-auto" *ngIf="currentQuestion.type === 'SearchConditions'" placeholder="{{'HEADER.SEARCH' | translate}}" [items]="filteredConditions" [loading]="loading" 
          bindLabel="0" [multiple]="true" (search)='Rx_getConditions($event)' (change)="saveConditions($event, currentQuestion)" [(ngModel)]="currentQuestion.answer">
        </ng-select>
        <ng-select style="max-width:500px" class="custom text-danger mb-4 w-100 mx-auto" *ngIf="currentQuestion.type === 'SearchMedicines'" placeholder="{{'HEADER.SEARCH' | translate}}"   [loading]="loading"
          [multiple]="true" [(ngModel)]="currentQuestion.answer"  (keyup)="getNDC($event)" (change)="saveMeds($event, currentQuestion)">
          <ng-option *ngFor="let med of medicines" [value]="med">{{med.name}}</ng-option>
        </ng-select>

        <div class="grid-wrapper mx-auto">
          <div class="selection-wrapper animate__animated animate__fadeInDown">
            <label for="option" class="radio-card">
              <!-- <input *ngIf="currentQuestion.type != 'Multiple'" class="selects" type="radio" name="selected-item" [id]="option" [(ngModel)]="currentQuestion.answer" [checked]="questions[currentQuestionIndex].answer.includes(option)" [value]="option" /> -->
              <input *ngIf="currentQuestion.type != 'Multiple'" class="selects" type="radio" name="selected-item" [id]="option" [(ngModel)]="currentQuestion.answer"  [value]="option" />
              <!-- <input *ngIf="currentQuestion.type == 'Multiple'" class="selects" type="checkbox" name="selected-item" [id]="option" [value]="option" (click)="saveMultipleAnswer($event, currentQuestion);" [ngModel]="setChecked(option, currentQuestion)" /> -->
              <input *ngIf="currentQuestion.type == 'Multiple'" class="selects" type="checkbox" name="selected-item" [id]="option" [value]="option" [checked]="currentQuestion.answer.includes(option)" (change)="saveMultipleAnswer($event, option, currentQuestion)"/>
              <div class="card-content-wrapper">
                <span *ngIf="currentQuestion.type != 'Multiple'" class="check-icon"></span>
                <span *ngIf="currentQuestion.type == 'Multiple'" class="icon-check"></span>
                <div class="card-content">
                  <h5>{{ option | translation:currentLang}}</h5>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="options mb-1" *ngIf="currentQuestion.type === 'SelectionExplain' && currentQuestion.answer === currentQuestion.options[currentQuestion.options.length -1]">
        <div class="selection-explain" >
          <div class="selection-wrapper animate__animated animate__fadeInDown" >
            <label for="explain" class="selected-label answers animate">
              <span class="d-flex justify-content-start" style="text-align: center;" >{{currentQuestion?.subquestion | translation:currentLang}}</span>
              <div class="answer-card mx-auto p-0" style="background-color: transparent; width: 90%;">
                <div class="text d-flex" style="flex-direction: column;">
                  <!-- <textarea [(ngModel)]="currentQuestion.explain" (blur)="saveExplanation($event, currentQuestion)" placeholder="{{currentQuestion.hints | translation: currentLang}}"></textarea> -->
                  <textarea id="explain" [(ngModel)]="currentQuestion.explain" placeholder="{{currentQuestion.hints | translation: currentLang}}"></textarea>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="action-buttons">
      <button type="button" class="btn btn-primary action-btn" (click)="back()">Previous</button>
      <button id="next-btn" type="button" class="btn btn-primary action-btn" (click)="saveAnswers(currentQuestion); next()">Next</button>
      <!-- <button id="next-btn" type="button" class="btn btn-primary action-btn" (click)="next()">Next</button> -->
    </div>
  </div>
</div>

<div *ngIf="completed" class="completed">
  <div class="d-flex flex-column">
    <h3>{{'MEDICAL-HISTORY.COMPLETED' | translate}}</h3>
    <span class="mx-auto">{{'MEDICAL-HISTORY.COMPLETED-THANKS' | translate}}</span>


    <div class="check-wrap"></div>
  </div>
</div>





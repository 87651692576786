import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NULL_MEDICAL_INSURANCE } from 'src/app/core/interfaces/const/interfaces';
import { MedicalInsurance, Profile } from 'src/app/core/services/interfaces';
import { MedicalInsuranceService } from 'src/app/core/services/medical-insurance/medical-insurance.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-medical-insurance',
  templateUrl: './medical-insurance.component.html',
  styleUrls: ['./medical-insurance.component.scss']
})



export class MedicalInsuranceComponent implements OnInit, OnDestroy{

  @Input() user: Profile;

  medicalInsurance: MedicalInsurance = NULL_MEDICAL_INSURANCE;
  insuranceTemplates: any = [];
  
  constructor(
    private medicalInsuranceService: MedicalInsuranceService,
    private db: AngularFirestore,
    private auth: AuthenticationService
  ) { }

  async ngOnInit(): Promise<void> {
    this.user = await this.auth.profile() as unknown as Profile;
    const medicalInsurance = await this.medicalInsuranceService.getMedicalInsurance(this.user.uid);
    let test = medicalInsurance.docs.map(doc => doc.data());
    test.sort((a: any, b: any) => {
      return a.date - b.date;
    })
    if (test[0]) {
      this.medicalInsurance = test[0] as MedicalInsurance;
    }

    this.insuranceTemplates = await this.medicalInsuranceService.getMedicalInsuranceTemplates();
    this.insuranceTemplates = this.insuranceTemplates.docs.map(doc => doc.data());
  }


  ngOnDestroy(): void {
    
  }

  async saveMedicalInsurance() {
    this.medicalInsuranceService.saveMedicalInsurance(this.user.uid, this.medicalInsurance, this.medicalInsurance.url, this.medicalInsurance.id);
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-biological-gender',
  templateUrl: './biological-gender.component.html',
  styleUrls: ['./biological-gender.component.scss']
})
export class BiologicalGenderComponent implements OnInit, OnDestroy{
  @Input() socialGenderValue: string = "";
  @Input() previousStep = '';
  @Input() styling = ''
  @Output() biologicalGender = new EventEmitter<any>(); 
  @Output() next = new EventEmitter<void>(); 
  @Output() componentLoaded = new EventEmitter<void>();
  @Output() componentUnloaded = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();
  @Input() background = 'white';
  
  biological_genders = ['PROFILE.MALE', 'PROFILE.FEMALE'];
  constructor(){}

  ngOnInit(): void {
    this.componentLoaded.emit();
    // if((this.socialGenderValue == 'Male' || this.socialGenderValue == 'Female') && (this.previousStep == 'gender' || this.previousStep == '8')){
    //   this.emit(this.socialGenderValue);
    // }
  }

  emit(value:string){
    this.biologicalGender.emit({target: {value: value, name: "biologicalgender"}});
    this.next.emit();
  }

  ngOnDestroy(): void {
    this.componentUnloaded.emit();
  }
}

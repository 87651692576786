import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthenticationService } from '../auth.service';
import { MEDICAL_CONSULTATION, LAB_RESULTS, PRESCRIPTION } from '../../enums/process-history';
import { ConsultHistory, History } from '../../interfaces/history';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    private db: AngularFirestore,
  ) { }

  getHistory(uid: string) {
    return this.db.collection('history', ref => ref.where('uid', '==', uid)).valueChanges();
  }

  getHistoryByConsultationId(consultationId: string) {
    return this.db.collection('history', ref => ref.where('consultationId', '==', consultationId)).valueChanges();
  }

  getHistoryByConsultationIdAndType(consultationId: string, type: string) {
    return this.db.collection('history', ref => ref.where('consultationId', '==', consultationId).where('type', '==', type)).valueChanges();
  }

  //store history in collection called history
  storeHistory(history: History) {
    return this.db.collection('history').doc(history.id).set(history);
  }

  //store history in subcollection called history under the consultation collection
  storeHistoryInConsultation(history: ConsultHistory) {
    return this.db.collection('consultation').doc(history.consultID).collection('History').doc(history.id).set(history);
  }
}
  


import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss']
})
export class AgreementsComponent implements OnInit {

  terms:any;
  signature:string;

  @ViewChild('signaturePad', { read: TemplateRef }) signaturePadModal:TemplateRef<any>;
  constructor(private afs: AngularFirestore, private modalService: NgbModal,) { }

  ngOnInit(): void {
    fetch("http://www.geoplugin.net/json.gp").then(response => response.json()).then(data => {
      // console.error("FETCHED IP GEOPLUGIN", data);
    });
    fetch("http://ip-api.com/json").then(response => response.json()).then(data => {
      // console.error("FETCHED IP-API", data);
      // this.afs.collection.
    });
  }


  async ngAfterViewInit() {
    this.afs.collection("settings").doc("Terms").collection("Terms").doc("0001").valueChanges().subscribe(async(res:any) => {
      this.terms = await res.html_en;
      await this.createDoc();
    });
  }


  createDoc(){
    let page = document.getElementById("page");
    page.innerHTML = this.terms;
    let element = document.createElement("div");
    let div2 = document.createElement("div");
    let div3 = document.createElement("div");
    let placeholder = document.createElement("h3");
    let hr = document.createElement("hr");
    placeholder.innerHTML = "Signature";
    element.classList.add("sign");
    // element.setAttribute("onclick","setAttributes();");
    page.appendChild(div2);
    div2.classList.add("signature");
    div2.classList.add("d-flex");
    div3.classList.add("d-flex");
    div3.classList.add("flex-column");
    div3.classList.add("signature-placeholder");
    div2.appendChild(placeholder);
    div2.appendChild(div3);
    div3.appendChild(hr);
    page.appendChild(element);
    placeholder.style.fontFamily = "Times New Roman";
    div3.style.width = "100%";
    div3.style.marginTop = "7%";
    element.style.background = "yellow";
    element.style.width = "45%";
    element.style.height = "10vh";
    element.style.borderRadius = "15px";
    element.style.margin = "auto";
    element.style.marginTop = "-12%";
    element.style.cursor = "pointer";
    hr.style.width = "100%";
    element.addEventListener("click", () => {
      //bring up the signature pad
      this.openSignaturePad();
    })
    // element.style.opacity = "0.5";
  }

  openSignaturePad(){
    this.modalService.open(this.signaturePadModal, { centered: true, size: "lg" });
  }

  
  getSignature(signature:string){
    this.signature = signature;
    // console.log('catched',this.signature);
    let element = document.querySelectorAll<HTMLElement>('.sign')[0];
    let div2 = document.querySelectorAll<HTMLElement>('.signature')[0];
    let div3 = document.querySelectorAll<HTMLElement>('.signature-placeholder')[0];
    element.style.display = "none";
    let img = document.createElement("img");
    img.src = this.signature;
    let page = document.getElementById("page");
    let hr = document.querySelectorAll<HTMLElement>('.hr')[0];
    img.style.width = "20%";
    img.style.marginTop = "2%";
    img.style.background = "#A9BBD0";
    img.style.borderRadius = "15px";
    img.style.opacity = "0.5";
    div3.classList.remove("flex-column");
    div3.style.flexDirection = "column-reverse";
    div3.style.marginTop = "0%";
    div3.style.marginLeft = "5%";

    //EDIT SIGNATURE
    let edit = document.createElement("div");
    edit.style.borderRadius = "50px";
    edit.style.width = "10%";
    edit.style.height = "10%";
    edit.style.background = "#A9BBD0";
    div3.appendChild(edit);
    div3.appendChild(img);
  }



}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthenticationService } from '../auth.service';
import { FirebaseService } from '../firebase.service';
import { Profile } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  user:Profile;
  orders:any[] = [];
  consults:any[] = [];
  telehealth: any[] = [];
  helpdesk: any[] = [];


  constructor(
    private db: AngularFirestore,
  ) {
      
    }

  async getOrders(user:Profile){
    return new Promise<any>((resolve, reject) => {
      this.db.collection("orders", ref => ref.where("uid", '==', user.uid)).valueChanges().subscribe(async (orders:any) => {
        if(orders.length > 4){
          this.orders = orders.sort(this.sortFunction);
          this.orders = this.orders.filter(order => order.paymentStatus && order.orderNo && (order?.productType != 'service' || order.paymentStatus == 'paid'))
          // console.log("this.orders", this.orders);
          
          let lastFive = this.orders.slice((orders.length - 5), orders.length);
          // console.log("lastFive", lastFive)
          resolve(lastFive);
          return
        }
        else {
          orders = orders.filter(order => order.paymentStatus && order.orderNo && (order?.productType != 'service' || order.paymentStatus == 'paid'))
          resolve(orders);
        }
      });
    })
  }
  async getConsults(user:Profile){
    return new Promise<any>((resolve, reject) => {
      if(user){
        let typeofUid = user.accountType == 'practitioner' ? 'UID_Practitioner' : 'UID_Patient'
        this.db.collection("consultation", ref => ref.where(typeofUid, '==', user.uid).where('Status', '!=', 'Canceled')).valueChanges().subscribe(async (consults:any) => {
          // this.consults = consults;
          if(consults.length > 4){
            this.consults = consults.sort(this.sortFunction);
            
            let lastFive = this.consults.slice((consults.length - 5), consults.length);
            // console.log("lastFive consults", lastFive)
            resolve(lastFive);
            return
          }
          resolve(consults)
        });
      }
      else {
        resolve([]);
      }
    });
  }
  async getTelehealth(user:Profile){
    return new Promise<any>((resolve, reject) => {
      if(user){
        var today: any = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd
        let typeofUid = user.accountType == 'practitioner' ? 'uidPractitioner' : 'uidPatient'
        this.db.collection("calendar", ref => ref.where(typeofUid, '==', user.uid).where('start', '>=', today)).valueChanges().subscribe(async (events:any) => {
          // this.telehealth = events;
          resolve(events);
        });
      }
      else {
        resolve([]);
      }
    });
  }
  
  sortFunction(a,b){  
    var dateA = new Date(a.date).getTime();
    var dateB = new Date(b.date).getTime();
    return dateA > dateB ? 1 : -1;  
  }; 

}

<div class="">
  <app-pagetitle *ngIf="this.route.url.startsWith('/telehealth')" [title]="pagetitle"></app-pagetitle>
  <div class="px-3">
    <div *ngIf="!this.route.url.includes('/followUp')" class="row w-100 d-flex m-0" style="flex-direction: column;">
      <p *ngIf="userAccountType" class="m-0">{{'TELEHEALTH.PROFILETIME' | translate}} <a href='/profile'>{{time | titlecase}}</a></p>
      <p *ngIf="userAccountType" class="m-0">{{'TELEHEALTH.PROFILETIMEZONE' | translate}} <a href='/profile'>{{this.userAccountType?.timezone?.name}}</a></p>
    </div>
    <div class="cont d-flex mt-3">
      <div class="calendar">
        <div class="text-center" *ngIf="!route.url.startsWith('/telehealth') && !this.route.url.includes('/followUp') && userAccountType && userAccountType.accountType == 'practitioner'">
          <button style="margin-top: 10%;" class="btn btn-info btn-cyan btn-md" (click)="showModalAddEvent(addEventModal)">{{'EVENTS.ADDEVENT' | translate}}</button>
        </div>
        <full-calendar [ngClass]="{'disableCalendar': this.route.url.includes('/followUp')}" #calendar [options]="calendarOptions" class="calendar modal-calendar"></full-calendar>
        <!-- <ng-moment-timezone-picker [hidden]="true" [getUserZone]="true" [(ngModel)]="currentTimezone" >
        </ng-moment-timezone-picker> -->
        <ng-select class="custom select mt-0" hidden style="display: none !important;" [ngModel]="currentTimezone" id="timezone" name="timezone">
          <ng-option *ngFor="let timezone of timezoneList">{{timezone.name}}</ng-option>
        </ng-select> 
        <ng-template #addEventModal let-modal>
          <app-add-event [modalRef]="modalRef"></app-add-event>
        </ng-template>
      </div>
      <div *ngIf="!this.route.url.includes('/followUp')" class="appointments">
        <h3 class="mx-3 mt-2">{{'APPOINTMENTS.SCHEDULED' | translate}}</h3>
  
        <div *ngFor="let appointment of appointments">   
          <div class="appointment flex-column" *ngIf="appointment.end > currentDate">
            <div class="">
              <div class="duration">
                <span class="time">{{appointment.duration}} min</span>
              </div>
            </div>
    
            <div class="details-div">
              <div class="">
                <p class="title m-0">{{appointment.title}}</p>
              </div>
              <div class="d-flex justify-content-end flex-column mb-2">
                <span class="bold">{{ appointment.start | date:'MMMM dd, yyyy':'':userAccountType.preferedLang | titlecase}}</span>
                <span>{{ appointment.start | date:'h:mm a':'':userAccountType.preferedLang | titlecase}} - {{ appointment.end | date:'h:mm a':'':userAccountType.preferedLang | titlecase }}</span>
              </div>
              <div *ngIf="appointment.room" class="actions flex-column">
                <a class="meet-link" target="_blank" href="{{appointment.room}}">
                    <button class="btn btn-info">
                    {{'TELEHEALTH.JOIN' | translate}}
                  </button>
                  </a>
                <p style="color: red;" *ngIf="!appointment.room && appointment.meetStatus == 'paid'">
                  {{'APPOINTMENTS.PAIDSTATUS' | translate}}
                </p>
                <p style="color: red;" *ngIf="!appointment.room && appointment.meetStatus == 'pending'">
                  {{'APPOINTMENTS.PENDINGSTATUS' | translate}}
                </p>
                <button *ngIf="!appointment.requestUpdate && appointment.meetStatus != 'paid' && appointment.meetStatus != 'pending'" class="btn btn-info mt-2" (click)="reschedule(appointment)">{{'TELEHEALTH.REQUEST' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row" style=" width: 100%; justify-content: center; height: 73vh;" [ngClass]="{'followUp': route.url.includes('/followUp')}">
     <div class="text-center" *ngIf="!route.url.startsWith('/telehealth') && !this.route.url.includes('/followUp') && userAccountType && userAccountType.accountType == 'practitioner'">
      <button style="margin-top: 10%;" class="btn btn-info btn-cyan btn-md" (click)="showModalAddEvent(addEventModal)">{{'EVENTS.ADDEVENT' | translate}}</button>
    </div>
    <full-calendar [ngClass]="{'disableCalendar': this.route.url.includes('/followUp')}" #calendar [options]="calendarOptions" class="calendar"></full-calendar>
    <ng-moment-timezone-picker [hidden]="true" [getUserZone]="true" [(ngModel)]="currentTimezone" >
    </ng-moment-timezone-picker>
    <ng-template #addEventModal let-modal>
      <app-add-event [modalRef]="modalRef"></app-add-event>
    </ng-template>
  </div><br> -->

  <!-- <div *ngIf="!this.route.url.includes('/followUp')" class="meetings">
    <h3>{{'TELEHEALTH.MEETINGS' | translate}}</h3><br>
    <div>
      <div class="row">
        <div class="col-lg-12">
          <ul class="auto-grid mr-5">
            <div *ngFor="let meet of todaysMeetings; let i = index;">
              <div *ngIf="meet.start" style="height: 100%;">
                <div class="card">
                  <h6 *ngIf="meet.consultID == consultID" [ngClass]="{ 'inConsults': meetings }">{{meet.title}}</h6>
                  <h6 *ngIf="meet.consultID != consultID" [ngClass]="{ 'notInConsults': notInThisConsults }">{{meet.title}}</h6>
                  <h6>{{userAccountType.abbr}} {{currentUserName}}</h6>
                  <h6>{{'VIEWCONSULTS.PATIENT' | translate}}<br> {{meet.patientName}}</h6>
                  <h6>{{'TELEHEALTH.STARTS' | translate}} {{meet.start | date:'h:mm a'}}</h6>
                  <div class="rowCamera justify-content-end">
                    <i class="fas fa-video join"></i>
                  </div>
                  <a class="btn btn-info" target="_blank" href="{{meet.room}}">{{'TELEHEALTH.JOIN' | translate}}</a>
                </div>  
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>  
  </div> -->
</div>

<ng-template #scheduler let-modal>
  <button type="button" class="close" style="text-align: end;" aria-label="Close" (click)="modal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
  <app-appointments-scheduler [modalRef]="modalRef" [appointment]="appointment"></app-appointments-scheduler>
</ng-template>
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Profile, VendorBill } from 'src/app/core/services/interfaces';
import { VendorBillsService } from 'src/app/core/services/vendor-bills/vendor-bills.service';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  // standalone: true,
  // imports: [MatFormFieldModule, MatDatepickerModule, MatNativeDateModule],
})


export class BillingComponent implements OnInit {
  vendorBillSubscription: Subscription;
  currentMonthTimestamp: number;

  user: Profile;

  invoices:VendorBill[] = [];
  days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  years: number[] = [];
  selectedMonth: string;
  selectedYear: number;


  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private vendorBillService: VendorBillsService,
  ) { 
    this.getCurrentMonthTimestamp()
  }

  async ngOnInit() {
    const currentYear = new Date().getFullYear();
    this.user = await this.authService.profile();

    for (let year = 2022; year <= currentYear; year++) {
      this.years.push(year);
    }

    // Set default selected values to the current month and year
    this.selectedMonth = this.months[new Date().getMonth()];
    this.selectedYear = currentYear;

    // this.vendorBillSubscription = this.vendorBillService.getVendorBill$()
    this.vendorBillSubscription = this.vendorBillService.getUserVendorBill$(this.user.uid)
    .subscribe((data) => {
      this.invoices = data.map((invoice:VendorBill) => {
        let date = new Date(invoice.date)
        invoice.date = date.toLocaleDateString();
        // invoice.amountResidual = invoice.paymentState === 'not_paid' ? invoice.amountResidual : 0;
        invoice.paymentState = invoice.paymentState === 'not_paid' ? 'Pending' : 'Paid';
        return invoice;
      });
    });
  }



  getCurrentMonthTimestamp() {  
    const currentDate = new Date();
    currentDate.setDate(1); // Set the date to the first day of the month
    currentDate.setHours(0, 0, 0, 0); // Set the time to midnight
    this.currentMonthTimestamp = currentDate.getTime(); 
  }


  


  generateTimestamp(year, month) {
    // Ensure the month is in the valid range (0-11 for JavaScript Date)
    const monthIndex = this.months.findIndex(m => m.toLowerCase() === month.toLowerCase());
    // Create a new Date object for the specified year and month (months are 0-indexed)
    const date = new Date(year, monthIndex, 1);
    // Get the Unix timestamp in milliseconds
    const timestamp = date.getTime();
    this.currentMonthTimestamp = timestamp;
    return timestamp;
  }


  

  setAmountPaid(invoice: VendorBill){
    return invoice.paymentState == 'Paid' ? invoice.amountResidual : 0
  }

  setPaymentDue(invoice: VendorBill){
    return invoice.paymentState == 'Paid' ? 0 : invoice.amountResidual;

  } 

  goToConsult(consultRef:string){
    this.router.navigate(['/consults', consultRef]);

  }






















  



  



}
  

  // // Set header nav actions
  // document.querySelectorAll(".cal-btn").forEach((btn) => {
  //   btn.addEventListener("click", () => {
  //     updateCalendar(btn);
  //     updateInput();
  //   });
  // });

  // input.addEventListener('click', () => {
  //   document.querySelector('#date_picker_calendar').classList.toggle('hidden');
  //   document.querySelector('#date_picker_input').classList.toggle('showCal');
  //   document.querySelector('#date').classList.toggle('onFocus');
  // });
  






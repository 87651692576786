import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ChangeDetectorRef, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Subscription, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { ConsultationsComponent } from '../../consultations/consultations.component'
import { ConsultationsService } from '../../consultations/consultations.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { OrdersService } from 'src/app/core/services/orders.service';
import { BaseProduct } from 'src/app/core/services/interfaces';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import { ProductService, shipping } from 'src/app/core/services/product.service';
import { Observable } from 'rxjs';
import { map, switchMap, filter, tap } from 'rxjs/operators';
import { co, el } from '@fullcalendar/core/internal-common';
import firebase from "firebase/compat/app";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})

export class CheckoutComponent implements OnInit, OnDestroy, OnChanges {
  @Input() consult: any;
  @Input() conditionRules: any;
  @Input() orderConfirmed: boolean = false;
  @Input() emitFocus: boolean = false;
  @Input() patientProfile;
  @Input() activatePromo = false;

  @Output() shippingAddress:any;
  @Output() disableHold = new EventEmitter<boolean>();
  @Output() amountDueToday = new EventEmitter<any>();
  @Output() amountDueConfirmed = new EventEmitter<any>();
  @Output() enableCheckoutBtn = new EventEmitter<Boolean>();
  @Output() order = new EventEmitter<any>();
  @Input() validateAddress = false;
  @Input() validateCard = false;
  @Output() validateAddressState = new EventEmitter<string>();
  @Output() validateCardState = new EventEmitter<string>();
  @Output() changeProduct = new EventEmitter<boolean>();

  user:any;
  shipping:any;

  orderLines: any = [];
  orderTax: any = 0;
  orderShipping: any = 0;
  orderSubtotal: any = 0;
  orderTotal: any = 0;
  discountTotal:any = 0
  orderNumber: any;
  orderAmountDueToday: number = 0;
  orderAmountDueConfirmed: number = 0;
  orderInfo;

  shippingMethod: shipping[] = [];

  creditCardRef:any;
  allPaymentMethods: any = [] ;

  orderLinesLoaded:Boolean = false;
  acceptedRefillTerms:boolean = false;

  //Modal reference
  modalRef:any;

  allSubscriptions = new Subscription()
  currentLang = this.translate.store.currentLang

  generatingOrder:boolean = true;
  wishlist:any[] = [];
  selectedItems:any[] = [];

  
  showShipmentFrequency: boolean[] = [];
  deliveryFrequencies: any[] = [];
  
  selectedShippingMethod: any;

  displayShippingMethodOptions: boolean = false;

  currentShippingMethod: null;

  chooseMedication = false;
  hasPromo = false;

  couponCode: string;
  appliedDiscount: boolean = false;
  couponError: boolean = false;
  couponValid: boolean = false;
  orderDiscount: any = 0;
  couponExistsInOrder: boolean = false;
  couponErrorExists: boolean = false;

  showAddCouponOption: boolean = false;

  applyingCoupon: boolean = false;
  couponInvalid: boolean = false;
  couponInvalidOrder: boolean = false;
  couponPreviouslyUsed: boolean = false;

  hasCheckedCoupon: boolean = false;

  private docSubscription: Subscription | undefined;
  private documentRef: AngularFirestoreDocument<any> | undefined;
  private docId$ = new BehaviorSubject<string | null>(null);

  clickedApplyCoupon: boolean = false;

  removingCoupon: boolean = false;
  
  constructor(
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public firebaseService: FirebaseService,
    private consultationsService: ConsultationsService,
    public consultation: ConsultationsComponent,
    public http: HttpClient,
    public authService: AuthenticationService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private orderService: OrdersService,
    private productService: ProductService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone
  ) { }

  ngOnDestroy(): void {
    this.allSubscriptions.unsubscribe();
    if (this.docSubscription) {
      this.docSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    this.displayAddCouponOption();
    this.listenToDocumentFieldChange();

    this.checkIfCouponsAreExpired();

    // Simulate document ID being dynamically set after component load
    if(!this.orderInfo){
      setTimeout(() => {
        const dynamicDocId = this.orderInfo?.id; // Replace with logic to get the dynamic ID
        // console.log('Setting dynamic document ID:', dynamicDocId);
        this.docId$.next(dynamicDocId);
      }, 5000);  // Simulate delay for dynamic document ID loading  
    }

    this.chooseMedication = this.consult.steps.findIndex(step => step.stepId == 'chooseMedication') != -1 ? true : false;
    let sub = this.afs.collection('consultation').doc(`${this.consult.ID}`).collection('Items').valueChanges().subscribe(items =>{
      this.selectedItems = items;

      this.getUser();
      this.getSalesOrderData();
      this.getAcceptaCards();
      
      this.getShippingMethods();
      this.getWishlistItems(); 
    });
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes?.validateAddress?.currentValue){
      this.validateAddress = changes?.validateAddress?.currentValue;
      if(this.shipping && this.shipping.length > 0){
        this.validateAddressState.emit('valid');
      }
    }
    if(changes?.validateCard?.currentValue){
      this.validateCard = changes?.validateCard?.currentValue;

      if(this.creditCardRef){
        this.validateCardState.emit('valid');
      }
    }
  }

  listenToDocumentFieldChange(): void {
    // console.log('Listening to document field changes...');
    // console.log('Document ID:', this.docId$);
    this.docSubscription = this.docId$
      .pipe(
        // tap(docId => console.log('docId emitted:', docId)),
        filter(docId => !!docId),  // Ensure the document ID is not null or undefined
        switchMap(docId => {
          // console.log('Document ID:', docId);
          if (docId) {
            this.documentRef = this.afs.collection('orders').doc(docId);
            return this.documentRef.valueChanges();
          }
          return [];  // Empty observable if no document ID is set
        })
      )
      .subscribe((docData: any) => {
        // console.log('Document field value:', docData);
        if (docData) {
          // console.log('Document field value:', docData);
          const fieldValue = docData.orderState;  // Replace with your field name
          const fieldValue2 = docData.couponApplied;
          const fieldValue3 = docData.coupon;
          if (this.orderLinesLoaded ===  true && fieldValue != 'create' && fieldValue2 === false && this.hasCheckedCoupon === false && docData.coupon && this.clickedApplyCoupon === false) {
            this.hasCheckedCoupon = true;
            // console.log('Coupon applied: ', fieldValue3);
            this.checkOrderForExistingCoupon();
          }
        }
      });
  }

  getUser() {
    let getShippingOnce = true;
    let sub = this.afAuth.authState.subscribe(user => {
      this.user = user;
      if(user){
        this.authService.profile().then(data =>{
          this.user = data;
        });      
        this.allSubscriptions.add(sub)
        
        let sub1= this.afs.collection('consultation').doc(`${this.consult.ID}`).collection("Address", ref => ref.where('AddressType', '==', "Shipping").where('AddressStateCode', '==', this.consult.state.code)).valueChanges().subscribe(address => {
          this.shipping = address;

          if(getShippingOnce){
            getShippingOnce = false;
            this.getShippingAddress()
          }
        })
        this.allSubscriptions.add(sub1)

        this.afs.firestore.collection('users').doc(user.uid).collection("preferedShippingMethod").doc("Shipping").get().then((shipping:any) => {
          let chooseMedication = this.consult?.steps?.findIndex(step => step.stepId == 'chooseMedication')
          if(shipping.exists && chooseMedication != -1){
            this.setSelectedShippingMethod('', shipping.data())
          }
        })
      }
    });
    this.allSubscriptions.add(sub)
  }

  close(){
    this.modalService.dismissAll();
  }

  async getSalesOrderData(){
    this.orderLinesLoaded = false;
    this.enableCheckoutBtn.emit(false)
    this.generatingOrder = true;
    let runOneTime = true;
    let callOneTime = true;
    let sub = (await this.orderService.observeChangesInOrder(this.consult.ID, this.consult.UID_Patient)).subscribe(async (order:any) => {
      if(order.length > 0){        
        const { id, orderNo, orderState, orderTotal, orderTax, orderSubtotal, discountTotal } = order[0];
        this.orderInfo = order[0]
        this.order.emit(order);
        this.orderTax = Number(orderTax);
        this.orderShipping = 0;
        this.orderTotal =  Number(orderTotal);
        this.discountTotal = Number(discountTotal)
        this.amountDueToday.emit(this.orderAmountDueToday);
        this.amountDueConfirmed.emit(this.orderAmountDueConfirmed);
        this.orderNumber = orderNo;
        this.consultation.getOrderTotal(this.orderTotal);
        await this.getOrderLines(id).then(res => {
          let oneMinutesAgo = new Date(Date.now() - 1 * 60 * 1000);
          let sync_time = this.orderInfo.sync_time ? new Date(this.orderInfo.sync_time.seconds * 1000) : new Date();
          if((orderState == 'draft' || orderState == 'ready' || (orderState == 'create' && sync_time < oneMinutesAgo)) && runOneTime == true){
            runOneTime = false;
            if(orderState == 'create' && sync_time < oneMinutesAgo){
              this.afs.collection("orders").doc(order[0].id).update({orderState: "draft"});
              this.handleOrderUpdate();
            }
            else {
              this.handleOrderUpdate();
            }
          }  
        })

        this.orderSubtotal = Number(orderTotal) //Number(orderSubtotal)
        this.consultation.getTotal(this.consult, this.orderTotal);
        this.orderLinesLoaded = true;
        this.enableCheckoutBtn.emit(true)
        this.generatingOrder = false;    
        this.displayAddCouponOption(); 
        return
      }
      this.generatingOrder = true;

      const { phoneNumber, preferedLang, email, uid, partnerID  } = this.patientProfile;

      if(callOneTime){
        callOneTime = false;
        let service = await this.orderService.getServiceFromConsultation(this.consult.ID);
        if(service){
          let product: BaseProduct = {
            id: uuidv4(),
            image: service.productImg ? service.productImg : "",
            productID: service.odooProductId.toString(),
            productName: service.productName,
            productPrice: service.productPrice,
            productQty: "1",
            recurrency_order:false,
            type:service.productType,
            orderLineID: "",
            automaticPayment:false,
          }
          let response:any = await this.orderService.createOrderOnCall(uid, this.consult.ID, this.patientProfile, product, this.consult.state.code);
          if(response.data.status == 200){
            this.afs.collection("orders").doc(response.data.order.id).update({orderState: "create", sync_time: new Date(Date.now())});
            this.createDummyOrder(this.wishlist.length != 0 ? this.wishlist : [])
          }
        }
      }
    });
    this.allSubscriptions.add(sub)
  }

  handleShippingMethod(shippingMethod){
    this.orderLinesLoaded = false;
    this.enableCheckoutBtn.emit(false)
    this.disableHold.emit(false);
    let data = {
      shippingMethod: shippingMethod.name,
    }
    if(shippingMethod == undefined){
      data.shippingMethod = "";
    }
    this.afs.collection("consultation").doc(this.consult.ID).update(data);
    let ship = this.afs.firestore.collection("consultation").doc(this.consult.ID).collection("Items").doc("shipping")
    ship.get().then((doc:any) => {
      if(doc.exists){
        this.afs.collection("consultation").doc(this.consult.ID).collection("Items").doc(shippingMethod.productType).update(shippingMethod);
      }else{
        this.afs.collection("consultation").doc(this.consult.ID).collection("Items").doc(shippingMethod.productType).set(shippingMethod);
      }
    })
  }

  handleOrderUpdate(){
    this.orderLinesLoaded = false;
    if(this.orderLines.length == 0){
      this.selectedItems = this.selectedItems.filter(item => item.productType == 'service');
      let _product: BaseProduct = {
        id: uuidv4(),
        image: this.selectedItems[0].productImg,
        // productID: this.selectedItems[0].productId.toString(),
        productID: this.selectedItems[0].odooProductId.toString(),
        productName: this.selectedItems[0].productName,
        productPrice: this.selectedItems[0].productPrice,
        productQty: "1",
        recurrency_order:this.selectedItems[0].productQty == 0 ? false : true,
        type:this.selectedItems[0].productType,
        orderLineID: "",
        automaticPayment: this.selectedItems[0].automaticPayment,
      }
      this.orderService.createOrderLine(this.orderInfo.id, _product).then(res => {
        this.afs.collection("orders").doc(this.orderInfo.id).update({orderState: "create", sync_time: new Date(Date.now())});
      });
    }
    else {
      this.orderService.createOrderOdoo(this.orderInfo);
    }
  }

  //Payments.....................
  async getAcceptaCards(){
    this.afs.firestore.collection("users").doc(this.patientProfile.uid).collection("wallet").get().then(async (data:any) => {
      this.allPaymentMethods = [];
      if(data.docs.length > 0){
        for(let i = 0; i < data.docs.length; i++){

          let today = new Date();
          let exp = new Date(data.docs[i].data().expDate.split("/")[1], data.docs[i].data().expDate.split("/")[0]);
          if(data.docs[i].data().active && !this.allPaymentMethods.includes(data.docs[i].data())){
            if(exp.getTime() > today.getTime()){
              this.allPaymentMethods.push(data.docs[i].data());
            }
          }
        }
      }
      this.getShippingMethod_creditCard();
      if(this.allPaymentMethods.length != 0){
        let preferedCard = this.allPaymentMethods.filter(card => card.default == true);
        if(preferedCard.length != 0){
          this.creditCardRef = await preferedCard[0].brand + "-" + preferedCard[0].last4 + " Expires " + preferedCard[0].expDate;
          this.onItemChange(preferedCard[0]);
        }else{
          this.creditCardRef = await this.allPaymentMethods[0].brand + "-" + this.allPaymentMethods[0].last4 + " Expires " + this.allPaymentMethods[0].expDate;
          this.onItemChange(this.allPaymentMethods[0]);
        }
      }
    });
  }

  onItemChange(item){  
    let data = {
      creditCardRef: "",
      acceptaID: "",
    };
    if(item !== undefined){
      data.creditCardRef = item.brand + "-" + item.last4 + " Expires " + item.expDate;
      data.acceptaID = item.customerNumber;
    }
    this.afs.collection("consultation").doc(this.consult.ID).update(data);  
  }

  openOTC(modal: any){
    this.modalRef = this.modalService.open(modal, 
      {
        size: 'lg',
        centered: true,
        backdrop: 'static',
      }
    );
  }

  showModalAddCard(modal: any){
    this.modalRef = this.modalService.open(modal, 
      {
        size: 'md',
        centered: true,
        backdrop: 'static',
        windowClass: 'addCard'
      }
    );
  }

  dismiss(){
    this.modalRef?.dismiss();
  }

  async getShippingMethod_creditCard(){
    this.displayShippingMethods();
    if(this.consult.creditCardRef){
      let findCard = this.allPaymentMethods.filter(card => card.last4 == this.consult["creditCardRef"].split('-')[1].split(' Expires')[0])
      this.creditCardRef = findCard.length > 0 ? this.consult["creditCardRef"] : this.allPaymentMethods.filter(card => card.default == true) > 0 ? this.allPaymentMethods.filter(card => card.default == true)[0] : '' ;

      if(this.creditCardRef == '' || this.creditCardRef != this.consult["creditCardRef"]){
        this.afs.firestore.collection("consultation").doc(this.consult.ID).update({creditCardRef: this.creditCardRef})
      }
    }
    else {
      if(this.allPaymentMethods.length != 0){
        let preferedCard = this.allPaymentMethods.filter(card => card.default == true);
      if(preferedCard.length != 0){
        this.creditCardRef = await preferedCard[0].brand + "-" + preferedCard[0].last4 + " Expires " + preferedCard[0].expDate;
        this.onItemChange(preferedCard[0]);
      }else{
        this.creditCardRef = await this.allPaymentMethods[0].brand + "-" + this.allPaymentMethods[0].last4 + " Expires " + this.allPaymentMethods[0].expDate;
        this.onItemChange(this.allPaymentMethods[0]);
      }
      }
    }
  }

  getShippingMethods(){
    this.productService.getShipping(this.consult).then((methods: shipping[]) => {
      this.shippingMethod = methods.filter(m => {
       return m.productStates.some(pS => pS.state_code.includes(this.consult.state.code))
      });
    })
  }

  acceptRefillTerms($event){
    if ($event.target.checked) {
      this.acceptedRefillTerms = true;
      this.consultationsService.emitRefillTerms(true);
    }  
    else {
      this.acceptedRefillTerms = false
      this.consultationsService.emitRefillTerms(false);
    }
    let data = {
      acceptedRefillTerms: this.acceptedRefillTerms,
      acceptedRefillDate: new Date(),
    }
    this.afs.collection('consultation').doc(this.consult.ID).update(data)
  }

  edit = false;
  showTerms(modal){
    this.edit = this.shipping.length == 0 ? false : true;
    this.modalRef = this.modalService.open(modal, {size: 'lg',centered: true, backdrop: 'static'});
  }

  getWishlistItems(){
    this.afs.collection("consultation").doc(this.consult.ID).collection("wishlist").valueChanges().subscribe(async (data:any) => {
      let arr = [];
      for(let i = 0; i < data.length; i++) {
        if(data[i].type === 'product'){
          if(data[i].odooProductId != 980){
            arr.push(await data[i]);
          }
        }
        else if(data[i].type === 'shipping'){
          this.currentShippingMethod = data[i].fbProductId;

          arr.push(await data[i]);
        }

        if(i == data.length - 1){
          this.wishlist = arr;
          this.createDummyOrder(this.wishlist.length != 0 ? this.wishlist : [])
        }
      };

      if(this.wishlist.length == 0){
        this.createDummyOrder(this.wishlist.length != 0 ? this.wishlist : [])
      }
    })
  }

  automaticPayment(){
    return this.consult.automaticPrescriptionPayment ? true : false;
  }

  async getShippingAddress(){
    await this.firebaseService.getUserAddresses(this.patientProfile)
    let sub = this.firebaseService.addressessSubscription.subscribe(data => {
      let address = data.filter((address:any) => address.AddressType === "Shipping" && address.AddressStateCode === this.patientProfile.currentLocation.code && address.AddressDefault === true);
      if(address.length == 0){
        address = data.filter((address:any) => address.AddressType === "Shipping" && address.AddressStateCode === this.patientProfile.currentLocation.code);
      }

      let currentAddress = data.filter((address:any) => address.id == this.shipping?.id)[0];

      if(((this.shipping == undefined || this.shipping.length == 0 || (this.shipping && currentAddress && _.isEqual(this.shipping.id, currentAddress.id) && !_.isEqual(this.shipping, currentAddress)))) 
          && address.length != 0 && (this.consult.Status == 'Open' || this.consult.Status == 'Draft')){
          
        if(this.shipping && currentAddress && _.isEqual(this.shipping.id, currentAddress.id) && !_.isEqual(this.shipping, currentAddress)){
          this.saveAddress(currentAddress)
        }
        else {
          this.saveAddress(address[0]);
        }
      }
    })
    this.allSubscriptions.add(sub)
  }
  
  saveAddress(address:any){
    if(address){
      this.firebaseService.saveAddressInConsult(address, this.consult.ID);
      this.afs.collection('orders').doc(this.orderInfo.id).update({shippingAddress: address})
    } 
  }

  dummyOrder;
  dummyOrderLines = []
  async createDummyOrder(product:any){  
    let tempTotal = 0
    let dummyShipping = 0;
    let dummyPromo = 0;

    // console.warn(this.orderLines)
    let timer = setInterval(() => {
      if(this.orderLines.length > 0){
        clearInterval(timer)
        let promos = this.orderLines.filter(line => line.productPromo && line.productPromo.length > 0 && line.type == 'service') || [];
        // console.warn(promos, this.orderLines)
        if(promos.length > 0){
          for(let i = 0; i < promos.length; i++){
            if(promos[i].productPromoPrice){
              dummyPromo += Number(!Array.isArray(promos[i].productPromoPrice) ? promos[i].productPromoPrice[this.consult.state.code] : promos[i].productPromoPrice[0][this.consult.state.code])
            }
          }
        }
        product.forEach((p) => {
          let orderLine = this.orderLines.filter(line => line.productPromo && line.productPromo.includes(Number(p.id))) || [];
          if(p.type == 'product' && orderLine.length == 0){
            tempTotal += Number(!Array.isArray(p.price) ? p.price[this.consult.state.code] : p.price[0][this.consult.state.code])
          }

    
          if(p.type == 'shipping' && orderLine.length == 0){
            dummyShipping += Number(typeof(p.price) == 'string' ? p.price : !Array.isArray(p.price) ? p.price[this.consult.state.code] : p.price[0][this.consult.state.code])
          }

          if(orderLine.length > 0 && !orderLine[0].productPromoPrice){
            p.includedInPromo = true;
          }
        })
    
        let create = {      
          productType: 'product',
          subTotal: tempTotal + dummyPromo,
          shipping: dummyShipping,
          total: tempTotal + dummyShipping + dummyPromo,
          tax: 0,
        }
    
        let checkProducts = product //.filter(p => p.type != 'shipping') 
        this.dummyOrderLines = []
        this.dummyOrder = undefined;

        if(checkProducts.length > 0){
          this.dummyOrder = create;

          this.dummyOrderLines = product.filter(p => p.type != 'shipping' && p.id != 980 && !p.includedInPromo).sort((a, b) => {
            return a.productId - b.productId
          });

          this.getProductDeliveryFrequencyOrderLines(this.dummyOrderLines) 
        }
        else {
          if(dummyPromo){
            this.dummyOrder = create;
          }
        }
      }
    }, 1000)
  }

  getProductDeliveryFrequencyOrderLines(orderLines: any[]) {
    for (let i = 0; i < orderLines.length; i++) {
      if(orderLines[i].deliveryFrequencies){
        this.deliveryFrequencies[orderLines[i].productId] = orderLines[i].deliveryFrequencies;
      }
    }
  }

  setAutomaticPayments(checked: boolean, product: any) {
    const automaticPaymentValue = checked ? true : false;
    this.afs.collection("consultation").doc(this.orderInfo.consultationID).collection("wishlist").doc(product.productId.toString()).update({ automaticPayment: automaticPaymentValue });
  }

  setPrice(price, product?){

    if(product?.productPromo && product?.productPromoPrice){
      return Number(!Array.isArray(product.productPromoPrice) ? (product.productPromoPrice[this.consult.state.code] ? product.productPromoPrice[this.consult.state.code] : product.productPromoPrice ) : product.productPromoPrice[0][this.consult.state.code])
    }
    if(price){
      return Number(!Array.isArray(price) ? (price[this.consult.state.code] ? price[this.consult.state.code] : price ) : price[0][this.consult.state.code])
    }
  }
  
  async getOrderLines(orderId:string){
    let promise = new Promise((resolve, reject) => {
      let sub = this.afs.collection("orders").doc(orderId).collection("orderLines").valueChanges().subscribe(async (lines) => {
        this.orderLines = lines;
        for(let i = 0; i < this.orderLines.length; i++){
          let service = this.selectedItems.filter(item => item.id.toString() == this.orderLines[i]['productID']);

          if(service.length > 0){
            this.orderLines[i].productPromo = service[0].productPromo ? service[0].productPromo : [];
            this.orderLines[i].productDescription = service[0].productDescription ? service[0].productDescription : [];
            this.orderLines[i].productValue = service[0].productValue ? service[0].productValue : [];
            this.orderLines[i].tabletQty = service[0].tabletQty ? service[0].tabletQty : 0;
            this.orderLines[i].productPromoPrice = service[0].productPromoPrice ? service[0].productPromoPrice : 0;
            let search = this.orderLines.filter(line => line.productPromo && line.productPromo.length > 0) || [];
            this.hasPromo = search.length > 0 ? true : false;
    
          }
        }
  
        this.orderLines = await this.orderLines.sort((a, b) => {
          return a.orderLineID - b.orderLineID
        });
        resolve(this.orderLines)
      });
      this.allSubscriptions.add(sub)
    })
    return promise;
  }

  openDisclaimer(event){
    var popup = document.getElementById("disclaimer");
    if(event.target.localName == 'p' || event.target.localName == 'button'){
      popup.classList.toggle("show");
      popup.classList.remove("hide");
    }
    if(event.target.localName == 'span' || event.target.localName == 'small') {
      popup.classList.remove("show");
      popup.classList.toggle("hide");
    }
  }

  setShipmentFrequency(event, product){    
    this.afs.collection("consultation").doc(this.consult.ID).collection("wishlist").doc(product.productId.toString()).update({deliveryFrequencyInDays: Number(event.frequency), automaticPayment: event.autoPayment});
  }

  setDeliveryFrequency(frequency:number, automaticPayment:boolean){
    return this.dummyOrderLines.some((line) => {
      return line.deliveryFrequencyInDays == frequency && line.automaticPayment == automaticPayment
    });
  }

  addShippingMethod(shippingMethod){
    this.orderLinesLoaded = false;
    this.enableCheckoutBtn.emit(false)
    this.disableHold.emit(false);
    let data = {
      shippingMethod: shippingMethod.name,
    }
    if(shippingMethod == undefined){
      data.shippingMethod = "";
    }
    this.afs.collection("consultation").doc(this.consult.ID).update(data);
    let ship = this.afs.firestore.collection("consultation").doc(this.consult.ID).collection("wishlist").doc("shipping")
    ship.get().then((doc:any) => {
      if(doc.exists){
        this.afs.collection("consultation").doc(this.consult.ID).collection("wishlist").doc(shippingMethod.productType).update(shippingMethod);
      }else{
        this.afs.collection("consultation").doc(this.consult.ID).collection("wishlist").doc(shippingMethod.productType).set(shippingMethod);
      }
      
    })
  }

  displayShippingMethods(){
    if(this.consult){

      if(this.consult.Type.includes('Psychotherapy') === true){

        this.displayShippingMethodOptions = false;

      }
      else if(this.consult.Type.includes('Psychotherapy') === false){

        this.displayShippingMethodOptions = true;
        
      }
    }
  }

  setSelectedShippingMethod(event, shippingMethod){

    var parentDocRef = this.afs.firestore.collection('consultation').doc(`${this.consult.ID}`);

    var subcollectionRef = parentDocRef.collection('wishlist');

    var query = subcollectionRef.where('type', '==', 'shipping');
    
    // var obj = {
    //   productId: shippingMethod.productId,
    //   odooId: shippingMethod.odooProductId,
    //   productImg: shippingMethod.productImg,
    //   productName: shippingMethod.productName,
    //   productPrice: shippingMethod.productPrice,
    //   productQty: 1,
    //   productType: shippingMethod.productType
    // }

    var obj = {
      id: shippingMethod.odooProductId ? shippingMethod.odooProductId : shippingMethod.id,
      fbProductId: shippingMethod.productId ? shippingMethod.productId : (shippingMethod.fbProductId ? shippingMethod.fbProductId : shippingMethod.id),
      odooProductId: shippingMethod.odooProductId ? shippingMethod.odooProductId : shippingMethod.id,
      imgRef: shippingMethod.productImg ? shippingMethod.productImg : shippingMethod.imgRef,
      name: shippingMethod.productName ? shippingMethod.productName : shippingMethod.name,
      price: typeof(shippingMethod.productPrice) == 'string' ? 
      (shippingMethod.productPrice ? [{[this.consult.state.code]:shippingMethod.productPrice}] : [{[this.consult.state.code]:shippingMethod.price}]) : 
      (shippingMethod.productPrice && !Array.isArray(shippingMethod.productPrice) ? [shippingMethod.productPrice] : (shippingMethod.productPrice ? shippingMethod.productPrice : (!Array.isArray(shippingMethod.price) ? [shippingMethod.price] : (typeof(shippingMethod.price) == 'string' ? [{[this.consult.state.code]:shippingMethod.price}] : shippingMethod.price )))),
      qty: 1,
      type: shippingMethod.productType ? shippingMethod.productType : shippingMethod.type
    }

    query.get().then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            
            var data = doc.data();
            if(data.fbProductId != obj.fbProductId){
              var parentDocRef = this.afs.firestore.collection('consultation').doc(`${this.consult.ID}`);
  
              var subcollectionRef = parentDocRef.collection('wishlist');
  
              var docToDeleteRef = subcollectionRef.doc(data.id.toString());
  
              docToDeleteRef.delete().then(() => {
                  this.afs.firestore.collection("consultation").doc(this.consult.ID).collection("wishlist").doc(shippingMethod.odooProductId.toString()).set(obj);
                  this.afs.collection("users").doc(this.patientProfile.uid).collection("preferedShippingMethod").doc("Shipping").set(obj);
  
              }).catch((error) => {
              });
            }
          });
        }
        else {
          this.afs.firestore.collection("consultation").doc(this.consult.ID).collection("wishlist").doc(obj.id.toString()).set(obj);
          this.afs.collection("users").doc(this.patientProfile.uid).collection("preferedShippingMethod").doc("Shipping").set(obj);
        }
    }).catch((error) => {
    });

  }

  getAddress(){
    let promise = new Promise((resolve, reject) => {
      this.validateAddress = true;
      let interval = setInterval(() => {
        if(this.shipping && this.shipping.length > 0){
          resolve(true) 
          clearInterval(interval)
          this.validateAddress = false;
          
          let chooseMedication = this.consult.steps.findIndex(step => step.stepId == 'chooseMedication')
          let toogle = chooseMedication != -1 ? 'toggle-2-header' : 'toggle-3-header';
          let element = document.getElementById(toogle);
          (element.childNodes[0] as HTMLElement).click(); //click on the toggle button that is deep in the ngb-panel

        }
        if(this.validateAddress == false && this.shipping.length == 0){
          resolve(false)
          clearInterval(interval)
          this.validateAddress = false;
        }
      }, 1000)
    })
    return promise 
  }

  applyCouponCode(){
    this.clickedApplyCoupon = true;
    
    this.applyingCoupon = true;
    this.couponExistsInOrder = false;
    this.couponValid = false;
    this.couponErrorExists = false;
    this.couponError = false;
    this.appliedDiscount = false;
    this.couponInvalid = false;
    this.couponInvalidOrder = false;
    this.couponPreviouslyUsed = false;

    const inputElement = document.getElementById('couponCodeValue') as HTMLInputElement;
    const inputValue = inputElement.value.trim();

    this.couponCode = inputValue.toLowerCase();

    //Check if coupon code is active and exists:
    this.afs.firestore.collection("coupons").where('couponCode', '==', this.couponCode).where('active', '==', true).limit(1).get().then((querySnapshot) => {
      //If coupon code does not exist or is not active:
      if(querySnapshot.empty){
        this.couponExistsInOrder = false;
        this.couponValid = false;
        this.couponErrorExists = true;
        this.couponError = false;
        this.appliedDiscount = false;
        this.applyingCoupon = false;
        this.couponInvalidOrder = false;
        this.couponPreviouslyUsed = false;
        this.couponInvalid = true;
        const couponInput = document.getElementById('couponCodeValue') as HTMLInputElement;
        if (couponInput) {
          couponInput.value = ''; // Reset the value to an empty string
        }
      }
      //If coupon code exists and is active:
      else {
        //Check if order total is greater than 0:
        if(this.orderTotal > 0) {
          //Check if coupon code has been previously used in another order:
          this.afs.firestore.collection("orders").where('uid', '==', this.orderInfo.uid).where('coupon', '==', this.couponCode).where('couponApplied', '==', true).limit(1).get().then((querySnapshot2) => {
            //If coupon code has not been used in another order:
            if(querySnapshot2.empty){
              this.afs.firestore.collection("orders").doc(this.orderInfo.id).get().then((doc) => {
                //If coupon code has already been applied in the current order:
                if(doc.data().couponApplied){
                  this.applyingCoupon = false;
                  this.couponErrorExists = false;
                  this.couponError = false;
                  this.couponInvalid = false;
                  this.couponInvalidOrder = false;
                  this.couponPreviouslyUsed = false;
                  this.couponExistsInOrder = true;
                  this.couponValid = true;
                  this.appliedDiscount = true;
                  this.couponCode = doc.data().coupon;
                }
                //If coupon code has not been applied in the current order:
                else{
                  //Save coupon code to order:
                  this.afs.collection("orders").doc(this.orderInfo.id).set({
                    coupon: this.couponCode,
                    couponApplied: false
                  }, {merge: true}).then(() => {
                    //Send coupon cdde to Odoo:
                    this.sendOrder();
                  })
                  .catch((error) => {
                    //An error has occurred:
                    this.applyingCoupon = false;
                    this.couponExistsInOrder = false;
                    this.couponValid = false;
                    this.appliedDiscount = false;
                    this.couponInvalid = false;
                    this.couponInvalidOrder = false;
                    this.couponPreviouslyUsed = false;
                    this.couponErrorExists = true;
                    this.couponError = true;
                  });
                }
              });
            }
            else{
              //If coupon code already has already been used in another order:
              this.applyingCoupon = false;
              this.couponExistsInOrder = false;
              this.couponValid = false;
              this.appliedDiscount = false;
              this.couponErrorExists = true;
              this.couponError = false;
              this.couponInvalid = false;
              this.couponInvalidOrder = false;
              this.couponPreviouslyUsed = true;
            }
          });
        }
        //If order total is 0:
        else if(this.orderTotal == 0) {
          this.applyingCoupon = false;
          this.couponExistsInOrder = false;
          this.couponValid = false;
          this.appliedDiscount = false;
          this.couponErrorExists = true;
          this.couponError = false;
          this.couponInvalid = false;
          this.couponPreviouslyUsed = false;
          this.couponInvalidOrder = true;
        }
      }
    });
    
  }

  displayAddCouponOption() {
    if(this.orderTotal > 0) {
      let sub = this.afs.collection('consultation').doc(`${this.consult.ID}`).collection('Items').valueChanges().subscribe(items => {
        if (items.length !== 0) {
          // if (items[0].productName.includes('Promo') || items[0].productName.includes('promo') || items[0].productName.includes('Promotion') || items[0].productName.includes('promotion')) {
          //   this.showAddCouponOption = false;
          // } 
          // else {
          //   this.showAddCouponOption = true;
          // }
          this.showAddCouponOption = true;
        } 
        else {
          let sub2 = this.afs.collection('consultation').doc(`${this.consult.ID}`).collection('wishlist').valueChanges().subscribe(wishlistItems => {
            if (wishlistItems.length !== 0) {
              this.showAddCouponOption = true;
            } 
            else {
              this.showAddCouponOption = false;
            }
            this.cd.detectChanges();
            // sub2.unsubscribe();
          });
        }
        this.cd.detectChanges();
        // sub.unsubscribe();
      });
    }
    else if(this.orderTotal == 0) {
      // this.showAddCouponOption = false;
      this.showAddCouponOption = true;
    }
  }

  sendOrder() {

    this.orderService.sendOrderData(this.orderInfo).subscribe(
      response => {
        // console.log('Order sent successfully');
        // console.log('Response: ', response);
        this.couponErrorExists = false;
        this.couponError = false;
        this.couponInvalid = false;
        this.couponInvalidOrder = false;
        this.couponPreviouslyUsed = false;
        this.couponExistsInOrder = true;
        this.couponValid = true;
        this.appliedDiscount = true;
        this.applyingCoupon = false;
        this.removeDuplicateOrderLines(this.orderInfo.id);
      },
      error => {
        this.couponExistsInOrder = false;
        this.couponValid = false;
        this.appliedDiscount = false;
        this.applyingCoupon = false;
        this.couponInvalid = false;
        this.couponInvalidOrder = false;
        this.couponPreviouslyUsed = false;
        this.couponErrorExists = true;
        this.couponError = true;
      }
    );
  }

  checkOrderForExistingCoupon() {
    if (this.orderInfo.id) {
      this.afs.firestore.collection("orders").doc(this.orderInfo.id).get().then((doc) => {
        if (doc.data()?.couponApplied === false && doc.data()?.coupon) {
          this.afs.firestore.collection("coupons").where('couponCode', '==', doc.data().coupon).where('active', '==', true).limit(1).get().then((querySnapshot) => {

            if (querySnapshot.empty) {
              this.couponExistsInOrder = false;
              this.couponValid = false;
              this.couponErrorExists = true;
              this.couponError = false;
              this.couponInvalidOrder = false;
              this.couponPreviouslyUsed = false;
              this.appliedDiscount = false;
              this.couponInvalid = true;
              this.couponCode = doc.data().coupon;
              const couponInput = document.getElementById('couponCodeValue') as HTMLInputElement;
              if (couponInput) {
                couponInput.value = doc.data().coupon; // Add coupon code value to textbox
              }
            } 
            else {
              this.couponErrorExists = false;
              this.couponError = false;
              this.couponInvalidOrder = false;
              this.couponPreviouslyUsed = false;
              this.couponExistsInOrder = true;
              this.couponValid = true;
              this.appliedDiscount = true;
              this.couponInvalid = false;
              this.couponCode = doc.data().coupon;

              const couponInput = document.getElementById('couponCodeValue') as HTMLInputElement;
              if (couponInput) {
                couponInput.value = doc.data().coupon; // Add coupon code value to textbox
                this.sendOrder();
              }
            }
          });
        }
        else {
          this.couponExistsInOrder = false;
          this.couponErrorExists = false;
        }
      });
    }
  }

  async removeCouponFromOrder(){

    this.removingCoupon = true;

    const docRef = this.afs.firestore.collection('orders').doc(this.orderInfo.id);
  
    try {

      this.orderService.updateOrderData(this.orderInfo).subscribe(
        async response => {
          this.couponErrorExists = false;
          this.couponError = false;
          this.couponInvalid = false;
          this.couponInvalidOrder = false;
          this.couponPreviouslyUsed = false;
          this.couponExistsInOrder = false;
          this.couponValid = false;
          this.appliedDiscount = false;
          this.applyingCoupon = false;
          const couponInput = document.getElementById('couponCodeValue') as HTMLInputElement;
          if (couponInput) {
            couponInput.value = '';
          }
          // Get the document data directly using async/await
          const docSnapshot = await docRef.get(); // No need for toPromise()
      
          if (docSnapshot.exists) {
            const docData = docSnapshot.data();
      
            const updates: any = {};
      
            // Check if the fields exist in the document
            if (docData && 'coupon' in docData) {
              updates.coupon = firebase.firestore.FieldValue.delete();
            }
            if (docData && 'couponApplied' in docData) {
              updates.couponApplied = firebase.firestore.FieldValue.delete();
            }
      
            // If there are any fields to delete, update the document
            if (Object.keys(updates).length > 0) {
              await docRef.update(updates);
              this.getSalesOrderData();
              this.removingCoupon = false;
            } 
            else {
              this.removingCoupon = false;
            }
          } 
          else {
            this.removingCoupon = false;
          }
        },
        error => {
          this.couponExistsInOrder = false;
          this.couponValid = false;
          this.appliedDiscount = false;
          this.applyingCoupon = false;
          this.couponInvalid = false;
          this.couponInvalidOrder = false;
          this.couponPreviouslyUsed = false;
          this.couponErrorExists = true;
          this.couponError = true;
          this.removingCoupon = false;
        }
      );
    } 
    catch (error) {
      this.removingCoupon = false;
    }

  }

  removeDuplicateOrderLines(orderId: string) {

    // console.log('Removing duplicate order lines...');

    // console.log('Order ID:', orderId);

    try{

      const orderLinesRef = this.afs.collection('orders').doc(orderId).collection('orderLines');

      orderLinesRef.get().subscribe((querySnapshot) => {
        const seenOrderLines: { [key: string]: boolean } = {}; // Track orderLineID
        const batch = this.afs.firestore.batch();

        // console.log('Seen order lines:', seenOrderLines);

        querySnapshot.forEach((doc) => {
          const orderLineData = doc.data() as { orderLineID: string }; // Type assertion
          const orderLineID = orderLineData.orderLineID;

          // console.log('Checking orderLineID:', orderLineID);

          if (seenOrderLines[orderLineID]) {
            // If orderLineID already exists, mark the document for deletion
            // console.log('Duplicate order line found:', orderLineID);
            const docRef = this.afs.doc(`orders/${orderId}/orderLines/${doc.id}`).ref;
            batch.delete(docRef);
          } 
          else {
            // If it's the first occurrence of this orderLineID, keep it
            // console.log('First occurrence of order line:', orderLineID);
            seenOrderLines[orderLineID] = true;
          }
        });

        if (!querySnapshot.empty) {
          // Commit the batch delete operation
          batch.commit().then(() => {
            // console.log('Duplicate order lines removed successfully, leaving only one for each orderLineID.');
          })
          .catch((error) => {
            // console.error('Error removing duplicate order lines:', error);
          });
        }
      });

    }
    catch(error){
      // console.error('Error removing duplicate order lines:', error);
    }

  }

  checkIfCouponsAreExpired() {
    this.afs.firestore.collection("coupons").where("active", "==", true).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const coupon = doc.data();
        if (this.isCouponExpired(coupon)) {
          this.afs.firestore.collection("coupons").doc(doc.id).set({ active: false }, { merge: true });
        }
      });
    });
  }
  
  isCouponExpired(coupon: firebase.firestore.DocumentData): boolean {
      // Add your implementation here to determine if the coupon is expired or not
      if (coupon.endDate) {
        const expiryDate = coupon.endDate.toDate();
        return expiryDate < new Date();
      }
  }

  dosageForm(form){
    if(form == 'Tablet'){
      return 'CONSULTATIONS.TABLETS'
    }

    if(form == 'Capsule'){
      return 'CONSULTATIONS.CAPSULES'
    }

    if(form == 'Wipes'){
      return 'CONSULTATIONS.WIPES'
    }

    return 'CONSULTATIONS.TABLETS'
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard as AuthGuardService } from '../core/guards/auth.guard';
import { RoleGuard as RoleGuardService } from '../core/guards/role.guard';

import { HomeComponent } from './home/home.component';
import { AccountsComponent } from './accounts/accounts.component';
import { TelehealthComponent } from './telehealth/telehealth.component';
import { ConsultationsComponent } from './consultations/consultations.component';
import { PractitionerConsultsComponent } from './practitioner-consults/practitioner-consults.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ViewconsultComponent } from './components/viewconsult/viewconsult.component';
import { ConsultPrescriptionsComponent } from './components/consult-prescriptions/consult-prescriptions.component';
import { Page404Component } from '../extrapages/page404/page404.component';
import { OrdersComponent } from './orders/orders.component';
import { ConsultQuestionsComponent } from './consult-questions/consult-questions.component';
import { WalkthroughmodalComponent } from './components/walkthroughmodal/walkthroughmodal.component';
import { ShopComponent } from './shop/shop.component';
import { PdfDownloadComponent } from './pdf-download/pdf-download.component';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { ReloadComponent } from '../extrapages/reload/reload.component';
import { AuthenticatorComponent } from './authenticator/authenticator.component';
import { PaymentComponent } from './payment/payment.component';
import { WelcomeComponent } from '../account/auth/welcome/welcome.component';
import { RefillsComponent } from './components/refills/refills.component';
import { AdminConsultsComponent } from './components/admin-consults/admin-consults.component';
import { ConsultationDetailsComponent } from './components/consultation-details/consultation-details.component';
import { PrescriptionListComponent } from './components/prescription-list/prescription-list.component';
import { AppointmentsSchedulerComponent } from './components/appointments-scheduler/appointments-scheduler.component';
import { MigrationComponent } from './components/migration/migration.component';
import { BackupComponent } from './components/backup/backup.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { PrescriptionsAndRefillsComponent } from './components/prescriptions-and-refills/prescriptions-and-refills.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { ARestructureComponentComponent } from '../a-restructure-component/a-restructure-component.component';
import { RefillManagerComponent } from './components/refill-manager/refill-manager.component';
import { MedicalHistoryComponent } from './components/medical-history/medical-history.component';
import { RemotePatientOnboardComponent } from './components/remote-patient-onboard/remote-patient-onboard.component';
import { BillingComponent } from './components/billing/billing.component';
import { AdminLaboratoriesComponent } from './components/admin-laboratories/admin-laboratories.component';
import { UploadComponent } from './components/upload/upload.component';
import { LabsAndDiagnosticsComponent } from './components/labs-and-diagnostics/labs-and-diagnostics.component';
import { DocumentManagerComponent } from './components/document-manager/document-manager.component';
import { LabsManagerComponent } from './components/labs-manager/labs-manager.component';
import { FamilyMedicalHistoryComponent } from './components/family-medical-history/family-medical-history.component';
import { LaboratoriesDashboardComponent } from './components/laboratories-dashboard/laboratories-dashboard.component';
import { ChangeProductComponent } from './components/change-product/change-product.component';
import { RefillBeforeReasonsComponent } from './components/refill-before-reasons/refill-before-reasons.component';
import { MedicalInsuranceComponent } from './components/medical-insurance/medical-insurance.component';
import { ExternalPatientFormComponent } from './components/external-patient-form/external-patient-form.component';
import { PharmacyPrescriptionsComponent } from './pharmacy-prescriptions/pharmacy-prescriptions.component';
import { ExternalPrescriptionComponent } from './components/external-prescription/external-prescription.component';
import { ManualPrescriptionComponent } from './components/manual-prescription/manual-prescription.component';
import { EngagementStepComponent } from './components/engagement-step/engagement-step.component';
import { AdminSurveysComponent } from './components/admin-surveys/admin-surveys.component';
import { AlliedPharmaciesComponent } from './components/allied-pharmacies/allied-pharmacies.component';
import { PharmacyDetailsComponent } from './components/pharmacy-details/pharmacy-details.component';
import { PrescriptionManagerComponent } from './prescription-manager/prescription-manager.component';
import { MelaniesCommandCenterComponent } from './melanies-command-center/melanies-command-center.component';
import { OrdersReportComponent } from './orders-report/orders-report.component';
import { AffiliateProgramComponent } from './affiliate-program/affiliate-program.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { PricesComponent } from './components/prices/prices.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [RoleGuardService]},
  { path: 'accounts', component: AccountsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin'] },
    children: [
      { path: ':id', component: AccountsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin']}
      }
    ]},
  { path: 'adminConsults', component: AdminConsultsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin'] } },
  { path: 'profile', component: WalkthroughmodalComponent, canActivate: [AuthGuardService] },
  { path: 'family-history', component: FamilyMedicalHistoryComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin'] } },
  { path: 'authenticator', component: AuthenticatorComponent, canActivate: [AuthGuardService] },
  { path: 'analytics', component: AnalyticsComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']} },
  { path: 'admin-surveys', component: AdminSurveysComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin'] } },
  { path: 'telehealth', component: TelehealthComponent, canActivate: [RoleGuardService], data: { expectedRole: ['practitioner', 'patient'] }},
  { path: 'consultations', component: ConsultationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['patient', 'admin']},
  children: [
    { path: ':id', component: ConsultationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['patient', 'admin']},
      children: [
       {path: 'ws/:id', component: ConsultationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['patient', 'admin']},
        children: [
          {path: 'sq/:id', component: ConsultationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['patient', 'admin']},}
        ]
      }
      ] 
    }
  ]},
  { path: 'consults', component: PractitionerConsultsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['practitioner', 'admin'] } },
  { path: 'pharmacy', component: AlliedPharmaciesComponent, canActivate: [RoleGuardService], data: { expectedRole: ['pharmacist', 'admin', 'practitioner'] }, },
  { path: 'pharmacy/:id', component: PharmacyDetailsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['pharmacist', 'admin', 'practitioner'] } },
  { path: 'inboxandsupport', component: NotificationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner','patient', 'none'] }, 
  children: [ { path: 'read/:id', component: NotificationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner','patient'] } }, 
              { path: 'chat', component: NotificationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner','patient'] } },
              { path: 'sent', component: NotificationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner','patient'] } },
              { path: 'general', component: NotificationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner','patient'] } },
              { path: 'archive', component: NotificationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner','patient'] } },
              { path: 'orders', component: NotificationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner','patient'] } },
              { path: 'orders/:orderNumber', component: NotificationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner','patient'] } },
              { path: 'consults', component: NotificationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner','patient'] } },
              { path: 'helpdesk', component: NotificationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner','patient'] } },
              { path: 'helpdesk/:id', component: NotificationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner','patient'] } },
              { path: 'faq', component: NotificationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner','patient'] } },
              { path: 'contactus', component: NotificationsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner','patient', "none"] } }
  ]},
  { path: 'consults/:id', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner'] },
  children: [
    { path: 'prescription', component: ConsultPrescriptionsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},
    { path: 'treatment', component: ConsultPrescriptionsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']} },
    { path: 'prescription/:id', component: ConsultPrescriptionsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},
    { path: 'medicalHistory', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},
    { path: 'summary', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},
    { path: 'diagnosticsAndReview', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},
    { path: 'messages', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},
    { path: 'consultChat', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}}, 
    { path: 'closingSignature', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}}, 
    { path: 'followUp', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},   
    { path: 'reevaluation', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},     
    { path: 'orders', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},     
    { path: 'consultHistory', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},     
    { path: 'others', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}, children: [
      { path: 'profile', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},
      { path: 'telehealth/:id', component: TelehealthComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},
      { path: 'notes', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},
      { path: 'patientConsults', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}}, 
      { path: 'referral', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},     
      { path: 'collaborate', component: ViewconsultComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','practitioner']}},     
    ]},     
  ] },
  { path: 'manageConsultQuestions', component: ConsultQuestionsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin', 'practitioner']}, children: [
    { path: 'condition', component: ConsultQuestionsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin', 'practitioner']}},
    { path: 'questions', component: ConsultQuestionsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin', 'practitioner']}},
    { path: 'questionAtributes', component: ConsultQuestionsComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin', 'practitioner']}}, 
  ]}, 
  { path: 'orders&Refills', component: OrdersComponent, canActivate: [AuthGuardService], 
    children: [
      { path: ':id', component: OrdersComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin','patient']}}
    ]
  },
  { path: 'shop', component: ShopComponent, canActivate: [AuthGuardService] },
  { path: 'pdfDownload', component: PdfDownloadComponent, canActivate: [AuthGuardService] },
  { path: 'payment', component: PaymentComponent, canActivate: [AuthGuardService] },
  { path: 'refills', component: RefillsComponent, canActivate: [AuthGuardService]},
  { path: 'refill-manager', component: RefillManagerComponent, canActivate: [AuthGuardService], data: { expectedRole: ['patient']}},
  { path: 'prescription-list', component: PrescriptionListComponent, canActivate: [AuthGuardService],  data: { expectedRole: ['admin','practitioner', 'patient']}},
  { path: 'appointment-scheduler', component: AppointmentsSchedulerComponent, canActivate: [AuthGuardService]},
  { path: 'migration', component: MigrationComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin']}},
  { path: 'restructure', component: ARestructureComponentComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin','practitioner', 'patient']}},
  // { path: 'refills', component: RefillsComponent, canActivate: [AuthGuardService]},
  { path: 'backup', component: BackupComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'subscriptions', component: SubscriptionsComponent, canActivate: [AuthGuardService], data: { expectedRole: ['patient', 'admin']}},
  { path: 'prescriptions', component: PrescriptionsAndRefillsComponent, canActivate: [AuthGuardService], data: { expectedRole: ['patient', 'admin']}},
  { path: 'medical-history', component: MedicalHistoryComponent, canActivate: [AuthGuardService], data: { expectedRole: ['patient', 'admin']}},
  { path: 'remote-patient-onboard', component: RemotePatientOnboardComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'billing', component: BillingComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'admin-laboratories', component: AdminLaboratoriesComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'upload', component: UploadComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'labs-and-diagnostics', component: LabsAndDiagnosticsComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin', 'patient']}},
  // { path: 'labs-picker', component: LabsPickerComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin', 'patient']}},
  { path: 'document-manager', component: DocumentManagerComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'labs-manager', component: LabsManagerComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'laboratory-dashboard', component: LaboratoriesDashboardComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin', 'practitioner']}},
  // { path: 'change-product', component: ChangeProductComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin', 'patient']}},
  // { path: 'refills-before-reasons', component: RefillBeforeReasonsComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin', 'patient']}},
  { path: 'medical-insurance', component: MedicalInsuranceComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin', 'patient']}},
  { path: 'external-patient-form', component: ExternalPatientFormComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin', 'patient']}},
  { path: 'pharmacy-prescriptions', component: PharmacyPrescriptionsComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin', 'practitioner']}},
  { path: 'prescription-manager', component: PrescriptionManagerComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'external-patient-form', component: ExternalPatientFormComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'external-prescription', component: ExternalPrescriptionComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'manual-prescription', component: ManualPrescriptionComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'engagement-step', component: EngagementStepComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'melanie-cc', component: MelaniesCommandCenterComponent, canActivate: [RoleGuardService], data: { expectedRole: ['admin']}},
  { path: 'orders-report', component: OrdersReportComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'affiliate-program', component: AffiliateProgramComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'promotions', component: PromotionsComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  { path: 'prices', component: PricesComponent, canActivate: [AuthGuardService], data: { expectedRole: ['admin']}},
  // All your other routes should come first   
  {path: 'reload', component: ReloadComponent}, 
  { path: '404', component: Page404Component },
  { path: '**', component: Page404Component },
]
  



@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class PagesRoutingModule { }


<div style="text-align: center;">
  <header id="page-topbar" style="background-color: transparent;" class="w-100 d-flex ml-3 mt-2"> 
    <a href="https://dzeus.com">
      <img class="center" src="../../../../assets/images/logos/Dzeus-Logo.png" alt="" height="50">
    </a>
  </header>
  <div style="margin-top: 75px;" *ngIf="survey && survey.surveyPatientStatus == 'active'">
    <h4 class="bold">{{'SURVEY.COMPLETESURVEY' | translate}}</h4>
    <h6 class="mt-2 mx-3">{{'SURVEY.SURVEYDESCRIPTION' | translate}}{{survey.surveyQuestions}}{{'SURVEY.SURVEYDESCRIPTION1' | translate}}</h6>
    <button class="btn btn-primary" (click)="openSurvey(surveyModal)">{{'SURVEY.TAKETHESURVEY' | translate}}</button>
  </div>
  <div style="margin-top: 75px;" *ngIf="!survey || survey.surveyPatientStatus == 'completed'">
    <div>
      <h4 class="bold">{{'SURVEY.SURVEYCOMPLETED' | translate}}</h4>
      <button class="btn btn-primary" [routerLink]="['/']">{{'SURVEY.GOTOHOME' | translate}}</button>
    </div>
  </div>
</div>

<ng-template #surveyModal let-modal>
  <app-survey [survey]="survey"></app-survey>
</ng-template>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// PROD
export const environment = {
  production: true,
  defaultauth: 'firebase',
  firebaseConfig: {
    apiKey: 'AIzaSyBdejqTBpXs9dNFxEJySImUZqVKGovaViE',
    authDomain: 'app.dzeus.com',
    databaseURL: 'https://dzeus-app-telemd.firebaseio.com', 
    projectId: 'dzeus-app-telemd',
    storageBucket: 'dzeus-app-telemd.appspot.com',
    messagingSenderId: '832776696670',
    appId: '1:832776696670:web:65e2fbc6831f4b73e055cf',
    measurementId: 'G-0TBB5WEY3K',
    // clientId: '832776696670-l6o09d66cusj9897jpl8gr8h54tq8o3q.apps.googleusercontent.com',
    // clientSecret: 'R4Ip17GsN5mbu5Q6KOEEYbOA',
    oficialDomain: 'https://app.dzeus.com'
  },
  // recaptcha: {siteKey: "6LdEUlIaAAAAAIsT7miQWDwE6MY7IGG1-DOWi4lT"},
  // version: "0.0.0",
  // kms: {
  //   keyResourceName: 'projects/dzeus-app-telemd/locations/global/keyRings/Encrypt-Decrypt-test/cryptoKeys/Encrypt-Decrypt/cryptoKeyVersions/1'
  // }
};

// //Development
// export const environment = {
//   production: false,
//   defaultauth: 'firebase',
//   firebaseConfig: {
//     apiKey: 'AIzaSyC6SF3EtuyFn_MOFm3C8w4mwO85HS08Ny4',
//     authDomain: 'dzeus-app-dev.firebaseapp.com',
//     databaseURL: 'https://dzeus-app-dev.firebaseio.com', 
//     projectId: 'dzeus-app-dev',
//     storageBucket: 'dzeus-app-dev.appspot.com',
//     messagingSenderId: '541036373530',
//     appId: '1:541036373530:web:6affe0b83be30cc1ab93a3',
//     measurementId: 'G-EBXCM0CQ07',
//     clientId: '541036373530-fd0o6tb2r8j5lko9apufk9bo52fl8ori.apps.googleusercontent.com', 
//     clientSecret: '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',//cred de dev
//     oficialDomain: 'https://dzeus-app-dev.firebaseapp.com'
//   },
//   version: "0.0.0"
// };

//Production Remote Config
export const remoteConfig = {
  "env": environment.firebaseConfig.projectId,
  "domain": environment.firebaseConfig.oficialDomain,
  "privacyPolicy": "https://dzeus.com/privacy-and-terms",
  "facebook": "https://facebook.com/dzeushealth",
  "dzeusPhoneNumber": "1 (800) 443 9387",
  "paymentAttempts": 3,
  "orderLink": `${environment.firebaseConfig.oficialDomain}/orders&Refills?order=`,
  "pngLogo": "https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Flogos%2FDzeus_Logo_FInal_Nuevo.png?alt=media&token=e5a18582-cae6-44a0-88e5-cf732f3f3d2d",
  "chatSchedule": "<en>Monday to Friday from 8:00am - 4:30pm (UTC-4)</en><es>Lunes a Viernes de 8:00am a 4:30pm (UTC-4)</es>",
  "loginLink": `${environment.firebaseConfig.oficialDomain}/account/welcome/login`,
  "consultLink": "/consultations?consultation=",
  "site": "https://dzeus.com",
  "contactUs": "https://dzeus.com/contact-us",
  "logo": "https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Flogos%2FDzeus_Logo_FInal_Nuevo.png?alt=media&token=e5a18582-cae6-44a0-88e5-cf732f3f3d2d",
  "phoneNumber": "1-888-443-9387",
  "paymentRetrialTime": 24,
  "help": "https://dzeus.com/contact-us",
  "year": "Copyright© 2024 Dzeus Corp.",
  "appName": "Dzeus Health",
  "twitter": "https://twitter.com/dzeushealth",
  "whatsapp": "https://api.whatsapp.com/message/6DDKA2DJDGSTP1?autoload=1&app_absent=0",
  "shortConsultURL": `${environment.firebaseConfig.oficialDomain}?code=`,
  "instagram": "https://instagram.com/dzeushealth",
  "shortenedUrl": `${environment.firebaseConfig.oficialDomain}?code=`,
  "DocumentsBucket": "http://bucketaddress.com/bucket01",
  "CurrentVersion": "1.0",
  "practitionerPhoneNumber": "787-333-0770",
  "textNumber": "787-333-0770",
  "AppIsDisable": false,
  "myAccount": `${environment.firebaseConfig.oficialDomain}`,
  "supportEmail": "<en>support@dzeus.com</en><es>soporte@dzeus.com</es>",
  "logoSm": "https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Flogos%2FDzeus_Logo_FInal_Nuevo.png?alt=media&token=e5a18582-cae6-44a0-88e5-cf732f3f3d2d",
  "iddleTimeout": 600,
  "manual": "https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/practitionerManual%2FManual_%20Uso%20y%20Manejo%20de%20la%20plataforma.pdf?alt=media&token=624b3066-98df-403c-8abf-bc7466119172",
  "mfaRequired": false,
  "reasonsToAbandonConsult": ["<en>Patient didn't respond</en><es>Paciente no respondió</es>", "<en>Can't continue with consult</en><es>No puede seguir con la consulta</es>", "<en>Other</en><es>Otros</es>"],
  "reasonsToReopenConsult": ["<en>New Prescription</en><es>Nueva Prescripción</es>", "<en>Reevaluation</en><es>Reevaluación</es>", "<en>Referral</en><es>Referido</es>", "<en>Other</en><es>Otros</es>"],
  "reasonsToUpdateFollowUp": ["<en>Problems with Pills</en><es>Problemas con el medicamento</es>", "<en>Side Effects</en><es>Efectos Secundarios</es>", "<en>Others</en><es>Otros</es>"],
  "reasonsForNoPrescriptions": ["<en>Patient's medical history (does not qualify and must be seen by his or her primary care physician for a thorough evaluation)</en><es>Historial médico del paciente (no cualifica y debe de ser visto por su médico de cabecera para una evaluación exhaustiva)</es>", 
                                "<en>Patient does not want to follow the doctor's recommendations.</en><es>Paciente no quiere seguir la recomendaciones del médico.</es>", 
                                "<en>Patient does not want treatment.</en><es>Paciente no desea tratamiento.</es>", 
                                "<en>Medicines are very expensive.</en><es>Los medicamentos son muy costosos.</es>", 
                                "<en>Others</en><es>Otros</es>"]
}

export const testUsers = ['melanie.vazquez@dzeus.com', 
                          'melanie13.mv@gmail.com',
                          'melanie11vazquez@gmail.com',
                          'meva2940@agu.inter.edu',
                          'aramis.martinez@dzeus.com', 
                          'aramismartinez15@gmail.com',
                          'aramis.softwaredev@gmail.com',
                          'josue.soto@gmail.com',
                          'obeth.soto@gmail.com',
                          'obeth@itacon.com',
                          'neftis.cruz@upr.edu',
                          'neftiscruz454@gmail.com',
                          // 'gcorchado21@gmail.com',
                          'ashley.ramirez@dzeus.com',
                          'itzsgera@gmail.com',
                          'gerardo.ruiz6@urp.edu',
                          'gerardojrb@gmail.com',
                          'ashley.ramirez@itacon.com',
                          'gerardotesting3@gmail.com',
                          'aramirezprueba@outlook.com',
                          'aramirezprueba@yahoo.com',
                          'dzeus.patient@gmail.com'
                          ];

export const testProgrammerAccounts = ['melanie.vazquez@dzeus.com', 'ashley.ramirez@dzeus.com']

export const byPassPayments = ['dzeus.patient@gmail.com']


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { ConsultationsService } from '../consultations/consultations.service';
import * as _ from 'lodash';
import { TranslationPipe } from 'src/app/translation.pipe';
import { ProductsService } from 'src/app/core/services/products/products.service';

@Component({
  selector: 'app-choose-product',
  templateUrl: './choose-product.component.html',
  styleUrls: ['./choose-product.component.scss']
})

export class ChooseProductComponent implements OnInit {
  @Input() currentConsult;
  @Input() currentPreviousUrlPath;
  @Input() consultRules;
  @Input() currentUserAuth;
  @Input() preconsult = false
  @Output() nextAction = new EventEmitter<boolean>(false)
  @Output() backAction = new EventEmitter<boolean>(false)

//   hide = false;
//   currentLang:any = this.translate.store.currentLang
//   dummyCheckout = false;
 
//   translation = new TranslationPipe();

//   constructor(
//     public translate: TranslateService, 
//     private afs: AngularFirestore,
//     private consultationsService: ConsultationsService,
//     private productService: ProductsService
//     ){
//     this.hide = screen.width < 1200 || (window.matchMedia("(orientation: landscape-primary)") && screen.height < 800 && screen.height > 768) ? true : false;
//   }

  ngOnInit(){
//     this.productBinder(this.currentConsult);
  }

//   atributeCounter = 1
//   finalProductTest: any = [[{atr0: ''}]] 
//   allProductsTest = []
//   filteredProducts = []
//   productFilter = [[]]
//   productGroups: any = [];
//   groupCounter = 0;
//   searchGroup:any = [{label: ''}];
//   async filterAtributes(event, position){
//     let atrName = 'atr' + (position).toString()
//     let nextAtrName = 'atr' + (position + 1).toString()
//     let arr = []

//     let allProducts = this.productsByGroup.sort((a, b) => {
//       return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
//     }).sort((a, b) => {
//       if(b.price == "0.00" ){
//         return -1
//       }
//       return 0
//     })
//     let currentProducts = []

//     for(let j = 0; j < await this.finalProductTest[this.groupCounter].length - 1; j++){
//       if(this.finalProductTest[this.groupCounter][j]['atr' + j] != ''){
//         for(let i = 0; i < allProducts.length; i++) {
//           if(await allProducts[i]['atr' + j] == await this.finalProductTest[this.groupCounter][j]['atr' + j]){
//             currentProducts.push(await allProducts[i])
//           }
//           if(i == allProducts.length - 1){
//             allProducts = currentProducts
//             currentProducts = []
//             if(('atr' + j) == atrName){
//               this.filteredProducts = allProducts
//             }
//           }
//         }
//       }
//       else {
//         break;
//       }
//     }
    
//     if(position != this.atributeCounter - 1){
//       this.atributeCounter = position + 1
//       this.finalProductTest[this.groupCounter].splice(position + 1)
//       this.productFilter[this.groupCounter].splice(position + 1)
//     }
//     this.finalProductTest[this.groupCounter][position] = {[atrName]:event}
//     this.finalProductTest[this.groupCounter][position + 1] = {[nextAtrName]: ''}

//     if(!this.productFilter[this.groupCounter]){
//       this.productFilter[this.groupCounter] = []
//     }

//     this.productFilter[this.groupCounter][position + 1] = []
//     this.filteredProducts.forEach(prod => {
//       if(prod[atrName] == event){  
//         arr.push(prod);
//         var copy = arr.slice(0);
//         copy.sort((a, b) => {
//           return a.sequence - b.sequence;
//         });    

//         this.productFilter[this.groupCounter][position + 1] = [...new Map(copy.map(item => [item[nextAtrName], item])).values()];
//       }
//     });

//     if(!this.productFilter[this.groupCounter][position + 1][0] || (this.productFilter[this.groupCounter][position + 1][0] && this.productFilter[this.groupCounter][position + 1][0][nextAtrName] == '') || position == 9){
//       this.buildProductTest()
//     }
//     else {
//       this.atributeCounter++

//       let allProducts = this.productsByGroup.sort((a, b) => {
//         return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
//       }).sort((a, b) => {
//         if(b.price == "0.00" ){
//           return -1
//         }
//         return 0
//       })
//       let currentProducts = []

//       for(let j = 0; j < this.finalProductTest[this.groupCounter].length - 1; j++){
//         for(let i = 0; i < allProducts.length; i++) {
//           if(allProducts[i]['atr' + j] == this.finalProductTest[this.groupCounter][j]['atr' + j]){
//             currentProducts.push(allProducts[i])
//           }
//           if(i == allProducts.length - 1){
//             allProducts = currentProducts
//             this.filteredProducts = currentProducts
//             currentProducts = []
//           }
//         }
//       }
//     }

//     //new
//     if(position + 1 == 3 && this.productFilter[this.groupCounter][3][0]){
//       // this.filterAtributes(this.productFilter[this.groupCounter][2][0].atr2, 2)
//       this.filterAtributes(this.productFilter[this.groupCounter][3][0].atr3, 3)
//     }
//     //end new
//   }

//   filterAtributesBack(){
//     if(this.finalProductTest[this.groupCounter].length > 0 && this.finalProductTest[this.groupCounter][0].atr0 != "<en>No Selection</en><es>Sin Selección</es>" && this.finalProductTest[this.groupCounter][0].atr0 != "<en>No Preference</en><es>Sin Preferencia</es>"){
//       this.atributeCounter = this.finalProductTest[this.groupCounter][this.atributeCounter - 2]['atr' + (this.atributeCounter - 2)] == '' ? this.atributeCounter - 1 : this.atributeCounter
//       // if(this.atributeCounter == 4) {
//       //   this.atributeCounter = 3
//       // }
//       let splicer = this.finalProductTest[this.groupCounter][this.atributeCounter - 2]['atr' + (this.atributeCounter - 2)] == '' ? this.atributeCounter : this.atributeCounter - 1
//       this.productFilter[this.groupCounter].splice(splicer)
//       this.finalProductTest[this.groupCounter].splice(this.atributeCounter - 2)
//       this.finalProductTest[this.groupCounter][this.atributeCounter - 2] = {['atr' + (this.atributeCounter - 2)]: ''}

//       let allProducts = this.productsByGroup.sort((a, b) => {
//         return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
//       }).sort((a, b) => {
//         if(b.price == "0.00" ){
//           return -1
//         }
//         return 0
//       })
//       let currentProducts = []
        
//       for(let j = 0; j < this.finalProductTest[this.groupCounter].length - 1; j++){
//         for(let i = 0; i < allProducts.length; i++) {
//           if(allProducts[i]['atr' + j] == this.finalProductTest[this.groupCounter][j]['atr' + j] && j <= this.atributeCounter){
//             currentProducts.push(allProducts[i])
//           }
//           if(i == allProducts.length - 1 && j <= this.atributeCounter){
//             allProducts = currentProducts
//             this.filteredProducts = currentProducts
//             currentProducts = []
//           }
//         }
//       }
//     }

//     if(this.finalProductTest[this.groupCounter][0].atr0 == "<en>No Selection</en><es>Sin Selección</es>" || this.finalProductTest[this.groupCounter][0].atr0 == "<en>No Preference</en><es>Sin Preferencia</es>"){
//       this.finalProductTest[this.groupCounter][0] = this.productFilter[this.groupCounter][0]; 
//       this.atributeCounter = 1;
//       this.finalProductTest[this.groupCounter].splice(1);
//       this.productFilter[this.groupCounter].splice(1);
//     }

//     if(this.atributeCounter == 2){
//       this.filteredProducts = this.productsByGroup.filter(product => product.atr0 == '<en>No Selection</en><es>Sin Selección</es>' || (product.hidePractitioner === false && (product.refillIntervalDays == 30 || product.refillIntervalDays == 28 || product.refillIntervalDays == 0))).sort((a, b) => {
//         return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
//       }).sort((a, b) => {
//         if(b.price == "0.00" ){
//           return -1
//         }
//         return 0
//       })
//     }
//   }

//   async buildProductTest(){
//     let matchedProduct = {atr0: this.finalProductTest[this.groupCounter][0]?.atr0 || '', atr1: this.finalProductTest[this.groupCounter][1]?.atr1 || '', atr2: this.finalProductTest[this.groupCounter][2]?.atr2 || '', atr3: this.finalProductTest[this.groupCounter][3]?.atr3 || '', atr4: this.finalProductTest[this.groupCounter][4]?.atr4 || '', atr5: this.finalProductTest[this.groupCounter][5]?.atr5 || '', atr6: this.finalProductTest[this.groupCounter][6]?.atr6 || '', atr7: this.finalProductTest[this.groupCounter][7]?.atr7 || '', atr8: this.finalProductTest[this.groupCounter][8]?.atr8 || '', atr9: this.finalProductTest[this.groupCounter][9]?.atr9 || ''};
  
//     for(let p of this.allProductsTest){
//       const{ condition, id, type, price, sequence, productGroup, name, refillQty, refillIntervalDays, rxInstruction, minPoint, maxPoint, imgRef, recommended, rxcui, isPack, treatment, hidePractitioner, qty, highlight, minRefillQty, 
//         atr0, atr1, atr2, atr3, atr4, atr5, atr6, atr7, atr8, atr9, ...other } = p;
//       if(_.isEqual(matchedProduct, {atr0, atr1, atr2, atr3, atr4, atr5, atr6, atr7, atr8, atr9})){
//         let frequencies = await this.productService.getProductDeliveryWishlistFrequency([p])
//         let defaultFrequency = frequencies[p.id] ? frequencies[p.id].filter(f => f.defaultFrequency == true)[0] : {frequency: p.refillIntervalDays, autoPayment: false};
//         let data = {
//           recurrency_order: true,
//           // automaticPayment: true,
//           deliveryFrequencyInDays: defaultFrequency.frequency, //new subscription
//           automaticPayment: defaultFrequency.autoPayment //new subscription
//         }

//         matchedProduct['recurrency_order'] = data.recurrency_order;
//         matchedProduct['deliveryFrequencyInDays'] = data.deliveryFrequencyInDays;
//         matchedProduct['automaticPayment'] = data.automaticPayment;
//         matchedProduct['id'] = id;
//         matchedProduct['condition'] = condition;
//         matchedProduct['sequence'] = sequence;
//         matchedProduct['productGroup'] = productGroup;
//         matchedProduct['type'] = type;
//         matchedProduct['name'] = name;
//         matchedProduct['refillIntervalDays'] = refillIntervalDays;
//         matchedProduct['refillQty'] = refillQty;
//         matchedProduct['treatment'] = treatment;
//         matchedProduct['rxInstruction'] = rxInstruction;
//         matchedProduct['minPoint'] = minPoint;
//         matchedProduct['maxPoint'] = maxPoint;
//         matchedProduct['price'] = price;
//         matchedProduct['imgRef'] = imgRef;
//         matchedProduct['recommended'] = recommended;
//         matchedProduct['rxcui'] = rxcui;
//         matchedProduct['isPack'] = isPack;
//         matchedProduct['hidePractitioner'] = hidePractitioner;
//         matchedProduct['qty'] = qty || 0;
//         matchedProduct['minRefillQty'] = minRefillQty || 0;
//         matchedProduct['groupSelection'] = []
        
//         let filteredProducts = this.productFilter.filter(product => (product[3] && product[3][0]?.id == id) || 
//         (product[0] && product[0][product[0].length - 1].id == id)) //no selection product
        
//         if(filteredProducts.length > 0){
//           for(let i = 0; i < this.productFilter.length; i++){
//             if((this.productFilter[i][3] && this.productFilter[i][3][0]?.id == id) || 
//             (this.productFilter[i][0] && this.productFilter[i][0][this.productFilter[i][0].length - 1].id == id) //no selection product
//             ){
//               matchedProduct['groupSelection'].push(this.productGroups[i].condition)
//             }
//           }
//         }
//         else {
//           matchedProduct['groupSelection'] = [this.productGroups[this.groupCounter].condition]
//         }
        
//         let productMatch = this.allProductsTest.filter(product => product.id == id)
//         if(this.allProductsTest.some(product => product.id === id)){
//           this.finalProductTest[this.groupCounter] = [] 
//           Object.keys(productMatch[0]).sort().forEach((key: any) => {
//             if(key != 'allowRefillBeforeDays' && key != 'pillPrice'){
//               this.finalProductTest[this.groupCounter].push({[key]: productMatch[0][key]});
//             }
//           })
//         }

//         if(this.productGroups[this.groupCounter].canChooseMultipleProducts == false){
//           let consultationRef = this.afs.firestore.collection("consultation").doc(this.currentConsult.ID).collection("wishlist");
//           consultationRef.get().then(async (doc) => {
//             await doc.forEach((doc) => {
//               if(doc.data().type == "product"){
//                 if(doc.data().condition.includes(this.productGroups[this.groupCounter].condition) && ((doc.data().groupSelection && doc.data().groupSelection.includes(this.productGroups[this.groupCounter].condition)) || (!doc.data().groupSelection && doc.data().productGroup == 0))){
//                   if(!doc.data().groupSelection || doc.data().groupSelection.length == 1){
//                     this.afs.collection("consultation").doc(this.currentConsult.ID).collection("wishlist").doc(doc.data().id.toString()).delete();
//                   }
//                   else {
//                     let groupSelection = doc.data().groupSelection.filter(el => el != this.productGroups[this.groupCounter].condition)
//                     this.afs.collection("consultation").doc(this.currentConsult.ID).collection("wishlist").doc(doc.data().id.toString()).update({groupSelection: groupSelection});
//                   }
//                 }
//               }
//             })
//             this.afs.collection('consultation').doc(this.currentConsult.ID).collection("wishlist").doc(id.toString()).set(matchedProduct, {merge: true});
//           })
//         } else{
//           this.afs.collection('consultation').doc(this.currentConsult.ID).collection("wishlist").doc(id.toString()).set(matchedProduct, {merge: true});
//         }
//         break;
//       }
//     }
//   }

//   setProductTest(position: number){
//     let atrName = 'atr' + (position).toString()
//     let nextAtrName = 'atr' + (position + 1).toString()
//     let arr = []

//     if(position != this.atributeCounter - 1){
//       this.atributeCounter = position + 1
//     }

//     if(!this.productFilter[this.groupCounter]){
//       this.productFilter[this.groupCounter] = []
//     }

//     this.productFilter[this.groupCounter][position + 1] = []
//     if(this.filteredProducts.length != 0){
//       this.filteredProducts.forEach(prod => {
//         if(prod[atrName] == this.finalProductTest[this.groupCounter][position][atrName]){  
//           arr.push(prod);
//           var copy = arr.slice(0);
//           copy.sort((a, b) => {
//             return a.sequence - b.sequence;
//           });    
          
//           this.productFilter[this.groupCounter][position + 1] = [...new Map(copy.map(item => [item[nextAtrName], item])).values()];       
//         }
//       });
      
//       if(this.productFilter[this.groupCounter][position + 1][0] && this.productFilter[this.groupCounter][position + 1][0][nextAtrName] != ''){
//         let allProducts = this.productsByGroup.sort((a, b) => {
//           return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
//         }).sort((a, b) => {
//           if(b.price == "0.00" ){
//             return -1
//           }
//           return 0
//         })
//         let currentProducts = []
        
//         for(let j = 0; j < this.finalProductTest[this.groupCounter].length - 1; j++){
//           for(let i = 0; i < allProducts.length; i++) {
//             if(allProducts[i]['atr' + j] == this.finalProductTest[this.groupCounter][j]['atr' + j] && j <= position){
//               currentProducts.push(allProducts[i])
//             }
//             if(i == allProducts.length - 1 && j <= position){
//               allProducts = currentProducts
//               this.filteredProducts = currentProducts
//               currentProducts = []
//             }
//           }
//         }
//         this.setProductTest(position + 1);
//       }
//       if(position == 3){
//         this.productBillingTerm();
//       }
//     }
//   }

//   handleProductRequiredTest(){
//     let index = this.groupCounter;

//     if(this.finalProductTest[this.groupCounter] && this.finalProductTest[this.groupCounter][0] && (this.finalProductTest[this.groupCounter][0].atr0 == "<en>No Selection</en><es>Sin Selección</es>" || this.finalProductTest[this.groupCounter][0].atr0 == "<en>No Preference</en><es>Sin Preferencia</es>")){
//       this.productFilter[this.groupCounter].splice(1)
//       // this.groupCounter = 0;
//       return false;
//     }
//     else if(this.productGroups[index]?.required == true && this.productFilter[this.groupCounter]){
//       // let productGroup = this.selectedItems.filter(el => {
//       //   return el.productGroup === index
//       // })

//       // console.warn('productGroup', productGroup, this.productFilter[this.groupCounter].length - 1, this.searchGroup.length, this.productFilter[this.groupCounter].length)
//       // if(productGroup.length !== 0 || this.productFilter[this.groupCounter].length == 4){ // og without || this.productFilter[this.groupCounter].length == 4
//       //   return false;
//       // }
//       let nextAtrName = 'atr' + (this.productFilter[this.groupCounter].length).toString()
//       if(this.finalProductTest[this.groupCounter][0] && this.finalProductTest[this.groupCounter][0].atr0 && this.productFilter[this.groupCounter].length > 0 && (!this.productFilter[this.groupCounter][this.productFilter[this.groupCounter].length - 1][0] || (this.productFilter[this.groupCounter][this.productFilter[this.groupCounter].length - 1][0] && this.productFilter[this.groupCounter][this.productFilter[this.groupCounter].length - 1][0][nextAtrName] == '') || this.productFilter[this.groupCounter].length == 9)){ 
//         return false;
//       }
//       return true;
//     }
//     else{
//       return false;
//     }
//   }

//   handleProductGroups($event){

//     this.groupCounter = $event.target.id == "next" ? this.groupCounter + 1 : this.groupCounter - 1;
//     this.atributeCounter = $event.target.id == "next" ? 1 : this.productFilter[this.groupCounter].length - 1;
//     this.productsByGroup = this.allProductsTest.filter(product => product.condition.includes(this.consultRules.conditions[this.groupCounter].condition) && product.states.some(p => p.state_code == this.currentConsult.state.code )).sort((a, b) => {
//       return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
//     }).sort((a, b) => {
//       if(b.price == "0.00" ){
//         return -1
//       }
//       return 0
//     })

//     this.filteredProducts = this.allProductsTest.filter(product => product.condition.includes(this.consultRules.conditions[this.groupCounter].condition) && product.states.some(p => p.state_code == this.currentConsult.state.code )).sort((a, b) => {
//       return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
//     }).sort((a, b) => {
//       if(b.price == "0.00" ){
//         return -1
//       }
//       return 0
//     })

//     if($event.target.id == "next"){
//       this.productFilter[this.groupCounter] = []
//       this.productFilter[this.groupCounter][0] = [...new Map(this.filteredProducts.map(item => [item.atr0, item])).values()]
      
//       if(!this.finalProductTest[this.groupCounter]){
//         this.finalProductTest[this.groupCounter] = [{atr0: ''}]
//       }
//       else {    
//         this.setProductTest(0)
//       }
//     }
    
//     this.consultationsService.getProductsSearchGroup(this.currentConsult, this.groupCounter).then(res => {
//       this.searchGroup = res;
//     });
//   }

//   getCounterArray(): number[] {
//     return Array(this.groupCounter + 1).fill(0).map((x, i) => i);
//   }

//   sumTempTotal(){
//     let total = 0;

//     for(let i = 0; i <= this.groupCounter; i++){
//       if(this.finalProductTest[i][0] && this.finalProductTest[i][0].atr0 != "<en>No Selection</en><es>Sin Selección</es>"){
//         total += Number(this.productFilter[i][3][0].price);
//       }
//     }

//     return total;
//   }

//   lowestStartingPrice = []
//   productsByGroup = []
//   productBinder(consult){
//     this.productGroups = this.consultRules.conditions;
//     this.consultationsService.getProducts(consult,0).then(async products => {

//     this.allProductsTest = await products.filter(product => ((product.refillIntervalDays == 90 || product.refillIntervalDays == 30 || product.refillIntervalDays == 28) && product.hidePractitioner === false) || product.atr0 == '<en>No Selection</en><es>Sin Selección</es>').sort((a, b) => {
//       return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
//     }).sort((a, b) => {
//       if(b.price == "0.00" ){
//         return -1
//       }
//       return 0
//     })

//     this.productsByGroup = await products.filter(product => (product.atr0 == '<en>No Selection</en><es>Sin Selección</es>' || (product.hidePractitioner === false && (product.refillIntervalDays == 30 || product.refillIntervalDays == 28 || product.refillIntervalDays == 0))) && product.condition.includes(this.consultRules.conditions[this.groupCounter].condition) && product.states.some(p => p.state_code == this.currentConsult.state.code)).sort((a, b) => {
//       return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
//     }).sort((a, b) => {
//       if(b.price == "0.00" ){
//         return -1
//       }
//       return 0
//     })
    
//     this.filteredProducts = await products.filter(product => (product.atr0 == '<en>No Selection</en><es>Sin Selección</es>' || (product.hidePractitioner === false && (product.refillIntervalDays == 30 || product.refillIntervalDays == 28 || product.refillIntervalDays == 0))) && product.condition.includes(this.consultRules.conditions[this.groupCounter].condition) && product.states.some(p => p.state_code == this.currentConsult.state.code)).sort((a, b) => {
//       return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
//     }).sort((a, b) => {
//       if(b.price == "0.00" ){
//         return -1
//       }
//       return 0
//     })

//     this.lowestStartingPrice = await products.filter(product => (product.atr0 == '<en>No Selection</en><es>Sin Selección</es>' || (product.hidePractitioner === false && (product.refillIntervalDays == 30 || product.refillIntervalDays == 28 || product.refillIntervalDays == 0))) && product.condition.includes(this.consultRules.conditions[this.groupCounter].condition) && product.states.some(p => p.state_code == this.currentConsult.state.code)).sort((a, b) => {
//       return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
//     }).sort((a, b) => {
//       if(b.price == "0.00" ){
//         return -1
//       }
//       return 0
//     })
    
//     this.lowestStartingPrice.forEach(product => { 
//       product.pillPrice = product.price / product.qty
//       return product
//     })

//     this.lowestStartingPrice.sort((a, b) => {
//       return a.pillPrice - b.pillPrice
//     })

//     let packFromOdoo = localStorage.getItem('package');
//       if(this.currentPreviousUrlPath == "/prescription-list" && packFromOdoo){
//         let pack = this.allProductsTest.filter(t => t.id == Number(packFromOdoo));
//         this.afs.collection("consultation").doc(this.currentConsult.ID).collection("wishlist").doc(pack[0].id.toString()).set(pack[0], {merge: true}).then(() => {
//           localStorage.removeItem('package');
//         });
//       }

//     this.productFilter[this.groupCounter] = []
    
//     this.productFilter[this.groupCounter][0] = [...new Map(this.filteredProducts.map(item => [item.atr0, item])).values()]

//     this.afs.firestore.collection('consultation').doc(this.currentConsult.ID).collection('wishlist').get().then(async items => {
//       let selectedItems = items.docs.map(i => i.data());

//       let packFromOdoo = localStorage.getItem('package');
//       if(packFromOdoo){
//         let pack = this.allProductsTest.filter(t => t.id == Number(packFromOdoo))
//         if(pack.length > 0){
//           Object.keys(pack[0]).sort().forEach((key: any) => {
//             if(key == 'atr0'){
//               this.finalProductTest[this.groupCounter].push({[key]: pack[0][key]});
//               this.productFilter[this.groupCounter] = [];
//               this.productFilter[this.groupCounter][0] = [...new Map(this.allProductsTest.map(item => [item.atr0, item])).values()]
//               this.filterAtributes(pack[0].atr0, 0)
//             }
//           })
//         }
//         localStorage.removeItem('package');
//       }


//       for(let i = 0; i < this.productGroups.length; i++){
//         // let filtered = selectedItems.filter(item => item.groupSelection == i);
//         let filtered = selectedItems.filter(item => (item.groupSelection && item.groupSelection.includes(this.productGroups[i].condition)) || (item.condition && item.condition.includes(this.productGroups[i].condition)));
//         if(filtered.length > 0){
//           if(this.allProductsTest.some(product => product.id === filtered[0].id)){
//             this.finalProductTest[i] = [] 
//             Object.keys(filtered[0]).sort().forEach((key: any) => {
//               this.finalProductTest[i].push({[key]: filtered[0][key]});
//             })
            
//             if(i == 0){
//               this.setProductTest(0)
//               this.upgradeBillingTerm = filtered[0].refillIntervalDays;
//             }
//           }
//         }
//       }
//     });
//   });
//   this.consultationsService.getProductsSearchGroup(consult, this.groupCounter).then(res => {
//     this.searchGroup = res;
//   });
//   }

//   upgradeBillingTerm:number = 30;
//   productBillingTerm(){
//     document.body.scrollTop = this.preconsult ? 80 : 0; // For Safari
//     document.documentElement.scrollTop = this.preconsult ? 80 : 0; // For Chrome, Firefox, IE, and Opera

//     this.upgradeBillingTerm = this.upgradeBillingTerm == 30 ? 90 : 30;

//     let typeofPlan = this.allProductsTest.filter(p => (p.refillIntervalDays == 30 || p.refillIntervalDays == 90 || p.refillIntervalDays == 28) 
//     && p.atr0 == this.finalProductTest[this.groupCounter][0].atr0 && p.atr1 == this.finalProductTest[this.groupCounter][1].atr1 && p.atr2 == this.finalProductTest[this.groupCounter][2].atr2
//     )

//     if(!this.productFilter[this.groupCounter]){
//       this.productFilter[this.groupCounter] = []
//     }

//     this.productFilter[this.groupCounter][3] = [...new Map(typeofPlan.map(item => [item['atr3'], item])).values()];
//   }
//   handleNextBtn(){
//     if(this.finalProductTest[this.groupCounter] && this.finalProductTest[this.groupCounter].length <= 1){
//       //Do nothing. This is to prevent the user from moving to the next group without selecting a attribute
//     } else
//     if(this.groupCounter != this.productGroups.length -1 && this.finalProductTest[this.groupCounter]){
//       this.handleProductGroups({target: {id: 'next'}});
//     }
//     else {
//       let productFilter = this.finalProductTest.filter(product => {
//         if(product[0].atr0 != '<en>No Selection</en><es>Sin Selección</es>'){
//           return true
//         }
//       })
  
//       if(productFilter.length == 0){
//         this.nextAction.emit(true)
//       }
//       else {
//         this.dummyCheckout = true;
//         this.productBillingTerm()
//       }
//     }
//   }

//   handleBackBtn(){
//     if(this.groupCounter == 0 && !this.finalProductTest[this.groupCounter][0]['atr0']){
//       this.backAction.emit(true);
//     }
//     else if(this.groupCounter > 0 && !this.finalProductTest[this.groupCounter][0]['atr0']){
//       this.handleProductGroups({target: {id: 'back'}});
//     }
//     else if(this.finalProductTest[this.groupCounter][0]['atr0'] && !this.dummyCheckout){
//       this.filterAtributesBack();
//     }
//     else {
//       this.dummyCheckout = false;
//     }
//   }

//   recommendedDosage(){
//     return this.finalProductTest[this.groupCounter][0].atr0 == 'Sildenafil (Viagra)' ? '50mg' : this.finalProductTest[this.groupCounter][0].atr0 == 'Tadalafil (Cialis)' ? '10mg' : this.finalProductTest[this.groupCounter][0].atr0 == 'Paroxetine' ? '10mg' : this.finalProductTest[this.groupCounter][0].atr0 == 'Atomoxetine (Strattera)' ? '40mg' : ''
//   }
}
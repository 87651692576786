<div id="layout-wrapper">
  <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()" (settingsButtonClicked)="onSettingsButtonClicked()">
  </app-topbar>
  <app-sidebar (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-sidebar>
  <div class="main-content">
    <div class="page-content mt-3">
      <router-outlet *ngIf="language == true"></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>
<!-- END layout-wrapper -->
<app-reload *ngIf="loading"></app-reload>

<ng-template #phoneVerification let-modal>
  <div class="m-2">
    <h3 class="m-0">{{'SIGNUP.PHONEVERIFICATION' | translate}}</h3>
    <app-phone-verification class="form-group row d-flex flex-column justify-content-center mt-2 mb-0" [profile]="profile" [phoneNumber]="('+1' + profile.phoneNumber)" (linkPhoneNumber)="modal.close()"></app-phone-verification>
    <div class="d-flex justify-content-center">
      <button class="btn btn-light mt-2 mb-2 justify-content-start float-left" style="width: 300px;" (click)="modal.close()">{{'PROFILE.SWAL.CANCEL' | translate}}</button>
    </div>
  </div>
</ng-template>

<ng-template #surveyModal let-modal>
  <app-survey [survey]="survey"></app-survey>
</ng-template>
import { Injectable } from '@angular/core';
import { Router, UrlSerializer, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(
    private router: Router,
    private urlSerializer: UrlSerializer
  ) { }

  public previousNavigationPath(): string{
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.previousNavigation) {
      const previousUrlTree: UrlTree = navigation.previousNavigation.finalUrl;
      const previousUrl = this.urlSerializer.serialize(previousUrlTree);
      let previousUrlPath = this.extractPath(previousUrl);
      return previousUrlPath;
    }
  }
  

  private extractPath(url: string): string {
    const urlTree: UrlTree = this.router.parseUrl(url);
    const segments = urlTree.root.children['primary']?.segments;
    const path = segments ? segments.map(segment => segment.path).join('/') : undefined;
    return  path ? `/${path}` : url;
  }
}

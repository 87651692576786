import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { BaseProduct, createOrder, Profile } from './interfaces';
import { HttpClient} from '@angular/common/http';
import firebase from "firebase/compat/app";


import { getFunctions, httpsCallable } from "firebase/functions";
import { environment } from 'src/environments/environment';

const app = firebase.initializeApp(environment.firebaseConfig);
const functions = getFunctions(app);

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private apiUrl = 'https://us-central1-dzeus-app-telemd.cloudfunctions.net/erp-AddCouponInOrder';
  private apiUrl2 = 'https://us-central1-dzeus-app-telemd.cloudfunctions.net/erp-RemoveCouponInOrder';

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient
  ) { }


  async getOrder(id:string){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("orders").doc(id).valueChanges().subscribe((order) => {
        resolve(order);
      })
    })
  }

  getUserOrders(uid:string){
    return new Promise<createOrder[]>((resolve, reject) => {
      this.afs.collection("orders", ref => ref.where("uid", "==", uid)).valueChanges().subscribe((orders:any) => {
        resolve(orders);
      })
    })
  }

  async createOrderLine(orderId:string, product:BaseProduct){
    let lines:BaseProduct[] = await this.getOrderLines(orderId);
    let service = lines.filter(line => line.type == 'service');

    if(service.length > 0){
      for(let i = 0; i < service.length; i++){
        this.afs.collection("orders").doc(orderId).collection("orderLines").doc(service[i].id).delete();
      }
    }
    this.afs.collection("orders").doc(orderId).collection("orderLines").doc(product.id).set(product)
  }

  async getOrderLines(orderId:string){
    return new Promise<[]>((resolve, reject) => {
      this.afs.collection("orders").doc(orderId).collection("orderLines").valueChanges().subscribe((lines:[]) => {
        resolve(lines);
      })
    })
  }

  async getServiceFromConsultation(consultId:string){
    return new Promise<any>((resolve, reject) => {
      this.afs.firestore.collection("consultation").doc(consultId).collection("Items").doc("service").get().then(snapshot => {
        resolve(snapshot.data());
      })
    });
  }

  async observeChangesInOrder(consultId:string, uid: string){
    let collection =   this.afs.collection<any>('orders', ref => ref.where('uid', '==', uid).where('consultationID', '==', consultId).where("orderState", "!=", "cancel"))
    let order = collection.valueChanges().pipe(
      map(details => { return details;}))
    return order; 
   }

   async getWishlist(consultId:string){
    return new Promise<any>(async (resolve, reject) => {
      this.afs.collection("consultation").doc(consultId).collection("wishlist").valueChanges().subscribe(async (items:any) => {
        resolve(items);
      })
    })
  }

  async createOrderOdoo(order:any){
    
    let data = {
      orderState: "create",
      sync_time: new Date(Date.now())
    }
    
    if(order.shippingAddress){
      let pricelist = await this.getPricelist(order.shippingAddress.AddressStateCode);
      if(pricelist != order.pricelist && !order.refill){
        order.pricelist = pricelist
        data = Object.assign(data, {pricelist: pricelist})
      }
    }

    this.afs.firestore.collection("orders").doc(order.id).collection("orderLines").get().then(async (lines:any) => {
      lines = lines.docs.filter(x => x.data().promo == true);
      if(lines.length > 0){
        lines.forEach((line, i:number) => {
          this.afs.collection("orders").doc(order.id).collection("orderLines").doc(line.data().id).delete();
          // this.removeFieldsIfExist(order.id); //Commented out because it is not used
          if(i == lines.length - 1){
            this.afs.collection("orders").doc(order.id).update(data);
          }
        });
      }
      else{
        this.afs.collection("orders").doc(order.id).update(data);
      }
    });
    this.updateCouponAppliedField(order);
  }

  getPricelist(state){
    return new Promise<any>((resolve, reject) => {
      if(state){
        this.afs.firestore.collection("pricelist").where('states', 'array-contains', state).get().then(async snapshot => {
          let pricelists = snapshot.docs.map(doc => doc.data()).sort((a, b) => b.date.seconds - a.date.seconds);
          if(pricelists.length > 0){
            resolve(Number(pricelists[0].id)); 
          }
          else {
            resolve('null');
          }
        })
      }
      else {
        resolve('null');
      }
    })
  }

  async createOrderOnCall(uid: string, consultationID:string, profile:Profile, service: BaseProduct, consultState: string){
    try{
      const CreateOrderFunction = httpsCallable(functions, 'oncall-create-order-firebase');
        // const searchUserByEmail = httpsCallable(functions, 'oncall-create-accepta-payment-initiation');
        let order = await CreateOrderFunction({
          uid: uid,
          consultID: consultationID,
          patient: profile,
          service: service,
          consultState: consultState
        });
        return order;
    }
    catch(e){
    }
  }

  sendOrderData(orderData: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, orderData);
  }

  async removeFieldsIfExist(docId: string) {
    const docRef = this.afs.firestore.collection('orders').doc(docId);
  
    try {
      // Get the document data directly using async/await
      const docSnapshot = await docRef.get(); // No need for toPromise()
  
      if (docSnapshot.exists) {
        const docData = docSnapshot.data();
  
        const updates: any = {};
  
        // Check if the fields exist in the document
        if (docData && 'coupon' in docData) {
          updates.coupon = firebase.firestore.FieldValue.delete();
        }
        if (docData && 'couponApplied' in docData) {
          updates.couponApplied = firebase.firestore.FieldValue.delete();
        }
  
        // If there are any fields to delete, update the document
        if (Object.keys(updates).length > 0) {
          await docRef.update(updates);
        } 
        else {
          // console.log('No fields to delete');
        }
      } 
      else {
        // console.log('Document does not exist');
      }
    } 
    catch (error) {
      // console.error('Error removing fields: ', error);
    }
  } 

  updateCouponAppliedField(order: any){
    if(order.couponApplied){
      this.afs.collection("orders").doc(order.id).set({couponApplied: false}, {merge: true});
    }
  }

  updateOrderData(orderData: any): Observable<any> {
    return this.http.post<any>(this.apiUrl2, orderData);
  }

}
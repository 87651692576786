import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-engagement-step',
  templateUrl: './engagement-step.component.html',
  styleUrls: ['./engagement-step.component.scss']
})


export class EngagementStepComponent {
  @Input() consultRules: any;
  @Output() back = new EventEmitter<void>();
  @Output() next = new EventEmitter<void>();
}

import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';
import { LanguageService } from '../../../core/services/language.service';
import { environment } from '../../../../environments/environment';
import {FirebaseService} from '../../../core/services/firebase.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import { DataService } from 'src/app/pages/notifications/data.service';
import firebase from "firebase/compat/app";
//New import of users
import {User} from '@firebase/auth-types';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';
import { AuditTraceService } from 'src/app/core/services/audit-trace.service';
import { fbRemoteConfig } from 'src/app/authUtils';
import { TranslationPipe } from 'src/app/translation.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {
  @Output() filteredNotifications = new EventEmitter<any>()

  cookieValue;
  flagvalue;
  countryName;
  valueset: string;
  user: User;
  public userData: any;
  notifications: Array<any> = [];
  mail:any;
  unread: number = 0;
  //camera 
  image: any = "assets/images/users/profile.png";

  chats: Array<any> = [];
  unreadChats = 0;
  unreadChatsCopy = 0;

  subscription = new Subscription()
  currentURL

  hidden = false;

  listLang = [
    { text: 'English(US)', flag: 'https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/icons%2FEU_1_2.svg?alt=media&token=2a8077e3-ab55-45a5-b901-abff795903b7', lang: 'en' },
    { text: 'Español(PR)', flag: 'https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/icons%2FPR_16.svg?alt=media&token=85736a35-41c8-41cb-a410-0ea2ef8d13ec', lang: 'es' },
  ];

  settings = fbRemoteConfig.defaultConfig
  supportEmail;
  translationPipe = new TranslationPipe()

  constructor(
    public router: Router, 
    public authService: AuthenticationService, 
    public languageService: LanguageService, 
    public afAuth: AngularFireAuth,
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
    private notificationsService: DataService,
    public translate: TranslateService,
    private auditTraceService: AuditTraceService,
    public modalService: NgbModal,
  ) { 
    this.hidden = screen.width < 650 || (window.matchMedia("(orientation: landscape-primary)") && screen.height < 650) ? true : false;
    this.currentURL = this.router.url
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() settingsButtonClicked = new EventEmitter();

  async ngOnInit(){
    this.cookieValue = this.translate.store.currentLang || 'es';
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = !this.hidden ? this.countryName = val.map(element => element.text).toString() : this.countryName = val.map(element => element.lang).toString();
    this.flagvalue = val.map(element => element.flag);
    this.getUser();
  }

  getUser() {
    let sub = this.afAuth.user.subscribe(user => {
      this.user = user;
      if(user){
        let sub = this.afs.collection('users').doc(`${user.uid}`).valueChanges().subscribe(async data => {
          this.userData = await data;
          this.image = this.userData.image ? this.userData.image : 'assets/images/users/profile.png';
          this.getNotifications(data['uid']);
          this.getChats()
          this.setLang(this.userData.preferedLang)
          this.supportEmail = this.translationPipe.transform(fbRemoteConfig.defaultConfig.supportEmail, this.userData.preferedLang)
        });
        this.subscription.add(sub)
      }
      else {
        this.router.navigate(['/account/welcome']).then(async () => {
          this.currentURL = this.router.url == '/account/reload' ? '/account/welcome' : this.router.url;
          ReloadComponent.loader = 'RELOAD.LOGOUT'
          await this.router.navigateByUrl('/account/reload', { skipLocationChange: true });
          await setTimeout(async () => {
            ReloadComponent.loader = ''
            return await this.router.navigateByUrl(this.currentURL);
          },500)
        });
      }
    });
    this.subscription.add(sub)
  }

  setLang(lang){
    for(let i = 0; i < this.listLang.length; i++){
      if(lang == this.listLang[i].lang){
        this.languageService.setLanguage(lang); 
        this.countryName = !this.hidden ? this.countryName = this.listLang[i].text.toString() : this.countryName = this.listLang[i].lang.toString();
        this.flagvalue = this.listLang[i].flag;
        this.cookieValue = lang;
      }
    }  
  }

  getNotifications(user:any){
    let sub = this.afs.collection<any>('mail', ref => ref.where('toUID', '==', user).orderBy('id', 'desc')).valueChanges().subscribe(mail => {
      this.unread = 0;
      this.notifications = []
      mail = mail.filter(m => !m.archive.includes(user)).sort((a, b) => {
        return b?.delivery?.startTime - a?.delivery?.startTime
      })

      this.unread = mail.filter(x => x.read == false).length

      for(let i = 0; i < 3; i++){
        this.notifications[i] = mail[i];
        var filter = this.notifications.filter(function (el) {
          return el != undefined
        });
        this.notifications = filter;
      }
    });
    this.subscription.add(sub)
  }

  notificationLink(data) {
    return data?.template?.data.subject !== undefined ? ['inboxandsupport/read',data?.id] : ['inboxandsupport/orders',data?.template.data.orderNumber]
  }

  chatCounter = 0
  getChats(){
    if(this.user?.email){
      this.unreadChats = 0;
      let sub = this.afs.collection('chatMessages', ref => ref.where('toUid', 'array-contains', this.user.uid).where('from', '!=', this.user.email)).valueChanges().subscribe((chats: any) => {  
        for(let j = 0; j < chats.length; j++){
          let index = chats[j].to.indexOf(this.userData.email)
          if(typeof(chats[j].read) != 'boolean' && chats[j].read[index] == false){// && chats[j].to == this.user?.email
            this.unreadChats += 1;
          }
        }
        this.unreadChatsCopy = this.unreadChats;
        this.unreadChats = 0
      })   
      this.subscription.add(sub)
    }
  }

  handleNotification(notification:any){
    if(notification.read == false && !notification.archive.includes(this.userData.uid)){
      this.unread = this.unread - 1;
    }
    
    if(notification.read == false){    
      notification.read = true;   
      this.firebaseService.archiveMail(notification); 
    }
    this.notificationsService.selectedNotification(notification);
  }

  /* Toggle the menu bar when having mobile screen */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /* Translate language */
  async setLanguage(text: string, lang: string, flag: string) {
    this.currentURL = this.router.url
    let sub = await this.afs.collection('users').doc(`${this.userData.uid}`).update({preferedLang: lang}).then( async res => {      
      await this.languageService.setLanguage(lang); 
      this.countryName = !this.hidden ? text.toString() : lang.toString();
      this.flagvalue = await flag;
      this.cookieValue = await lang;
      
      ReloadComponent.loader = 'RELOAD.DEFAULT'
      await this.router.navigateByUrl('account/reload', { skipLocationChange: true });     
      await setTimeout(async () => {
        return await this.router.navigateByUrl(this.currentURL);
      },500)
    });
    this.subscription.add(sub)
  }

  async logout() {
    let sub = this.afAuth.user.subscribe(async user => {
      if(user){
        await this.auditTraceService.logoutTrace().then(async res => {
          await this.authService.logout().then(res => {
            window.location.reload()
          });
        });
      }
    });
    this.subscription.add(sub)
  }

  emit(){
    this.notificationsService.emitter()
  }

  contactUs(modal, event){
    if(event.target.localName == 'i' || event.target.localName == 'button' || event.target.localName == 'p'){
      this.modalService.open(modal, { centered: true });
    }
    if(event.target.localName == 'span' || event.target.localName == 'small') {
      var popup = document.getElementById("myPopup");
      popup.classList.toggle("show");
    }
  }
}
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationPipe } from 'src/app/translation.pipe';
import { FirebaseService } from './firebase.service';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticationService } from './auth.service';
import { Profile } from './interfaces';
import { remoteConfig } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailsService {

  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    public firebaseService: FirebaseService,
    private authService: AuthenticationService,
  ) {}

  translationPipe = new TranslationPipe()
  settings = remoteConfig;

  //variables for email
  facebook = ''
  instagram = ''
  twitter = ''
  phoneNumber = ''
  contactUs = ''
  myAccount = ''
  privacyPolicy = ''
  help = ''
  appName = ''
  year = ''
  supportEmail = ''
  chatSchedule = ''
  logo = ''
  link = ''
  toName = ''
  fromName = ''
  name = ''
  consultID = ''
  serviceType = ''
  fields = []
  followUpDate = ''
  nextAttempt = ''
  last4 = ''
  retrialTime = ''
  order;
  orderLines;
  orderNumber = ''
  amountDueToday = ''
  phoneConsult = false;
  practitionerPhoneNumber = ''
  abbr = ''
  id = ''
  shortUrl = ''
  smsShortUrl = ''
  question = ''

  orderChange(email:string, practitioner:any, lang:any, order:any){
    const URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/orderChange`;
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '* ');
    let body = new HttpParams();
    body = body.set('email', email);
    body = body.set("practitioner", JSON.stringify(practitioner));
    body = body.set("patientLang", lang);
    body = body.set('order', order);
    this.http.post(URL, body, {responseType: 'text'}).subscribe(res => {
    });
  }

  suggestionEmail(email:string, practitioner:any, lang:any, order:any, name: any){
    return new Promise((resolve, reject) => {
      const URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/treatmentSuggestion`;
      const headers = new HttpHeaders();
      headers.set('Content-Type', 'application/json');
      headers.set('Access-Control-Allow-Origin', '*');
      let body = new HttpParams();
      body = body.set('email', email);
      body = body.set("practitioner", JSON.stringify(practitioner));
      body = body.set("patientLang", lang);
      body = body.set('order', JSON.stringify(order));
      body = body.set('name', name)
      // this.http.post(URL, body, {responseType: 'text'}).subscribe(res => {
      //   resolve(res);
      // });
    });
  }

  async emailConsultProcessed(data: any){
    const URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/emailConsultProcessed`;
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    let body = new HttpParams();
    // body = body.set('email', email);
  }

  async emailPaymentFailed(data:any){
    const URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/emailPaymentFailed`;
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    let body = new HttpParams();
  }

  async emailRejected(data:any){
    const URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/emailPatientRejectedOrderChange`;
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    let body = new HttpParams();
    body = body.set('email', data.email);
    body = body.set('message', data.message);
    this.http.post(URL, body, {responseType: 'text'}).subscribe(res => {
    });
  }

  patientConsultEmails(user: any, patientProfile: any, consult, emailTemplate, subject, settings, link?, fields?, followUpDate?, nextAttempt?, last4?, retrialTime?, phoneConsult?, finishedConsult?){
    
    return new Promise<any>((resolve, reject) => {
      let template
  
      this.facebook = settings.facebook
      this.instagram = settings.instagram
      this.twitter = settings.twitter
      this.phoneNumber = settings.phoneNumber
      this.contactUs = settings.contactUs
      this.myAccount = settings.myAccount
      this.privacyPolicy = settings.privacyPolicy
      this.help = settings.help
      this.appName = settings.appName
      this.name = patientProfile.firstName + ' ' + patientProfile.lastName1
      this.year = settings.year
      this.supportEmail = this.translationPipe.transform(settings.supportEmail, patientProfile.preferedLang)
      this.chatSchedule = this.translationPipe.transform(settings.chatSchedule, patientProfile.preferedLang)
      this.logo = settings.pngLogo
      this.link = link ? link : settings.loginLink
      this.toName = patientProfile.firstName + ' ' + patientProfile.lastName1
      this.fromName = user.displayName
      this.consultID = consult.ID
      this.followUpDate = followUpDate ? followUpDate : ''
      this.nextAttempt = nextAttempt ? nextAttempt : ''
      this.last4 = last4 ? last4 : ''
      this.retrialTime = retrialTime ? retrialTime : ''
      this.phoneConsult = phoneConsult == true ? true : false
      this.practitionerPhoneNumber = settings.practitionerPhoneNumber

      this.afs.firestore.collection('mail_templates').doc(emailTemplate).get().then(async (email: any) => {
        template = email.data().html
        template = template.replace('target="_blank"', '')
        let smsAvailable = email.data().smsAvailable

        if(fields){
          template = template.replace('{{#each fields}}', '<div style="display: flex; flex-direction: column; width: max-content; margin: auto; align-items: flex-start;">')
          template = template.replace('{{/each}}', '</div>')
          this.fields = fields
          let test = '';
          for(let i = 0; i < fields.length; i++){
            test += `<li style="text-align: start;">${fields[i].field}</li>`
          }
          template = template.replace('<li style="text-align: start;">{{field}}</li>', test) 
        }

        if(finishedConsult){
          template = template.replace('{{#each fields}}', '<div style="display: flex; flex-direction: column; width: fit-content; margin: auto; align-items: flex-start; margin-bottom:15px">')
          template = template.replace('{{/each}}', '</div>')
          this.fields = []
          let test = '';
          for(let i = 0; i < finishedConsult.length; i++){
            let line = await this.translationPipe.transform(finishedConsult[i], patientProfile.preferedLang)
            test += `<li style="text-align: start;">${line}</li>`
            this.fields.push({field: line})
          }
          template = template.replace('<li style="text-align: start;">{{field}}</li>', test) 
        }

        if(phoneConsult != undefined){
          template = template.replace('{{#if phoneConsult}}', '<div *ngIf="email?.template?.data?.phoneConsult">')
          template = template.replace('{{/if}}', '</div>')
        }
        
        let test = template.split('{{')
        for(let i = 1; i < test.length; i++){
          template = template.replace('{{' + test[i].split('}}')[0] + '}}', this[test[i].split('}}')[0]])
        }   
        let date = new Date(Date.now());
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();
        let id:any = yyyy + mm + dd + uuidv4();
        let data = {
          id: id,
          date: new Date(),
          consultID: consult.ID,
          consultMail: true,
          template: {
            name: emailTemplate,
            data: {
              from: user.email,
              fromName: user.displayName,
              to: patientProfile.email,
              toName: patientProfile.firstName + ' ' + patientProfile.lastName1,
              firstName: patientProfile.firstName,
              lastName: patientProfile.lastName1,
              subject: subject,
              html: await template,
              Order: false,
              facebook: settings.facebook,
              instagram: settings.instagram,
              twitter: settings.twitter,
              phoneNumber: settings.phoneNumber,
              contactUs: settings.contactUs,
              myAccount: settings.myAccount,
              privacyPolicy: settings.privacyPolicy,
              help: settings.help,
              appName: settings.appName,
              name: patientProfile.firstName + ' ' + patientProfile.lastName1,
              year: settings.year,
              supportEmail: this.translationPipe.transform(settings.supportEmail, patientProfile.preferedLang),
              chatSchedule: this.translationPipe.transform(settings.chatSchedule, patientProfile.preferedLang),
              logo: settings.pngLogo,
              link: link ? settings.domain + '/'  + link : settings.loginLink,
              consultID: consult.ID,
              fields: this.fields ? this.fields : '',
              followUpDate: followUpDate ? followUpDate : '',
              retrialTime: this.retrialTime,
              last4: this.last4,
              nextAttempt: this.nextAttempt,
              phoneConsult: this.phoneConsult ? this.phoneConsult : false,
              practitionerPhoneNumber: settings.practitionerPhoneNumber,
            }
          },  
          to: patientProfile.email,
          fromUID: user.uid,
          archive: [],
          toUID: patientProfile.uid,
          read: false,
          headers: {'X-Entity-Ref-ID': uuidv4()}
        }
        this.afs.collection("mail").doc(data.id).set(data).then(
          res => {
            resolve(true)
            data = undefined
          }
        );
        if(smsAvailable == true && patientProfile.phoneNumberMsg){
          let smsTemplate = email.data().smsTemplate;
          this.sendSms(patientProfile, smsTemplate, false)
        }
      })
    })
  }

  patientOrderEmails(user: any, patientProfile: any, emailTemplate, subject, settings, order, orderLines){
    
    return new Promise<any>((resolve, reject) => {

      let template 
  
      this.facebook = settings.facebook
      this.instagram = settings.instagram
      this.twitter = settings.twitter
      this.phoneNumber = settings.phoneNumber
      this.contactUs = settings.contactUs
      this.myAccount = settings.myAccount
      this.privacyPolicy = settings.privacyPolicy
      this.help = settings.help
      this.appName = settings.appName
      this.name = patientProfile.firstName + ' ' + patientProfile.lastName1
      this.year = settings.year
      this.supportEmail = this.translationPipe.transform(settings.supportEmail, patientProfile.preferedLang)
      this.chatSchedule = this.translationPipe.transform(settings.chatSchedule, patientProfile.preferedLang)
      this.logo = settings.pngLogo
      this.link = 'inboxandsupport/orders/' + order.orderNo
      this.toName = patientProfile.firstName + ' ' + patientProfile.lastName1
      this.fromName = user.displayName
      this.order = order
      this.orderLines = orderLines
      this.orderNumber = this.order.orderNo
      this.amountDueToday = Number(this.order.orderTotal).toFixed(2)

      this.afs.firestore.collection('mail_templates').doc(emailTemplate).get().then(async (email: any) => {
        template = email.data().html
        template = template.replace('target="_blank"', '')
        let smsAvailable = email.data().smsAvailable

        let test = template.split('{{')
        for(let i = 1; i < test.length; i++){
          template = template.replace('{{' + test[i].split('}}')[0] + '}}', this[test[i].split('}}')[0]])
        }   

        let date = new Date(Date.now());
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();
        let id:any = yyyy + mm + dd + uuidv4();
        let data = {
          id: id,
          date: new Date(),
          orderMail: true,
          deliveryOrder: false,
          template: {
            name: emailTemplate,
            data: {
              from: user.email,
              fromName: user.displayName,
              to: patientProfile.email,
              toName: patientProfile.firstName + ' ' + patientProfile.lastName1,
              firstName: patientProfile.firstName,
              lastName: patientProfile.lastName1,
              subject: subject,
              html: await template,
              orderNumber: this.order.orderNo,
              Subtotal: Number(this.order.orderSubtotal).toFixed(2),
              Tax: Number(this.order.orderTax).toFixed(2),
              Total: Number(this.order.orderTotal).toFixed(2),
              Order: true,
              orderLines: this.orderLines,
              amountDueConfirmed: Number(this.order.orderTotal).toFixed(2),
              amountDueToday: Number(this.order.orderTotal).toFixed(2),
              facebook: settings.facebook,
              instagram: settings.instagram,
              twitter: settings.twitter,
              phoneNumber: settings.phoneNumber,
              contactUs: settings.contactUs,
              myAccount: settings.myAccount,
              privacyPolicy: settings.privacyPolicy,
              help: settings.help,
              appName: settings.appName,
              name: patientProfile.firstName + ' ' + patientProfile.lastName1,
              year: settings.year,
              supportEmail: this.translationPipe.transform(settings.supportEmail, patientProfile.preferedLang),
              chatSchedule: this.translationPipe.transform(settings.chatSchedule, patientProfile.preferedLang),
              logo: settings.pngLogo,
              link: settings.domain + '/'  + this.link,
            }
          },  
          to: patientProfile.email,
          fromUID: user.uid,
          archive: [],
          toUID: patientProfile.uid,
          read: false,
          headers: {'X-Entity-Ref-ID': uuidv4()}
        }

        this.afs.collection("mail").doc(data.id).set(data).then(
          res => {
            resolve(true)
            data = undefined
          }
        );
        if(smsAvailable == true && patientProfile.phoneNumberMsg){
          let smsTemplate = email.data().smsTemplate;
          this.sendSms(patientProfile, smsTemplate, false)
        }
      })
    })
  }

  generalEmails(user:any, emailTemplate, subject, settings, link?, consultID?, serviceType?, fields?, sender?){
    
    return new Promise<any>(async (resolve, reject) => {
      let template
 
      this.facebook = settings.facebook
      this.instagram = settings.instagram
      this.twitter = settings.twitter
      this.phoneNumber = settings.phoneNumber
      this.contactUs = settings.contactUs
      this.myAccount = settings.myAccount
      this.privacyPolicy = settings.privacyPolicy
      this.help = settings.help
      this.appName = settings.appName
      this.name = user.firstName + ' ' + user.lastName1
      this.year = settings.year
      this.supportEmail = this.translationPipe.transform(settings.supportEmail, user.preferedLang)
      this.chatSchedule = this.translationPipe.transform(settings.chatSchedule, user.preferedLang)
      this.logo = settings.pngLogo
      this.link = link ? link : settings.loginLink
      this.toName = user.firstName + ' ' + user.lastName1
      this.fromName = user.displayName
      this.serviceType = serviceType ? serviceType : ''
      this.consultID = consultID ? consultID : ''

      if(sender){
        this.abbr = await this.translationPipe.transform(sender.abbr, user.preferedLang)
        if(user.preferedLang == 'es'){
          if(this.abbr.split('/')[1]){
            if(sender.biologicalgender == 'Female'){
              this.abbr = this.abbr.split('/la ')[1]
            }
            else {
              this.abbr = this.abbr.split('el ')[1].split('/')[0]
            }
          }
        }
        this.fromName = this.abbr + " " + sender.firstName + " " + sender.lastName1
      }

      this.afs.firestore.collection('mail_templates').doc(emailTemplate).get().then(async (email: any) => {
        template = email.data().html
        let smsAvailable = email.data().smsAvailable
        template = template.replace('target="_blank"', '')
        if(fields){
          template = template.replace('{{#each fields}}', '<div style="display: flex; flex-direction: column; width: max-content; margin: auto; align-items: flex-start;">')
          template = template.replace('{{/each}}', '</div>')
          this.fields = fields
          let test = '';
          for(let i = 0; i < fields.length; i++){
            test += `<li style="text-align: start;">${fields[i].field}</li>`
          }
          template = template.replace('<li style="text-align: start;">{{field}}</li>', test) 
        }

        let test = template.split('{{')
        for(let i = 1; i < test.length; i++){
          template = template.replace('{{' + test[i].split('}}')[0] + '}}', this[test[i].split('}}')[0]])
        }   
        
        let date = new Date(Date.now());
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();
        let id:any = yyyy + mm + dd + uuidv4();
        let data: any = {
          id: id,
          date: new Date(),
          generalMail: true,
          template: {
            name: emailTemplate,
            data: {
              to: user.email,
              toName: user.firstName + ' ' + user.lastName1,
              firstName: user.firstName,
              lastName: user.lastName1,
              subject: subject,
              html: await template,
              Order: false,
              facebook: settings.facebook,
              instagram: settings.instagram,
              twitter: settings.twitter,
              phoneNumber: settings.phoneNumber,
              contactUs: settings.contactUs,
              myAccount: settings.myAccount,
              privacyPolicy: settings.privacyPolicy,
              help: settings.help,
              appName: settings.appName,
              name: user.firstName + ' ' + user.lastName1,
              year: settings.year,
              supportEmail: this.translationPipe.transform(settings.supportEmail, user.preferedLang),
              chatSchedule: this.translationPipe.transform(settings.chatSchedule, user.preferedLang),
              logo: settings.pngLogo,
              link: link ? settings.domain + '/'  + link : settings.loginLink,
              consultID: consultID ? consultID : '',
              serviceType: serviceType ? serviceType : '',
              fields: this.fields ? this.fields : ''
            }
          },  
          to: user.email,
          archive: [],
          toUID: user.uid,
          read: false,
          headers: {'X-Entity-Ref-ID': uuidv4()}
        }

        if(sender){
          data.template.data.from = sender.email
          data.template.data.fromName = this.abbr + " " + sender.firstName + " " + sender.lastName1
        }

        this.afs.collection("mail").doc(data.id).set(data).then(
          res => {
            resolve(true)
            data = undefined
          }
        );
        if(smsAvailable == true && user.phoneNumberMsg){
          let smsTemplate = email.data().smsTemplate;
          this.sendSms(user, smsTemplate, false)
        }
      })
    })
  }

  surveyEmails(user:any, emailTemplate, subject, settings, link, fields, question){
    
    return new Promise<any>(async (resolve, reject) => {
      let template
 
      this.facebook = settings.facebook
      this.instagram = settings.instagram
      this.twitter = settings.twitter
      this.phoneNumber = settings.phoneNumber
      this.contactUs = settings.contactUs
      this.myAccount = settings.myAccount
      this.privacyPolicy = settings.privacyPolicy
      this.help = settings.help
      this.appName = settings.appName
      this.name = user.firstName + ' ' + user.lastName1
      this.year = settings.year
      this.supportEmail = this.translationPipe.transform(settings.supportEmail, user.preferedLang)
      this.chatSchedule = this.translationPipe.transform(settings.chatSchedule, user.preferedLang)
      this.logo = settings.pngLogo
      this.link = link ? link : settings.loginLink
      this.toName = user.firstName + ' ' + user.lastName1
      this.fromName = user.displayName
      this.question = question ? await this.translationPipe.transform(question.question, user.preferedLang) : ''

      this.afs.firestore.collection('mail_templates').doc(emailTemplate).get().then(async (email: any) => {
        let smsAvailable = email.data().smsAvailable
        template = await this.manageSurveyFields(email.data().html, fields, settings)

        let test = await template.split('{{')
        for(let i = 1; i < test.length; i++){
          template = template.replace('{{' + test[i].split('}}')[0] + '}}', this[test[i].split('}}')[0]])
        }   
        
        let date = new Date(Date.now());
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();
        let id:any = yyyy + mm + dd + uuidv4();
        let data: any = {
          id: id,
          date: new Date(),
          generalMail: true,
          template: {
            name: emailTemplate,
            data: {
              to: user.email,
              toName: user.firstName + ' ' + user.lastName1,
              firstName: user.firstName,
              lastName: user.lastName1,
              subject: subject,
              html: await template,
              Order: false,
              facebook: settings.facebook,
              instagram: settings.instagram,
              twitter: settings.twitter,
              phoneNumber: settings.phoneNumber,
              contactUs: settings.contactUs,
              myAccount: settings.myAccount,
              privacyPolicy: settings.privacyPolicy,
              help: settings.help,
              appName: settings.appName,
              name: user.firstName + ' ' + user.lastName1,
              year: settings.year,
              supportEmail: this.translationPipe.transform(settings.supportEmail, user.preferedLang),
              chatSchedule: this.translationPipe.transform(settings.chatSchedule, user.preferedLang),
              logo: settings.pngLogo,
              link: this.link ? settings.domain + '/'  + this.link : settings.loginLink,
              fields: this.fields ? this.fields : '',
              id: this.id,
              question: this.question,
            }
          },  
          to: user.email,
          archive: [],
          toUID: user.uid,
          read: false,
          headers: {'X-Entity-Ref-ID': uuidv4()}
        }

        this.afs.collection("mail").doc(data.id).set(data).then(
          res => {
            resolve(true)
            data = undefined
          }
        );
        if(smsAvailable == true && user.phoneNumberMsg){
          let smsTemplate = email.data().smsTemplate;
          this.sendSms(user, smsTemplate, false)
        }
      })
    })
  }

  internalCustomMessages(id: any,user:any, subject, html, text, settings, consultID?, consultMail?, sender?, customSms?, extraData?, groupMessageId?){
    return new Promise<any>(async (resolve, reject) => {
      let template
 
      this.facebook = settings.facebook
      this.instagram = settings.instagram
      this.twitter = settings.twitter
      this.phoneNumber = settings.phoneNumber
      this.contactUs = settings.contactUs
      this.myAccount = settings.myAccount
      this.privacyPolicy = settings.privacyPolicy
      this.help = settings.help
      this.appName = settings.appName
      this.name = user.firstName + ' ' + user.lastName1
      this.year = settings.year
      this.supportEmail = this.translationPipe.transform(settings.supportEmail, user.preferedLang)
      this.chatSchedule = this.translationPipe.transform(settings.chatSchedule, user.preferedLang)
      this.logo = settings.pngLogo
      this.link = extraData ? extraData.link : settings.loginLink
      this.toName = user.firstName + ' ' + user.lastName1
      this.fromName = user.displayName
      this.consultID = consultID ? consultID : ''
      this.shortUrl = extraData ? extraData.shortUrl : ''
      this.smsShortUrl = extraData ? extraData.smsShortUrl : ''

      if(sender){
        if(sender.accountType == 'practitioner'){
          this.abbr = await this.translationPipe.transform(sender.abbr, user.preferedLang)
          if(user.preferedLang == 'es'){
            if(this.abbr.split('/')[1]){
              if(sender.biologicalgender == 'Female'){
                this.abbr = this.abbr.split('/la ')[1]
              }
              else {
                this.abbr = this.abbr.split('el ')[1].split('/')[0]
              }
            }
          }
          this.fromName = this.abbr + " " + sender.firstName + " " + sender.lastName1
        }
        else {
          this.fromName = sender.firstName + " " + sender.lastName1
        }
      }

      this.afs.firestore.collection('mail_templates').doc('consult_messages_' + user.preferedLang).get().then(async (email: any) => {
        template = email.data().html
        let smsAvailable = email.data().smsAvailable
        template = template.replace('target="_blank"', '')

        let test = template.split('{{')
        for(let i = 1; i < test.length; i++){
          template = template.replace('{{' + test[i].split('}}')[0] + '}}', this[test[i].split('}}')[0]])
        }   

        let mail = {
          id: id,
          date: new Date(),
          to: user.email,
          consultID: consultID,
          consultMail: consultMail,
          generalMail: !consultMail,
          groupMessageId: groupMessageId || '',
          reply: true,
          template: {
            name: "consult_messages_" + user.preferedLang,
            data: {
              date: new Date(),
              from: sender.email,
              fromName: sender.firstName + ' ' + sender.lastName1,
              to: user.email,
              toName: user.firstName + ' ' + user.lastName1,
              subject: subject,
              html: html,
              text: text,
              facebook: settings.facebook,
              instagram: settings.instagram,
              twitter: settings.twitter,
              phoneNumber: settings.phoneNumber,
              contactUs: settings.contactUs,
              myAccount: settings.myAccount,
              privacyPolicy: settings.privacyPolicy,
              help: settings.help,
              year: settings.year,
              abbr: this.abbr,
              supportEmail: this.translationPipe.transform(settings.supportEmail, user.preferedLang),
              chatSchedule: this.translationPipe.transform(settings.chatSchedule, user.preferedLang),
              logo: settings.pngLogo,
              link: extraData ? settings.domain + '/' + this.link : settings.loginLink,
              appName: settings.appName
            }
          },
          fromUID: sender.uid,
          toUID: user.uid,
          read: false,
          archive: [],
          headers: {'X-Entity-Ref-ID': this.firebaseService.getDate()}
        }
          
        this.afs.collection("mail").doc(mail.id).set(mail);
        if((smsAvailable == true || customSms) && user.phoneNumberMsg){
          this.sendSms(user, email.data().smsTemplate, customSms)
        }
      })
    })
    
  }

  async psycotherapyReferral(data:any ){
    const URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/`;
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '* ');
    let body = new HttpParams();
    body = body.set('email', data.patientEmail);
    body = body.set('condition', data.Type)
    this.http.post(URL, body, {responseType: 'text'}).subscribe(res => {
    });
  }

  // * * Orden ejecutiva extiende este proceso hasta 31 de diciembre del 2023
  async externalEncryptedEmail(pharmacy:any, patient:any, prescription:any){
    let settings:any = await this.authService.getRemoteConfig();
    let doctor:Profile = await this.authService.getUserProfile(prescription.docId);
    let prescName = prescription.prescription_type == 'Rx' ? 'prescriptionExternalPharmacyURL' : 'prescriptionExternalLabURL';

    let abbr:any = this.translationPipe.transform(doctor.abbr, 'en');
    let mail = {
      date: new Date(),
      id: uuidv4(),
      generalMail: false,
      template: {
        name: prescription.prescription_type == 'Rx' ? `externalPharmacy_en_test` : `externalLab_en`,
        data: {
          to: pharmacy.email,
          toName: patient.firstName + ' ' + patient.lastName1,
          firstName: patient.firstName,
          lastName: patient.lastName1,
          facebook: settings.facebook,
          instagram: settings.instagram,
          twitter: settings.twitter,
          phone: patient.phoneNumber,
          docName: doctor.firstName + " " + doctor.lastName1,
          NPI: prescription.NPI,
          docEmail: prescription.docEmail,
          phoneNumber: settings.phoneNumber,
          contactUs: settings.contactUs,
          myAccount: settings.myAccount,
          privacyPolicy: settings.privacyPolicy,
          location: pharmacy.location || '',
          help: settings.help,
          appName: settings.appName,
          name: patient.firstName + ' ' + patient.lastName1,
          year: settings.year,
          supportEmail: this.translationPipe.transform(settings.supportEmail, 'en'),
          chatSchedule: this.translationPipe.transform(settings.chatSchedule, 'en'),
          logo: settings.pngLogo,
          from: doctor.email,
          fromName: abbr + " " + doctor.firstName + " " + doctor.lastName1,
          externalPDFCode: prescription.externalPDFCode,
          link: prescription[prescName],
        }
      },  
      to: pharmacy.email,
      from: doctor.email,
      archive: [],
      read: false,
      headers: {'X-Entity-Ref-ID': uuidv4()}
    }
    this.afs.collection("mail").doc(mail.id).set(mail)
  }

  async pharmacyAlertEmail(pharmacist){
    let settings:any = await this.authService.getRemoteConfig();

    let mail = {
      date: new Date(),
      id: uuidv4(),
      generalMail: false,
      template: {
        name: `pharmacyAlert_en`,
        data: {
          subject: `<en>New Prescription</en><es>Nueva Prescripción</es>`,
          to: pharmacist.email,
          facebook: settings.facebook,
          instagram: settings.instagram,
          twitter: settings.twitter,
          phoneNumber: settings.phoneNumber,
          contactUs: settings.contactUs,
          myAccount: settings.myAccount,
          privacyPolicy: settings.privacyPolicy,
          help: settings.help,
          appName: settings.appName,
          year: settings.year,
          supportEmail: this.translationPipe.transform(settings.supportEmail, 'en'),
          chatSchedule: this.translationPipe.transform(settings.chatSchedule, 'en'),
          logo: settings.pngLogo,
          from: 'smtp-relay@dzeus.com',
          fromName: 'Dzeus Support',
        }
      },  
      to: pharmacist.email,
      archive: [],
      read: false,
      headers: {'X-Entity-Ref-ID': uuidv4()}
    }
    this.afs.collection("mail").doc(mail.id).set(mail)
  }

  async shortenUrl(url: string, shortUrl: string){
    let promise = new Promise((resolve, reject) => {
      let date = new Date(Date.now());
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();
      this.afs.firestore.collection('urlCodes').where('code', '>=', shortUrl + yyyy + mm).get().then(async (res: any) => {
        let lastUrlCode;
        if(res.docs.length > 0){
          lastUrlCode = Number(res.docs[res.docs.length - 1].data().code.split(yyyy + mm)[1])
        }
        else {
          lastUrlCode = 0
        }
        let code = (shortUrl + yyyy + mm + (lastUrlCode + 1).toString().padStart(4, '0'));
        this.afs.collection('urlCodes').doc(yyyy + mm + (lastUrlCode + 1).toString().padStart(4, '0')).set({code: code.toString(), url: url})
        resolve(code)
      })
    })

    return await promise
  }

  sendSms(user, template, customSms) {
    let smsTemplate = ''
    if(customSms && !template){
      smsTemplate = customSms[user.preferedLang]
    }
    else{
      smsTemplate = template;
      let smsTest = smsTemplate.split('{{')
      for(let i = 1; i < smsTest.length; i++){
        smsTemplate = smsTemplate.replace('{{' + smsTest[i].split('}}')[0] + '}}', this[smsTest[i].split('}}')[0]])
      }   
    }
    this.afs.collection('mail_sms').add({to: user.phoneNumber, body: smsTemplate});
  }

  manageSurveyFields(template, fields, settings){
    let promise = new Promise(async (resolve, reject) => {
      if(fields) {
        template = await template.replace('{{#each fields}}', '<div style="display: flex; flex-direction: row; width: max-content; margin: auto; align-items: flex-start;">')
        template = await template.replace('{{/each}}', '</div>')
        this.fields = []
        let test = '';
      
        let promise = new Promise((resolve, reject) => {
          for(let i = 0; i < fields.length; i++){
            this.fields[i] = {field: `${settings.domain}/${this.link}&a=${fields[i]}`}
            test += `<a href="${this.link}&a=${fields[i]}" style="line-height: initial; padding: 10px 20px; border-radius: 10px; -webkit-border-radius: 10px; -moz-border-radius: 10px; text-decoration: none;"><img style="font-size: large; margin: 0 0.5rem; width: 25px; height: 25px;" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fwelcome%2Foutline-star-512.png?alt=media&token=b1cb841e-53ba-4145-9ea0-ae3281e45282"></a>`
          
            if(i == fields.length - 1){
              resolve(test)
            }
          }
        })
        template = await template.replace(`<a href="{{field}}" target="_blank" style="line-height: initial; padding: 10px 20px; border-radius: 10px; -webkit-border-radius: 10px; -moz-border-radius: 10px; text-decoration: none;"><img style="font-size: large; margin: 0 0.5rem; width: 25px; height: 25px;" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fwelcome%2Foutline-star-512.png?alt=media&token=b1cb841e-53ba-4145-9ea0-ae3281e45282"></a>`, await promise) 
        resolve(template)
      }else {
        resolve(template)
      }

    })
    return promise
  }
}
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase/compat/app';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import Swal from 'sweetalert2';
import { v4 as uuid_v4 } from "uuid";

@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss']
})
export class PhoneVerificationComponent implements OnInit, OnDestroy {
  @Input() profile;
  @Input() phoneNumber;
  @Input() styling = ''
  @Input() background = 'white';

  @Output() linkPhoneNumber = new EventEmitter<Boolean>();
  
  verificationCode;
  swal: any;

  constructor(private firebaseService: FirebaseService,
              private afs: AngularFirestore,
              private translate: TranslateService) { }

  ngOnDestroy(): void {
    if(this.profile.phoneNumberMsg == true && this.profile.phoneVerified == false && this.codeInfo){
      this.cancelPhoneVerification()
    }
  }

  ngOnInit() {
    this.linkPhone();
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  randomGenerator;
  codeInfo;
  msg;
  async linkPhone(){
    this.randomGenerator = Math.floor(100000 + Math.random() * 900000); //6 digit code
    let date = new Date(Date.now());
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    let id:any = yyyy + mm + dd + uuid_v4();
    this.codeInfo = {
      code: this.randomGenerator,
      usedCode: false,
      userUid: this.profile.uid,
      userAccountType: this.profile.accountType,
      generatedDate: new Date(Date.now()),
      expired: false,
      phoneNumber: this.profile.phoneNumber,
      id: id
    }

    this.firebaseService.updateProfile({phoneVerified: false}, this.profile.uid);
    this.profile.phoneVerified = false
    await this.afs.collection('phoneVerification').doc(this.codeInfo.id).set(this.codeInfo).then(() => {
      let sms = this.profile.preferedLang == 'es' ? `Su codigo de verificacion para Dzeus Health es: ${this.randomGenerator}. Este codigo expira en los proximos 5 minutos.` : `Your Dzeus Health verification code is: ${this.randomGenerator}. This code expires in the next 5 minutes.`
      this.firebaseService.sendsms(this.profile, sms, true);
      this.afs.collection('phoneVerification').doc(this.codeInfo.id).valueChanges().subscribe((res: any) => {
        if(res && res.delivery?.errorCode){
          this.translate.get('PROFILE').subscribe((res: any) => {
            this.swal = res
          })
          Swal.fire({
            customClass: {
              confirmButton: 'swalPurple',
            },
            showConfirmButton: true,
            title: this.swal.ERROR,
            text: this.swal.ERRORMSG,
            icon: 'error',
            confirmButtonText: this.swal.DISMISS
          }).then(() => {
            this.linkPhoneNumber.emit(false);
          })
        }
      })
    })
  }

  // previousInput;
  // checkVerificationCode(tag){
  //   console.log('tag', tag)
  //   this.verificationCode = ''
  //   let code = <HTMLInputElement>document.getElementById(document.activeElement.id);
  //   console.log('code', code)
  //   if(tag.code == 'Backspace'){
  //     let code = <HTMLInputElement>document.getElementById(document.activeElement.id);
  //     if(this.previousInput.value == '' && this.previousInput.code.toString() == code.id.split('-')[1] && code.id != 'code-1'){
  //       let id = code.id.split('-')[1];
  //       let prevCode = <HTMLInputElement>document.getElementById('code-' + (Number(id) - 1).toString());
  //       prevCode.focus();
  //       this.previousInput = {code: Number(id) - 1, value: prevCode.value}
  //     }
  //     else {
  //       this.previousInput = {code: code.id.split('-')[1], value: code.value}
  //     }
  //   }
  //   else if(Number(tag.target.value) || Number(tag.target.value) == 0){
  //     for(let i = 1; i <= 6; i++){
  //       let code = <HTMLInputElement>document.getElementById('code-' + i);
  //       this.verificationCode = code.value.length == 1 ? this.verificationCode.concat(code.value) : code.id.split('-')[1] == '6' ? this.verificationCode.concat(code.value.charAt(0)) : this.verificationCode
  //       if(code.value.length > 1 && document.activeElement == code){
  //         let test = code.value
  //         let startValue = code.value.length == 6 ? 1 : i
  //         for(let j = startValue, k = 0; k < test.length; j++, k++){
  //           if(j <= 6){
  //             let splitcode = <HTMLInputElement>document.getElementById('code-' + j);
  //             splitcode.value = test.charAt(k)
  //             splitcode.focus() //.blur()
  //             i + 1;
  //             this.verificationCode = this.verificationCode.concat(test.charAt(k))
  //             this.previousInput = {code: j, value: test.charAt(k)}
  //           }
  //         }
  //       }
  //       else if(document.activeElement == code) {
  //         let nextCode = <HTMLInputElement>document.getElementById('code-' + (i + 1))
  //         if(nextCode){
  //           nextCode.focus()
  //           this.previousInput = {code: i + 1, value: nextCode.value}
  //           i = 7;
  //           break;
  //         }
  //       }
  //     }
  
  //     if(this.verificationCode.length == 6){
  //       if(this.verificationCode.toString() == this.randomGenerator.toString()){
  //         this.afs.collection('phoneVerification').doc(this.codeInfo.id).update({expired: true, usedCode: true, usedCodeDate: new Date()})
  //         this.firebaseService.updateProfile({phoneVerified: true, phoneNumberMsg: true}, firebase.auth().currentUser.uid)
  //         this.profile.phoneVerified = true;
  //         this.translate.get('PROFILE').subscribe((res: any) => {
  //           this.swal = res
  //         })
  //         Swal.fire({
  //           customClass: {
  //             confirmButton: 'swalPurple',
  //           },
  //           showConfirmButton: true,
  //           title: this.swal.PHONEVERIFIED,
  //           icon: 'success',
  //           confirmButtonText: this.swal.CONTINUE
  //         })
  //         this.linkPhoneNumber.emit(false);
  //       }
  //     }
  //   }
  //   this.msg = this.verificationCode.length == 6 && this.verificationCode.toString() == this.randomGenerator.toString() ? '' : 'SIGNUP.INVALIDCODE'
  // }

  previousInput;
  checkVerificationCode(event: Event): void{

    const inputElement = event.target as HTMLInputElement;
    const verificationCode = inputElement.value;

    this.verificationCode = verificationCode;
  
    if(this.verificationCode.length == 6){
      if(this.verificationCode.toString() == this.randomGenerator.toString()){
        this.afs.collection('phoneVerification').doc(this.codeInfo.id).update({expired: true, usedCode: true, usedCodeDate: new Date()})
        this.firebaseService.updateProfile({phoneVerified: true, phoneNumberMsg: true}, firebase.auth().currentUser.uid)
        this.profile.phoneVerified = true;
        this.translate.get('PROFILE').subscribe((res: any) => {
          this.swal = res
        })
        Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          showConfirmButton: true,
          title: this.swal.PHONEVERIFIED,
          icon: 'success',
          confirmButtonText: this.swal.CONTINUE
        })
        this.linkPhoneNumber.emit(false);
      }
    }

    this.msg = this.verificationCode.length == 6 && this.verificationCode.toString() == this.randomGenerator.toString() ? '' : 'SIGNUP.INVALIDCODE'
  }

  async resendVerificationCode(){
    let sms = this.profile.preferedLang == 'es' ? `Su codigo de verificacion para Dzeus Health es: ${this.randomGenerator}. Este codigo expira en los proximos 5 minutos.` : `Your Dzeus Health verification code is: ${this.randomGenerator}. This code expires in the next 5 minutes.`
    this.firebaseService.sendsms(this.profile, sms, true);
  }

  cancelPhoneVerification() {
    this.afs.collection<any>('users').doc(this.profile.uid).update({phoneNumberMsg:false, phoneVerified: false}).then(
      res => {
        this.profile.phoneVerified = false
        this.afs.collection('phoneVerification').doc(this.codeInfo.id).update({expired: true})
    })
    // this.cancelProfileChanges()
    this.linkPhoneNumber.emit(false);
  }
}


  <div id="gapiModal" class="d-flex flex-column">
    <div class="d-flex justify-content-between">
      <h4>{{'THIRDPARTY.TITLE' | translate}}</h4>
      <i id="close" class="fas fa-times" (click)="close()"></i>
    </div>
    <div class="gapi-content mt-2">
      <p>{{'THIRDPARTY.ALLOWMSG' | translate}}
        <a href="{{'THIRDPARTY.LINK' | translate}}" target="_blank">{{'THIRDPARTY.LINK' | translate}}</a>
      </p>

      <div class="images text-center">
        <img  src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fmiscellaneous%2Fallcookiespath.png?alt=media&token=c95911f8-076e-4640-b6ae-c3469aa6f16d"  alt="">
        <img src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fmiscellaneous%2Fchrome-allowallcookies.png?alt=media&token=426996a3-b1c7-40f4-94a0-c46f9477e7b9" alt="">
      </div>
      <p>{{'THIRDPARTY.CONTINUELIMITEDACCESS' | translate}}</p>

    </div>
    <div class="d-flex flex-column justify-content-center">
      <a href="{{'THIRDPARTY.LINK' | translate}}" target="_blank" class="btn btn-primary w-50 mx-auto mt-2 mb-1" >{{'THIRDPARTY.ENABLECOOKIES' | translate}}</a>
      <button class="btn btn-primary w-50 mx-auto" (click)="close()">{{'THIRDPARTY.IGNORE' | translate}}</button>
    </div>
  </div>
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { v4 as uuidv4 } from 'uuid';
import firebase from "firebase/compat/app";
import { AuditTracer, createCreditCardTracer, LoginAuditTrace, MakePaymentTracer } from './interfaces';
import { resolve } from 'dns';
import { AngularFireAuth } from '@angular/fire/compat/auth';
@Injectable({
  providedIn: 'root'
})

export class AuditTraceService {

  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth
  ) { }


  // Orders trace (admin makes changes authorized by patient/user)
  async createCreditCard(data: createCreditCardTracer, order: string){
    this.afs.collection("orders").doc(order).collection("auditTrail").doc(data.id).set(data);
  }

  async makeAPayment(data: MakePaymentTracer, order:string){
    this.afs.collection("orders").doc(order).collection("auditTrail").doc(data.id).set(data);
  }
  
  async bindCardToOrder(data:AuditTracer, order:any){
    this.afs.collection("orders").doc(order).collection("auditTrail").doc(data.id).set(data);
  }


  async changeProductInOrder(data:AuditTracer, order:any){
    this.afs.collection("orders").doc(order).collection("auditTrail").doc(data.id).set(data);
  }

  async selectedShippingInOrder(data:AuditTracer, order:any){
    this.afs.collection("orders").doc(order).collection("auditTrail").doc(data.id).set(data);
  }

  async changeShippingInOrder(data:AuditTracer, order:any){
    this.afs.collection("orders").doc(order).collection("auditTrail").doc(data.id).set(data);
  }



  async loginTrace(){
    const currentUser = firebase.auth().currentUser.uid; 
    let trace: LoginAuditTrace = {
      id: uuidv4(),
      uid: currentUser,
      date: new Date(),
      description: "User logged in",
    }

    if(currentUser){
      this.afs.collection("users").doc(trace.uid).collection("auditTrail").doc(trace.id).set(trace);
    }
  }
  async logoutTrace(){
    const currentUser = firebase.auth().currentUser.uid; 
    let trace: LoginAuditTrace = {
      id: uuidv4(),
      uid: currentUser,
      date: new Date(),
      description: "User logged out",
    }
    this.afs.collection("users").doc(trace.uid).collection("auditTrail").doc(trace.id).set(trace);
  }

  async actionTracer(description:string){
    return new Promise<boolean>(async (resolve, reject) => {
      const currentUser = firebase.auth().currentUser?.uid; 
      await this.afAuth.authState.subscribe(user => {
        if(user && !user.isAnonymous && currentUser){
          let trace: LoginAuditTrace = {
            id: uuidv4(),
            uid: currentUser,
            date: new Date(),
            description: description,
          }
          this.afs.collection("users").doc(trace.uid).collection("auditTrail").doc(trace.id).set(trace).then(() => resolve(true));
        }  
        else {
          resolve(false)
        }
      })
    });
    

  }


}

  <div class="reevaluation">
    <div class="header d-flex flex-column">
      <div class="d-flex justify-content-between">
        <h3>{{'HELPDESK.ASKFORHELP' | translate}}</h3>
      </div>
      <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, possimus ut.
         Autem mollitia ratione sit omnis animi magni quidem iure enim, veniam, repellat impedit eligendi?</p> -->
    </div>
    <div class="content">
      <!-- <label for=""></label>
      <input type="text" class="form-control"      placeholder="...................."> -->
      
      <div class="sources mt-3 d-flex flex-column mx-auto">
        <div class="flex-column">
          <h4>{{'HELPDESK.SOMETHINGWRONG' | translate}}</h4>
          <p>{{'HELPDESK.SELECTOPTIONS' | translate}}&nbsp;<span class="text-danger"><b>{{'HELPDESK.911' | translate}}</b></span></p>
        </div>
        
        <label class="mt-2">{{'NOTIFICATIONS.HELPDESK.TICKET' | translate}}</label>
        <!-- <input type="text" class="form-control" placeholder="Consult Re-evaluation" > -->
        <ng-select [items]="ticketType | translation:currentLang" [(ngModel)]="ticketChosen">{{ticketType | translation:currentLang}}</ng-select>
        <!-- <div id="options" class="options mx-auto">
          <span *ngFor="let situation of options; let i = index" class="badge badge-secondary m-1 p-2" id="{{situation}}" (click)="handleSituation($event)">{{situation}}</span>
        </div> -->
      </div>
  
      <div class="mt-3">
        <textarea class="description" type="text" placeholder="{{'NOTIFICATIONS.DESCRIPTION' | translate}}" [(ngModel)]="description"></textarea>
      </div>
    </div>
  
    <div class="d-flex mt-2 justify-content-end">
      <!-- <button class="btn btn-secondary mr-2"(click)="closeComponent()">{{'CONSULTATIONS.CANCEL' | translate}}</button> -->
      <button class="btn btn-primary mr-2" (click)="submit()">{{'CONSULTATIONS.SUBMIT' | translate}}</button>
    </div>

    <div *ngIf="toast" class="custom-toast d-flex">
      <i class="{{icon}} mr-2"></i>
      <span>{{message}}</span>
    </div>
  </div>
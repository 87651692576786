<div class="justify-content-end">
  <button type="button" class="close float-right modal-close" (click)="close()" aria-hidden="true">×</button>
</div>
<div class="row mt-3 w-100">
  <div class="col-lg-12">
    <div class="form mx-lg-auto">
      <h4 *ngIf="bool == false">{{'EVENTS.ADDEVENT' | translate}}</h4>
      <h4 *ngIf="bool">{{'EVENTS.EDITEVENT' | translate}}</h4>

      <form [formGroup]="eventform">                
        <div class="form-group row">
          <div class="col-md-6 mx-auto align-self-center" >
            <label for="startDate" class="inp input">
              <input id="startDate" class="form-control" type="datetime-local" placeholder="&nbsp;" formControlName="Start" (change)="calcEndTime(form.Start.value)" >
              <span class="label">{{'EVENTS.STARTDATE' | translate}}</span>
              <span class="focus-bg"></span>
            </label>                   
          </div>
        </div>
        <div class="form-group row" [ngClass]="{'disableDiv': true}" >
          <div class="col-md-6 mx-auto align-self-center" >
            <label for="endDate" class="inp input"> 
              <input id="endDate" class="form-control" type="datetime-local" placeholder="&nbsp;" formControlName="End">
              <span class="label">{{'EVENTS.ENDDATE' | translate}}</span>
              <span class="focus-bg"></span>
            </label>
          </div>
        </div>
        <!-- <button class="btn btn-info btn-submit ml-1" [ngClass]="{'active': minutes == 15}" (click)="minutes = 15; calcEndTime(startSelected);">15 min</button>
        <button class="btn btn-info btn-submit ml-1" [ngClass]="{'active': minutes == 20}" (click)="minutes = 20; calcEndTime(startSelected);">20 min</button>
        <button class="btn btn-info btn-submit ml-1" [ngClass]="{'active': minutes == 25}" (click)="minutes = 25; calcEndTime(startSelected);">25 min</button>
        <button class="btn btn-info btn-submit ml-1" [ngClass]="{'active': minutes == 30}" (click)="minutes = 30; calcEndTime(startSelected);">30 min</button> -->
        <h4>{{'FOLLOWUP.DURATION' | translate}}:</h4>
        <div class="d-flex flex-row" style="place-content: center;">
          <ng-select style="width: 20%;" [clearable]="false" (change)="minutes = $event.target.value; calcEndTime(form.Start.value)">
            <ng-option [selected]="minutes == min" *ngFor="let min of getMinutes()">{{min}}</ng-option>
          </ng-select>
          <p class="m-0 mt-2">&nbsp;{{'FOLLOWUP.MINUTES' | translate}}</p>
        </div>
        <p style="color: red;">{{warning | translate}}</p>
        <p style="color: red;">{{passedDate | translate}}</p>
        <div class="row justify-content-center" style="padding-bottom: 20px; padding-top: 20px;">
          <button *ngIf="bool == false" type="button" class="btn btn-info btn-cyan btn-submit" (click)="createEventTest()" [disabled]="ret == true">{{'EVENTS.CREATE' | translate}}</button> 
        </div>
        <div class="row justify-content-center" style="padding-bottom: 20px; justify-content: space-evenly !important;">
          <button *ngIf="bool == true" type="button" class="btn btn-info btn-cyan btn-submit" (click)="deleteEvent()">{{'EVENTS.DELETE' | translate}}</button>
          <button *ngIf="bool == true" type="button" class="btn btn-info btn-cyan btn-submit" (click)="updateEvent()" [disabled]="ret == true">{{'EVENTS.UPDATE' | translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>



<ng-template #gapiModal let-modal>
  <app-thirdpartycookies></app-thirdpartycookies>
</ng-template>

<app-reload *ngIf="loading" [message]="reloadMsg"></app-reload>
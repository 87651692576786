import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

import { ChatMessage } from 'src/app/pages/chat/chat.model';

import {AngularFireAuth} from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/pages/chat/chat.service';
import { Profile } from 'src/app/core/services/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { PdfService } from 'src/app/core/services/pdf.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-consultchat',
  templateUrl: './consultchat.component.html',
  styleUrls: ['./consultchat.component.scss']
})
/**
 * Chat component
 */
export class ConsultchatComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @Input() patient: any;
  @Input() consult:any;
  @Input() collaborator: boolean;
  @Output() chatLeftsidebar = new EventEmitter<Boolean>()

  chatMessagesData: ChatMessage[] = [];
  roomData;

  // Form submit
  formData: UntypedFormGroup = this.formBuilder.group({
    message: ['', [Validators.required]]
  });
  chatSubmit: boolean;

  username: string;
  status: string;

  currentUser: Profile

  toName
  currentRoom
  currentTo
  data: any;
  profileImg
  lastMessageInChat = []
  searchChat = '';
  roomId = '';
  allSubscriptions = new Subscription()

  currentLang = this.translate.store.currentLang
   
  chatSubscription: Subscription;
  pastChats = []

  constructor(
    public formBuilder: UntypedFormBuilder,
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public firebaseService: FirebaseService,
    public chatService: ChatService,
    public translate: TranslateService,
    private pdfService: PdfService,
    private authService: AuthenticationService
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.chatService.getConsultChat(this.consult[0].ID).then((data: any) => {
      this.authService.userSubscription.subscribe((res: any)=> {
        this.currentUser = res})
      let openedChat = true
    this.chatSubscription = this.chatService.allChats.subscribe((data: any) => {
      if(data && data.length > 0){
        this.data = data[data.length - 1]
        data.splice(data.length - 1, 1)
        let testData = data ? data : []
        this.pastChats = []
        testData.forEach(d => {
          // d.profileImgs = []
          // for(let j = 0; j < d.usersUid.length; j++){
          //   this.afs.firestore.collection('users').doc(`${d.usersUid[j]}`).get().then(async (data: any) => {
          //     d.profileImgs[j] = await data.data().image
          //   });
          // }
          this.afs.firestore.collection("chatMessages").where('roomId','==',d.roomId).get().then(msg => {
            let messages = msg.docs
            let align = 'left'
            for(let j = 0; j < messages?.length; j++){     
              align = messages[j].data().from == this.currentUser.email ? 'right' : 'left'
              let isMessageRead = undefined;

              for(let i = 0; i < d.roomUsers.length; i++){
                if(messages[j].data().read[i] != undefined && messages[j].data().read[i] == false){
                  isMessageRead = false
                }

                if(i == d.roomUsers.length - 1 && isMessageRead == undefined){
                  isMessageRead = true
                }
              }
  
              let index = d.roomUsers.indexOf(messages[j].data().from)
              this.pastChats.push({
                align: align,
                name: d.userNames[index],
                message: messages[j].data().message,
                time: messages[j].data().dateSent + ' ' + messages[j].data().timeSent,
                image: d.profileImgs[index],
                read: messages[j].data().read,
                messageId: messages[j].data().messageId,
                messageRead: isMessageRead,
              }); 
            } 
          });
        });
      }
      else {
        this.data = undefined
      }
      
      if(this.patient && this.data != undefined){
        this.chatUsername(this.data, openedChat)
        openedChat = false;
        this.chatSubscription?.unsubscribe()
        this.chatService.getMessages(this.data.roomId)
      }
    });
    this.allSubscriptions.add(this.chatSubscription)
    });
  
    this.checkPrescription()
  }

  chatUsername(roomInfo, openedChat) {
    this.roomId = roomInfo.roomId
    this.roomData = roomInfo
      let sub = this.afs.collection("chats").doc(this.roomId).valueChanges().subscribe((data1: any) => {  
        this.chatService.getMessages(this.roomId)
        this.chatService.currentMessages.subscribe(data => {
          let messages = data
          this.chatMessagesData = []
          let align = 'left';
          for(let i = 0; i < messages?.length; i++){
            align = messages[i].data().from == this.currentUser.email ? 'right' : 'left'
            let isMessageRead = undefined;

            for(let j = 0; j < data1.roomUsers.length; j++){
              if(messages[i].data().read[j] != undefined && messages[i].data().read[j] == false){
                isMessageRead = false
              }

              if(j == data1.roomUsers.length - 1 && isMessageRead == undefined){
                isMessageRead = true
              }
            }

            let index = data1.roomUsers.indexOf(messages[i].data().from)
            this.chatMessagesData.push({
              align: align,
              name: data1.userNames[index],
              message: messages[i].data().message,
              time: messages[i].data().dateSent + ' ' + messages[i].data().timeSent,
              image: data1.profileImgs[index],
              read: messages[i].data().read,
              messageId: messages[i].data().messageId,
              messageRead: isMessageRead,
            }); 

            
            let index1 = data1.roomUsers.indexOf(this.currentUser.email)
            if(messages[i].data().read[index1] != undefined && typeof(messages[i].data().read) != 'boolean' && messages[i].data().read[index1] != true && this.chatMessagesData[i].time != undefined && index1 != -1){
              this.chatMessagesData[i].read[index1] = true;
              if(this.myScrollContainer && this.myScrollContainer.nativeElement.scrollTop != this.myScrollContainer.nativeElement.scrollHeight){
                this.showScroll = true;
              }
              this.firebaseService.readChats(this.roomId, {id: messages[i].data().messageId, read: this.chatMessagesData[i].read});
            }

            if(i == messages.length - 1 && openedChat){
              this.scrollToBottom();
              openedChat = false;
            }
          } 
        });
    
      })
    this.allSubscriptions.add(sub)
    this.username = this.roomData.userNames.join(', ').replace(', ' + this.currentUser.firstName + ' ' + this.currentUser.lastName1, '').replace(this.currentUser.firstName + ' ' + this.currentUser.lastName1 + ', ', '').replace(this.currentUser.firstName + ' ' + this.currentUser.lastName1, '');
    this.chatMessagesData = [];
    this.currentRoom = this.roomId;
    this.currentTo = this.roomData.roomUsers;
    this.status = this.roomData.status;
  }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }

  /**
   * Save the message in chat
   */
  messageSave() {
    const message = this.formData.get('message').value;
    const currentDate = new Date();
    let Month
    let Day
    if((currentDate.getMonth() + 1) <= 9){
      Month = '0' + (currentDate.getMonth() + 1)
    }
    else{
      Month = (currentDate.getMonth() + 1)
    }
    if((currentDate.getDate()) <= 9){
      Day = '0' + currentDate.getDate()
    }
    else{
      Day = currentDate.getDate()
    }

    let dateSent = Month+ '/' + Day + '/' + currentDate.getFullYear()
    if (message) {

      if(!this.currentTo.includes(this.currentUser.email)){
        this.currentTo.push(this.currentUser.email)
        this.roomData.userNames.push(this.currentUser.firstName + ' ' + this.currentUser.lastName1)
        this.roomData.usersUid.push(this.currentUser.uid)
        this.roomData.profileImgs.push(this.currentUser.image)
        this.roomData.roomUsers = this.currentTo
      }

      const room = {
        numberOfMessages: this.chatMessagesData.length + 1,
        userNames: this.roomData.userNames,
        usersUid: this.roomData.usersUid,
        profileImgs: this.roomData.profileImgs,
        roomUsers: this.currentTo,
      }


      let read = []
      for(let i = 0; i < this.currentTo.length; i++){
        if(this.currentTo[i] == this.currentUser.email){
          read.push(true)
        }
        else {
          read.push(false)
        }
      }

      const messages = {
        messageId: this.firebaseService.getDate(),
        message: message,
        timeSent: this.formatAMPM(new Date),
        dateSent: dateSent,
        dateCreated: currentDate,
        to: this.currentTo,
        from: this.currentUser.email,
        read: read,
        roomId: this.currentRoom,
        consultId: this.consult[0].ID,
        toUid: this.roomData.usersUid,
        toUserNames: this.roomData.userNames
      }   

      // this.afs.collection('mail_sms').add({to: '7876037589', body: 'First test with Twilio'}).then(() => console.log("Queued message for delivery!"));
   
      this.afs.collection("chatMessages").doc(messages.messageId).set(messages).then(
        res => {
        }
      );
      
      this.afs.collection("chats").doc(this.currentRoom).update(room).then(
        res => {
          this.scrollToBottom();
        }
      );
      this.formData = this.formBuilder.group({
        message: ['']
      }); 
    }
    this.chatSubmit = true;  
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime; 
  } 

  createNewChat(){
    const message = this.formData.controls.message.value;
    const currentDate = new Date();
    let Month
    let Day
    if((currentDate.getMonth() + 1) <= 9){
      Month = '0' + (currentDate.getMonth() + 1)
    }
    else{
      Month = (currentDate.getMonth() + 1)
    }
    if((currentDate.getDate()) <= 9){
      Day = '0' + currentDate.getDate()
    }
    else{
      Day = currentDate.getDate()
    }

    let dateSent = Month+ '/' + Day + '/' + currentDate.getFullYear()

  if(message != undefined || message != '' || message != null){
      const room = {
        roomId: this.firebaseService.getDate(),
        dateCreated: currentDate,
        roomUsers: [this.currentUser.email, this.patient.email],
        userNames: [this.currentUser.firstName + ' ' + this.currentUser.lastName1, this.patient.firstName + ' ' + this.patient.lastName1],
        usersUid: [this.currentUser.uid, this.patient.uid],
        profileImgs: [this.currentUser.image, this.patient.image],
        numberOfMessages: 1,
        consultId: this.consult[0].ID,
        status: 'active'
      }

      let read = []
      for(let i = 0; i < room.roomUsers.length; i++){
        if(this.currentTo[i] == this.currentUser.email){
          read.push(true)
        }
        else {
          read.push(false)
        }
      }
      
      const messages = {
        messageId: this.firebaseService.getDate(),
        message: message,
        timeSent: this.formatAMPM(new Date),
        dateSent: dateSent,
        dateCreated: currentDate,
        to: [this.currentUser.email, this.patient.email],
        from: this.currentUser.email,
        read: read,
        roomId: room.roomId,
        consultId: this.consult[0].ID,
        toUid: room.usersUid,
        toUserNames: room.userNames
      }    
      if(this.chatMessagesData.length == 0){
        this.roomId = room.roomId;
        this.firebaseService.createChat(room, messages)
        this.chatUsername(room, true)
      }
      
      this.formData = this.formBuilder.group({
        message: ['']
      });
    }
  }

  ngOnDestroy() {
    this.allSubscriptions?.unsubscribe()
    this.chatSubscription?.unsubscribe()
    this.roomId = 'noRoom'
    this.data = undefined
    this.chatMessagesData = []
    this.chatService.getConsultChat(true)
    this.chatService.getMessages(true) 
  }

  invalidSubmit() {
    if(this.formData.controls.message.value == undefined  || this.formData.controls.message.value == '')
    {
      var element = <HTMLInputElement> document.getElementById("saveBtn");
      element.disabled = true;
    }
    else {
      var element = <HTMLInputElement> document.getElementById("saveBtn");
      element.disabled = false;
    }
  }

  canWrite = true
  checkPrescription(){    
    return new Promise<any>(async (resolve, reject) => {
      this.afs.firestore.collection("prescription").where("consultID", "==", this.consult[0].ID).where('internal', '==', true).get().then(prescriptions => {
        if(this.consult[0].Status != 'Completed'){
          this.canWrite = true;
          // reject(false)
        }
        else if(prescriptions.empty && this.consult[0].Status == 'Completed'){
          this.canWrite = false;
        }
        else{
          prescriptions.docs.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            if(doc.data().authenticatorValidationDate){
              let date = doc.data().authenticatorValidationDate.seconds * 1000 + 7*24*60*60*1000; //signed date + 7 days
              let today = Date.now();

              if(today >= date){
                this.canWrite = false
              }
              else {
                this.canWrite = true
              }
            }
            else {
              this.canWrite = false
            }

            resolve(doc.data());
            return;
          });
        }
      })
      .catch((error) => {
          // console.log("Error getting documents: ", error);
      });
    });
  }

  ngAfterViewChecked() {
    // this.scrollToBottom();        
  } 

  showScroll: boolean = false;
  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      this.showScroll = false;
      } catch(err) {}                 
  }
}
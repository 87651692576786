import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { AuthenticationService } from '../services/auth.service';
import { ErrorhandlerService } from '../services/errorhandler.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
        private errorHandler: ErrorhandlerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
                // client-side error
                errorMessage = `Error: ${error.error.message}`;
                this.errorHandler.showError(errorMessage);
            } else {
                // server-side error
                errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                this.errorHandler.showError(errorMessage);
            }
            // console.error(errorMessage);
            return throwError(errorMessage);
        })
    )
            
        //     catchError(err => {
        //     if (err.status === 401) {
        //         // auto logout if 401 response returned from api
        //         this.authenticationService.logout();
        //         location.reload();
        //     }
        //     if (err.status === 404) {
        //         // auto logout if 401 response returned from api
        //         const error = err.error.code + ': ' + err.error.description;
        //         this.errorHandler.showError(error);
        //     }

        //     const error = err.error.message || err.statusText;
        //     this.errorHandler.showError(error);
        //     return throwError(error);
        // }));
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from 'src/app/core/services/product.service';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class PricesComponent implements OnInit {
  @Input() consult: any;
  @Input() consultRules: any;

  productList = [];
  groupByTabletQty = [];

  constructor(
    public translate: TranslateService,
    private db: AngularFirestore,
    private productService: ProductService
  ) {}
  
  ngOnInit() {
    this.getProductsByCondition(this.consult);
  }

  async getAllProducts(){
    let productSnapshot = await this.productService.getProducts({Type: 'All'}, {});
    let allProducts: any = productSnapshot.docs.map(doc => doc.data());
  }

  async getProductsByCondition(consult){
    let productSnapshot = await this.productService.getProducts(consult, this.consultRules);
    this.productList = productSnapshot.filter(product => product.productQty > 0 && product.productIsVisible && product.productStates.some(p => p.state_code == this.consult.state.code )).sort((a, b) => {
      let nameA = a.productName + a.productDosage + a.productQty
      let nameB = b.productName + b.productDosage + b.productQty
        return nameA.localeCompare(nameB, undefined, {numeric: true, sensitivity: 'base'})
      })

    this.groupByTabletQty = this.groupBy(this.productList, 'productQty');
  }

  groupBy(array, key) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  }

  setPrice(price, product?){
    if(product?.productPromo && product?.productPromoPrice){
      return Number(!Array.isArray(product.productPromoPrice) ? (product.productPromoPrice[this.consult.state.code] ? product.productPromoPrice[this.consult.state.code] : product.productPromoPrice ) : product.productPromoPrice[0][this.consult.state.code])
    }
    if(price){
      return Number(!Array.isArray(price) ? (price[this.consult.state.code] ? price[this.consult.state.code] : price ) : price[0][this.consult.state.code])
    }
  }

  setPillPrice(price, product){
    let pricePerPill = this.setPrice(price, product) / product.productQty;
    return pricePerPill;
  }
}

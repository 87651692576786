import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Wallet } from '../interfaces';
@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(
   private db: AngularFirestore
  ) 
  {
    
  }


  setDefault($event,profile, card){
    if ($event.target.checked) {
      const subscription = this.db.collection("users").doc(profile.uid).collection("wallet").valueChanges().subscribe(async (cards: any) => {
        subscription.unsubscribe(); // Unsubscribe from the observable after the initial value is fetched
        
        cards = cards.filter(x => card.customerNumber != x.customerNumber);
        if (cards.length > 0) {
          cards.forEach(async (_card: any) => {
            this.db.collection("users").doc(profile.uid).collection("wallet").doc(_card.customerNumber).update({ default: false });
          });
        }
        this.db.collection("users").doc(profile.uid).collection("wallet").doc(card.customerNumber).update({ default: true });
      });
    } else {
      this.db.collection("users").doc(profile.uid).collection("wallet").doc(card.customerNumber).update({ default: false });
    }
  }


  getDefault(profile){
    return new Promise<Wallet>((resolve, reject) => { 
      this.db.collection("users").doc(profile.uid).collection("wallet").valueChanges().subscribe((cards: Wallet[]) => {
        let defaultCard = cards.filter(x => x.default == true);
        if(defaultCard.length == 0 && cards.length > 0){
          this.db.collection("users").doc(profile.uid).collection("wallet").doc(cards[0].customerNumber).update({ default: true });
          defaultCard = [cards[0]];
        }
        resolve(defaultCard[0]);  
      });
    });
  }
}

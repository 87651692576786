export function isAgeValid(inputDate: Date): boolean {
  // Get the current date
  const currentDate = new Date();

  // Calculate the user's age
  const userAge = currentDate.getFullYear() - inputDate.getFullYear();

  // Check if the birthday for this year has passed or not
  const inputDateThisYear = new Date(currentDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());

  if (inputDateThisYear > currentDate) {
    // If the birthday for this year has not passed yet, subtract 1 from the age
    return userAge - 1 >= 21;
  } else {
    return userAge >= 21;
  }
}
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { RouteService } from 'src/app/core/services/route/route.service';
import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';
import firebase from "firebase/compat/app";
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-choose-location',
  templateUrl: './choose-location.component.html',
  styleUrls: ['./choose-location.component.scss']
})
export class ChooseLocationComponent {
  locations = [];
  loading = false;
  swal;
  @Input() currentUser;
  @Input() currentConsult;
  @Input() collectionToUpdate = 'users';
  @Output() close = new EventEmitter<any>();
  @Input() background = 'white';
  currentLocation: any = {code: ''};

  consultRules = [];
  consultations = [];

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private routeService: RouteService,
    private translate: TranslateService
    ) {

      afs.collection('settings').doc('Country').collection('Country').doc('United States').collection('States').valueChanges().subscribe((res:any) => {
        this.locations = res.filter((state:any) => state.enabled).sort((a) => {
          if (a.name === 'Puerto Rico') {
            return -1;
          }
          else {
            return 1;
          }
        });
      })

      this.afs.firestore.collection('consultRules').get().then((res) => {
        this.consultRules = res.docs.map((doc) => doc.data());
      })
      
      this.afs.collection('consultation', ref => ref.where('UID_Patient', '==', firebase.auth().currentUser.uid).where('Status', 'in', ['Open', 'Draft'])).valueChanges().subscribe((res1) => {
        this.consultations = res1
      })
  }

  selectLocation(location) {
    if(this.collectionToUpdate == 'users' && !this.router.url.includes('/account/preconsult') && this.router.url != '/account/welcome' && this.router.url != '/account/welcome/register'){
      this.loading = true;
    }
    
    for(let i = 0; i < this.consultations.length; i++){
      let doc = this.consultations[i];

      let consultRule = this.consultRules.filter((rule) => rule.id == doc.Type)[0];

      if(consultRule?.states.some(s => s.state_code == location.code)){
        this.afs.collection('consultation').doc(doc.ID).update({
          state: {code: location.code, name: location.name},
          country: location.country
        })
      }

      if(i == this.consultations.length - 1){
        this.updateUser(location);
      }
    }

    if(this.consultations.length == 0){
      this.updateUser(location);
    }
  }

  updateUser(location){
    this.currentUser.currentLocation = location;
    this.currentLocation = location
    this.afs.collection(this.collectionToUpdate).doc(firebase.auth().currentUser.uid).update({
      currentLocation: location
    }).then(async () => {
      if(!this.router.url.includes('/account/preconsult') && this.router.url != '/account/welcome' && this.router.url != '/account/welcome/register'){
        let currentURL = this.router.url == '/account/reload' ? this.routeService.previousNavigationPath() : this.router.url;

        ReloadComponent.loader = 'RELOAD.CHANGING_LOCATION'
        await this.router.navigateByUrl('account/reload', { skipLocationChange: true });     
        setTimeout(async () => {
          return await this.router.navigateByUrl(currentURL).then(() => {
            this.loading = false;
            this.close.emit({
              state: {code: location.code, name: location.name},
              country: location.country
            });
          });
        },500)
      }
      else {
        this.loading = false;
        this.close.emit({
              state: {code: location.code, name: location.name},
              country: location.country
            });
      }
    })
  }

  selectLocationWarn(location){
    if(this.collectionToUpdate == 'users' && this.currentUser.currentLocation && this.currentUser.currentLocation.code != location.code && this.currentUser.currentLocation.code != undefined){
      this.translate.get('LOCATION').subscribe((res: any) => {
        this.swal = res;
      })
  
      Swal.fire({
        customClass: {
          confirmButton: 'btn-cyan',
          cancelButton: 'btn-light'
        },
        title: this.swal.TITLE,
        text: this.swal.CHANGELOCATION,
        icon: 'warning',
        iconColor: '#ff0000 !important',
        confirmButtonText: this.swal.CHANGE,
        cancelButtonText: this.swal.CANCEL,
        allowEscapeKey : false,
        showCancelButton: true,
        allowOutsideClick: false,
      }).then(result => {
        if(result.isConfirmed){
          this.selectLocation(location);
        }
  
        if(result.isDismissed){
          this.afs.firestore.collection(this.collectionToUpdate).doc(this.currentUser.uid).get().then((res) => {
            this.currentUser = res.data();
            this.currentLocation = res.data().currentLocation;
          })
          this.close.emit({
              state: {code: location.code, name: location.name},
              country: location.country
            });
        }
      })
    }
    else if(this.currentUser.currentLocation.code != location.code) {
      this.selectLocation(location);
    }
    else {
      this.afs.firestore.collection(this.collectionToUpdate).doc(this.currentUser.uid).get().then((res) => {
        this.currentUser = res.data();
        this.currentLocation = res.data().currentLocation;
      })
      this.close.emit();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentUser && changes.currentUser.currentValue) {
      this.currentUser = changes.currentUser.currentValue;
      this.currentLocation = this.currentUser.currentLocation;
      if(!this.currentUser.currentLocation){
        this.currentUser.currentLocation = {}
        this.currentLocation = {}
      }
    }

    if (changes.currentConsult && changes.currentConsult.currentValue) {
      this.currentConsult = changes.currentConsult.currentValue;
    }

    if (changes.collectionToUpdate && changes.collectionToUpdate.currentValue) {
      this.collectionToUpdate = changes.collectionToUpdate.currentValue;
    }
  }
}

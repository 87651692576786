import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { isAgeValid } from 'src/app/core/constants/age-validator';
import { environment } from 'src/environments/environment';

import firebase from 'firebase/compat/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

const app = firebase.initializeApp(environment.firebaseConfig);
const functions = getFunctions(app);

interface Profile {
  email: string,
  accountType:string;
  firstName: string,
  lastName1: string,
  birthdate: string,
  phoneNumber: string,
}

@Component({
  selector: 'app-remote-patient-onboard',
  templateUrl: './remote-patient-onboard.component.html',
  styleUrls: ['./remote-patient-onboard.component.scss']
})


export class RemotePatientOnboardComponent implements OnInit{

  
  sections:number = 4;
  currentSectionIndex:number = 0;

  completedSteps: boolean[] = [false, false, false, false]; 
  stepLabels = ['Create Patient Account', 'Patient Profile', 'Medical History', 'Medical Prescription'];

  profile:Profile = {
    email: '',
    accountType:'',
    firstName: '',
    lastName1: '',
    birthdate: '',
    phoneNumber: '',
  };

  completed:boolean = false;

  constructor(
    private db: AngularFirestore,
    private authService: AngularFireAuth,
    private http: HttpClient
  ) { 
   
  }
  async ngOnInit(){
   this.callable()
  }


  // async callGateway(){
  //   try{

  //     let response = await fetch('https://security-test-6wjr35lm.ue.gateway.dev/security-test', {mode: 'no-cors'});
  //     let data = await response.json();
  //     console.log(data);
  //   }
  //   catch(error){
  //     console.error({error})
  //   }
  // }
  
  // callGateway();


  ageValidator(date: Date) {
    let valid = isAgeValid(new Date(date));
    const input = document.getElementById('birthdate') as HTMLInputElement;
    const errorMessage = document.getElementById('errorMessage') as HTMLParagraphElement;
    const nextButton = document.getElementById('nextButton') as HTMLButtonElement;

    if(!valid){
      errorMessage.style.display = 'block';
      input.style.border = '1px solid red';
      nextButton.disabled = true;
    }else{
      errorMessage.style.display = 'none';
      input.style.border = '1px solid #ced4da';
      nextButton.disabled = false;
    }
  }

  next(){

    // if(this.currentSectionIndex == this.sections){
    //   this.currentSectionIndex++;
    // }
    
    if(this.currentSectionIndex < this.sections) {
      this.completedSteps[this.currentSectionIndex] = true; // Mark the current step as completed
      this.currentSectionIndex++;
      const nextButton = document.getElementById('nextButton') as HTMLButtonElement;
      nextButton.disabled = true; 
      
    }
    
    // console.log(this.currentSectionIndex);
    // console.log(this.sections);
    if(this.currentSectionIndex == this.sections){
      this.completed = true;
    }
  }


  back(){
    if(this.currentSectionIndex == 0)
      return 
    if(!(this.currentSectionIndex <= 0)) {
      this.currentSectionIndex--;
    }
  }

  saveProfile(){
    // this.db.collection("users").doc(this.user.uid).update(this.profile)
  }

  createAccount(){
    const generatePassword = (
      passwordLength = 12,
      passwordChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    ) => [...window.crypto.getRandomValues(new Uint32Array(passwordLength))]
        .map(x => passwordChars[x % passwordChars.length])
        .join('');
    
    // if(environment.defaultauth === 'firebase'){
    //   this.loading = true;
    //   this.

    // }
  }



  callable(){
   
    const addMessage = httpsCallable(functions, 'AcceptaQueryServiceTransactionsByCustomer');
    addMessage({ creditCardPaymentAttempt: {
      amount: 1,
      currency: 'usd',
      description: 'test',
      date: this.formatDateToMMDDYYYY(new Date(2023,8,18)),
      // customerNumber: "0230830173740358"
      customerNumber: "0221121163757674"
    }})
      .then((result:any) => {
        // Read result of the Cloud Function.
        // let sanitizedMessage = result.data;
        // console.log(sanitizedMessage);
      });
  }

  formatDateToMMDDYYYY(timestamp:any) {
    const date = timestamp // Convert Firebase timestamp to JavaScript Date
  
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${month}/${day}/${year}`;
  }



  


  
  
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExtrapagesRoutingModule } from './extrapages-routing.module';

import { Page404Component } from './page404/page404.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReloadComponent } from './reload/reload.component';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [Page404Component, ReloadComponent],
  imports: [
    CommonModule,
    ExtrapagesRoutingModule,
    TranslateModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.cubeGrid,
      backdropBorderRadius: '3px',
      fullScreenBackdrop: true
    }),
  ],
  exports: [ ReloadComponent]
})
export class ExtrapagesModule { }

import { Component, EventEmitter, Input, OnInit, Output, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { LaboratoriesService } from 'src/app/core/services/laboratories/laboratories.service';
import { PrescriptionService } from '../consult-prescriptions/prescription.service';
import { Diagnostic, Profile } from 'src/app/core/services/interfaces';
import { DiagnosticsService } from 'src/app/core/services/diagnostics/diagnostics.service';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-diagnostics',
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.scss']
})
export class DiagnosticsComponent implements OnInit {
  @Input() consult: any;
  @Input() labDiagnosticDoc: any;

  @Output() labDiagnosticClose = new EventEmitter<boolean>();
  @ViewChild('ngSelect') ngSelect: NgSelectComponent;
  
  consultID:string;
  user: Profile;
  diagnostic: Diagnostic = {};
  diagnostics: any[] = [];
  diagnosticTemplates: any[] = [];
  possibleDiagnostics:any[] = [];
  possibleDiagnosticsHistory: any[] = [];

  form: boolean = false;
  edit: boolean = false;
  loadingSearch: boolean = false;
  filteredDiagnostics: any[] = [];

  currentPractitionerUID: string;

  viewMode: boolean = false;

  modalDiagnosticsDetails: any;
  @ViewChild("diagnosticsDetailsModal") modalDiagnosticsDetailsContent: TemplateRef<any>;

  viewDiagnosticDetailsData: any;

  constructor(
    private http: HttpClient,
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private diagnosticsService: DiagnosticsService,
    private modalService: NgbModal,
  ) {
    this.route.params.subscribe(params => {
      this.consultID = params['id'];  
    });
   }

  async ngOnInit() {
    this.user = await this.authService.profile();
    
    
    this.getConsultDiagnostics();
    this.getDiagnosticsTemplates();

    if(this.labDiagnosticDoc){
      this.documentLabDiagnostic();
    }

    this.getCurrentConsultData();

  }

  setForm(){
    this.form = true;
    this.diagnostic = {};
  }

  getConsultDiagnostics(){
    let id = this.consult?.ID ? this.consult?.ID : this.consultID;
    this.diagnosticsService.getConsultDiagnostics(id).subscribe((diagnostics:any) => {
      this.diagnostics = diagnostics;
    });
  }

  getDiagnostics($event){
    this.loadingSearch = true;
    this.http.get<any>("https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms="+$event.term).subscribe(response =>{
      for(let i = 0; i < 15; i++){
        this.filteredDiagnostics = response[3];
        this.loadingSearch = false;
      }
    });
  }

  //possible diagnostics
  getDiagnosticsTemplates(){
    if(this.diagnosticTemplates.length == 0){
      let id = this.consult?.ID ? this.consult?.ID : this.consultID;
      this.db.collection("consultation").doc(id).collection("diagnosticTemplates").valueChanges().subscribe((templates:any) => {
        this.possibleDiagnosticsHistory = [...templates];
        this.possibleDiagnostics = templates;
        this.possibleDiagnostics = this.possibleDiagnostics.filter(
          possibleDiagnostic => !this.diagnostics.some(diagnostic => diagnostic.id === possibleDiagnostic.id)
        );
      });
    }
  }

  setDiagnostic(diagnostic){
    this.diagnostic.code = diagnostic[0];
    this.diagnostic.name = diagnostic[1];
    this.diagnostic.code_name = `${diagnostic[0]} - ${diagnostic[1]}`
  }

  setEditMode(diagnostic){
    this.form = true;
    this.edit = true;
    this.diagnostic = diagnostic;
  }

  diagnosticSave(status?: string){

    if(status === 'add'){

      this.diagnostic.updatedBy = this.diagnostic.id ? this.user.firstName + ' ' + this.user.lastName1 : '';
      this.diagnostic.updatedByUID = this.diagnostic.id ? this.user.uid : '';
      this.diagnostic.updatedDate = this.diagnostic.id ? new Date(Date.now()) : null;
      this.diagnostic.id = this.diagnostic.id ? this.diagnostic.id : this.diagnostic.code_name;
      this.diagnostic.date = this.diagnostic.date ? this.diagnostic.date : new Date(Date.now());
      let id = this.consult?.ID ? this.consult?.ID : this.consultID;
      this.diagnostic.createdBy = this.diagnostic.createdBy ? this.diagnostic.createdBy : this.user.firstName + ' ' + this.user.lastName1;
      this.diagnostic.createdByUID = this.diagnostic.createdByUID ? this.diagnostic.createdByUID : this.user.uid;
      this.db.collection("consultation").doc(id).collection("Diagnostics").doc(this.diagnostic.id).set(this.diagnostic).then(() => {
        this.close('add');
      });

    }
    else if(status === 'edit'){

      this.diagnostic.updatedBy = this.diagnostic.id ? this.user.firstName + ' ' + this.user.lastName1 : '';
      this.diagnostic.updatedByUID = this.diagnostic.id ? this.user.uid : '';
      this.diagnostic.updatedDate = this.diagnostic.id ? new Date(Date.now()) : null;
      this.diagnostic.id = this.diagnostic.id ? this.diagnostic.id : this.diagnostic.code_name;
      this.diagnostic.date = this.diagnostic.date ? this.diagnostic.date : new Date(Date.now());
      let id = this.consult?.ID ? this.consult?.ID : this.consultID;
      this.diagnostic.createdBy = this.diagnostic.createdBy ? this.diagnostic.createdBy : this.user.firstName + ' ' + this.user.lastName1;
      this.diagnostic.createdByUID = this.diagnostic.createdByUID ? this.diagnostic.createdByUID : this.user.uid;
      this.db.collection("consultation").doc(id).collection("Diagnostics").doc(this.diagnostic.id).set(this.diagnostic).then(() => {
        this.close('edit');
        this.diagnostic = {};
      });

    }
  }

  //adds possible diagnostic to the list of selected diagnostics 
  addDiagnostic(diagnostic){
    this.diagnostic = diagnostic;
    this.diagnosticSave('add');
    setTimeout(() => {
      this.setEditMode(this.diagnostic);
    }, 1500);
    this.possibleDiagnostics = this.possibleDiagnostics.filter(x => x.id != diagnostic.id);
  }

  deleteDiagnostic(diagnostic){
    let id = this.consult?.ID ? this.consult?.ID : this.consultID;
    this.db.collection("consultation").doc(id).collection("Diagnostics").doc(diagnostic.id).delete();
    if(this.possibleDiagnosticsHistory.some(x => x.id == diagnostic.id)){
      this.possibleDiagnostics.push(diagnostic);
    }
  }

  close(status?: string){

    this.form = false;
    this.edit = false;

    if(status === 'add'){

      // this.diagnostic = {};
      if(this.labDiagnosticDoc){
        this.labDiagnosticClose.emit(true);
      }

    }
    else if(status === 'edit'){

      this.diagnostic = {};
      if(this.labDiagnosticDoc){
        this.labDiagnosticClose.emit(true);
      }
      this.getConsultDiagnostics()
    }

  }

  documentLabDiagnostic(){
    this.setEditMode(this.labDiagnosticDoc);
  }

  getCurrentConsultData(){

    let id = this.consult?.ID ? this.consult?.ID : this.consultID;
    this.db.collection("consultation").doc(id).valueChanges().subscribe((consult:any) => {
      this.currentPractitionerUID = consult['UID_Practitioner'];
    });

  }

  openDiagnosticsDetailsModal(diagnosticsDetailsModal: any) {

    this.modalService.open(diagnosticsDetailsModal, { size: 'xl', backdrop: 'static', centered: true });

  }

  closeDiagnosticsDetailsModal(){
      
    this.modalService.dismissAll();

  }

  getSelectedDiagnosticDetails(diag: any){

    this.viewDiagnosticDetailsData = diag;

  }

}
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private db: AngularFirestore
  ) { }


  async getProduct(id:string){
    return new Promise<any>(async (resolve, reject) => {
      this.db.collection("products").doc(id.toString()).valueChanges().subscribe(async (product:any) => {
        resolve(product);
      });
    });
  }

  async calculateSubscriptionDiscount(product:any): Promise<string>{
    let price = Number(product.price) || Number(product.productPrice) 
    let discountedPrice = price - (price * Number(product.subDiscountPercent));
    return discountedPrice.toFixed(2);
  }

  async getLessPillProducts(product) {
    let products = [];
  
    // Get products matching the given rxcui and id
    const snapshot = await this.db.firestore.collection("productsPacks")
      .where("rxcui", "==", product.rxcui).get();
    // const filter = snapshot.docs.filter(p => p.data().productID == product.id.toString() && p.data().qty <= product.qty);
    // const filter = snapshot.docs.filter(p => p.data().qty <= product.qty);
    const filter = snapshot.docs.filter(p => p.data());
    // Retrieve product data and populate the products array
    await Promise.all(filter.map(async (product) => {
      const pack = await this.db.firestore.collection("products")
        .doc(product.data().productIDParent.toString()).get();
      if (pack.exists) {
        products.push(pack.data());
      }
    }));
  
    // Sort the products by price
    products.sort((a, b) => Number(b.price) - Number(a.price));
  
    return products;
  }




  getProductsByRXCUI(rxcui:string){
    return this.db.collection("products", ref => ref.where("rxcui", "==", rxcui)).valueChanges();
  }

  getProductsByCondition(condition: string, rxcui:string, qty: number){
    return this.db.collection("products", ref => ref.where("condition", "array-contains", condition).where('hidePractitioner', '==', false).where('qty', '==', qty)).valueChanges();
  }

  getProductsPackByCondition(id: string){
    return this.db.collection("productsPacks", ref => ref.where("productIDParent", "==", id)).valueChanges();
  }
  
  // async getProductDeliveryWishlistFrequency(products:any[]){
  //   return new Promise<any>(async (resolve, reject) => {
  //     let hashList: { [key: string]: any[] } = {};
  //     const promises: Promise<void>[] = [];

  //     products.forEach((product:any) => {
  //       promises.push(new Promise<void>((innerResolve, innerReject) => {
  //         this.db.collection("products").doc(product.id.toString()).collection("deliveryFrequencies").valueChanges().subscribe(async (frequencies:any) => {
  //           if(frequencies.length > 0){
  //             frequencies.sort((a: any, b: any) => {
  //               if (a.autoPayment === false || b.autoPayment === false) {
  //                 return a.autoPayment === false ? 1 : -1; // If is auto payment false, move it to the end
  //               } else {
  //                 return a.frequency - b.frequency; // Sort based on frequency
  //               }
  //             });
  //             hashList[product.id] = [...frequencies];
  //           }
  //           innerResolve(); // Resolve the inner promise when the operation is complete
  //         }, (error) => {
  //           innerReject(error); // Reject the inner promise if there's an error
  //         });
  //       }));
  //     });

  //     // Wait for all promises to resolve
  //     Promise.all(promises)
  //       .then(() => {
  //         resolve(hashList); // Resolve the outer promise with the hashList
  //       })
  //       .catch((error) => {
  //         reject(error); // Reject the outer promise if any of the inner promises reject
  //       });
  //   });
  // }

  async getProductDeliveryFrequencyOrderLines(products:any[]){
    return new Promise<any>(async (resolve, reject) => {
      let hashList: { [key: string]: any[] } = {};
      const promises: Promise<void>[] = [];

      products.forEach((product:any) => {
        promises.push(new Promise<void>(async (innerResolve, innerReject) => { 
          // this.db.collection("products").doc(product.productID.toString()).collection("deliveryFrequencies").valueChanges().subscribe(async (frequencies:any) => {
          // this.db.collection('consultRules').doc('Erectile Dysfunction').collection('products').doc(product.productID.toString()).valueChanges().subscribe(async (freq:any) => {
          const freq = await this.db.firestore.collection('All Products').where('productId', '==', Number(product.productID)).get();
          let frequencies = freq.docs.length > 0 ? freq.docs[0].data().productDeliveryFrequencies : [];
            if(frequencies.length > 0){
              frequencies.sort((a: any, b: any) => {
                if (a.autoPayment === false || b.autoPayment === false) {
                  return a.autoPayment === false ? 1 : -1; // If is auto payment false, move it to the end
                } else {
                  return a.frequency - b.frequency; // Sort based on frequency
                }
              });
              hashList[product.productID] = [...frequencies];
            }
            innerResolve(); // Resolve the inner promise when the operation is complete
          // }, (error) => {
          //   innerReject(error); // Reject the inner promise if there's an error
          // });
        }));
      });

      // Wait for all promises to resolve
      Promise.all(promises)
        .then(() => {
          resolve(hashList); // Resolve the outer promise with the hashList
        })
        .catch((error) => {
          reject(error); // Reject the outer promise if any of the inner promises reject
        });
    });
  }
}

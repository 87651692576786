<app-pagetitle [title]="pagetitle"></app-pagetitle>

<div class="card">
    <div class="card-body">
        <span class="row">
            <div class="col-sm-12 col-md-12">
            <div id="tickets-table_filter" class="dataTables_filter text-md-right" >
                <label for="search"
                class="d-inline-flex align-items-center justify-content-end">{{'ACCOUNTS.SEARCH' | translate}}
                <input id="search" type="text" name="searchTerm" class="form-control form-control-sm ml-2"aria-controls="tickets-table" [(ngModel)]="term" (keyup)="filterOrders(term)"/>
                </label>
            </div>
            </div>
        </span>
        <div class="row" style="align-items: center;">
            <div class="col-auto">
                <label for="startDate">Start Date
                    <input type="date" class="form-control" id="startDate" name="startDate" [(ngModel)]="startDate"> <!--(change)="filterOrdersByDate(startDate, endDate)"-->
                </label>
            </div>
            <div class="col-auto">
                <label for="endDate">End Date
                    <input type="date" class="form-control" id="endDate" name="endDate" [(ngModel)]="endDate"> <!--(change)="filterOrdersByDate(startDate, endDate)"-->
                </label>
            </div>
            <div class="col-auto">
                <div></div>
                <button class="btn btn-primary" (click)="filterOrdersByDate(startDate, endDate)">Filter</button>
            </div>
            <div class="col-auto">
                <div></div>
                <button class="btn btn-light" (click)="resetOrdersFilter()">Reset</button>
            </div>
        </div>
        <div *ngIf="!loadingOrders" class="table-responsive">
            <ng-container *ngIf="filteredOrders.length > 0">
                <table class="table table-centered m-0">
                    <thead class="thead-light">
                        <tr> 
                            <th>Date</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <!-- <th>Order State</th> -->
                            <th>Consultation Type</th>
                            <th>Product Type</th>
                            <th>Payment Status</th>
                            <th>Current Step</th>
                            <th>Has Address</th>
                            <th>Has Payment</th>
                            <!-- <th>UID</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredOrders | paginate: { itemsPerPage: 10, currentPage: pageOrders, id: 'first'}; let i = index;">
                            <td>{{item.date.toDate() | date:'MM/dd/yyyy'}}</td>
                            <td>{{item.patientName}}</td>
                            <td>{{item.email}}</td>
                            <td>{{item.phone | mask:'(000) 000-0000'}}</td>
                            <!-- <td>{{item.orderState}}</td> -->
                            <td>{{item.consultationType}}</td> 
                            <td style="text-transform: capitalize;">{{item.productType}}</td>
                            <td style="text-transform: capitalize;">{{item.paymentStatus}}</td>  
                            <td style="text-transform: capitalize;">{{item.consultationStep}}</td> 
                            <td *ngIf="item.address" style="text-transform: capitalize;">Yes</td> 
                            <td *ngIf="!item.address" style="text-transform: capitalize;">No</td> 
                            <td *ngIf="item.acceptaId" style="text-transform: capitalize;">Yes</td> 
                            <td *ngIf="!item.acceptaId" style="text-transform: capitalize;">No</td> 
                            <!-- <td>{{item.uid}}</td>   -->
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            <!-- <ng-container *ngIf="filteredOrders.length === 0">
                <table class="table table-centered m-0">
                    <thead class="thead-light">
                        <tr> 
                            <th>Date</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Order State</th>
                            <th>Payment Status</th>
                            <th>Product Type</th>
                            <th>Refill</th>
                            <th>Subscription Status</th>
                            <th>UID</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="9">
                                <div class="row" style="justify-content: center; align-items: center;">
                                    <div class="col-lg-12">
                                        <div class="text-center">No orders available at this time.</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container> -->
        </div>
        <div *ngIf="loadingOrders" class="row" style="justify-content: center; align-items: center;">
            <div class="col-lg-12">
                <div class="text-center">
                    <span class="spinner-border spinner-border-sm" role="status"></span>
                </div> 
            </div>
        </div>
    </div>
</div> 
<ng-container *ngIf="filteredOrders.length > 0">
    <div class="row justify-content-end">
        <div class="col text-right">
        <pagination-controls (pageChange)="pageOrders = $event" id="first" style="text-align: right;"></pagination-controls>
        </div>
    </div>
</ng-container>
<!-- end row -->
  

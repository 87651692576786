<h2>{{'ORDERS.REFILL' | translate}}</h2>

<div class="container">
  <div class="grid-wrapper grid-col-auto auto-grid">
    <label for="{{refill.productID}}" class="radio-card" *ngFor="let refill of cyclicOrders" (click)="trackRefills(refill)">
      <input type="radio" name="radio-card" id="{{refill.productID}}" checked />
      <div class="card-content-wrapper">
        <span class="check-icon"></span>
        <div class="card-content">
          <!-- <h3 class="text-center">{{refill.id}}</h3> -->
          <img src="{{refill.image}}" alt=""/>
          <h4>{{refill.productPrice | currency:'USD'}}</h4>
          <!-- <h5 class="text-info" [ngClass]="{'text-danger': refill.state === 'Closed',
          'text-warning':refill.state === 'Hold',
          'text-info':refill.state === 'In Progress'}"> {{refill.state | titlecase}}</h5> -->
          <h5 class="text-info" *ngIf="refill.state === 'In Progress'" [ngClass]="{'text-info': refill.state === 'In Progress'}">{{'REFILLS.INPROGRESS' | translate | titlecase}}</h5>
          <h5 class="text-info" *ngIf="refill.state === 'Closed'" [ngClass]="{'text-danger': refill.state === 'Closed'}">{{'REFILLS.CLOSED' | translate | titlecase}}</h5>
          <h5 class="text-info" *ngIf="refill.state === 'Hold'" [ngClass]="{'text-warning': refill.state === 'Hold'}">{{'REFILLS.HOLD' | translate | titlecase}}</h5>
          <h5 class="text-info" *ngIf="refill.state === 'Draft'" [ngClass]="{'text-primary': refill.state === 'Draft'}">{{'REFILLS.DRAFT' | translate | titlecase}}</h5>  
          <p>{{refill.productName}}</p>
        </div>
      </div>
    </label>
  </div>
</div>





<ng-template #sidebar let-modal>
  <div class="w-100 p-3 d-flex justify-content-between">
    <i class="fas fa-arrow-left vertical-align cursor" (click)="back();"></i>
    <h3>{{'ORDERS.ORDERS' | translate}} {{refillClicked.order.orderNo}}</h3>
    <button type="button" class="close" aria-label="Close" (click)="dismiss(); modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- <div *ngIf="trackRefill" class="w-100 text-center mb-2">
    <span>{{refillClicked.productName}}</span><br>
  </div> -->
  <div *ngIf="refillClicked" class="">
    <div class="heading d-flex flex-column text-center">
      <span>Product: <span>{{refillClicked.productName | translation:lang}}</span></span>
      <span>Prescription:  
        <span class="ml-2">{{refillClicked.order.prescriptionID}}</span>
      </span>
    </div>

    <div class="details">
      <div class="section">
        <div class="collapsible d-flex justify-content-between" (click)="handleSection()">
          <span class="" (click)="handleSection()">Refills</span>
          <i class="fas fa-chevron-down vertical-align"></i>
        </div>
        <div class="content">
          <div *ngFor="let product of productRefillTrackList"  class="d-flex mb-1 product-div">
            <img src="{{product.image | translation:lang}}" alt="">
            <div class="d-flex w-100 justify-content-between">
              <div class="w-75 d-flex flex-column">
                <span class="ml-3 product-name">{{product?.productName | translation:lang}}</span>
                <!-- <span class="ml-3">8 tablets per month</span> -->
                <!-- <div class="d-flex justify-content-between"> -->
                <div class="d-flex flex-column">
                  <div class="d-flex">
                    <span class="ml-3">{{'ORDERS.AMOUNTTAKEN' | translate}}: {{product.productQty}}</span>
                    <span class="ml-3">{{'ORDERS.BALANCE' | translate}}: {{product.balance}}</span>
                  </div>
                  <div class="d-flex refill-payment-status">
                    <span class="ml-3 ">Status: {{product.paymentStatus | titlecase}}</span>
                    <i *ngIf="product.paymentStatus === 'paid'" class="far fa-check-circle text-success"></i>
                    <i *ngIf="product.paymentStatus === 'Refunded'" class="fas fa-undo-alt"></i>
                    <i *ngIf="product.paymentStatus === 'Chargeback'" class="far fa-times-circle text-danger"></i>
                    <i *ngIf="product.paymentStatus === 'error'" class="far fa-times-circle text-danger"></i>
                    <i *ngIf="product.paymentStatus === 'pending'" class="far fa-clock text-warning"></i>
                    <span class="">{{product.date.toDate() | date:'mediumDate'}}</span>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column">
                <span class="">{{product.productPrice | currency:'USD'}}</span>
                <span *ngIf="product.paymentStatus ==  'pending'" class="badge badge-success cursor-pointer badge-btn" (click)="goToOrder(product)">{{'ORDERS.VIEW' | translate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>





















import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Prescription } from '../../../core/classes/prescriptions'
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { ExternalPharmacy, Profile } from 'src/app/core/services/interfaces';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { PdfService } from 'src/app/core/services/pdf.service';
import { EmailsService } from 'src/app/core/services/emails.service';
import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';
import { fbRemoteConfig } from 'src/app/authUtils';


@Component({
  selector: 'app-consultation-details',
  templateUrl: './consultation-details.component.html',
  styleUrls: ['./consultation-details.component.scss']
})
export class ConsultationDetailsComponent implements OnInit {
  @Input() user: any = "en";
  @Input() consult: any;
  @Output() close = new EventEmitter<boolean>();
  @Input() customNav = 1

  breadCrumbItems: Array<{}>;
  questions: Array<any> = [];
  prescriptions: any[] = [];
  prescription:any;
  prescriptionsItems: any[] = [];
  allPrescriptions = [];
  doctor:Profile;

  base64:any;
  modalRef:any;
  image: string;
  term:string;
  labPdfs = [];
  currentPdf;
  pdf: SafeResourceUrl;
  lang:string;
  settings = fbRemoteConfig.defaultConfig;
  
  referrals = [];
  sending = false;

  labLogo:string = "assets/images/lab.png";

  constructor(
    private afs: AngularFirestore,
    public modalService: NgbModal,
    private sanitized: DomSanitizer,
    private firebaseService: FirebaseService,
    private emailService: EmailsService
  ) { }

  async ngOnInit(){
    this.breadCrumbItems = [{ label: 'LABEL.DZEUS', active: true, link: '/' }, { label: 'HOME.BREADCRUMBS', active: true, link: '/' }];
    this.pdf = this.sanitized.bypassSecurityTrustResourceUrl(this.consult.pdf);
    this.getConsultQuestions();
    this.getConsultPrescriptions();
    this.afs.collection('referrals', ref => ref.where('patientUid', '==', this.consult.UID_Patient).where('consultID', '==', this.consult.ID).where('valid', '==', true)).valueChanges().subscribe((res: any) => {
      if(res){
        this.referrals = res
        if(this.referrals.length > 0 && this.consult.status == 'Completed' && this.customNav != 2){
          this.customNav = 3
        }
      }
    })
  }

  async signPad(modal){
    this.modalRef = this.modalService.open(modal, {size: 'lg', backdrop: 'static', centered: true, windowClass:'signPad' });
  }

  getConsultQuestions(){    
    this.afs.collection('consultation').doc(this.consult.ID).collection("Questions").valueChanges().subscribe(async (data:any )=> {
      let arr = []
      for(let i of data){
        if(i.biologicalGender == undefined || i.biologicalGender.length == 0 || i.biologicalGender.includes(this.user.biologicalgender)){
          arr.push(i)
        }
      }
      
      arr.sort((a, b) => {
        return a.sequence - b.sequence;
      });

      this.questions = await arr;
    });
  }

  pdfModal
  showPDF(pdfTag){
    this.pdfModal = this.modalService.open(pdfTag, { size: 'lg', backdrop: 'static', centered: true  });
  }

  getConsultPrescriptions(){
    let notPassed = true
    this.afs.collection("prescription", ref => ref.where('uid', '==', this.user.uid).where("consultID", "==", this.consult.ID).where('prescription_type', '==', 'Lab').where('valid', '==', true).where('status', '==', 'Signed')).valueChanges().subscribe(async (data:any) => {
      this.allPrescriptions = data ? data : [];
      this.labPdfs = [];
      for(let i = 0; i < this.allPrescriptions.length; i++){
        this.afs.firestore.collection('Documents').where('uidPatient', '==',this.user.uid).where('referenceID', "==", this.allPrescriptions[i].ID).get().then(data => {  
          this.labPdfs.push(data.docs)
        })
      }

      if(this.allPrescriptions.length > 0 && this.consult.Status == 'In Progress' && notPassed){
        notPassed = false
        this.customNav = 4
      }
    }); 
  }

  async pdfSelected(fileInput: any){
    this.loading = true
    ReloadComponent.loader = 'RELOAD.SAVING'

    if (fileInput.target.files && fileInput.target.files[0]) {
      this.pdfName = fileInput.target.files[0].name;
      const reader = new FileReader();
      reader.onload = (e: any) => {
		    let blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
        let url = window.URL.createObjectURL(blob);
        this.pdfUpload(blob);
      };
      reader.readAsDataURL(fileInput.target.files[0]); 
    }
  }

  pdfUpload(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadPDFToFirebase(reader.result);
    };
  }

  async uploadPDFToFirebase(pdf) {
    const randomId = Math.random().toString(36).substr(2, 5);
    this.firebaseService.uploadPdf(pdf, randomId, this.consult.UID_Patient)
      .then(pdfURL => {
        this.uploadDocToFirebase(pdf)
        this.getConsultPrescriptions();
        this.loading = false;
      });
  }

  pdfName:string;
  description
  loading:boolean = false;
  prescriptionID:string;
  uploadDocToFirebase(pdfUrl){
    let index = this.allPrescriptions.findIndex(p => p.ID == this.prescriptionID)

    let Data = {
      id: this.firebaseService.getDate(),
      pdf: pdfUrl.split("base64,")[1],
      pdfName: this.pdfName,
      uploaded: new Date(),
      referenceID: this.prescriptionID,
      referenceIDModel: 'prescription',
      docFor: 'Lab Results',
      description: 'Lab Results',
      uidPatient: this.allPrescriptions[index].uid,
      uidPractitioner: this.allPrescriptions[index].docId
    }

    this.afs.collection('Documents').doc(Data.id).set(Data);

    if(this.labPdfs[index].length == 0){
      this.afs.firestore.collection('users').doc(this.allPrescriptions[index].docId).get().then(practitioner => {
        this.emailService.generalEmails(practitioner.data(), 'labResults_' + practitioner.data().preferedLang, '<en>Lab Results</en><es>Resultados de Laboratorio</es>', this.settings, 'consults/' + this.allPrescriptions[index].consultID + '/prescription', this.allPrescriptions[index].consultID)
      })
    }
  }

  checkOptions(answer, option){
    if(typeof option == "object"){
      return `${option.options}`;
    }
  }

  async getPrescriptionsItems(){
    const prescription = new Prescription(this.afs, this.firebaseService);
    this.prescriptions = await prescription.getPrescriptionsItems(this.consult);
  }

  typeOfOption(option){
    return typeof(option) != 'string'
  }

  dismiss(){
    this.modalService.dismissAll()
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public selectedMail = new BehaviorSubject([]);
  currentNotification = this.selectedMail.asObservable();
  public filterNotifications = new BehaviorSubject([]);
  notification = this.filterNotifications.asObservable();

  constructor() { }

  emitter(){
    this.filterNotifications.next(undefined)
  }

  changeSelectedNotification(data: any) {
    this.selectedMail.next(data);
  }

  selectedNotification(data: any){
    this.changeSelectedNotification(data);
  }
}
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Observable } from 'rxjs';
import { Prescription } from '../classes/prescriptions';
import { AuthenticationService } from './auth.service';
import { FirebaseService } from './firebase.service';
import { BaseProduct, createOrder, Profile } from './interfaces';
import { OrdersService } from './orders.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { RxPrescription } from '../interfaces/prescriptions';

import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll } from "firebase/storage";

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {

  constructor(
    private router: Router,
    private https: HttpClient,
    private afs: AngularFirestore,
    private firebaseService: FirebaseService,
    private authService: AuthenticationService,
  ) { 

  }


  async getPrescriptionItems(prescriptionId:string){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('prescription').doc(prescriptionId).collection("Items").valueChanges().subscribe(items => {
        resolve(items);
      })
    });
  }

  async changeRefillQty(prescriptionId:string, product:any){
    // console.error("Running again wtf");
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('prescription').doc(prescriptionId).collection("Items").doc(product.productID.toString()).update({refillQty: product.refillQty});
      resolve(true);
    });
  }
  async changePrescriptionQty(prescriptionId:string, product:any){
    // console.error("Running again wtf");
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('prescription').doc(prescriptionId).collection("Items").doc(product.productID.toString()).update({prescriptionQty: product.prescriptionQty});
      resolve(true);
    });
  }


  getPractitioners():Observable<any>{
    // return new Promise<any>((resolve, reject) => {
    return this.afs.collection('users', ref => ref.where("accountType", "==", "practitioner")).valueChanges();
  }



  async getPatientPrescriptions(uid:string){
    return new Promise<any>(async (resolve, reject) => {
      this.afs.collection("prescription", ref => ref.where("uid", "==", uid).where("valid", "==", true)).valueChanges().subscribe(async (prescriptions:any) => {
        resolve(prescriptions);
      });
    });
  }


  getPatientLabsPrescriptions(uid:string): Observable<any[]>{
    return this.afs.collection("prescription", ref => ref.where("uid", "==", uid).where("valid", "==", true)).valueChanges();
  }
  getPatientLabsPrescriptionsItems(id:string) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("prescription").doc(id).collection("Items").valueChanges().subscribe(async (items:any) => {
        resolve(items);
      });
    });
  }


  async getRefillInformationByProduct(){
    // this.afs.collection("erp_dzeus_query_prescription_full_info", ref => ref.where("prescriptionID", "==", ).valueChanges().subscribe(async (prescriptions:any) => {
  }


  async uploadPrescriptionStorage(uid:string, file:File, prescription?:RxPrescription){
    const storage = getStorage();
    const storageRef = ref(storage, `users/${uid}/external-prescription-${prescription.ID}-${file.name}`);
  
    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Handle progress, bytesTransferred, etc.
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          // Handle unsuccessful uploads
          reject(error);
        },
        () => {
          // Handle successful uploads on complete
          // Get the download URL for the uploaded file
          getDownloadURL(storageRef)
            .then((url) => {
              // this.afs.collection("prescription").doc(prescription.ID).update({prescriptionURL: url});
              resolve(url);
            })
            .catch((error) => {
              // Handle any errors
              reject(error);
            });
        }
      );
    });
  }


  async getExternalTranscribedPrescription(uid:string, prescriptionID: string): Promise<any>{
    try {
      const storage = getStorage();
      const storageRef = ref(storage, `users/${uid}/`);
      const filePrefix = `external-prescription-${prescriptionID}`;
      const res = await listAll(storageRef);

      for (const itemRef of res.items) {
        if (itemRef.name.startsWith(filePrefix)) {
          let extension = itemRef.name.split('.').pop();
          const downloadURL = await getDownloadURL(itemRef);
          return { extension, downloadURL};
        }
      }
      // throw new Error("File not found");
      return null;
  } catch (error) {
      throw error;
  }
}
  
  // async refillPrescription(prescription){
  //   let button: HTMLButtonElement = document.getElementById("reorder-btn") as HTMLButtonElement;
  //   button.innerHTML = "<span class='spinner-border'></span>";
  //   button.disabled;
  //   const { phoneNumber, preferedLang, email, uid, partnerID  } = await this.authService.getUserProfile(prescription.uid);
  //   let date = new Date(Date.now());
  //     var dd = String(date.getDate()).padStart(2, '0');
  //     var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  //     var yyyy = date.getFullYear();
  //     let id:any = yyyy + mm + dd + uuidv4();

  //   let create: createOrder = {
  //     id: id,
  //     uid: uid,
  //     email: email,
  //     lang: preferedLang,
  //     date: new Date(),
  //     paymentStatus: "pending",
  //     phone: phoneNumber,
  //     partnerID: partnerID, 
  //     orderState: "create",
  //     confirmed: false,
  //     consultationID: prescription.consultID,
  //     prescriptionID: prescription.ID,
  //     orderLineUpdate: 0,
  //     orderId: "",
      
  //   }
  //   let orderCreated = await this.orderService.createOrder(create);
  //   console.log("Order created", orderCreated)

  //   const presc = new Prescription(this.afs, this.firebaseService)
  //   presc.profile = await this.authService.getUserProfile(uid);
  //   // this.prescriptions = await prescription.getMyPrescriptionsItems();
  //   let OGPrescription = await presc.getPrescriptionItems(prescription.ID);
  //   if(OGPrescription.length > 0){
  //     OGPrescription.forEach(async (item, i) => {

  //       let product: BaseProduct = {
  //         image: item.image,
  //         productID: item.productID,
  //         productName: item.productName,
  //         productPrice: item.productPrice,
  //         productQty: item.productQty,
  //         recurrency_order:item.recurrency_order,
  //         type:item.type,
  //         orderLineID: ""
  //       }
  //       this.orderService.createOrderLine(orderCreated.id, product);
  //       console.log(i, OGPrescription.length)
  //       if(i == OGPrescription.length - 1){
  //         setTimeout(async () => {
  //           // this.modalRef.close();
  //           // this.modalRef = undefined;
  //           // button.disabled = false;
  //           // this.orderDetails(order);
  //           this.router.navigate([`/orders&Refills/${orderCreated.id}}`])
  //         }, 2000);
  //       }
  //     });
  //   }
  // }


  async searchMedicationAPI(term:string){
    return new Promise<any>((resolve, reject) => {
      this.https.get(`https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?terms=${term}&ef=STRENGTHS_AND_FORMS,RXCUIS`).subscribe((data:any) => {
        resolve(data);
      });
    });
  }

}

function uuidv4() {
  throw new Error('Function not implemented.');
}










<div class="main">
  <h3 class="title">{{'LABS-MANAGER.TITLE' | translate}}</h3>
  <div class="d-flex">
    <div class="form">
      
      <!-- <h5 class="title">Performed At</h5>
      <div class="performed-at">
        <svg id="pin" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 14a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"></path>
          <path d="M17.657 16.657 13.414 20.9a2 2 0 0 1-2.827 0l-4.244-4.243a8 8 0 1 1 11.314 0v0Z"></path>
        </svg>
        <div class="ml-3 d-flex flex-column">
          <h3 class="mb-0">Laboratorio Irizarry Guash</h3>
          <span>Ave. Agustin Ramos Calero</span><br hidden>
          <span>787-123-4567</span>
        </div>
        
       
      </div> -->

      <h5 class="title mt-2">{{'LABS-MANAGER.LABS-LIST' | translate}}</h5>
      <p class="w-75">{{'LABS-MANAGER.LABS-DESCRIPTION' | translate}}
      </p>
      <div class="labs-list">
        <table class="table">
          <thead>
            <tr>
              <!-- <th>Medical Condition</th> -->
              <th>CPT Code</th>
              <th>Lab Name</th>
              <th>ICD-10 Code</th>
            </tr>
          </thead>
          <tbody>
            <tr id="row-{{lab.id}}" *ngFor="let lab of laboratories" (click)="handleLabSelection(lab)">
              <!-- <td [ngClass]="{'unavailable': lab.condition.length == 0}">{{ lab.condition.length > 0 ? lab.condition : "null" }}</td> -->
              <td [ngClass]="{'unavailable': lab.labCode == ''}">{{ lab.labCode ? lab.labCode : "null" }}</td>
              <td [ngClass]="{'unavailable': lab.labName == ''}">{{ lab.labName ? lab.labName : "null" }}</td>
              <td [ngClass]="{'unavailable': lab.icd10.length == 0, 'icd10': lab.icd10.length > 0}">
                <!-- <span *ngFor="let icd of lab.icd10" (click)="manageDiagnostic(icd)">{{ lab.icd10.length > 0 ? icd : "null" }}</span>  -->
                <span *ngFor="let icd of lab.icd10">{{ lab.icd10.length > 0 ? icd : "null" }}</span> 
                <span *ngIf="lab.icd10.length === 0">null</span>
              </td>

            </tr>
           
            
            
            
          </tbody>
        </table>
        <!-- <div class="labs-available" *ngFor="let lab of laboratories">
          <input id="{{lab.id}}" style="vertical-align: middle;" type="checkbox" name="radio-card" class="inp-cbx" (click)="handleLabSelection(lab)" />
          <label for="{{lab.id}}" class="cbx">
            <span>
              <svg width="12px" height="10px">
                <use xlink:href="#check"></use>
              </svg>
            </span>
            <span class="ml-2">{{lab.labName}}</span>
          </label>
        </div> -->
      </div>
      <svg hidden class="inline-svg">
        <symbol id="check" viewBox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </svg>



      <ng-template #testResultForm let-modal>
        <div class="w-100 p-2 d-flex justify-content-end topbar">
          <div class="loading"> 
            <svg width="16px" height="12px">
              <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
              <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
            </svg>
          </div>
          <div class="d-flex flex-column w-100">
            <h5 class="mx-auto mt-5 mb-0">{{selected_labs.labName}}</h5>
            <div class="d-flex justify-content-around">
              <div class="">
                <span>Sample Type:</span> 
                <i *ngIf="selected_labs.sample_type == 'Blood'" class="fa fa-tint text-danger m-1" aria-hidden="true"></i>
                <i *ngIf="selected_labs.sample_type == 'Urine'" class="fa fa-flask text-warning m-1" aria-hidden="true"></i>
                <span>{{selected_labs.sample_type}}</span>
              </div>
              <div class="">
                <span>Test Type:</span> 
                <span>{{selected_labs.test_type}}</span>
              </div>
            </div>
          </div>
          <button type="button" class="close" aria-label="Close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- <div *ngFor="let lab of selected_labs; let i = index" class="lab-form">
          <div class="d-flex">
            <div class="circle">
              {{i + 1}}
            </div>
            <h5 class="title ml-2">{{lab.labTranslation | translation: "en"}}</h5>
          </div>
  
  
          <div class="mt-3">
            <label for="test-date">Test Date: </label>
            <input type="date" id="test-date" name="test-date" class="form-control" required />
  
            <label for="test-date">Test Date: </label>
            <input type="date" id="test-date" name="test-date" class="form-control" required />
          </div>
        </div> -->
        <div class="lab-form">
          <div class="mt-3">
            <h5 class="title">Performed At</h5>
            <div class="section">
              <label for="lab-name">Laboratory Name: </label>
              <input type="text" id="lab-name" name="lab-name" class="form-control" [(ngModel)]="labInformation.name" required />
              <label for="lab-address">Laboratory Address: </label>
              <input type="text" id="lab-address" name="lab-address" class="form-control" [(ngModel)]="labInformation.address" required />
              <label for="lab-phone">Laboratory Phone: </label>
              <input type="number" id="lab-phone" name="lab-phone" class="form-control" [(ngModel)]="labInformation.contactNumber" required />
            </div>

            <label class="mt-4" for="test-date">Test Date: </label>
            <input type="date" id="test-date" name="test-date" class="form-control" [(ngModel)]="lab_misc.dateOfTest" required />

            <label for="interpretation">Interpretation: </label>
            <input type="text" id="interpretation" name="interpretation" [(ngModel)]="lab_misc.interpretation"  class="form-control" required />

            <label for="comments">Comments: </label>
            <input type="text" id="comments" name="comments" class="form-control" [(ngModel)]="lab_misc.comments"  required />




            <div class="d-flex justify-content-between">
              <h5 class="title mt-4">Test Results</h5>
              <svg (click)="addTestResultTemplate()" class="mt-4" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5v14"></path>
                <path d="M5 12h14"></path>
              </svg>
            </div>
            <div class="section mb-5">
              <label for="test-name">Name: </label>
              <input type="text" class="form-control" [(ngModel)]="results.name"/>
              
              <div class="d-flex mt-2 mb-2 justify-content-between">
                <div class="flex-column w-75">
                  <label for="test-value">Value: </label>
                  <input type="text" id="test-value" name="test-value" class="form-control" [(ngModel)]="results.value" required />
                </div>
                <div class="flex-column">
                  <label for="test-value-unit">Unit: </label>
                  <ng-select id="test-value-unit" id="test-value-unit" [addTag]="true" [clearable]="false" placeholder="Units of Measure" [(ngModel)]="results.unit">
                    <ng-option *ngFor="let unit of selected_labs.labAnalysisTemplate.mesurements.decimal_units" [value]="unit">{{unit}}</ng-option>
                  </ng-select>
                </div>
              </div>
              <h5 class="title mt-4">Range</h5>
              <div class="d-flex justify-content-between">
                <div class="flex-column">
                  <label for="range-high">High: </label>
                  <input type="number" id="range-high" name="range-high" class="form-control" [(ngModel)]="results.range.high" required />
                </div>
                <div class="flex-column">
                  <label for="range-low">Low: </label>
                  <input type="number" id="range-low-" name="range-low" class="form-control" [(ngModel)]="results.range.low" required />
                </div>
                <!-- <div class="flex-column">
                  <label for="test-value-unit">Unit: </label>
                  <ng-select id="test-value-unit"  bindLabel="name" id="test-value-unit" [addTag]="true" [clearable]="false" bindValue="name" placeholder="Units of Measure" (change)="results.range.unit">
                    <ng-option *ngFor="let unit of selected_labs.labAnalysisTemplate.mesurements.decimal_units" [value]="unit">{{unit}}</ng-option>
                  </ng-select>
                </div> -->
              </div>
              <div class="d-flex mx-auto">
                <button class="btn btn-primary mx-auto" (click)="save(results)">Save</button>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary" (click)="submit()">Submit</button>
            </div>

            <div *ngFor="let result of test_results" (click)="edit(result)" class="section d-flex">
              <i *ngIf="selected_labs.sample_type == 'Blood'" class="fa fa-tint text-danger m-1" aria-hidden="true"></i>
              <i *ngIf="selected_labs.sample_type == 'Urine'" class="fa fa-flask text-warning m-1" aria-hidden="true"></i>
              <div class="flex-column ml-3">
                <h5 class="title">{{result.name}}</h5>
                <span>{{result.value}} {{result.unit}}</span>
              </div>

            </div>
            <!-- <div *ngFor="let result of test_results; let i = index" class="section mb-5">
              <label for="test-name">Name: </label>
              <input type="text" id="{{i}}" class="form-control" [(ngModel)]="result.name"/>
              
              <div class="d-flex mt-2 mb-2 justify-content-between">
                <div class="flex-column w-75">
                  <label for="test-value">Value: </label>
                  <input type="text" id="{{i}}" name="test-value" class="form-control" [(ngModel)]="result.value" required />
                </div>
                <div class="flex-column">
                  <label for="test-value-unit-{{i}}">Unit: </label>
                  <ng-select id="test-value-unit-{{i}}"  bindLabel="name" id="test-value-unit" [addTag]="true" [clearable]="false" bindValue="name" placeholder="Units of Measure" (change)="saveUnitsOfMeasure(i, $event)">
                    <ng-option *ngFor="let unit of selected_labs.labAnalysisTemplate.mesurements.decimal_units" [value]="unit">{{unit}}</ng-option>
                  </ng-select>
                </div>
              </div>
              <h5 class="title mt-4">Range</h5>
              <div class="d-flex justify-content-between">
                <div class="flex-column">
                  <label for="range-high">High: </label>
                  <input type="number" id="range-high-{{i}}" name="range-high-{{i}}" class="form-control" [(ngModel)]="result.range.high" required />
                </div>
                <div class="flex-column">
                  <label for="range-low">Low: </label>
                  <input type="number" id="range-low-{{i}}" name="range-low-{{i}}" class="form-control" [(ngModel)]="result.range.low" required />
                </div>
                <div class="flex-column">
                  <label for="test-value-unit-{{i}}">Unit: </label>
                  <ng-select id="test-value-unit-{{i}}"  bindLabel="name" id="test-value-unit" [addTag]="true" [clearable]="false" bindValue="name" placeholder="Units of Measure" (change)="test_results[i].range.unit">
                    <ng-option *ngFor="let unit of selected_labs.labAnalysisTemplate.mesurements.decimal_units" [value]="unit">{{unit}}</ng-option>
                  </ng-select>
                </div>
              </div>
              <button class="btn btn-primary" (click)="save(i, result)">Save</button>
            </div> -->


            
          </div>
        </div>
      </ng-template>



    </div>
    <!-- <div class="document">
      <h5>Document</h5>
      <object [data]="pdf"  width="100%" height="400px"></object> 

    </div> -->
  </div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';

@Component({
  selector: 'app-pagetitle',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PagetitleComponent implements OnInit {
  @Input() title: string;

  cookieValue;
  countryName;
  valueset: string;
  currentURL
  public userData: any;

  listLang = [
    { text: 'English', lang: 'en' },
    { text: 'Español', lang: 'es' },
  ];
  constructor(public translate: TranslateService,private router: Router, 
    public authService: AuthenticationService, 
    public languageService: LanguageService, 
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,) { }

  ngOnInit(): void {
    this.cookieValue = this.translate.store.currentLang;
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text).toString();
    
    this.getUser();
  }

  getUser() {
    this.afAuth.user.subscribe(user => {
      if(user){
        this.afs.collection('users').doc(`${user.uid}`).valueChanges().subscribe(async data => {
          this.userData = await data;
          this.setLang(this.userData.preferedLang)
        });
      }
    });
  }

  setLang(lang){
    for(let i = 0; i < this.listLang.length; i++){
      if(lang == this.listLang[i].lang){
        this.languageService.setLanguage(lang); 
        this.countryName = this.listLang[i].text.toString()

        this.cookieValue = lang;
      }
    }  
  }

  async setLanguage(text: string, lang: string, flag: string) {
    this.currentURL = this.router.url
    this.afs.collection('users').doc(`${this.userData.uid}`).update({preferedLang: lang}).then( async res => {      
      await this.languageService.setLanguage(lang); 
      this.countryName = text.toString();

      this.cookieValue = await lang;
      ReloadComponent.loader = 'RELOAD.DEFAULT'
      await this.router.navigateByUrl('account/reload', { skipLocationChange: true });     
      await setTimeout(async () => {
        return await this.router.navigateByUrl(this.currentURL);
      },500)
    });
  }
}
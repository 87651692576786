import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import firebase from "firebase/compat/app";
import { environment, remoteConfig } from 'src/environments/environment';

import { getFunctions, httpsCallable } from "firebase/functions";

const app = firebase.initializeApp(environment.firebaseConfig);
const functions = getFunctions(app);

@Injectable({
  providedIn: 'root'
})
export class NPIRegistryService {

  constructor(private http: HttpClient,) { }

  settings = remoteConfig

  async searchRegistry(NPI: string, uid: string) {
    // const URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/searchRegistry`;
    // return new Promise((resolve, reject) => {
    //   let body = new HttpParams();
    //   const headers = new HttpHeaders();
    //   headers.set('Content-Type', 'application/json');
    //   headers.set('Access-Control-Allow-Origin', this.settings.domain);
      
    //   body = body.set('NPI', NPI);
    //   body = body.set('uid', firebase.auth().currentUser.uid);
    //   return this.http.post(URL, body, { headers: headers }, )
    //   .subscribe(res => {

    //     resolve(res);
    //   });
    // }).catch(err => {
    // });
    // const registry = httpsCallable(functions, 'searchRegistry');
    const registry = httpsCallable(functions, 'oncall-search-registry');
    let result = await registry({NPI: NPI, uid: uid});
    return result.data;
  }


  orderChanged(){
    const URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/orderChange`;
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '* ');
    let body = new HttpParams();
    
    body = body.set('email', firebase.auth().currentUser.email);
    this.http.post(URL, body, {responseType: 'text'}).subscribe(res => {
    });
  }
}

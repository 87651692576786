import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ref } from 'firebase/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VendorBill } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class VendorBillsService {

  constructor(
    private db: AngularFirestore
  ) { }



  getVendorBill$(): Observable<VendorBill[]> {
    return new Observable<any[]>((observer) => {
      const sub = this.db.collection("vendor_bills").valueChanges().pipe(
        map((trail: any) => trail.sort((a, b) => b.date.seconds - a.date.seconds))
      ).subscribe((data) => {
        observer.next(data);
      }, (error) => {
        observer.error(error);
      });

      return () => {
        // Clean up the subscription when unsubscribed
        sub.unsubscribe();
      };
    });
  }

  getUserVendorBill$(uid:string): Observable<VendorBill[]> {
    return new Observable<any[]>((observer) => {
      const sub = this.db.collection("vendor_bills", ref => ref.where("uid", "==", uid)).valueChanges().pipe(
        map((trail: any) => trail.sort((a, b) => b.date.seconds - a.date.seconds))
      ).subscribe((data) => {
        observer.next(data);
      }, (error) => {
        observer.error(error);
      });

      return () => {
        // Clean up the subscription when unsubscribed
        sub.unsubscribe();
      };
    });
  }
}

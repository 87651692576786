  
  <div class="pad p-3">
    <div style="padding: 10px;">
      <h3 class="d-flex justify-content-center">{{'CONSULT-PRESCRIPTIONS.PROVIDDESIGNATURE' | translate}}</h3>
      <p *ngIf="router.url == '/consultations'" style="text-align: center;">{{'CONSULT-PRESCRIPTIONS.LEGAL' | translate}}</p>
      <div id="signature-pad" class="signature-pad">
        <div class="signature-pad--body">
          <canvas id="canvas" width="640" height="480" (click)="beginStroke()"></canvas>
        </div>
      </div>
      <div class="d-flex flex-column">
        <button *ngIf="router.url.includes('/referral') || router.url.startsWith('/consultations') || router.url.includes('/prescription') || auth" class="btn btn-info w-50 mx-auto mt-5" (click)="storeSignature()">{{'CONSULT-PRESCRIPTIONS.SAVE1' | translate }}</button>
        <button *ngIf="router.url.includes('/closingSignature')" class="btn btn-info w-50 mx-auto mt-5" (click)="storePDFSignature()">{{'CONSULT-PRESCRIPTIONS.SAVE1' | translate }}</button>
        <button *ngIf="router.url.includes('/orders&Refills')" class="btn btn-info w-50 mx-auto mt-5" (click)="storeSignature()">{{'CONSULT-PRESCRIPTIONS.SAVE1' | translate }}</button>
        <!-- <button *ngIf="router.url.includes('/consults')" class="btn btn-info w-50 mx-auto mt-5" (click)="storeSignature()">{{'CONSULT-PRESCRIPTIONS.SAVE1' | translate }}</button> -->
        <button class="btn btn-cyan w-50 mx-auto mt-1" (click)="clearCanvas()">{{'CONSULT-PRESCRIPTIONS.CLEAR' | translate }}</button>     
      </div>
    </div>
  </div>

  <app-pdf-download *ngIf="signed" [consult]="consult" [history]="history" [remoteConfig]="remoteConfig" [consultUpdateInfo]="consultUpdateInfo" [signature]="closingSignature" (pdfCreated)="checkPdf($event)"></app-pdf-download>

<div *ngIf="loadingDiagnostics" class="loading">
  <app-reload [message]="'RELOAD.VALIDATING'"></app-reload>
</div>
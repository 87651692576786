import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { PdfService } from 'src/app/core/services/pdf.service';

import { Subscription } from 'rxjs';
import { Prescription } from 'src/app/core/classes/prescriptions';
import { EmailsService } from 'src/app/core/services/emails.service';
import Swal from 'sweetalert2';
import { AuthenticatorService } from '../../authenticator/authenticator.service';
import { BaseProductPrescription, ExternalPharmacy } from 'src/app/core/services/interfaces';
import { RxPrescription } from 'src/app/core/interfaces/prescriptions';
import { v4 as uuidv4 } from 'uuid';
import { LaboratoriesService } from 'src/app/core/services/laboratories/laboratories.service';
import { testProgrammerAccounts } from 'src/environments/environment';
import { Product, ProductService } from 'src/app/core/services/product.service';

@Component({
  selector: 'app-consult-prescriptions',
  templateUrl: './consult-prescriptions.component.html',
  styleUrls: ['./consult-prescriptions.component.scss']
})

export class ConsultPrescriptionsComponent implements OnInit {
    @Input() patient:any;
    @Input() practitioner:any;
    @Input() consult:any;
    @Input() shippingAddress:any;
    @Input() prevConsult:any;
  
    @ViewChild('signaturePad', { read: TemplateRef }) signaturePadModal:TemplateRef<any>;
    @ViewChild('internalPrescription', { read: TemplateRef }) internalPrescription:TemplateRef<any>;
    @ViewChild('addLab', { read: TemplateRef }) addLab:TemplateRef<any>;
    @ViewChild('prescriptionSummary', { read: TemplateRef }) prescriptionSummaryModal:TemplateRef<any>;
    @ViewChild('pharmacyDetails', { read: TemplateRef }) pharmacyModal:TemplateRef<any>;
    @ViewChild('documentLabDiagnosticModal', { read: TemplateRef }) documentLabDiagnosticModal:TemplateRef<any>;
    @ViewChild('handlePrevPrescriptionsModal', { read: TemplateRef }) handlePrevPrescriptionsModal:TemplateRef<any>;
  
    practitionerAddress:any;
    NPIRegistry:any;
  
    public base64: any;
  
    user:any;
    all_prescriptions:any[] = [];
    all_prescriptions_items:any[] = [];
    all_prescriptions_labs:any[] = [];
    
    interactions:any = [];
    prescriptions:any = [];
    prescriptionsItems:any = [];
    
    prescriptionID;
    prescriptionRef
    currentPrescriptionItems:any[] = [];

    settings:any;
      
    currentConsultQuestions: any = [];

    //rx section
    rxInstruction:any;
    refillQty:number;
  
    isLab: Boolean = false;
    loadingLabs: Boolean = false;
    isEdit: Boolean = false;
  
    laboratories: any;
      
    modalRef:any;
    signModalRef: any;
      
    //internal prescription wizard variables
    products:Product[] = [];
    
    currentLang = this.translate.store.currentLang
  
    signPadSubscription = new Subscription();
      
    loading:boolean = false;
    loadingMessage: string = "RELOAD.DEFAULT";
  
    swal

    signature

    ///
    rxLogo:string = "assets/images/rx.png";
    labLogo:string = "assets/images/lab.png";

    showPreview:boolean = false;
    sentPrescriptionSuccess: boolean = false;

    sentProducts:any[] = [];

    labPdfs:any[] = [];
    currentPdf:any;
    //
    wishlist:any[] = [];
    
    labLocationsList:any[] = [];

    searchText
    searchText1
    
    consultLabs:any[] = [];

    possible_diagnostics:any[] = [];

    labDiagnosticToDocument:any;

    claims;

    service = []
    requiredProducts = []

    patientSubscriptions:any[] = [];

    today = new Date();
    aMonthFromNow = new Date(this.today.getFullYear(), this.today.getMonth() + 1, this.today.getDate());

    constructor(
      private afs: AngularFirestore,
      public firebaseService: FirebaseService,
      private modalService: NgbModal,
      public afAuth: AngularFireAuth,
      private route: Router,
      private http: HttpClient,
      public pdfService: PdfService,
      private authService: AuthenticationService,
      private authenticatorService: AuthenticatorService,
      private translate: TranslateService,
      private emailService: EmailsService,
      private labsService: LaboratoriesService,
      private productService: ProductService,
      private cdr: ChangeDetectorRef
    ) {
      authService.getClaims().then(claims => {
        this.claims = claims;
      })
    }
    
    async ngOnInit() {
      this.user = await this.authService.profile();
      this.settings = await this.authService.getRemoteConfig();      
      this.currentLang = this.user.preferedLang;
      
      if(this.consult){
        this.getPatientSelectedItems();
        this.getConsultAddress();
        this.getInteractionsAnalysis();
        
        if(this.consult.UID_Practitioner){
          this.getPractitionerProfile()
        }
  
        this.afs.collection("consultation").doc(this.consult.ID).collection('Diagnostics').valueChanges().subscribe(consult => {
          this.consult.diagnostics = consult;
        });
      }
      this.getPrescriptions();

      if(this.consult.hasLabs){
        this.afs.collection("consultation").doc(this.consult.ID).collection('LabsChosen').valueChanges().subscribe(labs => {
          this.consultLabs = labs;
        })
      }

      window.addEventListener("orientationchange", () => {
        this.dismissSignModal()
        setTimeout(res => {
          if(this.route.url.includes(`${this.consult.ID}/prescription/${this.prescriptionRef?.ID}`) && this.prescriptionRef?.status == 'Unsigned'){
            this.signPad();
          }
        }, 500)
      });

      // if(this.consult.hasLabs){
        this.afs.firestore.collection('labAnalysisTemplates').get().then(data => {    
          data.docs.forEach(lab => {
            if(lab.data().disabled == false){
              this.labAnalysis.push(lab.data());
              this.laboratories = this.labAnalysis;
            }
          })
        });
  
        // this.getLabLocations()
      // }

      this.afs.collection('pharmacyOrderSchedule', ref => ref.where('uid', '==', this.patient.uid)).valueChanges().subscribe((res:any) => {
        this.patientSubscriptions = [];
        this.patientSubscriptions = res.filter(sub => sub.status == 'active');
      })
    }

    getLabLocations(){
      this.afs.firestore.collection('labLocationTemplates').get().then((res) => {
        this.labLocationsList = res.docs.map(r => r.data())
      })
    }

    async getPractitionerProfile(){
      this.practitioner = await this.authService.getUserProfile(this.consult.UID_Practitioner);

      this.NPIRegistry = await this.authService.getNPIRegistry(this.practitioner.uid);
      this.NPIRegistry.taxonomies = this.NPIRegistry.taxonomies.filter(tax => tax.state == this.consult.state.code);
      let practitionerAddress = await this.firebaseService.getUserAddresses(this.practitioner)
      this.practitionerAddress = await practitionerAddress.filter(address => address.AddressType == 'Billing');
    }

    async getPrescriptions(){
      let id = this.prevConsult ? this.prevConsult : this.route.url.split('consults/').pop().split('/prescription')[0].split("/")[0];    
      this.afs.firestore.collection("prescription").where("uid", '==', this.patient.uid).get().then(async (presc:any) => {
        presc = presc.docs.map(p => p.data())
        this.prescriptions = presc.filter(x => x.consultID == id).sort((a, b) => b.date.toDate().getTime() - a.date.toDate().getTime());
        this.all_prescriptions = presc.filter(x => x.status == "Signed").sort((a, b) => b.date.toDate().getTime() - a.date.toDate().getTime());
        this.prescriptionsItems = [];
        this.all_prescriptions_items = [];
        this.all_prescriptions_labs = [];
        this.labPdfs = [];

        await this.afs.firestore.collection('Documents').get().then(async data => {  
          let tempData = data
          
          await this.prescriptions.map(async (presc, i )=> {
            if(presc.ID == this.prescriptionRef?.ID){
              this.prescriptionRef = presc;
              this.base64 = presc.z_processedB64 ? presc.z_processedB64 : presc.z_base64
            }
  
            await this.afs.firestore.collection("prescription").doc(presc.ID).collection("Items").get().then(async (doc:any) => {
              let arr = [];
              if(doc.docs.length > 0){
                arr = doc.docs.map(e => {
                  return e.data();
                })
              }
                
              this.prescriptionsItems[i] = arr;
              
              if(presc.prescription_type == 'Lab'){
                this.labPdfs.push(tempData.docs.filter(x => x.data().referenceID == presc.ID));
              }
              else {
                this.labPdfs.push([])
              }            
            });
          });

          await this.all_prescriptions.map(async (presc, i )=> {  
            await this.afs.firestore.collection("prescription").doc(presc.ID).collection("Items").get().then(async (doc:any) => {
              let arr = [];
              if(doc.docs.length > 0){
                arr = doc.docs.map(e => {
                  return e.data();
                })
              }
                
              this.all_prescriptions_items[i] = arr;
              
              if(presc.prescription_type == 'Lab'){
                this.all_prescriptions_labs.push(tempData.docs.filter(x => x.data().referenceID == presc.ID));
              }
              else {
                this.all_prescriptions_labs.push([])
              }            
            });
          });
        })      
      }); 
    }

    pdfModal
    showPDF(pdfTag){
      this.pdfModal = this.modalService.open(pdfTag, { size: 'lg', backdrop: 'static', centered: true  });
    }
  
    async getPrescriptionsItems(presc:any){
      
      let index = this.prescriptions.map(object => object.ID).indexOf(presc.ID);
      this.sentProducts = this.prescriptionsItems[index];
      this.currentPrescriptionItems = this.sentProducts;
      if(!this.sentProducts){
        this.afs.firestore.collection("prescription").doc(presc.ID).collection("Items").get().then((doc:any) => {
          this.sentProducts = doc.docs.map(e => {
            return e.data();
          })

          this.currentPrescriptionItems = this.sentProducts;
        })
      }
      
      if(presc.status == "Unsigned"){
        this.base64 = undefined;
        let doc = await this.pdfService.generatePdf(presc)

        if(doc == 'An error has occured.' || doc.data.pdf == 'No callable function sent.'){
          Swal.fire({
            customClass: {
              confirmButton: 'swalPurple',
            },
            title: 'An error has occured!',
            icon: 'warning',
            text: 'Please try again or contact administration.',
            confirmButtonText: 'Ok'
          }).then(res => {
            window.location.reload()
          })

          return
        }

        let index = this.prescriptions.findIndex(prescs => prescs.ID == presc.ID);
        if(index != -1 ){
          this.prescriptions[index] = Object.assign(this.prescriptions[index], doc.data.pdf)
          this.prescriptionRef = this.prescriptions[index]
          this.base64 = this.prescriptionRef.z_processedB64 ? this.prescriptionRef.z_processedB64 : this.prescriptionRef.z_base64
        }
      }
      else if(presc.z_base64){
        this.base64 = presc.z_processedB64 ? presc.z_processedB64 : presc.z_base64
      }

      this.prescriptionID = presc?.ID;

      if(presc?.prescription_type === "Lab"){
        this.isLab = true;
      }  
    }
  
    labAnalysis = [];
    searchLabs($event){
      this.loadingLabs = true;
      // this.http.get<any>("https://clinicaltables.nlm.nih.gov/api/hcpcs/v3/search?terms="+$event.target.value).subscribe(response =>{
      this.laboratories = this.labAnalysis.filter(lab => lab.labName.toLowerCase().includes($event.target.value.toLowerCase()) || lab.labCode.toLowerCase().includes($event.target.value.toLowerCase()));
        if(this.laboratories.length == 0){
          this.laboratories = this.labAnalysis
        }
        this.loadingLabs = false;
      // });
    }

    labLocations = [];
    searchLabLocations($event){
      this.loadingLabs = true;
      // this.http.get<any>("https://clinicaltables.nlm.nih.gov/api/npi_org/v3/search?sf=name.full,addr_practice.zip,provider_type&df=name.full,NPI,provider_type,addr_practice.full,addr_practice.phone&ef=NPI,name.full,provider_type,addr_practice.full,addr_practice.phone&terms="+$event.target.value+'&maxList=500').subscribe(response =>{
      //   this.labLocations = [];
      //   for(let i = 0; i < response[3].length; i++){
      //     if(response[3][i][2] == "Clinical Laboratory"){
      //       this.labLocations.push({'name.full':response[3][i][0], 'addr_practice.full':response[3][i][3], 'addr_practice.phone':response[3][i][4], 'NPI':response[3][i][1], 'provider_type':response[3][i][2]});
      //     }
      //   }
      //   this.loadingLabs = false;
      // });

      this.labLocations = [];
      setTimeout(() => {
        if(typeof($event.target.value) == 'string'){
          this.labLocations = this.labLocationsList.filter(lab => lab['name.full'].toLowerCase().includes($event.target.value.toLowerCase()) || 
                                                                  lab['addr_practice.full'].toLowerCase().includes($event.target.value.toLowerCase()) ||
                                                                  lab['addr_practice.phone'].toLowerCase().includes($event.target.value.toLowerCase()) || 
                                                                  lab['NPI'].toLowerCase().includes($event.target.value.toLowerCase()) || 
                                                                  lab['email'].toLowerCase().includes($event.target.value.toLowerCase()));
          this.loadingLabs = false;
        }
      },50) //allows labLocations to turn into an empty array before filtering 
    }

    pharmacies: any = {'name.full': []};
    selectedPharmacy = {};
    searchPharmacies($event){
      // if($event.target?.value){
      //   this.loadingLabs = true;
      //   this.http.get<any>("https://clinicaltables.nlm.nih.gov/api/npi_org/v3/search?sf=name.full,addr_practice.zip&df=name.full,NPI,provider_type,addr_practice.full&ef=NPI,name.full,provider_type,addr_practice.full,addr_practice.phone&terms="+$event.target.value).subscribe(response =>{
      //     this.pharmacies = response[2];
      //     this.loadingLabs = false;
      //   });
      // }
    }

    selectPharmacy(event){
      let index = this.pharmacies['name.full'].findIndex(pharmacy => pharmacy == event);
      this.selectedPharmacy = {name: this.pharmacies['name.full'][index], address: this.pharmacies['addr_practice.full'][index], phone: this.pharmacies['addr_practice.phone'][index], NPI: this.pharmacies.NPI[index]};
    }

    selectedLaboratory: any = {};
    selectLab(event){
      this.selectedLaboratory = event;
    }
  
    openPrescriptionPDF(prescription){
      this.prescriptionRef = prescription;
      this.modalRef = this.modalService.open(this.internalPrescription, {size: 'xl', backdrop: 'static', centered: true, windowClass: 'internal-prescription', keyboard: false});
      if(prescription.status == 'Signed'){
        this.base64 = prescription.z_processedB64 ? prescription.z_processedB64 : prescription.z_base64
      }
    }
  
    createPrescription(){
      this.isEdit = false;
      let patientFullName = this.patient.firstName + " " + this.patient?.middleName + " " + this.patient.lastName1;
      let docFullName = this.practitioner.firstName + " " + this.practitioner?.middleName + " " + this.practitioner.lastName1;
      
      let date = new Date(Date.now());
      let age = this.calculateAge();
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();
      this.prescriptionID = yyyy + mm + dd + uuidv4();    

      let data: RxPrescription = {
        ID: this.prescriptionID,
        address: this.shippingAddress ? this.shippingAddress : '',
        age: age,
        date: date,
        docId: this.practitioner.uid,
        docAdd:this.practitionerAddress[0],
        docName: docFullName,
        docPhone: this.practitioner.phoneNumber,
        docEmail:this.practitioner.email,
        name: patientFullName,
        email: this.patient.email,
        phone: this.patient.phoneNumber,
        consultID: this.consult.ID,
        diagnostics: this.consult.diagnostics ? this.consult.diagnostics : "",
        License: this.NPIRegistry.taxonomies[0].license,
        LicenseState: this.NPIRegistry.taxonomies[0].state,
        Specialty: this.NPIRegistry.taxonomies[0].desc,
        prescription_pdf: "prescription-" + this.consult.ID+"-"+this.prescriptionID,
        prescription_type: this.isLab ? "Lab" : "Rx",
        status: "Unsigned",
        birthdate: this.patient.birthdate,
        NPI:this.NPIRegistry.NPI,
        internal: false,
        valid: true,
        pharmacy: "",
        uid: this.patient.uid,
        partnerID: this.patient.partnerID,
        lang: this.patient.preferedLang,
        erpPrescriptionRef: "",
        automaticPayment: false,
        patientSignature: this.consult.patientSignature ? this.consult.patientSignature : "",
        patientSignedDate: this.consult.dateCertified ? this.consult.dateCertified : "",
        patientSignRef: this.consult.signRef ? this.consult.signRef : "",
        orientation: this.consult.orientation != undefined ? this.consult.orientation : false,
        transcribed: false
      }

      this.firebaseService.createPrescription(data.ID, data).then(
        async res => {
          if(this.isLab){
            this.prescriptionRef = await data;
            this.saveLabPrescription(data);
            this.dismiss();
          }
        },
        err => {
        }
      )
    }

    handleLabSelection(lab) {
      const labIndex = this.currentPrescriptionItems.findIndex((selectedLab) => selectedLab.labName === lab.labName);
      const allLabsIndex = this.laboratories.findIndex((selectedLab) => selectedLab.labName === lab.labName);
      if (labIndex === -1) {
        // Lab not included, so add it
        // let tempDiagnostic = this.consult.diagnostics.filter(d => lab.icd10.includes(d.code_name.split(' -')[0]));
        // let tempDiagnostic = this.consult.diagnostics.filter(d => lab.icd10.includes(d.code));
        this.currentPrescriptionItems.push({ labName: lab.labName, labCode: lab.labCode, id: Math.random().toString(36).substr(2, 9), diagnostics: this.consult.diagnostics});
        this.laboratories[allLabsIndex].diagnostics = typeof(this.consult.diagnostics) == 'object' ? this.consult.diagnostics : this.consult.diagnostics;
        
        this.laboratories[allLabsIndex].diagnostics = [...new Map(this.laboratories[allLabsIndex].diagnostics.map(item => [item.id, item])).values()]

      } else {
        // Lab already included, so remove it
        if(this.prescriptionRef){
          let index = this.prescriptions.findIndex(presc => presc.ID == this.prescriptionRef.ID);
          if(index != -1 && this.prescriptionsItems[index].find(presc => presc.labName == lab.labName)){
            this.deletePrescriptionItem(this.prescriptionsItems[index][labIndex].id);
          }
          this.currentPrescriptionItems.splice(labIndex, 1);
          this.laboratories[allLabsIndex].diagnostics = []
        }
        else if(!this.prescriptionRef){
          this.currentPrescriptionItems.splice(labIndex, 1);
          this.laboratories[allLabsIndex].diagnostics = []
        }
      }
    }
  
    async saveLabPrescription(prescription){
      for(let i = 0; i < this.currentPrescriptionItems.length; i++){
        let data = this.currentPrescriptionItems[i];
        if(this.isEdit == false){
          this.afs.collection("prescription").doc(this.prescriptionID).collection("Items").doc(data.id).set(data).then(
            res => {
            }
          );
          if(i == this.currentPrescriptionItems.length - 1){
              this.isLab = false;
              if(this.modalRef){
                this.modalRef.dismiss();
              }
              this.modalService.dismissAll();
              
              await this.getPrescriptions();

              this.getPrescriptionsItems(prescription); 
              this.goToPrescription('prescription', this.prescriptionID);       
              this.openPrescriptionPDF(prescription);
          }
        }
        else {
          this.firebaseService.editPrescription(this.prescriptionID, data);

          if(i == this.currentPrescriptionItems.length - 1){
            await this.getPrescriptions();
            if(this.modalRef){
              this.modalRef.dismiss();
            }
            this.modalService.dismissAll();
            this.getPrescriptionsItems(prescription); 
            this.goToPrescription('prescription', this.prescriptionID);       
            this.openPrescriptionPDF(prescription);
          }
        }
      }
    }

    documentLabDiagnostic(diagnostic){
      this.labDiagnosticToDocument = diagnostic;
      this.modalRef = this.modalService.open(this.documentLabDiagnosticModal, {size: 'xl', centered: true, backdrop: 'static', windowClass:"documentLabDiagnostic"}); 
    }
  
    deletePrescription(presc){
      this.afs.collection("prescription").doc(presc.ID).update({valid: false})
      let index = this.prescriptions.findIndex(prescs => prescs.ID == presc.ID);
      if(index != -1 ){
        this.prescriptions[index] = Object.assign(presc, {valid: false})
      }

      let index1 = this.all_prescriptions.findIndex(prescs => prescs.ID == presc.ID);
      if(index1 != -1 ){
        this.all_prescriptions[index1] = Object.assign(presc, {valid: false})
      }

      this.pdfService.addWaterMark(presc, this.patient.uid, true)
    }
  
    openModal(modal){
      this.isEdit = false;
      let route:any = this.route.url.split("/prescription")[0];
      this.route.navigate([`${route}/prescription`])
      if(this.isLab){
        this.currentPrescriptionItems = []
      }
      this.rxInstruction = "";
      this.refillQty = 0;

      if(this.consult.hasLabs){
        this.currentPrescriptionItems = this.consultLabs;
        this.currentPrescriptionItems.forEach((lab, i) => {   
          const allLabsIndex = this.laboratories.findIndex((selectedLab) => selectedLab.labName === lab.labName);
          // Lab not included, so add it
          // let tempDiagnostic = this.consult.diagnostics.filter(d => this.laboratories[allLabsIndex].icd10.includes(d.code));
          this.currentPrescriptionItems[i].diagnostics = []; 
          this.laboratories[allLabsIndex].diagnostics = this.consult.diagnostics;
        })
      }
      
      this.modalRef = this.modalService.open(modal, {size: 'xl', backdrop: 'static', windowClass: "newPrescription", centered: true });
    }
    
    async editManual(){
      this.isEdit = true;
      if(this.isLab){
          this.prescriptionID = this.prescriptionRef.ID;
          this.currentPrescriptionItems.forEach((lab, i) => {
            const allLabsIndex = this.laboratories.findIndex((selectedLab) => selectedLab.labName === lab.labName);
            // Lab not included, so add it
            // let tempDiagnostic = lab.diagnostics.filter(d => this.laboratories[allLabsIndex].icd10.includes(d.code));
            if(allLabsIndex != -1){
              this.laboratories[allLabsIndex].diagnostics = lab.diagnostics;
            }
          })
          this.isLab = true;
          this.modalRef = this.modalService.open(this.addLab, {size: 'xl', backdrop: 'static', centered: true });
      }
    }
  
    calculateAge(){
      var date = new Date(this.patient.birthdate);
      var today = new Date();
      var timeDiff = Math.abs(today.getTime() - date.getTime());
      var age1 = Math.floor(timeDiff / (1000 * 3600 * 24)) / 365.25;
      var age = Math.floor(age1);
      return age;
    }
  
    dismissSignModal(){
      if(this.signModalRef){
        this.signModalRef.dismiss()
      }
    }

    dismissManualProductModal(){
      if(this.modalRef){
        this.modalRef.dismiss();
        this.modalRef.close();
      }
    }
  
    dismiss(){
      if(this.modalRef){
        this.modalRef.dismiss();
        this.modalRef.close();
      }
  
      if(this.signModalRef) {
        this.signModalRef.dismiss()
      }
      
      this.justifyLetterAttached = false;
      this.currentPrescriptionItems = [];
      this.isLab = false;
      this.interactions = [];
      this.rxInstruction = undefined;
      this.refillQty = undefined;
      this.isEdit = false;
      this.prescriptionRef = ''

      this.modalRef = undefined;
      this.modalService.dismissAll();
    }
  
    deletePrescriptionItem(id){
      this.afs.collection('prescription').doc(this.prescriptionRef.ID).collection("Items").doc(id).delete()
    }
  
    goToPrescription(url, id){
      if(!this.prevConsult){
        let fullUrl = this.route.url.split("/prescription")[0];
        this.route.navigate([fullUrl+"/"+url, id])
      }    
    }
  
    getConsultAddress(){
      // this.afs.firestore.collection('consultation').doc(this.consult.ID).collection("Address").doc('Shipping').get().then(address => {     
      //   if(address?.data().AddressLine2 === null || address?.data().AddressLine2 === undefined){
      //     address.data().AddressLine2 = "";
      //     this.shippingAddress = address.data();
      //   }else{
      //     this.shippingAddress = address.data();
      //   }
      // });

      this.afs.firestore.collection('users').doc(this.patient.uid).collection("Address")
      .where("AddressType", "==", "Shipping")
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const address = querySnapshot.docs[0];
          if (address.data().AddressLine2 === null || address.data().AddressLine2 === undefined) {
            address.data().AddressLine2 = "";
          }
          this.shippingAddress = address.data();
        }
      });

    }

    async storeSignature(signature){
      this.loadingMessage = "RELOAD.GENERATING";
      this.signature = signature;
      
      this.authenticate().then(async(response:any) => {
        if(response !== null){
          this.loading = true;
          if(!this.isLab){
            if(this.prescriptionRef.internal == true){
              await this.wishlistHandling()

              let document = await this.pdfService.generatePdf(this.prescriptionRef, signature);

              if(document == 'An error has occured.' || document.data.pdf == 'No callable function sent.'){
                Swal.fire({
                  customClass: {
                    confirmButton: 'swalPurple',
                  },
                  title: 'An error has occured!',
                  icon: 'warning',
                  text: 'Please try again or contact administration.',
                  confirmButtonText: 'Ok'
                }).then(res => {
                  window.location.reload()
                })
    
                this.loading = false;
                return
              }

              this.base64 = document.data.pdf.z_processedB64 ? document.data.pdf.z_processedB64 : document.data.pdf.z_base64
              
              let index = this.prescriptions.findIndex(p => p.ID == document.data.pdf.ID);
              this.prescriptions[index] = document.data.pdf;
              this.loading = false;   
              this.prescriptionRef = document.data.pdf

              let sub = this.afs.collection("prescription").doc(this.prescriptionRef.ID).valueChanges().subscribe(async (presc:any) => {
                this.prescriptionRef = presc;
                let index = this.prescriptions.findIndex(presc => presc.ID == this.prescriptionRef.ID);
                if(index != -1 ){
                  this.prescriptions[index] = presc;
                }
                if(presc.signedDate){
                  sub.unsubscribe();
                }
              })
            }
          }else{
            await this.wishlistHandling();

            let doc = await this.pdfService.generatePdf(this.prescriptionRef, signature, this.justifyLetterAttached, this.referralText);
            
            if(doc == 'An error has occured.' || doc.data.pdf == 'No callable function sent.'){
              Swal.fire({
                customClass: {
                  confirmButton: 'swalPurple',
                },
                title: 'An error has occured!',
                icon: 'warning',
                text: 'Please try again or contact administration.',
                confirmButtonText: 'Ok'
              }).then(res => {
                window.location.reload()
              })
              
              this.loading = false
              return
            }
            
            let index = this.prescriptions.findIndex(prescs => prescs.ID == doc.data.pdf.ID);
            if(index != -1 ){
              this.prescriptions[index] = Object.assign(this.prescriptions[index], doc.data.pdf)
              this.prescriptionRef = this.prescriptions[index]
              this.base64 = this.prescriptionRef.z_processedB64 ? this.prescriptionRef.z_processedB64 : this.prescriptionRef.z_base64
            }
            //send Lab available warning to patient
            this.emailService.patientConsultEmails(Object.assign(this.practitioner, {displayName: this.practitioner.firstName + ' ' + this.practitioner.lastName1}), this.patient, this.consult, 'labSubmission_' + this.patient.preferedLang, '<en>Lab Test</en><es>Prueba de Laboratorio</es>', this.settings, '/consultations?consultation=' + this.consult.ID);
            this.loading = false;
          }
          this.signModalRef.dismiss()
        }
      })
    }
  
    async signPad(){
      if(this.consult.diagnostics.length == 0 && this.prescriptionRef.prescription_type == 'Rx'){
        this.translate.get('CONSULT-PRESCRIPTIONS.SWAL').subscribe((res: any) => {
          this.swal = res
        })
        Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          title: this.swal.MISSINGDIAGNOSTIC,
          text: this.swal.ADDDIAGNOSTICMSG,
          showCancelButton: false,
          confirmButtonText: this.swal.ADDDIAGNOSTIC,
          allowEnterKey: false,
          allowEscapeKey: false
        }).then(res => {
            this.dismiss()
            this.route.navigate([`/consults/${this.consult.ID}/diagnosticsAndReview`])
        })
      }
      else {
        this.signModalRef = this.modalService.open(this.signaturePadModal, {size: 'lg', backdrop: 'static', centered: true, windowClass:'signPad' });
      }
    }

    authenticate(){
      return new Promise((resolve, reject) => {
        this.translate.get('VIEWCONSULTS.SWAL').subscribe((res: any) => {
          this.swal = res
        })
        Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
            cancelButton: 'btn-light'
          },
          title: this.swal.AUTHENTICATE,
          text: this.swal.GOOGLEAUTH,
          showCancelButton: true,
          confirmButtonText: this.swal.VALIDATE,
          cancelButtonText: this.swal.CANCEL,
          input: 'text',
          inputAttributes:{
            maxlength:"6"
          },
          showLoaderOnConfirm:true,
          preConfirm: (value) => {
            this.loading = true;
            this.authenticatorService.validate(this.user.uid, value).then(v => {
              
              if(v == null){
                this.loading = false;
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'center',
                  showConfirmButton: false,
                  timer: 1000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })
                this.translate.get('VIEWCONSULTS.SWAL').subscribe((res: any) => {
                  this.swal = res.INVALIDTOKEN
                })
                
                Toast.fire({
                  icon: 'error',
                  title: this.swal
                }).then(() => {
                  this.storeSignature(this.signature)
                })
              }else{
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })
                this.translate.get('VIEWCONSULTS.SWAL').subscribe((res: any) => {
                  this.swal = res.VALIDTOKEN
                })
                Toast.fire({
                  icon: 'success',
                  title: this.swal
                })
                this.afs.collection("prescription").doc(this.prescriptionRef.ID).update({
                  authenticatorToken: value,
                  authenticatorValidationDate: new Date(),
                  authenticatorStaus: "valid",
                  authenticatorResponse: v
                })
                this.loading = false;
                // this.modalRef.dismiss();                                                  
                resolve(v)
              }
            })
        }
      })
      .then(async res => {
        if(res.isConfirmed === true){
          this.loading = true;
        }
        else if(res.isDenied === false){
            this.dismiss();
          }
        })
      })
    }

  //Everithing of shop component
  allProducts:Product[] = [];
  patientProduct:any = [];

  //product build
  productGroups: any = [{id:0, required:true}];
  finalProductObj:any = {};
  groupCounter:number = 0;
  productFilter: any[] = [];
  finalProductTest:any = [{ productName:''}];
  productRemaining:Product[] = [] //finalProductTest with all atributes
  atributeCounter:number = 1;

  change:boolean = false;

  updateProduct(product){
    product.productId = product.productID;
    this.manageProduct(product);
  }

  async manageProduct(product: Product){
    let promise = new Promise(async (resolve, reject) => {
      let frequency = await this.setShipmentFrequency(product);

      if(this.consult.productAutoPayment != undefined){
        frequency.automaticPayment = this.consult.productAutoPayment;
      }

      if(this.productGroups.canChooseMultipleProducts == false){
        for(let i = 0; i < this.currentPrescriptionItems.length; i++){
          if(this.currentPrescriptionItems[i].productID !== product.productId){
            this.afs.collection("prescription").doc(this.prescriptionRef.ID).collection("Items").doc(this.currentPrescriptionItems[i].productID.toString()).delete();
          }
        }
      }

      this.currentPrescriptionItems = this.currentPrescriptionItems.length > 0 ? this.currentPrescriptionItems.filter(p => p.productID.toString() !== product.productId.toString()) : [];
      const { productId, productName, productPrice, productQty, 
          // packID, isPack, 
          productRxInstruction, productRefillQty, productRxcui, 
          // prescriptionMinQty, 
          // prescriptionMaxQty, 
          ...other } = product;

          let addDosage = productName.includes(product.productDosage + ' ' + product.productDosageForm) ? false : true;

          console.log(addDosage)
        let item: BaseProductPrescription = {
          id: uuidv4(),
          image: product.productImg || "",
          productID: productId.toString(),
          productName: productName + (addDosage ? ' ' + product.productDosage + ' ' + product.productDosageForm : ''),
          productPrice: productPrice,
          productQty: productQty,
          recurrency_order: false,
          type: product.productType,
          prescriptionID: this.prescriptionRef.ID,
          rxcui: productRxcui,
          packID: null, //Verify if Erick needs this
          isPack:  null, //Verify if Erick needs this
          uom: 'Units', //Verify if Erick needs this
          rxInstruction: this.rxInstruction ? this.rxInstruction : productRxInstruction,
          refillQty: this.refillQty ? this.refillQty : productRefillQty,
          treatment: !product.productOTC,
          refillIntervalDays: product.productRefillIntervalDays,
          deliveryFrequencyInDays: frequency.frequency,
          rxcuiName: product.productRxcui ? product.productRxcui : productName,
          refillMainQty: productRefillQty,
          minRefillQty: product.productRefillMinQty,
          daw:0,
          automaticPayment: frequency.automaticPayment,
        }
        
        item = Object.assign(item, other);
        this.afs.collection("prescription").doc(this.prescriptionRef.ID).collection("Items").doc(item.productID.toString()).set(item);
        let prescIndex = this.prescriptions.findIndex(p => p.ID == this.prescriptionRef.ID);
        if(this.productGroups.canChooseMultipleProducts == true){
          if(this.currentPrescriptionItems.length > 0){
            this.currentPrescriptionItems.push(item)
          }
          else {
            this.currentPrescriptionItems = [item];
          }

          this.prescriptionsItems[prescIndex] = this.currentPrescriptionItems;           
        }
        else {
          this.prescriptionsItems[prescIndex] = [item];
          this.currentPrescriptionItems = [item];  
        }

        this.dismissManualProductModal()
        setTimeout(() => {
          resolve('done')
        }, 1000)
    })
    return promise;
  }

  removeProduct(product){
    this.translate.get('CONSULT-PRESCRIPTIONS.SWAL').subscribe((res: any) => {
      this.swal = res
    })
    Swal.fire({
      customClass: {
        confirmButton: 'swalPurple',
        cancelButton: 'btn-light'
      },
      title: this.swal.AREYOUSURE,
      text: this.swal.REMOVEPRODUCT,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.swal.REMOVECONFIRM,
      cancelButtonText: this.swal.CANCEL
    }).then((result) => {
      if (result.isConfirmed) {
        if(this.currentPrescriptionItems.length > 0){
          this.currentPrescriptionItems = this.currentPrescriptionItems.filter(p => p.productID !== product.productID);
        }

        this.afs.collection("prescription").doc(this.prescriptionRef.ID).collection("Items").doc(product.productID).delete();
        let prescIndex = this.prescriptions.findIndex(p => p.ID == this.prescriptionRef.ID);
                let prodIndex = this.prescriptionsItems[prescIndex].findIndex(p => p.productID == product.productID)
        this.prescriptionsItems[prescIndex].splice(prodIndex, 1)
      }
    })
  }

  handleSection(){
    let coll = document.getElementsByClassName("collapsible");
    let i;
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.display === "block") {
          content.style.display = "none";
        } else {
          content.style.display = "block";
        }
      });
    }
  }

  setup(){
    let obj = {
      Type: this.consult.Type,
    }
    // this.consultationService.getProductsGroups(obj).then(res => {
      this.afs.firestore.collection('consultRules').doc(this.consult.Type).get().then((consultRules: any) => {
      this.productGroups = consultRules.data();
      this.productService.getProducts(this.consult, consultRules.data()).then((products: Product[]) => {
        if(products.length == 0){
          this.change = false;
        }

        if(this.consult.Type == 'Erectile Dysfunction'){
          products = products.filter(product => product.productIsVisible); //Removes old products that are still active for old prescriptions
        }
        
        this.allProducts = products.filter(product => product.odooProductId !== 980 && product.productStates.some(p => p.state_code == this.consult.state.code)).sort(function (a, b) {
          return a.productName.localeCompare(b.productName, undefined, {numeric: true, sensitivity: 'base'})
        }).sort((a, b) => 
          this.requiredProducts.includes(b.odooProductId) ? 1 : -1
        );
        this.products = products.filter(product => product.odooProductId !== 980
          // && product.id != this.patientProduct[0]?.id
          && product.productStates.some(p => p.state_code == this.consult.state.code)
        ).sort(function (a, b) {
          return a.productName.localeCompare(b.productName, undefined, {numeric: true, sensitivity: 'base'})
        }).sort((a, b) => 
          this.requiredProducts.includes(b.odooProductId) ? 1 : -1
        );
        
        if(this.patientProduct.length > 0 && this.patientProduct[0].qty != undefined && this.patientProduct[0].qty != 0){
          this.products = this.products.filter(p => p.productQty == this.patientProduct[0].qty)
        }
        
        this.productFilter[0] = [...new Map(this.products.map(item => [item['productName'], item])).values()];
      });
    });
  }  

  viewProducts(type){
    if(type == 'all'){
      this.products = this.allProducts.filter(product => product.odooProductId !== 980).sort(function (a, b) {
        return a.productName.localeCompare(b.productName, undefined, {numeric: true, sensitivity: 'base'})
      }).sort((a, b) => 
        this.requiredProducts.includes(b.odooProductId) ? 1 : -1
      );
    }
    else {
      if(this.patientProduct.length > 0){
        this.products = this.products.filter(p => p.productQty == this.patientProduct[0].qty).sort((a, b) => 
          this.requiredProducts.includes(b.odooProductId) ? 1 : -1
        );
      }
    }
  }

  productTemplate = [
    'productName',
    'productDosage',
    'productQty',
    'productRefillIntervalDays'
  ];
  async filterAtributes(event, position){
    let index = this.productTemplate.indexOf(position)
    let atrName = position
    let nextAtrName = this.productTemplate[index + 1]
    let arr = []

    let allProducts = this.allProducts
    
    let currentProducts = []
    for(let j = 0; j < await this.finalProductTest.length - 1; j++){
      let productIndex = this.productTemplate[j]
      if(this.finalProductTest[j][productIndex] != ''){
        for(let i = 0; i < allProducts.length; i++) {
          if(await allProducts[i][productIndex] == await this.finalProductTest[j][productIndex]){
            currentProducts.push(await allProducts[i])
          }
          if(i == allProducts.length - 1){
            allProducts = currentProducts
            currentProducts = []
            if(productIndex == atrName){
              this.products = allProducts
            }
          }
        }
      }
      else {
        break;
      }
    }
    
    if(index != this.atributeCounter - 1){
      this.atributeCounter = index + 1
      this.finalProductTest.splice(index + 1)
      this.productFilter.splice(index + 1)
    }
    this.finalProductTest[index] = {[atrName]:event}

    if(index == 0){
      this.finalProductTest[index].productBrandName = this.products[0].productBrandName
    }

    if(nextAtrName != undefined){
      this.finalProductTest[index + 1] = {[nextAtrName]: ''}
    }

    if(!this.productFilter){
      this.productFilter = []
    }

    this.productFilter[index + 1] = []
    this.products.forEach(prod => {
      if(prod[atrName] == event){  
        arr.push(prod);
        var copy = arr.slice(0);
        copy.sort((a, b) => {
          return a.sequence - b.sequence;
        });    

        this.productFilter[index + 1] = [...new Map(copy.map(item => [item[nextAtrName], item])).values()];
      }
    });

    if((nextAtrName == undefined && this.finalProductTest.length == this.productTemplate.length) || this.productFilter[index + 1].length == 0){
      this.buildRemainingProduct(index)
    }
    else {
      this.atributeCounter++

      let allProducts = this.products
      let currentProducts = []

      for(let j = 0; j < this.finalProductTest.length - 1; j++){
        for(let i = 0; i < allProducts.length; i++) {
          let productIndex = this.productTemplate[j]
          if(allProducts[i][productIndex] == this.finalProductTest[j][productIndex]){
            currentProducts.push(allProducts[i])
          }
          if(i == allProducts.length - 1){
            allProducts = currentProducts
            this.products = currentProducts
            currentProducts = []
          }
        }
      }
    }

    //new
    if(nextAtrName == 'productRefillIntervalDays' && this.productFilter[3][0]){
      this.filterAtributes(this.productFilter[3][0].productRefillIntervalDays, 'productRefillIntervalDays')
    }
    //end new
  }

  buildRemainingProduct(position){
    this.productRemaining = this.allProducts;
    for(let i = 0; i <= position; i++){
      this.productRemaining = this.productRemaining.filter(p => p[this.productTemplate[i]] == this.finalProductTest[i][this.productTemplate[i]])
      this.refillQty = this.productRemaining.length > 0 ? this.productRemaining[0].productRefillQty : 0;
    }
  }

  refillCounter(product){
    let refill = [];
    let qty = product.productRefillQty || product.refillMainQty;

    // if(this.consult.Type != 'Erectile Dysfunction' || this.consult.totalPoints > 0){
      for(let i = product.productRefillMinQty; i <= qty; i++){
        refill.push(i)
      }
    // }
    // else {
    //   refill.push(qty)
    // }
    return refill
  }

  async getInteractionsAnalysis(){
    this.afs.collection("consultation").doc(this.consult.ID).collection("Interactions Analysis").valueChanges().subscribe(async interactions => {
      this.interactions = await interactions
    });
  }

  async getPatientSelectedItems(){
    this.afs.firestore.collection("consultation").doc(this.consult.ID).collection("wishlist").get().then(async patientSelectedItems => {
      if(patientSelectedItems){
        let test = patientSelectedItems.docs.map(doc => doc.data());
        test = test.filter(item => item.type === 'product' && item.id != 980);
        this.patientProduct = [];
        test.forEach(p => {
          this.patientProduct.push(p)
          this.wishlist.push(p)
        });

        if(this.wishlist.length == 0){
          this.afs.firestore.collection("consultation").doc(this.consult.ID).collection("Items").get().then(async items => {
            this.service = items.docs.map(doc => doc.data()).filter(item => item.productPromo && item.productPromo.length > 0);

            if(this.service && this.service.length > 0 && this.service[0].productRequired){
              this.requiredProducts = this.service[0].productRequired;
            }
            if(this.service.length > 0){
              this.patientProduct = [{qty: 0}]
              this.patientProduct[0].qty = this.service[0].tabletQty
            }
          })
        }
      }

      this.setup();
    });
  }

  verifyPatient(){
    if(this.patient && this.patient.status != 'Verified'){
      this.translate.get('CONSULT-PRESCRIPTIONS.SWAL').subscribe((res: any) => {
        this.swal = res
      })
      Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
        },
        title: this.swal.WARNING,
        icon: "warning",
        text: this.swal.VERIFYPROFILE,
        showCancelButton: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        confirmButtonText: this.swal.GOTOPROFILE,
      }).then(res => {
        this.route.navigate([`/consults/${this.consult.ID}/others/profile`])
      })
    }
  }

  setChecked(product){
    let selectedProduct = this.currentPrescriptionItems ? this.currentPrescriptionItems.filter(p => p.productID == product.productId) : []
    return selectedProduct.length != 0
  }

  setLabChecked(product){
    let selectedProduct = this.currentPrescriptionItems ? this.currentPrescriptionItems.filter(p => p.labName == product.labName) : []
    return selectedProduct.length != 0
  }

  async generatePrescription(){
    let promise = new Promise(async (resolve, reject) => {

      const prescription = new Prescription(this.afs, this.firebaseService);
      prescription.consult = await this.consult;
      prescription.profile = await this.patient;
      prescription.shipping = await this.shippingAddress ? this.shippingAddress : "";
      prescription.deliveryOrder = true;
      prescription.practitioner = await this.authService.getUserProfile(this.consult.UID_Practitioner);
      prescription.NPI = await this.NPIRegistry;
      prescription.docAdd = this.practitionerAddress;

      await prescription.generateOriginalPrescription().then(async res => {
        this.change = true;
        this.prescriptionRef = res
        await this.getPrescriptions();
        resolve(res)
      });
    })

    return promise;
  }

  async createPrescriptionBasedOnPatientSelection(product){
    await this.generatePrescription().then(async () => {
      let filterProduct = this.allProducts.find(p => p.productId == product.productId);
      await this.manageProduct(filterProduct).then(async () => {
        this.change = false;
        await this.getPrescriptions().then(() => {
          this.getPrescriptionsItems(this.prescriptionRef); 
          this.goToPrescription('prescription', this.prescriptionRef.ID); 
  
          this.openPrescriptionPDF(this.prescriptionRef)
        });
      });
    });
  }

  async sendInternal($event){
  // async sendInternal(continueSubscriptions){
    // if(this.patientSubscriptions.length == 0 || continueSubscriptions){
      // let btn = document.getElementById('send-internal') as HTMLButtonElement;
      const id = $event.target.id;
      let btn = document.getElementById(id) as HTMLButtonElement;
      btn.disabled = true;
      btn.innerHTML = "<span class='spinner spinner-border'></span>";
      this.afs.collection("prescription").doc(this.prescriptionRef.ID).update({pharmacy: ''}); //If an error occures and the button needs to be clicked again update the pharmacy to empty
      if(this.prescriptionRef.modified == "true"){
        this.afs.collection("prescription").doc(this.prescriptionRef.ID).update({automaticPayment:false, pharmacy: "Dzeus"});
      }else if(this.wishlist && this.wishlist[0]){
        this.afs.collection("prescription").doc(this.prescriptionRef.ID).update({automaticPayment:this.wishlist[0].automaticPayment, pharmacy: "Dzeus"});
      }
      else {
        this.afs.collection("prescription").doc(this.prescriptionRef.ID).update({automaticPayment: false, pharmacy: "Dzeus"});
      }
      
      let index = this.prescriptions.findIndex(presc => presc.ID == this.prescriptionRef.ID);
      if(index != -1 ){
        this.prescriptions[index] = Object.assign(this.prescriptions[index], {pharmacy: "Dzeus"})
        this.prescriptionRef = this.prescriptions[index]
      }
      this.sentPrescriptionSuccess = true;
  
      let sub = this.afs.collection("prescription").doc(this.prescriptionRef.ID).valueChanges().subscribe(async (presc:any) => {
        this.prescriptionRef = presc;
        let index = this.prescriptions.findIndex(presc => presc.ID == this.prescriptionRef.ID);
        if(index != -1 ){
          this.prescriptions[index] = presc;
        }
        if(presc.erpPrescriptionRef){
          sub.unsubscribe();
        }
      })
    // }
    // else {
    //   // handle subscriptions Modal
    //   this.showMedication()
    //   this.openModal(this.handlePrevPrescriptionsModal);
    // }
  }

  async sendExternal($event){
    this.modalRef = this.modalService.open(this.pharmacyModal,{size: "md", backdrop: 'static', windowClass: "pharmacyModal"});
  }

  sending = false;
  pharmacy:ExternalPharmacy = {
    email: "",
    location: ""
  };
  async sendToPharmacy(){
    this.sending = true
    try{
      let pdf = await this.pdfService.generateEncryptedPrescription(this.prescriptionRef).then(async res => {
        if(res == 'An error has occured.' || res.data.pdf == 'No callable function sent.'){
          Swal.fire({
            customClass: {
              confirmButton: 'swalPurple',
            },
            title: 'An error has occured!',
            icon: 'warning',
            text: 'Please try again or contact administration.',
            confirmButtonText: 'Ok'
          }).then(res => {
            window.location.reload()
          })

          return
        }
        let currentPrescription = this.prescriptions.find(x => x.ID == this.prescriptionRef.ID);
        currentPrescription.externalPDFCode = res.data.pdf.externalPDFCode;
        currentPrescription.prescriptionExternalPharmacyURL = res.data.pdf.prescriptionExternalPharmacyURL;
        await this.emailService.externalEncryptedEmail(this.pharmacy, this.patient, currentPrescription);
        this.sending = false;
        this.afs.collection('prescription').doc(currentPrescription.ID).update({pharmacy: 'External Pharmacy', externalPharmacyLocation: this.pharmacy.location, externalPharmacyEmail: this.pharmacy.email})
        this.sentPrescriptionSuccess = true;
        let index = this.prescriptions.findIndex(presc => presc.ID == this.prescriptionRef.ID);
        if(index != -1 ){
          this.prescriptions[index] = Object.assign(this.prescriptions[index], {pharmacy: "External Pharmacy", externalPharmacyLocation: this.pharmacy.location, externalPharmacyEmail: this.pharmacy.email})
          this.prescriptionRef = this.prescriptions[index]
        }
        this.modalRef.dismiss();
      });
    }
    catch(err) {
      this.sending = false;
    }
  }

  sendLaboratory(){
    this.pdfService.generateEncryptedPrescription(this.prescriptionRef).then(async res => {
      if(res == 'An error has occured.' || res.data.pdf == 'No callable function sent.'){
        Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          title: 'An error has occured!',
          icon: 'warning',
          text: 'Please try again or contact administration.',
          confirmButtonText: 'Ok'
        }).then(res => {
          window.location.reload()
        })

        return
      }
      let currentPrescription = this.prescriptions.find(x => x.ID == this.prescriptionRef.ID);
      currentPrescription.externalPDFCode = res.data.pdf.externalPDFCode;
      let prescName = 'prescriptionExternalLabURL';
      currentPrescription[prescName] = res.data.pdf[prescName];
      let sendLab = this.selectedLaboratory.email ? true : false;
      this.afs.collection('prescription').doc(currentPrescription.ID).update({sentLab: sendLab, laboratoryDetails: this.selectedLaboratory})
      if(sendLab){
        await this.emailService.externalEncryptedEmail(this.selectedLaboratory, await this.patient, this.prescriptionRef);
        let user = {
          displayName: 'Dzeus Support',
          email: 'smtp@dzeus.com',
          uid: ''
        }
        this.emailService.patientConsultEmails(user, this.patient, this.consult, 'labSubmission_' + this.patient.preferedLang, '<en>Lab Test</en><es>Prueba de Laboratorio</es>', this.settings, '/consultations?consultation=' + this.consult.ID)
      }
      else {
        let user = {
          firstName: 'Melanie Vazquez',
          lastName1: '',
          email: testProgrammerAccounts[0],
          uid: '',
          preferredLang: 'en'
        }
        this.emailService.generalEmails(user, 'labVerification_en', '', this.settings, '/admin-laboratories')
      }

      let index = this.prescriptions.findIndex(presc => presc.ID == this.prescriptionRef.ID);
      if(index != -1 ){
        this.prescriptions[index] = Object.assign(this.prescriptions[index], {sentLab: sendLab, laboratoryDetails: this.selectedLaboratory})
        this.prescriptionRef = this.prescriptions[index]
      }

      this.sending = false;
      setTimeout(() => {
        this.sentPrescriptionSuccess = true;
      }, 1500)
      this.modalRef.dismiss();
    });
  }

  disableSend(){
    const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return (this.pharmacy.email && this.pharmacy.location && emailRegex.test(this.pharmacy.email)) ? false : true;
  }

  showPreviewLG($event){
    const id = $event.target.id;
    let element = document.getElementById(id);
    // element.classList.add("slide-out-bck-right");
    this.showPreview = true;
  }

  closePrescription(){
    if(this.modalRef){
      this.modalRef.close();
    }
    this.modalService.dismissAll()
    this.modalRef = undefined;
  }

  async prescriptionCurrentItems(){
    let index = this.prescriptions.findIndex(p => p.ID == this.prescriptionRef.ID);
    this.currentPrescriptionItems = this.prescriptionsItems[index];
    if(this.isLab) {
      this.editManual()
    }
  }

  wishlistHandling(){
    return new Promise((resolve, reject) => {
      if(this.wishlist.length > 0){
        if(this.productGroups.canChooseMultipleProducts){
          let arr:any[] = [];
          let equal = this.wishlist.map(list => {
  
            this.currentPrescriptionItems.forEach(p => {
              if(list.id == p.productID){
                arr.push(p);
              }
            });
            arr = [...new Map(arr.map((item, key) => [item['productId'], item])).values()]
            return arr[0];
          });
          equal = arr;

          //equal.length == wishlist.length validates that all the products in the wishlist are in the prescription
          //currentPrescriptionItems.length == wishlist.length validates that all the products in the prescription and the wishlist are the same. In other words, doesnt contain any other extra product.
          if(equal.length == this.wishlist.length && this.currentPrescriptionItems.length == this.wishlist.length){
            this.afs.collection('prescription').doc(this.prescriptionRef.ID).update({
              modified: "false",
              automaticPayment: this.wishlist[0].automaticPayment,
              newPrescription: this.wishlist[0]['odooProductId'] ? true : false
            });
            let index = this.prescriptions.findIndex(prescs => prescs.ID == this.prescriptionRef.ID);
            if(index != -1 ){
              this.prescriptions[index] = Object.assign(this.prescriptions[index], {modified: "false", automaticPayment: this.wishlist[0].automaticPayment, newPrescription: this.wishlist[0]['odooProductId'] ? true : false})
              this.prescriptionRef = this.prescriptions[index]
            }

            resolve('done')
          }else{
            this.afs.collection('prescription').doc(this.prescriptionRef.ID).update({
              modified: "true",
              patientSignature: "",
              patientSignedDate: "",
              patientSignRef: "",
              automaticPayment: false,
              newPrescription: true
            }).then(() => {});
            let index = this.prescriptions.findIndex(prescs => prescs.ID == this.prescriptionRef.ID);
            if(index != -1 ){
              this.prescriptions[index] = Object.assign(this.prescriptions[index], {modified: "true", automaticPayment: false, patientSignature: "", patientSignedDate: "", patientSignRef: "", newPrescription: true})
              this.prescriptionRef = this.prescriptions[index]
            }
            resolve('done')
          }
        }else{
          let item = this.currentPrescriptionItems.find(p => p.productID.toString() == this.wishlist[0].id.toString());
          if(item !== undefined){
            this.afs.collection('prescription').doc(this.prescriptionRef.ID).update({
              modified: "false",
              automaticPayment: this.wishlist[0].automaticPayment,
              newPrescription: this.wishlist[0]['odooProductId'] ? true : false
            });
            let index = this.prescriptions.findIndex(prescs => prescs.ID == this.prescriptionRef.ID);
            if(index != -1 ){
              this.prescriptions[index] = Object.assign(this.prescriptions[index], {modified: "false", automaticPayment: this.wishlist[0].automaticPayment, newPrescription: this.wishlist[0]['odooProductId'] ? true : false})
              this.prescriptionRef = this.prescriptions[index]
            }
            resolve('done')
          }else{
            this.afs.collection('prescription').doc(this.prescriptionRef.ID).update({
              modified: "true",
              patientSignature: "",
              patientSignedDate: "",
              patientSignRef: "",
              automaticPayment: false,
              newPrescription: true
            }).then(() => {});
            let index = this.prescriptions.findIndex(prescs => prescs.ID == this.prescriptionRef.ID);
            if(index != -1 ){
              this.prescriptions[index] = Object.assign(this.prescriptions[index], {modified: "true", automaticPayment: false, patientSignature: "", patientSignedDate: "", patientSignRef: "", newPrescription: true})
              this.prescriptionRef = this.prescriptions[index]
            }
            resolve('done')
          }
        }
      }else{
        this.afs.collection('prescription').doc(this.prescriptionRef.ID).update({
          modified: "null",
          automaticPayment: this.prescriptionRef.patientSignature ? true : false,
          newPrescription: true
        });
        let index = this.prescriptions.findIndex(prescs => prescs.ID == this.prescriptionRef.ID);
        if(index != -1 ){
          this.prescriptions[index] = Object.assign(this.prescriptions[index], {modified: "null", automaticPayment: this.prescriptionRef.patientSignature ? true : false, newPrescription: true})
          this.prescriptionRef = this.prescriptions[index]
        }
        resolve('done')
      }
    });
  }

  openSummary(){
    this.modalService.open(this.prescriptionSummaryModal, {size: 'md', centered: true, backdrop: 'static', windowClass: 'summary-modal'});
  }

  referralText:string;
  collectMessage($event){
    this.referralText = $event.target.value
  }

  justifyLetterAttached:boolean = false;
  createJustificationLetter(){
    this.authService.getNPIRegistry(this.prescriptionRef.docId).then(async res => {
      this.prescriptionRef.credential = res.basic.credential || '';
      
      let labs = '';
      let index = await this.prescriptions.findIndex(p => p.ID == this.prescriptionRef.ID);
      this.prescriptionsItems[index].forEach(item => {
        let diagnostics = item.diagnostics.map(diag => {
          return diag.code
        }).join(", ")
        labs += '- ' + item.labName + `${item.labCode ? '(' + item.labCode + ')' : ''} ${diagnostics ? '- ' + diagnostics : ''}\n`
      })

      //DO NOT CHANGE THE FORMAT OF THIS TEMPLATE
      let template = `To Whom It May Concern,
  
I am writing to you as ${this.prescriptionRef.credential}, in order to provide medical justification for coverage of Sexually Transmitted Diseases (STI/STD) laboratory testing for my patient, ${this.prescriptionRef.name}.
  
${this.prescriptionRef.name} is a patient under my care and has consulted with me due to health concerns related to possible Sexually Transmitted Infections (STIs). Based on my medical evaluation and ${this.prescriptionRef.name}'s clinical history, I have recommended the following laboratory tests to rule out or confirm the presence of STIs:
  
${labs}\nTherefore, I kindly request that you consider approving coverage of STI/STD lab testing for ${this.prescriptionRef.name} under your health insurance plan. This will ensure that ${this.prescriptionRef.name} receives the necessary medical care and is able to maintain his or her sexual health and general well-being.
  
I appreciate your prompt attention to this matter and your consideration of ${this.prescriptionRef.name}'s health and well-being. If you require any additional medical information or documentation regarding this patient's case, please do not hesitate to contact me directly at ${this.settings.practitionerPhoneNumber} or ${this.prescriptionRef.docEmail}.
  
Thank you for your dedication to the health and welfare of individuals seeking medical coverage.`

      this.referralText = template;
    });
  }

  deleteLabDiagnostic(lab, index){
    let labIndex = this.currentPrescriptionItems.findIndex(x => x.labName == lab.labName);

    this.currentPrescriptionItems[labIndex].diagnostics.splice(index, 1);
    // this.afs.collection("consultation").doc(this.consultID).collection('Diagnostics').doc(lab.id).delete();
  }

  addLabDiagnostic(diagnostic){
    let labIndex = this.currentPrescriptionItems.findIndex(x => x.labName == this.labModifying.labName);
    let diagnosticIndex = this.currentPrescriptionItems[labIndex].diagnostics.findIndex(x => x.id == diagnostic.id);

    if(diagnosticIndex == -1){
      let obj = Object.assign({date: new Date(Date.now())}, diagnostic);
      this.currentPrescriptionItems[labIndex].diagnostics.push(obj);

      // this.afs.collection("consultation").doc(this.consultID).collection('Diagnostics').doc(diagnostic.id).set(obj);  
    }
    else {
      this.currentPrescriptionItems[labIndex].diagnostics.splice(diagnosticIndex, 1);
     
      // this.afs.collection("consultation").doc(this.consultID).collection('Diagnostics').doc(diagnostic.id).delete();  
    }
  }

  labModifying;
  searchDiagnostics(modal, currentLab, labTemplate){
    this.labModifying = currentLab;
    let included = [];
    this.possible_diagnostics = [];

    this.possible_diagnostics.push(...this.consult.diagnostics); 
    
    this.labsService.getDiagnosticTemplates().subscribe(res => {  
      included = res.map(x => labTemplate.icd10.includes(x.code) ? x : null).filter(x => x != null);
      
      this.possible_diagnostics.push(...included)
      this.possible_diagnostics = Array.from(
        this.possible_diagnostics.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()
      ); //Removes duplicates in diagnostics and templates?
    });
    
    this.modalRef = this.modalService.open(modal, {size: 'xl', centered: true, backdrop: 'static'}); 
  }

  setDiagnosticChecked(diagnostic){
    return this.labModifying.diagnostics.some(d => d.id === diagnostic.id)
  }

  //! Check if product in wishlist, if true => get the refillIntervalDays from the wishlist else search for default
  async setShipmentFrequency(product){
    let wishlist_product = this.wishlist.find(p => p.id.toString() == product.productId.toString());

    let frequencies;
    let defaultFrequency;

    if(!wishlist_product || !wishlist_product?.deliveryFrequenciesInDays){
      // frequencies = await this.productService.getProductDeliveryWishlistFrequency([product])
      frequencies = await product.productDeliveryFrequencies
      defaultFrequency = frequencies.filter(f => f.defaultFrequency == true)[0];
    }

    return wishlist_product?.deliveryFrequencyInDays != undefined ? {frequency: wishlist_product.deliveryFrequencyInDays, automaticPayment: wishlist_product.automaticPayment} : {frequency: defaultFrequency.frequency, automaticPayment: defaultFrequency.autoPayment};
  }

  loadingSearch:boolean = false;
  filteredDiagnostics:any[] = [];
  getDiagnostics($event){
    this.loadingSearch = true;
    this.http.get<any>("https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms="+$event.term).subscribe(response =>{
      for(let i = 0; i < 15; i++){
        this.filteredDiagnostics = response[3];
        this.loadingSearch = false;
      }
    });
  }

  setDiagnostic(diagnostic){
    let possible_diagnostic: any = {
      id: `${diagnostic[0]} - ${diagnostic[1]}`,
      code: diagnostic[0],
      name: diagnostic[1],
      code_name: `${diagnostic[0]} - ${diagnostic[1]}`,
      assessment: '',
      date: new Date(Date.now()),
      recommendation: ''
    }

    this.possible_diagnostics.push(possible_diagnostic);
    this.addLabDiagnostic(possible_diagnostic);
  }

  selectedMedication:any = [];
  selectMedication(med){
    let index = this.selectedMedication.findIndex(x => x.id == med.id);
    if(index == -1){
      this.selectedMedication.push(med)
    }
    else {
      this.selectedMedication.splice(index, 1)
    }
  }

  checkMedication(med){
    return this.selectedMedication.some(x => x.id == med.id)
  }

  patientActivePrescriptions = [];
  async showMedication(){
    this.patientActivePrescriptions = [];
    for(let i = 0; i < this.patientSubscriptions.length; i++){
      let med = this.patientSubscriptions[i];
      med.subscriptionID = med.id;
      let index = this.all_prescriptions.findIndex(p => p.ID == med.prescriptionID);
      let product = await this.all_prescriptions_items[index] ? this.all_prescriptions_items[index].filter((tablet:any) => (tablet.odooProductId?.toString() === med.tabletID.toString()) || (tablet.productID?.toString() === med.tabletID.toString()) || (tablet.parentProductId?.toString() === med.tabletID?.toString()))[0] : {};     
      
      if(product){
        let merge = Object.assign(product, med);
        this.patientActivePrescriptions.push(merge)
        console.warn(merge)
      }
    }

  }
  
  removeMedications(){
    for(let i = 0; i < this.selectedMedication.length; i++){
      console.log(this.selectedMedication[i])
      // this.afs.collection('pharmacyOrderSchedule').doc(this.selectedMedication[i].subscriptionID).update({status: 'inactive'})
      
      if(i == this.selectedMedication.length - 1){
        this.modalRef.dismiss();
      }
    }
  }

  onOpen(product, index){

    this.classMap['fixedHeight'] = true;
    let element = document.getElementsByClassName('fixedHeight')[index] as HTMLElement;
    if (element) {
      element.style.height = '240px'; 
    }
    
    this.cdr.detectChanges();

  }

  onSelectClose(){

    this.classMap['fixedHeight'] = false;
    let elements = document.getElementsByClassName('fixedHeight');

    for(let i = 0; i < elements.length; i++){
      let element = document.getElementsByClassName('fixedHeight')[i] as HTMLElement;
      if (element) {
        element.style.height = 'auto';
      }
    }

    this.cdr.detectChanges();

  }

  classMap = {
    'fixedHeight': false
  };
}
<div class="d-flex flex-column customHeight" [ngClass]="{'justify-content-between': styling == 'justify-content-between', 'justify-content-start': styling == 'justify-content-start', 'background-cyan': background == 'cyan'}">
  <div class="form-group" style="width: 90%; margin: 0 5% !important;">
    <i (click)="goBack()" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
  </div>
  <form class="" [formGroup]="signupForm" >
    <h2>{{'PROFILE.QUESTION2' | translate}}</h2>
    <label hidden for="date" class="inp">
      <!-- <input type="date" id="date" placeholder="&nbsp;" name="birthdate" value="{{profile?.birthdate}}" formControlName="birthdate" (blur)="save()" required>        -->
      <input type="date" id="date" placeholder="&nbsp;" name="birthdate" value="{{profile?.birthdate}}" required (blur)="save()">       
      <span class="label">{{'PROFILE.BIRTHDATE' | translate}}</span>
      <span class="focus-bg"></span>
    </label>
  
    <div class="d-flex flex-column w-75 mx-auto" style="max-width: 350px;">
      <div style="width: 200px;" class="mx-auto my-1">
        <p class="m-0">{{'SIGNUP.MONTH' | translate}}</p>
        <ng-select [searchable]="false" id="month" style="position: relative;" (change)="daysRemainingInMonth()" [ngModelOptions]="{standalone: true}" [clearable]="false" (change)="save()" [(ngModel)]="monthSelected">
          <ng-option *ngFor="let day of getMonths()" [value]="day">{{day | titlecase}}</ng-option>
        </ng-select>
      </div>
      <div style="width: 200px;" class="mx-auto my-1">
        <p class="m-0">{{'SIGNUP.DAY' | translate}}</p>
        <ng-select [searchable]="false" id="days" style="position: relative;" [ngModelOptions]="{standalone: true}" [clearable]="false" (change)="save()" [(ngModel)]="daySelected">
          <ng-option *ngFor="let day of getDays()" [value]="day">{{day}}</ng-option>
        </ng-select>
      </div>
      <div style="width: 200px;" class="mx-auto my-1">
        <p class="m-0">{{'SIGNUP.YEAR' | translate}}</p>
        <ng-select [searchable]="false" id="year" style="position: relative;" (change)="daysRemainingInMonth(); save();" [ngModelOptions]="{standalone: true}" [clearable]="false" [(ngModel)]="yearSelected">
          <ng-option *ngFor="let day of getYears()" [value]="day">{{day}}</ng-option>
        </ng-select>
      </div>
    </div>
    <div style="color: red; text-align: center;" class="text-center" *ngIf="f.birthdate.errors && f.birthdate.errors.required">{{'PROFILE.VALIDATIONS.BIRTHDATEREQUIRED' | translate}}</div>
    <div style="color: red; text-align: center;" class="text-center" *ngIf="f.birthdate.errors"> {{'PROFILE.BIRTHDATEMESSAGE' | translate}}</div>
  </form>
  
  <div class="row justify-content-center text-center flex-column mt-5 align-items-center btn-group w-100" style="margin: 0 auto;">
    <button type="button" class="btn btn-info nextbtn text-center mb-2" id="nextBtn" (click)="emit()" [disabled]="formSteps()">{{'PROFILE.NEXT' | translate}}</button>
  </div>
</div>


import { Injectable, OnDestroy } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthfakeauthenticationService } from '../services/authfake.service';
import { environment } from '../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from "firebase/compat/app";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, OnDestroy {
  mfa:any;
  subscription = new Subscription()

  constructor(
    private router: Router,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
  ){
    this.userState()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(environment.defaultauth === 'firebase'){
      let currentUser: any;
      await this.userState().then(user => {currentUser = user});
      if(currentUser && currentUser?.email == null){
        currentUser = firebase.auth().currentUser;
      }

      // not logged in so redirect to login page with the return url
      if(!currentUser){
        if(state.url.includes('/') && !state.url.includes('/account/welcome')){
          localStorage.setItem('returnUrl', state.url)
        }
        
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/account/welcome']);
         return false;
      }

      if(currentUser && currentUser.emailVerified === true){
        return true;
      }else if(currentUser && currentUser.emailVerified === false && currentUser.isAnonymous === false){
        if(!this.router.url.includes('account')){
          this.router.navigate(['account/welcome/register'])
        }          
        return false;
      }
    }
  }

  userState(){
    let promise = new Promise((resolve, reject) => {
     this.afAuth.authState.subscribe(user => {
        resolve(user)
      });
    });
    return promise
  }
}
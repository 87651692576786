<div class="filters" *ngIf="user">
  <!-- <h1>{{'CONSULT-PRESCRIPTIONS.PRODUCTS' | translate}}</h1> -->
  <h1>{{'SHOP.SHOP' | translate}}</h1>

  <div class="filters row ml-0 mr-0">
    <div class="filters col-lg-12 d-flex justify-content-between">
      <div class="mt-2 col-lg-12 w-100 d-flex ">

        <div *ngIf="!checkout && !cart" class="col-lg-12 all-products">
          <h2 class="">{{'CONSULT-PRESCRIPTIONS.ALLPRODUCTS' | translate}}</h2>
          <div class="col-sm-12 col-md-12 d-flex justify-content-end">
            <div id="tickets-table_filter" class="dataTables_filter text-md-right"><label
                for="search" class="d-inline-flex align-items-center">{{'PRACTITIONER-CONSULTS.SEARCH' | translate}}
                <input id="search" type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                  aria-controls="tickets-table" [(ngModel)]="searchText"></label></div>
            <button type="button" class="btn header-item noti-icon waves-effect" style="padding: 0.47rem 0.5rem" (click)="cart = true;">
              <i class="fas fa-shopping-cart icon-color"></i>
              <span *ngIf="patientProducts.length > 0" class="badge badge-pill badge-orange p-0"><p style="margin-top:0.1rem; margin-bottom:0">{{totalProducts}}</p></span>
            </button>
          </div>
          <div class="grid-wrapper">
            <span class="radio-card animate pop" *ngFor="let product of products | filter:searchText" >
              <div class="card-content-wrapper">
                <div class="card-content">
                  <img src="{{product.imgRef}}" alt=""/>
                  <p class="mt-2">{{ product.atr0 | translation:user.preferedLang }}</p>
                  <p>{{ product.atr2 | translation:user.preferedLang }}</p>
                  <p>{{ product.atr3 | translation:user.preferedLang }}</p>
                  <p>{{ product.atr4 | translation:user.preferedLang }}</p>
                  <p>{{ product.atr1}}</p>
                </div>
                <div class="w-100 d-flex justify-content-center align-items-end">
                  <button class="btn btn-info" (click)="manageProduct(product)">{{'SHOP.ADDTOCART' | translate}}</button>
                  <!-- <button class="btn btn-info" (click)="removeProduct(product)">Remove</button> -->
                </div>
              </div>
            </span>
          </div>
        </div>
        <div *ngIf="cart" class="col-lg-12 selection-summary">
          <button type="button" class="close" style="text-align: end; font-size: 30px !important;" aria-label="Close" (click)="cart = false">
            <span aria-hidden="true">&times;</span>
          </button>

          <h2 class="">{{'SHOP.SELECTIONSUMMARY' | translate}}</h2>
          <div class="details">
            <div class="section">
              <div class="collapsible d-flex justify-content-between" (click)="handleSection()">
                <span class="" (click)="handleSection()">{{'ORDERS.ORDERSUMMARY' | translate}}</span>
                <i class="fas fa-chevron-down vertical-align"></i>
              </div>
              <div class="content">
                <div *ngFor="let product of patientProducts" class="d-flex mb-1 center flex-column">
                  <div class="d-flex">
                    <img class="mx-auto" src="{{product.imgRef}}" alt="">
                    <div class="d-flex w-100 justify-content-between center flex-column">
                      <div class="d-flex flex-column">
                        <span class="ml-3 product-name">{{product.name | translation:currentLang}}</span>
                        <span class="ml-3 product-name">{{product.atr1 }}</span>
                        <span class="ml-3 product-name">
                          Qty:
                          <input class="inp" type="number" style="width: 50px;" (change)="updateProduct(product, $event.target.value)" value="{{product.qty}}">
                        </span>
                      </div>
                      <div class="d-flex flex-end">
                        <a class="fit-content" style="text-decoration: underline !important;" (click)="removeProduct(product)">{{'SHOP.REMOVE' | translate}}</a>
                      </div>
                    </div>
                  </div>
                  <hr class="h-auto w-100" style="border: 1px solid #c3c3c7">
                </div>

                <div>
                  {{'SHOP.SUMMARY' | translate}}:
                  <div class="order-info">
                    <div class="order-details-container mb-2 p-4 justify-content-center align-items-center" style="background: #edf2f7; border-radius: 10px;">
                      <div class="row" style="flex-wrap: nowrap;">
                        <p class="desc">{{'CHECKOUT.SUBTOTAL' | translate}}</p>
                        <p class="numbers bold">{{shop?.subtotal | currency:'USD'}}</p>
                      </div>
                      <div class="row" style="flex-wrap: nowrap;">
                        <p class="desc">{{'CHECKOUT.TAX' | translate}}</p>
                        <p class="numbers bold">{{shop?.tax | currency:'USD'}}</p>
                      </div>
                      <div class="row" style="flex-wrap: nowrap;">
                        <p class="desc m-0">{{'CHECKOUT.TOTAL' | translate}}</p>
                        <p class="numbers bold m-0">{{shop?.total | currency:'USD'}}</p>
                      </div>
                    </div>
                  </div>
                  <button class="btn btn-info" (click)="router.url == '/shop'  ? setCheckout() : dismiss()">{{'SHOP.PROCEEDTOCHECKOUT' | translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<app-reload *ngIf="changeLoader" [message]="'RELOAD.DEFAULT'"></app-reload>

<!-- <div *ngIf="checkout">
  <app-checkout></app-checkout>
  <button (click)="checkout = false">hide checkout</button>
</div> -->

<div *ngIf="checkout" class="d-flex flex-column">
  <button type="button" class="close" style="text-align: end; font-size: 30px !important;" aria-label="Close" (click)="checkout = false">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="checkout-display">
    <div class="checkout">
      <h4 class="bold">{{'CHECKOUT.ORDER' | translate}} #{{shop?.orderNo}} {{'CHECKOUT.SUMMARY' | translate}}</h4>
      <div class="my-cart">
        <div *ngIf="imagesLoaded == true">
          <div class="cart-container" *ngFor="let item of patientProducts" style="height: auto;">
            <img src="{{item.image | translation: currentLang}}"/>
            <div class="row productDetails">
              <p class="product-desc mb-2 bold" style="line-height: normal;">{{item.productName | translation:currentLang}}</p>
              <p class="product-price mb-2">{{item.productName | translation:currentLang}}: {{item.productQty}} x {{item.productPrice | currency:'USD'}}</p>
              <span *ngIf="item?.recurrency_order" class="badge badge-info" style="width:15%">Refill</span>
            </div>
          </div>
  
          
        </div>
        <div *ngIf="imagesLoaded == false">
          <div class="cart-container">
            <div class="img-div loading">
            </div>
            <div class="row-loading loading" style="display: flex; flex-direction: column;">
              <p class="loading product-desc"> </p>
              <p class="loading product-salesdesc"> </p>
            </div>
            <div class="loading row">
              <p class="loading product-price"> </p>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="address-shipping">
        <div class="address" style="display:flex; flex-direction: column;" *ngFor="let address of shipping">
          <h6 class="bold" style="width:30%">{{'CHECKOUT.DELIVERY' | translate}}</h6>
          <div class="address-container">
            <i class="fas fa-map-pin"></i>
            <div>
              <p style="line-height: normal;" *ngIf="address.AddressLine2 == '' || address.AddressLine2 == null" class="address-lines mt-1 mb-0 ml-3">{{address.AddressLine1}}</p>
              <p style="line-height: normal;" *ngIf="address.AddressLine2 != '' && address.AddressLine2 != null" class="address-lines mt-1 mb-1 ml-3">{{address.AddressLine1}}, {{address.AddressLine2}}</p>
              <p style="line-height: normal;" class="address-city mt-2 mb-1 ml-3">{{address.AddressCity}}, {{address.AddressState}} {{address.AddressZipcode}} - {{address.AddressCountry}}</p><br>
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>
  
   
    <div class="summary">
      <div class="checkout-wrapper">
        <div class="shipping-coupon">
        </div>
        <div class="order-info">
          <div class="order-details-container mb-2 p-4 justify-content-center align-items-center" style="background: #edf2f7; border-radius: 10px;">
            <div class="row" style="flex-wrap: nowrap;">
              <p class="desc">{{'CHECKOUT.SUBTOTAL' | translate}}</p>
              <p *ngIf="orderLinesLoaded == false" class="numbers"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></p>
              <p *ngIf="orderLinesLoaded == true" class="numbers bold">{{shop.subtotal | currency:'USD'}}</p>
            </div>
            <div class="row" style="flex-wrap: nowrap;">
              <p class="desc">{{'CHECKOUT.TAX' | translate}}</p>
              <p *ngIf="orderLinesLoaded == false" class="numbers"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></p>
              <p *ngIf="orderLinesLoaded == true" class="numbers bold">{{shop.tax | currency:'USD'}}</p>
            </div>
            <div class="row" style="flex-wrap: nowrap;">
              <p class="desc m-0">{{'CHECKOUT.TOTAL' | translate}}</p>
              <p *ngIf="orderLinesLoaded == false" class="numbers m-0"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></p>
              <p *ngIf="orderLinesLoaded == true" class="numbers bold m-0">{{shop.total | currency:'USD'}}</p>
            </div>
          </div>
        </div>
  
        <div *ngIf="shop.total" class="d-flex ml-3">
          <h6 class="bold">{{'CHECKOUT.PAYMENT' | translate}} </h6>
          <a href="javascript: void(0);" class="pl-2" (click)="showModalAddCard(addCard)" style="text-align: start;">
            <h6 class="h6"><i class="mdi mdi-plus-circle mr-1"></i>{{'CHECKOUT.ADDPAYMENT' | translate}}</h6>
          </a>
        </div>
        <div *ngIf="shop.total" class="shipping-method-container ml-2">
          <div class="form-inline shipping-field">
            <div class="input-group mb-3">
              <ng-select class="shipping-select checkoutSelect" placeholder="{{'CHECKOUT.SELECTCARD' | translate}}" [multiple]="false" [clearable]="false" [searchable]="false" (change)="onItemChange($event)" [(ngModel)]="creditCardRef" >
                <ng-option *ngFor="let item of allPaymentMethods" [value]="item">
                  <div>
                    <img src="assets/images/cards/visa-color-logo.png" *ngIf="item.brand === 'Visa' || item.brand === 'VISA'" alt="Visa" width="20"/>
                    <img src="assets/images/cards/mastercard-logo.png" *ngIf="item.brand === 'Mastercard' || item.brand === 'MASTERCARD'" alt="MasterCard" width="20"/>
                    <img src="assets/images/cards/american_express_01.png" *ngIf="item.brand === 'American Express' || item.brand === 'AMERICAN_EXPRESS'" alt="American Express" width="20"/>
                    <img src="assets/images/cards/discover.png" *ngIf="item.brand === 'Discover Club' || item.brand === 'DISCOVER'" alt="Discover" width="20"/>
                    <img src="assets/images/cards/jcb_01.png" *ngIf="item.brand === 'JCB' || item.brand === 'JCB'" alt="JCB" width="20"/>
                    <img src="assets/images/cards/diners_club_01.png" *ngIf="item.brand === 'Diners Club' || item.brand === 'DISCOVER_DINERS'" alt="Diners Club" width="20"/>
                    <img src="assets/images/cards/china_union_01.png" *ngIf="item.brand === 'UnionPay' || item.brand === 'CHINA_UNIONPAY'" alt="UnionPay" width="20"/>
                    {{item.brand}}-{{item.last4}} {{'CHECKOUT.EXPIRES' | translate}} {{item.expDate}}
                  </div>  
                </ng-option>
              </ng-select>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addCard style="max-height: 300px !important" let-modal>
  <!-- <div class="justify-content-end">
    <button type="button" class="close float-right modal-close" (click)="dismiss()" aria-hidden="true">×</button>
  </div> -->
  <app-add-payment [name]="user.firstName" [lastName]="user.lastName1" [modalRef]="this.modalRef" (cardAdded)="getAcceptaCards()"></app-add-payment>
</ng-template>
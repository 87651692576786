import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, catchError } from 'rxjs/operators';
// import 'rxjs/add/operator/catch';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ErrorhandlerService  {

  constructor() 
  {

  }
  // handleError(error: any): void {
  //   throw new Error('Method not implemented.');
  // }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // return next.handle(request).catch((err: HttpErrorResponse) => {
      
    //   if (err.status === 401) {
    //     this.showError(err.error.error);
    //   }
    //   if(err.status === 400){
    //     this.showError(err.error.error);
    //   }
    //   if(err.status === 500){
    //     // this.showError(err.error.error);
    //   }
    //   if(err.status === 404){
    //     this.showError(err.error.error);
    //   }

    //   if(err.error instanceof ErrorEvent) {
    //     this.showError(err.error.message);
    //   } else {
    //     this.showError(err.error);
    //   } 
    //   this.showError(err.error.message);
      // return Observable.throw(err);
      return 

    // });
  }



  showError(error: any, position?: any) {
    if(error != '' || error != undefined || error != null){ 
      // Swal.fire({
      //   title: 'Oops...',
      //   text: error,
      //   icon: 'info',

      // })
    }  
  }
}
<div class="patient-onboard">
  <div class="d-flex">
    <div class="p-3">
      <ol class="list-group vertical-steps">
        <!-- <li class="list-group-item active">
          <span>Create Patient Account</span>
        </li>
        <li class="list-group-item">
          <span>Patient Profile</span>
        </li>
        <li class="list-group-item">
          <span>Medical History</span>
        </li>
        <li class="list-group-item">
          <span>Medical Prescription</span>
        </li> -->
        <li *ngFor="let step of completedSteps; let i = index" class="list-group-item" [class.active]="i === currentSectionIndex" [class.completed]="step">
          <span>{{ stepLabels[i] }}</span>
        </li>
      </ol>  
    </div>
    <div class="steps">

      <div *ngIf="currentSectionIndex == 0" class="create-account">
        <div class="title">
          <h3>Create Account</h3>
          <span class="mx-auto">Let's start by creating the patient's account</span>
        </div>

        <div class="basic-account-inputs">
          <!-- <label for="inp" class="inp">
            <input type="email" class="form-control" id="email"  placeholder="&nbsp;">  
            <span class="label">{{'SIGNUP.EMAIL' | translate}}</span>
            <span class="focus-bg"></span>
          </label> -->
          <label for="email" style="width: 40%;">
            <span>Email</span>
            <input type="email" class="form-control" id="email" placeholder="&nbsp;">
          </label>


          <ng-select id="accountType">
            <ng-option value="patient">{{'ACCOUNTS.EMAIL&PASSWORD.PATIENT' | translate}}</ng-option>
            <ng-option value="practitioner">{{'ACCOUNTS.EMAIL&PASSWORD.PRACTITIONER' | translate}}</ng-option>
            <ng-option value="admin">{{'ACCOUNTS.EMAIL&PASSWORD.ADMIN' | translate}}</ng-option>
          </ng-select>
        </div>
      </div>

      <div *ngIf="currentSectionIndex == 1" class="patient-profile">
        <div class="title">
          <h3>Profile Information</h3>
          <span class="mx-auto">Fill the patient profile basic information to get started using the platform services.</span>
        </div>
        <div class="information d-flex flex-column">
          <label for="firstName">
            <span>First Name</span>
            <input type="text" class="form-control" id="firstName" placeholder="&nbsp;" [(ngModel)]="profile.firstName">
          </label>
          <label for="lastName">
            <span>Last Name</span>
            <input type="text" class="form-control" id="lastName" placeholder="&nbsp;" [(ngModel)]="profile.lastName1">
          </label>
          <label for="birthdate">
            <span>Birth date</span>
            <input type="date" class="form-control" id="birthdate" placeholder="&nbsp;" [(ngModel)]="profile.birthdate" (change)="ageValidator($event.target.value)">
            <p id="errorMessage" style="color: red; display: none;">You must be at least 21 years old.</p>
          </label>
          <label for="phone">
            <span>Phone number</span>
            <input type="text" class="form-control" mask="(000) 000-0000" id="phone" placeholder="(123) 456-7890" [(ngModel)]="profile.phoneNumber">
          </label>
        </div>
      </div>



      <div *ngIf="currentSectionIndex == 2" class="medical-history">
        <app-medical-history [patientProfile]="" [onboarding]="true" (completedOnboarding)="next()"></app-medical-history>
      </div>

      <div *ngIf="currentSectionIndex == 3" class="medical-prescription">
        <span>Medical Prescription</span>
      </div>
      <div *ngIf="currentSectionIndex != 2" class="action-buttons d-flex justify-content-center mb-3">
        <button class="btn btn-info w-50 text-center" id="nextButton" (click)="next()">Next</button>
      </div>
    </div>
  </div>
</div>
<div class="row justify-content-center align-items-center flex-column" style="margin: auto !important;">
  <div *ngIf="currentConsult.currentWizardStep != 'checkout' || (currentConsult.currentWizardStep == 'checkout' && finalProduct[conditionCounter][1] && finalProduct[conditionCounter][1]['atr1'])" class="form-group" style="width: 90%; margin: 0 5% !important;">
    <i (click)="handleBackBtn()" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
  </div>
  <div *ngIf="!dummyCheckout && view == 'product'" [ngClass]="{ 'disableDiv': currentConsult.Status != 'Open' && currentConsult.Status != 'Draft'}" class="products-details animate w-100">
    <!-- Pricing Table -->
    <div class="d-flex flex-column"> 
      <!-- <h3 class="bold m-0 animate">{{'CONSULTATIONS.RX' | translate}}</h3> -->
      <h1 class="w-100 mb-1 bold animate">{{'CONSULTATIONS.AVAILABLETREATMENTS' | translate}}</h1>
      <div class="d-flex justify-content-center flex-column">
        <div class="flex-column customView text-center mx-auto"> 
          <div *ngIf="productsByCondition[conditionCounter]" class="details">
            <!-- <h3>{{'CONSULTATIONS.CHOOSETREATMENT' | translate}} {{consultRules.conditions[conditionCounter].conditionName | translation:currentLang}}</h3> -->
            <h3>{{'CONSULTATIONS.INFOFORMED' | translate}}</h3>
            <div [hidden]="i < 3" class="w-100 btn-group btn-group-toggle d-flex flex-column animate align-items-center" data-toggle="buttons" *ngFor="let prod of productFilter[conditionCounter]; let i = index">
              <div class="d-flex align-items-center w-100" style="justify-content: space-around;" *ngIf="i == 0">
                <div *ngIf="finalProduct[conditionCounter][i][productTemplate[i]]">
                  <!-- <img *ngIf="finalProduct[conditionCounter][i][productTemplate[i]] && !finalProduct[conditionCounter][i][productTemplate[i]].includes('Tadalafil')" src="../../../assets/images/icons/Dzeus_Frasco_logo.png" class="mx-auto" style="height: 150px;">
                  <img *ngIf="finalProduct[conditionCounter][i][productTemplate[i]] && finalProduct[conditionCounter][i][productTemplate[i]].includes('Tadalafil')" src="../../../assets/images/Dzeus-Tadalafil-Pill-Bottle-2353x3890.png" class="mx-auto" style="height: 150px;"> -->
                  <img src="{{finalProduct[conditionCounter][0].productImg}}" class="mx-auto" style="height: 150px;">
                  <p style="max-width: 300px; font-size: 10px;">{{'SUBSCRIPTIONS.PRODUCTDISCLAIMER' | translate}}</p>
                </div>
                <div class="d-flex flex-column flex-wrap justify-content-center">
                  <div style="display: contents;" *ngFor="let atr of prod">
                    <label (click)="filterAtributes(atr[productTemplate[i]], productTemplate[i])" for="{{atr[productTemplate[i]]}}" class="productBorders p-0 selected-label rounded m-2" [ngClass]="{'activeProduct': finalProduct[conditionCounter][i] && finalProduct[conditionCounter][i][productTemplate[i]] === atr[productTemplate[i]] }">
                      <input type="radio" name="atr[productTemplate[i]]" class="selects" id="{{atr[productTemplate[i]]}}" value="{{atr[productTemplate[i]]}}">
                      <div class="m-2 p-2 h-auto" style="border-radius: 8px;">
                        <h3 class="m-0" style="max-width: 330px;">{{atr[productTemplate[i]] | translation:currentLang }} <span *ngIf="atr[productTemplate[i]] != atr['productBrandName'] && atr['productBrandName']">({{atr['productBrandNameTranslation'] | translation:currentLang}})</span></h3>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <hr *ngIf="i == 0" class="w-75">

              <!-- new -->
              <div *ngIf="i == 1 && productFilter[conditionCounter][1][0] && productFilter[conditionCounter][1][0][productTemplate[1]]" [ngClass]="{ 'disableDiv': currentConsult.Status != 'Open' && currentConsult.Status != 'Draft'}" class="text-center d-flex flex-wrap flex-column justify-content-center">
                <div>
                  <!-- <h3 class="bold m-0">{{'CONSULTATIONS.NEWPATIENT' | translate}}</h3> -->
                  <p style="max-width: 510px;">
                    <span class="font-size-12" *ngIf="recommendedDosage()">
                      <span *ngIf="productInformation()">{{productInformation() | translation:currentLang}}</span>
                      {{'CONSULTATIONS.NEWPATIENTINFO' | translate}} {{finalProduct[conditionCounter][0].productName}}<span *ngIf="finalProduct[conditionCounter][0].productName != finalProduct[conditionCounter][0]['productBrandName'] && finalProduct[conditionCounter][0]['productBrandName']">&nbsp;({{finalProduct[conditionCounter][0]['productBrandNameTranslation'] | translation:currentLang}})</span> {{'CONSULTATIONS.NEWPATIENTINFO2' | translate}} {{recommendedDosage()}}{{'CONSULTATIONS.NEWPATIENTINFO3' | translate}}
                    </span>
                    <!-- {{'CONSULTATIONS.NEWPATIENTINFO4' | translate}} -->
                  </p>
                  <h3 class="m-0 mt-1">{{searchGroup[i]?.label | translation:currentLang}}</h3>
                </div>
                <div *ngIf="productFilter[conditionCounter][1] && productFilter[conditionCounter][1][0] && productFilter[conditionCounter][1][0].atr1 != ''" class="d-flex flex-row justify-content-center flex-wrap">
                  <div class="row options mb-0 mx-auto" *ngFor="let atr of prod">
                    <div class="grid-wrapper grid-col-3 mx-auto" >
                      <div class="selection-wrapper animate__animated animate__fadeInDown" >
                        <label class="selected-label answers animate" for="{{atr[productTemplate[i]]}}" (click)="filterAtributes(atr[productTemplate[i]], productTemplate[i])">
                          <input class="selects" type="radio" id="{{atr[productTemplate[i]]}}" value="{{atr[productTemplate[i]]}}" name="selected-item" [(ngModel)]="finalProduct[conditionCounter][i][productTemplate[i]]">
                          <div class="d-flex justify-content-center flex-column mx-auto" style="width: fit-content;">
                            <div class="answer-card my-1" style="min-height: auto; padding: 5px;">
                              <div class="px-2">
                                <p class="m-0 optionOfQuestions optionFont" >{{atr[productTemplate[i]] | translation:currentLang}}</p>
                              </div>
                            </div>
                          </div>                  
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="i == 2 && productFilter[conditionCounter][2][0][productTemplate[2]]" >
                <h3 class="m-0 mt-2">{{searchGroup[i]?.label | translation:currentLang}}</h3>
                <div class="d-flex flex-wrap" style="justify-content: space-evenly;">
                  <div *ngFor="let atr1 of productFilter[conditionCounter][i]">
                    <label *ngIf="atr1[productTemplate[i]]" class="selected-label answers animate mx-1" for="{{atr1[productTemplate[i]]}}" (click)="filterAtributes(atr1[productTemplate[i]], productTemplate[i])">
                      <input class="selects" id="{{atr1[productTemplate[i]]}}" value="{{atr1[productTemplate[i]]}}" name="selected-item" [(ngModel)]="finalProduct[conditionCounter][i][productTemplate[i]]">
                      <div class="answer-card subOptions mt-2" [ngClass]="{subIcon: finalProduct[conditionCounter][i][productTemplate[i]] == atr1[productTemplate[i]]}">
                        <div class="d-flex flex-column text mx-2">
                            <h3 class="m-0 d-flex justify-content-between w-100 flex-row" style="margin-bottom: 5px;"><p class="m-0">{{atr1.productQty}} {{dosageForm(atr1.productDosageForm) | translate}}</p> 
                              <p class="m-0">{{atr1.productPrice[0][currentConsult.state.code] | currency:'USD':'symbol':'0.2-2'}} Total</p>
                            </h3>
                        </div>
                      </div>                         
                    </label>
                  </div>
                </div>                              
              </div>
<!-- end new -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- new -->
  <div class="d-flex flex-column align-items-center" *ngIf="dummyCheckout && view == 'product'">
    <h2 class="mt-1 bold">{{'SUBSCRIPTIONS.YOURSELECTION' | translate}}</h2>
    <ul class="p-0" style="--auto-grid-min-size: 100rem">
      <div class="card-content d-flex flex-column p-3">
        <div>
          <div class="mx-auto d-flex flex-column justify-content-center" style="width: fit-content;">
            <!-- <img *ngIf="finalProduct[conditionCounter][0][productTemplate[0]] && !finalProduct[conditionCounter][0][productTemplate[0]].includes('Tadalafil')" src="../../../assets/images/icons/Dzeus_Frasco_logo.png" class="mx-auto" style="height: 150px;">
            <img *ngIf="finalProduct[conditionCounter][0][productTemplate[0]] && finalProduct[conditionCounter][0][productTemplate[0]].includes('Tadalafil')" src="../../../assets/images/Dzeus-Tadalafil-Pill-Bottle-2353x3890.png" class="mx-auto" style="height: 150px;"> -->
            <img src="{{finalProduct[conditionCounter][0].productImg}}" class="mx-auto" style="height: 150px;">
            <p style="max-width: 300px; font-size: 10px;">{{'SUBSCRIPTIONS.PRODUCTDISCLAIMER' | translate}}</p>
          </div>
          <div class="d-flex flex-column justify-content-center">
            <h3 class="ml-2 mb-0" style="text-align: start;">{{'SUBSCRIPTIONS.INCLUDES' | translate}}</h3>
            <div *ngFor="let counter of getCounterArray()">
              <span *ngIf="productFilter[counter][3] && productFilter[counter][3][0] && productFilter[counter][3][0]['productName'] != '' && productFilter[counter][3][0]['productName'] != undefined" class="btn p-0 selected-label rounded ml-2">
                <h4 class="m-0 d-flex flex-row" style="text-align: left;"><img src="../../../assets/images/Dzeus_+Sign_Color.svg" style="width: 10px; margin-right: 0.3rem;"> 
                  <div>{{productFilter[counter][3][0]['productName'] | translation:currentLang }}<span *ngIf="productFilter[counter][3][0]['productName'] != productFilter[counter][3][0]['productBrandName'] && productFilter[counter][3][0]['productBrandName'] != ''">&nbsp;({{productFilter[counter][3][0]['productBrandNameTranslation'] | translation:currentLang}})</span><span *ngIf="productFilter[counter][3][0].productQty != 1">&nbsp;- {{productFilter[counter][3][0].productQty}} {{dosageForm(productFilter[counter][3][0].productDosageForm) | translate}} <span *ngIf="productFilter[counter][3][0]['productDosage']">{{'CONSULTATIONS.OF' | translate}} {{productFilter[counter][3][0]['productDosage'] | translation:currentLang }}</span></span></div></h4>
              </span>
            </div>
            <div *ngIf="consultRules.freeConsult && consultRules.freeConsultText">
              <span class="btn p-0 selected-label rounded ml-2">
                <h4 class="m-0 d-flex flex-row" style="text-align: left;"><img src="../../../assets/images/Dzeus_+Sign_Color.svg" style="width: 10px; margin-right: 0.3rem;"> {{consultRules.freeConsultText | translation:currentLang }}</h4>
              </span>
            </div>
            <!-- <div>
              <span class="btn p-0 selected-label rounded ml-2">
                <h4 class="m-0 d-flex flex-row" style="text-align: left; color: #9fa8b2;">*La receta tendrá un costo adicional de enviarse a otra farmacia.</h4>
              </span>
            </div> -->
          </div> 
        </div>

        <div class="d-flex justify-content-center flex-column align-items-center">
          <div class="d-flex flex-column text ml-2 mt-2" style="width: 250px;">
            <h2 class="m-0 d-flex justify-content-between mx-auto flex-row" style="margin-bottom: 5px;"><p class="m-0 bold">{{'CONSULTATIONS.TOTALTOPAY' | translate}} {{sumTempTotal() | currency:'USD':'symbol':'0.2-2'}}</p></h2>
          </div>
        </div>
      </div>
    </ul>
    <div id="buttons" class="d-flex flex-column mx-auto mt-4 mb-2">
      <button *ngIf="currentUserAuth && currentUserAuth.isAnonymous" class="btn btn-info nextbtn text-center mb-2" (click)="nextAction.emit(true)">{{'SUBSCRIPTIONS.CONTINUEWITHREGISTER' | translate | uppercase}}</button>
      <button *ngIf="currentUserAuth && !currentUserAuth.isAnonymous" class="btn btn-info nextbtn text-center mb-2" (click)="nextAction.emit(true)">{{'SUBSCRIPTIONS.CONTINUEWITHCONSULT' | translate | uppercase}}</button>
    </div>
    <div>
      <h4 class="mt-3 px-4">
        <!-- {{'CONSULTATIONS.FINALDECISION' | translate}}<br> -->
        {{'CHECKOUT.DISCLAIMER' | translate}}</h4>
    </div>
  </div>

  <div *ngIf="!dummyCheckout && view == 'product'" class="row form-group justify-content-center text-center mt-5 flex-column">
    <button style="border-radius: 0.25rem;" class="btn btn-primary nextbtn text-center mb-2 awNextStep m-auto" (click)="handleNextBtn()" [disabled]="handleProductRequiredTest()">{{'PROFILE.NEXT' | translate | uppercase}}</button>
    <div style="padding-bottom: 20px;">
    </div>
    <h4 class="px-4" style="max-width: 500px;">
      <!-- {{'CONSULTATIONS.FINALDECISION' | translate}}<br> -->
      {{'CHECKOUT.DISCLAIMER' | translate}}
    </h4>
  </div>

  <div *ngIf="view == 'promo'">
    <!-- Promo component -->
     <div class="animate">
       <app-promotions *ngIf="currentConsult" [currentConsult]="currentConsult" [selectedPromotion]="promoSelection[0]?.id" (continue)="nextAction.emit(true)"></app-promotions>
     </div>
  </div>
</div> 
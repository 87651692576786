import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { remoteConfig } from 'src/environments/environment';
// import { Translation } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  settings = remoteConfig
  public POST_URL = `https://us-central1-${this.settings.env}.cloudfunctions.net/translate`;
  constructor(
    public afAuth: AngularFireAuth,
    private httpClient: HttpClient
  ) {
    // this.translate();
   }


    public translate(fields){
      const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Access-Control-Allow-Origin', '*');
      let body = new HttpParams();
      // let fields: {id:string, text:string}[] = [];
      body = body.set("fields", JSON.stringify(fields));
      return this.httpClient.post(this.POST_URL, body).subscribe(res => {
      });
    }
}

import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { DateSelectArg } from '@fullcalendar/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { EmailsService } from 'src/app/core/services/emails.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { Profile } from 'src/app/core/services/interfaces';
import { TranslationPipe } from 'src/app/translation.pipe';
import { v4 as uuidv4 } from 'uuid';
import { fbRemoteConfig } from '../../../authUtils';

@Component({
  selector: 'app-appointments-scheduler',
  templateUrl: './appointments-scheduler.component.html',
  styleUrls: ['./appointments-scheduler.component.scss']
})
export class AppointmentsSchedulerComponent implements OnInit, OnDestroy {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @Input() modalRef
  @Input() consult
  @Input() appointment:any;
  @Output() finishConsult = new EventEmitter<Boolean>()

  patient:Profile;
  practitioner

  practitionersAppointments = []
  practitionerSchedule;
  abscentDates;
  selectedDate = '' //yyyy/mm/dd
  selectedDay = 0; //index of week day 
  minutes = 15; //duration of meet
  today = ''
  tomorrow = ''
  remoteConfig = fbRemoteConfig.defaultConfig
  pipe = new TranslationPipe()
  currentLang: any = this.translate.store.currentLang

  suggestedHours = []

  modal
  eventform:UntypedFormGroup;

  allSubscriptions = new Subscription()

  constructor(
    private authService: AuthenticationService,
    private db: AngularFirestore,
    private emailService: EmailsService,
    public translate: TranslateService,
    private firebaseService: FirebaseService,
    private translationPipe: TranslationPipe,
    public formBuilder: UntypedFormBuilder,  
    public router: Router
  ) {}

  async ngOnInit() {
    this.authService.userSubscription.subscribe(res => {this.patient = res as unknown as Profile})

    this.today = formatDate(new Date(), 'yyyy-MM-dd', this.currentLang);
    let tomorrow: any = new Date()
    this.tomorrow = formatDate(new Date(tomorrow.setDate(tomorrow.getDate() + 1)), 'yyyy-MM-dd', this.patient.preferedLang)

    this.selectedDate = this.today

    this.eventform = this.formBuilder.group({
      Start: [this.today + "T00:00"],
      End: [this.today + "T00:15"]
    });
    this.calcEndTime(this.eventform.controls.Start.value)

    this.minutes = this.appointment ? (new Date(this.appointment.end).getTime() - new Date(this.appointment.start).getTime())/60000 : 15

    let practitioner = this.appointment ? this.appointment.uidPractitioner : this.consult.UID_Practitioner ? this.consult.UID_Practitioner : this.consult.practitionerForConsult
    let sub = this.db.collection('users').doc(practitioner).valueChanges().subscribe(async (res: Profile) => {
      this.practitioner = res
      this.practitionerSchedule = res.schedule
      this.abscentDates = res?.abscentDates ? res?.abscentDates?.filter((date: any) => date.end >= this.today) : []

      for(let i in this.practitionerSchedule){
        this.practitionerSchedule[i].schedule.sort((a,b) =>{
          if(Number(a.start.split(':')[0]) == Number(b.start.split(':')[0])){
            if(Number(a.start.split(':')[1]) < Number(b.start.split(':')[1])){
              return -1
            } 
            return 1
          }
          if(Number(a.start.split(':')[0]) < Number(b.start.split(':')[0])){
            return -1
          } 
          return 1
        })
      }
    })
    this.allSubscriptions.add(sub)
  }

  ngOnDestroy(){
    this.allSubscriptions.unsubscribe()
  }

  handleDateSelect(selectInfo: DateSelectArg,) {
    this.selectedDate = selectInfo.startStr.split("T")[0]
    this.calcEndTime(this.eventform.controls.Start.value)
  }

  requestScheduleChange(){
    this.ret = true;
    var Start = this.eventform.controls.Start.value;
    if(typeof(Start) != 'string'){
      Start = Start[0]
    }
    const End = this.eventform.controls.End.value;

    this.db.collection('calendar').doc(this.appointment.id).update({requestUpdate: true, suggestedSchedule: {start: Start, end: End}, meetStatus: 'pending'})
    this.modalRef?.dismiss()

    this.emailService.generalEmails(this.practitioner, "requestAppointmentChange_" + this.practitioner.preferedLang,'<en>Reschedule Appointment</en><es>Reprogramar Cita</es>', this.remoteConfig, 'consults/' + this.appointment.consultID + '/others/telehealth/' + this.appointment.consultID, this.appointment.consultID)
  }

  calcEndTime(startTime){
    if(new Date(startTime).getTime() <= new Date(this.tomorrow).getTime()){
      this.overlap(true,'', true, startTime);
    }
    else {
      var futureDate: any = new Date(startTime).getTime() + (this.minutes*60000);
      this.eventform.controls.End.patchValue(formatDate(new Date(futureDate),'yyyy-MM-ddTHH:mm',this.patient.preferedLang))    
      let practitioner = this.appointment ? this.appointment.uidPractitioner : this.consult.UID_Practitioner ? this.consult.UID_Practitioner : this.consult.practitionerForConsult
      let sub = this.db.firestore.collection('calendar').where('uidPractitioner', '==', practitioner).where('start', '>=', this.tomorrow).get().then((data1: any) => {
        let test = []
        for(let i = 0; i < data1.docs.length; i++){
          test.push(data1.docs[i].data())
        }
        
        let data = test.concat(this.abscentDates).sort((a, b) => {
          return Number(a.start.replace(/\D/g, "")) - Number(b.start.replace(/\D/g, ""));
        })
        
        for(let i = 0; i < data.length; i++){ //if meets overlap with abcent dates
          if(new Date(data[i].start).getTime() <= new Date(data[i + 1]?.start).getTime() && new Date(data[i].end).getTime() >= new Date(data[i + 1]?.end).getTime()) {
            data.splice(i + 1,1)
            i--
          }
          if(i != -1 && new Date(data[i].start).getTime() <= new Date(data[i + 1]?.start).getTime() && new Date(data[i].end).getTime() <= new Date(data[i + 1]?.end).getTime() && new Date(data[i].end).getTime() >= new Date(data[i + 1]?.start).getTime()) {
            data[i].end = data[i + 1].end
            data.splice(i + 1,1)
            i--
          }
          if(i != -1 && new Date(data[i].start).getTime() <= new Date(data[i + 1]?.start).getTime() && new Date(data[i + 1]?.end).getTime() >= new Date(data[i].end).getTime() && new Date(data[i].end).getTime() >= new Date(data[i + 1]?.start).getTime()) {
            data[i].start = data[i + 1].start
            data.splice(i + 1,1)
            i--
          }
        }

        for (let i = 0;i < data.length; i++) {    
          if(i == 0 && new Date(futureDate).getTime() <= new Date(data[i].start).getTime()){
            this.overlap(false, data, false, startTime)
            i = data.length
            break;
          }   
          if (new Date(startTime).getTime() >= new Date(data[i].end).getTime() && new Date(futureDate).getTime() <= new Date(data[i + 1]?.start).getTime())
          {
            this.overlap(false, data, false, startTime)
            i = data.length
            break;
          }
          if(i == data.length - 1) {
            if (new Date(startTime).getTime() >= new Date(data[i].end).getTime()){
              this.overlap(false, data, false, startTime)
              i = data.length
              break;
            }
            else {
              this.overlap(true, data, false)
              this.possibleDates(data)
            }                 
          }   
        }   
        if(data.length == 0) {
          this.overlap(false, data, false, startTime)
        }
      });
    }  
  }

  ret
  warning
  overlap(ret: boolean, data: any, passed?: boolean, startTime?){
    this.selectedDay = new Date(this.eventform.controls.Start.value).getDay()

    if(ret){
      this.ret = true;
      // this.warning = 'EVENTS.WARNING'
      this.warning = 'APPOINTMENTS.NOWORK'
    }
    else {
      this.ret = false;
      this.warning = ''
      var futureDate: any = new Date(startTime).getTime() + (this.minutes*60000);
      for(let i = 0; i < this.practitionerSchedule[this.selectedDay].schedule.length; i++) {
        if(new Date(startTime).getTime() >= new Date(this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay].schedule[i].start).getTime() && new Date(futureDate).getTime() <= new Date(this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay].schedule[i].end).getTime()){
          this.ret = false;
          this.suggestedHours[0] = '' 
          this.suggestedHours[1] = '' 
          this.warning = '';
          break
        }
        else {
          this.ret = true
          this.warning = 'APPOINTMENTS.NOWORKHOUR'
        }
      }
      if(this.practitionerSchedule[this.selectedDay].schedule.length == 0){
        this.warning = 'APPOINTMENTS.NOWORK'
        this.ret = true
      }
    }
    if(passed){
      // this.warning = 'EVENTS.PASSED'
      this.warning = startTime < this.today ? 'APPOINTMENTS.PASTDATE' : 'APPOINTMENTS.TODAY'
      this.ret = true;
    }

    if(this.ret && !passed){
      this.possibleDates(data)
    }
  }

  createEventTest(){
    this.ret = true;

    if(this.appointment){
      this.requestScheduleChange()
    }
    else {
      var Start = this.eventform.controls.Start.value;
      var Title;
      if(this.patient.preferedLang == 'en'){
        // Title = 'Dzeus Health - ' +  this.translationPipe.transform(this.consult.conditionName, this.patient.preferedLang) + ' Consultation'
        Title = 'Medical Consultation'
      }
      else {
        // Title = 'Dzeus Health - Consulta de ' + this.translationPipe.transform(this.consult.conditionName, this.patient.preferedLang)
        Title = 'Consulta Médica'
      }
      if(typeof(Start) != 'string'){
        Start = Start[0]
      }
      const End = this.eventform.controls.End.value;
  
      let date = new Date(Date.now());
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();
      let id:any = yyyy + mm + dd + uuidv4();
      let data = {
        id: id,
        title: Title,
        start: Start,
        end: End,
        uidPractitioner: this.consult.UID_Practitioner ? this.consult.UID_Practitioner : this.consult.practitionerForConsult,
        practitionerEmail: this.consult.UID_Practitioner ? this.consult.practitionerEmail : '',
        uidPatient: this.consult.UID_Patient,
        patientName: this.consult.patientName,
        patientEmail: this.consult.patientEmail,
        consultID: this.consult.ID,
        conditionName: this.consult.conditionName,
        dateCreated: new Date(Date.now()),
        room:"",
        description: '',
        meetRef: '',
        eventTimezoneValue: 'UTC' + this.patient.timezone.timeValue,
        meetStatus: 'paid',
        requestUpdate: true,
        suggestedSchedule:  {start: Start, end: End}
      }

      this.firebaseService.scheduleEvent(data);
    }
    this.finishConsult.emit(true)
  }

  possibleDates(res){
    this.suggestedHours[0] = ''
    this.suggestedHours[1] = ''

    for(let i = res.length - 1; i > 0; i--){ //before selected Date
      let posDate: any = new Date(res[i].start).getTime() - (this.minutes*60000)
      posDate = formatDate(new Date(posDate), 'yyyy-MM-ddThh:mm', this.patient.preferedLang)
      if(posDate <= this.eventform.controls.Start.value){
        if(res.length == 1){ //doesnt search for [res + 1]
          this.findPrevDateAvailableWithOneMeeting(res, 0, 0)
        }
        else {
          this.findDatesAvailableBeforeSecondMeet(res[i], res[i - 1], 0, 0)
        }
      }
      if(this.tomorrow <= this.eventform.controls.Start.value.split('T')[0] && i == res.length - 1 && this.suggestedHours[0] == ''){
        if(this.practitionerSchedule[this.selectedDay].schedule.length != 0 && this.eventform.controls.Start.value < (this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay].schedule[this.practitionerSchedule[this.selectedDay].schedule.length - 1].end)){
          let nextSchedule = this.selectedDay;
          if(this.selectedDay == 0){
            nextSchedule = 6 //Sat
          }
          else {
            nextSchedule = -1;
          }
          this.findPrevDateAvailableTest(nextSchedule, 1)
        }
        else {
          this.findPrevDateAvailableTest(0, 0)
        }
      }
    }

    for(let i = 0; i < res.length; i++){ //after selected
      let posDate: any = new Date(res[i].end).getTime() + (this.minutes*60000)
      posDate = formatDate(new Date(posDate), 'yyyy-MM-ddThh:mm', this.patient.preferedLang)
      if(posDate >= this.eventform.controls.Start.value){
        if(res.length == 1){ //doesnt search for [res + 1]
          this.findNextDateAvailableWithOneMeeting(res, 0 , 0)
        }
        else {
          this.findDatesAvailableAfterFirstMeet(res[i], res[i + 1], 0, 0)
        }
      }
      if(i == res.length - 1 && this.suggestedHours[1] == ''){
        if(this.practitionerSchedule[this.selectedDay].schedule.length != 0 && this.eventform.controls.Start.value < (this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay].schedule[this.practitionerSchedule[this.selectedDay].schedule.length - 1].end)){
          this.findNextDateAvailableTest(0, 0)
        }
        else {
          let nextSchedule
          if(this.selectedDay == 6){
            nextSchedule = 0 //Sun
          }
          else {
            nextSchedule = 1
          }
          this.findNextDateAvailableTest(nextSchedule, 1)
        }
      }
    }

    if(res.length == 0 && this.practitionerSchedule[this.selectedDay].schedule.length != 0){ //no meetings & workday
      if(this.eventform.controls.Start.value < this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay].schedule[0].start){
        let nextSchedule = this.selectedDay;
        if(this.selectedDay == 0){
          nextSchedule = 6 //Sat
        }
        else {
          nextSchedule = -1
        }
        this.findPrevDateAvailableTest(nextSchedule, 1); //previous day before selectedDate
        this.suggestedHours[1] = this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay].schedule[0].start;
      }
      if(this.eventform.controls.Start.value > this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay].schedule[this.practitionerSchedule[this.selectedDay].schedule.length - 1].end){
        var pastDate: any = new Date(this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay].schedule[this.practitionerSchedule[this.selectedDay].schedule.length - 1].end).getTime() - (this.minutes*60000)
        if(pastDate >= new Date(this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay].schedule[this.practitionerSchedule[this.selectedDay].schedule.length - 1].start).getTime()){
          this.suggestedHours[0] = pastDate;
        }    

        // this.suggestedHours[0] = this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay].schedule[this.practitionerSchedule[this.selectedDay].schedule.length - 1].end;
        let nextSchedule;
        if(this.selectedDay == 6){
          nextSchedule = 0 //Sun
        }
        else {
          nextSchedule = 1
        }
        this.findNextDateAvailableTest(nextSchedule, 1); //next day after selectedDate
      }
    }    

    if(res.length == 0 && this.practitionerSchedule[this.selectedDay].schedule.length == 0){ // no meetings & no workday
      this.findPrevDateAvailableTest(0, 0);
      this.findNextDateAvailableTest(0, 0);
    }
  }

  findNextDateAvailableTest(nextSchedule, dayOfComparison){ //when theres no meetings
    if(this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length != 0){
      let tempDateSelected: any = new Date(this.eventform.controls.Start.value)
      tempDateSelected = formatDate(new Date(tempDateSelected.setDate(tempDateSelected.getDate() + dayOfComparison)), 'yyyy-MM-dd', this.patient.preferedLang)
      this.suggestedHours[1] = tempDateSelected + 'T'  + this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[0].start
    }
    else {
      if(this.selectedDay + nextSchedule == 6){
        nextSchedule = -[this.selectedDay]
      }
      else {
        nextSchedule++;
      }
      dayOfComparison++;
      this.findNextDateAvailableTest(nextSchedule, dayOfComparison)
    }
  }

  findPrevDateAvailableTest(nextSchedule, dayOfComparison){ //when theres no meetings
    let tempDateSelected: any = new Date(this.eventform.controls.Start.value)
    tempDateSelected = formatDate(new Date(tempDateSelected.setDate(tempDateSelected.getDate() - dayOfComparison)), 'yyyy-MM-dd', this.patient.preferedLang)
    if(this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length != 0 && tempDateSelected >= this.tomorrow){
      var pastDate: any = new Date(tempDateSelected + 'T' + this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length - 1].end).getTime() - (this.minutes*60000)
      if(pastDate >= new Date(tempDateSelected + 'T' + this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length - 1].start).getTime()){
        this.suggestedHours[0] = pastDate;
      }    
      // this.suggestedHours[0] = tempDateSelected + 'T'  + this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length - 1].end
    }
    else if(tempDateSelected > this.tomorrow) {
      if(this.selectedDay + nextSchedule == 0){
        nextSchedule = 6 - this.selectedDay   
      }
      else {
        nextSchedule--;
      }
      dayOfComparison++;
      this.findPrevDateAvailableTest(nextSchedule, dayOfComparison)
    }
  }

  findNextDateAvailableWithOneMeeting(res, nextSchedule, dayOfComparison){
    if(this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length != 0){
      let tempDateSelected: any = new Date(this.eventform.controls.Start.value)
      tempDateSelected = formatDate(new Date(tempDateSelected.setDate(tempDateSelected.getDate() + dayOfComparison)), 'yyyy-MM-dd', this.patient.preferedLang)
    
      if(this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[0].start == res[0].start){
        this.suggestedHours[1] = res[0].end;
      }
      if(this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[0].start < res[0].start){
        var pastDate: any = new Date(res[0].start).getTime() - (this.minutes*60000)
        if(pastDate >= new Date(this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[0].start).getTime()){
          this.suggestedHours[1] = this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[0].start;
        }
        else {
          this.suggestedHours[1] = res[0].end
        }    
      }
      if(this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[0].start > res[0].start){
        this.suggestedHours[1] = this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[0].start;
      }
    }
    else {
      if(this.selectedDay + nextSchedule == 6){
        nextSchedule = -[this.selectedDay]
      }
      else {
        nextSchedule++;
      }
      dayOfComparison++;
      this.findNextDateAvailableWithOneMeeting(res, nextSchedule, dayOfComparison)
    }
  }

  findPrevDateAvailableWithOneMeeting(res, nextSchedule, dayOfComparison){
    let tempDateSelected: any = new Date(this.eventform.controls.Start.value)
    tempDateSelected = formatDate(new Date(tempDateSelected.setDate(tempDateSelected.getDate() - dayOfComparison)), 'yyyy-MM-dd', this.patient.preferedLang)
    if(this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length != 0 && tempDateSelected >= this.tomorrow){
      if(this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length - 1].end == res[0].end){
        this.suggestedHours[0] = res[0].start;
      }
      if(this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length - 1].end > res[0].end){
        var pastDate: any = new Date(res[0].end).getTime() + (this.minutes*60000)
        if(pastDate >= new Date(this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length - 1].end).getTime()){
          this.suggestedHours[0] = tempDateSelected + 'T' + this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length - 1].end;
        } 
        else {
          this.suggestedHours[0] = res[0].end
        } 
      }
      if(this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length - 1].end < res[0].end){
        var pastDate: any = new Date(tempDateSelected + 'T' + this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length - 1].end).getTime() - (this.minutes*60000)
      if(pastDate >= new Date(tempDateSelected + 'T' + this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length - 1].start).getTime()){
        this.suggestedHours[0] = pastDate;
      }  
        // this.suggestedHours[0] = tempDateSelected + 'T' + this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length - 1].end;
      }
    }
    else if(tempDateSelected > this.tomorrow) {
      if(this.selectedDay + nextSchedule == 0){
        nextSchedule = 6 - this.selectedDay  
      }
      else {
        nextSchedule--;
      }
      dayOfComparison++;
      this.findPrevDateAvailableWithOneMeeting(res, nextSchedule, dayOfComparison)
    }
  }

  findDatesAvailableAfterFirstMeet(meet1, meet2, nextSchedule, dayOfComparison){ //search between meet(any) and the next meeting
    for(let j of this.practitionerSchedule[this.selectedDay + nextSchedule].schedule){
      let tempDateSelected: any = new Date(this.eventform.controls.Start.value)
      tempDateSelected = formatDate(new Date(tempDateSelected.setDate(tempDateSelected.getDate() + dayOfComparison)), 'yyyy-MM-dd', this.patient.preferedLang)
      if(meet1?.end >= this.eventform.controls.Start.value){
        let startTime = Number(meet1?.end.replace(/\D/g, ""));
        let endTime = Number(meet2?.start.replace(/\D/g, "")) ? Number(meet2?.start.replace(/\D/g, "")) : Number((tempDateSelected + 'T' + j.end).replace(/\D/g, ""));
        let timeDiff = endTime - startTime
        let posSuggestion = new Date(meet1.end).getTime()
        if(timeDiff > this.minutes && posSuggestion <= new Date(tempDateSelected + 'T' + j.end).getTime() && this.suggestedHours[1] == ''){
          if(posSuggestion >= new Date(tempDateSelected + 'T' + j.start).getTime() && this.eventform.controls.Start.value < (this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay].schedule[this.practitionerSchedule[this.selectedDay].schedule.length - 1].start)){
            this.suggestedHours[1] = this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay + nextSchedule].schedule[0].start
          }
          else {
            this.suggestedHours[1] = posSuggestion;
          }
          break;
        }
      }
    }
    if(this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length == 0){
      if(this.selectedDay + nextSchedule == 6){
        nextSchedule = -[this.selectedDay]
      }
      else {
        nextSchedule++;
      }
      dayOfComparison++;
      this.findDatesAvailableAfterFirstMeet(meet1, meet2, nextSchedule, dayOfComparison)
    }
  }

  findDatesAvailableBeforeSecondMeet(meet1, meet2, nextSchedule, dayOfComparison){ //search between meet(any) and the prev meeting
    for(let j of this.practitionerSchedule[this.selectedDay + nextSchedule].schedule){
      let tempDateSelected: any = new Date(this.eventform.controls.Start.value)
      tempDateSelected = formatDate(new Date(tempDateSelected.setDate(tempDateSelected.getDate() - dayOfComparison)), 'yyyy-MM-dd', this.patient.preferedLang)
      if(meet1?.start.split('T')[0] <= this.eventform.controls.Start.value){
        let startTime = Number(meet2?.end.replace(/\D/g, ""));
        let endTime = Number(meet1?.start.replace(/\D/g, ""));
        let timeDiff = endTime - startTime
        let posSuggestion = new Date(meet1.start).getTime() - (this.minutes*60000)
        if(timeDiff > this.minutes && posSuggestion >= new Date(tempDateSelected + 'T' + j.start).getTime() && this.suggestedHours[0] == ''){
          if(posSuggestion <= new Date(tempDateSelected + 'T' + j.end).getTime() && this.eventform.controls.Start.value > (this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay].schedule[this.practitionerSchedule[this.selectedDay].schedule.length - 1].end)){
            this.suggestedHours[0] = this.selectedDate + 'T' + this.practitionerSchedule[this.selectedDay].schedule[this.practitionerSchedule[this.selectedDay].schedule.length - 1].end
          }
          else {
            this.suggestedHours[0] = posSuggestion
          }
          break;
        }      
      }
    }
    if(this.practitionerSchedule[this.selectedDay + nextSchedule].schedule.length == 0){
      if(this.selectedDay + nextSchedule == 0){
        nextSchedule = 6 - this.selectedDay
      }
      else {
        nextSchedule--;
      }
      dayOfComparison++;
      this.findDatesAvailableBeforeSecondMeet(meet1, meet2, nextSchedule, dayOfComparison)
    }
  }
}
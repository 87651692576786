<div *ngIf="details">
  <div class="w-100 p-2 d-flex justify-content-end topbar">
    <!-- <h3 class="mx-auto">{{'ORDERS.ORDERS' | translate}} {{details.orderNo}}</h3> -->
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div *ngIf="!refillReasons" class="">
    <div>
      <div class="collapsible d-flex justify-content-center">
        <h4 class="bold">{{'ORDERS.ORDERSUMMARY' | translate}}: {{details.orderNo}}</h4>
        <!-- <i class="fas fa-chevron-down vertical-align"></i> -->
      </div>
      <div class="heading">
        <span>{{'ORDERS.STAT' | translate}}:  
          <i *ngIf="details.paymentStatus === 'paid'" class="far fa-check-circle text-success"></i>
          <i *ngIf="details.paymentStatus === 'Refunded' || details.paymentStatus === 'partial refund' || details.paymentStatus === 'partial credit' || details.paymentStatus === 'credit'" class="fas fa-undo-alt"></i>
          <i *ngIf="details.paymentStatus === 'Chargeback'" class="far fa-times-circle text-danger"></i>
          <i *ngIf="details.paymentStatus === 'error' || details.paymentStatus == 'expired'" class="far fa-times-circle text-danger"></i>
          <i *ngIf="details.paymentStatus === 'pending'" class="far fa-clock text-warning"></i>
          <span *ngIf="details.paymentStatus == 'paid'" class="ml-1 bold">{{'ORDERS.STATUS.PAID' | translate | titlecase}}</span>
          <span *ngIf="details.paymentStatus == 'Refunded'" class="ml-1 bold">{{'ORDERS.STATUS.REFUNDED' | translate | titlecase}}</span>
          <span *ngIf="details.paymentStatus == 'partial refund'" class="ml-1 bold">{{'ORDERS.STATUS.PARTIALREFUND' | translate | titlecase}}</span>
          <span *ngIf="details.paymentStatus == 'partial credit'" class="ml-1 bold">{{'ORDERS.STATUS.PARTIALCREDIT' | translate | titlecase}}</span>
          <span *ngIf="details.paymentStatus == 'Chargeback'" class="ml-1 bold">{{'ORDERS.STATUS.CHARGEBACK' | translate | titlecase}}</span>
          <span *ngIf="details.paymentStatus == 'error'" class="mb-0">{{'ORDERS.STATUS.CANCELLED' | translate | titlecase}}</span>
          <span *ngIf="details.paymentStatus == 'credit'" class="mb-0">{{'ORDERS.STATUS.CREDIT' | translate | titlecase}}</span>
          <span *ngIf="details.paymentStatus == 'pending'" class="ml-1 bold">{{'ORDERS.STATUS.PENDING' | translate | titlecase}}</span>
          <span *ngIf="details.paymentStatus == 'expired'" class="ml-1 bold">{{'ORDERS.STATUS.EXPIRED' | translate | titlecase}}</span>
        </span>
        <span>{{'ORDERS.DATE' | translate}}: <span>{{details.date.toDate() | date:'medium'}}</span></span>
      </div>
      <a *ngIf="details && details.trackingUrl && details.trackingUrl != 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=/False' && details.trackingUrl != undefined" class="ml-3" href="{{details.trackingUrl}}" target="_blank">{{'ORDERS.TRACKING' | translate}}</a>
    </div>

    <div class="details">
      <div class="section">
        <div class="content">
          <div *ngFor="let product of orderLines; let i = index"  class="mb-1 product-div flex-column" [ngClass]="{'d-none': product.productPrice && product.productPrice.includes('-'), 'd-flex': !product.productPrice.includes('-')}">
            <div class="d-flex">
              <div *ngIf="!product.productPrice.includes('-')" class="imgContent" style="min-width: 70px; width: 70px; height: auto;">
                <img style="width: -webkit-fill-available;" src="{{product.image | translation:lang}}" alt="">
              </div>
              <div *ngIf="!product.productPrice.includes('-')" class="d-flex w-100 justify-content-between">
                <div class="d-flex flex-column">
                  <span class="ml-3 product-name">{{product.productName | translation:lang}} {{product.productDosage}} {{product.productDosageForm}}</span>
                  <span *ngIf="product.type == 'product'" class="ml-3 product-name">Qty: x{{product.productQty}}</span>
                  <span *ngIf="product.productStatus == 'Canceled'" style="color: red;" class="ml-3 product-name">{{'ORDERS.STATUS.CANCELLED' | translate | titlecase}}</span>
                  <span *ngIf="product.productStatus == 'Refunded'" class="ml-3 product-name orangeText">{{'ORDERS.STATUS.REFUNDED' | translate | titlecase}}</span>
                  <!-- <span *ngIf="consult?.Status == 'Completed' && details.paymentStatus == 'pending' && product.type == 'product' && product.treatment == false " class="ml-3 badge badge-success cursor-pointer badge-btn" (click)="callOrderChangeProduct(product)">{{'ORDER-CHANGE-PRODUCT.CHANGEPRODUCT' | translate}}</span> -->
                  <div *ngIf="product.type == 'product' && details.paymentStatus == 'pending' && currentUser.accountType == 'admin' && (currentUser.firstName == 'Melanie' || currentUser.firstName == 'Neftis')" class="d-flex flex-row ml-3">
                    <label disabled for="switch" class="switch">
                      <input id="switch" type="checkbox" [checked]="product.automaticPayment">
                      <span class="slider round"></span>
                    </label>
                    <p class="ml-1 mb-0" style="align-self: center;">{{'CHECKOUT.AUTOREFILLPAYMENTS' | translate}}</p>
                  </div>
                  
                  <!-- <span class="badge-change-prescription ml-3 text-start">Change Prescription</span> -->
                  <!-- <button *ngIf="product.type == 'product' && product.prescriptionLineID != 'False' && product.hide == false" class="btn btn-info ml-3 badge-change-prescription" (click)="changePrescription(product.prescriptionLineID)">{{'ORDERS.CHANGE-PRESCRIPTION' | translate}}</button> -->
                </div>
                <div class="d-flex flex-column">
                  <span *ngIf="orderLinesLoaded == false || details.orderState == 'create'" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span *ngIf="orderLinesLoaded == true && details.orderState != 'create'" class="" [ngClass]="{'saveForLater': product?.productUOMQty === 0}">{{product?.productPrice | currency:'USD'}}</span>
                  <!-- <svg *ngIf="product.type == 'product'"  class="mx-auto" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
                    <path d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
                    <path d="M19 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
                  </svg> -->
                  <!-- <svg *ngIf="product.type == 'product'" class="mx-auto" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
                    <path d="M12 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
                    <path d="M12 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
                  </svg> -->
  
  
                  <div ngbDropdown placement="bottom-left"  *ngIf="product.type == 'product' && details.paymentStatus != 'paid' && details.paymentStatus != 'partial refund' && details.paymentStatus != 'partial credit' && details.paymentStatus != 'Refunded' && details.paymentStatus != 'credit'" class="mx-auto dropleft">
                    <svg id="dropdown1" ngbDropdownToggle class="mx-auto" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
                      <path d="M12 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
                      <path d="M12 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
                    </svg>
                    <!-- <svg id="dropdown1" ngbDropdownToggle  class="mx-auto" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
                      <path d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
                      <path d="M19 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
                    </svg> -->
                    <div ngbDropdownMenu aria-labelledby="dropdown1" style="left: -185px !important;">
                      <!-- items as buttons -->
                      <!-- <button ngbDropdownItem [disabled]="product.hide == true" (click)="changePrescription(product.prescriptionLineID)">
                        {{'ORDERS.CHANGE-PRESCRIPTION' | translate}}
                      </button> -->
                      <!-- <button ngbDropdownItem [disabled]="product.treatment == true" (click)="callOrderChangeProduct(product)">
                        {{'ORDER-CHANGE-PRODUCT.CHANGEPRODUCT' | translate}}
                      </button> -->
                      <button ngbDropdownItem [disabled]="product.treatment == true" (click)="showShipmentFrequency[i] = !showShipmentFrequency[i]">
                        <!-- {{'ORDER-CHANGE-PRODUCT.CHANGEPRODUCT' | translate}} -->
                        {{'ORDER-CHANGE-PRODUCT.DELIVERYFREQUENCY' | translate}}
                      </button>
                      <!-- <button ngbDropdownItem [disabled]="true">Recommended</button>
                      <button ngbDropdownItem [class.disabled]="true" aria-disabled="true">Button (disabled, focusable)</button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="shipment-frequency" *ngIf="showShipmentFrequency[i] || ((details.prescriptionSigned == false || product.deliveryFrequencyInDays == undefined) && product.type == 'product')">
              <h5 *ngIf="deliveryFrequencies[product.productID];" class="title">{{"SHIPMENT-FREQUENCIES.HOWOFTEN" | translate}}</h5>
              <div class="grid-wrapper mt-1" *ngFor="let freq of deliveryFrequencies[product.productID];">
                <label for="{{freq.id}}{{product.id}}" class="radio-card">
                  <input type="radio" name="{{freq.id}}{{product.id}}" id="{{freq.id}}{{product.id}}" value="{{freq.id}}{{product.id}}" (click)="setShipmentFrequency(freq, product)" [checked]="setDeliveryFrequency(freq.frequency, freq.autoPayment, product)"/>
                  <div class="card-content-wrapper d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <span class="check-icon"></span>
                      <span *ngIf="freq.autoPayment" class="font-size-18 w-100 gender ml-2 d-flex justify-content-between align-items-center">{{ freq.description | translation: lang }} <img class="mx-2" style="width: 25px;" src="../../../../../../assets/images/frequencies/Calendar gray.png"></span>
                      <span *ngIf="!freq.autoPayment" class="font-size-18 w-100 gender ml-2 d-flex justify-content-between align-items-center">{{ freq.description | translation: lang }} <img class="mx-2" style="width: 25px;" src="../../../../../../assets/images/frequencies/Manual Order Icon (Copy).png"></span>
                    </div>
                    <div *ngIf="setDeliveryFrequency(freq.frequency, freq.autoPayment, product)">
                      <p style="max-width: 300px; margin: 0; font-size: 12px;">{{freq.memo | translation:lang}}</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div class="invoice d-flex justify-content-between flex-column">
            <div class="d-flex flex-row justify-content-between px-1">
              <span>{{'CHECKOUT.SUBTOTAL' | translate}}:</span>
              <span *ngIf="orderLinesLoaded == false || details.orderState == 'create'" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span *ngIf="orderLinesLoaded == true && details.orderState != 'create'" class="">{{subtotalQty | currency:'USD'}}</span>
            </div>
            <div class="d-flex flex-column justify-content-between">
              <span class="pl-1" *ngIf="discountList.length == 1">{{'ORDERS.DISCOUNT' | translate}}: </span> 
              <span class="pl-1" *ngIf="discountList.length > 1">{{'ORDERS.DISCOUNTS' | translate}}: </span> 
              <span *ngIf="orderLinesLoaded == false || details.orderState == 'create' && discountList.length > 0" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <!-- <span *ngIf="orderLinesLoaded == true && details.orderState != 'create'">{{discountQty | currency:'USD'}}</span> -->
              <span *ngIf="orderLinesLoaded == true && details.orderState != 'create' && discountList.length > 0">
                <p class="my-1 discount d-flex justify-content-between" *ngFor="let p of discountList">
                  <span style="font-weight: 600 !important;">{{p.name}}</span>
                  <span style="font-weight: 600 !important;">{{p.price}}</span>
                </p>
              </span>
            </div>
            <div class="d-flex flex-row justify-content-between px-1">
              <span>{{'CHECKOUT.TAX' | translate}}:</span>
              <span *ngIf="orderLinesLoaded == false || details.orderState == 'create'" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span *ngIf="orderLinesLoaded == true && details.orderState != 'create'" class=""> {{details?.orderTax | currency:'USD'}} </span>
            </div>
            <div class="d-flex mt-1 flex-row justify-content-between px-1">
              <h5 *ngIf="details?.paymentStatus == 'pending'" class="bold">{{'CHECKOUT.AMOUNTDUE' | translate}}:</h5>
              <h5 *ngIf="details?.paymentStatus == 'error'" class="bold">{{'CHECKOUT.TOTAL' | translate}}:</h5>
              <h5 *ngIf="details?.paymentStatus == 'paid' || details.paymentStatus == 'partial refund' || details.paymentStatus == 'partial credit' || details.paymentStatus == 'credit' || details.paymentStatus == 'Refunded'" class="bold">{{'ORDERS.TOTALPAID' | translate}}:</h5>
              <h5 *ngIf="orderLinesLoaded == false || details.orderState == 'create'" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></h5>
              <!-- <span *ngIf="orderLinesLoaded == true && details.orderState != 'create'"><b>{{order?.orderTotal | currency:'USD'}}</b></span>
              <span *ngIf="orderLinesLoaded == false || details.orderState == 'create'"class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> -->
              <h5 *ngIf="orderLinesLoaded == true && details.orderState != 'create'"  [ngClass]="{'text-success': details.paymentStatus === 'paid' || details.paymentStatus == 'partial refund' || details.paymentStatus == 'partial credit' || details.paymentStatus == 'Refunded' || details.paymentStatus == 'credit','text-warning': details.paymentStatus === 'pending'}">
                <b class="bold" *ngIf="details?.paymentStatus == 'pending'">{{ amountDue | currency:'USD'}}</b>
                <b class="bold" *ngIf="details?.paymentStatus == 'paid' || details?.paymentStatus == 'error' || details.paymentStatus == 'partial refund' || details.paymentStatus == 'partial credit' || details.paymentStatus == 'credit' || details.paymentStatus == 'Refunded'">{{ details.orderTotal | currency:'USD'}}</b>
              </h5>
            </div>
            <div *ngIf="creditAmount > 0" class="d-flex mt-1 flex-row justify-content-between px-1">
              <h5>
                {{'ORDERS.STATUS.TOTALCREDIT' | translate}}:
              </h5>
              <h5>
                {{creditAmount | currency:'USD'}}
              </h5>
            </div>
            <div *ngIf="refundAmount > 0" class="d-flex mt-1 flex-row justify-content-between px-1">
              <h5>
                {{'ORDERS.STATUS.TOTALREFUNDED' | translate}}:
              </h5>
              <h5>
                {{refundAmount | currency:'USD'}}
              </h5>
            </div>
            <span *ngIf="currentUser.accountType == 'admin'" (click)="orderPaymentHistory()"><u>{{'ORDERS.PAYMENTS' | translate}}</u></span>
          </div>
          <hr>
        </div>
      </div>

      <ng-container *ngIf="showAddCouponOption && details.paymentStatus == 'pending'">
        <div class="section">
          <div class="collapsible d-flex justify-content-center flex-column">
            <h5 class="bold m-0">{{'ORDERS.ADD-COUPON' | translate}}</h5>
            <!-- <span>{{'ORDERS.COUPON-ADD' | translate}}: </span> -->
            <div class="form-inline shipping-field d-flex justify-content-center">
              <div class="input-group mb-2 mt-2" style="width: inherit;">
                <input type="text" class="form-control" placeholder="{{'ORDERS.ENTER-COUPON' | translate}}" id="couponCodeValue" style="border-radius: 5px;">
                <ng-container *ngIf="orderLinesLoaded == true && details.orderState != 'create'">
                  <div class="input-group-append" style="margin-left: 10px;">   
                    <button *ngIf="!applyingCoupon" class="btn btn-cyan" type="button" (click)="applyCouponCode()">{{'ORDERS.APPLY-COUPON' | translate}}</button>
                    <div style="margin-top: auto; margin-bottom: auto;"><span *ngIf="applyingCoupon" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>
                  </div>
                </ng-container>
                <ng-container *ngIf="orderLinesLoaded == false || details.orderState == 'create'">
                  <div class="input-group-append" style="margin-left: 10px;">
                    <div style="margin-top: auto; margin-bottom: auto;"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>
                  </div>
                </ng-container>
              </div>
              <div class="w-100 d-flex justify-content-center" *ngIf="couponErrorExists">
                <div *ngIf="couponInvalid" class="error"><i class="fa fa-times"></i> {{'ORDERS.COUPON-INVALID' | translate}}</div>
                <div *ngIf="couponError" class="error"><i class="fa fa-times"></i> {{'ORDERS.ERROR-COUPON' | translate}}</div>
                <div *ngIf="couponPreviouslyUsed" class="error"><i class="fa fa-times"></i> {{'ORDERS.COUPON-USED' | translate}}</div>
                <div *ngIf="couponInvalidOrder" class="error"><i class="fa fa-times"></i> {{couponCode}} {{'ORDERS.INVALID-ORDER' | translate}}</div>
              </div>
              <div class="w-100 d-flex justify-content-center" *ngIf="couponExistsInOrder">
                <div *ngIf="couponValid" style="margin-top: auto; margin-bottom: auto; display: flex;"><span class="valid" style="margin-top: auto; margin-bottom: auto;"><i class="fa fa-check"></i> {{couponCode}} {{'ORDERS.COUPON-APPLIED' | translate}}</span> <button *ngIf="!removingCoupon" class="btn btn-link" (click)="removeCouponFromOrder()" style="font-size: 12px !important;">{{'ORDERS.REMOVE-COUPON' | translate}}</button> <span *ngIf="removingCoupon" class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="margin-left: 15px; margin-top: auto; margin-bottom: auto;"></span></div>
              </div>
            </div>
          </div>
        </div>
        <hr class="mx-4">
      </ng-container>

      <div *ngIf="details.productType == 'product'" class="section">
        <div class="collapsible d-flex justify-content-center">
          <h5 class="bold m-0">{{'ORDERS.SHIPPINGINFO' | translate}}</h5>
          <!-- <i class="fas fa-chevron-down vertical-align"></i> -->
        </div>
        
        <app-address *ngIf="patientUser && details.paymentStatus != 'paid' && details.paymentStatus != 'error' && details.paymentStatus != 'partial refund' && details.paymentStatus != 'partial credit' && details.paymentStatus != 'credit' && details.paymentStatus != 'Refunded'" [currentOrder]="details" [user]="patientUser" [currentUser]="currentUser"></app-address>
        <div *ngIf="(details.paymentStatus == 'paid' || details.paymentStatus == 'error' || details.paymentStatus == 'partial refund' || details.paymentStatus == 'partial credit' || details.paymentStatus == 'credit' || details.paymentStatus == 'Refunded') && details.shippingAddress">
          <div class="address-card mx-auto">
            <div class="row">
              <div class="col d-flex p-0" style="max-width: fit-content;">
                <img src="../../../assets/images/shippingIcon.svg" alt="">
              </div>
              <div class="col">
                <div class="row align-items-end address-type-edit">
                  <h4 class="m-0 address" *ngIf="details.shippingAddress.AddressType == 'Shipping'">{{'ADDRESS.SHIPPING' | translate}}</h4>                 
                  <h4 class="m-0 address" *ngIf="details.shippingAddress.AddressType == 'Billing'">{{'ADDRESS.BILLING' | translate}}</h4>                 
                </div>
                <div class="row mt-2">
                  <h4 class="address">{{details.shippingAddress.AddressLine1}} {{details.shippingAddress?.AddressLine2}}</h4>
                  <h5 class="address">{{details.shippingAddress.AddressCity}}, {{details.shippingAddress.AddressState}} {{details.shippingAddress.AddressZipcode}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <hr *ngIf="details.productType == 'product'" class="mx-4">
        <div class="collapsible d-flex justify-content-center flex-column">
          <h5 class="bold m-0">{{'ORDERS.BILLINGINFO' | translate}}</h5>
          <div *ngIf="!card" class="d-flex">
            <h6 class="">{{'CHECKOUT.PAYMENT' | translate}} </h6>
            <a href="javascript: void(0);" class="pl-2" (click)="showModalAddCard(addCard)" style="text-align: start;">
              <h6 class="h6 text-primary"><i class="mdi mdi-plus-circle mr-1"></i>{{'CHECKOUT.ADDPAYMENT' | translate}}</h6>
            </a>
          </div>
          <span class="mb-1">{{'ORDERS.CARDREGISTER' | translate}}:</span>
          <div *ngIf="card && details.paymentStatus == 'pending'" class="items d-flex mb-1">
            <ng-select class="shipping-select checkoutSelect w-100"  placeholder="{{'CHECKOUT.SELECTCARD' | translate}}" [multiple]="false" [clearable]="false" [searchable]="false" (change)="onItemChange($event)" [(ngModel)]="creditCardRef">
              <ng-option *ngFor="let item of allPaymentMethods" [value]="item">
                <div class="card-box d-flex">
                  <img class="cards-img" src="assets/images/cards/visa-color-logo.png" *ngIf="item.brand === 'Visa' || item.brand === 'VISA'" alt="Visa" width="20"/>
                  <img class="cards-img" src="assets/images/cards/mastercard-logo.png" *ngIf="item.brand === 'Mastercard' || item.brand === 'MASTERCARD'" alt="MasterCard" width="20"/>
                  <img class="cards-img" src="assets/images/cards/american_express_01.png" *ngIf="item.brand === 'American Express' || item.brand === 'AMERICAN_EXPRESS'" alt="American Express" width="20"/>
                  <img class="cards-img" src="assets/images/cards/discover.png" *ngIf="item.brand === 'Discover Club' || item.brand === 'DISCOVER'" alt="Discover" width="20"/>
                  <img class="cards-img" src="assets/images/cards/jcb_01.png" *ngIf="item.brand === 'JCB' || item.brand === 'JCB'" alt="JCB" width="20"/>
                  <img class="cards-img" src="assets/images/cards/diners_club_01.png" *ngIf="item.brand === 'Discover' || item.brand === 'DISCOVER_DINERS'" alt="Diners Club" width="20"/>
                  <img class="cards-img" src="assets/images/cards/china_union_01.png" *ngIf="item.brand === 'UnionPay' || item.brand === 'CHINA_UNIONPAY'" alt="UnionPay" width="20"/>
                  <span class="card-text">{{item.brand}}-{{item.last4}} {{'CHECKOUT.EXPIRES' | translate}} {{item.expDate}}</span>
                </div>  
              </ng-option>
            </ng-select>
          </div>

          <div *ngIf="details.acceptaID" class="my-2">
            <div class="card-box d-flex" [ngClass]="paymentMethod?.brand === 'Visa' || paymentMethod?.brand === 'VISA' ? 'visa' : 'allother'">
              <img class="cards-img" src="assets/images/cards/visa-logo.png" *ngIf="paymentMethod?.brand === 'Visa' || paymentMethod?.brand === 'VISA'" alt="Visa" height="10"/>
              <img class="cards-img" src="assets/images/cards/mastercard-logo.png" *ngIf="paymentMethod?.brand === 'Mastercard' || paymentMethod?.brand === 'MASTERCARD'" alt="MasterCard" width="20"/>
              <img class="cards-img" src="assets/images/cards/american_express_01.png" *ngIf="paymentMethod?.brand === 'American Express' || paymentMethod?.brand === 'AMERICAN_EXPRESS'" alt="American Express" width="20"/>
              <img class="cards-img" src="assets/images/cards/discover.png" *ngIf="paymentMethod?.brand === 'Discover Club' || paymentMethod?.brand === 'DISCOVER'" alt="Discover" width="20"/>
              <img class="cards-img" src="assets/images/cards/jcb_01.png" *ngIf="paymentMethod?.brand === 'JCB' || paymentMethod?.brand === 'JCB'" alt="JCB" width="20"/>
              <img class="cards-img" src="assets/images/cards/diners_club_01.png" *ngIf="paymentMethod?.brand === 'Diners' || paymentMethod?.brand === 'DISCOVER_DINERS'" alt="Diners Club" width="20"/>
              <img class="cards-img" src="assets/images/cards/china_union_01.png" *ngIf="paymentMethod?.brand === 'China UnionPay' || paymentMethod?.brand === 'CHINA_UNIONPAY'" alt="UnionPay" width="20"/>
              <div class="d-flex flex-column" style="margin: auto 0;">
                <div class="d-flex flex-row">   
                  <span class="ml-1 mr-1"><div class="dots"></div> <div class="dots"></div> <div class="dots"></div> <div class="dots"></div></span>
                  <span class="ml-1 mr-1"><div class="dots"></div> <div class="dots"></div> <div class="dots"></div> <div class="dots"></div></span>
                  <span class="ml-1 mr-1"><div class="dots"></div> <div class="dots"></div> <div class="dots"></div> <div class="dots"></div></span>
                  <span class="font-size-18">{{paymentMethod?.last4}}</span>
                </div>
                <span class="font-size-16 ml-2">{{paymentMethod?.expDate}}</span>
              </div>
            </div>
          </div>
          
          
          <div *ngIf="(consult?.Status == 'In Progress' && details?.productType != 'service')">
            <p style="color: red;">{{'ORDERS.STATUS.CONSULTNOTCLOSED' | translate}}</p>
          </div>
          <div *ngIf="consult?.Status != 'Completed' && details?.productType == 'service' && !consult?.patientSignature && currentUser?.accountType == 'admin'">
            <p style="color: red;">El paciente no está en el carrito y no ha firmado.</p>
          </div>
          <div *ngIf="(consult?.Status == 'Open' || consult?.Status == 'Draft') && details.productType == 'service' && currentUser?.accountType == 'patient'">
            <p class="mt-3 mb-1">{{'ORDERS.STATUS.OPENCONSULT' | translate}}
            </p>
            <a class="btn btn-info d-flex justify-content-center mx-auto mb-2" style="width: fit-content;" [href]="['../consultations?consultation=' + consult.ID]">{{'ORDERS.STATUS.GOTOTREATMENT' | translate}}</a>
          </div>
          <div *ngIf="details.orderState == 'create' && card && creditCardRef != undefined && details.paymentStatus == 'pending' && !details.processing" class="d-flex w-100 mt-3 mb-3">
            <button *ngIf="details.orderState == 'create'" disabled id="sync" class="w-50 btn btn-cyan mx-auto d-flex flex-row justify-content-center align-items-center"><span class='spinner-border customSpinner mr-2'></span>{{'RELOAD.SYNCING' | translate}}</button>
          </div>
          <div *ngIf="card && creditCardRef != undefined && details.orderState == 'ready' && (consult?.Status == 'Completed' || details.refill == false || (consult && (consult?.Status != 'Open' && consult?.Status != 'Draft') && details.productType == 'service')) && details.paymentStatus == 'pending' && !details.processing" class="d-flex w-100 mt-3 mb-3">
            <button *ngIf="!details.refill" id="approval-btn" class="w-50 btn btn-cyan mx-auto scale" (click)="finalRefactor()">{{'ORDERS.PAY' | translate}}</button>
          </div>
          <div *ngIf="card && creditCardRef != undefined  && details.orderState == 'ready' && details.paymentStatus == 'pending' && !details.processing && details.refill && details.productType != 'service'" class="d-flex w-100 mt-3 mb-3">
            <button *ngIf="details.refill" id="approval-btn" class="w-50 btn btn-cyan mx-auto scale" (click)="finalRefactor()">{{'ORDERS.PAY' | translate}}</button>
          </div>
          
          <div *ngIf="card && creditCardRef != undefined && consult?.Status == 'Completed' && details.paymentStatus == 'pending' && details.processing" class="d-flex w-100 mt-3 mb-3">
              <button id="approval-btn" class="w-100 btn btn-cyan mx-auto" disabled><span> Payment is being processed </span><span class='spinner-border spinner-border-sm'></span></button>
          </div>
          <div *ngIf="details.paymentStatus == 'expired'" class="w-100 d-flex justify-content-center">
            <a class="btn btn-cyan mx-auto" [href]="['../consultations/chooseConsult']">{{'ORDERS.STATUS.GETNEWPRESCRIPTION' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="refillReasons" class="">
    <app-refill-before-reasons [order]="details" (proccess)="refillCanBeProcessed($event)"></app-refill-before-reasons>
  </div>
</div>

<ng-template #addCard style="max-height: 300px !important" let-modal>
  <app-add-payment [user]="currentUser" [name]="currentUser.firstName" [lastName]="currentUser.lastName1" [modalRef]="this.modalRef" [order]="details"></app-add-payment>
</ng-template>

<ng-template #orderPaymentHistoryRef let-modal>
  <div class="w-100 p-3 d-flex justify-content-between topbar">
    <h3 class="mx-auto">{{'ORDERS.ORDERS' | translate}} {{details.orderNo}}</h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="collapsible d-flex justify-content-between">
    <span class="">{{'ORDERS.PAYMENTHISTORY' | translate}}</span>
    <!-- <i class="fas fa-chevron-down vertical-align"></i> -->
  </div>

  <div class="details">
    <div class="content d-flex m-0">
      <span style="width: 20%;" class="mx-2">{{'ORDERS.BRAND' | translate}}</span>
      <span style="width: 20%;" class="mx-2">{{'ORDERS.LAST4' | translate}}</span>
      <span style="width: 20%;" class="mx-2">{{'ORDERS.PAYMENTSTATUS' | translate}}</span>
      <span style="width: 20%;" class="mx-2">{{'ORDERS.DATE' | translate}}</span>
      <span style="width: 20%;" class="mx-2">{{'ORDERS.AMOUNT' | translate}}</span>
    </div>
    <div *ngFor="let payment of paymentHistory" class="content m-0 d-flex payments align-items-center justify-content-center">
      <div style="width: 20%;" class="mx-2">
        <img class="" src="assets/images/cards/visa-color-logo.png" *ngIf="payment.brand === 'Visa' || payment.brand === 'VISA'" alt="Visa" width="20"/>
        <img class="" src="assets/images/cards/mastercard-logo.png" *ngIf="payment.brand === 'Mastercard' || payment.brand === 'MASTERCARD'" alt="MasterCard" width="20"/>
        <img class="" src="assets/images/cards/american_express_01.png" *ngIf="payment.brand === 'American Express' || payment.brand === 'AMERICAN_EXPRESS'" alt="American Express" width="20"/>
        <img class="" src="assets/images/cards/discover.png" *ngIf="payment.brand === 'Discover Club' || payment.brand === 'DISCOVER'" alt="Discover" width="20"/>
        <img class="" src="assets/images/cards/jcb_01.png" *ngIf="payment.brand === 'JCB' || payment.brand === 'JCB'" alt="JCB" width="20"/>
        <img class="" src="assets/images/cards/diners_club_01.png" *ngIf="payment.brand === 'Discover' || payment.brand === 'DISCOVER_DINERS'" alt="Diners Club" width="20"/>
        <img class="" src="assets/images/cards/china_union_01.png" *ngIf="payment.brand === 'UnionPay' || payment.brand === 'CHINA_UNIONPAY'" alt="UnionPay" width="20"/>
      </div>
      <span style="width: 20%;" class="mx-2">x{{payment.last4}}</span>


      <div style="width: 20%;" class="mx-2">
        <i *ngIf="payment.paymentStatus === 'paid'" class="far fa-check-circle text-success mr-1"></i>
        <i *ngIf="payment.paymentStatus === 'Refunded' || payment.paymentStatus === 'partial refund' || payment.paymentStatus === 'partial credit' || details.paymentStatus == 'credit'" class="fas fa-undo-alt mr-1"></i>
        <i *ngIf="payment.paymentStatus === 'Chargeback'" class="far fa-times-circle text-danger mr-1"></i>
        <i *ngIf="payment.paymentStatus === 'error' || payment.paymentStatus == 'expired'" class="far fa-times-circle text-danger mr-1"></i>
        <i *ngIf="payment.paymentStatus === 'pending'" class="far fa-clock text-warning mr-1"></i>
        <span *ngIf="payment.paymentStatus == 'paid'" class="mb-0">{{'ORDERS.STATUS.PAID' | translate | titlecase}}</span>
        <span *ngIf="payment.paymentStatus == 'Refunded'" class="mb-0">{{'ORDERS.STATUS.REFUNDED' | translate | titlecase}}</span>
        <span *ngIf="payment.paymentStatus == 'partial refund'" class="mb-0">{{'ORDERS.STATUS.PARTIALREFUND' | translate | titlecase}}</span>
        <span *ngIf="payment.paymentStatus == 'error'" class="mb-0">{{'ORDERS.STATUS.CANCELLED' | translate | titlecase}}</span>
        <span *ngIf="payment.paymentStatus == 'partial credit'" class="mb-0">{{'ORDERS.STATUS.PARTIALCREDIT' | translate | titlecase}}</span>
        <span *ngIf="payment.paymentStatus == 'credit'" class="mb-0">{{'ORDERS.STATUS.CREDIT' | translate | titlecase}}</span>
        <span *ngIf="payment.paymentStatus == 'Chargeback'" class="mb-0">{{'ORDERS.STATUS.CHARGEBACK' | translate | titlecase}}</span>
        <span *ngIf="payment.paymentStatus == 'pending'" class="mb-0">{{'ORDERS.STATUS.PENDING' | translate | titlecase}}</span>
        <span *ngIf="payment.paymentStatus == 'expired'" class="mb-0">{{'ORDERS.STATUS.EXPIRED' | translate | titlecase}}</span>
      </div>
     

      <span style="width: 20%;" class="mx-2">{{payment.date.toDate() | date:'shortDate'}}</span>
      <span style="width: 20%;" class="mx-2 ">{{payment.amount | currency:'USD'}}</span>
    </div>

    <div *ngIf="paymentHistory.length == 0" class="" style="margin:15%">
      <h3 class="no-payments">{{'ORDERS.NOPAYMENTS' | translate }}</h3>
    </div>
  </div>
</ng-template>

<ng-template #firstOrderTerms let-modal>
  <div class="p-3 d-flex justify-content-between ml-auto" style="width: fit-content; padding-bottom: 0 !important;">
    <button type="button" class="close" aria-label="Close" (click)="modal.close(); modalRef2 = undefined">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="">
    <page size="A4">
      <div class="w-100 d-flex">
        <h3 class="mx-auto justify-content-center">{{'ORDERS.PRODUCTSTOBEDISPATCHED' | translate}}</h3>
      </div>
      <div class="w-100 d-flex flex-column" style="padding: 0 5%;">
        <div class="content mb-3">
          <div *ngFor="let product of orderLines" [ngClass]="product.type !== 'product' ? 'not-a-product' : 'green'"  class="d-flex refillProducts mt-2 mb-1 product-div flex-row">
            <img style="height: 70px; width: auto;" *ngIf="product.type == 'product'" src="{{product.image | translation:lang}}" alt="">
            <div class="d-flex w-100 justify-content-center">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <span class="ml-3 product-name">{{product.productName | translation:lang}}</span>

                <!-- <div class="d-flex flex-column">
                  <div *ngIf="consult?.Status == 'Completed' && details.paymentStatus == 'pending' && product.type == 'product' && product.treatment == false" class="d-flex">
                    <label for="{{product.productID}}" class="inp mb-0">
                      <span  *ngIf="product.daw == 1" class="label ml-3 mb-2">{{'ORDERS.ASPRESCRIBED' | translate}}</span>
                      <input *ngIf="product.daw == 0 || product.daw == 2" class="ml-3" type="checkbox" id="{{product.productID}}" (click)="fixedDAW(product)" [checked]="setDAW(product)">        
                      <span  *ngIf="product.daw == 0 || product.daw == 2" class="label ml-2 mb-2">{{'ORDERS.ASPRESCRIBEDOREQUIVALENT' | translate}}</span>
                      <span class="focus-bg"></span>
                    </label>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="doc-footer" style="padding: 0 5%;">
        <div class="d-flex w-100 justify-content-start mt-2 flex-row">
          <label for="daw" class="checkbox-container">
            <input class="w-auto h-auto" type="checkbox" id="daw" style="position: relative;" (click)="setOrientation($event.target.checked)" [(ngModel)]="details.orientation">        
            <span class="checkmark" style="min-width: 13px; width: 13px; height: 13px;top: 2px; position: relative;"></span>
            <span class="label ml-2 font-size-13">{{'ORDERS.ORIENTATION' | translate}}</span>
          </label>  
        </div>

        <div class="mt-2">
          <div class="d-flex flex-row mb-3">
            <label for="signature" class="checkbox-container1">
              <input class="w-auto h-auto mr-2" type="checkbox" id="signature" (click)="electronicSignature()" [disabled]="details.patientSignature" [attr.disabled]="details.patientSignature">
              <span class="checkmark1" style="min-width: 13px; width: 13px; height: 13px;top: 2px; position: relative;"></span>
              <p class="m-0 ml-2 font-size-14">{{'ORDERS.DIGITALSIGNATUREORDERS' | translate}}</p>
            </label>  
          </div>
          <p class="m-0 font-size-12 orangeText" *ngIf="signature">Digitally Signed by: {{signature}} Ref: {{signRef}}</p>
          <p class="m-0 font-size-12" *ngIf="signature">Date: {{dateCertified | date:'MMMM dd, yyyy hh:mm aa'}}</p>
          <hr class="signature-line w-100">
          <span>{{'ORDERS.PATIENTSIGNATURE' | translate}}</span>
        </div>
        <div class="d-flex justify-content-end mt-4 ml-auto" style="width: fit-content;">
          <div *ngIf="signature" class="mr-2 pb-3">
            <button id="complete-and-pay-btn" class="send-prescription" (click)="disableCompleteAndPayButton(); test()">{{'ORDERS.COMPLETEANDPAY' | translate}}</button>
          </div>
        </div>
      </div>
    </page>
  </div>
</ng-template>


<ng-template #signaturePad let-modal>
  <i class="ri-close-fill" style="font-size: 23px; text-align: end;" (click)="modal.close('Cross click')"></i>
  <app-signaturepad (signature)="storeSignature($event)"></app-signaturepad>
</ng-template>


<ng-template #orderChangeProduct let-modal>
  <app-order-change-product [productToChange]="productToChange" [orderLineProduct]="orderLineProduct" [consult]="consult" [order]="details" (closeChangeProduct)="modal.close(); modalRef2 = undefined;" (closeAll)="redirectToConsults()" (selectedProduct)="productSelected = $event"></app-order-change-product>
</ng-template>


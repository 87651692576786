<div class="main">
  <div class="heading">
    <!-- <svg width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.7 3h6.6c.3 0 .5.1.7.3L20.7 8c.2.2.3.4.3.7v6.6c0 .3-.1.5-.3.7L16 20.7c-.2.2-.4.3-.7.3H8.7c-.3 0-.5-.1-.7-.3L3.3 16c-.2-.2-.3-.4-.3-.7V8.7c0-.3.1-.5.3-.7L8 3.3c.2-.2.4-.3.7-.3v0Z"></path>
      <path d="M12 8v4"></path>
      <path d="M12 16h.01"></path>
    </svg> -->
    <div class="d-flex flex-column">
      <h3 class="title">{{'REFILLS-BEFORE-REASONS.TITLE' | translate}}</h3>
      <p>{{'REFILLS-BEFORE-REASONS.DESCRIPTION' | translate}}</p>
    </div>
  </div>

  <div class="warning d-flex">
    <svg width="40" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 8a3 3 0 0 1 0 6"></path>
      <path d="M10 8v11a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5"></path>
      <path d="m12 8.002 4.524-3.77A.9.9 0 0 1 18 4.924V17.08a.9.9 0 0 1-1.476.692L12 14.002H4a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h8"></path>
    </svg>
    <p class="ml-2 mb-0">{{'REFILLS-BEFORE-REASONS.WARNING' | translate}}</p>
  </div>

  <h5 class="title mt-4">{{'REFILLS-BEFORE-REASONS.REASONS' | translate}}</h5>
  <div class="options">
    <div class="d-flex flex-column" *ngFor="let reason of reasons">
      <!-- <input class="inp-cbx" type="checkbox" name="radio-card" id="{reason.id}}" [checked]="labChosen.diagnostics.includes(diagnostic)" (click)="addLabDiagnostic(diagnostic, $event)"/> -->
      <input class="inp-cbx" type="checkbox" name="radio-card" id="{{reason}}" (click)="addReason(reason)"/>
      <label for="{{reason}}" class="cbx">
        <span>
          <svg width="12px" height="10px">
            <use xlink:href="#check"></use>
          </svg>
        </span>
        <span class="ml-1">{{reason}}</span>
      </label>
    </div>

    <svg hidden class="inline-svg">
      <symbol id="check" viewBox="0 0 12 10">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
      </symbol>
    </svg>
  </div>

  <div id="warn" class="warn">
    <p class="mb-0">{{'REFILLS-BEFORE-REASONS.WARN' | translate}}</p>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <button class="btn btn-info" (click)="storeReasons()" [disabled]="selectedReasons.length == 0">{{'REFILLS-BEFORE-REASONS.SUBMIT' | translate}}</button>
  </div>

</div>
<div class="col-lg-12" [ngClass]="{'background-cyan': background == 'cyan'}">
  <div class="form-group row justify-content-center align-items-center flex-column m-0 mt-2">
    <div class="form-group d-flex flex-column justify-content-center m-0 fade-in">
      <h2 class="fade-in text-center questionFont">{{'SIGNUP.PROVIDESTATE' | translate}}</h2>
      <h6 class="px-2" style="max-width: 500px;">{{'SIGNUP.CHOOSELOCATIONTEXT' | translate}}</h6>
      <div class="row mb-1 mx-auto justify-content-center">
        <div class="grid-wrapper grid-col-3 col-md-6" >
          <div *ngFor="let location of locations" class="selection-wrapper animate__animated animate__fadeInDown mt-3" >
            <label *ngIf="currentLocation" for="{{location.code}}" class="selected-label answers animate w-100" [ngClass]="{'selectedCountry': currentLocation && currentLocation.code == location.code}">
              <input class="selects" type="radio" (click)="selectLocationWarn(location);" id="{{location.code}}" [ngModel]="currentLocation.code">
              <div class="d-flex justify-content-center flex-row" >
                <div class="answer-card lab-answer-card">
                  <span class="check-icon" ></span>
                  <div class="text ml-2">
                    <p class="m-0 optionOfQuestions optionFont">{{location.name}}</p>
                  </div>
                </div>
              </div>  
            </label>
            <hr *ngIf="location.code != locations[locations.length - 1].code" class="my-2 animate" style="border-bottom: 3px dotted #ced4da; border-top: 0; height: 100%; width: 100%; margin: 0;">
          </div>
        </div>
      </div>
    </div> 
  </div>
</div>

<app-reload *ngIf="loading" [message]="'RELOAD.CHANGING_LOCATION'"></app-reload>
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { v4 as uuidv4 } from 'uuid';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { EmailsService } from 'src/app/core/services/emails.service';
import { AuthenticatorService } from '../../authenticator/authenticator.service';
import { Profile } from 'src/app/core/services/interfaces';
import { TranslationPipe } from 'src/app/translation.pipe';
import { AddEventComponent } from '../add-event/add-event.component';
import { EncryptService } from 'src/app/core/services/encrypt.service';
import firebase from "firebase/compat/app";
import { DatePipe, formatDate } from '@angular/common';
import { TelehealthService } from '../../telehealth/telehealth.service';
import { PrescriptionService } from 'src/app/core/services/prescription.service';
import { Subscription } from 'rxjs';
import { fbRemoteConfig } from '../../../authUtils'
import { ConsultHistory } from 'src/app/core/interfaces/history';
import { MEDICAL_CONSULTATION } from 'src/app/core/enums/process-history';

@Component({
  selector: 'app-viewconsult',
  templateUrl: './viewconsult.component.html',
  styleUrls: ['./viewconsult.component.scss']
})

export class ViewconsultComponent implements OnInit, OnDestroy {
  @Input() consultID:any;
  @Input() prescriptionID:any;
  @ViewChild('signaturePad', { read: TemplateRef }) signaturePadModal:TemplateRef<any>;
  @ViewChild('noPrescriptionModal', { read: TemplateRef }) noPrescriptionModal:TemplateRef<any>;
  @ViewChild('scheduler', { read: TemplateRef }) rescheduleModal:TemplateRef<any>;
  @ViewChild('profileUpdate', { read: TemplateRef }) profileUpdate:TemplateRef<any>;
  @ViewChild('transferModal', { read: TemplateRef }) transferModal:TemplateRef<any>;
  @ViewChild('transferModalAction', { read: TemplateRef }) transferModalActionRef:TemplateRef<any>;

  closingDate:any = new Date();
  closingTitle:string;
  closingMessage:string;
  appointment;

  closingSignature:any;
  closingImg = '<en>../assets/images/finalSignature_en.svg</en><es>../assets/images/finalSignature_es.svg</es>'

  pdf:any;

  btnTitles = 'VIEWCONSULTS.ALLQUESTIONS';

  translationPipe = new TranslationPipe()
  loading:boolean = false;

  remoteConfig = fbRemoteConfig.defaultConfig;
  collaboratives:any[];
  collaborator = false;
  allSubscriptions = new Subscription()

  lastIDReminderMsg: any = '';

  practitionerProfile:any;
  currentUser: any;
  user:any;
  consult:any;
  transferInfo: any;
  patientProfile: any;
  consultQuestions:any;
  notes: any;
  isQuestionsLoaded:boolean = false;
  
  age:any;
  fullName: string;
  bioGender: any;
  patientProfileImg: any;

  //Prescription (Rx)
  prescriptionItems:any;
  selectedService = [];
  selectedWishlist = [];

  //notes
  $form = document.getElementById("#form");
  history = [];
  $notes = document.querySelector <HTMLElement>("#notes");
  $noteTitle = document.querySelector <HTMLInputElement>("#note-title");
  $noteText = document.querySelector<HTMLElement>("#note-text");
  $formButtons = document.querySelector<HTMLElement>("#form-buttons");
  $formCloseButton = document.querySelector<HTMLElement>("#form-close-button");
  $modal = document.querySelector<HTMLElement>(".modal");
  $modalTitle = document.querySelector<HTMLInputElement>(".modal-title");
  $modalText = document.querySelector<HTMLInputElement>(".modal-text");
  $modalCloseButton = document.querySelector<HTMLElement>(".modal-close-button");

  title = "";
  text = "";
  referrals: any; //[];
  editableNote:any[] = [];

  modalRef:any;

  //for Swal modal
  swal

  //Diagnostics Tab
  loadingDiagnostics: Boolean = false;
  filteredDiagnostics:any[] = [];
  selectedDiagnostics:any;
  editDiagnostic = false;
  editMode = false;
  selectedDiagnostic: any = {code: '', recommendation: '', assessment: ''};
  assessment = ''
  recommendation = ''

  allConsults = [];
  prevConsult = [];
  prevConsultQuestion = [];
  prevDiagnostics = [];
  consultMeetings = []
  allPatientConsults = true;

  currentLang
  
  notePlaceholder
  ckeditorplaceholder = "none"
  placeholder = this.translate.get('NOTIFICATIONS.STARTMESSAGE').subscribe((res: any) => {
    if(res != undefined){
      this.ckeditorplaceholder = res
    }
  })

  allergies:any = [];

  public model = {
    editorData: "",
    text: ''
  };

  pastPractitioners //Practitioners that abadoned the consult
  practitioners:Profile[] = [];

  verifyFields = []
  profileFields = [
    {field: 'PROFILE.PROFILEPICTURE'},
    {field: 'PROFILE.FIRSTNAME'},
    {field: 'PROFILE.LASTNAME1'},
    {field: 'PROFILE.PHONE'},
    {field: 'PROFILE.IDFRONT'},
    {field: 'PROFILE.IDNUMBER'},
    {field: 'PROFILE.EXPDATE'},
    {field: 'PROFILE.IDTYPE'},
    {field: 'PROFILE.COUNTRY'},
    {field: 'PROFILE.STATE'},
  ];

  transferDoc:Profile;
  transferReason:string = "";
  transferOption:string = "Temporarly transfer this consult";

  claims;

  constructor(
    private afs: AngularFirestore,
    public firebaseService: FirebaseService,
    private modalService: NgbModal,
    public afAuth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
    public route: Router,
    private http: HttpClient,
    public translate: TranslateService,
    public formBuilder: UntypedFormBuilder,
    private authService: AuthenticationService,
    private emailService: EmailsService,
    private authenticatorService: AuthenticatorService,
    private sanitized: DomSanitizer,
    private encryptService: EncryptService,
    private telehealthService: TelehealthService,
    private prescriptionService: PrescriptionService,
    private cd: ChangeDetectorRef,
  ) {
    this.getUser(); 

    this.currentLang = translate.store.currentLang;
    this.activatedRoute.params.subscribe(params => {
      this.consultID = params.id;

      if(params.prescription != undefined){
        this.prescriptionID = params.prescription;
      }
    });

    if(!this.consultID){
      let tempConsultID = this.route.url.split('consults/')[1]
      this.consultID = tempConsultID.split('/')[0];
    }
  }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe()
  }

  async ngOnInit(){
    var docRef = this.afs.firestore.collection("consultation").doc(this.consultID);

    docRef.get().then(async(doc) => {
        if (doc.exists) {

          let consult = doc.data();

          this.consult = consult;

          //bring user profile
          this.getPatientProfile(consult['UID_Patient']);
          this.getServiceAndWishlist();

          if(this.consult.transfered == 'Temporarly'){
            this.afs.firestore.collection("consultation").doc(this.consultID).collection('History').where('transfer', '==', true).get().then(res => {
              if(res.docs){
                this.transferInfo = res.docs[res.docs.length - 1].data()
              }
            })
          }

          if(this.consult.schedule?.time instanceof Array){
            let array = []
            this.consult.schedule.time.forEach(ans => {
              array.push(this.translationPipe.transform(ans, this.currentLang));
            });   
            this.consult.schedule.time = array.join(" - ");
          }

          if(this.consult.schedule?.type instanceof Array){
            let array = []
            this.consult.schedule.type.forEach(ans => {
              array.push(this.translationPipe.transform(ans, this.currentLang));
            });   
            this.consult.schedule.type = array.join(" - ");
          }
                      
          this.getConsultQuestions();
          if(consult['UID_Practitioner']){
            this.getPractitionerProfile(consult['UID_Practitioner'])
            this.getConsultMeetings(consult['UID_Practitioner']);
          }

          this.getNotes();
          this.getConsultItems();
          
          if(this.currentUser.accountType == 'admin'){
            this.getHistory();
          }

          this.allergies = await this.getPatientAllergies();  
          
          this.showPdf(consult);

          this.cd.detectChanges();

        }
        else {

        }

    })
    .catch((error) => {
        
    });

    this.afs.firestore.collection("consultation").doc(this.consultID).collection('Diagnostics').orderBy('id', 'desc').get().then((querySnapshot) => {
      const diagnostics = [];

      querySnapshot.forEach((doc) => {

        var diagnosticData = doc.data();
        diagnostics.push(diagnosticData);

      });

      this.selectedDiagnostics = diagnostics;

    });

    // this.afs.firestore.collection("consultation").doc(this.consultID).collection('History').where('action', '==', 'Abandoned').get().then((querySnapshot) => {
    //   const practitioners = [];

    //   querySnapshot.forEach((doc) => {

    //     var practitionersData = doc.data();
    //     practitioners.push(practitionersData);

    //   });

    //   this.pastPractitioners = practitioners;

    // });

    this.translate.get('VIEWCONSULTS.NOTES.TAKENOTE').subscribe((res: any) => {
      this.notePlaceholder = res
    });

    this.claims = await this.authService.getClaims();
    this.getCollaboratives();

    window.addEventListener("orientationchange", () => {
      this.dismiss();
      setTimeout(res => {
        if(this.route.url.includes(`${this.consult.ID}/closingSignature`) && this.pdf == undefined){
          this.signPad();
        }
      }, 400)
    });

    this.cd.detectChanges();

  }

  getServiceAndWishlist(){
    this.afs.firestore.collection('consultation').doc(this.consultID).collection('wishlist').get().then((doc) => {
      this.selectedWishlist = doc.docs.map(doc => doc.data()).filter(x => x.type == 'product');
    
      if(this.selectedWishlist.length > 0 && this.selectedWishlist[0].id == 980){
        this.afs.firestore.collection('consultation').doc(this.consultID).collection('Items').get().then((doc) => {
          this.selectedService = doc.docs.map(doc => doc.data()).filter(x => x.productPromo);
          console.log(this.selectedService, doc.docs.map(doc => doc.data()), this.selectedWishlist)
        })
      }
    })
  }

  goBack(){
    this.route.navigate(['/consults'])
  }

  showPdf($event){
    this.consult = $event;
    if(this.consult.pdf){
      this.pdf = this.sanitized.bypassSecurityTrustResourceUrl(this.consult.pdf);
      if(this.pdf !== undefined){
        this.closingTitle = "VIEWCONSULTS.MESSAGE";
        this.closingDate = this.consult.authenticatorValidationDate && this.consult.authenticatorValidationDate.seconds ? new Date(this.consult.authenticatorValidationDate.seconds * 1000) : this.consult.authenticatorValidationDate ? new Date(this.consult.authenticatorValidationDate) : new Date();
      }else{
        this.closingTitle = "VIEWCONSULTS.FINISH";
        this.closingMessage = "VIEWCONSULTS.COMPLETECONSULT"; 
      }
    }else{
      this.closingTitle = "VIEWCONSULTS.FINISH";
      this.closingMessage = "VIEWCONSULTS.COMPLETECONSULT"; 
    }
  }

  userTimezone
  getUser() {
    this.afAuth.user.subscribe(user => {
      this.user = user;
      if(user){
        this.authService.userSubscription.subscribe(res => {
          this.currentUser = res})
      }
    });
  }

  getReferrals(){
    return new Promise((resolve, reject) => {
      this.afs.firestore.collection('referrals').where('consultID', '==', this.consultID).get().then((querySnapshot) => {
        const referrals = [];
  
        querySnapshot.forEach((doc) => {
  
          var referralsData = doc.data();
          referrals.push(referralsData);
  
        });
  
        this.referrals = referrals;
        resolve(referrals)
      });
    })
  }

  getPosibleCollaborators(){
    let sub = this.prescriptionService.getPractitioners().subscribe(data => {
      this.practitioners = []
      for(let i = 0; i < data.length; i++){
        if(data[i].typeOfPractitioner){
          for(let j = 0; j < data[i].typeOfPractitioner.length; j++) {
            if(this.consult.typeOfPractitioner.includes(data[i].typeOfPractitioner[j])){
              this.practitioners.push(data[i])
              break;
            }
          }
        }
      }
      this.practitioners = this.practitioners.filter(x => x.status == "Verified" && x.isAccountDisabled == false && x.uid != this.consult.UID_Practitioner);

    });
    this.allSubscriptions.add(sub);
  }

  patientAddress;
  async getPatientAddress(){
    this.afs.firestore.collection('consultation').doc(this.consultID).collection('Address').doc("Shipping").get().then((querySnapshot) => {
      this.patientAddress = querySnapshot.data();
    })
  }

  getPatientProfile(patient){

    var docRef = this.afs.firestore.collection('users').doc(patient);

    docRef.get().then((doc) => {
        if (doc.exists) {

          var data = doc.data();

          this.patientProfile = data;
          this.fullName = data.firstName + " " + data.middleName + " " + data.lastName1;
          this.patientProfileImg = data.image;
          this.age = this.calculateAge();
          this.bioGender = data['biologicalgender'];
          this.getAllConsults();

          this.getPatientAddress()

          this.afs.firestore.collection('mail').where('toUID', '==', patient).where('template.name', 'in', ['IDRequired_en', 'IDRequired_es']).get().then((querySnapshot) => {
            const mailData = [];
            querySnapshot.forEach((doc) => {
              mailData.push(doc.data());
            });

            mailData.sort((a, b) => {
              return (b.delivery && b.delivery.startTime) - (a.delivery && a.delivery.startTime);
            });

            if (mailData.length > 0) {
              this.lastIDReminderMsg = mailData[0].delivery.startTime && mailData[0].delivery.startTime.seconds ? new Date(mailData[0].delivery.startTime.seconds * 1000) : mailData[0].delivery.startTime ? new Date(mailData[0].delivery.startTime) : '';
            } else {
              // console.log('No mail data found for the specified criteria.');
            }
          })
          .catch((error) => {
            // console.error('Error fetching mail data:', error);
          });

        } else {
            // doc.data() will be undefined in this case
        }
    }).catch((error) => {
    });

  }

  getPractitionerProfile(practitioner){
    let sub = this.afs.collection('users').doc(practitioner).valueChanges().subscribe(data => {
      this.practitionerProfile = data;
      this.userTimezone = 'UTC' + this.practitionerProfile.timezone.timeValue

      if(this.practitionerProfile.accountType == 'practitioner'){
        let abbr = this.translationPipe.transform(this.practitionerProfile.abbr, this.practitionerProfile.preferedLang);
        
        this.practitionerProfile.abbr = this.practitionerProfile.preferedLang == 'en' ? abbr : this.practitionerProfile.biologicalgender == 'Female' ? this.practitionerProfile.abbr = abbr.split('/la ')[1] : this.practitionerProfile.abbr = abbr.split('el ')[1].split('/')[0]
      }
    })
    this.allSubscriptions.add(sub)
  }

  getHistory(){
    let sub = this.afs.collection('consultation').doc(this.consultID).collection('History').valueChanges().subscribe(history => {
      this.history = history;
    });
    this.allSubscriptions.add(sub)
  }

  getNotes(){

    this.afs.firestore.collection('consultation').doc(this.consultID).collection('Notes').get().then((querySnapshot) => {
      const notes = [];

      querySnapshot.forEach((doc) => {

        var notesData = doc.data();
        notes.push(notesData);

      });

      this.notes = notes;

    });

  }

  prevNotes
  getPrevNotes(noteId){
    let sub = this.afs.collection('consultation').doc(noteId).collection('Notes', ref => ref.where('encrypted', '!=', true)).valueChanges().subscribe(notes => {
      this.prevNotes = notes;
    });
    this.allSubscriptions.add(sub)
  }

  optionStyling(option, index){
    return option == false ? 'answered-icon-hide' : this.filteredStyles[index];
  }

  filteredStyles = []
  styles = []
  getConsultQuestions(){
    let sub = this.afs.collection('consultation').doc(this.consultID).collection('Questions').valueChanges().subscribe(questions => {
      questions = questions.sort(function(a, b) {
        return a.sequence - b.sequence;
      })

      this.consultQuestions = questions.filter(x => x.biologicalGender == undefined || x.biologicalGender.length == 0 || x.biologicalGender.includes(this.patientProfile.biologicalgender));
      this.filteredStyles = this.consultQuestions.map(x => x.points > 0 ? 'answer-icon-risk' : 'answer-icon')
      this.styles = this.filteredStyles;
      this.filteredQuestions = this.consultQuestions;
      this.isQuestionsLoaded = true; 
    });
    this.allSubscriptions.add(sub)
  }

  getAllConsults(){

    this.afs.firestore.collection('consultation').where('UID_Patient', '==', this.patientProfile.uid).where('Status', '==', 'Completed').get().then((querySnapshot) => {
      const consults = [];

      querySnapshot.forEach((doc) => {

        var consultsData = doc.data();
        consults.push(consultsData);

      });

      this.allConsults = consults;

    });

  }

  getPrevConsult(consult, modal){
    this.prevConsult = [consult];
    this.allPatientConsults = false;
    this.abandonReopenConsultModal(modal)
  }

  getConsultMeetings(uid){
    let sub = this.afs.collection('calendar', ref => ref.where('uidPractitioner', '==', uid).where('consultID', '==', this.consultID)).valueChanges().subscribe(meet => {
      this.consultMeetings = meet;
    })
    this.allSubscriptions.add(sub)
  }
  
  filteredQuestions;
  prevMedicalHistory = false
  filterQuestions(){
    this.btnTitles = 'VIEWCONSULTS.ALLQUESTIONS'
    this.scalePosition = -1;
    this.diagnosticsTests = false;
    this.filteredQuestions = !this.prevMedicalHistory ? this.consultQuestions : this.prevConsultQuestion
    this.filteredStyles = this.styles;

    this.filteredQuestions.sort(function(a, b) {
      return a.sequence - b.sequence;
    })
  }

  filterRedFlags(){
   this.btnTitles = 'VIEWCONSULTS.REDFLAGS'
   this.scalePosition = -1;
   this.diagnosticsTests = false;

    let tempConsult = !this.prevMedicalHistory ? this.consultQuestions : this.prevConsultQuestion
    this.filteredQuestions = tempConsult.filter(x => x.points > 0)
    this.filteredStyles = this.filteredQuestions.map(x => 'answer-icon-risk')
  }

  filterExplain(){
    this.btnTitles = 'VIEWCONSULTS.PATIENTEXPLANATION'
    this.scalePosition = -1;
    this.diagnosticsTests = false;
    
    let tempConsult = !this.prevMedicalHistory ? this.consultQuestions : this.prevConsultQuestion
    this.filteredQuestions = tempConsult.filter(x => x.explain || x.type == 'BMI' || x.type == 'BP' || x.type == 'Text')
    this.filteredStyles = this.filteredQuestions.map(x => x.points > 0 ? 'answer-icon-risk' : 'answer-icon')
  }

  diagnosticsTests = false;
  scalePosition = -1;
  filterDiagnosticsTests(event){
    this.btnTitles = event

    this.diagnosticsTests = true;
    this.scalePosition = this.consult.diagnosticsTests.indexOf(event)

    let tempConsult = !this.prevMedicalHistory ? this.consultQuestions : this.prevConsultQuestion
    this.filteredQuestions = tempConsult.filter(x => x.diagnosticsTestId == event || (x.diagnosticsTestId && x.diagnosticsTestId.includes(event)))
    this.filteredStyles = this.filteredQuestions.map(x => x.points > 0 ? 'answer-icon-risk' : 'answer-icon')
  }

  saveNotes(){
    let childNodesLength =  document.getElementsByClassName('editable-div')[0]?.childNodes?.length;
    for(let i = 0; i < childNodesLength; i++){
      this.text += document.getElementsByClassName('editable-div')[0]?.childNodes[i]?.textContent + " ";
    }
    
    let note = {
      title: this.title,
      text: this.text,
      date: new Date(Date.now()),
      id: this.firebaseService.getDate(),
      createdBy: this.user.displayName,
      createdByUID: this.user.uid,
      encrypted: false,
      encrypt: false
    }

    if(this.title !== "" && this.text !== ""){
      this.firebaseService.saveMyNotes(this.consultID, note).then(
        res => {
          for(let i = 0; i < childNodesLength; i++){
            document.getElementsByClassName('editable-div')[0].childNodes[i].textContent =  '';
          }
          this.closeForm();
          this.getNotes();
        }
      );
    }
    return Promise.resolve([note, this.consultID]);
  }

  updateNotes(){
    let childNodesLength =  document.getElementsByClassName('editable-div-modal')[0].childNodes.length;
    let note_data =  this.editableNote[0];
    let text: string = '';
    for(let i = 0; i < childNodesLength; i++){
      text += document.getElementsByClassName('editable-div-modal')[0].childNodes[i].textContent + " " + "\n";
    }

    let note = {
      title: document.getElementsByClassName('modal-title')[0].childNodes[0].textContent,
      text:  text,
      updatedDate: new Date(Date.now()),
      id: note_data.id,
      updatedBy: this.user.displayName,
      updatedByUID: this.user.uid,
      encrypted: false
    }

    this.firebaseService.updateNotes(this.consultID, note).then(
      async res => {
        //Do i encrypt or simply dismiss???? Ask Josue
        if(note_data.encrypt){
          await this.encryptService.encryptNote(note.text, this.user.uid, this.consultID, note.id)
        } 
        this.dismiss();
        this.getNotes()
      }
    );
  }

  async manageEncryption(note){
    if(note.encrypted) {
      let index = this.notes.findIndex(n => n.id == note.id)
      this.notes[index] = Object.assign(this.notes[index], {decrypting: true, innerText: '<en>Decripting</en><es>Decriptando</es>'})

      await this.decryptNote(note).then(n => {
        this.notes[index].encrypted = false;
        this.notes[index].text = n
        this.notes[index].decrypting = false
      })
    }
    else {
      let index = this.notes.findIndex(n => n.id == note.id)
      this.notes[index] = Object.assign(this.notes[index], {decrypting: true, innerText: '<en>Encripting</en><es>Encriptando</es>'})

      await this.encryptService.encryptNote(note.text, this.user.uid, this.consultID, note.id)
      this.getNotes()
    }
  }

  async getConsultItems(){

    const snapshot = await this.afs.collection('prescription', ref => ref.where("consultID", "==", this.consultID).where('valid', '==', true).where('status', '==', 'Signed')).get().toPromise();
    
    this.prescriptionItems = [];
    
    for (const doc of snapshot.docs) {
      
      this.afs.firestore.collection('prescription').doc(doc.id).collection('Items').get().then(res => {
        this.prescriptionItems = this.prescriptionItems.concat(res.docs.map(doc => doc.data()));
      });

    }

  }

  calculateAge(){
    var date = new Date(this.patientProfile.birthdate);
    var today = new Date();
    var timeDiff = Math.abs(today.getTime() - date.getTime());
    var age1 = Math.floor(timeDiff / (1000 * 3600 * 24)) / 365.25;
    var age = Math.floor(age1);
    return age;
  }

  abandonReopenReasons = []
  explanation = ''
  abandonReopenConsultModal(modalRef){
    this.modalService.open(modalRef, {size:"md", centered: true });
  }

  selectReason(event) {
    if (event.target.checked ) {
      this.abandonReopenReasons.push(event.target.id);
    } else {
      let index = this.abandonReopenReasons.indexOf(event.target.id.toString())
      this.abandonReopenReasons.splice(index, 1);
    }
  }

  deniedConsultationByPatient() {
    Swal.fire({
      customClass: {
        confirmButton: 'swalPurple',
        cancelButton: 'btn-light'
      },
      title: 'Deny Consultation',
      text: 'Are you sure you want to deny this consultation?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, deny'
    }).then(async result => {
      if (result.isConfirmed) {
        this.denyConsultation();
      }
    });
  }

  denyConsultation(){
    const htmlMessage = '<en>Hello ' + this.patientProfile.firstName + ' ' + this.patientProfile.lastName1 + '. <br><br>We hope this message finds you well. <br><br>Despite our repeated attempts to reach you regarding your consultation, we have not received any response from you. As a result, we regret to inform you that we will be canceling your consultation and closing your case. If you wish to resume treatment in the future, please feel free to contact our customer service, and we will be happy to assist you with scheduling a new appointment. <br><br>We thank you for your understanding and are always at your disposal. <br><br>' + this.currentUser.firstName + " " + this.currentUser.lastName1  + '<br>Customer Service Specialist<br>' + this.remoteConfig.textNumber + '<br>' + this.remoteConfig.phoneNumber +  '</en><es>Saludos ' + 
    this.patientProfile.firstName + ' ' + this.patientProfile.lastName1 + '. <br><br>Esperamos que este mensaje le encuentre bien. <br><br>A pesar de nuestros intentos repetidos para ponernos en contacto con usted respecto a su consulta, no hemos recibido respuesta alguna. Por lo tanto, lamentamos informarle que procederemos a cancelar su consulta y cerrar su caso. Si desea reanudar el tratamiento en el futuro, no dude en ponerse en contacto con servicio al cliente, y estaremos encantados de ayudarle a programar una nueva cita.  <br><br>Agradecemos su comprensión y quedamos a su disposición.<br><br>' + this.currentUser.firstName + " " + this.currentUser.lastName1 + '<br>Especialista en Servicio al Cliente<br>' + this.remoteConfig.textNumber + '<br>' + this.remoteConfig.phoneNumber + '</es>';

    const message = '<en>Hello ' + this.patientProfile.firstName + ' ' + this.patientProfile.lastName1 + '. We hope this message finds you well. Despite our repeated attempts to reach you regarding your consultation, we have not received any response from you. As a result, we regret to inform you that we will be canceling your consultation and closing your case. If you wish to resume treatment in the future, please feel free to contact our customer service, and we will be happy to assist you with scheduling a new appointment. We thank you for your understanding and are always at your disposal. '+ this.practitionerProfile.abbr + ' ' + this.currentUser.firstName + " " + this.currentUser.lastName1  + 'Customer Service Specialist' + this.remoteConfig.textNumber + ' ' + this.remoteConfig.phoneNumber + '</en><es>Saludos ' + 
    this.patientProfile.firstName + ' ' + this.patientProfile.lastName1 + '. Esperamos que este mensaje le encuentre bien. A pesar de nuestros intentos repetidos para ponernos en contacto con usted respecto a su consulta, no hemos recibido respuesta alguna. Por lo tanto, lamentamos informarle que procederemos a cancelar su consulta y cerrar su caso. Si desea reanudar el tratamiento en el futuro, no dude en ponerse en contacto con servicio al cliente, y estaremos encantados de ayudarle a programar una nueva cita. Agradecemos su comprensión y quedamos a su disposición. ' + this.currentUser.firstName + " " + this.currentUser.lastName1 + 'Especialista en Servicio al Cliente' + this.remoteConfig.textNumber + ' ' + this.remoteConfig.phoneNumber + '</es>';
    let date = new Date(Date.now());
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    let id:any = yyyy + mm + dd + uuidv4();
    let groupMessageId = id

    this.emailService.internalCustomMessages(id, this.patientProfile, '<en>Cancelation of Consultation</en><es>Cancelación de Consulta', htmlMessage, message, this.remoteConfig, this.consultID, true, this.currentUser, '', '', groupMessageId);   
    this.updateConsultStatus('Denied')
    Swal.fire('Consultation Denied', 'The consultation has been denied', 'success').then(res => {
      this.route.navigate(['/consults'])
    })
  }

  updateConsultStatus(status){
    this.afs.collection('consultation').doc(this.consultID).update({Status: status})
    this.consult.Status = status

    let history:ConsultHistory = {
      date: new Date(Date.now()),
      id: this.firebaseService.getDate(),
      userUID: this.currentUser.uid,
      user: this.currentUser.firstName + ' ' +  this.currentUser.lastName1,
      userAccountType:  this.currentUser.accountType,
      action: status,
      consultID: this.consultID,
      consultStatus: this.consult.Status,
      createdBy: this.user.uid,
      reason: [],
      description: 'Patient denied the consultation'
    }

    this.afs.collection('consultation').doc(this.consultID).collection('History').doc(history.id).set(history)
  }


  abandonConsult(){
    this.translate.get('VIEWCONSULTS.SWAL').subscribe((res: any) => {
      this.swal = res
    })

   if(this.practitionerProfile.accountType == 'admin'){
      this.swal.TEXT = this.swal.ADMINTEXT
   } 
   
    Swal.fire({
      customClass: {
        confirmButton: 'swalPurple',
        cancelButton: 'btn-light'
      },
      title: this.swal.TITLE,
      text: this.swal.TEXT,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: this.swal.CANCEL,
      confirmButtonText: this.swal.CONFIRM
    }).then(async result => {
      if (result.isConfirmed) {
        
        this.afs.firestore.collection('chats').where('usersUid', 'array-contains', this.consult.UID_Practitioner).where('consultId', '==', this.consultID).get().then(async (dataRoom: any) => {
          let data: any = dataRoom.docs[dataRoom.docs.length - 1]?.data()

          if(data && data.status != 'abandoned'){
            let chat = {
              status: 'abandoned',
              dateAbandoned: new Date(Date.now()),
            }

            this.afs.collection("chats").doc(data.roomId).update(chat).then(
              res => {          
              } 
            );
          }
        });

        let history:ConsultHistory = {
          date: new Date(Date.now()),
          id: this.firebaseService.getDate(),
          userUID: this.practitionerProfile.uid,
          user: this.practitionerProfile.firstName + ' ' +  this.practitionerProfile.lastName1,
          userAccountType:  this.practitionerProfile.accountType,
          action: MEDICAL_CONSULTATION.ABANDONED,
          consultID: this.consultID,
          consultStatus: this.consult.Status,
          createdBy: this.user.uid,
          reason: this.abandonReopenReasons,
          explanation: this.explanation
        }

        this.afs.collection('consultation').doc(this.consultID).collection('History').doc(history.id).set(history)
        //newest possible versions
          // this.historyService.storeHistory(history);      ----  its own collection
          // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation

        const data = {
          UID_Practitioner: "",
          Status: 'Paid', 
          practitionerForConsult: '',
          practitionerName: ''
        }

        this.firebaseService.updateConsult(this.consultID, data);
        Swal.fire(this.swal.DELETE, this.swal.DELETEDMSG, 'success').then(
          res => {
            this.route.navigate([`consults`])
            this.abandonReopenReasons = []
            this.dismiss()
          }
        )
      }
    });
  }

  reopenConsult(){
    let history:ConsultHistory = {
      date: new Date(Date.now()),
      id: this.firebaseService.getDate(),
      userUID: this.currentUser.uid,
      user: this.currentUser.firstName + ' ' +  this.currentUser.lastName1,
      userAccountType:  this.currentUser.accountType,
      action: MEDICAL_CONSULTATION.REOPENED,
      consultID: this.consultID,
      consultStatus: this.consult.Status,
      createdBy: this.user.uid,
      reason: this.abandonReopenReasons,
      description: this.explanation
    }

    this.afs.collection('consultation').doc(this.consultID).collection('History').doc(history.id).set(history)
    //newest possible versions
          // this.historyService.storeHistory(history);      ----  its own collection
          // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation

    this.afs.collection('consultation').doc(this.consultID).update({Status: 'In Progress', pdf: ''})
    this.consult = Object.assign(this.consult, {Status: 'In Progress', pdf: ''})
    this.pdf = undefined
    this.dismiss()
  }

  editorTextFormat($event){
    this.model.text = $event.target.innerText;
  }

  //notes
  openForm() {
    this.notePlaceholder = '';
    this.$form =  <HTMLFormElement> document.querySelector("#form");
    this.$noteTitle =  document.querySelector<HTMLInputElement>("#note-title");
    this.$formButtons = document.querySelector<HTMLElement>("#form-buttons");
    
    this.$form.classList.add("form-open");
    this.$noteTitle.style.display = "block";
    this.$formButtons.style.display = "block";
  }

  closeForm() { 
    this.$form.classList.remove("form-open");
    this.$noteTitle.style.display = "none";
    this.$formButtons.style.display = "none";

    this.title = '';
    this.text = '';

    this.translate.get('VIEWCONSULTS.NOTES.TAKENOTE').subscribe((res: any) => {
      this.notePlaceholder = res
    })
  }

  async openModal(editNotes:any, note) { 
    if(!note.encrypted){
      this.editableNote = [];
      this.editableNote.push(note);
      this.modalRef = this.modalService.open(editNotes, {size: 'md', backdrop: 'static', centered: true })
    }else{
      let index = this.notes.findIndex(n => n.id == note.id)
      this.notes[index] = Object.assign(this.notes[index], {decrypting: true, innerText: '<en>Decripting</en><es>Decriptando</es>'})
      await this.decryptNote(note).then(n => {
        this.notes[index].encrypted = false;
        this.notes[index].text = n
        this.notes[index].decrypting = false
      })
    }
  }

  typeOfOption(option){
    return typeof(option) != 'string'
  }

  openCalendar(calendar:any, addEvent?: boolean, meeting?) {  
    if(addEvent){
      let selectInfo = {
        title: "",
        start: "",
        end:   ""
      }
  
      let data = {
        uidPractitioner: this.consult.UID_Practitioner,
        uidPatient: this.patientProfile.uid,
        patientName: this.patientProfile.firstName + ' ' + this.patientProfile.lastName1,
        patientEmail: this.patientProfile.email,
        consultID: this.consultID,
        conditionName: this.consult.conditionName,
        room:""
      }
   
      AddEventComponent.sendDate(selectInfo, false, data);
  
      this.modalRef = this.modalService.open(calendar,{
        size: 'md',
        centered: true,
        backdrop: 'static'
      })
    }
    else if(addEvent == false) {
      let data = {
        uidPatient: this.patientProfile.uid,
        consultID: this.consultID,
        conditionName: this.consult.conditionName
      }

      let clickInfo = {
        event: {
          id: meeting.id,
          startStr: meeting.start + ':00-',
          endStr: meeting.end + ':00-',
          extendedProps: meeting,
          title: meeting.title
        } 
      }

      AddEventComponent.sendDate(clickInfo, true, data);
      this.modalRef = this.modalService.open(calendar,{
        size: 'md',
        centered: true,
        backdrop: 'static'
      })
    }
    else {
      this.modalRef = this.modalService.open(calendar,{
        size: 'xl',
        centered: true,
        backdrop: 'static'
      })
    }
  }

  dismiss(){
    if(this.modalRef){
      this.modalRef.dismiss();
    }
    this.modalService.dismissAll()
    this.abandonReopenReasons = []
    this.explanation = ''
  }

  pageLoad(){
    if(this.route.url == `/consults/${this.consultID}/summary` || this.route.url == `/consults/${this.consultID}` ){
      return 1;
    }if(this.route.url == `/consults/${this.consultID}/medicalHistory`){
      return 3;
    }if(this.route.url == `/consults/${this.consultID}/diagnosticsAndReview`){
      return 4;
    }if(this.route.url == `/consults/${this.consultID}/treatment`){
      return 9;
    }if(this.route.url == `/consults/${this.consultID}/prescription`){
      return 9;
    }if(this.route.url == `/consults/${this.consultID}/messages`){
      return 6;
    }
    if(this.route.url == `/consults/${this.consultID}/closingSignature`){
      return 10;
    }
    if(this.route.url == `/consults/${this.consultID}/followUp`){
      return 11;
    }
    if(this.route.url == `/consults/${this.consultID}/orders`){
      return 14;
    }
    if(this.route.url == `/consults/${this.consultID}/consultHistory`){
      return 15;
    }
    if(this.route.url.includes(`/consults/${this.consultID}/others`)){
      return 17;
    }
    return 9;
  }

  othersNav(){
    // if(this.route.url == `/consults/${this.consultID}/others/profile` || this.route.url == `/consults/${this.consultID}/others` ){
    //   return 2;
    // }
    if(this.route.url == `/consults/${this.consultID}/others/notes`){
      return 7;
    }if(this.route.url == `/consults/${this.consultID}/others/telehealth/${this.consultID}` || this.route.url == `/consults/${this.consultID}/others`){
      return 5;
    }
    if(this.route.url == `/consults/${this.consultID}/others/patientConsults`){
      return 8;
    }
    if(this.route.url == `/consults/${this.consultID}/others/referral`){
      return 13;
    }
    if(this.route.url == `/consults/${this.consultID}/others/collaborate`){
      return 16;
    }
    return 5;
  }

  deleteDiagnostic(i){
    this.afs.collection("consultation").doc(this.consultID).collection('Diagnostics').doc(this.selectedDiagnostics[i].id).delete();   
  }

  verifyForm: UntypedFormGroup;
  verifyAccount(){
    this.verifyForm = this.formBuilder.group({
      status: ['Verified'],
      verifiedBy_Uid: [this.currentUser.uid],
      verifiedDate: [new Date()]
    });

    this.firebaseService.updateProfile(this.verifyForm.value, this.patientProfile.uid)
    this.patientProfile.status = 'Verified';
    this.firebaseService.updateConsult(this.consult.ID, {updateId: false})
  }

  sendMail = true;
  sendRequest(){
    this.sendMail = true;
    this.translate.get('VIEWCONSULTS.SWAL').subscribe((res: any) => {
      this.swal = res
    })

    Swal.fire({
      customClass: {
        confirmButton: 'swalPurple',
        cancelButton: 'btn-light'
      },
      title: this.swal.TITLE,
      text: this.swal.SENDREQUEST,
      showCancelButton: true,
      confirmButtonText: this.swal.SENDEMAIL,
      cancelButtonText: this.swal.CANCEL
    }).then(
      async res => {
        if(res.isConfirmed){
          await this.translate.getTranslation(this.patientProfile.preferedLang).subscribe(async (res: any) => {
            for(let i = 0; i < this.verifyFields.length; i++){
              this.verifyFields[i].field = res.PROFILE[this.verifyFields[i].field.split('.')[1]]
              
              if(i == this.verifyFields.length - 1){
                this.emailService.patientConsultEmails(this.user, this.patientProfile, this.consult, 'IDRequired_'+ this.patientProfile.preferedLang, '<en>ID Required</en><es>ID Requerido</es>', this.remoteConfig, 'profile', this.verifyFields).then(res => {
                  this.dismiss()
                  this.lastIDReminderMsg = new Date();
                  Swal.fire(this.swal.SENT, this.swal.NOTIFY, 'success');
                })
              }
            }
          })
        }
      }
    );
  }

  async signPad(){
    if(this.patientProfile.status != 'Verified'){
      this.translate.get('CONSULT-PRESCRIPTIONS.SWAL').subscribe((res: any) => {
        this.swal = res
      })
      Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
        },
        title: this.swal.WARNING,
        icon: "warning",
        text: this.swal.VERIFYPROFILE,
        showCancelButton: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        confirmButtonText: this.swal.GOTOPROFILE,
      }).then(res => {
        this.route.navigate([`/consults/${this.consultID}/others/profile`])
      })
    }else {
      
      let prescs = await this.getConsultPrescription();

      if(prescs == 'valid' || (this.consult.noPrescriptionReasons && this.consult.noPrescriptionReasons.length > 0)){ 
        await this.getReferrals();
        if(((this.consult.followUpConsult || this.referrals.length > 0) && this.consult.requiredFollowUp) || !this.consult.requiredFollowUp){
          this.modalRef = this.modalService.open(this.signaturePadModal, {size: 'lg', backdrop: 'static', centered: true, windowClass:'signPad' });
        } 
        else {
          this.translate.get('CONSULT-PRESCRIPTIONS').subscribe((res: any) => {
            this.swal = res
          })
          Swal.fire({
            customClass: {
              confirmButton: 'swalPurple',
            },
            title: this.swal.SWAL.WARNING,
            icon: "warning",
            text: this.swal.NEEDCREATEFOLLOWUP,
            showCancelButton: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            confirmButtonText: this.swal.CREATEFOLLOWUP,
          }).then(res => {
            this.route.navigate([`/consults/${this.consultID}/followUp`])
          })
        }
      }
      else if(prescs == null){
        this.abandonReopenConsultModal(this.noPrescriptionModal)
      }
      else {
        this.translate.get('CONSULT-PRESCRIPTIONS').subscribe((res: any) => {
          this.swal = res
        })
        Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          title: this.swal.SWAL.WARNING,
          icon: "warning",
          text: this.swal.SIGNORSENDTOPHARMACY,
          showCancelButton: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          confirmButtonText: this.swal.GOTOPRESCRIPTION,
        }).then(res => {
          this.route.navigate([`/consults/${this.consultID}/prescription`])
        })
      }
    }
  }

  submitReason(){
    this.afs.collection('consultation').doc(this.consultID).update({noPrescriptionReasonExplanation: this.explanation, noPrescriptionReasons: this.abandonReopenReasons})
    this.consult.noPrescriptionReasonExplanation = this.explanation;
    this.consult.noPrescriptionReasons = this.abandonReopenReasons;
    this.dismiss();
    this.signPad()
  }

  getConsultPrescription(){
    return new Promise<any>(async (resolve, reject) => {
      this.afs.firestore.collection("prescription").where("consultID", "==", this.consultID).where('internal', '==', true).where('valid', '==', true).get().then(querySnapshot => {
        if(querySnapshot.empty){
          resolve(null)
        }else{
          querySnapshot.forEach((doc) => {
            if(doc.data().status != 'Signed' || doc.data().pharmacy == ''){
              resolve('invalid');
            }
          });
          resolve('valid')
        }
      })
    });
  }

  pathReset($event){
    this.route.navigate([$event]);
    this.pageLoad();
  }

  async privateNote(){
    this.saveNotes().then(async note => {
      await this.encryptService.encryptNote(note[0].text, firebase.auth().currentUser.uid, this.consultID,note[0].id);
    })
  }

  async decryptNote(note){
    return await this.encryptService.decryptNote(note.text, firebase.auth().currentUser.uid, this.consultID, note.id);
  }

  authenticate(){
    return new Promise((resolve, reject) => {
      this.translate.get('VIEWCONSULTS.SWAL').subscribe((res: any) => {
        this.swal = res
      })
      Swal.fire({
        customClass: {
          confirmButton: 'swalPurple',
          cancelButton: 'btn-light'
        },
        title: this.swal.AUTHENTICATE,
        text: this.swal.GOOGLEAUTH,
        showCancelButton: true,
        confirmButtonText: this.swal.VALIDATE,
        cancelButtonText: this.swal.CANCEL,
        input: 'text',
        inputAttributes:{
          maxlength:"6"
        },
        showLoaderOnConfirm:true,
        preConfirm: (value) => {
          this.loading = true;
          this.authenticatorService.validate(this.currentUser.uid, value).then(v => {
            if(v == null){
              this.loading = false;
              const Toast = Swal.mixin({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              this.translate.get('VIEWCONSULTS.SWAL').subscribe((res: any) => {
                this.swal = res.INVALIDTOKEN
              })
              
              Toast.fire({
                icon: 'error',
                title: this.swal
              }).then(() => {
                this.authenticate();
                resolve(v)
              })
            }else{
              const Toast = Swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              this.translate.get('VIEWCONSULTS.SWAL').subscribe((res: any) => {
                this.swal = res.VALIDTOKEN
              })
              Toast.fire({
                icon: 'success',
                title: this.swal
              })
              this.loading = false;
             
              resolve(v)
            }
          })
      }
    })
    .then(async res => {
      if(res.isConfirmed === true){
        this.loading = true;
      }
      else if(res.isDenied === false){
          this.dismiss();
        }
      })
    })
  }

  reloadMsg;
  scheduleMeet(appointment){
    var description;
    if(this.patientProfile.preferedLang == 'en'){
      description = `Telehealth consultation with ${this.practitionerProfile.abbr} ` + this.practitionerProfile.firstName + ' ' + this.practitionerProfile.lastName1
    }
    else {
      description = `Consulta de Telemedicina con ${this.practitionerProfile.abbr}` + this.practitionerProfile.firstName + ' ' + this.practitionerProfile.lastName1 
    }

    //2021-08-13T12:00
    let datePipe = formatDate(appointment.start,'MMMM dd, yyyy',this.patientProfile.preferedLang);
    let timePipe = formatDate(appointment.start,'hh:mm a',this.patientProfile.preferedLang);
    
    if(this.patientProfile.timezone.timeValue != this.userTimezone.split('UTC')[1]){
      let changeStart = new DatePipe('en-Us').transform(appointment.start + ':00.000' + this.userTimezone.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.patientProfile.timezone.timeValue);
      datePipe = formatDate(changeStart,'MMMM dd, yyyy',this.patientProfile.preferedLang);
      timePipe = formatDate(changeStart,'hh:mm a',this.patientProfile.preferedLang);
    }

    let data = {
      id: appointment.id,
      title: appointment.title,
      start: appointment.start,
      end: appointment.end,
      uidPractitioner: this.consult.UID_Practitioner,
      uidPatient: this.consult.UID_Patient,
      patientName: this.consult.patientName,
      patientEmail: this.consult.patientEmail,
      consultID: this.consult.ID,
      dateCreated: appointment.dateCreated,
      room: '',
      eventTimezoneValue: this.userTimezone,
      requestUpdate: false,
      meetStatus: 'approved',
      meetRef: '',
      practitionerEmail: this.practitionerProfile.email
    }

    let meetData = {
      randomId: this.firebaseService.getDate(),
      eventName: appointment.title,
      startTime: appointment.start,
      endTime: appointment.end,
      patientEmail: this.patientProfile.email,
      practitionerEmail: this.practitionerProfile.email,
      description: description,
      timezone: this.userTimezone
    }

    if(this.consult.updateFollowUpReasons || appointment.requestUpdate == true){
      data.start = appointment.suggestedSchedule.start
      data.end = appointment.suggestedSchedule.end

      meetData.startTime = appointment.suggestedSchedule.start
      meetData.endTime = appointment.suggestedSchedule.end

      datePipe = formatDate(appointment.suggestedSchedule.start,'MMMM dd, yyyy',this.patientProfile.preferedLang);
      timePipe = formatDate(appointment.suggestedSchedule.start,'hh:mm a',this.patientProfile.preferedLang);
      
      if(this.patientProfile.timezone.timeValue != this.userTimezone.split('UTC')[1]){
        let changeStart = new DatePipe('en-Us').transform(appointment.suggestedSchedule.start + ':00.000' + this.userTimezone.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.patientProfile.timezone.timeValue);
        datePipe = formatDate(changeStart,'MMMM dd, yyyy',this.patientProfile.preferedLang);
        timePipe = formatDate(changeStart,'hh:mm a',this.patientProfile.preferedLang);
      }  
    }
    this.loadingDiagnostics = true
    this.reloadMsg = 'RELOAD.APPROVING'
    let meetAction = data.room == '' ? 'createMeet' : 'updateMeet'
    this.telehealthService[meetAction](meetData).then(async (res: any)=> {
      data.room = res.conferenceData.entryPoints[0].uri
      data.meetRef = res.id
      this.firebaseService.scheduleEventUpdate(data);
      this.loadingDiagnostics = false

      let date = new Date(Date.now());
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();
      let id:any = yyyy + mm + dd + uuidv4();
      let groupMessageId = id

      let customSms: any = {
        shortUrl: {code: ''},
        smsShortUrl: '',
      }
  
      await this.emailService.shortenUrl('https://app.dzeus.com/inboxandsupport/read/' + id, this.remoteConfig.shortenedUrl).then(code => {     
        customSms.shortUrl.code = code
        customSms.smsShortUrl = code
        customSms.link = '/inboxandsupport/read/' + id
      
        this.emailService.internalCustomMessages(id, this.patientProfile, '<en>Updated event</en><es>Evento actualizado</es>', `<en>Hello ${data.patientName},<br><br> Your Healthcare Provider has updated the meeting: "${appointment.title}" for ${datePipe + " at " + timePipe+ ' - UTC' + this.patientProfile.timezone.timeValue}.   
        <br><br>Thanks,<br>your ${this.remoteConfig.appName} team</en><es>Saludos ${data.patientName},<br><br> Su Proveedor de Salud ha actualizado su teleconsulta: "${appointment.title}" para ${datePipe + " a las " + timePipe+ ' - UTC' + this.patientProfile.timezone.timeValue}.  
        <br><br>Gracias,<br>su equipo de ${this.remoteConfig.appName}</es>`, 
            `<en>Hello ${data.patientName}, Your Healthcare Provider has updated the meeting: "${appointment.title}" for ${datePipe + " at " + timePipe+ ' - UTC' + this.patientProfile.timezone.timeValue}.  
            Thanks,your ${this.remoteConfig.appName} team</en><es>Saludos ${data.patientName}, Su Proveedor de Salud ha actualizado su teleconsulta: "${appointment.title}" para ${datePipe + " a las " + timePipe+ ' - UTC' + this.patientProfile.timezone.timeValue}.  
            Gracias,su equipo de ${this.remoteConfig.appName}</es>`, this.remoteConfig, data.consultID, true, this.practitionerProfile, '', customSms, groupMessageId);  
      })
    })
  }

  reschedule(appointment){
    var description;
    if(this.patientProfile.preferedLang == 'en'){
      description = 'Telehealth consultation with ' + this.practitionerProfile.firstName + ' ' + this.practitionerProfile.lastName1
    }
    else {
      description = 'Consulta de Telemedicina con el Proveedor de Salud ' + this.practitionerProfile.firstName + ' ' + this.practitionerProfile.lastName1
    }

    let data = {
      uidPatient: this.patientProfile.uid,
      consultID: this.consultID,
      conditionName: this.consult.conditionName
    }

    let clickInfo = {
      event: {
        id: appointment.id,
        startStr: appointment.start + ':00-',
        endStr: appointment.end + ':00-',
        extendedProps: Object.assign(appointment, 
                                    {uidPractitioner: this.practitionerProfile.uid,
                                    practitionerEmail: this.practitionerProfile.email,
                                    description: description}),
        title: appointment.title,
      } 
    }

    AddEventComponent.sendDate(clickInfo, true, data);

    this.appointment = appointment;
    this.modalRef = this.modalService.open(this.rescheduleModal, {
      size: 'lg', centered: true, backdrop: 'static',
      windowClass: "appointment-scheduler"     
    })
  }

  createMeet(){
    var now: any = new Date();
    var dd = String(now.getDate()).padStart(2, '0');
    var mm = String(now.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = now.getFullYear();
    var hour = String(now.getHours()).padStart(2, '0')
    var minutes = String(now.getMinutes()).padStart(2, '0')
    
    var futureDate: any = new Date(now).getTime() + (15*60000);
    now = yyyy + '-' + mm + '-' + dd + 'T' + hour + ':' + minutes

    var Start = now;
    var Title;
    var description;
    if(this.patientProfile.preferedLang == 'en'){
      Title = 'Medical Consultation'
      description = 'Telehealth consultation with ' + this.practitionerProfile.firstName + ' ' + this.practitionerProfile.lastName1
    }
    else {
      Title = 'Consulta Médica'
      description = 'Consulta de Telemedicina con el Proveedor de Salud ' + this.practitionerProfile.firstName + ' ' + this.practitionerProfile.lastName1
    }
    if(typeof(Start) != 'string'){
      Start = Start[0]
    }
    const End = formatDate(new Date(futureDate),'yyyy-MM-ddTHH:mm',this.patientProfile.preferedLang);

    let datePipe = formatDate(Start,'MMMM dd, yyyy',this.patientProfile.preferedLang);
    let timePipe = formatDate(Start,'hh:mm a',this.patientProfile.preferedLang);
    let msgDatePipe = formatDate(Start,'MMM d, y',this.patientProfile.preferedLang);

    if(this.patientProfile.timezone.timeValue != this.userTimezone.split('UTC')[1]){
      let changeStart = new DatePipe('en-Us').transform(Start + ':00.000' + this.userTimezone.split('UTC')[1], 'yyyy-MM-ddTHH:mm', this.patientProfile.timezone.timeValue);
      datePipe = formatDate(changeStart,'MMMM dd, yyyy',this.patientProfile.preferedLang);
      timePipe = formatDate(changeStart,'hh:mm a',this.patientProfile.preferedLang);
      msgDatePipe = formatDate(changeStart,'MMM d, y',this.patientProfile.preferedLang);
    }

    let data = {
      id: this.firebaseService.getDate(),
      title: Title,
      start: Start,
      end: End,
      uidPractitioner: this.practitionerProfile.uid,
      practitionerEmail: this.practitionerProfile.email,
      uidPatient: this.patientProfile.uid,
      patientName: this.patientProfile.firstName + ' ' + this.patientProfile.lastName1,
      patientEmail: this.patientProfile.email,
      consultID: this.consult.ID,
      conditionName: this.consult.conditionName,
      dateCreated: new Date(Date.now()),
      room:"",
      description: description,
      meetRef: '',
      eventTimezoneValue: this.userTimezone,
      meetStatus: 'approved',
      requestUpdate: false
    }

    let meetData = {
      randomId: this.firebaseService.getDate(),
      eventName: Title,
      startTime: Start,
      endTime: End,
      patientEmail: this.patientProfile.email,
      practitionerEmail: this.practitionerProfile.email,
      description: description,
      timezone: this.userTimezone,
    }
  
    this.loadingDiagnostics = true
    this.reloadMsg = 'RELOAD.CREATING'
    this.telehealthService.createMeet(meetData).then(async (res: any)=> {
      data.room = res.conferenceData.entryPoints[0].uri
      data.meetRef = res.id
      this.firebaseService.scheduleEvent(data);
      this.loadingDiagnostics = false;

      this.modalService.dismissAll();

      let date = new Date(Date.now());
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();
      let id:any = yyyy + mm + dd + uuidv4();
      let groupMessageId = id

      let customSms: any = {
        shortUrl: {code: ''},
        smsShortUrl: '',
      }
  
      await this.emailService.shortenUrl('https://app.dzeus.com/inboxandsupport/read/' + id, this.remoteConfig.shortenedUrl).then(code => {     
        customSms.shortUrl.code = code
        customSms.smsShortUrl = code
        customSms.link = '/inboxandsupport/read/' + id
      
        this.emailService.internalCustomMessages(id, this.patientProfile, '<en>New event</en><es>Nuevo evento</es>', `<en>Hello ${this.patientProfile.firstName + ' ' + this.patientProfile.lastName1},<br><br> Your Healthcare Provider has schedule a new meeting: "${Title}" for ${datePipe + " at " + timePipe + ' - UTC' + this.patientProfile.timezone.timeValue}.  
        If you need to update the date, please contact your Healthcare Provider.<br><br>Thanks,<br>your ${this.remoteConfig.appName} team</en>
        <es>Saludos ${this.patientProfile.firstName + ' ' + this.patientProfile.lastName1},<br><br> Su Proveedor de Salud ha programado una nueva teleconsulta: "${Title}" para ${datePipe + " a las " + timePipe+ ' - UTC' + this.patientProfile.timezone.timeValue}.  
        Si necesita actualizar el día, favor de contactar a su Proveedor de Salud.<br><br>Gracias,<br>su equipo de ${this.remoteConfig.appName}</es>`, 
        `<en>Hello ${this.patientProfile.firstName + ' ' + this.patientProfile.lastName1},Your Healthcare Provider has schedule a new meeting: "${Title}" for ${datePipe + " at " + timePipe+ ' - UTC' + this.patientProfile.timezone.timeValue}.   
              If you need to update the date, please contact your Healthcare Provider.Thanks,your ${this.remoteConfig.appName} team</en><es>Saludos ${this.patientProfile.firstName + ' ' + this.patientProfile.lastName1}, Su Proveedor de Salud ha programado una nueva teleconsulta: "${Title}" para ${datePipe + " a las " + timePipe+ ' - UTC' + this.patientProfile.timezone.timeValue}.    
              Si necesita actualizar el día, favor de contactar a su Proveedor de Salud.Gracias,su equipo de ${this.remoteConfig.appName}</es>`, this.remoteConfig, this.consult.ID, true, this.practitionerProfile, '', customSms, groupMessageId);
      })
    })
  }

  openProfileModal(){
    this.modalRef = this.modalService.open(this.profileUpdate)
  }

  selectFields(event) {
    if (event.target.checked ) {
      this.verifyFields.push({field: event.target.id});
    } else {
      let index = this.verifyFields.findIndex(x => x.field == event.target.id.toString())
      this.verifyFields.splice(index, 1);
    }
  }

  inviteCollab(coworker){
    this.translate.get('VIEWCONSULTS.SWAL').subscribe((res: any) => {
      this.swal = res
    })
   
    Swal.fire({
      customClass: {
        confirmButton: 'swalPurple',
        cancelButton: 'btn-light'
      },
      title: this.swal.INVITETITLE,
      text: this.swal.INVITETEXT + coworker.firstName + ' ' + coworker.lastName1 + '?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: this.swal.CANCEL,
      confirmButtonText: this.swal.COLLABORATE
    }).then(async result => {
      if (result.isConfirmed) {
        const { firstName, lastName1, uid, email } = coworker;
        let collab = {
          firstName: firstName,
          lastName1: lastName1,
          email: email,
          uid: uid,
          collabStart: new Date(Date.now()),
          collabEnd:  new Date(Date.now() + 3 * 24 * 60 * 60 * 1000),
          active: true
        }
        this.afs.collection("consultation").doc(this.consult.ID).collection("collaborative").doc(collab.uid).set(collab).then(() => {
          this.emailService.generalEmails(coworker, `collab_${coworker.preferedLang}`, `<en>New Collaboration</en><es>Nueva Colaboración</es>`, this.remoteConfig, '', '', '', '', this.practitionerProfile)
        });
      }
    });
  }

  getCollaboratives(){
    let sub = this.afs.collection("consultation").doc(this.consultID).collection("collaborative").valueChanges().subscribe(data => {
      this.collaboratives = data;
      this.collaborator = this.collaboratives.filter(e => e.uid == this.user.uid).length > 0 ? true : false;
    })
    this.allSubscriptions.add(sub)
  }

  removeCollabAccess(coworker){
    const { uid } = coworker;
    this.afs.collection("consultation").doc(this.consult.ID).collection("collaborative").doc(uid).update({active: false, collabEnd: new Date(Date.now())});
  }

  validateCollab(coworker){
    let doctor = this.collaboratives.find(x => x.uid == coworker.uid);
    return doctor ? doctor : "N/A"
  }

  transferConsult(doc){
    this.transferDoc = doc;
    this.modalRef = this.modalService.open(this.transferModalActionRef, {size: 'md', backdrop: 'static', centered: true, windowClass:"transfer-modal"});
  }

  transfer(){
    this.getPosibleCollaborators();
    this.modalRef = this.modalService.open(this.transferModal, {size: 'md', backdrop: 'static', centered: true, windowClass:"transfer-modal"})
  }

  async completeTransfer(){
    if(this.transferConsult.length > 0){
        let chat = {
          status: 'abandoned'
        }
        
        this.afs.firestore.collection('chats').where('consultId', '==', this.consultID).get().then(async (dataRoom: any) => {      
          let data: any = dataRoom.docs[dataRoom.docs.length - 1]?.data()

          if(data && data.status != 'abandoned'){
            this.afs.collection("chats").doc(data.roomId).update(chat)
          }
        });

        let history:ConsultHistory = {
          date: new Date(Date.now()),
          id: this.firebaseService.getDate(),
          userUID: this.practitionerProfile.uid,
          user: this.practitionerProfile.firstName + ' ' +  this.practitionerProfile.lastName1,
          userAccountType:  this.practitionerProfile.accountType,
          action: this.transferOption,
          consultID: this.consultID,
          consultStatus: this.consult.Status,
          createdBy: this.user.uid,
          reason: [this.transferOption],
          transfer: true
        }

        this.afs.collection('consultation').doc(this.consultID).collection('History').doc(history.id).set(history);
        //newest possible versions
          // this.historyService.storeHistory(history);      ----  its own collection
          // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation

        const data = {
          UID_Practitioner: this.transferDoc.uid,
          Status: 'In Progress', 
          practitionerForConsult: this.transferOption.split(" ")[0] == "Fully" ? this.transferDoc.uid : this.practitionerProfile.uid,
          practitionerName: this.transferDoc.firstName + " " + this.transferDoc.lastName1,
          transfered: this.transferOption.split(" ")[0]
        }
        this.emailService.generalEmails(this.transferDoc, `transfer_${this.transferDoc.preferedLang}`, '<en>Consult Transfered</en><es>Consulta Transferida</es>', this.remoteConfig, '', '', '', '', this.practitionerProfile)
        this.firebaseService.updateConsult(this.consultID, data);
        this.route.navigate([`consults`]);
        this.dismiss();
    }
  }

  getPatientAllergies(){
    return new Promise<any>((resolve, reject) => {
      this.afs.firestore.collection("users").doc(this.consult.UID_Patient).collection("medicalHistory").doc("2").get().then(data => {
        resolve(data.data().answerHistory[data.data().answerHistory.length - 1]?.explain);
      });
    });
  }

  adminProfile(){
    this.route.navigate([`/accounts/${this.patientProfile.uid}`])
  }
}
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EmailsService } from './emails.service';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class GeneratePsychotherapyService {
  draftConsult

  constructor(
    private afs: AngularFirestore,
    private firebaseService: FirebaseService,
    private emailService: EmailsService
  ) { }

  async generatePsycotherapy(consult:any, isFollowUp:boolean){
    // this.afs.collection("consultation").doc()
    // this.copyConsult(consult, isFollowUp);
  }
}

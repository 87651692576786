<div class="container-fluid">
  <app-pagetitle [title]="pagetitle"></app-pagetitle>
  <div class="row">
    <!-- <div *ngIf="profile?.accountType == 'admin'">
      <ng-select (change)="filterNotifications()" [(ngModel)]="filter" [clearable]="false">
        <ng-option value="myMessages">My Messages</ng-option>
        <ng-option value="all">All Messages</ng-option>
      </ng-select>
    </div> -->
    <div class="col-12">
      <div class="email-leftbar card" id="sidebar">
        <ul ngbNav #customNav="ngbNav" [activeId]="pageLoad()" class="inbox nav-tabs nav-tabs-custom nav-justified flex-column" style="border-bottom: 0">
          <b>{{'NOTIFICATIONS.MESSAGES' | translate}}</b>
          <li [ngbNavItem]="1" (click)="getNotifications()">
            <a ngbNavLink [routerLink]="['./general']" *ngIf="unread.length > 0" [ngClass]="{ 'activeLink': router.url == '/inboxandsupport' || router.url == '/inboxandsupport/general' }"  (click)="emailIds = []; hide(true)"><i class="mdi mdi-email-outline mr-2"></i> {{'NOTIFICATIONS.GENERAL' | translate}} <span
              class="ml-1 float-right">{{unread.length}}</span></a>
            <a ngbNavLink [routerLink]="['./general']" *ngIf="unread.length == 0" [ngClass]="{ 'activeLink': router.url == '/inboxandsupport' || router.url == '/inboxandsupport/general' }"  (click)="emailIds = []; hide(true)"><i class="mdi mdi-email-outline mr-2"></i> {{'NOTIFICATIONS.GENERAL' | translate}} <span
              class="ml-1 float-right"></span></a>
          </li>
          <li [ngbNavItem]="2" (click)="getConsultMails()">
            <a ngbNavLink [routerLink]="['./consults']" *ngIf="consultMailUnread.length > 0" [ngClass]="{ 'activeLink': router.url == '/inboxandsupport/consults'}"  (click)="emailIds = []; hide(true)"><i class="ri-survey-fill mr-2"></i> {{'NOTIFICATIONS.CONSULTATIONS' | translate}} <span
              class="ml-1 float-right">{{consultMailUnread.length}}</span></a>
            <a ngbNavLink [routerLink]="['./consults']" *ngIf="consultMailUnread.length == 0" [ngClass]="{ 'activeLink': router.url == '/inboxandsupport/consults'}"  (click)="emailIds = []; hide(true)"><i class="ri-survey-fill mr-2"></i> {{'NOTIFICATIONS.CONSULTATIONS' | translate}} <span
              class="ml-1 float-right"></span></a>
          </li>
          <li *ngIf="profile?.accountType == 'patient'" [ngbNavItem]="3" (click)="getOrderMails()">
            <a ngbNavLink [routerLink]="['./orders']" *ngIf="orderUnread.length > 0" [ngClass]="{ 'activeLink': router.url.startsWith('/inboxandsupport/orders') }"  (click)="emailIds = []; hide(true)"><i class="fas fa-box mr-2"></i> {{'NOTIFICATIONS.ORDERS' | translate}} <span
              class="ml-1 float-right">{{orderUnread.length}}</span></a>
            <a ngbNavLink [routerLink]="['./orders']" *ngIf="orderUnread.length == 0" [ngClass]="{ 'activeLink': router.url.startsWith('/inboxandsupport/orders') }"  (click)="emailIds = []; hide(true)"><i class="fas fa-box mr-2"></i> {{'NOTIFICATIONS.ORDERS' | translate}} <span
              class="ml-1 float-right"></span></a>
          </li>
          <li [ngbNavItem]="4" (click)="getSentNotifications()">
            <a ngbNavLink [routerLink]="['./sent']" [ngClass]="{ 'activeLink': router.url == '/inboxandsupport/sent' }"  (click)="emailIds = []; hide(true)"><i class="fab fa-telegram-plane mr-2"></i> {{'NOTIFICATIONS.SENT' | translate}} <span
              class="ml-1 float-right"></span></a>
          </li>
          <li [ngbNavItem]="5" (click)="getArchiveMails()">
            <a ngbNavLink [routerLink]="['./archive']" [ngClass]="{ 'activeLink': router.url == '/inboxandsupport/archive' }"  (click)="emailIds = []; hide(true)"><i class="fa fa-inbox mr-2" ></i> {{'NOTIFICATIONS.ARCHIVE' | translate}} <span
              class="ml-1 float-right"></span></a>
          </li>
          <hr class="w-100" style="border: 1.5px solid #ebe9ee"/>
          <b>{{'NOTIFICATIONS.SUPPORT' | translate}}</b>
          <li [ngbNavItem]="6">
            <a ngbNavLink [routerLink]="['./helpdesk']" [ngClass]="{ 'activeLink': router.url == '/inboxandsupport/helpdesk' }"  (click)="hide(true); filteredNotifications = undefined;"> {{'NOTIFICATIONS.HELPDESK.HELPDESK' | translate}}<span class="ml-1 float-right"></span></a>
          </li>
          <li [ngbNavItem]="7">
            <a ngbNavLink [routerLink]="['./faq']" [ngClass]="{ 'activeLink': router.url == '/inboxandsupport/faq' }"  (click)="hide(true); filteredNotifications = undefined;"> {{'NOTIFICATIONS.FAQ.FAQ' | translate}}<span class="ml-1 float-right"></span></a>
          </li>
          <li [ngbNavItem]="8">
            <a ngbNavLink [routerLink]="['./contactus']" [ngClass]="{ 'activeLink': router.url == '/inboxandsupport/contactus' }"  (click)="hide(true); filteredNotifications = undefined;"> {{'NOTIFICATIONS.CONTACTUS.CONTACTUS' | translate}}<span class="ml-1 float-right"></span></a>
          </li>
          <li [ngbNavItem]="9">
            <a ngbNavLink [routerLink]="['./chat']" [ngClass]="{ 'activeLink': router.url == '/inboxandsupport/chat' }"  (click)="hide(true); filteredNotifications = undefined;"> {{'NOTIFICATIONS.CHAT.CHAT' | translate}}<span class="ml-1 float-right"></span></a>        
          </li>
        </ul>
      </div>
      
      <div class="email-rightbar mb-3" id="navBar">
        <div *ngIf="filteredNotifications">
          <div class="card"> 
            <div class="btn-toolbar p-1" role="toolbar">
              <div *ngIf="hidden" class="btn-group mb-2 mb-sm-0">
                <button type="button" class="btn btn-primary toolbarBtn" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button>
              </div>
              <div class="btn-group mb-2 mb-sm-0">
                <button *ngIf="profile?.accountType == 'admin'" class="btn btn-primary toolbarBtn mr-1"><i class="mdi mdi-pencil" ngbTooltip="{{'NOTIFICATIONS.NEWMESSAGE' | translate}}" (click)="open(content, undefined)"></i></button>
                <button *ngIf="router.url != '/inboxandsupport/archive'" type="button" class="btn btn-primary toolbarBtn" (click)="archiveMails();" id="toolbarArchive" [disabled]="disableToolbar()"><i class="fa fa-inbox" ngbTooltip="{{'NOTIFICATIONS.ARCHIVEHOVER' | translate}}"></i></button>
                <button *ngIf="router.url == '/inboxandsupport/archive'" type="button" class="btn btn-primary toolbarBtn" (click)="unarchiveMails();" id="toolbarArchive" [disabled]="disableToolbar()"><i class="ri-inbox-unarchive-fill" ngbTooltip="{{'NOTIFICATIONS.UNARCHIVE' | translate}}"></i></button>
              </div>      
                <div class="col-sm-6 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter position-relative">
                    <input type="text" name="searchTerm" class="form-control searchbar form-control-sm" 
                      aria-controls="tickets-table" placeholder="{{'NOTIFICATIONS.SEARCH' |  translate}}" [(ngModel)]="searchText"/> 
                  </div>
                </div>             
            </div> 
            
            <ul class="message-list">
              <li class="unread d-flex flex-row align-items-center" *ngFor="let email of filteredNotifications | filter: searchText; let i = index;"
                [ngClass]="{ 'unread': email.read === true }">
                <div class="checkbox-wrapper-mail ml-2" style="min-width: 20px;">
                  <input hidden type="checkbox" id="chk-{{i}}" (change)="selectMail($event,email)"/>
                  <label for="chk-{{i}}"></label>
                </div>
                <div class="d-flex flex-column pl-3 pr-2" style="line-height: normal; width: calc(100% - 30px);">
                  <a *ngIf="email?.template?.data.subject" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" [routerLink]="['./read',email.id]" class="font-size-15" (click)="handleNotification(email); filteredNotifications = undefined" >{{email?.template?.data.subject  | translation:profile.preferedLang}}</a>
                  <a *ngIf="email?.template.data.orderNumber" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" [routerLink]="['./orders',email.template.data.orderNumber]" class="font-size-15" (click)="handleNotification(email); filteredNotifications = undefined" >{{'TOPBAR.ORDERNUM' | translate}}{{email?.template.data.orderNumber}}</a> 
                  <a *ngIf="email?.template?.data.subject" [routerLink]="['./read',email.id]" class="date title" style="font-size: 11px !important; width: 100%; text-align-last: end;" (click)="handleNotification(email); filteredNotifications = undefined">{{email?.delivery?.endTime?.toDate() | date:'yyyy-MM-dd h:mm a'}}</a>
                  <a *ngIf="email?.template.data.orderNumber" [routerLink]="['./orders',email.template.data.orderNumber]" class="date title" style="font-size: 11px !important; width: 100%; text-align-last: end;" (click)="handleNotification(email); filteredNotifications = undefined">{{email?.delivery?.endTime?.toDate() | date:'yyyy-MM-dd h:mm a'}}</a>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div *ngIf="router.url.startsWith('/inboxandsupport/read') && selectedNotification[0] != ''">
          <div class="card">
            <div *ngIf="hidden" class="btn-toolbar p-3" role="toolbar">
              <div class="btn-group mb-2 mb-sm-0">
                <button type="button" class="btn btn-primary toolbarBtn" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button>
              </div>
            </div>

            <div class="card-body" *ngFor="let data of selectedNotification">
              <div class="content">
                <div class="media mb-1">
                  <div class="circle mr-2" [ngStyle]="{'background-color': getUserColor(data.toUID)}">
                    <span class="initials">{{data?.initials}}</span>
                  </div>
                  <!-- <img *ngIf="settings" class="d-flex mr-3 avatar-sm" src="../../../assets/images/FAQ_Icons/Dzeus_Icono_d+.svg"
                    alt="Generic placeholder image"> -->
                  <div class="media-body">
                    <h4 class="font-size-16 mt-1 mb-0 bold" style="text-align: start;">{{data?.template?.data?.subject | translation:profile?.preferedLang}}</h4>
                    <!-- <small *ngIf="data?.from" class="text-muted">{{'NOTIFICATIONS.FROM' | translate}}: {{data?.from}}</small> -->
                    <!-- <small *ngIf="!data?.from" class="text-muted">{{'NOTIFICATIONS.FROM' | translate}}: Dzeus Support</small> -->
                    <small *ngIf="profile?.accountType == 'patient' && previousUrl != 'sent'" class="text-muted">{{'NOTIFICATIONS.FROM' | translate}}: <span *ngIf="data.template.data.fromName != 'Dzeus Support' && data.template.data.fromName != '' && data.template.data.fromName != undefined">{{data?.template.data.fromName}} (</span>Dzeus Support<span *ngIf="data.template.data.fromName != 'Dzeus Support' && data.template.data.fromName != '' && data.template.data.fromName != undefined">)</span></small>
                    <small *ngIf="profile?.accountType != 'patient' && previousUrl != 'sent'" class="text-muted">{{'NOTIFICATIONS.FROM' | translate}}: {{data?.template.data.fromName}} ({{data.template.data.from}})</small>
                    <small *ngIf="profile?.accountType == 'patient' && previousUrl == 'sent'" class="text-muted">{{'NOTIFICATIONS.CHAT.TO' | translate}}: <span *ngIf="data.template.data.toName != 'Dzeus Support' && data.template.data.toName != '' && data.template.data.toName != undefined">{{data?.template.data.toName}} (</span>Dzeus Support<span *ngIf="data.template.data.toName != 'Dzeus Support' && data.template.data.toName != '' && data.template.data.toName != undefined">)</span></small>
                    <small *ngIf="profile?.accountType != 'patient' && previousUrl == 'sent'" class="text-muted">{{'NOTIFICATIONS.CHAT.TO' | translate}}: {{data?.template.data.toName}} ({{data.template.data.to}})</small>
                    <h6 class="msg-time" >{{ data?.delivery?.endTime?.toDate() | date:'yyyy-MM-dd h:mm a'}}</h6>
                  </div>
                </div>
                <!-- <h4 class="mt-0 font-size-16" style="text-align: left;">{{data?.template?.data?.subject | translation:profile?.preferedLang}}</h4> -->
                <hr>
                <div [innerHTML]="data.html"></div>


                <!-- View prev messages -->
                <div *ngIf="prevMessages && prevMessages.length > 0" style="margin-bottom: 110px;" >
                  <button *ngIf="!viewPrevMessages" class="dropdown-toggle ml- btn header-item langBtn h-auto" (click)="viewPrevMessages = true"><span class="prevMessages">{{'NOTIFICATIONS.PREVIOUSMESSAGES' | translate}} <i class="fas fa-caret-down"></i></span></button>
                  <button *ngIf="viewPrevMessages" class="dropdown-toggle ml-1 btn header-item langBtn h-auto" (click)="viewPrevMessages = false"><span class="prevMessages">{{'NOTIFICATIONS.HIDEMESSAGES' | translate}} <i class="fas fa-caret-up"></i></span></button>
                  <!-- <h6 class="bold" style="text-align: start;">{{'NOTIFICATIONS.PREVIOUSMESSAGES' | translate}}</h6> -->
                  <div *ngIf="viewPrevMessages">
                    <div class="ml-2" *ngFor="let message of prevMessages">
                      <div class="media mb-1">
                        <div class="circle mr-2" [ngStyle]="{'background-color': getUserColor(message.toUID)}">
                          <span class="initials">{{message.initials}}</span>
                        </div>
                        
                        <!-- <img *ngIf="settings" class="d-flex mr-3 avatar-sm" src="../../../assets/images/FAQ_Icons/Dzeus_Icono_d+.svg"
                        alt="Generic placeholder image"> -->
                        <div class="media-body">
                          <h4 class="font-size-16 mt-1 mb-0 bold" style="text-align: start;">{{message?.template?.data?.subject | translation:profile?.preferedLang}}</h4>
                          <small *ngIf="profile?.accountType == 'patient' && previousUrl != 'sent'" class="text-muted">{{'NOTIFICATIONS.FROM' | translate}}: <span *ngIf="message.template.data.fromName != 'Dzeus Support' && message.template.data.fromName != '' && message.template.data.fromName != undefined">{{message?.template.data.fromName}} (</span>Dzeus Support<span *ngIf="message.template.data.fromName != 'Dzeus Support' && message.template.data.fromName != '' && message.template.data.fromName != undefined">)</span></small>
                          <small *ngIf="profile?.accountType != 'patient' && previousUrl != 'sent'" class="text-muted">{{'NOTIFICATIONS.FROM' | translate}}: {{message?.template.data.fromName}} ({{message.template.data.from}})</small>
                          <small *ngIf="profile?.accountType == 'patient' && previousUrl == 'sent'" class="text-muted">{{'NOTIFICATIONS.CHAT.TO' | translate}}: <span *ngIf="message.template.data.toName != 'Dzeus Support' && message.template.data.toName != '' && message.template.data.toName != undefined">{{message?.template.data.toName}} (</span>Dzeus Support<span *ngIf="message.template.data.toName != 'Dzeus Support' && message.template.data.toName != '' && message.template.data.toName != undefined">)</span></small>
                          <small *ngIf="profile?.accountType != 'patient' && previousUrl == 'sent'" class="text-muted">{{'NOTIFICATIONS.CHAT.TO' | translate}}: {{message?.template.data.toName}} ({{message.template.data.to}})</small>
                          <h6 class="msg-time" >{{ message?.delivery?.endTime?.toDate() | date:'yyyy-MM-dd h:mm a'}}</h6>
                        </div>
                      </div>
                      <!-- <h4 class="mt-0 font-size-16" style="text-align: left;">{{message?.template?.data?.subject | translation:profile?.preferedLang}}</h4> -->
                      <hr>
                      <div *ngIf="message && message.html" [innerHTML]="message.html"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="data?.reply && data?.fromUID != user?.uid" class="replyBtn">
                <hr/>
                <button *ngIf="(currentConsult && currentConsult?.Status != 'Completed' && data.reply) || (data.reply && !currentConsult)" class="btn btn-secondary btn-reply" (click)="open(content, data)"><i class="mdi mdi-reply"></i>{{'NOTIFICATIONS.REPLY' | translate}}</button>  
                <p *ngIf="currentConsult && currentConsult?.Status == 'Completed'" style="color: red;">{{'NOTIFICATIONS.COMPLETED'| translate}}</p>
              </div>          
            </div>
          </div>
        </div>

        <div *ngIf="selectedNotification[0] != undefined && selectedNotification[0] != '' && router.url.startsWith('/inboxandsupport/orders') && router.url != '/inboxandsupport/orders'">
          <div class="card">
            <div *ngIf="hidden" class="btn-toolbar p-3" role="toolbar">
              <div class="btn-group mb-2 mb-sm-0">
                <button type="button" class="btn btn-primary toolbarBtn" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button>
              </div>
            </div>

            <div class="card-body" style="overflow-y: auto;" *ngFor="let data of selectedNotification">
              <div class="row" *ngIf="selectedNotification != []">
                <div class="col-lg-12">
                  <div class="row justify-content-center">
                    <!-- <img class="avatar-sm mr-3" src="assets/images/dzeus_icon_logo.png" alt=""> -->
                    <h2 class="invoice-title mt-2">{{'CHECKOUT.ORDERCONFIRMED' | translate}}</h2>
                  </div><br>
                  <div class="invoice-container">
                    <h2>{{'CHECKOUT.HELLO' | translate}} {{data?.template?.data?.firstName}} {{data?.template?.data?.lastName}},</h2>
                    <p>{{'CHECKOUT.YOURORDER' | translate}} #{{data?.template?.data?.orderNumber}} {{'CHECKOUT.CONFIRMED' | translate}}</p>
                    <h4 class="cyan">{{'CHECKOUT.ORDERSUMMARY' | translate}}:</h4>
                    <hr>
                    <div class="items-summary" *ngIf="data?.template?.data?.orderLines">
                      <div class="cart-container" *ngFor="let item of data.template.data.orderLines">
                        <div>
                          <img *ngIf="item.productName != 'No Product Selected'" src="{{item.image | translation:currentLang}}"/>
                        </div>
                        <span *ngIf="item.productName != 'No Product Selected'" class="row products flex-column justify-content-center">
                          <p class="product-desc mb-0">{{item.productName | translation:currentLang}}</p>
                          <p class="product-price">{{item.productName | translation:currentLang}}: {{item.productQty}} x {{item.productPrice | currency:'USD'}}</p>   
                        </span>
                        
                      </div>
                    </div>
                    <div class="order-info">
                      <hr>
                      <div class="order-details-container">
                        <div class="row">
                          <p class="desc">{{'CHECKOUT.SUBTOTAL' | translate}}</p>
                          <p class="numbers"> {{data?.template?.data?.Subtotal | currency:'USD'}}</p>
                        </div>
                        <div class="row">
                          <p class="desc">{{'CHECKOUT.TAX' | translate}}</p>
                          <p class="numbers"> {{data?.template?.data?.Tax | currency:'USD'}}</p>
                        </div>
                        <div class="row">
                          <p class="desc">{{'CHECKOUT.TOTAL' | translate}}</p>
                          <div class="d-flex flex-column">
                            <p class="numbers bold">&nbsp;{{data?.template?.data?.Total | currency:'USD'}}</p>
                            <!-- <p class="numbers bold">-{{data?.template?.data?.amountDueToday | currency:'USD'}}</p> -->
                            <hr>
                          </div>                        
                        </div>    
                        <!-- <div *ngIf="data?.template?.data?.amountDueConfirmed" class="row">
                          <p class="desc">{{'CHECKOUT.AMOUNTDUECONFIRMED' | translate}}</p>
                          <p class="numbers bold">{{data?.template?.data?.amountDueConfirmed | currency:'USD'}}</p>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>         
            </div>
          </div>
        </div>

        <div *ngIf="router.url == '/inboxandsupport/helpdesk'">
          <div class="card"> 
            <div class="btn-toolbar p-3" style="flex-wrap: nowrap;" role="toolbar">
              <div *ngIf="hidden" class="btn-group mb-2 mb-sm-0">
                <button style="margin: 3% 3% 0; width: 33px" type="button" class="btn btn-primary toolbarBtn" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button>
              </div>
              <div class="btn-group mb-2 mb-sm-0">
                <button type="button" class="btn btn-primary toolbarBtn btn-block" data-toggle="modal" data-target="#composemodal"
                (click)="open(HelpDesk, undefined)">{{'NOTIFICATIONS.HELPDESK.NEWTICKET' | translate}}</button>
              </div>
              <div class="col-sm-6 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter position-relative">
                  <input type="text" name="searchTerm" class="form-control searchbar form-control-sm" 
                    aria-controls="tickets-table" placeholder="{{'NOTIFICATIONS.SEARCH' |  translate}}" [(ngModel)]="searchText" />
                </div>
              </div>  
            </div>

            <ul class="message-list">
              <li class="unread" *ngFor="let ticket of helpdesk | filter: searchText; let i = index; ">
                <div class="col-mail col-mail-1" style="width: 190px;">
                  <a [routerLink]="['./helpdesk',ticket.id]" style="left: 20px !important;" class="title fade-in" (click)="currentTicket = [ticket];" >{{ticket.ticketType  | translation:profile.preferedLang}}</a>
                </div>
                <div class="col-mail col-mail-2" style="left: 200px;" (click)="currentTicket = [ticket];">
                  <a [routerLink]="['./helpdesk',ticket.id]" style="right: 150px;" class="subject fade-in" >{{ticket.description | translation:profile.preferedLang}}</a>
                  <div class="date mr-1">{{ticket.date.toDate() | date:'yyyy-MM-dd h:mm a'}}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div *ngIf="router.url.startsWith('/inboxandsupport/helpdesk') && router.url != '/inboxandsupport/helpdesk'">
          <div class="card">
            <div *ngIf="hidden" class="btn-toolbar p-3" role="toolbar">
              <div class="btn-group mb-2 mb-sm-0">
                <button type="button" class="btn btn-primary toolbarBtn" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button>
              </div>
            </div>

            <div class="card-body" *ngFor="let data of currentTicket">
              <div class="content">
                <div class="media mb-4">
                  <img *ngIf="settings" class="d-flex mr-3 avatar-sm" src="../../../assets/images/FAQ_Icons/Dzeus_Icono_d+.svg"
                    alt="Generic placeholder image">
                  <div class="media-body">
                    <h5 style="text-align: start;" class="font-size-14 mt-1">Dzeus Health</h5>
                    <h5 style="text-align: start;" class="font-size-14 mt-1">{{'HELPDESK.HDT' | translate}}</h5>
                  </div>
                </div>
                <h4 class="mt-0 font-size-18 d-flex"><p class="bold m-0">{{'HELPDESK.SUBJECT' | translate}}&nbsp;</p>{{data?.ticketType | translation:profile?.preferedLang}}</h4><br>              
                <div class="">
                  <div class="">
                    <h4 class="m-0 font-size-16 d-flex"><p class="bold m-0">{{'HELPDESK.REPORTEDBY' | translate}}&nbsp;</p>{{data?.customer | translation:profile?.preferedLang}}</h4>
                    <h4 class="m-0 font-size-16 d-flex"><p class="bold m-0">{{'HELPDESK.STATUS' | translate}}&nbsp;</p>{{data?.stage | translation:profile?.preferedLang}}</h4><br>
                  </div>
                  <div>
                    <h4 class="m-0 font-size-16 d-flex"><p class="bold m-0">{{'HELPDESK.ASSIGNEDTO' | translate}}&nbsp;</p>{{data?.assignedTo | translation:profile?.preferedLang}}</h4>
                    <h4 class="m-0 font-size-16 d-flex"><p class="bold m-0">{{'HELPDESK.DESCRIPTION' | translate}}&nbsp;</p>{{data?.description | translation:profile?.preferedLang}}</h4>
                  </div>
                </div>
              </div>      
            </div>
          </div>
        </div>

        <div *ngIf="router.url == '/inboxandsupport/faq'">
          <div class="card" >
            <button style="margin: 3% 3% 0; width: 33px" *ngIf="hidden" type="button" class="btn btn-primary toolbarBtn" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button> 
            <div class="p-3">
              <h1>{{'NOTIFICATIONS.FAQ.FAQ' | translate}}</h1>
            </div>
            <app-faqs style="overflow-y: auto; padding-bottom: 5%;"></app-faqs>
          </div>
        </div>

        <div *ngIf="router.url == '/inboxandsupport/contactus'">
          <div class="card">
            <button style="margin: 3% 3% 0; width: 33px" *ngIf="hidden" type="button" class="btn btn-primary toolbarBtn" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button> 
            <app-contactus></app-contactus>
          </div>  
        </div>
        
        <div *ngIf="router.url == '/inboxandsupport/chat'">
          <div class="card">
            <button style="margin: 1rem 1rem 0; width: 33px" *ngIf="hidden && chatLeftsidebar" type="button" class="btn btn-primary toolbarBtn" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button>
            <app-chat style="overflow-y: auto; overflow-x: hidden;" (chatLeftsidebar)="chatLeftsidebarComponent($event)"></app-chat>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="composemodalTitle">{{'NOTIFICATIONS.NEWMESSAGE' | translate}}</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">   
      <div [hidden]="profile.accountType == 'patient'" class="form-group">
        <input  id="email" type="email" class="form-control" placeholder="To" [(ngModel)]="this.to" [disabled]="disable()">
      </div>
      <div [hidden]="profile.accountType != 'patient'" class="form-group">
        To: {{toName}}
      </div>
      <div class="form-group">
        <input id="subject" type="text" class="form-control" placeholder="{{'NOTIFICATIONS.SUBJECT' | translate}}" [(ngModel)]="this.subject">
      </div>
      <div class="form-group">
        <ckeditor [editor]="Editor" [config]="{ placeholder:this.placeholder}" (keyup)="collectMessage($event)" (change)="onChange($event)"></ckeditor>
      </div> 
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{'NOTIFICATIONS.CLOSE' | translate}}</button>
    <button type="button" class="btn btn-info" (click)="sendNotification()">{{'NOTIFICATIONS.SEND' | translate}} <i class="fab fa-telegram-plane ml-1"></i></button>
  </div>
</ng-template>

<ng-template #HelpDesk let-modal>
  <i class="ri-close-fill" style="font-size: 20px; text-align: end;" (click)="modal.dismiss('Cross click')"></i>
  <app-reevaluation (close)="modalService.dismissAll()"></app-reevaluation>
</ng-template>
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class BackupService {

  constructor(
    private db: AngularFirestore
  ) { }


  calendarBackup(){
    this.db.collection("calendar").valueChanges().subscribe(async (data:any) => {
      data.forEach(element => {
        this.db.collection('backup_calendar').doc(element.id).set(element);
      });
    })
  }
  chatsBackup(){
    this.db.collection("chats").valueChanges().subscribe(async (data:any) => {
      data.forEach(element => {
        this.db.collection('backup_chats').doc(element.roomId).set(element);
        this.db.collection('chats').doc(element.roomId).collection("messageID").valueChanges().subscribe(async (msg:any) => {
          msg.forEach(m => {
            this.db.collection("backup_chats").doc(element.roomId).collection("messageID").doc(m.messageId).set(m)
          });
        });
      });
    })
  }

  conditionsBackup(){
    this.db.collection("conditions").valueChanges().subscribe(async (data:any) => {
      data.forEach(element => {
        this.db.collection('backup_conditions').doc(element.id).set(element);
        this.db.collection('conditions').doc(element.id).collection("productGroup").valueChanges().subscribe(async (groups:any) => {
          if(groups.length > 0){
            groups.forEach(g => {
              this.db.collection("backup_conditions").doc(element.id).collection("productGroup").doc(g.id.toString()).set(g)
              this.db.collection("conditions").doc(element.id).collection("productGroup").doc(g.id.toString()).collection("searchGroup").valueChanges().subscribe(async (searchs:any) => {
                if(searchs.length > 0){
                  searchs.forEach(search_group => {
                    this.db.collection("backup_conditions").doc(element.id).collection("productGroup").doc(g.id.toString()).collection("searchGroup").doc(search_group.id).set(search_group);
                  });
                }
              });
            });
          }
        });
      });
    })
  }
  conditionsQuestionsBackup(){
    this.db.collection("conditionsQuestions").valueChanges().subscribe(async (data:any) => {
      data.forEach(element => {
        this.db.collection('backup_conditionsQuestions').doc(element.id).set(element);
        this.db.collection('conditionsQuestions').doc(element.id).collection("Questions").valueChanges().subscribe(async (questions:any) => {
          if(questions.length > 0){
            questions.forEach(question => {
              if(element.id == "medicalHistory"){
                this.db.collection("backup_conditionsQuestions").doc(element.id).collection("Questions").doc(question.medicalHistoryId.toString()).set(question);
              }else{
                this.db.collection("backup_conditionsQuestions").doc(element.id).collection("Questions").doc(question.id.toString()).set(question);
              }
            });
          }
        });
      });
    })
  }

  creditCardPaymentsBackup(){
    this.db.collection("creditCardPayments").valueChanges().subscribe(async (data:any) => {
      data.forEach(element => {
        this.db.collection('backup_creditCardPayments').doc(element.id).set(element);
      });
    })
  }
  deliveryOrdersBackup(){
    this.db.collection("deliveryOrders").valueChanges().subscribe(async (data:any) => {
      data.forEach(element => {
        this.db.collection('backup_deliveryOrders').doc(element.consultationId).set(element);
        this.db.firestore.collection("deliveryOrders").doc(element.consultationId).collection("deliveryOrderLines").get().then(async (lines:any) => {
          if(lines.docs.length > 0){
            lines.docs.forEach(line => {
              this.db.collection('backup_deliveryOrders').doc(element.consultationId).collection("deliveryOrderLines").doc(line.id).set(line.data());
            })
          }
        });
      });
    })
  }

  diagnosticTemplatesBackup(){
    this.db.collection("diagnosticTemplates").valueChanges().subscribe(async (data:any) => {
      data.forEach(element => {
        this.db.collection('backup_diagnosticTemplates').doc(element.code).set(element);
      });
    })
  }
  diagnosticsTestBackup(){
    this.db.collection("diagnosticsTests").valueChanges().subscribe(async (data:any) => {
      data.forEach(element => {
        this.db.collection('backup_diagnosticsTests').doc(element.id).set(element);
      });
    })
  }

  mailBackup(){
    this.db.collection("mail").valueChanges().subscribe(async (data:any) => {
      data.forEach(element => {
        this.db.collection('backup_mail').doc(element.id).set(element);
      });
    })
  }

  mailTemplatesBackup(){
    this.db.firestore.collection("mail_templates").get().then(async (data:any) => {
      data.docs.forEach(element => {
        this.db.collection('backup_mailTemplates').doc(element.id).set(element.data());
      });
    });
  }

  ordersBackup(){
    this.db.collection("orders").valueChanges().subscribe(async (data:any) => {
      data.forEach(async element => {
        let id = element?.id ? element?.id : element?.orderNo;
        this.db.collection('backup_orders').doc(id).set(element);
        await this.orderLinesBackup(id);
      });
    });
  }

  orderLinesBackup(id){
    return new Promise<any>((resolve, reject) => {
      this.db.collection('orders').doc(id).collection("orderLines").valueChanges().subscribe(async (lines:any[]) => {
        if(lines.length > 0){
          lines.forEach((line, i)=> {
            this.db.collection('backup_orders').doc(id).collection("orderLines").doc(line.orderLineId).set(line);
            if(i == lines.length){
              resolve(true);
            }
          });
        }
        else{
          resolve(true)
        }
      })
    })
  }
  
  prescriptionBackup(){
    this.db.collection("prescription").valueChanges().subscribe(async (data:any) => {
      data.forEach(element => {
        this.db.collection('backup_prescription').doc(element.ID).set(element);
        this.db.collection('prescription').doc(element.ID).collection("Items").valueChanges().subscribe(async (items:any[]) => {
          if(items.length > 0){
            items.forEach(item => {
              this.db.collection('backup_prescription').doc(element.ID).collection("orderLines").doc(item.productID).set(item);
            });
          }
        });
      });
    });
  }

  async usersBackup(){
    this.db.collection("users").valueChanges().subscribe(async (data:any) => {
      data.forEach(async element => {
        this.db.collection('backup_users').doc(element.uid).set(element);
        // await this.userAddressBackup(element.uid);
        // await this.userAuditTrailBackup(element.uid);
        // await this.medicalHistoryBackup(element.uid);
        // await this.myPractitionersBackup(element.uid);
        // await this.walletBackup(element.uid);
        if(element.accountType == 'practitioner'){
          // await this.licenseBackup(element.uid);
          await this.NPIRegistryBackup(element.uid);
        }
        // this.db.collection('users').doc(element.uid).collection("Address").valueChanges().subscribe(async (items:any[]) => {
        //   if(items.length > 0){
        //     items.forEach(item => {
        //       this.db.collection('backup_users').doc(element.uid).collection("").doc(item.productID);
        //     });
        //   }
        // });
      });
    });
  }


  userAddressBackup(uid){
    return new Promise<any>((resolve, reject) => {
      this.db.collection('users').doc(uid).collection("Address").valueChanges().subscribe(async (addresses:any[]) => {
        if(addresses.length > 0){
          addresses.forEach((address, i) => {
            this.db.collection('backup_users').doc(uid).collection("Address").doc(address.AddressType).set(address);
            if(i == addresses.length){
              resolve(true);
            }
          });
        }else{
          resolve(true);
        }
      });
    })
  }

  userAuditTrailBackup(uid){
    return new Promise<any>((resolve, reject) => {
      this.db.collection('users').doc(uid).collection("auditTrail").valueChanges().subscribe(async (audit:any[]) => {
        if(audit.length > 0){
          audit.forEach((trail, i) => {
            this.db.collection('backup_users').doc(uid).collection("Address").doc(trail.id).set(trail);
            if(i == audit.length){
              resolve(true);
            }
          });
        }else{
          resolve(true);
        }
      });
    })
  }

  medicalHistoryBackup(uid){
    return new Promise<any>((resolve, reject) => {
      this.db.collection('users').doc(uid).collection("medicalHistory").valueChanges().subscribe(async (history:any[]) => {
        if(history.length > 0){
          history.forEach((medical, i) => {
            this.db.collection('backup_users').doc(uid).collection("medicalHistory").doc(medical.medicalHistoryId).set(medical);
            if(i == history.length){
              resolve(true);
            }
          });
        }else{
          resolve(true);
        }
      });
    })
  }
  myPractitionersBackup(uid){
    return new Promise<any>((resolve, reject) => {
      this.db.collection('users').doc(uid).collection("myPractitioners").valueChanges().subscribe(async (myPractitioners:any[]) => {
        if(myPractitioners.length > 0){
          myPractitioners.forEach((doc, i) => {
            this.db.collection('backup_users').doc(uid).collection("myPractitioners").doc(doc.id).set(doc);
            if(i == myPractitioners.length){
              resolve(true);
            }
          });
        }else{
          resolve(true);
        }
      });
    })
  }
  licenseBackup(uid){
    return new Promise<any>((resolve, reject) => {
      this.db.collection('users').doc(uid).collection("License").valueChanges().subscribe(async (license:any[]) => {
        if(license.length > 0){
          license.forEach((doc, i) => {
            this.db.collection('backup_users').doc(uid).collection("License").doc(doc.license).set(doc);
            if(i == license.length){
              resolve(true);
            }
          });
        }else{
          resolve(true);
        }
      });
    })
  }
  NPIRegistryBackup(uid){
    return new Promise<any>((resolve, reject) => {
      this.db.collection('users').doc(uid).collection("NPI Registry").valueChanges().subscribe(async (npi:any[]) => {
        if(npi.length > 0){
          npi.forEach((doc, i) => {
            this.db.collection('backup_users').doc(uid).collection("NPI Registry").doc(doc.NPI.toString()).set(doc);
            if(i == npi.length){
              resolve(true);
            }
          });
        }else{
          resolve(true);
        }
      });
    })
  }

  walletBackup(uid){
    return new Promise<any>((resolve, reject) => {
      this.db.collection('users').doc(uid).collection("wallet").valueChanges().subscribe(async (wallet:any[]) => {
        if(wallet.length > 0){
          wallet.forEach((doc, i) => {
            this.db.collection('backup_users').doc(uid).collection("wallet").doc(doc.customerNumber).set(doc);
            if(i == wallet.length){
              resolve(true);
            }
          });
        }else{
          resolve(true);
        }
      });
    })
  }

  productsBackup(){
    this.db.collection("products").valueChanges().subscribe(async (data:any) => {
      data.forEach(element => {
        // this.db.collection('backup_products').doc(element.id.toString()).set(element);
        this.db.collection('products').doc(element.id.toString()).collection("packProducts").valueChanges().subscribe(async (items:any[]) => {
          if(items.length > 0){
            items.forEach(item => {
              this.db.collection('backup_products').doc(element.id.toString()).collection("packProducts").doc(item.productID).set(item);
            });
          }
        });
      });
    });
  }
  productsPacksBackup(){
    this.db.collection("productsPacks").valueChanges().subscribe(async (data:any) => {
      data.forEach(element => {
        this.db.collection('backup_productsPacks').doc(element.packID.toString()).set(element);
      });
    });
  }

  consultationBackup(){
    this.db.collection("consultation").valueChanges().subscribe(async (data:any) => {
      data.forEach(async consult => {
        // this.db.collection('backup_consultation').doc(consult.ID).set(consult);
        // await this.consultAddress(consult);
        // await this.consultDiagnostics(consult);
        // await this.consultHistory(consult);
        // await this.consultQuestions(consult);
        // await this.consultItems(consult);
        // await this.consultStopQuestions(consult);
        // await this.consultDiagnosticTemplates(consult);
        await this.consultNPIRegistry(consult);
      })
    });
  }

  async consultQuestions(consult){
    //Questions
    return new Promise<any>((resolve, reject) => {
      this.db.collection('consultation').doc(consult.ID).collection("Questions").valueChanges().subscribe((questions:any) => {
        questions.forEach(question => {
          this.db.collection('backup_consultation').doc(consult.ID).collection("Questions").doc(question.id).set(question);
        });
        resolve(true);
      });
    });
  }

  async consultationsFormated(){
    return new Promise<any>((resolve, reject) => {
      this.db.collection("backup_consultation").valueChanges().subscribe(async (data:any) => {
        resolve(await data);
      });
    });
  }

  async consultAddress(consult){
    //Address
    return new Promise<any>((resolve, reject) => {
      this.db.collection('consultation').doc(consult.ID).collection("Address").valueChanges().subscribe((addresses:any) => {
        if(addresses.length > 0){
          addresses.forEach((address, i) => {
            this.db.collection('backup_consultation').doc(consult.ID).collection("Address").doc(address.AddressType).set(address);
            if(i == addresses.length){
              resolve(true);
            } 
          });
        }else{
          resolve(true);
        }
      });
    });
  }

  async consultHistory(consult){
    //History
    return new Promise<any>((resolve, reject) => {
      this.db.collection('consultation').doc(consult.ID).collection("History").valueChanges().subscribe((historial:any) => {
        if(historial.length > 0){
          historial.forEach((trail, i) => {
            this.db.collection('backup_consultation').doc(consult.ID).collection("History").doc(trail.id).set(trail);
            if(i == historial.length){
              resolve(true);
            }
          });
        }else{
          resolve(true);
        }
      });
    });
  }

  async consultItems(consult){
    // Items
    return new Promise<any>((resolve, reject) => {
      this.db.collection('consultation').doc(consult.ID).collection("Items").valueChanges().subscribe((items:any) => {
        if(items.length > 0){
          items.forEach((item, i) => {
            if(item.type == "service"){
              this.db.collection('backup_consultation').doc(consult.ID).collection("Items").doc(item.type).set(item);
            }else{
              this.db.collection('backup_consultation').doc(consult.ID).collection("Items").doc(item.id.toString()).set(item);
            }
            if(i == items.length){
              resolve(true);
            }
          });
        }else{
          resolve(true);
        }
      });
    });
  }
  async consultDiagnostics(consult){
    // Diagnostics = field = id
    return new Promise<any>((resolve, reject) => {
      this.db.collection('consultation').doc(consult.ID).collection("Diagnostics").valueChanges().subscribe((diagnostics:any) => {
        if(diagnostics.length > 0){
          diagnostics.forEach((diagnostic, i) => {
            this.db.collection('backup_consultation').doc(consult.ID).collection("Diagnostics").doc(diagnostic.id).set(diagnostic);
            if(i == diagnostics.length){
              resolve(true)
            }
          });
        }else{
          resolve(true);
        }
      });
    });
  }

  async consultWishlist(consult){
    // wishlist

  }

  async consultDiagnosticTemplates(consult){
    // diagnosticTemplates = field = code
    return new Promise<any>((resolve, reject) => {
      this.db.collection('consultation').doc(consult.ID).collection("diagnosticTemplates").valueChanges().subscribe((diagnostics:any) => {
        if(diagnostics.length > 0){
          diagnostics.forEach((diagnostic, i) => {
            this.db.collection('backup_consultation').doc(consult.ID).collection("diagnosticTemplates").doc(diagnostic.code).set(diagnostic);
            if(i == diagnostics.length){
              resolve(true);
            }
          });
        }else{
          resolve(true);
        }
      });
    });
  }


  async consultStopQuestions(consult){
    // stopQuestions
    return new Promise<any>((resolve, reject) => {
      this.db.collection('consultation').doc(consult.ID).collection("stopQuestions").valueChanges().subscribe((questions:any) => {
        if(questions.length > 0){
          questions.forEach((question, i) => {
            this.db.collection('backup_consultation').doc(consult.ID).collection("stopQuestions").doc("1").set(question);
            if(i == questions.length){
              resolve(true);
            }
          });
        }else{
          resolve(true);
        }
      });
    });
  }
  async consultNPIRegistry(consult){
    // stopQuestions
    return new Promise<any>((resolve, reject) => {
      this.db.collection('consultation').doc(consult.ID).collection("NPI Registry").valueChanges().subscribe((registry:any) => {
        if(registry.length > 0){
          registry.forEach((data, i) => {
            this.db.collection('backup_consultation').doc(consult.ID).collection("NPI Registry").doc(data.NPI.toString()).set(data);
            if(i == registry.length){
              resolve(true);
            }
          });
        }else{
          resolve(true);
        }
      });
    });
  }


  async getConsultCondition(id){
    return new Promise<any>((resolve, reject) => {
      // this.db.collection("conditions",ref => ref.where("id","==", id)).valueChanges().subscribe(async condition => {
      this.db.collection("conditions").doc(id).valueChanges().subscribe(async condition => {
        resolve(await condition);
      });
    });
  }

  

}


import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { Prescription } from '../../../core/classes/prescriptions';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { TranslationPipe } from 'src/app/translation.pipe';
import { OrdersService } from 'src/app/core/services/orders.service';
import { Observable, forkJoin } from 'rxjs';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { BaseProduct, IPharmacySubscription, UpcomingRefillOrderLine } from 'src/app/core/services/interfaces';
import { v4 as uuidv4 } from 'uuid';
import { PharmacyOrderScheduleService } from 'src/app/core/services/pharmacy-order-schedule.service';

@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html',
  styleUrls: ['./prescription-list.component.scss']
})

export class PrescriptionListComponent implements OnInit {

  @Input() consult:any;
  @Input() user:any;
  @Input() consultPrescriptionOnly:boolean = false;
  @Input() condition: string;
  @Input() notInPopUp = true;
  @ViewChild('orderDetailsRef', { read: TemplateRef }) orderDetailsRefModal:TemplateRef<any>;
  @ViewChild('deliveryScheduleModal', { read: TemplateRef }) deliveryScheduleModal:TemplateRef<any>;

  prescriptionParam:any;

  term:string;
  modalRef:any;
  prescriptions: UpcomingRefillOrderLine[] = [];
  pharmacySubscriptions:any = {};
  prescriptionLatestOrders: {}
  unexpiredPrescriptions: any[] = [];
  productsBasedOnPrescription:any[] = [];
  refillProductsAvailable:any[] = [];
  productsToRefill:any[] = [];
  pipe = new TranslationPipe();

  today = new Date();
  
  lists$: Observable<any>;
  prescription_line: UpcomingRefillOrderLine;
  order:any = null;

  productToChange:any;
  migrateProduct:boolean = false; 
  changeNextRefillDate: boolean = false;
  nextRefillDate:any;

  showShipmentFrequency: boolean[] = [];
  deliveryFrequencies: any[] = [];

  currentUser: any;

  pricelists: any[] = [];
  loading: boolean = true;
  
  constructor(
    public router: Router,
    private modalService: NgbModal,
    private afs: AngularFirestore,
    private firebaseService: FirebaseService,
    private authService: AuthenticationService,
    public translate: TranslateService,
    private orderService: OrdersService,
    private productService: ProductsService,
    private route: ActivatedRoute,
    private pharmacyOrderScheduleService: PharmacyOrderScheduleService,
  ) {
  }
  
  async ngOnInit() {
    this.user = this.user ? this.user : await this.authService.profile();
    this.currentUser = await this.authService.profile()
    this.route.queryParams.subscribe(params => {
      this.prescriptionParam = params['prescription'];
    }).unsubscribe();
    const navigationExtras: NavigationExtras = {
      queryParams: null,
      queryParamsHandling: 'merge'
    };
    // Navigate to the same route (current route) with the NavigationExtras
    this.router.navigate([], {queryParams: null});
    await this.getPrescriptionsItems();
    this.today.setHours(0,0,0,0);

    this.afs.firestore.collection("pricelist").get().then((pricelists:any) => {
      this.pricelists = pricelists.docs.map(doc => doc.data());
    })
  }

  async getPrescriptionsItems(){
    const prescription = new Prescription(this.afs, this.firebaseService);
    prescription.profile = this.user;

    /**
     *  Esto fue cambiado a una busqueda en la coleción de `erp_dzeus_queryz_prescription_full_info`
     *  Originalmente se buscaba todo en las colleciones locales y no por esta colección que nos la da el erp.
     */

    let presc = await this.getPrescriptions();
    if(this.condition){
      this.prescriptions = presc.filter(x => x.Type == this.condition);
    }

    if(this.prescriptionParam){
      let match = this.prescriptions.find(x => x.id == this.prescriptionParam);
      this.changeProduct(match)
    }
  }

  getPricelist(pricelist){
    return this.pricelists ? this.pricelists.filter(x => x.id.toString() == pricelist?.toString())[0]?.name : '';
  }

  getConsult(id:string){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("consultation").doc(id).valueChanges().subscribe(async (data:any) => {
        resolve(data);
      });
    });
  }

  disableCreateRefill = false
  async getPrescriptions(): Promise<any>{
    
    return new Promise<any>((resolve, reject) => {
      this.afs.firestore.collection("pharmacyOrderSchedule").where('uid', '==', this.user.uid).get().then(async (subscriptions: any) => {

        let subs = subscriptions.docs.map(doc => doc.data());

        this.afs.collection("erp_dzeus_query_prescription_full_info", ref => ref.where('valid', '==', true).where('uid', '==', this.user.uid)).valueChanges().subscribe(async (actions:UpcomingRefillOrderLine[]) => {
          this.prescriptions = [];
          actions = actions.sort((a, b) => {
            return b.date.seconds - a.date.seconds;
          })
          for(let i = 0; i < actions.length; i++) {
            actions[i].erpId = actions[i].id;
            let d = new Date(actions[i].date.seconds * 1000); 
            let year = d.getFullYear();
            let month = d.getMonth();
            let day = d.getDate();
            let expires = new Date(year + 1, month, day );
            let nextRefillDay:any = d.getDate() + actions[i].refillIntervalDays;
            let nextRefill:any = new Date(actions[i].date.seconds * 1000);
            nextRefill.setDate(nextRefillDay)

            await this.afs.firestore.collection('consultation').doc(actions[i].consultID).get().then((consult) => {
                actions[i] = Object.assign(actions[i], {Type: consult.data()?.Type ? consult.data()?.Type : "Erectile Dysfunction"})
            })

            let tempSub = subs.filter(x => x.prescriptionID == actions[i].prescriptionID)[0];
            actions[i] = Object.assign(actions[i], tempSub);
          
            let tempItem = Object.assign(actions[i], {expires: expires, nextRefill: nextRefill});
  
            this.prescriptions.push(tempItem);

            console.warn('Prescriptions', this.prescriptions)
            if(actions.length - 1 == i){
              this.prescriptionLatestOrders = await this.getLatestOrders(this.prescriptions);
              this.pharmacySubscriptions = await this.getNextSubscriptionDate(this.prescriptions)
              resolve(this.prescriptions);
  
            }
          };

          if(actions.length == 0){
            this.loading = false;
            resolve(this.prescriptions);
          }
        });
      })
    });
  }

  dismissListener(){
    this.modalRef.close();
    this.modalRef = undefined;
    this.productsToRefill = []
  }

  remainingRefills(refill){
    let string = refill.split("/");

    return Number.isNaN(Number(string[1] - string[0])) ? 0 : string[1] - string[0];
  }

  // queremos verificar si ya existe un refill que no esta pago.
  // si existe, abrir esta orden y verificar la fecha que se supone que sea el refill,
  // si aun no ha pasado el tiempo estipulado mostrar la opcion esa de que el escoja una razon por la cual desea el refill antes de tiempo
  // se le va a permitir pagar esta orden y tannia va a poder verla alla en farmacia y decide si despacharla o no.
  // si no, hay que devolverle el dinero al paciente. 

  async changeProduct(product){
    let consult = await this.getConsult(product.consultID);
    if(consult){
      let condition = await this.getCondition(consult.Type);
      this.condition = condition.conditionCode;
    }

    this.productToChange = product;
    this.migrateProduct = true;

  }

  getCondition(condition:string){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("consultTemplates").doc(condition).valueChanges().subscribe((template:any) => {
        resolve(template);
      });
    });
  }

  async getLatestOrders(prescriptions: UpcomingRefillOrderLine[]){
    let orders = {};
    return new Promise<any>((resolve, reject) => {
      prescriptions.forEach(async (prescription, i) => {
        if(prescription.orderID != null){
          this.afs.collection("orders", ref => ref.where("orderId", "==", prescription.orderID.toString()).where("uid", "==", prescription.uid)).valueChanges().subscribe((order:any) => {
            orders[i] = {...order[0]}
            if(i == prescriptions.length - 1){
              resolve(orders);
            }
            return {...order[0]}
          });
        }
        else {
          if(i == prescriptions.length - 1){
            resolve(orders);
          }
          return null;
        }
      });
    });
  }

  async linkToPharmacyOrderSchedule(prescriptions: UpcomingRefillOrderLine[]){
    return new Promise<any>((resolve, reject) => {

      prescriptions.forEach(async (prescription, i) => {
        this.afs.collection("pharmacyOrderSchedule", ref => ref.where("uid", "==", prescription.uid).where("prescriptionID", "==", prescription.prescriptionID)).valueChanges().subscribe((subscriptions:IPharmacySubscription[]) => {
          const subscription = subscriptions.find(x => x.productID == prescription.productID.toString());
          if(subscription){
            this.afs.collection("erp_dzeus_query_prescription_full_info").doc(prescription.id).update({subscriptionID: subscription.id});
            
          }
          if(i == prescriptions.length - 1){
            resolve(true);
          }
        });
      });
    });
  }

  async getNextSubscriptionDate(prescriptions: UpcomingRefillOrderLine[]){
    let subscriptions = {};
    let subscriptionQuery: any = [];
    let promise = new Promise<any>((resolve, reject) => {
      this.afs.firestore.collection("pharmacyOrderSchedule").where('uid', '==', this.user.uid).get().then((sub: any) => {
        subscriptionQuery = sub.docs.map(doc => doc.data());
      
        prescriptions.forEach(async (prescription, i) => {
          let subscription = subscriptionQuery.find(x => x.prescriptionID == prescription.prescriptionID); 
          if(subscription){
             subscriptions[i] = {...subscription};
           }

           if(i == prescriptions.length - 1){
            this.loading = false;
             resolve(subscriptions);
           }
       });

       if(prescriptions.length == 0){
          this.loading = false;
          resolve(subscriptions);
       }
      })
    });

    return promise;
  }

  formatNextSubscriptionDate = ''
  async changeDeliveryFrequency(prescription, subscription){
    if(subscription && prescription.expires > new Date()){
      this.productToChange = subscription;
      this.formatNextSubscriptionDate = this.formatDate(prescription.nextSubscriptionDate ? prescription.nextSubscriptionDate.toDate() : prescription.nextRefill ? prescription.nextRefill : new Date());
      this.deliveryFrequencies = subscription ? await this.productService.getProductDeliveryFrequencyOrderLines([{productID: subscription.productID}]) : [];
      this.modalRef = this.modalService.open(this.deliveryScheduleModal, {size:"lg", backdrop:'static', centered:true});
  
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set to midnight (12:00:00 AM)\
      const tomorrow = new Date(today.setDate(today.getDate() + 1));
  
  
      const formattedDate = this.formatDate(this.currentUser.accountType == 'admin' ? new Date().toISOString() : tomorrow.toISOString());
  
      const maxFormattedDate = this.formatDate(prescription.expires.toISOString());
      // Set the min attribute of the input element
      document.getElementById('datepicker').setAttribute('min', formattedDate);
      document.getElementById('datepicker').setAttribute('max', maxFormattedDate);
    }
    else {
      //What to do if there's no subscription?

      if(prescription.expires < new Date()){
        this.translate.get('PRESCRIPTIONS-LISTS').subscribe((res: any) => {
          Swal.fire({
            title: res.PRESCRIPTIONEXPIRED,
            icon: 'error',
            confirmButtonText: res.DISMISS
          })
        })
      }
    }
  }

  async changeNotificationStatus(subscription, index){
    if(subscription){
      this.afs.collection("pharmacyOrderSchedule").doc(subscription.id).update({sendNotifications: !subscription.sendNotifications});
      this.pharmacySubscriptions[index].sendNotifications = !subscription.sendNotifications;
    }
  }

  async changeSubscriptionStatus(subscription, index){
    if(subscription){
      this.afs.collection("pharmacyOrderSchedule").doc(subscription.id).update({status: subscription.status == 'active' ? 'inactive' : 'active'});
      this.pharmacySubscriptions[index].status = subscription.status == 'active' ? 'inactive' : 'active';
    }
  }

  setShipmentFrequency(freq){    
    this.productToChange.deliveryFrequencyInDays = freq.frequency;
    this.productToChange.automaticPayment = freq.autoPayment;
  }

  saveSchedule(){
    let nextSubscriptionDate = new Date(this.formatNextSubscriptionDate);

    this.pharmacyOrderScheduleService.updatePharmacyOrderScheduleDeliveryFrequency(this.productToChange.uid, this.productToChange, this.productToChange.deliveryFrequencyInDays, this.productToChange.automaticPayment, nextSubscriptionDate);
    this.modalService.dismissAll();
  }

  async dismissScheduleChanges(){
    this.productToChange = {};
    this.pharmacySubscriptions = await this.getNextSubscriptionDate(this.prescriptions)
    this.modalService.dismissAll();
  }

  setDeliveryFrequency(frequency){
    return this.productToChange.deliveryFrequencyInDays == frequency.frequency && this.productToChange.automaticPayment == frequency.autoPayment
  }

  formatDate(inputDate: string): string {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 because getMonth returns zero-based index
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Profile } from 'src/app/core/services/interfaces';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { boolean } from 'zod';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-change-product',
  templateUrl: './change-product.component.html',
  styleUrls: ['./change-product.component.scss']
})
export class ChangeProductComponent  implements OnInit{
  @Input() user: Profile;
  @Input() condition: string;
  @Input() prescription: any;

  @Output() dismiss = new EventEmitter<boolean>();


  possible_products = [];

  conditionName: string;

  productsArray: any[] = [];

  productsPackArray: any[] = [];

  possibleProductsArray: any[] = [];

  all_possible_products: any[] = [];

  constructor(
    private router: Router,
    private productsService: ProductsService,
    private afs: AngularFirestore,
  ){

  }

  ngOnInit(){

    this.getProducts();
  }

  redirectToConsults(){
    this.router.navigate(['/consultations'], {queryParams: {condition: this.condition}});
  }


  getProducts(){
    this.getCurrentCondition();
    this.productsService.getProductsByRXCUI(this.prescription.rxcui).subscribe((products:any) => {
      this.possible_products = products;//.filter(p => p.hidePractitioner == false);
      this.prescription['price'] = this.possible_products.find(p => p.qty == this.prescription.productQty && p.refillIntervalDays == this.prescription.refillIntervalDays)?.price;
      this.possible_products = this.possible_products.filter(p => p.qty != this.prescription.productQty && p.refillIntervalDays != this.prescription.refillIntervalDays);
    });
  }


  setProduct(product){
    localStorage.setItem('package', product.id)
    this.redirectToConsults();
  }

  close(){
    this.dismiss.emit(true);
  }

  setCurrentPrescriptionPrice(){
    let product = this.possible_products.filter(p => p.qty == this.prescription.qty && p.refillIntervalDays == this.prescription.refillIntervalDays)[0];
    return product.price;
  }

  setProductName(product){
    return `${product.split('-')[0]} - ${product.split('-')[1]}`;
  }

  setProductDetails(product){
    return `${product.split('-')[2]}`;
  }

  getCurrentCondition(){

    var arr = [];

    this.afs.firestore.collection('consultation').where('ID', "==", this.prescription.consultID).get().then((querySnapshot) => {

      querySnapshot.forEach((doc) => {

        this.conditionName = doc.data().Type;

        this.productsService.getProductsByCondition(this.conditionName, this.prescription.rxcui, this.prescription.productQty).subscribe((products:any) => {

          // this.possible_products = products;

          products.map(product => {

            const hasCommon = this.hasCommonWord(product.atr0, this.prescription.productName);
            
            if(hasCommon){
              arr.push(product);

              this.getProductsPack(product.id);

            }

          });

          this.productsArray = arr;

        });

      });

    });

  }

  getProductsPack(id){

    this.productsService.getProductsPackByCondition(id).subscribe((products2:any) => {

      products2.map(product => {
          this.productsPackArray.push(product);
      });

      if(this.productsArray.length === this.productsPackArray.length){

        this.productsArray.map((product, index) => {

          var obj = {
            allowRefillBeforeDays: product.allowRefillBeforeDays,
            atr0: product.atr0,
            atr1: product.atr1,
            atr2: product.atr2,
            atr3: product.atr3,
            atr4: product.atr4,
            atr5: product.atr5,
            atr6: product.atr6,
            atr7: product.atr7,
            atr8: product.atr8,
            atr9: product.atr9,
            condition: product.condition,
            hidePractitioner: product.hidePractitioner,
            highlight: product.highlight,
            id: product.id,
            imgRef: product.imgRef,
            isPack: product.isPack,
            maxPoint: product.maxPoint,
            minPoint: product.minPoint,
            minRefillQty: product.minRefillQty,
            name: this.productsPackArray[index].name,
            price: product.price,
            productGroup: product.productGroup,
            qty: this.productsPackArray[index].qty,
            recommended: product.recommended,
            refillIntervalDays: product.refillIntervalDays,
            refillQty: product.refillQty,
            rxInstruction: product.rxInstruction,
            rxcui: product.rxcui,
            sequence: product.sequence,
            treatment: product.treatment,
            type: product.type,
          }

          if(this.prescription.productName != obj.name){

            this.possibleProductsArray.push(obj);

            const finalPossibleProductsArray = this.removeDuplicateObjects(this.possibleProductsArray);
  
            this.all_possible_products = finalPossibleProductsArray.sort((a, b) => {
              const stringA = a.name.toLowerCase();
              const stringB = b.name.toLowerCase();
              if (stringA < stringB) {
                  return -1;
              }
              if (stringA > stringB) {
                  return 1;
              }
              return 0;
            });

          }
    
        });
  
      }

    });
    
  }

  hasCommonWord(string1: string, string2: string): boolean {
    // Split the strings into arrays of words
    const words1 = string1.split(' ');
    const words2 = string2.split(' ');

    // Iterate through each word in one array and check if it exists in the other array
    for (const word of words1) {
        if (words2.includes(word)) {
            return true; // If a common word is found, return true
        }
    }

    // If no common word is found, return false
    return false;
  }

  removeDuplicateObjects(arr: any[]): any[] {
    const uniqueObjects = [];
    for (let i = 0; i < arr.length; i++) {
        let isDuplicate = false;
        for (let j = 0; j < uniqueObjects.length; j++) {
            if (this.isEqual(arr[i], uniqueObjects[j])) {
                isDuplicate = true;
                break;
            }
        }
        if (!isDuplicate) {
            uniqueObjects.push(arr[i]);
        }
    }
    return uniqueObjects;
  }

  // Helper function to check if two objects are equal
  isEqual(obj1: any, obj2: any): boolean {
      // Implement your logic here to compare objects
      // For example, you can use JSON.stringify for a simple comparison
      return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

}

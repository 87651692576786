import { AngularFirestore } from "@angular/fire/compat/firestore";
import { resolve } from "dns";
import { Subscription } from "rxjs";
import { RxPrescription } from "../interfaces/prescriptions";
import { FirebaseService } from "../services/firebase.service";
import { Profile } from "../services/interfaces";
import { v4 as uuidv4 } from 'uuid';
import { AuthenticationService } from "../services/auth.service";

interface Prescriptions {
  ID: string,
  address: string
  age: number,
  date: any,
  docAdd: string,
  docId: string,
  docName: string
  docPhone: string,
  email: string,
  name: string,
  phone: string,
  diagnostics: string[],
  consultID: string,
  prescription_pdf: string,
  z_base64?: string,
  make_pdf: string,
  status: string,
  prescription_type: string,
  Specialty:string;
  prescriptionURL:string;
  internal: boolean;
}

export class Prescription{

  profile: Profile
  consult: any;
  shipping: any;
  NPI: any;
  practitioner: any;
  product: any;
  docAdd:any;
  deliveryOrder:boolean = false;
  allSubscriptions = new Subscription()

  constructor(
    private afs: AngularFirestore, 
    private firebaseService: FirebaseService,
    // private authService: AuthenticationService
    )
  {
  }



  async generateOriginalPrescription(){
    //TODO create multiple prescriptions
    return new Promise(async (resolve, reject) => {

      let date = new Date(Date.now());
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();
      let id:any = yyyy + mm + dd + uuidv4();
      let { firstName, middleName, lastName1, email, phoneNumber, birthdate, uid, partnerID, preferedLang } = this.profile;
      if(middleName == undefined){
        middleName = '';
      }
      let fullName:string = firstName + " " + middleName + " " + lastName1;
      let prescription: RxPrescription = {
        ID: id.toString(),
        address: this.shipping,
        age: this.calculateAge(birthdate),
        date: date,
        docAdd: this.docAdd[0],
        docId: this.practitioner.uid,
        docName: this.practitioner.firstName + " " + this.practitioner.lastName1,
        docPhone: this.practitioner.phoneNumber,
        docEmail: this.practitioner.email,
        name: fullName,
        email: email,
        phone: phoneNumber,
        consultID: this.consult.ID,
        diagnostics: [],
        License: this.NPI.taxonomies[0].license,
        LicenseState: this.NPI.taxonomies[0].state,
        NPI: this.NPI.NPI,
        Specialty:this.NPI.taxonomies[0].desc,
        prescription_pdf: "prescription-" + this.consult.ID+"-"+ id,
        prescription_type: "Rx",
        status: "Unsigned",
        internal:true,
        birthdate: birthdate,
        prescriptionURL: "",
        valid: true,
        uid: uid,
        partnerID: partnerID,
        lang: preferedLang,
        pharmacy: "",
        erpPrescriptionRef: "",
        automaticPayment: false,
        patientSignature: this.consult.patientSignature ? this.consult.patientSignature : "",
        patientSignedDate: this.consult.dateCertified ? this.consult.dateCertified : "",
        patientSignRef: this.consult.signRef ? this.consult.signRef : "",
        orientation: this.consult.orientation != undefined ? this.consult.orientation : false,
        transcribed: false
      }

      await this.firebaseService.createPrescription(prescription.ID, prescription);
        resolve(prescription)
    })
  }



  calculateAge(user){
    var date = new Date(user);
    var today = new Date();
    var timeDiff = Math.abs(today.getTime() - date.getTime());
    // var age1 = Math.ceil(timeDiff / (1000 * 3600 * 24)) / 365;
    var age1 = Math.floor(timeDiff / (1000 * 3600 * 24)) / 365.25;
    var age = Math.floor(age1);
    return age;
  }
  

  logger(){
  }

  getConsultPrescriptionsItems(consult:any){
    let tablets: any[] = [];
    let end:Promise<any>
    let promise 
    return promise = new Promise<any>((resolve, reject) => {
        this.afs.collection("prescription", ref => ref.where("uidPatient", "==", consult.ID)).valueChanges().subscribe(async (prescriptions:any) => {
        prescriptions.map((p, i )=> {
          this.afs.collection("prescription").doc(p.ID).collection("Items").valueChanges().subscribe(async (items:any) => {
            items.map((item, index) => {
              item = Object.assign(prescriptions[i], item);
              tablets.push(item);
              return item;
            });
            if(tablets.length >= prescriptions.length){
              resolve(tablets);
            }        
          });
        })

        Promise.all([promise]).then((values:any) => {
          Promise.resolve(values)
        })
      })
    })
  }


  getPrescriptionsItems(consult:any){
    let tablets: any[] = [];
    let end:Promise<any>
    let promise 
    return promise = new Promise<any>((resolve, reject) => {
        this.afs.collection("prescription", ref => ref.where("consultID", "==", consult.ID).where("erpPRescriptionRef", '==', "none")).valueChanges().subscribe(async (prescriptions:any) => {
        prescriptions.map((p, i) => {
          this.afs.collection("prescription").doc(p.ID).collection("Items").valueChanges().subscribe(async (items:any) => {
            items.map((item, index) => {
              let { ID, date, prescription_type, consultID } = prescriptions[i];
              let { Type, conditionName } = consult;
              let prescription_data = {ID, date, prescription_type, consultID, conditionName };
              item = Object.assign(prescription_data, item);
              tablets.push(item);
              return item;
            });
            if(tablets.length >= prescriptions.length){
              resolve(tablets);
            }        
          });
        })

        Promise.all([promise]).then((values:any) => {
          Promise.resolve(values)
        })
      })
    })
  }

  async getMyPrescriptionsItems(){
    
    let tablets: any[] = [];
    let end:Promise<any>
    let promise 
    return promise = new Promise<any>((resolve, reject) => {
      this.afs.collection("prescription", ref => ref.where("uid", "==", this.profile.uid).where('status', '==', 'Signed').where('pharmacy', '==', 'Dzeus')).valueChanges().subscribe(async (prescriptions:any) => {
        tablets = []
        prescriptions.filter(x => x.valid);
        prescriptions.map((p, i) => {
          this.afs.firestore.collection("prescription").doc(p.ID).collection("Items").get().then(async (items:any) => {
            items.docs.map((item, index) => {
              let { ID, date, prescription_type, consultID, recurrency_order, uid, valid } = prescriptions[i];
              let d = new Date(date.seconds * 1000); 
              var year = d.getFullYear();
              var month = d.getMonth();
              var day = d.getDate();
              var expires = new Date(year + 1, month, day)
              let nextRefillDay:any = d.getDate() + item.data().refillIntervalDays;
              // nextRefillDay = nextRefillDay + (30 * 24 * 60 * 60 * 1000);
              // let nextRefillDay:any = d.getDate();
  
              let nextRefill:any = new Date(date.seconds * 1000);
              nextRefill.setDate(nextRefillDay)
              
              // let { Type, conditionName } = consult;
              // let prescription_data = {ID, date, prescription_type, consultID, conditionName };
              // let prescription_data = { ID, date, prescription_type, consultID };
              let prescription_data = { ID, date, consultID, expires, nextRefill, recurrency_order, uid, valid };
              let tempItem = Object.assign(prescription_data, item.data());
              tablets.push(tempItem);
              return tempItem;
            });
            if(tablets.length >= prescriptions.length){
              resolve(tablets);
            }        
          });
        });

        Promise.all([promise]).then((values:any) => {
          Promise.resolve(values)
        });
      });
    });
  }


  async getAllPrescriptionsItems(){
    
    let tablets: any[] = [];
    let end:Promise<any>
    let promise 
    return promise = new Promise<any>((resolve, reject) => {
      this.afs.collection("prescription", ref => ref.where('status', '==', 'Signed').where('pharmacy', '==', 'Dzeus')).valueChanges().subscribe(async (prescriptions:any) => {
        tablets = []
        prescriptions.map((p, i) => {
          this.afs.firestore.collection("prescription").doc(p.ID).collection("Items").get().then(async (items:any) => {
            items.docs.map(async (item, index) => {
              let { ID, date, prescription_type, consultID, recurrency_order, uid, email } = prescriptions[i];
              // let patient = await this.authService.getUserProfile(uid);
              // {}
              let d = new Date(date.seconds * 1000); 
              var year = d.getFullYear();
              var month = d.getMonth();
              var day = d.getDate();
              var expires = new Date(year + 1, month, day )
              // let { Type, conditionName } = consult;
              // let prescription_data = {ID, date, prescription_type, consultID, conditionName };
              // let prescription_data = { ID, date, prescription_type, consultID };
              let prescription_data = { ID, date, consultID, expires, recurrency_order, uid, email };
              let tempItem = Object.assign(prescription_data, item.data());
              tablets.push(tempItem);
              return tempItem;
            });
            if(tablets.length >= prescriptions.length){
              resolve(tablets);
            }        
          });
        });

        Promise.all([promise]).then((values:any) => {
          Promise.resolve(values)
        });
      });
    });
  }


  async getPrescriptionItems(id:string){
    let promise 
    return promise = new Promise<any>((resolve, reject) => {
      this.afs.collection("prescription").doc(id).valueChanges().subscribe(async (prescriptions:any) => {
        this.afs.firestore.collection("prescription").doc(id).collection("Items").get().then(async (items:any) => {
          resolve(items.docs.map(x => x.data()));       
        });
      });
    });
  }



  async getPrescriptions() : Promise<any> {
    let tablets: any[] = [];
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("erp_dzeus_query_prescription_full_info", ref => ref.where('valid', '==', true).where('uid', '==', this.profile.uid)).valueChanges().subscribe(async (prescriptions:any) => {
        prescriptions.map((p, i) => {
          let d = new Date(p.date.seconds * 1000); 
          let year = d.getFullYear();
          let month = d.getMonth();
          let day = d.getDate();
          let expires = new Date(year + 1, month, day );
          let nextRefillDay:any = d.getDate() + p.refillIntervalDays;
          // nextRefillDay = nextRefillDay + (30 * 24 * 60 * 60 * 1000);
          // let nextRefillDay:any = d.getDate();

          let nextRefill:any = new Date(p.date.seconds * 1000);
          nextRefill.setDate(nextRefillDay)

        
          let tempItem = Object.assign(p, {expires: expires, nextRefill: nextRefill});
          tablets.push(tempItem);
          return tempItem;
        });
        if(tablets.length >= prescriptions.length){
          resolve(tablets);
        }   
      });
    });
  }
}
import { Injectable, ChangeDetectorRef } from '@angular/core';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Subject } from 'rxjs';
import { AuditTraceService } from './audit-trace.service';
import { AuthenticationService } from './auth.service';
import { fbRemoteConfig } from '../../authUtils'


@Injectable({
  providedIn: 'root'
})
export class IdleService {

  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;

  notification:Subject<boolean> = new Subject();
  remoteConfig:any = fbRemoteConfig.defaultConfig;
  constructor(
    private idle: Idle, 
    private authService: AuthenticationService,
    private auditTraceService: AuditTraceService
    
  ) {
        // set idle parameters
        idle.setIdle(900); // how long can they be inactive before considered idle, in seconds
        idle.setTimeout(900); // how long can they be idle before considered timed out, in seconds
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active
        
    
    
        // do something when the user becomes idle
        idle.onIdleStart.subscribe(() => {
          this.idleState = "IDLE";
          this.notification.next(true);
        });
        // do something when the user is no longer idle
        idle.onIdleEnd.subscribe(() => {
          this.idleState = "NOT_IDLE";
          this.countdown = null;
          this.notification.next(false);
          // cd.detectChanges(); // how do i avoid this kludge?
        });
        // do something when the user has timed out
        idle.onTimeout.subscribe(() => {
          this.idleState = "TIMED_OUT"
          let message = `System logged out user due to being idle for ${Number(this.remoteConfig.idleTimeout)/60} minutes`;
          this.auditTraceService.actionTracer(message).then(async (res) => {
            if(res == true){
              this.authService.logout();
              window.location.reload()
            }
          });
          // this.
        });
        // do something as the timeout countdown does its thing
        idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);
    
        // set keepalive parameters, omit if not using keepalive
        // keepalive.interval(15000); // will ping at this interval while not idle, in seconds
        // keepalive.onPing.subscribe(() => this.lastPing = new Date()); // do something when it pings
   }


   async reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
    this.idle.setIdle(await Number(this.remoteConfig.iddleTimeout)); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(await Number(this.remoteConfig.iddleTimeout) + 60);
  }

}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { MedicalInsurance, Profile } from 'src/app/core/services/interfaces';
import { LaboratoriesService } from 'src/app/core/services/laboratories/laboratories.service';
import { ConsultationsService } from '../../consultations/consultations.service';
import { MedicalInsuranceService } from 'src/app/core/services/medical-insurance/medical-insurance.service';
import { NULL_MEDICAL_INSURANCE } from 'src/app/core/interfaces/const/interfaces';
import { RxPrescription } from 'src/app/core/interfaces/prescriptions';
import { PrescriptionService } from 'src/app/core/services/prescription.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, OnDestroy {

  @Input() user: Profile;
  @Input() file_types: string = ".pdf";
  @Input() uploadMedicalInsurance: boolean = false;
  @Input() prescription: RxPrescription;
  @Input() uploadPrescription: boolean = false;

  @Output() close = new EventEmitter<boolean>();
  constructor(
    private labService: LaboratoriesService,
    private consultService: ConsultationsService, 
    private prescriptionService: PrescriptionService,
    private medicalInsuranceService: MedicalInsuranceService,
  ) { }

  preview: string;
  uploading: boolean = false;
  files: File[];
  upload_progress: number[] = [0];
  


  async ngOnInit() {
  
    this.ekUpload();
    // let lab = await this.consultService.getLatestLab("THSmUV56a6eKLUdh590FuxmQn9X2");
    // let valid = this.labService.isAnalysisValid(325, lab);
    // console.log(valid)
    // console.log(await this.consultService.getLatestLab("THSmUV56a6eKLUdh590FuxmQn9X2"));
  }

  ngOnDestroy(): void {
    
  }
  
  // File Upload
  ekUpload(){
    function Init() {

      var fileSelect    = document.getElementById('file-upload'),
          fileDrag      = document.getElementById('file-drag'),
          submitButton  = document.getElementById('submit-button');

      fileSelect?.addEventListener('change', fileSelectHandler, false);

      // Is XHR2 available?
      var xhr = new XMLHttpRequest();
      if (xhr.upload) {
        // File Drop
        fileDrag.addEventListener('dragover', fileDragHover, false);
        fileDrag.addEventListener('dragleave', fileDragHover, false);
        fileDrag.addEventListener('drop', fileSelectHandler, false);
      }
    }

    function fileDragHover(e) {
      var fileDrag = document.getElementById('file-drag');

      e.stopPropagation();
      e.preventDefault();

      fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
    }

    function fileSelectHandler(e) {
      // Fetch FileList object
      var files = e.target.files || e.dataTransfer.files;

      // Cancel event and hover styling
      fileDragHover(e);

      // Process all File objects
      for (var i = 0, f; f = files[i]; i++) {
        // parseFile(f);
        uploadFile(f);
      }
    }

    // Output
    function output(msg) {
      // Response
      var m = document.getElementById('messages');
      m.innerHTML = msg;
    }

    // function parseFile(file) {

    //   console.log(file.name);
    //   output(
    //     '<strong>' + encodeURI(file.name) + '</strong>'
    //   );
      
    //   // var fileType = file.type;
    //   // console.log(fileType);
    //   var imageName = file.name;

    //   var isGood = (/\.(?=gif|jpg|png|jpeg)/gi).test(imageName);
    //   if (isGood) {
    //     document.getElementById('start').classList.add("hidden");
    //     document.getElementById('response').classList.remove("hidden");
    //     document.getElementById('notimage').classList.add("hidden");
    //     // Thumbnail Preview
    //     document.getElementById('file-image').classList.remove("hidden");
    //     const fileImage = document.getElementById('file-image') as HTMLImageElement;
    //     const file = fileImage.files?.[0];
    //     // const fileInput = document.getElementById('file-image').src = URL.createObjectURL(file);
    //   }
    //   else {
    //     document.getElementById('file-image').classList.add("hidden");
    //     document.getElementById('notimage').classList.remove("hidden");
    //     document.getElementById('start').classList.remove("hidden");
    //     document.getElementById('response').classList.add("hidden");
    //     <HTMLFormElement>document.getElementById("file-upload-form").reset();
    //   }
    // }

    function setProgressMaxValue(e) {
      var pBar = <HTMLProgressElement>document.getElementById('file-progress');

      if (e.lengthComputable) {
        pBar.max = e.total;
      }
    }

    function updateFileProgress(e) {
      var pBar = <HTMLProgressElement>document.getElementById('file-progress');

      if (e.lengthComputable) {
        pBar.value = e.loaded;
      }
    }

    function uploadFile(file) {

      var xhr = new XMLHttpRequest(),
        fileInput = document.getElementById('class-roster-file'),
        pBar = document.getElementById('file-progress'),
        fileSizeLimit = 1024; // In MB
      if (xhr.upload) {
        // Check if file is less than x MB
        if (file.size <= fileSizeLimit * 1024 * 1024) {
          // Progress bar
          pBar.style.display = 'inline';
          xhr.upload.addEventListener('loadstart', setProgressMaxValue, false);
          xhr.upload.addEventListener('progress', updateFileProgress, false);

          // File received / failed
          xhr.onreadystatechange = function(e) {
            if (xhr.readyState == 4) {
              // Everything is good!

              // progress.className = (xhr.status == 200 ? "success" : "failure");
              // document.location.reload(true);
            }
          };

          // Start upload
          // xhr.open('POST', document.getElementById('file-upload-form').action, true);
          // xhr.setRequestHeader('X-File-Name', file.name);
          // xhr.setRequestHeader('X-File-Size', file.size);
          // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
          // xhr.send(file);
        } else {
          output('Please upload a smaller file (< ' + fileSizeLimit + ' MB).');
        }
      }
    }

    // Check for the various File API support.
    if (window.File && window.FileList && window.FileReader) {
      Init();
    } else {
      document.getElementById('file-drag').style.display = 'none';
    }
  }
// ekUpload();


  async getFiles(event){
    
    const file = event.target.files[0];
    this.files = event.target.files;

    //! Change the uid to be dynamic
    // let url = await this.labService.uploadLabsStorage(this.user.uid, file);
    if(this.uploadMedicalInsurance){
      // this.upload_progress.push(0);
      let data: MedicalInsurance = NULL_MEDICAL_INSURANCE;
      let url = await this.medicalInsuranceService.saveToStorage(this.user.uid, data, file);// upload insurance to storage, and profile?
      return 
    }

    if(this.uploadPrescription){
      // this.upload_progress.push(0);
      let url = await this.prescriptionService.uploadPrescriptionStorage(this.user.uid, file, this.prescription);
      return this.close.emit(true);
    }
    this.files.forEach(async (file, index) => {  
      this.upload_progress.push(0);
      // let url = await this.labService.uploadLabsStorage("THSmUV56a6eKLUdh590FuxmQn9X2", file);
      let url = await this.labService.uploadLabsStorage(this.user.uid, file);
      // let url = await this.labService.uploadLabsStorage(this.user.uid, file);
      this.startUpload(index);
    });

    // this.convertFile(event.target.files[0]).subscribe(base64 => {
    //   this.preview = "data:application/pdf;base64," + base64;  
 

    // });
  }
  
  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }


  formatFileSize(size: number): string {
    if (size < 1024) {
      return size + ' bytes';
    } else if (size < 1048576) {
      return (size / 1024).toFixed(2) + ' KB';
    } else if (size < 1073741824) {
      return (size / 1048576).toFixed(2) + ' MB';
    } else {
      return (size / 1073741824).toFixed(2) + ' GB';
    }
  }


  startUpload(index): void {
    this.uploading = true;
    this.simulateUpload(index);
  }

  simulateUpload(index:number) {

    if(this.upload_progress[index] < 100) {
      setTimeout(() => {
        // Increment the progress by a small amount
        // let progress = Math.floor(Math.random() * 10); 
        // this.upload_progress[0] +=  Math.min(progress, 100);
        if((this.upload_progress[index] + Math.floor(Math.random() * 10)) > 90){
          this.upload_progress[index] = 100;
        }else{
          this.upload_progress[index] += Math.min(Math.floor(Math.random() * 10), 100);
        }
        // Call simulateUpload recursively
        this.simulateUpload(index);
      }, 150); // Adjust the timeout to change the speed of the upload simulation
    } else {
      this.uploading = false;
    }
  }
}

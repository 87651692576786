<div class="container mt-3" *ngIf="promosDocs">
    <h2 class="mb-4 animate">{{'PROMOTIONS.OPTIONS' | translate}}</h2>
    <form>
      <div class="row mt-4">
        <!-- Promotion Card -->
        <div class="col-md-6 animate" *ngFor="let doc of promosDocs">
          <!-- <div class="card mb-4" [ngClass]="{'border-primary': selectedPromotion === doc.productId}" (click)="selectPromotion(doc.productId)"> -->
            <div class="card mb-4 border-primary">
                <label for="{{doc.productId}}" >
                    <div class="card-body">
                        <h5 class="card-title" style="text-align: start;">
                            <input type="radio" id="{{doc.productId}}" (click)="saveService(doc)" name="promotion" [checked]="checkSelectedPromo(doc.productId)" class="custom-control-input">
                            <span class="check-icon" ></span>
                            <div class="row">
                                <div class="col-lg-12 d-flex flex-row p-0">
                                    <img class="pill-img m-0 mr-3" src="{{doc.productImg}}"/>
                                    <div class="row mt-4 mb-2" style="text-align: left; font-weight: bold !important; font-size: 19px;">
                                        <div class="col-lg-12 p-0">
                                            <p for="{{doc.productId}}">{{ doc.productName | translation: currentUserPreferredLang }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </h5>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item" style="border: none; padding: 0;"><img class="checkmark-img" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fpromos%2FDzeus_Sample_Promo_Checkmark.png?alt=media&token=ff6d31c8-a164-41b4-ac54-63d27a234553"/> <span class="included-txt">{{'PROMOTIONS.ITEM1' | translate}}</span></li>
                            <li class="list-group-item" style="border: none; padding: 0;"><img class="checkmark-img" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fpromos%2FDzeus_Sample_Promo_Checkmark.png?alt=media&token=ff6d31c8-a164-41b4-ac54-63d27a234553"/> <span class="included-txt">{{doc.tabletQty}} {{'PROMOTIONS.ITEM2' | translate}}</span>&nbsp;<span class="asterisk">*</span></li>
                            <li class="list-group-item" style="border: none; padding: 0;"><img class="checkmark-img" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fpromos%2FDzeus_Sample_Promo_Checkmark.png?alt=media&token=ff6d31c8-a164-41b4-ac54-63d27a234553"/> <span class="included-txt">{{'PROMOTIONS.ITEM3' | translate}}</span>&nbsp;<span class="asterisk">**</span></li>
                        </ul>
                        <div class="total-section mt-2">
                            <div class="total-section-item">
                                <span class="label bold">{{'PROMOTIONS.TOTAL' | translate}}</span>
                                <span class="amount bold">${{doc.productPromoPrice[0][currentUserLocation]}}</span>
                            </div>
                            <div class="total-section-item">
                                <span class="label-offer-value">{{'PROMOTIONS.VALUE' | translate}}</span>
                                <span class="amount-offer-value">{{doc.productValue[0][currentUserLocation]}}</span>
                            </div>
                        </div>
                        <!-- <div class="row mt-3 mb-2">
                            <div class="col-lg-12">
                                <button type="button" class="btn btn-info nextbtn text-center mb-3 mt-3" style="margin-right: auto; margin-left: auto; display: block;" (click)="saveService(doc)">{{'PROMOTIONS.SELECT' | translate}}</button>
                            </div>
                        </div> -->
                    </div>
                </label>
            </div>
        </div>

        <div class="animate">
            <div class="container-small-text mt-1">
                <div class="left-column" (click)="openDetailsModal(detailsModal)">
                    <span class="info-icon"><i class="fa fa-info-circle" style="font-size: 14px; vertical-align: text-top; color: #f37227;"></i></span>
                </div>
                <div class="right-column">
                    <p class="description-info">{{'PROMOTIONS.DISCLAIMER0' | translate}}</p>
                </div>
            </div>
            <div class="container-small-text mt-2">
                <div class="left-column">
                    <span class="asterisk-small">*<span style="visibility: hidden;">*</span></span>
                </div>
                <div class="right-column">
                    <p class="description">{{'PROMOTIONS.DISCLAIMER1' | translate}}</p>
                </div>
            </div>
            <div class="container-small-text mt-2">
                <div class="left-column">
                    <span class="asterisk-small">**</span>
                </div>
                <div class="right-column">
                    <p class="description">{{'PROMOTIONS.DISCLAIMER2' | translate}}</p>
                </div>
            </div>
            <div class="container-small-text mt-2">
                <div class="left-column">
                    <span class="info-icon"><i class="fa fa-info-circle" style="font-size: 12px; vertical-align: text-top;"></i></span>
                </div>
                <div class="right-column">
                    <p class="description">{{'PROMOTIONS.DISCLAIMER3' | translate}}</p>
                </div>
            </div>
        </div>
        <div class="row mt-3 mb-2 w-100 animate">
            <div class="col-lg-12">
                <!-- <button *ngIf="!loader" type="button" class="btn btn-info nextbtn text-center mb-3 mt-3" style="margin-right: auto; margin-left: auto; display: block;" (click)="saveService(doc)">{{'PROMOTIONS.CONTINUE' | translate}}</button>
                <button *ngIf="loader" type="button" class="btn btn-info nextbtn text-center mb-3 mt-3" style="margin-right: auto; margin-left: auto; display: block;"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> -->
                <button *ngIf="!loader" type="button" class="btn btn-info nextbtn text-center mb-3 mt-3" style="margin-right: auto; margin-left: auto; display: block;" (click)="continue.emit(true)">{{'PROMOTIONS.CONTINUE' | translate}}</button>
                <button *ngIf="loader" type="button" class="btn btn-info nextbtn text-center mb-3 mt-3" style="margin-right: auto; margin-left: auto; display: block;" [disabled]><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
            </div>
        </div>
      </div>
    </form>
  
    <!-- <div *ngIf="selectedPromotion" class="alert alert-info mt-3">
      <strong>Selected Promotion:</strong> {{ getSelectedPromotionText() }}
    </div> -->
</div>

<ng-template #detailsModal let-modal>
    <div class="modal-header" style="border: 0; padding-bottom: 0;">
      <!-- <h5 class="modal-title" id="exampleModalLongTitle">Details</h5> -->
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="padding-top: 0;">
        <!-- <h3>{{'PROMOTIONS.REASONTITLE' | translate}}</h3> -->
        <h4>{{'PROMOTIONS.REASON' | translate}}</h4>
        <div class="mt-4" style="font-size: 14px; line-height: 2;">
            <ol type="1">
                <li>{{'PROMOTIONS.REASON1' | translate}}</li>
                <li>{{'PROMOTIONS.REASON2' | translate}}</li>
                <li>{{'PROMOTIONS.REASON3' | translate}}</li>
                <li>{{'PROMOTIONS.REASON4' | translate}}</li>
                <li>{{'PROMOTIONS.REASON5' | translate}}</li>
                <li>{{'PROMOTIONS.REASON6' | translate}}</li>
                <li>{{'PROMOTIONS.REASON7' | translate}}</li>
                <li>{{'PROMOTIONS.REASON8' | translate}}</li>
                <li>{{'PROMOTIONS.REASON9' | translate}}</li>
                <li>{{'PROMOTIONS.REASON10' | translate}}</li>
                <li>{{'PROMOTIONS.REASON11' | translate}}</li>
              </ol> 
        </div>
        <div class="mt-4" style="font-size: 14px; line-height: 2;">
            <p>{{'PROMOTIONS.REASONTEXT' | translate}}</p>
        </div>
    </div>
    <div class="modal-footer" style="display: none;">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="modal.close('Close click')">{{'PROMOTIONS.CLOSE' | translate}}</button>
    </div>
</ng-template>
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { ConsultationsService } from '../consultations/consultations.service';
import * as _ from 'lodash';
import { TranslationPipe } from 'src/app/translation.pipe';
import { Product, ProductService } from 'src/app/core/services/product.service';

@Component({
  selector: 'app-choose-product-copy',
  templateUrl: './choose-product-copy.component.html',
  styleUrls: ['./choose-product-copy.component.scss']
})

export class ChooseProductCopyComponent implements OnInit, OnChanges {
  @Input() currentConsult;
  @Input() activatePromo;
  @Input() currentPreviousUrlPath;
  @Input() consultRules;
  @Input() currentUserAuth;
  @Input() preconsult = false
  @Output() nextAction = new EventEmitter<boolean>(false)
  @Output() backAction = new EventEmitter<boolean>(false)

  currentLang:any = this.translate.store.currentLang
  dummyCheckout = false;
 
  translation = new TranslationPipe();
  view = 'product';
  promoSelection = []
  promoServices = []

  productTemplate = [
    'productName',
    'productDosage',
    'productQty',
    'productRefillIntervalDays'
  ];

  constructor(
    public translate: TranslateService, 
    private afs: AngularFirestore,
    private consultationsService: ConsultationsService,
    private productService: ProductService
    ){
      if(this.activatePromo){
        this.view = 'promo';
      }
    }
    
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.activatePromo && changes.activatePromo.currentValue == true){
      this.view = 'promo';
    }
  }

  ngOnInit(){
    this.productBinder(this.currentConsult);

    this.productService.getPromos(this.currentConsult).then(res => {
      this.promoServices = res;
    })

    this.afs.collection('consultation').doc(this.currentConsult.ID).collection('Items').valueChanges().subscribe(res => {
      this.promoSelection = res;
    })
  }

  atributeCounter = 1
  finalProduct: any = [[{productName: ''}]] 
  products: Product[] = []
  filteredProducts = []
  productFilter = [[]]
  conditions: any = [];
  conditionCounter = 0;
  searchGroup:any = [{label: ''}];
  async filterAtributes(event, position){
    let index = this.productTemplate.indexOf(position)
    let atrName = position
    let nextAtrName = this.productTemplate[index + 1]
    let arr = []

    let allProducts = this.productsByCondition
    
    let currentProducts = []
    for(let j = 0; j < await this.finalProduct[this.conditionCounter].length - 1; j++){
      let productIndex = this.productTemplate[j]
      if(this.finalProduct[this.conditionCounter][j][productIndex] != ''){
        for(let i = 0; i < allProducts.length; i++) {
          if(await allProducts[i][productIndex] == await this.finalProduct[this.conditionCounter][j][productIndex]){
            currentProducts.push(await allProducts[i])
          }
          if(i == allProducts.length - 1){
            allProducts = currentProducts
            currentProducts = []
            if(productIndex == atrName){
              this.filteredProducts = allProducts
            }
          }
        }
      }
      else {
        break;
      }
    }
    
    if(index != this.atributeCounter - 1){
      this.atributeCounter = index + 1
      this.finalProduct[this.conditionCounter].splice(index + 1)
      this.productFilter[this.conditionCounter].splice(index + 1)
    }
    this.finalProduct[this.conditionCounter][index] = {[atrName]:event}

    if(index == 0 && this.filteredProducts.length > 0){
      this.finalProduct[this.conditionCounter][index].productBrandName = this.filteredProducts[0].productBrandName
      this.finalProduct[this.conditionCounter][index].productBrandNameTranslation = this.filteredProducts[0].productBrandNameTranslation
      this.finalProduct[this.conditionCounter][index].productInfo = this.filteredProducts[0].productInfo
      this.finalProduct[this.conditionCounter][index].productImg = this.filteredProducts[0].productImg
      this.finalProduct[this.conditionCounter][index].odooProductId = this.filteredProducts[0].odooProductId
    }

    if(nextAtrName != undefined){
      this.finalProduct[this.conditionCounter][index + 1] = {[nextAtrName]: ''}
    }

    if(!this.productFilter[this.conditionCounter]){
      this.productFilter[this.conditionCounter] = []
    }

    this.productFilter[this.conditionCounter][index + 1] = []
    this.filteredProducts.forEach(prod => {
      if(prod[atrName] == event){  
        arr.push(prod);
        var copy = arr.slice(0);
        copy.sort((a, b) => {
          return a.sequence - b.sequence;
        });    

        this.productFilter[this.conditionCounter][index + 1] = [...new Map(copy.map(item => [item[nextAtrName], item])).values()];
      }
    });

    if(((nextAtrName == undefined) && this.finalProduct[this.conditionCounter].length == this.productTemplate.length)){
      this.buildProductTest()
    }
    else {
      this.atributeCounter++

      let allProducts = this.productsByCondition
      let currentProducts = []

      for(let j = 0; j < this.finalProduct[this.conditionCounter].length - 1; j++){
        for(let i = 0; i < allProducts.length; i++) {
          let productIndex = this.productTemplate[j]
          if(allProducts[i][productIndex] == this.finalProduct[this.conditionCounter][j][productIndex]){
            currentProducts.push(allProducts[i])
          }
          if(i == allProducts.length - 1){
            allProducts = currentProducts
            this.filteredProducts = currentProducts
            currentProducts = []
          }
        }
      }
    }

    //new
    if(nextAtrName == 'productRefillIntervalDays' && this.productFilter[this.conditionCounter][3][0]){
      this.filterAtributes(this.productFilter[this.conditionCounter][3][0].productRefillIntervalDays, 'productRefillIntervalDays')
    }

    if(this.productFilter[this.conditionCounter][index + 1][0] && this.productFilter[this.conditionCounter][index + 1][0][this.productTemplate[index + 1]] == ''){
      this.filterAtributes(this.productFilter[this.conditionCounter][index + 1][0][this.productTemplate[index + 1]], this.productTemplate[index + 1])
    }
    //end new
  }

  filterAtributesBack(){
    if(this.finalProduct[this.conditionCounter].length > 0){
      let productIndex = this.productTemplate[this.atributeCounter - 2]
      this.atributeCounter = this.finalProduct[this.conditionCounter][this.atributeCounter - 2][productIndex] == '' ? this.atributeCounter - 1 : this.atributeCounter

      let splicer = this.finalProduct[this.conditionCounter][this.atributeCounter - 2][productIndex] == '' ? this.atributeCounter : this.atributeCounter - 1
      this.productFilter[this.conditionCounter].splice(splicer)
      this.finalProduct[this.conditionCounter].splice(this.atributeCounter - 2)
      this.finalProduct[this.conditionCounter][this.atributeCounter - 2] = {[productIndex]: ''}

      let allProducts = this.productsByCondition
      let currentProducts = []
        
      for(let j = 0; j < this.finalProduct[this.conditionCounter].length - 1; j++){
        for(let i = 0; i < allProducts.length; i++) {
          let productIndex = this.productTemplate[j]
          if(allProducts[i][productIndex] == this.finalProduct[this.conditionCounter][j][productIndex] && j <= this.atributeCounter){
            currentProducts.push(allProducts[i])
          }
          if(i == allProducts.length - 1 && j <= this.atributeCounter){
            allProducts = currentProducts
            this.filteredProducts = currentProducts
            currentProducts = []
          }
        }
      }
    }

    if(this.productFilter[this.conditionCounter][this.atributeCounter - 2][0] && this.productFilter[this.conditionCounter][this.atributeCounter - 2][0][this.productTemplate[this.atributeCounter - 2]] == ''){
      this.filterAtributesBack();
    }

    if(this.atributeCounter == 2){
      this.filteredProducts = this.productsByCondition
    }
  }

  async buildProductTest(){
    let matchedProduct = {}
    for(let i = 0; i < this.productTemplate.length; i++){
      matchedProduct[this.productTemplate[i]] = this.finalProduct[this.conditionCounter][i] && this.finalProduct[this.conditionCounter][i][this.productTemplate[i]] ? this.finalProduct[this.conditionCounter][i][this.productTemplate[i]] : ''
    }
    for(let p of this.products){
      const{ productId, productPrice, productRefillQty, productRxInstruction, productMinPoint, productMaxPoint, productImg, productRxcui, productOTC, productIsVisible, productRefillMinQty,
      productName, productDosage, productRefillIntervalDays, productQty, productBrandName } = p;
      if(_.isEqual(matchedProduct, {productDosage, productName, productQty, productRefillIntervalDays})){
        // let frequencies = await this.productService.getProductDeliveryWishlistFrequency([p])
        let frequencies = p.productDeliveryFrequencies
        let defaultFrequency = frequencies.filter(f => f.defaultFrequency == true)[0]
        let data = {
          recurrency_order: true,
          deliveryFrequencyInDays: defaultFrequency?.frequency || 30, //new subscription
          automaticPayment: defaultFrequency?.autoPayment || false //new subscription
        }


        matchedProduct['recurrency_order'] = data.recurrency_order;
        matchedProduct['deliveryFrequencyInDays'] = data.deliveryFrequencyInDays;
        matchedProduct['automaticPayment'] = data.automaticPayment;
        matchedProduct['id'] = productId;
        matchedProduct['productId'] = productId;
        matchedProduct['condition'] = p.productCondition;
        // matchedProduct['sequence'] = sequence;
        // matchedProduct['productGroup'] = productGroup;
        matchedProduct['type'] = p.productType;
        matchedProduct['fbProductId'] = productId
        matchedProduct['odooProductId'] = p.odooProductId;
        matchedProduct['name'] = productName + (productBrandName != productName && productBrandName != '' ? ' (' + productBrandName + ')' : '');
        matchedProduct['refillIntervalDays'] = productRefillIntervalDays;
        matchedProduct['refillQty'] = productRefillQty;
        matchedProduct['treatment'] = !productOTC;
        matchedProduct['rxInstruction'] = productRxInstruction;
        matchedProduct['minPoint'] = productMinPoint;
        matchedProduct['maxPoint'] = productMaxPoint;
        matchedProduct['price'] = productPrice;
        matchedProduct['imgRef'] = productImg;
        // matchedProduct['recommended'] = recommended;
        matchedProduct['rxcui'] = productRxcui;
        // matchedProduct['isPack'] = isPack;
        matchedProduct['hidePractitioner'] = !productIsVisible;
        matchedProduct['qty'] = productQty || 0;
        matchedProduct['minRefillQty'] = productRefillMinQty || 0;
        matchedProduct['groupSelection'] = []
        matchedProduct['deliveryFrequencies'] = frequencies
        matchedProduct['dosageForm'] = p.productDosageForm
        
        let filteredProducts = this.productFilter.filter(product => (product[3] && product[3][0]?.productId == productId) || 
        (product[0] && product[0][product[0].length - 1].productId == productId)) //no selection product
        
        if(filteredProducts.length > 0){
          for(let i = 0; i < this.productFilter.length; i++){
            if((this.productFilter[i][3] && this.productFilter[i][3][0]?.productId == productId) || 
            (this.productFilter[i][0] && this.productFilter[i][0][this.productFilter[i][0].length - 1].productId == productId) //no selection product
            ){
              matchedProduct['groupSelection'].push(this.conditions[i].condition)
            }
          }
        }
        else {
          matchedProduct['groupSelection'] = [this.conditions[this.conditionCounter].condition]
        }
        
        let productMatch = this.products.filter(product => product.productId == productId)
        if(this.products.some(product => product.productId === productId)){
          this.finalProduct[this.conditionCounter] = [] 
          for(let i = 0; i < this.productTemplate.length; i++){
            this.finalProduct[this.conditionCounter].push({[this.productTemplate[i]]: productMatch[0][this.productTemplate[i]]});
            if(i == 0){
              this.finalProduct[this.conditionCounter][i].productBrandName = productMatch[0].productBrandName;
              this.finalProduct[this.conditionCounter][i].productBrandNameTranslation = productMatch[0].productBrandNameTranslation
              this.finalProduct[this.conditionCounter][i].productInfo = productMatch[0].productInfo
              this.finalProduct[this.conditionCounter][i].productImg = productMatch[0].productImg
              this.finalProduct[this.conditionCounter][i].odooProductId = productMatch[0].odooProductId
            }
          }
        }

        if(this.conditions[this.conditionCounter].canChooseMultipleProducts == false){
          let consultationRef = this.afs.firestore.collection("consultation").doc(this.currentConsult.ID).collection("wishlist");
          consultationRef.get().then(async (doc) => {
            await doc.forEach((doc) => {
              if(doc.data().type == "product"){
                if(doc.data().condition.includes(this.conditions[this.conditionCounter].condition) && ((doc.data().groupSelection && doc.data().groupSelection.includes(this.conditions[this.conditionCounter].condition)) || (!doc.data().groupSelection && doc.data().productGroup == 0))){
                  if(!doc.data().groupSelection || doc.data().groupSelection.length == 1){
                    this.afs.collection("consultation").doc(this.currentConsult.ID).collection("wishlist").doc(doc.data().productId.toString()).delete();
                    if(this.promoSelection && doc.data().productId.toString() != productId.toString()){
                      this.afs.collection("consultation").doc(this.currentConsult.ID).collection("Items").doc('service').delete();
                    }
                  }
                  else {
                    let groupSelection = doc.data().groupSelection.filter(el => el != this.conditions[this.conditionCounter].condition)
                    this.afs.collection("consultation").doc(this.currentConsult.ID).collection("wishlist").doc(doc.data().productId.toString()).update({groupSelection: groupSelection});
                  }
                }
              }
            })
            this.afs.collection('consultation').doc(this.currentConsult.ID).collection("wishlist").doc(productId.toString()).set(matchedProduct, {merge: true});
          })
        } else{
          this.afs.collection('consultation').doc(this.currentConsult.ID).collection("wishlist").doc(productId.toString()).set(matchedProduct, {merge: true});
        }
        // this.handleNextBtn();
        break;
      }
    }
  }

  setProductTest(position){
    let index = this.productTemplate.indexOf(position)
    let atrName = position
    let nextAtrName = this.productTemplate[index + 1]

    let arr = []
    
    if(index == 0){
      this.finalProduct[this.conditionCounter][index].productBrandName = this.filteredProducts[0].productBrandName
      this.finalProduct[this.conditionCounter][index].productBrandNameTranslation = this.filteredProducts[0].productBrandNameTranslation
      this.finalProduct[this.conditionCounter][index].productInfo = this.filteredProducts[0].productInfo
      // this.finalProduct[this.conditionCounter][index].productImg = this.filteredProducts[0].productImg
      // this.finalProduct[this.conditionCounter][index].odooProductId = this.filteredProducts[0].odooProductId
    }

    if(index != this.atributeCounter - 1){
      this.atributeCounter = index + 1
    }

    if(!this.productFilter[this.conditionCounter]){
      this.productFilter[this.conditionCounter] = []
    }

    this.productFilter[this.conditionCounter][index + 1] = []
    if(this.filteredProducts.length != 0){
      this.filteredProducts.forEach(prod => {
        if(prod[atrName] == this.finalProduct[this.conditionCounter][index][atrName]){  
          arr.push(prod);
          var copy = arr.slice(0);
          copy.sort((a, b) => {
            return a.sequence - b.sequence;
          });    
          
          this.productFilter[this.conditionCounter][index + 1] = [...new Map(copy.map(item => [item[nextAtrName], item])).values()];       
        }
      });
      
      if(nextAtrName != undefined || this.productFilter[this.conditionCounter].length < 4){
        let allProducts = this.productsByCondition
        let currentProducts = []
        
        for(let j = 0; j < this.finalProduct[this.conditionCounter].length - 1; j++){
          for(let i = 0; i < allProducts.length; i++) {
            let productIndex = this.productTemplate[j]
            if(allProducts[i][productIndex] == this.finalProduct[this.conditionCounter][j][productIndex] && j <= position){
              currentProducts.push(allProducts[i])
            }
            if(i == allProducts.length - 1 && j <= position){
              allProducts = currentProducts
              this.filteredProducts = currentProducts
              currentProducts = []
            }
          }
        }
        let nextAtrName = this.productTemplate[index + 1]
        this.setProductTest(nextAtrName);
      }
      if(position == 3){
        this.productBillingTerm();
      }
    }
  }

  handleProductRequiredTest(){
    let index = this.conditionCounter;

     if(this.conditions[index]?.required == true && this.productFilter[this.conditionCounter]){
      if(this.finalProduct[this.conditionCounter].length == this.productTemplate.length || (this.finalProduct[this.conditionCounter].length == this.productFilter[this.conditionCounter].length && 
        this.productFilter[this.conditionCounter][this.productFilter[this.conditionCounter].length - 1].length == 0)){ //Nothing else to filter 
        return false;
      }
      return true;
    }
    else{
      return false;
    }
  }

  handleProductGroups($event){

    this.conditionCounter = $event.target.id == "next" ? this.conditionCounter + 1 : this.conditionCounter - 1;
    this.atributeCounter = $event.target.id == "next" ? 1 : this.productFilter[this.conditionCounter].length - 1;
    this.productsByCondition = this.products.filter(product => product.productCondition.includes(this.consultRules.conditions[this.conditionCounter].condition) && product.productStates.some(p => p.state_code == this.currentConsult.state.code )).sort((a, b) => {
      let nameA = a.productName + a.productDosage + a.productQty
      let nameB = b.productName + b.productDosage + b.productQty
      return nameA.localeCompare(nameB, undefined, {numeric: true, sensitivity: 'base'})
    }).sort((a, b) => {
      if(b.productPrice[0][this.currentConsult.state.code] == "0.00" ){
        return -1
      }
      return 0
    })

    this.filteredProducts = this.productsByCondition

    if($event.target.id == "next"){
      this.productFilter[this.conditionCounter] = []
      this.productFilter[this.conditionCounter][0] = [...new Map(this.filteredProducts.map(item => [item.productName, item])).values()]
      
      if(!this.finalProduct[this.conditionCounter]){
        this.finalProduct[this.conditionCounter] = [{productName: ''}]
      }
      else {    
        this.setProductTest(this.productTemplate[0])
      }
    }

    this.consultationsService.getProductsSearchGroup(this.currentConsult, this.conditionCounter).then(res => {
      this.searchGroup = res;
    });
  }

  getCounterArray(): number[] {
    return Array(this.conditionCounter + 1).fill(0).map((x, i) => i);
  }

  sumTempTotal(){
    let total = 0;

    for(let i = 0; i <= this.conditionCounter; i++){
      total += Number(this.productFilter[i][3][0].productPrice[0][this.currentConsult.state.code])
    }

    return total;
  }

  lowestStartingPrice = []
  productsByCondition: Product[] = []
  async productBinder(consult){
    this.conditions = this.consultRules.conditions;
    await this.productService.getProducts(consult, this.consultRules).then(async (productList: Product[]) => {
      this.products = productList.filter(product => (product.productIsVisible === true && product.productStates.some(p => p.state_code == this.currentConsult.state.code ))).sort((a, b) => {
        let nameA = a.productName + a.productDosage + a.productQty
        let nameB = b.productName + b.productDosage + b.productQty
        return nameA.localeCompare(nameB, undefined, {numeric: true, sensitivity: 'base'})
      }).sort((a, b) => {  
        if(b.productPrice[0][this.currentConsult.state.code] == "0.00" ){
          return -1
        }
        return 0
      })
      
      this.productsByCondition = this.products
    
    this.filteredProducts = this.products

    // this.lowestStartingPrice = this.products
    
    // this.lowestStartingPrice.forEach(product => { 
    //   product.pillPrice = product.productPrice[0][this.currentConsult.state.code] / product.productQty
    //   return product
    // })

    // this.lowestStartingPrice.sort((a, b) => {
    //   return a.pillPrice - b.pillPrice
    // })

    let packFromOdoo = localStorage.getItem('package');
      if(this.currentPreviousUrlPath == "/prescription-list" && packFromOdoo){
        let pack = this.products.filter(t => t.productId == Number(packFromOdoo));
        this.afs.collection("consultation").doc(this.currentConsult.ID).collection("wishlist").doc(pack[0].productId.toString()).set(pack[0], {merge: true}).then(() => {
          localStorage.removeItem('package');
        });
      }

    this.productFilter[this.conditionCounter] = []
    
    this.productFilter[this.conditionCounter][0] = [...new Map(this.filteredProducts.map(item => [item.productName, item])).values()]

    this.afs.firestore.collection('consultation').doc(this.currentConsult.ID).collection('wishlist').get().then(async items => {
      let selectedItems = items.docs.map(i => i.data());

      let packFromOdoo = localStorage.getItem('package');
      if(packFromOdoo){
        let pack = this.products.filter(t => t.productId == Number(packFromOdoo))
        if(pack.length > 0){
          Object.keys(pack[0]).sort().forEach((key: any) => {
            if(key == 'productName'){
              this.finalProduct[this.conditionCounter].push({[key]: pack[0][key]});
              this.productFilter[this.conditionCounter] = [];
              this.productFilter[this.conditionCounter][0] = [...new Map(this.products.map(item => [item.productName, item])).values()]
              this.filterAtributes(pack[0].productName, 0)
            }
          })
        }
        localStorage.removeItem('package');
      }


      for(let i = 0; i < this.conditions.length; i++){
        let filtered = selectedItems.filter(item => (item.groupSelection && item.groupSelection.includes(this.conditions[i].condition)) || (item.productCondition && item.productCondition.includes(this.conditions[i].condition)));
        if(filtered.length > 0){
          if(this.products.some(product => product.productId === filtered[0].productId)){
            this.finalProduct[i] = [] 
            
            for(let j = 0; j < this.productTemplate.length; j++){
              await this.finalProduct[i].push({[this.productTemplate[j]]: filtered[0][this.productTemplate[j]]});
              if(j == 0){
                // this.finalProduct[i][j].productBrandName = filtered[0].productBrandName;
                // this.finalProduct[i][j].productBrandNameTranslation = filtered[0].productBrandNameTranslation
                // this.finalProduct[i][j].productInfo = filtered[0].productInfo
                this.finalProduct[i][j].productImg = filtered[0].imgRef
                this.finalProduct[i][j].odooProductId = filtered[0].odooProductId
          
              }
              if(i == 0 && j == this.productTemplate.length - 1){
                this.setProductTest(this.productTemplate[0])
                this.upgradeBillingTerm = filtered[0].refillIntervalDays;
              }
            }
          }
        }
      }
    });
  });
  this.consultationsService.getProductsSearchGroup(consult, this.conditionCounter).then(res => {
    this.searchGroup = res;
  });
  }

  upgradeBillingTerm:number = 30;
  productBillingTerm(){
    document.body.scrollTop = this.preconsult ? 80 : 0; // For Safari
    document.documentElement.scrollTop = this.preconsult ? 80 : 0; // For Chrome, Firefox, IE, and Opera

    this.upgradeBillingTerm = this.upgradeBillingTerm == 30 ? 90 : 30;

    let typeofPlan = this.products.filter(p => p.productName == this.finalProduct[this.conditionCounter][0].productName && p.productDosage == this.finalProduct[this.conditionCounter][1].productDosage && p.productQty == this.finalProduct[this.conditionCounter][2].productQty
    )

    if(!this.productFilter[this.conditionCounter]){
      this.productFilter[this.conditionCounter] = []
    }

    this.productFilter[this.conditionCounter][3] = [...new Map(typeofPlan.map(item => [item['atr3'], item])).values()];
  }
  handleNextBtn(){
    if(this.finalProduct[this.conditionCounter] && this.finalProduct[this.conditionCounter].length <= 1){
      //Do nothing. This is to prevent the user from moving to the next group without selecting a attribute
    } else
    if(this.conditionCounter != this.conditions.length -1 && this.finalProduct[this.conditionCounter]){
      this.handleProductGroups({target: {id: 'next'}});
    }
    else {
      let productFilter = this.finalProduct[this.conditionCounter].filter(product => {
        if(product.odooProductId && product.odooProductId != 980){
          return true
        }
      })
  
      if(productFilter.length == 0){
        if(this.promoServices.length > 0){
          this.view = 'promo';
        }
        else {
          this.nextAction.emit(true)
        }
      }
      else {
        this.dummyCheckout = true;
        this.productBillingTerm()
      }
    }
  }

  handleBackBtn(){
    if(this.view == 'product'){
      if(this.conditionCounter == 0 && !this.finalProduct[this.conditionCounter][0]['productName']){
        this.backAction.emit(true);
      }
      else if(this.conditionCounter > 0 && !this.finalProduct[this.conditionCounter][0]['productName']){
        this.handleProductGroups({target: {id: 'back'}});
      }
      else if(this.finalProduct[this.conditionCounter][0]['productName'] && !this.dummyCheckout){
        this.filterAtributesBack();
      }
      else {
        this.dummyCheckout = false;
      }
    }
    else {
      let productFilter = this.finalProduct[this.conditionCounter].filter(product => {
        if(product.odooProductId && product.odooProductId == 980){
          return true
        }
      })

      if(productFilter.length == 0){
        this.backAction.emit(true);
      }
      else {
        this.view = 'product';
        this.dummyCheckout = false;
      }
    }
  }

  recommendedDosage(){
    return this.products.filter(p => p.productName == this.finalProduct[this.conditionCounter][0].productName && p.productIsStandardDosage)[0]?.productDosage || ''
  }

  productInformation(){
    return this.products.filter(p => p.productName == this.finalProduct[this.conditionCounter][0].productName)[0]?.productInfo || ''
  }

  dosageForm(form){
    if(form == 'Tablet'){
      return 'CONSULTATIONS.TABLETS'
    }

    if(form == 'Capsule'){
      return 'CONSULTATIONS.CAPSULES'
    }

    if(form == 'Wipes'){
      return 'CONSULTATIONS.WIPES'
    }

    return ''
  }
}
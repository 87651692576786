import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-survey-handler',
  templateUrl: './survey-handler.component.html',
  styleUrls: ['./survey-handler.component.scss']
})
export class SurveyHandlerComponent implements OnInit {

  survey;

  modalRef:any;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private afs: AngularFirestore,
    public translate: TranslateService,
    public languageService: LanguageService,
  ) { 
  }

  ngOnInit() {
    let surveyRef = this.router.url.split('?sn=')[1].split('&')[0];
    
    this.afs.firestore.collection('surveys').doc(surveyRef).get().then((survey) => {
      this.survey = survey.data();
      this.languageService.setLanguage(this.survey.surveyLanguage);
      
      this.afs.firestore.collection('conditionsQuestions').doc(this.survey.surveyQuestionsTemplate).collection('Questions').get().then((questions) => {
        this.survey.surveyQuestions = questions.docs.length
      })
    });
  }


  openSurvey(content) {
    this.modalRef = this.modalService.open(content, { centered: true });
  }
}

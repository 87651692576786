import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import firebase from "firebase/compat/app";
import { LanguageService } from 'src/app/core/services/language.service';
import { Profile } from 'src/app/core/services/interfaces';
import { NPIRegistryService } from 'src/app/core/services/npi-registry.service';
import { Router } from '@angular/router';
import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationPipe } from 'src/app/translation.pipe';
import { fbRemoteConfig } from '../../../authUtils' 
import { WalletService } from 'src/app/core/services/wallet/wallet.service';
import { EmailsService } from 'src/app/core/services/emails.service';
import { ProfileDataService } from '../../profile/data.service';
import { DbCollectionTriggersService } from 'src/app/core/services/db-collection-triggers.service';

@Component({
  selector: 'app-walkthroughmodal',
  templateUrl: './walkthroughmodal.component.html',
  styleUrls: ['./walkthroughmodal.component.scss']
})
export class WalkthroughmodalComponent implements OnInit, OnDestroy {
  @ViewChild('shipping',{static:true, read: ElementRef}) shippingAddress : ElementRef ;
  @ViewChild('NPIRegistry', { read: TemplateRef }) NPIRegistryModal:TemplateRef<any>;
  @ViewChild('addCard', { read: TemplateRef }) addCard:TemplateRef<any>;
  @ViewChild('imageCropper', { read: TemplateRef }) imageCropper:TemplateRef<any>;
  @ViewChild('termsModal') termsModal: any;
  @ViewChild("video") public video: ElementRef;
  @ViewChild("canvas") public canvas: ElementRef;
  @ViewChild("canvasID") public canvasID: ElementRef;
  @ViewChild('authUsersModal') authUsersModal: any;


  public captures: String;

  windowRef: any;

  profile: any;
  phoneInProfile;
  phoneMsgInProfile;
  abbr: string = '';
  preferedLang = ["English", "Español"]

  image:any;
  defaultProfileImg = '../assets/images/users/profile.png'
  defaultIDImg = '../assets/images/users/IDFront.svg'
  defaultIDImgBack = '../assets/images/users/IDBack.svg'
  emailExists: Boolean = false

  timezoneList: any = []

  // Form submition
  submit: boolean;
  subscription: Subscription;
  allSubscriptions = new Subscription()

  //loader
  public loading = false;

  allPaymentMethods: any;

  phoneNumberMsg = false;
  alternateNumberMsg = false;

  //new profile
  editProfile = false
  editProfileForm: UntypedFormGroup = this.formBuilder.group({
    email: new UntypedFormControl(''),
    gender: new UntypedFormControl(''),
    phoneNumber: new UntypedFormControl(''),
    phoneNumberMsg: new UntypedFormControl(this.phoneNumberMsg),
    timezone: this.formBuilder.group({name: '', nameValue: '', timeValue: ''}),
    preferedLang: new UntypedFormControl(''),
    alternateNumberMsg: new UntypedFormControl(this.alternateNumberMsg),
    alternateNumber: new UntypedFormControl(''),
    firstName: new UntypedFormControl(''),
    lastName1: new UntypedFormControl(''),
    biologicalgender: new UntypedFormControl(''),
    middleName: new UntypedFormControl(''),
    birthdate: new UntypedFormControl(''),
    BMI: this.formBuilder.group({BMI: '', feet: '', inches: '', pounds: ''}),
    abbr: new UntypedFormControl('')
  }); // bootstrap validation form
  editSchedule = false

  daySelected = new Date().getDate().toString().padStart(2, '0');
  monthSelected = new Intl.DateTimeFormat('es', { month: 'long' }).format(new Date());
  yearSelected = new Date().getFullYear() - 21;
  numberOfDays = this.daysRemainingInMonth();
  monthNumber = (new Date(this.monthSelected + ' 1, 2023').getMonth() + 1).toString().padStart(2, '0');

  editId = false;
  editIdForm: UntypedFormGroup = this.formBuilder.group({
    // idNumber: new UntypedFormControl(''),
    // idExpDate: new UntypedFormControl(''),
    // // idType: new UntypedFormControl(''),
    // idStateIssued: new UntypedFormControl(''),
    // idCountryIssued: new UntypedFormControl(''),
    status: new UntypedFormControl('unverified')
  });
  currentEmail = '';
  emailConfirm = '';
  currentLang = this.translate.store.currentLang;
  states = [];
  pagetitle = "PROFILE.title";

  title
  text
  cancel
  confirmBtn
  remove 
  removeMsg 
  swal
  modalRef: any;
  edit: Boolean;
  phoneNumber: string;
  linkPhoneNumber: boolean = false;

  myPdfs = []
  pdfsLabel = ['<en>Medicine Licence</en><es>Licencia de Medicina</es>', '<en>Telemedicine Certificate</en><es>Certificado de telemedicina</es>', '<en>Endoursement</en><es>Endoso</es>']
  search:boolean = false;
  NPI:string;
  BMI = [{feet: '', inches: '', pounds: '', BMI: ''}]
  countries;
  currentURL

  idExpired = false;
  hide = false

  //image-cropper
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = true;
  containWithinAspectRatio = false;
  transform: any = {};
  ratio = 1

  translationPipe = new TranslationPipe()
  sendEmail = true
  allowDismiss = false;

  claims;

  pdfToSave: any = '';
  expDate: any = '';

  constructor(
    public firebaseService: FirebaseService,
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    public languageService: LanguageService, 
    private NPIRegistryService: NPIRegistryService,
    public route: Router,
    private sanitaizer: DomSanitizer,
    private walletService: WalletService,
    private emailService: EmailsService,
    private dataService: ProfileDataService,
    private updateUserTrigger: DbCollectionTriggersService
  ) {
    const moment = require('moment-timezone');  
    moment.tz.names().forEach(t => {
      let timeValue = String((moment.tz(t).utcOffset())/60).includes('-') ? '-' + String((moment.tz(t).utcOffset())/60).replace('-', '').padStart(2, '0') : String((moment.tz(t).utcOffset())/60).padStart(2, '0')
      this.timezoneList.push({nameValue: t, timeValue: timeValue + ':00', name: t + ' (' + timeValue + ':00)'})
    })

    this.hide = (screen.width < 1200 || (window.matchMedia("(orientation: landscape-primary)") && screen.height < 800 && screen.height > 768)) ? true : false;

    this.currentURL = this.route.url

    this.authenticationService.profile().then(async (profile: Profile) => {
      this.editProfileForm.patchValue(await profile)
      this.daySelected = profile.birthdate ? new Date(profile.birthdate + 'T00:00:00Z').getUTCDate().toString().padStart(2, '0') : new Date().getDate().toString().padStart(2, '0');
      this.monthSelected = profile.birthdate ?  new Intl.DateTimeFormat(profile.preferedLang, { month: 'long' }).format(new Date(profile.birthdate + 'T00:00:00Z')) : new Intl.DateTimeFormat('es', { month: 'long' }).format(new Date());
      this.yearSelected = profile.birthdate ? new Date(profile.birthdate + 'T00:00:00Z').getFullYear() : new Date().getFullYear() - 21;
      this.numberOfDays = this.daysRemainingInMonth();
      this.monthNumber = (new Date(this.monthSelected + ' 1, 2023').getMonth() + 1).toString().padStart(2, '0');
      this.editIdForm.patchValue(await profile)
      this.profile = await profile;
      this.phoneInProfile = profile.phoneNumber;
      this.phoneMsgInProfile = profile.phoneNumberMsg;
      this.editProfileForm.controls.timezone.patchValue(await profile.timezone)
      if(profile.accountType == 'patient'){
        this.getAcceptaCards()
      }

      if(profile.accountType == 'practitioner' && profile.abbr){        
        let translationPipe = new TranslationPipe()
        let abbr = translationPipe.transform(profile.abbr, profile.preferedLang);
        if(profile.preferedLang == 'en'){
          this.abbr = abbr;
        }
        else {
          if(profile.biologicalgender == 'Female'){
            this.abbr = abbr.split('/la ')[1]
          }
          else {
            this.abbr = abbr.split('el ')[1].split('/')[0]
          }
        }

        afs.collection('Documents', ref => ref.where('referenceID', '==', profile.uid)).valueChanges().subscribe(async (docs: any) => {
          this.myPdfs = []
          docs.sort((a,b) => {
            return b.expDate.localeCompare(a.expDate)
          })
          for(let i = 0; i < this.pdfsLabel.length; i++){
            this.myPdfs[i]= docs.filter(doc => doc.description == this.pdfsLabel[i]) || []
          }
        })
      }

      // this.verifyExpDate(await profile.idExpDate)
      if(this.profile.idCountryIssued != undefined && this.profile.idCountryIssued != ''){
        this.getStates({country: this.profile.idCountryIssued})
      }
      this.currentEmail = profile.email;
      this.currentLang = profile.preferedLang;

      //Avoids blank values when you update other information
      this.editProfileForm.patchValue({
        firstName: profile.firstName,
        middleName: profile.middleName,
        lastName1: profile.lastName1,
        biologicalgender: profile.biologicalgender,
        birthdate: profile.birthdate,
        abbr: profile.abbr ? profile.abbr: ''
      });
      this.editProfileForm.controls.timezone.patchValue(this.profile.timezone)
    })
  } 
  ngOnDestroy(): void {    
    this.allSubscriptions.unsubscribe()
  }

  async ngOnInit(): Promise<void> {
    this.claims = await this.authenticationService.getClaims();
    // this.searchRegistry("1043548613")
    // let sub2 = this.afs.collection<any>('users').doc(profile.uid).collection('authUsers').doc('1').valueChanges().subscribe(async user1 => {
    //   if(user1 == undefined && this.profile.accountType == 'patient'){
    //     this.modalService.open(this.authUsersModal, {size: 'lg', backdrop: 'static', centered: true, keyboard: false})
    //     this.allowDismiss = false
    //   }else {
      if(!this.claims.devAdmin){
        this.getTerms()
      }
    //   }
    // })
    // this.allSubscriptions.add(sub2)
    this.getCountry()
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  async getNPIRegistry(){
    let sub = await this.authenticationService.profile().then(async (profile: Profile) => {
      if((this.termsOfUse && profile?.terms != undefined && profile.terms[0]?.acceptedTerms != false 
        && profile.terms[0]?.version == await this.termsOfUse[0]?.version)){
        this.authenticationService.getNPIRegistry(profile.uid).then((registry:any) => {
          if(registry){
            if(profile.firstName == "" || profile.lastName1 == "" || profile.biologicalgender == ""){
              profile.firstName = registry.basic.first_name.charAt(0).toUpperCase() + registry.basic.first_name.slice(1).toLowerCase();
              profile.lastName1 = registry.basic.last_name.split(" ")[0].charAt(0).toUpperCase() + registry.basic.last_name.split(" ")[0].slice(1).toLowerCase() + " " + registry.basic.last_name.split(" ")[1].charAt(0).toUpperCase() + registry.basic.last_name.split(" ")[1].slice(1).toLowerCase();
              profile.phoneNumber = registry.addresses[0].telephone_number;
              if(registry.basic.gender == "M"){
                profile.biologicalgender = "Male"
              }else{
                profile.biologicalgender = "Female"
              }
              this.afs.collection('users').doc(`${profile.uid}`).update(profile);
            }
          }else{
            if(profile.accountType === 'practitioner' && !registry){
              this.modalRef = this.modalService.open(this.NPIRegistryModal, { centered: true, size: 'lg', backdrop: 'static', keyboard: false, windowClass: 'NPIRegistry' });
            }
          }
        });
      }
    });
    this.allSubscriptions.add(sub)
  }

  getCountry(){
    let sub = this.afs.collection<any>('settings').doc('Country').collection('Country').valueChanges().subscribe(data => {
      this.countries = []; 
      for(let i = 0; i < data.length; i++) {
        if(data[i].enabled == true){
          this.countries.push(data[i])
        }
      }
    });
    this.allSubscriptions.add(sub)
  }

  countryWithStates = true;
  async getStates(country: any){
    let sub = await this.afs.collection("settings").doc('Country').collection('Country').doc(country.country).valueChanges().subscribe(data => {
      if(data.hasStates){
        let sub = this.afs.collection("settings").doc('Country').collection('Country').doc(country.country).collection('States').valueChanges().subscribe(data => {
          this.states = data;
          this.states.sort((a) => {
            if (a.name === 'Puerto Rico') {
              return -1;
            }
            else {
              return 1;
            }
          });
        });
        this.allSubscriptions.add(sub)
        this.countryWithStates = true
      }
      else {
        this.countryWithStates = false
        this.states = []
      }
    });
    this.allSubscriptions.add(sub)
  }

  saveProfile(){
    let langVerify = this.currentLang
    this.loading = true;
    ReloadComponent.loader = 'RELOAD.SAVING'
    this.submit = true;
    if(this.editProfileForm.controls.alternateNumberMsg.value == undefined || this.editProfileForm.controls.alternateNumber.value == ''){
      this.editProfileForm.controls.alternateNumberMsg.patchValue(false)
    }
    if(this.editProfileForm.controls.phoneNumberMsg.value == undefined || this.editProfileForm.controls.phoneNumber.value == ''){
      this.editProfileForm.controls.phoneNumberMsg.patchValue(false)
    }
    if(this.editProfileForm.controls.abbr.value == undefined){
      this.editProfileForm.controls.abbr.patchValue('')
    }

    let BMICalc: any = (Number(this.editProfileForm.controls.BMI.value.pounds) * 703)/(((Number(this.editProfileForm.controls.BMI.value.feet * 12)) + Number(this.editProfileForm.controls.BMI.value.inches)) * ((Number(this.editProfileForm.controls.BMI.value.feet * 12)) + Number(this.editProfileForm.controls.BMI.value.inches)))
      BMICalc = Number(BMICalc.toFixed(1))

      if(BMICalc < 18.5){
        BMICalc = '<en>' + BMICalc + ' - Underweight</en><es>' + BMICalc + ' - Bajo Peso</es>'
      }
      if(BMICalc >= 18.5 && BMICalc <= 24.9){
        BMICalc = '<en>' + BMICalc + ' - Normal Weight</en><es>' + BMICalc + ' - Peso Normal</es>'
      }
      if(BMICalc >= 25 && BMICalc <= 29.9 ){
        BMICalc = '<en>' + BMICalc + ' - Overweight</en><es>' + BMICalc + ' - Sobre Peso</es>'
      }
      if(BMICalc >= 30){
        BMICalc = '<en>' + BMICalc + ' - Obese</en><es>' + BMICalc + ' - Obeso</es>'
      }

      if((this.editProfileForm.controls.phoneNumber.value != this.phoneInProfile || this.editProfileForm.controls.phoneNumberMsg.value != this.phoneMsgInProfile) && this.editProfileForm.controls.phoneNumberMsg.value == true){
        this.linkPhone();
      }

    this.editProfileForm.controls.BMI.setValue({feet: Number(this.editProfileForm.controls.BMI.value.feet),BMI: BMICalc, pounds: Number(this.editProfileForm.controls.BMI.value.pounds), inches: Number(this.editProfileForm.controls.BMI.value.inches)})
    this.editProfileForm.controls.timezone.patchValue(this.timezoneList.find(t => t.name == this.editProfileForm.controls.timezone.value.name))
    const data = this.editProfileForm.value 
    if(this.editProfileForm.controls.email.value != this.currentEmail){
      this.translate.get('PROFILE.SWAL').subscribe((res: any) => {
        this.title = res.SUCCESS
        this.text = res.EMAILSENT
        this.cancel = res.VERIFY
      })
      firebase.auth().currentUser.updateEmail(this.editProfileForm.controls.email.value).then(email => {
        // Update successful
        this.profile.email = this.editProfileForm.controls.email.value
        this.dataService.resendEmail(this.profile).then(() => {
          // Email verification sent!
          this.firebaseService.updateProfile(data, this.profile.uid).then(
            async res => {
              this.cancelIdChanges()
              this.loading = false;
              this.editProfile = false;
              this.submit = false
              this.front = undefined
              this.croppedImage = ''
              this.imageChangedEvent = ''
              if(langVerify != this.editProfileForm.controls.preferedLang.value && this.editProfileForm.controls.preferedLang.value != ''){
                this.languageService.setLanguage(this.editProfileForm.controls.preferedLang.value); 
                ReloadComponent.loader = 'RELOAD.SAVING'
                await this.route.navigateByUrl('/reload', { skipLocationChange: true });
                await setTimeout(async () => {
                  return await this.route.navigateByUrl(this.currentURL);
                },500)
              }
              this.updateUserTrigger.updateUserCollection({trigger: 'handleUserErp'})
            },
            err => {
              this.loading = false;
              this.front = undefined
              this.editProfile = false;
              this.croppedImage = ''
              this.imageChangedEvent = ''
            }
          ); 
          Swal.fire(this.title, this.text + this.editProfileForm.controls.email.value + this.cancel, 'success');
        })
      }).catch((error) => {
        // An error occurred
        this.cancelProfileChanges()
      });
    }
    else {
      this.firebaseService.updateProfile(data, this.profile.uid).then(
        async res => {
          this.cancelIdChanges()
          this.loading = false;
          this.front = undefined
          this.editProfile = false;
          this.submit = false;
          this.croppedImage = ''
          this.imageChangedEvent = ''
          if(langVerify != this.editProfileForm.controls.preferedLang.value){
            this.languageService.setLanguage(this.editProfileForm.controls.preferedLang.value); 
            ReloadComponent.loader = 'RELOAD.CHANGING'
            await this.route.navigateByUrl('/reload', { skipLocationChange: true });
            await setTimeout(async () => {
              return await this.route.navigateByUrl(this.currentURL);
            },500)
          }
          this.updateUserTrigger.updateUserCollection({trigger: 'handleUserErp'})
        },
        err => {
          this.loading = false;
          this.front = undefined
          this.editProfile = false;
          this.croppedImage = ''
          this.imageChangedEvent = ''
        }
      ); 
    }
  }

  cancelProfileChanges(){
    let sub = this.afs.collection<any>('users').doc(this.profile.uid).valueChanges().subscribe(data => {
      this.currentEmail = data['email']
      this.currentLang = data['preferedLang']
      this.profile = data;
      this.phoneInProfile = this.profile.phoneNumber;
      this.phoneMsgInProfile = this.profile.phoneNumberMsg;
      this.editProfileForm.patchValue(this.profile)
      this.daySelected = this.profile.birthdate ? new Date(this.profile.birthdate + 'T00:00:00Z').getUTCDate().toString().padStart(2, '0') : new Date().getDate().toString().padStart(2, '0');
      this.monthSelected = this.profile.birthdate ?  new Intl.DateTimeFormat(this.profile.preferedLang, { month: 'long' }).format(new Date(this.profile.birthdate + 'T00:00:00Z')) : new Intl.DateTimeFormat('es', { month: 'long' }).format(new Date());
      this.yearSelected = this.profile.birthdate ? new Date(this.profile.birthdate + 'T00:00:00Z').getFullYear() : new Date().getFullYear() - 21;
      this.numberOfDays = this.daysRemainingInMonth();
      this.monthNumber = (new Date(this.monthSelected + ' 1, 2023').getMonth() + 1).toString().padStart(2, '0');

      this.editIdForm.patchValue(this.profile)
      this.editProfileForm.controls.timezone.patchValue(this.profile.timezone)

      if(this.profile.accountType == 'practitioner'){
        
        let translationPipe = new TranslationPipe()
        let abbr = translationPipe.transform(this.profile.abbr, this.profile.preferedLang);
        if(this.profile.preferedLang == 'en'){
          this.abbr = abbr;
        }
        else {
          if(this.profile.biologicalgender == 'Female'){
            this.abbr = abbr.split('/la ')[1]
          }
          else {
            this.abbr = abbr.split('el ')[1].split('/')[0]
          }
        }
      }
    });
    this.allSubscriptions.add(sub)
    this.editProfile = false;
    this.emailExists = false;
    this.emailConfirm = '';
  }

  submitId(){
    // if(
    //   // this.editIdForm.controls.idType.value != this.profile.idType ||
    //   this.editIdForm.controls.idExpDate.value != this.profile.idExpDate
    //   || this.editIdForm.controls.idNumber.value != this.profile.idNumber
    //   || this.editIdForm.controls.idStateIssued.value != this.profile.idStateIssued
    //   || this.editIdForm.controls.idCountryIssued.value != this.profile.idCountryIssued){

      this.loading = true;
      ReloadComponent.loader = 'RELOAD.SAVING'
      this.submit = true;
    
      const data = this.editIdForm.value 
      this.firebaseService.updateProfile(data, this.profile.uid).then(
        res => {
          this.cancelIdChanges()
          let update = true
          let sub = this.afs.collection('consultation', ref => ref.where('UID_Patient', '==', this.profile.uid).where('Status', 'in', ['Open', 'In Progress', 'Paid']).where('exception', '!=', [])).valueChanges().subscribe(async consult => {
            let consults: any = consult
            if(update){
              for(let i = 0; i < consults.length; i++){
                let idException = []
                  if(consults[i].exception.includes('payLater')){
                    idException = ['payLater']
                  }
                  this.afs.collection('consultation').doc(consults[i].ID).update({exception: await idException})
                if(i == consults.length - 1){
                  update = false
                }    
              }
            }          
          })
          this.loading = false;
          this.front = undefined
          this.editId = false;
          this.croppedImage = ''
          this.imageChangedEvent = ''
        },
        err => {
          this.loading = false;
          this.front = undefined
          this.editId = false;
          this.croppedImage = ''
          this.imageChangedEvent = ''
        }
      );
    // }
    this.editId = false;
    this.cancelProfileChanges()
  }

  cancelIdChanges(){
    let sub = this.afs.collection<any>('users').doc(this.profile.uid).valueChanges().subscribe(data => {
      this.currentEmail = data['email']
      this.currentLang = data['preferedLang']
      this.profile = data;
      this.phoneInProfile = this.profile.phoneNumber;
      this.phoneMsgInProfile = this.profile.phoneNumberMsg;
      this.editProfileForm.patchValue(this.profile)
      this.daySelected = this.profile.birthdate ? new Date(this.profile.birthdate + 'T00:00:00Z').getUTCDate().toString().padStart(2, '0') : new Date().getDate().toString().padStart(2, '0');
      this.monthSelected = this.profile.birthdate ?  new Intl.DateTimeFormat(this.profile.preferedLang, { month: 'long' }).format(new Date(this.profile.birthdate + 'T00:00:00Z')) : new Intl.DateTimeFormat('es', { month: 'long' }).format(new Date());
      this.yearSelected = this.profile.birthdate ? new Date(this.profile.birthdate + 'T00:00:00Z').getFullYear() : new Date().getFullYear() - 21;
      this.numberOfDays = this.daysRemainingInMonth();
      this.monthNumber = (new Date(this.monthSelected + ' 1, 2023').getMonth() + 1).toString().padStart(2, '0');

      this.editIdForm.patchValue(this.profile)

      this.editProfileForm.controls.timezone.patchValue(this.profile.timezone)

      if(this.profile.accountType == 'practitioner'){
        
        let translationPipe = new TranslationPipe()
        let abbr = translationPipe.transform(this.profile.abbr, this.profile.preferedLang);
        if(this.profile.preferedLang == 'en'){
          this.abbr = abbr;
        }
        else {
          if(this.profile.biologicalgender == 'Female'){
            this.abbr = abbr.split('/la ')[1]
          }
          else {
            this.abbr = abbr.split('el ')[1].split('/')[0]
          }
        }
      }
      // this.verifyExpDate(data.idExpDate)
    });
    this.allSubscriptions.add(sub)
    this.editId = false;
  }

  imageLoaded = false
  cropImage(prevPhoto?){
    this.modalRef = this.modalService.open(this.imageCropper, {size: 'md', backdrop: 'static', windowClass: 'addAddressModal', centered: true})

    if(this.front != undefined){
      this.ratio = 485/300
    }
    else {
      this.ratio = 1
    }
    if(prevPhoto){
      this.imageLoaded = true
      this.imageCropped({base64: prevPhoto})
      this.imageChangedEvent = prevPhoto
    }
    else {
      this.imageLoaded = false
      let element = <HTMLInputElement> document.getElementById("imageInput")
      element.click();
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event) {
    this.croppedImage = event.base64;
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  async fileSelected(fileInput: any){
    this.loading = true;
    ReloadComponent.loader = 'RELOAD.SAVING'
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
		    let blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
        let url = window.URL.createObjectURL(blob);
        this.pwaUpload(blob);
      };
      reader.readAsDataURL(fileInput.target.files[0]); 
    }
  }

  saveImage(){
    this.loading = true;
    ReloadComponent.loader = 'RELOAD.SAVING'

    const decodedData = window.atob(this.croppedImage.split(';base64,')[1]);
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    let test = new Blob([uInt8Array], { type: 'image/png' });
    this.pwaUpload(test)
    this.imageChangedEvent = '';
    this.croppedImage = ''
    this.modalService.dismissAll()
  }

  pwaUpload(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadImageToFirebase(reader.result);
    };
  }

  async uploadImageToFirebase(image) {
    const randomId = Math.random().toString(36).substr(2, 5);

    let deleteImageRef = this.profile.image

    if(!this.editProfile){
      if(this.front){
        deleteImageRef = this.profile.identification
      }
      // else {
      //   deleteImageRef = this.profile.identificationBack
      // }
    }

    this.firebaseService.uploadImage(image, randomId, this.profile.uid, deleteImageRef)
      .then(photoURL => {
        this.image = photoURL;
        this.onSubmit();
      }, err => {
        // console.log(err);
      });
  }

  front = undefined
  onSubmit() {
    this.loading = true;
    ReloadComponent.loader = 'RELOAD.SAVING'

    if(this.editProfile){
      if(this.editProfileForm.controls.alternateNumberMsg.value == undefined || this.editProfileForm.controls.alternateNumber.value == ''){
        this.editProfileForm.controls.alternateNumberMsg.patchValue(false)
      }
      if(this.editProfileForm.controls.phoneNumberMsg.value == undefined || this.editProfileForm.controls.phoneNumber.value == ''){
        this.editProfileForm.controls.phoneNumberMsg.patchValue(false)
      }

      if(this.editProfileForm.controls.abbr.value == undefined){
        this.editProfileForm.controls.abbr.patchValue('')
      }

      const data = {
        image: this.image
      };
      let finalObject = Object.assign(data, this.editProfileForm.value)
      this.firebaseService.updateProfile(finalObject, this.profile.uid)
      .then(
        res => { 
          this.cancelIdChanges()
          let update = true
          let sub = this.afs.collection('consultation', ref => ref.where('UID_Patient', '==', this.profile.uid).where('Status', 'in', ['Open', 'In Progress', 'Paid'])).valueChanges().subscribe(consult => {
            let consults: any = consult
            if(update){
              for(let i = 0; i < consults.length; i++){
                if(consults[i].image != data.image) {
                  this.sendEmail = true
                  this.afs.collection('consultation').doc(consults[i].ID).update({image: data.image}).then(res => {
                  })
                }
                if(i == consults.length - 1){
                  update = false
                  this.front = undefined
                }  
              }
              if(consult.length == 0){
                this.front = undefined
              }
            }          
          })
          this.allSubscriptions.add(sub)
          this.cancelProfileChanges()
          this.editProfile = true;
          this.editId = false;
          this.loading = false
          this.croppedImage = ''
          this.imageChangedEvent = ''
        }
      );
    }
    else{
      var data
      if(this.front == true){
        data = {
          identification: this.image,
          status: 'unverified'
        };
      }
      // else {
      //   data = {
      //     identificationBack: this.image,
      //     status: 'unverified'
      //   };
      // }
      let finalObject = Object.assign(this.editIdForm.value, data)
      this.firebaseService.updateProfile(finalObject, this.profile.uid)
      .then(
        res => {
          this.cancelIdChanges()
          let update = true
          let sub = this.afs.collection('consultation', ref => ref.where('UID_Patient', '==', this.profile.uid).where('Status', 'in', ['Open', 'In Progress', 'Paid'])).valueChanges().subscribe(async consult => {
            let consults: any = consult
            if(update){
              for(let i = 0; i < consults.length; i++){
                // if(consults[i].Status == 'Open' || consults[i].updateId == true) {
                  let idException = []
                  if(consults[i].exception?.includes('payLater')){
                    idException = ['payLater']
                  }
                  if(this.front && consults[i].identification != data.identification){
                    this.afs.collection('consultation').doc(consults[i].ID).update({identification: data.identification, exception: await idException})
                  }
                  // else if(!this.front && consults[i].identificationBack != data.identificationBack){                 
                  //   this.afs.collection('consultation').doc(consults[i].ID).update({identificationBack: data.identificationBack, exception: await idException})
                  // }
                // }      
                if(i == consults.length - 1){
                  update = false
                  this.front = undefined
                }    
              }
              if(consult.length == 0){
                this.front = undefined
              }
            }          
          })
          this.allSubscriptions.add(sub)
          this.cancelIdChanges()
          this.editId = true;
          this.editProfile = false;
          this.loading = false;
          this.croppedImage = ''
          this.imageChangedEvent = ''
        }
      );
    }
  }

  invalidId(){
    if(!this.profile.identification.startsWith('../assets/images') && this.profile.identification !== "" 
      // // && this.defaultIDImgBack !== this.profile.identificationBack && this.profile.identificationBack !== "" 
      // && this.editIdForm.controls.idNumber.value != '' && this.editIdForm.controls.idExpDate.value != '' 
      // && ((this.editIdForm.controls.idStateIssued.value != null && this.editIdForm.controls.idStateIssued.value != '')|| !this.countryWithStates) && this.editIdForm.controls.idCountryIssued.value != null
      // // && this.editIdForm.controls.idType.value != null 
      // && !this.idExpired
      ){
    var element = <HTMLInputElement> document.getElementById("submitId");
      element.disabled = false;
    }
    else{
      var element = <HTMLInputElement> document.getElementById("submitId");
      element.disabled = true;
    }
  }

  disableCheckbox(item, id){
     var element: any = <HTMLInputElement> document.getElementById(id);
   
    if(this.editProfile){
      if (item == '' || item == null || item == undefined || item.length != 10) { 
        element.disabled = true
        if(element.checked)
        {
          element.checked = false;
          element.value = false;
        }
        return true
      }
      if (item != '' || item != null || item != undefined && item.length == 10) {
        element.disabled = false
        return false
      }
    }
    if(this.editProfile == false) {
      element.disabled = true
    }
  }

  close(){
    this.modalService.dismissAll();
  }

  addAddress(addressModal:any, bool:any){
    this.edit = bool;
    this.modalRef = this.modalService.open(addressModal, {size: 'lg', backdrop: 'static', windowClass: 'addAddressModal', centered: true});
  }

  deleteAddress(address: any){
    var id = address.id.toString();
    this.firebaseService.deleteAddress(id, this.profile.uid)
  }

  deleteAddressConfirmation(address: any){
    this.translate.get('PROFILE.SWAL').subscribe((res: any) => {
      this.title = res.DELETEADDRESS
      this.text = res.DELETEADDRESSTEXT
      this.cancel = res.CANCEL
      this.confirmBtn = res.CONFIRM,
      this.remove = res.REMOVED,
      this.removeMsg = res.DELETEDADDRESSMSG
    })

    Swal.fire({
      title: this.title,
      text: this.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#ff3d60',
      confirmButtonText: this.confirmBtn,
      cancelButtonText: this.cancel
    }).then(result => {
      if (result.isConfirmed) {

        this.deleteAddress(address);
        
        Swal.fire(this.remove, this.removeMsg, 'success');
      }
    })
  }

  showModal(){
    this.modalRef = this.modalService.open(this.addCard,{
      size: 'md',
      centered: true,
      backdrop: 'static'
    });
   }

   async openAddCard(error){
    let res = JSON.parse(error);
    let err = res.errors[0].detail;
    let config = await this.remoteConfig();
    this.translate.get('WALLET').subscribe((res: any) => {
      this.swal = res
    })
    Swal.fire({
      title: this.swal.ERRORINCARD,
      text: `${err}`,
      html: `${this.swal.VERIFYINFO}<br>
      ${this.swal.CALLORCONTACTUS}<br>
      <a href="${config.help}">${config.help}</a><br>
      Phone: <a href="'tel:'${config.phoneNumber}">${config.phoneNumber}</a>
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#ff3d60',
      confirmButtonText: this.swal.RETRY,
      cancelButtonText: this.swal.CANCEL
    }).then(result => {
      if (result.isConfirmed) {
        this.modalRef = this.modalService.open(this.addCard,{
          size: 'md',
          centered: true,
          backdrop: 'static'
        });
        return 
      }
    });
   }

  deleteCard(item: any){
    let id = item.customerNumber.toString();

    let uid = this.profile.uid.toString();
    // this.afs.collection("users").doc(uid).collection("wallet").doc(id).delete().then(res => {
    //   this.getAcceptaCards()
    // }); //Added 03/17/22
    this.afs.collection("users").doc(uid).collection("wallet").doc(id).update({active:false}).then(res => {
      this.getAcceptaCards()
    }); //Added 03/17/22
  }

  deleteCardConfirmation(item: any){
    this.translate.get('PROFILE.SWAL').subscribe((res: any) => {
      Swal.fire({
        title: res.TITLE,
        text: res.TEXT,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#ff3d60',
        confirmButtonText: res.CONFIRM,
        cancelButtonText: res.CANCEL
      }).then(result => {
        if (result.isConfirmed) {

          this.deleteCard(item);
          
          Swal.fire(res.REMOVED, res.REMOVEDMSG, 'success');
        }
      })
    })
  }

  selectPhone(event) {
    if (event.target.checked && event.target.id == 'phoneType') {
      this.phoneNumberMsg = true;
    } else if (event.target.checked && event.target.id == 'phoneType1') {
      this.alternateNumberMsg = true;
    }
    else if (!event.target.checked && event.target.id == 'phoneType') {
      this.phoneNumberMsg = false;
    } else if (!event.target.checked && event.target.id == 'phoneType1') {
      this.alternateNumberMsg = false;
    }
  }

  invalidProfile(){
    var element = <HTMLInputElement> document.getElementById("saveProfileBtn");
    if( this.editProfileForm.controls.firstName.value != undefined && this.editProfileForm.controls.firstName.value != ''
        && this.editProfileForm.controls.lastName1.value != undefined && this.editProfileForm.controls.lastName1.value != ''
        && this.editProfileForm.controls.biologicalgender.value != undefined && this.editProfileForm.controls.biologicalgender.value != ''
        && this.editProfileForm.controls.birthdate.value != undefined && this.editProfileForm.controls.birthdate.value != ''
        && this.editProfileForm.controls.phoneNumber.value != undefined && this.editProfileForm.controls.phoneNumber.value != ''
        && this.emailExists == false && this.editProfileForm.controls.phoneNumber.value.length == 10
        && this.editProfileForm.controls.email.value != undefined && this.editProfileForm.controls.email.value != ''
        // && this.defaultProfileImg !== this.profile.image && this.profile.image !== "" 
        && this.editProfileForm.controls.preferedLang.value != undefined && this.editProfileForm.controls.preferedLang.value != ''){
        element.disabled = false
    }
    else {
      element.disabled = true
    }
    if(this.currentEmail != this.editProfileForm.controls.email.value && this.editProfileForm.controls.email.value != ''
      && this.emailExists == false){
      if(this.emailConfirm == this.editProfileForm.controls.email.value){
        element.disabled = false
      }
      else {
        element.disabled = true
      }
    }

    if(this.profile.accountType == 'practitioner' && (this.editProfileForm.controls.abbr.value == undefined || this.editProfileForm.controls.abbr.value == '')){
      element.disabled = true
    }
  }

  ifEmailConfirm(event){
    this.emailConfirm = event.target.value
  }

  emailExist($event:any){
      let email = $event.target.value;
      this.afs.firestore.collection("users").where("email","==", email).get().then(data => {
        
        if(data.docs.length !== 0 && this.currentEmail != email){
          this.emailExists = true;
        }else{
          this.emailExists = false;
        }
      })
  }

  randomGenerator;
  codeInfo;
  msg;
  phoneProfile;
  linkPhone(){
    this.phoneProfile = Object.assign(this.profile, this.editProfileForm.value)
    this.linkPhoneNumber = true;
  }

  //for practitioners
  searchRegistry(NPI){
    this.NPI = NPI;
    if(NPI.length == 10){
      this.search = true;
      this.NPIRegistryService.searchRegistry(NPI, this.profile.uid).then((res:any) => {
        if(Object.keys(res.message).length !== 0){
          if(res.message.result_count > 0){
            this.search = false;  
            let billing = res.message.addresses[0];
            let shipping = res.message.addresses[1];
            this.firebaseService.practitionersAddresses([billing, shipping]).then(() => {
              this.modalService.dismissAll()
            })
          }
        }else{
          this.search = false;
        }
      });
    }
  }

  pdf
  pdfName
  async pdfSelected(fileInput: any){
    this.loading = true
    ReloadComponent.loader = 'RELOAD.SAVING'

    if (fileInput.target.files && fileInput.target.files[0]) {
      this.pdfName = fileInput.target.files[0].name;
      const reader = new FileReader();
      reader.onload = (e: any) => {
		    let blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
        let url = window.URL.createObjectURL(blob);
        this.pdfUpload(blob);
      };
      reader.readAsDataURL(fileInput.target.files[0]); 
    }
  }

  pdfUpload(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadPDFToFirebase(reader.result);
    };
  }

  async uploadPDFToFirebase(pdf) {
    const randomId = Math.random().toString(36).substr(2, 5);
    this.firebaseService.uploadPdf(pdf, randomId,this.profile.uid)
      .then(pdfURL => {
        this.uploadDocToFirebase(pdf)
      });
  }
  
  description = '';
  uploadDocToFirebase(pdfUrl){

    let Data = {
      id: this.firebaseService.getDate(),
      pdf: pdfUrl.split("base64,")[1],
      pdfName: this.pdfName,
      uploaded: new Date(),
      referenceID: this.profile.uid,
      referenceIDModel: 'users',
      docFor: 'Practitioner Info',
      description: this.description,
      uidPatient: 'N/A',
      uidPractitioner: this.profile.uid,
      valid: false,
      expDate: this.expDate
    }

    this.afs.collection('Documents').doc(Data.id).set(Data);
    this.expDate = '';
    this.pdfToSave = '';
    this.description = '';
    this.cancelIdChanges()
    this.loading = false;
    this.front = undefined
    this.croppedImage = ''
    this.imageChangedEvent = ''
  }

  pdfModal
  showPDF(pdfTag){
    this.pdfModal = this.modalService.open(pdfTag, { size: 'lg', backdrop: 'static', centered: true  });
  }

  closePdf(){
    this.pdfModal.dismiss()
  }

  openModal = false
  termsOfUse
  async getTerms(){
    let sub = this.afs.collection("settings").doc("Terms").collection('Terms', ref => ref.where('termsUserType', '==', this.profile.accountType)).valueChanges().subscribe(async data => {
      this.termsOfUse = await [data[data.length -1]]
      if(this.termsOfUse[0]){
        if(this.currentLang == 'en'){
          this.termsOfUse.html = await this.sanitaizer.bypassSecurityTrustHtml(this.termsOfUse[0].html_en)
        }
        else {
          this.termsOfUse.html = await this.sanitaizer.bypassSecurityTrustHtml(this.termsOfUse[0].html_es)
        }
        
        if(( await this.profile?.terms == undefined
          || await this.profile?.terms[0]?.acceptedTerms == false 
          || await this.profile?.terms[0]?.version != await this.termsOfUse[0]?.version) && this.openModal == false){
          this.modalService.open(this.termsModal, {size: 'lg', backdrop: 'static', centered: true, keyboard: false})
          this.openModal = true
        }  
        else if(this.profile.accountType == 'practitioner') {
          this.getNPIRegistry()
        }
      }      
    })   
    this.allSubscriptions.add(sub)
  }

  remoteConfig(){
    return new Promise<any>(async (resolve, reject) => {
      resolve(fbRemoteConfig.defaultConfig);
    });
  }

  verifyExpDate(event){
    let month = (new Date().getMonth() + 1).toString()
    let day = (new Date().getDate()).toString()
    if(Number(month) < 10){
      month = '0' + month
    }
    if(Number(day) < 10){
      day = '0' + day
    }
    let date = new Date().getFullYear() + '' + month + '' + day
    let expDate = event.replaceAll('-','')

    if(event != '' && Number(expDate) < Number(date)){
      this.idExpired = true;
      if(this.profile.status != 'unverified'){
        this.afs.collection<any>('users').doc(this.profile.uid).update({status: 'unverified'})
      }
    }
    else {
      this.idExpired = false;
    }
  }

  async getAcceptaCards(){
    this.afs.collection("users").doc(firebase.auth().currentUser.uid).collection("wallet").valueChanges().subscribe(async (data:any) => {
      this.allPaymentMethods = await data;
    });
  }


  //OPEN CAMERA
  public capture() {
    // this.loading = true //original without cropper
    ReloadComponent.loader = "RELOAD.SAVING"
    if(this.editProfile){
      var oc = <HTMLCanvasElement> document.getElementById("imageCanvasTT");
      var occtx = oc.getContext('2d');
 
      var imgEl = this.video;
      var img = new Image();
      img.crossOrigin = "anonymous";
      img.src = imgEl.nativeElement.baseURI + imgEl.nativeElement.srcObject.id;
      oc.width = imgEl.nativeElement.videoWidth * 0.55;
      oc.height = imgEl.nativeElement.videoHeight * 0.55;
      occtx.drawImage(imgEl.nativeElement, 0, 0, oc.width, oc.height);
      
      var c = <HTMLCanvasElement> document.getElementById("imageCanvasT");
      var ctx = c.getContext("2d");

      c.width = 250;
      c.height = 250;
      let centerWidth = (oc.width - 250)/2
      let centerHeight = (oc.height - 250)/2

      ctx.drawImage(oc, centerWidth, centerHeight, 250, 250, 0, 0, 250, 250);
      this.captures = c.toDataURL('image/png')
      // var context = this.canvas.nativeElement.getContext("2d").drawImage(this.video.nativeElement, 0, 0, 250, 250);
      // this.captures = this.canvas.nativeElement.toDataURL("image/png")
      this.cropImage(c.toDataURL())
    }
    else {
      this.canvasID.nativeElement.getContext("2d").drawImage(this.video.nativeElement, 0, 0, 350, 250)
      this.captures = this.canvasID.nativeElement.toDataURL("image/png")
      this.cropImage(this.captures)

    }
   
    const decodedData = window.atob(this.captures.split(';base64,')[1]);
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    let test = new Blob([uInt8Array], { type: 'image/png' });
    // this.pwaUpload(test) //original without cropper
  }

  videoSrc
  openedCam = false;
  startCam(){
    this.loading = true
    ReloadComponent.loader = 'RELOAD.STARTCAM'

    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {

        this.video.nativeElement.srcObject = stream
        this.video.nativeElement.play();
        this.loading = false
        this.videoSrc = stream
      });
    }   
  }

  closeCam(){
    if(this.video && this.video.nativeElement){
      this.video.nativeElement.pause()
      this.video.nativeElement.srcObject = undefined
      if(this.videoSrc){
        this.videoSrc.getTracks()[0].stop()
      }
    }
    this.openedCam = false
  }

  updateAuthorizedUsersInAccount(){
    this.modalService.open(this.authUsersModal, {size: 'lg', backdrop: 'static', centered: true, keyboard: false})
  }

  daysRemainingInMonth() {
    this.getMonths().forEach((month, index )=> {
      if(month === this.monthSelected){
        this.monthNumber = (index + 1).toString().padStart(2, '0');
      }
    });
    this.numberOfDays = new Date(this.yearSelected, Number(this.monthNumber), 0).getDate()
    this.daySelected = this.numberOfDays.toString().padStart(2, '0') > this.daySelected ? this.daySelected : this.numberOfDays.toString().padStart(2, '0');
    this.editProfileForm.patchValue({birthdate: this.yearSelected + '-' + this.monthNumber + '-' + this.daySelected})
    return new Date(this.yearSelected, Number(this.monthNumber), 0).getDate()
  }

  getMonths() {
    let months = [];
    for (let i = 0; i <= 11; i++) {
      const date = new Date(2023, i, 10);
      months.push(date.toLocaleString(this.currentLang, { month: 'long' }));
    }
    return months;
  }

  getDays() {
    let days = [];
    for (let i = 1; i <= this.numberOfDays; i++) {
      days.push(i.toString().padStart(2, '0'));
    }
    return days;
  }

  getYears() {
    let years = [];
    let currentYear = new Date().getFullYear();
    for (let i = currentYear - 21; i >= currentYear - 100; i--) {
      years.push(i);
    }
    return years;
  }

  makeDefaultPaymentMethod($event, card) {
    this.walletService.setDefault($event, this.profile, card);
  }

  openModals(modal){
    this.modalService.open(modal, {size: 'lg', backdrop: 'static', centered: true, keyboard: false})
  }

  imgType = ''
  chooseImgEnv(imgType,modal){
    this.imgType = imgType
    this.modalRef = this.modalService.open(modal, { size: 'md', backdrop: 'static', windowClass: 'chooseImgEnvModal', centered: true});
  }
}
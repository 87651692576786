import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LaboratoriesService } from 'src/app/core/services/laboratories/laboratories.service';
import { TranslationPipe } from 'src/app/translation.pipe';


@Component({
  selector: 'app-labs-picker',
  templateUrl: './labs-picker.component.html',
  styleUrls: ['./labs-picker.component.scss']
})


export class LabsPickerComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()  consult: any = null;
  @Output() continue: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  
  
  labsChosen: any[] = []; 
  view: string = "detailed";
  
  categories: string[] = [];
  laboratories: any[] = [];

  selectedCategoriesLabs: any[] = [];
  lastCategory:string = null;

  LAB_VIEW_OPTIONS = {
    GROUP: "group",
    DETAILED: "detailed",
    BOTH: "both",
    NONE: "none"
  }


  constructor(
    private db: AngularFirestore,
    private labsService: LaboratoriesService,
    private translationPipe: TranslationPipe
  ) { }


  async ngOnInit(): Promise<void> {
    await this.getLabs();
    await this.getUniqueCategories();
    
    // this.consult.hasLabs = this.LAB_VIEW_OPTIONS.GROUP;
    
  }

  ngAfterViewInit(): void {
    
    
  }



  getLabs(){
    this.labsService.getLabs().subscribe(async (labs: any) => {
      this.laboratories = labs.filter(lab => lab.condition.includes(this.consult.Type));
      // setTimeout(async () => {
        if(this.consult.generalLabs){
          this.laboratories = this.laboratories.map(lab => {
            lab.required = true;
            if(lab.condition.includes(this.consult.Type)){
              this.selectLab(lab)
              this.labsChosen.push(lab)
            }
            return lab;
          })
        }
        this.laboratories.forEach(lab_ => {
          
          let lab = this.laboratories.some(lab => lab.condition.includes(this.consult.Type) && lab_.required);
          let element = <HTMLInputElement>document.getElementById(lab_.id);
          if (lab) {
            
            this.selectedCategoriesLabs.push(lab_);
            // element.checked = true;
          }
        });
        this.consultLabsChosen();

      // }, 1000);
      // this.consultLabsChosen();
      // this.laboratories = this.laboratories.filter(lab => lab.condition.includes(this.consult.Type));
      
    });
  }

  //! THIS FUNCTIONS ARE SPECIFIC FOR CATEGORY SECTION  AND VIEW EQUAL TO 'group'

  getUniqueCategories(){
    let c = this.labsService.getUniqueCategories(this.consult.Type).subscribe((categories: any[]) => {
      this.categories = categories;
      c.unsubscribe();
    });
  }


  setImage(category: string){
    const lab = this.laboratories.find(lab => lab.category.includes(category));
    return lab.image;
  }

  setListOfLabs(category: string){
    const labs = this.laboratories
    .map(lab => (lab.category.includes(category) ? lab : null))
    .filter(labTranslation => labTranslation !== null);
    // labs.filter(x => x != null);
    return labs.map(lab => this.translationPipe.transform(lab.labTranslation, this.consult.consultLanguage) );
  }

  setCategories(){
    return this
  }

  //This enforcement is based on the condition of the current consultation the patient is on.
  enforceSelection(lab_:any){
    // this.consult.Type = "STD";
    // let labs = this.laboratories.some(lab => lab.condition.includes(this.condition) && lab.category.includes(...this.categories.map(cat => cat)));
    // let labs = this.laboratories.some(lab => lab.condition.includes(this.consult.Type) && lab.category.includes(category));
    let labs = this.laboratories.some(lab => lab.condition.includes(this.consult.Type) && lab_.required);
    let element = <HTMLInputElement>document.getElementById(lab_.id);
    if (labs) {
      // this.selectLab(lab_);
      element.checked = true;
    }
    return labs;
  }




  getSelectedCategories(category:string){
    
    // element.checked = true;
      // return;

    
    const has = this.selectedCategoriesLabs.some(cat => cat.category.includes(category));
    if (has) {
      this.selectedCategoriesLabs = this.selectedCategoriesLabs.filter(cat => !cat.category.includes(category));
      let labsToDelete = this.labsChosen.filter(labChosen => labChosen.category[0] == category);
      labsToDelete.forEach(labToDelete => {
        this.db.collection("consultation").doc(this.consult.ID).collection("LabsChosen").doc(labToDelete.id).delete();
      })
      // element.checked = false;
    } else {
      let element = <HTMLInputElement>document.getElementById(category);
      let labs = [...this.laboratories];

      const matchingLabs = labs.filter(lab => lab.category.includes(category));
      this.selectedCategoriesLabs.push(...matchingLabs);
    }
  }

 

  storeLabs(){
    
    // this.consult.labs = this.selectedCategoriesLabs.map(lab => lab.labName);
    this.selectedCategoriesLabs.forEach((lab, i) => {
      let lab_data = Object.assign({id: lab.id, labCode: lab.labCode, labName: lab.labName, category: lab.category});
      this.db.collection("consultation").doc(this.consult.ID).collection("LabsChosen").doc(lab_data.id).set(lab_data);
      if(i == this.selectedCategoriesLabs.length - 1){
        this.continue.emit(true);
      }
    });
  }

  uncheckCategory(category:string){
    return this.labsChosen.some(lab => lab.category.includes(category))
  }


  goBack(){
    this.back.emit(true);
  }


  consultLabsChosen(){
    if(this.laboratories.length > 0){
      this.labsService.getLabsChosen(this.consult.ID).subscribe((labs: any[]) => {
        this.labsChosen = labs;
        if(labs.length > 0){
          this.selectedCategoriesLabs = labs.map(lab_ => {
            return this.laboratories.find(lab => lab.labName == lab_.labName);
          });
        }
      });
    }
  }





  //* THIS FUNCTIONS ARE SPECIFIC FOR VIEW EQUAL TO 'detailed'

  selectLab(lab:any){
    const has = this.selectedCategoriesLabs.some(cat => cat.id == lab.id);
   
    if(!lab.required){
      if (has) {
        this.selectedCategoriesLabs = this.selectedCategoriesLabs.filter(cat => cat.id != lab.id);
        const labsToDelete = this.labsChosen.filter(l => !this.selectedCategoriesLabs.some(cat => cat.id === l.id));
        labsToDelete.forEach(labToDelete => {
          this.db.collection("consultation").doc(this.consult.ID).collection("LabsChosen").doc(labToDelete.id).delete();
        });
  
        //delete here
      } else {
        this.selectedCategoriesLabs.push(lab);
      }
    }
    
  }

  uncheck(lab:any){
    return this.labsChosen.some(l => l.id == lab.id) || this.selectedCategoriesLabs.some(l => l.id == lab.id) || lab.required;
  }


















  ngOnDestroy(): void {
    this.selectedCategoriesLabs = []
  } 



}
<app-pagetitle [title]="pagetitle"></app-pagetitle>

<!-- <div class="card">
    <div class="card-body">
        <span class="row">
            <div class="col-sm-12 col-md-12">
            <div id="tickets-table_filter" class="dataTables_filter text-md-right" >
                <label for="search"
                class="d-inline-flex align-items-center justify-content-end">{{'ACCOUNTS.SEARCH' | translate}}
                <input id="search" type="text" name="searchTerm" class="form-control form-control-sm ml-2"aria-controls="tickets-table" [(ngModel)]="term" (keyup)="filterPrescriptions(term)"/>
                </label>
            </div>
            </div>
        </span>
        <div class="table-responsive">
            <table class="table table-centered m-0">
                <thead class="thead-light">
                    <tr> 
                        <th>Action</th>
                        <th>Patient</th>
                        <th>Patient Phone</th>
                        <th>Patient Email</th>
                        <th>Order Date</th>
                        <th>Scheduled Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of filteredPrescriptions | paginate: { itemsPerPage: 10, currentPage: page, id: 'first'}; let i = index;">
                        <td>
                            <div style="cursor: pointer;" (click)="getDetails(item)">
                                <i class="mdi mdi-prescription mr-3 text-dark" style="font-size: 22px;"></i>
                            </div>
                        </td>
                        <td>{{item.name}}</td>
                        <td>{{item.phone | mask:'(000) 000-0000'}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.date.toDate() | date:'MM/dd/yyyy'}}</td>  
                        <td></td>
                        <td><span class="badge badge-success font-size-14 mb-0">{{item.status}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> 
<div class="row justify-content-end">
    <div class="col text-right">
      <pagination-controls (pageChange)="page = $event" id="first" style="text-align: right;"></pagination-controls>
    </div>
</div>  -->
<!-- end row -->
<div class="card">
    <div class="card-body">
        <span class="row">
            <div class="col-sm-12 col-md-12">
                <div>
                    <ng-select style="width: 150px;" [clearable]="false" (change)="filterPrescriptionsByStatus(filteredPrescriptionsByStatus)" [(ngModel)]="filteredPrescriptionsByStatus" >
                        <ng-option value="done">Pending</ng-option>
                        <ng-option value="inProcess">In Process</ng-option>
                        <ng-option value="completed">Completed</ng-option>
                        <ng-option value="error">Canceled</ng-option>
                    </ng-select>
                    <div>
                        <button class="btn btn-link" (click)="copyTextToClipboard()">Copy Data <i class="mdi mdi-content-copy text-primary"></i></button>
                    </div>
                </div>
                <div id="tickets-table_filter" class="dataTables_filter text-md-right" >
                    <label for="search"
                    class="d-inline-flex align-items-center justify-content-end">{{'ACCOUNTS.SEARCH' | translate}}
                    <input id="search" type="text" name="searchTerm" class="form-control form-control-sm ml-2"aria-controls="tickets-table" [(ngModel)]="term" (keyup)="filterOrders(term)"/>
                    </label>
                </div>
            </div>
        </span>
        <div *ngIf="!loadingOrders" class="table-responsive">
            <ng-container *ngIf="filteredOrders">
                <ng-container *ngIf="filteredOrders.length > 0">
                    <table class="table table-centered m-0">
                        <thead class="thead-light">
                            <tr> 
                                <th>Action</th>
                                <th>Order ID</th>
                                <th>Patient</th>
                                <th>Patient Phone</th>
                                <th>Patient Email</th>
                                <th>Order Date</th>
                                <!-- <th>Scheduled Date</th> -->
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of filteredOrders | paginate: { itemsPerPage: 10, currentPage: pageOrders, id: 'first'}; let i = index;">
                                <td style="display: flex;">
                                    <div *ngIf="!hideAcquireIcon || item.id != selectedOrderId" style="cursor: pointer; display: flex;" (click)="acquireOrder(item)">
                                        <div *ngIf="item.orderState === 'done' && item.deliveryStatus != 'Done'">
                                            <i class="mdi mdi-plus-circle mr-3 text-success" style="font-size: 22px;"></i>
                                        </div>
                                    </div>
                                    <div *ngIf="(showRxIcon && !showLoading && item.id === selectedOrderId) || (item.orderState === 'inProcess') || (item.orderState == 'done' && item.deliveryStatus == 'Done')" style="cursor: pointer; display: flex;" (click)="getDetails(item)">
                                        <div>
                                            <i class="mdi mdi-prescription mr-3 text-dark" style="font-size: 22px;"></i>
                                        </div>
                                    </div>
                                    <!-- <div *ngIf="orderAcquired != true || item.id != selectedOrderId" style="cursor: pointer; display: flex;" (click)="getDetails(item)">
                                        <div *ngIf="item.orderState === 'inProcess'">
                                            <i class="mdi mdi-prescription mr-3 text-dark" style="font-size: 22px;"></i>
                                        </div>
                                    </div> -->
                                    <div *ngIf="hideAcquireIcon && showLoading && item.id === selectedOrderId">
                                        <span class="spinner-border spinner-border-sm" role="status"></span>
                                    </div>
                                </td>
                                <td>{{item.orderNo}}</td>
                                <td>{{item.fullName}}</td>
                                <td>{{item.phone | mask:'(000) 000-0000'}}</td>
                                <td>{{item.email}}</td>
                                <td>{{item.paymentDate.toDate() | date:'MM/dd/yyyy'}}</td>  
                                <!-- <td></td> -->
                                <td>
                                    <ng-container *ngIf="orderAcquired != true || item.id != selectedOrderId">
                                        <div *ngIf="item.orderState === 'done' && item.deliveryStatus != 'Done'">
                                            <span class="badge badge-success font-size-14 mb-0" style="text-transform: capitalize;">Pending</span>
                                        </div>
                                        <div *ngIf="item.orderState === 'done' && item.deliveryStatus == 'Done'">
                                            <span class="badge badge-success font-size-14 mb-0" style="text-transform: capitalize;">Completed</span>
                                        </div>
                                        <div *ngIf="item.orderState === 'inProcess'">
                                            <span class="badge badge-dark font-size-14 mb-0" style="text-transform: capitalize;">In Process</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="orderAcquired === true && item.id === selectedOrderId">
                                        <div>
                                            <span class="badge badge-dark font-size-14 mb-0" style="text-transform: capitalize;">In Process</span>
                                        </div>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
                <ng-container *ngIf="filteredOrders.length === 0">
                    <table class="table table-centered m-0">
                        <thead class="thead-light">
                            <tr> 
                                <th>Action</th>
                                <th>Order ID</th>
                                <th>Patient</th>
                                <th>Patient Phone</th>
                                <th>Patient Email</th>
                                <th>Order Date</th>
                                <!-- <th>Scheduled Date</th> -->
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="7">
                                    <div class="row" style="justify-content: center; align-items: center;">
                                        <div class="col-lg-12">
                                            <div class="text-center">No orders available at this time.</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </ng-container>
        </div>
        <div *ngIf="loadingOrders" class="row" style="justify-content: center; align-items: center;">
            <div class="col-lg-12">
                <div class="text-center">
                    <span class="spinner-border spinner-border-sm" role="status"></span>
                </div> 
            </div>
        </div>
    </div>
</div> 
<ng-container *ngIf="filteredOrders">
    <div *ngIf="filteredOrders.length > 0" class="row justify-content-end">
        <div class="col text-right">
        <pagination-controls (pageChange)="pageOrders = $event" id="first" style="text-align: right;"></pagination-controls>
        </div>
    </div>
</ng-container>
<!-- end row -->

<!--------------------NEW PAGINATION-------------------->
<!-- <table class="table table-centered m-0">
    <thead class="thead-light">
        <tr> 
            <th>Action</th>
            <th>Order ID</th>
            <th>Patient</th>
            <th>Patient Phone</th>
            <th>Patient Email</th>
            <th>Order Date</th>
            <th>Status</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of ordersData | paginate: { itemsPerPage: 10, currentPage:currentPageTest, id: 'second'}; let i = index;">
            <td style="display: flex;">
                <div *ngIf="!hideAcquireIcon || item.id != selectedOrderId" style="cursor: pointer; display: flex;" (click)="acquireOrder(item)">
                    <div *ngIf="item.orderState === 'done'">
                        <i class="mdi mdi-plus-circle mr-3 text-success" style="font-size: 22px;"></i>
                    </div>
                </div>
                <div *ngIf="(showRxIcon && !showLoading && item.id === selectedOrderId) || (item.orderState === 'inProcess')" style="cursor: pointer; display: flex;" (click)="getDetails(item)">
                    <div>
                        <i class="mdi mdi-prescription mr-3 text-dark" style="font-size: 22px;"></i>
                    </div>
                </div>
                <div *ngIf="hideAcquireIcon && showLoading && item.id === selectedOrderId">
                    <span class="spinner-border spinner-border-sm" role="status"></span>
                </div>
            </td>
            <td>{{item.orderNo}}</td>
            <td>{{item.fullName}}</td>
            <td>{{item.phone | mask:'(000) 000-0000'}}</td>
            <td>{{item.email}}</td>
            <td>{{item.date.toDate() | date:'MM/dd/yyyy'}}</td>  
            <td>
                <ng-container *ngIf="orderAcquired != true || item.id != selectedOrderId">
                    <div *ngIf="item.orderState === 'done'">
                        <span class="badge badge-success font-size-14 mb-0" style="text-transform: capitalize;">Pending</span>
                    </div>
                    <div *ngIf="item.orderState === 'inProcess'">
                        <span class="badge badge-dark font-size-14 mb-0" style="text-transform: capitalize;">In Process</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="orderAcquired === true && item.id === selectedOrderId">
                    <div>
                        <span class="badge badge-dark font-size-14 mb-0" style="text-transform: capitalize;">In Process</span>
                    </div>
                </ng-container>
            </td>
        </tr>
    </tbody>
</table>
  
<div class="row justify-content-end">
    <div class="col text-right">
        <pagination-controls 
            [maxSize]="7"
            [directionLinks]="true"
            [autoHide]="false"
            [responsive]="true"
            [totalItems]="totalItems"
            [itemsPerPage]="itemsPerPage"
            (pageChange)="onPageChange($event)" 
            id="second" 
            style="text-align: right;">
        </pagination-controls>
    </div>
</div> -->
  
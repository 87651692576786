import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'MENUITEMS.HOME.TEXT',
    icon: 'ri-home-4-line',
    link: '/',
    patient: true,
    practitioner: true,
    typeOfPractitioner: [],
    admin: true,
    analyticsViewer: true,
    none: true,
  },
  {
    label: 'MENUITEMS.PROFILE.TEXT',
    icon: 'ri-user-line',
    link: '/profile',
    patient: true,
    practitioner: true,
    typeOfPractitioner: [],
    admin: true,
    analyticsViewer: true,
    emailVerified: true,
  },
  {
    label: 'MENUITEMS.SUPPORT.TEXT',
    icon: 'ri-mail-line',
    link: '/inboxandsupport',
    patient: true,
    practitioner: true,
    typeOfPractitioner: [],
    admin: true,
    emailVerified: true,
  },
  {
    label: 'MENUITEMS.PATIENT.TEXT',
    isTitle: true,
    patient: true,
    emailVerified: true
  },
  {
    label: 'MENUITEMS.PRACTITIONER.TEXT',
    isTitle: true,
    practitioner: true,
    typeOfPractitioner: [],
    emailVerified: true
  },
  {
    label: 'MENUITEMS.ADMIN.TEXT',
    isTitle: true,
    admin: true,
    emailVerified: true
  },
  {
    label: "Melanie's CC",
    link: '/melanie-cc',
    icon: 'fas fa-server',
    programmer: true,
    emailVerified: true,
  },
  {
    label: 'MENUITEMS.ACCOUNTS.TEXT',
    icon: 'ri-account-box-line',
    link: '/accounts',
    admin: true,
    emailVerified: true,
  },
  {
    label: 'MENUITEMS.CONSULTATIONS.TEXT',
    icon: 'ri-survey-fill',
    link: '/consultations',
    patient: true,
    admin: true,
    emailVerified: true,
  },
  // {
  //   label: 'MENUITEMS.FAMILYHISTORY.TEXT',
  //   icon: 'fa-solid fa-book-medical',
  //   link: '/family-history',
  //   patient: false,
  //   programmer: true,
  //   emailVerified: true,
  // },
  {
    label: 'MENUITEMS.TELEHEALTH.TEXT',
    icon: 'ri-video-chat-line',
    link: '/telehealth',
    patient: true,
    practitioner: true,
    typeOfPractitioner: ['Doctor', 'Psychologist'],
    emailVerified: true,
  },
  {
    label: 'MENUITEMS.ORDERS.TEXT',
    icon: 'ri-shopping-cart-2-line',
    link: '/orders&Refills',
    patient: true,
    admin: true,
    emailVerified: true,
  },
  {
    label: 'MENUITEMS.PRACTITIONERSCONSULTS.TEXT',
    icon: 'ri-survey-fill',
    link: '/consults',
    practitioner: true,
    typeOfPractitioner: ['Doctor', 'Psychologist'],
    admin: true,
    emailVerified: true,
  },
  {
    label: 'MENUITEMS.PRESCRIPTIONS&REFILLS.TEXT',
    icon: 'ri-survey-fill',
    link: '/prescriptions',
    emailVerified: true
  },
  // {
  //   label: 'MENUITEMS.SUBSCRIPTIONS.TEXT',
  //   icon: 'fa fa-credit-card',
  //   link: '/subscriptions',
  //   emailVerified: true,
  //   patient: true
  // },
  // {
  //   label: 'MENUITEMS.LABORATORIES.TEXT',
  //   link: '/admin-laboratories',
  //   icon: 'fa fa-flask',
  //   admin: true,
  //   emailVerified: true
  // },
  {
    label: 'MENUITEMS.ANALYTICS.TEXT',
    icon: 'ri-dashboard-line',
    link: '/analytics',
    emailVerified: true,
    admin: true,
    analyticsViewer: true
  },
  {
    label: 'MENUITEMS.PRESCRIPTIONS.TEXT',
    icon: 'fas fa-file-prescription',
    link: '/prescription-list',
    emailVerified: true,
    patient: true
  },
  {
    label: 'Migration',
    icon: 'fa fa-arrows-alt',
    link: '/migration',
    programmer: true,
    emailVerified: true
  },
  {
    label: 'MENUITEMS.CONSULTQUESTIONS.TEXT',
    link: '/manageConsultQuestions',
    icon: 'fa fa-question',
    programmer: true,
    questionAdmin: true,
    emailVerified: true
  },
  {
    label: 'MENUITEMS.BILLING.TEXT',
    link: '/billing',
    icon: 'fa fa-list-alt',
    practitioner: true,
    typeOfPractitioner: ['Doctor', 'Psychologist'],
    emailVerified: true
  },
  // {
  //   label: 'CONSULT-PRESCRIPTIONS.MEDICALPRESCRIPTION',
  //   link: '/pharmacy-prescriptions',
  //   icon: 'fas fa-prescription-bottle-alt',
  //   practitioner: true,
  //   typeOfPractitioner: ['Pharmacist'],
  //   emailVerified: true
  // },
  {
    label: 'Surveys',
    link: '/admin-surveys',
    icon: 'ri-dashboard-line',
    programmer: true,
    admin: true,
    emailVerified: true
  },
  {
    label: 'Pharmacy',
    link: '/pharmacy',
    icon: 'fa fa-pills',
    programmer: true,
    emailVerified: true,
    practitioner: true,
    superAdmin: true,
    typeOfPractitioner: ['Pharmacist']
  },
  {
    label: 'Affiliate-Program',
    icon: 'fa fa-share-alt',
    link: '/affiliate-program',
    admin: true,
    emailVerified: true,
  },
  {
    label: 'Prescription Manager',
    link: '/prescription-manager',
    icon: 'fa fa-pills',
    programmer: true,
    emailVerified: true,
  },
  {
    label: "Orders Report",
    link: '/orders-report',
    icon: 'fas fa-table',
    programmer: true,
    emailVerified: true,
  }
];
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { s } from '@fullcalendar/core/internal-common';

@Injectable({
  providedIn: 'root'
})
export class ZplConverterService {

  private apiUrl = 'https://us-central1-dzeus-app-telemd.cloudfunctions.net/convertZplToPng';

  constructor(private http: HttpClient) { }

  convertZplToPng(data: any): Observable<any> {

    // console.log('label_url', data.label_url);

    // console.log('data', data);

    var label_url = data.label_url;

    var orderId = data.orderId;

    var shipmentId = data.shipmentId;

    return this.http.get<any>(this.apiUrl, 
      { 
        params: 
        { 
          label_url,
          orderId,
          shipmentId
        } 
      }
    );
  }

}

<div>
  <h3>Prescription Manager</h3>

  <div *ngIf="!loading && prescriptions.length == 0">
    <p>No prescriptions found.</p>
  </div>
  <div *ngIf="loading">
    <p>Loading...</p>
  </div>
  <div *ngIf="prescriptions.length > 0">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Prescription ID</th>
          <th>Doctor</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let prescription of prescriptions">
          <td>{{ prescription.ID }}</td>
          <td>{{ prescription.docName }}</td>
          <td>
            <button class="btn btn-primary" (click)="createDummyOrder(prescription.ID)">Manage</button>
          </td>
        </tr>
      </tbody>
    </table>
</div>

<ng-template #editPrescriptionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Manage Prescription</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="d-flex flex-row">
    <div class="modal-body w-50">
      <h5>With us ...</h5>
      <div class="products" id="dummyOrder" *ngIf="dummyOrderLines.length > 0">
        <div class="product" style="width: 100%; align-self: center;" *ngFor="let item of dummyOrderLines; let i = index">
          <div class="d-flex flex-row justify-content-center">
            <div class="d-flex flex-row w-100">
              <div class="image">
                <img *ngIf="item.type == 'product'" src="assets/images/icons/Dzeus_Frasco_logo.png" style="width: 60px;" alt="bottle.png">
                <img *ngIf="item.type == 'shipping'" src="{{item.imgRef | translation:currentLang}}" style="width: 60px;" alt="bottle.png">
              </div>
              <div class="d-flex flex-column p-info w-100">
                <div class="product-main-details ml-2 w-100">
                  <h5 *ngIf="item.type == 'product'" class="title-prod" style="text-align: start;">{{item.productName | translation:currentLang}}</h5>
                  <h5 *ngIf="item.type == 'shipping'" class="title-prod" style="text-align: start;">{{item.name | translation:currentLang}}</h5>
                  <p *ngIf="item.type == 'product'" class="m-0">{{setPrice(item.productPrice) | currency:'USD'}} - {{item.productQty}} {{'CONSULTATIONS.PILLS' | translate}} {{'CONSULTATIONS.OF' | translate}} {{item.productDosage}}</p>
                  <p *ngIf="item.type == 'shipping'" class="m-0">{{setPrice(item.price) | currency:'USD'}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>*Additional discounts provided at checkout.</p>
      </div>
  
      <div class="order-details-container mt-3 p-4 d-flex justify-content-center align-items-center">
        <div class="row" style="flex-wrap: nowrap;">
          <p class="desc">{{'CHECKOUT.SUBTOTAL' | translate}}</p>
          <p *ngIf="dummyOrder && dummyOrder.subTotal != undefined" class="numbers bold">{{dummyOrder?.subTotal | currency:'USD'}}</p>
        </div>
        <div class="row" style="flex-wrap: nowrap;">
          <p class="desc">{{'CHECKOUT.TAX' | translate}}</p>
          <p *ngIf="dummyOrder && dummyOrder.tax != undefined" class="numbers bold">{{dummyOrder?.tax | currency:'USD'}}</p>
        </div>
        <div class="row" style="flex-wrap: nowrap;">
          <p class="desc">{{'ADDRESS.SHIPPING' | translate}}</p>
          <p *ngIf="dummyOrder && dummyOrder.shipping != undefined" class="numbers bold">{{dummyOrder?.shipping | currency:'USD'}}</p>
        </div>
        <div style="width: inherit;">
          <hr class="mt-0">
        </div>
        <div class="row" style="flex-wrap: nowrap;">
          <h5 class="desc m-0">{{'CHECKOUT.TOTAL' | translate}}</h5>
          <p *ngIf="dummyOrder && dummyOrder.total != undefined" class="numbers bold m-0" style="align-self: self-end;">{{dummyOrder?.total | currency:'USD'}}</p>
        </div>
      </div>
    </div>
    <div class="modal-body w-50">
      <h5>With another pharmacy ...</h5>
      <div class="products" id="prescriptionOrder" *ngIf="prescriptionOrderLines.length > 0">
        <div class="product mb-1" style="width: 100%; align-self: center;" *ngFor="let item of prescriptionOrderLines; let i = index">
          <div class="d-flex flex-row justify-content-center">
            <div class="d-flex flex-row w-100">
              <div class="image">
                <img src="{{item.productImg | translation:currentLang}}" style="width: 60px;" alt="bottle.png">
              </div>
              <div class="d-flex flex-column p-info w-100">
                <div class="product-main-details ml-2 w-100">
                  <h5 class="title-prod" style="text-align: start;">{{item.productName | translation:currentLang}}</h5>
                  <p class="m-0">{{setPrice(item.productPrice) | currency:'USD'}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngFor="let desc of prescriptionOrderLines[0].productDescription">
          <h6 class="title-prod" style="text-align: start;">- {{desc | translation:currentLang}}</h6>
        </div>
      </div>
  
      <div class="order-details-container mt-5 p-4 d-flex justify-content-center align-items-center">
        <div class="row" style="flex-wrap: nowrap;">
          <p class="desc">{{'CHECKOUT.SUBTOTAL' | translate}}</p>
          <p *ngIf="prescriptionOrder && prescriptionOrder.subTotal != undefined" class="numbers bold">{{prescriptionOrder?.subTotal | currency:'USD'}}</p>
        </div>
        <div class="row" style="flex-wrap: nowrap;">
          <p class="desc">{{'CHECKOUT.TAX' | translate}}</p>
          <p *ngIf="prescriptionOrder && prescriptionOrder.tax != undefined" class="numbers bold">{{prescriptionOrder?.tax | currency:'USD'}}</p>
        </div>
        <div class="row" style="flex-wrap: nowrap;">
          <p class="desc">{{'ADDRESS.SHIPPING' | translate}}</p>
          <p *ngIf="prescriptionOrder && prescriptionOrder.shipping != undefined" class="numbers bold">{{prescriptionOrder?.shipping | currency:'USD'}}</p>
        </div>
        <div style="width: inherit;">
          <hr class="mt-0">
        </div>
        <div class="row" style="flex-wrap: nowrap;">
          <h5 class="desc m-0">{{'CHECKOUT.TOTAL' | translate}}</h5>
          <p *ngIf="prescriptionOrder && prescriptionOrder.total != undefined" class="numbers bold m-0" style="align-self: self-end;">{{prescriptionOrder?.total | currency:'USD'}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-around">
    <button type="button" style="width: 330px;" class="btn btn-primary" (click)="modal.dismiss('Cancel click')">Purchase with us</button>
    <button type="button" style="width: 330px;" class="btn btn-cyan" (click)="createExternalPrescriptionOrder()">Continue with external pharmacy</button>
  </div>
</ng-template>
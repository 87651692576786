<div class="container-fluid">  
  <div>
    <div class="w-100 user-chat mt-sm-0" id="chat-rightsidebar" style="border-radius: 4px;">
      <div class="p-3 px-lg-4 user-chat-border fixedPadding">   
        <div class="row">
          <div class="col-6" style="display: flex; flex-direction: row; align-items: center;">
            <!-- <div class="btn-group mb-2 mr-3 mb-sm-0">
                <button type="button" class="btn btn-primary" (click)="hide(false)"><i class="fas fa-angle-left" ngbTooltip="{{'NOTIFICATIONS.BACK' | translate}}"></i></button>
            </div> -->
          <div>
            <h5 class="font-size-15 mb-1 text-truncate">{{username}}</h5>
            <!-- <p class="text-muted text-truncate mb-0"><i class="mdi mdi-circle text-success align-middle mr-1" [ngClass]="{'text-success': status === 'online',
                'text-warning': status === 'intermediate'}"></i>
              {{status}}</p> -->
            </div>
          </div>
          <div class="col-6">
            <ul class="list-inline user-chat-nav text-right mb-0">
              <li class="list-inline-item d-inline-block d-sm-none">
                <div class="dropdown" ngbDropdown>
                  <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" ngbDropdownToggle>
                    <i class="mdi mdi-magnify"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-md" ngbDropdownMenu>
                    <form class="p-2">
                      <div class="search-box">
                        <div class="position-relative">
                          <input id="text" type="text" class="form-control searchbar rounded" placeholder="{{'NOTIFICATIONS.SEARCH' |  translate}}" name="search" [(ngModel)]="searchChat">
                          <i class="mdi mdi-magnify search-icon"></i>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </li>
              <li class="d-none d-sm-inline-block tabletView">
                <div class="search-box mr-2">
                  <div class="position-relative">
                    <input type="text" class="form-control searchbar" placeholder="{{'NOTIFICATIONS.SEARCH' |  translate}}" name="search" [(ngModel)]="searchChat">
                    <i class="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
              </li>
              <!-- <li class="list-inline-item m-0 d-none d-sm-inline-block">
                <div class="dropdown" ngbDropdown>
                  <button class="btn nav-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" ngbDropdownToggle>
                    <i class="mdi mdi-cog"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                    <a class="dropdown-item" (click)="deleteChat()">{{'NOTIFICATIONS.CHAT.DELETE' | translate}}</a>
                  </div>
                </div>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <div class="px-lg-2">
        <div class="chat-conversation p-3 chatView" #scrollMe style="display: flex; overflow-y: auto;" id="messageBody">
          <div style="display: flex; flex-direction: column; width: 100%;">
            <ul class="list-unstyled mb-0 pr-3" *ngFor="let data of pastChats | filter: searchChat"
              [ngClass]="{ 'right': data.align === 'right' }">
              <li *ngIf="!data.text">
                <div class="conversation-list">
                  <div class="chat-avatar">
                    <img class="cropped" src="{{data.image}}" alt="">
                  </div>
                  <div class="ctext-wrap">
                    <div class="conversation-name">{{data.name}}</div>
                    <div class="ctext-wrap-content">
                      <div class="mb-0" [innerHTML]="data.message | translation:currentLang">
                      </div>
                    </div>
                    <p class="chat-time mb-0"><i class="mdi mdi-clock-outline align-middle mr-1"></i> {{data.time}} <i *ngIf="data.align == 'right' || currentUser?.accountType == 'admin'" class="ri-check-double-line" [ngClass]="{'text-success': data.messageRead}" style="color: #cedada; font-size: 20px;"></i></p>
                  </div>
                </div>
              </li>
              <li *ngIf="data.text">
                <div class="chat-day-title">
                  <span class="title">{{data.text}}</span>
                </div>
              </li>
            </ul>
            <ul class="list-unstyled mb-0 pr-3" *ngFor="let data of chatMessagesData | filter: searchChat"
              [ngClass]="{ 'right': data.align === 'right' }">
              <li *ngIf="!data.text">
                <div class="conversation-list">
                  <div class="chat-avatar">
                    <img class="cropped" src="{{data.image}}" alt="">
                  </div>
                  <div class="ctext-wrap">
                    <div class="conversation-name">{{data.name}}</div>
                    <div class="ctext-wrap-content">
                      <div class="mb-0" [innerHTML]="data.message | translation:currentLang">
                      </div>
                    </div>
                    <p class="chat-time mb-0"><i class="mdi mdi-clock-outline align-middle mr-1"></i> {{data.time}} <i *ngIf="data.align == 'right' || currentUser?.accountType == 'admin'" class="ri-check-double-line" [ngClass]="{'text-success': data.messageRead}" style="color: #cedada; font-size: 20px;"></i></p>
                  </div>
                </div>
              </li>
              <li *ngIf="data.text">
                <div class="chat-day-title">
                  <span class="title">{{data.text}}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="showScroll">
        <button class="btn btn-block" style="font-size: 15px !important; margin-top: -32px;position: absolute;" (click)="scrollToBottom()">{{'NOTIFICATIONS.NEWMESSAGE' | translate}} <i class="fas fa-chevron-down"></i></button>
      </div>
      <div class="px-lg-3">
        <div class="p-3 chat-input-section" [ngClass]="{'disableDiv': collaborator}">
          <!-- <form *ngIf="consult[0]?.Status != 'Completed'" [formGroup]="formData" class="row"> -->
          <form [formGroup]="formData" *ngIf="canWrite || consult[0]?.Status != 'Completed'" class="row">
              <div class="col">
              <div class="position-relative">
                <input id="message" type="text" class="form-control chat-input" placeholder="{{'NOTIFICATIONS.CHAT.ENTERMESSAGE' | translate}}"
                  formControlName="message" [ngClass]="{'is-invalid': chatSubmit && form.message.errors}">
                <div *ngIf="chatSubmit && form.message.errors" class="invalid-feedback">
                  <span *ngIf="form.message.errors.required">{{'NOTIFICATIONS.CHAT.VALUE' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <button *ngIf="chatMessagesData.length > 0" type="submit" class="btn btn-dark chat-send toolbarBtn w-md waves-effect waves-light" (click)="messageSave()"><span
              class="d-none d-sm-inline-block mr-2">{{'NOTIFICATIONS.CHAT.SEND' | translate}}</span> <i class="mdi mdi-send"></i></button>
              <button *ngIf="chatMessagesData.length == 0" type="submit" class="btn btn-dark chat-send toolbarBtn w-md waves-effect waves-light" (click)="createNewChat()"><span
                class="d-none d-sm-inline-block mr-2">{{'NOTIFICATIONS.CHAT.SEND' | translate}}</span> <i class="mdi mdi-send"></i></button>
            </div>
          </form>
          <div *ngIf="!canWrite && consult[0]?.Status == 'Completed'" class="col-auto">
            <p>{{'NOTIFICATIONS.CHAT.CONSULTCOMPLETED' | translate}}</p>
          </div> 
        </div>
      </div>
    </div>
  </div>
</div>


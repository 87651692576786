import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase/compat/app';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { remoteConfig, testUsers } from 'src/environments/environment';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {


  getObjectKeys(obj: object): string[] {
    return Object.keys(obj);
  }

  combinedData = [];

  allOrderValuesBasedonCampaign = {};
  allUsersBasedOnCampaign ={};
  constructor(
    private db: AngularFirestore,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private httpClient: HttpClient,
  ) { }

  settings = remoteConfig;
 
  loaded = false;
  loadAges = false;
  orderData: any[] = [];

  consultSteps = [
    { stepId: 'preconsultQuestions'},
    { stepId: 'engagementStep'},
    { stepId: 'gender'},
    { stepId: 'biologicalgender'},
    { stepId: 'chooseState'},
    { stepId: 'birthdate'},
    { stepId: 'chooseLabTests'},
    { stepId: 'chooseLabLocation'},
    { stepId: 'chooseMedication'},
    { stepId: 'nonPreconsultQuestions'},
    { stepId: 'phoneNumber'},
    { stepId: 'choosePractitioner'},
    { stepId: 'chooseService'},
    { stepId: 'profileImage'},
    { stepId: 'checkout'},
    { stepId: 'chooseTime/Type'} 
  ];

  openConsultAnalytics = {
    series: [{data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], name: ''}],
    labels: ['ADMINCONSULTS.QUESTIONS', 'ADMINCONSULTS.ENGAGEMENT', 'ADMINCONSULTS.GENDER', 'ADMINCONSULTS.BIOLOGICALGENDER', 'ADMINCONSULTS.COUNTRY', 'ADMINCONSULTS.BIRTHDATE', 'ADMINCONSULTS.LABS', 'ADMINCONSULTS.LABLOCATION', 'ADMINCONSULTS.SELECTPRODUCT', 'ADMINCONSULTS.QUESTIONS', 'ADMINCONSULTS.PHONE', 'ADMINCONSULTS.CHOOSEPRACTITIONER', 'ADMINCONSULTS.SELECTSERVICE', 'ADMINCONSULTS.IDENTIFICATION', 'ADMINCONSULTS.CHECKOUT', 'ADMINCONSULTS.CHOOSETIME', 'Outdated Consults'],
    colors: ['#dd4031', '#fe840e', '#c72169', '#ffd762', '#765239', '#636249', '#d79c9c', '#9e0f30', '#ff7063', '#8d9440', '#05539e', '#d69c30', '#e7b5ce', '#fde973'],
  };

  allConsultAnalytics = {
    series: [{data: [], name: ''}],
    labels: [],
    status: {},
    colors: ['#0049f4'],
  };


  ReferalInfluencerAnalytics = {
    series: [{ data: [0, 0, 0], name: 'Referral Count' }],
    labels: ['Molusco', 'Jay Fonseca', 'Carlos Ponce'],
    colors: ['#dd4031', '#fe840e', '#c72169'],
  };

  OrderReferralAnalytics = {
    series: [{ data: [0, 0, 0], name: 'Order Count' }],
    labels: ['Molusco', 'Jay Fonseca', 'Carlos Ponce'],
    colors: ['#dd4031', '#fe840e', '#c72169'],
  };

  allConsults = []
  openedConsults = []
  allConsultAddresses = []

  invalidIds = []

  testUsers = testUsers

  allFilteredAnalytics: any;
  pageAnalytics = 1;
  pageSizeAnalytics = 10;
  totalAnalyticsRecords = 0;

  showLoadingAnalytics: boolean = false;

  ngOnInit(): void {
    this.consultTypes();
    // this.consultAnalitics();
    // this.getOrderReports()
    // this.patientsByGender()
    this.getUsers();
    // this.getOrders();
  }

  getDateMinusMonths(months: number): string {
    const date = new Date();
    // Subtract months from the current date
    date.setMonth(date.getMonth() - months);

    // Format the date to YYYY-MM-DD
    return date.toISOString().split('T')[0]; // "YYYY-MM-DD"
  }

  oldConsultsDate: any = new Date('2023-07-18').getTime() / 1000;
  yearlyReport = 'Jan 01,' + new Date().getFullYear() //Cant use it because of different date variables
  getConsults(){
    let promise = new Promise((resolve, reject) => {
      let body = new HttpParams();
      const headers = new HttpHeaders();
      headers.set('Content-Type', 'application/json');
      headers.set('Access-Control-Allow-Origin', '*');

      this.httpClient.post(`https://us-central1-${this.settings.env}.cloudfunctions.net/getConsultsforAdmins`, body, {headers: headers}).subscribe(res => {
        resolve(res);
        // this.subscription.add(sub)
      });
    })

    return promise;
  }

  async consultTypes(){
    await this.getConsults().then(async (res: any) => {
      this.allConsultAnalytics = res.allConsultAnalytics
      this.openConsultAnalytics = res.openConsultAnalytics
      this.allConsults = res.allConsults

      for(let i = 0; i < 16; i++){
        await this.translateService.get(this.openConsultAnalytics.labels[i]).subscribe(ret => {
          this.openConsultAnalytics.labels[i] = ret.toString()
        })
      }

      this.loaded = true;
    })
  }

  filterConsults(timeRange){
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set to midnight (12:00:00 AM)
    let dateFilterStart = timeRange != 'all' ? new Date(today.setDate(today.getDate() - Number(this.startDate))) : new Date(); //timeRange is in days
    let dateFilterEnd = timeRange != 'all' ? new Date(today.setDate(today.getDate() - Number(this.endDate))) : new Date(); //timeRange is in days
    if(this.startDate.includes('T')){
      dateFilterStart = new Date(this.startDate.replace('T', ' '))
    }
    if(this.endDate.includes('T')){
      dateFilterEnd = new Date(this.endDate.replace('T', ' '))
    }
    let bypass = timeRange == 'all' ? true : false

    this.allConsultAnalytics.series[0].data = []
    this.allConsultAnalytics.labels = []
    this.allConsultAnalytics.status = {}

    this.openConsultAnalytics.series = [{data: [], name: ''}]
    this.openConsultAnalytics.series[0].data = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]

    let res = this.allConsults
    console.warn(res)
    for(let j = 0; j < res.length; j++){
      if(bypass || res[j].dateToSort._seconds >= dateFilterStart.getTime() / 1000 && res[j].dateToSort._seconds <= dateFilterEnd.getTime() / 1000){
        let nonAnonymous = res[j].Status == 'Open' && res[j].patientName != '(Anonymous)' ? 1 : 0
  
        let type = res[j].Type.includes('Psychotherapy') ? 'Psychotherapy' : res[j].Type ==  'Anxiety' || res[j].Type == 'Depression' ? 'Mental Health' : res[j].Type
        !this.allConsultAnalytics.status[type] ? (Object.assign(this.allConsultAnalytics.status, {[type]: {[res[j].Status]: 1}})) : this.allConsultAnalytics.status[type][res[j].Status] ? this.allConsultAnalytics.status[type][res[j].Status] += 1 : this.allConsultAnalytics.status[type][res[j].Status] = 1
        this.allConsultAnalytics.labels.includes(type) ? this.allConsultAnalytics.series[0].data[this.allConsultAnalytics.labels.indexOf(type)] += 1 : (this.allConsultAnalytics.labels.push(type), this.allConsultAnalytics.series[0].data.push(1))
  
        if(nonAnonymous == 1){
          this.allConsultAnalytics.status[type]['Open Non Anonymous'] ? this.allConsultAnalytics.status[type]['Open Non Anonymous'] += 1 : this.allConsultAnalytics.status[type]['Open Non Anonymous'] = 1
        }

        if(res[j].Status == 'Open' && res[j].patientName != '(Anonymous)'){
           if(res[j].lastRevision.seconds < this.oldConsultsDate){
            this.openConsultAnalytics.series[0].data[16] ? this.openConsultAnalytics.series[0].data[16] += 1 : this.openConsultAnalytics.series[0].data[16] = 1
          }
          else {
            let index = this.consultSteps.findIndex(step => step.stepId == res[j].currentWizardStep)
            this.openConsultAnalytics.series[0].data[index] ? this.openConsultAnalytics.series[0].data[index] += 1 : this.openConsultAnalytics.series[0].data[index] = 1
          }
        }
      }
    }
  }

  // consultTypes(){
  //   let consultByMonths = [{consults: []}]
  //   this.db.collection('consultation').valueChanges().subscribe(async (res: any) => {
  //     let year2023 = new Date('2023-01-01').getTime() / 1000;
  //     let year2024 = new Date('2024-01-01').getTime() / 1000;
  //     res = res.filter((consult, i) => {

  //       if(!this.testUsers.includes(consult.patientEmail) && consult.Status != 'Draft' && consult.submittedConsultDate && consult.submittedConsultDate.seconds >= year2023 && consult.submittedConsultDate.seconds < year2024){
  //         for(let j = 1; j <= 12; j++){
  //           if(!consultByMonths[j - 1]){
  //             consultByMonths.push({consults: []})
  //           }
  //           consultByMonths[j - 1].consults = res.filter(consult => {
  //             return consult.submittedConsultDate?.seconds >= new Date(j <= 9 ? '0'+j+'-01-2023': j+'-01-2023').getTime() / 1000 && consult.submittedConsultDate.seconds < new Date(j + 1 <= 9 ? '0'+(j+1)+'-01-2023' : j < 12 ? (j+1)+'-01-2023' : '01-01-2024').getTime() / 1000
  //           }).map(filteredItem => ({name: filteredItem.patientName, date: filteredItem.submittedConsultDate?.seconds, email: filteredItem.patientEmail}))
  //         }
  //       }
  //       if(i == res.length - 1){
  //         console.warn(consultByMonths)
  //       }
  //       return !this.testUsers.includes(consult.patientEmail) && consult.Status != 'Draft' && consult.submittedConsultDate && consult.submittedConsultDate.seconds >= year2023 && consult.submittedConsultDate.seconds < year2024})
  //     // res = res.filter(consult => {
  //     //   if(!this.testUsers.includes(consult.patientEmail)){
  //     //     console.warn(consult.patientName)
  //     //   }
  //     //   return !this.testUsers.includes(consult.patientEmail)})
  //     this.allConsultAnalytics.series[0].data = []
  //     this.allConsultAnalytics.labels = []
  //     this.allConsultAnalytics.status = {}

  //     this.invalidIds = []
  //     for(let j = 0; j < res.length; j++){
  //       let nonAnonymous = res[j].Status == 'Open' && res[j].patientName != '(Anonymous)' ? 1 : 0

  //       let type = res[j].Type.includes('Psychotherapy') ? 'Psychotherapy' : res[j].Type ==  'Anxiety' || res[j].Type == 'Depression' ? 'Mental Health' : res[j].Type
  //       !this.allConsultAnalytics.status[type] ? (Object.assign(this.allConsultAnalytics.status, {[type]: {[res[j].Status]: 1}})) : this.allConsultAnalytics.status[type][res[j].Status] ? this.allConsultAnalytics.status[type][res[j].Status] += 1 : this.allConsultAnalytics.status[type][res[j].Status] = 1
  //       this.allConsultAnalytics.labels.includes(type) ? this.allConsultAnalytics.series[0].data[this.allConsultAnalytics.labels.indexOf(type)] += 1 : (this.allConsultAnalytics.labels.push(type), this.allConsultAnalytics.series[0].data.push(1))

  //       if(nonAnonymous == 1){
  //         this.allConsultAnalytics.status[type]['Open Non Anonymous'] ? this.allConsultAnalytics.status[type]['Open Non Anonymous'] += 1 : this.allConsultAnalytics.status[type]['Open Non Anonymous'] = 1
  //       }

  //       if(res[j].exception && res[j].exception.length != 0){
  //         this.invalidIds.push(res[j])
  //       }
  //     }
  //   })
  // }

  checkData(){
    let date = new Date('2024-04-09').getTime() / 1000;
    let consultFilter = this.allConsults.filter(c => c.serviceType == 'Premature Ejaculation' && c.patientName != '(Anonymous)' && c.Status == 'Completed' && c.patientEmail != '' && c.Date.seconds >= date)
    let hasTreatment = 0
    console.warn(consultFilter)
    this.db.firestore.collection("orders").get().then(async orders => {

      for(let i = 0; i < consultFilter.length; i++){
        let consult = consultFilter[i]
        let order = orders.docs.filter(order => order.data().consultationID == consult.ID && order.data().paymentStatus == 'paid')
        if(order.length >= 2){
          hasTreatment += 1
        }
        else {
          let allOrders = orders.docs.filter(order => order.data().consultationID == consult.ID).map(order => order.data())
          console.warn(consult.patientName, allOrders)
        }

        if(i == consultFilter.length - 1){
          console.warn(hasTreatment)
        }
      }

    })
  }

  ageRanges = []
  completedConsults = []
  openConsults = []
  openAndCompletedConsults = []
  ageRangeLabels = ['21-29','30-39','40-49','50-59','60+']
  getPartner = []
  ageRange(){
    // let exceptions = ['(Anonymous)', 'Melanie Vazquez', 'Aramis Martinez', 'Neftis Toro', 'Neftis Cruz Toro']
    // this.db.firestore.collection('consultation').where('patientName', 'not-in', exceptions).get().then(async (res: any) => {
    //   // this.ageRanges = [0,0,0,0,0]

    //   res.docs.forEach(consult => {
      this.db.firestore.collection('consultation').where('patientName', '!=', '(Anonymous)').where('serviceType', '==', 'Erectile Dysfunction').where('Status', 'in', ['Completed', 'Open']).get().then(async (consult: any) => {

        let consults = consult.docs.map(consult => consult.data())
        
        this.db.firestore.collection('users').where('accountType', '==', 'patient').get().then(res => {
          res.forEach(res1 => {
            var date = new Date(res1.data().birthdate);
            var today = new Date();
            var timeDiff = today.getTime() - date.getTime();

            var age1 = Math.floor(timeDiff / (1000 * 3600 * 24)) / 365.25;
            
            if(age1 <=29){
              this.ageRanges[0] = this.ageRanges[0] != undefined ? this.ageRanges[0] + 1 : 1
              let openConsult = consults.filter(consult => consult.Status == 'Open' && consult.UID_Patient == res1.data().uid)
              if(openConsult.length > 0){
                this.openConsults[0] = this.openConsults[0] != undefined ? this.openConsults[0] + 1 : 1
              }

              let completedConsult = consults.filter(consult => consult.Status == 'Completed' && consult.UID_Patient == res1.data().uid)
              if(completedConsult.length > 0){
                this.completedConsults[0] = this.completedConsults[0] != undefined ? this.completedConsults[0] + 1 : 1
              }

              if(openConsult.length > 0 || completedConsult.length > 0){
                this.openAndCompletedConsults[0] = this.openAndCompletedConsults[0] != undefined ? this.openAndCompletedConsults[0] + 1 : 1
                this.getPartner.push({partnerID: res1.data().partnerID, openConsults: openConsult.length, completedConsults: completedConsult.length, openAndCompletedConsults: openConsult.length + completedConsult.length})
              }
            }
            else if(age1 <=39){
              this.ageRanges[1] = this.ageRanges[1] != undefined ? this.ageRanges[1] + 1 : 1

              let openConsult = consults.filter(consult => consult.Status == 'Open' && consult.UID_Patient == res1.data().uid)
              if(openConsult.length > 0){
                this.openConsults[1] = this.openConsults[1] != undefined ? this.openConsults[1] + 1 : 1
              }

              let completedConsult = consults.filter(consult => consult.Status == 'Completed' && consult.UID_Patient == res1.data().uid)
              if(completedConsult.length > 0){
                this.completedConsults[1] = this.completedConsults[1] != undefined ? this.completedConsults[1] + 1 : 1
              }

              if(openConsult.length > 0 || completedConsult.length > 0){
                this.openAndCompletedConsults[1] = this.openAndCompletedConsults[1] != undefined ? this.openAndCompletedConsults[1] + 1 : 1
                this.getPartner.push({partnerID: res1.data().partnerID, openConsults: openConsult.length, completedConsults: completedConsult.length, openAndCompletedConsults: openConsult.length + completedConsult.length})
              }
            }
            else if(age1 <=49){
              this.ageRanges[2] = this.ageRanges[2] != undefined ? this.ageRanges[2] + 1 : 1

              let openConsult = consults.filter(consult => consult.Status == 'Open' && consult.UID_Patient == res1.data().uid)
              if(openConsult.length > 0){
                this.openConsults[2] = this.openConsults[2] != undefined ? this.openConsults[2] + 1 : 1
              }

              let completedConsult = consults.filter(consult => consult.Status == 'Completed' && consult.UID_Patient == res1.data().uid)
              if(completedConsult.length > 0){
                this.completedConsults[2] = this.completedConsults[2] != undefined ? this.completedConsults[2] + 1 : 1
              }

              if(openConsult.length > 0 || completedConsult.length > 0){
                this.openAndCompletedConsults[2] = this.openAndCompletedConsults[2] != undefined ? this.openAndCompletedConsults[2] + 1 : 1
                this.getPartner.push({partnerID: res1.data().partnerID, openConsults: openConsult.length, completedConsults: completedConsult.length, openAndCompletedConsults: openConsult.length + completedConsult.length})
              }
            }
            else if(age1 <=59){
              this.ageRanges[3] = this.ageRanges[3] != undefined ? this.ageRanges[3] + 1 : 1

              let openConsult = consults.filter(consult => consult.Status == 'Open' && consult.UID_Patient == res1.data().uid)
              if(openConsult.length > 0){
                this.openConsults[3] = this.openConsults[3] != undefined ? this.openConsults[3] + 1 : 1
              }

              let completedConsult = consults.filter(consult => consult.Status == 'Completed' && consult.UID_Patient == res1.data().uid)
              if(completedConsult.length > 0){
                this.completedConsults[3] = this.completedConsults[3] != undefined ? this.completedConsults[3] + 1 : 1
              }

              if(openConsult.length > 0 || completedConsult.length > 0){
                this.openAndCompletedConsults[3] = this.openAndCompletedConsults[3] != undefined ? this.openAndCompletedConsults[3] + 1 : 1
                this.getPartner.push({partnerID: res1.data().partnerID, openConsults: openConsult.length, completedConsults: completedConsult.length, openAndCompletedConsults: openConsult.length + completedConsult.length})
              }
            }
            else if(age1 >= 60){
              this.ageRanges[4] = this.ageRanges[4] != undefined ? this.ageRanges[4] + 1 : 1

              let openConsult = consults.filter(consult => consult.Status == 'Open' && consult.UID_Patient == res1.data().uid)
              if(openConsult.length > 0){
                this.openConsults[4] = this.openConsults[4] != undefined ? this.openConsults[4] + 1 : 1
              }

              let completedConsult = consults.filter(consult => consult.Status == 'Completed' && consult.UID_Patient == res1.data().uid)
              if(completedConsult.length > 0){
                this.completedConsults[4] = this.completedConsults[4] != undefined ? this.completedConsults[4] + 1 : 1
              }

              if(openConsult.length > 0 || completedConsult.length > 0){
                this.openAndCompletedConsults[4] = this.openAndCompletedConsults[4] != undefined ? this.openAndCompletedConsults[4] + 1 : 1
                this.getPartner.push({partnerID: res1.data().partnerID, openConsults: openConsult.length, completedConsults: completedConsult.length, openAndCompletedConsults: openConsult.length + completedConsult.length})
              }
            }
            this.loadAges = true;
          })
        })
      })

        // if(consult.data().ID == res.docs[res.docs.length - 1].data().ID){
        //   this.loadAges = true;
        // }
    //   })
    // })
  }

  genders = [{female: 0, male: 0}]
  patientsByGender(){
    this.db.firestore.collection('consultation').where('patientName', '!=', '(Anonymous)').where('Status', 'in', ['Completed', 'In Progress']).get().then(async (consult: any) => {

      let consults = consult.docs.map(consult => consult.data())
      
      this.db.firestore.collection('users').where('accountType', '==', 'patient').get().then(res => {
        res.forEach(res1 => {
          let openConsult = consults.filter(consult => consult.UID_Patient == res1.data().uid)

          if(openConsult.length > 0){
            if(res1.data().biologicalgender == 'Male'){
              this.genders[0].male += 1
            }
            else {
              this.genders[0].female += 1
            }
          }
          this.loadAges = true;
        })
      })
    })
  }


  allOrders = []
  getOrderReports(){
    this.db.firestore.collection('orders').where('paymentStatus', '==', 'paid').get().then(async (res: any) => {
      let total = 0;
      // console.warn(res.docs.filter(order => !this.testUsers.includes(order.data().email) && order.data().orderTotal != '0.0'))
      let filteredDates = {orders: [], total: 0}
      res.docs.filter(order => !this.testUsers.includes(order.data().email)).forEach(order => {
        total += Number(order.data().orderTotal) || 0;
      })

      for(let i = 2022; i <= new Date().getFullYear(); i++){
        filteredDates.orders[i] = {orders: [], total: 0}
        for(let j = 1; j <= 12; j++){
          filteredDates.orders[i].orders[j + ' - ' + new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date('0'+j+'-01-'+i))] = {orders: [], total: 0}
          
          for(let k = 1; k <= 31; k++){
            filteredDates.orders[i].orders[j + ' - ' + new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date('0'+j+'-01-'+i))].orders[k] = {orders: [], total: 0}

            res.docs.filter(order => !this.testUsers.includes(order.data().email) && new Date(order.data().date.toDate()).getFullYear() == i && (new Date(order.data().date.toDate()).getMonth() + 1) == j && new Date(order.data().date.toDate()).getDate() == k).forEach(order => {
              // console.warn(new Date(order.data().date.toDate()).getDate().toString().padStart(2, '0'))
              filteredDates.orders[i].orders[j + ' - ' + new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date('0'+j+'-01-'+i))].orders[k].total += Number(order.data().orderTotal) || 0;
              filteredDates.orders[i].orders[j + ' - ' + new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date('0'+j+'-01-'+i))].total += Number(order.data().orderTotal) || 0;
              filteredDates.orders[i].total += Number(order.data().orderTotal) || 0;
              filteredDates.total += Number(order.data().orderTotal) || 0;
              
              filteredDates.orders[i].orders[j + ' - ' + new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date('0'+j+'-01-'+i))].orders[k].orders.push(order.data())
            })  
          }
        }
      }

      console.warn(filteredDates)
    })
  }

  //Consults
  allUsers = []
  allCompletedUsers = []
  filterUsers = []
  completedUsers = []
  async getUsers() {
    const adminIdToken = await firebase.auth().currentUser.getIdToken();
    let response:any = await this.authenticationService.getRequest(adminIdToken);
    let dateFilter = new Date(this.getDateMinusMonths(18));
    let authData = response.data.users.filter(user => new Date(user.metadata.creationTime) >= dateFilter);

    let profileSnapshot = await this.db.firestore.collection("users").get();
    let users = profileSnapshot.docs.map(u => u.data());
  
    const userProfileMap = new Map(users.map(user => [user.uid, user]));

  // Merge auth data with user profile data using map
    let tempUsers = authData
    .map(auth => {
      const userInfo = userProfileMap.get(auth.uid);
      return userInfo ? { ...auth, ...userInfo } : null; // Return merged object or null
    })
    .filter(user => user !== null); // Filter out null values
  
    this.allUsers = authData;
    this.filterUsers = authData;
    this.allCompletedUsers = tempUsers;
    this.completedUsers = tempUsers;
    this.consultUsers = this.allConsults.filter(consult => tempUsers.filter(user => user.uid == consult.UID_Patient).length > 0)
    // this.updateReferralAnalytics();
  }
  

  date = new Date();
  year = this.date.getFullYear();
  month = this.date.getMonth() + 1; // Months are zero-indexed
  day = this.date.getDate();
  startDate: any = this.getDateMinusMonths(18) + 'T00:00';
  endDate = this.year + '-' + (this.month < 10 ? '0' + this.month : this.month) + '-' + (this.day < 10 ? '0' + this.day : this.day) + 'T' + this.date.getHours().toString().padStart(2, '0') + ':' + this.date.getMinutes().toString().padStart(2, '0');
  consultUsers = [];
   filterAccounts(timeRange){
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set to midnight (12:00:00 AM)
    let dateFilterStart = timeRange != 'all' ? new Date(today.setDate(today.getDate() - Number(this.startDate))) : new Date(); //timeRange is in days
    let dateFilterEnd = timeRange != 'all' ? new Date(today.setDate(today.getDate() - Number(this.endDate))) : new Date(); //timeRange is in days
    let bypass = timeRange == 'all' ? true : false

    if(this.startDate?.includes('T')){
      dateFilterStart = new Date(this.startDate.replace('T', ' '))
    }
    if(this.endDate?.includes('T')){
      dateFilterEnd = new Date(this.endDate.replace('T', ' '))
    }

    this.filterUsers = bypass ? this.allUsers : this.allUsers.filter(o => new Date(o.metadata.creationTime) >= dateFilterStart && new Date(o.metadata.creationTime) <= dateFilterEnd)
    this.completedUsers = bypass ? this.allCompletedUsers : this.allCompletedUsers.filter(o => new Date(o.metadata.creationTime) >= dateFilterStart && new Date(o.metadata.creationTime) <= dateFilterEnd)
    let nonLoggedUsers =  bypass ? this.allCompletedUsers : this.allCompletedUsers.filter(o => new Date(o.metadata.lastSignInTime) >= dateFilterStart && new Date(o.metadata.lastSignInTime) <= dateFilterEnd && new Date(o.metadata.creationTime) >= dateFilterStart && new Date(o.metadata.creationTime) <= dateFilterEnd)
    // this.updateReferralAnalytics();
    console.warn(this.filterUsers, this.completedUsers, nonLoggedUsers)

    this.consultUsers = this.allConsults.filter(consult => this.completedUsers.filter(user => user.uid == consult.UID_Patient).length > 0)   
  }

   getAffiliate = (referredBy: string) => {
    if (!referredBy) {
      return null
    }
    const parts = referredBy.split('-');
    return parts[0]; // Return the first part as affiliate
  };

  updateReferralAnalytics() {
    const moluscoUsersObject = [];
    const jayUsersObject = [];
    const carlosUsersObject = [];
  
    this.filterUsers.forEach(user => {
      const affiliate = this.getAffiliate(user.referredBy);
      if (affiliate === 'Molusco') {
        moluscoUsersObject.push(user);
      } else if (affiliate === 'Jay') {
        jayUsersObject.push(user);
      } else if (affiliate === 'Carlos_Ponce') {
        carlosUsersObject.push(user);
      }
    });
    const moluscoUsersLength = moluscoUsersObject.length;
    const jayUsersLength = jayUsersObject.length;
    const carlosUsersLength = carlosUsersObject.length;

    const allUsersObjects = [...moluscoUsersObject, ...jayUsersObject, ...carlosUsersObject];

    this.allUsersBasedOnCampaign = {};
    allUsersObjects.forEach(user => {
      const { affiliate, campaign } = this.getAffiliateAndCampaign(user.referredBy);

      if (campaign) {
        if (!this.allUsersBasedOnCampaign[affiliate]) {
          this.allUsersBasedOnCampaign[affiliate] = {};
        }
        if (this.allUsersBasedOnCampaign[affiliate][campaign]) {
          this.allUsersBasedOnCampaign[affiliate][campaign]++;
        } else {
          this.allUsersBasedOnCampaign[affiliate][campaign] = 1;
        }
      }
    });


    console.log("Carlos Users: ", carlosUsersLength);
    console.log("Molusco Users: ",moluscoUsersLength);
    console.log("Jay Users: ",jayUsersLength);
    console.log("User counts per campaign: ", this.allUsersBasedOnCampaign);

    this.ReferalInfluencerAnalytics.series = [{data: [], name: ''}];
    this.ReferalInfluencerAnalytics.series[0].data = [moluscoUsersLength, jayUsersLength, carlosUsersLength];


    this.updateCombinedData();
  }

  async getOrders() {
    let orders = await this.db.firestore.collection("orders").get();// where status == paid3
    this.orderData = orders.docs.map(order => order.data());
    this.updateOrderReferralAnalytics(this.orderData);
  }

  getOrdersBasedOnTimeRange(timeRange: string) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let dateFilterStart = timeRange !== 'all' ? new Date(today.setDate(today.getDate() - Number(this.startDate))) : new Date('2022-07-15T00:00');
    let dateFilterEnd = timeRange !== 'all' ? new Date(today.setDate(today.getDate() - Number(this.endDate))) : new Date();

    if (this.startDate?.includes('T')) {
      dateFilterStart = new Date(this.startDate.replace('T', ' '));
    }
    if (this.endDate?.includes('T')) {
      dateFilterEnd = new Date(this.endDate.replace('T', ' '));
    }
    let filteredOrders = this.orderData.filter(order => {
      let orderDate = order.date instanceof firebase.firestore.Timestamp ? order.date.toDate() : new Date(order.date); //arreglar este filtro
      return orderDate >= dateFilterStart && orderDate <= dateFilterEnd;
    });

    this.updateOrderReferralAnalytics(filteredOrders);
  }
  getAffiliateAndCampaign = (referredBy) => {
    if (!referredBy) {
      return { affiliate: null, campaign: null }; // Return null values if referredBy is not defined
    }

    const [affiliate, campaign] = referredBy.split('-');
    return { affiliate, campaign };
  };

  updateOrderReferralAnalytics(orderData) {
    // Initialize arrays for each affiliate's orders
    const moluscoOrdersObject = orderData.filter(order => {
      const affiliate = this.getAffiliate(order.referredBy);
       return affiliate === 'Molusco';
       });
    const jayOrdersObject = orderData.filter(order => this.getAffiliate(order.referredBy) === 'Jay');
    const carlosOrdersObject = orderData.filter(order => this.getAffiliate(order.referredBy) === 'Carlos_Ponce');
  
    // Initialize counts for each affiliate
    const moluscoOrdersLength = moluscoOrdersObject.length;
    const jayOrdersLength = jayOrdersObject.length;
    const carlosOrdersLength = carlosOrdersObject.length;
  
    // Group all affiliate orders together
    const basedOnCampaign = [...moluscoOrdersObject, ...jayOrdersObject, ...carlosOrdersObject];
  
    // Initialize an object to store counts of campaigns
     this.allOrderValuesBasedonCampaign = {};
    basedOnCampaign.forEach(order => {
      const { affiliate, campaign } = this.getAffiliateAndCampaign(order.referredBy);
    if (campaign) {
      if (!this.allOrderValuesBasedonCampaign[affiliate]) {
        this.allOrderValuesBasedonCampaign[affiliate] = {};
      }
      if (this.allOrderValuesBasedonCampaign[affiliate][campaign]) {
        this.allOrderValuesBasedonCampaign[affiliate][campaign]++;
      } else {
        this.allOrderValuesBasedonCampaign[affiliate][campaign] = 1;
      }
      }
    });


    console.log("Carlos Orders: ",carlosOrdersLength); 
    console.log("Molusco Orders: ",moluscoOrdersLength); 
    console.log("Jay Orders: ",jayOrdersLength);
    console.log("Order counts per campaign: ", this.allOrderValuesBasedonCampaign);



    this.OrderReferralAnalytics.series =[{data: [], name: ''}];
     this.OrderReferralAnalytics.series[0].data = [moluscoOrdersLength, jayOrdersLength, carlosOrdersLength];
     this.updateCombinedData();
  }

  updateCombinedData() {
    this.combinedData = [
      {
        influencer: 'Molusco',
        orders: this.OrderReferralAnalytics.series[0].data[0],
        registered: this.ReferalInfluencerAnalytics.series[0].data[0],
        campaignsOrders: this.allOrderValuesBasedonCampaign['Molusco'] || {},
        campaignsUsers: this.allUsersBasedOnCampaign['Molusco'] || {},
      },
      {
        influencer: 'Jay Fonseca',
        orders: this.OrderReferralAnalytics.series[0].data[1],
        registered: this.ReferalInfluencerAnalytics.series[0].data[1],
        campaignsOrders: this.allOrderValuesBasedonCampaign['Jay'] || {},
        campaignsUsers: this.allUsersBasedOnCampaign['Jay'] || {},
      },
      {
        influencer: 'Carlos Ponce', 
        orders: this.OrderReferralAnalytics.series[0].data[2],
        registered: this.ReferalInfluencerAnalytics.series[0].data[2],
        campaignsOrders: this.allOrderValuesBasedonCampaign['Carlos_Ponce'] || {},
        campaignsUsers: this.allOrderValuesBasedonCampaign['Carlos_Ponce'] || {},
      }
    ];
  }


  exportToCSV() {
    const csvRows = [
      ['Influencer', 'Orders', 'Registered Users'],
      ...this.combinedData.map(row => [row.influencer, row.orders, row.registered])
    ];

    const csvContent = csvRows.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'referral_analytics.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  reset(){
    this.startDate = this.getDateMinusMonths(18) + 'T00:00';
    this.date = new Date();
    this.endDate = this.year + '-' + (this.month < 10 ? '0' + this.month : this.month) + '-' + (this.day < 10 ? '0' + this.day : this.day) + 'T' + this.date.getHours().toString().padStart(2, '0') + ':' + this.date.getMinutes().toString().padStart(2, '0');
  }

  evenOdd(index){
    return index % 2 == 0 ? false : true
  }
}
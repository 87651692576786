<div class="main">
  <div class="mt-2">
    <label >{{'CONSULT-PRESCRIPTIONS.MED' | translate}}</label>
    <ng-select [appendTo]="'body'" placeholder="{{'CONSULT-PRESCRIPTIONS.SEARCHPLACEHOLDER' | translate }}"
      [multiple]="false" (keyup)="searchProducts($event.target.value)" (change)="handleSelection($event)" [(ngModel)]="product.productName">
      <ng-option *ngFor="let product of products[1]" value="{{product}}" >{{product}}</ng-option>
    </ng-select>
  </div>
  <div *ngIf="products.length > 0" class="mt-2">
    <label>{{'CONSULT-PRESCRIPTIONS.DOSAGE' | translate}}</label>
    <ng-select [appendTo]="'body'" placeholder="{{'CONSULT-PRESCRIPTIONS.SEARCHPLACEHOLDER' | translate }}"
      [multiple]="false" (change)="handleDosage($event)">
      <ng-option *ngFor="let product of products[2].STRENGTHS_AND_FORMS[productIndex]" value="{{product}}">{{product}}</ng-option>
      {{productIndex}}
    </ng-select>
  </div>
  <div *ngIf="products.length > 0" class="mt-2">
    <label>Tablet Quantity:</label>
    <input class="form-control" type="number" placeholder="Quantity" [(ngModel)]="product.productQty">
  </div>
  <div *ngIf="products.length > 0" class="d-flex flex-column mt-2">
    <label>Refill Quantity: {{product.refillQty}}</label>
    <input class="form-control" placeholder="Refill Qty" (keyup)="setRID($event.target.value)" type="number" min="{{product.minRefillQty}}" max="{{product.refillMainQty}}" step="" value="0" [(ngModel)]="product.refillQty">
  </div>
  <div *ngIf="products.length > 0" class="d-flex flex-column mt-2">
    <label>Refill Interval Days</label>
    <input class="form-control" type="number" placeholder="Refill Interval Days" [(ngModel)]="product.refillIntervalDays">
  </div>
  <div *ngIf="products.length > 0" class="d-flex flex-column mt-2">
    <label>Instructions:</label>
    <textarea class="" name="" id="" cols="10" rows="10" [(ngModel)]="product.rxInstruction"></textarea>
  </div>

  <div class="d-flex mt-2">
    <button class="btn btn-info" (click)="closeAndGeneratePdf()">Close & Generate PDF</button>
    <button class="btn btn-info ml-2" (click)="save()">Save</button>
  </div>
</div>
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})

export class ShopComponent implements OnInit {
  @ViewChild('productDetails', { read: TemplateRef }) detailsModal:TemplateRef<any>;
  @ViewChild("sidebarFilter", { read: TemplateRef }) sidebarFilter:TemplateRef<any>;
  @Input() order;

  searchText:any = '';

  products: any[] = [];
  patientProducts:any[] = [];
  totalProducts = 0;
  user:any;
  currentLang = this.translate.store.currentLang;

  shipping:any;
  changeLoader = false;
  shop;
  checkout = false;
  cart = false;
  orderLinesLoaded = true
  allPaymentMethods = []
  creditCardRef = ''
  @Input() modalRef: any;
  imagesLoaded = true;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private modalService: NgbModal,
    public router: Router
  ) { 
    this.getProducts();
  }

  async ngOnInit(){
    this.authService.userSubscription.subscribe(res => {
      this.user = res})
    
    this.currentLang = this.user.preferedLang;
    await this.getCartProducts();
    this.getAcceptaCards()

    this.afs.collection('users').doc(`${this.user.uid}`).collection("Address", ref => ref.where('AddressType', '==', "Shipping")).valueChanges().subscribe(address => {
      this.shipping = address;
    });
  }

  getCartProducts(){
    if(this.order){
      this.afs.collection('consultation').doc(this.order.consultationID).collection('Items').valueChanges().subscribe(res => {
        this.patientProducts = res.filter(product => product.type != 'service')
        this.shop = this.order

        let orderTotal = 0
        this.totalProducts = 0
        for(let i of this.patientProducts){
          for(let j = 0; j < i.qty; j++){
            orderTotal += Number(i.price)
          }
          this.products.filter(p => p.id !== i.id)
          this.totalProducts += i.qty
        }
      })
    }
    else {
      this.afs.collection('shop').doc(this.user.uid).valueChanges().subscribe(res => {
        if(res) {
          this.shop = res
        }else {
          this.afs.collection('shop').doc(this.user.uid).set({
          uid: this.user.uid,
          id: this.user.uid,
          subtotal: 0,
          total: 0,
          tax: 0
          })
        }
      })

      this.afs.collection('shop').doc(this.user.uid).collection('products').valueChanges().subscribe(res => {
      if(res){
        this.patientProducts = res
        let orderTotal = 0
        this.totalProducts = 0
        for(let i of this.patientProducts){
          for(let j = 0; j < i.qty; j++){
            orderTotal += Number(i.price)
          }
          this.products.filter(p => p.id !== i.id)
          this.totalProducts += i.qty
        }

        this.afs.collection('shop').doc(this.user.uid).update({subtotal: orderTotal, total: orderTotal})
      }
      })
    }
  }

  getProducts(){
    this.afs.collection('products', ref => ref.where('productGroup', '==', 0)).valueChanges().subscribe((products:any) => {
      let test = products.filter(product => product.type === 'product');
      this.products = test
    })
  }

  async manageProduct(product){
    let searchProduct = this.patientProducts.filter(p => p.id === product.id)
    if(searchProduct.length > 0){
      product.qty = Number(searchProduct[0].qty) + 1 
    }
    else {
      product = Object.assign(product, {qty: Number(1)})
    }
    // this.patientProducts.push(product);

    if(this.order){
      this.afs.collection('consultation').doc(this.order.consultationID).collection('Items').doc(product.id.toString()).set(product)
    }
    else {
      this.afs.collection('shop').doc(this.user.uid).collection('products').doc(product.id.toString()).set(product)
    }
  }


  removeProduct(product){
    this.patientProducts = this.patientProducts.filter(p => p.id !== product.id); 
    this.afs.collection('shop').doc(this.user.uid).collection('products').doc(product.id.toString()).delete()        
  }
      
  handleSection(){
    let coll = document.getElementsByClassName("collapsible");
    let i;
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.display === "block") {
          content.style.display = "none";
        } else {
          content.style.display = "block";
        }
      });
    }
  }

  updateProduct(product, qty){
    this.afs.collection('shop').doc(this.user.uid).collection('products').doc(product.id.toString()).update({qty: Number(qty)})
  }

  async getAcceptaCards(){
    this.afs.firestore.collection("users").doc(this.user.uid).collection("wallet").get().then(async (data:any) => {
      this.allPaymentMethods = [];
      if(data.docs.length > 0){
        for(let i = 0; i < data.docs.length; i++){

          let today = new Date();
          let exp = new Date(data.docs[i].data().expDate.split("/")[1], data.docs[i].data().expDate.split("/")[0]);
          if(!this.allPaymentMethods.includes(data.docs[i].data())){
            if(exp.getTime() > today.getTime()){
              this.allPaymentMethods.push(data.docs[i].data());
            }
          }
        }
      }
      if(this.allPaymentMethods.length != 0){
        this.creditCardRef = await this.allPaymentMethods[0].brand + "-" + this.allPaymentMethods[0].last4 + " Expires " + this.allPaymentMethods[0].expDate;
        this.onItemChange(this.allPaymentMethods[0]);
      }

    });
  }

  onItemChange(item){  
    let data = {
      creditCardRef: "",
      acceptaID: "",
    };
    if(item !== undefined){
      data.creditCardRef = item.brand + "-" + item.last4 + " Expires " + item.expDate;
      data.acceptaID = item.customerNumber;
    }
    // this.afs.collection("consultation").doc(this.consult.ID).update(data);  
  }

  showModalAddCard(modal: any){
    this.modalRef = this.modalService.open(modal, 
      {
        size: 'md',
        centered: true,
        backdrop: 'static',
        windowClass: 'addCard'
      }
    );
  }

  dismiss(){
    this.modalRef.dismiss();
  }

  setCheckout(){
    this.checkout = true;
    this.cart = false;
  }
}
import {  ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiModule } from '../shared/ui/ui.module';
import { WidgetModule } from '../shared/widget/widget.module';

import { NgbNavModule, NgbDropdownModule, NgbTooltipModule, NgbPopoverModule, NgbPaginationModule, NgbCollapseModule, NgbTypeaheadModule, NgbAlertModule, NgbAccordionModule, NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgSelectModule } from '@ng-select/ng-select';
import {NgxPaginationModule} from 'ngx-pagination';


import { ArchwizardModule } from 'angular-archwizard';
// import {   } from 'ng5-slider';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

// import { MomentTimezonePickerModule } from 'moment-timezone-picker';

//Components
import { AccountsComponent } from './accounts/accounts.component';
import { ChatComponent } from './chat/chat.component';
import { HomeComponent } from './home/home.component';
import { TelehealthComponent } from './telehealth/telehealth.component';
import { ConsultationsComponent } from './consultations/consultations.component';
import { PractitionerConsultsComponent } from './practitioner-consults/practitioner-consults.component';


import { PagesRoutingModule } from './pages-routing.module';
import { AddressComponent } from './address/address.component';
import { AddAddressComponent } from './components/add-address/add-address.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { AddPaymentComponent } from './components/add-payment/add-payment.component';
import { WalkthroughmodalComponent } from './components/walkthroughmodal/walkthroughmodal.component';
import { OrdersComponent } from './orders/orders.component';
import { NotificationsComponent } from './notifications/notifications.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

import { ViewconsultComponent } from './components/viewconsult/viewconsult.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FaqsComponent } from './faqs/faqs.component';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ConsultmessagesComponent } from './components/viewconsult/consultmessages/consultmessages.component';
import { ConsultPrescriptionsComponent } from './components/consult-prescriptions/consult-prescriptions.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { AddEventComponent } from './components/add-event/add-event.component';
import { EmailTemplatesComponent } from './components/email-templates/email-templates.component';

import { TranslationPipe } from '../translation.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '../core/services/language.service';
import { AdminAccountsComponent } from './components/admin-accounts/admin-accounts.component';
import { ConsultchatComponent } from './components/viewconsult/consultchat/consultchat.component'
import { ConsultQuestionsComponent } from "./consult-questions/consult-questions.component";
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ErrohandlerComponent } from './components/errorhandler/errorhandler.component';
import { ErrorhandlerService } from '../core/services/errorhandler.service';
import { ShopComponent } from './shop/shop.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PdfDownloadComponent } from './pdf-download/pdf-download.component';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { AuthenticatorComponent } from './authenticator/authenticator.component';
import { ExtrapagesModule } from '../extrapages/extrapages.module';
import { AgreementsComponent } from './components/agreements/agreements.component';
import { SignaturepadComponent } from './components/signaturepad/signaturepad.component';
import { ReevaluationComponent } from './components/reevaluation/reevaluation.component';
import { PaymentComponent } from './payment/payment.component';
import { ThirdpartycookiesComponent } from './components/thirdpartycookies/thirdpartycookies.component';
import { PaymentCardService } from './components/add-payment/service/payment-card.service'
import { PaymentCardNumberPipe } from './components/add-payment/pipe/payment-card-number/payment-card-number.pipe';
import { ValidThruPipe } from './components/add-payment/pipe/valid-thru/valid.thru.pipe';
import { RefillsComponent } from './components/refills/refills.component';
import { PdfRejectedComponent } from './pdf-rejected/pdf-rejected.component';
import { FollowUpComponent } from './components/follow-up/follow-up.component';
import { BodyAnatomyComponent } from './components/body-anatomy/body-anatomy.component';
import { ReferralComponent } from './components/referral/referral.component';
import { ImageCropperModule } from 'ngx-image-cropper'
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { AdminConsultsComponent } from './components/admin-consults/admin-consults.component';
import { PharmacyConsultOrderComponent } from './components/pharmacy-consult-order/pharmacy-consult-order.component';
import { OrderChangeProductComponent } from './components/order-change-product/order-change-product.component';
import { AuthUsersDocComponent } from './components/auth-users-doc/auth-users-doc.component';
import { ChangeShippingComponent } from './components/change-shipping/change-shipping.component';
import { ConsultationDetailsComponent } from './components/consultation-details/consultation-details.component';
import { PrescriptionListComponent } from './components/prescription-list/prescription-list.component';
import { AppointmentsSchedulerComponent } from './components/appointments-scheduler/appointments-scheduler.component';
import { MigrationComponent } from './components/migration/migration.component'
import { ConsultationQuestionsComponent } from './consultation-questions/consultation-questions.component'
import { BackupComponent } from './components/backup/backup.component';
import { PractitionerScheduleComponent } from './components/practitioner-schedule/practitioner-schedule.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { PrescriptionsAndRefillsComponent } from './components/prescriptions-and-refills/prescriptions-and-refills.component'
import { CustomCameraComponent } from './components/custom-camera/custom-camera.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { ChooseProductComponent } from './choose-product/choose-product.component'
import { ChooseProductCopyComponent } from './choose-product-copy/choose-product-copy.component'
import { ARestructureComponentComponent } from '../a-restructure-component/a-restructure-component.component';
import { RefillManagerComponent } from './components/refill-manager/refill-manager.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { MedicalHistoryComponent } from './components/medical-history/medical-history.component';
import { RemotePatientOnboardComponent } from './components/remote-patient-onboard/remote-patient-onboard.component';
import { PhoneVerificationComponent } from './phone-verification/phone-verification.component';
import { BillingComponent } from './components/billing/billing.component';
import { DateFilterPipe } from '../core/pipes/date-filter.pipe';
import { AdminLaboratoriesComponent } from './components/admin-laboratories/admin-laboratories.component';
import { UploadComponent } from './components/upload/upload.component';
import { LabsAndDiagnosticsComponent } from './components/labs-and-diagnostics/labs-and-diagnostics.component';
import { LabsAndDiagnosticsEditpanelComponent } from './components/labs-and-diagnostics-editpanel/labs-and-diagnostics-editpanel.component';
import { LabsPickerComponent } from './components/labs-picker/labs-picker.component';
import { DocumentManagerComponent } from './components/document-manager/document-manager.component';
import { LabsManagerComponent } from './components/labs-manager/labs-manager.component';
import { FamilyMedicalHistoryComponent } from './components/family-medical-history/family-medical-history.component';
import { LaboratoriesDashboardComponent } from './components/laboratories-dashboard/laboratories-dashboard.component';
import { DiagnosticsComponent } from './components/diagnostics/diagnostics.component';
import { ChangeProductComponent } from './components/change-product/change-product.component';
import { RefillBeforeReasonsComponent } from './components/refill-before-reasons/refill-before-reasons.component';
import { MedicalInsuranceComponent } from './components/medical-insurance/medical-insurance.component';
import { ExternalPatientFormComponent } from './components/external-patient-form/external-patient-form.component';
import { PharmacyPrescriptionsComponent } from './pharmacy-prescriptions/pharmacy-prescriptions.component';
import { ExternalPrescriptionComponent } from './components/external-prescription/external-prescription.component';
import { ManualPrescriptionComponent } from './components/manual-prescription/manual-prescription.component';
import { EngagementStepComponent } from './components/engagement-step/engagement-step.component';
import { BirthdateComponent } from './components/sign-up-form-components/birthdate/birthdate.component';
import { SocialGenderComponent } from './components/sign-up-form-components/social-gender/social-gender.component';
import { BiologicalGenderComponent } from './components/sign-up-form-components/biological-gender/biological-gender.component';
import { PhoneNumberComponent } from './components/sign-up-form-components/phone-number/phone-number.component';
import { SurveyComponent } from './survey/survey.component';
import { SurveyHandlerComponent } from './survey-handler/survey-handler.component';
import { AdminSurveysComponent } from './components/admin-surveys/admin-surveys.component';
import { AlliedPharmaciesComponent } from './components/allied-pharmacies/allied-pharmacies.component';
import { PharmacyDetailsComponent } from './components/pharmacy-details/pharmacy-details.component';
import { NoRightClickDirective } from './components/pharmacy-details/disable-right-click.directive';
import { ChooseLocationComponent } from './components/choose-location/choose-location.component';
import { PreferedShippingMethodComponent } from './prefered-shipping-method/prefered-shipping-method.component';
import { PrescriptionManagerComponent } from './prescription-manager/prescription-manager.component';
import { MelaniesCommandCenterComponent } from './melanies-command-center/melanies-command-center.component';
import { OrdersReportComponent } from './orders-report/orders-report.component';
import { AffiliateProgramComponent } from './affiliate-program/affiliate-program.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { PricesComponent } from './components/prices/prices.component';
import { ExitModalComponent } from './components/exit-modal/exit-modal.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;



// FullCalendarModule([ // register FullCalendar plugins
//   interactionPlugin,
//   listPlugin,
//   timeGrigPlugin,
//   bootstrapPlugin,
//   dayGridPlugin
// ]);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.3
};



@NgModule({
  declarations: [

    HomeComponent, 
    AccountsComponent, 
    ChatComponent, 
    TelehealthComponent, 
    ConsultationsComponent, 
    PractitionerConsultsComponent, 
    AddressComponent, 
    AddAddressComponent, 
    AddAddressComponent, 
    CheckoutComponent, 
    AddPaymentComponent, 
    WalkthroughmodalComponent, 
    OrdersComponent,
    NotificationsComponent, 
    ViewconsultComponent, 
    FaqsComponent, 
    ConsultmessagesComponent, 
    ConsultPrescriptionsComponent, 
    TelehealthComponent, 
    AddEventComponent,
    EmailTemplatesComponent,
    TranslationPipe,
    DateFilterPipe,
    AdminAccountsComponent,
    ConsultchatComponent,
    ConsultQuestionsComponent,
    ErrohandlerComponent,
    ShopComponent,
    TermsOfUseComponent,
    PdfDownloadComponent,
    OrderDetailsComponent,
    AuthenticatorComponent,
    AgreementsComponent,
    SignaturepadComponent,
    ReevaluationComponent,
    PaymentComponent,
    ThirdpartycookiesComponent,
    PaymentCardNumberPipe,
    ValidThruPipe,
    RefillsComponent,
    PdfRejectedComponent,
    FollowUpComponent,
    BodyAnatomyComponent,
    ReferralComponent,
    AdminConsultsComponent,
    PharmacyConsultOrderComponent,
    OrderChangeProductComponent,
    AuthUsersDocComponent,
    ChangeShippingComponent,
    ConsultationDetailsComponent,
    PrescriptionListComponent,
    AppointmentsSchedulerComponent,
    MigrationComponent,
    ConsultationQuestionsComponent,
    BackupComponent,
    PractitionerScheduleComponent,
    SubscriptionsComponent,
    PrescriptionsAndRefillsComponent,
    CustomCameraComponent,
    AnalyticsComponent,
    ChooseProductComponent,
    ChooseProductCopyComponent,
    ARestructureComponentComponent,
    RefillManagerComponent,
    ContactusComponent,
    MedicalHistoryComponent,
    RemotePatientOnboardComponent,
    PhoneVerificationComponent,
    BillingComponent,
    AdminLaboratoriesComponent,
    UploadComponent,
    LabsAndDiagnosticsComponent,
    LabsAndDiagnosticsEditpanelComponent,
    LabsPickerComponent,
    DocumentManagerComponent,
    LabsManagerComponent,
    FamilyMedicalHistoryComponent,
    LaboratoriesDashboardComponent,
    DiagnosticsComponent,
    ChangeProductComponent,
    RefillBeforeReasonsComponent,
    MedicalInsuranceComponent,
    ExternalPatientFormComponent,
    PharmacyPrescriptionsComponent,
    ExternalPrescriptionComponent,
    ManualPrescriptionComponent,
    EngagementStepComponent,
    BirthdateComponent,
    SocialGenderComponent,
    BiologicalGenderComponent,
    PhoneNumberComponent,
    SurveyComponent,
    SurveyHandlerComponent,
    AdminSurveysComponent,
    AlliedPharmaciesComponent,
    PharmacyDetailsComponent,
    NoRightClickDirective,
    ChooseLocationComponent,
    PreferedShippingMethodComponent,
    PrescriptionManagerComponent,
    MelaniesCommandCenterComponent,
    OrdersReportComponent,
    AffiliateProgramComponent,
    PromotionsComponent,
    PricesComponent,
    ExitModalComponent,
    ErrorModalComponent,
   ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    WidgetModule,
    PerfectScrollbarModule,
    LeafletModule,
    // RecaptchaV3Module,
    FullCalendarModule,
    NgApexchartsModule,
    NgbAlertModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbPopoverModule,
    AutocompleteLibModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgSelectModule,
    ArchwizardModule,
    Ng2SearchPipeModule,
    // Ng5SliderModule,
    DropzoneModule,
    NgxExtendedPdfViewerModule,
    CKEditorModule,
    NgbAccordionModule,
    NgbCarouselModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.cubeGrid,
      backdropBorderRadius: '3px',
      fullScreenBackdrop: true
    }),
    TranslateModule,
    // MomentTimezonePickerModule,
    NgxMaskModule.forRoot(options),
    ExtrapagesModule,
    ImageCropperModule,
    NgxPhotoEditorModule,
    NgxPaginationModule
//calendar imports for add event btn
    
    // MatDatepickerModule,     
    // NgxMatDatetimePickerModule, 
    // NgxMatMomentModule


  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LdEUlIaAAAAAIsT7miQWDwE6MY7IGG1-DOWi4lT" },
    { provide: LanguageService},
    TranslationPipe,
    TermsOfUseComponent, 
    AuthenticatorComponent,
    PaymentCardService,
    ConsultationsComponent,
    DateFilterPipe,
    DatePipe
    // {
    //   provide: ErrorHandler,
    //   useClass: ErrorhandlerService
    // }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
],
exports: [ 
  TranslationPipe, 
  TermsOfUseComponent, 
  AuthenticatorComponent, 
  ConsultationQuestionsComponent, 
  ChooseProductComponent, 
  ChooseProductCopyComponent, 
  ContactusComponent, 
  PhoneVerificationComponent, 
  TranslateModule, 
  DateFilterPipe, 
  LabsPickerComponent,
  BirthdateComponent,
  SocialGenderComponent,
  BiologicalGenderComponent,
  PhoneNumberComponent,
  EngagementStepComponent,
  SurveyComponent,
  ChooseLocationComponent
]
})
export class PagesModule { }

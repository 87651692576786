import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { fbRemoteConfig } from 'src/app/authUtils';
import { EmailsService } from 'src/app/core/services/emails.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-laboratories',
  templateUrl: './admin-laboratories.component.html',
  styleUrls: ['./admin-laboratories.component.scss']
})
export class AdminLaboratoriesComponent implements OnInit {
  @ViewChild('internalPrescription', { read: TemplateRef }) internalPrescription:TemplateRef<any>;

  allPrescriptions: any[] = [];
  searchText

  translateStatus = ["ADMINLABS.ALLLABS", "ADMINLABS.SENT", "ADMINLABS.NOTSENT"];
  prescriptionStatus = ["All", true, false];
  filterConsults: any = "All"

  labLogo:string = "assets/images/lab.png";
  prescriptionRef: any;
  base64: any;
  currentLang = this.translate.store.currentLang;
  showPreview = false;

  loadingLabs = false;
  sending = false;
  sentPrescriptionSuccess = false;
  patient: any;

  labLocationsList: any[] = [];
  consult: any;
  settings = fbRemoteConfig.defaultConfig;

  constructor(
    private afs: AngularFirestore,
    public modalService: NgbModal,
    private translate: TranslateService,
    private emailService: EmailsService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.getLaboratories();
    this.getLabLocations();
  }

  getLaboratories() {
    this.afs.collection('prescription', ref => ref.where('prescription_type', '==', 'Lab').where('valid', '==', true).where('status', '==', 'Signed')).valueChanges().subscribe((res) => {
      this.allPrescriptions = res
    })
  }

  getLabLocations(){
    this.afs.collection('labLocationTemplates').valueChanges().subscribe((res) => {
      this.labLocationsList = res;
    })
  }

  async openPrescriptionPDF(prescription) {
    this.prescriptionRef = prescription;
    this.afs.collection('consultation').doc(prescription.consultID).get().subscribe((res) => {
      this.consult = res.data();
    })
    this.base64 = prescription.z_processedB64 ? prescription.z_processedB64 : prescription.z_base64
    this.modalService.open(this.internalPrescription, {size: 'xl', backdrop: 'static', centered: true, windowClass: 'internal-prescription', keyboard: false});
  }  

  labLocations: any = [];
  async searchLabLocations($event){
    this.loadingLabs = true;
    // for(let j = '00601'; j != '00989'; j = '00' + (Number(j) + 1).toString()){
    //   await this.http.get<any>("https://clinicaltables.nlm.nih.gov/api/npi_org/v3/search?sf=name.full,addr_practice.zip,provider_type&df=name.full,NPI,provider_type,addr_practice.full,addr_practice.phone&ef=NPI,name.full,provider_type,addr_practice.full,addr_practice.phone&terms="+j+'&maxList=500').subscribe(async response =>{
    //     // this.labLocations = [];
    //     for(let i = 0; i < response[3].length; i++){
    //       if(response[3][i][2] == "Clinical Laboratory"){
    //         await this.labLocations.push({'name.full':response[3][i][0], 'addr_practice.full':response[3][i][3], 'addr_practice.phone':response[3][i][4], 'NPI':response[3][i][1], 'provider_type':response[3][i][2], id: (this.labLocations.length + 1).toString(), email: ''});
    //         await this.afs.collection('labLocationTemplates').doc((this.labLocations.length).toString()).set(this.labLocations[this.labLocations.length - 1])
    //       }
    //     }
    //   });
    //   if(j == '00988'){
    //     this.loadingLabs = false;
    //   }
    // }
    this.labLocations = [];
    setTimeout(() => {
      if(typeof($event.target.value) == 'string'){
        this.labLocations = this.labLocationsList.filter(lab => lab['name.full'].toLowerCase().includes($event.target.value.toLowerCase()) || 
                                                                lab['addr_practice.full'].toLowerCase().includes($event.target.value.toLowerCase()) ||
                                                                lab['addr_practice.phone'].toLowerCase().includes($event.target.value.toLowerCase()) || 
                                                                lab['NPI'].toLowerCase().includes($event.target.value.toLowerCase()) || 
                                                                lab['email'].toLowerCase().includes($event.target.value.toLowerCase()));
        this.loadingLabs = false;
      }
    },50) //allows labLocations to turn into an empty array before filtering 
  }

  selectedLaboratory: any = {};
  selectLab(event){
    this.selectedLaboratory = event;
  }

  labToManage: any = {};
  selectLabToManage(event){
    this.labToManage = event;
  }

  manageLabLocations(event){
    this.modalService.open(event, {size: 'xl', backdrop: 'static', centered: true, windowClass: 'internal-prescription', keyboard: false});
  }

  saveLab(){
    this.afs.collection('labLocationTemplates').doc(this.labToManage.id).update(this.labToManage);
  }

  sendLaboratory(){
    if(this.selectedLaboratory.email != ''){
      this.afs.firestore.collection('users').doc(this.prescriptionRef.uid).get().then(async (res) => {
        this.patient = res.data();
        await this.emailService.externalEncryptedEmail(this.selectedLaboratory, await this.patient, this.prescriptionRef);
        this.afs.collection('prescription').doc(this.prescriptionRef.ID).update({sentLab: true, laboratoryDetails: this.selectedLaboratory})
        let user = {
          displayName: 'Dzeus Support',
          email: 'smtp@dzeus.com',
          uid: ''
        }
        this.emailService.patientConsultEmails(Object.assign(user), this.patient, this.consult, 'labSubmission_' + this.patient.preferedLang, '<en>Lab Test</en><es>Prueba de Laboratorio</es>', this.settings, '/consultations?consultation=' + this.consult.ID)
        this.sending = false;
        setTimeout(() => {
          this.sentPrescriptionSuccess = true;
        }, 2500)
      })
    }
    else {
      Swal.fire({
        icon: 'error',
        title: this.translate.instant('ADMINLABS.NOEMAIL'),
        showConfirmButton: true,
        confirmButtonText: this.translate.instant('ADMINLABS.OK')
      })
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { fbRemoteConfig } from '../../authUtils'

import { TranslationPipe } from 'src/app/translation.pipe';
import { EmailsService } from 'src/app/core/services/emails.service';
import { takeUntil } from 'rxjs/operators';
import { ConsultHistory } from 'src/app/core/interfaces/history';
import { MEDICAL_CONSULTATION } from 'src/app/core/enums/process-history';
import { HistoryService } from 'src/app/core/services/history/history.service';
import { testUsers } from 'src/environments/environment';
import { DbCollectionTriggersService } from 'src/app/core/services/db-collection-triggers.service';

@Component({
  selector: 'app-practitioner-consults',
  templateUrl: './practitioner-consults.component.html',
  styleUrls: ['./practitioner-consults.component.scss']
})
export class PractitionerConsultsComponent implements OnInit {  
  currentUser:any;
  pagetitle = "PRACTITIONER-CONSULTS.BREADCRUMBS"
  paidConsults:any = [];
  inProgressConsults: any = [];
  followUpConsults: any = []
  completedConsults: any = [];
  searchText:any = '';
  
  currentLang = this.translate.store.currentLang

  translationPipe = new TranslationPipe()
  ngOnDestroy$:Subject<boolean> = new Subject();
  //For Swal Modal
  title
  text

  collaborativeConsults:any[] = [];
  hide = false;

  showConsults = 'followUpConsults'
  pageConsults = 1;

  constructor(
    private afs: AngularFirestore,
    public firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
    public activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private authService: AuthenticationService,
    private emailService: EmailsService,
    private historyService: HistoryService,
    private updateConsultationErp: DbCollectionTriggersService
  ) {
      this.hide = screen.width < 1200 || (window.matchMedia("(orientation: landscape-primary)") && screen.height < 800 && screen.height > 768) ? true : false;
   }

  ngOnInit(){
    this.authService.userSubscription.subscribe(res => {
      this.currentUser = res;
    })
    if(this.currentUser.accountType == 'practitioner'){
      this.getConsultations();
    }
  }

  onlyUnique(item, variable){
    if(!this[variable].some((i) => i.ID == item.ID)){
      this[variable].push(item);
    }
  }

  sendMail(consult:any, patientProfile:any){
    let settings:any = fbRemoteConfig.defaultConfig;

    let abbr = this.translationPipe.transform(this.currentUser.abbr, consult.consultLanguage);
    if(consult.consultLanguage == 'en'){
      this.currentUser.transformedAbbr = abbr;
    }
    else {
      this.currentUser.transformedAbbr = this.currentUser.biologicalgender == 'Female' ? abbr.split('/la ')[1] : abbr.split('el ')[1].split('/')[0]
    }

    if(!this.currentUser.transformedAbbr){
      this.currentUser.transformedAbbr = 'Dr.'
    }
    
    let typeConditions = [
      'Audiocall', 'Llamada',
      // 'Any type of contact method', 'Cualquier método de contacto',
      // '<en>Any type of contact method</en><es>Cualquier método de contacto</es>',
      '<en>Audiocall</en><es>Llamada</es>'
    ]
    this.emailService.patientConsultEmails(this.currentUser = Object.assign(this.currentUser, {displayName: this.currentUser.transformedAbbr + " " + this.currentUser.lastName1}), patientProfile, consult, 'consultReview_' + patientProfile.preferedLang, '<en>Consult Review</en><es>Revisión de Consulta</es>', settings, '', '', '', '', '', '', typeConditions.some(el => consult.schedule?.type.includes(el)))
  }

  async assignConsults(consult:any){
    let patient: any;
    this.afs.firestore.collection('users').doc(consult.UID_Patient).get().then(patientProifle => {
      patient = patientProifle.data();
      this.afs.firestore.collection('users').doc(consult.UID_Patient).collection('myPractitioners').get().then(async practitioners => {
        let patientsPractitioners = practitioners.docs.map(p => p.data()).filter(p => p.uid == this.currentUser.uid);

        if(patientsPractitioners.length == 0){
          let newPractitioner = {
            id:(practitioners.docs.length + 1).toString(),
            uid: this.currentUser.uid
          }
          this.afs.collection('users').doc(consult.UID_Patient).collection('myPractitioners').doc(newPractitioner.id).set(newPractitioner)
        }      
      })

      if(this.currentUser.accountType == 'practitioner' && this.currentUser.status == 'Verified'){               
        const data = {
          UID_Practitioner: this.currentUser.uid, 
          practitionerForConsult: this.currentUser.uid,
          Status: "In Progress",
          practitionerName: this.currentUser.firstName + " " + this.currentUser.lastName1,
          dateToSort: new Date(Date.now())
        }
        
  
        if(consult.Status === "Paid" || consult.practitionerForConsult === this.currentUser.uid){
          let history:ConsultHistory = {
            date: new Date(Date.now()),
            id: this.firebaseService.getDate(),
            userUID: this.currentUser.uid,
            user: this.currentUser.firstName + ' ' + this.currentUser.lastName1,
            userAccountType: this.currentUser.accountType,
            action: MEDICAL_CONSULTATION.AQURIED,
            consultID: consult.ID,
            consultStatus: consult.Status,
            createdBy: this.currentUser.uid,
          }
  
          //newest possible versions
          // this.historyService.storeHistory(history);      ----  its own collection
          this.historyService.storeHistoryInConsultation(history); // ----  subcollection in consultation
          // this.afs.collection('consultation').doc(consult.ID).collection('History').doc(history.id).set(history)
          this.firebaseService.updateConsult(consult.ID, data).then(
            async res => {
              if(res){
                this.updateConsultationErp.updateConsultationCollection({consultationId: consult.ID, trigger: 'handleConsultationErp'})
                this.sendMail(consult, patient);
              }
              this.translate.get('PRACTITIONER-CONSULTS.SWAL').subscribe((res: any) => {
                this.title = res.TITLE
              })
  
              Swal.fire({
                title: this.title,
                timer:2000,
                timerProgressBar: true,
                position: "top",
                toast: true,
                showConfirmButton: false,
                background: '#5cb85c'
              })
  
              if(!this.currentUser.transformedAbbr){
                let abbr = await this.translationPipe.transform(this.currentUser.abbr, consult.consultLanguage);
                if(consult.consultLanguage == 'es'){
                  if(this.currentUser.biologicalgender == 'Female'){
                    this.currentUser.transformedAbbr = await abbr.split('/la ')[1]
                  }
                  else {
                    this.currentUser.transformedAbbr = await abbr.split('el ')[1].split('/')[0]
                  }
                }
                else {
                  this.currentUser.transformedAbbr = abbr
                }
              }
  
              if(!this.currentUser.transformedAbbr){
                this.currentUser.transformedAbbr = 'Dr.'
              }

              this.handleChat(consult, patient)
            },
            err => {
              // console.log(err);
            }
          );
        }
        else{
          this.translate.get('PRACTITIONER-CONSULTS.SWAL').subscribe((res: any) => {
            this.title = res.ERRORTITLE,
            this.text = res.TEXT
          })
          Swal.fire({
            icon: 'error',
            title: this.title,
            text: `${consult.UID_Practitioner}`+ this.text,
          })
        }
      }
      else {
        this.translate.get('PRACTITIONER-CONSULTS.SWAL').subscribe((res: any) => {
          this.title = res.ERRORADMINTITLE,
          this.text = res.ERRORPRACTITIONER
        })
        Swal.fire({
          icon: 'error',
          title: this.title,
          text: this.text,
        })
      }
    })
  }

  handleChat(consult:any, patient:any){
    this.afs.firestore.collection('chats').where('consultId', '==', consult.ID).where('status', '==', 'active').get().then(async chat => {
      if(chat.docs.length == 0){
        console.log('no chat')
        this.createChat(consult, patient, false)
      }
      else {
        console.log('chat')
        let roomData = chat.docs[0].data();
        let currentTo = roomData.roomUsers;
        if(!currentTo.includes(this.currentUser.email)){
          currentTo.push(this.currentUser.email)
          roomData.userNames.push(this.currentUser.firstName + ' ' + this.currentUser.lastName1)
          roomData.usersUid.push(this.currentUser.uid)
          roomData.profileImgs.push(this.currentUser.image)
          roomData.roomUsers = currentTo

          this.createChat(consult, patient, true, roomData)
        }
      }
    })
  }

  createChat(consult:any, patient:any, update, roomData?){
    const currentDate = new Date();
    let Month = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)
    let Day = (currentDate.getDate()) <= 9 ? '0' + currentDate.getDate() : currentDate.getDate()
    let dateSent = Month+ '/' + Day + '/' + currentDate.getFullYear()

    const room = {
      roomId: this.firebaseService.getDate(),
      dateCreated: currentDate,
      roomUsers: [this.currentUser.email, consult.patientEmail],
      userNames: [this.currentUser.firstName + " " + this.currentUser.lastName1, consult.patientName],
      usersUid: [this.currentUser.uid, consult.UID_Patient],
      profileImgs: [this.currentUser.image, patient.image],
      numberOfMessages: 1,
      consultId: consult.ID,
      status: 'active'
    }
    const messages = {
      messageId: this.firebaseService.getDate(),
      message: consult.consultModale == 'Asynchronous' ? '<en>Hello ' + consult.patientName + '. I will be attending your consultation. If you qualify for treatment, you will receive the prescription and instructions as soon as possible. If you have any questions about your consultation, do not hesitate to contact me. I am at your disposal. <br> - '+ this.currentUser.transformedAbbr + ' ' +this.currentUser.firstName + " " +this.currentUser.lastName1 + '</en><es>Saludos ' + 
                consult.patientName + '. Estaré atendiendo su consulta. De usted cualificar para el tratamiento, recibirá la receta y las instrucciones lo antes posible. De tener alguna duda sobre su consulta, no dude en contactarme. Quedo a su disposición. <br> - ' + this.currentUser.transformedAbbr + ' ' + this.currentUser.firstName + " " + this.currentUser.lastName1 + '</es>' :
                '<en>Hello ' + consult.patientName + '. I will be attending your consultation. If you have any questions about your consultation, do not hesitate to contact me. I am at your disposal. <br> - '+ this.currentUser.transformedAbbr + ' ' +this.currentUser.firstName + " " + this.currentUser.lastName1 + '</en><es>Saludos ' + 
                consult.patientName + '. Estaré atendiendo su consulta. De tener alguna duda sobre su consulta, no dude en contactarme. Quedo a su disposición. <br> - ' + this.currentUser.transformedAbbr + ' ' + this.currentUser.firstName + " " + this.currentUser.lastName1 + '</es>',
      timeSent: this.formatAMPM(new Date),
      dateSent: dateSent,
      dateCreated: currentDate,
      to: [this.currentUser.email, consult.patientEmail],
      from: this.currentUser.email,
      read: [true, false],
      roomId: room.roomId,
      consultId: consult.ID,
      toUid: room.usersUid,
      toUserNames: room.userNames
    }    

    if(update){
      const updateRoom = {
        numberOfMessages: roomData.numberOfMessages + 1,
        userNames: roomData.userNames,
        usersUid: roomData.usersUid,
        profileImgs: roomData.profileImgs,
        roomUsers: roomData.roomUsers,
      }

      console.warn('updateRoom', updateRoom)
      messages.to = roomData.roomUsers;
      messages.toUid = roomData.usersUid;
      messages.toUserNames = roomData.userNames;
      messages.read = roomData.roomUsers.map(u => u == this.currentUser.email ? true : false)
      messages.roomId = roomData.roomId;

      this.afs.collection("chatMessages").doc(messages.messageId).set(messages);
      this.afs.collection("chats").doc(roomData.roomId).update(updateRoom);
    }
    else {
      this.firebaseService.createChat(room, messages)
    }
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime; 
  } 

  getConsultations(){
    this.authService.getNPIRegistry(this.currentUser.uid).then((profile:any) => {
      let taxonomies = [];
      profile.taxonomies.forEach((t: any) => {
        taxonomies.push(t.state);
      })

      this.afs.collection('consultation', ref => ref.where('Status', 'in', ['Paid', 'Draft', 'In Progress', 'Completed'])).valueChanges().subscribe((consults: any) => {
        let practitionerForConsult = [this.currentUser.uid, 'random', ''];
        const passedTime = Date.now() - 20*60*60*1000; //20 hours ago
        consults = consults.filter(c => practitionerForConsult.includes(c.practitionerForConsult) || (c.submittedConsultDate?.seconds * 1000 < passedTime && c.Status == 'Paid'))//if 20 hours have passed since the consult was submitted any practitioner can take it.
                           .sort((a,b) => {
                              return a.dateToSort.seconds - b.dateToSort.seconds
                           })
        
        this.afs.firestore.collection('users').get().then(allUsers => {
          let users = allUsers.docs;

          this.paidConsults = consults.filter(c => c.Status == 'Paid' && !c.serviceType.includes('Follow Up')
                              && taxonomies.includes(c.state.code)
                              && c.typeOfPractitioner.some(element => this.currentUser.typeOfPractitioner.includes(element))
                              && !c.exception?.includes('idExpired') 
                              && !c.exception?.includes('invalidId')
                              && users.filter(u => u.data().uid == c.UID_Patient)[0]?.data().status == 'Verified')

          if(this.currentUser.email != 'dzeus.dev@gmail.com'){
            this.paidConsults = this.paidConsults.filter(c => !testUsers.includes(c.patientEmail))
          }
              
          this.followUpConsults = consults.filter(c => c.serviceType.includes('Follow Up') 
                                                      && (c.Status == 'Paid' || c.Status == 'Draft')
                                                      && taxonomies.includes(c.state.code)
                                                      && c.typeOfPractitioner.some(element => this.currentUser.typeOfPractitioner.includes(element))
                                                      && users.filter(u => u.data().uid == c.UID_Patient)[0]?.data().status == 'Verified')
                                                      .sort((a,b) => {
                                                        return a.Status == 'Draft' ? 1 : -1 //Puts Paid consults first
                                                      });
  
          if(this.currentUser.email != 'dzeus.dev@gmail.com'){
            this.followUpConsults = this.followUpConsults.filter(c => !testUsers.includes(c.patientEmail))
          }
      
          this.inProgressConsults = consults.filter(c => c.Status == 'In Progress')
    
          this.completedConsults = consults.filter(c => c.Status == 'Completed')
        })      
      })
    })
  }

  async getCollaborativeConsults(){
    this.afs.collection("consultation", ref => ref.where("Status", "==", "In Progress")).valueChanges().pipe(takeUntil(this.ngOnDestroy$)).subscribe(async (consults:any) => {
      if(consults.length > 0){
        const map = consults.map(consult => {
          this.afs.collection("consultation").doc(consult.ID).collection("collaborative").valueChanges().pipe(takeUntil(this.ngOnDestroy$)).subscribe(async (collaborators:any) => {
            let me = collaborators.find(c => c.uid == this.currentUser.uid && c.active);
            if(me){
              let date = me.collabEnd.toDate();
              let year = date.getFullYear()
              let month = date.getMonth()
              let day = date.getDate()
              if(new Date(year,month,day) > new Date()){
                const index = this.collaborativeConsults.findIndex(c => c.ID == consult.ID);
                if(me && index === -1){
                  this.collaborativeConsults.push(consult);
                }
              }
            }
          });
        });
      }
    });
  }
};
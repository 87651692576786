import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { snap } from 'gsap';
import { AuditTraceService } from 'src/app/core/services/audit-trace.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { BaseProduct } from 'src/app/core/services/interfaces';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-order-change-product',
  templateUrl: './order-change-product.component.html',
  styleUrls: ['./order-change-product.component.scss']
})

export class OrderChangeProductComponent implements OnInit {
  @Input() consult: any;
  @Input() order: any;
  @Input() productToChange:  any;
  @Input() orderLineProduct: any;
  @Output() closeChangeProduct = new EventEmitter<boolean>();
  @Output() selectedProduct = new EventEmitter<any>();
  @Output() closeAll = new EventEmitter<boolean>();
  user: any;
  products: any[] = [];
  selection: any;
  disableBtn = false;
  constructor(
    private afs: AngularFirestore,
    private authService: AuthenticationService,
    private auditTraceService: AuditTraceService,
    private firebaseService: FirebaseService,
    private router: Router,
    private productService: ProductsService
  ) { }

  async ngOnInit() {
    this.orderLineProduct.parentProductId = Number(this.orderLineProduct.parentProductId); 
    this.orderLineProduct = Object.assign({id:Number(this.orderLineProduct.parentProductId) }, this.orderLineProduct);
    this.selection = this.orderLineProduct;
    this.user = await this.authService.profile();
    this.availablePacks();
  }

  async availablePacks(){
    let filteredProducts = [];
    // this.afs.firestore.collection("productsPacks").where("qty", "<=", this.productToChange.productQty).get().then(async (snapshot:any) => {
    this.afs.firestore.collection("productsPacks").where("rxcui", "==", this.productToChange.rxcui).get().then(async (snapshot:any) => {
      filteredProducts = snapshot.docs.filter(p => p.data().refillQty <= this.productToChange.refillQty && p.data().productID == this.productToChange.productID && p.data().qty <= this.productToChange.qty);
      
      filteredProducts.forEach(async (product:any) => {
        this.afs.firestore.collection("products").doc(product.data().productIDParent.toString()).get().then(async (pack:any) => {
          this.products.push(pack.data());
          this.products.sort((a,b) => Number(b.price) - Number(a.price));
          this.products = this.products.filter(p => p !== undefined);
          this.products = this.products.filter(p => p.hidePractitioner == false);
        });
        // this.products = [...new Set(this.products.map(item => item.id))]; // [ 'A', 'B']
      })
    });
  }

  async changeProduct(){
    this.disableBtn = true;
    // let btn = document.getElementById("change-btn");
    let product:any;
    // btn.innerHTML = "<span class='spinner spinner-border'></span>";
    this.selectedProduct.emit(this.selection);
    // this.afs.collection("consultation").doc(this.consult.ID).collection("wishlist").doc(this.orderLineProduct.parentProductId.toString()).delete();
    this.afs.firestore.collection("orders").doc(this.order.id).collection("orderLines").where("promo", "==", true).get().then(lines => {
      if(lines){
        lines.forEach(x => {
          this.afs.collection("orders").doc(this.order.id).collection("orderLines").doc(x.data().productID.toString()).delete();
        });
      }
    });
    this.afs.collection("orders").doc(this.order.id).collection("orderLines").doc(this.orderLineProduct.productID.toString()).delete();
    this.afs.collection("orders").doc(this.order.id).collection("orderLines").doc(this.selection.productID.toString()).set(this.selection);
    
    // this.afs.collection("consultation").doc(this.consult.ID).collection("wishlist").doc(this.selection.productID.toString()).set(this.selection);
    let patient:any = await this.authService.getUserProfile(this.order.uid);
    
    if(this.user.accountType === "admin"){
      let trace = {
        id: this.firebaseService.getDate(),
        date: new Date(),
        userName: patient.firstName + " " + patient.lastName1,
        userUID: this.order.uid,
        description: `Changed product to ${this.orderLineProduct.parentProductId.toString()}`,
        authorizedAdminName: this.user.firstName + " " + this.user.lastName1,
        authorizedAdminUID: this.user.uid     
      }
      this.auditTraceService.changeProductInOrder(trace, this.order.id);
    }
    

    
    // setTimeout(res => {
      // this.afs.collection("orders").doc(this.order.id).update({orderState: "waiting"});
    // },1000)
    this.afs.collection("orders").doc(this.order.id).update({orderLineUpdate:1, orderState: "draft"});
    this.afs.collection("orders").doc(this.order.id).collection("orderLines").valueChanges().subscribe(async (lines:any) => {
      product = lines.filter(line => line.productID == this.selection.productID.toString())[0];
      if(product !== undefined && product.orderLineID){
        this.close();
      }
    });
  }


  async changeProduct2(){
    this.disableBtn = true;
    let product:any;
    this.selectedProduct.emit(this.selection);
    
    this.afs.collection("orders").doc(this.order.id).collection("orderLines").doc(this.orderLineProduct.id.toString()).delete();
    this.afs.collection("orders").doc(this.order.id).collection("orderLines").doc(this.selection.id.toString()).set(this.selection);
    let patient:any = await this.authService.getUserProfile(this.order.uid);
    if(this.user.accountType === "admin"){
      let trace = {
        id: this.firebaseService.getDate(),
        date: new Date(),
        userName: patient.firstName + " " + patient.lastName1,
        userUID: this.order.uid,
        description: `Changed product to ${this.orderLineProduct.parentProductId.toString()}`,
        authorizedAdminName: this.user.firstName + " " + this.user.lastName1,
        authorizedAdminUID: this.user.uid     
      }
      this.auditTraceService.changeProductInOrder(trace, this.order.id);
    }
    this.afs.collection("orders").doc(this.order.id).update({orderState: "create"});
    this.close();
  }

  disableChange(){
    if(this.selection == undefined){
      return true;
    }
    
    return false;
  }

  setChecked(product){
    if(this.selection.productID == product.id.toString()){
      return true;
    }
    
    return false
  }

  handleSelection(shipping: any){
    let selected: BaseProduct = {
      id: uuidv4(),
      image: shipping.imgRef,
      productID: shipping.id.toString(),
      productName: shipping.name,
      productPrice: shipping.price,
      productQty: Number(shipping.qty),
      recurrency_order: false,
      type: shipping.type,
      orderLineID: "",
      treatment:shipping.treatment,
      rxcui: shipping.rxcui,
      prescriptionID: this.orderLineProduct.prescriptionID,
      prescriptionLineID: this.orderLineProduct.prescriptionLineID,
      automaticPayment:true
      // promoProductID: shipping.promoProductID ? shipping.promoProductID.toString() : '0'
    }
    this.selection = selected;
  }

  close(){
    this.closeChangeProduct.emit(true);
  }

  async getPrescriptionOriginalProduct(id:string, productID:string){
    return new Promise<any>((resolve,reject) => {
      this.afs.collection("prescription").doc(id).collection("Items").valueChanges().subscribe(async (items:any) => {
        let product = items.find(x => x.productID = productID);
        resolve(product);
      });
    });
  }

  async redirectToConsults(){
    // let product = await this.productService.getProduct(this.productToChange.parentProductId.toString());
    // let template = await this.getCondition(product.condition[0]);
    // this.close();
    // setTimeout(() => {

    //   this.router.navigate(['/consultations'], {queryParams: {condition: template.conditionCode}});
    // }, 2000)
    this.closeAll.emit(true);
  }

 

}
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { fbRemoteConfig } from '../../authUtils'
import { Router } from '@angular/router';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})

export class Page404Component implements OnInit {
  copyright = fbRemoteConfig.defaultConfig.year
  allSubscriptions = new Subscription()

  constructor(
    private router: Router,
  ) {}
  
  ngOnInit(): void {
    this.router.navigateByUrl('/account/404');
  }
}

import { Injectable, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  public chat = new BehaviorSubject([]);
  public messages = new BehaviorSubject([]);
  
  allChats = this.chat.asObservable();
  currentMessages = this.messages.asObservable();

  public subscription: Subscription
  public subscription1: Subscription

  constructor( 
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,) { }

    changeSelectedChat(data: any) {
      this.messages.next(data);
    }
  
    selectedChat(data: any){
      this.changeSelectedChat(data);
    }
  
    // getChats(user:any){
    //   this.subscription = this.afs.collection('chats', ref => ref.where('usersUid', 'array-contains', user)).valueChanges().subscribe(async dataRoom => {
    //     this.chat.next(dataRoom);
    //     console.log(this.chat)
    //   });
    // }

    getConsultChat(consultId:any){
      let promise = new Promise((resolve, reject) => {

        let removeSubscription = false
        if(consultId != true){ 
          this.subscription = this.afs.collection('chats', ref => ref.where('consultId', '==', consultId)).valueChanges().subscribe(async dataRoom => {
            if(!removeSubscription){
              this.chat.next(dataRoom);
            }
            resolve(dataRoom);
          });
        }
        else {
          removeSubscription = true
          this.chat.next(undefined)
        }
      })

      return promise
    }

    getMessages(chat: any){
      if(chat != true){
        this.afs.firestore.collection("chatMessages").where('roomId', '==', chat).get().then(data => {
          this.messages.next(data.docs)
        })
      }
      else {
        this.messages.next(undefined)
      }
      
    }
}

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { EmailsService } from 'src/app/core/services/emails.service';
import { testUsers, remoteConfig } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-admin-surveys',
  templateUrl: './admin-surveys.component.html',
  styleUrls: ['./admin-surveys.component.scss']
})
export class AdminSurveysComponent implements OnInit {

  constructor(
    private db: AngularFirestore,
    private translateService: TranslateService,
    private emailService: EmailsService
  ) { }

  loaded = false;

  consultSteps = [
    { stepId: 'preconsultQuestions'},
    { stepId: 'engagementStep'}
  ];

  openConsultAnalytics = {
    series: [{data: [0,0], name: ''}],
    labels: ['ADMINCONSULTS.QUESTIONS', 'ADMINCONSULTS.ENGAGEMENT'],
    colors: ['#dd4031', '#fe840e'],
  };

  allConsultAnalytics = {
    series: [{data: [], name: ''}],
    labels: [],
    status: {},
    colors: ['#0049f4'],
  };

  testUsers = testUsers;
  settings = remoteConfig;


  //Survey Question Templates
  allQuestionsTemplatesFromAllSurveys = [] //gets all question templates from all surveys

  // Survey Templates
  surveyList = []

  // Surveys
  allSurveysCompleted = []
  surveyFilter = []
  questionFilter = []

  filter = 0;

  ngOnInit(): void {
    // this.consultTypes();
    // this.consultAnalitics();
    this.getSurveyTemplate();
    this.getUsers();
  }

  getSurveyTemplate(){
    this.db.firestore.collection('conditionsQuestions').where('survey', '==', true).get().then((res: any) => {
      let allQuestionsTemplatesGroups = res.docs.map((doc: any) => doc.data())

      for(let i = 0; i < allQuestionsTemplatesGroups.length; i++){
        this.db.firestore.collection('conditionsQuestions').doc(allQuestionsTemplatesGroups[i].id).collection('Questions').get().then((res: any) => {
          for(let j = 0; j < res.docs.length; j++){
            let question = res.docs[j].data()
            question.surveyId = allQuestionsTemplatesGroups[i].id
            this.allQuestionsTemplatesFromAllSurveys.push(question)
          }
          // this.viewSurvey(index)
          if(i == allQuestionsTemplatesGroups.length - 1){
            this.getCompletedSurveys();
          }
        })
      }
    })
  }

  getCompletedSurveys(){
    this.db.firestore.collection('surveyTemplates').get().then((templates: any) => {
      this.surveyList = templates.docs.map((doc: any) => doc.data()).sort((a, b) => new Date(b.surveyDate.seconds * 1000).getTime() - new Date(a.surveyDate.seconds * 1000).getTime())
      this.filter = this.surveyList[0].surveyTemplate
      this.db.firestore.collection('surveys').where('surveyPatientStatus', '==', 'completed').get().then((res: any) => {
        this.allSurveysCompleted = res.docs.map((doc: any) => doc.data()).sort((a, b) => new Date(a.surveyCompletedDate.seconds * 1000).getTime() - new Date(b.surveyCompletedDate.seconds * 1000).getTime())

        this.viewSurvey(0);
      })
    })
  }

  viewSurvey(index){      
    this.surveyFilter = this.allSurveysCompleted.filter(s => s.surveyTemplate == this.surveyList[index].surveyTemplate).sort((a, b) => new Date(a.surveyCompletedDate.seconds * 1000).getTime() - new Date(b.surveyCompletedDate.seconds * 1000).getTime())
    this.questionFilter = this.allQuestionsTemplatesFromAllSurveys.filter(q => q.surveyId == this.surveyList[index].surveyQuestionsTemplate)

    this.handleSurveysToPublish()

    for(let i = 0; i < this.questionFilter.length; i++){
      this.questionFilter[i].answerAnalytics = {}
      for(let j = 0; j < this.surveyFilter.length; j++){
        if(this.questionFilter[i].type != 'Text'){
          let answer = this.surveyFilter[j].surveyAnswers[i].answer;
          if(this.questionFilter[i].answerAnalytics == undefined){
            this.questionFilter[i].answerAnalytics = {}
          }
          this.questionFilter[i].answerAnalytics[answer] ? this.questionFilter[i].answerAnalytics[answer] += 1 : this.questionFilter[i].answerAnalytics[answer] = 1
        }
        else {
          if(this.questionFilter[i].answerAnalytics.reviews == undefined){
            this.questionFilter[i].answerAnalytics = {reviews: []}
          }

          this.questionFilter[i].answerAnalytics.reviews.push(this.surveyFilter[j].surveyAnswers[i].answer)
        }
      }
    }
  }

  getUsers(){
    this.db.firestore.collectionGroup('Address').get().then(async (res: any) => {
      for(let i = 0; i < res.size; i++){
        const doc = await res.docs[i];
        const fullPath = await doc.ref.path; // Get the full path of the document
    
        let userId = fullPath.split('/')[1].split('/')[0];
        if(fullPath.includes('users')){
          this.allUserAddresses.push({userId: userId, address: doc.data()})
        }
      }
      this.db.firestore.collection('users').get().then((users: any) => {
        this.allUsers = users.docs.map((doc: any) => doc.data())
      })
    })
  }

  groupSurveysByPatient = [];
  allUserAddresses = [];
  allUsers = [];
  allSurveyReviews: any = 0
  handleSurveysToPublish(){ 
    this.surveyFilter = this.surveyFilter.map(survey => {
      let user = this.allUsers.find(user => user.uid == survey.surveyPatientUid)
      let address = this.allUserAddresses.filter(o => o.userId == user.uid)[0]
      if(address){
        survey.address = address.address.AddressCity + ', ' + address.address.AddressStateCode
      }
      survey.patientName = survey.anonymousAnswer == false ? user.firstName : 'Anonymous'
      //  + ' ' + user.lastName1
      return survey
    })

    let questionFilterReviewIndex = this.questionFilter.findIndex(q => q.review)

    let tempSurveys = this.surveyFilter
    // .filter(s => s.allowPublishSurvey && s.surveyAnswers[questionFilterReviewIndex].answer != '')

    this.groupSurveysByPatient = []

    let allReviews = 0
    for(let j = 0; j < tempSurveys.length; j++){
      let rating = 0;

      for(let i = 0; i < this.questionFilter.length; i++){
        if(this.questionFilter[i].type != 'Text'){
          let answerIndex = this.questionFilter[i].options.findIndex(option => option == tempSurveys[j].surveyAnswers[i].answer);
          rating += this.questionFilter[i].optionsPoints[answerIndex]
        }

        if(i == this.questionFilter.length - 1){
          let answers = {
            rating: parseFloat(
              ((rating / (this.questionFilter.filter(q => q.type != 'Text').length * 100)) * 5).toFixed(2)
          ).toString().replace(/\.?0+$/, ''), //500 == max questions points == length of survey - review
            review: tempSurveys[j].surveyAnswers[questionFilterReviewIndex].answer
          }

          allReviews += Number(answers.rating)
          this.groupSurveysByPatient.push({ allowPublishSurvey: tempSurveys[j].allowPublishSurvey, patientName: tempSurveys[j].patientName, rating: answers.rating, review: answers.review, address: tempSurveys[j].address })
        
          if(j == tempSurveys.length - 1){
            this.allSurveyReviews = parseFloat((allReviews / tempSurveys.length).toFixed(2)).toString().replace(/\.?0+$/, '')
          }
        }
      }
    }
  }

  oldConsultsDate: any = new Date('2023-07-18').getTime() / 1000;
  consultAnalitics() {
    this.db.collection('consultation', ref => ref.where('Status', '==', 'Open').where('patientName', '!=', '(Anonymous)')).valueChanges().subscribe(async (res: any) => {
      res = res.filter(consult => !this.testUsers.includes(consult.patientEmail))
      this.openConsultAnalytics.series[0].data = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]

      for(let i = 0; i < 16; i++){
        await this.translateService.get(this.openConsultAnalytics.labels[i]).subscribe(ret => {
          this.openConsultAnalytics.labels[i] = ret.toString()
        })
      }

      for(let j = 0; j < res.length; j++){
        if(res[j].lastRevision.seconds < this.oldConsultsDate){
          this.openConsultAnalytics.series[0].data[16] += 1
        }
        else {
          let index = this.consultSteps.findIndex(step => step.stepId == res[j].currentWizardStep)
          this.openConsultAnalytics.series[0].data[index] += 1
        }

        if(j == res.length - 1){
          this.loaded = true
        }
      }
    })
  }

  consultTypes(){
    this.db.collection('consultation').valueChanges().subscribe(async (res: any) => {
      res = res.filter(consult => !this.testUsers.includes(consult.patientEmail))
      this.allConsultAnalytics.series[0].data = []
      this.allConsultAnalytics.labels = []
      this.allConsultAnalytics.status = {}

      for(let j = 0; j < res.length; j++){
        let nonAnonymous = res[j].Status == 'Open' && res[j].patientName != '(Anonymous)' ? 1 : 0

        let type = res[j].Type.includes('Psychotherapy') ? 'Psychotherapy' : res[j].Type ==  'Anxiety' || res[j].Type == 'Depression' ? 'Mental Health' : res[j].Type
        !this.allConsultAnalytics.status[type] ? (Object.assign(this.allConsultAnalytics.status, {[type]: {[res[j].Status]: 1}})) : this.allConsultAnalytics.status[type][res[j].Status] ? this.allConsultAnalytics.status[type][res[j].Status] += 1 : this.allConsultAnalytics.status[type][res[j].Status] = 1
        this.allConsultAnalytics.labels.includes(type) ? this.allConsultAnalytics.series[0].data[this.allConsultAnalytics.labels.indexOf(type)] += 1 : (this.allConsultAnalytics.labels.push(type), this.allConsultAnalytics.series[0].data.push(1))

        if(nonAnonymous == 1){
          this.allConsultAnalytics.status[type]['Open Non Anonymous'] ? this.allConsultAnalytics.status[type]['Open Non Anonymous'] += 1 : this.allConsultAnalytics.status[type]['Open Non Anonymous'] = 1
        }
      }
    })
  }

  createSurvey(){
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Set to midnight (12:00:00 AM)\
    const todayPlus7 = new Date(today.setDate(today.getDate() + 7));


    let survey = {
      surveyId: '', //random ID. Always blank on template
      allQuestionsTemplatesFromAllSurveys: 'EDSurvey2', //change on new template
      surveyStatus: 'available', //Active on template until it becomes "unavailable" to stop sending it
      surveyTemplate: 'Survey_2', // change on new template
      surveyDate: new Date(),
      surveyExpDate: todayPlus7, //Add if you need a expiration date. Adjust the Date as desired.
      surveyAnswers: [], // Always blank on template
      surveyDescription: `This survey collects patient reviews regarding our services. 
                    This survey is for patients who have been treated and have completed 
                    a purchase with us.`, // change on new template
      surveyLanguage: '', //patient language. Always blank on template
      surveyPatientStatus: 'active', //always active on template
      surveyPatientUid: '', //patient uid. Always blank on template
    }

    console.log(survey)
    this.db.collection('surveyTemplates').doc(survey.surveyTemplate).set(survey).then(() => {
      console.log('Survey created')
    })
  }

  createSurveyQuestions(){
    this.db.firestore.collection('conditionsQuestions').doc('EDSurvey1').collection('Questions').get().then((res: any) => {
      let questions = res.docs.map((doc: any) => doc.data())
      
      for(let i = 0; i < questions.length; i++){
        let question = questions[i]

        this.db.firestore.collection('conditionsQuestions').doc('EDSurvey2').collection('Questions').doc(question.id).set(question)
      }
    })
  }

  sendSurvey(){
    this.db.firestore.collection('users').where('accountType', 'in', ['patient', 'admin']).where('status', '==', 'Verified').where('isAccountDisabled', '==', false).get().then((users) => {

      this.db.firestore.collection('orders').where('paymentStatus', '==', 'paid').where('productType', '==', 'product').get().then((orders) => {
       
        let survey = this.surveyList[0];
        this.db.firestore.collection('surveyTemplates').doc(survey.surveyTemplate).get().then((survey) => {
          this.db.firestore.collection('conditionsQuestions').doc(survey.data().surveyQuestionsTemplate).collection('Questions').get().then(async (questions) => {
            
            console.warn('total Users', users.docs.length)
            let allOrders = orders.docs.map(o => o.data()).filter(o => !o.refill && new Date(o.date.seconds * 1000) > new Date('2024-09-25') && new Date(o.date.seconds * 1000) < new Date('2024-10-25'))
            console.warn('total Orders', allOrders)
            let noOrderCount = 0;
            let emailsSent = 0;
            for(let i = 0; i < users.docs.length; i++){

              let checkUserOrders = allOrders.filter(order => order.uid == users.docs[i].data().uid)

              if(checkUserOrders.length > 0){
                let date = new Date(Date.now());
                var dd = String(date.getDate()).padStart(2, '0');
                var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = date.getFullYear();
                let id:any = yyyy + mm + dd + await uuidv4();
                await this.db.collection('surveys').doc(id).set({
                  surveyId: await id,
                  surveyQuestionsTemplate: await survey.data().surveyQuestionsTemplate,
                  surveyTemplate: await survey.data().surveyTemplate,
                  surveyDate: new Date(),
                  surveyExpDate: await survey.data().surveyExpDate,
                  surveyAnswers: await survey.data().surveyAnswers,
                  surveyDescription: await survey.data().surveyDescription,
                  surveyLanguage: await users.docs[i].data().preferedLang,
                  surveyPatientStatus: await survey.data().surveyPatientStatus,
                  surveyPatientUid: await users.docs[i].data().uid,
                })
      
                await this.emailService.surveyEmails(users.docs[i].data(), 'survey_' + users.docs[i].data().preferedLang, '<en>Satisfaction Survey</en><es>Encuesta de Satisfacción</es>', this.settings, 'account/survey?sn=' + id + '&q=' + questions.docs[0].data().id, questions.docs[0].data().options, questions.docs[0].data())
                emailsSent += 1;
                // console.warn('email sent', emailsSent)
              }
              else {
                noOrderCount += 1;
                // console.warn('No orders for', users.docs[i].data().email, noOrderCount)
              }

              if(i == users.docs.length - 1){
                console.warn('all emails sent', emailsSent, noOrderCount)
              }
            }
          })
        })
      })
    })
  }
}
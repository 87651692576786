<div class="d-flex flex-column">
  <div class="d-flex justify-content-end">
    <i [hidden]="disableBtn" class="fas fa-times cursor-pointer" (click)="close()"></i>
  </div>
  <div class="">
    <h2 class="title">{{ 'ORDER-CHANGE-PRODUCT.PRESCRIBED' | translate }}</h2>
    <span>{{ 'ORDER-CHANGE-PRODUCT.PROVIDERCRITIRIA' | translate }}</span>
    <!-- <div class="content">
      <div class="d-flex mb-1">
        <div class="d-flex w-100 justify-content-between">
          <div class="d-flex flex-column">
            <h3 class="ml-3 product-name">{{productToChange?.name}}</h3>
            <span class="ml-3">{{productToChange.qty}}</span>
            <div class="d-flex justify-content-between">
              <span class="ml-3 badge badge-info">{{ 'ORDER-CHANGE-PRODUCT.REFILLAVAILABLE' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="d-flex box justify-content-between">
      <div class="d-flex w-100">
        <img class="img" src="{{orderLineProduct.image}}" alt="">
        <div class="d-flex flex-column">
          <h5 class="title mt-2 mb-0">{{productToChange.name}}</h5>
          <span>{{productToChange.qty}} {{'CHANGE-PRODUCT.TABLETS' | translate}}</span>
          <span>$ {{ productToChange.productPrice }}</span>
        </div>
      </div>
      <div class="d-flex w-50 justify-content-end">
        <span class="change-prescription linear-wipe" (click)="redirectToConsults()">{{'ORDERS.CHANGE-PRESCRIPTION' | translate}}</span>
        <svg id="arrow-right" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" (click)="redirectToConsults()">
          <path d="M5 12h14"></path>
          <path d="m15 16 4-4"></path>
          <path d="m15 8 4 4"></path>
        </svg>
      </div>
    </div>
    <div class="description mt-1">
      <p class="ml-3">{{'CHANGE-PRODUCT.ACTION-DESCRIPTION-PRESCRIPTION' | translate}}
      </p>
    </div>


    <h2 class="title mt-5">{{ 'ORDER-CHANGE-PRODUCT.AVAILABLEPRODUCTS' | translate }}</h2>
    <div class="col-lg-12 all-products">
      <div class="grid-wrapper">
        <label for="{{product.id}}" class="radio-card animate pop" *ngFor="let product of products">
          
          <input hidden type="radio" name="radio-card animate pop" id="{{product.id}}" [checked]="setChecked(product)"/>
          <div class="card-content-wrapper" (click)="handleSelection(product)">
            <div class="w-100 d-flex">
              <span class="check-icon"></span>
              <div *ngIf="product.price == '0.00'" class="w-100 d-flex justify-content-end">
                <span class="free-badge">Free</span>
              </div>
            </div>
            <div class="card-content">
              <img src="{{product.imgRef}}" alt=""/>
              <div class="d-flex flex-column mt-1">
                <h3 class="mx-auto text-success">$ {{product.price | translation: user.preferedLang}}</h3>
                <h4 class="mt-2 mx-auto">{{product.name | translation: user.preferedLang}}</h4>
                <h4 class="mx-auto text-success">{{product?.highlight | translation: user.preferedLang}}</h4>
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-2">
      <button *ngIf="!disableBtn" id="change-btn" class="btn btn-info" (click)="changeProduct2()" [disabled]="disableChange()">{{ 'ORDER-CHANGE-PRODUCT.CHANGE' | translate }}</button>
      <button *ngIf="disableBtn" id="change-btn" class="btn btn-info" (click)="changeProduct2()" [disabled]="disableChange()"><span class='spinner spinner-border' style="width: 1rem; height: 1rem"></span>&nbsp;{{ 'RELOAD.DEFAULT' | translate }}</button>
      <button id="change-btn" class="btn btn-info ml-2" (click)="close()" [hidden]="disableBtn">{{ 'ORDER-CHANGE-PRODUCT.CLOSE' | translate }}</button>
    </div>
    <div style="font-size: initial; color: red; display: flex; justify-content: center !important;" *ngIf="disableBtn">{{'ORDER-CHANGE-PRODUCT.WARNING' | translate}}</div>
  </div>
</div>
<div class="row" [ngClass]="{'m-0': consultID, 'mt-3': !consultID}">
  <div class="col-lg-12">
  <div class="form mx-lg-auto">
    <h4 *ngIf="bool == false && (!consultID || showBtns)">{{ 'ADDRESS.ADDADDRESS' | translate}}</h4>
    <h4 *ngIf="bool && (!consultID || showBtns)">{{ 'ADDRESS.EDITADDRESS' | translate}}</h4>
    <form [formGroup]="addressform">
      <div [hidden]="consultID" class="form-group row mb-2">
        <div class="col-md-8 mx-auto align-self-center">
            <ng-select style="top: 0;" *ngIf="consultShipping" class="custom" name="AddressType" disabled placeholder="{{ 'ADDRESS.ADDRESSTYPE' | translate}}" formControlName="AddressType">
              <ng-option value="Shipping">{{ 'ADDRESS.SHIPPING' | translate}}</ng-option>
            </ng-select>
            <ng-select style="top: 0;" *ngIf="!consultShipping" class="custom" name="AddressType"  placeholder="{{ 'ADDRESS.ADDRESSTYPE' | translate}}" formControlName="AddressType">
              <ng-option value=""></ng-option>
              <ng-option value="Billing">{{ 'ADDRESS.BILLING' | translate}}</ng-option>
            <ng-option value="Shipping">{{ 'ADDRESS.SHIPPING' | translate}}</ng-option>
            </ng-select>
          <!-- <div *ngIf="bool == false" class="mt-2">
            <ng-container *ngFor="let validation of validation_messages.AddressType">
            <div style="color: red;" *ngIf="billingAddresses.length != 0 && addressgetter.AddressType.value == billingAddresses[0].data().AddressType">
              {{'ADDRESS.VALIDATIONS.BILLING' | translate}}
            </div>
            <div style="color: red;" *ngIf="shippingAddresses.length != 0 && addressgetter.AddressType.value == shippingAddresses[0].data().AddressType">
              {{'ADDRESS.VALIDATIONS.SHIPPING' | translate}}
            </div>
            </ng-container>
          </div>       -->
        </div>      
      </div>
      <div class="d-flex flex-row mx-auto my-2" style="width: fit-content;">
        <div class="d-flex justify-content-between">
          <label for="switch-address" class="switch-address">
            <input id="switch-address" type="checkbox" [checked]="address.AddressDefault" [ngModelOptions]="{standalone: true}" [(ngModel)]="address.AddressDefault" (change)="warnDefaultAddress($event)">
            <span class="slider round"></span>
          </label>
        </div>
        <span class="default ml-1" *ngIf="!address.AddressDefault">{{'PAYMENTS.SETDEFAULT' | translate}}</span>
        <span class="default ml-1" *ngIf="address.AddressDefault">{{'PROFILE.DEFAULT' | translate}}</span>
      </div>
      <div class="form-group row m-0">
        <div class="col-md-8 mx-auto align-self-center">
          <label for="AddressLine1" class="inp">
            <input class="form-control" type="text" maxlength="50"  name="AddressLine1" id="AddressLine1" placeholder="&nbsp;" formControlName="AddressLine1">
            <span class="label">{{ 'ADDRESS.ADDRESSLINE1' | translate}}</span>
            <span class="focus-bg"></span>
            <ng-container *ngFor="let validation of validation_messages.AddressLine1">
              <div style="color: red;" *ngIf="addressgetter.AddressLine1.hasError(validation.type) && (addressgetter.AddressLine1.dirty || addressgetter.AddressLine1.touched)">
                {{ validation.message | translate }}
              </div>
            </ng-container>
          </label>
        </div>
      </div>
      <div class="form-group row m-0">
        <div class="col-md-8 mx-auto align-self-center" >
          <label for="AddressLine2" class="inp">
            <input class="form-control" type="text"  name="AddressLine2" maxlength="50" id="AddressLine2" placeholder="&nbsp;" formControlName="AddressLine2">
            <span class="label">{{ 'ADDRESS.ADDRESSLINE2' | translate}}</span>
            <span class="focus-bg"></span>
          </label>
        </div>
      </div>
      <div class="form-group row m-0">
        <div class="col-md-8 mx-auto align-self-center" >
          <label for="AddressCity" class="inp">
            <input type="text" class="form-control" name="AddressCity" id="AddressCity" placeholder="&nbsp;" formControlName="AddressCity">
            <span class="label">{{ 'ADDRESS.CITY' | translate}}</span>
            <span class="focus-bg"></span>
            <ng-container>
              <!-- <div style="color: red;" *ngIf="addressgetter.AddressCity.hasError(validation.type) && (addressgetter.AddressCity.dirty || addressgetter.AddressCity.touched)">
                {{ validation.message | translate }}
              </div> -->
            </ng-container>
          </label>
        </div>
      </div>
      <div [hidden]="consultID" class="form-group row m-0">
        <div class="col-md-8 mx-auto align-self-center" >
            <ng-select style="min-height: 56px;" [disabled]="true" placeholder="{{ 'ADDRESS.COUNTRY' | translate}}" [items]="countries" (change)="getStates($event); addressform.controls.AddressState.patchValue(undefined)" bindLabel="name" bindValue="name" class="custom" formControlName="AddressCountry" >
            </ng-select>
            <ng-container *ngFor="let validation of validation_messages.AddressCountry">
              <br *ngIf="addressgetter.AddressCountry.hasError(validation.type) && (addressgetter.AddressCountry.dirty || addressgetter.AddressCountry.touched)">
              <div style="color: red;" *ngIf="addressgetter.AddressCountry.hasError(validation.type) && (addressgetter.AddressCountry.dirty || addressgetter.AddressCountry.touched)">
                {{ validation.message | translate }}
              </div>  
            </ng-container>
        </div>
      </div>
      <div class="form-group row m-0" *ngIf="statesUS.length != 0">
        <div class="col-md-8 mx-auto align-self-center" >       
            <ng-select style="min-height: 56px;" [disabled]="true" [items]="statesUS" bindLabel="name" id="state" bindValue="name" (change)="stateAllowed($event)" placeholder="{{ 'ADDRESS.STATE' | translate}}" class="custom" formControlName="AddressState">
            </ng-select>
        </div>
      </div>
      <div class="form-group row m-0">
        <div class="col-md-8 col-form-label mx-auto align-self-center">
          <label for="shippingZip" class="inp">
            <input type="text" class="form-control" name="AddressZipcode" id="shippingZip" placeholder="&nbsp;" formControlName="AddressZipcode" pattern=".{5,5}" maxlength="5" minlength="5" (input)="onZipCodeInput($event)">
            <span style="display: flex; flex-direction: row;" class="label">{{ 'ADDRESS.ZIPCODE' | translate}}
            </span>
            <span class="focus-bg"></span>
            <ng-container *ngFor="let validation of validation_messages.AddressZipcode">
              <div style="color: red;" *ngIf="addressgetter.AddressZipcode.hasError(validation.type) && (addressgetter.AddressZipcode.dirty || addressgetter.AddressZipcode.touched)">
                {{ validation.message | translate }}
              </div>
            </ng-container>
          </label>
        </div>
      </div>
      <div *ngIf="!consultID || showBtns" class="row justify-content-center" style="padding-bottom: 20px;">
        <button *ngIf="bool == false && !validatingZipCode" [hidden]="save != undefined" id="saveBtn" type="button" class="btn btn-cyan" (click)="validSubmit()" [disabled]="invalidSubmit()" disabled>{{ 'ADDRESS.SAVE' | translate}}</button>
        <button *ngIf="validatingZipCode" [hidden]="save != undefined" id="saveBtn" type="button" class="btn btn-cyan" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{'RELOAD.VERIFYING' | translate}}</button>
        <button *ngIf="bool && !validatingZipCode" type="button" id="saveBtn" class="btn btn-cyan" (click)="updateAddress()" [disabled]="invalidSubmit()">{{ 'ADDRESS.UPDATE' | translate}}</button>
        <button [hidden]="save != undefined" type="button" class="ml-2 btn btn-light" (click)="cancel()">{{ 'PAYMENTS.CANCEL' | translate}}</button>
      </div>
    </form> 
    </div>
  </div>
</div>

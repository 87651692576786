<div class="">
  <!-- <h3 class="main-title">{{'SUBSCRIPTIONS.SUBSCRIPTIONS' | translate}}</h3> -->
  <h3 class="main-title">Refills</h3>
  <div class="w-100 d-flex subscriptions flex-grid-thirds flex-wrap">
    <div *ngFor="let subscription of refills" class="subscription-card">
      <div class="w-100 d-flex justify-content-end">
        <span *ngIf="subscription.state == 'In Progress'" class="current-badge">
          <svg  width="15" height="15" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 15a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"></path>
            <path d="m12.002 15 3.4 5.889L17 17.657l3.598.232-3.4-5.889"></path>
            <path d="m6.802 12-3.4 5.889L7 17.657l1.598 3.232 3.4-5.889"></path>
          </svg> 
          {{'SUBSCRIPTIONS.CURRENT' | translate}}
        </span>
      </div>

      <div class="text-center mt-2">
        <h4 class="title">{{ subscription.productName | translation:currentUser.preferedLang}}</h4>
        <span><span class="price font-size-24 orangeText">{{subscription.productPrice | currency:'USD'}}</span>/{{'SUBSCRIPTIONS.EVERY' | translate}} {{subscription?.terms}} {{'SUBSCRIPTIONS.DAYS' | translate}}</span><br>
        <span>{{'SUBSCRIPTIONS.SINCE' | translate}}: <span class="text-success">{{subscription.startDate | date:'MMMM dd, yyyy':'':currentUser.preferedLang | titlecase}}</span></span>

      </div>
      <!-- <div class="d-flex flex-column mt-3 mx-auto">
        <li *ngFor="let atr of subscription | keyvalue" [ngClass]="{'invisible': atr.value == '' || !atr.key.startsWith('atr')}" class="d-flex"><i class="fa fa-check-circle d-flex justify-content-center align-items-center"></i> <span class="ml-2">{{ atr.value | translation:currentUser.preferedLang}}</span></li>
        <li class="d-flex" [ngClass]="{'invisible': subscription.tabletsQty == 0}"><i class="fa fa-check-circle d-flex justify-content-center align-items-center"></i> <span class="ml-2">{{ subscription.qty}} {{'CONSULTATIONS.TABLETS' | translate}}</span></li>
      </div> -->
      
      <div class="w-100 d-flex mt-4 text-center">
        <span class="mx-auto">{{'SUBSCRIPTIONS.NEXTBILLING' | translate}}:<br> <span class="text-danger">{{  subscription.orderDate | date:'MMMM dd, yyyy':'':currentUser.preferedLang | titlecase}}</span></span>
      </div>
      <div *ngIf="card" class="payment-info mx-auto mb-4 text-center">
        <span>{{'ORDERS.CARDREGISTER' | translate}}:</span>
        <div class="d-flex my-2 align-items-center justify-content-center">
          <img src="assets/images/cards/visa-color-logo.png" *ngIf="subscription?.brand === 'Visa' || subscription?.brand === 'VISA'" alt="Visa" width="20"/>
          <img src="assets/images/cards/mastercard-logo.png" *ngIf="subscription?.brand === 'Mastercard' || subscription?.brand === 'MASTERCARD'" alt="MasterCard" width="20"/>
          <img src="assets/images/cards/american_express_01.png" *ngIf="subscription?.brand === 'American Express' || subscription?.brand === 'AMERICAN_EXPRESS'" alt="American Express" width="20"/>
          <img src="assets/images/cards/discover.png" *ngIf="subscription?.brand === 'Discover Club' || subscription?.brand === 'DISCOVER'" alt="Discover" width="20"/>
          <img src="assets/images/cards/jcb_01.png" *ngIf="subscription?.brand === 'JCB' || subscription?.brand === 'JCB'" alt="JCB" width="20"/>
          <img src="assets/images/cards/diners_club_01.png" *ngIf="subscription?.brand === 'Diners' || subscription?.brand === 'DISCOVER_DINERS'" alt="Diners Club" width="20"/>
          <img src="assets/images/cards/china_union_01.png" *ngIf="subscription?.brand === 'China UnionPay' || subscription?.brand === 'CHINA_UNIONPAY'" alt="UnionPay" width="20"/>
          <div class="flex-column ml-3">
            <!-- <span class="font-weight-bold">{{subscription?.brand}}</span> -->
            <span>**** **** **** {{subscription?.last4}}</span>
          </div>
        </div>
      </div>
      <div class="items d-flex mb-1">
        <ng-select class="shipping-select checkoutSelect w-100"  placeholder="{{'CHECKOUT.SELECTCARD' | translate}}" [multiple]="false" [clearable]="false" [searchable]="false" (change)="onItemChange(subscription, $event)" [(ngModel)]="subscription.creditCardRef">
          <ng-option *ngFor="let item of allPaymentMethods" [value]="item">
            <div class="d-flex" style="place-items: center;">
              <img src="assets/images/cards/visa-color-logo.png" *ngIf="item.brand === 'Visa' || item.brand === 'VISA'" alt="Visa" width="20"/>
              <img src="assets/images/cards/mastercard-logo.png" *ngIf="item.brand === 'Mastercard' || item.brand === 'MASTERCARD'" alt="MasterCard" width="20"/>
              <img src="assets/images/cards/american_express_01.png" *ngIf="item.brand === 'American Express' || item.brand === 'AMERICAN_EXPRESS'" alt="American Express" width="20"/>
              <img src="assets/images/cards/discover.png" *ngIf="item.brand === 'Discover Club' || item.brand === 'DISCOVER'" alt="Discover" width="20"/>
              <img src="assets/images/cards/jcb_01.png" *ngIf="item.brand === 'JCB' || item.brand === 'JCB'" alt="JCB" width="20"/>
              <img src="assets/images/cards/diners_club_01.png" *ngIf="item.brand === 'Discover' || item.brand === 'DISCOVER_DINERS'" alt="Diners Club" width="20"/>
              <img src="assets/images/cards/china_union_01.png" *ngIf="item.brand === 'UnionPay' || item.brand === 'CHINA_UNIONPAY'" alt="UnionPay" width="20"/>
              <span class="" style="margin:auto 5%;">{{item.brand}}-{{item.last4}} {{'CHECKOUT.EXPIRES' | translate}} {{item.expDate}}</span>
            </div>  
          </ng-option>
        </ng-select>
      </div>

      <div class="w-100 mt-3">
        <button class="button" *ngIf="subscription.state == 'In Progress'" (click)="pauseSubscription(subscription)">{{'SUBSCRIPTIONS.CANCEL' | translate}}</button>
        <button class="button" *ngIf="subscription.state == 'Hold'" (click)="activateSubscription(subscription)">{{'SUBSCRIPTIONS.ACTIVATE' | translate}}</button>
        <!-- <button class="button btnOrange mt-2" (click)="subscriptionHistory(subscription)">{{'SUBSCRIPTIONS.HISTORY' | translate}}</button> -->
        <!-- <button class="button mt-2" (click)="openUpgradeModal(subscription)">{{'SUBSCRIPTIONS.UPGRADEPLAN' | translate}} <i class="fa fa-arrow-right" aria-hidden="true"></i></button> -->
        <button class="button mt-2" *ngIf="subscription.state != 'Closed'" (click)="chooseActionModal(subscription)">{{'SUBSCRIPTIONS.UPGRADEPLAN' | translate}} <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
      </div>
    </div>
    <div *ngIf="refills.length == 0">
      <!-- <h3>{{'SUBSCRIPTIONS.NOSUBSCRIPTIONS' | translate}}</h3> -->
      <h3>No Refills available</h3>
    </div>
  </div>
</div>








<ng-template #historyRef let-modal>
  <div class="">
    <div class="w-100 d-flex justify-content-between">
      <div class="flex-column">
        <h2>{{'SUBSCRIPTIONS.SUBSCRIPTIONHISTORY' | translate}}</h2>
        <p>{{'SUBSCRIPTIONS.LIST' | translate}}</p>
      </div>
      <i class="fa fa-times" (click)="modal.close(); modalRef = undefined;"></i>
    </div>
    <hr>
  </div>
  <div class="w-100 d-flex phoneView flex-column">
    <div class="">
      <h5>{{'SUBSCRIPTIONS.SUBSCRIPTION' | translate}}</h5>
      <span>{{'SUBSCRIPTIONS.CURRENTSUBSCRIPTION' | translate}}</span>
      <div class="subscription-card">
        <div class="w-100 d-flex justify-content-end">
          <span *ngIf="currentSubscription.state == 'In Progress'" class="current-badge">
            <svg  width="15" height="15" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 15a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"></path>
              <path d="m12.002 15 3.4 5.889L17 17.657l3.598.232-3.4-5.889"></path>
              <path d="m6.802 12-3.4 5.889L7 17.657l1.598 3.232 3.4-5.889"></path>
            </svg> 
            {{'SUBSCRIPTIONS.CURRENT' | translate}}
          </span>
        </div>
  
        <div class="text-center">
          <h4 class="title">{{ currentSubscription.productName | translation:currentUser.preferedLang }}</h4>
          <span><span class="price">{{currentSubscription.productPrice | currency:'USD'}}</span>/{{'SUBSCRIPTIONS.EVERY' | translate}} {{currentSubscription?.terms}} {{'SUBSCRIPTIONS.DAYS' | translate}}</span><br>
          <span>{{'SUBSCRIPTIONS.SINCE' | translate}}: {{currentSubscription.startDate | date:'MMMM dd, yyyy':'':currentUser.preferedLang | titlecase}}</span>
        </div>
        <div class="d-flex flex-column mt-3 mx-auto">
          <li *ngFor="let atr of currentSubscription | keyvalue" [ngClass]="{'invisible': atr.value == '' || !atr.key.startsWith('atr')}" class="d-flex"><i class="fa fa-check-circle d-flex justify-content-center align-items-center"></i> <span class="ml-2">{{ atr.value | translation:currentUser.preferedLang}}</span></li>
          <li class="d-flex" [ngClass]="{'invisible': currentSubscription.qty == 0}"><i class="fa fa-check-circle d-flex justify-content-center align-items-center"></i> <span class="ml-2">{{ currentSubscription.qty}} {{'CONSULTATIONS.TABLETS' | translate}}</span></li>
        </div>
        
        <div class="w-100 d-flex mt-4">
          <span class="mx-auto">{{'SUBSCRIPTIONS.NEXTBILLING' | translate}}: <span class="text-danger">{{  currentSubscription.orderDate | date:'MMMM dd, yyyy':'':currentUser.preferedLang | titlecase}}</span></span>
        </div>
        <div class="w-100 mt-3" style="text-align: center;">
          <button class="button" style="min-width: 30%;" *ngIf="currentSubscription.state == 'In Progress'" (click)="pauseSubscription(currentSubscription)">{{'SUBSCRIPTIONS.CANCEL' | translate}}</button>
          <button class="button" style="min-width: 30%;" *ngIf="currentSubscription.state == 'Hold'" (click)="activateSubscription(currentSubscription)">{{'SUBSCRIPTIONS.ACTIVATE' | translate}}</button>
        </div>
      </div>
    </div>

    <div class="">
      <div class="">
        <h5>{{'SUBSCRIPTIONS.ORDERHISTORY' | translate}}</h5>
        <span>{{'SUBSCRIPTIONS.LISTOFORDERS' | translate}}</span>

        <div class="history-table">
          <div class="table-responsive">
            <table id="basic-datatable" class="table table-centered dt-responsive nowrap datatables sortable no-footer dtr-inline">
              <thead class="border-rounded">
                <tr class="justify-content-center text-center">
                  <th sortable="order">{{'ORDERS.ORDER' | translate}}</th>
                  <th sortable="date">{{'ORDERS.DATE' | translate}}</th>
                  <th sortable="status">{{'ORDERS.STAT' | translate}}</th>
                  <th sortable="automaticPayment">{{'ORDERS.PAYMENT' | translate}}</th>
                  <th sortable="customer">{{'ORDERS.ACTION' | translate}}</th>
                </tr>
              </thead>
              <tbody>
               
                <tr class="text-center" *ngFor="let order of orders">
                  <td *ngIf="order.paymentStatus && order.orderNo && (order?.productType != 'service' || order.paymentStatus == 'paid')">
                    <a class="text-dark font-weight-bold">
                      <div class="d-flex flex-column">
                        <ngb-highlight [result]="order.orderNo"></ngb-highlight>
                        <span *ngIf="order?.refill" class="refill-order-badge">Refill</span>
                      </div>
                    </a>
                  </td>
                  <td *ngIf="order.paymentStatus && order.orderNo && (order?.productType != 'service' || order.paymentStatus == 'paid')">
                    <ngb-highlight *ngIf="order.date" [result]="order?.date.toDate() | date:'medium'"></ngb-highlight>
                  </td>
                 
                  
                  <td *ngIf="order.paymentStatus && order.orderNo && (order?.productType != 'service' || order.paymentStatus == 'paid')">
                    <div class="status d-flex justify-content-center">
                      <i *ngIf="order.paymentStatus === 'paid'" class="far fa-check-circle text-success"></i>
                      <i *ngIf="order.paymentStatus === 'Refunded'" class="fas fa-undo-alt W-50"></i>
                      <i *ngIf="order.paymentStatus === 'Chargeback'" class="far fa-times-circle text-danger w-50"></i>
                      <i *ngIf="order.paymentStatus === 'error'" class="far fa-times-circle text-danger"></i>
                      <i *ngIf="order.paymentStatus === 'pending'" class="far fa-clock text-warning"></i>
        
                      <div class="w-50 d-flex justify-content-start">
                        <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'paid'" [result]="'ORDERS.STATUS.PAID' | translate"></ngb-highlight>
                        <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'Refunded'" [result]="'ORDERS.STATUS.REFUNDED' | translate"></ngb-highlight>
                        <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'Chargeback'" [result]="'ORDERS.STATUS.CHARGEBACK' | translate"></ngb-highlight>
                        <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'error'" [result]="'ORDERS.STATUS.ERROR' | translate"></ngb-highlight>
                        <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'pending'" [result]="'ORDERS.STATUS.PENDING' | translate"></ngb-highlight>
                      </div>
                    </div>
                  </td>
      
                  <td *ngIf="order.paymentStatus && order.orderNo && (order?.productType != 'service' || order.paymentStatus == 'paid')">
                    <ngb-highlight class="ml-2" *ngIf="order.automaticPayment" [result]="'ORDERS.STATUS.AUTOMATIC' | translate"></ngb-highlight> 
                    <ngb-highlight class="ml-2" *ngIf="!order.automaticPayment" [result]="'ORDERS.STATUS.MANUAL' | translate"></ngb-highlight> 
                  </td>
                  <td *ngIf="order.paymentStatus && order.orderNo && (order?.productType != 'service' || order.paymentStatus == 'paid')">
                    <ngb-highlight class="ml-2" *ngIf="order.paymentStatus === 'pending'" [result]="'ORDERS.STATUS.NEEDPATIENTAPPROVAL' | translate" ></ngb-highlight>                
                    <ngb-highlight class="ml-2" *ngIf="order.paymentStatus === 'paid'" [result]="'ORDERS.STATUS.NOACTION' | translate" ></ngb-highlight>                
                    <ngb-highlight class="ml-2" *ngIf="order.paymentStatus === 'error'" [result]="'ORDERS.STATUS.CONTACTSUPPORT' | translate" ></ngb-highlight>                
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>




<ng-template #upgradeRef let-modal>
  <div class="w-100 d-flex justify-content-end">
    <i class="fa fa-times" (click)="modal.close(); modalRef = undefined;"></i>
  </div>
  <div class="">
    <h3 class="upgrade-title">{{'SUBSCRIPTIONS.UPGRADEYOURPLAN' | translate}}</h3>
    <p class="text-center">{{'SUBSCRIPTIONS.CHOOSEAPLAN' | translate}}</p>
    <div class="w-100 d-flex justify-content-center">
      <span class="billing-term">{{'SUBSCRIPTIONS.MONTHLY' | translate}}</span>
      <label for="switch" class="switch">
        <input id="switch" type="checkbox" [checked]="upgradeBillingTerm == 90" (click)="plansList(currentSubscription)">
        <span class="slider round"></span>
      </label>
      <span class="billing-term">{{'SUBSCRIPTIONS.QUARTERLY' | translate}}</span>
    </div>
  </div>

  <div class="w-100 d-flex subscriptions flex-grid-thirds flex-wrap justify-content-center">
    
    <div *ngFor="let subscription of planList" class="subscription-card">
      <div class="w-100 d-flex justify-content-end">
        <span *ngIf="currentSubscription.productID == subscription.id" class="current-badge">
          <svg width="15" height="15" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 15a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"></path>
            <path d="m12.002 15 3.4 5.889L17 17.657l3.598.232-3.4-5.889"></path>
            <path d="m6.802 12-3.4 5.889L7 17.657l1.598 3.232 3.4-5.889"></path>
          </svg> 
          {{'SUBSCRIPTIONS.CURRENT' | translate}}
        </span>
      </div>

      <div class="d-flex flex-column mt-3 mx-auto">
        <li class="d-flex"><span class="ml-2">{{ subscription?.atr0 | translation:currentUser.preferedLang}}</span></li>
        <li class="d-flex"><span class="ml-2">{{ subscription?.atr1 | translation:currentUser.preferedLang}}</span></li>
        <li class="d-flex"><span class="ml-2">{{ subscription?.atr3 | translation:currentUser.preferedLang}}</span></li>
        <li class="d-flex" [ngClass]="{'invisible': subscription.tabletsQty == 0}"><span class="ml-2">{{ subscription.qty}} {{'CONSULTATIONS.TABLETS' | translate}}</span></li>
      </div>
      <div class="text-center">
        <span><span class="price orangeText font-size-24">{{subscription.price | currency:'USD'}}</span><br>/{{'SUBSCRIPTIONS.EVERY' | translate}} {{subscription?.refillIntervalDays}} {{'SUBSCRIPTIONS.DAYS' | translate}}</span><br>
      </div>
      <div class="w-100 mt-3" style="text-align: center;">
        <button *ngIf="currentSubscription.productName !== subscription.name" class="button mt-2" (click)="openUpgradeNotificationModal(subscription)">{{'SUBSCRIPTIONS.UPGRADETOTHISPLAN' | translate}} <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="currentSubscription">
    <div *ngFor="let item of filteredProducts | keyvalue">
      <b class="font-size-24 orangeText bold">{{item.key | translation:'en'}}:</b>
      <div *ngFor="let item1 of filteredProducts[item.key]">
        <b class="ml-2 font-size-18 cyan bold">{{item1.name | translation:'en'}}:</b>
        <div class="d-flex flex-wrap justify-content-center align-items-center">
          <div *ngFor="let item2 of item1.products" class="subscription-card" [ngClass]="{'borderSelected': currentPromoProduct.name == item2.name, 'borderNoSelection': currentPromoProduct.name != item2.name}">
            <b class="font-size-18 text-center cyan">{{item2.qty | translation:'en'}} {{'CONSULTATIONS.TABLETS' | translate}}</b>
            <div class="text-center">
              <span><span class="price orangeText font-size-24">{{item2.price | currency:'USD'}}</span><br>/{{'SUBSCRIPTIONS.EVERY' | translate}} {{item2?.refillIntervalDays}} {{'SUBSCRIPTIONS.DAYS' | translate}}</span><br>
            </div>
            <div class="w-100 mt-3" style="text-align: center;">
              <button *ngIf="currentPromoProduct.name !== item2.name" class="button mt-2" (click)="openUpgradeNotificationModal(item2)">{{'SUBSCRIPTIONS.UPGRADETOTHISPLAN' | translate}} <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
              <button *ngIf="currentPromoProduct.name == item2.name" style="background-color: #f37228;" class="button mt-2">{{'SUBSCRIPTIONS.ACTUALPLAN' | translate}}</button>
            </div>
          </div>    
        </div>
      </div>
    </div>
  </div> -->
</ng-template>

<ng-template #actionRef let-modal>
  <div class="w-100 d-flex justify-content-end">
    <i class="fa fa-times" (click)="modal.close(); modalRef = undefined;"></i>
  </div>

  <h4 class="mt-3">Before you continue</h4>
  <h4 class="">What do you want to do with your subscription</h4>

  <!-- <div class="grid-wrapper">
    <div class="radio-card" *ngFor="let option of options">
      <div class="card-content-wrapper mb-2">
        <input type="radio" name="radio-card" id="{{option}}" value="{{option}}" [(ngModel)]="chooseOptions" (change)="selectOption($event)">
        <label class="custom-control-label" for="{{option}}">
          {{option | translate}}
        </label>
      </div>
    </div>
  </div> -->
  <div class="grid-wrapper">
    <label for="{{option}}" class="radio-card animate pop" *ngFor="let option of options">
      <input hidden type="radio" name="radio-card animate pop" id="{{option}}" value="{{option}}" [(ngModel)]="chooseOptions" (change)="selectOption($event)"/>
      <div class="card-content-wrapper" >
        <div class="w-100 d-flex">
          <span class="check-icon"></span>
        </div>
        <div class="card-content">
          <div class="d-flex flex-column mt-1">
            <h4 class="mt-2 mx-auto">{{option | translate}}</h4>
          </div>
        </div>
      </div>
    </label>
  </div>
  <div class="d-flex justify-content-center">
    <button class="btn btn-info button fit-content" role="button" (click)="modal.close(); nextAction()" [disabled]="chooseOptions === ''">{{'SUBSCRIPTIONS.CONTINUE' | translate | uppercase}}</button>
  </div>
</ng-template>


<ng-template #upgradeNotification>
  <div class="d-flex w-100">
    <div class="circle mr-2">
      <svg width="25" height="25" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 5a2 2 0 1 1 4 0 7 7 0 0 1 4 6v3a4 4 0 0 0 2 3H4a4 4 0 0 0 2-3v-3a7 7 0 0 1 4-6"></path>
        <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
      </svg>
    </div>
    <!-- <div class="flex-column ml-3 w-100">
      <h3 style="text-align:start;">{{'SUBSCRIPTIONS.BEFORECONTINUE' | translate}} </h3>
      <span>{{'SUBSCRIPTIONS.UPGRADINGTOTHISPLAN' | translate}} </span>
    </div> -->
    <div>
      <h3 style="text-align:start;">Confirm Upgrade</h3>
      <span *ngIf="currentSubscription.productID != possibleUpgradePlan.subProductID && possibleUpgradePlan.subProductID">Your plan will be modified from {{currentSubscription.productName | translation:currentUser.preferedLang}} to {{availablePlans[0].name | translation:currentUser.preferedLang }}.</span>
      <span>Your price will change from {{currentSubscription.productPrice | currency:'USD'}} to {{possibleUpgradePlan.price | currency:'USD'}} on {{currentSubscription.orderDate | date:'MMMM dd, yyyy':'':currentUser.preferedLang | titlecase}}</span>
      <span>Your pill quantity will change from {{currentPromoProduct.qty}} to {{possibleUpgradePlan.qty}} </span>
    </div>
    <!-- <div class="">
      <i class="fas fa-times"></i>
    </div> -->
  </div>

  <div class="d-flex justify-content-end">
    <button class="btn" (click)="modalRef.close(); modalRef = undefined;">{{'SUBSCRIPTIONS.CANCEL' | translate}} </button>
    <button class="btn" (click)="upgrade()">{{'SUBSCRIPTIONS.CONFIRM' | translate}} </button>
  </div>
</ng-template>
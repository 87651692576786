import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { snap } from 'gsap';
import { LaboratoryBase, MedicalAnalysisTemplate } from '../../interfaces/laboratories';
import { Document } from '../interfaces';
import { v4 as uuidv4 } from 'uuid';

interface LaboratoryAnalysis {
  // Resource Type
  resourceType: string; // The type of resource, which should be "LaboratoryAnalysis"
  
  // Identifier
  id: string; // Unique identifier for the laboratory analysis
  
  // Status and Category
  status: string; // Status of the laboratory analysis (e.g., "final", "preliminary", etc.)
  category: string; // Category of the laboratory analysis (e.g., "chemistry", "hematology", etc.)
  
  // Code Details
  code: {
    system: string; // The system that defines the meaning of the code
    code: string; // The code that represents the laboratory test (e.g., LOINC code)
    display: string; // Human-readable display of the laboratory test
  };
  
  // Subject of the Analysis
  subject: {
    reference: string; // Reference to the patient who is the subject of the analysis
  };
  
  // Date and Time of the Analysis
  effectiveDateTime: string; // Date and time when the analysis was performed
  
  // Result Value and Unit
  valueQuantity: {
    value: number; // The numerical result of the analysis
    unit: string; // The unit of measurement for the result (e.g., "mg/dL", "mmol/L", etc.)
  };
  
  // Optional Fields
  
  // Interpretation of the Analysis
  interpretation?: string; // An interpretation of the result (e.g., "normal", "abnormal", etc.)
  
  // Performer of the Analysis
  performer?: {
    reference: string; // Reference to the individual or organization that performed the analysis
    display: string; // Human-readable display of the performer
  };
  
  // Conclusion or Summary
  conclusion?: string; // A conclusion or summary of the analysis
}



@Injectable({
  providedIn: 'root'
})
export class LaboratoriesService {

  constructor(
    private db: AngularFirestore,
  ) { }


  getLabs(): Observable<any[]> {
    return this.db.collection("labAnalysisTemplates").valueChanges();
  }

  getDiagnosticTemplates(): Observable<any[]> {
    return this.db.collection("diagnosticTemplates").valueChanges();
  };

  getUniqueCategories(consultType): Observable<{ category: string, image: string }[]> {
    return this.getLabs().pipe(
      map((data: any[]) => {
        const uniqueCategoriesMap = new Map<string, { category: string, image: string, categoryTranslation: string }>();

        for (const lab of data) {
          if(lab.condition.includes(consultType)) {
            const categories = Array.isArray(lab.category) ? lab.category : [lab.category];
            for (const category of categories) {
              if (!uniqueCategoriesMap.has(category)) {
                uniqueCategoriesMap.set(category, { category, image: lab.image, categoryTranslation: lab.categoryTranslation });
              }
            }
          }
        }

        return Array.from(uniqueCategoriesMap.values());
      })
    );
  }


  getLabsChosen(consultID: string): Observable<any[]> {
    return this.db.collection("consultation").doc(consultID).collection("LabsChosen").valueChanges();
  }



  getGeneralLabs(): Observable<any[]> {
    return this.getLabs().pipe(
      map((data: any[]) => {
        const generalLabs = data.filter(lab => lab.condition.includes("Preventive Health"));
        return generalLabs;
      })
    );
  }




  //! Questions
  //* The labs, individual documents, or single document with all the labs?
  //* This will be critical to know how to structure the data, and how to query it

  /*

   Based on discussion ......
    1. We should create a template for each lab analysis
    2. Patient will upload the results of lab analysis
    3. Based on the tests we have to use the templates and fill the data MANUALLY....


  */


    async uploadLabsStorage(uid: string, file: File): Promise<string> {
      const storage = getStorage();
      const storageRef = ref(storage, `users/${uid}/labAnalysis/${file.name}`);
    
      return new Promise((resolve, reject) => {
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Handle progress, bytesTransferred, etc.
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            // Handle unsuccessful uploads
            reject(error);
          },
          () => {
            // Handle successful uploads on complete
            // Get the download URL for the uploaded file
            getDownloadURL(storageRef)
              .then((url) => {
                this.storeLabs(uid, file, url);
                resolve(url);
              })
              .catch((error) => {
                // Handle any errors
                reject(error);
              });
          }
        );
      });
    }

    async storeLabs(uid: string, file:File, url:string){
      let lab: Document = {
        // id: uuidv4(),
        // uid: uid,
        // fileName: file.name, // Original file name of the PDF
        // storagePath: `users/${uid}/labAnalysis/${file.name}`, // Storage path of the PDF in Firebase Storage
        // downloadUrl: url, // Download URL of the PDF
        // uploadedAt: new Date(), // Timestamp of when the PDF was uploaded
        // lastModified: new Date(file.lastModified) // Timestamp of when the PDF was last modified

        id: uuidv4().toString(),
        pdf: await this.convertFile(file),
        url: url,
        pdfName: file.name,
        uploaded: new Date(),
        referenceID: "reference.id",
        referenceIDModel: 'prescription',
        docFor: 'Lab Results',
        description: 'Lab Results',
        uidPatient: uid,
        uidPractitioner: "reference.uidPractitioner"
      }
      this.db.collection("Documents").doc(lab.id).set(lab);
    }

    storeTestResults(results:MedicalAnalysisTemplate, documentRef:LaboratoryBase){
      let analysis = Object.assign(results, documentRef, { id:uuidv4() });
      this.db.collection("laboratory_results", ref => ref.where("storagePath", "==", documentRef.storagePath)).valueChanges().subscribe((data:any[]) => {
        if(data.length == 0){
          this.db.collection("laboratory_results").doc(analysis.id).set(analysis);
        } else {
          this.db.collection("laboratory_results").doc(data[0].id).update(analysis);
        }
      });
    }
    



    isAnalysisValid(analysisValidPeriod:number, laboratoryAnalysis): boolean {
      const currentDate = new Date(); // Current date
      // const { dateOfTest, analysisValidPeriod } = laboratoryAnalysis;
      // Calculate the expiration date based on the analysisValidPeriod
      const expirationDate = new Date(laboratoryAnalysis.uploadedAt.toDate());
      expirationDate.setDate(expirationDate.getDate() + analysisValidPeriod);
      // Check if the current date is before or equal to the expiration date
      return currentDate <= expirationDate;
    }



    getLabDocuments(uid:string): Observable<any[]> {
      return this.db.collection("laboratories", ref => ref.where("uid", "==", uid)).valueChanges();
    }

    getLabResults(uid:string): Observable<any[]> {  
      return this.db.collection("laboratory_results", ref => ref.where("uid", "==", uid)).valueChanges();
    }


    formatFileSize(size: number): string {
      if (size < 1024) {
        return size + ' bytes';
      } else if (size < 1048576) {
        return (size / 1024).toFixed(2) + ' KB';
      } else if (size < 1073741824) {
        return (size / 1048576).toFixed(2) + ' MB';
      } else {
        return (size / 1073741824).toFixed(2) + ' GB';
      }
    }

    convertFile(file: File): Promise<string> {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
    
        reader.onload = (event) => {
          if (event.target && event.target.result) {
            const base64String = btoa(event.target.result.toString());
            resolve(base64String);
          } else {
            reject('Failed to read the file');
          }
        };
    
        reader.onerror = (error) => {
          reject('Error reading the file');
        };
    
        reader.readAsBinaryString(file);
      });
    }
}



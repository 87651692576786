<div class="main d-flex flex-column customHeight" [ngClass]="{'justify-content-between': styling == 'justify-content-between', 'justify-content-start': styling == 'justify-content-start', 'background-cyan': background == 'cyan'}">
  <div class="form-group"  style="width: 90%; margin: 0 5% !important;">
    <i (click)="back.emit()" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
  </div>
  <div>
    <h2>{{'SOCIAL-GENDERS.QUESTION' | translate}}</h2>
    <p class="w-50 mx-auto social-gender">{{'SOCIAL-GENDERS.INFO' | translate}}</p>
    
    <div class="grid-wrapper grid-col-auto" style="max-width: 750px; margin: auto;">
      <label for="{{gender}}" class="radio-card" *ngFor="let gender of social_genders; let i = index">
        <input type="radio" name="gender" id="{{gender}}" value="{{social_genders_value[i]}}" (click)="emit($event.target.value)"/>
        <div class="card-content-wrapper">
          <span class="check-icon"></span>
          <!-- <h5 class="w-100">{{gender | translate}}</h5> -->
          <span class="w-100 text-center font-size-20">{{gender | translate}}</span>
        </div>
      </label>
    </div>
  </div>
  <div>
  </div>
</div>
<div class="col-lg-12">
  <div>
    <button class="btn btn-primary" (click)="manageLabLocations(manageLabs)">{{'ADMINLABS.MANAGELABS' | translate}}</button>
  </div>
  <div class="card-body">
    <h3 class="mb-0 text-center bold" style="font-size: 24px !important;"> {{'ADMINLABS.ALLLABS' | translate}}</h3>
    <div class="col-sm-6 col-md-6 mx-auto">
      <div id="tickets-table_filter" class="dataTables_filter position-relative">
        <input type="text" name="searchTerm" class="form-control searchbar form-control-sm"
          aria-controls="tickets-table" placeholder="{{'NOTIFICATIONS.SEARCH' |  translate}}"
          [(ngModel)]="searchText" />
      </div>
    </div>
    <div class="d-flex mt-3" style="justify-content: space-evenly;">
      <a *ngFor="let status of translateStatus; let i = index"
        [ngClass]="{'filteredConsult': filterConsults == prescriptionStatus[i]}"
        (click)="filterConsults = prescriptionStatus[i]">{{status | translate}} <p
          [ngClass]="{'underline': filterConsults == prescriptionStatus[i]}"></p>
      </a>
    </div>
  </div>
  <div class="card" style="box-shadow: 0px 0px 0px !important;">
    <div class="card-body padding-bottom pt-0">

      <div id="Progress-task" class="task-list">
        <div class="dndPlaceholder" dndPlaceholderRef></div>
        <div class="d-flex flex-wrap">
          <div class="radio-card uncheckedProduct"
            *ngFor="let presc of allPrescriptions; let i = index" [ngClass]="{'disable-div': filterConsults != 'All' && presc.sentLab != filterConsults}">
            <div  *ngIf="presc.sentLab == filterConsults || filterConsults == 'All'" class="card-body" style="padding: 10px;" class="">
              <div class="card-content">
                <div class="sections ml-auto" style="width:fit-content !important; text-align-last: end">
                  <span class="prescription-status bold" (click)="openPrescriptionPDF(presc)"
                    [ngClass]="{'badge-soft-danger': presc.status === 'Unsigned', 'badge-soft-success': presc.status === 'Signed'}">{{'CONSULT-PRESCRIPTIONS.SIGNED'
                    | translate }}</span>
                </div>
                <div class="d-flex mb-2 align-items-center">
                  <div class="d-flex img">
                    <img *ngIf="presc.prescription_type == 'Lab'" src="{{labLogo}}" alt="" />
                  </div>
                  <div>
                    <h3 *ngIf="presc.prescription_type == 'Lab'" class="prescription-type m-0 bold"
                      style="text-align: start;">{{'CONSULT-PRESCRIPTIONS.LABORATORY' | translate }}</h3>
                    <p class="m-0">{{'CONSULT-PRESCRIPTIONS.CREATED' | translate}}: {{presc.date.toDate() | date:'MMMM
                      dd, yyyy hh:mm aa':'':currentLang}}</p>
                    <p *ngIf="presc?.signedDate?.seconds" class="m-0">{{'CONSULT-PRESCRIPTIONS.SIGNED' | translate}}:
                      {{presc.signedDate.toDate() | date:'MMMM dd, yyyy hh:mm aa':'':currentLang}}</p>
                    <!-- <div *ngFor="let items of prescriptionsItems[i];"
                      class="prescriptionItems d-flex flex-column justify-content-center">
                      <p class="mb-0">- {{items.labName}}</p>
                    </div> -->
                  </div>
                </div>
                <!-- <div *ngIf="labPdfs[i] && labPdfs[i].length > 0" class="mt-2">
                  <p class="m-0 bold cyan">{{'CONSULTATIONS.UPLOADEDDOCS' | translate}}</p>
                  <div *ngFor="let pdf of labPdfs[i]">
                    <button class="orangeText" style="background: transparent;border: 0; text-decoration: underline;"
                      (click)="prescriptionID = presc.ID; currentPdf = pdf.data().pdf; showPDF(showPdf)">{{pdf.data().pdfName}}</button>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #internalPrescription let-modal>
  <div class="row mt-3 product-wizard">
    <div class="col-lg-12">
      <div class="row justify-content-between pl-5 pr-5">
        <h2>{{'CONSULT-PRESCRIPTIONS.PRESCRIPTION' | translate }}</h2>
        <i class="ri-close-fill" style="font-size: 23px;" (click)="modal.close()"></i>
      </div>
      <ng-template [ngTemplateOutlet]="pdfViewer"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #pdfViewer let-modal>
  <div class="pharmacy-step">
    <div *ngIf="!showPreview" id="prescription-preview" class="prescription-preview" style="height: 25%;">
      <span>{{'CONSULT-PRESCRIPTIONS.PREVIEW' | translate}}</span> 
      
      <div class="pdf-preview" (click)="showPreview = true;">
        <button class="btn btn-primary">{{'CONSULT-PRESCRIPTIONS.VIEWPDF' | translate}}</button>
      </div>
    </div>
  
    <div  *ngIf="!showPreview" class="pharmacy-selection">
      <ng-template [ngTemplateOutlet]="sendToLab"></ng-template>
    </div>
    
    <div *ngIf="showPreview" class="preview-lg animate pop">
      <div style="align-items: center; font-size: 18px;" class="d-flex" (click)="showPreview = false">
        <i class="fa fa-angle-left"></i>
        <span class="ml-3">{{'CONSULT-PRESCRIPTIONS.BACK' | translate}}</span>
      </div>
      <ngx-extended-pdf-viewer class="animate pop" *ngIf="base64" [base64Src]="base64" backgroundColor="#e8e8ef"
        [showZoomButtons]="true"  [useBrowserLocale]="true"
        [sidebarVisible]="false" [showHandToolButton]="false" [zoom]="'50%'">
      </ngx-extended-pdf-viewer>
    </div>
  </div>
</ng-template>  

<ng-template #sendToLab>
  <div *ngIf="!prescriptionRef.sentLab" class="details">
    <div class="section" style="width: 90%;">
      <div class="options" >
        <div class="external">
          <span>{{'CONSULT-PRESCRIPTIONS.SEARCHLABLOCATIONS' | translate}}</span>
          <ng-select placeholder="{{'CONSULT-PRESCRIPTIONS.SEARCHPLACEHOLDER' | translate }}" [loading]="loadingLabs"
            [multiple]="false"  (keyup)="searchLabLocations($event)" (change)="selectLab($event)">
            <ng-option *ngFor="let lab of labLocations" [value]="lab">{{lab['name.full']}}</ng-option>
          </ng-select>
        </div>
      </div>
      <button class="btn btn-primary" (click)="sendLaboratory(); modalService.dismissAll();">{{'CONSULT-PRESCRIPTIONS.SENDTOLAB' | translate}}</button>
    </div>
    <div style="text-align-last: start;">
      <h2>{{'CONSULT-PRESCRIPTIONS.LABCHOSENDETAILS' | translate}}</h2>
      <h5>{{'CONSULT-PRESCRIPTIONS.NAME' | translate}}: {{prescriptionRef.laboratoryDetails['name.full']}}</h5>
      <h5>{{'CONSULT-PRESCRIPTIONS.ADDRESS' | translate}}: {{prescriptionRef.laboratoryDetails['addr_practice.full']}}</h5>
      <h5>{{'CONSULT-PRESCRIPTIONS.PHONE' | translate}}: {{prescriptionRef.laboratoryDetails['addr_practice.phone']}}</h5>
      <h5>{{'CONSULT-PRESCRIPTIONS.NPI' | translate}}: {{prescriptionRef.laboratoryDetails['NPI']}}</h5>
      <h5>{{'CONSULT-PRESCRIPTIONS.EMAIL' | translate}}: {{prescriptionRef.laboratoryDetails['email']}}</h5>
    </div>
  </div>

  <div *ngIf="prescriptionRef.sentLab" class="content" style="text-align: -webkit-center;">
    <div class="check-wrap"></div>
    <h2>{{'CONSULT-PRESCRIPTIONS.PRESCSENT' | translate}}</h2>
    <p *ngIf="prescriptionRef.pharmacy" class="sent-to">{{'CONSULT-PRESCRIPTIONS.PRESCWASSENT' | translate}}<span class="pharmacy"></span>{{'CONSULT-PRESCRIPTIONS.PHARMACY' | translate}}</p>
    <!-- <div class="prescription-contents">
      <div *ngFor="let product of sentProducts" class="products d-flex">
        <span>{{product.labName}}</span><br>
      </div>
    </div> -->

    <div style="text-align-last: start;">
      <h2>{{'CONSULT-PRESCRIPTIONS.LABCHOSENDETAILS' | translate}}</h2>
      <h5>{{'CONSULT-PRESCRIPTIONS.NAME' | translate}}: {{prescriptionRef.laboratoryDetails['name.full']}}</h5>
      <h5>{{'CONSULT-PRESCRIPTIONS.ADDRESS' | translate}}: {{prescriptionRef.laboratoryDetails['addr_practice.full']}}</h5>
      <h5>{{'CONSULT-PRESCRIPTIONS.PHONE' | translate}}: {{prescriptionRef.laboratoryDetails['addr_practice.phone']}}</h5>
      <h5>{{'CONSULT-PRESCRIPTIONS.NPI' | translate}}: {{prescriptionRef.laboratoryDetails['NPI']}}</h5>
      <h5>{{'CONSULT-PRESCRIPTIONS.EMAIL' | translate}}: {{prescriptionRef.laboratoryDetails['email']}}</h5>
    </div>

    <div class="d-flex mt-3 justify-content-center">
      <button class="btn btn-primary" (click)="modalService.dismissAll()">{{'CONSULT-PRESCRIPTIONS.CLOSE' | translate}}</button>
    </div>
  </div>
</ng-template>


<ng-template #manageLabs let-modal>
  <div class="details p-2">
    <i class="ri-close-fill" style="font-size: 23px; float: right;" (click)="modal.close()"></i>
    <div class="section" style="width: 90%;">
      <div class="options" >
        <div class="external">
          <span>{{'CONSULT-PRESCRIPTIONS.SEARCHLABLOCATIONS' | translate}}</span>
          <ng-select placeholder="{{'CONSULT-PRESCRIPTIONS.SEARCHPLACEHOLDER' | translate }}" [loading]="loadingLabs"
            [multiple]="false"  (keyup)="searchLabLocations($event)" (change)="selectLabToManage($event)">
            <ng-option *ngFor="let lab of labLocations" [value]="lab">{{lab['name.full']}}</ng-option>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="mt-2" style="text-align-last: start;">
      <h2>{{'CONSULT-PRESCRIPTIONS.LABCHOSENDETAILS' | translate}}</h2>
      <h5>{{'CONSULT-PRESCRIPTIONS.NAME' | translate}}: <input type="text" name="searchTerm" class="form-control searchbar form-control-sm"
        aria-controls="tickets-table" [(ngModel)]="labToManage['name.full']" /></h5>
      <h5>{{'CONSULT-PRESCRIPTIONS.ADDRESS' | translate}}: <input type="text" name="searchTerm" class="form-control searchbar form-control-sm"
        aria-controls="tickets-table" [(ngModel)]="labToManage['addr_practice.full']" /></h5>
      <h5>{{'CONSULT-PRESCRIPTIONS.PHONE' | translate}}: <input type="text" name="searchTerm" class="form-control searchbar form-control-sm"
        aria-controls="tickets-table" [(ngModel)]="labToManage['addr_practice.phone']" /></h5>
      <h5>{{'CONSULT-PRESCRIPTIONS.NPI' | translate}}: <input type="text" name="searchTerm" class="form-control searchbar form-control-sm"
        aria-controls="tickets-table" [(ngModel)]="labToManage['NPI']" /></h5>
      <h5>{{'CONSULT-PRESCRIPTIONS.EMAIL' | translate}}: <input type="text" name="searchTerm" class="form-control searchbar form-control-sm"
        aria-controls="tickets-table" [(ngModel)]="labToManage['email']" /></h5>
    </div>
    <button class="btn btn-primary" (click)="saveLab(); modal.close()">{{'CONSULT-PRESCRIPTIONS.SAVE' | translate}}</button>
  </div>
</ng-template>
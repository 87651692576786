import { formatDate } from "@angular/common";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AuditTraceService } from "src/app/core/services/audit-trace.service";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { EmailsService } from "src/app/core/services/emails.service";
import { FirebaseService } from "src/app/core/services/firebase.service";
import { AcceptaPaymentInitiation, Blocked, CompleteOrder, createOrder, Customer, MakePaymentTracer, Profile, Transaction } from "src/app/core/services/interfaces";
import { PaymentService } from "src/app/core/services/payment.service";
import { TranslationPipe } from "src/app/translation.pipe";
import { byPassPayments, environment, testProgrammerAccounts } from "src/environments/environment";
import { v4 as uuidv4 } from 'uuid';


import firebase from 'firebase/compat/app';
import { getFunctions, httpsCallable } from "firebase/functions";

const app = firebase.initializeApp(environment.firebaseConfig);
const functions = getFunctions(app);

export class Payment {
  //acceptaPaymentInitiation
  data: AcceptaPaymentInitiation = {
    amount: "",
    customerNumber: "",
    checkNumber: "",
    invoiceNumber: "",
    orderID: "",
    uid:"",
  }

  consultID:string;
  order: CompleteOrder;
  orderLines: any[];
  orderTotal:string;
  //blocked
  blocked:Blocked = {
    blocked: false,
    blockedDate: new Date(Date.now()),
    blockedReason: null,
    attempts: 0,
  }

  //
  transaction: Transaction = {
    amount: "0.00",
    cardType: "CREDIT",
    status: "",
    date: new Date(Date.now()),
    consultationId: "",
    salesOrderID: "",
    id: "",
    uid: "",
    paymentStatus: "",
    approved: false,
  }

  customer: Customer = {
    uid: "",
    partnerID: "",
    accountType: "",
    email: "",
  }

  testAccounts = testProgrammerAccounts;
  bypassPayments = byPassPayments;

  currentUser:Profile;
  
  paymentAttempts: number;
  paymentRetrialTime: number;

  translationPipe = new TranslationPipe()

  constructor(private paymentService: PaymentService,
    private authService: AuthenticationService,
    private afs: AngularFirestore,
    private firebaseService: FirebaseService, 
    private emailService: EmailsService,
    private auditTraceService: AuditTraceService,   
    ) 
  {}

  async createAcceptaPaymentInitiation(){
    const user:any = await this.authService.getUserProfile(this.data.uid);
    let remoteConfig = await this.authService.getRemoteConfig();
    let paymentStatus: string = "pending";
    return new Promise<any>(async (resolve, reject) => {

      /** 
       We add the processing field true to make the button disabled and prevent multiple button clicks 
       that could send more than one payment   
      */

      this.afs.collection("orders").doc(this.order.id).update({processing: true});
    
      let cardRef:any = await this.getAcceptaCard(this.data.customerNumber);
      const nextAttempt:any = (cardRef?.blockedDate?.seconds + (this.paymentRetrialTime * 3600)) * 1000;
    
      this.data.orderID = this.order.id;
      this.transaction.salesOrderID = this.order.orderNo;
      const testUser = this.paymentValidateTest(this.customer.email);
      if(testUser == false && Number(this.transaction.amount) !== 0 && Number(this.transaction.amount) > 0){
        if(cardRef.attempts >= this.paymentAttempts){
          this.blocked.blocked = true;
          this.blocked.blockedDate = new Date(Date.now());
          this.blocked.blockedReason = "Too many failed attempts";
          this.blocked.attempts = cardRef.attempts;
          if(Date.now() < nextAttempt){
            this.blocked.blockedDate = cardRef.blockedDate;
            this.transaction.amount = this.data.amount.toString();
            this.transaction.cardType = "CREDIT";
            this.transaction.status = "success";
            this.transaction.date = new Date(Date.now());
            this.transaction.consultationId = "";
            this.transaction.salesOrderID = "";
            this.transaction.id = "";
            this.transaction.uid = this.customer.uid;
            this.transaction.paymentStatus = "pending";
            this.transaction.approved = false;
            this.afs.collection("users").doc(this.customer.uid).collection("wallet").doc(this.data.customerNumber).update(this.blocked);
            if(cardRef.attempts == 2){
              this.failedAttemptMail(cardRef)
              this.afs.collection("orders").doc(this.order.id).update({processing: false});
            }
            const settings:any = await this.authService.getRemoteConfig();
            let res = {
              value: "blocked",
              cardRef: cardRef,
              nextAttempt: this.blocked.blockedDate ? formatDate(new Date((this.blocked.blockedDate.seconds + (settings.paymentRetrialTime * 3600)) * 1000), 'MMMM d, y hh:mm a zzzz', user.preferedLang) : formatDate(new Date((Date.now() + (settings.paymentRetrialTime * 3600)) * 1000), 'MMMM d, y hh:mm a zzzz', user.preferedLang)
            }
            resolve(res);
          }else{
            this.blocked.attempts = 0;
            this.blocked.blocked = false;
            this.blocked.blockedDate = cardRef.blockedDate;
            this.afs.collection("users").doc(this.customer.uid).collection("wallet").doc(this.data.customerNumber).update(this.blocked).then(() => {
              this.createAcceptaPaymentInitiation();
            });
          }
        }else{
          //TODO change of functions was made here

          this.paymentService.createAcceptaPaymentInitiation(this.data, cardRef, user, this.order, remoteConfig, this.orderLines).subscribe(async (res:any) => {
            if(res.Code == "9999" || res.ErrorMessage == "Decline" || res.ErrorMessage == "Communication error" || res.ErrorMessage == "Account information not found" || res.ErrorMessage !== ""){
              res.attempts += 1;
              cardRef.attempts += 1;
              this.blocked.attempts = cardRef.attempts;
              this.transaction.date = new Date(Date.now());
              this.transaction.status = "error";
              paymentStatus = "pending";
              resolve("failed");
            }else{
              res.attempts = 0;
              cardRef.attempts = 0;
              this.blocked.attempts = 0;
              this.transaction.approved = true;
              this.transaction.date = new Date(Date.now());
              this.transaction.status = "success";
        
              resolve("success");
            }
            this.transaction.amount = Number(this.transaction.amount);
            this.afs.collection("users").doc(this.customer.uid).collection("wallet").doc(this.data.customerNumber).update(this.blocked).then(async res => {
              const settings:any = await this.authService.getRemoteConfig();
              if(cardRef.attempts == 2){
                let res = {
                  value: "blocked",
                  cardRef: cardRef,              
                  nextAttempt:  formatDate(new Date((this.blocked.blockedDate.seconds + (settings.paymentRetrialTime * 3600)) * 1000), 'MMMM d, y hh:mm a zzzz', user.preferedLang),
                }
                resolve(res);
                this.failedAttemptMail(cardRef);
                this.afs.collection("orders").doc(this.order.id).update({processing: false});
              }
            });
          });
        }
      }
      if(testUser == true || Number(this.transaction.amount) === 0){
        let attempt:any;
        if(cardRef == undefined){
          attempt = Object.assign(this.transaction, {AuthorizationCode:"411111111", Code: "0000", status:"success", paymentStatus:"paid", uid: this.customer.uid, id: uuidv4(), orderID: this.order.id, orderIDRef: Number(this.order.orderId)});
        }else{
          
          cardRef.attempts = 0;
          attempt = Object.assign(cardRef, this.transaction, {
            AuthorizationCode:"411111111", Code: "0000", 
            status:"success", 
            paymentStatus:"paid",
            uid: this.customer.uid, 
            id: uuidv4(), 
            orderID: this.order.id,
            orderIDRef: Number(this.order.orderId)
          });
        }
        this.blocked.attempts = 0;
        this.transaction.date = new Date(Date.now());
        this.transaction.status = "success";
        paymentStatus = 'paid';

        if(this.transaction.amount < Number(this.orderTotal)){
          paymentStatus = 'pending';
          this.afs.collection("orders").doc(this.order.id).update({processing: false});
        }
        if(this.transaction.amount == Number(this.orderTotal)){
          paymentStatus = 'paid'
          this.afs.collection("orders").doc(this.order.id).update({processing: true});
        }
       
        resolve("success");
        this.transaction.amount = Number(this.transaction.amount);
        if(this.transaction.amount > 0){
          this.afs.collection('creditCardPayments').doc(attempt.id).set(attempt);
          this.afs.collection("orders").doc(this.order.id).update({
            acceptaID: cardRef?.customerNumber == undefined ? "0000000000000000" : cardRef.customerNumber,
            last4: cardRef?.last4 == undefined ? "0000" : cardRef.last4,
            paymentStatus: paymentStatus,
            creditCardPaymentID: attempt.id,
            attempts: 0,
            paymentDate: paymentStatus == 'success' ? new Date(Date.now()) : null 
          });
        }
        if(this.transaction.amount == 0){
          this.afs.collection("orders").doc(this.order.id).update({
            acceptaID: cardRef?.customerNumber == undefined ? "0000000000000000" : cardRef.customerNumber,
            last4: cardRef?.last4 == undefined ? "0000" : cardRef.last4,
            paymentStatus: paymentStatus,
            attempts: 0,
            paymentDate: paymentStatus == 'success' ? new Date(Date.now()) : null,
            orderState: "done"
          });
        }
      }
    });
  }

  async getAcceptaCard(id:string){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('users').doc(this.customer.uid).collection('wallet').doc(id).valueChanges().subscribe((res:any) => {
        resolve(res.filter(x => x.active));
      });
    });
  }

  async failedAttemptMail(cardRef){
    const user:any = await this.authService.profile();
    const settings:any = await this.authService.getRemoteConfig();
    let userAdmin = {
      displayName: 'Dzeus Support',
      email: 'smtp@dzeus.com',
      uid: ''
    }    
    this.emailService.patientConsultEmails(userAdmin , user, {ID: this.transaction.consultationId}, "cardBlocked_" + user.preferedLang, '<en>Maximum payment attempts reached</en><es>Se alcanzó el máximo de intentos de pago</es>',settings, '', '', '', formatDate(new Date((cardRef.blockedDate.seconds + (settings.paymentRetrialTime * 3600)) * 1000), 'MMMM d, y hh:mm a zzzz', user.preferedLang), cardRef.last4, settings.paymentRetrialTime)
  }

  paymentValidateTest(email:any){
    let bool = false;
    if(this.testAccounts.includes(email) || this.bypassPayments.includes(email)){
      bool = true;
    }else{
      bool = false;
    }
    return bool;
  }

  async paymentOnCall(data, attempt, cardRef, order:any){
    const user:any = await this.authService.getUserProfile(order.uid);
    let remoteConfig = await this.authService.getRemoteConfig();
    const paymentOnCall = httpsCallable(functions, 'oncall-create-accepta-payment-initiation');
    let response:any;
    let tracer: MakePaymentTracer = {
      id: this.firebaseService.getDate(),
      date: new Date(),
      userName: "",
      userUID: this.order.uid,
      description: "",
      authorizedAdminName: "",
      authorizedAdminUID: ""  
    }

    if(this.currentUser.accountType == "admin"){
      tracer.id = this.firebaseService.getDate(),
      tracer.date = new Date();
      tracer.userName = user.firstName + " " + user.lastName1,
      tracer.userUID = user.uid;
      tracer.description = `Made a payment with ${this.order.acceptaID} credit card.`
      tracer.authorizedAdminName = this.currentUser.firstName + " " + this.currentUser.lastName1;
      tracer.authorizedAdminUID = this.currentUser.uid;
    }
    return new Promise<any>((resolve, reject) => {
      try {
        paymentOnCall({ 
          data: data,
          creditCardPaymentAttempt: attempt,
          cardRef: cardRef,
          remoteConfig: JSON.stringify(remoteConfig), 
          order: JSON.stringify(order),
          user: JSON.stringify(user),
          orderLines: JSON.stringify(this.orderLines),
        })
          .then((result:any) => {
            // Read result of the Cloud Function.
            let sanitizedMessage = result.data.result;
            // button.innerText = sanitizedMessage;

            if(sanitizedMessage === "success"){
              this.afs.collection("orders").doc(this.order.id).update({paymentStatus: "paid", orderState: "done"});
              resolve("success");
            }
  
  
            if(sanitizedMessage === "error"){
              if(this.currentUser.accountType == "admin"){
                tracer.description = `Failed to make a payment with ${this.order.acceptaID} credit card.`
                this.auditTraceService.makeAPayment(tracer, this.order.id)
              }
              // this.handlePaymentError()
              resolve("failed")
            }
  
            if(sanitizedMessage ==  'blocked'){
              // button.innerHTML = "Blocked!";
              // setTimeout(() => this.blockedCard(result.data.nextAttempt), 1000);  
              resolve("blocked")
            }
            return response;
          })    
        }
      catch(err){
      }
    }) 
  }
}
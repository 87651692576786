import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../environments/environment';

import { LayoutsModule } from './layouts/layouts.module';
import { PagesModule } from './pages/pages.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initFirebaseBackend } from './authUtils';

import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { FakeBackendInterceptor } from './core/helpers/fake-backend';

import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { DecimalPipe } from '@angular/common';
import { WizardComponent } from 'angular-archwizard';
import { NgxMaskModule, IConfig } from 'ngx-mask'
// import {ConnectionServiceModule} from 'ngx-connection-service';

// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';


import { QRCodeModule } from 'angularx-qrcode';
import { ARestructureComponentComponent } from './a-restructure-component/a-restructure-component.component';





//signin pad
// import { SignaturePad } from 'angular2-signaturepad';
// import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

if (environment.defaultauth === 'firebase') {
  initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expression
  FakeBackendInterceptor;
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
// const config: SocketIoConfig = { url: 'http://localhost:8000', options: {} };
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    PagesModule,
    LayoutsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireStorageModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(options),
    // NgxStripeModule.forRoot('pk_test_mcr7TDm7RnFKkpnklwk4PoEH00fFZBXVnT'),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    // SocketIoModule.forRoot(config),
    NoopAnimationsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    QRCodeModule,

//     ServiceWorkerModule.register('ngsw-worker.js', {
//       enabled: environment.production,
//     // Register the ServiceWorker as soon as the application is stable
//     // or after 30 seconds (whichever comes first).
//     registrationStrategy: 'registerWhenStable:30000'
// }), // ToastrModule added
    // ConnectionServiceModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
    DecimalPipe, WizardComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],

})
export class AppModule { }

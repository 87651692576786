import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-practitioner-schedule',
  templateUrl: './practitioner-schedule.component.html',
  styleUrls: ['./practitioner-schedule.component.scss']
})
export class PractitionerScheduleComponent implements OnInit {
  @Input() practitioner;
  @Input() editSchedule = false;
  @Input() admin = false;
  @Output() cancelEdit = new EventEmitter<Boolean>();

  warning = 'Time isnt acurrate'
  scheduleDays = [{ day: 'Sun', checked: false }, { day: 'Mon', checked: false }, { day: 'Tue', checked: false }, { day: 'Wed', checked: false }, { day: 'Thu', checked: false }, { day: 'Fri', checked: false }, { day: 'Sat', checked: false }];
  practitionerSchedule: any = [{schedule: []},{schedule: []},{schedule: []},{schedule: []},{schedule: []},{schedule: []},{schedule: []}]
  disableBtn = false;
  abscentDates: any = []

  constructor(private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.afs.collection('users').doc(this.practitioner.uid).valueChanges().subscribe((res: any) => {
      if(res.schedule){
        this.practitionerSchedule = res.schedule
        
        for(let i = 0; i < this.practitionerSchedule.length; i++) {
          if(this.practitionerSchedule[i].schedule.length > 0){
            this.scheduleDays[i].checked = true
          }
        }
      }
      if(res.abscentDates){
        this.abscentDates = res.abscentDates
      }
    })
  }

  addOption(day){
    let index = this.scheduleDays.indexOf(day)
    this.practitionerSchedule[index].schedule.push({start: [''], end: ['']})
    this.disableBtn = true
  }

  addDate(){
    this.abscentDates.push({start: [''], end: ['']})
    this.disableBtn = true
  }

  removeOption(day, time){
    let index = this.scheduleDays.indexOf(day)
    this.practitionerSchedule[index].schedule.splice(time, 1)
  }

  selectDay(day){
    this.scheduleDays[day.target.id].checked = day.target.checked  
  }

  saveSchedule(){
    this.afs.collection('users').doc(this.practitioner.uid).update({schedule: this.practitionerSchedule, abscentDates: this.abscentDates})
    this.editSchedule = false
    this.cancelEdit.emit(false)
  }

  checkTime(){
    this.disableBtn = false
    for(let i = 0; i < this.practitionerSchedule.length; i++){
      for(let j = 0; j < this.practitionerSchedule[i].schedule.length; j++){
        if(this.practitionerSchedule[i].schedule[j].end < this.practitionerSchedule[i].schedule[j].start && this.practitionerSchedule[i].schedule[j].end != ''){
          this.disableBtn = true
          i = this.practitionerSchedule.length
          break;
        }
      }
    }
  }

  checkDate(){
    this.disableBtn = false
    for(let i = 0; i < this.abscentDates.length; i++){
      if(this.abscentDates[i].end < this.abscentDates[i].start && this.abscentDates[i].end != ''){
        this.disableBtn = true
        i = this.abscentDates.length
        break;
      }
    }
  }
}
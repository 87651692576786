
import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, ElementRef, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {User} from '@firebase/auth-types';
import { Router } from '@angular/router';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {FirebaseService} from '../../core/services/firebase.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { WizardComponent } from 'angular-archwizard';
import { ConsultationsService } from '../consultations/consultations.service';
import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../components/add-payment/data.service';

import * as _ from 'lodash';
import { TranslationPipe } from 'src/app/translation.pipe';
import { formatDate } from '@angular/common';
import { PaymentService } from 'src/app/core/services/payment.service';
import { SubscriptionsService } from 'src/app/core/services/subscriptions.service';
import { BaseProduct } from 'src/app/core/services/interfaces';

import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';
import { Payment } from '../components/consult-prescriptions/consult-prescription';
import { OrdersService } from 'src/app/core/services/orders.service';

import { v4 as uuidv4 } from 'uuid';
import { EmailsService } from 'src/app/core/services/emails.service';
import { TelehealthService } from '../telehealth/telehealth.service';
import { fbRemoteConfig } from '../../authUtils'
import { AuditTraceService } from 'src/app/core/services/audit-trace.service';
import { WalletService } from 'src/app/core/services/wallet/wallet.service';
import { ConsultHistory, History } from 'src/app/core/interfaces/history';
import { MEDICAL_CONSULTATION } from 'src/app/core/enums/process-history';
import { HistoryService } from 'src/app/core/services/history/history.service';
import { QuestionServiceService } from 'src/app/core/services/question-service.service';
import { RouteService } from 'src/app/core/services/route/route.service';
import { ProductService, Service } from 'src/app/core/services/product.service';

var FileSaver = require('file-saver');

@Component({
  selector: 'app-consultations',
  templateUrl: './consultations.component.html',
  styleUrls: ['./consultations.component.scss']
}) 

export class ConsultationsComponent implements OnInit, OnDestroy {
  @Input() patientUid;
  @ViewChild('firstOrderTerms', { read: TemplateRef }) firstOrderTermsModal:TemplateRef<any>;
  @ViewChild('termsModal') termsModal: any;
  @ViewChild('updateFollowUpModal') updateFollowUp: any;
  @ViewChild('signaturePad', { read: TemplateRef }) signatureModal:TemplateRef<any>;
  @ViewChild('reevaluate', { read: TemplateRef }) reevaluateModal:TemplateRef<any>;
  @ViewChild(WizardComponent) public wizard: WizardComponent;
  @ViewChild("video") public video: ElementRef;
  @ViewChild("canvas") public canvas: ElementRef;
  @ViewChild("canvasID") public canvasID: ElementRef;
  @ViewChild('imageCropper', { read: TemplateRef }) imageCropper:TemplateRef<any>;
  @ViewChild('FollowUpOrRefill', { read: TemplateRef }) followUpOrRefillModal:TemplateRef<any>;
  @ViewChild('chooseTypeOfConsult', { read: TemplateRef }) chooseTypeOfConsultModal:TemplateRef<any>;
  @ViewChild('chooseImageEnvironment', { read: TemplateRef }) chooseImageEnvironment:TemplateRef<any>;
  @ViewChild('camera', { read: TemplateRef }) cameraModal:TemplateRef<any>;

  @ViewChild('authUsersModal') authUsersModal: any;
  emitPrescriptionTab = 1;

  completedAdress = false;
  disableAddress = true;

  public captures: String;

  allSubscriptions = new Subscription();
  closeSubscriptions = new Subscription();

  termsOfUse 
  acceptedTerms = false;

  user: User;
  userProfile:any;
  patientProfile: any;

  treatments:any;
  packages = [] //[{days: 30, text: '<es>Planes mensuales</es><en>Monthly plans</en>'}, {days: 90, text: '<es>Planes trimestrales</es><en>Three month plans</en>'}, {days: 0, text: '<es>Solo Consulta</es><en>Consult Only</en>'}]
  packSelected = 30;

  searchText

  completedConsults: any[] = [];
  inprogressConsults: any[] = [];
  allConsults: any[] = []; //for Admins
  currentConsult: any = {};
  currentId = '';
  currentIdBack = '';
  currentImage = '';
  currentConsultQuestions: any = [];
  myPractitioners = []
  conditions;
  currentCondition: any;
  currentTreatment: Service;
  selectedItems: any = [];
  wishlist: any = [];
  shippingService: any = [];
  pagetitle = "CONSULTATIONS.CONSULTS"

  //address
  shippingAddresses:any = [];
  shipping: any;

  //Modal reference
  modalRef:any;
    
  //points 
  pointsToSum:any[] = [];
  total:number;

  objCharge: any;
  
  //questions
  selectedCondition:any;
  serviceType
  conditionCode:any;
  questionsCode: any;
  stopQuestions;
  
  scoreLimit: number;

  allPaymentMethods: any;

  globalStripeAmount: any;
  creditCardId: any;
  
  chargeProcessingStripe: Boolean = false;

  public consultation: any;
  creatingConsult: Boolean = false;
  onClick: Boolean = false;
  enableButton: Boolean = false;
  loadingImg = false;

  order: any;
  orderLines: any;

  orderTax: any;
  orderSubtotal: any;
  orderTotal: any;
  orderNumber: any;

  creditCardRef:any;
  orderConfirmed:boolean = false;
  //interactions
  rxcui:any[] = [];
  prescriptionProducts:any[] = [];
  interactions:any;
  edit: boolean = false;

  //for Swal modal
  swal

  startPage = true;
  consultationQuestions = false;
  chooseConsultation = false;
 
  states = [];

  currentLang: any = this.translate.store.currentLang
  pipe = new TranslationPipe()

  hide = false;
  
  state:any;
  salesOrderID: any;

  idExpired = false;

  customize: boolean = true;
  prescriptionOnly:Boolean = false;

  paymentIntentSuccess: Boolean = false;
  paymentIntentError: Boolean = false;
  paymentIntentSquare: Boolean = true;

  typeCopy: any = []
  timeCopy = ['<en>Morning</en><es>Mañana</es>', '<en>Afternoon</en><es>Medio Día</es>', '<en>Evening</en><es>Tarde</es>', '<en>Night</en><es>Noche</es>', '<en>Any Time</en><es>A cualquier hora del día</es>']
  timeRanges:any = [{min: '2023-02-28T08:00', max: '2023-02-28T11:59'}, {min: '2023-02-28T12:00', max: '2023-02-28T15:00'}, {min: '2023-02-28T15:00', max: '2023-02-28T18:00'}, {min: '2023-02-28T18:00', max: '2023-02-28T22:00'}, {min: '2023-02-28T08:00', max: '2023-02-28T22:00'}]

  showPaymentIntentError: Boolean = false;

  paymentIntentErrorMsg: any;

  signature:string;
  signRef:any;
  dateCertified: any;
  reevaluate: Boolean = false;
  remoteConfig: any = fbRemoteConfig.defaultConfig;
  
  refillTerms:boolean = false;

  //image-cropper
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = true;
  containWithinAspectRatio = false;
  transform: any = {};
  ratio = 1;

  showConsultDetails:boolean = false;

  appointment
  subscriptions:any[] = [];
  selectedPlan: any;

  activatePromo: boolean = false;

  //For Admins
  translateStatus = ["PRACTITIONER-CONSULTS.ALLCONSULTS", "PRACTITIONER-CONSULTS.DRAFT", "PRACTITIONER-CONSULTS.OPEN", "PRACTITIONER-CONSULTS.PAID", "PRACTITIONER-CONSULTS.INPROGRESS", "PRACTITIONER-CONSULTS.COMPLETED", "PRACTITIONER-CONSULTS.REJECTED", "PRACTITIONER-CONSULTS.DENIED"];
  consultsStatus = ["All","Draft", "Open", "Paid", 'In Progress', "Completed", "Rejected", "Denied"];
  filterConsults = "Open"

  consultsStatusPatients = ["inprogressConsults", "completedConsults", "completedConsults"];
  consultFilterArray = [['Open', 'Draft'], ['Paid', 'In Progress'], ['Completed', 'Rejected', 'Denied']];
  consultFilter = this.consultFilterArray[0];
  showConsults = this.consultsStatusPatients[0]; //filter between completed and inprogress consults
  translateStatusPatients = [ "ORDERS.STATUS.PENDING", "PRACTITIONER-CONSULTS.INPROGRESS", "PRACTITIONER-CONSULTS.COMPLETED"];

  followUpToEvaluate:any;

  timezoneList = [];
  currentTimezone;

  claims;

  previousUrlPath: string = null;

  allFilteredConsults: any;
  pageConsults = 1;
  pageSizeConsults = 10;
  totalConsultRecords = 0;

  showLoadingConsults: boolean = false;

  currentSelectedStatus: any;

  constructor(
    private afs: AngularFirestore,
    public firebaseService: FirebaseService,
    public authService: AuthenticationService,
    public modalService: NgbModal,
    public afAuth: AngularFireAuth,
    public formBuilder: UntypedFormBuilder,
    public consultationsService: ConsultationsService,
    private router: Router,
    public translate: TranslateService,
    public dataService: DataService,
    private paymentService: PaymentService,
    private orderService: OrdersService,
    private emailService: EmailsService,
    private telehealthService: TelehealthService,
    private subscriptionsService: SubscriptionsService,
    private auditTraceService: AuditTraceService,
    private walletService: WalletService,
    private historyService: HistoryService,
    private questionService: QuestionServiceService,
    private routeService: RouteService,
    private productService: ProductService
    ) {

      const moment = require('moment-timezone');  
      moment.tz.names().forEach(t => {
        let timeValue = String((moment.tz(t).utcOffset())/60).includes('-') ? '-' + String((moment.tz(t).utcOffset())/60).replace('-', '').padStart(2, '0') : String((moment.tz(t).utcOffset())/60).padStart(2, '0')
        this.timezoneList.push({nameValue: t, timeValue: timeValue + ':00', name: t + ' (' + timeValue + ':00)'})
      })
  
      this.currentTimezone = this.timezoneList.find(t => t.nameValue == moment.tz.guess())
  
      if(router.url == '/consultations/chooseConsult'){
        this.chooseConsultation = true;
        this.startPage = false;
      }

      if(screen.width < 1200 || (window.matchMedia("(orientation: landscape-primary)") && screen.height < 800 && screen.height > 768)){
        this.hide = true; 
      }
      else {
        this.hide = false;
      }

      this.previousUrlPath = this.routeService.previousNavigationPath();
    }
    
    
    async ngOnInit(): Promise<void> {  
      await this.getIdCountry()
      
      this.currentConsultQuestions.length = 0;
      // Collapse value
      this.shipping = '';
      await this.getUser();

      this.claims = await this.authService.getClaims();

      this.showLoadingConsults = true;

    }

    getDateMinusMonths(months: number): string {
      const date = new Date();
      // Subtract months from the current date
      date.setMonth(date.getMonth() - months);
  
      // Format the date to YYYY-MM-DD
      return date.toISOString().split('T')[0]; // "YYYY-MM-DD"
    }

  loadingConsults = true;
  getUser() {
    let sub = this.afAuth.user.subscribe(async user => {     
      if(user){
        this.user = user;
        this.authService.userSubscription.subscribe(res => {
          this.userProfile = res
          if(this.userProfile.accountType == 'patient'){
            this.patientProfile = this.userProfile
          }
        })
        await this.consultsAvailable().then(async res => {
          // if(await this.userProfile.accountType == 'patient'){
            if(this.userProfile.accountType == 'patient'){
              this.patientProfile = await this.userProfile;
              this.currentTimezone = this.patientProfile.timezone ? this.patientProfile.timezone : this.currentTimezone;
              
              this.getConsults();
              if(this.userProfile.idCountryIssued != ''){
                this.getIdStates({country: this.userProfile.idCountryIssued})
              }
              // this.verifyExpDate(await profile.idExpDate)
              
              // let sub1 = this.afs.collection<any>('users').doc(user.uid).collection('authUsers').doc('1').valueChanges().subscribe(async user1 => {
              //   if(user1 == undefined && this.userProfile.accountType == 'patient'){
              //     this.modalService.open(this.authUsersModal, {size: 'lg', backdrop: 'static', centered: true, keyboard: false})
              //   }else {
                  this.getTerms()
              //   }
              // })
              // this.allSubscriptions.add(sub1)
    
              await this.firebaseService.getUserAddresses(this.patientProfile)
              let sub = this.firebaseService.addressessSubscription.subscribe(data => {
                this.shippingAddresses = data.filter((address:any) => address.AddressType === "Shipping" && address.AddressStateCode === this.patientProfile.currentLocation.code);
              })
              this.allSubscriptions.add(sub)
            }
          // }
        })
        
        if(await this.userProfile.accountType == 'admin' && !this.patientUid){
          this.afs.firestore.collection('consultation').where('patientName', '!=', '(Anonymous)').get().then((consults: any) => {
            let last12Months = new Date(this.getDateMinusMonths(12));
            let tempConsults = consults.docs.map(c => c.data()).filter(c => c.Status != 'Canceled' && c.Date.seconds >= last12Months.getTime()/1000);

            this.allConsults = tempConsults.sort((a, b) => {
              return b.dateToSort.seconds - a.dateToSort.seconds
            })

            this.allFilteredConsults = this.allConsults;

            this.getCurrentFilterStatus(this.filterConsults);

            this.loadingConsults = false;
          })
        }
        if(this.patientUid){
          this.afs.collection('users').doc(this.patientUid).valueChanges().subscribe(patient => {
            this.patientProfile = patient;
            this.currentTimezone = this.patientProfile.timezone ? this.patientProfile.timezone : this.currentTimezone;
            this.getConsults();
          })
        }
        this.allSubscriptions.add(sub)
      }
    });
    this.allSubscriptions.add(sub)
  }

  getConsults(){
       let sub1 = this.afs.collection<any>('consultation', ref => ref.where('UID_Patient', '==', this.patientProfile.uid).where('Status', "in", ["Completed", "In Progress", "Paid", "Rejected", "Denied"]).where('state.code', '==', this.patientProfile.currentLocation.code)).valueChanges().subscribe(completed => {
        this.completedConsults = completed.sort((a, b) => {
          return b.Date.seconds - a.Date.seconds
        })
        if(this.completedConsults){
          for (let i = 0; i < this.completedConsults.length; i++){
            if(this.completedConsults[i].UID_Practitioner){
              this.afs.firestore.collection('users').doc(this.completedConsults[i].UID_Practitioner).get().then( profile => {
                const profiles:any = profile.data();
                this.completedConsults[i].practitionerImg = profiles.image;
              });
            }
            else {
              this.translate.get('CONSULTATIONS').subscribe((res: any) => {
                this.swal = res.NOPRACTITIONER
              })
              this.completedConsults[i].practitionerName = this.swal;
              this.completedConsults[i].practitionerImg = "/assets/images/users/profile.png";
            }
            
          }
        }
      });
      this.allSubscriptions.add(sub1)

      let sub4
      return sub4 = this.afs.collection<any>('consultation', ref => ref.where('UID_Patient', '==', this.user.uid).where('Status', "in", ["Draft", "Open"]).where('state.code', '==', this.patientProfile.currentLocation.code)).valueChanges().subscribe(progress => {       
        this.inprogressConsults = progress.sort((a, b) => {
          return a.Date.seconds - b.Date.seconds
        })

        for(let i = 0; i < this.inprogressConsults.length; i++){
          if(this.inprogressConsults[i].ID == this.currentConsult?.ID){
            this.currentConsult = this.inprogressConsults[i];
            this.ConsultProgress(false)
          }
          if(this.inprogressConsults[i].biologicalGender.includes(this.userProfile.biologicalgender)){           
            if(this.inprogressConsults[i].preconsult == true){
              this.editConsult(this.inprogressConsults[i]);
              break;
            }
          }
          else {
            //DELETE CONSULT
            this.afs.collection("consultation").doc(this.inprogressConsults[i].ID).delete()
          }
        }
        this.loadingConsults = false;
        this.allSubscriptions.add(sub4)
      })
  }

  adminConsult(){
    this.scoreLimitCheck();
    this.afs.collection('users').doc(this.currentConsult.UID_Patient).valueChanges().subscribe(patient => {
      this.patientProfile = patient;
      this.currentTimezone = this.patientProfile.timezone ? this.patientProfile.timezone : this.currentTimezone;
    })

    this.afs.collection<any>('consultation').doc(this.currentConsult.ID).valueChanges().subscribe(progress => {       
      if(progress){
        this.currentConsult = progress;
        this.ConsultProgress(false)
      }
    })
  }

  calculateAge(user){
    var date = new Date(user);
    var today = new Date();
    var timeDiff = Math.abs(today.getTime() - date.getTime());
    var age1 = Math.floor(timeDiff / (1000 * 3600 * 24)) / 365.25;
    var age = Math.floor(age1);
    return age;
  }

  //All consultsAvailable for the patient to choose
  async consultsAvailable(){
    let promise = new Promise(async (resolve, reject) => {
      if(await this.userProfile){
        this.enableButton = false;
        this.afAuth.user.subscribe(async user => {
          this.user = user;
          if(user){
            this.afs.firestore.collection('consultRules').get().then(async rules => {
              let rulesArray = rules.docs.map(r => r.data()).filter(r => r.states.some(state => state.state_name == this.userProfile.currentLocation.name));

              let sub = this.afs.collection<any>('consultTemplates', ref => ref.where('biologicalGender', "array-contains", this.userProfile.biologicalgender)).valueChanges().subscribe(data => {
                let age = this.calculateAge(this.userProfile.birthdate);
                
                this.conditions = []

                for(let i = 0; i < data.length; i++){
                  if(rulesArray.some(r => r.id == data[i].id) && data[i].minAge <= age && data[i].maxAge >= age){
                    this.conditions.push(data[i]) 
                  }
                };

                resolve(this.conditions)
              });
              this.allSubscriptions.add(sub)
            });
          }
        })
      }
    })

    return promise;
  }

  condition:any;
  conditionRules:any;
  setSelectedCondition(condition:any){
    let promise = new Promise((resolve, reject) => {
      this.condition = condition;
      this.selectedCondition = condition.id;
      this.serviceType = condition.serviceType
      this.conditionCode = condition.conditionCode;
      this.questionsCode = condition.conditionQuestionsId
      this.currentCondition = condition.id;
      this.scoreLimit = condition.scoreLimit;
  
      this.afs.collection("consultRules").doc(this.currentCondition).valueChanges().subscribe(rules => {
        this.conditionRules = rules;
        resolve(this.conditionRules)
      })
    })

    return promise;
  }

  setConsult(condition){
    let consultTemplate = this.conditions.find(c => c.serviceType == condition)
    this.setSelectedCondition(consultTemplate);
    this.generateConsult();
    this.modalService.dismissAll();
  }

  createConsultOnce = true;
  generateConsult(){
    let inprogressConsults = this.inprogressConsults.filter(c => c.Type == this.condition.id)
    for(let i = 0; i < inprogressConsults.length; i++){
      this.translate.get('CONSULTATIONS.SWAL').subscribe((res: any) => {
        this.swal = res
      })
      let datePipe = formatDate(new Date(inprogressConsults[i].Date.seconds * 1000),'MMMM d, y',this.currentLang);
      let timePipe = formatDate(new Date(inprogressConsults[i].Date.seconds * 1000),'hh:mm a',this.currentLang);

      if(this.condition.lastRevision.seconds != inprogressConsults[i].lastRevision.seconds && this.inprogressConsults[i].Status == 'Open'){
        this.cancelAndGenerateNewConsult(inprogressConsults[i])
      }
      else {
        if(!this.claims.devAdmin && !this.claims.testUsers){
          this.modalService.dismissAll();
          this.creatingConsult = false;
          this.onClick = false;
          this.editConsult(inprogressConsults[i]);
        }
        else {
          Swal.fire({
          customClass: {
            confirmButton: 'btn-light',
            denyButton: 'swalPurple'
          },
          html: `<img class="w-25 mx-auto" src="${this.condition.Photo1}"><div><h3>${this.swal.GENERATECONSULTTITLE}</h3></div><div><p>${this.swal.TEXT + this.pipe.transform(this.condition.Name, this.currentLang) + this.swal.TEXT1 + datePipe + " " + timePipe}?</p></div>`,
          iconColor: '#ff0000 !important',
          showCancelButton: false,
          confirmButtonText: this.swal.CONFIRM,
          denyButtonText: this.swal.CANCEL,
          showDenyButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCloseButton: true,
          }).then(result => {
            if (result.isConfirmed) {
              this.cancelAndGenerateNewConsult(inprogressConsults[i])
            }
            else if(result.isDenied){
              this.modalService.dismissAll();
              this.creatingConsult = false;
              this.onClick = false;
              this.editConsult(inprogressConsults[i]);
            }
            else {
              this.modalService.dismissAll()
              this.onClick = false;
            }
          });
        }  
        break;
      }
    }
   
    if(inprogressConsults.length == 0 && this.createConsultOnce){
      this.createConsultOnce = false;
      this.creatingConsult = true;
      this.onClick = false;
      let data = Object.assign(this.condition, {preconsult: false, currentQuestion: 1,
        currentWizardStep: this.condition.steps[0].stepId || 'preconsultQuestions',
      });

      this.firebaseService.createConsult(data, this.userProfile).then(
        res => {
          let newConsultId = res.ID;

          let history:ConsultHistory = {
            date: new Date(Date.now()),
            id: this.firebaseService.getDate(),
            userUID: this.user.uid,
            user: this.userProfile.firstName + ' ' + this.userProfile.lastName1,
            userAccountType: this.userProfile.accountType,
            action: MEDICAL_CONSULTATION.CREATED,
            consultID: res.ID,
            consultStatus: res.Status,
            createdBy: this.user.uid
          }
  
          this.afs.collection('consultation').doc(newConsultId).collection('History').doc(history.id).set(history)

          //newest possible versions
          // this.historyService.storeHistory(history);      ----  its own collection
          // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation

          this.currentConsult = res;
          this.setQuestionsToConsult(this.questionsCode);
        }
      )
    }
  }

  consultLabs:any[] = [];
  handleLabSelection(lab) {
    const labIndex = this.consultLabs.findIndex((selectedLab) => selectedLab.labName === lab.labName);
  
    if (labIndex === -1) {
      // Lab not included, so add it
      let tempLab = {labName: lab.labName, labCode: lab.labCode, id: Math.random().toString(36).substr(2, 9), required: lab.required};
      this.afs.collection('consultation').doc(this.currentConsult.ID).collection("LabsChosen").doc(tempLab.id).set(tempLab)
    } else {
      this.deleteLabItem(this.consultLabs[labIndex].id);
    }
  }

  deleteLabItem(id){
    this.afs.collection('consultation').doc(this.currentConsult.ID).collection("LabsChosen").doc(id).delete()
  }

  cancelAndGenerateNewConsult(consult, condition?){
    const canceled = {
      Status: "Canceled"
    }

    if(condition){
      this.setSelectedCondition(condition)
    }

    this.firebaseService.updateConsult(consult.ID, canceled);
    this.creatingConsult = true;
    this.onClick = false;
    let data = Object.assign(this.condition, {preconsult: false, currentQuestion: 1,
      currentWizardStep: this.condition.steps[0].stepId || 'preconsultQuestions'})

    if(this.createConsultOnce){
      this.createConsultOnce = false;
      this.firebaseService.createConsult(data, this.userProfile).then(
        res => {
          this.currentConsult = res;
  
          let history:ConsultHistory = {
            date: new Date(Date.now()),
            id: this.firebaseService.getDate(),
            userUID: this.user.uid,
            user: this.userProfile.firstName + ' ' + this.userProfile.lastName1,
            userAccountType: this.userProfile.accountType,
            action: MEDICAL_CONSULTATION.CREATED,
            consultID: res.ID,
            consultStatus: res.Status,
            createdBy: this.user.uid
          }
          let newConsultId = res.ID;
  
          this.afs.collection('consultation').doc(newConsultId).collection('History').doc(history.id).set(history)
  
          //newest possible versions
            // this.historyService.storeHistory(history);      ----  its own collection
            // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation
  
            this.currentConsult = res;
          this.setQuestionsToConsult(this.questionsCode);
        }
      )      
    }
  }

  openConsults = true;
  setQuestionsToConsult(condition){
    this.openConsults = true
    this.consultationsService.changeID(this.currentConsult.ID);

    this.questionService.getTemplateQuestions(condition).then((res:any) => {
      this.afs.collection('consultation').doc(this.currentConsult.ID).update({totalQuestions: res.length})
      this.firebaseService.addQuestionsToUser(res, this.currentConsult.ID);
    })

    this.afs.firestore.collection('consultation').doc(this.currentConsult.ID).collection('Questions').get().then(async questions =>{
      this.currentConsultQuestions = await questions.docs.sort(function (a,b) {
        return a.data().sequence - b.data().sequence; 
      });
      if(this.currentConsultQuestions.length === questions.docs.length){
        this.creatingConsult = false;
        this.onClick = false;
        this.selectedCondition = "";
        this.serviceType = '';
        this.enableButton = true;
        if(this.openConsults == true){
          this.editConsult(this.currentConsult);
          this.openConsults = false;
        }
      }
      else{
        this.enableButton = false;
      }
    });
  }

  //(Selected consult in 'Draft' Status) - check if consult can be completed
  checkDraftConsult(consult){
    let consultTemplate = this.conditions.find(c => c.serviceType == consult.serviceType)
    this.setSelectedCondition(consultTemplate).then(async res => {
      this.verifyDraftConsults()
    })
  }

  editProgress = true;
  async editConsult(consult:any){

    this.afs.collection('calendar', ref => ref.where('uidPatient', '==', consult.UID_Patient).where('consultID', '==', consult.ID)).valueChanges().subscribe(appointment => {
      this.appointment = appointment[0]
    })

    let consultType = consult.serviceType

    let consultTemplate = this.conditions.find(c => c.serviceType == consultType)
    await this.setSelectedCondition(consultTemplate).then(async res => {
      let outdatedConsult = consult.Date.seconds * 1000 + this.conditionRules.daysForNextOriginalConsult*24*60*60*1000; //days from the last original consult + time for new original consult not follow up

      if(this.userProfile.accountType == 'patient' && ((consultTemplate.lastRevision.seconds != consult.lastRevision.seconds && consult.Status == 'Open') || (this.conditionRules.daysForNextOriginalConsult != 0 && Number(Date.now()) >= Number(outdatedConsult)))){
        if(Number(Date.now()) >= Number(outdatedConsult)){
          let consultTemplate = this.conditions.find(x => x.serviceType == this.conditionRules.consultTemplate)
          this.setSelectedCondition(consultTemplate).then(res => {
            this.cancelAndGenerateNewConsult(consult)
          })
        }
        else {
          this.cancelAndGenerateNewConsult(consult)  
        }
      }
      else {
        let chooseMedication = consult.steps.findIndex(step => step.stepId == 'chooseMedication')
        if(chooseMedication != -1){
          let sub = this.afs.collection('consultation').doc(consult.ID).collection('wishlist').valueChanges().subscribe(async items =>{
            this.selectedItems = items ? items.filter(i => i.id != 980 && i.type != 'shipping') : []
            this.shippingService = items ? items.filter(i => i.type == 'shipping') : []
            this.wishlist = items ? items : []
          })
          this.allSubscriptions.add(sub)
        }
    
        this.typeCopy = consult.typeOfContact;
  
        this.afs.firestore.collection('users').doc(`${this.user.uid}`).collection("myPractitioners").get().then(data => {
          this.myPractitioners = [];
  
          for(let i = 0; i < data.docs.length; i++){
            this.afs.firestore.collection('users').doc(`${data.docs[i].data().uid}`).get().then(async (data1: any) => {
              if(data1.exists && data1.data().typeOfPractitioner && data1.data().isAccountDisabled == false && data1.data().status == 'Verified'){
                for(let k = 0; k < data1.data().typeOfPractitioner.length; k++) {
                  if(this.currentConsult.typeOfPractitioner.includes(data1.data().typeOfPractitioner[k])){
                    let tempData = {
                      name: data1.data().firstName + " " + data1.data().lastName1,
                      practitionerPhoto: data1.data().image
                    }   
  
                    tempData = Object.assign(tempData, data1.data())
  
                    this.onlyUnique(Object.assign(data.docs[i].data(), tempData), 'myPractitioners')
                    break;
                  }
                }
              }
            })
          }
        });
    
        if(consult?.prescriptionOnly == true){
          this.prescriptionOnly = true;
          this.customize = false;
        }
        this.currentConsult = await consult;
        
        if(this.userProfile.accountType == 'admin'){
          this.adminConsult()
        }

        this.suggestedHour = consult.suggestedHour ? consult.suggestedHour : []

        this.enableButton = false;
        this.verifyQuestions();
        this.checkedTime = consult?.schedule?.time || []
        this.checkedType = consult?.schedule?.type || []
        this.currentId = consult.identification;
        this.currentImage = consult.image;
        this.currentCondition = consult.Type;
        this.serviceType = consult.serviceType
        this.scoreLimit = consult.scoreLimit;
        this.consultationsService.changeID(consult.ID);

        if(consult.patientName === '(Anonymous)'){
          this.authService.profile().then( user => {
            let tempUser: any = user
  
            let userData = {
              patientEmail: tempUser.email,
              patientName: tempUser.middleName ? tempUser.firstName + " " + tempUser.middleName + " " + tempUser.lastName1 : tempUser.firstName + " " + tempUser.lastName1,
              preconsult: false,
            }
  
            if(this.defaultImg == this.currentConsult.image && this.defaultImg != tempUser.image){
              userData = Object.assign(userData, {
                image: tempUser.image
              })
              this.currentImage = tempUser.image;
            }
  
            if(this.currentConsult.identification.startsWith('../assets/images') && !tempUser.identification.startsWith('../assets/images')){
              userData = Object.assign(userData, {
                identification: tempUser.identification
              })
              this.currentId = tempUser.identification;
            }
  
            this.afs.collection('consultation').doc(this.currentConsult.ID).update(userData)
          })
        }
  
        if(consult.acceptedTerms == undefined){
          this.afs.firestore.collection('users').doc(consult.UID_Patient).get().then( user => {
            let tempUser: any = user.data()
            if(tempUser.terms){
              let terms = {
                acceptedTerms: tempUser.terms
              }
              this.afs.collection('consultation').doc(this.currentConsult.ID).update(terms)
            }
          })
        }
        let sub1 = this.afs.collection('consultation').doc(this.currentConsult.ID).collection("Questions").valueChanges().subscribe(async data => { 
          let arr = [];
          for(let i of data){
              if(i.biologicalGender == undefined || i.biologicalGender.length == 0 || i.biologicalGender.includes(this.patientProfile.biologicalgender)){
                arr.push(i)
              }
          }
          
          arr.sort((a, b) => {
            return a.sequence - b.sequence;
          });
          this.currentConsultQuestions = await arr;
  
          if(this.editProgress){
            this.editProgress = false;
            if(this.userProfile.accountType == 'patient'){
              await this.ConsultProgress();   
            }
          }
        });
  
        this.allSubscriptions.add(sub1)
  
  
        let sub3 = this.afs.collection('consultation').doc(this.currentConsult.ID).collection('Address').valueChanges().subscribe(items =>{
          this.shipping = items.filter(item => item.AddressStateCode == this.currentConsult.state.code);
        });
        this.allSubscriptions.add(sub3)
    
        let sub = this.afs.collection("consultation").doc(this.currentConsult.ID).valueChanges().subscribe(data => {          
          let shippingData: any = data
          this.creditCardRef = shippingData?.creditCardRef;
          this.selectedLaboratory = shippingData?.selectedLaboratory || {download: false};
        });
        this.allSubscriptions.add(sub)
    
        let sub11 = this.afs.collection("consultation").doc(this.currentConsult.ID).collection("Items", ref => ref.where("type", "==", "service")).valueChanges().subscribe((data: Service[]) => {
          this.currentTreatment = data[0];        
        });
  
        let sub12 = this.afs.collection('consultation').doc(this.currentConsult.ID).collection("stopQuestions").doc('1').valueChanges().subscribe(data => {
          if(data){
            this.stopQuestions = data;
          }
          else {
            this.stopQuestions = {reason: [], suggestions: []}
          }
        });
  
        this.allSubscriptions.add(sub11)
        this.allSubscriptions.add(sub12)
    
        if(consult.preconsult == true){
          let userData = {
            preconsult: false
          }
          this.afs.collection('consultation').doc(this.currentConsult.ID).update(userData)
        }
  
        if(consult !== undefined || ""){
          this.consultationQuestionsSwitch()
          consult = undefined;
        }
        this.getCurrentOrder(this.currentConsult.ID); 
      }
    })
  }

  onlyUnique(item, variable){
    if(!this[variable].some((i) => i.uid == item.uid)){
      this[variable].push(item);
    }
  }


  close(){
    this.modalService.dismissAll();
    this.closeSubscriptions.unsubscribe()
    this.shipping = '';
    this.creatingConsult = false;
    this.createConsultOnce = true;
    this.onClick = false;
    this.enableButton = false;
    this.idCountryWithStates = true;
    this.pointsToSum = [];

    this.chargeProcessingStripe = false;
    this.paymentIntentSuccess = false;
    this.paymentIntentError = false;
    this.orderConfirmed = false;

    this.openTermsModal = false
    this.currentConsultQuestions = []
    this.currentConsult = {};
    this.editProgress = true
    this.serviceType = ''
    this.router.navigateByUrl('/consultations');
  }

  async saveProductNew(){
    if(this.currentTreatment == undefined){
      this.productService.getServices(this.currentConsult).then(async items => { 
        let service = this.currentConsult.serviceType.includes('Follow Up') ? this.conditionRules.followUpService : this.conditionRules.consultService
        let services: Service[] = items.filter(item => {
          return item.productServiceType.includes(service) && item.productStates.some(p => p.state_code == this.currentConsult.state.code )
        }).sort((a, b) => {
          return Number(a.productPrice) - Number(b.productPrice)
        });

        if(services.length > 0){
          this.saveService(services[0])
        }
      });
    }
  }

  async saveService(product){
    try{
      // let order = await this.orderService.orderExists(this.currentConsult.ID, product.productType, this.patientProfile.uid);
      product.type = product.productType;
      product.id = product.odooProductId
      // product.productPricing = product.productPrice.filter(p => p[this.currentConsult.state.code])[0]
      // console.warn('product', product, order, this.currentConsult)
      this.firebaseService.saveProduct(product, this.currentConsult.ID);
      // if(!order){
        let _product: BaseProduct = {
          automaticPayment:false,
          id: uuidv4(),
          image: product.productImg,
          orderLineID: "",
          productID: product.odooProductId.toString(),
          productName: product.productName,
          productPrice: product.productPrice,
          productQty: "1",
          recurrency_order: false,
          type:product.productType,
        }

        const { uid } = this.patientProfile;
        let response:any = await this.orderService.createOrderOnCall(uid, this.currentConsult.ID, this.patientProfile, _product, this.currentConsult.state.code);
        
        if(response && response.data.status == 200){
          this.afs.collection("orders").doc(response.data.order.id).update({orderState: "create", sync_time: new Date(Date.now())});
        }else{
          await this.saveService(product);
        }
      // }
    }
    catch(e){
    }
  }

   async scoreLimitCheck(){
    let unfiltered: any = [];
    let points:any = [0];
    let questions:any = [];
    let total: number;
    let obj = {
      totalPoints: 0
    }

    this.productService.getServices(this.currentConsult).then(async items1 => {  
      let items = items1;
      let service = this.currentConsult.serviceType.includes('Follow Up') ? this.conditionRules.followUpService : this.conditionRules.consultService
      this.treatments = items.filter(item => {
        return item.productServiceType.includes(service) && item.productStates.some(p => p.state_code == this.currentConsult.state.code )
        // && item.productMaxPoint >= this.total && item.productMinPoint <= this.total
      }).sort((a, b) => {
        return Number(a.productPrice) - Number(b.productPrice)
      });
    
      this.packages = [...new Map(this.treatments.map(item => [item.productSubscriptionDays, {text: item.productName, days: item.productSubscriptionDays}])).values()]
      this.packages = this.packages.sort((a, b) => {
        return a.productSubscriptionDays - b.productSubscriptionDays;
      });

      // this.packSelected = this.currentTreatment?.productSubscriptionDays ? this.currentTreatment.productSubscriptionDays : this.packages.length > 0 ? this.packages[0].productSubscriptionDays : 0;
      if(this.wishlist.length > 0){
        this.saveProductNew()
      }

      if(this.currentTreatment && !this.order){
        this.saveService(this.currentTreatment)
      }
    });

    const reducer = (accumulator, currentValue) => accumulator + currentValue;
      questions = this.currentConsultQuestions;
      points.length = this.currentConsultQuestions.length;
      obj.totalPoints = 0;
      this.total = 0;
      unfiltered = [];
      for(let i = 0; i < questions.length; i++){
        points.length = this.currentConsultQuestions.length;
        unfiltered.push(await questions[i].points);
        points = unfiltered.filter(function (el) {
          return typeof el != 'string' && el != undefined;
        });
        if(points !== undefined && (i == questions.length - 1)){ 
          total = points.reduce(reducer);
          this.total = total;
          obj.totalPoints = total;
          
          this.firebaseService.setConsultPoints(this.currentConsult.ID, obj).then(async ()=>{
          })
        }
      }
   }

  async diagnosticsTestsScoreCheck(){
    let unfiltered: any = [];
    let points:any = [];
    let questions:any = [];
    let total: number;
    let obj = {
      diagnosticsTestsPoints: []
    }
    let test
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    questions = this.currentConsultQuestions;
    obj.diagnosticsTestsPoints.length = 0
    unfiltered.length = [];
    points.length = []

    for(let j = 0; j < this.currentConsult?.diagnosticsTests?.length; j++){
      questions = this.currentConsultQuestions.filter(item => {
        if(typeof(item.diagnosticsTestId) == 'string'){
          return item.diagnosticsTestId == this.currentConsult.diagnosticsTests[j]
        }
        else if(item.diagnosticsTestId && item.diagnosticsTestId.length > 0){
          return item.diagnosticsTestId.includes(this.currentConsult.diagnosticsTests[j])
        }
      });

      for(let i = 0; i < questions.length; i++){
        test = j
        if(points[test] == undefined){
          points[test] = [];
          unfiltered[test] = [];
          obj.diagnosticsTestsPoints[test] = [];
        }
        
        unfiltered[test].push(await questions[i].diagnosticsTestsPoints);
        points[test] = unfiltered[test].filter(function (el) {
          return typeof el != 'string' && el != undefined;
        });
        
        total = points[test].reduce(reducer);
        obj.diagnosticsTestsPoints[test] = total;

        if(points.length !== 0 && (i == questions.length - 1) && j == this.currentConsult.diagnosticsTests.length - 1){            
          this.afs.collection('consultation').doc(this.currentConsult.ID).update(obj)
        }
      }
    }

  }

  showModal(modal:any){
    this.edit = false;
    this.modalRef = this.modalService.open(modal, { size: 'lg', backdrop: 'static', windowClass: 'addressModal', centered: true});
  }

  viewTerms = false;
  openTerms(){
    this.viewTerms = true;
    this.modalService.open(this.termsModal, {size: 'lg', backdrop: 'static', centered: true, keyboard: false})
  }

  dismiss(){
    if(this.modalRef){
      this.modalRef.dismiss();
      this.modalRef = undefined;
    }
  }  

  getOrderTotal(total: any){
    this.orderTotal = Number(total);
  }

  getCurrentUserCard(){
    this.consultationsService.currentCard.subscribe(data => {
      this.creditCardId = data;
    });
  }

  async createAcceptaPaymentInitiation(){
    let chooseMedication = this.currentConsult.steps.findIndex(step => step.stepId == 'chooseMedication')
    if(!this.currentConsult.patientSignature && this.userProfile.accountType == 'patient'
      && this.currentConsult.Type != 'STD' && this.currentConsult.Type != 'Preventive Health'
      // && chooseMedication != -1 && this.selectedItems.length > 0
    ){
      if(this.currentConsult.productAutoPayment == undefined){
        this.updateAutoPayment(true);
      }
      this.openFirstOrderTerms();
    }
    else {   
      if(this.idExpired){
        this.afs.collection('consultation').doc(this.currentConsult.ID).update({exception: ['idExpired']})
      } else if(!this.invalidId()){
        this.afs.collection('consultation').doc(this.currentConsult.ID).update({exception: ['invalidId']})
      }
      else {
        this.afs.collection('consultation').doc(this.currentConsult.ID).update({exception: []})
      }
      const payment = new Payment(this.paymentService, this.authService, this.afs, this.firebaseService, this.emailService, this.auditTraceService);
      const { paymentAttempts, paymentRetrialTime } = this.remoteConfig;
      payment.consultID = this.currentConsult.ID;
      payment.paymentAttempts = paymentAttempts;
      payment.paymentRetrialTime = paymentRetrialTime;
      payment.customer.uid = this.patientProfile.uid;
      payment.customer.accountType = this.patientProfile.accountType;
      payment.customer.email = this.patientProfile.email;
      payment.order = this.order;
      payment.orderLines = this.orderLines;
      
      
      payment.data.customerNumber = this.currentConsult.acceptaID;
      payment.data.amount = this.order.orderTotal.toString();
      payment.data.uid = this.patientProfile.uid; //added by Melanie. Ask Aramis
      payment.data.orderID = this.order.id,
      
      payment.transaction.consultationId = this.currentConsult.ID;
      payment.transaction.amount = this.order.orderTotal.toString();
      payment.transaction.paymentStatus = "pending";
      payment.currentUser = this.patientProfile;
  

      this.orderNumber = this.order.orderNo;
      this.order.acceptaID = this.currentConsult.acceptaID;
  
      this.chargeProcessingStripe = true;
      let attempt = await this.paymentService.createPaymentAttempt(payment.transaction, this.order);
      let cardRef = await this.paymentService.getCard(this.order.uid, this.currentConsult.acceptaID);
      let testUser = payment.paymentValidateTest(this.order.email);
      if(testUser == true || Number(this.order.orderTotal) == 0){
        attempt = Object.assign(attempt, cardRef, {AuthorizationCode:"411111111", Code: "0000", status:"success", paymentStatus:"paid", uid: this.order.uid, orderID: this.order.id, orderIDRef: Number(this.order.orderId)});
        this.afs.collection("creditCardPayments").doc(attempt.id).update(attempt);
        this.afs.collection("orders").doc(this.order.id).update({
          acceptaID: cardRef?.customerNumber == undefined ? "0000000000000000" : cardRef.customerNumber,
          last4: cardRef?.last4 == undefined ? "0000" : cardRef.last4,
          paymentStatus: "paid",
          attempts: 0,
          paymentDate: new Date(Date.now()),
          orderState: "done"
        });
      }
      if(testUser == false && Number(this.order.orderTotal) !== 0 && Number(this.order.orderTotal) > 0){
        let response = await payment.paymentOnCall(payment.data, attempt, cardRef, this.order);

        if(response === "failed"){
          this.chargeProcessingStripe = false;
          this.paymentIntentSuccess = false;
          this.paymentIntentError = true;
          setTimeout(() => this.failedmsg(), 1000); 
        }
  
        if(response?.value ==  'blocked'){
          this.chargeProcessingStripe = false;
          this.paymentIntentSuccess = false;
          this.paymentIntentError = true;
          setTimeout(() => this.blockedCard(), 1000);  
        }
      }
    }
  }

  openFirstOrderTerms(){
    this.modalRef = this.modalService.open(this.firstOrderTermsModal, {size: 'md', backdrop: 'static', windowClass:'firstOrderDocument', centered: true})
  }

  electronicSignature(){
    this.signature = this.userProfile.firstName + ' ' + this.userProfile.lastName1
    this.signRef = Math.floor(Math.random() * 10000000) + 1
    this.dateCertified = new Date(Date.now())

    this.updateSignature()

  }

  updateSignature(){
    this.afs.collection('consultation').doc(this.currentConsult.ID).update({patientSignature: this.signature, signRef: this.signRef, dateCertified: this.dateCertified});
    this.currentConsult.patientSignature = this.signature;
    for(let i = 0; i < this.selectedItems.length; i++){
      this.afs.collection('consultation').doc(this.currentConsult.ID).collection('wishlist').doc(this.selectedItems[i].productId.toString()).update({patientSignature: this.signature, signRef: this.signRef, dateCertified: this.dateCertified, orientation: this.currentConsult.orientation || false});
    }
  }

  updateAutoPayment(event){
    this.afs.collection('consultation').doc(this.currentConsult.ID).update({productAutoPayment: event});
  }

  setOrientation(event){
    this.afs.collection('consultation').doc(this.currentConsult.ID).update({orientation: event});
  }

  setDAW(product){
    return product.daw == 2 ? true : false;
  }

  fixedDAW(product){
    let index = this.orderLines.findIndex(x => x.productID == product.productID);
    if(product.daw == 0){
      this.orderLines[index].daw = 2;
      this.afs.collection('consultation').doc(this.currentConsult.ID).collection("wishlist").doc(product.productID).update({daw: 2})
      this.orderLines[index].daw = 0;
    }else if(product.daw == 2){
      this.afs.collection('consultation').doc(this.currentConsult.ID).collection("wishlist").doc(product.productID).update({daw: 0})
    }
  }

  async payLater(){
    if(this.idExpired){
      this.afs.collection('consultation').doc(this.currentConsult.ID).update({exception: ['idExpired', 'payLater']})
    } else if(!this.invalidId()){
      this.afs.collection('consultation').doc(this.currentConsult.ID).update({exception: ['invalidId', 'payLater']})
    }
    else {
      this.afs.collection('consultation').doc(this.currentConsult.ID).update({exception: ['payLater']})
    }

    let user: any = {
      displayName: 'Dzeus Support',
      email: 'smtp@dzeus.com',
      uid: ''
    }
    await this.emailService.patientConsultEmails(user, this.patientProfile, this.currentConsult, 'payLaterException_' + this.patientProfile.preferedLang, '<en>Pending Payment</en><es>Pago Pendiente</es>', this.remoteConfig, 'consultations?consultation=' + this.currentConsult.ID)

    await this.afs.firestore.collection('users').where('mainAdmin', '==', true).get().then(async res => {
      let mainAdmin = res.docs[0].data();
      mainAdmin = Object.assign(mainAdmin, {displayName: mainAdmin.firstName + ' ' + mainAdmin.lastName1})
      await this.emailService.generalEmails(mainAdmin, 'payLaterAdminNotification_es', '<en>Customer Service</en><es>Servicio al Cliente</es>', this.remoteConfig, 'consults/' + this.currentConsult.ID, this.currentConsult.ID)
    })
    
    this.translate.get('CONSULTATIONS.SWAL').subscribe((res: any) => {
      this.swal = res
    })

    Swal.fire({
      customClass: {
        confirmButton: 'swalPurple'
      },
      titleText: this.swal.SUCCESS,
      icon: "success",
      text: this.swal.TEXT3,
      confirmButtonText: this.swal.CLOSE
    }).then(res => {
      if(res.isConfirmed){
        this.close();
        this.startPageSwitch();
      }
    })
  }

  successmsg() {
    this.translate.get('CONSULTATIONS.SWAL').subscribe((res: any) => {
      this.swal = res
    })

    let htmlText = `<h4>${this.swal.PAYMENTTEXT}</h4>`
    let showCancelButton = false;
    let confirmButton = this.swal.CLOSE
    if(this.patientProfile.identification.startsWith('../assets/images')){
      htmlText += `<h6>${this.swal.ADDIDTEXT}</h6>`
      showCancelButton = true;
      confirmButton = this.swal.ADDIDBTN
    }

    Swal.fire({
      customClass: {
        confirmButton: 'swalPurple',
        cancelButton: 'btn-light'
      },
      title: this.swal.PAYMENTTITLE,
      html: htmlText,
      icon: 'success',
      showCancelButton: showCancelButton,
      confirmButtonText: confirmButton,
      cancelButtonText: this.swal.MAYBELATER,
    }).then((result) => {
      if (result.isConfirmed && this.patientProfile.identification.startsWith('../assets/images')) {
        this.addID();
      }
    });
    this.startPageSwitch()
  }

  failedmsg(error?) {
    this.translate.get('CONSULTATIONS.SWAL').subscribe((res: any) => {
      this.swal = res
    })

    if(error == undefined){
      error = this.swal
    }
    
    Swal.fire({
      title: this.swal.FAILEDPAYMENT,
      text: error ? error.FAILEDPAYMENTTEXT : error.FAILEDPAYMENTTEXT,
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: 'v.$orange',
    });

    this.chargeProcessingStripe = false;
    this.paymentIntentSuccess = false;
    this.paymentIntentError = false;
    this.orderConfirmed = true;
  }

  blockedCard(){
    this.translate.get('WALLET').subscribe((res: any) => {
      this.swal = res
    })
    Swal.fire({
      title: this.swal.CARDBLOCKED,
      text: this.swal.TEXT,
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: 'v.$orange',
    });

    this.chargeProcessingStripe = false;
    this.paymentIntentSuccess = false;
    this.paymentIntentError = false;
    this.orderConfirmed = true;
  }

  ConsultProgress(check?){
    setTimeout(wait => {
      let checkWizardStep = this.wizard ? this.wizard.getIndexOfStepWithId(this.currentConsult.currentWizardStep) : 1
      if(checkWizardStep > 4 && check != false){
        this.scoreLimitCheck();
        this.diagnosticsTestsScoreCheck();
      }
      if(this.wizard){
        this.currentWizardStep = this.currentConsult.currentWizardStep;
        this.activatePromo = false;
        let promoAnswer = this.currentConsultQuestions.filter(item => item.activatePromo == true);
        for(let item of promoAnswer){
          if(item.answered && typeof(item.answer) == 'string' && item.activatePromoOptions.includes(item.answer)){
            this.activatePromo = true;
            break;         
          }
          if(item.answered && item.answer.some((element) => item.activatePromoOptions.includes(element))){
            this.activatePromo = true;
            break;
          }
        }
        let wizardStep = this.wizard.getIndexOfStepWithId(this.currentWizardStep)
        this.wizard.goToStep(wizardStep);
        this.doProgress();
      }
    },500)  
  }

  doProgress(){
    // let percentLength = 100/(this.currentConsultQuestions.length + this.wizard.wizardSteps.length)
    // let index = this.currentConsult.steps.findIndex(step => step.stepId == this.currentWizardStep)
    // let progressBarTest = (index + this.currentConsult.currentQuestion) * percentLength
    // document.getElementById('progress-bar').style.width = (progressBarTest) +'%';
  }

  removeProgress(){
    // let percentLength = 100/(this.currentConsultQuestions.length + this.wizard.wizardSteps.length)
    // let index = this.currentConsult.steps.findIndex(step => step.stepId == this.currentWizardStep)
    // let progressBarTest = (index + this.currentConsult.currentQuestion) * percentLength
    // document.getElementById('progress-bar').style.width = (progressBarTest) +'%';
  }

  scrollToTop(){
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
  }

  currentWizardStep = 'loader'
  previousStep = ''
  activeBtn = true;
  consultWizardStep(updateConsult?){
    if(this.activeBtn){
      this.activeBtn = false;
      this.previousStep = this.currentWizardStep;
      let currentStep = this.currentConsult.steps.findIndex(step => step.stepId == this.currentWizardStep)
      this.currentWizardStep = this.currentConsult.steps[currentStep + 1].stepId || this.currentWizardStep;
      let index = this.wizard.getIndexOfStepWithId(this.currentWizardStep);
      this.wizard.goToStep(index);
  
      if(this.currentWizardStep == 'gender' && this.patientProfile.gender) {
        let step = this.currentConsult.steps.findIndex(step => step.stepId == 'gender')
        if(step != -1 && step != this.currentConsult.steps.length - 1){
          this.activeBtn = true;
          this.consultWizardStep();
        }
      }
      else if(this.currentWizardStep == 'biologicalgender' && this.patientProfile.biologicalgender){
        let step = this.currentConsult.steps.findIndex(step => step.stepId == 'biologicalgender')
        if(step != -1 && step != this.currentConsult.steps.length - 1){
          this.activeBtn = true;
          this.consultWizardStep();
        }
      }
      else if(this.currentWizardStep == 'chooseMedication'){
        // this.activatePromo = this.currentConsultQuestions.filter(item => item.activatePromo == true && item.answer.some((element) => item.activatePromoOptions.includes(element))).length > 0 ? true : false;
        this.activatePromo = false;
        let promoAnswer = this.currentConsultQuestions.filter(item => item.activatePromo == true);
        for(let item of promoAnswer){
          if(item.answered && typeof(item.answer) == 'string' && item.activatePromoOptions.includes(item.answer)){
            this.activatePromo = true;
            break;         
          }
          if(item.answered && item.answer.some((element) => item.activatePromoOptions.includes(element))){
            this.activatePromo = true;
            break;
          }
        }
      }
      else if(this.currentWizardStep == 'phoneNumber' && this.patientProfile.phoneNumber){
        let step = this.currentConsult.steps.findIndex(step => step.stepId == 'phoneNumber')
        if(step != -1 && step != this.currentConsult.steps.length - 1){
          this.activeBtn = true;
          this.consultWizardStep();
        }
      }
      else if(this.currentWizardStep == 'birthdate' && this.patientProfile.birthdate){
        let step = this.currentConsult.steps.findIndex(step => step.stepId == 'birthdate')
        if(step != -1 && step != this.currentConsult.steps.length - 1){
          this.activeBtn = true;
          this.consultWizardStep();
        }
      }
      else if(this.currentWizardStep == 'choosePractitioner' && this.myPractitioners.length == 0){
        let step = this.currentConsult.steps.findIndex(step => step.stepId == 'choosePractitioner')
        if(step != -1 && step != this.currentConsult.steps.length - 1){
          this.activeBtn = true;
          this.consultWizardStep();
        }
      }
      
      let obj: any = {currentWizardStep: this.currentWizardStep}
  
      if(updateConsult){
        this.currentConsult = {...this.currentConsult, ...updateConsult}
        obj = {currentWizardStep: this.currentWizardStep, ...updateConsult}
      }
      // if(this.userProfile.accountType != 'admin'){
        this.afs.collection('consultation').doc(this.currentConsult.ID).update(obj).then(() => {
          this.activeBtn = true;
        })
      // }
    }
  }

  prevConsultWizardStep(){
    if(this.activeBtn){
      this.activeBtn = false;
      this.previousStep = this.currentWizardStep;
      let currentStep = this.currentConsult.steps.findIndex(step => step.stepId == this.currentWizardStep)
      this.currentWizardStep = this.currentConsult.steps[currentStep - 1].stepId;
      let index = this.wizard.getIndexOfStepWithId(this.currentWizardStep);
      this.wizard.goToStep(index);
  
      if(this.currentWizardStep == 'gender' && this.patientProfile.gender) {
        let step = this.currentConsult.steps.findIndex(step => step.stepId == 'gender')
        if(step != -1 && step != 0){
          this.activeBtn = true;
          this.prevConsultWizardStep();
        }
      }
      else if(this.currentWizardStep == 'biologicalgender' && this.patientProfile.biologicalgender){
        let step = this.currentConsult.steps.findIndex(step => step.stepId == 'biologicalgender')
        if(step != -1 && step != 0){
          this.activeBtn = true;
          this.prevConsultWizardStep();
        }
      }
      else if(this.currentWizardStep == 'phoneNumber' && this.patientProfile.phoneNumber){
        let step = this.currentConsult.steps.findIndex(step => step.stepId == 'phoneNumber')
        if(step != -1 && step != 0){
          this.activeBtn = true;
          this.prevConsultWizardStep();
        }
      }
      else if(this.currentWizardStep == 'choosePractitioner' && this.myPractitioners.length == 0){
        let step = this.currentConsult.steps.findIndex(step => step.stepId == 'choosePractitioner')
        if(step != -1 && step != 0){
          this.activeBtn = true;
          this.prevConsultWizardStep();
        }
      }
      else if(this.currentWizardStep == 'birthdate' && this.patientProfile.birthdate){
        let step = this.currentConsult.steps.findIndex(step => step.stepId == 'birthdate')
        if(step != -1 && step != 0){
          this.activeBtn = true;
          this.prevConsultWizardStep();
        }
      }
      else if(this.currentWizardStep == 'preconsultQuestions'){
        this.checkPrevQuestion();
      }
      
      // if(this.userProfile.accountType != 'admin'){
        this.afs.collection('consultation').doc(this.currentConsult.ID).update({currentWizardStep: this.currentWizardStep}).then(() => {
          this.activeBtn = true;
        })
      // }
    }
  }

   handleNextButtonState(){
    if(this.wizard && this.wizard.currentStep?.stepId == 'chooseState'){ 
      if(this.currentConsult.state != undefined && this.currentConsult.state != null && this.currentConsult.state != ''
        && this.currentConsult.country != undefined && this.currentConsult.country != null && this.currentConsult.country != ''){
        return false;
      }
      else {
        return true;
      }
    }
    if(this.wizard && this.wizard.currentStep?.stepId == 'chooseTime/Type'){ 
      if(this.checkedTime != undefined && this.checkedTime != null && this.checkedTime.length != 0
         && this.checkedType != undefined && this.checkedType != null && this.checkedType.length != 0){
        return false;
      }
      else {
        return true;
      }
    }
    if(this.wizard && this.wizard.currentStep?.stepId == 'choosePractitioner'){
      if(this.currentConsult.practitionerForConsult){
        return false 
      }    
      else {
        return true;
      }
    }

    if(this.wizard && this.wizard.currentStep?.stepId == 'chooseLabLocation'){
      if(this.selectedLaboratory.download == true || this.selectedLaboratory.download == undefined){
        return false;
      }
      else {
        return true;
      }
    }
  }

  hasAddress(){
    if(this.shipping !== undefined){
      if(this.shipping.length > 0){
        return false;
      }
      else{
        return true;
      }
    }
  }


  currentOrder
  async getCurrentOrder(consultationID: any){
      let sub = await this.afs.collection<any>('orders', ref => ref.where('uid', '==', this.currentConsult.UID_Patient).where('consultationID', '==', consultationID)).valueChanges().subscribe(async data => {  
        for(let item of data){
          if(item.orderTotal){
            this.currentOrder = item;
            this.orderTax = Number(item.orderTax).toFixed(2);
            this.orderSubtotal = Number(item.orderSubtotal).toFixed(2);
            this.orderTotal = Number(item.orderTotal).toFixed(2);
            
            this.orderNumber = item.orderNo;
            this.afs.firestore.collection('orders').doc(item.id).collection('orderLines').get().then(lines =>{
              this.orderLines = lines.docs.map(doc => doc.data());  
              this.orderLines = this.orderLines.sort((a, b) => {
                return a.orderLineID - b.orderLineID
              });
              for(let i = 0; i < this.orderLines.length; i++){
                if(this.orderLines[i].productName == "No Product Selected"){
                  this.orderLines.splice(i, 1)
                }
              }
            });   
          }

        }
      });
      this.allSubscriptions.add(sub)
  } 

  async getTotal(consultation: any, orderTotal: any){
    this.orderTotal = parseFloat(orderTotal) //orderTotal.toString();
  }

  handlePaymentButton(){ 
    const paymentBtn = <HTMLButtonElement>document.getElementById('holdBtn');
    if(this.creditCardRef !== "" && this.creditCardRef !== undefined && this.shippingAddresses.length > 0
    ){
      paymentBtn.disabled = false;
      return false;
    }
    else{
      paymentBtn.disabled = true;
      return true;
    }
  }

  handlePaymentOnce = true;
  getOrderFromCheckout(order){
    this.order = order[0];
    this.orderTotal = Number(this.order.orderTotal);
    if(order.length > 0 && order[0].orderState == "sale"){
      this.orderConfirmed = true;
     }

    if(order.length > 0 && order[0].orderState == "done" && order[0].paymentStatus == "paid" && this.handlePaymentOnce){
      this.handlePaymentOnce = false;
      this.handlePaymentSuccess();
     }
  }

  async ngAfterViewInit(){
    this.getCurrentUserCard(); 
   
    let route = await this.router.url.split("consultations/")[1];
    if(route === 'chooseConsult'){
      this.chooseConsultationSwitch()
    }
  }

  async ngOnDestroy(){
    await this.allSubscriptions.unsubscribe();
    await this.closeSubscriptions.unsubscribe()
    this.selectedCondition = await "";
    this.serviceType = ""
    this.currentConsult = {};
    this.currentConsultQuestions = ''   
    this.idCountryWithStates = true;
  }

  finishedConsult(){
    setTimeout(() => {    
      let user: any = {
        displayName: 'Dzeus Support',
        email: 'smtp@dzeus.com',
        uid: ''
      }
      this.emailService.patientConsultEmails(user, this.patientProfile, this.currentConsult, 'finishedConsult_' + this.patientProfile.preferedLang, '<en>Consult Received</en><es>Consulta Recibida</es>', this.remoteConfig,'','','','','', '', '',this.condition.nextSteps)
      this.close(); this.successmsg();}, 1000);
  }

  startPageSwitch(){
    this.startPage = true;
    this.consultationQuestions = false;
    this.chooseConsultation = false;
  }

  consultationQuestionsSwitch(){
    this.startPage = false;
    this.consultationQuestions = true;
    this.chooseConsultation = false;
  }

  chooseConsultationSwitch(){
    this.startPage = false;
    this.consultationQuestions = false;
    this.chooseConsultation = true;
  }

  imageLoaded = false
  cropImage(prevPhoto?){
    this.modalRef = this.modalService.open(this.imageCropper, {size: 'md', backdrop: 'static', windowClass: 'addAddressModal', centered: true})

    if(this.profileId){
      this.ratio = 485/300
    }
    else {
      this.ratio = 1
    }

    if(prevPhoto){
      this.imageLoaded = true
      this.imageCropped({base64: prevPhoto})
      this.imageChangedEvent = prevPhoto
    }
    else {
      this.imageLoaded = false
      let element = <HTMLInputElement> document.getElementById("imageInput")
      element.click();
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event) {
    this.croppedImage = event.base64;
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  saveImage(){
    ReloadComponent.loader = 'RELOAD.SAVING'

    const decodedData = window.atob(this.croppedImage.split(';base64,')[1]);
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    let test = new Blob([uInt8Array], { type: 'image/png' });

    this.pwaUpload(test)
    this.imageChangedEvent = '';
    this.croppedImage = ''
    this.dismiss()
    this.modalService.dismissAll()
  }

  async fileSelected(fileInput: any){
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
		    let blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
        let url = window.URL.createObjectURL(blob);
      };
      reader.readAsDataURL(fileInput.target.files[0]); 
    }
  }

  pwaUpload(file) {
    this.loadingImg = true;
    ReloadComponent.loader = 'RELOAD.SAVING';

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadImageToFirebase(reader.result);
    };
  }

  image
  profileImage = false;
  profileId = false;
  defaultIDImg = "../assets/images/users/IDFront.svg"
  defaultImg = '../assets/images/users/profile.png'
  defaultIDImgBack = '../assets/images/users/IDBack.svg';
  almostDoneImg = '<en>../assets/images/finalSteps_en.png</en><es>../assets/images/finalSteps_es.png</es>'

  async uploadImageToFirebase(image) {
    const randomId = Math.random().toString(36).substr(2, 5);

    let deleteImageRef = this.patientProfile.image

    if(this.profileId){
      if(this.front){
        deleteImageRef = this.patientProfile.identification
      }
    }

    this.firebaseService.uploadImage(image, randomId, this.patientProfile.uid, deleteImageRef)
    .then(photoURL => {
      this.image = photoURL;
      this.onSubmit();
    }, err => {
    });
  }

  front = true;
  onSubmit() {
    if(this.profileId){
      let data
      if(this.front == true){
        data = {
          identification: this.image,
          status: 'unverified'
        };
        this.patientProfile.identification = data.identification
        this.userProfile.identification = this.userProfile.accountType == 'patient' ? data.identification : this.userProfile.identification;
        this.patientProfile.status = data.status
        this.currentId = data.identification;
      }

      let finalObject = Object.assign(this.patientProfile, data)
      this.firebaseService.updateProfile(finalObject, this.patientProfile.uid)
      .then(
        res => {
          if(this.userProfile.accountType =='patient'){
            this.patientProfile = finalObject;
          }

          if(this.front && this.currentConsult){
            this.firebaseService.updateConsult(this.currentConsult.ID, {identification: data.identification})
          }

          let update = true
          let sub = this.afs.collection('consultation', ref => ref.where('UID_Patient', '==', this.patientProfile.uid).where('Status', 'in', ['Open', 'In Progress'])).valueChanges().subscribe(consult => {
            let consults: any = consult
            if(update){
              for(let i = 0; i < consults.length; i++){
                if(this.front && consults[i].identification != data.identification){
                  this.afs.collection('consultation').doc(consults[i].ID).update({identification: data.identification})
                }

                if(i == consults.length - 1){
                  update = false
                }    
              }
            }          
          })
          this.allSubscriptions.add(sub)
          this.loadingImg = false;
          this.profileImage = false;
          this.profileId = false;
        }
      );
    }
    if(this.profileImage){
      let imageData = {
        image: this.image,
        status: 'unverified'
      };
      if(this.userProfile.accountType == 'admin'){
        this.patientProfile.image = imageData.image
        this.patientProfile.status = imageData.status
      }
      this.currentImage = imageData.image;
      let finalObject = Object.assign(this.patientProfile, imageData)
      this.firebaseService.updateProfile(finalObject, this.patientProfile.uid)
      .then(
        res => {
          if(this.userProfile.accountType =='patient'){
            this.patientProfile = Object.assign(this.patientProfile, imageData)
          }

          this.firebaseService.updateConsult(this.currentConsult.ID, {image: imageData.image})
          this.loadingImg = false
          this.profileImage = false;
          this.profileId = false;

          let update = true
          let sub = this.afs.collection('consultation', ref => ref.where('UID_Patient', '==', this.patientProfile.uid).where('Status', 'in', ['Open', 'In Progress'])).valueChanges().subscribe(consult => {
            let consults: any = consult
            if(update){
              for(let i = 0; i < consults.length; i++){
                if(consults[i].image != imageData.image) {
                  this.afs.collection('consultation').doc(consults[i].ID).update({image: imageData.image})
                }
                if(i == consults.length - 1){
                  update = false
                }  
              }
            }          
          })
          this.allSubscriptions.add(sub)
        }
      );
    }
    if(this.takePicture){
      this.currentConsult.uploadedImages = this.currentConsult.uploadedImages && this.currentConsult.uploadedImages.length > 0 ? [...this.currentConsult.uploadedImages, this.image] : [this.image]
      this.afs.collection('consultation').doc(this.currentConsult.ID).update({uploadedImages: this.currentConsult.uploadedImages})

    }
    this.loadingImg = false;
    this.profileImage = false;
    this.profileId = false;
    this.takePicture = false;
  }

  invalidId(){
    // if(
    //    this.defaultImg !== this.patientProfile.image && this.patientProfile.image !== "" //validate profile image
    //   ){
    //     if(this.wizard && this.wizard.currentStep?.stepId == 'profileImage'){
    //       var element = <HTMLInputElement> document.getElementById("submitId");
    //       element.disabled = false;
    //     }   
    //   return true
    // }
    // else{
    //   if(this.wizard && this.wizard.currentStep?.stepId == 'profileImage'){
    //     var element = <HTMLInputElement> document.getElementById("submitId");
    //     element.disabled = true;
    //   } 
    //   return false
    // }
    if(this.wizard && this.wizard.currentStep?.stepId == 'profileImage'){
      var element = <HTMLInputElement> document.getElementById("submitId");
      element.disabled = false;
    } 
    return true
  }

  takePicture = false;
  submitId(){
    this.loadingImg = true;
    ReloadComponent.loader = 'RELOAD.SAVING';
  
    const data = this.patientProfile
    this.firebaseService.updateProfile(data, this.patientProfile.uid).then(
      res => {
        this.afs.collection('consultation').doc(this.currentConsult.ID).update({exception: []})
        this.loadingImg = false;
        this.profileImage = false;
        this.profileId = false;
      },
      err => {
        this.loadingImg = false;
        this.profileImage = false;
        this.profileId = false;
      }
    );
  }

  verifyExpDate(event){
    let month = (new Date().getMonth() + 1).toString()
    let day = (new Date().getDate()).toString()
    if(Number(month) < 10){
      month = '0' + month
    }
    if(Number(day) < 10){
      day = '0' + day
    }
    let date = new Date().getFullYear() + '' + month + '' + day
    let expDate = event.replaceAll('-','')

    if(event != '' && Number(expDate) < Number(date)){
      this.idExpired = true;
      if(this.userProfile.status != 'unverified'){
        this.afs.collection<any>('users').doc(this.userProfile.uid).update({status: 'unverified'})
      }
    }
    else {
      this.idExpired = false;
    }
  }

  handleTreatmentRequired(){
    if(this.currentTreatment){
      return false
    }
    else {
      return true
    }
  }

  openTermsModal = false
  async getTerms(){
    let sub = this.afs.collection("settings").doc("Terms").collection('Terms', ref => ref.where('termsUserType', '==', this.userProfile.accountType)).valueChanges().subscribe(async data => {
      this.termsOfUse = await [data[data.length -1]]

      if(this.termsOfUse[0]){
        if((await this.userProfile?.terms == undefined 
          || await this.userProfile?.terms[0]?.acceptedTerms == false 
          || await this.userProfile?.terms[0]?.version != await this.termsOfUse[0]?.version) && this.openTermsModal == false){
          this.modalService.open(this.termsModal, {size: 'lg', backdrop: 'static', centered: true, keyboard: false})
          this.openTermsModal = true
        }  
        else {
          if(this.router.url.split("consultations?condition=")[1]){
              let cond = this.router.url.split("consultations?condition=")[1]
              this.router.navigateByUrl('consultations');
            let consultTemplate = !this.claims?.devAdmin && !this.claims?.testUsers ? this.conditions.find(x => x.conditionCode == cond && x.disabled == false) : this.conditions.find(x => x.conditionCode == cond) //For testing only
            if(consultTemplate?.biologicalGender.includes(this.userProfile.biologicalgender)){
              this.setSelectedCondition(consultTemplate).then(() => {
                this.verifyDraftConsults()
              });
            }          
            else{
              this.router.navigate(['/account/404'])
            }
          }

          if(this.router.url.split("consultations?consultation=")[1]){
            this.afs.firestore.collection('consultation').where('ID', '==', this.router.url.split("consultations?consultation=")[1]).where('UID_Patient', '==', this.userProfile.uid).get().then((data:any)=> {
              if(data.docs[0] && data.docs[0].data().Status != 'Completed' && data.docs[0].data().Status != 'In Progress'){
                // this.router.navigateByUrl('consultations/' + data.docs[0].data().ID + '/ws/' + data.docs[0].data().currentWizardStep);
                if(data.docs[0].data().Status == 'Open'){
                  this.editConsult(data.docs[0].data())
                } else {
                  let consultTemplate = this.conditions.find(x => x.serviceType == data.docs[0].data().serviceType)
                  if(consultTemplate?.biologicalGender.includes(this.userProfile.biologicalgender)){
                    // const nextTimestamp = data.docs[0].data().Date.seconds * 1000 - 5*24*60*60*1000; //5 days before the consult scheduled Date
                    // if(Number(Date.now()) <= Number(nextTimestamp)){
                      this.setSelectedCondition(consultTemplate)
                      this.verifyDraftConsults();
                    // }
                    // else {
                    //   this.checkDraftConsult(data.docs[0].data());
                    // }
                  }          
                  else{
                    this.router.navigate(['/account/404'])
                  }
                }
              }
              else if(data.docs[0] && data.docs[0].data().Status == 'Completed' || data.docs[0].data().Status == 'In Progress'){
                this.router.navigateByUrl('consultations');
                this.consultDetails(data.docs[0].data())
                this.emitPrescriptionTab = data.docs[0].data().Status == 'Completed' ? 2 : 1; 
              }
              else {
                this.router.navigate(['/account/404'])
              }
            })
          }
        }
      }     
    })   
    this.allSubscriptions.add(sub)
  }

  consultDownload
  openPdfDownload(consult){
    FileSaver.saveAs(consult.pdf, "consult.pdf");
    this.consultDownload = consult;
  }

  checkedTime = []
  checkedType = []
  suggestedHour = []
  saveSchedule($event, $event2?){
    if($event != ''){
      if($event.target.checked){
        this.checkedTime.push($event.target.id);
  
        if(this.checkedTime.includes(this.timeCopy[this.timeCopy.length-1])){
          this.checkedTime.splice(Number(this.timeCopy[this.timeCopy.length-1]), 1); 
        }  
        if(this.timeCopy.indexOf($event.target.id) == this.timeCopy.length-1) {
          this.checkedTime = [];
          this.checkedTime.push($event.target.id);
        }
      }
      else {
        let index = this.checkedTime.indexOf($event.target.id);
        if(index > -1){
          this.checkedTime.splice(index, 1);
        }
      }
    }
    else {
      if($event2.target.checked){
        this.checkedType.push($event2.target.id);
  
        if(this.checkedType.includes(this.typeCopy[this.typeCopy.length-1])){
          this.checkedType.splice(Number(this.typeCopy[this.typeCopy.length-1]), 1); 
        }
  
        if(this.typeCopy.indexOf($event2.target.id) == this.typeCopy.length-1) {
          this.checkedType = [];
          this.checkedType.push($event2.target.id);
        }
      }
      else {
        let index = this.checkedType.indexOf($event2.target.id);
        if(index > -1){
          this.checkedType.splice(index, 1);
        }
      }
    }
    this.afs.collection('consultation').doc(this.currentConsult.ID).update({schedule: {type: this.checkedType, time: this.checkedTime}});
    this.currentConsult.type = this.checkedType;
    this.currentConsult.time = this.checkedTime;
  }
  
  checkTime($event){
    if(this.checkedTime){
      if(this.checkedTime.includes($event)){
        return true;
      }
      else{
        return false;
      }   
    }
  }

  checkType($event){
    if(this.checkedType){
      if(this.checkedType.includes($event)){
        return true;
      }
      else{
        return false;
      }
    } 
  }

  saveHours(hour, id){
    this.suggestedHour[id] = hour
    for(let i = 0; i < id; i++){
      if(!this.suggestedHour[i]){
        this.suggestedHour[i] = ''
      }
    }
    this.afs.collection('consultation').doc(this.currentConsult.ID).update({suggestedHour: this.suggestedHour});
  }

  practitionerForConsult(event){
    this.afs.collection('consultation').doc(this.currentConsult.ID).update({practitionerForConsult: event.uid})
    this.currentConsult.practitionerForConsult = event.uid
  }

  getIdCountry(){
    let sub = this.afs.collection<any>('settings').doc('Country').collection('Country').valueChanges().subscribe(data => {
      this.idCountries = []; 
      for(let i = 0; i < data.length; i++) {
        if(data[i].enabled == true){
          this.idCountries.push(data[i])
        }
      }
    });
    this.allSubscriptions.add(sub)
  }

  idCountryWithStates = true;
  idStates = []
  idCountries = []
  async getIdStates(country: any){
    let countryChosen = await this.idCountries.find(c => c.country == country.country)
    if(countryChosen?.hasStates){
      let sub = this.afs.collection("settings").doc('Country').collection('Country').doc(country.country).collection('States').valueChanges().subscribe(data => {
        this.idStates = data;
        this.idStates.sort((a) => {
          if (a.name === 'Puerto Rico') {
            return -1;
          }
          else {
            return 1;
          }
        });
      });
      this.allSubscriptions.add(sub)
      this.idCountryWithStates = true
    }
    else {
      this.idCountryWithStates = false
      this.idStates = []
    }
  }

  saveAddress(){
    this.completedAdress = true
  }

  changeTimezone(modal){
    this.modalService.open(modal, {  size: 'md', centered:true, backdrop: 'static', keyboard: false })
  }

  saveTimezone(){
    this.afs.collection('users').doc(this.patientProfile.uid).update({timezone: this.currentTimezone})
      this.patientProfile.timezone = this.currentTimezone
  }

  selectTimezone(event) {
   this.currentTimezone = this.timezoneList.find(t => t.name == event)
  }

  //getSignature
  async getSignature(signature:string){
    this.signature = signature;
    this.modalRef.dismiss();
    this.modalRef = undefined;
    let data = {
      patientSignature: signature,
      patientSignatureDate: new Date(),
    }
    this.afs.collection("consultation").doc(this.currentConsult.ID).update(data);

    let history:ConsultHistory = {
      date: new Date(Date.now()),
      id: this.firebaseService.getDate(),
      userUID: this.user.uid,
      user: this.userProfile.firstName + ' ' + this.userProfile.lastName1,
      userAccountType: this.userProfile.accountType,
      action: MEDICAL_CONSULTATION.SIGNED,
      consultID: this.currentConsult.ID,
      consultStatus: this.currentConsult.Status,
      createdBy: this.user.uid
    }
    this.afs.collection('consultation').doc(this.currentConsult.ID).collection('History').doc(history.id).set(history)

    //newest possible versions
          // this.historyService.storeHistory(history);      ----  its own collection
          // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation
  }

  validateSignature(){
    this.afs.collection("consultation").doc(this.currentConsult.ID).valueChanges().subscribe(async(data:any )=> {
      this.currentConsult.creditCardRef = data?.creditCardRef;
      this.currentConsult.prescriptionOnly = data?.prescriptionOnly;
      this.currentConsult.acceptaID = data?.acceptaID;

      if(data.patientSignature){
        this.signature = data.patientSignature;
        this.refillTerms = data.acceptedRefillTerms;
      }
      else{
        this.signature = "";
        if(!this.modalService.hasOpenModals()){
          this.modalRef = this.modalService.open(this.signatureModal, { keyboard: false, size: 'lg', backdrop: 'static', centered: true});
        }
      }
    });
  }

  reevaluation(consult){
    this.currentConsult = consult;
    this.modalRef = this.modalService.open(this.reevaluateModal, { centered: true });
  }

  //NEW IMAGE THINGY
  public capture() {
    if(this.profileImage || this.takePicture){
      var oc = <HTMLCanvasElement> document.getElementById("imageCanvasTT");
      var occtx = oc.getContext('2d');
 
      var imgEl = this.video;
      var img = new Image();
      img.crossOrigin = "anonymous";
      img.src = imgEl.nativeElement.baseURI + imgEl.nativeElement.srcObject.id;
      oc.width = imgEl.nativeElement.videoWidth * 0.55;
      oc.height = imgEl.nativeElement.videoHeight * 0.55;
      occtx.drawImage(imgEl.nativeElement, 0, 0, oc.width, oc.height);
      
      var c = <HTMLCanvasElement> document.getElementById("imageCanvasT");
      var ctx = c.getContext("2d");

      c.width = 250;
      c.height = 250;
      let centerWidth = (oc.width - 250)/2
      let centerHeight = (oc.height - 250)/2

      ctx.drawImage(oc, centerWidth, centerHeight, 250, 250, 0, 0, 250, 250);
      this.captures = c.toDataURL('image/png')
      this.cropImage(this.captures)
    }
    else {
      if(!this.canvasID){
        this.canvasID = new ElementRef(document.getElementById('canvasID'))
      }
      this.canvasID.nativeElement.getContext("2d").drawImage(this.video.nativeElement, 0, 0, 350, 250)
      this.captures = this.canvasID.nativeElement.toDataURL("image/png")
      this.cropImage(this.captures)

    }
  }

  videoSrc
  startCam(){
    this.loadingImg = true
    ReloadComponent.loader = 'RELOAD.STARTCAM'
    if(!this.currentConsult.ID){
      this.modalRef = this.modalService.open(this.cameraModal, { size: 'lg', backdrop: 'static', windowClass: 'addAddressModal', centered: true})
    }

    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
        if(!this.video){
          this.video = new ElementRef(document.getElementById('video'))
        }
        this.video.nativeElement.srcObject = stream
        this.video.nativeElement.play();
        this.loadingImg = false
        this.videoSrc = stream
      });
    }   
  }

  async closeCam(){
    if(this.video && this.video.nativeElement){
      await this.video.nativeElement.pause()
      this.video.nativeElement.srcObject = await undefined
      if(this.videoSrc){
        this.videoSrc.getTracks()[0].stop()
      }
    }
  }

  rejectConsult() {
   this.close();
   this.startPageSwitch()
   this.afs.collection("consultation").doc(this.currentConsult.ID).update({Status: 'Rejected', currentQuestion: this.currentConsultQuestions[0].sequence, currentWizardStep: this.condition.steps[0].stepId || 'preconsultQuestions', submittedConsultDate: new Date(), dateToSort: new Date()});
  }

  createRejectedPdf(rejectedConsult, rejectedPDFModal){
    if(rejectedConsult.pdf){
      this.openPdfDownload(rejectedConsult)
    }
    else {
      this.currentConsult = rejectedConsult
      this.modalRef = this.modalService.open(rejectedPDFModal, { size: 'lg', backdrop: 'static', windowClass: 'addressModal', centered: true});
    }
  }

  verifyQuestions(){
    let consultTemplate = this.conditions.find(x => x.serviceType == this.currentConsult.serviceType)
    this.conditionCode = consultTemplate.conditionCode
    this.questionsCode = consultTemplate.conditionQuestionsId

    this.afs.firestore.collection("consultation").doc(this.currentConsult.ID).collection("Questions").get().then(questions => {
      if(questions.docs.length != this.currentConsult.totalQuestions) {
        this.questionService.getTemplateQuestions(this.questionsCode).then((res:any) => {
          let questionsToAdd = res.filter(q => this.currentConsultQuestions.filter(q1 => q1.id == q.id).length == 0)
          if(questionsToAdd.length > 0){
            this.firebaseService.addQuestionsToUser(questionsToAdd, this.currentConsult.ID);
          }
        })
      }
    });
  }

  followUpReasons = []
  followUpReasonsTxt = ''
  selectReason(event) {
    if (event.target.checked ) {
      this.followUpReasons.push(event.target.id);
    } else {
      let index = this.followUpReasons.indexOf(event.target.id.toString())
      this.followUpReasons.splice(index, 1);
    }
  }

  followUpTxt(event){
    this.followUpReasonsTxt = event.target.value
  }

  saveReasons(){
    this.afs.collection('consultation').doc(this.currentConsult.ID).update({updateFollowUpReasons: this.followUpReasons, updateFollowUpReasonsTxt: this.followUpReasonsTxt});
    this.dismiss()
    this.currentConsult = Object.assign(this.currentConsult, {updateFollowUpReasons: this.followUpReasons, updateFollowUpReasonsTxt: this.followUpReasonsTxt})
    this.editConsult(this.currentConsult);
  }

  verifyDraftConsults(){
    let draftConsult = this.inprogressConsults.filter(consult => consult.Status == 'Draft' && consult.Type == this.condition.id)[0]
    
    if(draftConsult){
      const nextTimestamp = draftConsult.Date.seconds * 1000 - 5*24*60*60*1000; //5 days before the consult scheduled Date
      if(Number(Date.now()) <= Number(nextTimestamp)){
        this.modalRef = this.modalService.open(this.updateFollowUp, { size: 'md', backdrop: 'static', centered: true})
        this.onClick = false;
        this.currentConsult = draftConsult;
      }
      else {
        this.editConsult(draftConsult)
      }
    }
    else {
      this.verifyLastConsultStatus()
    }
  }

  async verifyLastConsultStatus(){
    let completedConsults = this.completedConsults.filter(consult => consult.Type == this.condition.id)
    
    for(let i of completedConsults){
      if(i.Status == 'In Progress' || i.Status == 'Paid'){
        this.translate.get('CONSULTATIONS.SWAL').subscribe((res: any) => {
          this.swal = res
        })
        
        Swal.fire({
          customClass: {
            confirmButton: 'swalPurple',
          },
          html: `<img class="w-25 mx-auto" src="${this.condition.Photo1}"><div><h3>${this.swal.CONSULTINPROGRESS}</h3></div><div><p>${this.swal.INPROGRESSWARN + this.pipe.transform(this.condition.Name, this.currentLang) + this.swal.PREVCONSULT + this.pipe.transform(this.condition.Name, this.currentLang) + this.swal.CONSULTSTATUS}</p></div>`,
          iconColor: '#ff0000 !important',
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.modalService.dismissAll()
            this.creatingConsult = false;
            this.onClick = false;
          }
        }); 
        break;
      }

      if(i.Status == 'Completed'){
        if(i.serviceType == this.condition.serviceType){
          this.followUpToEvaluate = i;
          if(this.previousUrlPath === "/prescription-list" || this.previousUrlPath === "/orders&Refills"){
            this.consultChoosed();
          }else{
            this.checkPrescriptions();
          }
          break;
        }
      }
  
      if(i.Status == 'Rejected'){
        const nextTimestamp = Date.now() - 30*24*60*60*1000; //30 days ago
        // const nextTimestamp = Date.now() - 365*24*60*60*1000; //365 days ago
  
        if(Number(i.Date.seconds * 1000) >= Number(nextTimestamp)){
          this.translate.get('CONSULTATIONS.SWAL').subscribe((res: any) => {
            this.swal = res
          })
          var first = (new Date(nextTimestamp)).getTime();
          var second = (new Date(i.Date.seconds * 1000)).getTime(); 
          var diferenceInDays: any = (((second - first) / 1000 )/3600)/24;
          
          diferenceInDays = diferenceInDays.toString().split('.')[0]
  
          Swal.fire({
            customClass: {
              confirmButton: 'swalPurple',
            },
            html: `<img class="w-25 mx-auto" src="${this.condition.Photo1}"><div><h3>${this.swal.CONSULTREJECTED}</h3></div><div><p>${this.swal.REJECTEDWARN + this.pipe.transform(this.condition.Name, this.currentLang) + this.swal.COMEBACK + diferenceInDays + this.swal.TRYAGAIN}</p></div>`,
            iconColor: '#ff0000 !important',
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.modalService.dismissAll()
              this.creatingConsult = false;
              this.onClick = false;
            }
          });  
          break;      
        }
      }  
    }

    if(completedConsults.length == 0){
      this.generateConsult()
    }
  }

  consultDetails(consult:Object){
    this.showConsultDetails = true;
    this.currentConsult = consult;
    this.afs.firestore.collection('users').doc(this.currentConsult.UID_Patient).get().then((patient:any) => {
      this.patientProfile = patient.data();
    })
  }

  async getShippings(){
    return new Promise<any>((resolve, reject) => {
        this.productService.getShipping(this.currentConsult.Type).then((snapshot:any) => {
          resolve(snapshot);
        })
    });
  }

  checkPackages(days){
    return this.treatments?.filter(pack => pack.refillIntervalDays == days).length > 0
  }

  activateSubscription(subscription:any){
    this.afs.collection("subscriptions").doc(subscription.id).update({state: "In Progress"})
  }
  
  pauseSubscription(subscription:any){
    return new Promise<boolean>((resolve, reject) => {
      this.afs.collection("subscriptions").doc(subscription.id).update({state: "Hold"}).then(() => {
        this.modalRef.close()
        resolve(true);
      });
    });
  }

  checkPrevQuestion(){
    let sequenceCounter = this.currentConsultQuestions[this.currentConsult.currentQuestion - 2].sequence;

    this.afs.collection('consultation').doc(this.currentConsult.ID).update({currentQuestion: sequenceCounter})
  }

  consultChoosed(){
    const nextTimestamp = this.followUpToEvaluate.Date.seconds * 1000 + this.conditionRules.daysForNextOriginalConsult*24*60*60*1000; //days from the last original consult + time for new original consult not follow up
    if(this.conditionRules.daysForNextOriginalConsult == 0){
      this.modalRef = this.modalService.open(this.chooseTypeOfConsultModal, { size: 'lg', backdrop: 'static', windowClass: 'followUpOrRefillModal', centered: true});
    } else 
    if(Number(Date.now()) >= Number(nextTimestamp)){ //time for a new original consult not follow up
      let consultTemplate = this.conditions.find(x => x.serviceType == this.conditionRules.consultTemplate)
      this.setSelectedCondition(consultTemplate)
      this.generateConsult()
    }
    else {
      let consultTemplate = this.conditions.find(x => x.serviceType == this.conditionRules.followUpTemplate)
        this.setSelectedCondition(consultTemplate)
        this.generateConsult()
        if(this.modalRef !== undefined){
          this.modalRef.close()
          this.modalRef = undefined;
        }
    }
  }

  prescriptions = [];
  checkPrescriptions(){
    let currentUser: any = this.authService.currentUser();
    this.prescriptions = [];
    this.afs.firestore.collection("erp_dzeus_query_prescription_full_info").where("uid", "==", currentUser.uid).where('valid', '==', true).get().then(async (prescriptions) => {
      if(prescriptions.docs.length > 0){
        prescriptions.docs.forEach(async (prescription, index) => {
          var d = new Date(prescription.data().date.toDate());
          var year = d.getFullYear();
          var month = d.getMonth();
          var day = d.getDate();
          var expires = new Date(year + 1, month, day);
          
          if(expires >= new Date(Date.now())){
            this.prescriptions.push(Object.assign({expirationDate: expires}))
          }
          if(index == prescriptions.docs.length - 1 && this.prescriptions.length == 0){
            this.consultChoosed();
          }
          if(index == prescriptions.docs.length - 1 && this.prescriptions.length != 0){
            this.modalRef = this.modalService.open(this.followUpOrRefillModal, { size: 'lg', backdrop: 'static', windowClass: 'followUpOrRefillModal', centered: true});
          }
        });
      }else{
        this.consultChoosed();
      }
    });
  }

  handleSection(){
    let coll = document.getElementById("collapsible-content");
    let arrow = document.getElementById("arrowFunction");
    if (coll.style.display === "block") {
      coll.style.display = "none";
      arrow.classList.remove("fa-chevron-up");
      arrow.classList.add("fa-chevron-down");
    } else {
      coll.style.display = "block";
      arrow.classList.remove("fa-chevron-down");
      arrow.classList.add("fa-chevron-up");
    }
  }

  handlePaymentSuccess(){
    if(this.currentConsult.Status == 'Draft' || this.currentConsult.Status == 'Open'){
      this.paymentIntentSuccess = true;
      
      setTimeout(() => {
        this.doProgress(); 
        this.scrollToTop();
        this.activeBtn = true;
        this.consultWizardStep(); 
      }, 1000);

      if(this.appointment){
        let meetData = {
          randomId: this.firebaseService.getDate(),
          eventName: this.appointment.title,
          startTime: this.appointment.start,
          endTime: this.appointment.end,
          patientEmail: this.appointment.patientEmail,
          practitionerEmail: this.appointment.practitionerEmail,
          description: this.appointment.description,
          timezone: this.appointment.eventTimezoneValue,
        }
        this.paymentIntentError = false;
        this.chargeProcessingStripe = false;


        //newest possible versions
        // this.historyService.storeHistory(history);      ----  its own collection
        // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation
     
        let data = {
          room: '',
          meetRef: '',
          id: this.appointment.id,
        }
        ReloadComponent.loader = 'RELOAD.UPDATING';
        this.telehealthService.createMeet(meetData).then((res: any) => {
          data.room = res.conferenceData.entryPoints[0].uri
          data.meetRef = res.id
          this.firebaseService.scheduleEventUpdate(data);
        })
      }

      this.paymentIntentError = false;
      this.chargeProcessingStripe = false;
      setTimeout(() => this.paymentIntentSuccess = false, 3000);
      let history:ConsultHistory = {
        date: new Date(Date.now()),
        id: this.firebaseService.getDate(),
        userUID: this.user.uid,
        user: this.userProfile.firstName + ' ' + this.userProfile.lastName1,
        userAccountType: this.userProfile.accountType,
        action: `${MEDICAL_CONSULTATION.SUBMITTED} by ${this.userProfile.accountType}`,
        consultID: this.currentConsult.ID,
        consultStatus: this.currentConsult.Status,
        createdBy: this.user.uid
      }
      this.afs.collection('consultation').doc(this.currentConsult.ID).collection('History').doc(history.id).set(history)
      
      this.afs.collection('consultation').doc(this.currentConsult.ID).update({
        Status: "Paid",
        submittedConsultDate: new Date(),
        dateToSort: new Date(),
      });

      if(this.currentConsult.practitionerForConsult != 'random' && this.currentConsult.practitionerForConsult != ''){
        let user = this.myPractitioners.find(p => p.uid == this.currentConsult.practitionerForConsult)
        this.emailService.sendSms(user, '', {en:'Dzeus Alerts - A patient has selected you for a consultation. Please log in to review the consultation at https://app.dzeus.com/consults', es: 'Alertas de Dzeus - Un paciente lo ha seleccionado para una consulta. Inicie sesion para revisar la consulta en https://app.dzeus.com/consults.'})
      }
    }
  }

  labTemplates = []
  diagnosticTemplates = []
  getLabs(){
    this.afs.collection('diagnosticTemplates').valueChanges().subscribe(data => {
      this.diagnosticTemplates = data;
      
      this.afs.firestore.collection('labAnalysisTemplates').where('showPatient', '==', true).where('condition', 'array-contains', this.currentConsult.Type).get().then((labs:any) => {
        this.labTemplates = labs.docs.map(lab => lab.data());
  
        if(this.consultLabs.length == 0){
          this.labTemplates.forEach(lab => {
            if(lab.required){
              this.handleLabSelection(lab);
              lab.icd10.forEach(d => {
                let diagnostic = this.diagnosticTemplates.find(diag => diag.code == d);
                this.afs.collection("consultation").doc(this.currentConsult.ID).collection("diagnosticTemplates").doc(diagnostic.id).set(Object.assign(diagnostic, {timesAdded: 1}));
              })
            }
          })
        }
      })
    })

  }

  setLabChecked(product){
    let selectedProduct = this.consultLabs ? this.consultLabs.filter(p => p.labName == product.labName) : []
    return selectedProduct.length != 0
  }

  selectedLaboratory: any = {download: false};
  selectLab(event){
    this.selectedLaboratory = event;
    this.afs.collection('consultation').doc(this.currentConsult.ID).update({selectedLaboratory: this.selectedLaboratory})
  }

  imgType = ''
  chooseImgEnv(imgType,modal){
    this.imgType = imgType
    this.modalRef = this.modalService.open(modal, { size: 'md', backdrop: 'static', windowClass: 'chooseImgEnvModal', centered: true});
  }

  verifyAddressAndCard(){
    this.getAddress().then((address:any) => {
     if(address){
       this.getCard().then((card:any) => {
          if(card){
            console.warn(this.shippingService.length, this.selectedItems.length == 0)
            if(this.shippingService.length > 0 || this.selectedItems.length == 0){
              this.createAcceptaPaymentInitiation()
            }
            else {
              if(this.shippingService.length == 0 && this.selectedItems.length > 0){
                this.translate.get('CONSULTATIONS').subscribe((res: any) => {
                  this.swal = res
                })
                Swal.fire({
                  customClass: {
                    confirmButton: 'swalPurple',
                  },
                  html: `<div><h3>${this.swal.NOSHIPPING}</h3></div>`,
                  iconColor: '#ff0000 !important',
                  confirmButtonText: 'OK',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                })           
              }
            }
          }
       })
      }
    })
  }

  validateAddress = false;
  validateAddressState = 'pending'
  validateCard = false;
  validateCardState = 'pending'
  getAddress(){
    const paymentBtn = <HTMLButtonElement>document.getElementById('spinner-pay');
    paymentBtn.classList.add("spinner-border");
    paymentBtn.classList.add("spinner-border-sm");

    let promise = new Promise((resolve, reject) => {
      this.validateAddress = true;
      this.validateAddressState = 'pending';
      let interval = setInterval(() => {
        if(this.validateAddressState == 'valid'){
          resolve(true) 
          clearInterval(interval)
          this.validateAddress = false;
          paymentBtn.classList.remove("spinner-border");
          paymentBtn.classList.remove("spinner-border-sm");
        }
        if(this.validateAddressState == 'error'){
          resolve(false)
          clearInterval(interval)
          this.validateAddress = false;
          paymentBtn.classList.remove("spinner-border");
          paymentBtn.classList.remove("spinner-border-sm");
        }
      }, 1000)
    })
    return promise 
  }

  getCard(){
    const paymentBtn = <HTMLButtonElement>document.getElementById('spinner-pay');
    paymentBtn.classList.add("spinner-border");
    paymentBtn.classList.add("spinner-border-sm");

    let promise = new Promise((resolve, reject) => {
      this.validateCard = true;
      this.validateCardState = 'pending';
      let interval = setInterval(() => {
        if(this.validateCardState == 'valid'){
          resolve(true)
          clearInterval(interval)
          this.validateCard = false;
          paymentBtn.classList.remove("spinner-border");
          paymentBtn.classList.remove("spinner-border-sm");
        }
        if(this.validateCardState == 'error'){
          resolve(false)
          clearInterval(interval)
          this.validateCard = false;
          paymentBtn.classList.remove("spinner-border");
          paymentBtn.classList.remove("spinner-border-sm");
        }
      }, 1000)
    })
    return promise 
  }

  saveEmitter(event){
    if(this.patientProfile){
      this.patientProfile[event.target.name] = event.target.value
    }
    else {
      this.patientProfile = {[event.target.name]: event.target.value}
    }

    this.afs.collection('users').doc(this.patientProfile.uid).update({[event.target.name]: event.target.value})
    
    if(this.currentWizardStep != 'phoneNumber'){ //the phone number is managed differently
      this.activeBtn = true;
      this.consultWizardStep()
    }
  }

  addID(){
    this.currentConsult = {}
    if(this.hide){
      this.cropImage();
      this.profileImage = false;
      this.profileId = true;
      this.front = true;
      this.ratio = 485/300
    }
    else {
      this.profileImage = false;
      this.profileId = true;
      this.front = true;
      this.ratio = 485/300
      this.chooseImgEnv('ID', this.chooseImageEnvironment);
    }
  }

  filterConsultations(term: string) {
    if (!term) {

      // this.allFilteredConsults = this.allConsults;

      if(this.currentSelectedStatus === 'All'){

        return this.allFilteredConsults;

      }
      else{
  
        this.allFilteredConsults = this.allConsults?.filter((consult: any) => { 
          return consult.Status === this.currentSelectedStatus;
        });
  
      }

    }
    else {

      this.allFilteredConsults = this.allConsults.filter(user => {
        return Object.values(user).some(val => {
          if (typeof val === 'string') {
            return val.toLowerCase().includes(term.toLowerCase());
          }
          return false;
        });
      });

    }

    this.pageConsults = 1;
  }

  getCurrentFilterStatus(status){

    this.currentSelectedStatus = status;

    this.allFilteredConsults = this.allConsults;

    this.showLoadingConsults = false;

    if(status === 'All'){

      return this.allFilteredConsults;
    }
    else{

      this.allFilteredConsults = this.allFilteredConsults?.filter((consult: any) => { 
        return consult.Status === this.currentSelectedStatus;
      });

    }

  }

}
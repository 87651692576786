<div class="container">
  <div class="d-flex align-items-center padding" *ngIf="practitioner">
    <img src="{{practitioner.image}}" class="rounded-circle cropped" style="width: 15%;">
    <div class="ml-3">
      <h4 class="orange">{{practitioner.firstName}} {{practitioner.lastName1}}</h4>
      {{'TELEHEALTH.SCHEDULE' | translate}} 
      <h5 class="bold" *ngIf="practitionerSchedule && practitionerSchedule[selectedDay].schedule.length > 0">{{(selectedDate + 'T' + practitionerSchedule[selectedDay].schedule[0].start) | date:'hh:mm aa'}} - {{selectedDate + 'T' + practitionerSchedule[selectedDay].schedule[practitionerSchedule[selectedDay].schedule.length - 1].end | date:'hh:mm aa'}}</h5>
      <h5 class="bold" style="color: red;" *ngIf="practitionerSchedule && practitionerSchedule[selectedDay].schedule.length == 0">{{'APPOINTMENTS.NOWORK' | translate}}</h5>
    </div>
  </div>
  <div class="scheduler d-flex flex-column">
    <div class="preview d-flex" [ngClass]="{'flex-column': router.url == '/consultations'}">
      <div class="leftSide" [ngClass]="{'w-100': router.url == '/consultations'}">
        <div class="details mt-3" *ngIf="appointment">
          <div class="d-flex justify-content-between">
            <h3 *ngIf="appointment">{{'APPOINTMENTS.SCHEDULED' | translate}}</h3>
            <div class="time">
              <i class="far fa-clock"></i>
              <span>{{appointment.duration}} min</span>
            </div>
          </div>
          <span class="header">{{'APPOINTMENTS.DATE' | translate | uppercase}}</span><br>
          <span>{{appointment.start | date: 'fullDate':'':patient?.preferedLang}}</span><br>
          <span class="header">{{'APPOINTMENTS.HOUR' | translate | uppercase}}</span><br>
          <span>{{appointment.start | date: 'hh:mm aa'}} - {{appointment.end | date: 'hh:mm aa'}}</span>
        </div> 
        <div class="mt-2 padding" *ngIf="suggestedHours[0] || suggestedHours[1]">
          <h4 class="bold" style="color: red;">{{'APPOINTMENTS.SUGGEST' | translate}}</h4>
          <p class="bold m-0 mt-2">{{'APPOINTMENTS.SUGGESTEDHOURS' | translate}}</p>
          <p class="m-0" *ngIf="suggestedHours[0]">{{'APPOINTMENTS.BEFORE' | translate}} {{suggestedHours[0] | date:'dd MMMM, yyyy hh:mm aa':'':currentLang}}</p>
          <p *ngIf="suggestedHours[1]">{{'APPOINTMENTS.AFTER' | translate}} {{suggestedHours[1] | date:'dd MMMM, yyyy hh:mm aa':'':currentLang}}</p>   
        </div>    
      </div>
      <div class="calendar mt-2"  [ngClass]="{'w-100': router.url == '/consultations'}">
        <!-- {{'APPOINTMENTS.POSSIBLEHOURS' | translate}}       -->
        <div>
          <div>
            <div class="row mt-3" style="text-align: center;">
              <div class="col-lg-12">
                <div class="form mx-lg-auto">
                  <h4>{{'APPOINTMENTS.NEWAPPOINTMENT' | translate}}</h4>          
                  <form *ngIf="eventform" [formGroup]="eventform">                
                    <div class="form-group row">
                      <div class="mx-auto align-self-center" >
                        <label for="startDate" class="inp input">
                          <input id="startDate" class="form-control" type="datetime-local" placeholder="&nbsp;" formControlName="Start" (change)="calcEndTime(eventform.controls.Start.value); selectedDate = eventform.controls.Start.value.split('T')[0]">
                          <span class="label">{{'EVENTS.STARTDATE' | translate}}</span>
                          <span class="focus-bg"></span>
                        </label>                   
                      </div>
                    </div>
                    <div class="form-group row m-0" [ngClass]="{'disableDiv': true}" >
                      <div class="mx-auto align-self-center" >
                        <label for="endDate" class="inp input"> 
                          <input id="endDate" class="form-control" type="datetime-local" placeholder="&nbsp;" formControlName="End">
                          <span class="label">{{'EVENTS.ENDDATE' | translate}}</span>
                          <span class="focus-bg"></span>
                        </label>
                      </div>
                    </div>
                    <p style="color: red; margin: 0;">{{warning | translate}}</p>
                    <div class="row justify-content-center" style="padding-bottom: 20px; padding-top: 20px;">
                      <button *ngIf="consult" type="button" class="btn btn-info btn-cyan btn-submit" (click)="createEventTest()" [disabled]="ret == true">{{'TELEHEALTH.REQUESTEVENT' | translate}}</button> 
                      <button *ngIf="!consult" type="button" class="btn btn-info btn-cyan btn-submit" (click)="requestScheduleChange()" [disabled]="ret == true">{{'TELEHEALTH.REQUESTEVENT' | translate}}</button> 
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div *ngFor="let dates of posibleDates">
    <div class="details">
      <span class="header">{{'APPOINTMENTS.BETWEEN' | translate}}: </span><br>
      <span>{{dates.start | date:'hh:mm aa'}} - {{dates.end | date:'hh:mm aa'}}</span><br>
    </div>
  </div> -->
  <!-- <full-calendar #calendar hidden [options]="calendarOptions" class="calendar"></full-calendar> -->
</div>
<div class="main">
  <div class="form-group" style="width: 90%; margin: 0 5% !important;">
    <i (click)="back.emit()" class="fas fa-arrow-left vertical-align cursor font-size-20"></i>
  </div>
  <div class="title mt-5 flex-column px-3">
    <div class="d-flex flex-column">
      <img style="width: 125px !important;" src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fpromos%2FDzeus_Sample_Promo_Pills30_R.png?alt=media&token=f921930b-113a-4d3c-8a22-5498934dd8cf">
      <h3 class="bold" style="text-align: start;">{{'ENGAGEMENT-STEP.TITLE' | translate}}</h3>
      <!-- <span class="bold">{{'ENGAGEMENT-STEP.DESCRIPTION' | translate}}</span> -->
    </div>
    <div class="items mt-2">
      <div class="d-flex flex-row align-items-center mb-2">
        <!-- <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
          <path d="m9 12 2 2 4-4"></path>
        </svg> -->
        <img src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fconditions%2FStethoscope_Icon.png?alt=media&token=ff5bf47a-7de4-4d12-bd76-889dd4a54598">
        <span class="ml-2">{{'ENGAGEMENT-STEP.ITEM1' | translate}}</span>
      </div>
      <div class="d-flex flex-row align-items-center mb-2">
        <!-- <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
          <path d="m9 12 2 2 4-4"></path>
        </svg> -->
        <img src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fconditions%2FFDA_Logo.png?alt=media&token=82d0c54f-6f75-4472-b416-b237aaf702d1">
        <span class="ml-2">{{'ENGAGEMENT-STEP.ITEM2' | translate}}</span>
      </div>
      <div class="d-flex flex-row align-items-center mb-2"> <!-- esto debe llevar una validacion de acuerdo a la consulta  -->
        <!-- <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
          <path d="m9 12 2 2 4-4"></path>
        </svg> -->
        <img src="https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Fconditions%2FEnvelope_Icon.png?alt=media&token=db3b0b4e-4a2a-4700-9819-0f0835801b2e">
        <span class="ml-2"><span *ngIf="consultRules.freeConsult">{{'ENGAGEMENT-STEP.ITEM3' | translate}}</span> {{'ENGAGEMENT-STEP.ITEM4' | translate}} <span *ngIf="consultRules.freeConsult">{{'ENGAGEMENT-STEP.ITEM5' | translate}}</span> {{'ENGAGEMENT-STEP.ITEM6' | translate}}</span>
      </div>
      <!-- <div class="d-flex flex-row align-items-center mb-2"> 
        <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"></path>
          <path d="m9 12 2 2 4-4"></path>
        </svg>
        <span class="ml-2">{{'ENGAGEMENT-STEP.ITEM7' | translate}}</span>
      </div> -->
    </div>
    <div id="buttons" class="row ml-0 mr-0 mb-2">
      <div class="form-group justify-content-center text-center mt-3 flex-column d-flex" style="margin: 0 auto;">
        <button class="btn btn-info nextbtn text-center mb-2" (click)="next.emit();">{{'CONSULTATIONS.FREECONSULT' | translate}} <i class="fa-solid fa-angle-right" style="font-style: normal;"></i></button>
      </div>
    </div>
    <div>
      <span style="max-width: 400px; font-size: 8px !important; display: block;">{{'ENGAGEMENT-STEP.FOOTER' | translate}}</span>
    </div>
  </div>
</div>
export enum MEDICAL_CONSULTATION {
  CREATED = 'Medical Consultation Created',
  DRAFT = 'Medical Consultation in Draft',
  OPEN = 'Medical Consultation Open',
  STARTED = 'Medical Consultation Started',
  IN_PROGRESS = 'Medical Consultation In Progress',
  COMPLETED = 'Medical Consultation Completed',
  SUBMITTED = 'Medical Consultation Submitted',
  PAID = 'Medical Consultation Paid',
  ABANDONED = 'Medical Consultation Abandoned',
  REOPENED = 'Medical Consultation Reopened',
  CANCELLED = 'Medical Consultation Cancelled',
  REJECTED = 'Medical Consultation Rejected',
  AQURIED = 'Medical Consultation Aquired',
  ACCEPTED = 'Medical Consultation Accepted',
  SIGNED = 'Medical Consultation Signed',
  BILL = 'Medical Consultation Bill Created',
  PAID_BILL = 'Medical Consultation Bill Paid',

} 

export enum LAB_RESULTS  {
  STARTED = 'Lab results Started',
  IN_PROGRESS = 'Lab results In Progress',
  COMPLETED = 'Lab results Completed',
  UPLOADED = 'Lab results Uploaded',
  NEGATIVE = 'Lab results Negative',
  POSITIVE = 'Lab results Positive',
  PAID = 'Lab results Paid',
}

export enum PRESCRIPTION {
  STARTED = 'Prescription Started',
  IN_PROGRESS = 'Prescription In Progress',
  COMPLETED = 'Prescription Completed',
  SIGNED = 'Prescription Signed',
  PAID = 'Prescription Paid',
}

export enum PROCESS_HISTORY_DESCRIPTION {
  AQURIED = "Aquired by",
  ASSIGNED = "Assigned by",
  CREATED = "Created by",
}

export enum MEDICAL_REFERAL {
  CREATED = 'Medical Referral Created',


}


export enum SUBSCRIPTION_STATES {
  ACTIVE = 'Active',
  INPROGRESS = 'In Progress',
  HOLD = 'Hold',
  CLOSED = 'Closed',
}

export enum SUBSCRIPTION_HISTORY_DESCRIPTION {
  CREATED = "Subcription created by",
  UPDATED = "Subcription updated by",
  CANCELLED = "Subcription cancelled by",
  ACTIVATED = "Subcription activated by",
  CLOSED = "Subcription closed by",
  HOLD = "Subcription holded by",
  PAUSED = "Subcription paused by",

}

// export const PROCESS_HISTORY_ADMIN = {
//   MEDICAL_CONSULTATION: {
//     DRAFT: 'Medical Consultation in Draft',
//     OPEN: 'Medical Consultation Open',
//     STARTED: 'Medical Consultation Started',
//     IN_PROGRESS: 'Medical Consultation In Progress',
//     COMPLETED: 'Medical Consultation Completed',
//     SUBMITED: 'Medical Consultation Submited',
//     PAID: 'Medical Consultation Paid',
//     REOPENED: 'Medical Consultation Reopened',
//     CANCELLED: 'Medical Consultation Cancelled',
//     REJECTED: 'Medical Consultation Rejected',
//     ACCEPTED: 'Medical Consultation Accepted',
//     SIGNED: 'Medical Consultation Signed',
//     BILL: 'Medical Consultation Bill Created',
//     PAID_BILL: 'Medical Consultation Bill Paid',

//   },
//   LAB_RESULTS: {
//     STARTED: 'Lab results Started',
//     IN_PROGRESS: 'Lab results In Progress',
//     COMPLETED: 'Lab results Completed',
//     UPLOADED: 'Lab results Uploaded',
//     NEGATIVE: 'Lab results Negative',
//     POSITIVE: 'Lab results Positive',
//     PAID: 'Lab results Paid',
//   },
//   PRESCRIPTION: {
//     STARTED: 'Prescription Started',
//     IN_PROGRESS: 'Prescription In Progress',
//     COMPLETED: 'Prescription Completed',
//     SIGNED: 'Prescription Signed',
//     PAID: 'Prescription Paid',
//   },
// };

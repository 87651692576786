import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ProductsService } from './products/products.service';
import { OrdersService } from './orders.service';
import { v4 as uuidv4 } from 'uuid';
import { BaseProduct, createOrder } from './interfaces';
@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {

  constructor(
    private db: AngularFirestore,
    private orderService: OrdersService,
    private productService: ProductsService
  ) { }



  async getActiveSubscriptions(uid:string){
    return new Promise<any>((resolve, reject) => {
      this.db.collection("subscriptions", ref => ref.where("state", "==", 'In Progress').where('uid', '==', uid)).valueChanges().subscribe(async (subs:any) => {
        resolve(subs);
      });
    });
  }

  async getSubscriptionsOverlap(condition, uid) {
    return new Promise<any>((resolve, reject) => {
      this.db.collection("subscriptions", ref => ref.where("state", "==", 'In Progress').where('uid', '==', uid).where("condition", "array-contains-any", condition)).valueChanges().subscribe(async (subs:any) => {
        // let subscriptions = [];
        // let subscriptions = subs.map(x => {
        //   this.db.collection("products").doc(x.productID.toString()).valueChanges().subscribe((product:any) => {
        //     let obj
        //     const { atr0,atr1, atr2, atr3, atr4, atr5, atr6, atr7, atr8, atr9, ...other } = product;
        //     let atrs = {
        //       atr0,atr1, atr2, atr3, atr4, atr5, atr6, atr7, atr8, atr9
        //     }
        //     obj = Object.assign(x, atrs);
        //     subscriptions.push(obj);
        //     console.log(subscriptions);
        //     return obj
        //   });
        // });
        if(subs.length > 0){
          subs.forEach(element => {
            let obj 
            this.db.collection("products").doc(element.productID.toString()).valueChanges().subscribe((product:any) => {
              const { atr0,atr1, atr2, atr3, atr4, atr5, atr6, atr7, atr8, atr9, tabletsQty, ...other } = product;
              let atrs = {
                atr0,atr1, atr2, atr3, atr4, atr5, atr6, atr7, atr8, atr9, tabletsQty
              }
              obj = Object.assign(element, atrs);
              // subscriptions.push(obj);
              // console.log(obj);
              resolve(obj);
            });
          });
        }else{
          resolve(undefined);
        }
      });
    });
  }

  async getProductsPlans(condition:string, type:string){
    return new Promise<any>((resolve, reject) => {
      if(type == "products"){
        this.db.collection("products", ref => ref.where("condition", "array-contains", condition).where("type", "==", "product")).valueChanges().subscribe(async (plans:any) => {
          resolve(plans.filter(x => x.qty > 0 && x.hidePractitioner == false && (x.refillIntervalDays == 90 || x.refillIntervalDays == 30)).sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
          }).sort((a, b) => {
            if(b.price == "0.00" ){
              return -1
            }
            return 0
          }));
        });
      }
      else {
        this.db.collection("products", ref => ref.where("condition", "array-contains", condition).where("type", "==", "service")).valueChanges().subscribe(async (plans:any) => {
          resolve(plans);
        });
      }
    });
  }



  async createSubscription(wishlistProduct, details, patient){
    // With the id provided from the wishlist product search the subscritpion
    let service = await this.productService.getProduct(wishlistProduct.subProductID.toString());
    // console.error("found product service", service)
    if(service){
      // console.log("service", service);
      let date = new Date(Date.now());
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();
      let id:any = yyyy + mm + dd + uuidv4();

      let data:createOrder = {
        acceptaID: details.acceptaID,
        id: id,
        uid: patient.uid,
        email: patient.email,
        lang: patient.preferedLang,
        date: new Date(),
        paymentStatus: "pending",
        phone: patient.phoneNumber,
        partnerID: patient.partnerID, 
        orderState: "draft",
        confirmed: false,
        consultationID: details?.consultationID,
        orderLineUpdate: 0,
        orderId: "",
        productType: 'service',
        syncPromo: false,
        attempts: 0
      }
      let product: BaseProduct = {
        id: uuidv4(),
        image: service.imgRef ? service.imgRef : "",
        productID: service.id.toString(),
        productName: service.name,
        productPrice: await this.productService.calculateSubscriptionDiscount(wishlistProduct),
        productQty: "1",
        recurrency_order: wishlistProduct.recurrency_order,
        type:service.type,
        orderLineID: "",
        automaticPayment:false
        // promoProductID: wishlistProduct.productID ? wishlistProduct.productID.toString() : '0'
      }
      // console.log(" logging ", await this.productService.calculateSubscriptionDiscount(wishlistProduct))
      // let subscriptionOrder = await this.orderService.createOrder(data);
      // console.log('TAIKO',subscriptionOrder);
      // this.orderService.createOrderLine(subscriptionOrder.id, product);
      // this.db.collection("orders").doc(data.id).update({orderState: 'create'})
      let observe = await this.observeChangesInOrder(data.id, wishlistProduct);
      return observe;
    }
  }


  private async observeChangesInOrder(id, product){
    return new Promise<any>((resolve, reject) => {
      this.db.collection("orders").doc(id).valueChanges().subscribe(async (order:any) => {
        // if(Number(order.orderTotal) == Number(product.productPrice)){
          // console.log("total", Number(order.orderTotal));
          // console.log("discount", Number(this.productService.calculateSubscriptionDiscount(product)));

        let total = Number(await this.productService.calculateSubscriptionDiscount(product))
        if(Number(order.orderTotal) == total){
          resolve(order);
        }
        // if(Number(order.orderTotal) == Number(this.productService.calculateSubscriptionDiscount(product))){
        //   console.error("resolved")
        //   resolve(order);
        // }
      });
    });
  }

}

<app-pagetitle [title]="pagetitle"></app-pagetitle>

<div class="box fade-in userProfile" *ngIf="!linkPhoneNumber && profile">
  <div class="d-flex flex-row justify-content-between title">
    <h2 style="font-size: x-large;" class="cyan mt-3">{{'PROFILE.USERPROFILE' | translate}}</h2>
    <div *ngIf="editProfile == false" class="mt-3">
      <div class="btn btn-primary py-0" style="cursor: pointer"  (click)="editProfile = true; editId = false;">{{'ACCOUNTS.NGBTOOLTIP.EDIT' | translate}}</div>          
    </div>    
  </div>
  <hr style="margin: auto; border: none; border-bottom: 2px dotted #e9ebee;">
  <div *ngIf="(!editProfile || !openedCam) && !hide" class="row justify-content-center" [ngClass]="{'disableDiv': editProfile == false}" style="width: 100%;">
    <img style="width: 250px; height: 250px;" class="mt-2 cropped" src="{{profile.image}}" (click)="startCam(); openedCam = true" alt="Header Avatar">
  </div>
  <div *ngIf="(!editProfile || !openedCam) && !hide" class="changeProfile-img" (click)="startCam(); openedCam = true; editProfile = true; editId = false;"><i class="fas fa-camera font-size-18" style="color: #74788D" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.EDIT' | translate}}" (click)="editProfile = true; editId = false; startCam(); openedCam = true"></i></div>
  <div *ngIf="(!editProfile || !openedCam) && hide" class="row justify-content-center" [ngClass]="{'disableDiv': editProfile == false}" style="width: 100%;">
    <img style="width: 250px; height: 250px;" class="mt-2 cropped" src="{{profile.image}}" (click)="cropImage(); front = undefined; editProfile = true; editId = false; ratio = 1" alt="Header Avatar">
    <input type="file" id="file-upload" hidden accept="image/*" capture="environment" #img (change)="fileSelected($event); editProfile = true; editId = false;"> 
  </div>
  <div *ngIf="(!editProfile || !openedCam) && hide" class="changeProfile-img" (click)="cropImage(); front = undefined; editProfile = true; editId = false; ratio = 1"><i class="fas fa-camera font-size-18" style="color: #74788D" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.EDIT' | translate}}" (click)="editProfile = true; editId = false;"></i></div>
  <input type="file" id="file-upload" hidden accept="image/*" capture="environment" #img (change)="fileSelected($event); editProfile = true; editId = false;"> 

  <div style="color: red;" *ngIf="profile.image == defaultProfileImg && editProfile == true">{{'PROFILE.VALIDATIONS.IMAGEREQUIRED' | translate}}</div>
  
  <div class="align-items-center" *ngIf="editProfile == false || !openedCam">
    <button (click)="chooseImgEnv('Image', chooseImageEnvironment)" class="btn btn-cyan showLaptop">
      {{'CONSULTATIONS.TAKESELFIE' | translate}}
    </button>
    <button (click)="cropImage(); front = undefined; editProfile = true; editId = false; ratio = 1" class="btn btn-cyan showMobile">
      {{'CONSULTATIONS.TAKESELFIE' | translate}}
    </button>
    <input type="file" id="file-upload" hidden accept="image/*" capture="environment" #img (change)="fileSelected($event); front = undefined; editProfile = true; editId = false"> 
  </div>
  
  <div *ngIf="editProfile && openedCam" class="d-flex align-items-center flex-column" id="app">
    <div><video #video class="justify-content-center cropped" id="video" width="250" height="250" autoplay></video></div>
    <div><button class="takePic btn btn-cyan" id="snap" (click)="capture(); closeCam()">{{'CONSULTATIONS.TAKEPICTURE' | translate}}</button></div>
    <canvas #canvas id="canvas" hidden width="250" height="250"></canvas>
    <button class="btn btn-light" (click)="editProfile = false; closeCam()">
      {{'CONSULTATIONS.CANCEL' | translate}}
    </button>
    <canvas id="imageCanvasT" hidden style="border:1px solid #d3d3d3; border-radius: 50%;">
    </canvas>

    <canvas id="imageCanvasTT" hidden style="border:1px solid #d3d3d3; border-radius: 50%; ">
    </canvas>
  </div>

  <div class="profile-preview mt-1" style="display: flex; flex-direction: column; align-items: center; width: 100%;">
    <form class="profileForm" [formGroup]="editProfileForm">
      <span style="font-size: large; display: flex; margin-bottom: 0;">{{'SIGNUP.EMAIL' | translate}}</span>
      <h5 style="display: flex; align-items: center; justify-content: space-between;">{{profile.email}}</h5>
      <!-- <label *ngIf="editProfile == true" for="email" class="inp m-0">
        <input class="p-0" style="height: 38px;" (keyup)="emailExist($event)" type="text" value="email" name="email" id="email" placeholder="&nbsp;" formControlName="email">       
      </label> 
      <label *ngIf="editProfile == true" [hidden]="currentEmail == editProfileForm.controls.email.value" style="font-size: large; display: flex; margin-bottom: 0;">{{'PROFILE.VALIDATIONS.RETYPEEMAIL' | translate}}</label>
      <label *ngIf="editProfile == true" [hidden]="currentEmail == editProfileForm.controls.email.value" for="confirmEmail" class="inp m-0">
        <input class="p-0" style="height: 38px;" type="text" (keyup)="ifEmailConfirm($event)" value="{{emailConfirm}}" name="email" id="confirmEmail" placeholder="&nbsp;">       
      </label> 
      <div *ngIf="emailExists" style="color: red;"> {{'PROFILE.VALIDATIONS.EMAILEXISTS' | translate}}</div>
      <div *ngIf="editProfile == true" [hidden]="(emailExists != true && currentEmail == editProfileForm.controls.email.value) || emailConfirm == editProfileForm.controls.email.value"  style="color: red;"> {{'PROFILE.VALIDATIONS.EMAILCHANGE' | translate}}</div>  
      <div *ngIf="emailConfirm != '' && emailConfirm != editProfileForm.controls.email.value" style="color: red;"> {{'PROFILE.VALIDATIONS.MUSTMATCH' | translate}}</div> -->
      <span style="font-size: large; display: flex; margin-bottom: 0;">{{'PROFILE.NAME' | translate}}</span>   
      <h5 style="display: flex; align-items: center; justify-content: space-between;">{{abbr}} {{profile.firstName}} {{profile.middleName}} {{profile.lastName1}}</h5>  
      <label *ngIf="editProfile == true && profile.accountType == 'practitioner' && (profile?.abbr == '' || profile?.abbr == undefined || profile.status == 'unverified')" for="abbr" class="inp mb-1" style="max-width: 250px;">
        <ng-select *ngIf="profile.accountType == 'practitioner'" style="height: 38px;" class="py-0" id="abbr" formControlName="abbr">
          <ng-option value="<en>Dr.</en><es>el Dr./la Dra.</es>">{{'SIGNUP.DR' | translate}}</ng-option>
          <ng-option value="<en>Lic.</en><es>el Lcdo./la Lcda.</es>">{{'SIGNUP.LIC' | translate}}</ng-option>
        </ng-select>
      </label>
      <div *ngIf="editProfile == true && (profile.firstName == '' || profile.lastName1 == '' || profile.status == 'unverified')" style="display: flex; flex-direction: column;">
        <label for="firstName" class="inp m-0">
          <input  style="height: 38px;" class="py-0" type="text" id="firstName" value="{{profile.firstName}}" name="firstName"  placeholder="{{'PROFILE.FIRSTNAME' | translate}}" formControlName="firstName" required>
        </label>
        <div style="color: red;" *ngIf="editProfile == true && editProfileForm.controls.firstName.value == ''">{{'PROFILE.VALIDATIONS.FIRSTNAMEREQUIRED' | translate}}</div>
        <label for="middleName" class="inp m-0" [ngClass]=" ">
          <input style="height: 38px;" class="py-0" type="text" value="{{profile.middleName}}" name="middleName" id="middleName" placeholder="{{'PROFILE.MIDDLENAME' | translate}}"  formControlName="middleName">
        </label>
        <label for="lastName1" class="inp m-0">
            <input style="height: 38px;" class="py-0" type="text" value="{{profile.lastName1}}" name="lastName1" id="lastName1" placeholder="{{'PROFILE.LASTNAME1' | translate}}"  formControlName="lastName1" required>
        </label>
        <div style="color: red;" *ngIf="editProfile == true && editProfileForm.controls.lastName1.value == ''">{{'PROFILE.VALIDATIONS.LASTNAMEREQUIRED' | translate}}</div>
      </div>        
      <span style="font-size: large; display: flex; margin-bottom: 0;">{{'PROFILE.BIRTHDATE' | translate}}</span>
      <h5 style="display: flex; align-items: center;">{{profile.birthdate}}</h5>
      <label hidden *ngIf="editProfile == true && profile.birthdate == '' && (profile.status == 'unverified' || profile.status == 'none')" for="date" class="inp">
        <input style="height: 38px;" class="py-0" type="date" id="date" value="{{profile.birthdate}}" placeholder="&nbsp;" formControlName="birthdate" required>
      </label>   
      <div *ngIf="editProfile == true && profile.birthdate == '' && (profile.status == 'unverified' || profile.status == 'none')" class="d-flex flex-row" style="max-width: 485px;">
        <div style="width: auto; min-width: 115px;" class="mr-auto ml-0">
          <p class="m-0">{{'SIGNUP.MONTH' | translate}}</p>
          <ng-select (change)="daysRemainingInMonth()"  [ngModelOptions]="{standalone: true}" [clearable]="false" [(ngModel)]="monthSelected">
            <ng-option *ngFor="let day of getMonths()" [value]="day">{{day | titlecase}}</ng-option>
          </ng-select>
        </div>
        <div style="width: 60px;" class="mx-auto">
          <p class="m-0">{{'SIGNUP.DAY' | translate}}</p>
          <ng-select (change)="daysRemainingInMonth()" [ngModelOptions]="{standalone: true}" [clearable]="false"  [(ngModel)]="daySelected">
            <ng-option *ngFor="let day of getDays()" [value]="day">{{day}}</ng-option>
          </ng-select>
        </div>
        <div style="width: 80px;" class="mx-auto">
          <p class="m-0">{{'SIGNUP.YEAR' | translate}}</p>
          <ng-select (change)="daysRemainingInMonth()"  [ngModelOptions]="{standalone: true}" [clearable]="false" [(ngModel)]="yearSelected">
            <ng-option *ngFor="let day of getYears()" [value]="day">{{day}}</ng-option>
          </ng-select>
        </div>
      </div>   
      <div style="color: red;" *ngIf="editProfile == true && editProfileForm.controls.birthdate.value == ''">{{'PROFILE.VALIDATIONS.BIRTHDATEREQUIRED' | translate}}</div>
      <span style="font-size: large; display: flex; margin-bottom: 0;">{{'PROFILE.GENDER' | translate}}</span>
      <h5 style="display: flex; align-items: center;" *ngIf="profile.biologicalgender == '' && editProfile == false"></h5>                 
      <h5 style="display: flex; align-items: center;" *ngIf="profile.biologicalgender == 'Male'">{{'PROFILE.MALE' | translate}} </h5>
      <h5 style="display: flex; align-items: center;" *ngIf="profile.biologicalgender == 'Female'">{{'PROFILE.FEMALE' | translate}} </h5>
      <label *ngIf="editProfile == true && profile.biologicalgender == ''" for="select" class="inp" style="max-width: 250px;">
        <select style="height: 38px;" class="py-0" id="select" formControlName="biologicalgender" required>
          <option value="Male">{{'PROFILE.MALE' | translate}}</option>
          <option value="Female">{{'PROFILE.FEMALE' | translate}}</option>
        </select>   
      </label>
      <div style="color: red;" *ngIf="editProfile == true && editProfileForm.controls.biologicalgender.value == ''">{{'PROFILE.VALIDATIONS.GENDERREQUIRED' | translate}}</div>
      <span style="font-size: large; display: flex; margin-bottom: 0;">{{'PROFILE.SOCIALGENDER' | translate}}</span>   
      <h5 style="display: flex; align-items: center; justify-content: space-between;" *ngIf="editProfile == false">{{profile.gender}}</h5>  
      <label *ngIf="editProfile == true" for="gender" class="inp" style="max-width: 250px;">
        <input class="py-0" style="height: 38px;" type="text" value="gender" name="gender" id="gender" placeholder="&nbsp;" formControlName="gender">       
      </label>   


      <span *ngIf="profile.accountType == 'patient'" style="font-size: large; display: flex; margin-bottom: 0;">{{'CONSULTATIONS.BMI' | translate}}</span>   
      <h5 style="display: flex; align-items: center; justify-content: space-between;" *ngIf="profile.BMI && editProfile == false && profile.accountType == 'patient'">{{profile.BMI.feet}} {{'CONSULTATIONS.FEET' | translate}} {{profile.BMI.inches}} {{'CONSULTATIONS.INCHES' | translate}}, {{profile.BMI.pounds}} {{'CONSULTATIONS.POUNDS' | translate}}</h5>
        <h5 style="display: flex; align-items: center; justify-content: space-between;" *ngIf="!profile.BMI && editProfile == false && profile.accountType == 'patient'"></h5>  
      <div *ngIf="profile.accountType == 'patient'" class="d-flex flex-column">
        <div class="d-flex">
          <label *ngIf="editProfile == true" for="BMI.feet" class="inp m-0" style="max-width: 250px;">
            <div formArrayName="BMI">
              <div class="d-flex align-items-center">
                <input class="py-0" style="height: 38px; width: 30%;" type="text" name="BMI.feet" id="BMI.feet" placeholder="&nbsp;" formControlName="feet"> <div class="ml-1">{{'CONSULTATIONS.FEET' | translate}}</div>         
              </div>
            </div>
          </label> 
          <label *ngIf="editProfile == true" for="BMI.inches" class="inp m-0" style="max-width: 250px;">
            <div formArrayName="BMI">
              <div class="d-flex align-items-center">
                <input class="py-0" style="height: 38px; width: 30%;" type="text" name="BMI.inches" id="BMI.inches" placeholder="&nbsp;" formControlName="inches"> <div class="ml-1">{{'CONSULTATIONS.INCHES' | translate}}</div>         
              </div>
            </div>
          </label> 
        </div>
        <label *ngIf="editProfile == true" for="BMI.pounds" class="inp m-0" style="max-width: 250px;">
          <div formArrayName="BMI">
            <div class="d-flex align-items-center">
              <input class="py-0" style="height: 38px; width: 30%;" type="text" name="BMI.pounds" id="BMI.pounds" placeholder="&nbsp;" formControlName="pounds"> <div class="ml-1">{{'CONSULTATIONS.POUNDS' | translate}}</div>
            </div>
          </div>
        </label> 
      </div>



      <span style="font-size: large; display: flex; margin-bottom: 0;">{{'SIGNUP.PHONENUMBER' | translate}}
      </span> 
      <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="editProfile == false">{{profile.phoneNumber | mask:'(000) 000-0000'}}</h5> 
      <label *ngIf="editProfile == true" for="phoneNumber" class="inp" style="max-width: 250px;">
        <input class="py-0" style="height: 38px;" type="tel" value="phoneNumber" mask="(000) 000-0000" maxlength="14" name="phoneNumber" id="phoneNumber" placeholder="&nbsp;" formControlName="phoneNumber">       
      </label>  
      <div class="custom-checkbox mb-2 d-flex align-items-center">
        <label class="switch m-0">
          <input id="switch" type="checkbox" (change)="selectPhone($event);" name="phoneNumberMsg" formControlName="phoneNumberMsg" [attr.disabled]="disableCheckbox(editProfileForm.controls.phoneNumber.value, 'switch')">
          <span class="slider round"></span>
        </label>
        <span class="ml-2 mb-0" for="switch">{{'PROFILE.MSG' | translate}}</span>
      </div>
      <div style="color: red;" *ngIf="editProfile == true && (editProfileForm.controls.phoneNumber.value == '' || editProfileForm.controls.phoneNumber.value.length != 10)">{{'PROFILE.VALIDATIONS.PHONEREQUIRED' | translate}}</div>
      
      <span style="font-size: large; display: flex; margin-bottom: 0;">{{'PROFILE.ALTERNATENUMBER' | translate}}
      </span> 
      <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="editProfile == false">{{profile.alternateNumber | mask:'(000) 000-0000'}}</h5>   
      <label *ngIf="editProfile == true" for="alternateNumber" class="inp" style="max-width: 250px;">
        <input class="py-0" style="height: 38px;" type="tel" value="alternateNumber" mask="(000) 000-0000" maxlength="14" name="alternateNumber" id="alternateNumber" placeholder="&nbsp;" formControlName="alternateNumber">       
      </label>  

      <span style="font-size: large; display: flex; margin-bottom: 0;">{{'PROFILE.timezone' | translate}}
      </span> 
      <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="editProfile == false">{{profile.timezone?.name}}</h5> 
      <label *ngIf="editProfile == true" class="inp" style="max-width: 250px;">
        <div formArrayName="timezone">
          <ng-select class="custom select mt-0" bindLabel="timezone"  id="timezone" name="timezone" formControlName="name">
            <ng-option *ngFor="let timezone of timezoneList" value="{{timezone.name}}">{{timezone.name}}</ng-option>
          </ng-select>    
        </div>
      </label>  
      <span style="font-size: large; display: flex; margin-bottom: 0;">{{'PROFILE.PREFEREDLANG' | translate}}
      </span> 
      <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="editProfile == false && profile.preferedLang == 'en'">English</h5> 
      <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="editProfile == false && profile.preferedLang == 'es'">Español</h5> 
      <label *ngIf="editProfile == true" class="inp" style="max-width: 250px;">
        <ng-select class="mt-0" formControlName="preferedLang" id="lang" name="preferedLang">
          <ng-option value="es">Español</ng-option>
          <ng-option value="en">English</ng-option>
        </ng-select>
      </label>
      <span style="font-size: large; display: flex; margin-bottom: 0;">{{'SIGNUP.PROVIDESTATE' | translate}}
      </span> 
      <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;">{{profile.currentLocation?.name}}</h5> 
      <label *ngIf="editProfile == true" class="inp" style="max-width: 250px;">
       <button class="btn btn-primary" (click)="openModals(chooseLocation)">{{'SIGNUP.CHOOSELOCATION' | translate}}</button>
      </label>  
      <div style="margin-top: 10px; display: flex; width: 100%; justify-content: center;" *ngIf="editProfile == true">
        <button type="button" class="btn btn-info btn-cyan" style="border-radius: .25rem !important; color: white !important;" id="saveProfileBtn" (click)="saveProfile()" [disabled]="invalidProfile()">{{'ADDRESS.SAVE' | translate}}</button>
        <button style="border-radius: .25rem !important; margin-left: 10px;" type="button" class="btn btn-light" (click)="cancelProfileChanges(); closeCam()">{{'TOPBAR.SWAL.CANCEL' | translate}}</button>
      </div>        
    </form>
    <!-- <a class="mt-4" style="text-decoration: underline !important; font-size: 18px; cursor: pointer;" (click)="updateAuthorizedUsersInAccount(); allowDismiss = true">{{'PROFILE.UPDATEAUTHUSERS' | translate}}</a> -->
  </div>        

</div>

<!-- Identification Container -->
<div class="box fade-in userProfile" id="hide" *ngIf="!linkPhoneNumber && profile">
  <div class="card-body w-100" >
  
    <div class="title" style="display: flex; justify-content: space-between; margin: auto;">
      <h2 class="card-title mb-2 text-center cyan" style="font-size: x-large;">{{'PROFILE.ID' | translate}}</h2>
      <!-- <div *ngIf="editId == false">
        <div class="btn btn-primary py-0" style="cursor: pointer"  (click)="editId = true; editProfile = false;">{{'ACCOUNTS.NGBTOOLTIP.EDIT' | translate}}</div>
      </div>   -->
    </div>     
    <hr style="margin: auto; border: none; border-bottom: 2px dotted #e9ebee;">
    <h4 class="card-title mb-2" *ngIf="profile.status == 'Verified'" [ngClass]="{'text-success': profile.status == 'Verified'}" style="color: red; display: flex; margin-left: auto;">{{'PROFILE.VERIFIED' | translate}}</h4>
    <h4 class="card-title mb-2" *ngIf="profile.status != 'Verified'" [ngClass]="{'text-success': profile.status == 'Verified'}" style="color: red; display: flex; margin-left: auto;">{{'PROFILE.UNVERIFIED' | translate}}</h4>

    <div class="form-group column justify-content-center"> 
      <form [formGroup]="editIdForm">
        <div *ngIf="(((editId == false || front == false)) || (front == undefined && editId == true)) && !hide" class="align-items-end justify-content-center">
          <div class="custom-dropzone justify-content-center">
            <div class="dropzone-content justify-content-center">
              <div class="row" [ngClass]="{'disableDiv': editId == false || hide}">
                <img *ngIf="profile.identification != ''" class="justify-content-center" src="{{profile.identification}}" (click)="front = true; startCam()"  alt="Header Avatar">
                <input  hidden type="file" accept="image/*" capture="environment" #img (change)="fileSelected($event); front = true; editId = true; editProfile = false"> 
                <h4 *ngIf="profile.identification == ''" class="justify-content-center ml-4" (click)="front = true; startCam()">{{'PROFILE.IDINFO3' | translate}}</h4>
              </div>
            </div>
          </div>
          <div [ngClass]="{'disableDiv': hide}" class="change-img d-flex mx-auto camIcon" style="margin-top: -40px;" (click)="front = true; startCam(); editId = true; editProfile = false;"><i class="fas fa-camera font-size-18" style="color: #74788D" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.EDIT' | translate}}" (click)="front = true; editId = true; editProfile = false; startCam()"></i></div>
        </div>
        <div *ngIf="((editId == false || front == false || hide) || (front == undefined && editId == true || hide)) && hide" class="align-items-end justify-content-center">
          <div class="custom-dropzone justify-content-center">
            <div class="dropzone-content justify-content-center">
              <div class="row" [ngClass]="{'disableDiv': editId == false}">
                <img *ngIf="profile.identification != ''" class="justify-content-center" src="{{profile.identification}}" (click)="cropImage(); front = true; editProfile = false; editId = true; ratio = 485/300"  alt="Header Avatar">
                <input  hidden type="file" accept="image/*" capture="environment" #img (change)="fileSelected($event);front = true; editId = true;editProfile = false"> 
                <h4 *ngIf="profile.identification == ''" class="justify-content-center ml-4" (click)="cropImage(); front = true; editProfile = false; editId = true; ratio = 485/300">{{'PROFILE.IDINFO3' | translate}}</h4>
              </div>
            </div>
          </div>
          <!-- <div class="change-img d-flex mx-auto camIcon" (click)="front = true; img.click(); editId = true; editProfile = false;"><i class="mdi mdi-pencil font-size-18" style="color: #74788D" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.EDIT' | translate}}" (click)="front = true; editId = true; editProfile = false;"></i></div> -->
          <div class="change-img d-flex mx-auto camIcon" (click)="cropImage(); front = true; editProfile = false; editId = true; ratio = 485/300"><i class="fas fa-camera font-size-18" style="color: #74788D" ngbTooltip="{{'ACCOUNTS.NGBTOOLTIP.EDIT' | translate}}" (click)="front = true; editId = true; editProfile = false;"></i></div>
          <input  hidden type="file" accept="image/*" capture="environment" #img (change)="fileSelected($event);front = true; editId = true; editProfile = false"> 
        </div>

        <div class="align-items-center d-flex justify-content-center" style="margin-top: 20px;" *ngIf="(editId == false || front == false) || (front == undefined && editId == true) || hide">
          <button (click)="chooseImgEnv('ID', chooseImageEnvironment)" class="btn btn-cyan showLaptop">
            {{'CONSULTATIONS.UPLOADID' | translate}}
          </button>
          <button (click)="cropImage(); front = true; editProfile = false; editId = true; ratio = 485/300" class="btn btn-cyan py-1 showMobile">
            {{'CONSULTATIONS.UPLOADID' | translate}}
          </button>
          <input type="file" id="id-file-upload" hidden accept="image/*" capture="environment" #img (change)="fileSelected($event);front = true; editProfile = false; editId = true"> 
        </div>
        
        <div *ngIf="editId && front && !hide" class="d-flex align-items-center flex-column" id="app">
          <div><video #video class="justify-content-center" id="video" width="325" height="250" autoplay></video></div>
          <div><button class="takePic btn btn-cyan" id="snap" (click)="capture(); closeCam()">{{'CONSULTATIONS.TAKEPICTURE' | translate}}</button></div>
          <canvas #canvasID id="canvasID" hidden width="325" height="250"></canvas>
          <button class="btn btn-light" (click)="editId = false; front = undefined; closeCam()">
            {{'CONSULTATIONS.CANCEL' | translate}}
          </button>
        </div>

        <div style="color: red; display: flex; justify-content: center !important;" *ngIf="(profile.identification == defaultIDImg) 
          && editId == true">{{'PROFILE.VALIDATIONS.IDREQUIRE' | translate}}</div>  <!-- || profile.identificationBack == defaultIDImgBack) -->
        <!-- <div style="display: flex; flex-direction: column; align-items: center; margin-top: 5%;">
          <div class="leftOptions ml-0">
            <div class="col-md-12">
              <span style="font-size: large; display: flex; margin-bottom: 0;">{{'PROFILE.IDNUMBER' | translate}}</span>
              <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="editId == false">{{profile.idNumber}}</h5>   
              <label *ngIf="editId" for="idNumber" class="inp input">                        
                  <input class="py-0" style="height: 38px;" type="text" value="idNumber" name="idNumber" id="idNumber" placeholder="&nbsp;" formControlName="idNumber">    
              </label>
            </div>
            <div class="col-md-12">
              <span style="font-size: large; display: flex; margin-bottom: 0;">{{'PROFILE.EXPDATE' | translate}}</span>
              <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="editId == false">{{profile.idExpDate}}</h5>   
              <label *ngIf="editId" for="idExpDate" class="inp input">
                  <input (change)="verifyExpDate($event.target.value)" class="py-0" style="height: 38px;" type="date" value="idExpDate" name="idExpDate" id="idExpDate" placeholder="&nbsp;" formControlName="idExpDate">       
              </label>
              <div class="d-flex justify-content-center" *ngIf="idExpired"><p style="color: red;"> {{'PROFILE.VALIDATIONS.IDEXPIRED' | translate}}</p></div>
            </div>
          </div>
          <div class="rightOptions">
            <div class="col-md-12 selectID">
              <span style="font-size: large; display: flex; margin-bottom: 0; margin-top: -16%;">{{'PROFILE.COUNTRYISSUED' | translate}}</span>
              <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="editId == false">{{profile.idCountryIssued}}</h5>   
              <ng-select *ngIf="editId" class="custom select mt-0" [items]="countries" bindLabel="name" bindValue="name" (change)="getStates($event);editIdForm.controls.idStateIssued.setValue(''); profile.idStateIssued = ''" placeholder="{{'PROFILE.COUNTRYISSUED' | translate}}" formControlName="idCountryIssued" name="idCountryIssued"></ng-select>    
            </div>
            <div *ngIf="states.length != 0" class="col-md-12">
              <span style="font-size: large; display: flex; margin-bottom: 0; margin-top: 0;">{{'PROFILE.STATEISSUED' | translate}}</span>
              <h5 style="display: flex; align-items: center; margin-bottom: 0; justify-content: space-between;" *ngIf="editId == false">{{profile.idStateIssued}}</h5>   
              <ng-select *ngIf="editId" class="custom select mt-0" [items]="states" bindLabel="name" bindValue="name"  placeholder="{{'PROFILE.STATEISSUED' | translate}}" formControlName="idStateIssued" name="idStateIssued"></ng-select>    
            </div>                    
          </div>
        </div> -->
                                  
        <!-- <div style="margin-top: 10px; display: flex; width: 100%; justify-content: center;" *ngIf="editId == true">
          <button type="button" class="btn btn-info btn-cyan" style="border-radius: .25rem !important; color: white !important;" (click)="submitId()" id="submitId" [disabled]="invalidId()">{{'ADDRESS.SAVE' | translate}}</button>
          <button style="border-radius: .25rem !important; margin-left: 10px;" type="button" class="btn btn-light" (click)="cancelIdChanges(); closeCam()">{{'TOPBAR.SWAL.CANCEL' | translate}}</button>
        </div>   -->
      </form>
    </div>
  </div>
</div>

<!-- Address Container -->
<div *ngIf="!linkPhoneNumber && profile" style="display: flex; flex-direction: column; align-items: center;">
  <div class="data">
    <div class="box fade-in userProfile">
      <div class="col-lg-12 d-md-block p-0">
        <div class="card-body px-0" style="padding-bottom: 0 !important;">

          <h2 class="card-title mb-2 cyan mx-auto title" style="font-size: x-large; text-align: start;"> {{'PROFILE.ADDRESS' | translate}}</h2>
          <hr style="margin: auto; border: none; border-bottom: 2px dotted #e9ebee;">

          <perfect-scrollbar style="padding-bottom: 15px;"> 
            <!-- <ul class="list-unstyled activity-wid" *ngFor="let address of addresses">
              <div class="address-card">
                <div class="d-flex align-items-center justify-content-center addressForm mx-auto">
                  <div class="col" style="width: auto;">  
                    <div class="row address-type-edit">
                      <h5 *ngIf="address.AddressType == 'Shipping'" style="margin: 0;">{{'ADDRESS.SHIPPING' | translate}}</h5>                 
                      <h5 *ngIf="address.AddressType == 'Billing'" style="margin: 0;">{{'ADDRESS.BILLING' | translate}}</h5>                 
                    </div>                              
                    <div class="row mt-0">
                      <span style="line-height: initial; margin: 0 !important; font-size: large;" class="address-text">{{address.AddressLine1}}</span>
                    </div>
                    <div class="row" *ngIf="address.AddressLine2 != '' && address.AddressLine2 != null">
                      <span style="line-height: initial; margin: 0 !important; font-size: large;">{{address.AddressLine2}}</span>
                    </div>
                    <div class="row">
                      <span style="line-height: initial; margin: 0 !important; font-size: large;">{{address.AddressCity}}, {{address.AddressState}} {{address.AddressZipcode}}</span>
                    </div>
                  </div>
                  <div style="width:auto" *ngIf="profile.accountType != 'practitioner'">
                    <div class="row">
                      <i class="mdi mdi-square-edit-outline font-size-24 mr-3 text-success" (click)="addAddress(addAddressModal, true, address)"></i>
                    </div>  
                    <div class="row">
                      <i class="mdi mdi-delete-outline font-size-24 mr-3 text-danger" (click)="deleteAddressConfirmation(address)"></i>
                    </div>  
                  </div>
                </div>
              </div>      
            </ul> -->
            <app-address [component]="'walkthrough'" [user]="profile" [currentUser]="profile"></app-address>
          </perfect-scrollbar>
          <!-- <div *ngIf="profile?.accountType != 'practitioner' && addresses && addresses.length < 2" class="text-center mt-0"> -->
            <div *ngIf="profile?.accountType != 'practitioner'" class="text-center mt-0">
            <a href="javascript: void(0);" class="btn btn-info btn-sm btn-cyan cardBtn mb-4" (click)="addAddress(addAddressModal, false)"> {{'PROFILE.ADDADDRESS' | translate}}</a>
          </div>
        </div>
      </div>    
      
      <!-- <app-medical-insurance [user]="profile"></app-medical-insurance> -->
  
      <ng-template #addAddressModal let-modal>
        <app-add-address (dismiss)="close()" [bool]="edit"></app-add-address>
      </ng-template>    
    </div>
  </div>
</div>

<!-- Prefered Shipping Method -->
<!-- <div *ngIf="!linkPhoneNumber && profile?.accountType == 'patient'" style="display: flex; flex-direction: column; align-items: center;">
  <div class="data" style="padding-bottom: 20px;">
    <div class="box fade-in userProfile">
      <div class="col-lg-12 d-md-block p-0">
        <div class="card-body" style="padding-bottom: 0 !important;">
          <h2 class="card-title mb-2 cyan mx-auto title" style="font-size: x-large; text-align: start;"> {{'PROFILE.PREFEREDSHIPPINGMETHOD' | translate}}</h2>
          <hr style="margin: auto; border: none; border-bottom: 2px dotted #e9ebee;">
          <button class="btn btn-info btn-sm btn-cyan cardBtn mb-4" (click)="openModals(changeShippingMethod)">{{'PROFILE.CHANGESHIPPINGMETHOD' | translate}}</button>
        </div>
      </div>  
    </div>
  </div>
</div> -->

<!-- Card Container -->
<div *ngIf="!linkPhoneNumber && profile?.accountType == 'patient'" style="display: flex; flex-direction: column; align-items: center;">
  <div class="data" style="padding-bottom: 20px;">
    <div class="box fade-in userProfile">
      <div class="col-lg-12 d-md-block p-0">
        <div class="card-body" style="padding-bottom: 0 !important;">
          <h2 class="card-title mb-2 cyan mx-auto title" style="font-size: x-large; text-align: start;"> {{'PROFILE.WALLET' | translate}}</h2>
          <hr style="margin: auto; border: none; border-bottom: 2px dotted #e9ebee;">

            <perfect-scrollbar style="padding-bottom: 15px;">
            <div *ngFor="let item of allPaymentMethods; let i = index" class="card-box d-flex mt-2 mb-2" [ngClass]="item?.brand === 'Visa' || item?.brand === 'VISA' ? 'visa' : 'allother'">
              <img class="cards-img" src="assets/images/cards/visa-logo.png" *ngIf="item?.brand === 'Visa' || item?.brand === 'VISA'" alt="Visa" height="10"/>
              <img class="cards-img" src="assets/images/cards/mastercard-logo.png" *ngIf="item?.brand === 'Mastercard' || item?.brand === 'MASTERCARD'" alt="MasterCard"/>
              <img class="cards-img" src="assets/images/cards/american_express_01.png" *ngIf="item?.brand === 'American Express' || item?.brand === 'AMERICAN_EXPRESS'" alt="American Express"/>
              <img class="cards-img" src="assets/images/cards/discover.png" *ngIf="item?.brand === 'Discover Club' || item?.brand === 'DISCOVER'" alt="Discover" height="60" width="60"/>
              <img class="cards-img" src="assets/images/cards/jcb_01.png" *ngIf="item?.brand === 'JCB' || item?.brand === 'JCB'" alt="JCB" />
              <img class="cards-img" src="assets/images/cards/diners_club_01.png" *ngIf="item?.brand === 'Diners' || item?.brand === 'DISCOVER_DINERS'" alt="Diners Club"/>
              <img class="cards-img" src="assets/images/cards/china_union_01.png" *ngIf="item?.brand === 'China UnionPay' || item?.brand === 'CHINA_UNIONPAY'" alt="UnionPay"/>
              <div class="d-flex flex-column default-payment" style="margin: auto 0;">
                <div class="d-flex">
                  <span class="ml-1 mr-1"><div class="dots"></div> <div class="dots"></div> <div class="dots"></div> <div class="dots"></div></span>
                  <span class="ml-1 mr-1"><div class="dots"></div> <div class="dots"></div> <div class="dots"></div> <div class="dots"></div></span>
                  <span class="ml-1 mr-1"><div class="dots"></div> <div class="dots"></div> <div class="dots"></div> <div class="dots"></div></span>
                  <span class="last4">{{item?.last4}}</span>
                  <span class="exp-date ml-2">{{item?.expDate}}</span>
                </div>
                <div *ngIf="!item.active" class="d-flex ml-1">
                  <span class="inactive">{{'PROFILE.INACTIVE' | translate}}</span>
                </div>  
                <div  *ngIf="item.active">
                  <div class="d-flex flex-row">
                    <div class="d-flex justify-content-between">
                      <label for="switch-{{i}}" class="switch">
                        <input [attr.disabled]="!item.active" id="switch-{{i}}" type="checkbox" [(ngModel)]="item.default" (change)="makeDefaultPaymentMethod($event, item)">
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <span class="default ml-1" *ngIf="!item.default">{{'PAYMENTS.SETDEFAULT' | translate}}</span>
                    <span class="default ml-1" *ngIf="item.default">{{'PROFILE.DEFAULT' | translate}}</span>
                  </div>
                </div>
              </div>
              <div class="default-payment">
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-between ml-auto">
                    <p [disabled]="!item.active" class="text-muted mb-0"><i [disabled]="!item.active" class="mdi mdi-delete-outline font-size-20 mr-3" (click)="deleteCardConfirmation(item)"></i></p>
                  </div>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <div class="text-center mt-0">
            <a href="javascript: void(0);" class="btn btn-info btn-sm btn-cyan cardBtn mb-4" (click)="showModal()"> {{'PROFILE.ADDCARD' | translate}}</a>
          </div>
        </div>
      </div>  
    </div>
  </div>
</div>

<!-- Practitioner Info -->
<div style="display: flex; flex-direction: column; align-items: center;" *ngIf="profile && profile.accountType == 'practitioner' && !linkPhoneNumber">
  <div class="data" style="padding-bottom: 20px;">
    <div class="box fade-in userProfile">
      <div class="col-lg-12 d-md-block">
        <div class="card-body" style="padding-bottom: 0 !important;">
          <h2 class="card-title mb-2 cyan mx-auto title d-flex justify-content-between" style="font-size: x-large; text-align: start;">{{'PROFILE.PRACTITIONERINFO' | translate}} <button (click)="openModals(choosePdf)" class="btn btn-primary py-1 m-0" style="color: white;">
            {{'CONSULTATIONS.UPLOADPDFFILE' | translate}}
          </button></h2>

          <hr style="margin: auto; border: none; border-bottom: 2px dotted #e9ebee;">
        
          <div class="mt-1" style="display: flex; flex-direction: column; align-items: flex-start; width: 100%;">
            <div class="w-100">
              <div class="w-75 mx-auto mt-3">
                <div *ngFor="let p of pdfsLabel; let i = index" class="mb-3">
                  <h4 class="d-flex justify-content-between m-0" style="min-width: 100%; font-weight: 400; text-align: start; font-weight: bold !important;">{{p | translation:currentLang}}
                  </h4>
                  <div *ngFor="let pdfs of myPdfs[i]" class="ml-2">
                    <span style="text-align: start; margin: 0; font-size: large;">{{pdfs?.pdfName}}</span>
                    <div class="d-flex flex-row justify-content-between">
                      <h5 style="text-align: start;" *ngIf="pdfs.expDate">{{'PROFILE.EXPDATE' | translate}}: {{pdfs.expDate | date:'MMMM dd, yyyy'}}</h5>
                    </div>
                    <button class="btn btn-cyan d-flex ml-auto" (click)="pdf = pdfs.pdf; showPDF(showPdf)">{{'CONSULTATIONS.VIEW' | translate}}</button>
                    <hr class="w-100">
                  </div>
                </div>
              </div>
            </div>          
          </div>
        </div>
      </div>        
    </div>
  </div>
</div>

<!-- Practitioner Schedule -->
<div style="display: flex; flex-direction: column; align-items: center;" *ngIf="profile?.accountType == 'practitioner' && !linkPhoneNumber">
  <div class="data" style="padding-bottom: 20px;">
    <div class="box fade-in userProfile">
      <div class="col-lg-12 d-md-block">
        <div class="card-body" style="padding-bottom: 0 !important;">
          <div class="d-flex flex-row justify-content-between title mx-auto">
            <h2 class="card-title mb-2 cyan" style="font-size: x-large; text-align: start;"> {{'PROFILE.PRACTITIONERSCHEDULE' | translate}}</h2>
            <h5 *ngIf="!editSchedule" style="display: flex; align-items: center; justify-content: center;"><div class="btn btn-primary py-0" style="cursor: pointer" (click)="editSchedule = true;">{{'ACCOUNTS.NGBTOOLTIP.EDIT' | translate}}</div></h5>
          </div>
          <hr style="margin: auto; border: none; border-bottom: 2px dotted #e9ebee;">
          <div [ngClass]="{'disableDiv': !editSchedule}" style="width: auto; display: flex; justify-content: center; align-items: center;">
            <app-practitioner-schedule [editSchedule]="editSchedule" (cancelEdit)="editSchedule = false" *ngIf="profile.accountType == 'practitioner'" [practitioner]="profile"></app-practitioner-schedule>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <ng-template [ngTemplateOutlet]="MedicalInsuranceUpload"></ng-template> -->

<ng-template #MedicalInsuranceUpload>
  <div class="main w-100" >
    <div class="header">
      <h3>{{'PROFILE.MEDICALINSURANCE' | translate}}</h3>
    </div>
    <app-medical-insurance [user]="profile"></app-medical-insurance>
    <!-- <app-upload [user]="profile"></app-upload> -->
  </div>
</ng-template>


<ng-template #addAddressModal let-modal>
  <app-add-address [dismiss]="modalRef" [bool]="edit"></app-add-address>
</ng-template>

<ng-template #addCard let-modal>
  <app-add-payment [user]="profile" [name]="profile.firstName" (close)="openAddCard($event)" [lastName]="profile.lastName1" [modalRef]="modalRef"></app-add-payment>
</ng-template>


<div *ngIf="linkPhoneNumber == true">
  <app-phone-verification class="form-group row d-flex flex-column justify-content-center mt-2" [profile]="phoneProfile" [phoneNumber]="('+1' + this.editProfileForm.controls.phoneNumber.value)"></app-phone-verification>
  <div class="d-flex justify-content-center">
    <button class="btn btn-light mt-2 justify-content-start float-left" style="width: 300px;" (click)="linkPhoneNumber = false; cancelProfileChanges();"> {{'PROFILE.BACK' | translate}}</button>
  </div>
</div> 

<div *ngIf="loading">
  <app-reload></app-reload>
</div>

<ng-template #NPIRegistry let-modal>
  <div class="w-100 header pt-1 d-flex justify-content-between">
    <h3>{{'NPI.NPI' | translate}}</h3>
    <button type="button" class="close float-right modal-close" (click)="close()" aria-hidden="true">×</button>
  </div>
  <div *ngIf="search == false" class="registry-container">
    <div class="details">
      <h6>{{'NPI.BENEFITS' | translate}}</h6>
      <li class="list-inline-item">{{'NPI.BENEFIT1' | translate}}</li>
      <li class="list-inline-item">{{'NPI.BENEFIT2' | translate}}</li>
      <li class="list-inline-item">{{'NPI.BENEFIT3' | translate}}</li>
    </div>
    <div class="search-npi mt-3">
      <label for="NPI">{{'NPI.NPINUM' | translate}}</label>
      <input type="tel" maxlength="10" id="NPI" class="search form-control" placeholder="{{'NPI.PLACEHOLDER' | translate}}" (keyup)="searchRegistry($event.target.value)">
      <i class="fas fa-search"></i>
    </div>
  </div>
  <div *ngIf="search" class="loader text-center registry-container">
    <span class="spinner-border spinner-border-lg"></span>
    <h6>{{'NPI.SEARCHING' | translate}}</h6>
  </div>
</ng-template>

<ng-template #showPdf let-modal>
  <div>
    <ngx-extended-pdf-viewer *ngIf="pdf != undefined" [base64Src]="pdf" backgroundColor="#e8e8ef"
      [showZoomButtons]="true" [height]="'75vh'" [width]="'55vw'" [useBrowserLocale]="true"
      [sidebarVisible]="false" [showHandToolButton]="true" [zoom]="'50%'" style="width: 55vw;">
    </ngx-extended-pdf-viewer>
  </div>
  
  <button *ngIf="pdf != undefined" style="margin-left: auto; margin-right: auto;" class="btn btn-info btn-sm cardBtn mt-2 mb-4" (click)="pdf = undefined; closePdf()">{{'ACCOUNTS.SWAL.CLOSE' | translate}}</button>
</ng-template>

<ng-template #termsModal let-modal style="height: 500px;">
  <app-terms-of-use (getNPI)="getNPIRegistry()" (dismiss)="modal.close()" [profile]="profile"></app-terms-of-use>
</ng-template>

<ng-template #imageCropper let-modal style="height: 500px;">
  <input hidden type="file" id="imageInput"  accept="image/*" (change)="fileChangeEvent($event)" />

  <div class="d-flex justify-content-center align-items-center flex-column">
    <div *ngIf="croppedImage">
      <div class="btn-toolbar p-3" style="flex-wrap: nowrap;" role="toolbar">
        <div class="btn-group mb-sm-0 mx-2 mb-4">    
          <button class="btn btn-info btn-sm btn-cyan cardBtn d-flex align-items-center flex-row" style="width: fit-content; border-radius: 4px 0 0 4px !important;" (click)="rotateRight()">{{'PROFILE.ROTATE' | translate}} <i class="mdi mdi-rotate-right"></i></button>
          <hr class="m-0 h-auto" style="border: 1px solid #2a72ca">
          <button class="btn btn-info btn-sm btn-cyan cardBtn d-flex align-items-center flex-row" style="width: fit-content; border-radius: 0 4px 4px 0 !important;" (click)="flipHorizontal()">{{'PROFILE.FLIP' | translate}} <i class="mdi mdi-flip-horizontal"></i></button>
        </div>
      </div>
    </div>  
  
    <div>
      <image-cropper *ngIf="!imageLoaded" [ngClass]="{'image': editProfile == true, 'id': editId == true}"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="containWithinAspectRatio"
          [aspectRatio]="ratio"
          [resizeToWidth]="485"
          [cropperMinWidth]="128"
          [onlyScaleDown]="true"
          [roundCropper]="false"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
          [alignImage]="'left'"
          [style.display]="showCropper ? null : 'none'"
          format="jpeg"
          (imageCropped)="imageCropped($event)"
      ></image-cropper>
      <image-cropper *ngIf="imageLoaded" [ngClass]="{'image': editProfile == true, 'id': editId == true}"
        [imageBase64]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [containWithinAspectRatio]="containWithinAspectRatio"
        [aspectRatio]="ratio"
        [resizeToWidth]="485"
        [cropperMinWidth]="128"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        [alignImage]="'left'"
        [style.display]="showCropper ? null : 'none'"
        format="jpeg"
        (imageCropped)="imageCropped($event)"
    ></image-cropper>
    </div>
    <img hidden [ngClass]="{'image': editProfile == true, 'id': editId == true}" [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />
  
    <button *ngIf="croppedImage" class="btn btn-info btn-sm cardBtn mb-4 mt-2" style="width: fit-content;" (click)="saveImage()">{{'PROFILE.SAVEIMAGE' | translate}}</button>
  </div>


  <div *ngIf="!croppedImage" class="mb-5">
    <h2 class="text-center m-2">{{'PROFILE.WAITINGFORIMAGE' | translate}}</h2>
  </div>
  <div *ngIf="!croppedImage" class="d-flex align-items-center justify-content-center">
    <button type="button" class="btn btn-cyan mb-2 px-2 py-1 modal-close" (click)="close(); cancelProfileChanges(); cancelIdChanges();imageChangedEvent = '';
    croppedImage = ''" aria-hidden="true" style="width: fit-content;">{{'CONSULTATIONS.SWAL.CLOSE' | translate}}</button>
  </div>
</ng-template>

<ng-template #authUsersModal let-modal class="authModal">
  <div *ngIf="allowDismiss" class="justify-content-end">
    <button type="button" class="close float-right modal-close" (click)="close()" aria-hidden="true">×</button>
  </div>
  <app-auth-users-doc></app-auth-users-doc>
</ng-template>

<ng-template #choosePdf let-modal>
  <div class="p-2 d-flex flex-column">
    <div class="justify-content-end">
      <button type="button" class="close float-right modal-close" style="font-size: xx-large;" (click)="modal.close()" aria-hidden="true">×</button>
    </div>
    <div class="d-flex" *ngFor="let label of pdfsLabel">
      <div class="custom-control custom-radio mb-2">
        <input type="radio" class="custom-control-input" id="{{label}}" (change)="description = label" [checked]="description == label">
        <label class="custom-control-label" for="{{label}}">{{label | translation:currentLang}}</label>
      </div>
    </div>
    <button (click)="pdfInput.click()" class="btn btn-primary py-1 m-0 mb-2" style="color: white; width: fit-content;">
      {{'CONSULTATIONS.UPLOADPDFFILE' | translate}}
    </button>
    <input type="file" id="pdf-file-upload" hidden accept=".pdf" capture="environment" #pdfInput (change)="pdfToSave = $event">   
    <p *ngIf="pdfToSave">Pdf Selected: {{pdfToSave.target.files[0].name}}</p>
    <div class="d-flex flex-row mb-2" style="align-items: flex-end;">
      <p class="m-0">{{'PROFILE.EXPDATE'| translate}}:&nbsp;</p>
      <label for="expDate" class="inp m-0">
        <input  style="height: 30px;" class="p-0" type="date" id="expDate" value="{{expDate}}" name="expDate" placeholder="{{'PROFILE.EXPDATE' | translate}}" [(ngModel)]="expDate" required>
      </label>
    </div>
    <button class="btn btn-cyan mx-auto" style="width: fit-content;" (click)="pdfSelected(pdfToSave); modal.close()" [disabled]="pdfToSave == '' || expDate == '' || description == ''">{{'ADDRESS.SAVE' | translate}}</button>
  </div>
</ng-template>

<ng-template #chooseImageEnvironment let-modal>
  <div class="justify-content-end">
    <button type="button" class="close float-right modal-close" (click)="modal.close()" aria-hidden="true">×</button>
  </div>

  <h2 class="text-center">{{'CONSULTATIONS.CHOOSEENVIRONMENT' | translate}}</h2>
  <div *ngIf="imgType == 'ID'" class="d-flex justify-content-center">
    <button class="btn btn-cyan py-1 mr-2 showLaptop" (click)="editProfile = false; editId = true; startCam(); front = true;ratio = 485/300; modal.dismiss()">
      {{'CONSULTATIONS.TAKEIDPHOTO' | translate}}
    </button>
    <span class="mr-2 showLaptop">
      {{'CONSULTATIONS.OR' | translate}}
    </span>
    <button (click)="cropImage(); front = true; editProfile = false; editId = true; ratio = 485/300" class="btn btn-cyan py-1 showLaptop">
      {{'CONSULTATIONS.CHOOSEIDFILE' | translate}}
    </button>
  </div>
  <div *ngIf="imgType == 'Image'" class="d-flex justify-content-center">
    <button class="btn btn-cyan py-1 mr-2 showLaptop" (click)="editProfile = true; editId = false; front = undefined; openedCam = true; startCam(); ratio = 1; modal.dismiss()">
      {{'CONSULTATIONS.TAKESELFIE' | translate}}
    </button>
    <span class="mr-2 showLaptop">
      {{'CONSULTATIONS.OR' | translate}}
    </span>
    <button (click)="cropImage(); front = undefined; editProfile = true; editId = false; ratio = 1" class="btn btn-cyan py-1 showLaptop">
        {{'CONSULTATIONS.CHOOSEFILE' | translate}}
    </button>
  </div>
</ng-template>

<ng-template let-modal #chooseLocation>
  <div class="card" style="box-shadow: none;">
    <button type="button" class="close mr-2 ml-auto" style="text-align: end;" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span style="font-size: 32px;" aria-hidden="true">&times;</span>
    </button>
    <app-choose-location *ngIf="profile" [collectionToUpdate]="'users'" [currentUser]="profile" (close)="modal.close()"></app-choose-location>
  </div>  
</ng-template>

<ng-template let-modal #changeShippingMethod>
  <app-prefered-shipping-method></app-prefered-shipping-method>
</ng-template>
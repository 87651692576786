<div class="container-fluid">
  <div class="title row">
    <h1 class="p-0">{{'TOF.AGREEMENTS' | translate}}</h1>
  </div>
  <perfect-scrollbar style="height: 450px;">
    <div [innerHTML]="termsOfUse?.html"></div>
  </perfect-scrollbar>
  <div *ngIf="(profile || anon) && !viewTerms"
    class="footer-row row justify-content-center flex-column align-items-center">
    <div><input id="acceptTerms" type="checkbox" (change)="acceptTerms($event)"><span
        style="vertical-align: middle;">{{'TOF.ACCEPTTC' | translate}}</span></div>
    <button class="btn btn-info" (click)="close()" [disabled]="acceptedTerms == false">{{'TOF.CONTINUE' |
      translate}}</button>
  </div>
  <div *ngIf="(profile == undefined && !anon) || viewTerms" class="footer-row row justify-content-center">
    <button class="accept btn btn-info" style="color:white !important;" (click)="close()">{{'TOF.CLOSE' |
      translate}}</button>
  </div>
</div>
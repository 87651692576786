import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SUBSCRIPTION_HISTORY_DESCRIPTION, SUBSCRIPTION_STATES } from 'src/app/core/enums/process-history';
import { SubscriptionHistory } from 'src/app/core/interfaces/history';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { BaseProduct, Profile } from 'src/app/core/services/interfaces';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { SubscriptionsService } from 'src/app/core/services/subscriptions.service';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-refill-manager',
  templateUrl: './refill-manager.component.html',
  styleUrls: ['./refill-manager.component.scss']
})
export class RefillManagerComponent {
  @ViewChild('historyRef',{ read: TemplateRef }) historyModal:TemplateRef<any>;
  @ViewChild('upgradeRef',{ read: TemplateRef }) upgradeModal:TemplateRef<any>;
  @ViewChild('actionRef',{ read: TemplateRef }) actionModal:TemplateRef<any>;
  @ViewChild('upgradeNotification',{ read: TemplateRef }) upgradeNotificationModal:TemplateRef<any>;
  @Input() patientUid = ''

  currentUser:Profile;
  modalRef:any;
  orders:any[] = [];
  refills:any[] = [];
  currentSubscription:any;
  currentPromoProduct:any;
  ordersFromSubscription:any[] = [];

  card:boolean = false;
  allPaymentMethods:any[] = [];

  upgradeBillingTerm:number = 30;
  planList:any[] = [];

  possibleUpgradePlan:any;
  options = ['SUBSCRIPTIONS.CHANGEQTY', 'SUBSCRIPTIONS.CHANGEDOSE', 'SUBSCRIPTIONS.TTD']


  constructor(
    private db: AngularFirestore,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private firebaseService: FirebaseService,
    private subscriptionService: SubscriptionsService,
    private route: Router,
    private productService: ProductsService
  ) { }

  async ngOnInit() {
    this.currentUser = await this.authService.profile();
    this.patientUid = this.patientUid ? this.patientUid : this.currentUser.uid;

    await this.getAcceptaCards(this.patientUid);
    this.getRefills(this.patientUid);
  }

  getRefills(uid){
    this.db.collection("orders", ref => ref.where("uid", "==", uid).where("refill", "==", true)).valueChanges().subscribe((subs:any) => {
      this.refills = subs.filter(x => x.paymentStatus == "paid");
      subs.forEach((element, i) => {
        let obj 
        this.db.firestore.collection("orders").doc(element.id).collection("orderLines").get().then(async (product:any) => {
          let productData = await product.docs.map((doc:any) => doc.data()).filter(x => x.type == 'product' && x.recurrency_order == true)[0];
          // const { atr0,atr1, atr2, atr3, atr4, atr5, atr6, atr7, atr8, atr9, qty, ...other } = product.data();
          // let atrs = {
          //   atr0,atr1, atr2, atr3, atr4, atr5, atr6, atr7, atr8, atr9, qty
          // }

          let creditCardRef = await this.getCardInSubscription({uid: uid}, element.acceptaID);
          if(creditCardRef){
            element = Object.assign(element, creditCardRef)
          }
          obj = Object.assign(element, productData);
          
          this.refills[i] = obj;
        });
      });
    });
  }

  async activateSubscription(subscription:any){
    await this.checkOverlap(subscription).then(res => {
      let swal:any;
      this.translate.get('SUBSCRIPTIONS.SWAL').subscribe((res: any) => {
        swal = res
      })
      if(res.length > 0){
        Swal.fire({
          icon:"info",
          title: swal.TITLE,
          text: swal.INFO,
          showConfirmButton: true,
          confirmButtonText: swal.CONFIRM,
          showCancelButton: true,
          cancelButtonText: swal.CLOSE
        }).then(swalRes => {
          if(swalRes.isConfirmed){
            res.forEach((sub, i) => {
              this.db.collection("subscriptions").doc(sub.id).update({state: SUBSCRIPTION_STATES.CLOSED, lastUpdatedBy: this.currentUser.uid}).then(res => {
                let history:SubscriptionHistory = {
                  date: new Date(Date.now()),
                  id: this.firebaseService.getDate(),
                  userUID: this.currentUser.uid,
                  user: this.currentUser.firstName + ' ' + this.currentUser.lastName1,
                  userAccountType: this.currentUser.accountType,
                  action: `${SUBSCRIPTION_HISTORY_DESCRIPTION.CLOSED} ${this.currentUser.accountType}`
                }
                this.db.collection("subscriptions").doc(sub.id).collection('History').doc(history.id).set(history);
                //newest possible versions
                // this.historyService.storeHistory(history);      ----  its own collection
                // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation
              });
              if(i == res.length -1){
                this.db.collection("subscriptions").doc(subscription.id).update({state: SUBSCRIPTION_STATES.INPROGRESS, lastUpdatedBy: this.currentUser.uid}).then(res => {
                  let history:SubscriptionHistory = {
                    date: new Date(Date.now()),
                    id: this.firebaseService.getDate(),
                    userUID: this.currentUser.uid,
                    user: this.currentUser.firstName + ' ' + this.currentUser.lastName1,
                    userAccountType: this.currentUser.accountType,
                    action: `${SUBSCRIPTION_HISTORY_DESCRIPTION.ACTIVATED} ${this.currentUser.accountType}`
                  }
                  this.db.collection("subscriptions").doc(subscription.id).collection('History').doc(history.id).set(history)
                  //newest possible versions
                  // this.historyService.storeHistory(history);      ----  its own collection
                  // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation
                })
              }
            });
          }
        })
      }
      else {
        this.db.collection("subscriptions").doc(subscription.id).update({state: SUBSCRIPTION_STATES.INPROGRESS, lastUpdatedBy: this.currentUser.uid}).then(res => {
          let history:SubscriptionHistory = {
            date: new Date(Date.now()),
            id: this.firebaseService.getDate(),
            userUID: this.currentUser.uid,
            user: this.currentUser.firstName + ' ' + this.currentUser.lastName1,
            userAccountType: this.currentUser.accountType,
            action: `${SUBSCRIPTION_HISTORY_DESCRIPTION.ACTIVATED} ${this.currentUser.accountType}`
          }
          this.db.collection("subscriptions").doc(subscription.id).collection('History').doc(history.id).set(history)
          //newest possible versions
          // this.historyService.storeHistory(history);      ----  its own collection
          // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation
        });
      }
    })
  }
  
  pauseSubscription(subscription:any){
    this.db.collection("subscriptions").doc(subscription.id).update({state: SUBSCRIPTION_STATES.HOLD, lastUpdatedBy: this.currentUser.uid}).then(res => {
      let history:SubscriptionHistory = {
        date: new Date(Date.now()),
        id: this.firebaseService.getDate(),
        userUID: this.currentUser.uid,
        user: this.currentUser.firstName + ' ' + this.currentUser.lastName1,
        userAccountType: this.currentUser.accountType,
        action: `${SUBSCRIPTION_HISTORY_DESCRIPTION.PAUSED} ${this.currentUser.accountType}`
      }

      this.db.collection("subscriptions").doc(subscription.id).collection('History').doc(history.id).set(history)
      //newest possible versions
          // this.historyService.storeHistory(history);      ----  its own collection
          // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation
    })
  }

  // async subscriptionHistory(subscription){
  //   let plans = await this.subscriptionService.getProductsPlans(subscription.condition[0], 'products');
  //   this.currentPromoProduct = plans.filter(x => x.id == subscription.promoProductID)[0];
  //   this.currentSubscription = subscription;
  //   await this.getOrderHistory(subscription);
  //   this.modalRef = this.modalService.open(this.historyModal, {size: 'xl', backdrop: "static", windowClass:"historyModal"})
  // }
  
  getOrderHistory(sub){
    return new Promise<any>((resolve, reject) => {
      this.db.collection('orders', ref => ref.where('subscriptionID', "==", sub.id)).valueChanges().subscribe(async (orders:any) => {
        this.orders = orders;
        resolve(true);
      });
    });
  }

  async checkOverlap(subscription){
    return new Promise<any>(async (resolve, reject) => {
      let active = await this.refills.filter(x => x.condition.includes(subscription.condition[0]) && x.state == "In Progress");
      resolve(active);
    });
  }

  async getAcceptaCards(uid){
    this.db.collection("users").doc(uid).collection("wallet").valueChanges().subscribe(async (res:any) => {
      this.allPaymentMethods = await res.filter(x => x.active);
      if(res.length > 0){
        this.card = true;
      }
    });
  }

  getCardInSubscription(user, acceptaID){
    return new Promise<any>((resolve, reject) => {
      if(acceptaID !== undefined && acceptaID !== "") {
         let data: any = this.allPaymentMethods.filter(p => p.customerNumber == acceptaID)[0];
        if(data){
          let creditCardRef = data.brand + "-" + data.last4 + " Expires " + data.expDate;
          let paymentMethod = {creditCardRef: creditCardRef, brand: data.brand, last4: data.last4};
          resolve(paymentMethod);
          return 
        }
        else {
          resolve(null)
        }
      }else{
        this.getAcceptaCards(user.uid);
      }
    })
  }

  async onItemChange(order, event){
    let data = {
      creditCardRef: "",
      acceptaID: "",
    };
    if(event !== undefined){
      data.creditCardRef = event.brand + "-" + event.last4 + " Expires " + event.expDate;
      data.acceptaID = event.customerNumber;
    }
    this.db.collection("orders").doc(order.id).update({acceptaID: data.acceptaID}).then(res => {
      let history:SubscriptionHistory = {
        date: new Date(Date.now()),
        id: this.firebaseService.getDate(),
        userUID: this.currentUser.uid,
        user: this.currentUser.firstName + ' ' + this.currentUser.lastName1,
        userAccountType: this.currentUser.accountType,
        action: `${this.currentUser.accountType} updated credit card.`
      }
      this.db.collection("subscriptions").doc(order.id).collection('History').doc(history.id).set(history)
      //newest possible versions
          // this.historyService.storeHistory(history);      ----  its own collection
          // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation
    });
    this.getCardInSubscription({uid: this.patientUid}, data.acceptaID);
  } 

  async openUpgradeModal(subscription){
    // let plans = await this.subscriptionService.getProductsPlans(subscription.condition[0], 'products');
    
    let product = await this.productService.getProduct(subscription.productID);
    let plans = await this.productService.getLessPillProducts(product);
    this.currentPromoProduct = plans.filter(x => x.id == subscription.productID)[0];
    this.currentSubscription = subscription;

    if(!this.modalService.hasOpenModals()){
      this.modalRef = this.modalService.open(this.upgradeModal, {size: 'xl', backdrop: "static", keyboard:false, windowClass:"upgradeModal"})    
      this.upgradeBillingTerm = subscription.refillIntervalDays;
      this.plansList(subscription);
    }
  }

  async chooseActionModal(subscription){
    let product = await this.productService.getProduct(subscription.productID);
    // let plans = await this.subscriptionService.getProductsPlans(subscription.condition[0], 'products');
    let plans = await this.productService.getLessPillProducts(product);
    this.currentPromoProduct = plans.filter(x => x.id == subscription.promoProductID)[0];
    this.currentSubscription = subscription;

    if(!this.modalService.hasOpenModals()){
      this.modalRef = this.modalService.open(this.actionModal, {size: 'md', backdrop: "static", centered: true, keyboard:false, windowClass:"upgradeModal"})    
      this.upgradeBillingTerm = subscription.refillIntervalDays;
      this.plansList(subscription);
    }
  }

  async nextAction(){
    await this.modalService.dismissAll();
    if(this.chooseOptions == 'SUBSCRIPTIONS.CHANGEQTY'){
      
      this.openUpgradeModal(this.currentSubscription)
    }
    else {  
      let product = await this.productService.getProduct(this.currentSubscription.productID);   
       this.db.collection("consultTemplates").doc(product.condition[0]).valueChanges().subscribe(async(condition:any) => {
         this.route.navigate(['/consultations'], {queryParams: {condition: condition.conditionCode}});
         this.modalRef.close();
         this.modalRef = undefined;
       })
    }
  }

  chooseOptions = ''
  selectOption(event) {
    // if (event.target.checked ) {
      this.chooseOptions = event.target.id;
    // }
    //  else {
    //   let index = this.chooseOptions.indexOf(event.target.id.toString())
    //   this.chooseOptions.splice(index, 1);
    // }
  }

  async plansList(subscription){
    let plans:any[] = [];  
    this.upgradeBillingTerm = this.upgradeBillingTerm == 30 ? 90 : 30;
    if(plans.length == 0){
      let product = await this.productService.getProduct(subscription.productID);
      // let plans = await this.subscriptionService.getProductsPlans(subscription.condition[0], 'products');
      plans = await this.productService.getLessPillProducts(product);
      // plans = await this.subscriptionService.getProductsPlans(this.currentSubscription.condition[0], 'products');
      this.planList = plans.filter(x => x.refillIntervalDays == this.upgradeBillingTerm);
      // this.planList = plans;
    }else{
      this.planList = plans.filter(x => x.refillIntervalDays == this.upgradeBillingTerm);
      // this.planList = plans;
    }
    // this.filterProducts()
  }


  availablePlans = [];
  async openUpgradeNotificationModal(plan){
    // this.currentPromoProduct = this.currentSubscription;
    this.currentPromoProduct = await this.productService.getProduct(this.currentSubscription.productID);
    this.possibleUpgradePlan = plan;
    let product = await this.productService.getProduct(this.currentSubscription.productID);
    // this.availablePlans = await this.subscriptionService.getProductsPlans(this.currentSubscription.condition[0], 'service');
    this.availablePlans = await this.productService.getLessPillProducts(product);
    this.availablePlans = this.availablePlans.filter(x => x.id == this.possibleUpgradePlan.subProductID);
    this.modalRef = this.modalService.open(this.upgradeNotificationModal, {size: 'md', centered: true, backdrop: "static", keyboard:false, windowClass:"upgradeModal"});
  }
  
  
  upgrade(){
    let product:BaseProduct = {
      id: uuidv4(),
      image: this.possibleUpgradePlan.imgRef,
      productID: this.possibleUpgradePlan.id.toString(),
      productName: this.possibleUpgradePlan.name,
      productPrice: this.possibleUpgradePlan.price,
      productQty: Number(this.possibleUpgradePlan.qty),
      recurrency_order: false,
      type: this.possibleUpgradePlan.type,
      orderLineID: "",
      treatment:this.possibleUpgradePlan.treatment,
      rxcui: this.possibleUpgradePlan.rxcui,
      prescriptionID: this.currentSubscription.prescriptionID,
      automaticPayment:true
    }
    // this.db.collection("subscriptions").doc(this.currentSubscription.id).update({upgrade: true, upgradePlanID: this.possibleUpgradePlan.id}).then(() => {
    this.db.collection("orders").doc(this.currentSubscription.id).collection("orderLines").doc(this.currentSubscription.productID).delete().then(() => {
      this.db.collection("orders").doc(this.currentSubscription.id).collection("orderLines").doc(product.productID).set(product).then(() => {
        this.modalRef.close();
        this.modalService.dismissAll();
        this.modalRef = undefined;
      });
    });
  }

  filteredProducts:any = []
  filterProducts(){
    this.filteredProducts = [];
      for(let i = 0; i < this.planList.length; i++){
        if(this.planList[i].atr0 != "<en>Doctor's Decision</en><es>Sugerencia Médica</es>" && this.planList[i].atr0 != '' && this.planList[i].type == 'product'){
          if(!this.filteredProducts[this.planList[i].atr0] && this.planList[i].atr0 == this.currentPromoProduct.atr0 && this.planList[i].atr1 == this.currentPromoProduct.atr1){
            this.filteredProducts[this.planList[i].atr0] = [{name: this.planList[i].atr1, products: []}]
          }

          for(let j = 0; j < this.filteredProducts[this.planList[i].atr0]?.length; j++){
            let test = this.filteredProducts[this.planList[i].atr0].filter(el => {
              return el.name === this.planList[i].atr1
            })

            if(test.length == 0 && this.planList[i].atr1 == this.currentPromoProduct.atr1){
              this.filteredProducts[this.planList[i].atr0].push({name: this.planList[i].atr1, products: []})
            }

            this.filteredProducts[this.planList[i].atr0] = this.filteredProducts[this.planList[i].atr0].sort((a, b) => {
              return a.name.split('mg')[0] - b.name.split('mg')[0]
            })

            if(this.planList[i].atr1 == this.filteredProducts[this.planList[i].atr0][j].name && this.planList[i].atr1 == this.currentPromoProduct.atr1 
              && ((this.planList[i].qty <= this.currentPromoProduct.qty && this.upgradeBillingTerm == 30) || this.planList[i].qty <= this.currentPromoProduct.qty * 3 && this.upgradeBillingTerm == 90)
              ){ 
              this.filteredProducts[this.planList[i].atr0][j].products.push(this.planList[i])
              this.filteredProducts[this.planList[i].atr0][j].products.sort((a, b) => {
                return a.name.split('- ')[1].split(" Pill")[0] - b.name.split('- ')[1].split(" Pill")[0];
              });    
            }
          }
        }
      }
  }
}

<header id="page-topbar">
  <!-- LOGO -->
  <a href="https://dzeus.com" class="logo ml-2">
    <img src="../../../assets/images/logos/Dzeus-Logo.png" alt="" height="50" class="img-container"> <!--span style="float:right;color:#ffffff;">Dzeus TeleMD</span--> <!--20-->
  </a>
</header>

<div class="text-center mb-5">
  <h1><img class="img" src="../../../assets/images/404Icon.svg"></h1>
  <h3 class="text-uppercase text">{{'404.NOTFOUND' | translate}}</h3>
  <div class="mt-5 row text-center justify-content-center">
    <a class="btn round-btn waves-effect waves-light" routerLink="">{{'404.RETURN' | translate | uppercase}}</a>
  </div>
</div>

<footer class="footer-2"> <!--class="footer"-->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 ml-2">
        {{copyright}}
      </div>
    </div>
  </div>
</footer>
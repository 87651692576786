<div class="container-fluid">
  <app-pagetitle *ngIf="currentUser.accountType != 'admin'" [title]="pagetitle"></app-pagetitle>

  <div class="card-body p-0">
    <!-- <ng-select style="width: 150px;" [clearable]="false" >
      <ng-option value="all">Follow Up</ng-option>
      <ng-option value="refill">My Consults</ng-option>
      <ng-option value="pendingRefill">Aquire</ng-option>
      <ng-option value="pending">Completed</ng-option>
      <ng-option value="paid">Collaboration</ng-option>
    </ng-select> -->
    <ul ngbNav #customNav="ngbNav" [activeId]="1" style="justify-content: space-around;" class="nav nav-tabs nav-tabs-custom mb-4">
      <li *ngIf="currentUser.accountType == 'practitioner'" [ngbNavItem]="1" (click)="showConsults = 'followUpConsults'">
        <a ngbNavLink>
          <span>{{'PRACTITIONER-CONSULTS.FOLLOWUPCONSULTS' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="AllConsults"></ng-template>
        </ng-template>
      </li>
      <li *ngIf="currentUser.accountType == 'practitioner'" (click)="showConsults = 'inProgressConsults'" [ngbNavItem]="2">
        <a ngbNavLink>
          <span>{{'PRACTITIONER-CONSULTS.MYCONSULTS' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="AllConsults"></ng-template>
        </ng-template>
      </li>
      <li *ngIf="currentUser.accountType == 'practitioner'" (click)="showConsults = 'paidConsults'" [ngbNavItem]="3">
        <a ngbNavLink>
          <span>{{'PRACTITIONER-CONSULTS.AQUIRE' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="AllConsults"></ng-template>
        </ng-template>
      </li>
      <li *ngIf="currentUser.accountType == 'practitioner'" (click)="showConsults = 'completedConsults'" [ngbNavItem]="4">
        <a ngbNavLink>
          <span>{{'PRACTITIONER-CONSULTS.COMPLETEDCONSULTS' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="AllConsults"></ng-template>
        </ng-template>
      </li>
      <li *ngIf="currentUser.accountType == 'practitioner'" [ngbNavItem]="5">
        <a ngbNavLink (click)="getCollaborativeConsults()">
          <span>{{'VIEWCONSULTS.COLLABORATION' | translate}}</span>
        </a>
        <ng-template ngbNavContent>
          <ng-template [ngTemplateOutlet]="Collaboration"></ng-template>
        </ng-template>
      </li>
      <li *ngIf="currentUser.accountType == 'admin'" [ngbNavItem]="1">
        <ng-template *ngIf="currentUser.accountType == 'admin'" ngbNavContent>
          <ng-template [ngTemplateOutlet]="adminConsults"></ng-template>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="customNav"></div>
  </div>
</div>
  
<!-- All consults - showConsults contains the variable of the consults that are being shown -->
<ng-template #AllConsults>
  <div class="row mb-md-2" style="justify-content: flex-end;">
    <label for="search" class="d-inline-flex align-items-center">{{'PRACTITIONER-CONSULTS.SEARCH' | translate}}
      <input id="search" type="text" name="searchTerm" class="form-control form-control-sm ml-2"
        aria-controls="tickets-table" [(ngModel)]="searchText">
    </label>
  </div>         
  <div *ngFor="let consult of this[showConsults] | paginate: { itemsPerPage: 5, currentPage: pageConsults, id: 'first'}; let i = index; filter: searchText;" [ngClass]="{'flex-column': hide, 'flex-row': !hide}" class="card d-flex p-3 justify-content-between" [routerLink]="(consult.Status == 'In Progress' || consult.Status == 'Completed') ? ['../consults', consult.ID] : ['../consults']">
    <div class="d-flex">
      <img style="width: 65px; height: intrinsic;" src="{{consult.Photo1}}">
      <div class="d-flex flex-column justify-content-center">
        <h4 class="condition-name m-0">{{consult.conditionName | translation:currentLang}}</h4>
        <p class="m-0">{{consult.patientName}}</p>
        <p class="m-0">{{'PRACTITIONER-CONSULTS.LOCATION' | translate}}: {{consult.state.name}}, {{consult.country}}</p>
      </div>
      <p class="m-0" *ngIf="consult?.transfered">{{"TRANSFER.TRANSFERED" | translate}}</p>
    </div>
    
    <div>             
      <p class="d-flex flex-column m-0 font-size-12" style="width: fit-content; border-radius: 0.25rem; padding: 0.25em 0.4em;">
        <span class="m-0">{{'VIEWCONSULTS.CONSULTSUMMARY.TYPEOFSCHEDULE' | translate}}:&nbsp;</span>
        <span class="m-0">{{consult.schedule?.time | translation:currentLang}}, {{consult.schedule?.type | translation:currentLang}}</span>
      </p>
      <div class="d-flex w-100" style="text-align: end; margin-top: 10px; justify-content: end;">
        <span class="m-0 font-size-12"  [ngClass]="{'badge-soft-success':consult.Status === 'Completed','badge-soft-warning': consult.Status === 'Paid', 'badge-soft-danger': consult.Status === 'Draft',
        'badge-soft-progress': consult.Status === 'In Progress'}"
          style="width: auto !important; border-radius: 0.25rem; padding: 0.25em 0.4em;">
          <ngb-highlight *ngIf="consult.Status === 'Draft'" [result]="'PRACTITIONER-CONSULTS.DRAFT' | translate" ></ngb-highlight>
          <ngb-highlight *ngIf="consult.Status === 'Paid'" class="w-100" [result]="'PRACTITIONER-CONSULTS.PAID' | translate" ></ngb-highlight>
          <ngb-highlight *ngIf="consult.Status === 'In Progress'" class="w-100" [result]="'PRACTITIONER-CONSULTS.INPROGRESS' | translate" ></ngb-highlight>
          <ngb-highlight *ngIf="consult.Status === 'Completed'" [result]="'PRACTITIONER-CONSULTS.COMPLETED' | translate" ></ngb-highlight>
        </span>
        <span class="d-flex m-0 ml-2 font-size-12 w-100" style="width: auto !important; border-radius: 0.25rem; padding: 0.25em 0.4em;" [ngClass]="{'badge-soft-cyan': true }">
          <span class="m-0">{{'VIEWCONSULTS.LANG' | translate}}&nbsp;</span>
          <span class="m-0" *ngIf="consult.consultLanguage == 'en'">{{'VIEWCONSULTS.ENGLISH' | translate}}</span>
          <span class="m-0" *ngIf="consult.consultLanguage == 'es'">{{'VIEWCONSULTS.SPANISH' | translate}}</span>
        </span>
        <a *ngIf="consult.Status == 'Paid'" href="javascript:void(0);" class="ml-3 text-primary" placement="top" ngbTooltip="{{'PRACTITIONER-CONSULTS.NGBTOOLTIP.AQUIRE' | translate}}" (click)="assignConsults(consult)"><i
          class="mdi mdi-briefcase-check font-size-18"></i></a> 
      </div>   
      <p *ngIf="consult.dateToSort" class="m-0" style="text-align: end;">{{ consult.dateToSort.toDate() | date:'MMMM dd, yyyy h:mma' | titlecase}}</p>
    </div>
  </div>
  <div class="no-consult" *ngIf="this[showConsults]?.length == 0">
    <hr>
    <p class="justify-content-center d-flex">{{'PRACTITIONER-CONSULTS.MESSAGE' | translate}}</p>
  </div>
  <div class="row justify-content-end mt-4">
    <div class="col text-right">
      <pagination-controls (pageChange)="pageConsults = $event" id="first" style="text-align: right;"></pagination-controls>
    </div>
  </div>
</ng-template>

<ng-template #adminConsults>
  <app-admin-consults></app-admin-consults>
</ng-template>

<ng-template #Collaboration>
  <div *ngFor="let consult of collaborativeConsults | filter: searchText" [ngClass]="{'flex-column': hide}" class="card d-flex" [routerLink]="['../consults', consult.ID]">
    <img style="width: 65px; height: intrinsic;" src="{{consult.Photo1}}">
    <div class="d-flex flex-column justify-content-center">
      <h4 class="m-0">{{ "COLLAB.TEAMUP" | translate }}</h4>
      <h5 class="m-0">{{consult.practitionerName}} {{ "COLLAB.DESCRIPTION" | translate }} <span>{{consult.conditionName | translation:currentLang}}</span></h5>
      <p class="m-0">{{consult.patientName}}</p>
    </div>
  </div>
</ng-template>
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GeneratePsychotherapyService } from 'src/app/core/services/generate-psychotherapy.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { ReferralService } from 'src/app/core/services/referral.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { History } from 'src/app/core/interfaces/history';
import { MEDICAL_REFERAL } from 'src/app/core/enums/process-history';
import { QuestionServiceService } from 'src/app/core/services/question-service.service';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {
  @Input() consult: any;
  referralText: any = ''
  referralSelection = ''
  referralConsult = ''
  referralPractitioner = ''
  practitioners = []
  pastPractitioners = []
  showPractitioners = 'past'
  consults = []
  modalRef: any;
  practitioner: any;
  practitionerAddress
  patient: any;
  patientAddress
  referralDate = new Date()
  NPI
  base64
  referrals
  creatingReferral = false;
  typeOfReferral = [
    'Dzeus Consult', 'Recommendation', 
  'Specialist outside Dzeus']

  constructor(
    private generatePychotherapy: GeneratePsychotherapyService,
    private afs: AngularFirestore,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    public firebaseService: FirebaseService,
    private questionService: QuestionServiceService
  ) { }

  ngOnInit(): void {
    this.afs.collection('consultTemplates', ref => ref.where('disabled', '==', false)).valueChanges().subscribe(res => {
      this.consults = res
    })

    this.afs.collection('referrals', ref => ref.where('consultID', '==', this.consult.ID)).valueChanges().subscribe((res: any) => {
      if(res){
        this.referrals = res
      }
    })

    if(this.consult.UID_Practitioner != undefined && this.consult.UID_Practitioner != null && this.consult.UID_Practitioner != ""){

      // console.log("this.consult.UID_Practitioner",this.consult.UID_Practitioner)

      this.afs.collection('users').doc(this.consult.UID_Practitioner).valueChanges().subscribe(async res => {
        this.practitioner = res
        this.NPI = await this.authService.getNPIRegistry(this.practitioner.uid);
        let practitionerAddressess = await this.firebaseService.getUserAddresses(this.practitioner)
        let practitionerAddress = await practitionerAddressess.filter(address => address.AddressType == 'Billing');
  
        if(practitionerAddress[0].AddressLine2 === undefined || practitionerAddress[0].AddressLine2 === ""){
          this.practitionerAddress = practitionerAddress[0].AddressLine1+", "+practitionerAddress[0].AddressCity + " " + practitionerAddress[0].AddressState + " " + practitionerAddress[0].AddressZipcode+", "+practitionerAddress[0].AddressCountry;
        }else{
          this.practitionerAddress = practitionerAddress[0].AddressLine1 + " " + practitionerAddress[0].AddressLine2+", "+practitionerAddress[0].AddressCity + " " + practitionerAddress[0].AddressState + " " + practitionerAddress[0].AddressZipcode+", "+practitionerAddress[0].AddressCountry;
        }
      })

    }

    this.afs.collection('users').doc(this.consult.UID_Patient).valueChanges().subscribe(async res => {
      this.patient = res
      // let patientAddress: any = await this.authService.userBillingAddress(this.patient.uid)
      // if(patientAddress[0].AddressLine2 === undefined || patientAddress[0].AddressLine2 === ""){
      //   this.patientAddress = patientAddress[0].AddressLine1+", "+patientAddress[0].AddressCity + " " + patientAddress[0].AddressState + " " + patientAddress[0].AddressZipcode+", "+patientAddress[0].AddressCountry;
      // }else{
      //   this.patientAddress = patientAddress[0].AddressLine1 + " " + patientAddress[0].AddressLine2+", "+patientAddress[0].AddressCity + " " + patientAddress[0].AddressState + " " + patientAddress[0].AddressZipcode+", "+patientAddress[0].AddressCountry;
      // }    
    })
  }

  createReferral(){
    // this.generatePychotherapy.generatePsycotherapy(this.consult, false);
  }
  createReferralFollowUp(){
    // this.generatePychotherapy.generatePsycotherapy(this.consult, true);
  }

  collectMessage($event){
    this.referralText = $event.target.value
  }
  
  async signPad(modal){
    this.modalRef = this.modalService.open(modal, {size: 'lg', backdrop: 'static', centered: true, windowClass:'signPad' });
  }

  async getSignature(signature:string){
    this.modalRef.dismiss();
    this.modalRef = undefined;

    let referral = new ReferralService(this.afs, this.authService)

    referral.consult = this.consult
    referral.practitioner = this.practitioner
    referral.patient = this.patient
    referral.signature = signature
    referral.practitionerAddress = this.practitionerAddress
    referral.patientAddress = this.patientAddress
    referral.referralCondition = 'Referral to: ' + (this.referralSelection == 'Specialist outside Dzeus' ? 'Specialist': this.referralSelection)
    referral.text = this.referralText
    referral.generatePdf(signature)
    this.creatingReferral = false;

    let history:History = {
      date: new Date(Date.now()),
      id: this.firebaseService.getDate(),
      userUID: this.practitioner.uid,
      user: this.practitioner.firstName + ' ' + this.practitioner.lastName1,
      userAccountType: this.practitioner.accountType,
      action: MEDICAL_REFERAL.CREATED,
      // consultID: this.consult.ID,
      // consultStatus: this.consult.status,
      // createdBy: this.practitioner.uid,
    }
    this.afs.collection('consultation').doc(this.consult.ID).collection('History').doc(history.id).set(history);
    
    //newest possible versions
          // this.historyService.storeHistory(history);      ----  its own collection
          // this.historyService.storeHistoryInConsultation(history);  ----  subcollection in consultation

    if(this.referralSelection == 'Dzeus Consult') {
      this.createConsult()
    }
  }

  invalidateReferral(invalidReferral){
    this.afs.collection("referrals").doc(invalidReferral.ID).update({valid: false})
    let referral = new ReferralService(this.afs, this.authService)
    referral.addWaterMark(invalidReferral, this.patient.uid)
  }

  selectReferral(event){
    this.referralSelection = event.target.value
    this.referralPractitioner = ''
    this.referralConsult = ''
  }

  selectPractitioner(event){
    this.referralPractitioner = event.target.value
  }

  selectTypeOfConsult(event){
    this.referralConsult = event.target.value

    let practitionerForConsult = this.consults.filter(consult => consult.serviceType == this.referralConsult)
    this.practitioners = []
    this.pastPractitioners = []
    if(practitionerForConsult[0]){
      practitionerForConsult[0].typeOfPractitioner?.forEach(p => {
        this.afs.firestore.collection('users').where('typeOfPractitioner', 'array-contains', p).where('isAccountDisabled', '==', false).where('status', '==', 'Verified').get().then((practitioner: any) => {    
          practitioner.docs.forEach(p => {
            this.practitioners.push(p.data())
          })
        })
      })

      this.afs.firestore.collection('users').doc(this.patient.uid).collection('myPractitioners').get().then(res => {
        res.docs.forEach(p => {
          this.afs.firestore.collection('users').doc(p.data().uid).get().then(res1 => {
            for(let k = 0; k < res1.data().typeOfPractitioner.length; k++) {
              if(practitionerForConsult[0].typeOfPractitioner.includes(res1.data().typeOfPractitioner[k]) && res1.data().isAccountDisabled == false && res1.data().status == 'Verified'){
                this.showPractitioners = 'past'
                this.pastPractitioners.push(res1.data())
                break
              }
            }
          })
        })
        if(this.pastPractitioners.length == 0){
          this.showPractitioners = 'all'
        }
      })
    }
  }

  createConsult(){
    let consult = this.referralConsult.includes('Mental Health') && this.consult.serviceType.includes('Mental Health') ? 'same' :'diff';
    let practName = this.referralPractitioner.split(' ')
    let pract = this.referralPractitioner == 'Any Healthcare Provider' ? '' : this.practitioners.filter(p => p.firstName == practName[0] && p.lastName1 == practName[1])[0]
    this.afs.firestore.collection('consultTemplates').doc(this.referralConsult).get().then(res => {
      let data = Object.assign(res.data(), {preconsult: false, currentQuestion: 1,
        currentWizardStep: 1,
        currentProgress: 0,
        practitionerForConsult: pract?.uid ? pract.uid : ''})
      this.firebaseService.createConsult(data, this.patient).then(res => {
        if(consult == 'same' && !this.consult.serviceType.includes('Follow Up')){
          this.afs.firestore.collection('consultation').doc(this.consult.ID).collection('Questions').get().then(questions =>{
            let testQuestions = questions.docs.map(res => res.data()).sort((a,b) => {
              return a.sequence - b.sequence
            })

            this.afs.collection('consultation').doc(res.ID).update({totalQuestions: testQuestions.length})

            this.firebaseService.addQuestionsToUser(testQuestions, res.ID);
          })
        }
        else {
          this.questionService.getTemplateQuestions(data.conditionQuestionsId).then((res1:any) => {
              this.afs.collection('consultation').doc(res.ID).update({totalQuestions: res1.length})
              this.firebaseService.addQuestionsToUser(res1, res.ID);
          })
        }
      })
    })
  }
}

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import firebase from "firebase/compat/app";
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { Profile } from 'src/app/core/services/interfaces';
import { LanguageService } from 'src/app/core/services/language.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})
export class VerticalComponent implements OnInit, AfterViewInit {
  @ViewChild('phoneVerification') public phoneNumberModal : ElementRef ;
  @ViewChild('surveyModal') public surveyModal : ElementRef ;

  recaptchaVerifier
  loading = true;
  language = false;
  profile;
  swal: any;

  settings;
  pdfsLabel = ['<en>Medicine Licence</en><es>Licencia de Medicina</es>', '<en>Telemedicine Certificate</en><es>Certificado de telemedicina</es>', '<en>Endoursement</en><es>Endoso</es>']

  modalRef:any;
  survey;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    private translate: TranslateService,
    private languageService: LanguageService,
    private modalService: NgbModal
  ) { 
    this.authenticationService.getUser();
    if(router.url.includes('?code=')){
      afs.firestore.collection('urlCodes').doc(router.url.split('?code=')[1]).get().then((res: any) => {
        let decodedRoute = decodeURIComponent(res.data().url.split('.com')[1])
        router.navigateByUrl(decodedRoute)
      })
    }
  }
  ngAfterViewInit(): void {
    setTimeout(res =>{
      this.loading = false;  
    },500)

    let url = localStorage.getItem('returnUrl')
    if(url && this.router.url == '/'){
      localStorage.removeItem('returnUrl')
      this.router.navigateByUrl(url)
    }
  }

  ngOnInit(): void {
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-layout');
    document.body.removeAttribute('data-topbar');
    document.body.classList.remove('auth-body-bg');

    let currentUser: any = this.authenticationService.currentUser();
    if(currentUser && currentUser?.email == null){
      currentUser = firebase.auth().currentUser;
    }

    this.languageSet();

    if(currentUser && this.router.url != '/account/reload'){
      this.afs.firestore.collection('settings').doc('maintenance').get().then((res: any) => {
        this.settings = res.data();

        this.authenticationService.profile().then(async profile => {
          let data:any = profile;
          if(data && data.finishedSignup == false){
            this.translate.get('GUARD').subscribe((res: any) => {            
              Swal.fire({
                customClass: {
                  confirmButton: 'swalPurple',
                },
                title: res.TITLE,
                text: res.TEXT,
                icon: 'warning',
                showConfirmButton: true,
                allowEscapeKey : false,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: res.CONFIRM,
              }).then(result => {
                if (result.isConfirmed) {
                  this.router.navigate(['account/welcome/register'])
                }
              })
            })
          }
  
          const nextTimestamp = Date.now() - 7*24*60*60*1000; //7 days ago
          if(!this.settings.maintenance && data && data.phoneNumber && data.phoneNumberMsg == false && !data.dontAskForMessages && (!data.lastMessageReminder || Number(data.lastMessageReminder.seconds * 1000) <= Number(nextTimestamp))){
            this.profile = data;
            this.translate.get('PROFILE').subscribe((res: any) => {
              this.swal = res
              
              Swal.fire({
                customClass: {
                  confirmButton: 'swalPurple',
                  cancelButton: 'btn-light'
                },
                title: this.swal.SMSNOTIFICATIONS,
                html: `<div>
                        <h5 class="m-0">${this.swal.SMSTEXT}</h5>
                        <h5 class=" mb-4">${this.swal.SMSQUESTION}</h5>
                        <p class="m-0 d-flex" style="color: #7f7f7f; font-size: 14.5px; text-align: left;">
                            <span>*&nbsp;&nbsp;</span>
                            <span>${this.swal.SMSIMPORTANCE}</span></p>
                            <div class="d-flex mt-3">
                              <label class="checkbox-container">
                                <input type="checkbox" id="dontAskForMessages" style="position: relative;">
                                <span class="checkmark" style="min-width: 1rem; width: 1rem; height: 1rem; position: relative;"></span>
                              </label> 
                              <span class="ml-1" style="font-size: 13.5px; color: #7f7f7f;">${this.swal.DONTASK}</span>
                            </div>
                      <div>`,
                // text: this.swal.SMSTEXT,
                // icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                allowEscapeKey : false,
                allowOutsideClick: false,
                confirmButtonText: this.swal.SUBSCRIBE,
                cancelButtonText: this.swal.NOTHANKS,
              }).then(res => {
                if(res.isConfirmed){
                  this.modalService.open(this.phoneNumberModal, {backdrop: 'static', keyboard: false, centered: true, size: 'lg'})
                }
                if(res.isDismissed){
                  this.afs.collection('users').doc(firebase.auth().currentUser.uid).update({lastMessageReminder: new Date()})
  
                  let doc: any = <HTMLInputElement>document.getElementById('dontAskForMessages')
                  if(doc.checked){
                    this.afs.collection('users').doc(firebase.auth().currentUser.uid).update({dontAskForMessages: true})
                  }
                }
              })
            })
          } else 
          if(!this.settings.maintenance && data && data.accountType == 'practitioner'){
            this.afs.firestore.collection('Documents').where('referenceID', '==', profile.uid).get().then(async (docs: any) => {
              let myPdfs = []

              for(let i = 0; i < this.pdfsLabel.length; i++){
                myPdfs[i] = docs.docs.filter(doc => doc.data().description == this.pdfsLabel[i]).sort((a, b) => {
                  return b.data().expDate.localeCompare(a.data().expDate)
                }) || []
              }

              for(let i = 0; i < myPdfs.length; i++){
                if(myPdfs[i][0] && myPdfs[i][0].data().expDate < new Date().toISOString().split('T')[0]){
                  this.translate.get('PROFILE').subscribe((res: any) => {
                    this.swal = res
                    
                    Swal.fire({
                      customClass: {
                        confirmButton: 'swalPurple',
                      },
                      title: this.swal.EXPIREDDOCS,
                      html: `<div>
                              <h5 class="m-0">${this.swal.EXPIREDDOCSTEXT}</h5>
                            <div>`,
                      showConfirmButton: true,
                      allowEscapeKey : false,
                      allowOutsideClick: false,
                      confirmButtonText: this.swal.GOTOPROFILE,
                    }).then(res => {
                      if(res.isConfirmed){
                        this.router.navigate(['profile'])
                      }
                    })
                  })
                  i = myPdfs.length;
                  break;
                }
              }

            })
          }
          else {
            if(!this.settings.maintenance && data && data.accountType == 'patient'){
              this.afs.firestore.collection('surveys').where('surveyPatientUid', '==', profile.uid).where('surveyPatientStatus', '==', 'active').get().then((res: any) => {
                if(res.docs.length > 0){
                  const nextTimestamp = Date.now() - 7*24*60*60*1000; //7 days ago
                  if((!res.docs[0].data().surveyLastReminder || Number(res.docs[0].data().surveyLastReminder.seconds * 1000) <= Number(nextTimestamp))){
                    this.survey = res.docs[0].data();
                    this.modalRef = this.modalService.open(this.surveyModal, {backdrop: 'static', keyboard: false, centered: true,})
                  }        
                }
              })
            }
          }
        })
      })
    }
  }
  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('vertical-collpsed');

    if (window.screen.width <= 767) {
      document.body.classList.remove('vertical-collpsed');
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }


  async languageSet(){
    this.authenticationService.profile().then((profile:Profile) => {
      if(profile !== undefined){
        this.languageService.setLanguage(profile.preferedLang);

        // setTimeout(res =>{
          this.language = true;
        // },1000)
      }
    })
  }
}

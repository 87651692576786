import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DiagnosticTemplate } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AdminManagementService {

  constructor(
    private http: HttpClient,
    private db: AngularFirestore,  
  ) { }



  storeDiagnosticTemplate(data: DiagnosticTemplate[]){
    data.forEach((item: any) => {
      return this.db.collection("diagnosticTemplates").doc(item.id).set(item);
    });
  }


  storeICD10(lab:any, icd10s: any[]){  
    // data.forEach((item: any) => {
    
    return this.db.collection("labAnalysisTemplates").doc(lab.id).update({icd10: icd10s});
    // });
  }

  getDiagnosticsTemplates(codes: any[]) {
    return new Promise((resolve, reject) => {
      let diagnostics: any[] = [];
      codes.forEach((item: any, i: number) => {
        this.db.collection("diagnosticTemplates", ref => ref.where("code", "==", item)).valueChanges().subscribe((data: any) => {
          diagnostics.push(data[0]);
          if (i == codes.length - 1) {
            resolve(diagnostics);
          }
        });
      });
    });
  }
}
<div class="vertical-menu">
  <perfect-scrollbar>
    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu" #sideMenu  *ngIf="emailVerified == true"> 
        <ng-container *ngFor="let item of menuItems">
          <li class="menu-title" *ngIf="claims && claims.accountType && item.isTitle && item[claims.accountType]">{{ item.label | translate }}</li>

          <!-- none, patient, practitioner, admin -->
          <li *ngIf="claims && claims.accountType && !claims.analyticViewer && !item.isTitle && ((item[claims.accountType] && claims.accountType != 'practitioner') || ((item[claims.accountType] && claims.accountType == 'practitioner' && searchTypeOfPractitioner(item))) || item.programmer == programmer || item.superAdmin == superAdmin || item.questionAdmin == questionAdmin)">
            <a [routerLink]="item.link" (click)="this.toggleMobileMenu()" class="side-nav-link-ref d-flex" routerLinkActive="active">
              <i class="{{ item.icon }}" *ngIf="item.icon"></i>
              <span> {{ item.label | translate }}</span>
              <div *ngIf="item.link == '/orders&Refills' && orders.length != 0" class="notification mx-auto"><span>{{orders.length}}</span></div>
            </a>
          </li>

          <!-- Analytics viewer -->
          <!-- <li *ngIf="claims && claims.accountType && claims.analyticViewer && !item.isTitle && ((item[claims.accountType] && claims.accountType != 'practitioner') || ((item[claims.accountType] && claims.accountType == 'practitioner' && searchTypeOfPractitioner(item))) || item.programmer == programmer)">
            <a [routerLink]="item.link" (click)="this.toggleMobileMenu()" class="side-nav-link-ref d-flex" routerLinkActive="active">
              <i class="{{ item.icon }}" *ngIf="item.icon"></i>
              <span> {{ item.label | translate }}</span>
              <div *ngIf="item.link == '/orders&Refills' && orders.length != 0" class="notification mx-auto"><span>{{orders.length}}</span></div>
            </a>
          </li> -->
        </ng-container>
      </ul>
    </div>
    <!-- Sidebar -->
  </perfect-scrollbar>
</div>
<div class="container">
  <div class="child">
    <div *ngIf="!consult" class="header-text">
      <h4 *ngIf="editCard == false">{{'WALLET.NEWCARD' | translate}}</h4>
      <h4 *ngIf="editCard == true">{{'WALLET.EDITCARD' | translate}}</h4>
    </div>

  <!-- Accepta Component -->
    <section class="cc-wrapper">
      <form [ngClass]="{'py-0': consult}" class="cc-form p-0" [formGroup]="ccForm" autocomplete="off" style="min-width: auto !important;">
        <div class="row">
          <div class="col-auto mx-auto align-self-center">
            <label for="cc-holder-name" class="inp">
              <input class="form-control" id="cc-holder-name" aria-label="Card holder name" type="text"
                    title="Card holder name" maxlength="30" formControlName="cardHolder">
              <span class="label">{{'PAYMENTS.CARDHOLDER' | translate}}</span>
              <span class="focus-bg"></span>      
            </label>
            <div class="cc-form__error"
                *ngIf="validateCardHolder  &&
                ccForm.get('cardHolder').hasError('required')">
              {{'PAYMENTS.cardHolderMissingTxt' | translate}}
            </div>
            <div class="cc-form__error"
                *ngIf="validateCardHolder &&
                ccForm.get('cardHolder').hasError('maxlength')">
              {{'PAYMENTS.cardHolderTooLongTxt' | translate}}
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col-auto mx-auto align-self-center">
            <label for="cc-number" class="inp">       
              <input #ccNumber autocomplete="false" class="form-control" id="cc-number" aria-label="Card number"
              type="text" title="Card number" maxlength="19" formControlName="cardNumber"
              (keyup)="validateCard($event.target.value)">
              <span class="label">{{'PAYMENTS.CARDNUMBER' | translate}}</span>
              <span class="focus-bg"></span>
              <div class="cc-form__error"
                    *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('required')">
                {{'PAYMENTS.ccNumMissingTxt' | translate}}
              </div>
              <div class="cc-form__error"
                    *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('minlength')">
                {{'PAYMENTS.ccNumTooShortTxt' | translate}}
              </div>
              <div class="cc-form__error"
                    *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('maxlength')">
                {{'PAYMENTS.ccNumTooLongTxt' | translate}}
              </div>
              <div class="cc-form__error"
                    *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('numbersOnly')">
                {{'PAYMENTS.ccNumContainsLettersTxt' | translate}}
              </div>
              <div class="cc-form__error"
                    *ngIf="validateCCNum && ccForm.get('cardNumber').touched && ccForm.get('cardNumber').hasError('checksum')">
                {{'PAYMENTS.ccNumChecksumInvalidTxt' | translate}}
              </div>
              <div class="cc-form__error"
                *ngIf="getCardType(ccNumber.value) != null && !cardsAvailable.includes(getCardType(ccNumber.value))">
                {{'PAYMENTS.INVALIDCARD' | translate}}
              </div>
            </label>
          </div>
        </div>


        <div class="row">
          <div class="col-auto align-self-center">
            <div class="d-flex">
              <label for="cc-expiration-month" class="inp">       
                <select class="form-control" style="height:46px" id="cc-expiration-month" name="expirationMonth" aria-label="Expiration month"
                  formControlName="expirationMonth">
                  <option value="">
                    </option>
                <option *ngFor="let month of months" value="{{month}}"
                        >{{month}}
                </option>
                </select>
                <span class="label">MM</span> <!-- {{'PAYMENTS.EXPIRATIONMONTH' | translate}} -->
                <span class="focus-bg"></span>  
              </label>
              <p class="m-0" style="font-size: 30px">&nbsp;/&nbsp;</p>
              <label for="cc-expiration-year" class="inp">
                <select id="cc-expiration-year" class="form-control" style="height:46px" name="expirationYear" aria-label="Expiration year"
                        formControlName="expirationYear">
                  <option value=""
                          >
                  </option>
                  <option *ngFor="let year of years" value="{{year}}"
                          >{{year}}
                  </option>
                </select>
                <span class="label">YYYY</span> <!-- {{'PAYMENTS.EXPIRATIONYEAR' | translate}} -->
                <span class="focus-bg"></span>  
              </label>
            </div>
            <div class="mt-2">
              <label for="cc-ccv" class="inp">            
                <input class="form-control" style="height: 46px;" id="cc-ccv" aria-label="CCV" type="text" title="CCV"
                        minlength="3" maxlength="4" formControlName="ccv">
                <span class="label">{{'PAYMENTS.CCV' | translate}}</span>
                <span class="focus-bg"></span>  
              </label>
            </div>
            <div class="cc-form__error"
              *ngIf="validateCCV && ccForm.get('ccv').touched && ccForm.get('ccv').hasError('required')">
            {{'PAYMENTS.ccvMissingTxt' | translate}}
            </div>
            <div class="cc-form__error"
                *ngIf="validateCCV && ccForm.get('ccv').touched && ccForm.get('ccv').hasError('minlength')">
              {{'PAYMENTS.ccvNumTooShortTxt' | translate}}
            </div>
            <div class="cc-form__error"
                *ngIf="validateCCV && ccForm.get('ccv').touched && ccForm.get('ccv').hasError('maxlength')">
              {{'PAYMENTS.ccvNumTooLongTxt' | translate}}
            </div>
            <div class="cc-form__error"
                *ngIf="validateCCV && ccForm.get('ccv').touched && ccForm.get('ccv').hasError('numbersOnly')">
              {{'PAYMENTS.ccvContainsLettersTxt' | translate}}
            </div>
            <div class="cc-form__error"
                *ngIf="validateExpirationMonth && ccForm.get('expirationMonth').touched &&
                    ccForm.get('expirationMonth').hasError('required')">
              {{'PAYMENTS.expirationMonthMissingTxt' | translate}}
            </div>
            <div class="cc-form__error"
                *ngIf="validateExpirationMonth && ccForm.get('expirationYear').touched &&
                    ccForm.get('expirationYear').hasError('required')">
              {{'PAYMENTS.expirationYearMissingTxt' | translate}}
            </div>
            <div class="cc-form__error" *ngIf="validateCardExpiration && ccForm.get('expirationMonth').touched &&
            ccForm.get('expirationYear').touched && ccForm.hasError('expiration')">
              {{'PAYMENTS.cardExpiredTxt' | translate}}
            </div>
          </div> 
        </div>
        <div class="d-flex">
          <label for="default" class="switch">
            <input id="default" type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="default">
            <span class="slider round"></span>
          </label>
          <p class="ml-1 mb-0">{{'PAYMENTS.SETDEFAULT' | translate}}</p>
        </div>
        <div class="custom-control custom-checkbox mt-3">
          <input type="checkbox" class="custom-control-input" id="shippingAddress" (change)="selectShippingAddress($event)" name="phoneNumberMsg">
          <label class="custom-control-label" for="shippingAddress">{{'ADDRESS.USESHIPPING' | translate}}</label>
        </div>
       
        <div class="billing-address">
          <label for="cc-addressLine1" class="inp">
            <input class="form-control" id="cc-addressLine1" aria-label="Billing Address" type="text"
            title="Billing address" maxlength="50" formControlName="addressLine1">
            <span class="label">{{'PAYMENTS.ADDRESSLINE1' | translate}}</span>
            <span class="focus-bg"></span>      
          </label>
          <div class="cc-form__error" *ngIf="validateAddress && ccForm.get('addressLine1').touched && ccForm.get('addressLine1').hasError('required')">
            {{'PAYMENTS.addressMissingTxt' | translate}}
          </div>
        </div> 
        <div class="billing-address d-flex flex-column" style="text-align: center;">
          <label for="cc-addressLine2" class="inp">
            <input class="form-control" id="cc-addressLine2" aria-label="Billing Address" type="text"
            title="Billing address" maxlength="50" formControlName="addressLine2">
            <span class="label">{{'PAYMENTS.ADDRESSLINE2' | translate}}</span>
            <span class="focus-bg"></span>      
          </label>
          {{'SIGNUP.OPTIONAL' | translate}}
        </div> 
        <div class="cc-form__wrapper cc-form__wrapper--last mt-0" [ngClass]="{'mb-0': consult}">
          <label for="cc-zip" class="inp">
            <input class="form-control" id="cc-zip" aria-label="ZIP" type="text" title="ZIPCODE"
                   minlength="5" maxlength="9" formControlName="zipCode">
              <span class="label">{{'PAYMENTS.ZIPCODE' | translate}}</span>
              <span class="focus-bg"></span>            
            </label>
            <div class="cc-form__error" *ngIf="validateZip && ccForm.get('zipCode').touched && ccForm.get('zipCode').hasError('required')">
              {{'PAYMENTS.zipCodeMissingTxt' | translate}}
            </div>
            <div class="cc-form__error" *ngIf="validateZip && ccForm.get('zipCode').touched && ccForm.get('zipCode').hasError('minlength')">
              {{'PAYMENTS.zipCodeTooShortTxt' | translate}}
            </div>
            <div class="cc-form__error" *ngIf="validateZip && ccForm.get('zipCode').touched && ccForm.get('zipCode').hasError('maxlength')">
              {{'PAYMENTS.zipCodeTooLongTxt' | translate}}
            </div>
        </div>
        <button *ngIf="editCard == false && loading == false && !consult" type="submit" class="cc-form__button cc-form__button--ripple btn" aria-label="submit" (click)="emitSavedCard()"
                (keydown.enter)="emitSavedCard()" [disabled]="invalidCard()">{{'PAYMENTS.SUBMIT' | translate}}
        </button>
        <button *ngIf="editCard == true && loading == false && !consult" type="submit" class="cc-form__button cc-form__button--ripple btn" aria-label="submit" (click)="emitSavedCardEdit()"
                (keydown.enter)="emitSavedCardEdit()" [disabled]="invalidCard()">{{'WALLET.UPDATE' | translate}}
        </button>
        <button *ngIf="loading == true && !consult" type="submit" class="cc-form__button cc-form__button--ripple btn" aria-label="submit"
                disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{'RELOAD.SAVING' | translate}}
        </button>
        <button *ngIf="!consult" type="submit" class="cc-form__button cc-form__button--ripple btn btn-light mb-3" aria-label="submit" (click)="modalRef.dismiss()"
                 >{{'PAYMENTS.CANCEL' | translate}}
        </button>
      </form>
    </section>
  </div>
</div>
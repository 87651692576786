import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-templates',
  templateUrl: './accountCreatedPractitioners_en.html',
  styleUrls: ['./email-templates.component.scss']
})
export class EmailTemplatesComponent implements OnInit {

  constructor() { }

  email
  twitter
  appName
  contactUs
  facebook
  help
  instagram
  myAccount
  phoneNumber
  privacyPolicy
  year = 'Copyright 2022'
  logo = 'https://firebasestorage.googleapis.com/v0/b/dzeus-app-telemd.appspot.com/o/image%2Flogos%2FDzeus%20Logo%20(1)-01%20(1).png?alt=media&token=a56c97c7-99a7-4ea3-abb4-1ef2a984fa5f'
  supportEmail = ''
  login = ''
  link = ''
  toName = ''

  ngOnInit(): void {
  }

}

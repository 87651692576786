<div class="main">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            Medical Insurance
          </div>
          <div class="">
            <app-upload *ngIf="medicalInsurance.url == null" [user]="user" [uploadMedicalInsurance]="true" [file_types]="'image/*'"></app-upload>
            <div *ngIf="medicalInsurance.url != null" class="d-flex justify-content-center">
              <img [src]="medicalInsurance.url" alt="Medical Insurance" class="img-thumbnail" style="width: 50%; height: 50%;">
            </div>
            <!-- <form id="file-upload-form" class="uploader mt-2">
              <input #fileUpload id="file-upload-comp" type="file" multiple name="fileUpload" accept="image/*" (change)="getFiles($event)"/>
            
              <label for="file-upload-comp" id="file-drag">
                <img id="file-image" src="#" alt="Preview" class="hidden">
                <div id="start">
                  <svg id="upload-icon" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2"></path>
                    <path d="m7 9 5-5 5 5"></path>
                    <path d="M12 4v12"></path>
                  </svg>
                  <span class="formats mt-3">{{'UPLOAD.SUPPORTED-FORMATS' | translate}} png, jpeg, etc...</span>
                  <div id="notimage" class="hidden">{{'UPLOAD.SELECT-IMG' | translate}}</div>
                  <span id="file-upload-btn" class="btn btn-primary" (change)="fileUpload.click()">{{'UPLOAD.SELECT-FILE' | translate}}</span>
                </div>
                <div id="response" class="hidden">
                  <div id="messages"></div>
                  <progress class="progress" id="file-progress" value="0">
                    <span>0</span>%
                  </progress>
                </div>
              </label>
            </form> -->
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="medicalInsurance">Medical Insurance Name</label>
                  <ng-select type="text" class="form-control p-0" id="medicalInsurance" placeholder="Medical Insurance Name" [(ngModel)]="medicalInsurance.name">
                    <ng-option *ngFor="let insurance of insuranceTemplates" [value]="insurance.name">{{insurance.name}}</ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="policyNumber">Contract Number</label>
                  <input type="text" class="form-control" id="policyNumber" placeholder="Contract Number" [(ngModel)]="medicalInsurance.contractNumber">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="policyHolder">Group Number</label>
                  <input type="text" class="form-control" id="policyHolder" placeholder="Group Number" [(ngModel)]="medicalInsurance.groupNumber">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="policyHolder">Efective Date</label>
                  <input type="date" class="form-control" id="policyHolder" placeholder="Efective Date" [(ngModel)]="medicalInsurance.efectiveDate">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="policyHolder">Co-payment</label>
                  <input type="text" class="form-control" id="policyHolder" placeholder="Co-payment" [(ngModel)]="medicalInsurance.copayment">
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="form-group">
                  <label for="policyHolder">Policy Holder</label>
                  <input type="text" class="form-control" id="policyHolder" placeholder="Policy Holder" [(ngModel)]="medicalInsurance.">
                </div>
              </div> -->
            </div>
          </div>
          <div>
            <div class="form-group" style="text-align: -webkit-center;">
              <button type="button" class="btn btn-primary" (click)="saveMedicalInsurance()">{{'SAVE' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <ng-template #uploadForm> -->
  <!-- <form id="file-upload-form" class="uploader mt-2">
    <input #fileUpload id="file-upload" type="file" multiple name="fileUpload" accept="image/*" (change)="getFiles($event)"/>
  
    <label for="file-upload" id="file-drag">
      <img id="file-image" src="#" alt="Preview" class="hidden">
      <div id="start">
        <svg id="upload-icon" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2"></path>
          <path d="m7 9 5-5 5 5"></path>
          <path d="M12 4v12"></path>
        </svg>
        <span class="formats mt-3">{{'UPLOAD.SUPPORTED-FORMATS' | translate}} png, jpeg, etc...</span>
        <div id="notimage" class="hidden">{{'UPLOAD.SELECT-IMG' | translate}}</div>
        <span id="file-upload-btn" class="btn btn-primary" (change)="fileUpload.click()">{{'UPLOAD.SELECT-FILE' | translate}}</span>
      </div>
      <div id="response" class="hidden">
        <div id="messages"></div>
        <progress class="progress" id="file-progress" value="0">
          <span>0</span>%
        </progress>
      </div>
    </label>
  </form> -->
<!-- </ng-template> -->
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { QuestionServiceService } from 'src/app/core/services/question-service.service';

@Component({
  selector: 'app-consultation-questions',
  templateUrl: './consultation-questions.component.html',
  styleUrls: ['./consultation-questions.component.scss']
})
export class ConsultationQuestionsComponent implements OnInit, OnChanges {
  @ViewChild('telehealthConsent', { read: TemplateRef })  concentModal:TemplateRef<any>;

  @Input() currentConsult
  @Input() preconsultQuestions;
  @Input() userProfile;
  @Input() patientProfile;
  @Input() questionsCounter;
  @Input() sequenceCounter;
  @Input() preconsult: boolean = false;
  @Input() component: string = 'Consultation'
  @Output() tempAnswered = false;
  @Output() nextWizardStep = new EventEmitter<number>()
  @Output() prevWizardStep = new EventEmitter<number>()
  @Output() percentageAdd = new EventEmitter<number>()
  @Output() percentageRemove = new EventEmitter<number>()

  currentLang: any = this.translate.store.currentLang

  currentQuestion: any = []
  currentConsultQuestions: any = []
  checkedOptions = []
  sliderValue = ''
  pointsToSum:any[] = []
  medicalHistoryQuestions = []
  diagnosticTemplatesInConsult = []
  stopQuestions;

  systolic: any = ''
  systolicIndex = -1
  diastolic: any = ''
  diastolicIndex = -1

  filteredSurgeries:any[] = []
  filteredConditions:any[] = []
  medicines = []
  loading = false
  editProgress = true
  userCollection = 'users'
  allQuestionsLength = []

  currentWizardStep = this.preconsult ? 4 : 6;
  myPractitioners = [];
  diagnosticTemplates

  highlightSignature = false;

  @ViewChild('sys') sys: NgSelectComponent;
  @ViewChild('dias') dias: NgSelectComponent;
  @ViewChild('focusInput') focusInput: ElementRef;

  openSystolic: any = false;
  openDiastolic: any = false;

  classMapSystolic = {
    'fixedHeight': false
  };

  classMapDiastolic = {
    'fixedHeight': false
  };

  loadingNext: boolean = false;

  constructor(    
    private afs: AngularFirestore,
    public firebaseService: FirebaseService,
    public translate: TranslateService,
    private http: HttpClient,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private questionService: QuestionServiceService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.currentConsult){
      this.ConsultProgress()
      this.currentWizardStep = this.currentConsult.currentWizardStep
    }
  }

  async ngOnInit(): Promise<void> {
    if(this.component == 'Consultation'){
      this.getConsultPrescriptions();
    }
    if(this.component == 'Preconsult'){
      this.userCollection = 'anonymousUsers'
    }

    if(this.userCollection == 'users'){
      this.afs.collection(this.userCollection).doc(this.patientProfile.uid).collection('myPractitioners').valueChanges().subscribe(res => {
        if(res){
          this.myPractitioners = [];
          res.forEach(p => {
            this.afs.firestore.collection('users').doc(p.uid).get().then(res1 => {
              if(res1.data().isAccountDisabled == false && res1.data().status == 'Verified'){
                this.myPractitioners.push(res1.data())
              }
            })
          })
        }
      })
    }

    let stop = false;
    this.afs.collection('consultation').doc(this.currentConsult.ID).collection("Questions").valueChanges().subscribe(async data => { 
      let arr = [];
      this.allQuestionsLength = data.filter(i => i.biologicalGender == undefined || i.biologicalGender.length == 0 || i.biologicalGender.includes(this.patientProfile.biologicalgender)).sort((a, b) => {
        return a.sequence - b.sequence;
      });
      // arr.length =  data.length;
      for(let i of data){
        if((i.biologicalGender == undefined || i.biologicalGender.length == 0 || i.biologicalGender.includes(this.patientProfile.biologicalgender)) && this.preconsultQuestions == null){
          arr.push(i)
        }
        else if((i.biologicalGender == undefined || i.biologicalGender.length == 0 || i.biologicalGender.includes(this.patientProfile.biologicalgender)) && this.preconsultQuestions == true && i.preconsult == true){
          arr.push(i)
        }
        else if((i.biologicalGender == undefined || i.biologicalGender.length == 0 || i.biologicalGender.includes(this.patientProfile.biologicalgender)) && this.preconsultQuestions == false && i.preconsult == false){
          arr.push(i)
        }
      }
      arr.sort((a, b) => {
        return a.sequence - b.sequence;
      });

      this.currentConsultQuestions = arr;
      for(let i = 0; i < this.currentConsultQuestions.length; i++){
        if(await this.currentConsultQuestions[i]?.type == 'BMI' && this.currentConsultQuestions[i]?.answered == false && this.patientProfile.BMI && stop == false &&
          (this.currentConsultQuestions[i]?.answer[0] != Number(this.patientProfile?.BMI?.feet) ||
            this.currentConsultQuestions[i]?.answer[1] != Number(this.patientProfile?.BMI?.inches) ||
            this.currentConsultQuestions[i]?.answer[2] != Number(this.patientProfile?.BMI?.pounds) ||
            this.currentConsultQuestions[i]?.answer[3] != this.patientProfile?.BMI?.BMI)){
              await this.afs.collection('consultation').doc(this.currentConsult.ID).collection("Questions").doc(this.currentConsultQuestions[i].id).update(
              {answer: [Number(this.patientProfile.BMI.feet), Number(this.patientProfile.BMI.inches), Number(this.patientProfile.BMI.pounds), this.patientProfile.BMI.BMI], answered: true}
              )           
            stop = true
            i = this.currentConsultQuestions.length
        }    
        if(this.currentConsultQuestions[i]?.type == 'BMI' && this.currentConsultQuestions[i]?.answered == true){
          this.tempAnswered = true
          this.feetSelected = this.currentConsultQuestions[i]?.answer[0];
          this.inchesSelected = this.currentConsultQuestions[i]?.answer[1];
        }
        if(await this.currentConsultQuestions[i]?.type == 'BP'){
          this.systolic = this.currentConsultQuestions[i].answer[0]
          if(!isNaN(this.systolic) && (this.systolic < 111 || this.systolic > 149)){
            this.systolicIndex = 0  
          }
          if(!isNaN(this.systolic) && (this.systolic >= 111 || this.systolic <= 149)){
            this.systolicIndex = 1
          }
          // this.systolicIndex = this.currentConsultQuestions[i].options[0]['systolic'].indexOf(this.systolic);
          this.diastolic = this.currentConsultQuestions[i].answer[1]
          if(!isNaN(this.diastolic) && (this.diastolic < 70 || this.diastolic > 89)){
            this.diastolicIndex = 0
          }
          if(!isNaN(this.diastolic) && (this.diastolic >= 70 || this.diastolic <= 89)){
            this.diastolicIndex = 1
          }
          // this.diastolicIndex = this.currentConsultQuestions[i].options[1]['diastolic'].indexOf(this.diastolic);
        }    
      }
      if(this.editProgress){
        this.editProgress = false;
        this.afs.firestore.collection(this.userCollection).doc(`${this.patientProfile.uid}`).collection("medicalHistory").get().then(async(data: any) => {
          this.medicalHistoryQuestions = []
          for(let i = 0; i < data.docs.length; i++){
            this.medicalHistoryQuestions.push(data.docs[i].data())
            
            if(i == (data.docs.length - 1)){
              await this.addMedicalHistoryQuestions()
              await this.ConsultProgress();
            }
          }
          if(data.docs.length == 0){
            await this.ConsultProgress()
          }
        });   
      }
    });

    let sub12 = this.afs.collection('consultation').doc(this.currentConsult.ID).collection("stopQuestions").doc('1').valueChanges().subscribe(data => {
      if(data){
        this.stopQuestions = data;
      }
      else {
        this.stopQuestions = {reason: [], suggestions: []}
      }
    });

    let sub13 = this.afs.collection('consultation').doc(this.currentConsult.ID).collection("diagnosticTemplates").valueChanges().subscribe(data => {
      if(data){
        this.diagnosticTemplatesInConsult = data;
      }
    });

    this.afs.collection('diagnosticTemplates').valueChanges().subscribe(data => {
      this.diagnosticTemplates = data;
    })
    this.productBinder();
  }

  allPrescriptions = [];
  labPdfs = [];
  labLogo:string = "assets/images/lab.png";
  prescriptionID = '';
  base64 = '';
  modalRef;
  pdfName = '';
  getConsultPrescriptions(){
    this.afs.firestore.collection('Documents').where('uidPatient', '==', this.patientProfile.uid).get().then(data => {  
     let tempLabs = data.docs
     
     this.afs.collection("prescription", ref => ref.where('uid', '==', this.patientProfile.uid).where('prescription_type', '==', 'Lab').where('valid', '==', true).where('status', '==', 'Signed')).valueChanges().subscribe(async (data:any) => {
       this.allPrescriptions = data ? data : [];
       this.labPdfs = [];
       for(let i = 0; i < this.allPrescriptions.length; i++){
          this.labPdfs.push(tempLabs.filter(lab => lab.data().referenceID == this.allPrescriptions[i].ID))
       }
     }); 
    })
  }

  async pdfSelected(fileInput: any){
    this.loading = true
    ReloadComponent.loader = 'RELOAD.SAVING'

    if (fileInput.target.files && fileInput.target.files[0]) {
      this.pdfName = fileInput.target.files[0].name;
      const reader = new FileReader();
      reader.onload = (e: any) => {
		    let blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
        let url = window.URL.createObjectURL(blob);
        this.pdfUpload(blob);
      };
      reader.readAsDataURL(fileInput.target.files[0]); 
    }
  }

  pdfUpload(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadPDFToFirebase(reader.result);
    };
  }

  async uploadPDFToFirebase(pdf) {
    const randomId = Math.random().toString(36).substr(2, 5);
    this.firebaseService.uploadPdf(pdf, randomId, this.patientProfile.uid)
      .then(pdfURL => {
        this.uploadDocToFirebase(pdf)
        this.getConsultPrescriptions();
        this.loading = false;
      });
  }

  uploadDocToFirebase(pdfUrl){
    let index = this.allPrescriptions.findIndex(p => p.ID == this.prescriptionID)

    let Data = {
      id: this.firebaseService.getDate(),
      pdf: pdfUrl.split("base64,")[1],
      pdfName: this.pdfName,
      uploaded: new Date(),
      referenceID: this.prescriptionID,
      referenceIDModel: 'prescription',
      docFor: 'Lab Results',
      description: 'Lab Results',
      uidPatient: this.allPrescriptions[index].uid,
      uidPractitioner: this.allPrescriptions[index].docId
    }

    this.afs.collection('Documents').doc(Data.id).set(Data);
  }

  async openPrescription(modal){
    this.modalRef = this.modalService.open(modal, {size: 'lg', backdrop: 'static', centered: true, windowClass:'signPad' });
  }

  async saveAnswers($event:any, question:any){
    // if(($event.target.localName == 'input' || question.displayType == 'textImage') && this.userProfile.accountType != 'admin'){
    if(($event.target.localName == 'input' || question.displayType == 'textImage')){
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      var data = {
        answer: $event.target.id,
        points: '',
        answered: true
      };

      if(question.type == 'slider'){
        data.answer = $event.target.value
      }

      if(question.displayType == 'textImage'){
        data.answer = question.options[Number($event.target.value)]
        data.answer.image = await this.convertImageToBase64(question.options[Number($event.target.value)].image)
      }

      if(question.displayType == 'image'){
        let index = question.options.indexOf($event.target.id);
        data.answer = {text: question.options[index], image: ''}
        data.answer.image = await this.convertImageToBase64(question.options[index])
      }

      if(data.answer.length == 0 || data.answer === null){
        data.answered = false;
      }

      let i = question.options ? question.options.indexOf($event.target.id) : -1;
      if(i !== -1 && question.optionsPoints){
        data.points = question.optionsPoints[i];
      }

      if(question.type == "Multiple"){
        if($event.target.checked){
          this.checkedOptions.push($event.target.id);
          this.pointsToSum.push(question.optionsPoints ? question?.optionsPoints[i] : 0);
          data.points = this.pointsToSum.reduce(reducer);

          if(this.checkedOptions.includes(question.options[question.options.length-1])){
            this.checkedOptions.splice(question.options[question.options.length-1], 1); 
          }
          data.answer = this.checkedOptions;

          if(question.options.indexOf($event.target.id) == question.options.length-1) {
            this.checkedOptions = [];
            this.pointsToSum = []
            this.checkedOptions.push($event.target.id);
            this.pointsToSum.push(question.optionsPoints[i]);
            data.points = this.pointsToSum.reduce(reducer);
            data.answer = this.checkedOptions;
          }
        }
        else {
          let index = this.checkedOptions.indexOf($event.target.id);
          let i = this.pointsToSum.indexOf($event.target.id);
          if(index > -1){
            this.checkedOptions.splice(index, 1);
            this.pointsToSum.splice(i, 1);
            data.answer = this.checkedOptions;
            if(data.answer.length == 0) {
              data.answered = false
              this.pointsToSum = [0]
              data.points = this.pointsToSum[0]
            } 
            if(this.pointsToSum.length != 0){
              data.points = this.pointsToSum.reduce(reducer); 
            }
            else {
              this.pointsToSum = [0]
              data.points = this.pointsToSum[0]
            }

            if(question.posibleDiagnostics){
              let remove = question.options.indexOf($event.target.id);
              let test = question?.posibleDiagnostics[remove]
    
              if(test != null){
                if(typeof(test) == 'string'){
                  test = [test]
                }
                test.forEach((item) => {
                  let data = this.diagnosticTemplates.filter((templates) => templates.id === item)
                  let tempTemplate = data[0]
  
                  if(this.diagnosticTemplatesInConsult.find((item) => item.id === tempTemplate.id)) {
                    let index = this.diagnosticTemplatesInConsult.indexOf(tempTemplate);
                    this.afs.collection("consultation").doc(this.currentConsult.ID).collection("diagnosticTemplates").doc(tempTemplate.id).update({timesAdded: this.diagnosticTemplatesInConsult[index].timesAdded - 1});
                  }
                })
              }
            }
          }
        }
      }

      if(question.type == 'SearchMedicines' || question.type == 'SearchConditions' || question.type == 'SearchSurgeries'){
        this.pointsToSum = [0]
        data.points = this.pointsToSum[0]
        data.answer = []
        let option = {optionAnswer: $event.target.id, rxcui: ''}
        data = Object.assign(data, option)
      }

      if(question.type == 'SelectionWithOptions' || question.type == 'MultipleSelectionWithOptions'){
        this.pointsToSum = [0]
        data.points = this.pointsToSum[0];
        data.answered = false;
        let option = {optionAnswer: ''}
        data = Object.assign(data, option)
    
        for(let index = 0; index < question.options.length; index++){   
          if(question.options[index].options == data.answer){
            if(question.options[index]?.subOptions == undefined){
              data.answered = true;
              data.points = question.optionsPoints[index] || 0;
              this.checkedOptions = []
            }
          }
        }

        // if($event.target.id == question.options[question.options.length - 1].options){
        //   data.answered = true;
        //   this.checkedOptions = []
        // }
      }

      if(question?.lastQuestion == true){
        let date = {dateCertified: new Date(), signedBy: this.userProfile.firstName + ' ' + this.userProfile.lastName1, signRef: Math.floor(Math.random() * 10000000) + 1}
        data = Object.assign(data, date)

        // this.modalService.open(this.concentModal, {size: 'lg', backdrop: 'static', centered: true, windowClass:'signPad' });
      }
      

      if(question.explain != undefined && !this.checkedOptions?.includes('<en>Other</en><es>Otros</es>') && !this.checkedOptions?.includes('<en>Other</en><es>Otro</es>')){
        data = Object.assign(data, {explain: ''})
      }
      if(question.explain != undefined && (this.checkedOptions?.includes('<en>Other</en><es>Otros</es>') || this.checkedOptions?.includes('<en>Other</en><es>Otro</es>'))){
        this.checkedOptions.sort((a) => {
          if (a === '<en>Other</en><es>Otros</es>') {
            return 1;
          }
          else {
            return -1;
          }
        });
      }

      if(question.type == 'SelectionExplain'){
        data = Object.assign(data, {explain: ''})
      }

      if(question.medicalHistory && question.type != 'BMI'){
        let newQuestion: any = {
          medicalHistoryId: question.medicalHistoryId,
          question: question.question,
          answerHistory: []
        }

        if(data.answer.length == 0){
          newQuestion.answerHistory = [{answer: data.answer, Date: new Date(), optionAnswer: $event.target.id, consultId: this.currentConsult.ID, consultType: this.currentConsult.Type}]
        }
        else {
          newQuestion.answerHistory = [{answer: data.answer, Date: new Date(), consultId: this.currentConsult.ID, consultType: this.currentConsult.Type}]
        }

        if(question.explain != undefined){
          newQuestion.answerHistory = [Object.assign(newQuestion.answerHistory[0], {explain: question.explain})]
        }

        for(let i in this.medicalHistoryQuestions){
          if(this.medicalHistoryQuestions[i].medicalHistoryId == question.medicalHistoryId && this.medicalHistoryQuestions[i].answerHistory){
            this.medicalHistoryQuestions[i].answerHistory.push(newQuestion.answerHistory[0])
            newQuestion.answerHistory = this.medicalHistoryQuestions[i].answerHistory
          }
        }
        this.afs.collection(this.userCollection).doc(this.patientProfile.uid).collection("medicalHistory").doc(question.medicalHistoryId).set(newQuestion);
      }

      if(question.stopQuestion) {
        if(Number(data.points) > 0){
          if(!this.stopQuestions.reason?.includes(question.reason)){
            this.stopQuestions.reason?.push(question.reason)
            this.stopQuestions.suggestions?.push({suggestions: question.suggestions})
          }
        } else if(this.stopQuestions.reason.includes(question.reason)){
          this.stopQuestions.reason.splice(this.stopQuestions.reason.indexOf(question.reason), 1)
          this.stopQuestions.suggestions.splice(this.stopQuestions.suggestions.indexOf(question.reason), 1)
        }

        this.afs.collection("consultation").doc(this.currentConsult.ID).collection("stopQuestions").doc('1').set(this.stopQuestions);
      }

      if(question.posibleDiagnostics) {
        if(this.checkedOptions && this.checkedOptions.length != 0){
          for(let j = 0; j < this.checkedOptions.length; j++){
            let index = question.options.indexOf(this.checkedOptions[j]);
            let test = question.posibleDiagnostics[index]

            if(test != null){
              if(typeof(test) == 'string'){
                test = [test]
              }
              test.forEach((item) => {
                let data1 = this.diagnosticTemplates.filter((templates) => templates.id === item)
                let tempTemplate = data1[0]

                if(!this.diagnosticTemplatesInConsult.find((item) => item.code === tempTemplate.code)) {
                  this.afs.collection("consultation").doc(this.currentConsult.ID).collection("diagnosticTemplates").doc(tempTemplate.id).set(Object.assign(tempTemplate, {timesAdded: 1}));
                }
                else {
                  let index = this.diagnosticTemplatesInConsult.findIndex(t => t.code == tempTemplate.code)
                  this.afs.collection("consultation").doc(this.currentConsult.ID).collection("diagnosticTemplates").doc(tempTemplate.id).update({timesAdded: this.diagnosticTemplatesInConsult[index].timesAdded + 1});
                }
              })
            }
          }
        }
        else {
          let test = question.posibleDiagnostics[i]

          if(this.currentQuestion.answer != data.answer){
            if(test != null){
              if(typeof(test) == 'string'){
                test = [test]
              }
              test.forEach((item) => {
                let data1 = this.diagnosticTemplates.filter((templates) => templates.id === item)
                let tempTemplate = data1[0]
              
                if(!this.diagnosticTemplatesInConsult.find((item) => item.code === tempTemplate.code)){
                  this.afs.collection("consultation").doc(this.currentConsult.ID).collection("diagnosticTemplates").doc(tempTemplate.id).set(Object.assign(tempTemplate, {timesAdded: 1}));
                }
                else {
                  let index = this.diagnosticTemplatesInConsult.findIndex(t => t.code == tempTemplate.code)
                  this.afs.collection("consultation").doc(this.currentConsult.ID).collection("diagnosticTemplates").doc(tempTemplate.id).update({timesAdded: this.diagnosticTemplatesInConsult[index].timesAdded + 1});
                }
              })
            }
            
            if(this.currentQuestion.answer != ''){
              let remove = this.currentQuestion.options.indexOf(this.currentQuestion.answer)
              let test = this.currentQuestion.posibleDiagnostics[remove]

              if(test != null){
                if(typeof(test) == 'string'){
                  test = [test]
                }
                test.forEach((item) => {
                  let data1 = this.diagnosticTemplates.filter((templates) => templates.id === item)
                  let tempTemplate = data1[0]

                  if(this.diagnosticTemplatesInConsult.find((item) => item.code === tempTemplate.code)) {
                    let index = this.diagnosticTemplatesInConsult.findIndex(t => t.code == tempTemplate.code)
                    this.afs.collection("consultation").doc(this.currentConsult.ID).collection("diagnosticTemplates").doc(tempTemplate.id).update({timesAdded: this.diagnosticTemplatesInConsult[index].timesAdded - 1});
                  }
                })
              }
            }
          }
        }
      }
      if(question.diagnosticsTestId && question.diagnosticsTestId.length > 0){
        let diagnosticPoints = 0
        let i = question.options.indexOf($event.target.id);
        if(i !== -1 && question.diagnosticsTestsOptionsPoints){
          diagnosticPoints = question.diagnosticsTestsOptionsPoints[i];
        }  
        this.firebaseService.updateAnswers(question.id, this.currentConsult.ID, {diagnosticsTestsPoints: diagnosticPoints}, this.userProfile).then(
          res => {
            this.currentQuestion = Object.assign(question, data)
          }
        )  
      }
      
      this.firebaseService.updateAnswers(question.id, this.currentConsult.ID, data, this.userProfile).then(
        res => {
          if(question.type == 'Selection'){
            this.doProgress(); 

            if(this.preconsultQuestions && this.questionsCounter !== this.currentConsultQuestions.length - 1){
              this.nextQuestion(this.questionsCounter + 1, true);
            }
            else if(this.preconsultQuestions && this.questionsCounter === this.currentConsultQuestions.length - 1){ //added the else because it was validating after the changes made in the if before 
              this.nextWizardStep.emit(this.currentWizardStep + 1); 
              this.checkNextQuestion();
            } else if(!this.preconsultQuestions && this.questionsCounter !== this.allQuestionsLength.length - 1){
              this.nextQuestion(this.questionsCounter + 1, true);
            }
            else if(!this.preconsultQuestions && this.questionsCounter === this.allQuestionsLength.length - 1){ //added the else because it was validating after the changes made in the if before 
              this.nextWizardStep.emit(this.currentWizardStep + 1); 
              this.checkNextQuestion();
            }
          }
          this.currentQuestion = Object.assign(question, data)
          this.highlightSignature = false;
        }
      )
    }
  }

  saveSubOptions($event:any, question:any){
    // if(this.userProfile.accountType != 'admin'){ //intervines admins from changing questionaire
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      var data = {
        optionAnswer: $event.target.id,
        points: '',
        answered: true
      };
  
      this.pointsToSum = []
  
      if(question.type == 'SelectionWithOptions'){     
        for(let index = 0; index < question.options.length; index++){   
          if(question.options[index].options == question.answer){
            let i = question.options[index].subOptions.indexOf($event.target.id);
            if(i !== -1 && question.subOptionsPoints){
              data.points = question.subOptionsPoints[index].subOptionsPoints[i];
            }
            else {
              this.pointsToSum = [0]
              data.points = this.pointsToSum[0]
            }
  
            if(question.posibleDiagnostics){
  
              let test = question.posibleDiagnostics[index].posibleDiagnostics[i]
              if(this.currentQuestion.optionAnswer != data.optionAnswer){
                if(test != null){
                  if(typeof(test) == 'string'){
                    test = [test]
                  }
                  test.forEach((item) => {
                    let data1 = this.diagnosticTemplates.filter((templates) => templates.id === item)
                    let tempTemplate = data1[0]
                    
                    if(!this.diagnosticTemplatesInConsult.find((item) => item.code === tempTemplate.code)){
                      this.afs.collection("consultation").doc(this.currentConsult.ID).collection("diagnosticTemplates").doc(tempTemplate.id).set(Object.assign(tempTemplate, {timesAdded: 1}));
                    }
                    else {
                      let index = this.diagnosticTemplatesInConsult.findIndex(t => t.code == tempTemplate.code);
                      this.afs.collection("consultation").doc(this.currentConsult.ID).collection("diagnosticTemplates").doc(tempTemplate.id).update({timesAdded: this.diagnosticTemplatesInConsult[index]?.timesAdded + 1});
                    }
                  })
                }
                
                if(this.currentQuestion.optionAnswer != ''){
                  let remove = this.currentQuestion.options[index].subOptions.indexOf(this.currentQuestion.optionAnswer)
                  let test = this.currentQuestion.posibleDiagnostics[index].posibleDiagnostics[remove]
                  if(test != null){
                    if(typeof(test) == 'string'){
                      test = [test]
                    }
                    test.forEach((item) => {
                      let data1 = this.diagnosticTemplates.filter((templates) => templates.id === item)
                      let tempTemplate = data1[0]
  
                      if(this.diagnosticTemplatesInConsult.find((item) => item.code === tempTemplate.code)) {
                        let index = this.diagnosticTemplatesInConsult.findIndex(t => t.code == tempTemplate.code)
                        this.afs.collection("consultation").doc(this.currentConsult.ID).collection("diagnosticTemplates").doc(tempTemplate.id).update({timesAdded: this.diagnosticTemplatesInConsult[index].timesAdded - 1});
                      }
                    })
                  }
                }
              }
            }
          }
        }
      }
      else {
        for(let index = 0; index < question.options.length; index++){   
          if(question.options[index].options == question.answer){
            let i = question.options[index].subOptions.indexOf($event.target.id);
            if(i !== -1 && question.subOptionsPoints){
              data.points = question.subOptionsPoints[index].subOptionsPoints[i];
            }
  
            if(question.optionAnswer.includes($event.target.id)){
              $event.target.checked = false;
            }
            else {
              $event.target.checked = true;
            }
            if($event.target.checked){
              this.checkedOptions.push($event.target.id);
              this.pointsToSum.push(question.subOptionsPoints ? question.subOptionsPoints[index].subOptionsPoints[i] : 0);
              data.points = this.pointsToSum.reduce(reducer);
              data.optionAnswer = this.checkedOptions;
            }
            else if($event.target.checked != undefined) {
              let index = this.checkedOptions.indexOf($event.target.id);
              let i = this.pointsToSum.indexOf($event.target.id);
              if(index > -1){
                this.checkedOptions.splice(index, 1);
                this.pointsToSum.splice(i, 1);
                data.optionAnswer = this.checkedOptions;
                if(data.optionAnswer.length == 0) {
                  data.answered = false
                  this.pointsToSum = [0]
                  data.points = this.pointsToSum[0]
                } 
                if(this.pointsToSum.length != 0){
                  data.points = this.pointsToSum.reduce(reducer); 
                }
                else {
                  // data.answered = false
                  this.pointsToSum = [0]
                  data.points = this.pointsToSum[0]
                }
              }
            }
          }
        }
      }
    
      this.firebaseService.updateAnswers(question.id, this.currentConsult.ID, data, this.userProfile).then(
        res => {
          this.currentQuestion = Object.assign(question, data)
        }
      )
    // }
  }

  //explain
  saveExplanation($event, question){
    // if(this.userProfile.accountType != 'admin'){
      let data = {
        explain: $event.target.value,
        answered: true
      }
  
      if($event.target.value == ''){
        data.answered = false
      }
      // for (let i = 0; i < $event.length; i++) {
          // arr.push($event[i]);
          // data.explain = arr;
          this.firebaseService.updateAnswers(question.id, this.currentConsult.ID, data, this.userProfile).then(
          res => {
            this.currentQuestion = Object.assign(question, data)
          }
        ) 
      // }
    // }
  }

  setSubOptions(option, question){ 
    if(question.optionAnswer?.includes(option))
      return true;
    else
      return false;
  }

  handleQuestions(answers, question){
    let questionsToAdd = [];
    for(let i = 0; i < answers.length; i++){
      let answer = answers[i];
      let answerIndex = question.options.indexOf(answer);

      if(answerIndex !== -1){
        questionsToAdd = questionsToAdd.concat(question.questionsToAdd[answerIndex].template);
      }

      if(i === answers.length - 1){
        this.questionService.getTemplateQuestions(questionsToAdd, this.allQuestionsLength.length).then((res:any) => {
          this.afs.collection('consultation').doc(this.currentConsult.ID).update({totalQuestions: res.length + this.allQuestionsLength.length})
          this.firebaseService.addQuestionsToUser(res, this.currentConsult.ID);
        })
      }
    }
  }

  async convertImageToBase64(url){
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        resolve(base64data);
      }
    }); 
  }

   //this function helps to keep focus on input when updating
  trackByFn(index, item) {
    return index;  
  }

  onConditionSelected($event:any, question:any){
    // console.log($event.target.value)
    // if(this.userProfile.accountType != 'admin'){

    let data = {
      answer: [],
      answered: false,
    }

    let obj = {
      points: 0
    }
    let arr = [];

    if(question.type == "BP"){
      this.openDiastolic = false;
      this.openSystolic = false;
      // if($event.target.name == "systolic"){
      //   this.systolic = $event.target.value;
      //   this.systolicIndex = question.options[0][$event.target.name].indexOf($event.target.value);
      // }else{
      //   this.diastolic = $event.target.value;
      //   this.diastolicIndex = question.options[1][$event.target.name].indexOf($event.target.value);
      // }
      if(!isNaN(this.systolic) && (this.systolic < 111 || this.systolic > 149)){
        this.systolicIndex = 0  
      }
      if(!isNaN(this.systolic) && (this.systolic >= 111 || this.systolic <= 149)){
        this.systolicIndex = 1
      }
      if(!isNaN(this.diastolic) && (this.diastolic < 70 || this.diastolic > 89)){
        this.diastolicIndex = 0
      }
      if(!isNaN(this.diastolic) && (this.diastolic >= 70 || this.diastolic <= 89)){
        this.diastolicIndex = 1
      }
      if(this.diastolic !== undefined && this.systolic !== undefined && this.diastolic !== 0 && this.systolic !== 0 && this.diastolic !== '' && this.systolic !== ''){
        data.answer.push(this.systolic);
        data.answer.push(this.diastolic);
        data.answered = true;
        if(this.systolicIndex !== -1 && this.diastolicIndex !== -1 && question.optionsPoints){
          obj.points = question.optionsPoints[this.systolicIndex] + question.optionsPoints[this.diastolicIndex];
          data = Object.assign(data, obj);
        }
        this.firebaseService.updateAnswers(question.id, this.currentConsult.ID, data, this.userProfile).then(
          res => {
            this.currentQuestion = Object.assign(question, data)
          }
        )
      }     
    }

    if(question.type == "BMI"){
      let element1 = this.feetSelected;
      let element2 = this.inchesSelected;
      let element3 = <HTMLInputElement> document.getElementById("pounds");
      
      if(element1 != '' && (element2 != '' || Number(element2) == 0)  && element3.value != '' && Number(element3.value) != 0){  
        let BMICalc: any = (Number(element3.value) * 703)/(((Number(element1) * 12) + Number(element2)) * ((Number(element1) * 12) + Number(element2)))
            BMICalc = Number(BMICalc.toFixed(1))

        if(BMICalc < 18.5){
          BMICalc = '<en>' + BMICalc + ' - Underweight</en><es>' + BMICalc + ' - Bajo Peso</es>'
        }
        if(BMICalc >= 18.5 && BMICalc <= 24.9){
          BMICalc = '<en>' + BMICalc + ' - Normal Weight</en><es>' + BMICalc + ' - Peso Normal</es>'
        }
        if(BMICalc >= 25 && BMICalc <= 29.9 ){
          BMICalc = '<en>' + BMICalc + ' - Overweight</en><es>' + BMICalc + ' - Sobre Peso</es>'
        }
        if(BMICalc >= 30){
          BMICalc = '<en>' + BMICalc + ' - Obese</en><es>' + BMICalc + ' - Obeso</es>'
        }
        if(BMICalc >= 35){
          obj.points = 10
          data = Object.assign(data, obj);
        }
        if(BMICalc < 35){
          obj.points = 0
          data = Object.assign(data, obj);
        }
        
        data.answer.push(Number(element1));
        data.answer.push(Number(element2));
        data.answer.push(Number(element3.value));
        data.answer.push(BMICalc)
        data.answered = true;

        if(question.medicalHistory){
          let newQuestion = {
            medicalHistoryId: question.medicalHistoryId,
            question: question.question,
            answerHistory: [{answer: data.answer, Date: new Date(), consultId: this.currentConsult.ID, consultType: this.currentConsult.Type}]
          }
          for(let i in this.medicalHistoryQuestions){
            if(this.medicalHistoryQuestions[i].medicalHistoryId == question.medicalHistoryId && this.medicalHistoryQuestions[i].answerHistory){
              this.medicalHistoryQuestions[i].answerHistory.push({answer: data.answer, Date: new Date(), consultId: this.currentConsult.ID, consultType: this.currentConsult.Type})
              newQuestion.answerHistory = this.medicalHistoryQuestions[i].answerHistory
            }
          }
          this.afs.collection(this.userCollection).doc(this.patientProfile.uid).collection("medicalHistory").doc(question.medicalHistoryId).set(newQuestion);
        }

        this.firebaseService.updateAnswers(question.id, this.currentConsult.ID, data, this.userProfile).then(
          res => { 
            this.currentQuestion = Object.assign(question, data)
            let BMI = { feet: Number(element1), inches: Number(element2),pounds: Number(element3.value), BMI: BMICalc}
            this.afs.collection(this.userCollection).doc(this.patientProfile.uid).update({BMI: BMI})
          }
        )    
      }     
      else {
        data.answered = false;
      }
    }

    if(question.type === "SearchConditions"){
      for (let i = 0; i < this.filteredConditions.length; i++) {
        if($event[i] !== undefined){
          if($event[i] instanceof Array) {
            arr.push($event[i][0]);
          }
          else {
            arr.push($event[i])
          }
          data = Object.assign(data, {optionAnswer: ''})
          data.answer = arr;
          data.answered = true;
          this.firebaseService.updateAnswers(question.id, this.currentConsult.ID, data, this.userProfile).then(
            res => {
              this.currentQuestion = Object.assign(question, data)
            }
          )
        }
      }
      if($event.length == 0){
        data.answered = false;
        data.answer = []
        this.firebaseService.updateAnswers(question.id, this.currentConsult.ID, data, this.userProfile).then(
          res => {
            this.currentQuestion = Object.assign(question, data)
        })
      }
    }
    if(question.type === "SearchSurgeries"){
      for (let i = 0; i < this.filteredSurgeries.length; i++) {
        if($event[i] !== undefined){
          if($event[i] instanceof Array) {
            arr.push($event[i][0]);
          }
          else {
            arr.push($event[i])
          }
          data = Object.assign(data, {optionAnswer: ''})
          data.answer = arr;
          data.answered = true;
          this.firebaseService.updateAnswers(question.id, this.currentConsult.ID, data, this.userProfile).then(
            res => {
              this.currentQuestion = Object.assign(question, data)
            }
          )
        }
      }
      if($event.length == 0){
        data.answered = false;
        data.answer = []
        this.firebaseService.updateAnswers(question.id, this.currentConsult.ID, data, this.userProfile).then(
          res => {
            this.currentQuestion = Object.assign(question, data)
        })
      }
    }

    if(question.medicalHistory && question.type != 'BMI'){
      let newQuestion = {
        medicalHistoryId: question.medicalHistoryId,
        question: question.question,
        answerHistory: []
      }
      if(data.answer.length == 0){
        newQuestion.answerHistory = [{answer: data.answer, Date: new Date(), optionAnswer: $event.target.id, consultId: this.currentConsult.ID, consultType: this.currentConsult.Type}]
      }
      else {
        newQuestion.answerHistory = [{answer: data.answer, Date: new Date(), consultId: this.currentConsult.ID, consultType: this.currentConsult.Type}]
      }
      
      if(question.explain != undefined){
        newQuestion.answerHistory = [{answer: question.answer, Date: new Date(), explain: $event.target.value, consultId: this.currentConsult.ID, consultType: this.currentConsult.Type}]
      }
      for(let i in this.medicalHistoryQuestions){
        if(this.medicalHistoryQuestions[i].medicalHistoryId == question.medicalHistoryId && this.medicalHistoryQuestions[i].answerHistory){
          this.medicalHistoryQuestions[i].answerHistory.push(newQuestion.answerHistory[0])
          newQuestion.answerHistory = this.medicalHistoryQuestions[i].answerHistory
        }
      }
      this.afs.collection(this.userCollection).doc(this.patientProfile.uid).collection("medicalHistory").doc(question.medicalHistoryId).set(newQuestion);
    }
    // }
  }

  tempAnswer(){
    let element1 = this.feetSelected;
    let element2 = this.inchesSelected;
    let element3 = <HTMLInputElement> document.getElementById("pounds");
    if(element1 != '' && element2 != '' && element3.value != '' && Number(element1) != 0 && Number(element3.value) != 0){
      this.tempAnswered = true
    } else {
      this.tempAnswered = false
    }
  }

  saveMeds($event:any, question:any){
    // if(this.userProfile.accountType != 'admin'){
      let data = {
        answer: [],
        answered: false,
        rxcui: [],
        optionAnswer: ''
      }
  
      let arr = [];
      let rxcui = [];
  
      if(question.type === "SearchMedicines"){
        for (let i = 0; i < $event.length; i++) {
          if($event[i] !== undefined){
            if($event[i].name != undefined){
              arr.push($event[i].name);
              rxcui.push($event[i].rxcui);
            }
            else {
              arr.push($event[i])
            }
            data.answer = arr;
            data.rxcui = rxcui;
            
            
            data.answered = true;
          }
          else{
            let index = arr.indexOf($event[i].name);
            if(index > -1){
              arr.splice(index, 1);
              data.answer = arr;
              data.rxcui = rxcui;
            }
          }
        }
      }
  
      if(question.medicalHistory){
        let newQuestion = {
          medicalHistoryId: question.medicalHistoryId,
          question: question.question,
          answerHistory: [{answer: data.answer, rxcui: data.rxcui, Date: new Date(), optionAnswer: '', consultId: this.currentConsult.ID, consultType: this.currentConsult.Type}]
        }
        for(let i in this.medicalHistoryQuestions){
          if(this.medicalHistoryQuestions[i].medicalHistoryId == question.medicalHistoryId && this.medicalHistoryQuestions[i].answerHistory){
            this.medicalHistoryQuestions[i].answerHistory.push(newQuestion.answerHistory[0])
            newQuestion.answerHistory = this.medicalHistoryQuestions[i].answerHistory
          }
        }
        this.afs.collection(this.userCollection).doc(this.patientProfile.uid).collection("medicalHistory").doc(question.medicalHistoryId).set(newQuestion);
      }
      
      this.firebaseService.updateAnswers(question.id, this.currentConsult.ID, data, this.userProfile).then(
        res => {
          this.currentQuestion = Object.assign(question, data)
        }
      )
    // }
  }

  Rx_getConditions($event){
    this.loading = true;
    this.filteredConditions = []
    this.http.get<any>("https://clinicaltables.nlm.nih.gov/api/conditions/v3/search?terms="+$event.term+'&df=primary_name').subscribe(response =>{
    for(let i = 0; i < 15; i++){
      // console.log("response",response[3][0]);
      // console.log("response",response);
      this.filteredConditions = response[3];
      this.loading = false;
    }
    }, error => {
      // console.log(error);
    });
  } 
  
  getNDC($event){
    this.loading = true;
    this.medicines = [];
    let arr:any[] = [];
    let rxcui = [];
    let filteredRxcui = [];
    let uniqueMeds = [];
    if($event.target.value !== undefined && $event.target.value !== ""){
      this.http.get<any>("https://rxnav.nlm.nih.gov/REST/approximateTerm?term="+$event.target.value+"&maxEntries=10").subscribe(response => {
        // console.error(response.approximateGroup.candidate.length)
        for(let i = 0; i < response.approximateGroup.candidate?.length; i++){
          // console.log("Possible candidates rxcui's",response.approximateGroup.candidate[i].rxcui)
          //Remove duplicates rxcui
          rxcui.push(response.approximateGroup.candidate[i].rxcui);
          filteredRxcui = rxcui.filter(this.onlyUnique);
          filteredRxcui = rxcui.filter(value => value !== undefined);
          // console.log("Filtered Rxcui's =>", filteredRxcui);
          // this.http.get<any>("https://rxnav.nlm.nih.gov/REST/rxcui/"+filteredRxcui[i]).subscribe(res =>{
          if(filteredRxcui[i] !== undefined || filteredRxcui[i] !== ""){
            // console.error("https://rxnav.nlm.nih.gov/REST/rxcui/"+filteredRxcui[i]);
            this.http.get<any>("https://rxnav.nlm.nih.gov/REST/rxcui/"+filteredRxcui[i]).subscribe(res => {
              // console.log("Rxcui's matches names", res);
              if(res.idGroup.name){
                res.idGroup = Object.assign(res.idGroup, {rxcui: filteredRxcui[i]});
                arr.push(res.idGroup);
                // console.error("arr",arr);
                arr.forEach(item => {
                  var i = this.medicines.findIndex(x => x.name == item.name);
                  if(i <= -1){
                    this.medicines.push(item);
                  }
                });
                this.loading = false;
              }
              // console.log('arr',arr);
              // console.log('medicines', this.medicines);
            });
          }
        }
        if(!response.approximateGroup.candidate){
          this.loading = false;
        }
      });
    }
  } 

  onlyUnique(value, index, self){
    return self.indexOf(value) === index;
  }

  setChecked(option, question){ 
    return question.answer.includes(option);
  }

  searchSurgeries($event){
    this.loading = true;
    this.filteredSurgeries = []
    this.http.get<any>("https://clinicaltables.nlm.nih.gov/api/procedures/v3/search?terms="+$event.term).subscribe(response =>{
      for(let i = 0; i < 15; i++){
          this.filteredSurgeries = response[3];
          this.loading = false;
        }
    }, error => {
      // console.log(error);
    });
  }

  showButtons = false;
  ConsultProgress(){
    let timer = setInterval(() => {
      if(this.allQuestionsLength.length > 0 && this.currentConsult){
        clearInterval(timer)
        for(let i = 0; i < this.allQuestionsLength.length; i++){
          if(this.allQuestionsLength[i].sequence == this.currentConsult.currentQuestion){
            this.sequenceCounter = this.currentConsult.currentQuestion
            this.questionsCounter = i
            this.currentQuestion = this.allQuestionsLength[i];
            setTimeout(res => {
              this.showButtons = true;
            },400)
    
            if(this.currentQuestion.answer != '' && typeof(this.currentQuestion.answer) != 'string' ){
              this.checkedOptions = this.currentQuestion.answer
            }
            else if(this.currentQuestion.optionAnswer != '' && typeof(this.currentQuestion.optionAnswer) != 'string'){
              this.checkedOptions = this.currentQuestion.optionAnswer
            }
            if(this.currentQuestion.answer == false){
              this.checkedOptions = []
            }
    
            this.sliderValue = ''
            if(this.currentQuestion.type == 'slider') {
              // this.sliderValue = Math.round(this.currentQuestion.answer || (this.currentQuestion.range[1] + this.currentQuestion.range[0])/2).toString()
            }
          }
        }
      }
    }, 500)
  }

  addMedicalHistoryQuestions(){
    for(let i in this.currentConsultQuestions){
      if(this.currentConsultQuestions[i].medicalHistory && this.medicalHistoryQuestions && this.currentConsultQuestions[i].type != 'BMI'){
        for(let j in this.medicalHistoryQuestions){
          if(this.currentConsultQuestions[i].medicalHistoryId == this.medicalHistoryQuestions[j].medicalHistoryId && this.medicalHistoryQuestions[j].answerHistory.length > 0){
            this.currentConsultQuestions[i].answer = this.medicalHistoryQuestions[j].answerHistory[this.medicalHistoryQuestions[j].answerHistory.length - 1].answer
            this.currentConsultQuestions[i].answered = true
            if(this.medicalHistoryQuestions[j].answerHistory[this.medicalHistoryQuestions[j].answerHistory.length - 1].answer.length == 0 && this.medicalHistoryQuestions[j].answerHistory[this.medicalHistoryQuestions[j].answerHistory.length - 1].optionAnswer == ''){
              this.currentConsultQuestions[i].answered = false
            }
            if(this.medicalHistoryQuestions[j].answerHistory[this.medicalHistoryQuestions[j].answerHistory.length - 1].optionAnswer != '' && this.medicalHistoryQuestions[j].answerHistory[this.medicalHistoryQuestions[j].answerHistory.length - 1].optionAnswer != undefined){
              let obj = {optionAnswer: this.medicalHistoryQuestions[j].answerHistory[this.medicalHistoryQuestions[j].answerHistory.length - 1].optionAnswer}
              this.currentConsultQuestions[i] = Object.assign(this.currentConsultQuestions[i], obj)
            }

            if(this.medicalHistoryQuestions[j].answerHistory[this.medicalHistoryQuestions[j].answerHistory.length - 1].explain){
              this.currentConsultQuestions[i].explain = this.medicalHistoryQuestions[j].answerHistory[this.medicalHistoryQuestions[j].answerHistory.length - 1].explain
            }
            
            if(this.currentConsultQuestions[i]?.optionsPoints?.includes(100)){
              const reducer = (accumulator, currentValue) => accumulator + currentValue;

              for(let k = 0; k < this.currentConsultQuestions[i].answer.length; k++){
                let index
                for(let l = 0; l < this.currentConsultQuestions[i].options.length; l++){
                  if(this.currentConsultQuestions[i].options[l] == this.currentConsultQuestions[i].answer[k]){
                    index = this.currentConsultQuestions[i].options.indexOf(this.currentConsultQuestions[i].options[l]);

                    if(index !== -1){
                      this.currentConsultQuestions[i].points = this.currentConsultQuestions[i].optionsPoints[index];
                    }
              
                    this.pointsToSum.push(this.currentConsultQuestions[i].optionsPoints[index]);
                    this.currentConsultQuestions[i].points = this.pointsToSum.reduce(reducer);
                
                    if(index == this.currentConsultQuestions[i].options.length-1) {
                      this.pointsToSum.push(this.currentConsultQuestions[i].optionsPoints[index]);
                      this.currentConsultQuestions[i].points = this.pointsToSum.reduce(reducer);
                    }
                    break;
                  }
                }
              }  
              this.pointsToSum = [];
            }

            this.afs.collection('consultation').doc(this.currentConsult.ID).collection('Questions').doc(this.currentConsultQuestions[i].id).update(this.currentConsultQuestions[i])
          }
        }
      }
    }
  }

   handleNextButtonState(){
    let index = this.questionsCounter;
    if(this.allQuestionsLength !== undefined && this.allQuestionsLength.length !== 0 && index < this.allQuestionsLength.length){
      if(this.allQuestionsLength[index].sequence === this.sequenceCounter){ 
        if(this.allQuestionsLength[index].required == true){
          if(this.allQuestionsLength[index].type == 'SelectionExplain' && this.allQuestionsLength[index].answer == this.allQuestionsLength[index].options[this.allQuestionsLength[index].options.length -1]
              && this.allQuestionsLength[index].explain != '' && this.allQuestionsLength[index].answered == true){
            this.loadingNext = false;
            return false;
          }
          else if(this.allQuestionsLength[index].type == 'Multiple' && this.allQuestionsLength[index].explain != undefined){
            if((this.setChecked(this.allQuestionsLength[index].options[this.allQuestionsLength[index].options.length -2], this.allQuestionsLength[index]) 
            && this.allQuestionsLength[index].explain != '') 
              && this.allQuestionsLength[index].answered == true){
              this.loadingNext = false;
              return false;
            }
            else if(!this.setChecked(this.allQuestionsLength[index].options[this.allQuestionsLength[index].options.length -2], this.allQuestionsLength[index]) 
            && this.allQuestionsLength[index].answered == true){
              this.loadingNext = false;
              return false;
            }
            else{
              return true;
            }
          }                 
          else if(this.allQuestionsLength[index].type == 'SelectionExplain' && this.allQuestionsLength[index].answer != this.allQuestionsLength[index].options[this.allQuestionsLength[index].options.length -1]
                    && this.allQuestionsLength[index].answered == true){
            this.loadingNext = false;
            return false;
          } 
          else if (this.allQuestionsLength[index].type == 'BMI' && this.tempAnswered == true){
            this.loadingNext = false;
            return false;
          }
          else if(this.allQuestionsLength[index].type != 'SelectionExplain' && this.allQuestionsLength[index].type != 'BMI' && this.allQuestionsLength[index].answered == true){
            this.loadingNext = false;
            return false;
          }          
          else {
            this.loadingNext = true;
            return true;
          }
        }
        else{
          this.loadingNext = false;
          return false;
        }
      }
    }
  }

  activeBtn = true;
  nextQuestion(counter, changeQuestion){
    if(this.allQuestionsLength[counter - 1].questionsToAdd){
      this.handleQuestions(this.allQuestionsLength[counter - 1].answer, this.allQuestionsLength[counter - 1]);
    }
    if(this.activeBtn || !changeQuestion){
      this.activeBtn = false;
      let tempNextQuestion = this.allQuestionsLength[counter]
      let dependantQuestion;
  
      if(tempNextQuestion.dependsOnQuestion){
        dependantQuestion = this.allQuestionsLength.find((item) => item.assessmentId === tempNextQuestion.dependsOnQuestion && tempNextQuestion.assessment == item.assessment);
        if(dependantQuestion == undefined){
          dependantQuestion = this.allQuestionsLength.find((item) => item.id === tempNextQuestion.dependsOnQuestion);
        }
        let typeOfAnswer = typeof(dependantQuestion.answer)
        if(typeOfAnswer == 'string'){
          dependantQuestion.answer = [dependantQuestion.answer]
        }
        if(tempNextQuestion.dependsOnAnswer.some((element) => dependantQuestion.answer.includes(element))){
          if(changeQuestion){
            this.questionsCounter++;
            this.sequenceCounter = this.allQuestionsLength[this.questionsCounter].sequence;
            this.currentQuestion = this.allQuestionsLength[this.questionsCounter]
          }
        }
        else {
          let nextSequence = this.allQuestionsLength.find((item) => item.assessmentId === tempNextQuestion.changeNextSequenceTo.toString() && tempNextQuestion.assessment == item.assessment);
          if(nextSequence == undefined){
            nextSequence = this.allQuestionsLength.find((item) => item.id === tempNextQuestion.changeNextSequenceTo.toString());
          }
          this.sequenceCounter = nextSequence.sequence;
          this.currentQuestion = this.allQuestionsLength.find((item) => item.sequence === this.sequenceCounter);
          this.questionsCounter = this.allQuestionsLength.indexOf(this.currentQuestion);
          this.nextQuestion(this.questionsCounter, false); //Calls the function again to verify if the next question is another dependant question. 
                                                            //The false parameter is to avoid changing the question if is a dependant question that can be answered based on the previous question.
        }
      }
      else if(changeQuestion){
        this.questionsCounter++;
        this.sequenceCounter = this.allQuestionsLength[this.questionsCounter].sequence;
        this.currentQuestion = this.allQuestionsLength[this.questionsCounter]
      }
      
      this.pointsToSum = [];
      if(this.currentQuestion.answer != '' && typeof(this.currentQuestion.answer) != 'string' && this.currentQuestion.optionAnswer == undefined && this.currentQuestion.type != 'MultipleSelectionWithOptions' && this.currentQuestion.type != 'SelectionWithOptions'){
        this.checkedOptions = this.currentQuestion.answer
      }
      else if(this.currentQuestion?.optionAnswer != undefined && this.currentQuestion.optionAnswer != '' && typeof(this.currentQuestion.optionAnswer) != 'string'){
        this.checkedOptions = this.currentQuestion.optionAnswer
      }
      else {
        this.checkedOptions = []
      }
  
      this.sliderValue = ''
      if(this.currentQuestion.type == 'slider'){
        // this.sliderValue = Math.round(this.currentQuestion.answer || (this.currentQuestion.range[1] + this.currentQuestion.range[0])/2).toString()
      }
  
      // if(this.userProfile.accountType != 'admin'){
        this.afs.collection('consultation').doc(this.currentConsult.ID).update({currentQuestion: this.sequenceCounter}).then(() => {
          this.activeBtn = true;
        })
        this.disabledBtn = false;
      // }
    }
  }

  prevQuestion(){
    if(this.activeBtn){
      this.activeBtn = false;
      let tempPrevQuestion = this.allQuestionsLength[this.questionsCounter - 1]
      if(tempPrevQuestion.answered == true || tempPrevQuestion.required == false){
        this.questionsCounter--;
        this.sequenceCounter = this.allQuestionsLength[this.questionsCounter].sequence;
        this.currentQuestion = this.allQuestionsLength[this.questionsCounter]
      }
      else {
        for(let i = this.questionsCounter - 1; i >= 0; i--){
          if(this.allQuestionsLength[i].answered == true || tempPrevQuestion.required == false){
            this.questionsCounter = i;
            this.sequenceCounter = this.allQuestionsLength[this.questionsCounter].sequence;
            this.currentQuestion = this.allQuestionsLength[this.questionsCounter]
            break;
          }
        }
      }
  
      if(this.currentQuestion.answer != '' && typeof(this.currentQuestion.answer) != 'string' ){
        this.checkedOptions = this.currentQuestion.answer
      }
      else if(this.currentQuestion.optionAnswer != '' && typeof(this.currentQuestion.optionAnswer) != 'string'){
        this.checkedOptions = this.currentQuestion.optionAnswer
      }
      else {
        this.checkedOptions = []
      }
  
      this.sliderValue = ''
      if(this.currentQuestion.type == 'slider'){
        // this.sliderValue = Math.round(this.currentQuestion.answer || (this.currentQuestion.range[1] + this.currentQuestion.range[0])/2).toString()
      }
  
      // if(this.userProfile.accountType != 'admin'){
        this.afs.collection('consultation').doc(this.currentConsult.ID).update({currentQuestion: this.sequenceCounter}).then(() => {
          this.activeBtn = true;
        })
      // }
    }
  }

  disabledBtn = false;
  doProgress(){
    console.warn('doProgress')
    this.percentageAdd.emit(0)
    this.disabledBtn = true;
  }

  removeProgress(){
    this.percentageRemove.emit(0)
  }

  checkNextQuestion(){
    if(this.preconsultQuestions == true){
      // if(this.currentConsult.productByPatient || this.component == 'Consultation'){
        this.nextQuestion(this.questionsCounter + 1, true);
      // }
    }
  }

  checkPrevQuestion(){
    if(this.activeBtn){
      this.activeBtn = false;
      if(this.preconsultQuestions == false){
        let sequenceCounter = this.allQuestionsLength[this.currentConsult.currentQuestion - 2].sequence;
    
        // if(this.userProfile.accountType != 'admin'){
          this.afs.collection('consultation').doc(this.currentConsult.ID).update({currentQuestion: sequenceCounter}).then(() => {
            this.activeBtn = true;
          })
        // }
      }
    }
  }

  treatment = true
  productBinder(){
    let chooseMedication = this.currentConsult.steps.findIndex(step => step.stepId == 'chooseMedication')
    this.treatment = chooseMedication == -1 ? false : true
  }

  highlightSignatureAnimation(){
    this.highlightSignature = false;
    setTimeout(res => {
      this.highlightSignature = this.currentQuestion.lastQuestion ? true : false
    }, 250)
  }

  feetSelected = ""
  inchesSelected = ""
  openDropdown = true
  getFeetValues(){
    let feets = []
    for (let feet = 2; feet <= 8; feet++) {
      feets.push(feet);
      
      if(feet == 8){
        //Dont delete for future reference. You can customize where a dropdown opens that isnt part of your code.
        // const selectElement: any = document.getElementsByClassName('ng-dropdown-panel-items scroll-host');      
        // selectElement.length > 0 && this.openDropdown ? (selectElement[0].scrollTop = selectElement[0].scrollHeight / 2.34, this.openDropdown = false) : '';
        return feets
      }
    }
  }

  getSystolicValues(){
    let systolic = []
    for (let s = 60; s <= 180; s++) {
      systolic.push(s);
      
      if(s == 180){
        //Dont delete for future reference. You can customize where a dropdown opens that isnt part of your code.
        const selectElement: any = document.getElementsByClassName('ng-dropdown-panel-items scroll-host');      
        selectElement.length > 0 && this.openDropdown ? (selectElement[0].scrollTop = selectElement[0].scrollHeight / 2.01, this.openDropdown = false) : '';
        return systolic
      }
    }
  }

  getDiastolicValues(){
    let diastolic = []
    for (let d = 40; d <= 120; d++) {
      diastolic.push(d);
      
      if(d == 120){
        //Dont delete for future reference. You can customize where a dropdown opens that isnt part of your code.
        const selectElement: any = document.getElementsByClassName('ng-dropdown-panel-items scroll-host'); 
        selectElement.length > 0 && this.openDropdown ? (selectElement[0].scrollTop = selectElement[0].scrollHeight / 2.01, this.openDropdown = false) : '';
        return diastolic
      }
    }
  }


  // openSystolic = false;
  // openDiastolic = false;
  checkDropdown(input){
    this.openSystolic = input == 'systolic' ? true : false
    this.openDiastolic = input == 'diastolic' ? true : false

    this[input] == 0 ? this.openDropdown = true : this.openDropdown = false

    this.openSystolic = input == 'systolic' ? true : false
    this.openDiastolic = input == 'diastolic' ? true : false
  }

  getInchesValues(){
    let inches = []
    for (let inch = 0; inch <= 11; inch++) {
      inches.push(inch);
      
      if(inch == 11){
        return inches
      }
    }
  }

  checkQuestions(){
    let find = this.currentConsultQuestions.find((item) => item.sequence === this.currentConsult.currentQuestion)
    if(!find){
      this.questionsCounter = this.currentConsultQuestions.length + 1
      this.prevQuestion()
    }
  }

  onOpenSystolic(){
      
    this.classMapSystolic['fixedHeight'] = true;

    this.classMapDiastolic['fixedHeight'] = false;

    this.cdr.detectChanges();

  }

  onOpenDiastolic(){

    this.classMapDiastolic['fixedHeight'] = true;

    this.classMapSystolic['fixedHeight'] = false;

    this.cdr.detectChanges();

  }

  onSelectSystolicClose(){
      
    this.classMapSystolic['fixedHeight'] = false;

    this.cdr.detectChanges();

  }

  onSelectDiastolicClose(){

    this.classMapDiastolic['fixedHeight'] = false;

    this.cdr.detectChanges();

  }

  onBlurSystolic(){
    
    this.classMapSystolic['fixedHeight'] = false;

    this.classMapDiastolic['fixedHeight'] = false;

    this.cdr.detectChanges();

  }

  onBlurDiastolic(){

    this.classMapDiastolic['fixedHeight'] = false;

    this.classMapSystolic['fixedHeight'] = false;

    this.cdr.detectChanges();

  }

  onFocusSystolic(){

    this.classMapSystolic['fixedHeight'] = true;

    this.classMapDiastolic['fixedHeight'] = false;

    this.cdr.detectChanges();
  
  }

  onFocusDiastolic(){

    this.classMapDiastolic['fixedHeight'] = true;

    this.classMapSystolic['fixedHeight'] = false;

    this.cdr.detectChanges();
  
  }

  onFocusOutSystolic(){

    this.classMapSystolic['fixedHeight'] = false;

    this.cdr.detectChanges();
  
  }

  onFocusOutDiastolic(){

    this.classMapDiastolic['fixedHeight'] = false;

    this.cdr.detectChanges();
  
  }

  // loadingNextQuestion(){

  //   console.log('loading next question');

  //   this.loadingNext = true;
  //   // setTimeout(res => {
  //   //   this.loadingNext = false;
  //   // }, 2000)
  // }

}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { error } from 'console';
import { snap } from 'gsap/all';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { Profile } from 'src/app/core/services/interfaces';

@Component({
  selector: 'app-reevaluation',
  templateUrl: './reevaluation.component.html',
  styleUrls: ['./reevaluation.component.scss']
})
export class ReevaluationComponent implements OnInit {

  @Input() consult:any;
  @Input() defaultType:any;
  @Output() close = new EventEmitter<boolean>();

  options = [
    "Ask for refill",
    "Dosage is to high",
    "Dosage doesn't work",
    "Allergic reaction",
    "Other"
  ];

  ticketType = [
    "Reevaluation",
    "Refill",
  ];

  currentLang = this.translate.store.currentLang;

  situation: string;
  description: string;

  ticketExists: boolean = false;
  loading: boolean = false;

  //toast
  toast:boolean = false;
  icon:string = "";
  message: string = "";
  previous: string = "";
  constructor(
    private afs: AngularFirestore,
    private firebaseService: FirebaseService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
  ) { }


  ngOnInit(): void {
    this.ticketChosen = this.defaultType;

    this.afs.collection('settings').doc('helpDesk').valueChanges().subscribe((res: any) => {
      this.ticketType = res.typeOfTicket
    })
  }

  handleSituation($event) {
    this.situation = $event.target.innerText;
    let option = document.getElementById($event.target.id);
    
    let previous = [].slice.call(document.getElementById('options').getElementsByTagName('*'),0);
    for(let i = 0; i < previous.length; i++){
      if(previous[i].classList.contains("selected")){
        previous[i].classList.remove("selected")
      }
    }
    option.classList.add("selected");

    // if(option.classList.contains("selected")){
    //   option.classList.remove("selected");
    // }
    // else{
    //   option.classList.add("selected");
    // }

    this.previous = $event.target.id;
  }

  ticketChosen
  subject
  async submit(){
    let tempPatient
    if(this.consult){
      await this.authenticationService.profile().then(async res => {
        tempPatient = await res
  
        this.loading = true;

        let helpDesk = {
          id: this.firebaseService.getDate(),
          date: new Date(Date.now()),
          ref: this.consult.ID,
          stage: 'Unassigned',
          customer: this.consult.patientName,
          customerEmail: this.consult.patientEmail,
          helpDeskTeam: 'Patient Service',
          assignTo: '',
          subject: this.situation ? this.situation : "",
          description: this.description? this.description : "",
          ticketType: this.ticketChosen,
          partnerID: await tempPatient.partnerID,
          uid: await tempPatient.uid,
          phoneNumber: tempPatient.phoneNumber
        }
    
        let existingTicket: boolean = await this.isThereAnyTicketsForThisConsult();
        if(!existingTicket){
          this.loading = false;
          this.afs.collection('helpdesk').doc(helpDesk.id).set(helpDesk);
          this.toast = true;
          this.icon = "far fa-check-circle text-success";
          this.message = "Ticket submitted";
          setTimeout(() => {
            this.toast = false;
            this.closeComponent();
          }, 2000);
    
        }else{
          this.loading = false;
          this.ticketExists = true;
          this.toast = true;
          this.icon = "fa fa-times-circle text-danger"
          this.message = "There is already a ticket for this consult";
          setTimeout(() => {
            this.toast = false;
          }, 2000);
        }
      });
    }
    else {
      let tempUser
      let helpDeskTeam
      this.authenticationService.profile().then(async (profile: Profile) => {
        tempUser = await profile
  
        this.loading = true;
        if(tempUser.accountType == 'patient'){
          helpDeskTeam = 'Patient Service'
        }
        else {
          helpDeskTeam = 'Healthcare Provider Service'
        }

        let helpDesk = {
          id: this.firebaseService.getDate(),
          date: new Date(Date.now()),
          ref: 'No Consult Ref',
          stage: 'Unassigned',
          customer: tempUser.firstName + ' ' + tempUser.lastName1,
          customerEmail: tempUser.email,
          helpDeskTeam: helpDeskTeam,
          assignTo: '',
          subject: this.situation ? this.situation : "",
          description: this.description? this.description : "",
          ticketType: this.ticketChosen,
          partnerID: await tempUser.partnerID,
          uid: await tempUser.uid,
          phoneNumber: tempUser.phoneNumber
        }
        this.loading = false;
        this.afs.collection('helpdesk').doc(helpDesk.id).set(helpDesk);
        this.toast = true;
        this.icon = "far fa-check-circle text-success";
        this.message = "Ticket submitted";
        setTimeout(() => {
          this.toast = false;
          this.closeComponent();
        }, 2000);
      });
    }
  }

  closeComponent(){
    this.close.emit(true);
  }

  async isThereAnyTicketsForThisConsult(){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('helpdesk').ref.where("ref", "==", this.consult.ID).get().then(snapshot => {
        if(snapshot.empty){
          resolve(false);
        }else{
          snapshot.forEach((doc:any) => {
            if(doc.data().ticketType == this.ticketChosen){
              resolve(true);
            }
          });
          resolve(false)
        }
      });
    });
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CustomCookieService {

  constructor(private db: AngularFirestore,
  ) { 
    
  }

  async setCookie(userData){
      const ref = localStorage.getItem('cookieRef');
      if (ref) {
        const referredByParts = ref.split("-");
        const cookiesAffiliateID = referredByParts[0];
        const cookieCampaignID = referredByParts[1] || '';
        
        let id = uuidv4();
        
        const docRef = this.db.collection('cookies').doc(id); 
        
        const cookieData = {
          cookiesId: id,
          cookiesPatientUID: userData.uid,
          cookiesAffiliateID: cookiesAffiliateID,
          CookieCampaignID: cookieCampaignID,
          cookiesDate: new Date().toISOString(),
          cookieUser: 'existing'
        };
        
        try {
          await docRef.set(cookieData); 
          // console.log('Cookie data added successfully');
          localStorage.removeItem('cookieRef');
        } catch (error) {
          // console.error('Error adding cookie data: ', error);
        }
      }
  }
}

<div class="heading">
  <h3>Invoices</h3>
</div>

<div class="date-picker">
  <div class="d-flex flex-column w-25">
    <span>Months</span>
    <ng-select id="select" name="required" placeholder="Month" [(ngModel)]="selectedMonth" (change)="generateTimestamp(this.selectedYear, this.selectedMonth)">
      <ng-option *ngFor="let month of months" [value]="month">{{month | titlecase}}</ng-option>
    </ng-select>
  </div>
  
  <div class="d-flex flex-column w-25">
    <span>Years</span>
    <ng-select id="select" name="required" style="margin-left: 15px;" placeholder="Year" [(ngModel)]="selectedYear" (change)="generateTimestamp(this.selectedYear, this.selectedMonth)">
      <ng-option *ngFor="let year of years" [value]="year">{{year}}</ng-option>
    </ng-select>
  </div>
</div>

<div class="table-container">
  <table class="table">
    <thead>
      <tr>
        <th>{{ 'BILLING.TABLE-HEADER.BILLNUMBER' | translate}}</th>
        <th>{{ 'BILLING.TABLE-HEADER.BILLDATE' | translate}}</th>
        <th>{{ 'BILLING.TABLE-HEADER.CONSULTREF' | translate}}</th>
        <th>{{ 'BILLING.TABLE-HEADER.BILLAMOUNT' | translate}}</th>
        <th>{{ 'BILLING.TABLE-HEADER.TAXES' | translate}}</th>
        <th>{{ 'BILLING.TABLE-HEADER.AMOUNTPAID' | translate}}</th>
        <th>{{ 'BILLING.TABLE-HEADER.PAYMENTDUE' | translate}}</th>
        <th>{{ 'BILLING.TABLE-HEADER.STATUS' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let invoice of invoices | dateFilter: currentMonthTimestamp">
        <td>{{ invoice.billName }}</td>
        <td>{{ invoice.date | date:"mediumDate" | titlecase }}</td>
        <td (click)="goToConsult(invoice.consultRef)"> <u>{{ invoice.consultRef.substring(0, 4) + "...." + invoice.consultRef.substring(invoice.consultRef.length - 6) }}</u></td>
        <td>{{ invoice.amountUntaxed | currency:"USD"}}</td>
        <td class="pending">{{ invoice.amountTax  | currency:"USD"}}</td>
        <td [ngClass]="{'paid': invoice.paymentState == 'Paid'}">{{ setAmountPaid(invoice) | currency:"USD"}}</td>
        <td [ngClass]="{'due':invoice.paymentState == 'Pending'}" >{{ setPaymentDue(invoice) | currency:"USD"}}</td>
        <td [ngClass]="{'paid': invoice.paymentState == 'Paid', 'pending':invoice.paymentState == 'Pending'}">
          {{ invoice.paymentState }}<br>
          {{ invoice?.paymentDate | date:"mediumDate" | titlecase }}
        </td>
      </tr>
      
      
    </tbody>
  </table>
</div>




<div class="mobile-table">

  <div class="table-box" *ngFor="let invoice of invoices | dateFilter: currentMonthTimestamp">
    <div class="mobile-table-layout">
      <div class="d-flex flex-column">
        <h3 class="mobile-billname">{{ invoice.billName }}</h3>
        <span class="mobile-billdate">{{ invoice.date | date:"mediumDate" | titlecase }}</span>
        <span class="mobile-consultref" (click)="goToConsult(invoice.consultRef)"> <u>{{ invoice.consultRef }}</u></span>
        
        <div class="d-flex w-100 justify-content-between">
          <span class="mobile-amount">{{ 'BILLING.TABLE-HEADER.BILLAMOUNT' | translate}}</span>
          <span class="mobile-amount">{{ invoice.amountTotal | currency:"USD"}}</span>
        </div>
        <div class="d-flex w-100 justify-content-between">
          <span class="mobile-amount">{{ 'BILLING.TABLE-HEADER.TAXES' | translate}}</span>
          <span class="mobile-amount pending">{{ invoice.amountTax | currency:"USD"}}</span>
        </div>
        <div class="d-flex w-100 justify-content-between">
          <span class="mobile-amount">{{ 'BILLING.TABLE-HEADER.AMOUNTPAID' | translate}}</span>
          <span class="mobile-amount" [ngClass]="{'paid': invoice.paymentState == 'Paid'}">{{ setAmountPaid(invoice) | currency:"USD"}}</span>
        </div>
        <div class="d-flex w-100 justify-content-between">
          <span class="mobile-amount">{{ 'BILLING.TABLE-HEADER.PAYMENTDUE' | translate}}</span>
            <span class="mobile-amount" [ngClass]="{'due':invoice.paymentState == 'Pending'}">{{ setPaymentDue(invoice) | currency:"USD"}}</span>
        </div>
        <div class="d-flex justify-content-end">
          <span [ngClass]="{'paid': invoice.paymentState == 'Paid', 'pending':invoice.paymentState == 'Pending'}">
            {{ invoice.paymentState }} {{ invoice?.paymentDate | date:"mediumDate" | titlecase }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>



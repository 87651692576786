<div style="height: auto">
  <div *ngIf="startPage" style="margin-top: -23px">
    <div class="row">
      <div class="col-lg-12">
        <div class="row box flex-column">
          <div class="mx-auto align-self-center">
            <h2 *ngIf="manage == 'Consults'" class="header selectConsult">{{'CONSULTQUESTIONS.ALLCONDITIONS' | translate}}</h2>
            <h2 *ngIf="manage == 'Questions'" class="header selectConsult">{{'CONSULTQUESTIONS.ALLQUESTIONS' | translate}}</h2>
          </div>

          <div class="d-flex flex-row">
            <ng-select id="choose" [clearable]="false" style="width: fit-content !important;" class="mr-2" [(ngModel)]="manage">
              <ng-option [value]="'Consults'">Consults</ng-option>
              <ng-option [value]="'Questions'">Questions</ng-option>
            </ng-select>
  
            <button *ngIf="manage == 'Consults'" class="btn payBtn btn-primary" style="width: fit-content;" [routerLink]="['./condition']" (click)="manageConsultSwitch()">{{'CONSULTQUESTIONS.CREATECONDITION' | translate}}</button>
            <button *ngIf="manage == 'Questions'" class="btn payBtn btn-primary" style="width: fit-content;" [routerLink]="['./questions']" (click)="manageConsultSwitch(); activeId = 2;">{{'CONSULTQUESTIONS.CREATEQUESTIONGROUP' | translate}}</button>    
          </div>
        </div>
        <div class="container conditions-container-height">
          <div *ngIf="manage == 'Consults'" class="content auto-grid" style="margin-top: 10px;">
            <div class="grid-wrapper grid-col-3" *ngFor="let condition of allConditions">
              <div class="selection-wrapper" (click)="setSelectedCondition(condition)" [routerLink]="['./condition']">
                <div class="selected-content">
                  <img src="{{condition.Photo1}}" alt="">
                  <h4>{{condition.Name | translation:currentLang}}</h4>
                </div>
              </div>
            </div>
          </div>     
          <div *ngIf="manage == 'Questions'" class="d-flex flex-column" style="margin-top: 10px;">
            <div *ngFor="let question of allQuestionsDocuments">
              <h4 style="text-align: start;" (click)="setSelectedQuestionGroup(question.id)" [routerLink]="['./questions']">{{question.id}}</h4>
            </div>
          </div>
        </div>
      </div>    
    </div> 
  </div>
  
  <div *ngIf="!startPage" style="margin-top: 3%">
    <div class="row" style="margin-top: -23px">
      <div class="mx-auto align-self-center">
        <h2 class="header selectConsult">{{'CONSULTQUESTIONS.CONDITION' | translate}}</h2>
      </div>
    </div>
    <div class="col-lg-12 mb-3 box radious">
      <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom nav-justified pt-3">
        <li *ngIf="manage == 'Consults'" [ngbNavItem]="1">
            <a ngbNavLink [routerLink]="['./condition']">
              <span>{{'CONSULTQUESTIONS.CONDITION' | translate}}</span>
            </a>
            <ng-template ngbNavContent>
              <ng-template [ngTemplateOutlet]="Condition"></ng-template>
            </ng-template>
        </li>
        <li [ngbNavItem]="2" *ngIf="manage == 'Questions'">
            <a ngbNavLink [routerLink]="['./questions']" (click)="setQuestionForm(); questionAtributes = ''; questionAtributesOptions = ''; tempValue = []">
              <span>{{'CONSULTQUESTIONS.QUESTIONS' | translate}}</span>
            </a>
            <ng-template ngbNavContent>
              <ng-template [ngTemplateOutlet]="Questions"></ng-template>
            </ng-template>
        </li>
        <li [ngbNavItem]="3" *ngIf="questionAtributes != ''">
            <a ngbNavLink [routerLink]="['./questionAtributes']">
                <span>{{'CONSULTQUESTIONS.QUESTIONATRIBUTES' | translate}}</span>
            </a>
            <ng-template ngbNavContent>
                <ng-template [ngTemplateOutlet]="QuestionsAtributes"></ng-template>
            </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="customNav">
      </div>
      <div>   
        <div class="row justify-content-end mt-4" style="padding-bottom: 20px;">
          <button *ngIf="activeId == 1 && !consultation" id="saveBtn" type="button" class="btn btn-info btn-submit mr-2" (click)="createConsult()" [routerLink]="['./']" [disabled]="invalidSubmit()">{{'CONSULTQUESTIONS.CREATECONDITION' | translate}}</button>
          <button *ngIf="activeId == 1 && consultation" type="button" id="saveBtn" class="btn btn-info btn-submit mr-2" (click)="updateConsult()" [routerLink]="['./']" [disabled]="invalidSubmit()">{{'CONSULTQUESTIONS.UPDATECONDITION' | translate}}</button>
          <button *ngIf="activeId == 1" style="padding-left: 25px; padding-right: 25px;" class="btn btn-info btn-submit mr-2" [routerLink]="['./']" (click)="disableConsult();">{{'CONSULTQUESTIONS.DISABLECONDITION' | translate}}</button>
          <button *ngIf="activeId == 2 && questionGroupId" (click)="setSelectedQuestion('new')" [routerLink]="['./questionAtributes']" class="btn btn-info btn-submit mr-2">{{'CONSULTQUESTIONS.ADDQUESTION' | translate}}</button>
          <!-- <button *ngIf="activeId == 2 && questionGroupId" (click)="addMedicalHistoryQuestions()" class="btn btn-info btn-submit mr-2">custom questions</button> -->
          <button *ngIf="activeId == 3 && questionAtributes == 'new'" type="button" id="saveBtn" class="btn btn-info btn-submit mr-2" [routerLink]="['./questions']" (click)="createQuestion()" [disabled]="invalidQuestionSubmit()">{{'CONSULTQUESTIONS.CREATEQUESTION' | translate}}</button>     
          <button *ngIf="activeId == 3 && questionAtributes != 'new'" type="button" id="saveBtn" class="btn btn-info btn-submit mr-2" [routerLink]="['./questions']" (click)="updateQuestion()" [disabled]="invalidQuestionSubmit()">{{'CONSULTQUESTIONS.UPDATEQUESTION' | translate}}</button>
          <button *ngIf="activeId == 3 && questionAtributes != 'new'" type="button" id="saveBtn" class="btn btn-info btn-submit mr-2" [routerLink]="['./questions']" (click)="deleteQuestion()">{{'CONSULTQUESTIONS.DELETEQUESTION' | translate}}</button>
          <button type="button" class="btn btn-light btn-submit mr-4" (click)="startPageSwitch()" [routerLink]="['./']" aria-hidden="true">{{'CONSULTQUESTIONS.CANCEL' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #Condition>
    <div class="row mx-auto mb-3">
      <div class="col-lg-12">
        <div class="form mx-lg-auto">
          <form [formGroup]="conditionForm">
            <div class="condition">
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.CONDITIONNAME' | translate}}</p>
                    <input type="text" autocomplete="false" class="form-control" style="margin-left: 15px;" id="Name" name="Name"  placeholder="&nbsp;" formControlName="Name">
                  </li>
                  <p *ngIf="conditionForm.controls.Name.errors && conditionForm.controls.Name.errors.required && conditionForm.controls.Name.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.NAMEREQUIRED' | translate}}</p>   
                </div>      
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.PHOTO1' | translate}}</p>
                    <input class="form-control" style="margin-left: 15px;" id="Photo1" name="Photo1"  placeholder="&nbsp;" formControlName="Photo1">
                  </li>
                  <p *ngIf="conditionForm.controls.Photo1.errors && conditionForm.controls.Photo1.errors.required && conditionForm.controls.Photo1.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.PHOTO1REQUIRED' | translate}}</p>   
                </div>      
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center" >
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.QUESTIONGROUPS' | translate}}</p>
                    <ng-select class="form-control" [multiple]="true" id="conditionQuestionsId" name="conditionQuestionsId" style="margin-left: 15px;" placeholder="&nbsp;" formControlName="conditionQuestionsId">
                      <ng-option [value]=""></ng-option>
                      <ng-option *ngFor="let q of allQuestionsDocuments" [value]="q.id">{{q.id}}</ng-option>
                    </ng-select>
                  </li>  
                  <!-- <p *ngIf="conditionForm.controls.biologicalGender.errors && conditionForm.controls.biologicalGender.errors.required && conditionForm.controls.biologicalGender.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.GENDERREQUIRED' | translate}}</p>                -->
                </div>      
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center" >
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.BIOLOGICALGENDER' | translate}}</p>
                    <ng-select class="form-control" [multiple]="true" id="biologicalGender" name="biologicalGender" style="margin-left: 15px;" placeholder="&nbsp;" formControlName="biologicalGender">
                      <ng-option [value]=""></ng-option>
                      <ng-option [value]="'Female'">{{'CONSULTQUESTIONS.FEMALE' | translate}}</ng-option>
                      <ng-option [value]="'Male'">{{'CONSULTQUESTIONS.MALE' | translate}}</ng-option>
                      <!-- <ng-option [value]="'Intersex'">{{'CONSULTQUESTIONS.INTERSEX' | translate}}</ng-option> -->
                    </ng-select>
                  </li>  
                  <p *ngIf="conditionForm.controls.biologicalGender.errors && conditionForm.controls.biologicalGender.errors.required && conditionForm.controls.biologicalGender.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.GENDERREQUIRED' | translate}}</p>               
                </div>      
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center" >
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.CONSULTMODALE' | translate}}</p>
                    <ng-select class="form-control" id="consultModale" name="consultModale" style="margin-left: 15px;" placeholder="&nbsp;" formControlName="consultModale">
                      <ng-option [value]=""></ng-option>
                      <ng-option [value]="'Synchronous'">{{'CONSULTQUESTIONS.SYNCHRONOUS' | translate}}</ng-option>
                      <ng-option [value]="'Asynchronous'">{{'CONSULTQUESTIONS.ASYNC' | translate}}</ng-option>
                    </ng-select>
                  </li>  
                  <p *ngIf="conditionForm.controls.consultModale.errors && conditionForm.controls.consultModale.errors.required && conditionForm.controls.consultModale.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.CONSULTMODALEREQUIRED' | translate}}</p>               
                </div>      
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">        
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.DISABLED' | translate}}</p>
                    <ng-select class="form-control" id="disabled" name="disabled" style="margin-left: 15px;" placeholder="&nbsp;" formControlName="disabled">
                      <ng-option [value]=""></ng-option>
                      <ng-option [value]="true">{{'CONSULTQUESTIONS.TRUE' | translate}}</ng-option>
                      <ng-option [value]="false">{{'CONSULTQUESTIONS.FALSE' | translate}}</ng-option>
                    </ng-select>
                  </li>  
                  <p *ngIf="conditionForm.controls.disabled.errors && conditionForm.controls.disabled.errors.required && conditionForm.controls.disabled.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.DISABLEDREQUIRED' | translate}}</p>  
                </div>
              </div>  
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">        
                  <li class="support"><p style="width: 20%;">Steps</p>
                    <ng-select *ngIf="stepsInCondition" [multiple]="true" class="form-control" id="steps" name="steps" style="margin-left: 15px;" placeholder="&nbsp;" [ngModelOptions]="{standalone: true}" [(ngModel)]="stepsInCondition">
                      <ng-option *ngFor="let step of steps" [value]="step">{{step.stepId}}</ng-option>
                    </ng-select>
                  </li>  
                  <p *ngIf="conditionForm.controls.steps.errors && conditionForm.controls.steps.errors.required && conditionForm.controls.steps.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.STEPSREQUIRED' | translate}}</p>  
                </div>
              </div>  
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.MAXAGE' | translate}}</p>
                    <input type="number" class="form-control" style="margin-left: 15px;" id="maxAge" name="maxAge"  placeholder="&nbsp;" formControlName="maxAge">
                  </li>
                  <p *ngIf="conditionForm.controls.maxAge.errors && conditionForm.controls.maxAge.errors.required && conditionForm.controls.maxAge.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.MAXAGEREQUIRED' | translate}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.MINAGE' | translate}}</p>
                    <input type="number" class="form-control" style="margin-left: 15px;" id="minAge" name="minAge"  placeholder="&nbsp;" formControlName="minAge">
                  </li>  
                  <p *ngIf="conditionForm.controls.minAge.errors && conditionForm.controls.minAge.errors.required && conditionForm.controls.minAge.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.MINAGEREQUIRED' | translate}}</p>
                  <p *ngIf="conditionForm.controls.minAge.value > conditionForm.controls.maxAge.value" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.MINAGEMAYORMAXAGE' | translate}}</p> 
                </div>
              </div>         

              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">        
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.NS' | translate}}</p>
                    <ng-select [multiple]="true" class="form-control" id="nextSteps" name="nextSteps" style="margin-left: 15px;" placeholder="&nbsp;" aria-disabled="true" formControlName="nextSteps">
                      <ng-option [value]=""></ng-option>
                      <ng-option [value]="'<en>Review your medical history</en><es>Revisar su historial médico</es>'">Revisar su historial médico</ng-option>
                      <ng-option [value]="'<en>Determine if the treatment is right for you</en><es>Determinar si el tratamiento es correcto para usted</es>'">Determinar si el tratamiento es correcto para usted</ng-option>
                      <ng-option [value]="'<en>Send messages inside the platform regarding your consult</en><es>Enviar mensajes dentro de la plataforma con relación a su consulta</es>'">Enviar mensajes dentro de la plataforma con relación a su consulta</ng-option>
                    </ng-select>
                  </li>  
                  <p *ngIf="conditionForm.controls.nextSteps.errors && conditionForm.controls.nextSteps.errors.required && conditionForm.controls.nextSteps.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.NSREQUIRED' | translate}}</p>  
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">        
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.PSL' | translate}}</p>
                    <ng-select class="form-control" id="passScoreLimit" name="disabled" style="margin-left: 15px;" placeholder="&nbsp;" formControlName="passScoreLimit">
                      <ng-option [value]=""></ng-option>
                      <ng-option [value]="true">{{'CONSULTQUESTIONS.TRUE' | translate}}</ng-option>
                      <ng-option [value]="false">{{'CONSULTQUESTIONS.FALSE' | translate}}</ng-option>
                    </ng-select>
                  </li>  
                  <p *ngIf="conditionForm.controls.passScoreLimit.errors && conditionForm.controls.passScoreLimit.errors.required && conditionForm.controls.passScoreLimit.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.PSLREQUIRED' | translate}}</p>  
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">        
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.RFU' | translate}}</p>
                    <ng-select class="form-control" id="requiredFollowUp" name="requiredFollowUp" style="margin-left: 15px;" placeholder="&nbsp;" formControlName="requiredFollowUp">
                      <ng-option [value]=""></ng-option>
                      <ng-option [value]="true">{{'CONSULTQUESTIONS.TRUE' | translate}}</ng-option>
                      <ng-option [value]="false">{{'CONSULTQUESTIONS.FALSE' | translate}}</ng-option>
                    </ng-select>
                  </li>  
                  <p *ngIf="conditionForm.controls.requiredFollowUp.errors && conditionForm.controls.requiredFollowUp.errors.required && conditionForm.controls.requiredFollowUp.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.RFUREQUIRED' | translate}}</p>  
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.SCORELIMIT' | translate}}</p>
                    <input type="number" class="form-control" style="margin-left: 15px;" id="scoreLimit" name="scoreLimit"  placeholder="&nbsp;" formControlName="scoreLimit">
                  </li>  
                  <p *ngIf="conditionForm.controls.scoreLimit.errors && conditionForm.controls.scoreLimit.errors.required && conditionForm.controls.scoreLimit.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.SCOREREQUIRED' | translate}}</p>                    
                </div>
              </div>   

              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.SERVICETYPE' | translate}}</p>
                    <input class="form-control" style="margin-left: 15px;" id="serviceType" name="serviceType"  placeholder="&nbsp;" formControlName="serviceType">
                  </li>
                  <p *ngIf="conditionForm.controls.serviceType.errors && conditionForm.controls.serviceType.errors.required && conditionForm.controls.serviceType.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.SERVICETYPEREQUIRED' | translate}}</p>   
                </div>      
              </div>

              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.TQ' | translate}}</p>
                    <input type="number" class="form-control" style="margin-left: 15px;" id="totalQuestions" name="totalQuestions"  placeholder="&nbsp;" formControlName="totalQuestions">
                  </li>  
                  <p *ngIf="conditionForm.controls.totalQuestions.errors && conditionForm.controls.totalQuestions.errors.required && conditionForm.controls.totalQuestions.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.TQREQUIRED' | translate}}</p>                    
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">        
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.TOC' | translate}}</p>
                    <ng-select *ngIf="conditionForm.controls.typeOfContact.value" [multiple]="true" class="form-control" id="typeOfContact" name="typeOfContact" style="margin-left: 15px;" placeholder="&nbsp;" formControlName="typeOfContact">
                      <ng-option [value]=""></ng-option>
                      <ng-option [value]="{text: '<en>Messages inside the platform</en><es>Mensajes dentro de la plataforma</es>', recommended: false}">Mensajes dentro de la plataforma</ng-option>
                      <ng-option [value]="{text: '<en>Audiocall</en><es>Llamada</es>', recommended: false}">Llamada</ng-option>
                      <ng-option [value]="{text: '<en>Videocall</en><es>Videollamada</es>', recommended: false}">Videollamada</ng-option>
                      <ng-option [value]="{text: '<en>Any type of contact method</en><es>Cualquier método de contacto</es>', recommended: false}">Cualquier método de contacto</ng-option>
                    </ng-select>
                  </li>  
                  <p *ngIf="conditionForm.controls.typeOfContact.errors && conditionForm.controls.typeOfContact.errors.required && conditionForm.controls.typeOfContact.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.TOCREQUIRED' | translate}}</p>  
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.TOP' | translate}}</p>
                    <ng-select [multiple]="true"  class="form-control" id="typeOfPractitioner" name="typeOfPractitioner" style="margin-left: 15px;" placeholder="&nbsp;" formControlName="typeOfPractitioner">
                      <ng-option [value]=""></ng-option>
                      <ng-option [value]="'Doctor'">{{'ACCOUNTS.EMAIL&PASSWORD.DOCTOR' | translate}}</ng-option>
                      <ng-option [value]="'Psychologist'">{{'ACCOUNTS.EMAIL&PASSWORD.PSYCHOLOGIST' | translate}}</ng-option>
                    </ng-select>
                  </li>
                  <p *ngIf="conditionForm.controls.typeOfPractitioner.errors && conditionForm.controls.typeOfPractitioner.errors.required && conditionForm.controls.typeOfPractitioner.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.TOPREQUIRED' | translate}}</p>  
                </div>
              </div> 
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #Questions>
    <h2>{{questionGroupId}}</h2>
    <li *ngIf="!questions" class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.QUESTIONGROUPNAME' | translate}}</p>
      <input class="form-control" style="margin-left: 15px;" id="questionGroupId" [(ngModel)]="questionGroupId" name="questionGroupId" placeholder="&nbsp;">
    </li>

    <div class="allQuestions">
      <div *ngFor="let q of questions; let i = index" style="cursor: pointer;" >
        <div (click)="setSelectedQuestion(q)" [routerLink]="['./questionAtributes']" style="font-size: 16px; color: black; padding: 10px;" class="pl-6">
          {{i + 1}}. {{q.question | translation:currentLang }}
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #QuestionsAtributes>

    
    <div  class="row mx-auto mb-3">
      <div class="col-lg-12">
        <div class="form mx-lg-auto">
          <form [formGroup]="questionForm">
            <div class="condition">
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.DISPLAYTYPE' | translate}}</p>
                    <ng-select class="form-control" id="displayType" name="displayType" style="margin-left: 15px;" placeholder="&nbsp;" formControlName="displayType">
                      <ng-option [value]=""></ng-option>
                      <ng-option [value]="'text'">{{'CONSULTQUESTIONS.TEXT' | translate}}</ng-option>
                      <ng-option [value]="'image'">{{'CONSULTQUESTIONS.IMAGE' | translate}}</ng-option>
                      <ng-option [value]="'textImage'">{{'CONSULTQUESTIONS.TEXTIMAGE' | translate}}</ng-option>
                    </ng-select>
                  </li>
                  <p *ngIf="questionForm.controls.displayType.errors && questionForm.controls.displayType.errors.required && questionForm.controls.displayType.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.DISPLAYTYPEREQUIRED' | translate}}</p>
                </div>      
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">        
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.PRECONSULT' | translate}}</p>
                    <ng-select class="form-control" id="preconsult" name="preconsult" style="margin-left: 15px;"  placeholder="&nbsp;" formControlName="preconsult">
                      <ng-option [value]=""></ng-option>
                      <ng-option [value]="true">{{'CONSULTQUESTIONS.TRUE' | translate}}</ng-option>
                      <ng-option [value]="false">{{'CONSULTQUESTIONS.FALSE' | translate}}</ng-option>
                    </ng-select>
                    </li>
                    <p *ngIf="questionForm.controls.preconsult.errors && questionForm.controls.preconsult.errors.required && questionForm.controls.preconsult.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.PRECONSULTREQUIRED' | translate}}</p>               
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.QUESTION' | translate}} </p>
                    <input class="form-control" style="margin-left: 15px;" id="question" name="question"  placeholder="&nbsp;" formControlName="question">
                  </li>
                  <p *ngIf="questionForm.controls.question.errors && questionForm.controls.question.errors.required && questionForm.controls.question.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.QUESTIONREQUIRED' | translate}}</p>
                </div>      
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">Sequence </p>
                    <input class="form-control" style="margin-left: 15px;" id="sequence" name="sequence"  placeholder="&nbsp;" formControlName="sequence">
                  </li>
                  <p *ngIf="questionForm.controls.sequence.errors && questionForm.controls.sequence.errors.required && questionForm.controls.sequence.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.SEQUENCEREQUIRED' | translate}}</p>
                </div>      
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.REQUIRED' | translate}}</p>
                    <ng-select class="form-control" id="required" name="required" style="margin-left: 15px;" placeholder="&nbsp;" formControlName="required">
                      <ng-option [value]=""></ng-option>
                      <ng-option [value]="true">{{'CONSULTQUESTIONS.TRUE' | translate}}</ng-option>
                      <ng-option [value]="false">{{'CONSULTQUESTIONS.FALSE' | translate}}</ng-option>
                    </ng-select>
                  </li>
                  <p *ngIf="questionForm.controls.required.errors && questionForm.controls.required.errors.required && questionForm.controls.required.touched" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.REQUIREDREQUIRED' | translate}}</p>
                </div>      
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center" >
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.TYPEOFQUESTION' | translate}}</p>
                    <ng-select class="form-control" style="margin-left: 15px;" id="type" name="type"  placeholder="&nbsp;" formControlName="type">
                      <ng-option [value]=""></ng-option>
                      <!-- <ng-option [value]="BP">{{'CONSULTQUESTIONS.BP' | translate}}</ng-option> -->
                      <!-- <ng-option [value]="SearchConditions">{{'CONSULTQUESTIONS.SEARCHCONDITIONS' | translate}}</ng-option> -->
                      <ng-option [value]="'Multiple'">{{'CONSULTQUESTIONS.MULTIPLE' | translate}}</ng-option>
                      <ng-option [value]="'Selection'">{{'CONSULTQUESTIONS.SELECTION' | translate}}</ng-option>
                      <!-- <ng-option [value]="'SelectionWithOptions'">{{'CONSULTQUESTIONS.SELECTIONWO' | translate}}</ng-option> -->
                      <!-- <ng-option [value]="'MultipleSelectionWithOptions'">{{'CONSULTQUESTIONS.MSELECTIONWO' | translate}}</ng-option> -->
                      <ng-option [value]="'SelectionExplain'">{{'CONSULTQUESTIONS.SELECTIONEXPLAIN' | translate}}</ng-option>
                      <ng-option [value]="'Text'">{{'CONSULTQUESTIONS.TEXT' | translate}}</ng-option>
                      <ng-option [value]="'uploadDocuments'">{{'CONSULTQUESTIONS.UPLOADDOCUMENTS' | translate}}</ng-option>
                      <!-- <ng-option [value]="SearchMedicines">{{'CONSULTQUESTIONS.SEARCHMEDICINE' | translate}}</ng-option> -->
                    </ng-select>
                  </li>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.HINTS' | translate}}</p>
                    <textarea class="form-control" style="margin-left: 15px;" id="hints" name="hints"  placeholder="&nbsp;" formControlName="hints"></textarea>
                  </li>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.INFO' | translate}}</p>
                    <textarea class="form-control" style="margin-left: 15px;" id="info" name="info"  placeholder="&nbsp;" formControlName="info"></textarea>
                  </li>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.DEPENDSONQUESTION' | translate}}</p>
                    <input class="form-control" style="margin-left: 15px;" id="dependsOnQuestion" name="dependsOnQuestion"  placeholder="&nbsp;" formControlName="dependsOnQuestion">
                  </li>
                </div>
              </div>
              <div *ngIf="questionForm.controls.dependsOnQuestion.value != ''" class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.DEPENDSONANSWER' | translate}}</p>
                    <ng-select [multiple]="true" class="form-control" id="dependsOnAnswer" name="dependsOnAnswer" style="margin-left: 15px;" placeholder="&nbsp;" formControlName="dependsOnAnswer">
                      <ng-option [value]=""></ng-option>
                      <ng-option *ngFor="let option of questions[questionForm.controls.dependsOnQuestion.value - 1].options" [value]="option">{{option}}</ng-option>
                    </ng-select>
                  </li>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support"><p style="width: 20%;">{{'CONSULTQUESTIONS.BREAKSEQUENCE' | translate}}</p>
                    <input type="number" class="form-control" style="margin-left: 15px;" id="changeNextSequenceTo" name="changeNextSequenceTo"  placeholder="&nbsp;" formControlName="changeNextSequenceTo">
                  </li>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-form-label mx-auto align-self-center">
                  <li class="support">
                    <table style="border: 2px solid #eff2f7;" *ngIf="questionForm.controls.type.value == 'Selection' || questionForm.controls.type.value == 'SelectionExplain' || questionForm.controls.type.value == 'Multiple'"  class="table table-centered">
                      <tr>
                        <th style="color: black; border: 1px solid #eff2f7;">{{'CONSULTQUESTIONS.OPTION' | translate}}</th>
                        <th style="width: 10%; color: black; border: 1px solid #eff2f7;">{{'CONSULTQUESTIONS.POINTS' | translate}}</th>
                        <th style="width: 5%;"><i (click)="addOption();" ngbTooltip="{{'CONSULTQUESTIONS.ADDOPTION' | translate}}" class="ri-play-list-add-line"></i></th>
                      </tr>
                      <tr *ngFor="let opt of questionAtributesOptions.options; let i = index" [attr.data-index]="i">
                        <th style="border: 1px solid #eff2f7;">
                        <label style="min-width: 100%; font-weight: 400;" contenteditable="true" (blur)="optionSave()" for="optionsPoints-{{i}}" (keyup)="edit($event.target.textContent, i, questionAtributesOptions.optionsPoints[i])">{{opt}}</label><input hidden id="optionsPoints-{{i}}"></th>
                        <th style="border: 1px solid #eff2f7;"><input style="box-shadow: none; font-weight: 400;" name="optionsPoints-{{i}}" value="{{questionAtributesOptions.optionsPoints[i]}}" (blur)="optionSave()" (keyup)="edit(opt, i, $event.target.value)"></th>
                        <th><i class="mdi mdi-delete-outline font-size-20 mr-3 text-danger" ngbTooltip="{{'NOTIFICATIONS.CHAT.DELETE' | translate}}" (click)="deleteOption(i)"></i></th>
                      </tr>
                    </table>
                    <table style="border: 1px solid #eff2f7;" *ngIf="questionForm.controls.type.value == 'BP' || questionForm.controls.type.value == 'SearchConditions' || questionForm.controls.type.value == 'SearchMedicines'"  class="table table-centered">
                      <tr>
                        <th style="color: black;  border: 1px solid #eff2f7;">{{'CONSULTQUESTIONS.OPTION' | translate}}</th>
                        <th><i (click)="addOption();" ngbTooltip="{{'CONSULTQUESTIONS.ADDOPTION' | translate}}" class="ri-play-list-add-line"></i></th>
                      </tr>
                      <tr *ngFor="let opt of questionAtributesOptions.options; let i = index" [attr.data-index]="i">
                        <th style="border: 1px solid #eff2f7;">
                          <label style="min-width: 100%; font-weight: 400;" contenteditable="true" (blur)="optionSave()" (keyup)="edit($event.target.textContent, i, questionAtributesOptions.optionsPoints[i])">{{opt}}</label></th>
                        <th><i class="mdi mdi-delete-outline font-size-20 mr-3 text-danger" ngbTooltip="{{'NOTIFICATIONS.CHAT.DELETE' | translate}}" (click)="deleteOption(i)"></i></th>
                      </tr>
                    </table>
                  </li>  
                  <p *ngIf="(questionForm.controls.type.value == 'Selection' || questionForm.controls.type.value == 'SelectionExplain' || questionForm.controls.type.value == 'Multiple') && (questionAtributesOptions == '' || questionAtributesOptions.options.length == 0)" style="color: red; width: 100%; padding-left: 5%;">{{'CONSULTQUESTIONS.OPTIONSREQUIRED' | translate}}</p>
                </div>
              </div> 
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
</div>
import { OnChanges, Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import { LanguageService } from './core/services/language.service';
import { Subscription } from "rxjs";
import { AuthenticationService } from './core/services/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Pipe({name: 'htmlToPlaintext'})
export class HtmlToPlaintextPipe implements PipeTransform {
  transform(value: string): string {
    return value? value.replace(/]+>/gm, '') : '';
  }
}

@Pipe({
  name: 'translation',
  pure: false
})

export class TranslationPipe implements PipeTransform{
  // lang:any =  this.cookieService.get('lang');
  subscription: Subscription;

  constructor(
    ){
  }
 

  transform(value: any, lang: string): any {

    if(lang == null || lang  == undefined || lang == ''){
      lang = 'es'
    }
    //CREDITS ARAMIS
    if(value !== undefined){
      if(value instanceof Array){
        for(let i = 0; i < value.length; i++){
          // if(typeof value[i] == "string"){
          //   return value[i].split(`<${lang}>`).pop().split(`</${lang}>`)[0];
          // }else{
          //   return value;
          // }
          if(typeof value[i] == "string"){
            value[i] = value[i].split(`<${lang}>`).pop().split(`</${lang}>`)[0];
          }

          if(i == value.length - 1){
            return value;
          }
        }
      }else{
        if(value != null && value != '' && value != undefined) {
          if(typeof value == "string"){
            if(value.includes(`<${lang}>`)){
              return value.split(`<${lang}>`).pop().split(`</${lang}>`)[0];
            }
          }
          if(typeof value == "object"){
            for (const [key, val] of Object.entries(value)) {
              let v:any = val;
              if(v.includes(`<${lang}>`)){
                return v.split(`<${lang}>`).pop().split(`</${lang}>`)[0];
              }else{
                return v;
              }
            }
          }
          // else if(!value.startsWith('https://firebasestorage.googleapis.com') ){
          //   return "No translation available for selected language";
          // }
          else {
            if(value == '0.00'){
              let sus = "<en>Free</en><es>Gratis</es>";
              return sus.split(`<${lang}>`).pop().split(`</${lang}>`)[0];
            }
            return value
          }
        }  
      }
    }    
  }
}
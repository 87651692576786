<div *ngIf="loading">
    <app-reload [message]="'RELOAD.DOWNLOAD'"></app-reload>
</div>
  
  <div style="opacity: 0; position: absolute;">
    <img crossorigin="anonymous" id="imageT"
    src="{{consult?.image}}" width='220px'>
  
    <canvas id="imageCanvasT" style="border:1px solid #d3d3d3; border-radius: 50%;">
    </canvas>
  
    <canvas id="imageCanvasTT"  style="border:1px solid #d3d3d3; border-radius: 50%; ">
    </canvas>
  
    <img crossorigin="anonymous" id="id"
  src="{{consult?.identification}}" width="320px" height="220px">
  
  <!-- <img crossorigin="anonymous" id="idBack"
  src="{{consult?.identificationBack}}" width="320px" height="220px"> -->
  
  <canvas id="idBackCanvas" width="320px" height="220px" style="border:1px solid #d3d3d3;">
  </canvas>
  <canvas id="idCanvas" width="320px" height="220px" style="border:1px solid #d3d3d3;">
  </canvas>
  </div>
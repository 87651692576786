import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuditTraceService } from 'src/app/core/services/audit-trace.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { AuditTracer, BaseProductPrescription, Blocked, MakePaymentTracer } from 'src/app/core/services/interfaces';

import { Payment } from '../../components/consult-prescriptions/consult-prescription';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { PaymentService } from 'src/app/core/services/payment.service';

import { ReloadComponent } from 'src/app/extrapages/reload/reload.component';
import { EmailsService } from 'src/app/core/services/emails.service';
import { OrdersService } from 'src/app/core/services/orders.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';
import { PdfService } from 'src/app/core/services/pdf.service';
import { v4 as uuidv4 } from 'uuid';
import { switchMap, filter } from 'rxjs/operators';


import firebase from 'firebase/compat/app';
import { getFunctions, httpsCallable } from "firebase/functions";
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import { ProductsService } from 'src/app/core/services/products/products.service';
import { Router } from '@angular/router';
import { PharmacyOrderScheduleService } from 'src/app/core/services/pharmacy-order-schedule.service';

const app = firebase.initializeApp(environment.firebaseConfig);
const functions = getFunctions(app);

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})

export class OrderDetailsComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('signaturePad', { read: TemplateRef }) signaturePadModal:TemplateRef<any>;
  @ViewChild('firstOrderTerms', { read: TemplateRef }) firstOrderTermsModal:TemplateRef<any>;
  @ViewChild('orderChangeProduct', { read: TemplateRef }) orderChangeProductModal:TemplateRef<any>;
  @ViewChild('orderPaymentHistoryRef', { read: TemplateRef }) orderPaymentHistoryModal:TemplateRef<any>;

  @Input() id:string;
  @Input() orderId:number;
  @Input() consult:any;
  @Input() consultOrdersDetails: boolean = false;
  @Output() dismissModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  details:any;
  lang:string = this.translate.store.currentLang ? this.translate.store.currentLang : 'es';

  signature:string;
  signRef;
  dateCertified:any;

  orders:any = [];
  orderLines:any = [];
  orderLinesLoaded:boolean = false;

  card:boolean = false;
  amountDue:string;
  partialAmount:any;
  paymentHistory:any[] = [];

  modalRef:any;
  modalRef2:any;
  currentUser:any;

  patientUser:any;
  creditCardRef:any;
  paymentMethod:any = [];
  allPaymentMethods:any = [];
  
  settings:any;
  selectedItems:any;
  prescriptionOrder:any;
  loading:boolean = false;

  subscriptions = new Subscription();


  payments: any[] = [];
  private paymentsSubscription: Subscription;

  refillReasons: boolean = false;

  productToChange: any = {};
  orderLineProduct: any = {}; 
  productSelected: any = {};

  couponCode: string;
  appliedDiscount: boolean = false;
  couponError: boolean = false;
  couponValid: boolean = false;
  orderDiscount: any = 0;
  couponExistsInOrder: boolean = false;
  couponErrorExists: boolean = false;

  showAddCouponOption: boolean = true;

  waitingForOrderlines: boolean = true;
  applyingCoupon: boolean = false;
  couponInvalid: boolean = false;
  couponInvalidOrder: boolean = false;
  couponPreviouslyUsed: boolean = false;

  hasCheckedCoupon: boolean = false;

  clickedApplyCoupon: boolean = false;

  private docSubscription: Subscription | undefined;
  private documentRef: AngularFirestoreDocument<any> | undefined;
  private docId$ = new BehaviorSubject<string | null>(null);
  
  removingCoupon: boolean = false;

  constructor(
    private db: AngularFirestore,
    private modalService: NgbModal,
    private translate: TranslateService,
    private firebaseService: FirebaseService,
    private auditTraceService: AuditTraceService,
    private authService: AuthenticationService,
    private paymentService: PaymentService,
    private emailService: EmailsService,
    private orderService: OrdersService,
    public pdfService: PdfService,
    private productService: ProductsService,
    private route: Router,
    private pharmacyOrderScheduleService: PharmacyOrderScheduleService,
    private cd: ChangeDetectorRef,
  ) { 
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.consult && changes.consult.currentValue ){
      this.consult = changes.consult.currentValue;
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.paymentsSubscription.unsubscribe();
    if (this.docSubscription) {
      this.docSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    // this.getPendingCredit();
    await this.getOrder(); 
    // this.displayAddCouponOption();

    this.checkIfCouponsAreExpired();

    await this.authService.userSubscription.subscribe(res => { this.currentUser = res })
    this.settings = await this.authService.getRemoteConfig();

    this.listenToDocumentFieldChange();

    // Simulate document ID being dynamically set after component load
    setTimeout(() => {
      const dynamicDocId = this.details.id; // Replace with logic to get the dynamic ID
      this.docId$.next(dynamicDocId);
    }, 5000);  // Simulate delay for dynamic document ID loading
  }

  discountQty = 0
  discountList = [];
  subtotalQty = 0
  oneTimeClick = false;

  showShipmentFrequency: boolean[] = [];
  deliveryFrequencies: any[] = [];

  async getOrder(){
    let runOneTime = true;
    let calculateOnce = true;
    //Added value changes bc the amount due wasnt calculated the first time right
    let orderId = this.id ? this.id : this.orderId.toString(); 
    let sub = await this.db.collection("orders").doc(orderId).valueChanges().subscribe(async (order:any) => {
      this.details = order; 
      
      // this.displayAddCouponOption();

      if(!this.patientUser){
        this.patientUser = await this.authService.getUserProfile(await this.details.uid);
        this.getAcceptaCards();
      }
      this.paymentsSubscription = this.paymentService.orderPayments$(this.details.uid)
      .subscribe((data) => {
        this.payments = data.filter(x => x.status == "pending" && x.paymentStatus == "pending" && x.orderID == this.details.id);
      });
      this.subscriptions.add(this.paymentsSubscription);
      
      let sub1 = this.db.collection("orders").doc(await this.details.id).collection("orderLines").valueChanges().subscribe(async (lines:any) => {
        
        this.orderLines = await lines.sort((a, b) => {
          return a.orderLineID - b.orderLineID
        });

        this.details.prescriptionSigned = await this.prescriptionSigned() ? true : false;

        this.orderLinesLoaded = true;
        this.discountQty = 0
        this.discountList = [];
        this.subtotalQty = 0
        this.orderLines.forEach(async (line,i)=> {
          line.productPrice = Number(line.productPrice).toFixed(2);
          if(typeof(line.productPrice) == 'string' && line.productPrice?.startsWith('-')){
            this.discountQty += Number(line.productPrice)
            this.discountList.push({name: line.productName, price: Number(line.productPrice).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })})
          }
          else {
            this.subtotalQty += Number(line.productPrice)
          }

        });

        this.getProductDeliveryFrequencyOrderLines(this.orderLines);
      });
      this.subscriptions.add(sub1);
      if(runOneTime){
        runOneTime = false;
        this.getCardInOrder(this.details.acceptaID);
        this.getAddress();

        if(this.details.paymentStatus == 'credit' || this.details.paymentStatus == 'partial credit'){
          this.getCreditNote();
        }
  
        if(this.details.paymentStatus == 'Refunded' || this.details.paymentStatus == 'partial refund'){
          this.getRefund();
        }

        let oneMinutesAgo = new Date(Date.now() - 1 * 60 * 1000);
        let sync_time = this.details.sync_time ? new Date(this.details.sync_time.seconds * 1000) : new Date();

        if(
          // this.details.orderState !== "ready" && 
        this.details.orderState == 'ready' || this.details.orderState == 'draft' || (this.details.orderState == 'create' && sync_time < oneMinutesAgo)){
          if(this.details.orderState == 'create' && sync_time < oneMinutesAgo){
            this.db.collection("orders").doc(this.details.id).update({orderState: "draft"});
            this.syncronizeOrder();
          }
          else {
            this.syncronizeOrder();
          }
        }

        if(this.details.paymentStatus == 'paid'){
          this.details.orderState = "done";
        }
      }

      if((calculateOnce && this.details.orderTotal) || (this.details.orderTotal == '0.0' && this.details.paymentStatus != 'paid')){ //Checks amount due after the order is created
        calculateOnce = false;
        // this.calculateAmountDue();
      }
      this.calculateAmountDue();
    })  
    this.subscriptions.add(sub);
  }

  getProductDeliveryFrequencyOrderLines(orderLines: any[]) {
    for (let i = 0; i < orderLines.length; i++) {
      if(orderLines[i].deliveryFrequencies){
        this.deliveryFrequencies[orderLines[i].productID] = orderLines[i].deliveryFrequencies;
      }
      else {
        this.productService.getProductDeliveryFrequencyOrderLines([orderLines[i]]).then((res: any) => {
          this.deliveryFrequencies[orderLines[i].productID] = res[orderLines[i].productID];
        })
      }
    }
  }

  async getAddress(){
    // if(this.details.consultationID){
    //   this.db.firestore.collection('consultation').doc(this.details.consultationID).collection('Address').get().then(async res => {
    //     if(res.docs.length > 0){
    //       this.address = res.docs;
    //       this.address = await res.docs.filter(x => x.data().AddressType == "Shipping")[0].data();
    //       if(this.address){
    //         this.address = await Object.assign(this.address, {customer: this.details.name})
    //       }
    //     }
    //   });
    // }else{
    // }
    // Commented because the consult could have a reference to an old address.
    if(!this.details.shippingAddress && this.details.paymentStatus != "paid"){
      this.firebaseService.getUserAddresses(this.details).then((res:any) => {
        if(res.length > 0){
          let address = res.filter(x => x.AddressDefault == true && x.AddressType == 'Shipping')[0];
          if(address){
            let warehouse = 2
            if(address.AddressStateCode == "PR"){
              warehouse = 1
            }
            this.db.collection("orders").doc(this.details.id).update({shippingAddress: address, warehouse_id: warehouse});
          }
          else {
            address = res.filter(x => x.AddressType == 'Shipping')[0];
            if(address){
              let warehouse = 2
              if(address.AddressStateCode == "PR"){
                warehouse = 1
              }
              this.db.collection("orders").doc(this.details.id).update({shippingAddress: address, warehouse_id: warehouse});
            }
          }
        }
      })
    }
  }

  dismiss(){
    this.dismissModal.emit(true);
    this.modalService.dismissAll()
    
  }

  showModalAddCard(modal: any){
    this.modalRef = this.modalService.open(modal, 
      {
        size: 'md',
        centered: true,
        backdrop: 'static',
        windowClass: 'addCard'
      }
    );
  }

  handleSection(){
    let coll = document.getElementsByClassName("collapsible");
    let i;
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.display === "block") {
          content.style.display = "none";
        } else {
          content.style.display = "block";
        }
      });
    }
  }

  setAutomaticPayments($event, product){
    if($event.target.checked){
      this.db.collection("orders").doc(this.details.id).collection("orderLines").doc(product.id.toString()).update({automaticPayment: true});
    }else{
      this.db.collection("orders").doc(this.details.id).collection("orderLines").doc(product.id.toString()).update({automaticPayment: false});
    } 
  }

  orderPaymentHistory(){
    this.paymentHistory = [];
    let sub = this.db.collection("creditCardPayments", ref => ref.where('uid', '==', this.details.uid).where("orderID", "==", this.details.id)).valueChanges().subscribe( (trail: any) => {
      this.paymentHistory = trail.sort((a, b) => b.date.seconds - a.date.seconds);
    });
    this.modalRef = this.modalService.open(this.orderPaymentHistoryModal, { size: 'md', backdrop: 'static',keyboard:false, centered: false, windowClass:"test" });
    this.subscriptions.add(sub);
  }

  async onItemChange(event){
    let data = {
      creditCardRef: "",
      acceptaID: "",
    };
    if(event !== undefined){
      data.creditCardRef = event.brand + "-" + event.last4 + " Expires " + event.expDate;
      data.acceptaID = event.customerNumber;
    }
    this.paymentMethod = [event]
    this.details.acceptaID = data.acceptaID;
    this.details.last4 = event.last4;
    this.db.collection("orders").doc(this.details.id).update({acceptaID: data.acceptaID});
    let trace:AuditTracer = {
      id: this.firebaseService.getDate(),
      date: new Date(),
      userName: this.patientUser.firstName + " " + this.patientUser.lastName1,
      userUID: this.details.uid,
      description: `Binded card to order. acceptaID: ${data.acceptaID} `,
      authorizedAdminName:this.currentUser.firstName + " " +this.currentUser.lastName1,
      authorizedAdminUID:this.currentUser.uid     
    }
    this.auditTraceService.bindCardToOrder(trace, this.details.id);
    this.getCardInOrder(data.acceptaID);
  }

  getCardInOrder(acceptaID){
    if(acceptaID !== undefined && acceptaID !== ""){
      let sub = this.db.collection("users").doc(this.details.uid).collection("wallet").doc(acceptaID).valueChanges().subscribe(async data => {   
        if(data && data.length != 0 && data.active == true){
          this.creditCardRef = data.brand + "-" + data.last4 + " Expires " + data.expDate;
          this.paymentMethod = await data;
          this.card = true;
        }
      });
      this.subscriptions.add(sub);
    }else{
      this.getAcceptaCards();
    }
  }

  async getAcceptaCards(){
    let sub = this.db.collection("users").doc(this.patientUser.uid).collection("wallet").valueChanges().subscribe(async (res:any) => {
      this.allPaymentMethods = await res.filter(w => w.active == true);
      if(res.length > 0){
        this.card = true;
      }
    });
    this.subscriptions.add(sub);
  }

  swal
  blockedCard(nextAttempt){
    this.translate.get('WALLET').subscribe((res: any) => {
      this.swal = res
    })
    Swal.fire({
      customClass: {
        confirmButton: 'swalPurple',
      },
      title: this.swal.CARDBLOCKED,
      text: this.swal.TEXT + nextAttempt,
      icon: 'error',
      showCancelButton: false,
      confirmButtonText: this.swal.TESTCLOSE,
    });
  }

  denyCard(){
    this.translate.get('WALLET').subscribe((res: any) => {
      this.swal = res
    })
    Swal.fire({
      customClass: {
        confirmButton: 'swalPurple',
      },
      title: this.swal.DENYCARD,
      html: this.swal.DENYCARDTEXT,
      icon: 'error',
      showCancelButton: false,
      confirmButtonText: this.swal.TESTCLOSE,
    });
  }

  async calculateAmountDue(){ //Calculates the amount due after partial payment
    let payments:any = await this.orderPayments()
    if(payments.length > 0){
      payments = payments.filter(x => x.status == "success");
      const sum = payments.reduce((total, obj) => total + Number(obj.amount), 0);
      this.amountDue = (Number(this.details.orderTotal) - sum).toString();
      this.partialAmount = Number(this.amountDue).toFixed(2);
    }else{
      this.amountDue = this.details.orderTotal;
      this.partialAmount = Number(this.amountDue).toFixed(2);
    } 
  }

  orderPayments(){ //All payments for this order
    return new Promise<any>((resolve, reject) => {
      let sub = this.db.collection("creditCardPayments", ref => ref.where('uid', '==', this.details.uid).where("orderID", "==", this.details.id)).valueChanges().subscribe((trail: any) => {
        resolve(trail.sort((a, b) => b.date.seconds - a.date.seconds));
      });
      this.subscriptions.add(sub);
    })
  }

  openFirstOrderTerms(){
    this.modalRef2 = this.modalService.open(this.firstOrderTermsModal, {size: 'md', backdrop: 'static', windowClass:'firstOrderDocument', centered: true})
  }

  prescriptionSigned(){
    try{
      let promise = new Promise<any>((resolve, reject) => {
        this.orderLines.forEach(x => {
          if(x.type == 'product'){
            this.db.firestore.collection("prescription").doc(x.prescriptionID).get().then((document:any) => {
              if(document.data()){
                if(document.data().patientSignature){
                  resolve(true)
                }else{
                  resolve(false);
                }
              }else{
                resolve(true)
              }
            });
          }
          if(x.type == 'service' && !x.promo){
            resolve(false)
          }
        });
      });
      return promise;
    }
    catch(err){
    }
  }

  setDAW(product){
    return product.daw == 2 ? true : false;
  }

  sign(){
    this.modalRef = this.modalService.open(this.signaturePadModal, {size: 'lg', backdrop: 'static', centered: true, windowClass:'signPad' });
  }

  setOrientation(event){
    this.db.collection("orders").doc(this.details.id).update({ orientation: event})
    this.orderLines = this.orderLines.filter(x => x.type == "product" && x.prescriptionID != "")
    this.orderLines.forEach(async (line, i:number) => {
      this.db.collection("prescription").doc(line.prescriptionID).update({
        orientation: event
      })

      this.db.firestore.collection("prescription").doc(line.prescriptionID).get().then((document:any) => {
        this.pdfService.generatePdf(Object.assign(document.data(), {orientation: event}), document.data().practitionerSignature);
      })
    });
    return event;
  }

  test(){
    if(this.modalRef2 !== undefined){
      this.modalRef2.close();
      this.modalRef2 = undefined;
    }
    if(this.modalRef !== undefined){
      this.modalRef.close();
      this.modalRef = undefined;
    }
    // this.getOrder();    
    this.makePayment();
  }

  electronicSignature(){
    this.signature = this.patientUser.firstName + ' ' + this.patientUser.lastName1
    this.signRef = Math.floor(Math.random() * 10000000) + 1
    this.dateCertified = new Date(Date.now())

    if(this.details.orientation == undefined){
      this.details = Object.assign(this.details, {orientation: false});
    }
    this.db.collection("orders").doc(this.details.id).update({
      orientation: this.details.orientation,
      patientSignature: this.signature,
      patientSignedDate: this.dateCertified,
      patientSignRef: this.signRef
    }).then(() => {

      this.orderLines = this.orderLines.filter(x => x.type == "product" && x.prescriptionID != "")
      this.orderLines.forEach(async (line, i:number) => {
        this.db.collection("prescription").doc(line.prescriptionID).update({
          orientation: this.details.orientation,
          patientSignature: this.signature,
          patientSignedDate: this.dateCertified,
          patientSignRef: this.signRef
        })

        this.db.firestore.collection("prescription").doc(line.prescriptionID).get().then((document:any) => {
          this.pdfService.generatePdf(Object.assign(document.data(), {orientation: this.details.orientation, patientSignature: this.signature, patientSignedDate: this.dateCertified, patientSignRef: this.signRef}), document.data().practitionerSignature);
        })
      });
    });
  }

  fixedDAW(product){
    let index = this.orderLines.findIndex(x => x.productID == product.productID);
    if(product.daw == 0){
      this.orderLines[index].daw = 2;
      this.db.collection('orders').doc(this.details.id).collection("orderLines").doc(product.productID).update({daw: 2})
      this.orderLines[index].daw = 0;
    }else if(product.daw == 2){
      this.db.collection('orders').doc(this.details.id).collection("orderLines").doc(product.productID).update({daw: 0})
    }
  }

  async storeSignature(signature){ //Gets signature from signature pad
    this.signature = signature;  
    this.modalRef.close();
  }

  async syncronizeOrder(){ //Creates order in Odoo
    this.orderService.createOrderOdoo(this.details);
  }

  disableCompleteAndPayButton(){
    let other_btn: HTMLButtonElement = document.getElementById("complete-and-pay-btn") as HTMLButtonElement;
    other_btn.disabled = true;
    other_btn.classList.remove("scale");
    other_btn.innerHTML = "<span class='spinner-border'></span>";
  }




  async makePayment(){
    if(this.oneTimeClick == false){
      this.oneTimeClick = true;
      const PaymentClass = new Payment(this.paymentService, this.authService, this.db, this.firebaseService, this.emailService, this.auditTraceService);
      let button: HTMLButtonElement = document.getElementById("approval-btn") as HTMLButtonElement;
      const EXPIRATION_TIME_IN_SECONDS = 300;  // 300 seconds = 5 minutes
  
      // Get the current timestamp in seconds
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  
      // this.db.collection("orders").doc(this.details.id).update({processing: true});
      let latestAttempt = this.payments.length > 0 ? this.payments[0] : null;
      this.translate.get('ORDERS.STATUS').subscribe((res: any) => {
        this.swal = res;
      })
      button.innerText = this.swal.PROCESSING;
      button.classList.remove("scale");
  
      // 
      // let cardRef = await this.paymentService.getCard(this.details.uid, this.details.acceptaID)
      let blocked = this.details.acceptaID ? await this.handleCardAttempts() : true;
  
      const testUser = PaymentClass.paymentValidateTest(this.details.email);
      
  
      if(testUser == false && Number(this.details.orderTotal) !== 0 && Number(this.details.orderTotal) > 0 && blocked == false){
        if(this.payments.length == 0){
          this.paymentConfiguration();
          return
        }
    
        if (
          latestAttempt && latestAttempt.status === "pending" && latestAttempt.paymentStatus === "pending" &&
          !latestAttempt.hasOwnProperty("Code") && !latestAttempt.hasOwnProperty("ErrorMessage") &&
          (currentTimeInSeconds - latestAttempt.date.seconds) >= EXPIRATION_TIME_IN_SECONDS
        ){
          // this.paymentConfiguration();
          let transactions = await this.paymentService.acceptaQueryServiceTransactionByCustomer(latestAttempt);
          if(transactions == null || this.isResponseEmpty(transactions)){
            this.paymentConfiguration()
          }
        }
    
        if(latestAttempt && latestAttempt.status === "pending" && latestAttempt.paymentStatus === "pending" &&
        !latestAttempt.hasOwnProperty("Code") && !latestAttempt.hasOwnProperty("ErrorMessage") &&
        (currentTimeInSeconds - latestAttempt.date.seconds) <= EXPIRATION_TIME_IN_SECONDS){
          let button: HTMLButtonElement = document.getElementById("approval-btn") as HTMLButtonElement;
          button.classList.remove("scale");
          button.innerText = this.swal.PROCESSING + "...";
          this.processingAlert();
          // button.innerHTML = "Processing...<span class='spinner-border'></span>";
        }
      }
      
  
      if(testUser == true || Number(this.details.orderTotal) == 0){
        let attempt = Object.assign(PaymentClass.transaction, {AuthorizationCode:"411111111", Code: "0000", status:"success", paymentStatus:"paid", uid: this.details.uid, id: uuidv4(), orderID: this.details.id, orderIDRef: Number(this.details.orderId)});
        this.paymentService.createPaymentAttempt(attempt, this.details);
        this.db.collection("orders").doc(this.details.id).update({paymentStatus: "paid", orderState: "done"});
        let product = this.orderLines.filter(x => x.type == "product")
        this.pharmacyOrderScheduleService.generatePharmacyOrderSchedule(this.details, product);
      }
    }
    else {
      this.processingAlert();
    }
  }

  testFunction(){
    let product = this.orderLines.filter(x => x.type == "product")
    // console.log('passing')
    this.pharmacyOrderScheduleService.generatePharmacyOrderSchedule(this.details, product);
  }

  async paymentConfiguration(){
    let button: HTMLButtonElement = document.getElementById("approval-btn") as HTMLButtonElement;
    const payment = new Payment(this.paymentService, this.authService, this.db, this.firebaseService, this.emailService, this.auditTraceService);
    const { paymentAttempts, paymentRetrialTime } = this.settings;

    payment.consultID = this.details.consultationID;
    payment.transaction.consultationId = this.details.consultationID;
    payment.paymentAttempts = paymentAttempts;
    payment.paymentRetrialTime = paymentRetrialTime;
    payment.data.customerNumber = this.details.acceptaID;
    payment.data.amount = this.partialAmount.toString(); 
    payment.data.orderID = this.details.id;
    payment.transaction.amount = this.partialAmount.toString(); 
    payment.data.amount = this.partialAmount.toString(); 
    payment.data.uid = this.details.uid;
    payment.transaction.amount = this.partialAmount.toString(); //originally this.details.orderTotal
    payment.customer.uid = this.details.uid;
    payment.customer.accountType = this.patientUser.accountType;
    payment.customer.email = this.details.email;
    payment.order = this.details;
    payment.orderLines = this.orderLines;
    payment.orderTotal = this.amountDue;
    payment.currentUser = this.currentUser;

    

    let attempt = await this.paymentService.createPaymentAttempt(payment.transaction, this.details);
    setTimeout(async () => {
      // this.paymentOnCall(payment.data, attempt, await this.paymentService.getCard(this.details.uid, this.details.acceptaID),this.details)
      let response = await payment.paymentOnCall(payment.data, attempt, await this.paymentService.getCard(this.details.uid, this.details.acceptaID),this.details)
      if(response === "success"){
        this.db.collection("orders").doc(this.details.id).update({orderState: "done", paymentStatus: "paid"});
        let product = this.orderLines.filter(x => x.type == "product");
        this.pharmacyOrderScheduleService.generatePharmacyOrderSchedule(this.details, product);
      }


      if(response === "failed"){
        this.handlePaymentError()
      }

      if(response ==  'blocked'){
        button.innerHTML = "Blocked!";
        //calculate the time
        // setTimeout(() => this.blockedCard(result.data.nextAttempt), 1000);  
      }
    }, 3000);
  }

  handlePaymentError(){

    let button: HTMLButtonElement = document.getElementById("approval-btn") as HTMLButtonElement;
    this.translate.get('ORDERS.STATUS').subscribe((res: any) => {
      button.innerText = "Error!";
      this.oneTimeClick = false;
      setTimeout(() => button.innerHTML = res.RETRY, 1000);
      setTimeout(() => button.innerHTML = res.PAYBALANCEDUE, 3000); 
    })
  }
  




  async handleCardAttempts(){
    let remoteConfig:any = await this.authService.getRemoteConfig();
    const { paymentAttempts, paymentRetrialTime } = remoteConfig;
    const user:any = await this.authService.getUserProfile(this.details.uid);
    const cardRef = await this.paymentService.getCard(this.details.uid, this.details.acceptaID);
    const nextAttempt:any = cardRef.blockedDate ? (cardRef?.blockedDate?.seconds + (paymentRetrialTime * 3600)) * 1000 : (Date.now() + (paymentRetrialTime * 3600)) * 1000;
    return new Promise<any>((resolve, reject) => {
      if(cardRef.attempts >= paymentAttempts){
        let blocked:Blocked = {
          blocked: true,
          blockedDate: new Date(Date.now()),
          blockedReason: "Too many failed attempts",
          attempts: cardRef.attempts
        }
   
        if(Date.now() < nextAttempt){
          if(cardRef.blocked != true){
            this.db.collection("users").doc(this.details.uid).collection("wallet").doc(this.details.acceptaID).update(blocked);
          }
          
          if(cardRef.attempts == 2){
            const payment = new Payment(this.paymentService, this.authService, this.db, this.firebaseService, this.emailService, this.auditTraceService);
            let res = {
              value: "blocked",
              cardRef: cardRef,
              nextAttempt: cardRef.blockedDate ? formatDate(new Date((cardRef.blockedDate.seconds + (paymentRetrialTime * 3600)) * 1000), 'MMMM d, y hh:mm a zzzz', user.preferedLang) : formatDate(new Date((Date.now() + (paymentRetrialTime * 3600)) * 1000), 'MMMM d, y hh:mm a zzzz', user.preferedLang)
            }
            payment.failedAttemptMail(cardRef);
            this.oneTimeClick = false;
            setTimeout(() => this.blockedCard(res.nextAttempt), 1000);  
            this.db.collection("orders").doc(this.details.id).update({processing: false});
          }
          
          
          resolve(true);
        }else{
          if(cardRef.attempts == 2){
            resolve(false);
          }
          else  {
            resolve(true);
            setTimeout(() => this.denyCard(), 1000);  
          }
          // blocked.attempts = 0;
          // blocked.blocked = false;
          // blocked.blockedDate = cardRef.blockedDate;
          // this.db.collection("users").doc(this.details.uid).collection("wallet").doc(this.details.acceptaID).update(blocked).then(() => {
          //   resolve(true)
          // });
        }
      }else{
        resolve(false);
      }
    });
  }


  isResponseEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
 

  processingAlert(){
    this.translate.get('ORDERS.STATUS').subscribe((res: any) => {
      this.swal = res
    
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'info',
        title: res.PROCESSING,
        text: res.PROCESSINGTOAST
      })
    })
  }


  async finalRefactor(){

    if(this.details.shippingAddress){
      if(this.details.refill){
  
        //! FIX REFILL THING
        // let prodOrderLine = this.orderLines.filter(x => x.type == "product");
        // let product = await this.productService.getProduct(prodOrderLine[0].productID.toString());
        // let canBePaid = this.orderService.canBePaid(this.details.date, product);
          // this.makePayment();
  
          // if(!canBePaid){
          //   this.refillReasons = true;
          //   return 
          // }else{
          //   // this.makePayment();
          // }
          // return 
      }
      if(this.details?.productType == "service"){
        this.makePayment();
      }
  
      if(this.details?.productType !== "service"){
  
        let checkProducts = this.orderLines.filter(x => x.type == "product" && x.deliveryFrequencyInDays == undefined);
        if(checkProducts.length > 0){
          this.translate.get('ORDERS').subscribe((res: any) => {
            this.swal = res
          })
          Swal.fire({
            title: this.swal.WARNING,
            text: this.swal.WARNINGMSG,
            icon: 'warning',
            confirmButtonColor: 'v.$orange',
          });
  
        }
        else {
          let signed = await this.prescriptionSigned();
          if(!signed){
            this.openFirstOrderTerms();
          }else{
            this.makePayment();
          }
        }
      }
    }
    else {
      this.translate.get('ADDRESS').subscribe((res: any) => {
        this.swal = res
      })
      Swal.fire({
        customClass : {
          confirmButton: 'btn-light'
        },
        title: this.swal.WARNING,
        text: this.swal.MISSINGADDRESS,
        icon: 'warning',
        confirmButtonText: this.swal.CLOSE,
      });
    }
  }


  refillCanBeProcessed($event){
    this.refillReasons = false;
    setTimeout(() => {
      this.makePayment();
    }, 1500)
  }

  changePrescription(id: string){
    this.route.navigate(['/prescription-list'], { queryParams: { prescription: id } }).then(() => {
      this.dismiss();
    });
  }


  async redirectToConsults(){
    let product = await this.productService.getProduct(this.productToChange.parentProductId.toString());
    let template = await this.getCondition(product.condition[0]);
    this.route.navigate(['/consultations'], {queryParams: {condition: template.conditionCode}});
    this.dismiss()
  }

  getCondition(condition:string){
    return new Promise<any>((resolve, reject) => {
      this.db.collection("consultTemplates").doc(condition).valueChanges().subscribe((template:any) => {
        resolve(template);
      });
    });
  }




  callOrderChangeProduct(product){
    let open = false;
    
    // this.db.collection("prescription", ref => ref.where("orderID", "==", this.details.id)).valueChanges().subscribe((prescription:any) => {
      // const { ID } = prescription[0];
      this.db.collection("prescription").doc(product.prescriptionID).collection("Items").valueChanges().subscribe(async (items:any) => {
        // let item = items.filter(item => item.parentProductId == Number(product.productID));
        let item = items.filter(item => item.rxcui == product.rxcui);
        this.orderLineProduct = await product;
        if(item.length > 0){
          this.productToChange = await item[0];
          open = true;
        }else{
          this.productToChange = await item[0];
          open = true;
        }
        if(open && this.modalRef2 == undefined){
          open = false
          this.modalRef2 = this.modalService.open(this.orderChangeProductModal, { size: 'lg', backdrop: 'static',centered: false, windowClass:"orderChangeProduct", keyboard: false });
        }
      });
    // });
  }


  setShipmentFrequency(event, product:BaseProductPrescription){
    // this.showShipmentFrequency[index] = event.target.checked;
    const frequency = {deliveryFrequencyInDays: Number(event.frequency), automaticPayment: event.autoPayment};
    this.db.collection("orders").doc(this.details.id).collection("orderLines").doc(product.id.toString()).update(frequency);
    this.pharmacyOrderScheduleService.updatePharmacyOrderScheduleDeliveryFrequency(this.details.uid, product, frequency.deliveryFrequencyInDays, frequency.automaticPayment);
  }


  setDeliveryFrequency(frequency:number, automaticPayment: boolean, product: BaseProductPrescription){
    return this.orderLines.some((line) => {
      return product.id == line.id && line.deliveryFrequencyInDays == frequency && line.automaticPayment == automaticPayment;
    });
  }

  listenToDocumentFieldChange(): void {
    this.docSubscription = this.docId$
      .pipe(
        filter(docId => !!docId),  // Ensure the document ID is not null or undefined
        switchMap(docId => {
          if (docId) {
            this.documentRef = this.db.doc(`orders/${docId}`);
            return this.documentRef.valueChanges();
          }
          return [];  // Empty observable if no document ID is set
        })
      )
      .subscribe((docData: any) => {
        if (docData) {
          const fieldValue = docData.orderState;  // Replace with your field name
          const fieldValue2 = docData.couponApplied;
          if (this.orderLinesLoaded ===  true && fieldValue != 'create' && fieldValue2 === false && this.hasCheckedCoupon === false && docData.coupon && this.clickedApplyCoupon === false) {
            this.hasCheckedCoupon = true;
            this.checkOrderForExistingCoupon();
          }
        }
      });
  }

  applyCouponCode(){

    this.clickedApplyCoupon = true;

    var orderTotal = Number(this.amountDue);

    this.applyingCoupon = true;
    this.couponExistsInOrder = false;
    this.couponValid = false;
    this.couponErrorExists = false;
    this.couponError = false;
    this.appliedDiscount = false;
    this.couponInvalid = false;
    this.couponInvalidOrder = false;
    this.couponPreviouslyUsed = false;

    const inputElement = document.getElementById('couponCodeValue') as HTMLInputElement;
    const inputValue = inputElement.value.trim();

    this.couponCode = inputValue.toLowerCase();

    //Check if coupon code is active and exists:
    this.db.firestore.collection("coupons").where('couponCode', '==', this.couponCode).where('active', '==', true).limit(1).get().then((querySnapshot) => {
      //If coupon code does not exist or is not active:
      if(querySnapshot.empty){
        this.couponExistsInOrder = false;
        this.couponValid = false;
        this.couponErrorExists = true;
        this.couponError = false;
        this.appliedDiscount = false;
        this.applyingCoupon = false;
        this.couponInvalidOrder = false;
        this.couponPreviouslyUsed = false;
        this.couponInvalid = true;
        const couponInput = document.getElementById('couponCodeValue') as HTMLInputElement;
        if (couponInput) {
          couponInput.value = ''; // Reset the value to an empty string
        }
      }
      //If coupon code exists and is active:
      else {

        //Check if order total is greater than 0:
        if(orderTotal > 0) {
          //Check if coupon code has been previously used in another order:
          this.db.firestore.collection("orders").where('uid', '==', this.details.uid).where('coupon', '==', this.couponCode).where('couponApplied', '==', true).limit(1).get().then((querySnapshot2) => {
            //If coupon code has not been used in another order:
            if(querySnapshot2.empty){
              this.db.firestore.collection("orders").doc(this.details.id).get().then((doc) => {
                //If coupon code has already been applied in the current order:
                if(doc.data().couponApplied){
                  this.applyingCoupon = false;
                  this.couponErrorExists = false;
                  this.couponError = false;
                  this.couponInvalid = false;
                  this.couponInvalidOrder = false;
                  this.couponPreviouslyUsed = false;
                  this.couponExistsInOrder = true;
                  this.couponValid = true;
                  this.appliedDiscount = true;
                  this.couponCode = doc.data().coupon;
                }
                //If coupon code has not been applied in the current order:
                else{
                  //Save coupon code to order:
                  this.db.collection("orders").doc(this.details.id).set({
                    coupon: this.couponCode,
                    couponApplied: false
                  }, {merge: true}).then(() => {
                    //Send coupon cdde to Odoo:
                    this.sendOrder();
                  })
                  .catch((error) => {
                    //An error has occurred:
                    this.applyingCoupon = false;
                    this.couponExistsInOrder = false;
                    this.couponValid = false;
                    this.appliedDiscount = false;
                    this.couponInvalid = false;
                    this.couponInvalidOrder = false;
                    this.couponPreviouslyUsed = false;
                    this.couponErrorExists = true;
                    this.couponError = true;
                  });
                }
              });
            }
            else{
              //If coupon code already has already been used in another order:
              this.applyingCoupon = false;
              this.couponExistsInOrder = false;
              this.couponValid = false;
              this.appliedDiscount = false;
              this.couponErrorExists = true;
              this.couponError = false;
              this.couponInvalid = false;
              this.couponInvalidOrder = false;
              this.couponPreviouslyUsed = true;
            }
          });
        }
        //If order total is 0:
        else if(orderTotal == 0) {
          this.applyingCoupon = false;
          this.couponExistsInOrder = false;
          this.couponValid = false;
          this.appliedDiscount = false;
          this.couponErrorExists = true;
          this.couponError = false;
          this.couponInvalid = false;
          this.couponPreviouslyUsed = false;
          this.couponInvalidOrder = true;
        }
      }
    });
  }

  displayAddCouponOption() {
    var orderTotal = Number(this.amountDue);
    if(orderTotal > 0) {
      let sub = this.db.collection('consultation').doc(`${this.consult.ID}`).collection('Items').valueChanges().subscribe(items => {
        if (items.length !== 0) {
          // if (items[0].productName.includes('Promo') || items[0].productName.includes('promo') || items[0].productName.includes('Promotion') || items[0].productName.includes('promotion')) {
          //   this.showAddCouponOption = false;
          // } 
          // else {
          //   this.showAddCouponOption = true;
          // }
          this.showAddCouponOption = true;
        } 
        else {
          let sub2 = this.db.collection('consultation').doc(`${this.consult.ID}`).collection('wishlist').valueChanges().subscribe(wishlistItems => {
            if (wishlistItems.length !== 0) {
              this.showAddCouponOption = true;
            } 
            else {
              this.showAddCouponOption = false;
            }
            this.cd.detectChanges();
            // sub2.unsubscribe();
          });
        }
        this.cd.detectChanges();
        // sub.unsubscribe();
      });
    }
    else if(orderTotal == 0) {
      // this.showAddCouponOption = false;
      this.showAddCouponOption = true;
    }
  }

  sendOrder() {

    this.orderService.sendOrderData(this.details).subscribe(
      response => {
        this.couponErrorExists = false;
        this.couponError = false;
        this.couponInvalid = false;
        this.couponInvalidOrder = false;
        this.couponPreviouslyUsed = false;
        this.couponExistsInOrder = true;
        this.couponValid = true;
        this.appliedDiscount = true;
        this.applyingCoupon = false;
        // this.getOrder();
        // this.checkOrderForExistingCoupon();
        // this.refreshOrderLinesAfterAddingCoupon();
        this.removeDuplicateOrderLines(this.details.id);
      },
      error => {
        this.couponExistsInOrder = false;
        this.couponValid = false;
        this.appliedDiscount = false;
        this.applyingCoupon = false;
        this.couponInvalid = false;
        this.couponInvalidOrder = false;
        this.couponPreviouslyUsed = false;
        this.couponErrorExists = true;
        this.couponError = true;
      }
    );
  }

  checkOrderForExistingCoupon() {
    if (this.details.id) {
      this.db.firestore.collection("orders").doc(this.details.id).get().then((doc) => {
        if (doc.data()?.couponApplied === false && doc.data()?.coupon) {
          this.db.firestore.collection("coupons").where('couponCode', '==', doc.data().coupon).where('active', '==', true).limit(1).get().then((querySnapshot) => {

            if (querySnapshot.empty) {
              this.couponExistsInOrder = false;
              this.couponValid = false;
              this.couponErrorExists = true;
              this.couponError = false;
              this.couponInvalidOrder = false;
              this.couponPreviouslyUsed = false;
              this.appliedDiscount = false;
              this.couponInvalid = true;
              this.couponCode = doc.data().coupon;
              const couponInput = document.getElementById('couponCodeValue') as HTMLInputElement;
              if (couponInput) {
                couponInput.value = doc.data().coupon; // Add coupon code value to textbox
              }
            } 
            else {
              this.couponErrorExists = false;
              this.couponError = false;
              this.couponInvalidOrder = false;
              this.couponPreviouslyUsed = false;
              this.couponExistsInOrder = true;
              this.couponValid = true;
              this.appliedDiscount = true;
              this.couponInvalid = false;
              this.couponCode = doc.data().coupon;

              const couponInput = document.getElementById('couponCodeValue') as HTMLInputElement;
              if (couponInput) {
                couponInput.value = doc.data().coupon; // Add coupon code value to textbox
                this.sendOrder();
              }
            }
          });
        }
        else {
          this.couponExistsInOrder = false;
          this.couponErrorExists = false;
        }
      });
    }
  }

  async removeCouponFromOrder(){

    this.removingCoupon = true;

    const docRef = this.db.firestore.collection('orders').doc(this.details.id);
  
    try {

      this.orderService.updateOrderData(this.details).subscribe(
        async response => {
          this.couponErrorExists = false;
          this.couponError = false;
          this.couponInvalid = false;
          this.couponInvalidOrder = false;
          this.couponPreviouslyUsed = false;
          this.couponExistsInOrder = false;
          this.couponValid = false;
          this.appliedDiscount = false;
          this.applyingCoupon = false;
          const couponInput = document.getElementById('couponCodeValue') as HTMLInputElement;
          if (couponInput) {
            couponInput.value = '';
          }
          // Get the document data directly using async/await
          const docSnapshot = await docRef.get(); // No need for toPromise()
      
          if (docSnapshot.exists) {
            const docData = docSnapshot.data();
      
            const updates: any = {};
      
            // Check if the fields exist in the document
            if (docData && 'coupon' in docData) {
              updates.coupon = firebase.firestore.FieldValue.delete();
            }
            if (docData && 'couponApplied' in docData) {
              updates.couponApplied = firebase.firestore.FieldValue.delete();
            }
      
            // If there are any fields to delete, update the document
            if (Object.keys(updates).length > 0) {
              await docRef.update(updates);
              this.getOrder();
              this.removingCoupon = false;
            } 
            else {
              this.removingCoupon = false;
            }
          } 
          else {
            this.removingCoupon = false;
          }
        },
        error => {
          this.couponExistsInOrder = false;
          this.couponValid = false;
          this.appliedDiscount = false;
          this.applyingCoupon = false;
          this.couponInvalid = false;
          this.couponInvalidOrder = false;
          this.couponPreviouslyUsed = false;
          this.couponErrorExists = true;
          this.couponError = true;
          this.removingCoupon = false;
        }
      );
    } 
    catch (error) {
      this.removingCoupon = false;
    }

  }

  removeDuplicateOrderLines(orderId: string) {
    const orderLinesRef = this.db.collection(`orders/${orderId}/orderLines`);

    orderLinesRef.get().subscribe((querySnapshot) => {
      const seenOrderLines: { [key: string]: boolean } = {}; // Track orderLineID
      const batch = this.db.firestore.batch();

      querySnapshot.forEach((doc) => {
        const orderLineData = doc.data() as { orderLineID: string }; // Type assertion
        const orderLineID = orderLineData.orderLineID;

        if (seenOrderLines[orderLineID]) {
          // If orderLineID already exists, mark the document for deletion
          const docRef = this.db.doc(`orders/${orderId}/orderLines/${doc.id}`).ref;
          batch.delete(docRef);
        } 
        else {
          // If it's the first occurrence of this orderLineID, keep it
          seenOrderLines[orderLineID] = true;
        }


        // let lastOrderLineID: any = querySnapshot.docs[querySnapshot.size - 1].data();
        // if(orderLineID === lastOrderLineID.orderLineID){
        //   this.orderLinesLoaded = true;
        // }
      });

      if (!querySnapshot.empty) {
        // Commit the batch delete operation
        batch.commit().then(() => {
          // console.log('Duplicate order lines removed successfully, leaving only one for each orderLineID.');
        })
        .catch((error) => {
          // console.error('Error removing duplicate order lines:', error);
        });
      }
    });
  }

  checkIfCouponsAreExpired() {
    this.db.firestore.collection("coupons").where("active", "==", true).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const coupon = doc.data();
        if (this.isCouponExpired(coupon)) {
          this.db.firestore.collection("coupons").doc(doc.id).set({ active: false }, { merge: true });
        }
      });
    });
  }

  isCouponExpired(coupon: firebase.firestore.DocumentData): boolean {
      // Add your implementation here to determine if the coupon is expired or not
      if (coupon.endDate) {
        const expiryDate = coupon.endDate.toDate();
        return expiryDate < new Date();
      }
  }

  creditAmount = 0
  getCreditNote(){
    this.creditAmount = 0;
    // this.db.firestore.collection("users").doc(this.details.uid).collection("credits").where("prevOrderID", "==", this.details.id).get().then((querySnapshot) => {
    //   querySnapshot.forEach((doc) => {
    //     const creditNote = doc.data();
    //     console.log(creditNote);
    //     this.creditAmount += Number(creditNote.amount);
    //   });
    // });
    this.db.firestore.collection('creditCardPayments').where('uid', '==', this.details.uid).where('prevOrderID', '==', this.details.id).where('paymentStatus', '!=', 'paid').get().then((creditNotes: any) => {
      creditNotes.forEach((creditNote) => {
        creditNote = creditNote.data();
        // console.log(creditNote);
        if(creditNote.paymentStatus == 'credit' || creditNote.paymentStatus == 'partial credit'){
          creditNote.amount = creditNote.amount.replace('-', '');
          this.creditAmount += Number(creditNote.amount);
        }
      });
    });
  }

  refundAmount = 0
  getRefund(){
    this.refundAmount = 0;
    // this.db.firestore.collection("users").doc(this.details.uid).collection("credits").where("status", "==", "unused").get().then((querySnapshot) => {
    //   querySnapshot.forEach((doc) => {
    //     const refund = doc.data();
    //     console.log(refund);
    //     this.refundAmount += Number(refund.amount);
    //   });
    // });
    this.db.firestore.collection('creditCardPayments').where('uid', '==', this.details.uid).where('orderID', '==', this.details.id).where('paymentStatus', '!=', 'paid').get().then((refunds: any) => {
      refunds.forEach((refund) => {
        refund = refund.data();
        // console.log(refund);
        if(refund.paymentStatus == 'Refunded' || refund.paymentStatus == 'partial refund'){
          refund.amount = refund.amount.replace('-', '');
          this.refundAmount += Number(refund.amount);
        }
      });
    });
  }

  pendingCredit = 0;
  getPendingCredit(){
    this.db.firestore.collection("creditCardPayments").where('uid', '==', this.details.uid).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const creditNote = doc.data();
        // console.log(creditNote);
      });
    });
  }
}
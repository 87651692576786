<div *ngIf="!consultOrdersDetails">
  <app-pagetitle [title]="'ORDERS.ORDERSANDREFILLS'"></app-pagetitle>
  <ul ngbNav #justifiednav="ngbNav" [activeId]="1" class="nav-pills nav-justified w-25">
    <li [ngbNavItem]="1">
      <!-- <a ngbNavLink>
        <span>{{'ORDERS.ORDERS' | translate}}</span>
      </a> -->
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="ordersRef"></ng-template>
      </ng-template>
    </li>
    <!-- <li [ngbNavItem]="2">
      <a ngbNavLink>
        <span>{{'ORDERS.REFILL' | translate}}</span>
      </a>
      <ng-template ngbNavContent>
        <ng-template [ngTemplateOutlet]="refills"></ng-template>
      </ng-template>
    </li> -->
  </ul>
  <div class="col-lg-12" [ngbNavOutlet]="justifiednav"></div>
</div>

<div *ngIf="consultOrdersDetails" class="consultOrderDetails">
  <ng-template [ngTemplateOutlet]="ordersRef"></ng-template>
</div>

<ng-template #ordersRef>
  <div class="mt-2">
    <h4>{{'ORDERS.ORDERS' | translate}}</h4>
    <div *ngIf="currentUser.accountType == 'admin'">
      <button class="btn btn-link" (click)="copyTextToClipboard()">Copy Data <i class="mdi mdi-content-copy text-primary"></i></button>
    </div>
    <div *ngIf="currentUser.accountType == 'admin'" class="d-flex justify-content-end">
      <ng-select style="width: 150px;" [clearable]="false" [(ngModel)]="filter">
        <ng-option value="all">All Orders</ng-option>
        <ng-option value="refill">Refill</ng-option>
        <ng-option value="pendingRefill">Pending Refill</ng-option>
        <ng-option value="pending">{{'ORDERS.STATUS.PENDING' | translate}}</ng-option>
        <ng-option value="paid">{{'ORDERS.STATUS.PAID' | translate}}</ng-option>
        <ng-option value="error">{{'ORDERS.STATUS.CANCELLED' | translate}}</ng-option>
        <ng-option value="credit">{{'ORDERS.STATUS.CREDIT' | translate}}</ng-option>
        <ng-option value="expired">{{'ORDERS.STATUS.EXPIRED' | translate}}</ng-option>
        <ng-option value="Refunded">{{'ORDERS.STATUS.REFUNDED' | translate}}</ng-option>
        <ng-option value="partial refund">{{'ORDERS.STATUS.PARTIALREFUND' | translate}}</ng-option>
        <ng-option value="partial credit">{{'ORDERS.STATUS.PARTIALCREDIT' | translate}}</ng-option>
      </ng-select>
    </div>
    <div class="search">
      <i class="fas fa-search"></i>
      <input id="search" type="text" placeholder="{{'ORDERS.PLACEHOLDER' | translate}}" [(ngModel)]="term">
    </div>
    <div class="table-responsive">
      <table id="basic-datatable" class="table table-centered dt-responsive nowrap datatables sortable no-footer dtr-inline">
        <thead class="border-rounded">
          <tr class="justify-content-center text-center">
            <th *ngIf="currentUser.accountType == 'admin'">Patient Info</th>
            <th sortable="order">{{'ORDERS.ORDER' | translate}}</th>
            <th sortable="date">{{'ORDERS.DATE' | translate}}</th>
            <th sortable="status">{{'ORDERS.STAT' | translate}}</th>
            <th sortable="automaticPayment">{{'ORDERS.PAYMENT' | translate}}</th>
            <th sortable="customer">{{'ORDERS.ACTION' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" *ngFor="let order of orders | filter: term" [ngClass]="{'mid': !handleFilter(order)}" (click)="orderDetails(order)">
            <td *ngIf="order.paymentStatus && currentUser.accountType == 'admin'">
              <ngb-highlight *ngIf="order.customer" [result]="order?.customer"></ngb-highlight><br>
              <ngb-highlight *ngIf="order.phone" [result]="order?.phone | mask:'(000) 000-0000'"></ngb-highlight><br>
              <ngb-highlight *ngIf="order.email" [result]="order?.email"></ngb-highlight>
            </td> 
            <td *ngIf="order.paymentStatus">
              <a class="text-dark font-weight-bold">
                <div class="d-flex flex-column">
                  <ngb-highlight [result]="order.orderNo"></ngb-highlight>
                  <span *ngIf="order?.refill" class="refill-order-badge">Refill</span>
                </div>
              </a>
            </td>
            <td *ngIf="order.paymentStatus">
              <ngb-highlight *ngIf="order.date" [result]="order?.date.toDate() | date:'medium'"></ngb-highlight>
            </td>           
            
            <td *ngIf="order.paymentStatus">
              <div class="status d-flex justify-content-center">
                <i *ngIf="order.paymentStatus === 'paid'" class="far fa-check-circle text-success"></i>
                <i *ngIf="order.paymentStatus === 'Refunded' || order.paymentStatus == 'partial refund' || order.paymentStatus == 'partial credit' || order.paymentStatus == 'credit'" class="fas fa-undo-alt W-50"></i>
                <i *ngIf="order.paymentStatus === 'Chargeback'" class="far fa-times-circle text-danger w-50"></i>
                <i *ngIf="order.paymentStatus === 'error'" class="far fa-times-circle text-danger"></i>
                <i *ngIf="order.paymentStatus === 'pending'" class="far fa-clock text-warning"></i>
                <i *ngIf="order.paymentStatus === 'expired'" class="far fa-times-circle text-danger"></i>
                <div class="w-50 d-flex justify-content-start">
                  <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'paid'" [result]="'ORDERS.STATUS.PAID' | translate"></ngb-highlight>
                  <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'Refunded'" [result]="'ORDERS.STATUS.REFUNDED' | translate"></ngb-highlight>
                  <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'partial refund'" [result]="'ORDERS.STATUS.PARTIALREFUND' | translate"></ngb-highlight>
                  <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'partial credit'" [result]="'ORDERS.STATUS.PARTIALCREDIT' | translate"></ngb-highlight>
                  <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'Chargeback'" [result]="'ORDERS.STATUS.CHARGEBACK' | translate"></ngb-highlight>
                  <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'error'" [result]="'ORDERS.STATUS.CANCELLED' | translate"></ngb-highlight>
                  <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'credit'" [result]="'ORDERS.STATUS.CREDIT' | translate"></ngb-highlight>
                  <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'pending'" [result]="'ORDERS.STATUS.PENDING' | translate"></ngb-highlight>
                  <ngb-highlight class="ml-2 w-50" *ngIf="order.paymentStatus === 'expired'" [result]="'ORDERS.STATUS.EXPIRED' | translate"></ngb-highlight>
                </div>
              </div>
            </td>

            <td *ngIf="order.paymentStatus">
              <ngb-highlight class="ml-2" *ngIf="order.automaticPayment" [result]="'ORDERS.STATUS.AUTOMATIC' | translate"></ngb-highlight> 
              <ngb-highlight class="ml-2" *ngIf="!order.automaticPayment" [result]="'ORDERS.STATUS.MANUAL' | translate"></ngb-highlight> 
            </td>
            <td *ngIf="order.paymentStatus">
              <ngb-highlight class="ml-2" *ngIf="order.paymentStatus === 'pending' && consult && consult?.Status != 'Completed'" [result]="'ORDERS.STATUS.NEEDHCPAPPROVAL' | translate" ></ngb-highlight>                
              <ngb-highlight class="ml-2" *ngIf="order.paymentStatus === 'pending' && (consult?.Status == 'Completed' || !consult)" [result]="'ORDERS.STATUS.NEEDPATIENTAPPROVAL' | translate" ></ngb-highlight>                
              <ngb-highlight class="ml-2" *ngIf="order.paymentStatus === 'paid' || order.paymentStatus == 'expired'" [result]="'ORDERS.STATUS.NOACTION' | translate" ></ngb-highlight>                
              <ngb-highlight class="ml-2" *ngIf="order.paymentStatus === 'error'" [result]="'ORDERS.STATUS.CONTACTSUPPORT' | translate" ></ngb-highlight>                
            </td>
          </tr>
        </tbody>
      </table>
      
      <p *ngIf="orders.length == 0" class="d-flex justify-content-center">{{'ORDERS.NOORDERS' | translate}}</p>
    </div>

    <div class="mobile-table">
      <div class="">
        <li *ngFor="let order of orders | filter: term" (click)="orderDetails(order)">
          <div class="d-flex">
            <div class="icon mr-3">
              <i *ngIf="order.paymentStatus === 'paid'" class="far fa-check-circle text-success"></i>
              <i *ngIf="order.paymentStatus === 'Refunded' || order.paymentStatus == 'partial refund' || order.paymentStatus == 'partial credit' || order.paymentStatus == 'credit'" class="fas fa-undo-alt W-50"></i>
              <i *ngIf="order.paymentStatus === 'Chargeback'" class="far fa-times-circle text-danger w-50"></i>
              <i *ngIf="order.paymentStatus === 'error'" class="far fa-times-circle text-danger"></i>
              <i *ngIf="order.paymentStatus === 'pending'" class="far fa-clock text-warning"></i>
              <i *ngIf="order.paymentStatus === 'expired'" class="far fa-times-circle text-danger"></i>
            </div>
            <div class="w-100">
              <div class="d-flex justify-content-start">
                <ngb-highlight class="payment-status" *ngIf="order.paymentStatus === 'paid'" [result]="'ORDERS.STATUS.PAID' | translate"></ngb-highlight>
                <ngb-highlight class="payment-status" *ngIf="order.paymentStatus === 'Refunded'" [result]="'ORDERS.STATUS.REFUNDED' | translate"></ngb-highlight>
                <ngb-highlight class="payment-status" *ngIf="order.paymentStatus === 'partial refund'" [result]="'ORDERS.STATUS.PARTIALREFUND' | translate"></ngb-highlight>
                <ngb-highlight class="payment-status" *ngIf="order.paymentStatus === 'partial credit'" [result]="'ORDERS.STATUS.PARTIALCREDIT' | translate"></ngb-highlight>
                <ngb-highlight class="payment-status" *ngIf="order.paymentStatus === 'Chargeback'" [result]="'ORDERS.STATUS.CHARGEBACK' | translate"></ngb-highlight>
                <ngb-highlight class="payment-status" *ngIf="order.paymentStatus === 'error'" [result]="'ORDERS.STATUS.CANCELLED' | translate"></ngb-highlight>
                <ngb-highlight class="payment-status" *ngIf="order.paymentStatus === 'credit'" [result]="'ORDERS.STATUS.CREDIT' | translate"></ngb-highlight>
                <ngb-highlight class="payment-status" *ngIf="order.paymentStatus === 'pending'" [result]="'ORDERS.STATUS.PENDING' | translate"></ngb-highlight>
                <ngb-highlight class="payment-status" *ngIf="order.paymentStatus === 'expired'" [result]="'ORDERS.STATUS.EXPIRED' | translate"></ngb-highlight>
              </div>
              <div class="d-flex flex-row">
                <div style="width: 30%;">
                  <div class="d-flex flex-column">
                    <ngb-highlight [result]="order.orderNo"></ngb-highlight>
                    <span *ngIf="order?.refill" class="refill-order-badge">Refill</span>
                  </div>
                </div>
                <div class="w-50">
                  <div class="d-flex" *ngIf="order.paymentStatus && order.orderNo && (order.paymentStatus == 'paid')">
                    <span>{{'ORDERS.PAYMENT' | translate}}:  &nbsp; </span>
                    <ngb-highlight class="" *ngIf="order.automaticPayment" [result]="'ORDERS.STATUS.AUTOMATIC' | translate"></ngb-highlight> 
                    <ngb-highlight class="" *ngIf="!order.automaticPayment" [result]="'ORDERS.STATUS.MANUAL' | translate"></ngb-highlight> 
                  </div>
                  
                  <div class="d-flex flex-column" *ngIf="order.paymentStatus && order.orderNo && (order.paymentStatus == 'paid')">
                    <ngb-highlight class="" *ngIf="order.paymentStatus === 'pending' && consult?.Status != 'Completed'" [result]="'ORDERS.STATUS.NEEDHCPAPPROVAL' | translate" ></ngb-highlight>                
                    <ngb-highlight class="" *ngIf="order.paymentStatus === 'pending' && consult?.Status == 'Completed'" [result]="'ORDERS.STATUS.NEEDPATIENTAPPROVAL' | translate" ></ngb-highlight>                
                    <ngb-highlight class="" *ngIf="order.paymentStatus === 'paid' || order.paymentStatus == 'expired'" [result]="'ORDERS.STATUS.NOACTION' | translate" ></ngb-highlight>                
                    <ngb-highlight class="" *ngIf="order.paymentStatus === 'error'" [result]="'ORDERS.STATUS.CONTACTSUPPORT' | translate" ></ngb-highlight>   
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <ngb-highlight *ngIf="order.date" [result]="order?.date.toDate() | date:'mediumDate'"></ngb-highlight>
              </div>
            </div>
          </div>
          <hr style="margin: 0.5rem auto">
        </li>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #refills let-modal>
  <app-refills (viewOrder)="emmiterListener($event)"></app-refills>
</ng-template>

<ng-template #orderDetailsRef let-modal>
  <app-order-details style="height: inherit; overflow-y: auto;" [id]="orderId" [consult]="consult" (dismissModal)="dismissListener()"></app-order-details>
</ng-template>
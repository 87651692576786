import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import firebase from "firebase/compat/app";
import { create, toCanvas } from 'qrcode';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { ToastrService } from 'ngx-toastr';
import { ToastService } from 'src/app/core/services/toast.service';
import { map } from 'rxjs/operators';
import { remoteConfig } from 'src/environments/environment';
var QRCode = require('qrcode');

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';


@Component({
  selector: 'app-authenticator',
  templateUrl: './authenticator.component.html',
  styleUrls: ['./authenticator.component.scss']
})
export class AuthenticatorComponent implements OnInit {

  constructor(private http: HttpClient,
              private authService: AuthenticationService,
              private afs: AngularFirestore,
              private toast: ToastService
              ) { }

  @Output() isAuthenticatorValid = new EventEmitter<boolean>();
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };
  code:any;
  response:any;
  formattedKey:any;
  key:string;
  settings = remoteConfig

  phoneNumber:string;
  
  valid:boolean = false;
  invalid:boolean = false;
  loading:boolean = false;
  message:string;

  profile: any;

  ngOnInit(): void {
   
    this.validateUser();

    firebase.auth().getRedirectResult().then((result) => {
    });
    firebase.auth().onAuthStateChanged((result) => {
    });
    // this.remoteConfig = this.afs.collection("prescription").doc("p0s5k49w8").collection("Items").snapshotChanges().pipe(
    //   map(actions => actions.map(a => {
    //       return a.payload.doc.data();
    //     }))
    //   )
    // this.remoteConfig.subscribe((doc) => {
    //   console.log("remote config", doc)
    // })
    // this.afs.collection("prescription").doc("p0s5k49w8").collection("Items", ref => ref.where("type", "==", "product"))
    // .get().then(doc => {
    //   console.log(doc)
    // })
  }


  test(){
    // const url = `https://us-central1-${this.settings.env}.cloudfunctions.net/authenticator`;
    const url = "http://localhost:5001/dzeus-app-telemd/us-central1/authenticator";
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '* ');
    let body = new HttpParams();
    body = body.set('email', this.profile.email);

    this.http.post(url, body).subscribe(res => {
      let data:any = res;
      // console.log(data.authenthicatorKeys[0].otpauth)
      this.formattedKey = data.authenthicatorKeys[0].fk;
    });
  }

  

  validate(){
    const url = `https://us-central1-${this.settings.env}.cloudfunctions.net/authenticatorTokenValidator`;
    // const url = "http://localhost:5001/dzeus-app-telemd/us-central1/authenticatorTokenValidator";
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '* ');
    // headers.set('Content-Type', 'application/json');
    // headers.set('Access-Control-Allow-Origin', '* ');
    let body = new HttpParams();
    // body = body.set('formattedKey', this.formattedKey);
    // body = body.set('formattedToken', this.code);
    body = body.set('uid', this.profile.uid);
    body = body.set('token', this.code);

    this.http.post(url, body, {responseType: 'json'}).subscribe(async (res:any ) => {
      let data:any = await res;
      this.response = data;
      if(data == null){
       this.valid = false;
       this.toast.showError("Invalid code", "Invalid");
      }else{
        this.valid = true;
        this.isAuthenticatorValid.emit(true);
      }
    });
    // console.log("Calling validate function...");
  }

  trigger(){
    // authenticatorKey
    this.valid = false;
    this.loading = true;
    this.message = "Regenerating key..."
    this.authService.profile().then((profile:any) => {

      this.afs.collection('users').doc(profile.uid).update({authenticatorStatus:"new", authenticatorQRCode: ""});
      // this.afs.firestore.collection("users").doc(profile.uid).get()
      this.afs.collection('users').doc(profile.uid).valueChanges().subscribe((changes:any) => {
        if(changes.authenticatorStatus == 'pending'){
          this.loading = false;
          this.generateQR();
        }
      })
    })
  }

  async onCall(){
    let decrypt = firebase.functions().httpsCallable('decrypt');
    decrypt({ text: "Encrypta esta informacion" })
    .then(async result => {
        // Read result of the Cloud Function.
        
        var sanitizedMessage = await result.data.text;
        // console.log("result", sanitizedMessage);
    }).catch((error) => {
      // Getting the Error details.
      var code = error.code;
      var message = error.message;
      var details = error.details;
      // ...
      // console.log(code, message, details)
    });
  }


  generateQR(){
 
    const url = `https://us-central1-${this.settings.env}.cloudfunctions.net/authenticatorQRCode`;
    const headers = new HttpHeaders();
    let body = new HttpParams();
    let uid = this.profile.uid;
    body = body.set('uid', uid);

    this.http.post(url, body, { responseType: 'json' }).subscribe((res:any )=> {
      this.key = res.key;
      generateQR(res.otpauth).then(url => {
        (<HTMLImageElement>document.getElementById("qrcode")).src=url;
      })
    });



    const generateQR = async text => {
      try {
        let opts = {
          errorCorrectionLevel: 'H',
          type: 'image/jpeg',
          quality: 0.3,
          margin: 5,
          color: {
            dark:"#010599FF",
            light:"#FFFFFFFF"
          }
        }
        // console.log(await QRCode.toDataURL(text))
        return QRCode.toDataURL(text, opts);
      } catch (err) {
        // console.error(err)
      }
    }
    
    
  }

  validateUser(){
    this.authService.profile().then((profile:any)=> {
      this.profile = profile;
      if(profile.authenticatorStatus == "valid"){
        this.valid = true;
        this.isAuthenticatorValid.emit(true);
      }else if(profile.authenticatorStatus == "pending"){
        this.valid = false;
        this.generateQR();
      }
    })
  }



  copyText(){
    let btn = document.getElementById("copy-btn");
      /* Copy the text inside the text field */
    navigator.clipboard.writeText(this.key);
    btn.innerHTML = "Copied";
  }


  //Monday generate service, move http calls, call QRCode generator

}

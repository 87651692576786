<h2 *ngIf="!uploadMedicalInsurance || !uploadPrescription">{{'UPLOAD.FILE-UPLOAD' | translate}}</h2>
<!-- <h2 *ngIf="uploadMedicalInsurance">Upload your Medical Insurance</h2> -->

<!-- Upload  -->
<form id="file-upload-form" class="uploader mt-2">
  <input #fileUpload id="file-upload-comp" type="file" multiple name="fileUpload" accept="{{file_types}}" (change)="getFiles($event)"/>

  <label for="file-upload-comp" id="file-drag">
    <img id="file-image" src="#" alt="Preview" class="hidden">
    <div id="start">
      <svg id="upload-icon" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2"></path>
        <path d="m7 9 5-5 5 5"></path>
        <path d="M12 4v12"></path>
      </svg>
      <span class="formats mt-3">{{'UPLOAD.SUPPORTED-FORMATS' | translate}} {{file_types}}</span>
      <div id="notimage" class="hidden">{{'UPLOAD.SELECT-IMG' | translate}}</div>
      <span id="file-upload-btn" class="btn btn-primary" (change)="fileUpload.click()">{{'UPLOAD.SELECT-FILE' | translate}}</span>
      <!-- <span id="file-upload-btn" class="btn btn-primary" (click)="fileUpload.click()">{{'UPLOAD.SELECT-FILE' | translate}}</span> -->
    </div>
    <div id="response" class="hidden">
      <div id="messages"></div>
      <progress class="progress" id="file-progress" value="0">
        <span>0</span>%
      </progress>
    </div>
  </label>
</form>

 <br>

<div *ngIf="!uploadMedicalInsurance || !uploadPrescription" class="d-flex flex-column justify-content-center">
  <h3>{{'UPLOAD.FILES-UPLOADED' | translate}}</h3>
  <div class="file"  *ngFor="let file of files; let i = index">
    <div class="d-flex">

      <!-- <img src="../../assets/icons/pdf-logo.png" alt=""> -->
      <img src="../../../../assets/icons/pdf-logo.png" alt="">
      <div class="ml-3 w-100">
        <span class="card-title text-start">{{file.name}}</span> <br>
        <span class="card-text size text-start">{{ formatFileSize(file.size) }}</span>
      </div>
      <svg width="25" height="25" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 6 6 18"></path>
        <path d="m6 6 12 12"></path>
      </svg>
    </div>
    <div class="d-flex">
      <div class="progress mt-3">
        <div id="progress-{{file.name}}" class="progress-bar" role="progressbar" [style.width.%]="upload_progress[i]"
          aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
        </div>
      </div>
      <span class="percent">{{upload_progress[i]}} %</span>
    </div>
  </div>
</div>

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, remoteConfig } from 'src/environments/environment';
import firebase from "firebase/compat/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { formatDate } from '@angular/common';

const app = firebase.initializeApp(environment.firebaseConfig);
const functions = getFunctions(app);

@Injectable({
  providedIn: 'root'
})
export class EncryptService {
  settings = remoteConfig
  public cloudFunctionEncryptRef = `https://us-central1-${this.settings.env}.cloudfunctions.net/encryptNote`;
  public cloudFunctionDecryptRef = `https://us-central1-${this.settings.env}.cloudfunctions.net/decryptNote`;
  public cloudFunctionDecryptRefTest = `https://us-central1-${this.settings.env}.cloudfunctions.net/decrypt`;
  constructor(
    private http: HttpClient,
  ) { }

  async encryptNote(note: string, uid: string, consultID, noteID) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '* ');
    let body = new HttpParams();
    body = body.set('note', note);
    body = body.set('uid', uid);
    body = body.set('consultID', consultID);
    body = body.set('noteID', noteID);
    // // this.http.post(this.cloudFunctionDecryptRefTest, body, {headers: headers}).subscribe(res => {
    this.http.post(this.cloudFunctionEncryptRef, body, {headers: headers}).subscribe(res => {
    });
  }

  async decryptNote(note: string, uid: string, consultID, noteID) {
    return new Promise<any>(async (resolve, reject) => {   
      const headers = new HttpHeaders();
      headers.set('Content-Type', 'application/json');
      headers.set('Access-Control-Allow-Origin', '* ');
  
      let body = await new HttpParams();
      body = await body.set('note', encodeURIComponent(note));
      body = await body.set('uid', uid);
      body = await body.set('consultID', consultID);
      body = await body.set('noteID', noteID);
      this.http.post(this.cloudFunctionDecryptRef, body, {headers: headers}).subscribe(res => {
        resolve(res) 
      });
    })
  }
}

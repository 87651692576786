<div class="main">

  <div class="d-flex w-100 justify-content-between">
    <h4 class="title">{{'VIEWCONSULTS.DIAGNOSTICS.DIAGNOSTICS' | translate}}</h4>
    <button class="btn btn-info add" (click)="setForm()">{{'VIEWCONSULTS.DIAGNOSTICS.ADDDIAGNOSTIC' | translate}}</button>
  </div>
  <p class=" description w-75">
    {{'VIEWCONSULTS.DIAGNOSTICS.DESCRIPTION' | translate}}
  </p>



  

  <div *ngIf="!form" class="grid-container">
    <div *ngIf="diagnostics.length == 0" class="">
      <span>{{'VIEWCONSULTS.DIAGNOSTICS.NO-DIAGNOSTICS' | translate}}.</span>
    </div>
    <div class="list" *ngFor="let diag of diagnostics">
      <div class="d-flex w-100 justify-content-between upload-btn">
        <svg width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 5H7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-2"></path>
          <path d="M13 3h-2a2 2 0 1 0 0 4h2a2 2 0 1 0 0-4Z"></path>
          <path d="M9 17v-5"></path>
          <path d="M12 17v-1"></path>
          <path d="M15 17v-3"></path>
        </svg>
        <!--Add validation-->
        <ng-container *ngIf="diag.createdByUID === user.uid">
          <button class="btn btn-info btn-upload" (click)="setEditMode(diag)">
            <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 20h4L18.5 9.5a2.829 2.829 0 0 0-4-4L4 16v4Z"></path>
              <path d="m13.5 6.5 4 4"></path>
            </svg>
            Edit
          </button>
        </ng-container>
        <!--Add validation-->
        <!--Add validation-->
        <button class="btn btn-info btn-upload" (click)="openDiagnosticsDetailsModal(viewDiagnosticsDetailsModal); getSelectedDiagnosticDetails(diag);">View</button>
        <!--Add validation-->
      </div>
      <h5 class="title mt-2">{{diag.code_name}}</h5>
      <span>{{'VIEWCONSULTS.DIAGNOSTICS.RECOMMENDED' | translate}}</span>
      <p class="ellipsis">
        <ng-container *ngIf="diag.recommendation; else lineBreak">
          {{ diag.recommendation }}
        </ng-container>
        <ng-template #lineBreak><br></ng-template>
      </p>
      
      <span>{{'VIEWCONSULTS.DIAGNOSTICS.ASSESSMENT' | translate}}</span>
      <p class="ellipsis">
        <ng-container *ngIf="diag.assessment; else lineBreak">
          {{ diag.assessment }}
        </ng-container>
        <ng-template #lineBreak><br></ng-template>
      </p>

      <div class="d-flex justify-content-between delete-btn align-items-center">
        <span class="date">{{diag.date.toDate() | date: "medium"}}<br>{{diag.createdBy}}</span>
        <!--Add validation-->
        <ng-container *ngIf="diag.createdByUID === user.uid">
          <svg class="delete" (click)="deleteDiagnostic(diag)" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 7h16"></path>
            <path d="M10 11v6"></path>
            <path d="M14 11v6"></path>
            <path d="m5 7 1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12"></path>
            <path d="M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3"></path>
          </svg>
        </ng-container>
        <!--Add validation-->
      </div>
    </div>
    <div *ngIf="!form" class="grid-container">
      <div class="list" *ngFor="let diag of possibleDiagnostics">
        <div class="d-flex w-100 justify-content-between add-btn">
          <svg id="badge" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 17V4l-5 3-5-3v13l5 3 5-3Z"></path>
          </svg>
          <button class="btn btn-info btn-upload" (click)="addDiagnostic(diag)">
            <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 5v14"></path>
              <path d="M5 12h14"></path>
            </svg>
            {{'VIEWCONSULTS.DIAGNOSTICS.ADD' | translate}}
          </button>
        </div>
        <h5 class="title mt-2">{{diag.code_name}}</h5>
        <span class="recommended">{{'VIEWCONSULTS.DIAGNOSTICS.RECOMMENDED' | translate}}</span> 
        <br><br>
        <span class="mt-2">{{'VIEWCONSULTS.DIAGNOSTICS.POSSIBLEDIAGNOSTICS' | translate}}</span>
        <p class="ellipsis">
          <ng-container *ngIf="diag.assessment; else lineBreak">
            {{ diag.assessment }}
          </ng-container>
          <ng-template #lineBreak><br></ng-template>
        </p>
      </div>
    </div>
  </div>

  <!-- <h3 *ngIf="possibleDiagnostics.length > 0" class="title">Other possible diagnostics</h3>
  <div *ngIf="!form" class="grid-container">
    <div class="list" *ngFor="let diag of possibleDiagnostics">
      <div class="d-flex w-100 justify-content-between">
        <svg id="badge" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 17V4l-5 3-5-3v13l5 3 5-3Z"></path>
        </svg>
        <button class="btn btn-info btn-upload" (click)="setEditMode(diag)">
          <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 5v14"></path>
            <path d="M5 12h14"></path>
          </svg>
          Add
        </button>
      </div>
      <h5 class="title mt-2">{{diag.code_name}}</h5>
      <span>Recommendation:</span>
      <p class="ellipsis">
        <ng-container *ngIf="diag.recommendation; else lineBreak">
          {{ diag.recommendation }}
        </ng-container>
        <ng-template #lineBreak><br></ng-template>
      </p>
      
      <span>Assesments:</span>
      <p class="ellipsis">
        <ng-container *ngIf="diag.assessment; else lineBreak">
          {{ diag.assessment }}
        </ng-container>
        <ng-template #lineBreak><br></ng-template>
      </p>
    </div>
  </div> -->




  <div *ngIf="form" class="form-add-and-edit">
    <div *ngIf="!edit" class="d-flex flex-column mt-5">
      <h5 class="title">{{'VIEWCONSULTS.DIAGNOSTICS.SEARCH-DESC' | translate}}
        <!-- <button class="btn btn-info add" (click)="getDiagnosticsTemplates()">
        <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 12a5 5 0 1 0-5 5"></path>
          <path d="M11 3h2m-1 4V3v4Z"></path>
          <path d="m17.657 4.93 1.414 1.414m-3.535 2.121 2.828-2.828-2.828 2.828Z"></path>
          <path d="M21 11v2m-4-1h4-4Z"></path>
          <path d="M13 21h-2m1-4v4-4Z"></path>
          <path d="m6.343 19.07-1.414-1.414m3.535-2.121-2.828 2.828 2.828-2.828Z"></path>
          <path d="M3 13v-2m4 1H3h4Z"></path>
          <path d="M4.929 6.344 6.343 4.93m2.121 3.535L5.636 5.637l2.828 2.828Z"></path>
          <path d="M17.5 20a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"></path>
          <path d="M19.5 19.5 22 22"></path>
        </svg>
        Templates
        </button> -->
      </h5>
      
      <p>{{'VIEWCONSULTS.DIAGNOSTICS.REVIEWSUB' | translate}}.</p>
      <!-- <ng-select id="diagnostic" *ngIf="editDiagnostic && !editMode && chooseFromTemplates" placeholder="{{'VIEWCONSULTS.DIAGNOSTICS.SEARCH' | translate}}" -->
        <!-- <ng-select id="diagnostic" class="w-50" placeholder="{{'VIEWCONSULTS.DIAGNOSTICS.SEARCH' | translate}}"
          (change)="setDiagnosticTemplate($event); diagnosticSave()" [multiple]="false" [clearable]="false">
          <ng-option *ngFor="let template of diagnosticTemplates" [value]="template">{{template.code_name}}</ng-option>
        </ng-select> -->
        
        <!-- <ng-select id="diagnostic" *ngIf="editDiagnostic && !editMode && !chooseFromTemplates" placeholder="{{'VIEWCONSULTS.DIAGNOSTICS.SEARCH' | translate}}" [loading]="loadingSearch" [multiple]="true" -->
        <div class="w-100 d-flex">
          <ng-select #ngSelect *ngIf="!edit" id="diagnostic" class="w-75" placeholder="{{'VIEWCONSULTS.DIAGNOSTICS.SEARCH' | translate}}" [loading]="loadingSearch" [multiple]="true"
            (search)='getDiagnostics($event)' (change)="setDiagnostic($event);" [multiple]="false" [clearable]="false" >
            <ng-option *ngFor="let med of filteredDiagnostics" [value]="med" >{{med[0]}} - {{med[1]}}</ng-option>
            
            <ng-template ng-optgroup-tmp let-item="item" let-index="index">
              {{'VIEWCONSULTS.DIAGNOSTICS.FROMTEMPLATES' | translate}} 
              <ng-option *ngFor="let med of diagnosticTemplates" [value]="med" >{{med[0]}} - {{med[1]}}</ng-option>
            </ng-template>
          </ng-select>
          <div ngbDropdown class="d-inline-block ml-2">
            <button type="button" class="btn btn-outline-primary btn-filter" id="dropdown1" ngbDropdownToggle>
              <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6h16"></path>
                <path d="M8 12h8"></path>
                <path d="M6 18h12"></path>
              </svg>
              <svg width="10" height="10" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="m6 9 6 6 6-6"></path>
              </svg>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdown1">
              <!-- items as buttons -->
              <button ngbDropdownItem>
                <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 12a5 5 0 1 0-5 5"></path>
                  <path d="M11 3h2m-1 4V3v4Z"></path>
                  <path d="m17.657 4.93 1.414 1.414m-3.535 2.121 2.828-2.828-2.828 2.828Z"></path>
                  <path d="M21 11v2m-4-1h4-4Z"></path>
                  <path d="M13 21h-2m1-4v4-4Z"></path>
                  <path d="m6.343 19.07-1.414-1.414m3.535-2.121-2.828 2.828 2.828-2.828Z"></path>
                  <path d="M3 13v-2m4 1H3h4Z"></path>
                  <path d="M4.929 6.344 6.343 4.93m2.121 3.535L5.636 5.637l2.828 2.828Z"></path>
                  <path d="M17.5 20a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"></path>
                  <path d="M19.5 19.5 22 22"></path>
                </svg>
                {{'VIEWCONSULTS.DIAGNOSTICS.OURTEMPLATES' | translate}}
              </button>
              <button ngbDropdownItem>
                <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="m17.8 19.817-2.172 1.139a.392.392 0 0 1-.568-.41l.415-2.412-1.757-1.707a.39.39 0 0 1 .217-.665l2.428-.351 1.086-2.194a.392.392 0 0 1 .702 0l1.086 2.194 2.428.351a.389.389 0 0 1 .217.665l-1.757 1.707.414 2.41a.389.389 0 0 1-.567.412L17.8 19.817v0Z"></path>
                <path d="m6.2 19.817-2.172 1.139a.392.392 0 0 1-.568-.41l.415-2.412-1.757-1.707a.39.39 0 0 1 .217-.665l2.428-.351 1.086-2.194a.391.391 0 0 1 .702 0l1.086 2.194 2.428.351a.389.389 0 0 1 .217.665l-1.757 1.707.414 2.41a.39.39 0 0 1-.567.412L6.2 19.817v0Z"></path>
                <path d="m12 9.817-2.172 1.139a.392.392 0 0 1-.568-.41l.415-2.412-1.757-1.707a.389.389 0 0 1 .217-.665l2.428-.352 1.086-2.193a.392.392 0 0 1 .702 0l1.086 2.193 2.428.352a.39.39 0 0 1 .217.665l-1.757 1.707.414 2.41a.389.389 0 0 1-.567.412L12 9.817v0Z"></path>
                </svg>
                {{'VIEWCONSULTS.DIAGNOSTICS.RECOMMENDATIONS' | translate}}
              </button>
              <!-- <button ngbDropdownItem [disabled]="true">Recommended</button>
              <button ngbDropdownItem [class.disabled]="true" aria-disabled="true">Button (disabled, focusable)</button> -->
            </div>
          </div>
        </div>

    </div>
    <h5 *ngIf="edit" class="title current-diagnostic-edit">{{diagnostic.code_name}}</h5>
    <div class="d-flex flex-column">
      <h5 class="title mt-2">{{'VIEWCONSULTS.DIAGNOSTICS.RECOMMENDATION' | translate}}</h5>
      <textarea name="" id="" cols="30" rows="10" class="form-control" placeholder="Start typing..." [(ngModel)]="diagnostic.recommendation"></textarea>
    </div>
    
    <div class="d-flex flex-column">
      <h5 class="title mt-2">{{'VIEWCONSULTS.DIAGNOSTICS.ASSESSMENT' | translate}}</h5>
      <textarea name="" id="" cols="30" rows="10" class="form-control" placeholder="Start typing..." [(ngModel)]="diagnostic.assessment"></textarea>
    </div>

    <div class="d-flex justify-content-end mt-2 mb-2">
      <button class="btn btn-light mr-2" (click)="close('edit')">{{'VIEWCONSULTS.DIAGNOSTICS.CANCEL' | translate}}</button>
      <button class="btn btn-info" (click)="diagnosticSave('edit')">{{'VIEWCONSULTS.DIAGNOSTICS.SAVE' | translate}}</button>
    </div>
  </div>
</div>

<ng-template #viewDiagnosticsDetailsModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLongTitle">{{'VIEWCONSULTS.DIAGNOSTICS.DIAGNOSTICS' | translate}}</h5>
    <button type="button" class="close" (click)="closeDiagnosticsDetailsModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="row">
          <div class="col">
            <h5 class="title current-diagnostic-edit">{{viewDiagnosticDetailsData.code_name}}</h5>
            <div class="d-flex flex-column">
              <h5 class="title mt-2">{{'VIEWCONSULTS.DIAGNOSTICS.RECOMMENDATION' | translate}}</h5>
              <textarea name="" id="" cols="30" rows="10" class="form-control" placeholder="Start typing..." [(ngModel)]="viewDiagnosticDetailsData.recommendation" readonly></textarea>
            </div>
            
            <div class="d-flex flex-column">
              <h5 class="title mt-2">{{'VIEWCONSULTS.DIAGNOSTICS.ASSESSMENT' | translate}}</h5>
              <textarea name="" id="" cols="30" rows="10" class="form-control" placeholder="Start typing..." [(ngModel)]="viewDiagnosticDetailsData.assessment" readonly></textarea>
            </div>
          </div>
      </div>
  </div>
  <div class="modal-footer" style="display: none;">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
 
